import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import { TipoObjetoParaPermisos, TipoParaPermisos
} from '../../models/general.enum';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { ConfigWindow } from '../../models/config-window';
import { ConfigExpedienteDocumento } from '../../general-config/config-expediente-documento';

@Component({
  selector: 'app-config-consecutivo-expediente',
  templateUrl: './config-consecutivo-expediente.component.html',
  styleUrls: ['./config-consecutivo-expediente.component.css']
})
export class ConfigConsecutivoExpedienteComponent extends ConfigExpedienteDocumento implements OnInit {
  @Input() comandos: Array<any> = [];
  public OnInitStart(){
    this.setOpcionesPermisos=false;
  }

}
