import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

// FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-model-container-selector',
  templateUrl: './model-container-selector.component.html',
  styleUrls: ['./model-container-selector.component.scss']
})
export class ModelContainerSelectorComponent implements OnInit, AfterViewInit {

  @Input() label: string = "ModelContainer"
  @Input() property: string = "ModelContainer"
  @Input() isParentModelProperty: string = "IsParentModel";
  @Input() title: string = ""
  @Input() modelo: any = null;
  @Input() disabled: boolean = false;
  @Input() visble: boolean = true;
  @Input() viewExtra: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() visbleLabel: boolean = true;
  constructor() { }

  ngOnInit() {

    if (this.modelo && (this.modelo[this.isParentModelProperty] == null || this.modelo[this.isParentModelProperty] == undefined))
      this.modelo[this.isParentModelProperty] = false;
  }

  ngAfterViewInit() {
    // var DropdownHTMLElements = document.querySelectorAll('.mcs__dropdown');
    // for (var i = 0; i < DropdownHTMLElements.length; ++i) {
    //   var Dropdown = new fabric['Dropdown'](DropdownHTMLElements[i]);
    // }
  }

}
