import { Component, OnInit } from '@angular/core';
import { GeneralFormBase } from 'src/app/models/general-form-base';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-tencargo',
  templateUrl: './tencargo.component.html',
  styleUrls: ['./tencargo.component.css']
})
export class TEncargoComponent extends GeneralFormComponent implements OnInit {
  GridViewUsuarioEncargo: any;

  public Limpiar(callback: (data: any) => void = null, action?: any) {
    //debugger;
		//this.CreateModel(false);		
    if(action == undefined){
      this.GridViewUsuarioEncargo = this.componentsContainer.Tables.filter(
        (x) => x.name == "Usuario"
      )[0];
    this.GridViewUsuarioEncargo.ReCargarGrilla();
		this.ClearModel();
    }
		this.utility.SetFieldsToModel(this.modelo.modelo, null);//this.filterParent);
		if (callback) callback(this.modelo);
		this.SetModelToControls();
		this.parentClearCall.next({ modelo: this.modelo, action: action });
		this.SetProcesando(false);
  }

  public OnActionEventHandler(event) {
    //debugger;    
    if(event.Action){
      if(event.Action == "ClearModel"){
        if(event.Table){
        if(event.Table == "DataTables_Encargo"){
        //debugger;
        super.Limpiar();
        super.ClearModel();
       }
       if(event.Table == "Usuario"){
        this.modelo.modelo.IdUsuarioEncargo = null;
        this.modelo.modelo.UsuarioEncargo = null;
       }
      }
    }
  }
  super.OnActionEventHandler(event);    
  }  
}