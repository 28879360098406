import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import {
  ControlActions,
  ControllerMethods,
  GeneralPageControlTypes,
  OperacionesDeSolicitudes,
  TipoObjetoParaPermisos,
} from "src/app/models/general.enum";

import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from "src/app/components/controls/file-uploader/file-uploader.component";
import { FormActionsThroughEmitters } from "../../../../../models/general.enum";
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { NgTypeToSearchTemplateDirective } from "@ng-select/ng-select/ng-select/ng-templates.directive";
import { QRCodeBoxComponent } from "src/app/components/controls/qrcode-box/qrcode-box.component";
import { SolicitudCommonComponent } from "./../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { analyzeAndValidateNgModules } from "@angular/compiler";

@Component({
  selector: "app-solicitud-pqrd",
  templateUrl: "./solicitud-pqrd.component.html",
  styleUrls: ["./solicitud-pqrd.component.css"],
})
export class SolicitudPqrdComponent extends SolicitudCommonComponent {
  IdCanalRecepcion: any;
  CanalRecepcion: any;
  Asunto: any;
  Respuesta: any;
  RequiereRespuesta: any;
  ctrRespuesta: any;
  ctrRequiereRespuesta: any;
  BtnPqrdAsociada: any;
  GridRadicadosAsociados: any;

  GrillaAnexos: any;
  // GrillaAnexosDetalle: any;
  UsuariosCopias: any;
  ObservacionesAnulacion: any;
  mensajecontacto: any;
  ItemsAnexos: [];
  tipoSolicitud = "la PQRS";
  UsuarioResponsable: any;
  GridViewVincularExpediente: any;
  GrillaReasignarUsuario: any;

  cnfgButtons: any;
  ComboTema: any;
  textCommandBar: any;
  errorTipoAnexo: any;
  countTipoAnexos: any;
  Departamento: any;
  modalOpenPQRS: boolean;

  SinRespuesta: any;
  ObservacionSinRespuesta: any;
  CtrlSinRespuesta: any;
  FechaRespuesta: any;

  public OnInitEnd() {
    this.Departamento = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdDepartamento" });
    this.CtrlSinRespuesta = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "SinRespuesta"; });
    this.FechaRespuesta = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "FechaRespuesta" });

    this.postSaveEmitter.subscribe(event => {      
        setTimeout(() => { 
          super.Limpiar()
          this.MensajeSeleccionarContacto.Hidden = false;
          this.SetModelToControls();
        }, 2000);
		});

    super.OnInitEnd();
  }

  ngAfterViewInit() {
    this.RequiereRespuesta = this.form.controls[
      SolicitudCommonComponent.Controls.RequiereRespuesta
    ];
    this.Respuesta = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.Respuesta;
      }); //this.form.controls[SolicitudCommonComponent.Controls.Respuesta];

    //Lina Figueredo - Solucion bug 1163 y 1164 - 4/03/2024
    //Se ajustan condicionales y se hace la activacion de las propiedades de los controles de manera mas reducida
    this.Respuesta.Hidden = true;
    if (this.RequiereRespuesta) {
      this.RequiereRespuesta.valueChanges.subscribe((value: boolean = null) => {
        //if (this.textCommandBar == "Nuevo") {
          if (value !== null ) {
            if (value == true) {
              this.RequiereRespuesta.value = null;
              this.Respuesta.Hidden = false;
              /**
             * Edward Morales 19122023
             * Si se trata de una respuesta inmediata ya sea de carácter físico o digital, el sistema no debe obligar al usuario radicador a cargar ningún tipo de oficio
             * Incidencia 0000306
             */
              this.Oficio.RaiseForceValidateSaveEvent(true, false);
              //this.Respuesta.Value= null;
              this.RequiereRespuesta.value = false;
              this.SetControlsValueDocument(["Respuesta"], "required", "true");
              this.SetModelToControls();
              $("input[id='RequiereRespuesta']").prop("checked", true);
            } else if (value == false){
              this.RequiereRespuesta.value = null;
              /**
             * Edward Morales 19122023
             * Si se trata de una respuesta inmediata ya sea de carácter físico o digital, el sistema no debe obligar al usuario radicador a cargar ningún tipo de oficio
             * Incidencia 0000306
             */
              this.Oficio.RaiseForceValidateSaveEvent(true, (this.modelo.modeloVirtual.IdTipoRadicacion == 49 ? true : false));
              this.SetControlsValueDocument(["Respuesta"], "required", "false");
              this.modelo.modelo.Respuesta = null;
              this.SetModelToControls();
              this.Respuesta.Hidden = true;
              $("input[id='RequiereRespuesta']").prop("checked", false);
            }
          }
        //}
      });
    }

    /**Inicio No Requiere Respuesta para notificación de Alertas (1, 2, 3, Vencimiento)
     * 05/09/2023
     * HU No Requiere Respuesta PQRS
     */

     this.SinRespuesta = this.form.controls[
      "SinRespuesta"
    ];
    this.ObservacionSinRespuesta = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == "ObservacionSinRespuesta";
      });
    //debugger; 
    //Lina Figueredo - Solucion bug 1163 y 1164 - 4/03/2024
    //Se ajustan condicionales y se hace la activacion de las propiedades de los controles de manera mas reducida 
    if (this.SinRespuesta) {
      this.SinRespuesta.valueChanges.subscribe((value: any) => {
        //debugger;
        if (this.textCommandBar == "Detalle") {
          if (value != "" || value == false) { //null
            if (value == true) {
              //this.SinRespuesta.value = null;
              //$("#SinRespuesta").attr("disabled","true");
              this.SinRespuesta.Hidden = false;
              this.ObservacionSinRespuesta.Hidden = false;
              //this.Respuesta.Value= null;
              this.SetControlsValueDocument(["ObservacionSinRespuesta"], "required", "true");
              this.SetModelToControls();
              $("input[id='SinRespuesta']").prop("checked", true);
            } else if(value == false && value != "") {
              //this.SinRespuesta.value = null;
              //$("#SinRespuesta").removeAttr("disabled");
              this.SinRespuesta.Hidden = false;
              this.ObservacionSinRespuesta.Hidden = true;
              this.SetControlsValueDocument(["ObservacionSinRespuesta"], "required", "false");
              //this.modelo.modelo.ObservacionSinRespuesta = null;
              this.SetModelToControls();
              //this.ObservacionSinRespuesta.Hidden = true;
              $("input[id='SinRespuesta']").prop("checked", false);
            }
          }
        }
        else{   
          //debugger;                 
          this.SinRespuesta.Hidden = true;
          this.ObservacionSinRespuesta.Hidden = true;
          this.SetControlsValueDocument(["ObservacionSinRespuesta"], "required", "false");
          $("input[id='SinRespuesta']").prop("checked", false);
        }
      });
    }

    super.ngAfterViewInit();
  }

  public PostNgAfterViewInit() {
    super.PostNgAfterViewInit();
    let ControlsToFind = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls);
    this.IdCanalRecepcion = ControlsToFind
      .find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.IdCanalRecepcion;
      });
    this.CanalRecepcion = ControlsToFind
      .find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.CanalRecepcion;
      });
    this.Asunto = ControlsToFind
      .find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.Asunto;
      });
    // this.GrillaAnexosDetalle = this.GrillaAnexos = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name === SolicitudCommonComponent.Controls.GrillaAnexosDetalle; });
    this.GrillaAnexos = ControlsToFind
      .find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.GrillaAnexos;
      });
    this.UsuariosCopias = ControlsToFind
      .find((x) => {
        return x.Name == "UsuariosCopias";
      });
    this.ObservacionesAnulacion = ControlsToFind
      .find((x) => {
        return x.Name == "ObservacionesAnulacion";
      });
    this.ComboTema = ControlsToFind
      .find((x) => {
        return x.Name == "IdTemaSolicitud";
      });
    this.Respuesta = ControlsToFind
      .find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.Respuesta;
      });
    this.RequiereRespuesta = ControlsToFind
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.RequiereRespuesta;
      });
    this.BtnPqrdAsociada = ControlsToFind
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.BtnPqrdAsociada;
      });
      this.GridRadicadosAsociados = ControlsToFind
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.GridRadicadosAsociados;
      });

  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    switch (controlName) {
      case SolicitudCommonComponent.Controls.IdPais :{
        //debugger;
        if (this.modelo.modeloCascada.IdPais != 1 && this.modelo.modeloCascada.IdPais !=null){
          //debugger;
          this.modelo.modeloCascada.IdCiudad = null;
          this.modelo.modeloCascada.Departamento = null;
          this.Departamento = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdDepartamento" });
          this.Departamento.Hidden = true;
          //this.Departamento.Required = false;

          if (this.modelo.modeloCascada.IdPais) {
            let obj = this.utility.GetApiModel('ConsultaPaisCiudad', null)
            obj.QueryConfig = {
              Command: 'ConsultaPaisCiudad',
              IsSp: true,
              Entity: {
                IdPais: this.modelo.modeloCascada.IdPais
              }
            };
            let info = {
              componentName: this.componentName, controlName: 'ConsultaPaisCiudad',
              processMessage: 'Cargando Ciudad......'
            };

            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

              //debugger;
              this.modelo.modeloCascada.IdDepartamento = data.Data[0].IdDepartamento;
              super.EditCascading();
              //this.SetModelToControls();
              //super.EditCascading();
            }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });
          }
          else
          {
            this.modelo.modeloVirtual.Indicativo = null;
          }
        }else{
          this.Departamento.Hidden = false;
          //this.Departamento.Required = true;
        }
        //

        break;
      }
      case SolicitudCommonComponent.Controls.IdTipoSolicitud: {
         /**
        Inicio Edward Morales - 2023-10-18
        Ajuste Provisional Tutelas 
        */
        if(this.FechaRespuesta)
          this.modelo.modelo.IdTipoSolicitud == 22 ? this.FechaRespuesta.Hidden = false : this.FechaRespuesta.Hidden = true;
        if (!item) {   
          this.modelo.modelo.IdTemaSolicitud = null;
          this.configs.ConfigIdTemaSolicitud.Context.items = new Array();
          this.SetModelToControls();
        }
        break;
      }
      case SolicitudCommonComponent.Controls.IdCanalRecepcion: {

        if (item) {    
          this.SetControlsValueDocument(["Asunto"], "required", (item.IdDatoBasico == 26 || item.IdDatoBasico == 27) ? "false" : "true");
          this.SetModelToControls();
        }
        break;
      }
    }
    super.OnSelectedComboBox(item, controlName, config);
  }

  public OnDataTableEditHandler(event) {//
    //Lina Figueredo - Solucion bug 1163 y 1164 - 4/03/2024
    //Se agrega para iniciar las propiedades del control cuando se edita un registro de la tabla
    this.RequiereRespuesta.value = false;
    this.SinRespuesta = false;
    if (event.name == "GridViewRadicadosAsignados") {
      const findItemClone = this.utility.Clone(event.data.data.findItem);
      this.modelo.selectedItem = findItemClone;

      let obj = this.utility.GetApiModel("GetEstadoPrevioSolicitud", null);
            obj.QueryConfig = {
              Command: "ConsultaEstadoPrevioSolicitud",
              IsSp: true,
              Entity: {
                TipoSolicitud: "PQRS",
                IdSolicitud: this.modelo.selectedItem.IdSolicitudPqrd,
              },
            };

            let info = {
              componentName: this.componentName,
              controlName: "GetEstadoPrevioSolicitud",
              processMessage: "Cargando Estado previo de la Solicitud......",
            };

            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {

                  this.modelo.modelo.IdEstadoPrevio = data.Data[0].IdEstadoSolicitud;
                  this.SetModelToControls();

              })
              .catch((err) => {});
    }
    else{
      const crudButtonSave = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == "CrudButtonSave";
      });
    let estadocrudbutton = false;
    if (this.modelo.modelo.IdEstadoSolicitud == 29) estadocrudbutton = true;
    crudButtonSave.Hidden = estadocrudbutton;

    // this.cnfgButtons.Hidden = false
    this.UsuarioResponsable = this.modelo.modelo.IdUsuarioResponsable;
    if (event.modelo.modelo.Anexos) {
      this.modelo.modeloVirtual.GrillaAnexosDetalle = JSON.parse(
        event.modelo.modelo.Anexos
      );
      //Los elementos quedan desactivados (ineditables)
      this.modelo.modeloVirtual.GrillaAnexosDetalle.forEach((element) => {
        element.Disabled = true;
        element.DisabledDelete = true;
      });
    }

    /*this.configs.ConfigIdTipoSolicitud.Context.SetItemsComboBox([], true);*/
          this.configs.ConfigIdTipoSolicitud.QueryConfig.Filter = "IdTipoSolicitudPadre = 5 AND Activo = 1";
          this.configs.ConfigIdTipoSolicitud.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
    }

    super.OnDataTableEditHandler(event);
  }

  public Limpiar() {
//debugger
    if (this.calledCommandButton == "Incluir en Expediente") {
      this.modelo.modeloVirtual.NombreExpediente = "";
      this.modelo.modeloVirtual.CodigoExpediente = "";
      this.modelo.modelo.IdTipoDocumental = "";
      this.calledCommandButton = "";
      this.GridViewVincularExpediente = this.componentsContainer.Tables.filter(
        (x) => x.name == "GrillaAsignarExpediente"
      )[0];
      this.GridViewVincularExpediente.ReCargarGrilla();
      this.SetModelToControls();
      return;
    } else if (this.calledCommandButton == "SolicitaAnulacion") {
      this.modelo.modelo.ObservacionesAnulacion = null
      this.SetModelToControls()
    } else if (this.calledCommandButton == "Reasignar") {
      this.modelo.modeloVirtual.ReasignacionJerarquica = StaticData.GeneralConfig.ConfigUI.ReasignacionJerarquica;
      this.modelo.modelo.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
      this.GrillaReasignarUsuario = this.componentsContainer.Tables.filter(
        (x) => x.name == "GrillaReasignarUsuario"
      )[0];
      this.GrillaReasignarUsuario.ReCargarGrilla();
      this.SetModelToControls();
    } 
    else {
      //Miguel Patiño
      //08/12/2020
      //0003819: No borra registros de documentos en pendientes de oficio
      //INICIO
      //** se comenta estas lineas da error cuando viene del form SolicitudPqrdOficio - "this.configs" es undefined  */
      //this.configs.ConfigIdTemaSolicitud.Context.items = new Array();
      //this.SetModelToControls();
      if (this.config && this.configs.ConfigIdTemaSolicitud) {
        this.configs.ConfigIdTemaSolicitud.Context.items = new Array();
        this.SetModelToControls();
      }
      //FIN
      //this.ReCargarGrilla();
      super.Limpiar();
    }
  }

  public OnActionEventHandler(event) {
    //debugger;
    const crudButtonSave = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == "CrudButtonSave";
      });
    if (this.Pagina.Pagina == "SolicitudPqrd") {
      crudButtonSave.HideClear = false;
      this.SetModelToControls();
    }
    if (event.ControlType == GeneralPageControlTypes.CommandButton)
      this.textCommandBar = event.Option.Name;

    if (event.Action === FormActionsThroughEmitters.ClearModel) {
      if(this.Oficio)
      {
        this.Oficio.config.Required = true;
      }
      if (this.Asunto) {
        this.Asunto.Disabled = false;
        this.Asunto.Readonly = false;
      }
      //this.ClearModel.apply(this, propsInModeloModelo);

      //this.utility.SetFieldsToModel(this.modelo.modelo, null);

      //this.SetModelToControls();

      //Evitar borrar el modelo si la tabla es de reasignacion
      if (event.TableName && event.TableName === SolicitudCommonComponent.Controls.GrillaReasignarUsuario)
        return;

      if (event.Table == "GrillaAsignarExpediente" && event.Action == FormActionsThroughEmitters.ClearModel)
        return;

      if(event.Table == "GrillaUsuarioCopia"){

        var GridName = "GrillaUsuarioCopia";
        var GridViewUsuarioCopia = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
        GridViewUsuarioCopia.selectedItems = this.modelo.UsuariosCopia;

        this.SetModelToControls();
        return;
      }

      if(event.Table == "GridViewPQRSAsignadas"){
        this.calledCommandButton = null
      }


    } else if (event.actionType == "OnLoadedComboBoxExtendableTable") {
      this.ItemsAnexos = event.data.items;
    }

    switch (event.ControlType) {
      case GeneralPageControlTypes.Button: {
        switch (event.Name) {
          case SolicitudPqrdComponent.Controls.BtnContactos: {
            this.RequiereRespuesta.value = null;
            this.SetControlsValueDocument(["Respuesta"], "required", "false");
            this.modelo.modelo.Respuesta = null;
            this.SetModelToControls();
            this.Respuesta.Hidden = true;
            $("input[id='RequiereRespuesta']").prop("checked", false);

            if(!this.modalOpenContacto)
              this.VerBuscarContacto();
            else
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de contactos abierta." });
            break;
          }
          case SolicitudPqrdComponent.Controls.BtnPqrdAsociada: {
            if(!this.modalOpenPQRS){
              this.VerBuscarPqrd();
            }else{
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de Relacionar PQRS abierta." });
            }

            break;
          }
          case SolicitudCommonComponent.Controls.BtnVincular:{
            this.modelo.modelo.IdEstadoSolicitud = 12
            var OperacionSolicitud = this.modelo.modelo.DataAdd
            if (this.modelo.selectedItems.length > 0){
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdExpediente',this.modelo.modelo.IdExpediente);
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdTipoDocumental', this.modelo.modelo.IdTipoDocumental);
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdEstadoSolicitud', this.modelo.modelo.IdEstadoSolicitud);
              this.modelo.selectedItems.forEach((objeto) => {
                {
                  this.modelo.modelo = objeto
                  this.modelo.modelo.DataAdd = OperacionSolicitud
            
            let obj: any = null;
            let _isEdit = this.IsEdit;
            // const that = this;
            let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
            obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
            this.baseService.InsertUpdate(obj, null, {
              componentName: this.componentName + 'Controller',
              method: 'Guardar', processMessage: processMessage
            },
              false, this.componentName, true, true)

                }

              })
            this.ReCargarGrilla()
            
            break;
            }
          }
          case SolicitudPqrdComponent.Controls.BtnDetalleSolicitud: {

            let obj = this.utility.GetApiModel("GetDetalleSolicitud", null);
            obj.QueryConfig = {
              Command: "ConsultaDetallePQRSAsociada",
              IsSp: true,
              Entity: {
                IdEmpresa: -1,
                IdSolicitudPqrdAsociada: this.modelo.modelo
                  .IdSolicitudPqrdAsociada,
              },
            };

            let info = {
              componentName: this.componentName,
              controlName: "GetDetalleSolicitud",
              processMessage: "Cargando Detalle de la Solicitud......",
            };

            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {

                this.modeloDetalle = data.Data[0];
                if (
                  this.modeloDetalle.Anexos &&
                  this.modeloDetalle.Anexos != ""
                ) {
                  const Anexos = JSON.parse(this.modeloDetalle.Anexos);
                  let valorAnexos = "";
                  Anexos.forEach((anexo) => {
                    valorAnexos += anexo.Nombre + ": " + anexo.Cantidad + "\n";
                  });
                  this.modeloDetalle.Anexos = valorAnexos;
                }
                if (this.componentsContainer.FormsDetails.length > 0) {

                  let FormDetalleSolicitud = this.componentsContainer.Find(
                    "FormDetalleSolicitud"
                  );
                  FormDetalleSolicitud.modelo = this.modeloDetalle;
                }
              })
              .catch((err) => {});
            break;
          }
          case SolicitudCommonComponent.Controls.BtnGuardarComentario: {
            var lenComentario = this.modelo.modeloVirtual.Comentario;
            if (lenComentario != null && lenComentario.length > 0) {
              if (this.modelo.modelo.DataAdd) {
                this.modelo.modelo.DataAdd[
                  "TextoComentario"
                ] = this.modelo.modeloVirtual.Comentario;
                this.modelo.modelo.DataAdd["GuardaComentario"] = "true";
              } else {
                this.modelo.modelo.DataAdd = {
                  TextoComentario: this.modelo.modeloVirtual.Comentario,
                  GuardaComentario: "true",
                };
              }
              this.utility.VerModalConfirmacionGuardar().then((modelo) => {
                if (modelo.dialogResult == true) {
                  this.SetModelToControls();

                  this.modelo.modelo.Token = StaticData.Usuario.Token;
                  this.modelo.modelo.AppKey = StaticData.AppKey;
                  this.modelo.modelo.PkValue = this.modelo.modelo.IdSolicitudPqrd;

                  let obj = this.utility.GetApiModel(
                    "GuardarComentario",
                    this.componentName,
                    null,
                    null,
                    this.modelo.modelo
                  );

                  this.baseService
                    .GuardarComentario(
                      obj,
                      null,
                      {
                        componentName: "SolicitudPqrdComponent",
                        method: "GuardarComentario",
                        processMessage: "Procesando credenciales....",
                      },
                      "TSolicitudPqrd",
                      false,
                      true
                    )
                    .then((data) => {
                      this.modelo.modeloVirtual.Comentario = null;
                      this.SetModelToControls();

                      const tbGrillaComentario = this.componentsContainer.Tables.find(
                        (x) => {
                          return x.name == "DataTable_SolicitudPqrdComentario";
                        }
                      );
                      tbGrillaComentario.cargarGrilla = true;
                      tbGrillaComentario.ReCargarGrilla();

                      this.utility.VerModalOk(
                        "Se agregó comentario al radicado de pqrd: " +
                          "\n\r" +
                          this.modelo.modelo.Codigo
                      );
                    });
                }
              });
            } else {
              this.utility.VerModalDanger({
                titulo: "Valor Requerido",
                descripcion: "El campo comentario es requerido.",
              });
            }
          }
        }
        break;
      }
      case GeneralPageControlTypes.CommandButton: {
         /**
        Inicio Edward Morales - 2023-10-18
        Ajuste Provisional Tutelas
        */
        if(this.FechaRespuesta)
          this.FechaRespuesta.Hidden = true;
        this.SetControlsValueDocument(["ObservacionSinRespuesta"], "required", "false");
        if (
          this.modelo.modelo.IdSolicitudPqrdAsociada != null &&
          this.modelo.modelo.IdSolicitudPqrdAsociada != ""
        ) {
          if (this.BtnDetalleSolicitud) {
            this.BtnDetalleSolicitud.Hidden = false;
          }
          if (this.BtnAsociarSolicitud) {
            this.BtnAsociarSolicitud.Hidden = true;
          }
        } else {
          if (this.BtnDetalleSolicitud) {
            this.BtnDetalleSolicitud.Hidden = true;
          }
          if (this.BtnAsociarSolicitud) {
            this.BtnAsociarSolicitud.Hidden = false;
          }
        }

        if (!this.modelo.modelo.IdUsuarioResponsable)
          this.modelo.modelo.IdUsuarioResponsable = this.UsuarioResponsable;
        this.calledCommandButton = event.Option.Name;
        if(this.calledCommandButton === "GestionarConcepto"){
          this.SetControlsValueDocument(["Asunto"], "required", false);
          this.SetControlsValueDocument(["Observaciones", "ArchivoConcepto"], "required", "true");
        }
        /**
         * INICIO
         * 03/12/2020 17:47
         * Edward
         * Habilitar required de Copiar PQRS al dar click al commandbar
         */
        this.SetControlsValueDocument(["Codigo", "ExpedienteCodigo"], "enabled", false);
        this.SetControlsValueDocument(["IdUsuario"], "enabled", event.Option.Name === SolicitudCommonComponent.CommandBarActions.CopiarPQRS);
        /**
         * FIN
         */

        if (this.IdContacto) {
          this.SetControlsValueDocument([SolicitudPqrdComponent.Controls.IdContacto], "required", event.Option.Name === SolicitudCommonComponent.CommandBarActions.Nuevo ? "true" : "false");
        }

        if(this.ObservacionesAnulacion){
          this.SetControlsValueDocument(["ObservacionesAnulacion"], "required", event.Option.Name === SolicitudCommonComponent.CommandBarActions.SolicitarAnulacion ? "true" : "false");
        }

         //Desactivar agregar nuevos anexos
        if (this.BtnContactos && this.MensajeSeleccionarContacto) {
          if (
            event.Option.Name ===
            SolicitudCommonComponent.CommandBarActions.VerDetalle
          ) {
            this.SetControlsValueDocument(["Asunto"], "required", false);
            this.BtnContactos.Hidden = true;
            this.MensajeSeleccionarContacto.Hidden = true;
            setTimeout(function () {
              if (
                document.getElementsByClassName(
                  "float-right mb-4 ng-star-inserted"
                )[0] != undefined
              ) {
                (document.getElementsByClassName(
                  "float-right mb-4 ng-star-inserted"
                )[0] as HTMLElement).style.visibility = "visible";
              }
            }, 1500);
          } else {
            this.BtnContactos.Hidden = false;          
          }
        }

        if (this.IdTipoDocumental) {
          this.SetControlsValueDocument([SolicitudPqrdComponent.Controls.IdTipoDocumental], "required", event.Option.Name === SolicitudPqrdComponent.CommandBarActions.IncluirEnExpediente ||event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpedienteMasivo  ? "true" : "false");

        if(event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpedienteMasivo){
            this.BtnVincular.Hidden = false
            this.BtnGuardarVincular.HideSave = true
          }
        if(event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente){
            this.BtnVincular.Hidden = true
            this.BtnGuardarVincular.HideSave = false
          }
        }

        if (this.IdCanalRecepcion) {
          event.Option.Name ===
          SolicitudCommonComponent.CommandBarActions.VerDetalle
            ? this.RaiseFormEvent({
                Action: "DisableComboBoxesExtendableTable",
              })
            : this.RaiseFormEvent({
                Action: "EnableComboBoxesExtendableTable",
              });
          this.SetControlsValueDocument( [
            "IdCanalRecepcion",
            //"IdPais",
            //"IdDepartamento",
            //"IdCiudad",
            //"Direccion",
            //"Celular",
            //"Telefono",
            //"Ext",
            //"Correo",
            "ComentarioRadicado",
          ], "enabled", event.Option.Name ===
          SolicitudCommonComponent.CommandBarActions.VerDetalle
            ? "false"
            : "true");
          this.SetModelToControls();
        }

        if (this.UsuariosCopias) {
          this.SetControlsValueDocument(["UsuariosCopias"], "required", "false");
          this.SetModelToControls();
        }

        if (
          event.Option.Name ===
          SolicitudCommonComponent.CommandBarActions.Responder
        ) {
          if (
            this.modelo.modelo.IdExpediente == null ||
            this.modelo.modelo.IdExpediente == ""
          ) {
            this.utility.VerModalError(
              "La solicitud no posee un expediente asignado"
            );
            return;
          }
        } else if (
          event.Option.Name ===
          SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente
        ) {
            this.SetControlsValueDocument(["Asunto"], "required", "false");
          if (
            this.modelo.modelo.IdExpediente != null &&
            this.modelo.modelo.IdExpediente != ""
          ) {
            this.utility.VerModalError(
              "La solicitud ya tiene un expediente incluido"
            );

            return;
          }
        } else if (
          event.Option.Name ===
            SolicitudCommonComponent.CommandBarActions.SolicitarConcepto ||
          event.Option.Name ===
            SolicitudCommonComponent.CommandBarActions.Reasignar
        ) {
          this.modelo.modeloVirtual.ReasignacionJerarquica = StaticData.GeneralConfig.ConfigUI.ReasignacionJerarquica;
          this.modelo.modelo.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
          // this.IdUsuarioResponsable.Required = true;
          // this.SetModelToControls();
            //this.SetModelToControls();
          this.SetControlsValueDocument(["Asunto"], "required", "false");
          this.modelo.modelo.IdUsuarioResponsable = null;
        }

        else if (event.Option.Name == SolicitudCommonComponent.CommandBarActions.CopiarPQRS ||
          event.Option.Name ==
            SolicitudCommonComponent.CommandBarActions.SolicitarAnulacion){
          this.SetControlsValueDocument(["Asunto"], "required", "false");
        }
        else if (
          event.Option.Name ===
          SolicitudCommonComponent.CommandBarActions.VerDetalle
        ) {
          //debugger;
           /**
          Inicio Edward Morales - 2023-10-18
          Ajuste Provisional Tutelas
          */

          this.RequiereRespuesta.value = false;
          this.Respuesta.Hidden = true;
          this.SetControlsValueDocument(["Respuesta"], "required", "false");

          if(this.FechaRespuesta)
            this.FechaRespuesta.Hidden = this.modelo.modelo.IdTipoSolicitud == 22 ? false : true;
          this.CtrlSinRespuesta.value = false;
          this.CtrlSinRespuesta.Hidden = false;    
          this.SetControlsValueDocument(["ObservacionSinRespuesta"], "required", "false");      
          this.ObservacionSinRespuesta.Hidden = !this.modelo.modelo.SinRespuesta;
          this.modelo.modelo.ObservacionSinRespuesta = (this.modelo.modelo.ObservacionSinRespuesta == " " ? "" : this.modelo.modelo.ObservacionSinRespuesta);    // " ";          
          //Lina Figueredo - Solucion bug 1163 y 1164 - 4/03/2024
          // Se comenta ya que einhabilita el chek de no requiere respuesta, la logoca esta mal implementada.
          // if(this.modelo.modelo.SinRespuesta == true){
          //   $("#SinRespuesta").attr("disabled","true");
          // }else{ 
          //   $("#SinRespuesta").removeAttr("disabled");
          //   this.CtrlSinRespuesta.Value = 0;
          // }
          // this.CtrlSinRespuesta.Disabled = this.modelo.modelo.SinRespuesta == true ? true : false;
          // this.CtrlSinRespuesta.Readonly = this.modelo.modelo.SinRespuesta == true ? true : false;

          this.ObservacionSinRespuesta.Disabled = this.modelo.modelo.SinRespuesta == true ? true : false;
          this.ObservacionSinRespuesta.Readonly = this.modelo.modelo.SinRespuesta == true ? true : false;

          if(this.BtnPqrdAsociada){
            if(this.modelo.modelo.EsAsociada == true){
              this.BtnPqrdAsociada.Hidden = true;
              setTimeout(() => {
              if(this.GridRadicadosAsociados){
              this.GridRadicadosAsociados.Hidden = true;
              if(this.modelo.modeloVirtual.GridRadicadosAsociados){
                this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
                }
              }
            }, 1000);
            }else{
              this.BtnPqrdAsociada.Hidden = false;
              setTimeout(() => {
              if(this.GridRadicadosAsociados)
              this.GridRadicadosAsociados.Hidden = false;
            }, 1000);
            }
          }
          this.InfoAnexos.Hidden = true;
          this.modelo.modeloVirtual.AnexosRequeridos = "ok";
          this.modelo.modeloVirtual.ComentarioRadicado = this.modelo.selectedItem.Comentario;
          this.SetControlsValueDocument(["NumeroFolios"], "required", "false");
          crudButtonSave.HideClear = true;
        }

        if (
          event.Option.Name === SolicitudCommonComponent.CommandBarActions.Nuevo
        ) {
          //Lina Figueredo - Solucion bug 1163 y 1164 - 4/03/2024
          //Se agregan propiedades de los controles cuando se da click en el boton nuevo
          this.RequiereRespuesta.value = false;
          this.Respuesta.Hidden = true;
          this.SetControlsValueDocument(["Respuesta"], "required", "false");

          this.SetControlsValueDocument(["IdTipoRadicacion"], "enabled", "true");
          this.MensajeSeleccionarContacto.Hidden = false;

          this.CtrlSinRespuesta.Hidden = true;
          this.ObservacionSinRespuesta.Hidden = true;
          this.SetControlsValueDocument(["ObservacionSinRespuesta"], "required", "false");

          if(this.BtnPqrdAsociada){
            this.BtnPqrdAsociada.Hidden = false;
            setTimeout(() => {
              if(this.GridRadicadosAsociados)
              this.GridRadicadosAsociados.Hidden = false;
              this.modelo.modeloVirtual["GridRadicadosAsociados"] = new Array();
            }, 1000);
            }
          //Activa BOTON DE TIPO ANEXOS (Adiccionar)
          setTimeout(function () {
            if (
              document.getElementsByClassName(
                "float-right mb-4 ng-star-inserted"
              )[0]
            ) {
              (document.getElementsByClassName(
                "float-right mb-4 ng-star-inserted"
              )[0] as HTMLElement).style.visibility = "visible";
            }
          }, 1500);
          const crudButtonSave = this.utility
            .GetAllFormControls(this.Pagina.ConfigPage.Controls)
            .find((x) => {
              return x.Name == "CrudButtonSave";
            });
          crudButtonSave.Hidden = false;
          this.InfoAnexos.Hidden = true;
          this.modelo.modeloVirtual.AnexosRequeridos = "ok";
          this.SetControlsValueDocument(["NumeroFolios"], "required", "false");
          //this.configs.ConfigIdTipoSolicitud.Context.SetItemsComboBox([], true);
          //this.configs.ConfigIdTipoSolicitud.Params = { IdTipoSolicitudPadre: 5, AplicaCreacion: 1, Activo: 1 };
          this.configs.ConfigIdTipoSolicitud.QueryConfig.Filter = "IdTipoSolicitudPadre = 5 AND AplicaCreacion = 1 AND Activo = 1";
          this.configs.ConfigIdTipoSolicitud.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
        }

        switch(event.Option.Name)
        {
          case SolicitudCommonComponent.CommandBarActions.CopiarPQRS:
          {
            var GridName = "GrillaUsuarioCopia";
            var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
            this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

            break;
          }
          case SolicitudCommonComponent.CommandBarActions.Reasignar:
          {
            var GridName = "GrillaReasignarUsuario";
            var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
            this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

            break;
          }
          case SolicitudCommonComponent.CommandBarActions.SolicitarConcepto:
          {
            var GridName = "GrillaUsuarioConcepto";
            var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
            this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

            break;
          }
          case SolicitudCommonComponent.CommandBarActions.VerDetalle:
          {
            if(this.modelo.modelo.RequiereRespuesta === 1){
              this.Respuesta.Hidden = false;
            }
          }
        }
        this.SetModelToControls();
        break;
      }
      
    }
    if (
      event.actionType &&
      event.actionType == "OnLoadedComboBoxExtendableTable"
    ) {
      this.SetControlsValueDocument(["AnexosRequeridos"], "required", "true");
      this.modelo.modeloVirtual.AnexosRequeridos = null;
      this.InfoAnexos.Hidden = false;
      this.SetModelToControls();
      this.errorTipoAnexo = false;
      this.countTipoAnexos = event.data.items.length;
      if (this.modelo.modeloVirtual.GrillaAnexosDetalle) {
        var numeroItems =
          this.modelo.modeloVirtual.GrillaAnexosDetalle.length - 1;
        if (event.data.items.length != numeroItems) {
          if (this.modelo.modeloVirtual.GrillaAnexosDetalle.length > 1) {
            for (var prop in this.modelo.modeloVirtual.GrillaAnexosDetalle) {
              if (Number(prop) < numeroItems) {
                this.errorTipoAnexo = false;
                if (
                  this.modelo.modeloVirtual.GrillaAnexosDetalle[prop].Nombre ==
                  null
                ) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexosDetalle.splice(
                    this.modelo.modeloVirtual.GrillaAnexosDetalle.length - 1
                  );
                  this.utility.VerModalDanger({
                    titulo: "Valor Requerido",
                    descripcion: "El campo Tipo anexo es requerido.",
                  });
                  break;
                }
                if (
                  this.modelo.modeloVirtual.GrillaAnexosDetalle[prop]
                    .Cantidad == null ||
                  !this.modelo.modeloVirtual.GrillaAnexosDetalle[prop].Cantidad
                ) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexosDetalle.splice(
                    this.modelo.modeloVirtual.GrillaAnexosDetalle.length - 1
                  );
                  this.utility.VerModalDanger({
                    titulo: "Valor Requerido",
                    descripcion:
                      "El campo Cantidad tiene que ser requerido o mayor a cero",
                  });
                  break;
                }
              }
            }
          }
          //
          // if (this.errorTipoAnexo) {

          //     this.modelo.modeloVirtual.GrillaAnexosDetalle.forEach(datamodelo => {
          //
          //         event.data.items.forEach(element => {
          //             if (datamodelo.Nombre == element.Nombre) {
          //
          //                 const index = event.data.items.findIndex(x => { return x.Nombre == element.Nombre });
          //                 event.data.items.splice(index, 1);
          //             }
          //         });
          //     });
          // }
          if (this.countTipoAnexos ==this.modelo.modeloVirtual.GrillaAnexosDetalle.length) {
            //setTimeout(function () {
              if (document.getElementsByClassName("float-right mb-4 ng-star-inserted")[0] != undefined) {
                (document.getElementsByClassName("float-right mb-4 ng-star-inserted")[0] as HTMLElement).style.visibility = "hidden";
              }
            //}, 1500);
          }
        }

        this.modelo.modeloVirtual.GrillaAnexosDetalle.forEach((datamodelo) => {

          event.data.items.forEach((element) => {
            if (datamodelo.Nombre == element.Nombre) {

              if (!this.errorTipoAnexo) datamodelo.Disabled = true;
              const index = event.data.items.findIndex((x) => {
                return x.Nombre == element.Nombre;
              });
              event.data.items.splice(index, 1);
            }
          });
        });
      }
    } else if (event.actionType == "EliminarItem") {
      if (event.config.Label == "Tipos Anexo") {
        setTimeout(function () {
          if (
            document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0] != undefined
          ) {
            (document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0] as HTMLElement).style.visibility = "visible";
          }
        }, 1500);
      }
      var requeridoAnexosEliminacion = true;
      var requeridoAnexosControl = 1;
      this.modelo.modeloVirtual.GrillaAnexosDetalle.forEach((element) => {
        if (!element.Nombre || !element.Cantidad) {
          requeridoAnexosEliminacion = false;
          requeridoAnexosControl = null;
        }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    } else if (
      event.actionType &&
      event.actionType == "OnSelectedComboBoxExtendableTable"
    ) {

      // this.modelo.modeloVirtual.GrillaAnexosDetalle.forEach(element => {
      //     if (event.data.item && event.data.item.Nombre)
      //         if (element.Nombre == event.data.item.Nombre)
      //             if (element.Cantidad || element.Cantidad > 0) {
      //                 this.InfoAnexos.Hidden = true;
      //                 this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      //             }
      //             else {
      //                 this.InfoAnexos.Hidden = false;
      //                 this.modelo.modeloVirtual.AnexosRequeridos = null;
      //             }
      //         else {
      //             this.InfoAnexos.Hidden = false;
      //             this.modelo.modeloVirtual.AnexosRequeridos = null;
      //         }
      //     else {
      //         this.InfoAnexos.Hidden = false;
      //         this.modelo.modeloVirtual.AnexosRequeridos = null;
      //     }
      //     this.SetModelToControls();
      // });


      var requeridoAnexosEliminacion = true;
      var requeridoAnexosControl = 1;
      this.modelo.modeloVirtual.GrillaAnexosDetalle.forEach((element) => {
        if (event.data.item && event.data.item.Nombre) {
          if (element.Nombre == event.data.item.Nombre) {
            if (!element.Cantidad || element.Cantidad < 0) {
              requeridoAnexosEliminacion = false;
              requeridoAnexosControl = null;
            }
          }
        } else {
          requeridoAnexosEliminacion = false;
          requeridoAnexosControl = null;
        }
        // else {
        //   requeridoAnexosEliminacion = false
        //   requeridoAnexosControl = null
        // }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    } else if (
      event.actionType &&
      event.actionType == "OnChangeNumberBoxExtendableTable"
    ) {

      if (
        event.data.valueMetodo &&
        event.data.valueMetodo.Nombre &&
        event.data.valueMetodo.Cantidad &&
        event.data.valueMetodo.Cantidad > 0
      ) {
        this.InfoAnexos.Hidden = true;
        this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      } else {
        this.InfoAnexos.Hidden = false;
        this.modelo.modeloVirtual.AnexosRequeridos = null;
      }

      this.SetModelToControls();
    }
    super.OnActionEventHandler(event);
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
  }

  GetConfigPermisos() {
    const permissionsConfig: any = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Expediente,
      Pagina: this.Pagina,
      clear: true,
      CreatedRowConfig: {},
    };
    return permissionsConfig;
  }

  VerBuscarPqrd() {
    this.modalOpenPQRS = true;
    if(!this.modelo.modeloVirtual["GridRadicadosAsociados"]){
      this.modelo.modeloVirtual["GridRadicadosAsociados"] = new Array();
      //this.modelo.modeloVirtual["GridRadicadosAsociados"].length = 0;
    }
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[1],
      limpiarInternamente: true,
      modelo: this.utility.Clone(this.modelo),
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Relacionar PQRS";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility
      .OpenWindow(componentName, config, modeloTemp)
      .then((dataResult) => {
        dataResult.componentInstance.parentContext = this;

        dataResult.componentInstance.postSeleccionItemEmitter.subscribe((result) => {
          //debugger;
          if (result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("Codigo") &&
            !this.modelo.modeloVirtual["GridRadicadosAsociados"].find(x => { return x.Codigo == result.data.data.findItem.Codigo; }))
          {
            this.modelo.modeloVirtual["GridRadicadosAsociados"].push({ Id: result.data.data.findItem.IdSolicitud, Codigo: result.data.data.findItem.Codigo });
          }

          /*const CodigoRadicadoAsociado =
            result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("Codigo")
              ? result.data.data.findItem.Codigo
              : null;

          const IdSolicitud =
            result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("IdSolicitud")
              ? result.data.data.findItem.IdSolicitud
              : null;

          this.modelo.modeloVirtual.CodigoPqrdAsociada =
            CodigoRadicadoAsociado ||
            dataResult.componentInstance.modelo.Codigo;
          this.modelo.modelo.IdSolicitudPqrdAsociada =
            IdSolicitud || dataResult.componentInstance.modelo.IdSolicitud;

          this.BtnDetalleSolicitud.Hidden = false;*/
          this.SetModelToControls();
        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(          
          (result) => {
            if (result.Action === FormActionsThroughEmitters.ClearModel) {
              this.modelo.modeloVirtual.CodigoPqrdAsociada = null;
              this.modelo.modelo.IdSolicitudPqrdAsociada = null;
              this.SetModelToControls();
            }
          }
        );

        dataResult.onClosePromise.then((dataResultOnClose) => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
          this.modalOpenPQRS = false;
        });
      });
  }

  ConvertAnexos() {
    var chkRequiereRespuesta = $("#RequiereRespuesta").is(":checked");
    this.modelo.modelo.RequiereRespuesta = chkRequiereRespuesta;

    if (
      this.modelo.modeloVirtual.GrillaAnexosDetalle &&
      this.modelo.modeloVirtual.GrillaAnexosDetalle.length > 0
    ) {
      this.modelo.modelo.Anexos = JSON.stringify(
        this.modelo.modeloVirtual.GrillaAnexosDetalle
      );
    }
  }

  public GuardarAuditoriaSinRespuesta() {
    //debugger;
    var description = this.modelo.modelo.ObservacionSinRespuesta;
    var spName = "CrearEstadoSinRespuestaPqrd";

    this.componentName = "TSolicitudPqrd"
    let obj = this.utility.GetApiModel(spName, null)
    obj.QueryConfig.Command = spName;
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdSolicitudPqrd: this.modelo.modelo.IdSolicitudPqrd,      
      Observaciones: description,      
      IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
      MaquinaCreacion: -1
      
    };
    let info = {
      componentName: this.componentName, controlName: spName,
      processMessage: 'Registro Auditoría No Requiere Respuestas...'
    };
    this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
    }).catch(err => { }).finally();

  }

  _Guardar(form: FormGroup, configButtons?: any) {
    //debugger;
    if(this.modelo.modelo.GuidPlantillaPqrsInternal){
      //if (this.modelo.modelo.DataAdd) {
       // this.modelo.modelo.DataAdd[
       //   "GuidPlantillaPqrsInternal"
       // ] = this.modelo.modelo.GuidPlantillaPqrsInternal;
        this.modelo.modelo.DataAdd = {
          GuidPlantillaPqrsInternal: this.modelo.modelo.GuidPlantillaPqrsInternal,
          CorreoContacto: this.modelo.modelo.Correo,
        };
      //}
    }
    if(this.modelo.modelo.IdEstadoPrevio)
    {
      this.modelo.modelo.Observaciones = null;
      if (this.modelo.modelo.DataAdd) {
        this.modelo.modelo.DataAdd[
          "IdEstadoPrevio"
        ] = this.modelo.modelo.IdEstadoPrevio;
      } else {
        this.modelo.modelo.DataAdd = {
          IdEstadoPrevio: this.modelo.modelo.IdEstadoPrevio
        };
      }
    }

    //this.cnfgButtons = configButtons;
    if(this.ObservacionesAnulacion)
    {
      if (this.modelo.modelo.ObservacionesAnulacion != "" && this.modelo.modelo.ObservacionesAnulacion != null) {
        this.modelo.modelo.Observaciones = this.modelo.modelo.ObservacionesAnulacion;
      }
    }
    //debugger;
    if(this.IsEdit){
      {
        if(this.modelo.modelo.ObservacionSinRespuesta){
          this.modelo.modelo.SinRespuesta = this.modelo.modelo.ObservacionSinRespuesta != "" && this.modelo.modelo.ObservacionSinRespuesta != null ? true : false;
          if(this.modelo.modelo.SinRespuesta == true){
            this.GuardarAuditoriaSinRespuesta();
          }
        }
        if(this.modelo.modeloVirtual.GridRadicadosAsociados)
        if(this.modelo.modeloVirtual.GridRadicadosAsociados.length>0){
        this.modelo.modelo.AsociacionMultiple = JSON.stringify(
          this.modelo.modeloVirtual.GridRadicadosAsociados
        );
      }else{
        this.modelo.modelo.AsociacionMultiple = '';
      }
      }
    }
    //debugger;
    this.HasFiles();
    this.ConvertAnexos();
    this.GetUsersCopy();
    super._Guardar(form, configButtons);
  }
}
