export class ConfigWindow {
    titleWindow:string='';
    viewComponentHeader:boolean=false;
    viewComponentTitle:boolean=false;
    width:number=700;
    height:number=600;
    center:boolean=true;
    modal:boolean=false;
    viewCancel:boolean=true;
    viewExtra:boolean=false;
    extra:string="";
    extraValue:any="";
    viewOkExtra:boolean=false;
    Okextra:string="";
    OkextraValue:any="OkExtra";
    cancel:string="Cancelar";
    classCancel:string='cancel';
    viewOk:boolean=true;
    ok:string="Ok";
    classOk:string='ok'
    classExtra:string='btn btn-outline-success'
    bodyHtml:any='';
    classWindow:string='info';
    returnInstance:boolean=true;
    modelo:any=null;
    data:any=null;
    draggable:boolean=true;
    closeable:boolean=true;
    resizable:boolean=true;
    NotApplyDestroyAllSubscriptions: Boolean;
  }
  