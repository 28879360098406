import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { StaticData } from 'src/app/helpers/static-data';
import { List } from '../../../../assets/linqts/compilado/index';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { DataTableColumnTypes } from 'src/app/models/general.enum';
import { ComponentsContainer } from 'src/app/models/components-container';

//* FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
    selector: 'app-data-table-mosaic',
    templateUrl: './data-table-mosaic.component.html',
    styleUrls: ['./data-table-mosaic.component.scss']
})

export class DataTableMosaicComponent implements OnInit, AfterViewInit {

    @Input() pkName: string;
    @Input() columns: Array<any> = [];
    @Input() startIndexColumn: number = 0;
    @Input() configPermisos: any;
    @Input() config: any;
    @Input() index: number;
    @Input() prop: string;
    @Input() titleProp: string;
    @Input() esDocumentos: boolean;
    @Input() esExpediente: boolean;
    @Output() onClickEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onPagedEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onOrderEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onActionEmitter: EventEmitter<any> = new EventEmitter<any>();

    view: boolean = false;
    imagenExpediente: string;
    estilos: any = StaticData.Estilos;
    items: Array<any> = [];
    filteredItems: Array<any> = [];
    searchText: string;
    pageSize: number = 5;
    imagenNoPermitido: string;
    columnsOrder: List<any> = new List([]);

    selectionColumn: any;
    principalColumn: any;
    searchInputName: string;

    iconoNoPermitido: string;
    configDocViewer: any = null;
    // configDocViewer = { Style: { width: "400px", height: "300px" }, 
    // WithButton: true, LabelViewButton: "Ver Documento", Controller: "TDocumentoExpediente", ViewerType:"url" };
    viewDocViewer: boolean = false;
    @Input() componentsContainer: ComponentsContainer;
    @Input() Pagina: any;

    labelVistaDoc = "Ver Vista Documentos"

    constructor(public utility: Utilities, public helperImages: HelperImages) {

    }

    //* FUNCIONES ANGULAR

    ngOnInit() {
        

        if (this.Pagina && this.Pagina && this.Pagina.ConfigPage &&
            this.Pagina.ConfigPage.HiddenControls && this.config && this.config.DocumentViewerConfig) {

            this.configDocViewer = this.Pagina.ConfigPage.HiddenControls.find(x => { return x.Name == this.config.DocumentViewerConfig.Name });
        }
        this.searchInputName = "msSearchBoxMosaic_" + this.config.Name;

        this.principalColumn = this.config.Controls.find((col: any) => {
            return col.ColumnType == DataTableColumnTypes.Options ||
                col.ColumnType == "Options" ||
                col.ColumnType == 3
        });

        this.prop = (this.config.FieldMosaicLabel) ? this.config.FieldMosaicLabel : this.principalColumn.DataField;
        // let columnType: DataTableColumnTypes = Number.isInteger(column.ColumnType) ? parseInt(column.ColumnType) : DataTableColumnTypes["" + column.ColumnType];  //parseInt(column.ColumnType);
        this.selectionColumn = this.columns.find((col: any) => {
            return col.Type == DataTableColumnTypes.Selection ||
                col.Type == "Selection" ||
                col.Type == 6
        });
        this.pkName = this.config.PkName;

    }

    ngAfterViewInit() {

        setTimeout(() => {
            var msSearchBox = document.querySelector('#msSearchBoxMosaic_' + this.config.Name);
            if (msSearchBox)
                new fabric['SearchBox'](msSearchBox);

            //msSearchBox_
            // var DropdownHTMLElement = document.querySelector('#' + this.name + "_length");

            //   var Dropdown = new fabric['Dropdown'](DropdownHTMLElement);
        }, 1000);

    }

    //* END FUNCIONES ANGULAR

    //* FUNCIONES PERSONALIZADAS

    posMenu(e) {

        console.log(e.target);
        console.log((<any>$(e.target)).offset().left);
        console.log($(document).width());
        if ((<any>$(e.target)).offset().left > $(window).width() / 2) {
            (<any>$(e.target)).find(".dt__mosaic--menu").css("left", "-175%");
        } else {
            (<any>$(e.target)).find(".dt__mosaic--menu").css("left", "105%");
        }

    }

    public OnOrder(column: any, index: number) {

        var currentIndex = index + this.startIndexColumn;
        var order = [currentIndex, 'desc'];
        var col = this.columnsOrder.FirstOrDefault(x => { return x.index == currentIndex });
        if (col) {
            var orderDirection = (col.orderDirection == 'desc') ? 'asc' : 'desc';
            order = [currentIndex, orderDirection];
            col.orderDirection = orderDirection;
        }
        else
            this.columnsOrder.Add({ index: currentIndex, orderDirection: 'desc' });

        this.onOrderEmitter.emit({ pageSize: this.pageSize, searchText: this.searchText, order: order });

    }

    public OnOptionsSelected(event: any) {

        this.onPagedEmitter.emit({ pageSize: this.pageSize, searchText: this.searchText });

    }

    public OnKey(event: any) { // without type info

        //this.values += event.target.value + ' | ';
        
        if (event.keyCode == 13 || event.target.value == '' || event.target.value === undefined) {

            this.onSearchEmitter.emit({ pageSize: this.pageSize, searchText: this.searchText });
        }

    }

    public Click(item: any, index: number) {

        if (item.Ok) {
            this.onClickEmitter.emit({ item: item, index: index, column: this.selectionColumn });
        }

    }
    public SetViewDocViewer() {
        this.viewDocViewer = !this.viewDocViewer;

        this.labelVistaDoc = (this.viewDocViewer) ? "Ver Vista Mosaicos" : "Ver Vista Documentos";
        $.each(this.items, (index, item) => {

            if (item.Ok)
                item.ViewDocViewer = this.viewDocViewer;
        });
    }
    public GetFilteredColumns(): Array<any> {

        const that = this;
        /*  if(this.searchText){
             var lsearchText = this.searchText.toLowerCase();
             return this.items.filter((a) =>
               a[that.prop].toLowerCase().includes(lsearchText)
             );
         }
         else */

        return (this.columns) ? this.columns.filter((x) => x.isCustom !== true) : [];

    }

    public GetFilteredItems(): Array<any> {

        const that = this;
        /*  if(this.searchText){
             var lsearchText = this.searchText.toLowerCase();
             return this.items.filter((a) =>
               a[that.prop].toLowerCase().includes(lsearchText)
             );
         }
         else */
        return this.items;

    }

    GetOjectColumn(row, column: any) {

        let result: any = {
            Icon: column.Icon, Image: column.Image,
            ToolTip: column.ToolTip,
            Text: '', Carpeta: null, ExtraText: '',
            Id: row[this.config.PkName]
        };

        if (this.config.FieldMosaicLabel)
            result.Text = row[this.config.FieldMosaicLabel];
        if (this.config.ExtraFieldMosaicLabel)
            result.ExtraText = row[this.config.ExtraFieldMosaicLabel];
        if (this.config.ExtraFieldMosaicLabel1)
            result.ExtraText += ' [' + row[this.config.ExtraFieldMosaicLabel1] + ']';
        if (!column)
            return result;

        if (!column.DataField && !column.DataIconField && !column.Image && !column.Icon && !column.ToolTipField) {
            return result;
        }
        if (column.ToolTipField)
            result.ToolTip = row[column.ToolTipField];
        if (column.DataField)
            result.Text = row[column.DataField];

        if (!result.ToolTip)
            result.ToolTip = '';
        if (!result.Text)
            result.Text = '';

        if (column.DataIconField && column.SearchField && column.IconField) {
            let _icono = this.utility.GetNodeTypesArr().find(x => { return x[column.SearchField] == row[column.DataIconField] });
            if (_icono) {
                result.Icono = _icono[column.IconField];
                if (result.Icono.indexOf("/") !== -1 || result.Icono.indexOf(".") !== -1) {
                    result.Image = this.utility.GetPathImages(result.Icono);
                    result.Icono = "";
                }
                else {
                    result.Icono = this.utility.GetPathImages(result.Icono);
                }
            }
        }
        else if (column.DataIconField) {
            result.Icono = row[column.DataIconField];
            if (result.Icono.indexOf("/") !== -1 || result.Icono.indexOf(".") !== -1) {
                result.Image = result.Icono;
                result.Icono = "";
            }
        }

        return result;

    }

    public SetItems(_items: Array<any>) {

        const that = this;

        console.log("that: ", that);
        this.items = this.utility.Clone(_items);
        let count = 1;
        $.each(this.items, (index, item) => {
            item.ClassName = '';
            // item.Ok = count % 2 === 0;

            let textoLog = `MosaicoEditar`;
            if (this.pkName && item)
                textoLog += `,Id:${item[this.pkName]}`;
            if (item && item.Nombre)
                textoLog += `,Nombre:${item.Nombre}`;

            item.Ok = that.utility.TienePemisoUsuarioDos(that.configPermisos, item, 'Editar', false, textoLog);
            item.Title = '';
            item.Info = this.GetOjectColumn(item, this.principalColumn);
            item.IconoPermiso = (item.Ok) ? '' : 'ms-Icon ms-Icon--Blocked';

            // item.Info.Carpeta = item.Carpeta;
            if (this.config.MosaicsUseType == 'Documentos') {
                // if (item.Carpeta) {
                item.Info.Carpeta = item.Carpeta;
                //}
                /*     if (item.ConfiguracionAdicional) {
    
                        let atthachemn =
                    } */
                // item.ImagenCarpeta =this.helperImages.ImagenSql './../../../../assets/styles/images/icons/folder.svg';
                // item.IconoCarpeta = 'ms-Icon ms-Icon--FabricUserFolder';
                //  item.Icon = (item.Ok) ? that.utility.GetPathImages(that.utility.GetNodeType(item.Ext item.Info.ExtraText = item.FechaCreacion + ' [' + item.IdDocumentoExpediente + ']';ension)) : that.imagenNoPermitido;
            }
            else if (this.config.MosaicsUseType == 'Expedientes') { }
            else {
                // item.Title = (item.Ok) ? 'Seleccionar ' + item[that.prop] + '' : 'No Posee Permisos para seleccionar ' + item[that.prop] + '';
                //item.Icon = (item.Ok) ? that.imagenExpediente : that.imagenNoPermitido;
            }
            count++;
        });

    }

    //* END FUNCIONESPERSONALIZADAS

}
