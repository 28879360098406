//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods, TiposConsecutivo } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { ConfigConsecutivoComponent } from '../../../config-consecutivo/config-consecutivo/config-consecutivo.component';


@Component({
	selector: 'app-rconsecutivo',
	templateUrl: './rconsecutivo.component.html',
	styleUrls: ['./rconsecutivo.component.scss']
})
export class RConsecutivoComponent extends BaseCrud implements OnInit {

	@Input() verPrueba: boolean = false;
	@Input() verDocumento: boolean = true;
	@Input() verExpediente: boolean = true;
	@Input() labelConfigDocumento: string = "Config Documento";
	@Input() labelConfigExpediente: string = "Config Expediente";
	@ViewChild(ConfigConsecutivoComponent, { static: false })
	configConsecutivoComponent: ConfigConsecutivoComponent;
	verGenerarCodigo: boolean = false;
	verControl: boolean = true;
	modeloConsecutivo: any;
	tiposConsecutivo: Array<any> = [];
	constructor(public baseService: BaseServiceService, public utility: Utilities) {

		super(baseService, utility, Paginas.Consecutivo, 'RConsecutivo', { RTipoConsecutivo: 'Nombre AS TipoConsecutivo' }, false);
		this.pkName = 'IdConsecutivo';

	}

	public onSpecificStart() {
		this.conFiltroIdEmpresa = true;
		this.itemsSelectControls['ItemsIdTipoConsecutivo'] = [];
		// this.configs = {};
		// this.configs.ConfigIdTipoConsecutivo = { 'SetCargarItems': true, 'Controller': 'RTipoConsecutivo' }; //es aquí
		// this.configs.ConfigSearchIdTipoConsecutivo = { 'PagedServer': true, 'SearchParam': 'Nombre', 'ViewFieldModel': 'Nombre', 'SetLike': true, 'Include': {}, 'Params': {}, 'Controller': 'RTipoConsecutivo', 'Filter': 'Activo=1', 'MappingFields': { 'IdTipoConsecutivo': 'IdTipoConsecutivo' }, 'Columns': ['IdTipoConsecutivo', 'Nombre'], 'Props': ['IdTipoConsecutivo', 'Nombre'] };

		this.columns = [{ data: this.pkName, title: 'Id' },
		{ data: 'TipoConsecutivo', title: 'Tipo Consecutivo' },
		{ data: 'Nombre', title: 'Nombre' },
		{ data: 'IdConsecutivoActual', title: 'Consecutivo Actual' },
		{ data: 'Anio', title: 'Año' },
		];

	}
	public OnInitEnd() {

		this.preSaveEmitter.subscribe(event => {

			event.modelo.Disponible = 1;
			this.GetModeloJson();

		});

		this.postEditEmitter.subscribe(event => {

			//this.SetModeloJson();
			//this.verPrueba = true;


			
			setTimeout(() => {

				this.SetModeloJson();
				this.EvaluarVerControl();

			}, 500);
		});

		
		this.configs.ConfigIdTipoConsecutivo.onLoadedEmitter = new EventEmitter<any>();
		this.configs.ConfigIdTipoConsecutivo.onLoadedEmitter.subscribe(event => {
			
			this.tiposConsecutivo = event.items;

		});

		this.modelo.Nombre = '';		

	}
	public Limpiar(callback: (data: any) => void = null) {
		this.verPrueba = false;
		//this.verControl = false;
		this.modelo = {};
		this.modelo.IdConsecutivo = 0;
		this.modelo.PkValue = 0;
		this.modelo.IdEmpresa = -1;
		this.modelo.IdTipoConsecutivo = null;
		this.modelo.IdConsecutivoActual = '';
		this.modelo.ConfiguracionActual = '';
		this.modelo.Nombre = '';
		this.modelo.Anio = '';
		this.modelo.Configuracion = this.GetConfiguracion();
		this.modelo.Descripcion = null;
		this.modelo.Activo = true;
		this.utility.SetFieldsToModel(this.modelo, this.filterParent);
		if (callback) callback(this.modelo);



		this.verControl = false;

		setTimeout(() => {

			this.modeloConsecutivo = this.GetJsonConfiguracion();
			//this.verControl = true;
			this.EvaluarVerControl();
		}, 500);
		//this.SetModeloJson();
	}


	public EvaluarVerControl() {

		
		this.verControl = (this.modelo.IdTipoConsecutivo != null) ? true : false;
		const item = this.tiposConsecutivo.find(x => { return x.IdTipoConsecutivo == this.modelo.IdTipoConsecutivo });
		if (this.modelo.IdTipoConsecutivo == TiposConsecutivo.Expediente) {
			this.verDocumento = false;
			this.verExpediente = true;
			this.labelConfigExpediente = item.Nombre;
		}
		else {
			this.verDocumento = true;
			this.verExpediente = false;
			this.labelConfigDocumento = item.Nombre;
		}

	}
	public OnSelectedComboBox(item: any, controlName: string, config: any) {
		try {
			this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, { item: item, controlName: controlName, config: config });


			//this.comboBoxManager.ExecuteCascading(item, controlName, config);
			if (controlName == 'IdTipoConsecutivo') {
				this.verGenerarCodigo = (item.IdTipoConsecutivo == 4);
			}
			this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, { item: item, controlName: controlName, config: config });
			this.EvaluarVerControl();

		}
		catch (error) {
			this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
			this.utility.VerModalDanger().then(data => { });
		}
	}

	public SetModeloJson() {
		if (this.configConsecutivoComponent) {
			//this.configConsecutivoComponent.verGe
			this.configConsecutivoComponent.SetModeloJson(this.modelo.Configuracion);
		}
		else {
			let modeloJson = this.modelo.Configuracion.replace('\"', '"');
			this.modeloConsecutivo = JSON.parse(modeloJson);
		}
	}
	public GetModeloJson() {
		if (this.configConsecutivoComponent)
			this.modelo.Configuracion = this.configConsecutivoComponent.GetModeloJson();
		else
			this.modelo.Configuracion = JSON.stringify(this.modeloConsecutivo);
	}
	public GetComandos() {
		let Comandos: Array<any> = [
			{
				"Etiqueta": "Detalle Expediente",
				"Nombre": "BusquedaDetalleExpediente",
				"Campos": [
					{
						"Nombre": "IdExpediente",
						"Etiqueta": "IdExpediente"
					},
					{
						"Nombre": "Codigo",
						"Etiqueta": "Codigo"
					},
					{
						"Nombre": "Nombre",
						"Etiqueta": "Nombre"

					},
					{
						"Nombre": "Descripcion",
						"Etiqueta": "Descripcion"
					},
					{
						"Nombre": "FechaFinal",
						"Etiqueta": "FechaFinal"
					},
					{
						"Nombre": "IdTipoSeguridadDocumento",
						"Etiqueta": "IdTipoSeguridadDocumento"
					},
					{
						"Nombre": "TipoBodega",
						"Etiqueta": "TipoBodega"
					},
					{
						"Nombre": "FechaCreacion",
						"Etiqueta": "FechaCreacion"
					},
					{
						"Nombre": "MaquinaCreacion",
						"Etiqueta": "MaquinaCreacion"
					},
					{
						"Nombre": "IdUsuarioCreacion",
						"Etiqueta": "IdUsuarioCreacion"
					},
					{
						"Nombre": "Activo",
						"Etiqueta": "Activo"
					},

					{
						"Nombre": "IdVersionTablaRetencionDocumental",
						"Etiqueta": "IdVersionTablaRetencionDocumental"
					},
					{
						"Nombre": "IdTablaRetencionDocumental",
						"Etiqueta": "IdTablaRetencionDocumental"
					},
					{
						"Nombre": "VersionTablaRetencion",
						"Etiqueta": "VersionTablaRetencion"
					},
					{
						"Nombre": "CodigoTablaRetencion",
						"Etiqueta": "CodigoTablaRetencion"
					},
					{
						"Nombre": "TablaRetencion",
						"Etiqueta": "TablaRetencion"
					},
					{
						"Nombre": "DescripcionTablaRetencion",
						"Etiqueta": "DescripcionTablaRetencion"
					},
					{
						"Nombre": "TiempoAnos",
						"Etiqueta": "TiempoAnos"
					},
					{
						"Nombre": "TiempoMeses",
						"Etiqueta": "TiempoMeses"
					},
					{
						"Nombre": "TiempoDias",
						"Etiqueta": "TiempoDias"
					},
					{
						"Nombre": "TiempoTRD",
						"Etiqueta": "TiempoTRD"
					},

					{
						"Nombre": "IdVersionAreaEmpresa",
						"Etiqueta": "IdVersionAreaEmpresa"
					},
					{
						"Nombre": "VersionAreaEmpresa",
						"Etiqueta": "VersionAreaEmpresa"
					},
					{
						"Nombre": "IdAreaEmpresa",
						"Etiqueta": "IdAreaEmpresa"
					},
					{
						"Nombre": "IdVersionAreaEmpresaPadre",
						"Etiqueta": "IdVersionAreaEmpresaPadre"
					},
					{
						"Nombre": "IdTipoAreaEmpresa",
						"Etiqueta": "IdTipoAreaEmpresa"
					},
					{
						"Nombre": "TipoAreaEmpresa",
						"Etiqueta": "TipoAreaEmpresa"
					},
					{
						"Nombre": "AreaEmpresa",
						"Etiqueta": "AreaEmpresa"
					},

					{
						"Nombre": "IdVersionSerieTVersionSerie",
						"Etiqueta": "IdVersionSerieVersionSerie"
					},
					{
						"Nombre": "VersionSerie",
						"Etiqueta": "VersionSerie"
					},
					{
						"Nombre": "IdSerie",
						"Etiqueta": "IdSerie"
					},
					{
						"Nombre": "Serie",
						"Etiqueta": "Serie"
					},
					{
						"Nombre": "IdVersionSubSerie",
						"Etiqueta": "IdVersionSubSerie"
					},
					{
						"Nombre": "VersionSubSerie",
						"Etiqueta": "VersionSubSerie"
					},
					{
						"Nombre": "IdSubSerie",
						"Etiqueta": "IdSubSerie"
					},
					{
						"Nombre": "IdVersionSubSeriePadre",
						"Etiqueta": "IdVersionSubSeriePadre"
					},
					{
						"Nombre": "IdVersionSerieTVersionSubSerie",
						"Etiqueta": "IdVersionSerieTVersionSubSerie"
					},
					{
						"Nombre": "SubSerie",
						"Etiqueta": "SubSerie"
					},
					{
						"Nombre": "VersionSubSerie",
						"Etiqueta": "VersionSubSerie"
					},
					{
						"Nombre": "TiempoAnosSubSerie",
						"Etiqueta": "TiempoAnosSubSerie"
					},
					{
						"Nombre": "TiempoMesesSubSerie",
						"Etiqueta": "TiempoMesesSubSerie"
					},
					{
						"Nombre": "TiempoDiasSubSerie",
						"Etiqueta": "TiempoDiasSubSerie"
					},
					{
						"Nombre": "TiempoSubSerie",
						"Etiqueta": "TiempoSubSerie"
					},

					{
						"Nombre": "IdVersionAreaEmpresaResponsable",
						"Etiqueta": "IdVersionAreaEmpresaResponsable"
					},
					{
						"Nombre": "VersionAreaEmpresaResponsable",
						"Etiqueta": "VersionAreaEmpresaResponsable"
					},
					{
						"Nombre": "AreaEmpresaResponsable",
						"Etiqueta": "AreaEmpresaResponsable"
					},
					{
						"Nombre": "IdAreaEmpresaResponsable",
						"Etiqueta": "IdAreaEmpresaResponsable"
					},

					{
						"Nombre": "IdRoleResponsable",
						"Etiqueta": "IdRoleResponsable"
					},
					{
						"Nombre": "RoleResponsable",
						"Etiqueta": "RoleResponsable"
					},
					{
						"Nombre": "IdAreaEmpresaRoleResponsable",
						"Etiqueta": "IdAreaEmpresaRoleResponsable"
					},
					{
						"Nombre": "IdUsuarioResponsable",
						"Etiqueta": "IdUsuarioResponsable"
					},
					{
						"Nombre": "UsuarioResponsable",
						"Etiqueta": "UsuarioResponsable"
					},
					{
						"Nombre": "IdAreaEmpresaUsuarioResponsable",
						"Etiqueta": "IdAreaEmpresaUsuarioResponsable"
					},
					{
						"Nombre": "EmailResponsable",
						"Etiqueta": "EmailResponsable"
					}
				]
			},
			{
				"Etiqueta": "Detalle Documento",
				"Nombre": "BusquedaDetalleDocumentoExpediente"
			}
		];
		return Comandos;
	}
	public GetConfiguracion(): string {
		return JSON.stringify(this.GetJsonConfiguracion());
	}
	public GetJsonConfiguracion(): any {

		let modelo = {
			"Comandos": this.GetComandos(),
			"ConfigsExpediente": [],
			"ConfigExpediente": {
				"TipoIdExpediente": 0,
				"Id": 0,
				"ValoresReemplazo": { "REMOVER_VACIOS": "" },
				"FormatoTexto": "Mayusculas",
				"Campos": [],
				"CaracterSeparacion": "",
				"ConfigDocumento": null
			},
			"ConfigDocumento": {
				"ValoresReemplazo": { "REMOVER_VACIOS": "" },
				"FormatoTexto": "Minusculas",
				"Campos": [
					{
						"TipoCampo": "Autonumerico",
						"Valor": "",
						"Cultura": null,
						"Formato": null,
						"CaracterRelleno": "0",
						"AnchoRelleno": 5,
						"TipoRelleno": "Izquierdo",
						"ValoresReemplazo": { "ASDADSD": "ahsdhjasjd" }
					}
				],
				"CaracterSeparacion": "|",
				"Comandos": this.GetComandos()
			}
		}

		return modelo;
	}

}
