//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ControllerMethods,
  FormActionsThroughEmitters,
  OpcionesPermisosCrearExpediente,
  Paginas,
  TipoObjetoParaPermisos,
  TipoParaPermisos,
  TipoSeguridad,
} from "../../../models/general.enum";
import { FormControl, FormGroup } from "@angular/forms";
import {
  ModalNotifyComponent,
  ModalNotifyTypes,
} from "../../../components/modal-notify/modal-notify.component";

import { AdminPermisosComponent } from "../../admin-permisos/admin-permisos.component";
import { BaseCrud } from "../../../bussines/base-crud";
import { BaseServiceService } from "../../../services/base-service.service";
import { ConfigWindow } from "../../../models/config-window";
import { DataTableComponent } from "../../controls/data-table/data-table.component";
import { DataTableVersionesCrudTrdComponent } from "../../expediente/data-table-versiones-crud-trd/data-table-versiones-crud-trd.component";
import { DocumentoAdjuntosComponent } from "../../documento-expediente/documento-adjuntos/documento-adjuntos.component";
import { DynamicControlsMetadataComponent } from "../../controls/dynamic-controls-metadata/dynamic-controls-metadata.component";
import { FileUploaderComponent } from "../../controls/file-uploader/file-uploader.component";
import { FormDetailsComponent } from "../../controls/form-details/form-details.component";
import { GeneralFormComponent } from "../../controls/general-form/general-form.component";
import { HelperActions } from "src/app/helpers/helper-actions";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { List } from "../../../../assets/linqts/compilado/index";
import { PalabrasClaveComponent } from "../../palabras-clave/palabras-clave.component";
import { StaticData } from "../../../helpers/static-data";
import { Utilities } from "../../../helpers/utilities";
import { debug } from "util";
import { AdminPermisosServidorComponent } from "../../admin-permisos-servidor/admin-permisos-servidor.component";

declare var fabric: any;

@Component({
  selector: "app-tdocumento-expediente",
  templateUrl: "./tdocumento-expediente.component.html",
  styleUrls: ["./tdocumento-expediente.component.scss"],
})
export class TDocumentoExpedienteComponent
  extends GeneralFormComponent
  implements OnInit, AfterViewInit {
  // adminPermisosComponent: AdminPermisosComponent;
  adminPermisosComponent: AdminPermisosServidorComponent;
  palabrasClaveComponent: PalabrasClaveComponent;
  //@ViewChild('documento', { static: false }) documentoComponente: FileUploaderComponent;
  documentoAdjuntos: DocumentoAdjuntosComponent;
  @Input() detalleExpediente: any;
  @Input() configDocumento: any;
  @Input() public importUrl: string = null;
  // CM
  DynamicMetadata: DynamicControlsMetadataComponent;
  isModelContainer: boolean = true;
  adjuntos = new Array();
  public sidePanel: any;

  @Output() public onSaveDocumentData = new EventEmitter<any>();
  @Output() SetTipoSeguridadEmitter = new EventEmitter<any>();


  guardaAdjuntos: boolean = false;
  verAdminPermisos: boolean = true;
  adminPermisos: boolean = true;
  tienePermisoGuardar: boolean = true;
  tienePermisoCambiarDoc: boolean = true;
  imagenCambioDir: string;
  stopClear: boolean = false;
  IdExpedienteGeneral: any;
  Descriptores: any;
  TipoSeguridadValor: any;
  FirstLoadDatatable: any = 0;
  configValidacionDocumento: any;
  controlsConfigCache: any = null;
  PermisosTipoSeguridadDocumento: any;
  IdUsuarioResponsable: any;
  numeroRadicacionProceso: any;

  constructor(
    public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions
  ) {
    super(baseService, utility, helperImages, helperRules, helperActions);

    this.imagenCambioDir = this.utility.GetPathImages(
      this.utility.GetNodeType("CambioDireccion")
    );
  }

  ngAfterViewInit() {
    this.sidePanel = document.querySelector("#SidePanelDetalleExpediente");
    this.SetModeloPermisos(false, true);
    
    if(this.utility.EditItem != undefined){

      this.numeroRadicacionProceso = this.utility.EditItem.NumeroRadicacionProceso;
      //this.Pagina.Titulo = "C.U.I " + this.numeroRadicacionProceso;
      //debugger;
      /**
       * Ajuste cuando no existe C.U.I se muestra código de Expediente
       * Edward Morales 02062022_1407
       */
      if (this.numeroRadicacionProceso == undefined) {
        this.Pagina.Titulo = this.utility.EditItem.Codigo;
      } else {
        this.Pagina.Titulo = "C.U.I " + this.numeroRadicacionProceso;
      }

    }



    super.ngAfterViewInit();
  }
  SetDatosDocumento(isEdit: boolean, containDocuments?: boolean) {
    if (this.documentoAdjuntos) {
      this.documentoAdjuntos.tienePermisoCambiarDoc = this.tienePermisoCambiarDoc;
      this.documentoAdjuntos.tienePermisoGuardar = this.tienePermisoGuardar;
      this.documentoAdjuntos.isEdit = isEdit;

      if (isEdit || containDocuments)
        this.documentoAdjuntos.RaiseForceValidateSaveEvent(true, false);
      //CM
      else this.documentoAdjuntos.RaiseForceValidateSaveEvent(false, true); //CM
    }
  }
  SetModeloPermisos(isEdit: boolean, isAfterViewInit: boolean = false) {
    const that = this;

    if (isEdit) {
      this.verAdminPermisos = this.utility.TienePemisoUsuario(
        this.permissionsConfig,
        this.modelo.modelo,
        "VerPermisos"
      );
      this.adminPermisos = this.utility.TienePemisoUsuario(
        this.permissionsConfig,
        this.modelo.modelo,
        "AdminPermisos"
      );

      this.tienePermisoCambiarDoc = this.utility.TienePemisoUsuario(
        this.permissionsConfig,
        this.modelo.modelo,
        "CambiarDoc"
      );
      this.tienePermisoGuardar = this.utility.TienePemisoUsuario(
        this.permissionsConfig,
        this.modelo.modelo,
        "Guardar"
      );

      this.documentoAdjuntos.tienePermisoCambiarDoc = this.tienePermisoCambiarDoc;
      this.documentoAdjuntos.tienePermisoGuardar = this.tienePermisoGuardar;
      this.documentoAdjuntos.isEdit = true;
      this.documentoAdjuntos.RaiseForceValidateSaveEvent(true, false); //CM
    } else {
      this.verAdminPermisos = true;
      this.adminPermisos = true;
      this.tienePermisoGuardar = true;
      this.tienePermisoCambiarDoc = true;
    }
    // this.modelo.modelo.FirmaHash = '';
    this.SetDatosDocumento(isEdit);
    /**
     * INICIO
     * 25022021 - 1650 Edward Morales
     * Ajuste de permisos para documentos
     */
    //if (!isAfterViewInit)
    //setTimeout(() => {
    if (this.adminPermisosComponent) {
      //this.adminPermisosComponent.esDocumento=true;
      this.adminPermisosComponent.SetModelo(that.modelo);
      //this.adminPermisosComponent.Disabled = this.adminPermisos;
      this.adminPermisosComponent.verPermitido = this.adminPermisos;
      this.adminPermisosComponent.guardarPermitido = this.adminPermisos;
    }

    if (this.palabrasClaveComponent)
      this.palabrasClaveComponent.SetModelo(that.modelo);

    //this.palabrasClaveComponent.SetModelo(that.modelo);
    // }, 100);
  }

  public Limpiar(callback: (data: any) => void = null, action?: any) {
    if (this.palabrasClaveComponent.texto) {
      this.palabrasClaveComponent.texto = '';
    }
    let idExpediente = this.modelo.modelo.IdExpediente;
    /*let PermisosDoc = this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes;*/
    // let IdTipoSeguridadDocumento = this.modelo.modelo.IdTipoSeguridadDocumento;
    this.documentoAdjuntos.RaiseForceValidateSaveEvent(true, true); //CM
    this.SetModeloPermisos(false);
    //this.CreateModel(false);



    this.utility.SetFieldsToModel(this.modelo.modelo, null); //this.filterParent);
    if (callback) callback(this.modelo);
    this.SetModelToControls();
    this.parentClearCall.next({ modelo: this.modelo, action: action });
    this.SetProcesando(false);

    //  OJO  VALIDAR ESTE CODIGO
    // EL ERROR DE LA ACYUALIZACION DE DOCUMENTO ESPO QUE SE EJECUTA LA LINEA this.RaiseFormEvent('Clear');
    // y LIMPIA LOS ARCHIVOS A ENVIAR ANTES DE ENVIAR
    // ENTONCES SE DEBE DETENER LA LIMIPEZA PARA DESPUES DE BAJAR LOS ARCHIVOS
    // EL CODIGO ORIGINAL ERA SOLO LA LINEA this.RaiseFormEvent('Clear');
    if (this.documentoAdjuntos) {
      if (!this.documentoAdjuntos.stopClear) this.RaiseFormEvent("Clear");
    } else this.RaiseFormEvent("Clear");

    /// Inicio de modificacion Marlon Granda 29_11_2020
    /// Cambio de comobo Expediente por tabla de expediente , se comentarea la seigiente linea
    //this.componentsContainer.Tables[0].ReCargarGrilla();
    //debugger;
    this.modelo.modelo.IdExpediente = idExpediente
    /*this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = PermisosDoc;*/
    // this.modelo.modelo.IdTipoSeguridadDocumento = IdTipoSeguridadDocumento
    this.modelo.modeloVirtual.PermisosHeredadosExpediente = this.configValidacionDocumento.HeredaPermisosExpediente
    this.componentsContainer.ReloadTable("DataTables_TDocumentoExpediente", {
      IdEmpresa: -1,
      IdExpediente: this.modelo.modelo.IdExpediente
      /*,
        TodosPermisosDocumentosExpedientes: this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes*/
    });
    /// Fin de modificacion Marlon Granda 29_11_2020

    //this.commandBar.SetExtraModelo(null)
    //this.modelo.modelo.IdExpediente =idExpediente;
    //debugger;
    if (this.utility.ReturnParentDetailsItem) {
      this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente;
      if (this.utility.ReturnParentDetailsItem.IdTipoSeguridadDocumento) {
        this.TipoSeguridadValor = this.utility.ReturnParentDetailsItem.IdTipoSeguridadDocumento;
      }
      this.SetModelToControls()
      // this.modelo.modelo.IdTipoSeguridadDocumento = this.utility.ReturnParentDetailsItem.IdTipoSeguridadDocumento;
    }
    /*
    /*
    Ajuste Bug 0000788: Carga de Documentos - Control de acceso
    Lina Figueredo 30/11/2023
    Mantener el valor del "Control de acceso" Para multiples cargas de documentos en el mismo panel
    */
    this.SetModelToControls();

    /*
    Ajuste Bug 0005997: Tipo seguridad Documento
    Edward Morales 02062022_1310
    */
    if (this.TipoSeguridadValor) {
      if(this.TipoSeguridadValor == TipoSeguridad.Privado){
      this.modelo.modelo.IdTipoSeguridadDocumento = this.TipoSeguridadValor;
    }else{
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;
    }

    /*
    Ajuste Bug 0000788: Carga de Documentos - Control de acceso
    Lina Figueredo 30/11/2023
    Se comenta linea 289
    */
    // Ajuste bug borrado modelo al limpiar
    // Julian Sarmiento
    //this.ClearModel("IdExpediente");
    this.SetModelToControls();
    }

  }

  public LimpiarDocumentos() {
    if (this.documentoAdjuntos)
      // se
      this.documentoAdjuntos.LimpiarDocumentos();
  }

  // CABIAR POR PARMETRIZACION
  public SetExpediente(item: any) {
    //debugger;
    if (item.IdUsuarioResponsable) {
      this.IdUsuarioResponsable = item.IdUsuarioResponsable;
    }
    this.detalleExpediente = item;

    this.permissionsConfig.expediente = item;

    /*if (this.configValidacionDocumento.TipoSeguridadPorPermisos) {
      this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = item ? item.IdUsuarioResponsable != StaticData.Usuario.IdUsuario ? false : true : false;
      StaticData.Usuario.TodosPermisosDocumentosExpedientes = this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes;
    }
    else
      this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = false;*/
    /// Inicio de modificacion Marlon Granda 29_11_2020
    /// Cambio de comobo Expediente por tabla de expediente , se comentarea la siguiente linea
    this.componentsContainer.SetTableProperty(
      "DataTables_TDocumentoExpediente",
      "configPermisos",
      this.permissionsConfig
    );
    /// Fin de modificacion Marlon Granda 29_11_2020
    if (item) {
      /// Inicio de modificacion Marlon Granda 29_11_2020
      /// Cambio de comobo Expediente por tabla de expediente
      this.modelo.modelo.IdExpediente = item.IdExpediente;      
      // this.modelo.modelo.IdTipoSeguridadDocumento = item.IdTipoSeguridadDocumento;
      this.modelo.modeloVirtual.PermisosHeredadosExpediente = this.configValidacionDocumento.HeredaPermisosExpediente
      this.componentsContainer.ReloadTable("DataTables_TDocumentoExpediente", {
        IdEmpresa: -1,
        IdExpediente: this.detalleExpediente.IdExpediente
        /*,
            TodosPermisosDocumentosExpedientes: this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes*/
      });
      /// Fin de modificacion Marlon Granda 29_11_2020

      //this.commandBar.SetHiddenOptionByType(true,)

      // this.componentsContainer.Tables[0].cargarGrilla = true;
      // this.componentsContainer.Tables[0].QueryConfig = {
      // 	Command: 'BusquedaDocumentosExpedienteCrud',
      // 	IsSp: true,
      // 	Entity: { IdEmpresa: -1, IdExpediente: item.IdExpediente },
      // 	WithPagination: true
      // };
    } else {
      /// Inicio de modificacion Marlon Granda 29_11_2020
      /// Cambio de comobo Expediente por tabla de expediente , se comentarea la siguiente linea
      //this.componentsContainer.Tables[0].cargarGrilla = false;
      this.modelo.modelo.IdExpediente = null;
      this.componentsContainer.SetTableProperty(
        "DataTables_TDocumentoExpediente",
        "cargarGrilla",
        false
      );
      this.componentsContainer.ReloadTable("DataTables_TDocumentoExpediente", {
        IdEmpresa: -1,
        IdExpediente: -2,
      });

      /// Fin de modificacion Marlon Granda 29_11_2020
    }

    // this.permissionsConfig = {
    // 	TipoObjetoParaPermiso: TipoObjetoParaPermisos.Documento, Pagina: this.Pagina, clear: true,
    // 	expediente: item, CreatedRowConfig: {}
    // };

    /// Inicio de modificacion Marlon Granda 29_11_2020
    /// Cambio de comobo Expediente por tabla de expediente , se comentarea la siguiente linea
    //this.componentsContainer.Tables[0].configPermisos = this.permissionsConfig;
    /// Fin de modificacion Marlon Granda 29_11_2020
    this.RaiseFormEvent("OnUpdatePermissionsConfig", true);
  }

  // public GetDetalleExpediente(item: any) {

  // 	const that = this;
  // 	let obj = this.utility.GetApiModel('GetNodosTodoDetalleExpediente', null)
  // 	obj.QueryConfig.Command = 'BusquedaTodoDetalleExpediente';
  // 	obj.QueryConfig.IsSp = true;
  // 	obj.QueryConfig.IsMulti = true;
  // 	obj.QueryConfig.Entity = {
  // 		IdEmpresa: -1,
  // 		IdExpediente: item.IdExpediente
  // 	};

  // 	//this.url = (!this.url) ? 'TExpediente' : this.url;
  // 	//this.processMessage ='Buscando documentos del expediente......';
  // 	let info = {
  // 		componentName: this.componentName, controlName: 'GetDetalleExpediente',
  // 		processMessage: 'Cargando Detalles del expediente......'
  // 	};
  // 	this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

  // 		this.detalleExpediente = data.Data[0];

  // 	}).catch(err => { });

  // }

  public GetConfigDocuemento(item: any, setConfig: boolean) {
    /*Se crea funcion para cargar combobox de carpetas se separa la funcionalidad existente para que se cargue una vez se obtiene
    la configuración del documento, si no hay carpetas asociadas a la Versión de TRD seleccionada no se cargan carpetas*/
    /*yair romero 21-01-2024 se comenta la linea 407 ya que llama una funcion que se deveria de ejecuta en en el NgAfterViewInitEnd ya que
    esta ya que la funcion SetItemsComboBox de la linea 726 resetea el modelo */
    //this.cargarComboBoxCarpetas();
    if (!item) {
      if (setConfig == true) this.configDocumento = null;
      this.documentoAdjuntos.SetConfigDocumento(null);
      return;
    }
    if (item && item.IdTipoDocumental) {
      this.configs.ConfigDescriptores.Params = { IdTipoDocumental: item.IdTipoDocumental };
      this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
    }
    const that = this;
    let obj = this.utility.GetApiModel("GetConfigDocuemento", null);
    obj.QueryConfig = null;
    obj.IdExpediente = item.IdExpediente;

    let info = {
      componentName: this.componentName,
      controlName: "GetConfigDocuemento",
      processMessage: "Cargando Config Documento......",
    };
    this.baseService
      .Ejecutar(
        obj,
        null,
        info,
        false,
        this.componentName + "/GetConfig",
        false,
        false
      )
      .then((data) => {
        let configDocumento = data.Data;
        if (setConfig == true) this.configDocumento = configDocumento;
        this.documentoAdjuntos.SetConfigDocumento(configDocumento);
        this.documentoAdjuntos.configDocumento = this.configDocumento;
      })
      .catch((err) => { });
  }

  public GetDetalleTipoDocumental(item: any) {
    const that = this;
    if (!item) return;

    let obj = this.utility.GetApiModel("GetDetalleTipoDocumental", null);
    obj.QueryConfig = {
      Command: "BusquedaTipoDocumentalDetalle",
      IsSp: true,
      Entity: {
        IdEmpresa: -1,
        IdTipoDocumental: item.IdTipoDocumental,
      },
    };

    let info = {
      componentName: this.componentName,
      controlName: "GetDetalleTipoDocumental",
      processMessage: "Cargando Config Tipo Documental......",
    };

    this.baseService
      .Get(obj, null, info, this.componentName, false, false)
      .then((data) => {
        let configDocumento = data.Data;
        this.RemoveMetadataControls(null);
        this.CreateMetadataControls(data.Data[0].Configuracion);
        this.DynamicMetadata.Controls = data.Data[0].Configuracion
          ? this.Pagina.ConfigPage.Metadata
          : [];
        let obj = this.utility.GetApiModel("GetDetalleMetadata", null);

        obj.QueryConfig = {
          Command: "BusquedaValorMetadataDocumentoExpediente",
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdDocumentoExpediente: this.modelo.modelo.IdDocumentoExpediente,
          },
        };

        let info = {
          componentName: this.componentName,
          controlName: "GetMetadata",
          processMessage: "Cargando Config Metadata",
        };

        this.baseService
          .Get(obj, null, info, this.componentName, false, false)
          .then((data) => {

            this.modelo.modeloMetadata.Items._elements.forEach((_controls) => {
              data.Data.forEach((dataControls) => {
                if (dataControls.NombreControl == _controls.Name) {
                  // if (_controls.DataType == "String")
                  //   _controls.Value = dataControls.ValorTextoCuatrocientos
                  _controls.IdParentObject = dataControls.IdRegistroTipoDocumentalPaginaMetadatos;
                  _controls.Id = dataControls.IdRegistroTipoDocumentalPaginaMetadatosDetalle;
                  switch (_controls.DataType) {
                    case "Int32":
                    case "Int64":
                    case "Int16":
                      //ValorEntero
                      _controls.Value = dataControls.ValorEntero;
                      break;
                    case "Decimal":
                    case "Number":
                      //ValorNumero

                      _controls.Value = dataControls.ValorNumero;
                      break;
                    case "Boolean":
                      //ValorBoolean
                      _controls.Value = dataControls.ValorBoolean;
                      break;
                    case "DateTime":
                      //ValorFecha
                      _controls.Value = dataControls.ValorFecha;
                      break;
                    case "String":
                      //ValorTextoCuatrocientos
                      _controls.Value = dataControls.ValorTextoCuatrocientos;
                      break;
                    case "SemiLargeString":
                      //ValorTextoMil
                      _controls.Value = dataControls.ValorTextoMil;
                      break;
                    case "LargeString":
                    case "Json":
                      //ValorObservaciones
                      _controls.Value += dataControls.ValorObservaciones;
                      break;
                  }
                }
              });
            });

            this.SetModelToControls();
            data.Data;
          })
          .catch((err) => { });
        this.SetModelToControls();
      })
      .catch((err) => { });
  }
  public OnCreatePermissionsConfig() {
    this.permissionsConfig = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Documento,
      Pagina: this.Pagina,
      clear: true,
      expediente: null,
      CreatedRowConfig: {},
    };
    this.RaiseFormEvent("OnCreatePermissionsConfig", true);
  }

  public OnInit() {
    this.modelo.modeloVirtual.UsuarioAutenticado = StaticData.Usuario.IdUsuario;
    this.configValidacionDocumento = StaticData.GeneralConfig.ConfigValidacionDocumento
    this.modelo.modeloVirtual.PermisosHeredadosExpediente = this.configValidacionDocumento.HeredaPermisosExpediente;
    //debugger;
    /*if (this.utility.ReturnParentDetailsItem) {
      if (this.utility.ReturnParentDetailsItem.IdUsuarioResponsable == this.modelo.modeloVirtual.UsuarioAutenticado) {
        // StaticData.Usuario.TodosPermisosDocumentosExpedientes = true;
        this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = true;
      }
      else {
        this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = false;
      }
    }*/

    super.OnInit();


  }

  public OnLoadGridHandler(event) {
    if (event.controlName == 'DataTables_TDocumentoExpediente') {
      this.FirstLoadDatatable += 1;

      if (this.FirstLoadDatatable >= 2) {
        //setTimeout(function () {
        this.modelo.modeloVirtual.viewCuaderno = true;
        //}, 3000);
      }
    }
    else {
      this.modelo.modeloVirtual.viewCuaderno = false;
    }
  }

  public OnInitEnd() {
    this.OnCreatePermissionsConfig();

    this.loadedGridEmitter.subscribe((event) => {
      this.FinConsultaGrilla(event);
    });

    this.Descriptores = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Descriptores" });

    this.preSaveEmitter.subscribe(((modelo) => {
      // setTimeout(() => {
      this.modelo.modelo.PalabrasNormalizadas = JSON.stringify(this.modelo.modeloVirtual.GridPalabrasNormalizadas);

      if (this.palabrasClaveComponent && this.palabrasClaveComponent.items.length > 0)
        this.modelo.modelo.PalabrasClave = this.palabrasClaveComponent.items.toString();

      if (!this.modelo.modelo.IdExpediente && this.utility.ReturnParentDetailsItem && this.utility.ReturnParentDetailsItem.IdExpediente) {
        this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente
        // this.modelo.modelo.IdTipoSeguridadDocumento = this.utility.ReturnParentDetailsItem.IdTipoSeguridadDocumento
      }
      if (this.adminPermisosComponent) {
        //this.adminPermisosComponent.esDocumento=true;
        this.adminPermisosComponent.SetModelo(this.modelo, false);
        this.adminPermisosComponent.Disabled = !this.adminPermisos;
      }

      if (this.IsEdit && this.documentoAdjuntos.documentoComponente.isSelectedFiles())
        this.guardaAdjuntos = true;

    }))
    this.postSaveEmitter.subscribe((modelo) => {
      this.utility.EditItem.FechaCreacion=(this.utility.EditItem.FechaCreacion =='' || new Date(modelo.data.data.FechaCreacionDocumento)<new Date(this.utility.EditItem.FechaCreacion))?
      modelo.data.data.FechaCreacionDocumento.split('T')[0]:this.utility.EditItem.FechaCreacion

      this.utility.EditItem.FechaFinal=(this.utility.EditItem.FechaFinal =='' || new Date(modelo.data.data.FechaCreacionDocumento)>new Date(this.utility.EditItem.FechaFinal))?
      modelo.data.data.FechaCreacionDocumento.split('T')[0]:this.utility.EditItem.FechaFinal

      if (this.configValidacionDocumento.TipoSeguridadPorPermisos) {
        if (this.detalleExpediente && this.detalleExpediente.IdTipoSeguridadDocumento)
          this.TipoSeguridadValor = this.detalleExpediente.IdTipoSeguridadDocumento;
        else if (this.modelo.modelo.IdTipoSeguridadDocumento)
          this.TipoSeguridadValor = this.modelo.modelo.IdTipoSeguridadDocumento;
        else
          this.TipoSeguridadValor = TipoSeguridad.Privado
      }
      if (this.documentoAdjuntos.documentoComponente.isSelectedFiles()) {
        if (this.IsEdit) {
          this.guardaAdjuntos = true;
          this.documentoAdjuntos.habilitaGuardado.subscribe(event => {
            this.helperActions.comunicationService.raiseValidateSaveEvent({ Name: "FileBoxDocumentoExpediente", Ok: true });
            this.helperActions.comunicationService.raiseValidateSaveEvent({ Name: this.name, Ok: true });
          });
        }
        this.documentoAdjuntos.GuardarArchivo(modelo);
      }
      else {
        if (!this.IsEdit)
          this.utility.VerModalOk(
            "Se creó el documento sin adjuntos con el N° de codigo: " +
            "\n\r" +
            modelo.data.data.Codigo
          );
        else
          this.utility.VerModalOk(
            "Se modificó el archivo con código: " +
            "\n\r" +
            modelo.data.data.Codigo
          );

        this.onSaveDocumentData.emit();
        //debugger;
        setTimeout(() => {
          this.documentoAdjuntos.SetConfigDocumento(this.configDocumento);
        }, 2000);
      }
    });

    if (this.detalleExpediente && !this.configDocumento) {
      this.GetConfigDocuemento(this.detalleExpediente, true);
    }

    /// Inicio de modificacion Marlon Granda 29_11_2020
    /// Cambio realizado por navegacion desde detalle expediente
    if (this.utility.ParentDetailsItem) {
      this.utility.ReturnParentDetailsItem = this.utility.ParentDetailsItem;
      // this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente
      this.utility.ParentDetailsItem = null;
      const DataTables_BusquedaExpediente = this.Pagina.ConfigPage.Controls.find(
        (x) => {
          return x.Name == "DataTables_BusquedaExpediente" && x.Activo == true;
        }
      );
      if (DataTables_BusquedaExpediente) {
        DataTables_BusquedaExpediente.Activo = false;
      } else {
        const IdExpediente = this.Pagina.ConfigPage.Controls.find((x) => {
          return x.Name == "IdExpediente" && x.Activo == true;
        });
        IdExpediente.Activo = false;
      }
      // this.SetExpediente(this.utility.ReturnParentDetailsItem);
      // this.GetConfigDocuemento(this.utility.ReturnParentDetailsItem, true);
    }
    /// Fin de modificacion Marlon Granda 29_11_2020

    this.SetTipoSeguridadEmitter.subscribe(event => {
      if (this.utility.ReturnParentDetailsItem)
        if (this.utility.ReturnParentDetailsItem.IdTipoSeguridadDocumento) {
          this.modelo.modelo.IdTipoSeguridadDocumento = this.utility.ReturnParentDetailsItem.IdTipoSeguridadDocumento;
          this.SetModelToControls();
        }
    });
  }

  public NgAfterViewInitEnd() {

    //Cabio para visualizar el boton de retornar siempre desde cargar documento ya que no se nos mostraba cuando
    //se viaulizaba un documento de un expediente
    setTimeout(() => {
      if (this.commandBar)
        this.commandBar.SetHiddenOption(false, "Name", "Retornar");
    }, 500);
    this.cargarComboBoxCarpetas();
  }
  /*Se crea funcion para cargar combobox de carpetas se separa la funcionalidad existente para que se cargue una vez se obtiene
    la configuración del documento, si no hay carpetas asociadas a la Versión de TRD seleccionada no se cargan carpetas*/
  public cargarComboBoxCarpetas(){
    if (this.utility.ReturnParentDetailsItem) {
      // 02102021 Inicio Asignar datos a combos carpetas y tipos documentales edwin alonso
      const configuracion = JSON.parse(this.utility.ReturnParentDetailsItem.ConfiguracionTRD);
      if (configuracion && configuracion.TiposDocumentales) {
        var tiposDoc = configuracion["TiposDocumentales"];
        this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc)
      }
      if (configuracion && configuracion.Carpetas) {
        var CarpetasCombo = configuracion["Carpetas"];
        this.configs.ConfigIdCarpeta.Context.SetItemsComboBox(CarpetasCombo)
      }else{
        const carpetas =[];
        this.configs.ConfigIdCarpeta.Context.SetItemsComboBox(carpetas)
      }

      //this.modelo.modeloVirtual.NumeroRadicacionProceso = this.numeroRadicacionProceso;
      this.SetModelToControls();
      // fin
    }
  }
  // public FinGuardar(data: any) {
  // 	this.ExcecuteFuntion(ControllerMethods.Save, true, data);
  // 	this.ExcecuteFuntion(ControllerMethods.Clear, true);
  // 	this.SetProcesando(false);
  // 	if (this.cargarGrilla)
  // 		this.ReCargarGrilla();

  // 	this.Limpiar();
  // }
  public OnDataTableEditHandler(event) {
    /// Inicio de modificacion Marlon Granda 29_11_2020
    /// Cambio de comobo Expediente por tabla de expediente

    if (event && event.name == "DataTables_BusquedaExpediente") {
      this.SetExpediente(this.modelo.expediente);
      this.GetConfigDocuemento(this.modelo.expediente, true);
      delete this.modelo.expediente;
    }
    /// Inicio de modificacion Marlon Granda 29_11_2020
    else {
      this.GetConfigDocuemento(event.modelo.modelo, true);
      super.OnDataTableEditHandler(event);
      this.GetDetalleDocumento(event.modelo.modelo);
      this.SetModeloPermisos(true);
      this.guardaAdjuntos = false;

    }

    if (this.modelo.modelo.PalabrasNormalizadas && this.modelo.modelo.PalabrasNormalizadas != "" && this.modelo.modelo.PalabrasNormalizadas != "[]") {
      this.modelo.modeloVirtual.GridPalabrasNormalizadas = JSON.parse(this.modelo.modelo.PalabrasNormalizadas);
    }
    this.modelo.modeloVirtual.PermisosHeredadosExpediente = this.configValidacionDocumento.HeredaPermisosExpediente;
  }

  // _Guardar(form: FormGroup, configButtons?: any) {
  //   //this.cnfgButtons = configButtons;
  //
  //   super._Guardar(form, configButtons);
  // }

  public GetDetalleDocumento(modelo) {
    //this.parentClearCall.next(this.modelo);

    this.GetDetalleTipoDocumental(modelo);

    this.documentoAdjuntos.isEdit = true;
  }

  public SetConfigAdjuntosAdjuntos() {
    this.documentoAdjuntos.SetConfigDocumento(this.configDocumento);
    this.documentoAdjuntos.tienePermisoGuardar = this.tienePermisoGuardar;
    // if(this.documentoAdjuntos)
  }
  public FinConsultaGrilla(event: any) {
    //this.SetProcesandoBuscar(false);
    //this.CrearNodosJsTree(event.data);
    //this.CrearNodosdatosJsTree();
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    //debugger;
    try {
      this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, {
        item: item,
        controlName: controlName,
        config: config,
      });

      if (controlName == "IdExpediente") {
        //this.Limpiar(null,true);
        this.SetExpediente(item);
        this.GetConfigDocuemento(item, true);
        // this.ReCargarGrilla();

        this.comboBoxManager.ExecuteCascading(item, controlName, config);

      }

      if (controlName == "IdCarpeta") {

        if (this.modelo.modelo.IdExpediente == null && this.utility.ReturnParentDetailsItem) {
          this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente;
        }
        this.comboBoxManager.ExecuteCascading(item, controlName, config);
      }

      if (controlName == "Descriptores") {
        if(!this.modelo.modeloVirtual["GridPalabrasNormalizadas"])
          this.modelo.modeloVirtual.GridPalabrasNormalizadas = [];
        if (item && this.modelo.modeloVirtual.Descriptores &&
          !this.modelo.modeloVirtual["GridPalabrasNormalizadas"].find(x => { return x.Id == item.IdTesauro; })) {
          this.modelo.modeloVirtual["GridPalabrasNormalizadas"].push({ Id: item.IdTesauro, Palabra: item.Palabra });
        }
      }

      if (controlName == "IdTipoDocumental") {
        //debugger;
        this.GetDetalleTipoDocumental(item);
        if (this.Descriptores.Activo) {
          if (item) {
            this.configs.ConfigDescriptores.Params = { IdTipoDocumental: item.IdTipoDocumental };
          }
          this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
        }

      }

      this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, {
        item: item,
        controlName: controlName,
        config: config,
      });
    } catch (error) {
      this.utility.logger.LogError(error, null, {
        componentName: this.componentName,
        method: "OnSelectedComboBox",
        controlName: controlName,
      });
      this.utility.VerModalDanger().then((data) => { });
    }
  }

  public ContineArchivosCorreoMsg(selectedFiles: Array<any>) {
    let ver: boolean = false;

    for (let i = 0; i < selectedFiles.length; i++) {

      //MP se modificó porque daba error cuando el archivo tiene por nombre la extension, Ejemplo: ".msg.pdf"
      //if (selectedFiles[i].name.toLowerCase().includes('.msg') && selectedFiles[i].principal == true) {
      if (selectedFiles[i].name.toLocaleLowerCase().split('.').slice(-1).includes('msg') && selectedFiles[i].principal == true) {
        ver = true;
        return ver;
      }

    }

    return ver;
  }
  public AddComponentInstanceHandler(event) {
    if (event instanceof DocumentoAdjuntosComponent) {

      this.documentoAdjuntos = event;
      if (this.documentoAdjuntos.recargarGrillaFormulario.observers.length == 0)
        this.documentoAdjuntos.recargarGrillaFormulario.subscribe((item) => {
          this.modelo.modelo.IdExpediente = item.obj.Data.IdExpediente;
          //debugger;
          if (this.utility.EditItem != undefined) {
            // if (this.utility.EditItem.IdUsuarioResponsable == StaticData.Usuario.IdUsuario) {
            //   this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = true;
            //   StaticData.Usuario.TodosPermisosDocumentosExpedientes = true;
            // }
            // else {
            //   this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = false;
            //   StaticData.Usuario.TodosPermisosDocumentosExpedientes = false;
            // }
            // if (this.IsEdit) {
            //   this.guardaAdjuntos = true;
            //   this.Limpiar();
            // }
          }
          /*else {
            if (this.IdUsuarioResponsable == StaticData.Usuario.IdUsuario) {
              this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = true;
              StaticData.Usuario.TodosPermisosDocumentosExpedientes = true;
            } else {
              this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = false;
              StaticData.Usuario.TodosPermisosDocumentosExpedientes = false;
            }
          }*/


          // this.modelo.modelo.IdTipoSeguridadDocumento = item.obj.Data.IdTipoSeguridadDocumento;
          this.SetModelToControls();
          //this.utility.VerModalOk(`Se cargó el documento  ${item.obj.Data.Nombre}, con código ${item.obj.Data.Codigo} \ny adjunto ${item.obj.Data.NombreDocumento}.`).then(() => this.ReCargarGrilla()

          if (this.documentoAdjuntos) {
            if (!this.documentoAdjuntos.stopClear) this.RaiseFormEvent("Clear");
          } else this.RaiseFormEvent("Clear");

          this.utility
            .VerModalOk(
              `Se cargó el documento  ${item.obj.Data.Nombre}, con código ${item.obj.Data.Codigo}.`
            )
            .then(() => this.ReCargarGrilla());
          this.onSaveDocumentData.emit();
          this.modelo.modeloVirtual.PermisosHeredadosExpediente = this.configValidacionDocumento.HeredaPermisosExpediente
          this.componentsContainer.ReloadTable(
            "DataTables_TDocumentoExpediente",
            {
              IdEmpresa: -1, IdExpediente: this.modelo.modelo.IdExpediente
              /*,
                    TodosPermisosDocumentosExpedientes: this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes*/
            }
          );
          this.helperActions.comunicationService.raiseFormEvent({
            Name: this.name,
            Action: FormActionsThroughEmitters.NewDocument, modelo: this.utility.ReturnParentDetailsItem
          });
          this.SetTipoSeguridadEmitter.emit();
        });

      // 			this.documentoAdjuntos.isEdit = false;
      // 			this.documentoAdjuntos.onAfterViewInit.subscribe(item => {

      // 				setTimeout(() => {
      //
      // 					this.documentoAdjuntos.RaiseForceValidateSaveEvent(false, true);

      // 				}, 8000);
      // 			})





      this.SetConfigAdjuntosAdjuntos();
      if (this.IsEdit) {
        this.GetDetalleDocumento(this.modelo.modelo);
      }

      if (this.documentoAdjuntos.accionesEventEmitter.observers.length == 0)
        this.documentoAdjuntos.accionesEventEmitter.subscribe(item => {


          if (this.configDocumento && this.configDocumento.ConfigConvertirCorreo
            && this.configDocumento.ConfigConvertirCorreo.Campos.length > 0) {
            let contineMsg = false;
            switch (item.action) {

              case 'RemoverArchivo':
              case 'SeleccionArchivo':
                contineMsg = this.ContineArchivosCorreoMsg(item.event.selectedFiles);
                break;

            }

            if (contineMsg && !this.IsEdit) {

              this.configDocumento.ConfigConvertirCorreo.Campos.forEach(campo => {
                const ctr = this.form.controls[campo.Campo];
                let ctrConfig: any = this.utility
                  .GetAllFormControls(this.Pagina.ConfigPage.Controls)
                  .find((x) => {
                    return x.Name == campo.Campo;
                  });;
                switch (campo.Campo) {
                  case 'Autor':
                  case 'Descripcion':
                  case 'PalabrasClave':
                  case 'Nombre':

                    if (ctrConfig && ctrConfig.Required) {
                      ctr.setValue('TEMP POR MAPEO');
                    }
                    break;

                  case 'FechaCreacionDocumento':
                  case 'FechaTransmicion':
                  case 'FechaRecepcion':
                    if (ctrConfig && ctrConfig.Required) {
                      ctr.setValue(this.utility.GetCurrentDate());
                    }
                    break;
                  case 'NumeroFolios':
                  case 'CantidadPaginas':
                    if (ctrConfig && ctrConfig.Required) {
                      ctr.setValue(0);
                    }
                    break;

                }

              });
            }


          }


        });

      this.AddComponentInstanceHandlerInGeneral(event);
    }

    // CM
    else if (event instanceof DynamicControlsMetadataComponent) {
      this.DynamicMetadata = event;
      // this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;
      this.DynamicMetadata.form = this.form;
      this.AddComponentInstanceHandlerInGeneral(event);
    } else if (event instanceof PalabrasClaveComponent) {
      this.palabrasClaveComponent = event;
      this.palabrasClaveComponent.isModelContainer = true;
      this.AddComponentInstanceHandlerInGeneral(event);
    } else if (event instanceof AdminPermisosServidorComponent) {
      this.adminPermisosComponent = event;
      this.adminPermisosComponent.isModelContainer = true;
      this.adminPermisosComponent.Disabled = !this.adminPermisos;
      this.AddComponentInstanceHandlerInGeneral(event);
      this.adminPermisosComponent.onActionEventEmitter.subscribe(data => {
        this.SetTipoSeguridad(data.modelo)
      });
    }
    /// Inicio de modificacion Marlon Granda 29_11_2020
    /// Cambio realizado por navegacion desde detalle expediente
    else if (
      this.utility.ReturnParentDetailsItem &&
      event instanceof DataTableComponent
    ) {
      this.componentsContainer.Tables.push(event);
      if (event.name == "DataTables_TDocumentoExpediente") {
        this.SetExpediente(this.utility.ReturnParentDetailsItem);
        this.GetConfigDocuemento(this.utility.ReturnParentDetailsItem, true);
        this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente
        // this.modelo.modelo.IdTipoSeguridadDocumento = this.utility.ReturnParentDetailsItem.IdTipoSeguridadDocumento
        this.SetModelToControls();
      }
    }
    else if ( event.name == "SidePanelDoc")
    {
      if(event._subControl && event._subControl.OpenaPanel)
        this.newDocument();

      super.AddComponentInstanceHandler(event);
    }
    /// Fin de modificacion Marlon Granda 29_11_2020
    else {
      super.AddComponentInstanceHandler(event);
    }
  }

  public SetTipoSeguridad(modelo: any) {
    
    let LtsPermisos = modelo.modelo.ListaPermisos;

    let Privado = false;
    let Publico = false;
    let Reservado = false;

    const permisosUsuario = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.Usuario);
    const permisosRol = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.Role);
    const permisosArea = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.AreaEmpresa);

    if (LtsPermisos.Count() == 1) {

      if (permisosUsuario.find(element => element.Nombre == StaticData.Usuario.Email) != undefined|| 
          permisosRol.find(element => element.Nombre == StaticData.Usuario.Role) != undefined||
          permisosArea.find(element => element.Nombre == StaticData.Usuario.AreaEmpresa)!= undefined) {
          Privado = true;
      }
      else if(permisosUsuario.length > 0){
        if(permisosUsuario.find(element => element.AreaEmpresa != StaticData.Usuario.AreaEmpresa) != undefined){
          Reservado = true;
        }else{
          Privado = true;
        }
        
      }
      else{
        Reservado = true;
      }
      
    } else if (LtsPermisos.Count() > 1) {

      

      if (permisosRol.filter(element => element.Nombre != StaticData.Usuario.Role).length > 1 || 
          permisosArea.filter(element => element.Nombre != StaticData.Usuario.AreaEmpresa).length > 1) {
        Publico =true;        
      }else if(permisosRol.filter(element => element.Nombre != StaticData.Usuario.Role).length == 1 || 
      permisosArea.filter(element => element.Nombre != StaticData.Usuario.AreaEmpresa).length == 1){
        Reservado = true;
      }else{
        let cantPermisosUsuario = permisosUsuario.filter(element => element.AreaEmpresa != StaticData.Usuario.AreaEmpresa);
        if(cantPermisosUsuario != undefined && cantPermisosUsuario.length > 1){
          Publico = true;        
        }else if(cantPermisosUsuario != undefined && cantPermisosUsuario.length == 1){
          Reservado = true;
        }else{
          Privado = true;
        }
      }
    }else{
      Privado = true;
    }

    /*
      TipoSeguridad.Publico = Publica
      TipoSeguridad.Reservado = Reservada
      TipoSeguridad.Privado = Clasificada
    */   
    if (Publico)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Publico;
    else if (Reservado)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Reservado;
    else if (Privado)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;

    this.SetModelToControls();
    // this.SetTipoSeguridadEmitter.emit();
      
  }
  public ExtractPropertyFromJSON(json: string, property: string) {
    if (json != null) {
      var config = JSON.parse(json);
      if (config[property].length > 0) return config[property];
      else return null;
    } else {
      return null;
    }
  }

  public AuditoriaProcesos() {
    // this.InicializaAuditoria = true;
    const subscription = this.formEvent.subscribe({
      next: dataEvent => {

        var idSubRegistro = (this.modelo.documento) ? this.modelo.documento.IdDocumentoExpediente : this.modelo.modelo.IdDocumentoExpediente;
        if (dataEvent.Name === FormActionsThroughEmitters.OnDownloadSharePoint || dataEvent.Name === FormActionsThroughEmitters.OnDownloadAzureBlob) {

          let obj = this.utility.GetApiModel('CrearAuditoriaDescargaDocumentosSharePoint', null)
          obj.QueryConfig.Command = 'CrearAuditoriaDescargaDocumentosSharePoint';
          obj.QueryConfig.IsSp = true;
          obj.QueryConfig.Entity = {
            IdEmpresa: -1,
            IdRegistro: this.modelo.modelo.IdExpediente,
            IdSubRegistro: idSubRegistro,//this.modelo.documento.IdDocumentoExpediente,
            UsuarioCreacion: StaticData.Usuario.Email,
            IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
            Maquina: -1,
            Descripcion: 'Descarga de archivo del documento ' + (this.modelo.modelo.NombreDocumento || this.modelo.documento.NombreDocumento) + ' en el expediente con Id: ' + this.modelo.modelo.IdExpediente
          };
          let info = {
            componentName: this.componentName, controlName: 'CrearAuditoriaDescargaDocumentosSharePoint',
            processMessage: 'Auditoria Expediente......'
          };
          this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
          }).catch(err => { }).finally(() => subscription.unsubscribe())
        }
        else if (dataEvent.Name === FormActionsThroughEmitters.OnViewAzureBlob) {

          let obj = this.utility.GetApiModel('CrearAuditoriaVisualizacionDocumentos', null)
          obj.QueryConfig.Command = 'CrearAuditoriaVisualizacionDocumentos';
          obj.QueryConfig.IsSp = true;
          obj.QueryConfig.Entity = {
            IdEmpresa: -1,
            IdRegistro: this.modelo.modelo.IdExpediente,
            IdSubRegistro: idSubRegistro, //this.modelo.documento.IdDocumentoExpediente,
            UsuarioCreacion: StaticData.Usuario.Email,
            IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
            Maquina: -1,
            Descripcion: 'Visualización de archivo del documento ' + (this.modelo.modelo.NombreDocumento || this.modelo.documento.NombreDocumento) + ' en el expediente con Id: ' + this.modelo.modelo.IdExpediente
          };
          let info = {
            componentName: this.componentName, controlName: 'CrearAuditoriaVisualizacionDocumentos',
            processMessage: 'Auditoria Expediente......'
          };
          this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
          }).catch(err => { }).finally(() => subscription.unsubscribe())
        }

      }
    })
  }

  public NavegarPaginaHija(url) {
    this.utility.ParentDetailsItem = this.modelo.modelo;
    this.utility.router.navigate([url]);
  }

  public VerAdjuntos() {


    if (
      this.modelo &&
      this.modelo.modelo &&
      this.modelo.modelo.ConfiguracionAdicional
    ) {
      let config = this.modelo.modelo.ConfiguracionAdicional;

      this.adjuntos = this.ExtractPropertyFromJSON(config, "Files");

      this.adjuntos.forEach((adj) => {
        adj.TienePermisosVisualizacion = this.utility.TienePemisoUsuario(
          this.permissionsConfig,
          this.modelo.modelo,
          "Ver"
        );
        adj.TienePermisosDescarga = this.utility.TienePemisoUsuario(
          this.permissionsConfig,
          this.modelo.modelo,
          "Descargar"
        );
        adj.Image = this.helperImages.GetRuleIcon(adj.Extension);
      });

      this.modelo.documento = this.modelo.modelo;
      new fabric["Panel"](this.sidePanel);
      console.log("adjuntos detalle", this.adjuntos);
    } else {
      let descripcion: string = "El documento no cuenta con adjuntos";
      this.utility.VerModalOk(descripcion);
    }
  }

  public newDocument()
  {
    if (this.detalleExpediente.IdEstado == 2) {
      this.utility
        .VerModalWarning({
          titulo: "Alerta",
          descripcion:
            "No se permite cargar documentos, el expediente se encuentra cerrado",
          verOk: true,
          verCancelar: false,
          ok: "Aceptar",
          cancelar: "NO",
        })
        .then((data) => { });
        const crudButtonSave = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtons"; });
        crudButtonSave.Hidden = true;
        this.SetModelToControls();
    } else {
      const file = this.documentoAdjuntos.documentoComponente.modeloArchivo;
      let containDoc = false;
      if(file.length > 0){
        containDoc = true;
      }
      if (this.configValidacionDocumento.TipoSeguridadPorPermisos && !this.IsEdit) {
        if (this.detalleExpediente && this.detalleExpediente.IdTipoSeguridadDocumento)
          this.modelo.modelo.IdTipoSeguridadDocumento = this.detalleExpediente.IdTipoSeguridadDocumento;
        else
          this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;
      }
      if (this.modelo.modelo && this.modelo.modelo.IdTipoDocumental == null) {
        this.RemoveMetadataControls(null);
        this.Pagina.ConfigPage.Metadata = [];
        this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;
      }
      this.modelo.modeloVirtual.NumeroRadicacionProceso = this.numeroRadicacionProceso;
      this.SetModelToControls();
      this.SetDatosDocumento(false, containDoc);
      this.documentoAdjuntos.SetConfigDocumento(this.configDocumento);
      //super.OnActionEventHandler(event);
      this.helperActions.comunicationService.raiseFormEvent({
        Name: this.name,
        Action: FormActionsThroughEmitters.NewDocument, modelo: this.utility.ReturnParentDetailsItem
      });
      this.SetTipoSeguridadEmitter.emit();
    }
  }

  public OnActionEventHandler(event) {
    /// Inicio de modificacion Marlon Granda 29_11_2020
    /// Cambio de comobo Expediente por tabla de expediente
    if (
      event.Action === FormActionsThroughEmitters.ClearModel &&
      // event.Table == "DataTables_BusquedaExpediente"
      event.Table == "DataTables_TDocumentoExpediente"
    ) {
      let IdExpediente = this.modelo.modelo.IdExpediente
      this.guardaAdjuntos = false;
      this.Limpiar(null, true);
      this.modelo.modeloVirtual.PermisosHeredadosExpediente = this.configValidacionDocumento.HeredaPermisosExpediente
      this.componentsContainer.ReloadTable("DataTables_TDocumentoExpediente", {
        IdEmpresa: -1,
        IdExpediente: IdExpediente
        /*,
            TodosPermisosDocumentosExpedientes: this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes,*/
      });
      // super.OnActionEventHandler(event);
    }
    /// adicion de botones de navegacion a las paginas de documento y ubicacion
    else if (event.Option && event.Option.Type == "Return") {
      this.utility.router.navigate(["DetalleExpediente"]);
    }
    /// Fin de modificacion Marlon Granda 29_11_2020
    else {
      switch (event.ControlType) {
        case "CommandButton":
          if (event.Option.Name == "Cuadernos") {
            this.NavegarPaginaHija('Cuaderno');
            return;
          }
          if (event.Option.Type == "New") {
            this.newDocument();
            if (this.detalleExpediente.IdEstado != 2)
                super.OnActionEventHandler(event);
            // if (this.detalleExpediente.IdEstado == 2) {
            //   this.utility
            //     .VerModalWarning({
            //       titulo: "Alerta",
            //       descripcion:
            //         "No se permite cargar documentos, el expediente se encuentra cerrado",
            //       verOk: true,
            //       verCancelar: false,
            //       ok: "Aceptar",
            //       cancelar: "NO",
            //     })
            //     .then((data) => { });
            // } else {
            //   if (this.configValidacionDocumento.TipoSeguridadPorPermisos && !this.IsEdit) {
            //     if (this.detalleExpediente && this.detalleExpediente.IdTipoSeguridadDocumento)
            //       this.modelo.modelo.IdTipoSeguridadDocumento = this.detalleExpediente.IdTipoSeguridadDocumento;
            //     else
            //       this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;
            //   }
            //   if (this.modelo.modelo && this.modelo.modelo.IdTipoDocumental == null) {
            //     this.RemoveMetadataControls(null);
            //     this.Pagina.ConfigPage.Metadata = [];
            //     this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;
            //   }
            //   this.modelo.modeloVirtual.NumeroRadicacionProceso = this.numeroRadicacionProceso;
            //   this.SetModelToControls();
            //   this.SetDatosDocumento(false);
            //   this.documentoAdjuntos.SetConfigDocumento(this.configDocumento);
            //   super.OnActionEventHandler(event);
            //   this.helperActions.comunicationService.raiseFormEvent({
            //     Name: this.name,
            //     Action: FormActionsThroughEmitters.NewDocument, modelo: this.utility.ReturnParentDetailsItem
            //   });
            //   this.SetTipoSeguridadEmitter.emit();
            // }
          } else if (event.Option.Type == "Edit") {
            if (this.detalleExpediente.IdEstado == 2) {
              this.utility
                .VerModalWarning({
                  titulo: "Alerta",
                  descripcion:
                    "No se permite editar documentos, el expediente se encuentra cerrado",
                  verOk: true,
                  verCancelar: false,
                  ok: "Aceptar",
                  cancelar: "NO",
                })
                .then((data) => { });
            } else {
              this.modelo.modeloVirtual.NumeroRadicacionProceso = this.numeroRadicacionProceso;
              const ctr_codigo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Codigo"; });
              ctr_codigo.Hidden = false;
              const ctr_hash = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "FirmaHash"; });
              ctr_hash.Hidden = false;
              this.SetModelToControls();
              this.SetDatosDocumento(true);
              super.OnActionEventHandler(event);
            }
          } else if (event.Option.Type == "ViewAttachment") {
            this.VerAdjuntos();
          } else if (
            event.Option.Type == "Download" ||
            event.Option.Type == "ViewDocument"
          ) {

            this.componentName = "TDocumentoExpediente";
            this.AuditoriaProcesos();//Ejecuta Auditoria
            this.helperActions.ExecuteActionsControl(
              event.Option ? event.Option : event.config,
              false,
              this.modelo.modelo,
              this.modelo,
              this.permissionsConfig,
              this,
              null,
              new EventEmitter<any>(),
              this.componentsContainer,
              event.Option,
              this.modelo.modeloMetadata,
              { importUrl: this.importUrl }
            );

            // if (
            //   this.modelo.modelo.DataAdd &&
            //   this.modelo.modelo.DataAdd.hasOwnProperty("type")
            // ) {
            //
            //   // let datos
            //   // $.getJSON('https://ipapi.co/json/', function(data) {
            //   // 	datos = data
            //   // console.log(JSON.stringify(data, null, 2));
            //   // });
            //   let obj = this.utility.GetApiModel(
            //     "CrearAuditoriaDescargaDocumentosSharePoint",
            //     null
            //   );
            //   obj.QueryConfig.Command =
            //     "CrearAuditoriaDescargaDocumentosSharePoint";
            //   obj.QueryConfig.IsSp = true;
            //   obj.QueryConfig.Entity = {
            //     IdEmpresa: -1,
            //     IdRegistro: this.modelo.modelo.IdExpediente,
            //     UsuarioCreacion: StaticData.Usuario.Email,
            //     IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
            //     MaquinaCreacion: "1",
            //     Descripcion:
            //       "Descarga de archivo del documento" +
            //       this.modelo.modelo.NombreDocumento +
            //       "en el expediente con Id:" +
            //       this.modelo.modelo.IdExpediente,
            //   };
            //   let info = {
            //     componentName: this.componentName,
            //     controlName: "CrearAuditoriaDescargaDocumentosSharePoint",
            //     processMessage: "Auditoria Expediente......",
            //   };
            //   this.baseService
            //     .Get(obj, null, info, this.componentName, false, false)
            //     .then((data) => { })
            //     .catch((err) => { });
            // }
            //this.AuditoriaProcesos();
          } else super.OnActionEventHandler(event);
          break;
        case 'Button':
          if (event.Name == "searchTesauro") {
            this.configs.ConfigDescriptores.Params = { PalabraBusqueda: this.modelo.modeloVirtual.palabraTesauro };
            this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          }
          break;
        default:
          super.OnActionEventHandler(event);
          break;
      }
    }
  }
}
