import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { ControlActions, ControllerMethods, EstadosSolicitud, FormActionsThroughEmitters, TipoObjetoParaPermisos } from "src/app/models/general.enum";

import { AnimationMetadataType } from "@angular/animations";
import { BaseServiceService } from "src/app/services/base-service.service";
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from 'src/app/components/controls/file-uploader/file-uploader.component';
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { HelperActions } from "src/app/helpers/helper-actions";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { SolicitudCommonComponent } from "../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { Utilities } from "src/app/helpers/utilities";
import { validateContact } from "../../../contacto/contacto.component";
import { DocumentTemplateSocketService } from "src/app/services/document-template-socket-service";

declare let fabric: any;

@Component({
  selector: "app-solicitud-radicado-salida",
  templateUrl: "./solicitud-radicado-salida.component.html",
  styleUrls: ["./solicitud-radicado-salida.component.css"],
  providers: [DocumentTemplateSocketService]
})
export class SolicitudRadicadoSalidaComponent extends GeneralFormComponent implements OnInit {

  @Input() solicitudEntrada: any;
  indexCrudButtons = -1;
  verContactoButton: any;
  verExpedienteButton: any;
  configSeleccionarExpediente: any;
  configIdTipoRespuestaPqrs: any;
  configPin: any;
  configObservaciones: any;
  configObservacionesAprobar: any
  configObservacionesAnular: any
  configTipoFirma: any;
  configIdTipoDocumental: any;
  // configIdCiudad: any;
  configTipoDocumental: any;
  ConfigGridRadicadosAsociados: any;
  configArchivosAdjuntos: any;
  descargarPlanilla: any;
  configDestinatariCopias: any;
  configSeleccionUsuarios: any;
  RazonSocial: any;
  Cargo: any;
  Correo: any;
  Genero: any;
  PrimerNombre: any;
  SegundoNombre: any;
  PrimerApellido: any;
  SegundoApellido: any;
  abrirPanel = false;
  tipoOperacion: any = null;
  tipofirmaplantilla: any = null;
  TipofirmaEnvio: any;
  IdCiudad: any;
  IdPais: any;
  IdDepartamento: any;
  IdTipoIdentificacion: any;
  IdTipoDocumental: any;
  IdTipoRespuesta: any;
  NombreTipoDocumental: any;
  ConfigIdTipoRespuesta: any;
  FileBoxes: Array<FileUploaderComponent> = [];
  Oficio: FileUploaderComponent;
  HabilitaTipoDocumenta: any;
  InfoAnexos: any;
  errorTipoAnexo: any;
  countTipoAnexos: any;
  IdTipoSolicitudEntradaPadre: any;
  AsociacionMultiple: any;
  BtnPqrdAsociada: any;
  openModalBusquedaContacto: boolean;
  openModalBusquedaExpediente: boolean;
  received = [];
  sent = [];

  ConfigEntregaPersonal: any;
  CtrlEntregaPersonal: any;
  ConfigObservacionEntregaPersonal: any;
  textCommandBar: any;

  SinFirmaDigital: any = null;
  MessageSinFirmaDigital: any;
  MessageTipoRespuestaPqr: any;
  esTraslado: boolean = false;
  idContactoInicial: number = 0;
  CrudButtonsDetalle: any;

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions,
    private DocumentTemplateSocketService: DocumentTemplateSocketService) {
    super(baseService, utility, helperImages, helperRules, helperActions);

    DocumentTemplateSocketService.messages.subscribe(msg => {
      this.received.push(msg);
      console.log("Response from websocket: " + msg);
    });

  }

  public OnInitEnd() {


    this.SinFirmaDigital = StaticData.GeneralConfig.ConfigUbicaciones.find(x => { return x.IdTipoSolicitud == this.modelo.modelo.IdTipoSolicitud; }).ProcesarSinFirmaDigital;

    this.BtnPqrdAsociada = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.BtnPqrdAsociada;
      });


    if (this.utility["SolicitudEntrada"]) {
      this.solicitudEntrada = this.utility.Clone(this.utility["SolicitudEntrada"]);
      this.utility["SolicitudEntrada"] = null;
    }

    // if (this.name == "SolicitudesAnulaciones") {
    //   setTimeout(() => {
    //     document.getElementById("contentFile").style.display = "none";
    //   }, 0);
    // }

    this.InfoAnexos = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "InfoAnexos"; });
    this.indexCrudButtons = this.Pagina.ConfigPage.Controls.findIndex(x => { return x.Name == "CrudButtons"; });
    this.verContactoButton = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "BtnContactos"; });
    this.descargarPlanilla = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "btnDescargarPlanilla"; });
    this.verExpedienteButton = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "BtnExpedientes"; });
    this.configIdTipoRespuestaPqrs = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoRespuestaPqrs"; });
    this.configIdTipoDocumental = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoDocumental"; });
    // this.configIdCiudad = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCiudad"; });
    this.configTipoDocumental = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TipoDocumental"; });
    this.configSeleccionarExpediente = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "SeleccionarExpediente"; });
    this.RazonSocial = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "RazonSocial"; });
    this.Cargo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Cargo"; });
    this.Correo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Correo"; });
    this.Genero = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Genero"; });
    this.PrimerNombre = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "PrimerNombre"; });
    this.SegundoNombre = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "SegundoNombre"; });
    this.PrimerApellido = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "PrimerApellido"; });
    this.SegundoApellido = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "SegundoApellido"; });
    this.configPin = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Pin"; });
    this.configObservaciones = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Observaciones"; });
    this.configObservacionesAprobar = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "ObservacionesAprobar"; });
    this.configObservacionesAnular = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "ObservacionesAnular"; });
    this.configTipoFirma = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Tipofirma"; });
    this.configDestinatariCopias = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DestinatariosCopias"; });
    this.configSeleccionUsuarios = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "SeleccionUsuarios"; });

    this.configArchivosAdjuntos = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "ArchivosAdjuntos"; });
    this.ConfigIdTipoRespuesta = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoRespuesta"; });
    this.ConfigGridRadicadosAsociados = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "GridRadicadosAsociados"; });
    this.CrudButtonsDetalle = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtonsGuardar"; });
	  this.CtrlEntregaPersonal = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "EntregaPersonal"; });
    this.InfoAnexos.Hidden = true;

    this.MessageSinFirmaDigital = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "MessageBar_SinFirmaDigital" });
    this.MessageTipoRespuestaPqr = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "MsgBarTipoRespuesta"; });
    this.MessageTipoRespuestaPqr.Hidden = true;
    if (this.solicitudEntrada) {
      if (this.solicitudEntrada.AsociacionMultiple && this.solicitudEntrada.AsociacionMultiple.length > 10) {

        this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.solicitudEntrada.AsociacionMultiple);
        this.AsociacionMultiple = this.modelo.modeloVirtual.GridRadicadosAsociados;
      }
      this.HabilitaTipoDocumenta = "true";
      this.abrirPanel = true;
      this.verExpedienteButton.Hidden = true;
      this.modelo.modelo.IdExpediente = this.solicitudEntrada.IdExpediente;
      this.modelo.modelo.IdTipoDocumental = this.solicitudEntrada.IdTipoDocumental;
      this.modelo.modeloVirtual.NombreExpediente = this.solicitudEntrada.NombreExpediente;
      this.modelo.modeloVirtual.CodigoExpediente = this.solicitudEntrada.CodigoExpediente;
      this.modelo.modeloVirtual.IdTipoDocumental = this.modelo.modelo.IdTipoDocumental;
      this.modelo.modelo.Asunto = this.solicitudEntrada.Asunto;

      this.modelo.modelo.IdSolicitudEntrada = this.solicitudEntrada.IdSolicitud;
      this.modelo.modelo.IdTipoSolicitudEntrada = this.solicitudEntrada.IdTipoSolicitud;
      this.modelo.modelo.IdTipoRespuesta = this.solicitudEntrada.IdTipoRespuesta;
      this.IdTipoRespuesta = this.solicitudEntrada.IdTipoRespuesta;
      this.IdTipoDocumental = this.solicitudEntrada.IdTipoDocumental;
      this.NombreTipoDocumental = this.solicitudEntrada.TipoDocumental;
      //if (this.solicitudEntrada.IdContacto  || this.solicitudEntrada.IdDatoPersonal) {
      // CM Se cambia por un control hidden sin configuración
      // let ConfigIdContacto = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdContacto" });
      // ConfigIdContacto.Hidden = true;

      this.SetContacto(this.solicitudEntrada);
      // this.SetContacto.componentInstance.postEditEmitter.subscribe(result => {
      // });
      //}


      if (this.solicitudEntrada && this.solicitudEntrada.IdSolicitudPqrd) {

        let obj = this.utility.GetApiModel("GetDetalleSolicitud", null);
        obj.QueryConfig = {
          Command: "ConsultaTipoRespuestaPqrTraslado",
          IsSp: true,
          Entity: {
            IdSolicitudEntrada: this.solicitudEntrada.IdSolicitudPqrd
          },
        };

        let info = {
          componentName: this.componentName,
          controlName: "GetDetalleSolicitud",
          processMessage: "Cargando Tipo Respuesta PQRS.....",
        };

        this.baseService
          .Get(obj, null, info, this.componentName, false, false)
          .then((data) => {

            var itemsCombo = data.Data;
            this.configs.ConfigIdTipoRespuestaPqrs.Context.ResetComboBox(itemsCombo, true);

          })
          .catch((err) => { });

      }
      if (this.solicitudEntrada.IdTipoSolicitudPadre == 5) {
        //debugger;
        this.BtnPqrdAsociada.Hidden = false;
        this.configIdTipoRespuestaPqrs.Hidden = false;
        const actionTipoDoc = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: [],
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["IdTipoRespuestaPqrs"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: "true",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty],
        };
        const contextTipoDoc = this;
        this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);
      }
      else {
        this.configIdTipoRespuestaPqrs.Hidden = true;
        this.BtnPqrdAsociada.Hidden = true;
        const actionTipoDoc = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: [],
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["IdTipoRespuestaPqrs"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: "false",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty],
        };
        const contextTipoDoc = this;
        this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);
      }
    }
    else if (this.name != "SolicitudesAnulaciones") {
      this.HabilitaTipoDocumenta = "false";
      this.configIdTipoRespuestaPqrs.Hidden = true;
      this.configIdTipoRespuestaPqrs.Required = false;
      this.verExpedienteButton.Hidden = false;
    }
    if (this.name == "SolicitudRadicadoSalida" && this.configs.ConfigIdTipoDocumental.Cotext)
      this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox([]);
    
    this.SetModelToControls();

    if (!this.modelo.modelo["DataAdd"])
      this.modelo.modelo["DataAdd"] = { OperacionSolicitud: null };

    if (!this.permissionsConfig) {
      this.permissionsConfig = {

        Pagina: this.Pagina, clear: true, CreatedRowConfig: {}
      };
    }

    const actionEnabled = {
      ControlDetails: {
        MappingData: [],
        MappingFilter: []
      },
      ControlType: "Action",
      ControlsForHidden: [],
      ControlsToUpdate: ["IdTipoDocumental"],
      Guid: Guid.create().toString(),
      IsPost: false,
      IsParentModel: false,
      MappingValues: [],
      MappingComponents: [],
      Property: "enabled",
      PropertyValue: this.HabilitaTipoDocumenta ? "true" : "false",
      ResultType: "Url",
      SendQueryString: false,
      TimerAction: 0,
      Type: ControlActions[ControlActions.SetControlProperty]
    };
    const context = this;
    this.helperActions.SetControlsValue(actionEnabled, context);


  }

  SetDatosPorTipoContacto(tipo) {
    if (this.PrimerNombre) {
      if (tipo == 7)// ciudaddano
      {
        this.RazonSocial.Hidden = true;
        this.Cargo.Hidden = true;
        this.Genero.Hidden = false;
        this.PrimerNombre.Hidden = false;
        //this.PrimerNombre.Label = "Primer Nombre";
        this.PrimerApellido.Hidden = false;
        this.SegundoApellido.Hidden = false;
        this.SegundoNombre.Hidden = false;
      }
      else if (tipo == 10)// Empresa
      {
        //this.PrimerNombre.Label = "Razón Social";
        this.RazonSocial.Hidden = false;
        this.Cargo.Hidden = false;
        this.Genero.Hidden = true;
        this.PrimerNombre.Hidden = true;
        this.PrimerApellido.Hidden = true;
        this.SegundoApellido.Hidden = true;
        this.SegundoNombre.Hidden = true;
      }
    }
  }

  SetContacto(item: any) {
    //debugger;
    if (item) {

      this.modelo.modelo.IdContacto = item.IdContacto;
      this.modelo.modelo.IdDatoPersonal = item.IdDatoPersonal;
      this.modelo.modelo.RazonSocial = item.RazonSocial;
      this.modelo.modelo.Cargo = item.Cargo;
      this.modelo.modelo.Genero = item.Genero;
      this.modelo.modelo.PrimerNombre = item.PrimerNombre;
      this.modelo.modelo.SegundoNombre = item.SegundoNombre;
      this.modelo.modelo.PrimerApellido = item.PrimerApellido;
      this.modelo.modelo.SegundoApellido = item.SegundoApellido;
      this.modelo.modelo.Ext = item.Ext;
      this.modelo.modelo.Celular = item.Celular;
      this.modelo.modelo.Telefono = item.Telefono;
      this.modelo.modelo.Direccion = item.Direccion;
      this.modelo.modelo.Correo = item.Correo;
      //
      this.IdCiudad = item.IdCiudad;
      this.IdPais = item.IdPais;
      this.IdDepartamento = item.IdDepartamento;
      this.IdTipoIdentificacion = item.IdTipoIdentificacion;
      this.IdTipoDocumental = item.IdTipoDocumental;
      this.NombreTipoDocumental = item.TipoDocumental;
      this.modelo.modelo.Identificacion = item.Identificacion;

      this.SetDatosPorTipoContacto(item.IdTipoContacto);
    }
    else {
      this.modelo.modelo.IdContacto = null;
      this.modelo.modelo.IdDatoPersonal = null;
      this.modelo.modelo.RazonSocial = null;
      this.modelo.modelo.Cargo = null;
      this.modelo.modelo.Genero = null;
      this.modelo.modelo.PrimerNombre = null;
      this.modelo.modelo.SegundoNombre = null;
      this.modelo.modelo.PrimerApellido = null;
      this.modelo.modelo.SegundoApellido = null;
      this.modelo.modelo.Ext = null;
      this.modelo.modelo.Celular = null;
      this.modelo.modelo.Telefono = null;
      this.modelo.modelo.Direccion = null;
      this.modelo.modelo.Correo = null;
      this.modelo.modelo.IdCiudad = null;
    }
  }

  GetConfigPermisos() {
    const permissionsConfig: any = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Pagina,
      Pagina: this.Pagina, 
      clear: true, 
      CreatedRowConfig: {},
    };
    return permissionsConfig;
  }

  VerBuscarExpediente() {

    this.openModalBusquedaExpediente = true;
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[2],
      limpiarInternamente: true,
      //modelo:this.modelo
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Búsqueda de Expedientes";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility.OpenWindow(componentName,
      config, modeloTemp).then(dataResult => {


        dataResult.componentInstance.postEditEmitter.subscribe(result => {

          this.modelo.modelo.IdExpediente = dataResult.componentInstance.modelo.IdExpediente;
          document.getElementById("MsgBarExpediente").style.display = "none";
          this.modelo.modeloVirtual.NombreExpediente = dataResult.componentInstance.modelo.Nombre;
          this.modelo.modeloVirtual.CodigoExpediente = dataResult.componentInstance.modelo.Codigo;
          this.SetModelToControls();
          this.tipoOperacion = "IncluirExpdiente";
          if (dataResult.componentInstance.modelo.ConfiguracionTRD == "")
            dataResult.componentInstance.modelo.ConfiguracionTRD = "{\"TiposDocumentales\":[]}";
          const trd = JSON.parse(dataResult.componentInstance.modelo.ConfiguracionTRD);
          const tiposDoc = trd["TiposDocumentales"];
          this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);


        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
          if (result.Action === FormActionsThroughEmitters.ClearModel) {
            document.getElementById("MsgBarExpediente").style.display = "block";
            this.modelo.modelo.IdExpediente = null;
            this.modelo.modeloVirtual.NombreExpediente = null;
            this.modelo.modeloVirtual.CodigoExpediente = null;
            //this.modelo.modelo["DataAdd"]["OperacionSolicitud"] = null;
            this.SetModelToControls();
          }
        });


        dataResult.componentInstance.loadedGridEmitter.subscribe(result => {


        });

        dataResult.onClosePromise.then(dataResultOnClose => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
          this.openModalBusquedaExpediente = false;
        });

      });
  }


  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {
      if(item)
      {
        this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, { item: item, controlName: controlName, config: config });

        this.TipofirmaEnvio = this.modelo.modeloVirtual.Tipofirma;
        this.comboBoxManager.ExecuteCascading(item, controlName, config);

        if (controlName == "IdContacto") {
          this.SetContacto(item);
        }
        else if (controlName == "SeleccionDestinatarios") {

          if(!this.modelo.modeloVirtual["DestinatariosCopias"])
            {this.modelo.modeloVirtual["DestinatariosCopias"] = [];}

          if (item && this.modelo.modeloVirtual.DestinatariosCopias &&
            !this.modelo.modeloVirtual["DestinatariosCopias"].find(x => { return x.IdContacto == item.IdContacto; })) {
            //this.modelo.modeloVirtual["DestinatariosCopias"].push({ IdContacto: item.IdContacto, PrimerNombre: item.Texto, Correo: item.Correo, Direccion: item.Direccion, Telefono: item.Telefono, IdCiudad: item.IdCiudad, Ciudad: item.Ciudad });
            this.modelo.modeloVirtual["DestinatariosCopias"].push({ IdContacto: item.IdContacto, PrimerNombre: item.Texto, Correo: item.Correo, Direccion: item.Direccion, Celular: item.Celular, IdCiudad: item.IdCiudad, Ciudad: item.Ciudad, Telefono: item.Telefono });
            this.modelo.modeloVirtual.SeleccionDestinatarios = null;
          }
          // this.modelo.modeloVirtual.DestinatariosCopias.forEach(element => {
          //
          //      element.Disabled = true;
          //  });
        }
        else if (controlName == "SeleccionUsuarios") {

          if(!this.modelo.modeloVirtual["DestinatariosUsuarios"])
            {this.modelo.modeloVirtual["DestinatariosUsuarios"] = [];}

          if (item && this.modelo.modeloVirtual.DestinatariosUsuarios &&
            !this.modelo.modeloVirtual["DestinatariosUsuarios"].find(x => { return x.IdUsuario == item.IdUsuario; })) {
            this.modelo.modeloVirtual["DestinatariosUsuarios"].push({ IdUsuario: item.IdUsuario, Nombre: item.Nombre, Email: item.Email });
            this.modelo.modeloVirtual.SeleccionUsuarios = null;
          }
          // this.modelo.modeloVirtual.DestinatariosCopias.forEach(element => {
          //
          //      element.Disabled = true;
          //  });
        }
        else if (controlName === SolicitudCommonComponent.Controls.IdPais) {
          const context = this;
          this.helperActions.MultiSetControlsValue(
            [
              {
                ControlsToUpdate: ["CodigoPostal"],
                PropertiesToSet: {
                  required: item.IdPais != 1,
                },
              },
            ],
            context
          );
        }
        else if (controlName == "IdTipoRespuestaPqrs") {

          this.esTraslado = (item.EsTraslado == null) ? false : item.EsTraslado;
          this.MessageTipoRespuestaPqr.Hidden = !this.esTraslado;

          if(this.esTraslado){
            this.idContactoInicial = this.modelo.modelo.IdContacto;
            this.modelo.modelo.IdContacto = null;
          }
          else if(this.idContactoInicial)
            this.modelo.modelo.IdContacto = this.idContactoInicial;

          document.getElementById("MsgBarContacto").style.display = (this.esTraslado) ? "block" : "none"
          this.SetModelToControls();

          const actionRequiredContacto = {
            ControlDetails: {
              MappingData: [],
              MappingFilter: []
            },
            ControlType: "Action",
            ControlsForHidden: [],
            ControlsToUpdate: ["IdContacto"],
            Guid: Guid.create().toString(),
            IsPost: false,
            IsParentModel: false,
            MappingValues: [],
            MappingComponents: [],
            Property: "required",
            PropertyValue: this.esTraslado ? "true" : false,
            ResultType: "Url",
            SendQueryString: false,
            TimerAction: 0,
            Type: ControlActions[ControlActions.SetControlProperty]
          };
          const context = this;
          this.helperActions.SetControlsValue(actionRequiredContacto, context);

          if (item) {
            let obj = this.utility.GetApiModel('ConsultaAccionTipoRespuestaPqrs', null)
            obj.QueryConfig = {
              Command: 'ConsultaAccionTipoRespuestaPqrs',
              IsSp: true,
              Entity: {
                IdTipoRespuestaPqrs: item.IdTipoRespuestaPqrd
              }
            };
            let info = {
              componentName: this.componentName, controlName: 'ConsultaAccionTipoRespuestaPqrs',
              processMessage: 'Cargando acción respuesta......'
            };

            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

              //debugger;
              this.modelo.modelo.AccionRespuestaPqrs = data.Data[0].AccionRespuestaPqrs;

            }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });
          }

        }
        // else if (controlName == "IdContacto") {
        //     this.SetContacto(item);
        // }


        const actionrequired = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: []
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["Pin"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: controlName === "Tipofirma" ? this.modelo.modeloVirtual.Tipofirma === 46 && this.SinFirmaDigital != true ? "true" : "false" : "false",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty]
        };
        const context = this;
        this.helperActions.SetControlsValue(actionrequired, context);

        if (controlName == "Tipofirma" && this.modelo.modeloVirtual.Tipofirma == 46) {

          this.configPin.Hidden = false;
          this.modelo.modeloVirtual.Pin = "";
          this.configObservacionesAprobar.Hidden = true;
          //this.configObservacionesAnular.Hidden = true;
          this.configObservaciones.Hidden = true;
          this.modelo.modelo.Observaciones = this.modelo.modelo.Observaciones;
          // this.configPin.Required = true
        }
        else {

          this.configPin.Hidden = true;
          this.configObservaciones.Hidden = false;
          //this.configObservacionesAnular.Hidden = true;
          //this.modelo.modelo.Observaciones = this.modelo.modeloVirtual.ObservacionesAprobar;
          this.modelo.modelo.Observaciones = this.modelo.modelo.ObservacionesAprobar;
          this.configObservacionesAprobar.Hidden = false;
          // this.configPin.Required = false;
        }
        this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, { item: item, controlName: controlName, config: config });
        this.modelo.modelo.IdTipoDocumental = this.modelo.modeloVirtual.IdTipoDocumental;

        this.SetModelToControls();

        this.onSelectedComboBoxForRules.emit({ item: item, controlName: controlName, config: config });

      }
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: "OnSelectedComboBox", controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }
  VerBuscarPqrd() {
    //debugger;
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[1],
      limpiarInternamente: true,
      modelo: this.utility.Clone(this.modelo),
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Relacionar PQRS";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility
      .OpenWindow(componentName, config, modeloTemp)
      .then((dataResult) => {
        dataResult.componentInstance.parentContext = this;

        dataResult.componentInstance.postSeleccionItemEmitter.subscribe((result) => {

          //debugger;
          if (result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("Codigo") &&
            !this.modelo.modeloVirtual["GridRadicadosAsociados"].find(x => { return x.Codigo == result.data.data.findItem.Codigo; })) {
            this.modelo.modeloVirtual["GridRadicadosAsociados"].push({ Id: result.data.data.findItem.IdSolicitud, Codigo: result.data.data.findItem.Codigo });
          }

          /*const CodigoRadicadoAsociado =
            result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("Codigo")
              ? result.data.data.findItem.Codigo
              : null;

          const IdSolicitud =
            result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("IdSolicitud")
              ? result.data.data.findItem.IdSolicitud
              : null;

          this.modelo.modeloVirtual.CodigoPqrdAsociada =
            CodigoRadicadoAsociado ||
            dataResult.componentInstance.modelo.Codigo;
          this.modelo.modelo.IdSolicitudPqrdAsociada =
            IdSolicitud || dataResult.componentInstance.modelo.IdSolicitud;

          this.BtnDetalleSolicitud.Hidden = false;*/
          this.SetModelToControls();
        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(
          (result) => {
            if (result.Action === FormActionsThroughEmitters.ClearModel) {
              this.modelo.modeloVirtual.CodigoPqrdAsociada = null;
              this.modelo.modelo.IdSolicitudPqrdAsociada = null;
              this.SetModelToControls();
            }
          }
        );

        dataResult.componentInstance.loadedGridEmitter.subscribe(
          (result) => { }
        );

        dataResult.onClosePromise.then((dataResultOnClose) => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
        });
      });
  }

  public validateRequiredControls(controlsName: any, yesNo: any) {

    const action = {
      ControlDetails: {
        MappingData: [],
        MappingFilter: []
      },
      ControlType: "Action",
      ControlsForHidden: [],
      ControlsToUpdate: controlsName,
      Guid: Guid.create().toString(),
      IsPost: false,
      IsParentModel: false,
      MappingValues: [],
      MappingComponents: [],
      Property: "required",
      PropertyValue: yesNo,
      ResultType: "Url",
      SendQueryString: false,
      TimerAction: 0,
      Type: ControlActions[ControlActions.SetControlProperty]
    };

    const context = this;
    this.helperActions.SetControlsValue(action, context);
    super.SetModelToControls();

  }

  public OnActionEventHandler(event) {

    //debugger;

    switch (event.ControlType) {
      case "Button": {
        switch (event.Name) {

          case "BtnPqrdAsociada": {
            this.VerBuscarPqrd();
            break;
          }
          case "BtnContactos": {
            if(!this.openModalBusquedaContacto)
              this.VerBuscarContacto();
            else
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de Búsqueda Contactos abierta." });

            break;
          }
          case "BtnExpedientes": {
            if(!this.openModalBusquedaExpediente)
              this.VerBuscarExpediente();
            else
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de Búsqueda de Expediente abierta." });

            break;
          }
          case "BtnGuardarComentario": {

            var lenComentario = this.modelo.modeloVirtual.Comentario;
            if (lenComentario != null && lenComentario.length > 0) {

              if (this.modelo.modelo.DataAdd) {
                this.modelo.modelo.DataAdd["TextoComentario"] = this.modelo.modeloVirtual.Comentario;
                this.modelo.modelo.DataAdd["GuardaComentario"] = "true"
              }
              else {
                this.modelo.modelo.DataAdd = {
                  TextoComentario: this.modelo.modeloVirtual.Comentario,
                  GuardaComentario: "true"
                };
              }
              this.utility.VerModalConfirmacionGuardar().then(modelo => {
                if (modelo.dialogResult == true) {

                  this.SetModelToControls();

                  this.modelo.modelo.Token = StaticData.Usuario.Token;
                  this.modelo.modelo.AppKey = StaticData.AppKey;
                  this.modelo.modelo.PkValue = this.modelo.modelo.IdSolicitudRadicadoEntrada;

                  let obj = this.utility.GetApiModel("GuardarComentario", this.componentName, null, null, this.modelo.modelo);

                  this.baseService.GuardarComentario(obj, null,
                    { componentName: 'SolicitudRadicadoSalidaComponent', method: 'GuardarComentario', processMessage: 'Procesando credenciales....' },
                    'TSolicitudRadicadoSalida', false, true).then(data => {
                      this.modelo.modeloVirtual.Comentario = null;
                      this.SetModelToControls();

                      const tbGrillaComentario = this.componentsContainer.Tables.find(x => { return x.name == "DataTable_SolicitudEntradaComentario"; });
                      tbGrillaComentario.cargarGrilla = true;
                      tbGrillaComentario.ReCargarGrilla();

                      this.utility.VerModalOk("Se agregó comentario al radicado de salida: " + "\n\r" + this.modelo.modelo.Codigo);

                    }
                    );
                }
              });
            } else {
              this.utility.VerModalDanger({ "titulo": "Valor Requerido", "descripcion": "El campo comentario es requerido." });
            }

            break;
          }
        }
        break;
      }
      case "CommandButton": {
        //debugger;
        switch (this.Pagina.Titulo) {
          case "Radicado de Salida":
		        this.modelo.modelo.ObservacionEntregaPersonal = (this.modelo.modelo.ObservacionEntregaPersonal == "" ? " " : this.modelo.modelo.ObservacionEntregaPersonal); //" ";
            this.textCommandBar = event.Option.Name;
            this.CtrlEntregaPersonal.Hidden = true;
            this.modelo.modelo.Aprobado = null;
            this.modelo.modelo.IdCausalRechazo = null;
            this.MessageTipoRespuestaPqr.Hidden = true;
            this.idContactoInicial = null;
            this.esTraslado = false;
            // this.InfoAnexos.Hidden = true;
            // this.modelo.modeloVirtual.AnexosRequeridos = "ok";

            const actionAsunto = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["Asunto", "IdContacto"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: event.Option.Name === "Nuevo" ? "true" : "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };

            const actionPin = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["Pin"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: event.Option.Name === "Aprobar" && this.modelo.modelo.IdTipoRespuesta == 37 && this.SinFirmaDigital != true ? "true" : "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };

            const actionrequired2 = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["ObligatorioAprobacion"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: event.Option.Name === "SolicitaAprobacion" ? "true" : "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };

            const actionrequired3 = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["ObligatorioVB"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: event.Option.Name === "SolicitaVistoBueno" ? "true" : "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };
            const actionrequired4 = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              //ControlsToUpdate: ["Observaciones"],
              ControlsToUpdate: ["ObservacionesAnular"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: event.Option.Name === "SolicitaAnulacion" ? "true" : "false",
              //PropertyValue: event.Option.Label === "Solicitar Anulación" ? "true" : "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };
            //const context = this;
            const context = this;
            this.helperActions.SetControlsValue(actionrequired2, context);
            this.helperActions.SetControlsValue(actionrequired3, context);
            this.helperActions.SetControlsValue(actionrequired4, context);
            this.helperActions.SetControlsValue(actionAsunto, context);
            this.helperActions.SetControlsValue(actionPin, context);



            const actionrequired = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["Tipofirma"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: event.Option.Name === "Aprobar" ? this.modelo.modelo.IdTipoRespuesta === 36 && this.SinFirmaDigital != true ? "true" : "false" : "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };

            this.helperActions.SetControlsValue(actionrequired, context);
            this.modelo.modelo.Observaciones = null;
            if (true) {
              const action = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: []
                },
                ControlType: "Action",
                ControlsForHidden: [],
                //ControlsToUpdate: ["Observaciones"],
                ControlsToUpdate: ["ObservacionesAnular"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: event.Option.Name === "SolicitaAnulacion" ? "true" : "false",
                //PropertyValue: event.Option.Label === "Solicitar Anulación" ? "true" : "false",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty]
              };

              const action2 = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: []
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["IdCausalRechazo"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: event.Option.Name === "Rechazar" ? "true" : "false",
                //PropertyValue: event.Option.Label === "Solicitar Anulación" ? "true" : "false",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty]
              };

              const action3 = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: []
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["ObservacionesAutorizar"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: event.Option.Name === "AutorizaVistoBueno" ? "true" : "false",
                //PropertyValue: event.Option.Label === "Solicitar Anulación" ? "true" : "false",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty]
              };

              const action4 = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: []
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["Observaciones"],
                //ControlsToUpdate: ["ObservacionesAnular"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: event.Option.Name === "Rechazar" ? "true" : "false",
                //PropertyValue: event.Option.Label === "Solicitar Anulación" ? "true" : "false",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty]
              };

              const context = this;
              this.helperActions.SetControlsValue(action, context);
              this.helperActions.SetControlsValue(action2, context);
              this.helperActions.SetControlsValue(action3, context);
              this.helperActions.SetControlsValue(action4, context);
              //debugger;
            }
            if (event.Option.Name == "Responder") {
              if (this.modelo.modelo.IdExpediente == null || this.modelo.modelo.IdExpediente == "") {
                this.utility.VerModalError("La solicitud no posee un expediente asignado");
                return;
              }
            }
            else if (event.Option.Name == "Aprobar") { // && this.Pagina.Titulo == "Radicado de Salida") {

              //INICIO
              //MIGUEL PATIÑO 03-12-2020
              //0003773: PIN debe ser campo requerido
              /*const actionrequired = {
                  ControlDetails: {
                      MappingData: [],
                      MappingFilter: []
                  },
                  ControlType: "Action",
                  ControlsForHidden: [],
                  ControlsToUpdate: ["Pin"],
                  Guid: Guid.create().toString(),
                  IsPost: false,
                  IsParentModel: false,
                  MappingValues: [],
                  MappingComponents: [],
                  Property: "required",
                  PropertyValue: this.modelo.modelo.IdTipoRespuesta == 37 ? "true" : "false",
                  ResultType: "Url",
                  SendQueryString: false,
                  TimerAction: 0,
                  Type: ControlActions[ControlActions.SetControlProperty]
              };
              */
              const context = this;
              this.helperActions.SetControlsValue(actionrequired, context);
              //FIN
              //debugger;
              if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple.length > 7) {

                this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
                this.AsociacionMultiple = this.modelo.modeloVirtual.GridRadicadosAsociados;
              }

              this.modelo.modeloVirtual.Tipofirma = "";
              if(this.MessageSinFirmaDigital){
                this.MessageSinFirmaDigital.Hidden = this.SinFirmaDigital != true ? true : false;
              }
              if (this.configTipoFirma && this.configPin) {
                if (this.modelo.modelo && this.modelo.modelo.IdTipoRespuesta == 37) {
                  this.TipofirmaEnvio = 46; //Si es Tipo de Soporte Mixto se asigna tipoFirmadeEnvio Digital
                  this.configTipoFirma.Hidden = true;
                  this.configTipoFirma.Required = false;
                  this.configPin.Hidden = this.SinFirmaDigital != true ? false : true;
                  // this.configObservacionesAprobar.Hidden = false;

                  this.configObservacionesAprobar.Hidden = true;
                  //this.configObservacionesAnular.Hidden = true;

                } else if (this.modelo.modelo && this.modelo.modelo.IdTipoRespuesta == 36) {
                  this.TipofirmaEnvio = this.SinFirmaDigital != true ? null : 46;
                  this.modelo.modeloVirtual.Tipofirma = this.SinFirmaDigital != true ? null : 46;
                  this.configTipoFirma.Hidden = this.SinFirmaDigital != true ? false : true;
                  this.configPin.Hidden =  true;
                  this.configObservacionesAprobar.Hidden = true;
                  //this.configObservacionesAnular.Hidden = true;
                  this.configTipoFirma.Required = this.SinFirmaDigital != true ? true : false;
                }
                else {
                  if(this.MessageSinFirmaDigital){
                    this.MessageSinFirmaDigital.Hidden = true;
                  }
                  this.configTipoFirma.Hidden = true;
                  this.configPin.Hidden = true;
                  this.configObservacionesAprobar.Hidden = false;
                  //this.configObservacionesAnular.Hidden = true;
                }
              }
              this.configIdTipoRespuestaPqrs.Required = false;
              this.configIdTipoDocumental.Required = false;
              this.ConfigIdTipoRespuesta.Required = false;
              this.Oficio.RaiseForceValidateSaveEvent(true, false);
              this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
              // this.modelo.modelo.IdContacto = null;
              //this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
            }
            else if (event.Option.Name == "Nuevo") {
              setTimeout(function () {
                document.getElementById("MsgBarExpediente").style.display = "block";
                document.getElementById("MsgBarContacto").style.display = "block";
              }, 500);
              // this.InfoAnexos.Hidden = true
              this.CrudButtonsDetalle.Hidden = false;
              this.RazonSocial.Hidden = false;
              this.Cargo.Hidden = false;
              this.InfoAnexos.Hidden = true;
              this.modelo.modeloVirtual.AnexosRequeridos = "ok";
              this.configIdTipoRespuestaPqrs.Hidden = true;
              this.configIdTipoRespuestaPqrs.Required = false;
              //debugger;
              this.configIdTipoDocumental.Required = true;
              this.verContactoButton.Hidden = false;
              this.verExpedienteButton.Hidden = false;

              this.configSeleccionUsuarios.Hidden = false;

              this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
              setTimeout(function () {
                if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] != undefined) {
                  (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.display = 'block';
                }
              }, 1500);
              this.SetModelToControls();
              this.Oficio.RaiseForceValidateSaveEvent(true, false);
              /* setTimeout(function(){
              const tiposdocumental = {
                  ControlDetails: {
                      MappingData: [],
                      MappingFilter: []
                  },
                  ControlType: "Action",
                  ControlsForHidden: [],
                  ControlsToUpdate: ["IdTipoDocumental"],
                  Guid: Guid.create().toString(),
                  IsPost: false,
                  IsParentModel: false,
                  MappingValues: [],
                  MappingComponents: [],
                  Property: "required",
                  PropertyValue: event.Option.Label === "Nuevo" ? "true" : "false",
                  ResultType: "Url",
                  SendQueryString: false,
                  TimerAction: 0,
                  Type: ControlActions[ControlActions.SetControlProperty]
              };
              const context = this;
              this.helperActions.SetControlsValue(tiposdocumental, context);
              }, 500);*/
            }
            else if (event.Option.Name == "Detalle") {
              //debugger;
			        this.CtrlEntregaPersonal.Hidden = this.modelo.modelo.IdTipoRespuesta == 34 && this.modelo.modelo.IdEstadoSolicitud == 8 ? false : true;
              this.ConfigObservacionEntregaPersonal.Hidden = this.modelo.modelo.EntregaPersonal == true ? false : true;
              this.modelo.modelo.ObservacionEntregaPersonal = (this.modelo.modelo.ObservacionEntregaPersonal == " " ? "" : this.modelo.modelo.ObservacionEntregaPersonal);    // " ";
              this.CrudButtonsDetalle.Hidden =  (this.modelo.modelo.IdUsuarioCreacion !== this.modelo.modelo.IdUsuarioResponsable ||this.modelo.modelo.IdEstadoSolicitud ==5)?true:false;      
              if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple.length > 7) {

                this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
                this.AsociacionMultiple = this.modelo.modeloVirtual.GridRadicadosAsociados;
              }
              setTimeout(function () {
                document.getElementById("MsgBarExpediente").style.display = "none";
                document.getElementById("MsgBarContacto").style.display = "none";
              }, 500);
              // this.InfoAnexos.Hidden = true


              //this.configArchivosAdjuntos.Required = false;

              if (this.modelo.selectedItem.IdTipoContacto == 7) {
                this.RazonSocial.Hidden = true;
                this.Cargo.Hidden = true;

              } else {
                this.RazonSocial.Hidden = false;
                this.Cargo.Hidden = false;

              }
              this.InfoAnexos.Hidden = true;
              this.modelo.modeloVirtual.AnexosRequeridos = "ok";
              this.configIdTipoDocumental.Hidden = true;
              this.configTipoDocumental.Hidden = false;
              this.modelo.modelo.IdExpediente = this.modelo.modelo.IdExpediente;
              this.modelo.modelo.IdTipoDocumental = this.modelo.modelo.IdTipoDocumental;
              this.modelo.modeloVirtual.NombreExpediente = this.modelo.modelo.NombreExpedienteVer;
              this.modelo.modeloVirtual.CodigoExpediente = this.modelo.modelo.CodigoExpedienteVer;
              this.verExpedienteButton.Hidden = true;

              //this.configSeleccionUsuarios.Hidden = true;

              this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
             // setTimeout(function () {
               // if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] != undefined) {
                //  (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.display = 'none';
                //}
              //}, 1500);

              if (this.modelo.selectedItem != undefined) {
                this.modelo.modeloVirtual.ComentarioRadicado = this.modelo.selectedItem.Comentario;

                const actioncomentario = {
                  ControlDetails: {
                    MappingData: [],
                    MappingFilter: []
                  },
                  ControlType: "Action",
                  ControlsForHidden: [],
                  ControlsToUpdate: ["ComentarioRadicado"],
                  Guid: Guid.create().toString(),
                  IsPost: false,
                  IsParentModel: false,
                  MappingValues: [],
                  MappingComponents: [],
                  Property: "enabled",
                  PropertyValue: "false",
                  ResultType: "Url",
                  SendQueryString: false,
                  TimerAction: 0,
                  Type: ControlActions[ControlActions.SetControlProperty]
                };

                const context = this;
                this.helperActions.SetControlsValue(actioncomentario, context);


              }
              /**
               * Edward
               * 22/01/21 13:18
               *
               * Solicitud de adjunto no requerido
               */
              this.Oficio.RaiseForceValidateSaveEvent(true, false);

              if (this.modelo.modelo.AnexosEdit) {

                this.modelo.modeloVirtual.GrillaAnexos = JSON.parse(this.modelo.modelo.AnexosEdit);
                //Los elementos quedan desactivados (ineditables)
                this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
                  element.Disabled = true;
                //  element.DisabledDelete = true;
                });
              }


              //////////////
              //   debugger;
              let obj = this.utility.GetApiModel('ConsultaTipoDocumentalComboBox', null)
              obj.QueryConfig = {
                Command: 'ConsultaTipoDocumentalComboBox',
                IsSp: true,
                Entity: {
                  IdTipoDocumental: this.modelo.modelo.IdTipoDocumental
                }
              };
              let info = {
                componentName: this.componentName, controlName: 'ConsultaTipoDocumentalComboBox',
                processMessage: 'Cargando acción respuesta......'
              };

              this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
                this.configIdTipoDocumental.Hidden = true;
                this.configIdTipoDocumental.Disabled = true;
                this.configTipoDocumental.Hidden = false;
                const tiposDoc = [
                  {
                    IdTipoDocumental: data.Data[0].IdTipoDocumental,
                    Nombre: data.Data[0].Nombre
                  }];
                this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);
                this.modelo.modeloVirtual.IdTipoDocumental = data.Data[0].IdTipoDocumental;
                this.modelo.modelo.IdTipoDocumental = data.Data[0].IdTipoDocumental;
                this.SetModelToControls();
              }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });

              /////////////
            }
            else if (event.Option.Name == "SolicitaAnulacion") {
              //this.configObservaciones.Hidden = false;
              this.Oficio.RaiseForceValidateSaveEvent(true, false);
              //this.configObservacionesAnular.Hidden = false;
            }
            else if (event.Option.Name == "SolicitaVistoBueno" || event.Option.Name == "SolicitaAprobacion") {
              //debugger;
              //this.modelo.modelo.IdUsuarioResponsable = null;
              this.configIdTipoDocumental.Required = false;
              this.validateRequiredControls(["IdTipoRespuesta", "IdTipoDocumental"], "false"); //Rechazar
              //this.modelo.modelo.Usuarios_Destino = null;
              const actionTipoDoc = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: []
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["AnexosRequeridos"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: "false",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty]
              };
              const contextTipoDoc = this;
              this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);

              this.Oficio.RaiseForceValidateSaveEvent(true, false);

              if (event.Option.Name == "SolicitaVistoBueno") {
                var InputValue = "";
                var GridName = "GrillaVoBoUsuario";
                var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
                this.utility.GetDataTabledtInstance(GridViewClearSearch.dtElement, GridName, this.componentName).then(dtInstance => {
                  dtInstance.search(InputValue).draw();
                }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })
              }
              else if (event.Option.Name == "SolicitaAprobacion") {
                var InputValue = "";
                var GridName = "GrillaSolicitudAprobacion";
                var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
                this.utility.GetDataTabledtInstance(GridViewClearSearch.dtElement, GridName, this.componentName).then(dtInstance => {
                  dtInstance.search(InputValue).draw();
                }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })

              }

            }
            else if (event.Option.Name == "UrlServicioWeb") {

              this.getPlantillaRadicado(this.modelo.modelo.IdSolicitudRadicadoSalida, this.modelo.modelo.IdTipoSolicitud);

            }
            else { // if (this.Pagina.Titulo == "Radicado de Salida") { //if (event.Option.Label == "Rechazar" && this.Pagina.Titulo == "Radicado de Salida") {
              this.configIdTipoDocumental.Required = false;
              this.Oficio.RaiseForceValidateSaveEvent(true, false);
            }

            this.SetModelToControls();

            break;
          case "SolicitudesAnulaciones":
            //this.modelo.modelo.IdSolicitudRadicadoSalida = this.modelo.modelo.IdSolicitudRadicadoSalida;
            //super.OnActionEventHandler(event);
            this.modelo.modelo["DataAdd"] = {
              IdSolicitudRadicadoSalida: this.modelo.modelo.IdSolicitudRadicadoSalida,
              IdTipoSolicitud: this.modelo.modelo.IdTipoSolicitud
            };
            this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;

            if (event.Option.Name == "Aprobar") {
              this.utility.VerModalConfirmacionGuardar().then(modelo => {
                if (modelo.dialogResult == true) {
                  this.SetModelToControls();
                  this._Guardar(this.form, event.Option)
                }
              });
              return;
            }
            break;
        }

      }
    }
    this.SetModelToControls()

    if (event.Action === FormActionsThroughEmitters.ClearModel)
      if (event.Table == "GrillaSolicitudAprobacion" || event.Table == "GrillaVoBoUsuario") {
        this.modelo.modeloVirtual.IdTipoDocumental = this.modelo.modelo.IdTipoDocumental;
        this.modelo.modelo.IdUsuarioResponsable = null;

        if (event.Table == "GrillaVoBoUsuario") {
          this.modelo.modeloVirtual.ObligatorioVB = null;
        }

        if (event.Table == "GrillaSolicitudAprobacion") {
          this.modelo.modeloVirtual.ObligatorioAprobacion = null;
        }

        this.SetModelToControls();
        return;
      }
    if (event.actionType && event.actionType == "OnLoadedComboBoxExtendableTable") {

      this.errorTipoAnexo = false;
      this.countTipoAnexos = event.data.items.length;
      const actionTipoDoc = {
        ControlDetails: {
          MappingData: [],
          MappingFilter: []
        },
        ControlType: "Action",
        ControlsForHidden: [],
        ControlsToUpdate: ["AnexosRequeridos"],
        Guid: Guid.create().toString(),
        IsPost: false,
        IsParentModel: false,
        MappingValues: [],
        MappingComponents: [],
        Property: "required",
        PropertyValue: "true",
        ResultType: "Url",
        SendQueryString: false,
        TimerAction: 0,
        Type: ControlActions[ControlActions.SetControlProperty]
      };
      const contextTipoDoc = this;
      this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);

      this.modelo.modeloVirtual.AnexosRequeridos = null;
      this.InfoAnexos.Hidden = false;
      this.SetModelToControls();
      if (this.modelo.modeloVirtual.GrillaAnexos) {
        var numeroItems = this.modelo.modeloVirtual.GrillaAnexos.length - 1;
        if (event.data.items.length != numeroItems) {
          if (this.modelo.modeloVirtual.GrillaAnexos.length > 1) {
            for (var prop in this.modelo.modeloVirtual.GrillaAnexos) {
              if (Number(prop) < numeroItems) {
                this.errorTipoAnexo = false;
                if (this.modelo.modeloVirtual.GrillaAnexos[prop].TipoAnexo == null) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexos.splice(this.modelo.modeloVirtual.GrillaAnexos.length - 1);
                  this.utility.VerModalDanger({ "titulo": "Valor Requerido", "descripcion": "El campo Tipo anexo es requerido." });
                  break;
                }
                if (this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad == null || !this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexos.splice(this.modelo.modeloVirtual.GrillaAnexos.length - 1);
                  this.utility.VerModalDanger({ "titulo": "Valor Requerido", "descripcion": "El campo Cantidad tiene que ser requerido o mayor a cero" });
                  break;
                }
              }
            }
          }
          // if (!this.errorTipoAnexo) {

          //     this.modelo.modeloVirtual.GrillaAnexos.forEach(datamodelo => {
          //
          //         event.data.items.forEach(element => {
          //             if (datamodelo.TipoAnexo == element.Nombre) {
          //
          //                 const index = event.data.items.findIndex(x => { return x.Nombre == element.Nombre });
          //                 event.data.items.splice(index, 1);
          //             }
          //         });
          //     });
          // }
          if (this.countTipoAnexos == this.modelo.modeloVirtual.GrillaAnexos.length) {
            //setTimeout(function () {
              if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] != undefined) {
                (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.visibility = 'hidden';
              }
            //}, 1500);
          }
        }

        this.modelo.modeloVirtual.GrillaAnexos.forEach(datamodelo => {

          event.data.items.forEach(element => {
            if (datamodelo.TipoAnexo == element.Nombre) {
              if (!this.errorTipoAnexo)
                datamodelo.Disabled = true;
              const index = event.data.items.findIndex(x => { return x.Nombre == element.Nombre });
              event.data.items.splice(index, 1);
            }
          });
        });
      }

    }
    else if (event.actionType == "EliminarItem") {
      if (event.config.Name == "GrillaAnexos") {
        setTimeout(function () {
          if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] != undefined) {
            (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.visibility = 'visible';
          }
        }, 1500);
      }
      // this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      // this.InfoAnexos.Hidden = true;
      // this.SetModelToControls();

      var requeridoAnexosEliminacion = true
      var requeridoAnexosControl = 1
      this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
        if (!element.TipoAnexo || !element.Cantidad) {
          requeridoAnexosEliminacion = false;
          requeridoAnexosControl = null;
        }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    }
    else if (event.actionType && event.actionType == "OnSelectedComboBoxExtendableTable") {

      // this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
      //     if (event.data.item && event.data.item.Nombre)
      //         if (element.TipoAnexo == event.data.item.Nombre)
      //             if (element.Cantidad || element.Cantidad > 0) {
      //                 this.InfoAnexos.Hidden = true;
      //                 this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      //             }
      //             else {
      //                 this.InfoAnexos.Hidden = false;
      //                 this.modelo.modeloVirtual.AnexosRequeridos = null;
      //             }
      //         else {
      //             this.InfoAnexos.Hidden = false;
      //             this.modelo.modeloVirtual.AnexosRequeridos = null;
      //         }

      //     else {
      //         this.InfoAnexos.Hidden = false;
      //         this.modelo.modeloVirtual.AnexosRequeridos = null;
      //     }
      //     this.SetModelToControls();
      // });
      var requeridoAnexosEliminacion = true
      var requeridoAnexosControl = 1
      this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
        if (event.data.item && event.data.item.Nombre) {
          if (element.TipoAnexo == event.data.item.Nombre) {
            if (!element.Cantidad || element.Cantidad < 0) {
              requeridoAnexosEliminacion = false
              requeridoAnexosControl = null
            }
          }
        }
        else {
          requeridoAnexosEliminacion = false
          requeridoAnexosControl = null
        }
        // else {
        //   requeridoAnexosEliminacion = false
        //   requeridoAnexosControl = null
        // }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    }
    else if (event.actionType && event.actionType == "OnChangeNumberBoxExtendableTable") {

      if (event.data.valueMetodo && event.data.valueMetodo.TipoAnexo && event.data.valueMetodo.Cantidad && event.data.valueMetodo.Cantidad > 0) {
        this.InfoAnexos.Hidden = true;
        this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      }
      else {
        this.InfoAnexos.Hidden = false;
        this.modelo.modeloVirtual.AnexosRequeridos = null;
      }
      this.SetModelToControls();
    }
    if (this.name != "SolicitudesAnulaciones") {
      validateContact(this, undefined, undefined, ["Genero"], this.modelo.selectedItem);
    }
    super.OnActionEventHandler(event);
  }

  VerBuscarContacto() {

    this.openModalBusquedaContacto = true;
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[0],
      limpiarInternamente: true
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Búsqueda de Contactos";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility.OpenWindow(componentName,
      config, modeloTemp).then(dataResult => {


        dataResult.componentInstance.parentContext = this;

        dataResult.componentInstance.postEditEmitter.subscribe(result => {
          //debugger;
          this.SetDatosPorTipoContacto(dataResult.componentInstance.modelo.IdTipoContacto);
          this.modelo.modelo.IdContacto = dataResult.componentInstance.modelo.IdContacto;

          document.getElementById("MsgBarContacto").style.display = "none";
          this.modelo.modelo.PrimerNombre = dataResult.componentInstance.modelo.PrimerNombre;
          this.modelo.modelo.SegundoNombre = dataResult.componentInstance.modelo.SegundoNombre;
          this.modelo.modelo.PrimerApellido = dataResult.componentInstance.modelo.PrimerApellido;
          this.modelo.modelo.SegundoApellido = dataResult.componentInstance.modelo.SegundoApellido;
          this.modelo.modelo.RazonSocial = dataResult.componentInstance.modelo.RazonSocial;
          this.modelo.modelo.Cargo = dataResult.componentInstance.modelo.Cargo;
          this.modelo.modelo.CodigoPostal = dataResult.componentInstance.modelo.CodigoPostal;
          this.modelo.modelo.Genero = dataResult.componentInstance.modelo.Genero;
          this.modelo.modelo.Celular = dataResult.componentInstance.modelo.Celular;
          this.modelo.modelo.Telefono = dataResult.componentInstance.modelo.Telefono;
          this.modelo.modelo.Ext = dataResult.componentInstance.modelo.Ext;
          this.modelo.modelo.Direccion = dataResult.componentInstance.modelo.Direccion;
          this.modelo.modelo.Correo = dataResult.componentInstance.modelo.Correo;
          this.modelo.modelo.IdCiudad = dataResult.componentInstance.modelo.IdCiudad;
          this.modelo.modelo.IdPais = dataResult.componentInstance.modelo.IdPais;
          this.modelo.modelo.IdDepartamento = dataResult.componentInstance.modelo.IdDepartamento;
          this.modelo.modelo.IdTipoIdentificacion = dataResult.componentInstance.modelo.IdTipoIdentificacion;
          this.modelo.modelo.Identificacion = dataResult.componentInstance.modelo.Identificacion;
          //debugger;
          validateContact(
            this,
            undefined,
            undefined,
            ["Genero"],
            dataResult.componentInstance.modelo
          );
          if(this.solicitudEntrada && this.solicitudEntrada.IdSolicitudPqrd && this.esTraslado && this.idContactoInicial){
            if(this.idContactoInicial == this.modelo.modelo.IdContacto){
              this.modelo.modelo.IdContacto = null;
              this.utility.VerModalWarning(`No puede selccionar el mismo usuario, para traslado de PQRS`).then(() => true);
            }
          }
          this.SetModelToControls();

        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
          if (result.Action === FormActionsThroughEmitters.ClearModel) {

            this.modelo.modelo.IdContacto = null;
            document.getElementById("MsgBarContacto").style.display = "block";
            this.modelo.modelo.PrimerNombre = null;
            this.modelo.modelo.SegundoNombre = null;
            this.modelo.modelo.PrimerApellido = null;
            this.modelo.modelo.SegundoApellido = null;
            this.modelo.modelo.RazonSocial = null;
            this.modelo.modelo.Genero = null;
            this.modelo.modelo.Cargo = null;
            this.modelo.modelo.CodigoPostal = null;
            this.modelo.modelo.Celular = null;
            this.modelo.modelo.Telefono = null;
            this.modelo.modelo.Ext = null;
            this.modelo.modelo.Direccion = null;
            this.modelo.modelo.Correo = null;
            this.modelo.modelo.IdCiudad = null;

            this.modelo.modelo["DataAdd"]["OperacionSolicitud"] = null;
            this.SetModelToControls();
          }
        });


        dataResult.componentInstance.loadedGridEmitter.subscribe(result => {


        });

        dataResult.onClosePromise.then(dataResultOnClose => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
          this.openModalBusquedaContacto = false;
        });
      });

  }

  public AddComponentInstanceHandler(event) {

    if (event.name == "SidePanelSalida") {

      if (this.abrirPanel)
        event.OpenSidePanel();

      const tiposDoc = [
        {
          IdTipoDocumental: this.IdTipoDocumental,
          Nombre: this.NombreTipoDocumental
        }];
      this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);
      if (this.modelo.modelo.IdSolicitudEntrada) {
        //this.configArchivosAdjuntos.Required = true;
        this.configIdTipoDocumental.Required = true;
        this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
      }
      this.modelo.modelo.IdCiudad = this.IdCiudad;
      this.modelo.modelo.IdPais = this.IdPais;
      this.modelo.modelo.IdDepartamento = this.IdDepartamento;
      this.modelo.modelo.IdTipoIdentificacion = this.IdTipoIdentificacion;
      this.modelo.modelo.IdTipoDocumental = this.IdTipoDocumental;
      this.modelo.modelo.IdTipoRespuesta = this.IdTipoRespuesta;
      this.modelo.modeloVirtual.IdTipoDocumental = this.IdTipoDocumental;

      if (document.getElementById("MsgBarExpediente")) {
        document.getElementById("MsgBarExpediente").style.display = "none";
      }
      if (document.getElementById("MsgBarContacto")) {
        document.getElementById("MsgBarContacto").style.display = "none";
      }
      this.SetModelToControls();
    }
    if (event instanceof DataTableComponent) {

      if (event.name == "GrillaVoBoUsuario" || event.name == "GrillaSolicitudAprobacion") {
        event.parentContext = this;
      }
      this.componentsContainer.Tables.push(event);
    }
    else if (event instanceof FileUploaderComponent) {

      if (event.name == "ArchivosAdjuntos")
        this.Oficio = event;
      else
        this.FileBoxes.push(event);
    }
    super.AddComponentInstanceHandler(event);

  }


  public OnDataTableEditHandler(event) {
    //debugger;
    this.modelo.modeloVirtual.UsuarioAutenticado = StaticData.Usuario.IdUsuario;
    if (this.modelo.modelo.IdEstadoSolicitud == 5) {
      if (this.ConfigGridRadicadosAsociados) {
        if (this.ConfigGridRadicadosAsociados.DeleteOption) {
          this.ConfigGridRadicadosAsociados.DeleteOption = false;
          this.BtnPqrdAsociada.Hidden = true;
        }
      }
    } else {
      if (this.ConfigGridRadicadosAsociados) {
        if (this.ConfigGridRadicadosAsociados.DeleteOption) {
          this.ConfigGridRadicadosAsociados.DeleteOption = true;
          this.BtnPqrdAsociada.Hidden = true;
        }
      }
    }
    // switch (event.name) {
    // }
    this.modelo.modeloVirtual["DestinatariosCopias"] = [];
    this.modelo.modeloVirtual["DestinatariosUsuarios"] = [];

    if (event.name == "GridViewRadicadosAsignados") {

      this.IdTipoSolicitudEntradaPadre = event.data.data.findItem.IdTipoSolicitudEntradaPadre;
      if (this.IdTipoSolicitudEntradaPadre == 5) {
        this.configIdTipoRespuestaPqrs.Hidden = false;
      }
      else {
        this.configIdTipoRespuestaPqrs.Hidden = true;
      }
      this.SetDatosPorTipoContacto(event.data.data.findItem.IdTipoContacto);
      this.SetModelToControls();
    }

    if (this.IsEdit || event.name == "GrillaSolicitudAprobacion") {
      //debugger;
      if (event.name == "GrillaVoBoUsuario") {
        this.modelo.modeloVirtual.ObligatorioVB = 1;
      }

      if (event.name == "GrillaSolicitudAprobacion") {
        this.modelo.modeloVirtual.ObligatorioAprobacion = 1;
      }

      if (event.name == "GridViewRadicadosAsignados") {
        const findItemClone = this.utility.Clone(event.data.data.findItem);
        this.modelo.selectedItem = findItemClone;
      }

      if (this.modelo.modelo.Copias_Destinos && this.modelo.modelo.Copias_Destinos != "[]") {
        this.modelo.modeloVirtual.DestinatariosCopias = JSON.parse(event.modelo.modelo.Copias_Destinos);
      }

      if (this.modelo.modelo.AnexosEdit && this.modelo.modelo.AnexosEdit != "[]") {
        this.modelo.modeloVirtual.GrillaAnexos = JSON.parse(event.modelo.modelo.AnexosEdit);
        this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
          element.Disabled = true;
          element.DisabledDelete = true;
        });
      }

      if (this.modelo.modelo.Usuarios_Destino && this.modelo.modelo.Usuarios_Destino != "[]") {
        this.modelo.modeloVirtual.DestinatariosUsuarios = JSON.parse(event.modelo.modelo.Usuarios_Destino);
      }

      this.configArchivosAdjuntos.Required = false;
      // this.configArchivosAdjuntos.Activo = false;
      this.modelo.modeloVirtual.IdTipoDocumental = this.modelo.modelo.IdTipoDocumental;

    }
    else {
      this.modelo.modeloVirtual["DestinatariosCopias"] = [];
      this.modelo.modeloVirtual["GrillaAnexos"] = [];
      this.modelo.modeloVirtual["DestinatariosUsuarios"] = [];
      this.configArchivosAdjuntos.Required = true;
    }

    if (this.modelo.modelo.IdEstadoSolicitud == EstadosSolicitud.Finalizada) {
      this.verContactoButton.Hidden = true;
      this.descargarPlanilla.Hidden = true;
      this.verExpedienteButton.Hidden = true;
      this.modelo.modeloVirtual.DestinatariosCopiasEdit = this.modelo.modeloVirtual.DestinatariosCopias;
      this.modelo.modeloVirtual.DestinatariosUsuariosEdit = this.modelo.modeloVirtual.DestinatariosUsuarios;
      // this.configDestinatariCopias.IsExtendableTable = false;
    }
    else {
      this.verContactoButton.Hidden = false;
      //this.descargarPlanilla.Hidden = false;
      this.verExpedienteButton.Hidden = false;
      //this.modelo.modeloVirtual.DestinatariosCopiasEdit = this.modelo.modeloVirtual.DestinatariosCopias;
      // this.configDestinatariCopias.IsExtendableTable = false;
      // this.configArchivosAdjuntos.Required = true;

    }




    this.SetModelToControls();

    super.OnDataTableEditHandler(event);
  }

  ngAfterViewInit() {

    this.ConfigEntregaPersonal = this.form.controls["EntregaPersonal"];
    this.ConfigObservacionEntregaPersonal = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == "ObservacionEntregaPersonal";
      });

    if (this.ConfigEntregaPersonal) {
      this.ConfigEntregaPersonal.valueChanges.subscribe((value: any) => {

        if (this.textCommandBar == "Detalle"
        && this.modelo.modelo.IdTipoRespuesta == 34
        && this.modelo.modelo.IdEstadoSolicitud == 8) {
          if (value != "" || value == false) { //null
            if (value == true) {

              this.ConfigEntregaPersonal.Hidden = false;
              this.ConfigObservacionEntregaPersonal.Hidden = false;
              //this.modelo.modelo.ObservacionEntregaPersonal = ""; //""

              const context = this;
              const actionTrue = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: [],
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["ObservacionEntregaPersonal"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: "true",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty],
              };
              this.helperActions.SetControlsValue(actionTrue, context);
              this.SetModelToControls();
              $("input[id='EntregaPersonal']").prop("checked", true);
            }
            else if(value == false && value != "") {

              this.ConfigEntregaPersonal.Hidden = false;
              this.ConfigObservacionEntregaPersonal.Hidden = true;

              //this.modelo.modelo.ObservacionEntregaPersonal = ""; //"";
              const context = this;
              const actionFalse = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: [],
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["ObservacionEntregaPersonal"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: "false",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty],
              };
              this.helperActions.SetControlsValue(actionFalse, context);
              this.SetModelToControls();
              $("input[id='EntregaPersonal']").prop("checked", false);
            }
          }
        }else{
          this.ConfigEntregaPersonal.Hidden = true;
          this.ConfigObservacionEntregaPersonal.Hidden = true;
        }
      });
    }
    super.ngAfterViewInit();
  }

  public GuardarAuditoriaEntregaPersonal() {
    debugger;
    var description = this.modelo.modelo.ObservacionEntregaPersonal;
    var spName = "CrearEstadosRadicadoSalida";

    this.componentName = "TSolicitudRadicadoSalida"
    let obj = this.utility.GetApiModel(spName, null)
    obj.QueryConfig.Command = spName;
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdSolicitudRadicadoSalida: this.modelo.modelo.IdSolicitudRadicadoSalida,
      IdEstadoSolicitud: 37,
      Observaciones: description,
      IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
      MaquinaCreacion: -1

    };
    let info = {
      componentName: this.componentName, controlName: spName,
      processMessage: 'Registro Entrega Personal...'
    };
    this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
    }).catch(err => { }).finally();

  }

  getPlantillaRadicado(idSolcicitud : number, tipoSolicitud: number) {

    var serviceModel : any;
    var arrayservice = {
      "token": StaticData.Usuario.Token,
      "AppKey": StaticData.AppKey,
      "ExecutionObject": {
        "Name": "Documentos",
        "WebServiceMethod": {
          "Name": "GetPlantillaSolicitud",
          "Parameters": {
            "Codigo": idSolcicitud,
            "TipoSolicitud": tipoSolicitud
          }
        }
      }
    };

    let info = {
      componentName: this.componentName, controlName: 'ConsultaAccionTipoRespuestaPqrs',
      processMessage: 'Cargando acción respuesta......'
    };
    let obj = this.utility.GetApiModel("GetPlantillaSolicitud", this.componentName, null, null, this.modelo.modelo);
    this.baseService.DescargarTemplateBase64(arrayservice, null,
      { componentName: 'SolicitudRadicadoSalidaComponent', method: 'GetPlantillaSolicitud', processMessage: 'Procesando Plantilla....' },
      'TSolicitudRadicadoSalida', false, true).then(data => {

        serviceModel = data.Data[0].Archivo[0];

        let message = {
          IdAreaEmpresa: StaticData.Usuario.IdAreaEmpresa,
          IdRadicado: this.modelo.modelo.IdSolicitudRadicadoSalida,
          Nombre: serviceModel.Archivo,
          Codigo: this.modelo.modelo.Codigo,
          TipoSolicitud: this.modelo.modelo.IdTipoSolicitud,
          TipoRadicado: 'Salida',
          Token: StaticData.Usuario.Token,
          Appkey: StaticData.AppKey,
          IdUsuario:  StaticData.Usuario.IdUsuario,
          UsuarioAutenticado: StaticData.Usuario.Email,
          Base64: serviceModel.DatosArchivo,
          ConfiguracionWebsocket: {
            RutaArchivosTemporales:"C:\\Temp\\",
            RutaArchivosLogs:"C:\\Temp\\Logs\\",
            ExtensionArchivosWord:".docx",
            NombreDocumentoTemporalWord:"DocumentoTemporalBestDoc",
            UrlServer: StaticData.UrlServer,
            WebSocketUrl:"ws://0.0.0.0:8282"

          }
        };

        this.sent.push(message);
        this.DocumentTemplateSocketService.messages.next(message);

      }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });

  }

  _Guardar(form: FormGroup, configButtons?: any) {
    debugger;
	if(this.modelo.modelo.ObservacionEntregaPersonal){
      this.modelo.modelo.EntregaPersonal = this.modelo.modelo.ObservacionEntregaPersonal != "" && this.modelo.modelo.ObservacionEntregaPersonal != null && this.modelo.modelo.IdTipoRespuesta == 34 && this.modelo.modelo.IdEstadoSolicitud == 8 ? true : false;
      if(this.modelo.modelo.EntregaPersonal == true){
        this.GuardarAuditoriaEntregaPersonal();
      }
    }

    if (this.modelo.modelo.ObservacionesAutorizar) {

      if (this.modelo.modelo.ObservacionesAutorizar != "" && this.modelo.modelo.ObservacionesAutorizar != null) {
        this.modelo.modelo.Observaciones = this.modelo.modelo.ObservacionesAutorizar;
      }
    }
    this.modelo.modelo.Anexos = JSON.stringify(this.modelo.modeloVirtual.GrillaAnexos);
    this.modelo.modelo.DestinatariosCopias = JSON.stringify(this.modelo.modeloVirtual.DestinatariosCopias);
    this.modelo.modelo.DestinatariosUsuarios = JSON.stringify(this.modelo.modeloVirtual.DestinatariosUsuarios);

    let obj: any = null;
    try {
      if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
        return;
      if (form.invalid)
        return;


      const _isEdit = this.IsEdit;
      // const that = this;
      const processMessage = (this.modelo.modelo.PkValue == "0" || this.modelo.modelo.PkValue == "") ? "Creando Registro de " + this.Pagina.Titulo : "Actualizando registro de " + this.Pagina.Titulo;

      if (configButtons && configButtons.Actions) {

        this.helperActions.ExecuteActionsControl(configButtons,
          false, null, this.modelo, this.permissionsConfig,
          this, null, new EventEmitter<any>(), this.componentsContainer,
          null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
      }

      this.ExcecuteFuntion(ControllerMethods.Save, false);


      if (_isEdit) {
        this.FireFormEvent({ Name: "OnPreUpdate", modelo: this.modelo });
        // this.modelo.modelo.DestinatariosCopias = this.modelo.modelo.Copias_Destinos;
        // this.modelo.modelo.DestinatariosUsuarios = this.modelo.modelo.Usuarios_Destino;
        //Ver Detalle - Actualizar info - IdUsuarioResponsable null
        this.modelo.modelo.IdUsuarioResponsable =
          this.modelo.modelo.IdUsuarioResponsable ||
          StaticData.Usuario.IdUsuario;
      }
      else {
        this.FireFormEvent({ Name: "OnPreInsert", modelo: this.modelo });
        this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
      }


      if (this.modelo.modeloMetadata.Items.Count() > 0) {
        this.modelo.modeloMetadata.IdParentObject = this.modelo.modelo.PkValue;
        this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
        this.modelo.modelo.Metadata = this.utility.Clone(this.modelo.modeloMetadata);
        this.modelo.modelo.Metadata.Items = this.modelo.modelo.Metadata.Items.ToArray();
      }
      //this.modelo.modeloMetadata.Id = this.modelo.modelo.PkValue;
      // this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
      // this.modelo.modelo.Metadata = this.modelo.modeloMetadata;
      //debugger;
      var Asociadas = null
      if (this.solicitudEntrada) {
        if (this.solicitudEntrada.AsociacionMultiple)
          Asociadas = this.solicitudEntrada.AsociacionMultiple;
      }
      this.modelo.modelo["DataAdd"] = {
        TipofirmaEnvio: this.TipofirmaEnvio,
        Pin: this.modelo.modeloVirtual.Pin,
        IdTipoIdentificacion: 1,
        GuidServicioFirma: this.modelo.modeloVirtual.GuidServicioFirma,
        GuidServicioMail: this.modelo.modeloVirtual.GuidServicioMail,
        GiudPlantilla: this.modelo.modeloVirtual.GiudPlantilla,
        GuidPlantillaFinal: this.modelo.modeloVirtual.GuidPlantillaFinal,
        OperacionSolicitud: this.tipoOperacion,
        Proyector: this.modelo.modelo.NombreProyector,
        IdSolicitudRadicadoSalida: this.modelo.modelo.IdSolicitudRadicadoSalida,
        TextoComentario: this.modelo.modeloVirtual.ComentarioRadicado,
        GuardaComentario: "false",
        IdTipoSolicitudEntradaPadre: this.IdTipoSolicitudEntradaPadre,
        AsociacionMultipleOriginal: Asociadas,//this.solicitudEntrada.AsociacionMultiple, //Requerimiento Asociación Radicados
        AsociacionMultipleNuevo: JSON.stringify(this.modelo.modeloVirtual.GridRadicadosAsociados), //Requerimiento Asociación Radicados
        GuidTemplatePlantilla: this.modelo.modeloVirtual.GuidTemplatePlantilla //Guid para obtener la plantilla(template) del radicado
      };

      if (this.modelo.modelo.Observaciones == undefined) {
        this.modelo.modelo.Observaciones = this.modelo.modelo.ObservacionesAprobar;
      }
      if (this.modelo.modelo.ObservacionesAnular != "" && this.modelo.modelo.ObservacionesAnular != null) {
        this.modelo.modelo.Observaciones = this.modelo.modelo.ObservacionesAnular;
      }

      obj = this.utility.GetApiModel("Guardar", this.componentName, null, null, this.modelo.modelo);

      let isGeneralSave = true;

      if (this.componentsContainer.FileBox && this.componentsContainer.FileBox.isSelectedFiles()) {

        isGeneralSave = false;
        let externalFiles: any;
        if (this.componentsContainer.FileBoxWorkFlow != null) {
          externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
        }
        this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
      }


      // ////////Meter validacion antes de este
      if (isGeneralSave) {

        this.baseService.InsertUpdate(obj, form, {
          componentName: this.componentName + "Controller",
          method: "Guardar", processMessage: processMessage
        },
          false, this.componentName, false, true).then(data => {
            //this.modelo.modelo = data.Data != null ? data.Data : this.modelo.modelo;

            this.FinGuardar(data.Data);

            if (_isEdit)
              this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
            else
              this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });

            this.utility.VerModalOk(`Proceso realizado correctamente para el Radicado de salida con código: ${data.Data.Codigo}`).then(() => true);
            this.solicitudEntrada = null;

            this.OnInitEnd();
          }).catch(error => { this.SetProcesando(false); });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: "_Guardar" });
      this.utility.VerModalDanger().then(data => { });
      this.SetProcesando(false);
    }
  }
}
