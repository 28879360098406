import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { ConfigBaseTreeComponent, TiposCrearNodos } from '../../models/config-base-tree-component';
import { Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, QueryConfigIDETypes } from '../../models/general.enum';
import { Utilities } from '../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../assets/linqts/compilado/index';
import { StaticData } from '../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config, Subject } from 'rxjs';
import { ConfigWindow } from '../../models/config-window';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Guid } from 'guid-typescript';






@Component({
  selector: 'app-jstree',
  templateUrl: './jstree.component.html',
  styleUrls: ['./jstree.component.css']
})
export class JstreeComponent extends ConfigBaseTreeComponent implements OnInit, AfterViewInit {

  @Output() onInitialized = new EventEmitter<any>();
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;
  @Output() onContexMenuAction = new EventEmitter<any>(true);

  _config: any;
  @Input() set config(value: any) {
    this._config = value;
    this.OnSetConfig();
  }
  get config(): any {
    return this._config;
  }


  @Input() withSearch: boolean = false;
  searchData: string;

  @Input() plugins: string[] = [];

  @Output() ViewPageEventEmitter = new EventEmitter<any>();


  ngOnInit() {

    this.OnSetConfig();
    if (this.parentClearCall)
      this.parentClearCall.subscribe(event => {

        setTimeout(() => {

        }, 100);

      });

    if (this.onInitialized.observers.length) {
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }


  }

  public OnSetConfig() {


    if (this.config) {



      this.name = (this.config.Name) ? this.config.Name : this.name;
      this.componentName = (this.config.ComponentName) ? this.config.ComponentName : this.componentName;
      this.queryConfigIDEType = (this.config.QueryConfigIDEType) ? this.config.QueryConfigIDEType : this.queryConfigIDEType;
      this.autoInit = (this.config.AutoInit !== null) ? this.config.AutoInit : this.autoInit;
      this.withSearch = (this.config.WithSearch != null) ? this.config.WithSearch : this.withSearch;
      this.plugins = (this.config.Plugins != null) ? this.config.Plugins : this.plugins;
      this.expandAllMode = (this.config.ExpandAllMode != null) ? this.config.ExpandAllMode : this.expandAllMode;
      this.activo = (this.config.Activo != null) ? this.config.Activo : this.activo;
      this.initTime = (this.config.InitTime != null) ? this.config.InitTime : this.initTime;
      this.jsonData = (this.config.JsonData != null) ? this.config.JsonData : this.jsonData;
      let _config = {
        "ComponentName": "",
        "Name": "",
        "QueryConfigIDEType": -1,
        "AutoInit": false,
        "WithSearch": false,
        "Plugins": null,
        "ExpandAllMode": false,
        "ConfigControl": {}
      };


    }

    if (!this.name)
      this.name = this.componentName;
  }
  ngAfterViewInit() {


    this.LoadData();
  }

  public GeneralLoad() {

    const that = this;

    this.GetNodosFromParentOption().then(node => {


      this.jsonData = [node];
      this.InitTree();
    });


  }
  public LoadDocumentosExpediente() {

    const that = this;

    this.GetNodosFromParentOption().then(node => {


      this.jsonData = [node];
      this.InitTree();
    });


  }
  public PreCargarDatos() {
    this.InitTree();
  }
  public PostCargarDatos() {

    if (this.plugins.length == 0) {
      this.plugins.push('contextmenu');
      this.plugins.push('search');
    }
    if (this.selectionMode)
      this.plugins.push('checkbox');
  }

  ValidatePlugins(){
    if (this.plugins.length == 0) {
      this.plugins.push('contextmenu');
      this.plugins.push('search');
    }
  }
  public PostGetJsonTreeData() {

  }

  public SetSelectedNodes() {
    const that = this;

    if (this.selectionMode) {
      setTimeout(() => {
        $.each(this.selectedNodes.ToArray(), function (i, node: any) {
          (<any>$('#' + that.name)).jstree(true).select_node(node.node.id);
        });
        (<any>$('#' + that.name)).jstree("open_all");
      }, 200);

    }
  }

  public ExpandAll() {
    const that = this;

    if (this.expandAllMode) {
      setTimeout(() => {
        (<any>$('#' + that.name)).jstree("open_all");
      }, 1500);
    }
  }
  public GetSelectedNodes(): any {

    const that = this;
    let checkedNodes: any = [];
    var get_checked = (<any>$('#' + that.name)).jstree('get_checked');
    $.each(get_checked, function (i, id) {

      var checkedNode = (<any>$('#' + that.name)).jstree('get_node', id);//$(tree.Html).jstree(true).get_node("[id='" + id + "']");
      checkedNodes.push(checkedNode);
    });
    return checkedNodes;
  }
  public DoubleClickTree(node: any) {

    const that = this;


    if (node.data && node.data.ObjType === 'Expediente') {
      let modeloExpedinte = {};
      this.GetNodosDocumentosExpedinte(node.data, node).then(nodes => {

        $.each(nodes, function (i, _node) {

          that.AddNode(node.id, _node);

        });

      });
      //this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[3]) });
    }


  }
  public ChangedTree(e: any, data: any) {

    if (this.treeClickChange.observers.length > 0) {
      let parentNode: any = this.GetFindNode(data.node.parent);
      let parentParentNode: any = null;
      if (parentNode.parent)
        parentParentNode = this.GetFindNode(parentNode.parent);
      var _data = { e: e, data: data, isSelectNode: (data && data.action == "select_node") ? true : false };
      if ((data && data.action == "select_node")) {
        if (data.node.data && data.node.data.ObjType === 'Roles') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[0]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }
        else if (data.node.data && data.node.data.ObjType === 'AreasEmpresa') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[3]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }
        else if (data.node.data && data.node.data.ObjType === 'Series') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[4]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }
        else if (data.node.data && data.node.data.ObjType === 'SubSeries') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[5]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }
        else if (data.node.data && data.node.data.ObjType === 'Grupos') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[9]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }
        else if (data.node.data && data.node.data.ObjType === 'Expedientes') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[10]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }
        else if (data.node.data && data.node.data.ObjType === 'TiposSolicitud') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[11]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }
        else if (data.node.data && data.node.data.ObjType === 'Carpetas') {
          this.treeClickChange.emit({
            data: data, extraData: this.utility.Clone(this.jsonData[0].children[12]),
            parentNode: parentNode, parentParentNode: parentParentNode
          });
        }

        else
          this.treeClickChange.emit({ data: data, extraData: null, parentNode: parentNode, parentParentNode: parentParentNode });
      }

    }


    if (this.selectionMode) {
      let nodes = this.GetSelectedNodes();


      for (var i = this.selectedNodes.Count(); i--;) {
        this.selectedNodes.RemoveAt(i);
      }
      this.selectedNodes.AddRange(new List<any>(nodes).Select(x => { return { node: { data: x.data, id: x.id, text: x.text } } }).ToArray());

      if (this.selectedNodeEventEmitter.observers.length > 0)
        this.selectedNodeEventEmitter.emit({ node: data.node, selectedNodes: this.selectedNodes });
    }
  }
  workFlow:{};// WorkFlow;
  public LoadData() {

 
    if (this.queryConfigIDEType == QueryConfigIDETypes.AdminIDE)
      this.CargarDatos(this.queryConfigIDEType);
    else if (this.queryConfigIDEType == QueryConfigIDETypes.Formularios)
      this.CargarDatos(this.queryConfigIDEType, null, false, true);
    else if (this.queryConfigIDEType == QueryConfigIDETypes.Reglas)
      this.CargarDatos(this.queryConfigIDEType, null, false, true);
    else if (this.queryConfigIDEType == QueryConfigIDETypes.ConfiguracionGeneral)
      this.CargarDatos(this.queryConfigIDEType, null, false, true);
    else if (this.queryConfigIDEType == QueryConfigIDETypes.DocumentosExpediete) {
      this.LoadDocumentosExpediente();
    }
    else if (this.queryConfigIDEType == QueryConfigIDETypes.Ninguno && this.parentOption) {
      this.GeneralLoad();
    }
    else if (this.workFlow) {
     // this.CargarWorkFlow(this.workFlow);
    }
    else if (this.autoInit) {

      this.PostCargarDatos();
      this.InitTree();
      //  setTimeout(() => {
      //   this.PostCargarDatos();
      //   this.InitTree();
      // }, 1000);

    }
  }
  public RefreshCurrentData(notFire?: any) {
    this.jsonData = this.GetJsonTreeData(notFire);
    this.InitTree();
  }
  public InitTree() {

    const that = this;

    if (this.initTime > 0) {
      setTimeout(() => {
        const that = this;
        that.InternalInitTree(that);
      }, this.initTime);
    }
    else
      that.InternalInitTree(that);

  }
  Buscar(event) {
    console.log("You entered: ", event.target.value);
    (<any>$('#' + this.name)).jstree('search', event.target.value);
  }

  public InternalInitTree(that: JstreeComponent) {

    this.ValidatePlugins();

    if (!this.name)
      this.name = Guid.create().toString();
    try {

      (<any>$('#' + this.name)).jstree("destroy");
    }
    catch (error) {
    }

    (<any>$('#' + that.name)
      .on('changed.jstree', (e: any, data: any) => {

        that.currentNode = data.selected;

        that.ChangedTree(e, data);

      }))
      .jstree({

        core: {
          data: that.jsonData,

          check_callback: true
        },
        "checkbox": {
          "keep_selected_style": false
        },
        plugins: this.plugins, contextmenu: { items: this.contextMenuItems, context: that },
        "search": {
          "case_sensitive": false,
          "show_only_matches": true
        }

      }).on("dblclick.jstree", (e: any, data: any) => {


        var instance = (<any>$('#' + that.name)).jstree(true);
        //var instance = (<any>$.jstree).reference(this);
        var node = instance.get_node(e.target);


        that.DoubleClickTree(node);
      }).bind("move_node.jstree", (e: any, data: any) => {
        //["dnd", "contextmenu"]
        //
        /*
        requires crrm plugin
    
        .o - the node being moved
        .r - the reference node in the move
        .ot - the origin tree instance
        .rt - the reference tree instance
        .p - the position to move to (may be a string - "last", "first", etc)
        .cp - the calculated position to move to (always a number)
        .np - the new parent
        .oc - the original node (if there was a copy)
        .cy - boolen indicating if the move was a copy
        .cr - same as np, but if a root node is created this is -1
        .op - the former parent
        .or - the node that was previously in the position of the moved node */

        var nodeType = $(data.rslt.o).attr("rel");
        var parentType = $(data.rslt.np).attr("rel");

        if (nodeType && parentType) {
          //
          // TODO!
        }

        if (that.moveNodeChange) {
          var _data = { e: e, data: data, nodeType: nodeType, parentType: parentType };
          that.moveNodeChange.emit(_data);
        }
        //else
        //  console.log(data);
      });


    this.SetSelectedNodes();
    this.ExpandAll();
  }

  public ViewPageAction(tipo: string, node: any, context?: any, queryConfigIDEType?: QueryConfigIDETypes) {

    //let pkValue = node = node.UniqueID;
    let result;
    const that = (context) ? context : this;
    let _queryConfigIDEType: QueryConfigIDETypes = (queryConfigIDEType) ? queryConfigIDEType : that.queryConfigIDEType
    switch (tipo) {
      case 'VerDiseno':
        that.ViewPageEventEmitter.emit(node.data);
        // setTimeout(() => {
        //     that.ViewPageEventEmitter.emit(node.data);
        // }, 5);
        break;


    }

  }
  public SetcontextMenuItems() {

    if (!this.contextMenuItems && this.queryConfigIDEType == QueryConfigIDETypes.AdminIDE)
      this.contextMenuItems = this.GetCustomMenu;
    else if (!this.contextMenuItems && this.queryConfigIDEType == QueryConfigIDETypes.ConfiguracionGeneral) {
      this.contextMenuItems = this.GetMenusConfiguracion;
    }
    else if (this.queryConfigIDEType == QueryConfigIDETypes.Formularios) {
     // this.contextMenuItems = this.GetMenusFormularios;
    }
  }



  public GetMenusConfiguracion(node: any, context?: any): any {

  }

  public GetBasicMenus(node: any, context?: any): any {
 
    const that = (context) ? context : this;

    // that.AccionBasicaMenu('Editar', node)
    //return;
    let items;
    items = {
      save: { // The "rename" menu item
        label: "Guardar",
        icon: that.utility.GetPathImages('save.png', context),
        action: function () {
          that.AccionBasicaMenu('Guardar', node, context)
        }
      },
      edit: { // The "rename" menu item
        label: "Editar",
        icon: that.utility.GetPathImages('edit.png'),
        action: function (event) {
       
          // event.eventEmitter.emit(node);
          that.AccionBasicaMenu('Editar', node, context)
        }
      },
      search: { // The "rename" menu item
        label: "Buscar",
        icon: that.utility.GetPathImages('search.png'),
        action: function () {
          that.AccionBasicaMenu('Buscar', node)
        }
      },
      delete: { // The "rename" menu item
        label: "Eliminar",
        icon: that.utility.GetPathImages('delete.png'),
        action: function () {
          that.AccionBasicaMenu('Eliminar', node)
        }
      },
    }
    return items;
  }

  public GetMenuAdministracion(node: any, context?: any): any {
 
    const that = (context) ? context : this;

    // that.AccionBasicaMenu('Editar', node)
    //return;
    let items;
    items = {
      verVentana: { // The "rename" menu item
        label: "Ver Administracion",
        icon: that.utility.GetPathImages('admin_page.png', context),
        action: function () {
          that.AccionBasicaMenu('VerAdministracion', node)
        }
      }
    }
    return items;
  }
  public GetCustomMenu(node: any, context?: any): any {
    var items: any;
    const that = (context) ? context : this;
    if (that.EsTipoSegundoNivel(node)) {
      items = that.GetBasicMenus(node);

      if (node && node.data && node.data.ObjType == 'Formulario') {
        items['designer'] = { // The "rename" menu item
          label: "Ver en Disenador",
          icon: that.utility.GetPathImages('view-designer.png'),
          action: function () {
            that.ViewPageAction('VerDiseno', node)
          }
        }
      }

    }
    else if (that.EsTipoPrimerNivel(node))
      items = that.GetMenuAdministracion(node);

    return items;
  }


  public AddNode(parentId: any, newNode: any, position: string = "last", expandParent: boolean = true) {
    //var position = 'inside';
    //newNode.state = 'open';
    //"first"
    (<any>$('#' + this.name)).jstree().create_node('#' + parentId, newNode, position, function () {

    });

    if (expandParent == true) {
      (<any>$('#' + this.name)).jstree("open_node", $("#" + parentId));
      // let patenTnode = this.GetFindNode(parentId);
      //(<any>$('#' + this.name)).toggle_node(patenTnode);
    }
  }
  public DeleteNode(id: any) {


    let node = this.GetFindNode(id);
    ///_anchor

    /*     (<any>$('#' + this.name)).jstree("remove",  $("#"+ id));
       (<any>$('#' + this.name)).jstree("remove", "#" + id);
       (<any>$('#' + this.name)).jstree("remove", "#" + id+"_anchor"); */
    (<any>$('#' + this.name)).jstree("delete_node", node);
  }

  public GetFindNode(id: any) {
    return (<any>$('#' + this.name)).jstree('get_node', id);
  }
  public SetNodeData(id: any, data: any) {

    let node: any = this.GetFindNode(id);
    if (node.data.Data)
      node.data.Data = data;
    else
      node.data = data;
  }
  public SetNodeText(id: any, newText: string) {

    let node: any = this.GetFindNode(id);
    (<any>$('#' + this.name)).jstree('rename_node', node, newText)
  }

  public SetNodeIcon(id: any, objectType: any, nodeText?: any) {

    let icon = this.utility.GetPathImages(this.GetNodeByTypeFromTypesWithDefault(objectType, nodeText));
    (<any>$('#' + this.name)).jstree(true).set_icon(id, icon);
  }
  public GetJsonTreeData(fire?: any) {
    const that = this;
    let jsonProject = null;
    jsonProject = this.GetNode('Root');
    if (this.queryConfigIDEType === QueryConfigIDETypes.Formularios) {

      jsonProject = this.CreateNodeTree(null, this.paginas, 'Formularios', that);

    }

    else {
      this.CreateNodeTree(jsonProject, this.roles, 'Roles', that);


      this.CreateNodeTree(jsonProject, this.paginasProcesadas, 'Formularios', that);

      this.CreateNodeTree(jsonProject, this.querys, 'Querys', that);

      this.CreateNodeTree(jsonProject, this.areasEmpresa, 'AreasEmpresa', that, TiposCrearNodos.Recursiva);

      let configSeries = {
        items: this.subSeries,
        idParent: 'IdParent',
        idParentExternal: 'IdSerie',
        nodeTypeData: this.utility.GetNodeType('SubSeries'),
        TiposCrearNodo: TiposCrearNodos.Recursiva
      };

      this.CreateNodeTree(jsonProject, this.series, 'Series', that, TiposCrearNodos.Normal, configSeries);


      this.CreateNodeTree(jsonProject, this.subSeries, 'SubSeries', that, TiposCrearNodos.Recursiva);

      this.CreateNodeTree(jsonProject, this.tiposDocumentales, 'TiposDocumentales', that);


      this.CreateNodeTree(jsonProject, this.tablasRetencionDocumental, 'TablasRetencionDocumental', that);

      this.CreateNodeTree(jsonProject, this.aplicacionesPermitidas, 'AplicacionesPermitidas', that);

      this.CreateNodeTree(jsonProject, this.grupos, 'Grupos', that);

      this.CreateNodeTree(jsonProject, this.expedinetes, 'Expedientes', that);


      let configTiposSolicitud = {
        items: this.estadosSolicitud,
        idParentExternal: 'IdTipoSolicitud',
        nodeTypeData: this.utility.GetNodeType('EstadosSolicitud')
      };
      this.CreateNodeTree(jsonProject, this.tiposSolicitud, 'TiposSolicitud', that, TiposCrearNodos.Recursiva, configTiposSolicitud);

      this.CreateNodeTree(jsonProject, this.carepetas, 'Carpetas', that, TiposCrearNodos.Recursiva);
    }
    var jsonData = [jsonProject];

    return jsonData;
  }

}
