import { Component, ViewChild, ViewChildren, OnInit, AfterViewInit, QueryList, Input, Output, EventEmitter } from '@angular/core';
import { Paginas, ControllerMethods, TipoObjetoParaPermisos, TipoParaPermisos, nodeTypes } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { ConfigBaseTreeComponent, TiposCrearNodos } from '../../../models/config-base-tree-component';
import { OrgChartJsComponent } from '../../org-chart/org-chart-js/org-chart-js.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { JstreeComponent } from '../../jstree/jstree.component';
import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { PalabrasClaveComponent } from '../../palabras-clave/palabras-clave.component';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { DataTableComponent } from '../../controls/data-table/data-table.component';
import { FormDetailsComponent } from '../../controls/form-details/form-details.component';
import { DataTableVersionesCrudTrdComponent } from '../../expediente/data-table-versiones-crud-trd/data-table-versiones-crud-trd.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { TExpedienteComponent } from '../../bussines/texpediente/texpediente.component';

import { HelperActions } from 'src/app/helpers/helper-actions';

@Component({
	selector: 'app-edicion-expediente',
	templateUrl: './edicion-expediente.component.html',
	styleUrls: ['./edicion-expediente.component.css']
})
//export class EdicionExpedienteComponent extends TExpedienteComponent GeneralFormComponent implements OnInit, AfterViewInit {
export class EdicionExpedienteComponent extends TExpedienteComponent implements OnInit, AfterViewInit {
	// @ViewChild('DynamicMetadata', { static: false }) DynamicMetadata: DynamicControlsMetadataComponent;
	// @ViewChild(PalabrasClaveComponent, { static: false }) palabrasClaveComponent: PalabrasClaveComponent;
	// palabrasClaveComponent: PalabrasClaveComponent;
	constructor(public baseService: BaseServiceService, public utility: Utilities,
		public helperImages: HelperImages,
		public helperRules: HelperRules, public helperActions: HelperActions) {
		super(baseService, utility, helperImages, helperRules, helperActions);
	}

	detalleExpediente: any;

	ngOnInit() {
		//
		this.pageId = 'EdicionExpediente';
		super.ngOnInit();

	}
	public InternalSetModelo(_modelo: any, permissionsConfig?: any) {
		//
		//CM
		let pageSize = 200;
		let skip = 0;
		
		if(_modelo.modelo.IdExpediente)
		{
			let obj = this.utility.GetApiModel('GetDatosExpediente', null)
		obj.QueryConfig.Command = 'BusquedaTodoDetalleExpediente';
		obj.QueryConfig.IsSp = true;
		obj.QueryConfig.IsMulti = true;
		obj.QueryConfig.Entity = {
			IdEmpresa: -1,
			IdExpediente: _modelo.modelo.IdExpediente,
			PageSize: pageSize, Skip: skip
		};

		this.url = (!this.url) ? 'TExpediente' : this.url;
		//this.processMessage ='Buscando documentos del expediente......';
		let info = {
			componentName: this.componentName, controlName: 'GetNodosTodoDetalleExpediente',
			processMessage: 'Cargando Detalles del expediente......'
		};
		this.baseService.Get(obj, null, info, this.url, false, false).then(data => {
			

			this.detalleExpediente = data.Data.Table[0];
			

		}).catch(err => { });
		// this.modelo.modelo.Pala = this.detalleExpediente.IdExpediente 
		
		}

		
		if (permissionsConfig)
			this.permissionsConfig = this.permissionsConfig;

		this.modelo.modelo = (this.modelo.modelo.IdVersionTablaRetencionDocumental) ? this.utility.SetFieldsValue(this.modelo.modelo, _modelo.modelo) : _modelo.modelo;
		this.modelo.modelo.PkValue = this.modelo.modelo[this.pkName];

		if (_modelo.modeloMetadata) {
			this.modelo.modeloMetadata = (this.modelo.modeloMetadata) ? this.utility.SetFieldsValue(this.modelo.modeloMetadata, _modelo.modeloMetadata) : _modelo.modeloMetadata;
			this.modelo.modeloMetadata.PkValue = this.modelo.modeloMetadata[this.pkName];
		}

		this.dttTRD.IsEditModelo = true;
		this.dttTRD.BuscarVersionTRD(true);
		this.SetModelToControls();
		this.SetProcesando(false);
		//this.SetFocusModelo();
		if(this.detalleExpediente)		
		this.modelo.modelo.PalabrasClave = this.detalleExpediente.PalabrasClave

		if (this.palabrasClaveComponent) {
			this.palabrasClaveComponent.isModelContainer = true;
			this.palabrasClaveComponent.SetModelo(this.modelo);
		}
		if (this.adminPermisosComponent) {
			this.adminPermisosComponent.isModelContainer = true;
			this.adminPermisosComponent.SetModelo(this.modelo);
		}
		this.EditCascading();
		this.ExcecuteFuntion(ControllerMethods.Edit, true, { index: 0, id: this.modelo.modelo.PkValue });
	}
}
