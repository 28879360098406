import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter, QueryList, forwardRef, Inject } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes } from '../../models/general.enum';
import { Utilities } from '../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../assets/linqts/compilado/index';
import { StaticData } from '../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { ConfigWindow } from '../../models/config-window';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
// import { AutoGenerateField } from 'src/app/models/rule-engine/rules-models';
import { HelperImages } from 'src/app/helpers/helper-images';

@Component({
  selector: 'app-propert-grid-campo',
  templateUrl: './propert-grid-campo.component.html',
  styleUrls: ['./propert-grid-campo.component.css']
})
export class PropertGridCampoComponent implements OnInit {

  @Input() componentName: string;

  @Input() subControl: any = null;
  @Input() subControlParent: any = null;

  @Input() modelo: any = null;
  @Input() controls: Array<any> = [];


  constructor(public utility: Utilities,
    public helperImages: HelperImages) {

  }

  ngOnInit() {
  }

  public ConfigurarAutoGenerateField() {
 
    // @Input() propCampos: string = "Campos";
    // @Input() propCaracterSeparacion: string = "CaracterSeparacion";
    // @Input() propValoresReemplazo: string = "ValoresReemplazo";
    // @Input() propFormatoTexto: string = "FormatoTexto";
    // @Input() propTamanoMaximo: string = "TamanoMaximo";
    // @Input() propTipoNombreFolder: string = "TipoNombreFolder";
    // @Input() verNombre: boolean = false;
    // @Input() verTipoNombreFolder: boolean = false;
    // @Input() verGenerarNuevoCodigo: boolean = false;
    // @Input() verModelo: boolean = false;
    // @Input() listToConnect: Array<any> = [];
    // @Input() controls: Array<any> = [];
    // @Input() verControles: boolean = false;
    let config = this.utility.GetConfigWindow('Configuracion de Campos');
    if (!this.subControl.Fields)
      this.subControl.Fields = [];
    let windowModel: any = {
      propCampos: "Fields",
      propCaracterSeparacion: "CharacterSeparation",
      propValoresReemplazo: "ReplacementValues",
      propFormatoTexto: "TextFormat",
      propTamanoMaximo: "MaxLength",
      verModelo: true,
      verControles: true,
      controls: this.controls,
      modelo: this.subControl
    };

    this.utility.VerModalComponente('ConfigCamposComponent', windowModel, config).then(dataResultOnClose => {
      if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

        

      }
      else {
        this.subControl.Fields = [];
      }
    });
  }


}
