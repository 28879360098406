import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debug } from 'console';
import { StaticData } from 'src/app/helpers/static-data';
import { Utilities } from 'src/app/helpers/utilities';
import { ExternalMethodConfig } from 'src/app/models/rule-engine/rules-models';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { FileUploaderComponent } from '../../controls/file-uploader/file-uploader.component';

@Component({
  selector: 'app-document-download-settings',
  templateUrl: './document-download-settings.component.html',
  styleUrls: ['./document-download-settings.component.css']
})
export class DocumentDownloadSettingsComponent implements OnInit {
  @ViewChild('documento', { static: false }) documentoComponente: FileUploaderComponent;

  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() modelo: any;
 
  @Input() title: string = "";
  configFileUploader: any;
  @Input() DocumentDownloadSettingsTypes: Array<any> = []
  url: string = 'TDocumentoExpediente';
  @Input() configDownloadSettings: any;
  constructor(public baseService: BaseServiceService, public utility: Utilities) {
    this.configFileUploader = this.GetConfigFileUpload(null);

  }

  ngOnInit() {
  }

  public GetConfigFileUpload(configDocumento) {

    let configFileUploader = {
      NotManageResponseInternal: true,
      multiple: false,
      //formatsAllowed: configDocumento.ExtensionesValidas,
      formatsAllowed: ".txt,.csv,.docx,.xlsx,.pptx,.odt,.ods,.odp,.pdf,.xml,.html,.json,.xpdl,.jpeg,.jpg,.jpe,.jfif,.jfi,.jif,.jpg2,.jp2,.mng,.odg,.png,.tiff,.xpm,.cgm,.svg,.svgz,.mp3,.bwf,.wav,.mp4,.mpeg,.m4v,.mkv,.mka,.mks,.mk3d,.mj2.mjp2,.mpg,.mpeg,.mp1,.mp2,.mp3,.m1v,.m1a,.m2a,.mpa,.mpv,.mp2,.mxf,.webm,.ogg,.ogv,.oga,.ogx,.ogm,.spx,.opus,.gml,.shp,.shx,.sbn,.shp,.xml,.gz,.zip,.dbf,.mdbx,.siard,.warc,.eml,.mbox,.mbx,.xls,.doc",
      maxSize: 1000,// configDocumento.TamanoMaximo,
      Required: true,
      Label: "Documento",
      ControlType: "FileBox",
      Name: 'FileBoxDocumentoExpediente',
      // NotPrincipal: !this.tienePermisoCambiarDoc,
      NotPrincipal: true,
      uploadAPI: {
        url: StaticData.UrlServer + "/api/TDocumentoExpediente/UploadFileConfigs",
        headers: {
          modelo: ""
        }
      },
      WithScanner: false,
      ViewStatusScannerNotify: false,
      ViewStatusScanner: false,
      theme: "dragNDrop",
      hideProgressBar: false,
      hideResetBtn: false,
      //hideSelectBtn: false,
      replaceTexts: {
        selectFileBtn: 'Seleccione el documento',
        resetBtn: 'Limpiar',
        uploadBtn: 'Cargar Documento',
        dragNDropBox: 'Drag & Drop',
        attachPinBtn: 'Adjunte Documento...',
        afterUploadMsg_success: ' ',
        afterUploadMsg_error: 'Error al cargar !'
      }
    };

    return configFileUploader;
  }


  public CreateTextReplacementSettings() {
    this.modelo.TextReplacementSettings = { ReplacementCharacter: null, Configs: [] };
  }

  public DeleteTextReplacementSettings() {
    this.modelo.TextReplacementSettings = null;
  }

  public CreateExternalMethod() {
    this.modelo.ExternalMethod = new ExternalMethodConfig();
  }

  public DeleteExternalMethod() {
    this.modelo.ExternalMethod = null;
  }
  TEMPLATES: Array<any> = [];
  GetTemplates(force?: any) {
    if (StaticData.Project) {
      if (this.TEMPLATES.length == 0 || force == true) {
        this.TEMPLATES = [];
        this.TEMPLATES = this.utility.GetTemplates(StaticData.Project);
      }
      return this.TEMPLATES;
    }
    return [];
  }

  OnChangeType() {

    this.modelo.ExternalMethod = null;
    this.modelo.TextReplacementSettings = null;
    this.modelo.Template = null;
    this.modelo.File = null;

    if (!this.modelo.Type) {


    }
    else {
      if (this.modelo.Type == 'DocumentReplacement') {
        this.modelo.File = { Nombre: null };
      }
      else if (this.modelo.Type == 'TextProcessing') {
        this.modelo.TextReplacementSettings = { ReplacementCharacter: null, Configs: [] };
      }
      else if (this.modelo.Type == 'CustomByInterface' || this.modelo.Type == 'Custom') {
        this.modelo.ExternalMethod = new ExternalMethodConfig();
      }
    }
  }

  public Limpiar() {
   
    this.onActionEventEmitter.emit({ Action: 'Clear' })
  }

  public Guardar() {
    this.onActionEventEmitter.emit({ Action: 'PreSave' })
    if (this.modelo.Type == 'DocumentReplacement')
      this.GuardarArchivo(null);
    else
      this.InsertUpdateDownloadSettings()

  }
  public InsertUpdateDownloadSettings() {


    
    const that = this;
    let obj = this.utility.GetApiModel('InsertUpdateDownloadSettings', null)
    this.SetPropertiesObj(obj);
    
    let info = {
      componentName: this.url, method: 'InsertUpdateDownloadSettings',
      processMessage: 'Guardando Configuracion......'
    };
    this.baseService.Ejecutar(obj, null, info, false, this.url + '/InsertUpdateDownloadSettings', true, false).then(data => {


      this.onActionEventEmitter.emit({ Action: 'Save', Ok: true });
      


    }).catch(err => { });

  }

  SetPropertiesObj(obj) {
    obj.IdDocumentoExpediente = this.configDownloadSettings.IdDocumentoExpediente;
    obj.PkValue = this.configDownloadSettings.IdDocumentoExpediente;

    obj.DocumentoExpedienteConfiguracion = {
      PkValue: this.configDownloadSettings.IdDocumentoExpedienteConfiguracion,
      IdDocumentoExpedienteConfiguracion: this.configDownloadSettings.IdDocumentoExpedienteConfiguracion,
      IdDocumentoExpediente: this.configDownloadSettings.IdDocumentoExpediente
    }
    obj.ConfigDocumentSettings = this.configDownloadSettings;

    // obj.DownloadSettings = {
    //   PkValue: this.configDownloadSettings.IdDocumentoExpedienteConfiguracion,
    //   IdDocumentoExpedienteConfiguracion: this.configDownloadSettings.IdDocumentoExpedienteConfiguracion,
    //   IdDocumentoExpediente: this.configDownloadSettings.IdDocumentoExpediente,
    //   Configuracion: JSON.stringify(this.configDownloadSettings),
    //   Token: obj.Token,
    // }
  }
  public RespuestaCargaDocumento(event) {


    
    if (event.response) {
      let obj: any = JSON.parse(event.response);
      if (obj.Ok) {
        this.documentoComponente.selectedFiles = [];
        this.onActionEventEmitter.emit({ Action: 'ResponseUpload', Ok: true });
      

      }
      else {
        this.utility.logger.LogError(obj, 'RespuestaCargaDocumento')
        this.documentoComponente.selectedFiles = [];
        this.onActionEventEmitter.emit({ Action: 'ResponseUpload', Ok: false });
     
      }
    }
  }

  public EliminarDocumento(event) {

    if (this.modelo.File)
      this.modelo.File.Nombre =null;
  }
  public EventoProgresoCargaDocumento(event) {
  }

  public EventoCargarDocumento(event) {
    
    this.GuardarArchivo(null);
  }
  public EventoSeleccionArchivo(event) {
    
    if (this.modelo.File)
      this.modelo.File.Nombre = this.documentoComponente.selectedFiles[0].name;
  }
  public GuardarArchivo(event) {
    
    const that = this;
    let token: string = StaticData.Usuario.Token;

    if (this.modelo.File)
      this.modelo.File.Nombre = this.documentoComponente.selectedFiles[0].name;

    let model: any = {

      Token: token,
      MethodNameUI: 'Guardar',
      DataAdd: { FileModelType: 'Documents' }
    }
    this.SetPropertiesObj(model);
    model.CurrentGUID = this.modelo.GUID;
    
    this.documentoComponente.uploadFiles(false, model);

  }
}
