import { Component, OnInit, AfterViewInit, Input, Output, forwardRef, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { ObservacionesBase } from '../../../models/observaciones-base';
import { Utilities } from '../../../helpers/utilities';



@Component({
  selector: 'app-observaciones-modificacion-version',
  templateUrl: './observaciones-modificacion-version.component.html',
  styleUrls: ['./observaciones-modificacion-version.component.css']
})



export class ObservacionesModificacionVersionComponent extends ObservacionesBase implements OnInit {



  constructor(public utility: Utilities) {

    super(utility);
    setTimeout(() => {      
      (<HTMLInputElement>document.getElementById("btnOkGuardar")).disabled = true;
      var BotonExtraOk = (<HTMLInputElement>document.getElementById("btnOkGuardarExtra"));
      if (BotonExtraOk)
        (<HTMLInputElement>document.getElementById("btnOkGuardarExtra")).disabled = true;
    }, 400);

  }


  //cambiardr();

  public cambiardr() {    
    var obs = (<HTMLInputElement>document.getElementById("ObservacionesModificacion")).value;
    var BotonExtraOk = (<HTMLInputElement>document.getElementById("btnOkGuardarExtra"));
    if (obs != '') {
      (<HTMLInputElement>document.getElementById("btnOkGuardar")).disabled = false;
      if (BotonExtraOk)
        (<HTMLInputElement>document.getElementById("btnOkGuardarExtra")).disabled = false;
    } else {
      (<HTMLInputElement>document.getElementById("btnOkGuardar")).disabled = true;
      if (BotonExtraOk)
        (<HTMLInputElement>document.getElementById("btnOkGuardarExtra")).disabled = true;

    }
  }
}
