import { Component, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { BaseComboBox } from "../../../models/base-combo-box";
import { List } from "../../../../assets/linqts/compilado/index";

declare let $ : any;

@Component({
    selector: "app-multi-combo-box-r",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiComboBoxRComponent),
            multi: true
        }
    ],
    templateUrl: "./multi-combo-box-r.component.html",
    styleUrls: ["./multi-combo-box-r.component.scss"]
})
export class MultiComboBoxRComponent extends BaseComboBox implements OnInit,ControlValueAccessor {

    ngAfterViewInit(){
        const element = $("#" + this.controlName);
        element.mouseenter( function(){
            if( element.hasClass("ng-select-opened") )
                element.tooltip("hide");
        });
        element.children(".ng-select-container").mouseleave( function(){
            element.tooltip("hide");
        });
    }
    public OnChangeEvent(item: any, data: any = null) {
        try {
            
            //ojo implementar la asignacion de valor
            this.value = item;
            if (this.onSelectedEmitter.observers.length > 0)
                this.onSelectedEmitter.emit({ item: item, controlName: this.controlName, config: this.configData, data: data });
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, controlName: this.controlName, config: this.configData });
        }
    }
    get seleccionados(): any {
        if(this.modelo != null)
        {
            const listItems =new List<any>(this.items);
            const itemsModel =new List<any>(this.modelo);
            const selectItems =[];
            const that = this;
            $.each(listItems, function (key, value) {
                selectItems.push(value[that.textField]);
            });

     
            const resultado = "("+itemsModel.Count()+") "+ itemsModel.Select(o=>{ return o;}).ToArray().join(",");
            return resultado;
        }
        else
            return this.config.Label +" 0 Seleccionados";
    }

    getSeleccionados(selectItems:any[]): any {
        if(selectItems.length > 0)
        {
            const listItems =new List<any>(selectItems);
      
            const resultado = "("+selectItems.length+") "+ listItems.Take(2).Select(o=>{ return o[this.textField];}).ToArray().join(",")+".....";
            return resultado;
        }
        else
            return this.config.Label +" (0) Seleccionados";
    }

    public onSelectAllComboBox() {
        try{
            this.modelo = this.items.map(item => item[this.pkField]);
            this.updateChanges();
        }
        catch(error)
        {
            this.utility.logger.LogError(error,null,{omponentName:this.componentName,method:"onSelectAllComboBox", controlName:this.controlName});
        }
    }
 
    public onClearAllComboBox() {
        try{
            this.modelo=[];
            this.updateChanges();
        }
        catch(error)
        {
            this.utility.logger.LogError(error,null,{componentName:this.componentName,method:"onClearAllComboBox", controlName:this.controlName});
        }
    }
}

