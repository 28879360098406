import { Component, OnInit } from '@angular/core';
import { SolicitudRadicadoEntradaComponent } from '../solicitud-radicado-entrada/solicitud-radicado-entrada.component';

@Component({
  selector: 'app-radicado-entrada-recepcion',
  templateUrl: './radicado-entrada-recepcion.component.html',
  styleUrls: ['./radicado-entrada-recepcion.component.css']
})
export class RadicadoEntradaRecepcionComponent extends SolicitudRadicadoEntradaComponent implements OnInit {


}
