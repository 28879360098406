import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ComponentsContainer } from 'src/app/models/components-container';
import { QueryBuilderComponent, QueryBuilderConfig } from 'angular2-query-builder';
import { TipoParaPermisos } from 'src/app/models/general.enum';
import { Utilities } from 'src/app/helpers/utilities';
import { BaseServiceService } from 'src/app/services/base-service.service';

@Component({
  selector: 'app-filters-data-table',
  templateUrl: './filters-data-table.component.html',
  styleUrls: ['./filters-data-table.component.css']
})
export class FiltersDataTableComponent implements OnInit, AfterViewInit {

  @ViewChild(QueryBuilderComponent, { static: false }) queryBuilderComponent: QueryBuilderComponent;
  public componentsContainer: ComponentsContainer;
  public childComponents: Array<any> = [];

  @Output() onSearchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClearSearchEvent: EventEmitter<any> = new EventEmitter<any>()
  @Input() Pagina: any;
  @Input() modelo: any = {};

  @Input() config: any;
  @Input() componentName: string = "";


  filter: string = "";

  currentQuery: any = {
    condition: 'and',
    rules: []
  };

  configQuery: QueryBuilderConfig = {

    fields: {
      age: { name: 'Age', type: 'number' },
      fecha: { name: 'fecha', type: 'date' },
      gender: {
        name: 'Gender',
        type: 'category',
        options: [
          { name: 'Male', value: 'm' },
          { name: 'Female', value: 'f' }
        ]
      }
    }
  };

  category: Array<any> = ['=', "!=", "in", "not in", "Es Nulo", "No Es Nulo"];
  date: Array<any> = ['=', "!=", ">", ">=", "<", "<=", "in", "not in", "Es Nulo", "No Es Nulo"];
  number: Array<any> = ['=', "!=", ">", ">=", "<", "<=", "in", "not in", "Es Nulo", "No Es Nulo"];
  string: Array<any> = ['=', "!=", "in", "not in", "Es Nulo", "No Es Nulo", "Contiene", "No Contiene", "Inicia Con", "No Inicia Con", "Finaliza Con", "No Finaliza Con", "Es Vacio", "No Es Vacio"];

  constructor(public utility: Utilities, public baseService: BaseServiceService) { }
  htmlRemove(str) {
    var result = str.split("<strong>").join("").split("</strong>").join(""); //String(str).replace(/<strong>/g, '').replace(/\</strong>/g, '');

    return result;
  }
  public Buscar() {


    if (this.config.WithQueryBuilder) {
      
      let filter = this.htmlRemove(this.filter);
      this.onSearchEvent.emit({ modelo: this.modelo, filter: filter });
    }
    else
      this.BuscarFilterControls();
  }


  public BuscarFilterControls() {


    
    let i: number = 0;
    this.filter = "";
    $.each(this.modelo, (key, value: any) => {

      if (i > 0)
        this.filter += " AND ";
      let control: any = this.config.FilterControls.find(ctr => ctr.Name == key);
      switch (control.DataType) {
        case 'String':
          this.filter += " " + control.Name + " LIKE '%" + value + "%' ";
          break
        case 'DateTime':
          this.filter += " " + control.Name + " = CONVERT(DATETIME,'" + value + "',103)";
          break;
        case 'Boolean':
          //filter += " CONVERT(DATETIME,'" + value + "',103)";
          if (value == "true")
            this.filter += " " + control.Name + " = 1";
          else
            this.filter += " " + control.Name + " = 0";
          break;
        default:
          this.filter += " " + control.Name + " = " + value + " ";
          break


      }
      i++;
    });

    this.onSearchEvent.emit({ modelo: this.modelo, filter: this.filter });
  }
  ngOnInit() {

    
    if (this.config.WithQueryBuilder) {
      this.configQuery = { fields: {} };
      this.config.FilterControls.forEach(control => {

        switch (control.ControlType) {

          case 'NumberBox':
            this.configQuery.fields[control.Name] = { name: control.Label, type: "number", operators: this.number };
            break;
          case 'TextBox':
            this.configQuery.fields[control.Name] = { name: control.Label, type: "string", operators: this.string };
            break;
          case 'DateBox':
            this.configQuery.fields[control.Name] = { name: control.Label, type: "date", operators: this.date };
            break;
          case 'CheckBox':
            this.configQuery.fields[control.Name] = { name: control.Label, type: "boolean" };
            break;
          //
          case 'ComboBox':
          case 'MultiComboBox':
            let _items: any = [];
            this.configQuery.fields[control.Name] = { name: control.Label, type: "category", operators: this.category };
            this.configQuery.fields[control.Name].options = [];
            if (control.StaticList) {

              let tipo: TipoParaPermisos = Number.isInteger(control.StaticList) ?
                parseInt(control.StaticList) : TipoParaPermisos["" + control.StaticList];
              _items = this.utility.GetItemsAreasRolesUsuarioSeries(tipo);
              _items.forEach(item => {
                this.configQuery.fields[control.Name].options.push({ name: item[control.TextField], value: item[control.PkField] })
              });

            }
            else if (control.ConfigControl.Items) {
              control.ConfigControl.Items.forEach(item => {
                this.configQuery.fields[control.Name].options.push({ name: item[control.TextField], value: item[control.PkField] })
              });
            }
            else if (control.ConfigControl.QueryConfig) {
              this.CargarDatos(control);
            }
            break;

        }

      });
    }
  }

  htmlEntities(str) {
    return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  computed(group) {
    if (!group || !group.rules) return "";
    for (var str = "(", i = 0; i < group.rules.length; i++) {
      i > 0 && (str += " <strong>" + group.condition + "</strong> ");
      if (group.rules[i].rules)
        str += this.computed(group.rules[i])
      else {
        let control: any = this.config.FilterControls.find(ctr => ctr.Name == group.rules[i].field);
        var datatemp = '';
        var dateArr = [];
        if (group.rules[i].operator == "in") {

          switch (control.DataType) {
            case 'String':
              group.rules[i].value.forEach(_value => {
                dateArr.push("'" + _value + "'");
              });

              datatemp = dateArr.join(',');
              str += group.rules[i].field + " <strong> IN </strong> (" + datatemp + ")";

              break
            case 'DateTime':
              group.rules[i].value.forEach(_value => {
                dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
              });

              datatemp = dateArr.join(',');
              str += "CONVERT(DATETIME," + group.rules[i].field + ",103) " + " <strong> IN </strong> (" + datatemp + ")";
              //str += "CONVERT(DATETIME," + group.rules[i].field + ",103) " + this.htmlEntities(group.rules[i].operator) + "  CONVERT(DATETIME,'" + group.rules[i].value + "',103)";
              break;

            default:
              str += group.rules[i].field + " <strong> IN </strong> (" + group.rules[i].value + ")";
              break


          }

        }
        else if (group.rules[i].operator == "not in") {

          switch (control.DataType) {
            case 'String':
              group.rules[i].value.forEach(_value => {
                dateArr.push("'" + _value + "'");
              });

              datatemp = dateArr.join(',');
              str += group.rules[i].field + " <strong> NOT IN  </strong> (" + datatemp + ")";

              break
            case 'DateTime':
              group.rules[i].value.forEach(_value => {
                dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
              });

              datatemp = dateArr.join(',');
              str += "CONVERT(DATETIME," + group.rules[i].field + ",103) " + " <strong> NOT IN </strong> (" + datatemp + ")";
              //str += "CONVERT(DATETIME," + group.rules[i].field + ",103) " + this.htmlEntities(group.rules[i].operator) + "  CONVERT(DATETIME,'" + group.rules[i].value + "',103)";
              break;

            default:
              str += group.rules[i].field + "<strong> NOT IN </strong> (" + group.rules[i].value + ")";
              break


          }
          //str += group.rules[i].field + " NOT IN (" + group.rules[i].value + ")";
        }
        else if (group.rules[i].operator == "Contiene") {
          str += group.rules[i].field + " <strong> LIKE </strong> '%" + group.rules[i].value + "%'";
        }
        else if (group.rules[i].operator == "No Contiene") {
          str += group.rules[i].field + " <strong> NOT LIKE </strong> '%" + group.rules[i].value + "%'";
        }
        else if (group.rules[i].operator == "Inicia Con") {
          str += group.rules[i].field + " <strong> LIKE </strong> '" + group.rules[i].value + "%'";
        }
        else if (group.rules[i].operator == "No Inicia Con") {
          str += group.rules[i].field + " <strong> NOT LIKE </strong> '" + group.rules[i].value + "%'";
        }
        else if (group.rules[i].operator == "Finaliza Con") {
          str += group.rules[i].field + " <strong> LIKE </strong> '%" + group.rules[i].value + "'";
        }
        else if (group.rules[i].operator == "No Finaliza Con") {
          str += group.rules[i].field + " <strong> NOT LIKE </strong> '%" + group.rules[i].value + "'";
        }
        else if (group.rules[i].operator == "Es Nulo") {
          str += group.rules[i].field + " <strong> IS NULL </strong> ";
        }
        else if (group.rules[i].operator == "No Es Nulo") {
          str += group.rules[i].field + " <strong> NOT IS NULL </strong> ";
        }
        else if (group.rules[i].operator == "Es Vacio") {
          str += group.rules[i].field + " <strong> = </strong> '' ";
        }
        else if (group.rules[i].operator == "No Es Vacio") {
          str += group.rules[i].field + " <strong> <> </strong> '' ";
        }
        ///    str += group.rules[i].field.Name + " LIKE '%" + group.rules[i].data + "%'";
        else {
          switch (control.DataType) {
            case 'String':
              str += group.rules[i].field + " <strong>" + this.htmlEntities(group.rules[i].operator) + "</strong> '" + group.rules[i].value + "' ";
              break
            case 'DateTime':
              str += "CONVERT(DATETIME," + group.rules[i].field + ",103) <strong>" + this.htmlEntities(group.rules[i].operator) + "</strong>  CONVERT(DATETIME,'" + group.rules[i].value + "',103)";
              break;
            case 'Boolean':
              let boolValue = (group.rules[i].value == "true" || group.rules[i].value == true) ? 1 : 0;
              str += group.rules[i].field + " <strong>" + this.htmlEntities(group.rules[i].operator) + "</strong> " + boolValue + " ";
              //filter += " CONVERT(DATETIME,'" + value + "',103)";
              /*     if (value == "true")
                    this.filter += " " + control.Name + " = 1";
                  else
                    this.filter += " " + control.Name + " = 0"; */
              break;
            default:
              str += group.rules[i].field + " <strong>" + this.htmlEntities(group.rules[i].operator) + "</strong> " + group.rules[i].value + " ";
              break


          }
        }

        //str +=  group.rules[i].field + " " + this.htmlEntities(group.rules[i].operator) + " " + group.rules[i].value;
      }
      // str += group.rules[i].rules ?
      //   this.computed(group.rules[i]) :
      //   group.rules[i].field + " " + this.htmlEntities(group.rules[i].operator) + " " + group.rules[i].value;
    }

    return str + ")";
  }

  ngAfterViewInit() {

    const that = this;
    if (this.queryBuilderComponent) {
     // this.queryBuilderComponent.allowCollapse = true;
      // this.queryBuilderComponent.defaultOperatorMap["category"] = ['=', "!=", "in", "not in", "Es Nulo", "No Es Nulo"];
      // this.queryBuilderComponent.defaultOperatorMap["date"] = ['=', "!=", ">", ">=", "<", "<=", "in", "not in", "Es Nulo", "No Es Nulo"];
      // this.queryBuilderComponent.defaultOperatorMap["number"] = ['=', "!=", ">", ">=", "<", "<=", "in", "not in", "Es Nulo", "No Es Nulo"];
      // this.queryBuilderComponent.defaultOperatorMap["string"] = ['=', "!=", "in", "not in", "Es Nulo", "No Es Nulo",
      //   "Contiene", "No Contiene", "Inicia Con", "No Inicia Con", "Finaliza Con", "No Finaliza Con", "Es Vacio", "No Es Vacio"];
      // this.queryBuilderComponent.defaultOperatorMap
      this.queryBuilderComponent.onChangeCallback = function () {
        
        let temp = that.currentQuery;
        let temp2 = this.data;
        this.allowCollapse = true;
        // this.defaultOperatorMap["category"] = ['=', "!=", "in", "not in", "Es Nulo", "No Es Nulo"];
        // this.defaultOperatorMap["date"] = ['=', "!=", ">", ">=", "<", "<=", "in", "not in", "Es Nulo", "No Es Nulo"];
        // this.defaultOperatorMap["number"] = ['=', "!=", ">", ">=", "<", "<=", "in", "not in", "Es Nulo", "No Es Nulo"];
        // this.defaultOperatorMap["string"] = ['=', "!=", "in", "not in", "Es Nulo", "No Es Nulo",
        //   "Contiene", "No Contiene", "Inicia Con", "No Inicia Con", "Finaliza Con", "No Finaliza Con", "Es Vacio", "No Es Vacio"];
        that.filter = that.computed(that.currentQuery);
      }

    }

  }

  public CargarDatos(control, options?: any) {

    try {





      var obj = this.utility.GetApiModel('CargarDatos', '');
      obj.QueryConfig = control.ConfigControl.QueryConfig;

      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          processMessage: "",
          method: 'OnInit'
        }, control.ConfigControl.Controller, true, true).then(result => {

          this.modelo = result.Data[0];

          result.Data.forEach(item => {
            this.configQuery.fields[control.Name].options.push({ name: item[control.TextField], value: item[control.PkField] })
          });




        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'CargarDatos' });
        })



    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'CargarDatos' });
    }
  }


}
