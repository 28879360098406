import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, QueryList } from '@angular/core';
import { FormGroup, FormControl, NgForm, Validators, DefaultValueAccessor } from '@angular/forms';
import { Paginas, ControllerMethods } from '../models/general.enum';
import { DataTableComponent } from '../components/controls/data-table/data-table.component';
import { BaseComponentComponent } from '../components/base-component/base-component.component';
import { FormDetailsComponent } from '../components/controls/form-details/form-details.component';
import { FileUploaderComponent } from '../components/controls/file-uploader/file-uploader.component';
import { MessageBarComponent } from '../components/controls/message-bar/message-bar.component';
import { ChartComponent } from '../components/controls/chart/chart.component';
import { DashboardComponent } from '../components/controls/dashboard/dashboard.component';
import { HtmlComponent } from '../components/controls/html/html.component';

export class ComponentsContainer {

    public FormsDetails: Array<FormDetailsComponent> = [];
    public Tables: Array<DataTableComponent> = [];
    public ChildComponents: Array<any> = [];
    public FileBox: FileUploaderComponent = null;
    public FileBoxWorkFlow: FileUploaderComponent = null;
    public FloatPanels: Array<any> = [];
    public MessageBars: Array<MessageBarComponent> = [];
    public Charts: Array<ChartComponent> = [];
    public Dashboards: Array<DashboardComponent> = [];
    public Htmls: Array<HtmlComponent> = [];

    public DestroyAll() {


        this.ChildComponents.filter(ele => {

            return (ele.isDynamic && ele.destroy);
        }).forEach(componet => {

            componet.destroy();
        })
        this.ChildComponents.forEach(ele => {

            ele = null;
        });
        this.MessageBars.forEach(ele => {

            ele = null;
        });
        this.Tables.forEach(ele => {

            if (ele)
                ele.ngOnDestroy();
            ele = null;
        })
        this.FloatPanels.forEach(ele => {

            if (ele && ele.ngOnDestroy)
                ele.ngOnDestroy();
            ele = null;
        })
        this.Charts.forEach(ele => {

            if (ele && ele.ngOnDestroy)
                ele.ngOnDestroy();
            ele = null;
        })
        this.Dashboards.forEach(ele => {

            if (ele && ele.ngOnDestroy)
                ele.ngOnDestroy();
            ele = null;
        })
        this.Htmls.forEach(ele => {

            if (ele && ele.ngOnDestroy)
                ele.ngOnDestroy();
            ele = null;
        })
        this.FileBox = null;
        this.FileBoxWorkFlow = null;
    }
    public ReloadTable(name: string, entity?: any, addParameters?: boolean, throwError: boolean = true): any {
        const component = this.Tables.find(x => { return x.name == name });
        if (component) {
            if (entity && component.QueryConfig) {
                if (addParameters) {

                }
                else
                    component.QueryConfig.Entity = entity;
            }
            component.ReCargarGrilla();
        }
        else if (throwError)
            throw 'No exite la tabla por nombre ' + name;
    }
    public SetTableProperty(name: string, property: string, value: any, throwError: boolean = true): any {
        const component = this.Tables.find(x => { return x.name == name });
        if (component)
            component[property] = value;
        else if (throwError)
            throw 'No exite la tabla por nombre ' + name;
    }
    public Find(name: string): any {
        let component: any = null;
        component = this.Tables.find(x => { return x.name == name });
        if (!component)
            component = this.ChildComponents.find(x => { return x.name == name || (x.config && x.config.Name == name) || (x.subControl && x.subControl.Name == name) });
        else
            return component;
        if (!component)
            component = this.FloatPanels.find(x => { return x.name == name || (x.config && x.config.Name == name) || (x.subControl && x.subControl.Name == name) });
        else
            return component;
        if (!component)
            component = this.MessageBars.find(x => { return x.name == name });
        else
            return component;
        if (!component)
            component = this.FormsDetails.find(x => { return x.name == name });
        else
            return component;
        if (!component)
            component = this.Charts.find(x => { return x.name == name });
        else
            return component;
        if (!component)
            component = this.Dashboards.find(x => { return x.name == name });
        else
            return component;
        if (!component)
            component = this.Htmls.find(x => { return x.name == name });
        else
            return component;
        if (!component && this.FileBox && this.FileBox.name == name)
            component = this.FileBox;
        if (!component && this.FileBoxWorkFlow && this.FileBoxWorkFlow.name == name)
            component = this.FileBoxWorkFlow;


        return component;
    }

    public Remove(name: string) {
        let index: number = -1;
        index = this.Tables.findIndex(x => { return x.name == name });
        if (index > -1) {
            this.Tables.splice(index, 1);
            return;
        }

        index = this.ChildComponents.findIndex(x => { return x.name == name || (x.subControl && x.subControl.Name == name) });
        if (index > -1) {
            this.ChildComponents.splice(index, 1);
            return;
        }


        index = this.FloatPanels.findIndex(x => { return x.name == name || (x.subControl && x.subControl.Name == name) });
        if (index > -1) {
            this.FloatPanels.splice(index, 1);
            return;
        }

        index = this.FormsDetails.findIndex(x => { return x.name == name });
        if (index > -1) {
            this.FormsDetails.splice(index, 1);
            return;
        }
        index = this.Charts.findIndex(x => { return x.name == name });
        if (index > -1) {
            this.Charts.splice(index, 1);
            return;
        }
        index = this.Dashboards.findIndex(x => { return x.name == name });
        if (index > -1) {
            this.Dashboards.splice(index, 1);
            return;
        }
        index = this.Htmls.findIndex(x => { return x.name == name });
        if (index > -1) {
            this.Htmls.splice(index, 1);
            return;
        }
        index = this.MessageBars.findIndex(x => { return x.name == name });
        if (index > -1) {
            this.MessageBars.splice(index, 1);
            return;
        }

    }
}
