import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { OperacionesDeSolicitudes } from "src/app/models/general.enum";
import { SolicitudPqrdComponent } from "../solicitud-pqrd/solicitud-pqrd.component";

@Component({
    selector: "app-solicitud-pqrd-reemplazo",
    templateUrl: "./solicitud-pqrd-reemplazo.component.html",
    styleUrls: ["./solicitud-pqrd-reemplazo.component.css"]
})
export class SolicitudPqrdReemplazoComponent extends SolicitudPqrdComponent {


    public OnInitEnd() {
        this.postSaveEmitter.subscribe(modelo => {
            
            //this.utility.VerModalOk("Se asignó un oficio a la Solicitud con código: " + "\n\r" + modelo.data.data.Codigo);
            this.utility.VerModalOk("El proceso de carga de archivo se ejecutó correctamente.");
        });
    }

    _Guardar(form: FormGroup, configButtons?: any): void {
        
        if (this.modelo.modelo.DataAdd)
              this.modelo.modelo.DataAdd["OperacionSolicitud"] =
                OperacionesDeSolicitudes.ReemplazarDocumento;
            else
              this.modelo.modelo.DataAdd = {
                OperacionSolicitud: OperacionesDeSolicitudes.ReemplazarDocumento
              }; 
    
        super._Guardar(form, configButtons);

    }
}
