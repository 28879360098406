import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControllerMethods, Paginas } from '../../../models/general.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';

import { BaseCrud } from '../../../bussines/base-crud';
import { BaseServiceService } from '../../../services/base-service.service'
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { TDocumentoExpedienteComponent } from '../../bussines/tdocumento-expediente/tdocumento-expediente.component';
import { Utilities } from '../../../helpers/utilities';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-doble-auth',
  templateUrl: './doble-auth.component.html',
  styleUrls: ['./doble-auth.component.css']
})
export class DobleAuthComponent extends TDocumentoExpedienteComponent implements OnInit {

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);

  }

  IdExpediente: any;
  CodigoExpediente: any = "";
  ObservacionCierre: any = "";
  DataResult: any = "";
  BtnCerrar: any;
  IdEstado: any = 0;
  @Output() public onCloseExpediente = new EventEmitter<any>();

  public OnInit() {
    this.BtnCerrar = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { this.IdEstado = 1; return x.Name == "BtnCerrar"; });
  }
  public OnActionEventHandler() {
    if (this.BtnCerrar) {
      this.BtnCerrar.Hidden = true;
      this.SetModelToControls();
    }
    this.consumeSingService()
  }

  public consumeSingService() {

    if (this.modelo.modelo.Pin) {
      let IdTipoDocumental = null;
      let IdCarpeta = null;
      if (this.modelo.modelo.IdTipoDocumental)
        IdTipoDocumental = this.modelo.modelo.IdTipoDocumental
      if (this.modelo.modelo.IdCarpeta)
        IdCarpeta = this.modelo.modelo.IdCarpeta
      let obj = this.utility.GetApiModel('CloseExpediente', null)
      obj = {
        IdExpediente: this.IdExpediente,
        DataAdd: {
          Pin: this.modelo.modelo.Pin,
          IdTipoIdentificacion: 1,
          GUIDPlantilla: this.modelo.modelo.GUIDPlantilla,
          GUIDServicio: this.modelo.modelo.GUIDServicio,
          IdCarpeta: IdCarpeta,
          IdTipoDocumental: IdTipoDocumental,
          ObservacionCierre: this.ObservacionCierre,
          CodigoExpediente: this.CodigoExpediente,
          TipoProceso: 'Cierre',
          TipoBodega: 'Gestión'
        }
      };

      let info = {
        componentName: this.componentName, controlName: 'CerrarExpediente',
        processMessage: 'Realizando la firma del documento'
      };
      return this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/CloseExpediente', false, false).then(data => {

        if (data.Data.estado != '0') {
          this.IdEstado = 1;
          this.utility.VerModalError(data.Data.mensaje);
        }
        else {
          this.IdEstado = 2;
          this.utility.VerModalOk(data.Data.mensaje);
        }
        
        //Ocultar modal -> $("#dobleAuthComponent").closest(".modal-dialog").hide();
        //Ejecutar promesa al cerrar modal (por ende, toca cerrar con el botón) ->
        /*NOTE: El modal que lo contenga debe ser un modal (con modal-dialog -> {modal-content, modal-footer} y
        * modal-footer debe tener un botón con clase .cancel (que cierre el modal y resuelva la promesa).
        * POR DEFECTO: El modal tiene un modal-footer con un botón que tiene una clase .cancel.
        */

        try {
          //$("#dobleAuthComponent").closest(".modal-dialog").find(".modal-footer").hide();
          //$("#dobleAuthComponent").closest(".modal-dialog").find(".modal-footer").find(".cancel")[0].click();
          
          /*NOTE: se hace el respectivo cambi ya que con el .hide al cerrar el expediente y volverlo a abrir
          *al momento de volverlo a cerrar no funcionaba bien el boton de visualizar la contraseña
          *Yair Romero Ramirez-25/07/2023
        */
          //$(".modal-dialog").hide();
          $(".modal-dialog .ng-star-inserted").click()
        }
        catch {
          //El modal fue cerrado antes
        }

        // this.componentsContainer.Tables[0].ReCargarGrilla();
        // this.Limpiar();
        this.onCloseExpediente.emit(data.Data);
      }).catch(err => {
        this.utility.VerModalError(err);

      });
    }
    else {
      this.IdEstado = 1;
      this.utility.VerModalWarning({ titulo: 'Alerta', descripcion: 'El Campo Pin es requerido..' }).then(result => {
        this.BtnCerrar.Hidden = false;
        this.SetModelToControls();
      }).catch(err => { this.utility.logger.LogError(err, {}) });
    }

  }


}
