import { AfterViewInit, Compiler, Component, ComponentFactoryResolver, Injector, Input, NgModuleFactory, OnChanges, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { StaticData } from 'src/app/helpers/static-data';
import { Utilities } from 'src/app/helpers/utilities';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { ClientPluginLoaderService } from 'src/app/services/plugin-loader/client-plugin-loader.service';
import { GeneralFormComponent } from '../general-form/general-form.component';

const SystemJs = window['System'];

@Component({
  selector: 'app-dynamic-plugin',
  templateUrl: './dynamic-plugin.component.html',
  styleUrls: ['./dynamic-plugin.component.css']
})
export class DynamicPluginComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('pluginContainer', { read: ViewContainerRef, static: false }) pluginContainer: ViewContainerRef;
  public currentPluginComponent: any = null;
  @Input() public config: any;
  @Input() public name: string = "";

  constructor(public compiler: Compiler,
    private injector: Injector,
    public componentFactoryResolver: ComponentFactoryResolver,
    public pluginLoader: ClientPluginLoaderService,
    public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    
  }

  ngOnInit() {
    
    if (this.config) {
      this.name = this.config.Name;
    }
  }

  load<T>(pluginName): Promise<NgModuleFactory<T>> {


    return SystemJs.import(pluginName).then(
      module => (module.default) ? module.default : module
    );

  }


  LoadPlugs() {
    const url = "../../../assets/plugins/prueba/prueba-libreria.umd - Copy.js";
    const umlMyLib = "../../../assets/plugins/my-lib.umd.js";
    this.load(umlMyLib).then(moduleFactoryMyLib => {
      

      // moduleFactory['PruebaLibreriaModule'].ɵinj.imports[0].push(AppModule);
      console.log(moduleFactoryMyLib);

      this.compiler.compileModuleAndAllComponentsAsync(moduleFactoryMyLib['MyLibModule'])
        .then(compiledMyLib => {
          
          console.log(compiledMyLib);
          window['myLib'] = moduleFactoryMyLib;
          


          this.load(url).then(moduleFactory => {
            

            // moduleFactory['PruebaLibreriaModule'].ɵinj.imports[0].push(AppModule);
            console.log(moduleFactory);

            this.compiler.compileModuleAndAllComponentsAsync(moduleFactory['PruebaLibreriaModule'])
              .then(compiled => {
                
                console.log(compiled);


                const factory = compiled.componentFactories[0];

                this.currentPluginComponent = this.pluginContainer.createComponent(factory, 0);

                //this.currentPluginComponent.instance.generalForm = new GeneralFormComponent(this.baseService, this.utility);
                //this.currentPluginComponent.instance.generalForm.modelo = this.currentPluginComponent.instance.modelo;
                // this.currentPluginComponent.instance.dataTableType=DataTableComponent;
                //this.currentPluginComponent.instance.dataTableType=DataTableComponent;
                this.currentPluginComponent.instance.GetComponentTypeByName = this.GetComponentTypeByName;
                this.currentPluginComponent.instance.baseService = this.baseService;
                this.currentPluginComponent.instance.utility = this.utility;
                this.currentPluginComponent.instance.externalConfig = { Name: 'ajsdkjdjkasjdksadjaskdksadjsakdj' };
                if (this.currentPluginComponent.instance.Init)
                  this.currentPluginComponent.instance.Init();
                

              });



          });


        });


    });

  }
  ngAfterViewInit(): void {
    
    //this.LoadPlugs();
    this.RenderComponent();
  }

  async ngOnChanges() {
    
    //  await this.RenderComponent();
  }

  ngOnDestroy() {
    this.DestroyComponent();
  }

  GetComponentTypeByName(name) {
    // return current.AppModule['ɵmod'].declarations.find(x => x.name == name);
    const factories = Array.from<any>(this.componentFactoryResolver['_factories'].keys());
    return factories.find(function (x) { return x.name === name; });
  }
  private async RenderComponent() {
    
    this.DestroyComponent();


    this.pluginLoader.Load(this.config).then(moduleFactory => {

      
      this.compiler.compileModuleAndAllComponentsAsync(moduleFactory[(this.config.Module) ? this.config.Module : this.config.Name])
        .then(compiled => {
          



          const factory = compiled.componentFactories[0];

          this.currentPluginComponent = this.pluginContainer.createComponent(factory, 0);

          if (this.config.InstanceGeneralForm) {
            this.currentPluginComponent.instance.generalForm = new GeneralFormComponent(this.baseService, this.utility, this.helperImages, this.helperRules, this.helperActions);
            this.currentPluginComponent.instance.generalForm.modelo = this.currentPluginComponent.instance.modelo;
          }


          this.currentPluginComponent.instance.GetComponentTypeByName = name => {
            return this.GetComponentTypeByName(name);
          }
          this.currentPluginComponent.instance.baseService = this.baseService;
          this.currentPluginComponent.instance.utility = this.utility;
          this.currentPluginComponent.instance.helperImages = this.helperImages;
          this.currentPluginComponent.instance.helperRules = this.helperRules;
          this.currentPluginComponent.instance.helperActions = this.helperActions;
          this.currentPluginComponent.instance.staticData = StaticData;
          this.currentPluginComponent.instance.externalConfig = { Name: 'ajsdkjdjkasjdksadjaskdksadjsakdj' };
          if (this.config.ComponentTypes) {
            this.config.ComponentTypes.forEach(type => {
              type.Type = this.GetComponentTypeByName(type.Name);
            });
          }
          if (this.currentPluginComponent.instance.Init)
            this.currentPluginComponent.instance.Init();
          

        });
    });

    // this.component = await this.dynamicContentService.GetComponent(
    //   this.componentName
    // );
    // this.container.insert(this.component.hostView);
  }

  private DestroyComponent() {
    if (this.currentPluginComponent) {
      this.currentPluginComponent.destroy();
      this.currentPluginComponent = null;
    }
  }
}
