import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


interface resourceForLoad {
  name: string;
  src: string;
  group: string;
  isFirst?: boolean;
}

export const StypeStore: resourceForLoad[] = [

  { name: 'jquery.dataTables', src: "https://cdn.datatables.net/1.10.19/css/jquery.dataTables.min.css", isFirst: true, group: "dataTables" },
  { name: 'dataTables', src: "./assets/datatables.net/css/datatables.css", group: "dataTables" },
  { name: 'responsive.dataTables', src: "https://cdn.datatables.net/responsive/2.2.3/css/responsive.dataTables.min.css", group: "dataTables" },
  // // { name: 'buttons.ee.min', src:"https://cdn.datatables.net/buttons/1.5.6/css/buttons.ee.min.css", group: "dataTables" },
  { name: 'jstree', src: "./assets/styles/jstree.min.css", group: "general" },
  { name: 'trix', src: "./assets/trix-master/dist//trix.css", group: "general" },
  { name: 'jquery-ui', src: "//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css", group: "general" },
  { name: 'jtoggler', src: "./assets//jToggler-master//jtoggler.styles.css", group: "general" },
  { name: 'bootstrap', src: "./assets/bootstrap-material-design-master/css/bootstrap.css", group: "general" },
  { name: 'fontawesome', src: "https://use.fontawesome.com/releases/v5.8.2/css/all.css", group: "general" }
];
// export const StypeStore: resourceForLoad[] = [

//   // { name: 'jquery.dataTables', src:"https://cdn.datatables.net/1.10.19/css/jquery.dataTables.min.css", isFirst:true, group: "dataTables" },
//   // { name: 'dataTables', src:"./assets/datatables.net/css/datatables.css", group: "dataTables" },
//   // { name: 'responsive.dataTables', src:"https://cdn.datatables.net/responsive/2.2.3/css/responsive.dataTables.min.css", group: "dataTables" },
//   // // { name: 'buttons.ee.min', src:"https://cdn.datatables.net/buttons/1.5.6/css/buttons.ee.min.css", group: "dataTables" },
//   // { name: 'jstree', src:"./assets/styles/jstree.min.css", group: "general" },
//   // { name: 'trix', src:"./assets/trix-master/dist//trix.css", group: "general" },
//   // { name: 'jquery-ui', src:"//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css", group: "general" },
//   // { name: 'jtoggler', src:"./assets//jToggler-master//jtoggler.styles.css", group: "general" },
//   // { name: 'bootstrap', src:"./assets/bootstrap-material-design-master/css/bootstrap.css", group: "general" },
//   // { name: 'fontawesome', src:"https://use.fontawesome.com/releases/v5.8.2/css/all.css", group: "general" }
// ];
export const ScriptStore: resourceForLoad[] = [


  { name: 'picker', src: './assets/js/pickadate/picker.js', isFirst: true, group: 'picker' },
  { name: 'picker.date', src: './assets/js/pickadate/picker.date.js', group: 'picker' },
  { name: 'picker.time', src: './assets/js/pickadate/picker.time.js', group: 'picker' },
  { name: 'dataTables', src: './assets/js/datatables.net/jquery.dataTables.js', isFirst: true, group: 'dataTables' },
  { name: 'dataTables.select', src: './assets/js/datatables.net/dataTables.select.min.js', group: 'dataTables' },
  { name: 'dataTables.responsive.min', src: 'https://cdn.datatables.net/responsive/2.2.3/js/dataTables.responsive.min.js', group: 'dataTables' },
  { name: 'dataTables.buttons.min', src: "https://cdn.datatables.net/buttons/1.5.6/js/dataTables.buttons.min.js", group: 'dataTables' },
  { name: 'buttons.colVis.min', src: "https://cdn.datatables.net/buttons/1.5.6/js/buttons.colVis.min.js", group: 'dataTables' },
  { name: 'buttons.flash.min', src: "https://cdn.datatables.net/buttons/1.5.6/js/buttons.flash.min.js", group: 'dataTables' },
  { name: 'buttons.html5.min', src: "https://cdn.datatables.net/buttons/1.5.6/js/buttons.html5.min.js", group: 'dataTables' },
  { name: 'buttons.print.min', src: "https://cdn.datatables.net/buttons/1.5.6/js/buttons.print.min.js", group: 'dataTables' },
  { name: 'pdfmake', src: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.57/pdfmake.js", group: 'dataTables' },
  { name: 'vfs_fonts', src: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.57/vfs_fonts.js", group: 'dataTables' },
  { name: 'dataTables.scroller.min', src: "https://cdn.datatables.net/scroller/2.0.1/js/dataTables.scroller.min.js", group: 'dataTables' },
  { name: 'dataTables.rowGroup.min', src: "https://cdn.datatables.net/rowgroup/1.1.2/js/dataTables.rowGroup.min.js", group: 'dataTables' },
  { name: '', src: "./assets/vakata-jstree/dist/jstree.js", group: 'general' },

  { name: 'OrgChart', src: "https://balkangraph.com/js/latest/OrgChart.js", group: 'general' },
  { name: 'jszip', src: "https://cdnjs.cloudflare.com/ajax/libs/jszip/3.2.0/jszip.js", group: 'general' },
  { name: 'trix', src: "./assets/trix-master/dist/trix.js", group: 'general' },
  { name: 'bootstrap-notify', src: "./assets/bootstrap-notify-master/bootstrap-notify.js", group: 'general' },
  { name: 'jquery-ui', src: "https://code.jquery.com/ui/1.12.1/jquery-ui.js", group: 'general' },
  { name: 'jquery-handler-toolkit', src: "./assets/jquery-handler-toolkit.js-master/jquery-handler-toolkit.js", group: 'general' },
  { name: 'FileSaver', src: "./assets/js/fileSaver/src/FileSaver.js", group: 'general' },
  { name: 'xlsx.full.min', src: "./assets/js/xlsx/xlsx.full.min.js", group: 'general' },

  { name: 'popper', src: './assets/bootstrap-material-design-master/js/popper.min.js', group: 'general' },
  { name: 'bootstrap', src: './assets/bootstrap-material-design-master/js/bootstrap.js', group: 'general' },
  { name: 'moment', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js', isFirst: true, group: 'moment' },
  { name: 'moment-locate', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/locale/es.js', group: 'moment' },
  { name: 'jtoggler', src: './assets/jToggler-master/jtoggler.js', group: 'general' },

];
@Injectable({
  providedIn: 'root'
})
export class DynamicResourceLoaderServiceService {
  private scripts: any = {};
  private styles: any = {};
  constructor(public http: HttpClient) {
    StypeStore.forEach((script: any) => {
      this.styles[script.name] = {
        loaded: false,
        src: script.src,
        group: script.group,
        isFirst: script.isFirst
      };
    });
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        group: script.group,
        isFirst: script.isFirst
      };
    });
  }
  LoadAll() {



    setTimeout(() => {

      this.LoadAllScripts();
    }, 200);

    setTimeout(() => {

      this.loadStyles();
    }, 400);


  }

  LoadAllScripts() {

    let scriptsGeneral: string[] = ScriptStore.filter(x => { return x.group == "general" }).map(x => { return x.name });
    this.loadDataTable();
    this.loadPicker();
    this.loadArray(scriptsGeneral).then(data => {

    }).catch(error => console.error(error));
    this.load('moment').then(data => {
      this.load('moment-locate').then(data => {

      }).catch(error => console.error(error));
    }).catch(error => console.error(error));

  }
  loadDataTable() {


    this.load('dataTables').then(data => {

      let scripts: string[] = ScriptStore.filter(x => { return x.group == "dataTables" && x.isFirst != true }).map(x => { return x.name });
      this.loadArray(scripts).then(data => {

      }).catch(error => console.error(error));
    }).catch(error => console.error(error));
  }

  loadPicker() {
    this.load('picker').then(data => {
      this.load('picker.date').then(data => {
        this.load('picker.time').then(data => {

        }).catch(error => console.error(error));
      }).catch(error => console.error(error));
    }).catch(error => console.error(error));
  }

  loadArray(scripts: string[]) {
    const promises: any[] = [];
    if (scripts && scripts.length > 0)
      scripts.forEach((script) => promises.push(this.loadScript(script)));
    else {
      ScriptStore.forEach((script) => promises.push(this.loadScript(script.name)));
    }
    return Promise.all(promises);
  }
  load(...scripts: string[]) {
    const promises: any[] = [];
    if (scripts && scripts.length > 0)
      scripts.forEach((script) => promises.push(this.loadScript(script)));
    else {
      ScriptStore.forEach((script) => promises.push(this.loadScript(script.name)));
    }
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ name: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ name: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ name: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ name: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }


  loadStylesArray(styles: string[]) {
    const promises: any[] = [];
    if (styles && styles.length > 0)
      styles.forEach((style) => promises.push(this.loadStyle(style)));
    else {
      StypeStore.forEach((style) => promises.push(this.loadStyle(style.name)));
    }
    return Promise.all(promises);
  }
  loadStyles(...styles: string[]) {
    const promises: any[] = [];
    if (styles && styles.length > 0)
      styles.forEach((style) => promises.push(this.loadStyle(style)));
    else {
      StypeStore.forEach((style) => promises.push(this.loadStyle(style.name)));
    }
    return Promise.all(promises);
  }
  loadStyle(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.styles[name].loaded) {
        //load script
        let link: any = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = this.styles[name].src;
        if (link.readyState) {  //IE
          link.onreadystatechange = () => {
            if (link.readyState === "loaded" || link.readyState === "complete") {
              link.onreadystatechange = null;
              this.styles[name].loaded = true;
              resolve({ name: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          link.onload = () => {
            this.styles[name].loaded = true;
            resolve({ name: name, loaded: true, status: 'Loaded' });
          };
        }
        link.onerror = (error: any) => resolve({ name: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        resolve({ name: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }



  LoadAllByResource(resources: any[]) {
    return new Promise((resolve, reject) => {
      let styles: any[] = resources.filter(x => { return x.Type == "StyleSheet" });
      let scripts: any[] = resources.filter(x => { return x.Type == "JavaScript" });
      let htmls: any[] = resources.filter(x => { return x.Type == "Html" });

      this.loadHtmlsArrayByResource(htmls).then(_htmls => {

        this.loadStylesArrayByResource(styles).then(_styles => {

          this.loadScriptsArrayByResource(scripts).then(_scripts => {

            resolve({ htmls: _htmls,scripts:_scripts, loaded: true, status: 'Loaded' });

          }).catch(error => {
            console.error(error, scripts);
            reject({ error: error, loaded: false, status: 'Error', Type: 'JavaScript' });
          });

        }).catch(error => {
          console.error(error, styles);
          reject({ error: error, loaded: false, status: 'Error', Type: 'StyleSheet' });
        });

      }).catch(error => {
        console.error(error, htmls);
        reject({ error: error, loaded: false, status: 'Error', Type: 'Html' });
      });





    });
  }

  LoadScriptsStylesByResource(resources: any[]) {
    return new Promise((resolve, reject) => {
      let styles: any[] = resources.filter(x => { return x.Type == "StyleSheet" });
      let scripts: any[] = resources.filter(x => { return x.Type == "JavaScript" });
     

 

        this.loadStylesArrayByResource(styles).then(_styles => {

          this.loadScriptsArrayByResource(scripts).then(_scripts => {

            resolve({ scripts:_scripts, loaded: true, status: 'Loaded' });

          }).catch(error => {
            console.error(error, scripts);
            reject({ error: error, loaded: false, status: 'Error', Type: 'JavaScript' });
          });

        }).catch(error => {
          console.error(error, styles);
          reject({ error: error, loaded: false, status: 'Error', Type: 'StyleSheet' });
        });

   





    });
  }
  loadStylesArrayByResource(styles: any[]) {
    const promises: any[] = [];
    if (styles && styles.length > 0)
      styles.forEach((style) => promises.push(this.loadStyleByResource(style)));

    return Promise.all(promises);
  }
  loadStylesByResource(...styles: any[]) {
    const promises: any[] = [];
    if (styles && styles.length > 0)
      styles.forEach((style) => promises.push(this.loadStyleByResource(style)));

    return Promise.all(promises);
  }
  loadStyleByResource(style: any) {
    return new Promise((resolve, reject) => {
      if (!this.styles[style.Name]) {
        this.styles[style.Name] = {
          loaded: false,
          src: style.Src,
          group: style.Group,
          isFirst: style.IsFirst
        };
      }
      if (!this.styles[style.Name].loaded) {
        //load script
        let link: any = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = this.styles[style.Name].src;
        if (link.readyState) {  //IE
          link.onreadystatechange = () => {
            if (link.readyState === "loaded" || link.readyState === "complete") {
              link.onreadystatechange = null;
              this.styles[style.Name].loaded = true;
              resolve({ name: style.Name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          link.onload = () => {
            this.styles[style.Name].loaded = true;
            resolve({ name: style.Name, loaded: true, status: 'Loaded' });
          };
        }
        link.onerror = (error: any) => resolve({ name: style.Name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        resolve({ name: style.Name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  loadScriptsArrayByResource(scripts: any[]) {
    const promises: any[] = [];
    if (scripts && scripts.length > 0)
      scripts.forEach((script) => promises.push(this.loadScriptByResource(script)));
    return Promise.all(promises);
  }
  loadScriptsByResource(...scripts: any[]) {
    const promises: any[] = [];
    if (scripts && scripts.length > 0)
      scripts.forEach((script) => promises.push(this.loadScriptByResource(script)));

    return Promise.all(promises);
  }

  loadScriptByResource(_script: any) {
    return new Promise((resolve, reject) => {

      if (!this.scripts[_script.Name]) {
        this.scripts[_script.Name] = {
          loaded: false,
          src: _script.Src,
          group: _script.Group,
          isFirst: _script.IsFirst
        };
      }
      if (!this.scripts[_script.Name].loaded) {
        //load script
        let script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[_script.Name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[_script.Name].loaded = true;
              resolve({ name: _script.Name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[_script.Name].loaded = true;
            resolve({ name: _script.Name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ name: _script.Name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ name: _script.Name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  loadHtmlsArrayByResource(htmls: any[],resources?: any[]) {
    const promises: any[] = [];
    if(resources)
     htmls = resources.filter(x => { return x.Type == "Html" });
    if (htmls && htmls.length > 0)
      htmls.forEach((html) => promises.push(this.loadHtmlByResource(html)));
    return Promise.all(promises);
  }
  loadHtmlsByResource(...htmls: any[]) {
    const promises: any[] = [];
    if (htmls && htmls.length > 0)
      htmls.forEach((html) => promises.push(this.loadHtmlByResource(html)));

    return Promise.all(promises);
  }

  loadHtmlByResource(html: any) {
    return new Promise((resolve, reject) => {

      const headers = new HttpHeaders();
      // headers.append('Access-Control-Allow-Headers', 'Content-Type');
      headers.append('Access-Control-Allow-Methods', 'GET');
      headers.append('Access-Control-Allow-Origin', '*');


      // this.http.get<any>(html.Src,{}
      // ).subscribe(result => {
      //   
      //   resolve({ name: html.Name, loaded: true, status: 'Loaded', result: result });
      // }, error => {
      //   
      //   resolve({ name: html.Name, loaded: false, status: 'Loaded', error: error });
      //   console.error(error);
      //   alert("error");
      // });

      // this.http.jsonp<any>(html.Src,"Id"
      // ).subscribe(result => {
      //   
      //   resolve({ name: html.Name, loaded: true, status: 'Loaded', result: result });
      // }, error => {
      //   
      //   resolve({ name: html.Name, loaded: false, status: 'Loaded', error: error });
      //   console.error(error);
      //   alert("error");
      // });

      $.get(html.Src, function (result) {

        resolve({ name: html.Name, loaded: true, status: 'Loaded', result: result });

      }).fail(function (error) {

        resolve({ name: html.Name, loaded: false, status: 'Loaded', error: error });
        console.error(error);
        alert("error");
      });



    });
  }
}
