import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ControlActions, GeneralPageControlTypes } from "src/app/models/general.enum";
import { SolicitudCommonComponent } from '../common/solicitud-common.component';
import { GeneralFormComponent } from 'src/app/components/controls/general-form/general-form.component';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { Guid } from 'guid-typescript';
import { StaticData } from 'src/app/helpers/static-data';
import { DataTableComponent } from 'src/app/components/controls/data-table/data-table.component';

@Component({
  selector: 'app-busqueda-radicado',
  templateUrl: './busqueda-radicado.component.html',
  styleUrls: ['./busqueda-radicado.component.css']
})
export class BusquedaRadicadoComponent extends GeneralFormComponent implements OnInit {

  isSearch: boolean = null;
  filtroGeneral = "";
  GridViewRadicados: any;
  DivPqrs: any;
  DivEntrada: any;
  DivFactura: any;
  DivFactura2: any;
  DivPqrs2: any;
  DivMemorando: any;
  DivSalida: any;
  MensajeRadicados: any;
  UsuarioAutenticado: any;
  AreaEmpresaUsuario: any;
  RadTipoRadicacion: any;
  AccordionGeneral: any;
  GrillaReasignarUsuario: any;

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules, public helperActions: HelperActions) {

    super(baseService, utility, helperImages, helperRules, helperActions);
  }

  public OnInitEnd() {

    this.GridViewRadicados = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "GridViewRadicados" });
    this.DivPqrs = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivPqrs" });
    this.DivEntrada = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivEntrada" });
    this.DivFactura = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivFactura" });
    this.DivFactura2 = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivFactura2" });
    this.DivPqrs2 = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivPqrs2" });
    this.DivSalida = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivSalida" });
    this.DivMemorando = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivMemorando" });
    this.RadTipoRadicacion = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "RadTipoRadicacion" });
    this.AccordionGeneral = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AccordionGeneral" });


  }

  public AddComponentInstanceHandler(event) {
    if (event instanceof DataTableComponent) {
      if (
        event.name ==
        SolicitudCommonComponent.Controls.GrillaReasignarUsuario
      ) {
        event.parentContext = this;
      }
      this.componentsContainer.Tables.push(event);
    }
    super.AddComponentInstanceHandler(event);
  }

  public OnActionEventHandler(event) {
    this.UsuarioAutenticado = StaticData.Usuario.IdUsuario;
    this.AreaEmpresaUsuario = StaticData.Usuario.IdAreaEmpresa;
    switch (event.ControlType) {
      //CommandBar Options
      case GeneralPageControlTypes.CommandButton: {
        switch (event.Option.Name) {
          case SolicitudCommonComponent.CommandBarActions.Filtro: {
            this.validateRequiredControls(["IdTipoSolicitudBusqueda"], "true");
            this.validateRequiredControls(["Documento"], "false");
            this.validateRequiredControls(["IdUsuarioResponsable"], "false");
            break;
          }
          case SolicitudCommonComponent.CommandBarActions.Reasignar: {
            this.validateRequiredControls(["IdTipoSolicitudBusqueda", "Documento"], "false");
            setTimeout(() => {
              this.validateRequiredControls(["IdUsuarioResponsable"], "true");
            }, 500);
            this.modelo.modelo.IdUsuarioResponsable = null;
            var GridName = "GrillaReasignarUsuario";
            var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
            this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);
            break;
          }
          case SolicitudCommonComponent.CommandBarActions.Responder: {
            //debugger;
            this.validateRequiredControls(["IdTipoSolicitudBusqueda", "Documento"], "false");
            this.validateRequiredControls(["IdUsuarioResponsable"], "false");
            if (
              this.modelo.modelo.IdExpediente == null ||
              this.modelo.modelo.IdExpediente == ""
            ) {
              this.utility.VerModalError(
                "La solicitud no posee un expediente asignado"
              );
              return;
            }
            break;
          }
        }
        break;
      }
      //Custom Buttons
      case GeneralPageControlTypes.Button: {
        switch (event.Name) {
          case SolicitudCommonComponent.Controls.BtnBuscarRadicados: {
            this.Buscar(event.Name);
            break;
          }
          case SolicitudCommonComponent.Controls.BtnLimpiarRadicados: {
            this.controls.ForEach(control => {
              if (control.control && control.control.ModelContainer == "modelo")
                this.modelo.modelo[control.control.Name] = null;
            }
            );
            this.validateRequiredControls(["Documento"], "false");
            this.SetModelToControls();
            this.Buscar(event.Name);
            break;
          }
          case SolicitudCommonComponent.Controls.BtnLimpiarReasignar: {
            this.GrillaReasignarUsuario = this.componentsContainer.Tables.filter(
              (x) => x.name == "GrillaReasignarUsuario"
            )[0];
            this.GrillaReasignarUsuario.ReCargarGrilla();
            this.SetModelToControls();
            break;
          }
          case SolicitudCommonComponent.Controls.BtnGuardarComentario: {
            this.GuardarComentario();
            break;
          }
        }
      }
    }
    super.OnActionEventHandler(event);

    if(this.modelo.modelo.IdTipoSolicitudRadicado !=9){
      if(this.modelo.modelo.IdTipoRespuesta != 37){
        if (this.modelo.selectedItem.EsDigital == true){
          this.modelo.modeloVirtual.RadTipoRadicacion = "Digital"
        }else{
          this.modelo.modeloVirtual.RadTipoRadicacion = "Fisico"
        }
      } else {
        this.modelo.modeloVirtual.RadTipoRadicacion = "Mixto"
      }
    }
    this.SetModelToControls();
  }

  public OnDataTableEditHandler(event) {
    switch (event.name) {
      case 'DataTables_Radicados': {
        /**
         * Inicio Edward Morales 03/03/2023
         * Set modelo valores de grilla para opciones commandBar
         */
        const findItemClone = this.utility.Clone(event.data.data.findItem);
        this.modelo.selectedItem = findItemClone;
        this.modelo.modelo = this.modelo.selectedItem;
        this.modelo.modelo.UsuarioAutenticado = this.UsuarioAutenticado;
        this.modelo.modelo.AreaEmpresaUsuario = this.AreaEmpresaUsuario;

        var controllerRadicado = null;
        switch (this.modelo.modelo.IdTipoSolicitudRadicado) {
          case 5: //PQR
            controllerRadicado = 'TSolicitudPqrd';
            this.modelo.modelo.IdSolicitudPqrd = this.modelo.modelo.IdSolicitud;
            this.modelo.modelo.IdSolicitudPqrdPadre = this.modelo.modelo.IdSolicitudPadre;
            this.modelo.modelo.IdSolicitudPqrdAsociada = this.modelo.modelo.IdSolicitudAsociado;
            break;
          case 6: //Entrada
            controllerRadicado = 'TSolicitudRadicadoEntrada';
            this.modelo.modelo.IdSolicitudRadicadoEntrada = this.modelo.modelo.IdSolicitud;
            this.modelo.modelo.IdSolicitudRadicadoEntradaPadre = this.modelo.modelo.IdSolicitudPadre;
            this.modelo.modelo.IdSolicitudRadicadoEntradaAsociado = this.modelo.modelo.IdSolicitudAsociado;
            break;
          case 7: //Salida
            controllerRadicado = 'TSolicitudRadicadoSalida';
            this.modelo.modelo.IdSolicitudRadicadoSalida = this.modelo.modelo.IdSolicitud;
            this.modelo.modelo.IdSolicitudRadicadoSalidaPadre = this.modelo.modelo.IdSolicitudPadre;
            this.modelo.modelo.IdSolicitudRadicadoSalidaAsociado = this.modelo.modelo.IdSolicitudAsociado;
            break;
          case 8: //Factura
            controllerRadicado = 'TSolicitudRadicadoFactura';
            this.modelo.modelo.IdSolicitudRadicadoFactura = this.modelo.modelo.IdSolicitud;
            this.modelo.modelo.IdSolicitudRadicadoFacturaPadre = this.modelo.modelo.IdSolicitudPadre;
            this.modelo.modelo.IdSolicitudRadicadoFacturaAsociado = this.modelo.modelo.IdSolicitudAsociado;
            break;
          case 9: //Memorando
            controllerRadicado = 'TSolicitudRadicadoMemorando';
            this.modelo.modelo.IdSolicitudRadicadoMemorando = this.modelo.modelo.IdSolicitud;
            this.modelo.modelo.IdSolicitudRadicadoMemorandoPadre = this.modelo.modelo.IdSolicitudPadre;
            this.modelo.modelo.IdSolicitudRadicadoMemorandoAsociado = this.modelo.modelo.IdSolicitudAsociado;
            break;
        }

        if( this.modelo.modelo.IdTipoSolicitudRadicado != 7 && this.modelo.modelo.IdTipoSolicitudRadicado != 9){

        let ob = this.utility.GetApiModel("GetEstadoPrevioSolicitud", null);
        ob.QueryConfig = {
          Command: "ConsultaEstadoPrevioSolicitud",
          IsSp: true,
          Entity: {
            TipoSolicitud: this.modelo.modelo.IdTipoSolicitudRadicado == 5 ? "PQRS" :
            this.modelo.modelo.IdTipoSolicitudRadicado == 6 ? "Entrada" :
            "Factura",
            IdSolicitud: this.modelo.selectedItem.IdSolicitud,
          },
        };

        let info = {
          componentName: controllerRadicado,//this.componentName,
          controlName: "GetEstadoPrevioSolicitud",
          processMessage: "Cargando Estado previo de la Solicitud......",
        };

        this.baseService
          .Get(ob, null, info, /*this.componentName*/controllerRadicado, false, false)
          .then((data) => {

            this.modelo.modelo.IdEstadoPrevio = data.Data[0].IdEstadoSolicitud;
            this.SetModelToControls();

          })
          .catch((err) => { });
        }
        var obj = this.utility.GetApiModel('ConsultaDetalleMultiRadicados', this.componentName);

        obj.QueryConfig.Command = 'ConsultaDetalleMultiRadicados';
        obj.QueryConfig.IsSp = true;
        obj.QueryConfig.Entity = {
          TipoRadicado: event.modelo.modelo.TipoSolicitudRadicado,
          IdSolicitud: event.modelo.modelo.IdSolicitud,
          IdAreaEmpresa: null,
          IdSerie: null,
          IdSubSerie: null,
          IdVersionTablaRetencionDocumental: null
        };
        this.baseService.Get(obj, null,
          {
            componentName: this.componentName,
            method: 'ConsultaDetalleMultiRadicados',
            processMessage: 'Consultando detalle de radicado...'
          }, 'TExpediente')
          .then(data => {
            if (data.Data[0] != null) {
              if (data.Data[0].Message != null || data.Data[0].Message != undefined) {
                this.utility.VerModalWarning({
                  titulo: 'Alerta', descripcion: data.Data[0].Message,
                  verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                }).then(data => { });
              }
              else {

                this.modelo.modeloVirtual = data.Data[0];
                this.modelo.modeloVirtual.GridRadicadosAsociados = null;
                if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
                  this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
                }
                //this.modelo.modeloVirtual.RadTipoRadicado = event.modelo.modelo.TipoRadicado;
                this.modelo.modeloVirtual.RadTipoRadicado = event.modelo.modelo.TipoSolicitudRadicado;
                switch (this.modelo.modeloVirtual.RadTipoRadicado) {
                  case 'Radicado Pqrs':
                    this.DivPqrs.Hidden = false;
                    this.DivEntrada.Hidden = true;
                    this.DivFactura.Hidden = true;
                    this.DivFactura2.Hidden = true;
                    this.DivPqrs2.Hidden = false;
                    this.DivSalida.Hidden = true;
                    this.DivMemorando.Hidden = true;
                    this.RadTipoRadicacion.Hidden = false;
                    this.AccordionGeneral.Hidden = false;
                    break;
                  case 'Radicado Entrada':
                    this.DivPqrs.Hidden = true;
                    this.DivEntrada.Hidden = false;
                    this.DivFactura.Hidden = true;
                    this.DivFactura2.Hidden = true;
                    this.DivPqrs2.Hidden = true;
                    this.DivSalida.Hidden = true;
                    this.DivMemorando.Hidden = true;
                    this.RadTipoRadicacion.Hidden = false;
                    this.AccordionGeneral.Hidden = false;
                    break;
                  case 'Radicado Salida':
                    this.DivPqrs.Hidden = true;
                    this.DivEntrada.Hidden = true;
                    this.DivFactura.Hidden = true;
                    this.DivFactura2.Hidden = true;
                    this.DivPqrs2.Hidden = true;
                    this.DivSalida.Hidden = false;
                    this.DivMemorando.Hidden = true;
                    this.RadTipoRadicacion.Hidden = false;
                    this.AccordionGeneral.Hidden = false;
                    break;
                  case 'Radicado Factura':
                    this.DivPqrs.Hidden = true;
                    this.DivEntrada.Hidden = true;
                    this.DivFactura.Hidden = false;
                    this.DivFactura2.Hidden = false;
                    this.DivPqrs2.Hidden = true;
                    this.DivSalida.Hidden = true;
                    this.DivMemorando.Hidden = true;
                    this.RadTipoRadicacion.Hidden = false;
                    this.AccordionGeneral.Hidden = false;

                    break;
                  case 'Radicado Memorando':
                    this.DivPqrs.Hidden = true;
                    this.DivEntrada.Hidden = true;
                    this.DivFactura.Hidden = true;
                    this.DivFactura2.Hidden = true;
                    this.DivPqrs2.Hidden = true;
                    this.DivSalida.Hidden = true;
                    this.DivMemorando.Hidden = false;
                    this.RadTipoRadicacion.Hidden = true;
                    this.AccordionGeneral.Hidden = true;
                    if (this.modelo.modeloVirtual.RadDestinatariosCopias) {
                      this.modelo.modeloVirtual.RadDestinatariosCopias = JSON.parse(this.modelo.modeloVirtual.RadDestinatariosCopias);
                      /*const Anexos = JSON.parse(this.modelo.modeloVirtual.RadDestinatariosCopias);
                      let valorDestinatario = "";
                      Anexos.forEach(anexo => {
                        valorDestinatario += "Nombre: " + anexo.Nombre + "  Email: " + anexo.Email + '\n';
                      });
                      this.modelo.modeloVirtual.RadDestinatariosCopias = valorDestinatario;
                      */
                    }


                    break;
                }

                //debugger;
                /*if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
                  this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
                }*/

                this.modelo.modeloVirtual.IdSolicitud = event.modelo.modelo.IdSolicitud;

                this.SetModelToControls();
              }
            }

          }).catch(err => {
            this.utility.logger.LogError(err, obj, {
              componentName: this.componentName,
              method: 'ConsultaDetalleMultiRadicados',
              processMessage: 'Consultando detalle de radicado...'
            }, 'TExpediente')
          });
        break;
      }

    }

    super.OnDataTableEditHandler(event);
  }

  public OnLoadGridHandler(event) {
    this.FinConsultaGrilla(event);
  }

  public FinConsultaGrilla(event: any) {
    this.SetProcesando(false);
    if (this.isSearch == true) {
      this.utility.VerModalOk("Búsqueda Radicado Finalizada");
    } else if (this.isSearch == false) {
      this.utility.VerModalOk("Limpieza Campos Finalizada");
    }
    this.isSearch = null;
  }

  public SetModelo() {
    this.modelo.modelo.FiltroGeneral = '';
    let clone = this.utility.Clone(this.modelo.modelo);

    return {
      IdEmpresa: -1,
      IdTipoDocumento: clone.IdTipoDocumento,
      Documento: clone.Documento,
      PrimerNombre: clone.PrimerNombreBusqueda,
      PrimerApellido: clone.PrimerApellidoBusqueda,
      IdAreaEmpresaRadicado: clone.IdAreaEmpresaBusqueda,
      FechaInicial: clone.FechaInicial,
      FechaFinal: clone.FechaFinal,
      Codigo: clone.Codigo,
      IdEstadoSolicitud: clone.IdEstadoSolicitudBusqueda,
      Filtro: clone.Filtro,
      IdTipoSolicitud: clone.IdTipoSolicitudBusqueda
    };
  }

  public Buscar(boton) {
    debugger
    try {
      if ((boton == SolicitudCommonComponent.Controls.BtnBuscarRadicados &&
        this.modelo.modelo.IdTipoSolicitudBusqueda != null &&
        ((this.modelo.modelo.IdTipoDocumento != null &&
          this.modelo.modelo.Documento != null && this.modelo.modelo.Documento != '') ||
          this.modelo.modelo.IdTipoDocumento == null)) ||
        (boton == SolicitudCommonComponent.Controls.BtnLimpiarRadicados)) {
        this.SetProcesando(true);
        this.componentsContainer.Tables[0].QueryConfig.Entity = this.SetModelo();
        this.componentsContainer.Tables[0].cargarGrilla = true;
        this.componentsContainer.Tables[0]._CargarGrilla(true);
        if (boton == SolicitudCommonComponent.Controls.BtnBuscarRadicados) {
          this.isSearch = true;
        } else {
          this.isSearch = false;
        }
      } else {
        this.utility.VerModalWarning({ "titulo": "Alerta", "descripcion": "Campos obligatorios por diligenciar" });
      }
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }
  }

  public validateRequiredControls(controlsName: any, yesNo: any) {
    const action = {
      ControlDetails: {
        MappingData: [],
        MappingFilter: []
      },
      ControlType: "Action",
      ControlsForHidden: [],
      ControlsToUpdate: controlsName,
      Guid: Guid.create().toString(),
      IsPost: false,
      IsParentModel: false,
      MappingValues: [],
      MappingComponents: [],
      Property: "required",
      PropertyValue: yesNo,
      ResultType: "Url",
      SendQueryString: false,
      TimerAction: 0,
      Type: ControlActions[ControlActions.SetControlProperty]
    };
    const context = this;
    this.helperActions.SetControlsValue(action, context);
    super.SetModelToControls();
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {
      switch (controlName) {
        case 'IdTipoDocumento': {
          if (item) {
            this.validateRequiredControls(["Documento"], "true");
          } else {
            this.validateRequiredControls(["Documento"], "false");
          }
          break;
        }
        case 'IdTipoSolicitudBusqueda':{
          if(item)
            this.GetTipoRadicadoBusqueda()
          else {
            this.configs.ConfigIdEstadoSolicitudBusqueda.Context.items = [];
            this.modelo.modelo.IdEstadoSolicitudBusqueda = null;
            this.SetModelToControls();
          }


          break;

        }
      }
      super.OnSelectedComboBox(item, controlName, config);
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }

  public GetTipoRadicadoBusqueda() {
    /**
     * Inicio Edward Morales 13032023
     * Cargar estados existentes según tipo de radicado para agilizar búsqueda
     * */
    switch (this.modelo.modelo.IdTipoSolicitudBusqueda) {
      case 5: //PQR
        this.componentName = 'TSolicitudPqrd';
        break;
      case 6: //Entrada
        this.componentName = 'TSolicitudRadicadoEntrada';
        break;
      case 7: //Salida
        this.componentName = 'TSolicitudRadicadoSalida';
        break;
      case 8: //Factura
        this.componentName = 'TSolicitudRadicadoFactura';
        break;
      case 9: //Memorando
        this.componentName = 'TSolicitudRadicadoMemorando';
        break;
    }
    const that = this;
    let obj = this.utility.GetApiModel("GetTipoRadicadoBusqueda", null);
    obj.QueryConfig = {
      Command: "ConsultaEstadoSolicitudComboBox",
      IsSp: true,
      Entity: {
        IdEmpresa: -1,
        IdTipoSolicitudBusqueda: this.modelo.modelo.IdTipoSolicitudBusqueda,
        Filtro: null
      },
    };

    let info = {
      componentName: this.componentName,
      controlName: "GetTipoRadicadoBusqueda",
      processMessage: "Cargando Estados Radicados......",
    };

    this.baseService
      .Get(obj, null, info, this.componentName, false, false)
      .then((data) => {
        // debugger;
        const estados = data.Data;
        this.configs.ConfigIdEstadoSolicitudBusqueda.Context.SetItemsComboBox(estados)
        this.SetModelToControls();
      })
      .catch((err) => { });
  }

  _Guardar(form: FormGroup, configButtons?: any): void {
    // debugger;
    let obj: any = null;
    var controllerRadicado = null;
    switch (this.modelo.modelo.IdTipoSolicitudRadicado) {
      case 5: //PQR
        controllerRadicado = 'TSolicitudPqrd';
        this.modelo.modelo.IdSolicitudPqrd = this.modelo.modelo.IdSolicitud;
        break;
      case 6: //Entrada
        controllerRadicado = 'TSolicitudRadicadoEntrada';
        this.modelo.modelo.IdSolicitudRadicadoEntrada = this.modelo.modelo.IdSolicitud;
        break;
      case 7: //Salida
        controllerRadicado = 'TSolicitudRadicadoSalida';
        this.modelo.modelo.IdSolicitudRadicadoSalida = this.modelo.modelo.IdSolicitud;
        break;
      case 8: //Factura
        controllerRadicado = 'TSolicitudRadicadoFactura';
        this.modelo.modelo.IdSolicitudRadicadoFactura = this.modelo.modelo.IdSolicitud;
        break;
    }
    this.componentName = controllerRadicado;

    try {
      if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
        return;
      if (form.invalid)
        return;


      const _isEdit = this.IsEdit;
      // const that = this;
      const processMessage = (this.modelo.modelo.PkValue == "0" || this.modelo.modelo.PkValue == "") ? "Creando Registro de " + this.Pagina.Titulo : "Actualizando registro de " + this.Pagina.Titulo;

      if (configButtons && configButtons.Actions) {

        this.helperActions.ExecuteActionsControl(configButtons,
          false, null, this.modelo, this.permissionsConfig,
          this, null, new EventEmitter<any>(), this.componentsContainer,
          null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
      }
      // debugger;
      obj = this.utility.GetApiModel("Guardar", this.componentName, null, null, this.modelo.modelo);

      let isGeneralSave = true;

      // ////////Meter validacion antes de este
      if (isGeneralSave) {

        this.baseService.InsertUpdate(obj, form, {
          componentName: this.componentName + "Controller",
          method: "Guardar", processMessage: processMessage
        },
          false, this.componentName, false, true).then(data => {
            //debugger;
            this.FinGuardar(data.Data);

            if (_isEdit)
              this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
            else
              this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });


              //StaticData.GeneralConfig.ExtensionesValidas =  data.Data.CtrName;
            //this.OnInitEnd();
          }).catch(error => { this.SetProcesando(false); });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: "_Guardar" });
      this.utility.VerModalDanger().then(data => { });
      this.SetProcesando(false);
    }
    //super._Guardar(form, configButtons);
  }

  public GuardarComentario() {

    var controllerRadicado = null;
    switch (this.modelo.modelo.IdTipoSolicitudRadicado) {
      case 5: //PQR
        controllerRadicado = 'TSolicitudPqrd';
        this.modelo.modelo.IdSolicitudPqrd = this.modelo.modelo.IdSolicitud;
        break;
      case 6: //Entrada
        controllerRadicado = 'TSolicitudRadicadoEntrada';
        this.modelo.modelo.IdSolicitudRadicadoEntrada = this.modelo.modelo.IdSolicitud;
        break;
      case 7: //Salida
        controllerRadicado = 'TSolicitudRadicadoSalida';
        this.modelo.modelo.IdSolicitudRadicadoSalida = this.modelo.modelo.IdSolicitud;
        break;
      case 8: //Factura
        controllerRadicado = 'TSolicitudRadicadoFactura';
        this.modelo.modelo.IdSolicitudRadicadoFactura = this.modelo.modelo.IdSolicitud;
        break;
    }

    var lenComentario = this.modelo.modeloVirtual.Comentario;
    if (lenComentario != null && lenComentario.length > 0) {
      if (this.modelo.modelo.DataAdd) {
        this.modelo.modelo.DataAdd[
          "TextoComentario"
        ] = this.modelo.modeloVirtual.Comentario;
        this.modelo.modelo.DataAdd["GuardaComentario"] = "true";
      } else {
        this.modelo.modelo.DataAdd = {
          TextoComentario: this.modelo.modeloVirtual.Comentario,
          GuardaComentario: "true",
        };
      }
      this.utility.VerModalConfirmacionGuardar().then((modelo) => {
        if (modelo.dialogResult == true) {
          this.SetModelToControls();

          this.modelo.modelo.Token = StaticData.Usuario.Token;
          this.modelo.modelo.AppKey = StaticData.AppKey;
          //this.modelo.modelo.PkValue = this.modelo.modelo.IdSolicitudRadicadoEntrada;
          this.modelo.modelo.PkValue = this.modelo.modelo.IdSolicitud;


          let obj = this.utility.GetApiModel(
            "GuardarComentario",
            this.componentName,
            null,
            null,
            this.modelo.modelo
          );

          this.baseService
            .GuardarComentario(
              obj,
              null,
              {
                componentName: "BusquedaRadicadoComponent",//"SolicitudRadicadoEntradaComponent",
                method: "GuardarComentario",
                processMessage: "Procesando credenciales....",
              },
              controllerRadicado,//"TSolicitudRadicadoEntrada",
              false,
              true
            )
            .then((data) => {
              this.modelo.modeloVirtual.Comentario = null;
              this.SetModelToControls();

              const tbGrillaComentario = this.componentsContainer.Tables.find(
                (x) => {
                  return (
                    x.name == "DataTable_SolicitudRadicadoComentario"
                  );
                }
              );
              tbGrillaComentario.cargarGrilla = true;
              tbGrillaComentario.ReCargarGrilla();

              this.utility.VerModalOk(
                "Se agregó comentario al radicado: " +
                "\n\r" +
                this.modelo.modelo.Codigo
              );
            });
        }
      });
    } else {
      this.utility.VerModalDanger({
        titulo: "Valor Requerido",
        descripcion: "El campo comentario es requerido.",
      });
    }
  }

  ClearSearchDataTable(DataTableClear, DataTableName, ComponentName) {
    var InputValue = ""
    //this.GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == "GrillaAprobacionUsuario"; });
    this.utility.GetDataTabledtInstance(DataTableClear.dtElement, DataTableName, ComponentName).then(dtInstance => {
      dtInstance.search(InputValue).draw();
    }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })

  }
}

