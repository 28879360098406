import { Component, EventEmitter, Input, OnInit, ElementRef, Renderer2 } from "@angular/core";
import { ControlActions, ControllerMethods, EstadosSolicitud, FormActionsThroughEmitters, TipoObjetoParaPermisos, TiposConsecutivo, GeneralPageControlTypes } from "src/app/models/general.enum";

import { AnimationMetadataType } from "@angular/animations";
import { BaseServiceService } from "src/app/services/base-service.service";
import { CrudActions } from 'src/app/models/general.enum';
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from 'src/app/components/controls/file-uploader/file-uploader.component';
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { HelperActions } from "src/app/helpers/helper-actions";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { SolicitudCommonComponent } from "../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { Utilities } from "src/app/helpers/utilities";
import { validateContact } from "../../../contacto/contacto.component";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { DataTableDirective } from "angular-datatables";
import { BrowserStack } from "protractor/built/driverProviders";
import { DocumentTemplateSocketService } from "src/app/services/document-template-socket-service";

declare let fabric: any;
@Component({
  selector: 'app-solicitud-flujo-documental',
  templateUrl: './solicitud-flujo-documental.component.html',
  styleUrls: ['./solicitud-flujo-documental.component.css'],
  providers: [DocumentTemplateSocketService]
})
export class SolicitudFlujoDocumentalComponent extends SolicitudCommonComponent implements OnInit {

  isFilter: boolean = false;
  IdCausalRechazo: any;
  IdCuaderno: any;
  //crudButtonGestionVoBo: any;
  Observaciones: any;
  Tipofirma: any;
  CodigoOtp: any;
  HtmlOTP:any;
  TipofirmaEnvio: any;
  configPin: any;
  FiltroFirma: any;
  TipoFiltro: any
  configArchivosAdjuntos: any;
  crudButtons: any;
  fileBox: any;
  msgFileBox: any;
  DivContactos: any;
  plantillaButton: any;
  AcordionIntervinientes: any;
  modalOpenCrearContacto: boolean;
  withExternalUser: boolean = false;
  //GridViewAprobacionDocumento: any;
  //GridViewVistaBuenoDocumento: any;
  DivInfoFirma: any;

  SinFirmaDigital: any = null;
  MessageSinFirmaDigital: any;

  sent = [];
  received = [];

  constructor(public baseService: BaseServiceService, public utility: Utilities,
              public helperImages: HelperImages,
              public helperRules: HelperRules,
              public helperActions: HelperActions,
              private DocumentTemplateSocketService: DocumentTemplateSocketService,
              private el: ElementRef,
              private renderer: Renderer2) {
    super(baseService, utility, helperImages, helperRules, helperActions);

    DocumentTemplateSocketService.messages.subscribe(msg => {
      this.received.push(msg);
      console.log("Response from websocket: " + msg);
    });

  }

  public OnInitEnd() {
    this.SinFirmaDigital = StaticData.GeneralConfig.ConfigUbicaciones.find(x => { return x.IdTipoSolicitud == 29; }).ProcesarSinFirmaDigital;

    this.IdCausalRechazo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCausalRechazo"; });
    this.IdCuaderno = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCuaderno"; });
    //this.crudButtonGestionVoBo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Guardar_VoBo"; });
    this.Observaciones = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Observaciones"; });
    this.Tipofirma = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Tipofirma"; });
    this.CodigoOtp = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CodigoOtp"; });
    this.HtmlOTP = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "HtmlOTP"; });
    this.configPin = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Pin"; });
    this.FiltroFirma = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "FiltroProceso"; });
    this.DivInfoFirma = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "InformacionFirma"; });
    this.configArchivosAdjuntos = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "ArchivosAdjuntos"; });
    this.fileBox = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "FileBox_Documentos"; });
    this.crudButtons = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtons_641e9074-2139-854b-aae8-7cb5f31b9cc6"; });
    this.plantillaButton = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DescargarPlantilla"; });
    this.msgFileBox = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivMsgFileBox"; });
    this.DivContactos = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivContactos"; });
    this.AcordionIntervinientes = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AccordionGeneral"; });

    this.MessageSinFirmaDigital = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "MessageBar_SinFirmaDigital" });

    this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Pagina);
    this.startCountdown();

    this.postSaveEmitter.subscribe(event => {      
      if(this.Pagina.Pagina == 'ProcesarSolicitudFlujoDocumental'){
        setTimeout(() => { this.componentsContainer.ReloadTable("GridView_SolicitudFlujoDocumental", { IdEmpresa: -1 }, null, false); }, 2000);
      }
		});
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {

    try {
      debugger;
      if(item)
      {
        if (controlName === "IdVersionFlujoDocumental")
        {
          var obj = this.utility.GetApiModel('ConsultaFlujoDetalleSolicitud', this.componentName);

          obj.QueryConfig.Command = 'ConsultaFlujoDetalleSolicitud';
          obj.QueryConfig.IsSp = true;
          obj.QueryConfig.Entity = { IdVersion: item.IdVersionFlujoDocumental };

          this.baseService.Get(obj, null,
            {
              componentName: this.componentName,
              method: 'ConsultaFlujoDetalleSolicitud',
              processMessage: 'Consultando datos del flujo...'
            }, 'TSolicitudFlujoDocumental')
            .then(data => {
              debugger;
              if (data.Data[0] != null) {
                this.modelo.modeloVirtual.AreaResponsable = data.Data[0].AreaEmpresaResponsable;
                this.modelo.modeloVirtual.Consecutivo = data.Data[0].Consecutivo;
                var configFlow = JSON.parse(data.Data[0].Configuracion);
                var UsersGrid = JSON.parse(data.Data[0].Configuracion);
                let UsuariosGestion = '';
                for(var i = 0; i <= configFlow.UsuariosIntervinientes.length - 1; i++)
                {
                  configFlow.UsuariosIntervinientes[i]['EstadoEjecucion'] = null;
                  configFlow.UsuariosIntervinientes[i]['IdSolicitudFlujoHijo'] = null;
                  UsersGrid.UsuariosIntervinientes[i]['Aprobador'] = Boolean(UsersGrid.UsuariosIntervinientes[i]['Aprobador']) ? 'Si' : 'No';
                  UsersGrid.UsuariosIntervinientes[i]['NotificarEmail'] = Boolean(UsersGrid.UsuariosIntervinientes[i]['NotificarEmail']) ? 'Si' : 'No';
                }

                this.modelo.modelo.UsuariosGestionActual = '';
                this.modelo.modelo.Configuracion = JSON.stringify(configFlow);
                let date = new Date();
                date.setDate(date.getDate() + parseInt(data.Data[0].DiasVencimiento));
                let day = (date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate());
                let month = (date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1).toString() : date.getMonth()+1);
                this.modelo.modelo.FechaVencimiento = date; //day + '-' + month + '-' + date.getFullYear();
                this.modelo.modeloVirtual.GridUsuariosIntervinientes = UsersGrid.UsuariosIntervinientes;

                if(data.Data[0].UsuarioExterno)
                {
                  this.withExternalUser = true;
                  this.DivContactos.Hidden = false;
                  this.AcordionIntervinientes.Hidden = true;
                  this.modelo.modeloVirtual.ContactosIntervinientes = configFlow.UsuariosIntervinientes;
                  this.ValidaOrdenFlujo();
                }
                else
                {
                  this.DivContactos.Hidden = true;
                  this.AcordionIntervinientes.Hidden = false;
                  this.modelo.modeloVirtual.ContactosIntervinientes = [];
                }

                this.SetModelToControls();
              }
              else {
                this.utility.VerModalDanger({ "titulo": "Area de la entidad", "descripcion": "El Área de la entidad no posee Departamento y Ciudad, favor comuniquese con el administrador del sistema" });
              }
            }).catch(err => {
            this.utility.logger.LogError(err, obj, {
              componentName: this.componentName,
              method: 'ConsultaFlujoDetalleSolicitud',
              processMessage: 'Consultando datos del flujo...'
            }, 'TSolicitudFlujoDocumental')
          });
        }
        if (controlName === "IdCarpeta") {
          if (!this.IdCuaderno.Hidden) {
            if (this.modelo.expediente) {
              this.modelo.modeloVirtual.IdCuaderno = null;
              this.configs.ConfigIdCuaderno.Params = { IdExpediente: this.modelo.expediente.IdExpediente, IdCarpeta: this.modelo.modeloVirtual.IdCarpeta };
              this.configs.ConfigIdCuaderno.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
            }
          }
        }
      }

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: "OnSelectedComboBox", controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }

    super.OnSelectedComboBox(item, controlName, config);
  }

  public OnCreatePermissionsConfig(TipoObjeto: any) {

    this.permissionsConfig = {
      TipoObjetoParaPermiso: TipoObjeto,
      Pagina: this.Pagina, clear: true, expediente: null, CreatedRowConfig: {}
    };
    this.RaiseFormEvent('OnCreatePermissionsConfig', true);
  }

  public OnDataTableEditHandler(event) {
    //debugger;
    if (event.name == "GrillaAsignarExpediente") {

      // this.configs.ConfigIdCarpeta.Params = { IdExpediente: this.modelo.expediente.IdExpediente, IdEmpresa: StaticData.Usuario.IdEmpresa };
      // this.configs.ConfigIdCarpeta.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);


      if (!this.IdCuaderno.Hidden) {
        if (this.modelo.expediente) {
          if (this.modelo.modeloVirtual.IdCarpeta) {
            this.modelo.modeloVirtual.IdCuaderno = null;
            this.configs.ConfigIdCuaderno.Params = { IdExpediente: this.modelo.expediente.IdExpediente, IdCarpeta: this.modelo.modeloVirtual.IdCarpeta };
            this.configs.ConfigIdCuaderno.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
          }
        }
      }
      super.OnDataTableEditHandler(event);
    }
    else if (event.name == "GridView_SolicitudFlujoDocumental")
    {

      var Data = event.data.data.findItem;
      var config = JSON.parse(Data.Configuracion);
      for(var i = 0; i <= config.UsuariosIntervinientes.length - 1; i++)
      {
        config.UsuariosIntervinientes[i]['Aprobador'] = Boolean(config.UsuariosIntervinientes[i]['Aprobador']) ? 'Si' : 'No';
        config.UsuariosIntervinientes[i]['NotificarEmail'] = Boolean(config.UsuariosIntervinientes[i]['NotificarEmail']) ? 'Si' : 'No';
      }
      this.modelo.modelo.Configuracion = Data.Configuracion;
      this.modelo.modelo.FechaVencimiento = Data.FechaVencimiento;
      this.modelo.modelo.IdSolicitudFlujoDocumental = Data.IdSolicitudFlujoDocumental;
      this.modelo.modeloVirtual.GridUsuariosIntervinientes = config.UsuariosIntervinientes;
      this.modelo.modeloVirtual.ContactosIntervinientes = config.UsuariosIntervinientes;
      this.modelo.modeloVirtual.AreaResponsable = Data.AreaEmpresaResponsable;
      this.modelo.modeloVirtual.Consecutivo = Data.Consecutivo;
      this.modelo.modeloVirtual.Tipofirma = Data.FirmaElectronica ? 123 : 46;

      this.SetModelToControls();
      this.SetControlsValueDocument("IdVersionFlujoDocumental", "enabled", "false");

      if (this.modelo.modelo.DataAdd) {
        this.modelo.modelo.DataAdd["FirmaElectronica"] = Data.FirmaElectronica;
      }
      else {
        this.modelo.modelo.DataAdd = {
          FirmaElectronica: Data.FirmaElectronica
        };
      }

      super.OnDataTableEditHandler(event);

    }
    else {
      super.OnDataTableEditHandler(event);
    }
  }

  BuscarContacto() {

    this.modalOpenContacto = true;

    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[0],
      limpiarInternamente: true
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Selección de Contactos";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;
    debugger
    this.utility.OpenWindow(componentName,
      config, modeloTemp).then(dataResult => {
      var AlertaOrdenFlujo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AlertaOrdenFlujo"; });
      dataResult.componentInstance.parentContext = this;

      dataResult.componentInstance.postSeleccionItemEmitter.subscribe((result) => {

        if(result.data.data.column.CheckBoxCol.Value)
        {
          if(this.modelo.modeloVirtual["ContactosIntervinientes"].length < 10 )
          {
            var exists = this.modelo.modeloVirtual["ContactosIntervinientes"].find(x => { return x.TipoIdentificacion == result.data.data.findItem.TipoIdentificacion && x.Identificacion == result.data.data.findItem.Identificacion; })
            if(exists != undefined)
            {
              this.utility.VerModalWarning({ titulo: 'Alerta Contacto', descripcion: 'El contacto ' + result.data.data.findItem.PrimerNombre + ' ' + result.data.data.findItem.PrimerApellido + ' ya se encuentra incluido dentro del flujo' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
            }
            else
            {
              this.modelo.modeloVirtual["ContactosIntervinientes"].push({ IdUsuario: result.data.data.findItem.IdContacto, TipoIdentificacion: result.data.data.findItem.TipoIdentificacion, Identificacion: result.data.data.findItem.Identificacion, Nombre: result.data.data.findItem.PrimerNombre + ' ' + result.data.data.findItem.PrimerApellido, Email: result.data.data.findItem.Correo, Celular: result.data.data.findItem.Celular, NotificarEmail: false, Aprobador: true, TipoInterviniente: 'contacto', OrdenFlujo: null, EstadoEjecucion: null, IdSolicitudFlujoHijo: null });
              super.SetModelToControls();
              this.ValidaOrdenFlujo();
              //this.validaAprobador();
            }
          }
          else
            this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'Máximo permitido de Intervinientes (10)' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
        }

      });

      dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
        if (result.Action === FormActionsThroughEmitters.ClearModel) {
          /**Se comenta esta linea ya que al cambiar de página en selección de contactos se 
           * limpia el array sin necesidad ya que la grilla al agregar un contacto trae el b´ton de eliminar*/
          //this.modelo.modeloVirtual["ContactosIntervinientes"] = new Array;
          /*AlertaOrdenFlujo.HiddenByDefault = false
          AlertaOrdenFlujo.Msg = "Verifique el orden del flujo";*/
          /**Se valida orden de flujo una vez se agrega un contacto adicional */
          this.ValidaOrdenFlujo();
          this.SetModelToControls();
        }
      });
      dataResult.componentInstance.loadedGridEmitter.subscribe(result => {


      });

      dataResult.onClosePromise.then(dataResultOnClose => {
        dataResult.componentInstance.ngOnDestroy();
        dataResult.componentInstance = null;
        this.modalOpenContacto = false;
      });
    });

  }

  public OnActionEventHandler(event)
  {

    this.calledCommandButton = '';
    if (event.Action && event.Action == "ClearModel" && event.Table && event.Table != "GridView_SolicitudFlujoDocumental")
    {
      this.modelo.modeloVirtual.CodigoExpediente = null;
      this.modelo.modeloVirtual.IdTipoDocumental = null;
      this.modelo.modeloVirtual.NombreExpediente = null;
      this.modelo.modeloVirtual.IdCarpeta = null;
      /*if (event.Table == "GrillaVoBoUsuario" || event.Table == "GrillaAprobacionUsuario")
          this.modelo.modeloVirtual.IdUsuarioSeleccionado = null;*/
      this.SetModelToControls();
      return;
    }
    if (this.Pagina.Pagina != "SolicitudFlujoDocumental") {
      debugger;
      if (event.ControlType && event.ControlType == "CommandButton") {
        this.calledCommandButton = event.Option.Name;
        if (event.Option && event.Option.Name) {
          if(this.MessageSinFirmaDigital){
            this.MessageSinFirmaDigital.Hidden = event.Option.Name != "AprobarDocumento" ? true : (this.SinFirmaDigital != true ? true : false);
          }
          let validacion = event.Option.Name == "RechazarProceso" ? "true" : "false"
          this.SetControlsValueDocument("IdCausalRechazo", "required", validacion)
          this.IdCausalRechazo.Hidden = validacion == "true" ? false : true;
          let validacionApruebaFirma = event.Option.Name == "AprobarDocumento" ? (this.SinFirmaDigital != true ? "true" : "false") : "false";
          this.SetControlsValueDocument("Tipofirma", "required", validacionApruebaFirma)
          this.Tipofirma.Hidden = validacionApruebaFirma == "true" ? false : true;
          this.Observaciones.Hidden = event.Option.Name == "AprobarDocumento" ? true : validacionApruebaFirma == "true" ? true : false;
          debugger
          if(event.Option.Name  == "RechazarProceso"){
            this.configs.ConfigIdCausalRechazo.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false)
            this.CodigoOtp.Hidden = true;
            this.HtmlOTP.Hidden = true;
          }  
              
          if(this.FiltroFirma.Activo)
          {
            this.FiltroFirma.Hidden = validacionApruebaFirma == "true" ? false : true;
            this.SetControlsValueDocument("FiltroProceso", "required", validacionApruebaFirma)
          }
          if (this.modelo.modeloVirtual.Tipofirma != 46)
            validacionApruebaFirma = "false";

          this.SetControlsValueDocument("Observaciones", "required", (event.Option.Name == "AprobarVoBo") ? 'true' : validacion);

          this.configPin.Hidden = validacionApruebaFirma == "true" ? false : true;
          this.SetControlsValueDocument("Pin", "required", validacionApruebaFirma)
          if(event.Option.Name === "AprobarDocumento" && this.modelo.modelo.DataAdd.FirmaElectronica){
            //debugger;
            this.requestOTPCode(false);
            this.SetControlsValueDocument("CodigoOtp", "required", true)
            this.CodigoOtp.Hidden = false;
            this.modelo.modeloVirtual.countdown = 45;
            this.modelo.modelo.DataAdd.TechnoKey = this.modelo.modeloVirtual.TechnoKey;
            this.HtmlOTP.Hidden = false;
            let dynamicContent = this.HtmlOTP.Template;
            this.startCountdown();
          }
          if (this.DivInfoFirma) {
            if (this.modelo.modeloVirtual.Tipofirma === 47 && this.modelo.modeloVirtual.FiltroProceso && validacionApruebaFirma != "false")
              this.DivInfoFirma.Hidden = false
            else
              this.DivInfoFirma.Hidden = true
          }
        }

        switch (event.Option.Name) {
          case SolicitudCommonComponent.CommandBarActions.AprobarVoBo:
            this.modelo.modeloVirtual.IdEstadoSolicitudProceso =
              EstadosSolicitud.VoBo;
            break;
          case SolicitudCommonComponent.CommandBarActions.AprobarDocumento:
            this.modelo.modeloVirtual.IdEstadoSolicitudProceso =
              EstadosSolicitud.Aprobada;
            break;
          case SolicitudCommonComponent.CommandBarActions.RechazarProceso:
            this.modelo.modeloVirtual.IdEstadoSolicitudProceso =
              EstadosSolicitud.Rechazada;
            break;
          case SolicitudCommonComponent.CommandBarActions.EditarPlantilla:
            this.getPlantilla(this.modelo.modelo.IdSolicitudFlujoDocumental,this.modelo.modelo.IdTipoSolicitud);
            break;
        }
        this.SetModelToControls();
      }
      else if (event.ControlType && event.ControlType == "Button") {
        if (event.Name == "VistaPrevia") {
          let obj = this.utility.GetApiModel('ViewPrewview', null)
          obj = {
            IdSolicitudDocumento: this.modelo.modelo.IdSolicitudDocumento,
            DataAdd: {
              NumeroPagina: this.modelo.modeloVirtual.PaginaFirma,
              PosicionX: this.modelo.modeloVirtual.PosicionX,
              PosicionY: this.modelo.modeloVirtual.PosicionY,
              AnchoImagen: this.modelo.modeloVirtual.AnchoImagen,
              AltoImagen: this.modelo.modeloVirtual.AltoImagen,
              FiltroProceso: this.TipoFiltro
            }
          };

          let info = {
            componentName: this.componentName, controlName: 'CerrarExpediente',
            processMessage: 'Generando la Vista previa del documento'
          };
          return this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/ViewPrewview', false, false).then(data => {
            if (data.Ok != true) {
              this.utility.VerModalError(data.ValidationUI);
            }
            else {
              this.helperActions.OpenDocumentViewer(data.Data, "Preview");
            }
          }).catch(err => {
            this.utility.VerModalError(err);
          });
        }
        if(event.Name == "Limpiar") {
          this.modelo.modelo.Observaciones = null;
          this.modelo.modelo.IdCausalRechazo = null;
          this.modelo.modeloVirtual.CodigoOtp = null;
          this.modelo.modeloVirtual.Pin = null;
          this.SetModelToControls();

        }
      }
    }
    else
    {

      switch (event.ControlType) {

        case GeneralPageControlTypes.CommandButton: {
          this.calledCommandButton = event.Option.Name;
          this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Pagina);
          debugger
          switch (event.Option.Name) {
            case SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente: {
              this.modelo.modeloVirtual.CodigoExpediente = null;
              this.modelo.modelo.IdTipoDocumental = null;
              this.modelo.modeloVirtual.NombreExpediente = null;
              this.modelo.modelo.UsuariosGestionActual = '';
              var TiposDocs = '{"TiposDocumentales":[]}';
              const trd = JSON.parse(TiposDocs);
              const tiposDoc = trd["TiposDocumentales"];
              const carpetas = []
              this.configs.ConfigIdCarpeta.Context.SetItemsComboBox(carpetas);
              this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);


              this.SetControlsValueDocument("IdVersionFlujoDocumental", "required", "false")
              this.SetControlsValueDocument("Nombre", "required", "false")
              this.SetControlsValueDocument("IdTipoDocumental", "required", "true")
              //this.SetControlsValueDocument("IdCarpeta", "required", "true")

              /*
                Manuel Eduardo Rodriguez 12112023
                HU Flujos Electronicos Tarea 5
                Se comentan las lineas de RaiseForceValidateSaveEvent para el evento configArchivosAdjuntos
                dado que la tarea solicita que los documentos de este filebox sean opcionales no obligatorios
              */
              // this.configArchivosAdjuntos.RaiseForceValidateSaveEvent(true, false);

              if(!this.IdCuaderno.Hidden)
              {
                this.SetControlsValueDocument("IdCuaderno", "required", "true")
              }
              this.componentsContainer.ReloadTable("GrillaAsignarExpediente", {
                IdEmpresa: -1,
                IdAreaEmpresaUsuario: -1
              });
              //this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Expediente);
              this.SetModelToControls();

              break;
            }
            case SolicitudCommonComponent.CommandBarActions.Nuevo: {

              this.SetControlsValueDocument("IdVersionFlujoDocumental", "enabled", "true")
              this.SetControlsValueDocument("IdVersionFlujoDocumental", "required", "true")
              this.SetControlsValueDocument("Nombre", "required", "true")
              this.configs.ConfigIdVersionFlujoDocumental.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
              /*
                Manuel Eduardo Rodriguez 12112023
                HU Flujos Electronicos Tarea 5
                Se comentan las lineas de RaiseForceValidateSaveEvent para el evento configArchivosAdjuntos
                dado que la tarea solicita que los documentos de este filebox sean opcionales no obligatorios
              */
              //this.configArchivosAdjuntos.RaiseForceValidateSaveEvent(false, true);
              this.DivContactos.Hidden = true;
              this.AcordionIntervinientes.Hidden = false;
              this.crudButtons.Hidden = false;
              this.fileBox.Hidden = false;
              this.SetControlsValueDocument("Nombre", "enabled", "true")
              this.SetControlsValueDocument("IdTipoDocumental", "required", "false")
              this.SetControlsValueDocument("IdCarpeta", "required", "false")


              if (this.modelo.modelo.DataAdd) {
                this.modelo.modelo.DataAdd["EsEdit"] = false;
              }
              else {
                this.modelo.modelo.DataAdd = {
                  EsEdit: false
                };
              }
              this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
              this.SetModelToControls();
              break;
            }
            case SolicitudCommonComponent.CommandBarActions.VerDetalle: {

              this.configs.ConfigIdVersionFlujoDocumental.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
              if (this.modelo.modelo.DataAdd) {
                this.modelo.modelo.DataAdd["EsEdit"] = true;
              }
              else {
                this.modelo.modelo.DataAdd = {
                  EsEdit: true
                };
              }
              this.DivContactos.Hidden = true;
              this.AcordionIntervinientes.Hidden = false;
              let permiteEdicion = this.modelo.modelo.IdEstadoSolicitud == EstadosSolicitud.Creada || this.modelo.modelo.IdEstadoSolicitud == EstadosSolicitud.Rechazada || this.modelo.modelo.IdEstadoSolicitud == EstadosSolicitud.SolicitudActualizada ? "true" : "false"
              this.SetControlsValueDocument("Nombre", "enabled", permiteEdicion)
              /*
                  Manuel Eduardo Rodriguez 12112023
                  HU Flujos Electronicos Tarea 5
                  Se comentan las lineas de RaiseForceValidateSaveEvent para el evento configArchivosAdjuntos
                  dado que la tarea solicita que los documentos de este filebox sean opcionales no obligatorios
              */
              // this.configArchivosAdjuntos.RaiseForceValidateSaveEvent(true, (permiteEdicion === 'true'));

              this.fileBox.Hidden = !(permiteEdicion === 'true');
              this.plantillaButton.Hidden = !(permiteEdicion === 'true');
              this.msgFileBox.Hidden = !(permiteEdicion === 'true');
              if (!(permiteEdicion === 'true'))
                this.crudButtons.Hidden = true;
              else
                this.crudButtons.Hidden = false;
              this.SetModelToControls();

              break;
            }
            case SolicitudCommonComponent.CommandBarActions.Eliminar: {

              this.utility.VerModalConfirmacionGeneral("Eliminar Documento", "El documento sera eliminado, ¿esta seguro que desea continuar?").then(modelo => {

                if (modelo.dialogResult == true) {
                  this.modelo.modelo.IdEstadoSolicitud = 34
                  this.modelo.modelo.Activo = false;
                  this._Guardar(this.form)
                }
              });

              break;
            }
            default:

              break;
          }

          break;
        }
        case GeneralPageControlTypes.Button:
        {
          switch (event.Name)
          {
            case "Filtrar":
              this.Filtrar();
              break;
            case "Limpiar":
              this.controls.ForEach(control =>
              {
                if (control.control && control.control.ModelContainer == "modeloVirtual")
                  this.modelo.modeloVirtual[control.control.Name] = null;
              });
              this.SetModelToControls();
              this.Filtrar();
              break;
            case "BtnContactos":

              if(!this.modalOpenContacto)
                this.BuscarContacto();
              else
                this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de contactos abierta." });

              break;
            case "BtnAgregarContacto":
              this.componentsContainer.Find('PanelDocumentos').sidePanelInstance.dismiss();
              break;
          }
        }
          break;
      }

    }

    if(this.calledCommandButton != '')
    {
      if (this.modelo.modelo.DataAdd) {
        this.modelo.modelo.DataAdd["OperacionFlujoDocumental"] = this.calledCommandButton;
      }
      else {
        this.modelo.modelo.DataAdd = {
          OperacionFlujoDocumental: this.calledCommandButton
        };
      }
    }

    if (event.actionType && event.actionType == "OnSelectedComboBoxExtendableTable") {
      if (!event.data.item) {
        this.ValidaOrdenFlujo();
      }
      else {

        if (event.data.item > this.modelo.modeloVirtual["ContactosIntervinientes"].length)
          event.data.item = this.modelo.modeloVirtual["ContactosIntervinientes"].length.toString()

        const _indexequal = this.modelo.modeloVirtual["ContactosIntervinientes"].findIndex(x => { return x.OrdenFlujo == event.data.item; });

        if (_indexequal != -1) {
          this.utility.VerModalWarning({ titulo: 'Verificacion del orden', descripcion: 'El orden seleccionado ya existe en el flujo' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
        }
        else {

          if (event.data.item == this.modelo.modeloVirtual["ContactosIntervinientes"].length)
          {
            const IndexItem = this.modelo.modeloVirtual["ContactosIntervinientes"][event.data.index];
            if(IndexItem.TipoInterviniente == "contacto")
            {
              event.data.item = "";
              this.utility.VerModalWarning({ titulo: 'Verificacion del orden', descripcion: 'El orden del flujo no puede culminar con un contacto' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
            }
          }

          this.modelo.modeloVirtual["ContactosIntervinientes"][event.data.index].OrdenFlujo = event.data.item
          this.modelo.modeloVirtual.ValidacionOrdenFlujo = null;
          this.ValidaOrdenFlujo();
          //this.validaAprobador();
        }
      }
    }

    /*     if (event.actionType && event.actionType == "OnChangeCheckBoxxtendableTable")
        {
            debugger;
            if(event.data.TipoInterviniente == 'contacto')
            {
                event.data.Aprobador = true;
                this.utility.VerModalWarning({ titulo: 'Verificacion de Aprobador', descripcion: 'El contacto solo puede ser aprobador' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                super.SetModelToControls();
            }
        } */

    /*if (event.Option && event.Option.Name && event.Option.Name == "IncluirenExpediente") {
        this.componentsContainer.ReloadTable("GrillaAsignarExpediente", {
            IdEmpresa: -1,
            IdAreaEmpresaUsuario: -1
        });
        this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Expediente);
    }
    else
        this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Pagina);*/

    super.OnActionEventHandler(event);
  }
  public OnLoadGridHandler(event) {
    this.FinConsultaGrilla(event);
  }

  public ValidaOrdenFlujo() {
    var AlertaOrdenFlujo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AlertaOrdenFlujo"; });
    if (this.modelo.modeloVirtual["ContactosIntervinientes"].length > 0) {
      /**Se crea variable contador para validar que todos los usuarios intervinientes tengan un orden para poder dejar this.modelo.modeloVirtual.ValidacionOrdenFlujo
       * En 'ok' solo si todos los usuarios tienen un valor en el ordeFlujo y asi poder esconder la alerta*/
        var contador=0;
        this.modelo.modeloVirtual["ContactosIntervinientes"].forEach(element => {
          
            if (element.OrdenFlujo && element.OrdenFlujo != null && element.OrdenFlujo != "") {
              contador=contador + 1;
              if(contador == this.modelo.modeloVirtual["ContactosIntervinientes"].length){
                this.modelo.modeloVirtual.ValidacionOrdenFlujo = "ok";
                AlertaOrdenFlujo.HiddenByDefault = true
              }
            }
            else {
              this.modelo.modeloVirtual.ValidacionOrdenFlujo = null;
              AlertaOrdenFlujo.HiddenByDefault = false
              AlertaOrdenFlujo.Msg = "Verifique el orden del flujo";
            }
          
        });
    }
    else {
      this.modelo.modeloVirtual.ValidacionOrdenFlujo = null;
      AlertaOrdenFlujo.HiddenByDefault = false
      AlertaOrdenFlujo.Msg = "Verifique el orden del flujo";
    }
    super.SetModelToControls();

  }

  public Filtrar() {

    try {

      this.SetProcesando(true);
      this.componentsContainer.Tables[0].QueryConfig.Entity = this.SetModelo();
      this.componentsContainer.Tables[0].cargarGrilla = true;
      this.componentsContainer.Tables[0]._CargarGrilla(true);
      this.isFilter = true;
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }

  public FinConsultaGrilla(event: any) {
    this.SetProcesando(false);

    if (this.isFilter) {
      this.utility.VerModalOk("Filtro Finalizado");
      this.isFilter = false;
    }
  }
  public SetModelo() {
    var IdEstadoSolicitud = null;
    var IdUsuario = null
    if (this.modelo.modeloVirtual.EstadoFiltro && this.modelo.modeloVirtual.EstadoFiltro.IdEstadoSolicitud)
      IdEstadoSolicitud = this.modelo.modeloVirtual.EstadoFiltro.IdEstadoSolicitud
    if (this.modelo.modeloVirtual.UsuarioGestion && this.modelo.modeloVirtual.UsuarioGestion.IdUsuario)
      IdUsuario = this.modelo.modeloVirtual.UsuarioGestion.IdUsuario
    return {
      IdEmpresa: -1,
      FechaInicialTramite: this.modelo.modeloVirtual.FechaInicialTramite,
      FechaFinalTramite: this.modelo.modeloVirtual.FechaFinalTramite,
      EstadoFiltro: IdEstadoSolicitud,
      UsuarioGestion: IdUsuario,
      NombreFlujo: this.modelo.modeloVirtual.NombreFlujo,
    };
  }

  getPlantilla(idSolicitud : number, tipoSolicitud: number) {

    var serviceModel : any;
    var arrayservice = {
      "token": StaticData.Usuario.Token,
      "AppKey": StaticData.AppKey,
      "ExecutionObject": {
        "Name": "Documentos",
        "WebServiceMethod": {
          "Name": "GetPlantillaSolicitud",
          "Parameters": {
            "Codigo": idSolicitud,
            "TipoSolicitud": tipoSolicitud
          }
        }
      }
    };

    let info = {
      componentName: this.componentName, controlName: 'ConsultaAccionTipoRespuestaPqrs',
      processMessage: 'Cargando acción respuesta......'
    };
    //console.log( "nombre del componente   "+this.componentName);
    let obj = this.utility.GetApiModel("GetPlantillaSolicitud", this.componentName, null, null, this.modelo.modelo);
    this.baseService.DescargarTemplateBase64(arrayservice, null,
      { componentName: 'SolicitudFlujoDocumentalComponent', method: 'GetPlantillaSolicitud', processMessage: 'Procesando Plantilla....' },
      'TSolicitudFlujoDocumental', false, true).then(data => {
      debugger;
      serviceModel = data.Data[0].Archivo.find(o => o.EsPlantilla == true);

      let message = {
        IdAreaEmpresa: StaticData.Usuario.IdAreaEmpresa,
        IdRadicado: idSolicitud, //this.modelo.modelo.IdSolicitudFlujoDocumental,
        Nombre: serviceModel.Archivo,
        Codigo: this.modelo.modelo.Codigo,
        TipoSolicitud: tipoSolicitud, //this.modelo.modelo.IdTipoSolicitud,
        TipoRadicado: 'FlujosElectronicos',
        Token: StaticData.Usuario.Token,
        Appkey: StaticData.AppKey,
        IdUsuario:  StaticData.Usuario.IdUsuario,
        UsuarioAutenticado: StaticData.Usuario.Email,
        Base64: serviceModel.DatosArchivo,
        ConfiguracionWebsocket: {
          RutaArchivosTemporales:"C:\\Temp\\",
          RutaArchivosLogs:"C:\\Temp\\Logs\\",
          ExtensionArchivosWord:".docx",
          NombreDocumentoTemporalWord:"DocumentoTemporalBestDoc",
          UrlServer: StaticData.UrlServer,
          WebSocketUrl:"ws://0.0.0.0:8282"

        }
      };

      this.sent.push(message);
      this.DocumentTemplateSocketService.messages.next(message);

    }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });

  }

  _Guardar(form: FormGroup, configButtons?: any): void {
    debugger;
    var correctformatflow = true;

    if (this.Pagina.Pagina == "SolicitudFlujoDocumental") {
      if (this.modelo.modelo.DataAdd) {
        //this.modelo.modelo.DataAdd["IdCarpeta"] = this.modelo.modeloVirtual.IdCarpeta;
        this.modelo.modelo.DataAdd["IdCarpeta"] = this.modelo.modelo.IdCarpeta ? this.modelo.modelo.IdCarpeta : null;
        this.modelo.modelo.DataAdd["IdCuaderno"] = !this.IdCuaderno.Hidden ? this.modelo.modeloVirtual.IdCuaderno : null;
        this.modelo.modelo.DataAdd["IdTipoDocumental"] = !this.modelo.modeloVirtual.IdTipoDocumental ? this.modelo.modeloVirtual.IdTipoDocumental : null;
        //Lina Figueredo - Solucioin Bug 1113
        //Se comenta linea 833 y se ajusta en la linea 834, se ajusta condicional para que asigne el valos del IdTipoDocumental al Modelo.
        //this.modelo.modelo.IdTipoDocumental = !this.modelo.modeloVirtual.IdTipoDocumental ? this.modelo.modeloVirtual.IdTipoDocumental : null;
        this.modelo.modelo.IdTipoDocumental = this.modelo.modeloVirtual.IdTipoDocumental ? this.modelo.modeloVirtual.IdTipoDocumental : null;
        console.log(this.modelo.modelo.Configuracion)
      }

      if(this.withExternalUser)
      {
        for(var i = 0; i <= this.modelo.modeloVirtual.ContactosIntervinientes.length - 1; i++)
        {

          if(this.modelo.modeloVirtual.ContactosIntervinientes[i]['OrdenFlujo'] == '' || this.modelo.modeloVirtual.ContactosIntervinientes[i]['OrdenFlujo'] == undefined)
          {
            correctformatflow = false;
            this.utility.VerModalWarning({ titulo: 'Verificación de datos', descripcion: 'Para continuar con la solicitud es necesario que todos los intervinientes cuenten con un orden seleccionado' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
            break;
          }

          if(this.modelo.modeloVirtual.ContactosIntervinientes[i]['TipoInterviniente'] == 'contacto')
          {
            if(this.modelo.modeloVirtual.ContactosIntervinientes[i]['Email'] != "" && this.modelo.modeloVirtual.ContactosIntervinientes[i]['Email'] != undefined)
            {
              let pattern = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/;
              if(!pattern.test(this.modelo.modeloVirtual.ContactosIntervinientes[i]['Email']))
              {
                correctformatflow = false;
                this.utility.VerModalWarning({ titulo: 'Verificación de datos contactos', descripcion: 'Uno o mas contactos no cuentan con el formato esperado para el correo electrónico o número celular' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                break;
              }

            }
            else
            {
              correctformatflow = false;
              this.utility.VerModalWarning({ titulo: 'Verificación de datos contactos', descripcion: 'Uno o mas contactos no cuentan con el formato esperado para el correo electrónico o número celular' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
              break;
            }

            if(this.modelo.modeloVirtual.ContactosIntervinientes[i]['Celular'] != "" && this.modelo.modeloVirtual.ContactosIntervinientes[i]['Celular'] != undefined)
            {

              let pattern = /^[0-9]*$/;
              if(!pattern.test(this.modelo.modeloVirtual.ContactosIntervinientes[i]['Celular']))
              {
                correctformatflow = false;
                this.utility.VerModalWarning({ titulo: 'Verificación de datos contactos', descripcion: 'Uno o mas contactos no cuentan con el formato esperado para el correo electrónico o número celular' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                break;
              }

            }
            else
            {
              correctformatflow = false;
              this.utility.VerModalWarning({ titulo: 'Verificación de datos contactos', descripcion: 'Uno o mas contactos no cuentan con el formato esperado para el correo electrónico o número celular' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
              break;
            }

          }
          /**Bug 0000996: NO SE INICIA EL FLUJO DOCUMENTAL,} se cambia los valores de si y No a boleanos ya que  arroja error al enviar a guardar la configuración en TSolicitudFlujoDocumental */
          if(this.modelo.modeloVirtual.ContactosIntervinientes[i]['Aprobador'] == 'Si' || this.modelo.modeloVirtual.ContactosIntervinientes[i]['Aprobador'] == 'No'){
            this.modelo.modeloVirtual.ContactosIntervinientes[i]['Aprobador'] = this.modelo.modeloVirtual.ContactosIntervinientes[i]['Aprobador']=='Si' ? true : false;
            this.modelo.modeloVirtual.ContactosIntervinientes[i]['NotificarEmail'] = this.modelo.modeloVirtual.ContactosIntervinientes[i]['NotificarEmail']=='Si' ? true : false;
          }


        }

        if(correctformatflow)
        {
          var Config = JSON.parse(this.modelo.modelo.Configuracion);
          if(!(Config instanceof Object)){
            Config =JSON.parse(Config);
          }
          if (!Config)
            Config = {};
          Config.UsuariosIntervinientes = this.modelo.modeloVirtual.ContactosIntervinientes;
          this.modelo.modelo.Configuracion = JSON.stringify(Config);
        }
      }
    }
    debugger;

    if(correctformatflow)
    {
      super._Guardar(form, configButtons);
    }
    else
    {
      this.SetProcesando(false);
    }

  }
  startCountdown(): void {
    let countdown = 15;
    if(StaticData.GeneralConfig.ConfigTechnoKey.SegundosSolicitudOTP && StaticData.GeneralConfig.ConfigTechnoKey != ''){
      countdown = parseInt(StaticData.GeneralConfig.ConfigTechnoKey.SegundosSolicitudOTP);
    }
    const otpLink = $('#otpLink');

    otpLink.off('click').css('cursor', 'not-allowed');

    const intervalId = setInterval(() => {
      if (countdown > 0) {
        otpLink.text(`Podrá solicitar un nuevo código OTP en ${countdown} segundos...`);
        countdown--;
      } else {
        otpLink.html('<span style="color: #0a6aa1;text-decoration: underline">Solicitar código</span>');
        otpLink.css('cursor', 'pointer').click(() => {
          this.startCountdown();
          this.requestOTPCode(true);
        });
        clearInterval(intervalId);
      }
    }, 1000);
  }

  requestOTPCode(modal): void {
    debugger
    let obj = this.utility.GetApiModel('ViewPrewview', null)
    obj = {
      IdSolicitudDocumento: this.modelo.modelo.IdSolicitudDocumento,
      DataAdd: {
        IdUsuario: StaticData.Usuario.IdUsuario,
      }
    };
    let info = {
      componentName: "TSolicitudFlujoDocumental", method: 'SolicitarCodigo',
      processMessage: 'Solicitando un nuevo OTP......'
    };
    this.baseService.Ejecutar(obj, null, info, false, "TSolicitudFlujoDocumental/"+info.method , modal, false).then(data => {

    }).catch(err => { });
  }
}
