import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Enumerable, List } from "../../../assets/linqts/compilado/index";

import { APP_ROUTES } from "src/app/app.routes";
import { Base64 } from "js-base64";
import { BaseServiceService } from "../../services/base-service.service";
import { CrudActions } from "../../models/general.enum";
import { DashboardComponent } from "../controls/dashboard/dashboard.component";
import { FormGroup, NgForm } from "@angular/forms";
import { GeneralComunicationService } from "../../services/general-comunication.service";
import { GeneralFormComponent } from "../controls/general-form/general-form.component";
import { ModalBase } from "../../models/modal-base";
import { ModalNotifyComponent } from "../modal-notify/modal-notify.component";
import { NavItem } from "../menu-item/nav-item";
import { Router } from "@angular/router";
import { StaticData } from "../../helpers/static-data";
import { TabHost } from "dock-spawn-ts/lib/js/TabHost";
import { Utilities } from "../../helpers/utilities";
import _ from "lodash";
import { helpers } from "chart.js";
import { TranslateService } from "@ngx-translate/core";


declare let fabric: any;
declare let _spPageContextInfo: any;
declare let SP: any;
//
declare let ExecuteOrDelayUntilScriptLoaded: any;
//
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
    @ViewChild(ModalNotifyComponent, { static: false }) modalNotifyLogin: ModalNotifyComponent;
    @Output() endEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeEmitter: EventEmitter<any> = new EventEmitter<any>();
    botonProcesando = false;
    textoBoton = this.translate.instant('Ingresar', { defaultValue:'Ingresar' });
    textoBotonProcesando = "";
    model: any = { UData: StaticData.DefaultUser, CData: "", TipoSistema: null, AppKey: null };
    dialogResult = false;
    dedicatedWorker: Worker;
    dominios: Array<any> = [];
    ipclient: string = '';
    language: string = StaticData.Language
    languages: Array<{ nombre: string, sigla: string }> = StaticData.Languages;
    selectLanguage: string = this.languages.find(x => x.sigla == this.language).nombre;
    
    constructor(public baseService: BaseServiceService, public utility: Utilities, private router: Router,
        public comunicationService: GeneralComunicationService, public translate: TranslateService) {


    }

    SetClassConfigUIProperty(prop, value) {

        if (!StaticData.GeneralConfig.ConfigUI[prop])
            StaticData.GeneralConfig.ConfigUI[prop] = value;
    }
    SetClassConfigUI() {

        this.SetClassConfigUIProperty('IconIsInvalide', 'ms-Icon ms-Icon--info');
        this.SetClassConfigUIProperty('ColorIconIsInvalid', 'tooltip__validation-red');

        this.SetClassConfigUIProperty('IconIsRequiredError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconIsRequiredError', 'ms-CommandButton-icon ms-fontColor-red');

        this.SetClassConfigUIProperty('IconIsMinlengthError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconIsMinlengthError', 'ms-CommandButton-icon ms-fontColor-red');

        this.SetClassConfigUIProperty('IconIsMaxlengthError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconIsMaxlengthError', 'ms-CommandButton-icon ms-fontColor-red');


        this.SetClassConfigUIProperty('IconIsMinError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconIsMinError', 'ms-CommandButton-icon ms-fontColor-red');


        this.SetClassConfigUIProperty('IconIsMaxError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIsMaxError', 'ms-CommandButton-icon ms-fontColor-red');


        this.SetClassConfigUIProperty('IconIsPatternError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconIsPatternError', 'ms-CommandButton-icon ms-fontColor-yellow');

        this.SetClassConfigUIProperty('IconIsEmailError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconIsEmailError', 'ms-CommandButton-icon ms-fontColor-red');


        this.SetClassConfigUIProperty('IconGetCustomError', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconGetCustomError', 'ms-CommandButton-icon ms-fontColor-red');

        this.SetClassConfigUIProperty('IconEditRequired', 'ms-Icon ms-Icon--Info');
        this.SetClassConfigUIProperty('ColorIconEditRequired', 'ms-CommandButton-icon ms-fontColor-yellow');


    }
    public get ViewPasswordBox() {
        return StaticData.ViewPasswordBox;
    }
    public get ViewDomainComboBox() {
        return StaticData.ViewDomainComboBox;
    }
    // let  TEMP_APP_ROUTES: Routes = []
    SetProcesando(ok: boolean) {
        this.botonProcesando = ok;
        if (ok) {
            this.textoBoton = "";
            this.textoBotonProcesando = this.translate.instant('Ejecutando.......', { defaultValue:'Ejecutando.......' });
        }
        else {
            //this.model.CData = "";
            this.textoBoton = this.translate.instant('Ingresar', { defaultValue:'Ingresar' });
            this.textoBotonProcesando = "";
        }
    }
    ngOnInit() {

        // this.model.CData = "";

        this.SetProcesando(false);

        if(StaticData.ConsultarIpClient && StaticData.ApiGetIpClient.length > 0){

          this.baseService.getIPAddress().subscribe(res => {
            this.ipclient = res.ip;
            //alert('API ' + res.ip);
            //this.model.UData = res.ip;
          }, error => {
            this.utility.logger.LogError(error, null, { componentName: 'base-services.services', method: 'getIPAddress()' })
          });

        }



    }

    ngAfterViewInit() {
        const SpinnerElements = document.querySelectorAll(".ms-Spinner");
        for (let i = 0; i < SpinnerElements.length; i++) {
            new fabric["Spinner"](SpinnerElements[i]);
        }
        if (StaticData.InSharePoint)
            this.loginSHP();
    }

    public GetDatosEstilos(estilosPorRole: List<any>, estilosGenerales: List<any>, nombreKey: any, valorPorDefecto: any): any {
        let obj = estilosPorRole.FirstOrDefault(x => x.Nombre == nombreKey);
        if (!obj)
            obj = estilosGenerales.FirstOrDefault(x => x.Nombre == nombreKey);
        if (!obj)
            return valorPorDefecto;
        else
            return obj.Valor;
    }
    public SetStyle(estilos: any, objetoColor: any, valorPorDefecto: any): any {
        let obj = (valorPorDefecto) ? valorPorDefecto : {};
        if (estilos && estilos.split(";").length > 1 && estilos.split(":").length > 1) {
            obj = { color: null };
            if (objetoColor)
                obj.color = objetoColor;
            $.each(estilos.split(";"), function (key, val) {

                obj[val.split(":")[0]] = val.split(":")[1].trim();
            });
        }
        return obj;
    }


    public CrearMenus(menus: any, parentMenu: NavItem, isFirst: boolean, context: LoginComponent) {

        const i = 0;
        $.each(menus, function (key, menuItem: any) {
            //angular.forEach(menus, function (menuItem, key) {


            const config = (menuItem.Configuracion) ? JSON.parse(menuItem.Configuracion) : { iconName: null, sigla: null, ExternalUrl: null };

            const currrent: NavItem = {
                displayName: menuItem.Menu,
                disabled: false,
                iconName: (config.IconName) ? config.IconName : config.iconName,
                sigla: (config.Sigla) ? config.Sigla : config.sigla,
                children: [],
                style: "",
                colorMenu: "",
                menu: menuItem,
                //externalUrl: (config.ExternalUrl) ? config.ExternalUrl : config.externalUrl,
                config: config,
                // shortcut: (config.Shortcut) ? config.Shortcut : config.shortcut,
                shortcut: menuItem.ShortCut,
            };


            const style = (StaticData.Estilos.Menu) ? context.utility.Clone(StaticData.Estilos.Menu) : "";

            if (currrent.menu.ColorMenu) {
                //style.background = currrent.menu.ColorMenu;
            }
            currrent.style = style;

            //CM
            /*
        let _ReportesUsuario = new List<any>(StaticData.Usuario.ReportesUsuario);
        var report = _ReportesUsuario.Where(x => { return x.IdMenu == menuItem.IdMenu }).FirstOrDefault();
        */

            if (menuItem.IdPagina) {
                //currrent.callback = $scope.MenuNavigate;
            }

            //CM
            /*
        else if (report) {
          // currrent.callback = $scope.MenuNavigate;
          currrent.menu.Report = report;
          currrent.menu.Ruta = 'ReporteGeneral';
        }
        */

            const menusHijos = new List<any>(StaticData.Usuario.MenusUsuario).Where(x => { return x.IdMenuPadre == menuItem.IdMenu; }).ToArray();
            if (menusHijos.length > 0) {

                context.CrearMenus(menusHijos, currrent, false, context);
            }
            if (isFirst == true) {

                if (!menuItem.IdMenuPadre) {
                    parentMenu.children.push(currrent);
                }
            }
            else {
                if (parentMenu) {
                    parentMenu.children.push(currrent);
                }
            }
        });
    }

    //onSubmit(frm: FormGroup, email: string){ 
    onSubmit(frm: FormGroup, email: string, confirmCloseSession: boolean = false) {   
        StaticData.InitData();

        //this. baseService.modalNotify =this.modalNotifyLogin;
        //this.router.config.push( { path: 'formMdbostrap', component: FormMdbostrapComponent });
        const that = this;
        that.SetProcesando(true);
        that.comunicationService.raiseNavItemsEvent([]);

        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i))
            this.model.EsMovil = true;
        else
            this.model.EsMovil = false;


        //
        if (email != null) {
            this.model.UData = email;
        }
        //


        this.model.confirmCloseSession = confirmCloseSession;
        this.model.IpClient = this.ipclient; // this.baseService.ipAddress;
        this.model.ConsultarIpClient = StaticData.ConsultarIpClient

        //console.log("IP " + this.model.IpClient);

        //alert('IP ' + this.model.IpClient + ' us' + this.model.ConsultarIpClient);

        const modeloTemp = this.utility.Clone(this.model);
        modeloTemp.UData = btoa(this.model.UData);
        modeloTemp.AppKey = StaticData.AppKey;
        if (this.model.CData)
            modeloTemp.CData = btoa(unescape(encodeURIComponent(this.model.CData)));
        const obj = this.utility.GetApiModel("Login", "", CrudActions.None, null, modeloTemp);
        this.baseService.Login(obj, frm,
            { componentName: "ModalLoginComponent", method: "Login", processMessage: "Procesando credenciales...." },
            "AuthenticationBase", true, true).then(data => {
                debugger;
                if(data.Data.SessionActive > 0)
                {
                    that.utility.VerModalWarning(
                        { titulo: "Mensaje Alerta", descripcion: "El usuario ya cuenta con una sesion abierta, al continuar el sistema cerrará la sesion previa", verOk: true, verCancelar: true, ok: "ACEPTAR", cancelar: "CANCELAR" }).then(result => {
                            
                            if (result.dialogResult == true) 
                            {
                                //that.closeEmitter.emit(true);
                                this.comunicationService.raiseLoginEvent();
                                this.onSubmit(frm, this.model.UData, true);

                            }
                            else
                            {
                                that.closeEmitter.emit(true);
                            }

                         });
                }
                else
                {
                console.log(data.Data.TiempoAD1);
                console.log(data.Data.TiempoAD2);
                console.log(data.Data.TiempoSP);
                console.log(data.Data.TiempoGeneral);

                if (typeof Worker !== "undefined" && StaticData.UseWebWorker == true) {
                    const that =this;
                    data.LoadComponents = StaticData.LoginLoadAllComponents;
                    this.dedicatedWorker = new Worker("../../dedicatedWorkers/login.worker", { type: "module" });
                    const MainMenu: NavItem = {
                        displayName: "",
                        disabled: false,
                        iconName: "",
                        sigla: "",
                        children: [],
                        style: "",
                        colorMenu: "",
                        menu: {}
                    };
                    this.dedicatedWorker.onmessage = function (oEvent) {
                        switch (oEvent.data.event) {
                            case "ExecuteComplementaryData":

                                that.endEmitter.emit(true);

                                break;
                            case "Error":
                                {
                                    switch (oEvent.data.method) {
                                        case "VerModalWarning":
                                        default:
                                            that.utility.VerModalWarning(oEvent.data.obj);
                                            break;
                                    }
                                }
                                break;
                            case "StaticDataSet":
                                {

                                    if (!oEvent.data.property.includes(".") && oEvent.data.value && oEvent.data.value._elements && oEvent.data.value._elements.length > 0) {
                                        StaticData[oEvent.data.property] = new List<any>(oEvent.data.value._elements);
                                    }
                                    else{
                                        _.set(StaticData, oEvent.data.property, oEvent.data.value);

                                        if(oEvent.data.property== "GeneralConfig"){
                                            that.SetClassConfigUI();
                                        }
                                    }


                                    if (oEvent.data.property === "Usuario") {
                                        that.comunicationService.raiseFormEvent({ event: "LoginWorkedSet", value: oEvent.data.property });
                                    }
                                    else if (oEvent.data.property === "Estilos") {
                                        if (StaticData.Usuario.MenusUsuario) {
                                            that.CrearMenus(StaticData.Usuario.MenusUsuario, MainMenu, true, that);
                                            that.comunicationService.raiseNavItemsEvent(MainMenu.children);
                                        }
                                        else
                                            console.log("No Hay menus asignados");

                                    }
                                    else if (oEvent.data.property === "Usuario.Paginas") {
                                        StaticData.Usuario.Paginas.filter(pagina => { return pagina.IsCustom || pagina.IsDashboard; }).forEach(element => {
                                            const rout = APP_ROUTES.find(t => t.path == element.Ruta);
                                            if (!rout) {
                                                if (element.IsDashboard)
                                                    APP_ROUTES.push({ path: element.Ruta, component: DashboardComponent });
                                                else
                                                    APP_ROUTES.push({ path: element.Ruta, component: GeneralFormComponent });
                                            }

                                            // this.router.config.push({ path: element.Ruta, component: GeneralFormComponent });
                                        });
                                        const rout = APP_ROUTES.find(t => t.path == "**");
                                        if (!rout)
                                            APP_ROUTES.push({ path: "**", pathMatch: "full", redirectTo: "Home" });

                                        that.router.resetConfig(APP_ROUTES);

                                    }
                                }
                                break;


                        }
                    };

                    this.dedicatedWorker.postMessage(JSON.stringify({ method: "Login", data }));
                }
                else {
                    if (!data.Data || data.Data.Table === undefined || data.Data.Table[0] === undefined) {
                        that.utility.VerModalWarning(
                            { titulo: "Mensaje Alerta", descripcion: "El usuario no es valido en el sistema.....", verOk: true, verCancelar: false, ok: "ACEPTAR", cancelar: "NO" }).then(data => { });

                        //throw 'El usuario no es valido en el sistema';
                        return;
                    }
                    StaticData.Usuario = data.Data.Table[0];
                    StaticData.Usuario.Token = data.Data.Token;


                    if (StaticData.Usuario.Activo == false) {
                        StaticData.Usuario = null;
                        that.utility.VerModalWarning(
                            { titulo: "Mensaje Alerta", descripcion: "El usuario no se encuentra activo.....", verOk: true, verCancelar: false, ok: "ACEPTAR", cancelar: "NO" }).then(data => { });


                        //throw 'El usuario no se encuentra activo';
                        return;
                    }
                    if (!data.Data.Token) {
                        StaticData.Usuario = null;


                        that.utility.VerModalWarning(
                            { titulo: "Mensaje Alerta", descripcion: "Token Invalido....", verOk: true, verCancelar: false, ok: "ACEPTAR", cancelar: "NO" }).then(data => { });

                        //throw 'El usuario no se encuentra activo';
                        return;
                    }


                    //
                    StaticData.Usuario.MenusUsuario = data.Data.Table1;
                    StaticData.Usuario.PaginasUsuario = data.Data.Table2;
                    StaticData.Usuario.Paginas = data.Data.Table3;
                    // CM
                    //StaticData.Usuario.ReportesUsuario = data.Data.Table4;

                    //Optimización Login, data no necesaria
                    /*
                    StaticData.GeneralConfig = data.Data.GeneralConfig;

                    this.SetClassConfigUI();

                    if (!StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi)
                        StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi = 500;

                    if (!StaticData.GeneralConfig.ConfigUI.TiempoNotificacionError)
                        StaticData.GeneralConfig.ConfigUI.TiempoNotificacionError = 500;
                    if (!StaticData.GeneralConfig.ConfigUI.TiempoNotificacionAlerta)
                        StaticData.GeneralConfig.ConfigUI.TiempoNotificacionAlerta = 500;
                    if (!StaticData.GeneralConfig.ConfigUI.TiempoNotificacionOk)
                        StaticData.GeneralConfig.ConfigUI.TiempoNotificacionOk = 500;
                    if (!StaticData.GeneralConfig.ConfigUI.TiempoNotificacionInfo)
                        StaticData.GeneralConfig.ConfigUI.TiempoNotificacionInfo = 500;

                    if (data.Data.ToolBox) {
                        StaticData.ToolBox = JSON.parse(data.Data.ToolBox.StringValue);
                        StaticData.ToolBox.Id = data.Data.ToolBox.Id;
                        StaticData.ToolBox.TipoDato = data.Data.ToolBox.TipoDato;
                        StaticData.ToolBox.Name = data.Data.ToolBox.Name;
                    }
                    StaticData.WorkFlowTaskConfig = data.Data.WorkFlowTaskConfig;
                    
                        $.each(StaticData.Usuario.PaginasUsuario, function (key, pagina) {

                          // > [8, 10]
                          //var camposPaginas = Enumerable.From(data.Data.Table5).Where(function (x) { return x.IdPagina == pagina.IdPagina }).ToArray();

                          var paginaConfig = {
                            IdPagina: pagina.IdPagina,
                            Nombre: pagina.Pagina, Config: {}
                          };
                          if(pagina.Configuracion){

                          }

                          StaticData.ConfigPaginas.push(paginaConfig);
                        }); */



                   // StaticData.Usuario.Rules = data.Data.Rules;

                    //CM
                    /*
                $.each(StaticData.Usuario.ReportesUsuario, function (key, reporte) {

                  if (reporte.ConfiguracionFiltro && reporte.ConfiguracionFiltro !== null) {
                    reporte.ConfigFilter = JSON.parse(reporte.ConfiguracionFiltro);
                    let shapes = new List<any>(reporte.ConfigFilter.Shapes);
                    reporte.ConfigFilter.Shapes = shapes.Where(x => x.Active == true).ToArray();
                    // reporte.ConfigFilter.Shapes = Enumerable.From(reporte.ConfigFilter.Shapes).Where(function (x) { return x.Active == true }).ToArray();
                  }
                });
                */

                    // let BotonesComoMenu = StaticData.ParametrosGenerales.Where(x=>{return x.Nombre=='BotonesComoMenu' }).FirstOrDefault();
                    // StaticData.BotonesComoMenu =(BotonesComoMenu)?BotonesComoMenu.ValorFalsoVerdadero:false;

                    //Optimización Login, data no necesaria
                    /*
                    const estilosGenerales = (data.Data.Table4 && data.Data.Table4.length > 0) ? new List<any>(data.Data.Table4) : new List<any>([]);
                    const estilosPorRole = (data.Data.Table5 && data.Data.Table5.length > 0) ? new List<any>(data.Data.Table5) : new List<any>([]);

                    if (StaticData.LoginLoadAllComponents) {

                        //StaticData.ParametrosGenerales = (data.Data.Table6) ? new List<any>(data.Data.Table6) : new List<any>([]);
                        //  let ConfigGeneral = StaticData.ParametrosGenerales.FirstOrDefault(x => { return x.Nombre == 'ConfigGeneral' });
                     //if (ConfigGeneral) {
                     //  StaticData.ConfigGeneral = JSON.parse(ConfigGeneral);

                    // }

                        StaticData.RolesUsuariosGrupos = new List<any>(data.Data.Table7);
                        StaticData.Carpetas = new List<any>(data.Data.Table8);
                        StaticData.AreasEmpresa = new List<any>(data.Data.Table9);
                        StaticData.Series = new List<any>(data.Data.Table10);
                        StaticData.SubSeries = new List<any>(data.Data.Table11);


                    }

                    const BotonesComoMenu: string = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "BotonesComoMenu", "");
                    StaticData.BotonesComoMenu = false;

                    if (BotonesComoMenu &&
                        (BotonesComoMenu.toLowerCase() == "si" ||
                            BotonesComoMenu.toLowerCase() == "ok" ||
                            BotonesComoMenu.toLowerCase() == "yes" ||
                            BotonesComoMenu.toLowerCase() == "true" ||
                            BotonesComoMenu.toLowerCase() == "1"))
                        StaticData.BotonesComoMenu = true;


                    const Tema = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "Tema", "");

                    const ColorMenu = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorMenu", "blue");
                    const ColorTitulos = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorTitulos", "blue");
                    const ColorBotonGuardar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonGuardar", "blue");

                    const ColorBotonLimpiar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonLimpiar", "blue");
                    const ColorBotonFiltro = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonFiltro", "blue");
                    const ColorBotonImportar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonImportar", "blue");
                    const ColorBotonExportarExcel = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonExportarExcel", "blue");
                    const ColorBotonExportarCsv = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonExportarCsv", "blue");
                    const ColorBotonRedireccion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonRedireccion", "blue");
                    const ColorBotonBuscar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonBuscar", "blue");

                    const ClassMenu = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassMenu", "");
                    //let ClassTitulos =that.GetDatosEstilos(estilosPorRole,estilosGenerales,'ClassTitulos','blue');
                    const ClassBotonGuardar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonGuardar", "btn btn-outline-success");
                    const ClassBotonLimpiar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonLimpiar", "btn btn-outline-info");
                    const ClassBotonFiltro = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonFiltro", "btn btn-outline-info");
                    const ClassBotonImportar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonImportar", "btn btn-outline-info");
                    const ClassBotonExportarExcel = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonExportarExcel", "btn btn-outline-info");
                    const ClassBotonExportarCsv = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonExportarCsv", "btn btn-outline-info");
                    const ClassBotonRedireccion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonRedireccion", "btn btn-outline-info");
                    const ClassBotonBuscar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonBuscar", "btn btn-outline-info");
                    const ClassTitulo = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassTitulo", "");

                    const ClassContenedorGeneral = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedorGeneral", "container");
                    const ClassContenedor = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedor", "container card");
                    const ClassHeader = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassHeader", "card-header info-color white-text text-center py-4");
                    const ClassContenedorForm = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedorForm", "card-body px-lg-5 pt-0");

                    const ClassContenedorControlesAdd = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedorControlesAdd", "");

                    const ClassControles = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassControles", "md-form");

                    const ClassBotonOpcion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonOpcion", "btn btn-info btn dropdown-toggle");

                    const _EstiloTitulo = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloTitulo", "blue");
                    const EstiloTitulo = that.SetStyle(_EstiloTitulo, ColorTitulos, { color: ColorTitulos });
                    const _EstiloBotonGuardar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonGuardar", "");
                    const EstiloBotonGuardar = that.SetStyle(_EstiloBotonGuardar, ColorBotonGuardar, {
                        "background-color": ColorBotonGuardar,
                        "border-color": ColorBotonGuardar,
                        "cursor": "pointer"
                    });
                    const _EstiloBotonLimpiar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonLimpiar", "");
                    const EstiloBotonLimpiar = that.SetStyle(_EstiloBotonLimpiar, ColorBotonLimpiar, {
                        "background-color": ColorBotonLimpiar,
                        "border-color": ColorBotonLimpiar,
                        "cursor": "pointer"
                    });

                    const _EstiloBotonFiltro = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonFiltro", "");
                    const EstiloBotonFiltro = that.SetStyle(_EstiloBotonFiltro, ColorBotonFiltro, {
                        "background-color": ColorBotonFiltro,
                        "border-color": ColorBotonFiltro,
                        "cursor": "pointer"
                    });

                    const _EstiloBotonImportar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonImportar", "");
                    const EstiloBotonImportar = that.SetStyle(_EstiloBotonImportar, ColorBotonImportar, {
                        "background-color": ColorBotonImportar,
                        "border-color": ColorBotonImportar,
                        "cursor": "pointer"
                    });
                    const _EstiloBotonExportarExcel = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonExportarExcel", "");
                    const EstiloBotonExportarExcel = that.SetStyle(_EstiloBotonExportarExcel, ColorBotonExportarExcel, {
                        "background-color": ColorBotonExportarExcel,
                        "border-color": ColorBotonExportarExcel,
                        "cursor": "pointer"
                    });
                    const _EstiloBotonExportarCsv = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonExportarCsv", "");
                    const EstiloBotonExportarCsv = that.SetStyle(_EstiloBotonExportarCsv, ColorBotonExportarCsv, {
                        "background-color": ColorBotonExportarCsv,
                        "border-color": ColorBotonExportarCsv,
                        "cursor": "pointer"
                    });

                    const _EstiloBotonRedireccion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonRedireccion", "");
                    const EstiloBotonRedireccion = that.SetStyle(_EstiloBotonRedireccion, ColorBotonRedireccion, {
                        "background-color": ColorBotonRedireccion,
                        "border-color": ColorBotonRedireccion,
                        "cursor": "pointer"
                    });

                    const _EstiloBotonBuscar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonBuscar", "");
                    const EstiloBotonBuscar = that.SetStyle(_EstiloBotonBuscar, ColorBotonBuscar, {
                        "background-color": ColorBotonBuscar,
                        "border-color": ColorBotonBuscar,
                        "cursor": "pointer"
                    });



                    const _EstiloContenedorGeneral = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedorGeneral", "");
                    const EstiloContenedorGeneral = that.SetStyle(_EstiloContenedorGeneral, null, null);

                    const _EstiloContenedor = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedor", "");
                    const EstiloContenedor = that.SetStyle(_EstiloContenedor, null, null);
                    const _EstiloHeader = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloHeader", "");
                    const EstiloHeader = that.SetStyle(_EstiloHeader, null, null);
                    const _EstiloContenedorForm = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedorForm", "");
                    const EstiloContenedorForm = that.SetStyle(_EstiloContenedorForm, null, null);


                    const _EstiloContenedorControlesAdd = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedorControlesAdd", "");
                    const EstiloContenedorControlesAdd = that.SetStyle(_EstiloContenedorControlesAdd, null, null);



                    const ClassNodos = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodos", "");//'container alert alert-primary'
                    const ClassNodosNuevos = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosNuevos", "");
                    const ClassNodosEliminados = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosEliminados", "");
                    const ClassNodosModificados = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosModificados", "");
                    const ClassNodosCambioPadre = that.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosCambioPadre", "");
                    

                    StaticData.Estilos = {
                        EstiloBotonGuardar: EstiloBotonGuardar,
                        EstiloBotonLimpiar: EstiloBotonLimpiar,
                        EstiloBotonFiltro: EstiloBotonFiltro,
                        EstiloBotonImportar: EstiloBotonImportar,
                        EstiloBotonExportarExcel: EstiloBotonExportarExcel,
                        EstiloBotonExportarCsv: EstiloBotonExportarCsv,
                        ClassBotonGuardar: ClassBotonGuardar,
                        ClassBotonLimpiar: ClassBotonLimpiar,
                        ClassBotonFiltro: ClassBotonFiltro,
                        ClassBotonImportar: ClassBotonImportar,

                        ClassBotonExportarExcel: ClassBotonExportarExcel,
                        ClassBotonExportarCsv: ClassBotonExportarCsv,
                        ClassControles: ClassControles,
                        ClassBotonOpcion: ClassBotonOpcion,
                        EstiloTitulo: EstiloTitulo,
                        ClassTitulo: ClassTitulo,
                        EstiloContenedor: EstiloContenedor,
                        ClassContenedor: ClassContenedor,

                        EstiloHeader: EstiloHeader,
                        ClassHeader: ClassHeader,
                        EstiloContenedorForm: EstiloContenedorForm,
                        ClassContenedorForm: ClassContenedorForm,
                        EstiloContenedorGeneral: EstiloContenedorGeneral,
                        ClassContenedorGeneral: ClassContenedorGeneral,
                        EstiloContenedorControlesAdd: EstiloContenedorControlesAdd,
                        ClassContenedorControlesAdd: ClassContenedorControlesAdd,
                        Tema: Tema,
                        EstiloBotonRedireccion: EstiloBotonRedireccion,
                        ClassBotonRedireccion: ClassBotonRedireccion,
                        EstiloBotonBuscar: EstiloBotonBuscar,
                        ClassBotonBuscar: ClassBotonBuscar,
                        ClassNodos: ClassNodos,
                        ClassNodosNuevos: ClassNodosNuevos,
                        ClassNodosEliminados: ClassNodosEliminados,
                        ClassNodosModificados: ClassNodosModificados,
                        ClassNodosCambioPadre: ClassNodosCambioPadre,
                        MenuSuperior: {
                            "border-bottom": ColorMenu + " 2px solid"

                        },
                        Linea: {
                            height: "50px", "border-bottom": ColorMenu + " 2px solid"

                        },



                    };*/

                    const MainMenu: NavItem = {
                        displayName: "",
                        disabled: false,
                        iconName: "",
                        sigla: "",
                        children: [],
                        style: "",
                        colorMenu: "",
                        menu: {}
                    };

                    //
                    if (StaticData.Usuario.MenusUsuario) {
                        that.CrearMenus(StaticData.Usuario.MenusUsuario, MainMenu, true, that);

                        const Temp = MainMenu.children;
                    }
                    else
                        console.log("No Hay menus asignados");
                    //
                    StaticData.Usuario.Paginas.filter(pagina => { return pagina.IsCustom || pagina.IsDashboard; }).forEach(element => {


                        const rout = APP_ROUTES.find(t => t.path == element.Ruta);
                        if (!rout) {
                            if (element.IsDashboard)
                                APP_ROUTES.push({ path: element.Ruta, component: DashboardComponent });
                            else
                                APP_ROUTES.push({ path: element.Ruta, component: GeneralFormComponent });
                        }

                        // this.router.config.push({ path: element.Ruta, component: GeneralFormComponent });
                    });
                    const rout = APP_ROUTES.find(t => t.path == "**");
                    if (!rout)
                        APP_ROUTES.push({ path: "**", pathMatch: "full", redirectTo: "Home" });

                    this.router.resetConfig(APP_ROUTES);
                    that.comunicationService.raiseNavItemsEvent(MainMenu.children);

                }
                }

                

                that.SetProcesando(false);
                //if (StaticData.UseWebWorker == false) {

                that.closeEmitter.emit(true);

                //}
            }).catch(error => {
                console.log(error);
                that.SetProcesando(false);
                that.closeEmitter.emit(true);
                //that.endEmitter.emit(false);

                that.comunicationService.raiseLoginErrorEvent(error);
            });

        //baseService

    }


    public loginSHP() {
        try {
            const that = this;
            ExecuteOrDelayUntilScriptLoaded(function () {
                const siteCollectionURL = _spPageContextInfo.siteAbsoluteUrl;
                const context = new SP.ClientContext.get_current();
                const web = context.get_web();
                const currentUser = web.get_currentUser();
                currentUser.retrieve();
                context.load(web);
                context.executeQueryAsync(function () {

                    const userObject = web.get_currentUser();
                    try {
                        const email = userObject.get_email();
                        const id = userObject.get_id();
                        const loginName = userObject.get_loginName();
                        const title = userObject.get_title();
                        console.log(userObject);
                        console.log(loginName);
                        if (email !== undefined && email != "") {
                            that.onSubmit(null, email);
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }
                }, function (o, args) {

                    console.log(args);
                });
            }, "sp.js");
        }
        catch (err) {

            console.log(err);
        }
    }


    resultmodalNotify(data: any) {

    }

    showPass(show, id) {
      const tipo = document.getElementById(id);
      if(show == true){
        tipo.setAttribute('type', 'text');
      }else {
        tipo.setAttribute('type', 'password');
      }
    }

    public get ViewSelectLanguage() {
        return StaticData.ViewSelectLanguage;
    }

    onLanguageChange(event: any){
        localStorage.setItem('idioma', event.sigla);
        StaticData.Language = event.sigla;
        window.location.reload();
    }

}
