import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import {
  FormActionsThroughEmitters,
  TipoObjetoParaPermisos, TipoParaPermisos
} from '../../models/general.enum';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { Subject } from 'rxjs';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Guid } from 'guid-typescript';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { DataTableDirective } from 'angular-datatables';

// FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-admin-permisos',
  templateUrl: './admin-permisos.component.html',
  styleUrls: ['./admin-permisos.component.scss']
})

export class AdminPermisosComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;

  imagenEditorJson: string = '';
  imagenAEditorAce: string = '';

  @Output() onInitialized = new EventEmitter<any>();
  @Output() componentName: string = 'AdminPermisosComponent';
  @Input() public name: string = 'AdminPermisosComponent';
  @Input() public nameSecurity: string = 'AdminPermisosComponent';
  @Input() public nameSecurityInternal: string = 'AdminPermisosComponentInternal';
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() config: any;
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;
  @Input() tituloUsuarios: string;
  @Input() tituloRoles: string;
  @Input() tituloGrupos: string;
  @Input() tituloAreasEmpresa: string;
  @Input() guardarPermitido: boolean = true;
  @Input() verPermitido: boolean = true;
  @Input() esDocumento: boolean = false;
  @Input() noEsPagina: boolean = true;
  @Input() esExpediente: boolean = false;
  @Input() esImportar: boolean = false;
  @Input() conCamposPermisos: boolean = true;
  @Input() nombreTablaUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() nombreTablaRolesPermitidos: string = 'RolesPermitidos';
  @Input() nombreTablaGruposPermitidos: string = 'GruposPermitidos';
  @Input() nombreTablaAreasEmpresaPermitidos: string = 'AreasEmpresaPermitidos';
  @Input() nombreTabUsuarios: string = 'usuarios-tabcontent';
  @Input() nombreHrefTabUsuarios: string = '#usuarios-tabcontent';
  @Input() nombreTabRoles: string = 'roles-tabcontent';
  @Input() nombreHrefTabRoles: string = '#roles-tabcontent';
  @Input() nombreTabGrupos: string = 'grupos-tabcontent';
  @Input() nombreHrefTabGrupos: string = '#grupos-tabcontent';
  @Input() nombreTabAreas: string = 'areas-tabcontent';
  @Input() nombreHrefTabAreas: string = '#areas-tabcontent';
  @Input() index: number;

  isInit: boolean = false;
  _modelo: any;

  @Input() set modelo(value: any) {
    this._modelo = value;
    if (this.isInit == true) {
      // setTimeout(() => {
      //   
      //   this.SetModelo(this._modelo);
      // }, 500);

    }
  }

  get modelo(): any {
    return this._modelo;
  }

  @Input() propConfiguracionInterna: string = '';
  @Input() propConfiguracion: string = 'Configuracion';
  @Input() propUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() propRolesPermitidos: string = 'RolesPermitidos';
  @Input() propAreasEmpresaPermitidas: string = 'AreasEmpresaPermitidas';
  @Input() propGruposPermitidos: string = 'GruposPermitidos';
  @Input() maxWith: any = 600;

  estiloTablas: any = {};

  dtOptionsUsuarios: any;
  dtOptionsRoles: any;
  dtOptionsGrupos: any;
  dtOptionsAreas: any;

  dtTriggerUsuarios: Subject<any> = null;// new Subject<any>();
  dtTriggerRoles: Subject<any> = null;//
  dtTriggerGrupos: Subject<any> = null;//
  dtTriggerAreas: Subject<any> = null;//

  @Input() itemsUsuarios: Array<any> = [];
  @Input() itemsRoles: Array<any> = [];
  @Input() itemsGrupos: Array<any> = [];
  @Input() itemsAreasEmpresa: Array<any> = [];

  @Input() ___itemsUsuarios: Array<any> = [];


  UsuariosPermitidos = new List<any>([]);
  RolesPermitidos = new List<any>([]);
  GruposPermitidos = new List<any>([]);
  AreasEmpresaPermitidas = new List<any>([]);
  ListaPermisos = new List<any>([]);

  imagenChecked: string;
  imagenUnChecked: string;
  imagenUsuario: string;
  imagenRole: string;
  imagenGrupo: string;
  imagenArea: string;

  imagenEditar: string;
  imagenEliminar: string;
  imagenConsultar: string;
  imagenImportar: string;
  imagenExportar: string;
  imagenGuardar: string;
  imagenFiltrar: string;
  imagenVer: string;
  imagenVerPermisos: string;
  imagenAdminPermisos: string;
  imagenDescargar: string;
  imagenCambiarDoc: string;
  imagenCrearDoc: string;
  imagenTodos: string;
  imagenDecifrarGrid: string;
  imagenDecifrarForm: string;
  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;

  @Input() isForPermissions: boolean = false;
  @Input() setDefault: boolean = false;
  currentItem: any;
  tituloPermisos: string;
  tipoPermisos: string;
  encontradoPor = [];
  @Input() pageLength: any

  public get VerTabUsuarios(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosUsuario != true) ? true : false;
  }
  public get VerTabRoles(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosRoles != true) ? true : false;
  }
  public get VerTabGrupos(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosGrupos != true) ? true : false;
  }
  public get VerTabAreaEmpresa(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosAreaEmpresa != true) ? true : false;
  }
  constructor(public utility: Utilities, public comunicationService: GeneralComunicationService) {
    //   this.OnInit();
  }

  //* FUNCIONES ANGULAR

  ngOnInit() {


    this.OnInit();
    this.OnSetConfig();
    if (this.maxWith) {
      this.estiloTablas = { 'overflow-x': 'scroll', 'overflow-y': 'hidden', 'max-width': this.maxWith + 'px' };
    }
    this.SetModelo(this.modelo);
    this.isInit = true;

    if (this.parentClearCall) {
      // console.error("AdminPermisosComponent,parentClearCall observers: "+this.parentClearCall.observers.length);
      this.parentClearCall.subscribe(event => {
        this.InitControl(event);
      });
    }
    this.comunicationService.receivedFormEvent.subscribe(event => {
      if (event.Action == FormActionsThroughEmitters.Edit) {
        this.InitControl(event);
      }
    });
    if (this.onInitialized.observers.length) {
      // console.error("AdminPermisosComponent,onInitialized, observers: "+this.onInitialized.observers.length);
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }

    if (this.index) {
      this.nombreTablaUsuariosPermitidos = this.nombreTablaUsuariosPermitidos + this.index.toString();
      this.nombreTablaRolesPermitidos = this.nombreTablaRolesPermitidos + this.index.toString();
      this.nombreTablaGruposPermitidos = this.nombreTablaGruposPermitidos + this.index.toString();
      this.nombreTablaAreasEmpresaPermitidos = this.nombreTablaAreasEmpresaPermitidos + this.index.toString();
      this.nombreTabUsuarios = this.nombreTabUsuarios + this.index.toString();
      this.nombreHrefTabUsuarios = this.nombreHrefTabUsuarios + this.index.toString();
      this.nombreTabRoles = this.nombreTabRoles + this.index.toString();
      this.nombreHrefTabRoles = this.nombreHrefTabRoles + this.index.toString();
      this.nombreTabGrupos = this.nombreTabGrupos + this.index.toString();
      this.nombreHrefTabGrupos = this.nombreHrefTabGrupos + this.index.toString();
      this.nombreTabAreas = this.nombreTabAreas + this.index.toString();
      this.nombreHrefTabAreas = this.nombreHrefTabAreas + this.index.toString();
    }

  }

  public LimpiarListas() {
    this.itemsUsuarios = [];
    this.itemsRoles = [];
    this.itemsGrupos = [];
    this.itemsAreasEmpresa = [];
    this.tituloPermisos = null;
    this.tipoPermisos = '';
    this.encontradoPor = [];
  }
  public SetItemFromJson(item: any, _tituloPermisos: string, _tipoPermisos: string, itemFiltro: any, tipoFiltro: string, subTipoFiltro: string, propConfiguracion: string = 'Configuracion') {


    this.tituloPermisos = _tituloPermisos;
    this.tipoPermisos = _tipoPermisos;
    this.encontradoPor = [];
    if (item) {



      this.ListaPermisos = this.utility.GetPermisos(item, true, propConfiguracion, false, this.propConfiguracionInterna, true);
      let items = this.ListaPermisos.ToArray();
      this.itemsUsuarios = items.filter(x => { return x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario' });
      this.itemsRoles = items.filter(x => { return x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role' });
      this.itemsGrupos = items.filter(x => { return x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo' });
      this.itemsAreasEmpresa = items.filter(x => { return x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa' });




      this.SetSeleccionados(this.itemsUsuarios, TipoParaPermisos.Usuario, new List<any>([]), this.propUsuariosPermitidos);
      this.SetSeleccionados(this.itemsRoles, TipoParaPermisos.Role, new List<any>([]), this.propRolesPermitidos);
      this.SetSeleccionados(this.itemsAreasEmpresa, TipoParaPermisos.AreaEmpresa, new List<any>([]), this.propAreasEmpresaPermitidas);
      this.SetSeleccionados(this.itemsGrupos, TipoParaPermisos.Grupo, new List<any>([]), this.propGruposPermitidos);


      let dataGrupo = "";
      // if (tipoFiltro == 'Usuario') {
      if (subTipoFiltro == 'Menu' && itemFiltro.TodosPermisosMenus)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Pagina' && itemFiltro.TodosPermisosPaginas)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Expediente' && itemFiltro.TodosPermisosExpedientes)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Documento' && itemFiltro.TodosPermisosDocumentosExpedientes)
        this.encontradoPor.push("TodosPermisos");


      if (itemFiltro.IdUsuario && this.itemsUsuarios.find(o => o.Id.toString() == itemFiltro.IdUsuario.toString()))
        this.encontradoPor.push("Usuario");
      if (itemFiltro.IdRole && this.itemsRoles.find(o => o.Id.toString() == itemFiltro.IdRole.toString()))
        this.encontradoPor.push("Role");
      if (itemFiltro.IdAreaEmpresa && this.itemsAreasEmpresa.find(o => o.Id.toString() == itemFiltro.IdAreaEmpresa.toString()))
        this.encontradoPor.push("AreaEmpresa");

      if (itemFiltro.Grupos && itemFiltro.Grupos.split('|').length > 0) {
        let grupos: Array<any> = itemFiltro.Grupos.split('|');
        for (var i = 0; i < grupos.length; i++) {
          let grupo = grupos[i].split('-');;
          if (this.itemsGrupos.find(o => o.Id.toString() == grupo[0].toString())) {

            dataGrupo += grupo[1] + "(" + grupo[0] + "),";
          }

        }

        if (dataGrupo) {
          this.encontradoPor.push("Grupos:[" + dataGrupo + "]");

        }
      }
      //}
      //this.Rerender();
    }
  }
  InitControl(event) {
    setTimeout(() => {

      this.modelo = event.modelo;
      this.SetModelo(event.modelo);

    }, 100);
  }
  ngAfterViewInit() {

    setTimeout(() => {
      var PivotElement = document.querySelector("#" + this.nameSecurity);
      new fabric['Pivot'](PivotElement);

      var PivotElement2 = document.querySelector("#" + this.nameSecurityInternal);
      new fabric['Pivot'](PivotElement2);
    }, 400);

    if (this.isForPermissions) {

      // this.dtTriggerUsuarios.next();
      // this.dtTriggerRoles.next();
      // this.dtTriggerGrupos.next();
      // this.dtTriggerAreas.next();

    }
  }

  ngOnDestroy(): void {

    this.utility.Unsubscribe(this.parentClearCall);
    this.utility.Unsubscribe(this.parentCallRefresh);


    this.utility.Unsubscribe(this.dtTriggerAreas);
    this.utility.Unsubscribe(this.dtTriggerGrupos);
    this.utility.Unsubscribe(this.dtTriggerRoles);
    this.utility.Unsubscribe(this.dtTriggerUsuarios);

  }

  Rerender(): void {

    let count = 1;
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {

      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

        let name = dtInstance.name;
        if (name == 'UsuariosPermitidos') {
          //  dtInstance.destroy();
          //  this.dtTriggerUsuarios.next();
        }
        else if (name == 'RolesPermitidos') {
          //  dtInstance.destroy();
          // this.dtTriggerRoles.next();
        }
        else if (name == 'GruposPermitidos') {
          //  dtInstance.destroy();
          // this.dtTriggerGrupos.next();
        }
        else if (name == 'AreasPermitidos') {
          //  dtInstance.destroy();
          // this.dtTriggerAreas.next();
        }
        count++;
      });
    });
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     // Destroy the table first

    //     dtInstance.destroy();
    //     // Call the dtTrigger to rerender again
    //     this.dtTrigger.next();
    // });
  }
  //*  END FINCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public OnInit() {

    if (!this.isForPermissions)
      this.utility.SetItemsAreasRolesUsuarioSeries(this, true, true);
    else {

      // this.dtTriggerUsuarios = new Subject<any>();
      // this.dtTriggerRoles = new Subject<any>();
      // this.dtTriggerGrupos = new Subject<any>();
      // this.dtTriggerAreas = new Subject<any>();
    }
    // this.dtOptionsUsuarios = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    // this.dtOptionsRoles = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    // this.dtOptionsGrupos = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    // this.dtOptionsAreas = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    // this.imagenChecked = this.utility.GetPathImages(this.utility.GetNodeType('Activo'));
    // this.imagenUnChecked = this.utility.GetPathImages(this.utility.GetNodeType('Inactivo'));
    // this.imagenUsuario = this.utility.GetPathImages(this.utility.GetNodeType('Usuario'));
    // this.imagenRole = this.utility.GetPathImages(this.utility.GetNodeType('Role'));
    // this.imagenGrupo = this.utility.GetPathImages(this.utility.GetNodeType('Grupo'));
    // this.imagenArea = this.utility.GetPathImages(this.utility.GetNodeType('AreaEmpresa'));
    // this.imagenEditar = this.utility.GetPathImages(this.utility.GetNodeType('Editar'));
    // this.imagenEliminar = this.utility.GetPathImages(this.utility.GetNodeType('Eliminar'));
    // this.imagenConsultar = this.utility.GetPathImages(this.utility.GetNodeType('Consultar'));
    // this.imagenImportar = this.utility.GetPathImages(this.utility.GetNodeType('Importar'));
    // this.imagenExportar = this.utility.GetPathImages(this.utility.GetNodeType('Exportar'));
    // this.imagenGuardar = this.utility.GetPathImages(this.utility.GetNodeType('Guardar'));
    // this.imagenFiltrar = this.utility.GetPathImages(this.utility.GetNodeType('Filtrar'));
    // this.imagenVer = this.utility.GetPathImages(this.utility.GetNodeType('Ver'));
    // this.imagenVerPermisos = this.utility.GetPathImages(this.utility.GetNodeType('VerPermisos'));
    // this.imagenAdminPermisos = this.utility.GetPathImages(this.utility.GetNodeType('AdminPermisos'));
    // this.imagenDescargar = this.utility.GetPathImages(this.utility.GetNodeType('Descargar'));
    // this.imagenEditorJson = this.utility.GetPathImages(this.utility.GetNodeType('EditorJson'));
    // this.imagenAEditorAce = this.utility.GetPathImages(this.utility.GetNodeType('EditorAce'));
    // this.imagenCambiarDoc = this.utility.GetPathImages(this.utility.GetNodeType('CambiarDoc'));
    // this.imagenCrearDoc = this.utility.GetPathImages(this.utility.GetNodeType('CrearDoc'));

    this.dtOptionsUsuarios = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    this.dtOptionsRoles = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    this.dtOptionsGrupos = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    this.dtOptionsAreas = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    this.imagenChecked = 'ms-Icon--CompletedSolid';
    this.imagenUnChecked = 'ms-Icon--ChromeClose';
    this.imagenUsuario = 'ms-Icon--FabricUserFolder';
    this.imagenRole = 'ms-Icon--UserFollowed';
    this.imagenGrupo = 'ms-Icon--Group';
    this.imagenArea = 'ms-Icon--Org';
    this.imagenEditar = 'ms-Icon--PageEdit';
    this.imagenEliminar = 'ms-Icon--Delete';
    this.imagenConsultar = 'ms-Icon--SearchData';
    this.imagenImportar = 'ms-Icon--CloudImportExport';
    this.imagenExportar = 'ms-Icon--Upload';
    this.imagenGuardar = 'ms-Icon--Save';
    this.imagenFiltrar = 'ms-Icon--Filter';
    this.imagenVer = 'ms-Icon--RedEye';
    this.imagenVerPermisos = 'ms-Icon--Permissions';
    this.imagenAdminPermisos = 'ms-Icon--Permissions';
    this.imagenDescargar = 'ms-Icon--Download';
    this.imagenEditorJson = 'ms-Icon--CodeEdit';
    this.imagenAEditorAce = 'ms-Icon--EditSolidMirrored12';
    this.imagenCambiarDoc = 'ms-Icon--ChangeEntitlements';
    this.imagenCrearDoc = 'ms-Icon--PageAdd';
    this.imagenTodos = 'ms-Icon--PageAdd';
    this.imagenDecifrarForm = 'ms-Icon--Encryption';
    this.imagenDecifrarGrid = 'ms-Icon--Encryption';
  }

  public OnSetConfig() {

    this.nameSecurity = "msPivotAdminPermisos" + "_" + Guid.create().toString();
    this.nameSecurityInternal = "msPivotAdminPermisosInternal" + "_" + Guid.create().toString();
    if (this.setDefault) {
      this.tituloUsuarios = "Usuarios";
      this.tituloRoles = "Roles";
      this.tituloAreasEmpresa = "Dependencias";
      this.tituloGrupos = "Grupos";
    }
    else if (this.config) {
      this.propConfiguracionInterna = this.config.ConfigInternalProp || this.propConfiguracionInterna;
      this.propConfiguracion = this.config.ConfigProp || this.propConfiguracion;
      this.tituloUsuarios = this.config.UsersTitle || this.tituloUsuarios;
      this.tituloRoles = this.config.RolesTitle || this.tituloRoles;
      this.tituloAreasEmpresa = this.config.AreasTitle || this.tituloAreasEmpresa;
      this.tituloGrupos = this.config.GroupsTitle || this.tituloGrupos;
      this.conCamposPermisos = (this.config.WithPermisionsField !== null) ? this.config.WithPermisionsField : this.conCamposPermisos;
      this.maxWith = this.config.MaxWith || this.maxWith;
      
      if (this.config.IsImport)
        this.esImportar = this.config.IsImport;
      if (this.config.IsDoc)
         this.esDocumento = true;// (this.config.IsDoc !== null) ? this.config.IsDoc : this.esDocumento;
      if (this.config.NotIsPage == false)
        this.noEsPagina = false;// (this.config.NotIsPage !== null) ? this.config.NotIsPage : this.noEsPagina;
      if (this.config.IsExpe)
        this.esExpediente = this.config.IsExpe;
      this.propUsuariosPermitidos = this.config.UsersProp || this.propUsuariosPermitidos;
      this.propRolesPermitidos = this.config.RolesProp || this.propRolesPermitidos;
      this.propAreasEmpresaPermitidas = this.config.AreasProp || this.propAreasEmpresaPermitidas;
      this.propGruposPermitidos = this.config.GroupsProp || this.propGruposPermitidos;
      this.name = this.config.Name || this.name;



      /*   @Input() propUsuariosPermitidos: string = 'UsuariosPermitidos';
        @Input() propRolesPermitidos: string = 'RolesPermitidos';
        @Input() propAreasEmpresaPermitidas: string = 'AreasEmpresaPermitidas';
        @Input() propGruposPermitidos: string = 'GruposPermitidos'; */

      let _config = {
        "ConfigInternalProp": "",
        "ConfigProp": "Configuracion",
        "UsersTitle": "",
        "RolesTitle": "",
        "AreasTitle": "",
        "GroupsTitle": "",
        "UsersProp": "UsuariosPermitidos",
        "RolesProp": "RolesPermitidos",
        "AreasProp": "AreasEmpresaPermitidas",
        "GroupsProp": "GruposPermitidos",
        "WithPermisionsField": true,
        "NotIsPage": true,
        "IsDoc": false,
        "MaxWith": 900

      };
    }
  }

  public SetModelo(modelo: any, force?: any) {
    if (force == undefined)
      force = true
    this._modelo = modelo;


    if (this.isForPermissions) {
      return;

    }
    if (this.modelo) {
      if (this.isModelContainer) {
        this.ListaPermisos = this.utility.GetPermisos(this.modelo.modelo, true, this.propConfiguracion, false, this.propConfiguracionInterna, force);
        if (this.conCamposPermisos == true) {
          this.UsuariosPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propUsuariosPermitidos);
          this.RolesPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propRolesPermitidos);
          this.AreasEmpresaPermitidas = this.utility.GetPermitidos(this.modelo.modelo, true, this.propAreasEmpresaPermitidas);
          this.GruposPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propGruposPermitidos);
        }
      }
      else {
        this.ListaPermisos = this.utility.GetPermisos(this.modelo, true, this.propConfiguracion, false, this.propConfiguracionInterna, true);
        if (this.conCamposPermisos == true) {
          this.UsuariosPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propUsuariosPermitidos);
          this.RolesPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propRolesPermitidos);
          this.AreasEmpresaPermitidas = this.utility.GetPermitidos(this.modelo, true, this.propAreasEmpresaPermitidas);
          this.GruposPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propGruposPermitidos);
        }
      }
    }
    else {
      this.ListaPermisos = new List<any>([]);
      this.UsuariosPermitidos = new List<any>([]);
      this.RolesPermitidos = new List<any>([]);
      this.AreasEmpresaPermitidas = new List<any>([]);
      this.GruposPermitidos = new List<any>([]);
    }

    // this.UsuariosPermitidos = new List<any>([]);
    // this.RolesPermitidos = new List<any>([]);
    // this.AreasEmpresaPermitidas = new List<any>([]);
    // this.GruposPermitidos = new List<any>([]);

    this.SetSeleccionados(this.itemsUsuarios, TipoParaPermisos.Usuario, this.UsuariosPermitidos, this.propUsuariosPermitidos);
    this.SetSeleccionados(this.itemsRoles, TipoParaPermisos.Role, this.RolesPermitidos, this.propRolesPermitidos);
    this.SetSeleccionados(this.itemsAreasEmpresa, TipoParaPermisos.AreaEmpresa, this.AreasEmpresaPermitidas, this.propAreasEmpresaPermitidas);
    this.SetSeleccionados(this.itemsGrupos, TipoParaPermisos.Grupo, this.GruposPermitidos, this.propGruposPermitidos);
    this.SetConfiguracion();

  }

  //! NO HACE NADA
  public ValidarIndefinido(permiso: any, prop: string) {
    //if (permiso[prop] === undefined)
    // permiso[prop] = false
  }

  public SetSeleccionados(items: Array<any>, tipoParaPermisos: TipoParaPermisos, permitidos: List<any>, prop: string) {

    for (let i = 0; i < items.length; i++) {
      let stringType = TipoParaPermisos[tipoParaPermisos];
      let permiso = this.ListaPermisos.FirstOrDefault(x => {
        return x.Id == items[i].Id &&
          (x.Tipo == tipoParaPermisos || x.Tipo == stringType)
      });
      if (permiso) {
        // if (this.noEsPagina) {
        //   this.ValidarIndefinido(permiso, 'Ver');
        //   this.ValidarIndefinido(permiso, 'VerPermisos');
        //   this.ValidarIndefinido(permiso, 'AdminPermisos');
        //   if (this.esDocumento)
        //     this.ValidarIndefinido(permiso, 'Descargar');
        // }
        items[i].Seleccionado = true;
        this.utility.SetPermisosItemActual(items[i], permiso, this.noEsPagina);

        if (!permitidos.Any(x => { return x == 'code' + items[i].Id })) {
          permitidos.Add('code' + items[i].Id);
        }
      }
      else {
        items[i].Seleccionado = false;
        this.utility.SetValorPermisosItemActual(items[i], false, false, this.noEsPagina);

        if (permitidos.Any(x => { return x == 'code' + items[i].Id })) {
          permitidos = permitidos.RemoveAll(x => { return x == 'code' + items[i].Id });
        }
      }
      //permitidos[i].Seleccionado = this.ListaPermisos.Any(x => { return x.Tipo == tipoParaPermisos });
    }

    this.SetPermitidos(permitidos, prop);

  }

  public Adicionar(permitidos: List<any>, item: any, tipoParaPermisos: TipoParaPermisos, prop: string) {

    if (!this.guardarPermitido)
      return;
    if (this.Disabled)
      return;

    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = true;
    item.Consultar = true;
    let stringType = TipoParaPermisos[tipoParaPermisos];
    if (!this.ListaPermisos.Any(x => {
      return x.Id == item.Id &&
        (x.Tipo == tipoParaPermisos || x.Tipo == stringType)
    })) {
      this.ListaPermisos.Add(item);
    }
    if (this.conCamposPermisos == true) {
      if (!permitidos.Any(x => { return x == 'code' + item.Id })) {
        permitidos.Add('code' + item.Id);
      }
    }

    this.SetConfiguracion();
    this.SetPermitidos(permitidos, prop);
    this.FireAction('Add');

  }

  public Eliminar(permitidos: List<any>, item: any, tipoParaPermisos: TipoParaPermisos, prop: string) {

    if (!this.guardarPermitido)
      return;
    if (this.Disabled)
      return;
    let stringType = TipoParaPermisos[tipoParaPermisos];
    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = false;
    item.Consultar = false;
    this.SetInitValuesPermiso(item, false, true);
    this.ListaPermisos = this.ListaPermisos.RemoveAll(x => {
      return x.Id == item.Id &&
        (x.Tipo == tipoParaPermisos || x.Tipo == stringType)
    });

    if (this.conCamposPermisos == true)
      permitidos = permitidos.RemoveAll(x => { return x == 'code' + item.Id });

    this.SetConfiguracion();
    this.SetPermitidos(permitidos, prop);
    this.FireAction('Delete');

  }

  public SetConfiguracion() {

    if (this.modelo) {
      /*  if (this.propConfiguracionInterna) {
         let temp;
         if (typeof this.modelo[this.propConfiguracion] === 'string') {
           temp = (!this.modelo[this.propConfiguracion]) ? {} : JSON.parse(this.modelo[this.propConfiguracion]);
         }
         else {
           temp = (!this.modelo[this.propConfiguracion]) ? {} : this.modelo[this.propConfiguracion];
         }
         temp[this.propConfiguracionInterna] = this.ListaPermisos.ToArray();
         this.modelo[this.propConfiguracion] = JSON.stringify(temp);
       }
       else {
         this.modelo[this.propConfiguracion] = JSON.stringify(this.ListaPermisos.ToArray());
       }
       this.modelo.ListaPermisos = this.ListaPermisos; */
      if (this.isModelContainer)
        this._SetConfiguracion(this.modelo.modelo);
      else
        this._SetConfiguracion(this.modelo);
    }

  }

  public SetPermitidos(permitidos: List<any>, prop: string) {

    if (this.isModelContainer)
      this._SetPermitidos(this.modelo.modelo, permitidos, prop);
    else
      this._SetPermitidos(this.modelo, permitidos, prop);

  }

  public Limpiar(callback: (data: any) => void = null) {

    if (this.Disabled)
      return;
    if (this.isModelContainer)
      this._Limpiar(this.modelo.modelo, callback);
    else
      this._Limpiar(this.modelo, callback);

  }

  public AsignarPermiso(permitidos: List<any>, item: any, prop: string, permiso: string, tipoParaPermisos: TipoParaPermisos) {

    if (!this.guardarPermitido)
      return;

    let _permiso = this.ListaPermisos.FirstOrDefault(x => { return x.Id == item.Id && x.Tipo == tipoParaPermisos });

    if (item[permiso] == true) {
      item[permiso] = !item[permiso];
      if (_permiso && _permiso[permiso] !== item[permiso])
        _permiso[permiso] = item[permiso];
      this.SetConfiguracion();
      this.SetPermitidos(permitidos, prop);
    }
    else {
      item[permiso] = !item[permiso];
      if (_permiso && _permiso[permiso] !== item[permiso])
        _permiso[permiso] = item[permiso];
      this.Adicionar(permitidos, item, tipoParaPermisos, prop);
    }

    this.FireAction('Set');

  }

  public AsignarPermisoUsuario(item: any, permiso: string) {

    this.AsignarPermiso(this.UsuariosPermitidos, item, this.propUsuariosPermitidos, permiso, TipoParaPermisos.Usuario);
    //this.dtTriggerUsuarios.next();

  }

  public SeleccionarUsuario(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.UsuariosPermitidos, item, TipoParaPermisos.Usuario, this.propUsuariosPermitidos);


    }
    else {
      this.Adicionar(this.UsuariosPermitidos, item, TipoParaPermisos.Usuario, this.propUsuariosPermitidos);
    }
    // this.AsignarPermisoUsuario(item, 'Exportar');
    // this.AsignarPermisoUsuario(item, 'Eliminar');
    // this.AsignarPermisoUsuario(item, 'Editar');
    // this.AsignarPermisoUsuario(item, 'Guardar');
    // this.AsignarPermisoUsuario(item, 'Importar');
    // this.AsignarPermisoUsuario(item, 'DecifrarGrid');
    // this.AsignarPermisoUsuario(item, 'DecifrarForm');
    // if (this.esDocumento) {
    //   this.AsignarPermisoUsuario(item, 'Ver');
    //   this.AsignarPermisoUsuario(item, 'Descargar');
    //   this.AsignarPermisoUsuario(item, 'CambiarDoc');
    // }
    // if(this.noEsPagina){
    //   this.AsignarPermisoUsuario(item, 'AdminPermisos');

    // }
    // if (this.esExpediente) {
    //   this.AsignarPermisoUsuario(item, 'CrearDoc');
    //   this.AsignarPermisoUsuario(item, 'Firmar');

    // }
  }

  public AsignarPermisoRole(item: any, permiso: string) {

    this.AsignarPermiso(this.RolesPermitidos, item, this.propRolesPermitidos, permiso, TipoParaPermisos.Role);
    //this.dtTriggerRoles.next();

  }

  public SeleccionarRole(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.RolesPermitidos, item, TipoParaPermisos.Role, 'RolesPermitidos');
    }
    else {
      this.Adicionar(this.RolesPermitidos, item, TipoParaPermisos.Role, 'RolesPermitidos');
    }

  }

  public AsignarPermisoGrupo(item: any, permiso: string) {

    this.AsignarPermiso(this.GruposPermitidos, item, this.propGruposPermitidos, permiso, TipoParaPermisos.Grupo);
    //this.dtTriggerGrupos.next();

  }

  public SeleccionarGrupo(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.GruposPermitidos, item, TipoParaPermisos.Grupo, 'GruposPermitidos');
    }
    else {
      this.Adicionar(this.GruposPermitidos, item, TipoParaPermisos.Grupo, 'GruposPermitidos');
    }

  }

  public AsignarPermisoArea(item: any, permiso: string) {

    this.AsignarPermiso(this.AreasEmpresaPermitidas, item, this.propAreasEmpresaPermitidas, permiso, TipoParaPermisos.AreaEmpresa);
    //this.dtTriggerAreas.next();

  }

  public SeleccionarArea(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.AreasEmpresaPermitidas, item, TipoParaPermisos.AreaEmpresa, this.propAreasEmpresaPermitidas);
    }
    else {
      this.Adicionar(this.AreasEmpresaPermitidas, item, TipoParaPermisos.AreaEmpresa, this.propAreasEmpresaPermitidas);
    }

  }

  public VerEditor(tipo: any) {

    this.utility.VerEditor(tipo, this.modelo.ListaPermisos, false, "Editor de [Permisos]").then(data => {
      if (data.dialogResult == true)
        this.SetConfiguracion();
    });

  }

  FireAction(actionType: string) {

    this.onActionEventEmitter.emit({
      modelo: this.modelo,
      type: this.name,
      componentName: this.componentName,
      actionType: actionType,
    });

  }

  VerAlertaPermisosGuardar() {

    StaticData.ShowNotification('No Posee permisos para esta funcionalidad', 'danger');

  }

  _Limpiar(modelo: any, callback: (data: any) => void = null) {

    modelo = {};
    modelo.PkValue = 0;
    modelo.UsuariosPermitidos = null;
    modelo.RolesPermitidos = null;
    modelo.AreasEmpresaPermitidas = null;
    modelo.GruposPermitidos = null;
    modelo.Configuracion = null;
    if (callback) callback(modelo);

  }

  _SetConfiguracion(modelo: any) {

    if (this.propConfiguracionInterna) {
      let temp;
      if (typeof modelo[this.propConfiguracion] === 'string') {
        temp = (!modelo[this.propConfiguracion]) ? {} : JSON.parse(modelo[this.propConfiguracion]);
      }
      else {
        temp = (!modelo[this.propConfiguracion]) ? {} : modelo[this.propConfiguracion];
      }
      temp[this.propConfiguracionInterna] = this.ListaPermisos.ToArray();
      modelo[this.propConfiguracion] = JSON.stringify(temp);
    }
    else {
      modelo[this.propConfiguracion] = JSON.stringify(this.ListaPermisos.ToArray());
    }
    modelo.ListaPermisos = this.ListaPermisos;
    //this.modelo.DataAdd = { Configuracion: JSON.stringify(this.ListaPermisos.ToArray()) };

  }

  _SetPermitidos(modelo: any, permitidos: List<any>, prop: string) {

    if (modelo && this.conCamposPermisos)
      modelo[prop] = permitidos.ToArray().join('|');// '|' + permitidos.ToArray().join('|');

  }
  currentItemNombre: string;
  OpenAdd(open: any, item: any) {

    if (!this.noEsPagina)
      return;
    const container = <any>$('.ap__add-container');
    const userTitle = <any>$('.ap__add-user');
    if (open) {
      if (!item)
        return;
      if (item.Seleccionado) {
        this.SetInitValuesPermiso(item, false)

        this.currentItem = item;
        container.css('display', 'initial');
        userTitle.html(item.Nombre);
      }
    } else {
      container.css('display', 'none');
      this.currentItem = null;
    }
  }

  SetInitValuesPermiso(item, value: boolean, clear: boolean = false) {
    if (!item.Heredar || item.Heredar.length == 0) {
      item.Heredar = [{ Nombre: 'Eliminar', Valor: value },
      { Nombre: 'Editar', Valor: value },
      { Nombre: 'Guardar', Valor: value }];
    }

    if (!item.HeredarExpediente || item.HeredarExpediente.length == 0) {
      item.HeredarExpediente = [
        { Nombre: 'Eliminar', Valor: value },
        { Nombre: 'Editar', Valor: value },
        { Nombre: 'Guardar', Valor: value },
        { Nombre: 'Descargar', Valor: value },
        { Nombre: 'Ver', Valor: value },
        { Nombre: 'CambiarDoc', Valor: value }];

    }

    if (clear) {
      item.Heredar.forEach(x => {
        x.Valor = value;
      });

      item.HeredarExpediente.forEach(x => {
        x.Valor = value;
      });
    }
    if (!item.Custom || item.Custom.length == 0)
      item.Custom = [];
  }
  AddNewCustomPermision() {
    if (this.currentItem) {
      if (!this.currentItem.Custom.find(x => { return x.Nombre == this.currentItemNombre })) {
        this.currentItem.Custom.push({ Nombre: this.currentItemNombre, Valor: true });
        this.currentItemNombre = null;
      }
    }
  }
  DeleteCustomPermision(items, index) {
    items.splice(index, 1);
  }
  //* END FUNCIONES PERSONALIZADAS

}
