import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service'
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { Guid } from "guid-typescript";
import { ControlActions, ControllerMethods, TipoObjetoParaPermisos } from 'src/app/models/general.enum';

@Component({
  selector: 'app-rcausalrechazo',
  templateUrl: './rcausalrechazo.component.html',
  styleUrls: ['./rcausalrechazo.component.css']
})
export class RcausalrechazoComponent extends GeneralFormComponent implements OnInit, AfterViewInit {

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    super(baseService, utility, helperImages, helperRules, helperActions);
  }

  ctrActivo: any;

  ngAfterViewInit() {
    this.ctrActivo = this.form.controls["Activo"];    

    if (this.ctrActivo) {
      //debugger;
      this.ctrActivo.valueChanges.subscribe(value => {
        //debugger;
        if (value != null) {
          if (!value) {
            const context = this;
            const action = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["Activo"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: "true",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };
            this.helperActions.SetControlsValue(action, context);

            this.modelo.modelo.Activo = null;
            this.SetModelToControls();
            $("input[id='Activo']").prop('checked', false);
            this.ctrActivo.value = null;
            this.ctrActivo.pristine = true;
            this.ctrActivo.errors = { required: true };
            this.ctrActivo.status = "INVALID";
          }
        }
        else {
          $("input[id='Activo']").prop('checked', this.modelo.modelo.Activo );
        }
      });
    }

    super.ngAfterViewInit();
  }


}
