import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, Input, Output, EventEmitter, QueryList }
  from '@angular/core';
import { FormGroup, FormControl, NgForm, Validators, DefaultValueAccessor } from '@angular/forms';
import { Paginas, ControllerMethods, DataTableAjaxOrderTypes, TypesUseForm, ControlActions, FormActionsThroughEmitters } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralBaseDataTable } from 'src/app/models/general-base-data-table';
import { DataTableComponent } from '../../controls/data-table/data-table.component';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { FormDetailsComponent } from '../../controls/form-details/form-details.component';
import { ComponentsContainer } from 'src/app/models/components-container';
import { Subscription, Subject } from 'rxjs';
import { ComboBoxManager } from 'src/app/bussines/combo-box-manager';
import { List } from '../../../../assets/linqts/compilado/index';
import { GeneralFormBase } from 'src/app/models/general-form-base';
import { ObservacionesModificacionVersionComponent } from '../observaciones-modificacion-version/observaciones-modificacion-version.component';
import { ConfigWindow } from 'src/app/models/config-window';
import { StaticData } from 'src/app/helpers/static-data';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { TabHost } from 'dock-spawn-ts/lib/js/TabHost';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { WindowComponent } from '../../window.component/window.component';
import { CommandBarComponent } from '../command-bar/command-bar.component';
import { AttachmentSelectionComponent } from '../attachment-selection/attachment-selection.component';
import { MessageBarComponent } from '../message-bar/message-bar.component';
import { HmacSHA256, enc, TripleDES, AES } from 'crypto-js';
import { Stopwatch } from 'ts-stopwatch';
import { Guid } from "guid-typescript";
//import * as CryptoJS from 'crypto-js';
declare var FileSaver: any;
declare var saveAs: any;
declare var XLSX: any;
declare var XLSXStyle: any;
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare var shortcut: any;

//declare var AzureStorage: any;
@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss']
})
export class GeneralFormComponent extends GeneralFormBase implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('contextMenuGeneralForm', { static: false }) public contextMenuGeneralForm: ContextMenuComponent;
  @ViewChild(CommandBarComponent, { static: false }) public commandBar: CommandBarComponent;
  public childComponents: Array<any> = [];
  @Input() cargarGrilla: boolean = true;


  @Input() pkName: string = null;
  @Input() pkValueEdit: any;




  @Input() IsJson: boolean = false;
  @Input() hideForm: boolean = false;
  @Input() hideDataTable: boolean = false;


  @Input() url: string = null;
  @Input() public importUrl: string = null;

  @Input() QueryConfig: any = null;

  botonProcesando: boolean = false;
  textoBoton: string = 'Guardar';
  textoBotonProcesando: string = '';
  isModelContainer: boolean = true;
  public botonProcesandoBuscar: boolean = false;
  public textoBotonBuscar: string = 'Buscar';
  public textoProcesandoBuscar: string = '';

  swap: boolean = false;

  @Input() public processMessage: string;

  @Input() public comboBoxManager: ComboBoxManager;

  @Input() public parentCallRefresh: Subject<any> = new Subject();
  @Input() public parentClearCall: Subject<any> = new Subject();
  @Input() public formEvent: Subject<any> = new Subject();
  @Output() public onSelectedComboBoxForRules = new EventEmitter<any>();

  @Input() public parentCallAction = new EventEmitter<any>();


  @Output() onInitialized = new EventEmitter<any>();
  @Output() onExtraInitialized = new EventEmitter<any>();
  public guardarValido: boolean = false;

  @Input() viewComponentHeader: boolean = true;
  @Input() viewComponentTitle: boolean = true;

  @Output() loadedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() pageId: any;
  @Input() currentView: any;
  isUnion: boolean = false;

  @Input() config: any;
  @Input() name: string = null;
  @Input() modeloExterno: any

  private subscr: Subscription;
  mappingFieldsEvent: EventEmitter<any> = new EventEmitter<any>()
  @Input() componentsContainer: ComponentsContainer = new ComponentsContainer();

  @Input() inMainPage = true;
  @Input() notLoad: boolean = false;
  @Input() viewCommandBar: boolean = true;
  @Input() commandBarIsComponent: boolean = false;

  public workFlowItem: any = null;
  actionEmitter = new EventEmitter<any>();

  public get HasVersionModelData() {
    return (this.modelo.modelo.hasOwnProperty('VersionModelData') && this.modelo.modelo.VersionModelData);
  }
  public get HasPropVersionModelData() {
    return (this.HasVersionModelData && this.modelo.modelo.VersionModelData && this.modelo.modelo.VersionModelData.hasOwnProperty('Propiedades'));
  }
  get IsVersion() {
    return (this.HasVersionModelData && this.IsEdit);
  }

  @Input() SearcheableConfigs: Array<any> = [];

  currentBusyRecord: any

  @Input() set CurrentBusyRecord(value: any) {
    this.currentBusyRecord = value;

    if (this.Pagina && this.Pagina.IdPagina) {
      if (this.currentBusyRecord)
        localStorage.setItem(this.Pagina.IdPagina + '_CurrentBusyRecord', JSON.stringify(this.currentBusyRecord));
      else
        localStorage.setItem(this.Pagina.IdPagina + '_CurrentBusyRecord', null);
    }
  }
  get CurrentBusyRecord(): any {
    if (this.currentBusyRecord)
      return this.currentBusyRecord;
    else {

      if (this.Pagina && this.Pagina.IdPagina) {

        const _currentBusyRecord = localStorage.getItem(this.Pagina.IdPagina + '_CurrentBusyRecord');
        if (_currentBusyRecord)
          this.currentBusyRecord = JSON.parse(_currentBusyRecord);

      }
      return this.currentBusyRecord;
    }
  }
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    super(utility);


  }

  TESTT() {
    StaticData.ShowActionNotificationWarning("asdasdasd");
  }

  ngAfterViewInit() {
    this.SetRuleEngine();
    this.PostNgAfterViewInit();

    if (StaticData.GeneralConfig.ConfigUI.LoadPageNotificationType != 'None' &&
      this.Pagina.ConfigPage.DisabledShowLoadNotifyType !== true) {
      setTimeout(() => {
        //
        this.baseService.showLoadNotifyType = true;
        console.info("showLoadNotifyType = true; " + this.componentName);
      }, 2000);
    }

    if (this.Pagina.ConfigPage.ControlsToActivate && this.Pagina.ConfigPage.ControlsToActivate.length > 0) {

      if (!this.Pagina.ConfigPage.ActiveControlsInterval)
        this.Pagina.ConfigPage.ActiveControlsInterval = 500;
      setTimeout(() => {

        this.Pagina.ConfigPage.ControlsToActivate.forEach(name => {

        })
        if (this.Pagina.ConfigPage.ActiveCreateControlsInterval) {
          setTimeout(() => {
            this.CreateControls()
          }, this.Pagina.ConfigPage.ActiveCreateControlsInterval);
        }
      }, this.Pagina.ConfigPage.ActiveControlsInterval);
    }

    if (this.Pagina.ConfigPage && this.Pagina.ConfigPage.RequestConfig) {
      setTimeout(() => {
        this.ProcessRequestConfig()
      }, this.Pagina.ConfigPage.RequestConfig.Interval);
    }

    this.NgAfterViewInitEnd();


    this.LogTime();

  }


  public LogTime() {
    if (StaticData.StopWatch) {
      StaticData.StopWatch.stop();
      const time = StaticData.StopWatch.getTime();
      console.info("TIEMPO PAGINA S1: " + time);
      console.info("TIEMPO PAGINA S2: " + this.utility.FormatStopWatch(time));
    }
    var endProcess = new Date().getTime();
    const time = endProcess - StaticData.StartProcess;
    console.info("TIEMPO PAGINA1: " + time);
    console.info("TIEMPO PAGINA2: " + this.utility.FormatStopWatch(time));
  }
  public ProcessRequestConfig() {

    if (this.Pagina.ConfigPage.RequestConfig.PageNavigationProcessType == "Edit" && window.location.hash && window.location.hash.split('?').length > 1) {
      //MappingAllToModel
      let obj = this.utility.GetApiModel('ProcessRequestConfig', null);
      try {

        let queryConfig = this.utility.Clone(this.Pagina.ConfigPage.RequestConfig);

        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = this.componentName;
          if (queryConfig.CrudAction == 'GetById') {
            queryConfig.Entity = {};
          }
        }

        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, queryConfig, null, this, this.parentContext);
          })
        }


        obj.QueryConfig = queryConfig;
        this.baseService.Get(obj, null,
          {
            componentName: this.componentName,
            method: 'ProcessRequestConfig',
            controlName: this.componentName,
            processMessage: queryConfig.processMessage
          }, this.componentName)
          .then(data => {


            if (!this.pkName) {
              const pkControl = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.IsPk; });
              this.pkName = pkControl.Name;
            }
            if (!this.pkName) {
              this.utility.VerModalWarning({ titulo: 'Alerta', descripcion: 'La Configuracion de Llave Primaria, no puede ser nula,en el componente ' + this.Pagina.Titulo }).then(x => { });
            }
            else {
              this.SetModelo(data.Data[0], null, 100, true);
              const action = { ComponentName: this.Pagina.ConfigPage.RequestConfig.ComponentName }
              this.helperActions.OpenSidePanel(action, this.componentsContainer, this, this.modelo, data.Data[0], this.form, this.parentContext);
            }
          }).catch(err => {
            this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ProcessRequestConfig' });
            this.utility.VerModalDanger().then(data => { })
          });
      }


      catch (error) {
        this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ProcessRequestConfig' });
        this.utility.VerModalDanger().then(data => { })
      }

    }
    else if (this.Pagina.ConfigPage.RequestConfig.PageNavigationProcessType == "Edit" && window.location.hash && window.location.hash.split('?').length > 1) {
      //MappingAllToModel
      let obj = this.utility.GetApiModel('ProcessRequestConfig', null);
      try {

        let queryConfig = this.utility.Clone(this.Pagina.ConfigPage.RequestConfig);

        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = this.componentName;
          if (queryConfig.CrudAction == 'GetById') {
            queryConfig.Entity = {};
          }
        }

        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, queryConfig, null, this, this.parentContext);
          })
        }


        obj.QueryConfig = queryConfig;
        this.baseService.Get(obj, null,
          {
            componentName: this.componentName,
            method: 'ProcessRequestConfig',
            controlName: this.componentName,
            processMessage: queryConfig.processMessage
          }, this.componentName)
          .then(data => {


            if (!this.pkName) {
              const pkControl = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.IsPk; });
              this.pkName = pkControl.Name;
            }
            if (!this.pkName) {
              this.utility.VerModalWarning({ titulo: 'Alerta', descripcion: 'La Configuracion de Llave Primaria, no puede ser nula,en el componente ' + this.Pagina.Titulo }).then(x => { });
            }
            else {
              this.SetModelo(data.Data[0], null, 100, true);
              const action = { ComponentName: this.Pagina.ConfigPage.RequestConfig.ComponentName }
              this.helperActions.OpenSidePanel(action, this.componentsContainer, this, this.modelo, data.Data[0], this.form, this.parentContext);
            }
          }).catch(err => {
            this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ProcessRequestConfig' });
            this.utility.VerModalDanger().then(data => { })
          });
      }


      catch (error) {
        this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ProcessRequestConfig' });
        this.utility.VerModalDanger().then(data => { })
      }

    }
  }

  public PostNgAfterViewInit() {


  }

  public NgAfterViewInitEnd() {


  }
  helperRulesContext: any;
  public SetRuleEngine() {
    
    if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.Valida) {
      let timeOut = (this.Pagina.ConfigPage.TimeOutExecuteRules) ? this.Pagina.ConfigPage.TimeOutExecuteRules : 5000;
      setTimeout(() => {

        if (!this.Pagina.ConfigPage.DisabledRules)
          this.helperRulesContext = this.helperRules.SetRuleEngine(this, this.Pagina, this.componentName,
            this.form, true, this.formEvent, [], this.onSelectedComboBoxForRules, this.rules);

        setTimeout(() => {
          this.FireFormEvent({ Name: "AfterViewInit", modelo: this.modelo });
          //Se renderizo toda la página
          this.RaiseFormEvent("AfterViewInit");
        }, 1000);
      }, timeOut);
    }
  }

  ngAfterViewChecked() {

    //console.error("ngAfterViewChecked");

  }
  ngOnDestroy() {

    this.FireFormEvent({ Name: "OnDestroy", modelo: this.modelo });
    this.UnsubscribeValueChange();
    this.helperActions.comunicationService.ClearFormEvents();
    this.utility.Unsubscribe(this.parentClearCall, true);
    this.utility.Unsubscribe(this.parentCallRefresh, true);
    this.utility.Unsubscribe(this.formEvent, true);
    this.DestroyDynamicComponents();
    this.RemoveAllShortcuts();

    if (this.CurrentBusyRecord)
      this.LiberarRegistro(this.CurrentBusyRecord.id);
  }

  public RemoveAllShortcuts() {


    if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.EnableShortcut) {

      //Remover del CommandBar
      if (this.Pagina.ConfigPage.CommandBar) {
        this.Pagina.ConfigPage.CommandBar.Buttons.forEach(_option => {
          if (_option.Shortcut)
            shortcut.remove(_option.Shortcut);
          if (_option.Buttons) {
            _option.Buttons.forEach(_subOption => {

              if (_subOption.Shortcut)
                shortcut.remove(_subOption.Shortcut);
            })
          }
        })
      }

      //Remover de los botones
      this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).forEach(control => {

        if (control.ControlType == 'Button' && control.Shortcut)
          shortcut.remove(control.Shortcut);
        else if (control.ControlType == 'CrudButtons') {
          if (control.ShortcutSave)
            shortcut.remove(control.ShortcutSave);
          if (control.ShortcutClear)
            shortcut.remove(control.ShortcutClear);
        }
      })


    }
  }

  public AddAllShortcuts() {


    if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.EnableShortcut) {

      //add del CommandBar
      if (this.Pagina.ConfigPage.CommandBar) {
        this.Pagina.ConfigPage.CommandBar.Buttons.forEach(_option => {
          if (_option.Shortcut)
            shortcut.add(_option.Shortcut, () => {

              $("#" + _option.Name).trigger('click');
            });
          if (_option.Buttons) {
            _option.Buttons.forEach(_subOption => {

              if (_subOption.Shortcut)
                shortcut.add(_subOption.Shortcut, () => {


                  $("#" + _subOption.Name).trigger('click');
                });
            })
          }
        })
      }

      //add de los botones
      this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).forEach(control => {

        if (control.ControlType == 'Button' && control.Shortcut)
          shortcut.add(control.Shortcut, () => {


            $("#" + control.Name).trigger('click');
          });
        else if (control.ControlType == 'CrudButtons') {
          if (control.ShortcutSave)
            shortcut.add(control.ShortcutSave, () => {

              $("#" + control.NombreBotonGuardar).trigger('click');
            });
          if (control.ShortcutClear)
            shortcut.add(control.ShortcutClear, () => {

              $("#" + control.NombreBotonLimpiar).trigger('click');
            });
        }
      })


    }
  }
  public DestroyDynamicComponents() {

    this.componentsContainer.DestroyAll();

    this.componentsContainer = new ComponentsContainer();
  }
  public UnsubscribeValueChange() {
    if (this.subscr) {
      this.subscr.unsubscribe();
      this.subscr = null;
    }
  }
  public Redireccion(data?: any) {

  }



  public SetProcesando(ok: boolean) {

    this.botonProcesando = ok;
    if (ok) {
      this.textoBoton = '';
      this.textoBotonProcesando = 'Ejecutando.......';
    }
    else {
      this.textoBoton = !this.IsEdit ? 'Guardar' : 'Actualizar';
      this.textoBotonProcesando = '';
    }
  }

  public ValidarPermisosUsuario(item: any, permisoField: string): boolean {
    let ok = true;
    if (this.permissionsConfig) {
      this.permissionsConfig.Pagina = this.Pagina;
      ok = this.utility.TienePemisoUsuario(this.permissionsConfig, item, permisoField);
    }
    if (ok == false) {
      this.SetProcesando(false);
      this.utility.VerModalAlertaPermisos().then(data => { }).catch(err => console.log(err));
    }
    return ok;
  }
  public ValidarCrearNuevaVersion(): boolean {

    if (this.HasPropVersionModelData && this.IsEdit) {
      if (!this.modeloClone) {
        this.utility.VerModalDanger({ titulo: 'Error', descripcion: 'Error al crea una nuava version ' })
        throw 'La copia del modelo no puede ser  nula';
      }
      let resultModelo = [];
      let resultMetadata = [];
      resultModelo = this.utility.ValidateModels(this.modelo.modelo, this.modeloClone.modelo,
        this.modelo.modelo.VersionModelData.Propiedades, 'modelo', this.controls);
      if (this.modelo.modeloMetadata)
        resultMetadata = this.utility.ValidateModels(this.modelo.modeloMetadata,
          this.modeloClone.modeloMetadata, this.modelo.modelo.VersionModelData.PropiedadesMetadata, 'modeloMetadata', this.controls);

      this.modelo.modelo.VersionModelData.PropiedadesModificadas = resultModelo;
      this.modelo.modelo.VersionModelData.PropiedadesMetadataModificadas = resultMetadata;
      return (resultModelo.length > 0 || resultMetadata.length > 0)
    }
    return this.IsVersion;
  }

  ngOnInit() {

    this.internalOnInit();

  }
  internalOnInit() {

    try {

      //this.location.prepareExternalUrl(this.location.path());
      this.utility.logger.LogInfoText(this.GetTextMessage('ngOnInit'));

      if (this.subControl) {

        this.pageId = this.subControl.Name;
        this.isModelContainer = this.subControl.IsModelContainer;
        this.notLoad = this.subControl.NotLoad;
        if (this.subControl.HiddenCommandBar)
          this.viewCommandBar = false;

        this.commandBarIsComponent = true;
        if (this.subControl.PkName)
          this.pkName = this.subControl.PkName;
      }
      if (!this.pageId) {
        const _pageId = this.utility.router.url.split("?");
        this.pageId = (_pageId.length > 1) ? _pageId[0].replace('/', '') : this.utility.router.url.replace('/', '');
      }

      if (!this.Pagina.Valida)
        this.Pagina = this.utility.ValidateUserAndPage(this.pageId, true, this.componentName, 'ngOnInit');

      if (!this.Pagina || (this.Pagina && this.Pagina.Valida !== true)) {
        this.form = new FormGroup({});
        return;

      }
      if (this.Pagina.ConfigPage.DisabledShowLoadNotifyType == true) {
        this.baseService.showLoadNotifyType = false;
        console.info("showLoadNotifyType = false; " + this.componentName);
      }





      if (!this.name)
        this.name = this.pageId;
      if (this.Pagina.ConfigPage.MaxContainer) {

        this.Pagina.Estilos.ClassContenedorGeneral = '';
        this.Pagina.Estilos.EstiloContenedorGeneral = '';
      }
      this.componentName = this.Pagina.Controlador.replace('Controller', '')
      this.controlsForHiddenList = new List(this.controlsForHidden);
      //componentName
      //this.controls = new List(this.Pagina.ConfigPage.Controls);
      //this.controls = this.controls.Where(x=> this.utility.EsControlFormulario(x));

      this.workFlowItem = this.utility.Clone(this.utility.WorkFlowItem);
      this.utility.WorkFlowItem = null;

      if (!this.workFlowItem) {
        this.workFlowItem = { IdFlujo: 0, IdDetalleFlujo: 0, IdAccion: null };
      }
      this.SetProcesando(false);

      if (this.onInitialized.observers.length) {

        setTimeout(() => {

          this.onInitialized.emit(this);
        }, 1000);
      }
      this.ProcessSubControlConfig();
      this.CreateControls();
      /* if (this.Pagina.Valida == true && this.Pagina.ConfigPage) {
        this.utility.SetControlsConfig(this.Pagina, this.configs);

      } */
      this.ExcecuteDataSource();
      setTimeout(() => {
        //this.CreateControls();
      }, 6000);
      //this.Limpiar();
      this.OnInit();

      this.comboBoxManager = new ComboBoxManager(this.utility, this.baseService, this.configs,
        this.componentName, this.modelo, null, null, null, true, this.componentsContainer);


      // para controles qeu se cargan como modales o contextos externos al renderizador de dynamic controls
      this.onExtraInitialized.subscribe(event => {
        this.AddComponentInstanceHandler(event);
      });

      //Acciones de helper-actions
      this.actionEmitter.subscribe(event => {
        this.OnActionEventHandler(event);
      });

      // Adicionar eventos de Shorcut
      this.AddAllShortcuts();


      // Validar Registro Ocupado
      if (this.CurrentBusyRecord)
        this.LiberarRegistro(this.CurrentBusyRecord.id);
    }
    catch (error) {
      if (!this.form)
        this.form = new FormGroup({});
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'ngOnInit' });
    }

  }

  public ProcessSubControlConfig() {
    if (this.subControl && this.subControl.ContainerControl) {

      let ContainerControl = this.Pagina.ConfigPage.Controls.find(x => { return x.Id == this.subControl.ContainerControl.Id });
      if (this.subControl.OnlyControls)
        this.Pagina.ConfigPage.Controls = ContainerControl.Controls;
      else {
        this.Pagina.ConfigPage.Controls = [];
        this.Pagina.ConfigPage.Controls.push(ContainerControl);
      }
      let controls: Array<any>;
      if (this.subControl.ControlsToHidden && this.subControl.ControlsToHidden.length > 0) {
        controls = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls);
        this.subControl.ControlsToHidden.forEach(ctr => {

          let currentCtr = controls.find(x => { return (x.Name == ctr || x.Id == ctr) });
          currentCtr.Hidden = true;
        })
      }
      if (this.subControl.ControlsToInActive && this.subControl.ControlsToInActive.length > 0) {
        if (!controls)
          controls = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls);
        this.subControl.ControlsToInActive.forEach(ctr => {

          let currentCtr = controls.find(x => { return (x.Name == ctr || x.Id == ctr) });
          currentCtr.Activo = false;
        })
      }

    }
  }
  public ExcecuteDataSource(filterParent?: any) {
    let obj = this.utility.GetApiModel('ExcecuteDataSource', null);

    try {

      if (this.subControl && this.subControl.MappingParameters && this.subControl.MappingParameters.length > 0) {

        this.subControl.MappingParameters.forEach(parameter => {

          let value = this.utility.GetParmeterValue(parameter, this.modelo, this.form, this.QueryConfig, this.modeloPadre, this, this.parentContext);

          this.utility.SetValueParmeterValue(parameter, value, this.modelo, this.form, this.QueryConfig, this.modeloPadre, this, this.parentContext);

        });

        setTimeout(() => {
          if (this.subControl.FireSetModelo) {
            this.SetModelo(this.modelo, null, 1000);
          }
          else if (this.subControl.FireOnDataTableEdit) {
            this.OnDataTableEditHandler(null);
          }
          else
            this.SetModelToControls();
        }, 90);

      }

      if (!this.subControl || this.subControl.DataSources.length == 0 || this.notLoad) {

        return;
      }
      // if (filterParent)
      //   this.filterParent = filterParent;
      obj.QueryConfigs = [];

      let index = 0;
      this.subControl.DataSources.forEach(ds => {


        let queryConfig = this.utility.Clone(ds);
        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Entity.IdUsuario)
          queryConfig.Entity["IdUsuario"] = StaticData.Usuario.IdUsuario;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = index;
          if (queryConfig.CrudAction == 'GetAll' || queryConfig.CrudAction == 'GetAllOrderBy'
            || queryConfig.CrudAction == 'GetAllOrderByDesc') {
            queryConfig.Entity = {};
          }
        }





        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, queryConfig, this.modeloPadre, this, this.parentContext);
          })
        }

        obj.QueryConfigs.push(queryConfig);
        //this.dataSet.push({ index: index, data: null });
        index++;
      });

      this.baseService.MultiGet(obj, null,
        {
          componentName: this.componentName,
          method: 'ExcecuteDataSource',
          controlName: this.subControl.Name,
          processMessage: this.subControl.ProcessMessage
        }, (this.subControl.Controller) ? this.subControl.Controller : this.componentName)
        .then(data => {
          index = 0;

          data.Data.forEach(dtt => {


            if (Array.isArray(dtt)) {

              this.SetModelo(dtt[0], null, 3000);
            }
            else {

              this.SetModelo(dtt, null, 3000);
            }

            index++;

          });

        }).catch(err => {
          this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
          this.utility.VerModalDanger().then(data => { })
        });

    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
      this.utility.VerModalDanger().then(data => { })
    }
  }
  AddChildComponent(component: any) {


    let _component = new List(this.childComponents).FirstOrDefault(x => { return x.componentName == component.componentName });
    if (!_component)
      this.childComponents.push(component);

  }
  public GetTextMessage(method: string): string {
    return `componentName: ${this.componentName} method: ${method}`;
  }
  public OnInitParentOption() {

    /*  if (this.parentOption) {
         if (this.parentOption.CargarGrilla == false)
             this.cargarGrilla = false;
         if (this.parentOption.HideDataTable == true)
             this.hideDataTable = true;
         if (this.parentOption.HideForm == true)
             this.hideForm = true;
     } */

  }



  public CreateControls() {


    const formGroup: any = {};
    const that = this;
    if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.Controls && this.Pagina.ConfigPage.Controls.length > 0) {

      this.controls = new List([])
      this.SetFormControl(formGroup, this.Pagina.ConfigPage.Controls);
      this.form = new FormGroup(formGroup);
      this.CreateModel();
      this.SetValueChangesControlsToModel();

    }

  }

  public DeleteDbMetadatata(queryConfig: any) {
    let _url = (this.url) ? this.url : this.componentName;
    var obj = this.utility.GetApiModel('DeleteDbMetadatata', _url);

    obj.QueryConfig = queryConfig;

    this.baseService.Get(obj, null,
      {
        url: _url,
        componentName: this.componentName,
        method: 'DeleteDbMetadatata',
        processMessage: 'Eliminando Metadatos...'
      }, _url)
      .then(data => {




      }).catch(err => {
        this.utility.logger.LogError(err, obj, {
          componentName: this.componentName,
          method: 'DeleteDbMetadatata',
          processMessage: 'Eliminando Metadatos...'
        }, this.url)
      });
  }

  public RemoveMetadataControls(queryConfig: any) {
    //

    if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.Metadata &&
      this.Pagina.ConfigPage.Metadata.length > 0) {
      //let metadatForms = this.form.controls["Metadatos"];

      this.RemoveControls(this.Pagina.ConfigPage.Metadata)
      this.modelo.modeloMetadata.Items = new List<any>();

      //this.form.removeControl["Metadatos"];
      //this.CreateModel();
      // this.SetValueChangesControlsToModel();

      //OJO VALIDAR SI SE ELIMINAN  O NO LOS METADATOS
      // if (queryConfig)
      //this.DeleteDbMetadatata(queryConfig);
    }
  }

  public CreateMetadataControls(_metadata: any, prop: string = 'Metadatos') {

    if (!_metadata)
      return;
    let metadata: any = _metadata;
    const formGroup: any = {};
    if (typeof _metadata === 'string') {
      metadata = JSON.parse(_metadata);
    }
    else
      metadata = _metadata;
    if (!Array.isArray(metadata)) {
      metadata = metadata[prop];
    }
    this.Pagina.ConfigPage.Metadata = metadata;
    //this.modelo.modeloMetadata.IdParentObject = idParent;
    const that = this;
    if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.Metadata && this.Pagina.ConfigPage.Metadata.length > 0) {

      //this.controls = new List([])
      // this.SetFormControl(formGroup, this.Pagina.ConfigPage.Metadata);

      // let metadatForms = new FormGroup(formGroup);
      // this.form.addControl("Metadatos", metadatForms);
      //
      this.AddControls(this.Pagina.ConfigPage.Metadata);
      // this.CreateModel();
      this.SetValueChangesControlsToModel();

    }

  }
  public SetControlsValueDocument(NombreControl: any, propiedad: any, valorPropiedad) {
    let nom: any;
    Array.isArray(NombreControl) ? nom = NombreControl : nom = [NombreControl];
    const controlsSet = {
      ControlDetails: {
        MappingData: [],
        MappingFilter: []
      },
      ControlType: "Action",
      ControlsForHidden: [],
      ControlsToUpdate: nom,
      Guid: Guid.create().toString(),
      IsPost: false,
      IsParentModel: false,
      MappingValues: [],
      MappingComponents: [],
      Property: propiedad,
      PropertyValue: valorPropiedad,
      ResultType: "Url",
      SendQueryString: false,
      TimerAction: 0,
      Type: ControlActions[ControlActions.SetControlProperty]
    };
    const context = this;
    this.helperActions.SetControlsValue(controlsSet, context);
  }
  public SetModelToControls() {
    let controlsError = null;
    let metaControlsError = null;
    if (this.form && this.form.controls) {
      this.UnsubscribeValueChange();
      // if(this.is)
      // this.form.patchValue()

      this.controls.ForEach((control: any, index) => {
        if (control.control.Activo == true) {
          const ctr = this.form.controls[control.controlName];
          if (control.control.ModelContainer == 'modeloMetadata') {
            const item = this.modelo.modeloMetadata.Items.FirstOrDefault(x => { return x.Name == control.controlName });

            if (ctr)
              ctr.setValue(item ? item.Value : null);
            else
              metaControlsError += control.controlName + ",";

          }
          else {
            if (ctr)
              ctr.setValue(this.modelo[control.control.ModelContainer][control.controlName]);
            else
              controlsError += control.controlName + ",";

          }
        }
      });
      /* $.each(this.form.controls, (name, control: any) => {
        const ctr = this.controls.First(x => { return x.Name == name });
        control.setValue(this.modelo[ctr.ModelContainer][name])
      }); */
      if (controlsError && metaControlsError) {
        this.utility.logger.LogErrorText("Asignacion de Valor,No encontrado controles:" + controlsError + ", controles metadatos:" + metaControlsError + "");
        StaticData.ShowActionNotificationWarning("No se encontraron Controles y Metadatos para asignar")
      }
      else if (metaControlsError) {
        this.utility.logger.LogErrorText("Asignacion de Valor,No encontrados controles metadatos:" + metaControlsError + "");
        StaticData.ShowActionNotificationWarning("No se encontraron Metadatos para asignar")
      }
      else if (controlsError) {
        this.utility.logger.LogErrorText("Asignacion de Valor,No encontrados controles :" + controlsError + "");
        StaticData.ShowActionNotificationWarning("No se encontraron Controles para asignar")
      }
      this.SetValueChangesControlsToModel();
    }
  }
  public SetValueChangesControlsToModel() {
    let controlsError = null;

    if (!this.subscr)
      this.subscr = this.form.valueChanges.subscribe(values => {

        $.each(values, (name: string, value: any) => {

          const ctr = this.controls.FirstOrDefault(x => { return x.controlName == name });
          if (ctr) {
            if (ctr.control.ModelContainer == 'modeloMetadata') {
              //
              ctr.control.Value = value;
              this.SetControlMetadata(ctr.control, name, true);
              // const item = this.modelo.modeloMetadata.Items.First(x => { return x.Name == name });
              // item.Label = ctr.Label;
              // item.DataType = ctr.DataType;
              // item.Value = value;
            }
            else
              this.modelo[ctr.control.ModelContainer][name] = value;
          }
          else {
            controlsError += name + ",";
          }
        });
      })

    if (controlsError) {
      this.utility.logger.LogErrorText("Asignacion de Valor,No encontrados controles:" + controlsError + "");
      StaticData.ShowActionNotificationWarning("No se encontraron Controles para asignar Modelo")
    }
  }
  public ReCargarGrilla() {

    // console.error('ReCargarGrilla ' + this.componentName);
    this.parentCallRefresh.next();
  }
  public OnInitEnd() {

  }
  public OnInit() {

    try {

      this.OnInitParentOption();




      this.mappingFieldsEvent.subscribe(model => {


        this.modelo = model;
        this.SetModelToControls();
      });

      this.onSpecificStart();

      if (this.pageId)
        this.utility.IsUserAuthenticated(this.pageId, true, this.componentName, 'onInit', null);


      if (this.Pagina.Valida) {
        //this.CargarComboBox();
        if (this.modeloEdit) {
          this.cargarGrilla = false;
          this.hideDataTable = true;
          this.SetModelo(this.modeloEdit, null, 3000);

        }
        this.OnSetConfig();
        this.OnInitEnd();


      }

    }
    catch (error) {
      if (!this.form)
        this.form = new FormGroup({});
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }


  }
  public SetFocusModelo() {
    $.each(this.modelo.modelo, function (key: string, item) {
      setTimeout(() => {
        $('#' + key).focus();
      }, 20);
    });
  }
  public EndInternalSetModelo() {
  }
  public InternalSetModelo(_modelo: any, permissionsConfig?: any, fromRequestConfig?: any) {

    //
    if (permissionsConfig)
      this.permissionsConfig = this.permissionsConfig;

    if (fromRequestConfig) {

      if (_modelo.modelo)
        this.modelo.modelo = this.utility.SetFieldsValue(this.modelo.modelo, _modelo.modelo, this.Pagina.ConfigPage.RequestConfig.MappingAllToModel);
      else
        this.modelo.modelo = this.utility.SetFieldsValue(this.modelo.modelo, _modelo, this.Pagina.ConfigPage.RequestConfig.MappingAllToModel);
    }
    else {

      if (_modelo.modelo)
        this.modelo.modelo = (this.modelo.modelo) ? this.utility.SetFieldsValue(this.modelo.modelo, _modelo.modelo) : _modelo.modelo;
      else
        this.modelo.modelo = (this.modelo.modelo) ? this.utility.SetFieldsValue(this.modelo.modelo, _modelo) : _modelo;
    }

    if (this.modelo.modelo.PkValue)
      this.modelo.modelo.PkValue = this.modelo.modelo.PkValue;
    else {
      if (this.pkName)
        this.modelo.modelo.PkValue = this.modelo.modelo[this.pkName];
      else {
        this.utility.logger.LogErrorText('El valor pkName es nulo y no se puede asignar al modelo,' + this.pageId);
        throw 'El valor pkName es nulo y no se puede asignar al modelo,' + this.pageId;
      }
    }
    if (_modelo.modeloMetadata) {
      // this.modelo.modeloMetadata = (this.modelo.modeloMetadata) ? this.utility.SetFieldsValue(this.modelo.modeloMetadata, _modelo.modeloMetadata) : _modelo.modeloMetadata;
      // this.modelo.modeloMetadata.PkValue = this.modelo.modeloMetadata[this.pkName];
    }


    this.OnDataTableEditHandler(null);
    this.EndInternalSetModelo();

    // this.SetModelToControls();
    // this.SetProcesando(false);
    // //this.SetFocusModelo();

    // this.EditCascading();
    // this.ExcecuteFuntion(ControllerMethods.Edit, true, { index: 0, id: this.modelo.modelo.PkValue });

    // this.EndInternalSetModelo();
    // setTimeout(() => {
    //   this.FireFormEvent({ Name: "OnPostSetEdit", modelo: this.modelo });
    // }, 200);
  }

  public SetModelo(_modelo: any, permissionsConfig?: any, timeout?: number, fromRequestConfig?: any) {

    if (timeout) {
      setTimeout(() => {

        this.InternalSetModelo(_modelo, permissionsConfig, fromRequestConfig);
      }, timeout);
    }
    else
      this.InternalSetModelo(_modelo, permissionsConfig, fromRequestConfig);
  }

  public onSpecificStart() {

  }


  VerObservacionesModificacionVersion(form: FormGroup = null): any {
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Observaciones";
    config.returnInstance = false;
    config.ok = "Aceptar";
    config.modal = true;
    config.width = 600;
    config.height = 300;
    let windowModel: any = {
      modelo: this.modelo.modelo.VersionModelData,
      config: this.utility.GetConfigObservacionesVersion()
    };
    if (!form)
      return this.utility.OpenWindow(ObservacionesModificacionVersionComponent, config, windowModel);
    else {
      this.utility.OpenWindow(ObservacionesModificacionVersionComponent,
        config, windowModel).then(dataResult => {
          // console.log(dataResult);

          dataResult.onClosePromise.then(dataResultOnClose => {
            // console.log(dataResultOnClose);

            let limpiar = true;
            if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
              dataResultOnClose.modelo.ObservacionesModificacion) {
              limpiar = false;
              this.modelo.modelo.VersionModelData.ObservacionesModificacion = dataResultOnClose.modelo.ObservacionesModificacion;
              this.GuardarFormInterno(form);
              this.modelo.modelo.VersionModelData.ObservacionesModificacion = '';
            }
            else {
              this.modelo.modelo.VersionModelData.ObservacionesModificacion = '';

              this.LimpiarConfirm();
            }
          });

        })
    }
  }
  public ImportarForm() {

    console.log('Importar', this.Pagina);
  }
  public ModalFilter() {

  }
  public Save(form: FormGroup) {

    this.GuardarForm(form);
  }

  public RaiseFormEvent(eventName, setPermissionsConfig?: boolean) {

    this.helperActions.comunicationService.raiseFormEvent({
      Name: this.name,
      Action: eventName, modelo: this.modelo,
      permissionsConfig: setPermissionsConfig ? this.permissionsConfig : null
    });

  }
  public Limpiar(callback: (data: any) => void = null, action?: any) {
    //this.CreateModel(false);


    if (this.Pagina.ConfigPage.SearchTagRecordManagement && this.IsEdit) {

      this.LiberarRegistro(this.modelo.modelo.PkValue);
    }

    //this.ClearModel();
    //if(this.Pagina.IdPagina != 37){ //ojo
    this.ClearModel();
    // }
    this.utility.SetFieldsToModel(this.modelo.modelo, null);//this.filterParent);
    if (callback) callback(this.modelo);
    this.SetModelToControls();
    this.parentClearCall.next({ modelo: this.modelo, action: action });
    this.SetProcesando(false);
    this.RaiseFormEvent('Clear');

  }

  _LimpiarConfirm() {
    this.ExcecuteFuntion(ControllerMethods.Clear, false);
    this.modelo.modeloCascada = {};
    this.Limpiar();

    this.ExcecuteFuntion(ControllerMethods.Clear, true);
  }

  public LimpiarConfirm() {
    try {
      if (this.Pagina.VerComfirmarLimpiar != true) {

        this._LimpiarConfirm();
      }
      else {
        const that = this;
        this.utility.VerModalConfirmacionLimpiar().then(modelo => {
          if (modelo.dialogResult == true) {
            this._LimpiarConfirm();
          }
        });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'LimpiarConfirm' });
      this.utility.VerModalDanger().then(data => { })
    }
  }

  public GetListModelos(): Array<any> {

    return [];
  }
  public FinGuardar(data: any) {    
    if(!this.Pagina.ConfigPage.DisabledMsgOk && !this.IsEdit)
    {         
          if(this.Pagina.ConfigPage.MsgOk)
          {
            let msgOk = (this.Pagina.ConfigPage.MsgOk.includes("#")) ? this.utility.ReplaceTextFromModels(data, this.Pagina.ConfigPage.MsgOk) : this.Pagina.ConfigPage.MsgOk;
            this.utility.VerModalOk(msgOk).then(value => { }); 
          }
          else
            this.utility.VerModalOk().then(value => { });             
    } 
    if(!this.Pagina.ConfigPage.DisabledMsgOkEdit && this.IsEdit)
    {
      if (this.Pagina.ConfigPage.MsgOkEdit) {
        let msgOk = (this.Pagina.ConfigPage.MsgOkEdit.includes("#")) ? this.utility.ReplaceTextFromModels(data, this.Pagina.ConfigPage.MsgOkEdit) : this.Pagina.ConfigPage.MsgOkEdit;
        this.utility.VerModalOk(msgOk).then(value => { });
      }
      else
        this.utility.VerModalOk().then(value => { });
    }     

    // this.utility.VerModalOk().then(value => { });

    this.ExcecuteFuntion(ControllerMethods.Save, true, data);
    this.ExcecuteFuntion(ControllerMethods.Clear, true);
    this.SetProcesando(false);
    if (this.cargarGrilla)
      this.ReCargarGrilla();


    this.Limpiar();
  }

  public LiberarRegistro(id: any) {

    let obj = this.utility.GetApiModel('ProcesoDeRegistroEnGestion', null)
    obj.QueryConfig.Command = 'ProcesoDeRegistroEnGestion';
    obj.QueryConfig.IsSp = true;

    if (id == undefined) { id = null; }

    obj.QueryConfig.NotSetDefault = true;
    obj.QueryConfig.Entity = {
      IdTipoProceso: 2,
      IdEmpresa: -1,
      IdUsuario: -1,
      Maquina: -1,
      IdRegistro: id,
      TagBusqueda: this.Pagina.ConfigPage.SearchTagRecordManagement
    };

    if (!this.Pagina.ConfigPage.MsgReleaseRecordManagement)
      this.Pagina.ConfigPage.MsgReleaseRecordManagement = 'Liberando Registro......';

    let info = {
      componentName: this.componentName, controlName: 'LiberarRegistro',
      processMessage: this.Pagina.ConfigPage.MsgReleaseRecordManagement
    };

    if (this.componentName != "General") {
      this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
        this.CurrentBusyRecord = null;

      }).catch(err => { });
    }
  }
  public MultiGuardar(form: FormGroup) {
    let obj: any = null;
    try {
      if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
        return;

      // const that = this;
      this.ExcecuteFuntion(ControllerMethods.Save, false);
      obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.GetListModelos());
      this.baseService.MultiInsertUpdate(obj, form, {
        componentName: this.componentName + 'Controller',
        method: 'MultiGuardar', processMessage: 'Proceso de Multi Guardado de ' + this.Pagina.Titulo
      },
        false, this.componentName, false, true).then(data => {
          this.FinGuardar({ data: data.Data, isEdit: this.IsEdit });

        }).catch(error => { this.SetProcesando(false); });
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'MultiGuardar' });
      if (this.Pagina.ConfigPage.MsgError && this.modelo) {
        let msgError = this.utility.ReplaceTextFromModels(this.modelo, this.Pagina.ConfigPage.MsgError);
        this.utility.VerModalDanger({ titulo: 'Error', descripcion: msgError }).then(data => { });
      }
      else
        this.utility.VerModalDanger().then(data => { });
    }
  }
  public RespuestaCargaDocumento(event) {

    if (event.response) {
      let obj: any = JSON.parse(event.response);
      if (obj.Ok) {
        this.FinGuardar(obj.Data);

        if (this.helperActions.isTypeImport) {

          var currentcomponent = this.helperActions.componentsContainerImport;
          if (currentcomponent && currentcomponent.config && currentcomponent.config.ControlType == 'GridView') {
            currentcomponent.IsReloadData = true;
            currentcomponent.cargarGrilla = true;
            currentcomponent.ReCargarGrilla(true);
          }

        }

      }
      else {
        this.componentsContainer.FileBox.resetFileUpload();
      }
    }
  }
  public GetSaveModelContainer(): any {
    let modelo: any = {
      Token: null,
      Form: {
        GUID: this.Pagina.IdPagina,
        Name: this.Pagina.Pagina,
        ObjectType: "Form",
      },

      ModelContainer: this.modelo,

      NewAction: this.modelo.NewAction,
      NewActionByConfig: this.modelo.NewActionByConfig
    };

    if (this.workFlowItem.IdDetalleFlujo)
      modelo.IdDetalleFlujo = this.workFlowItem.IdDetalleFlujo;
    if (this.workFlowItem.IdFlujo)
      modelo.IdFlujo = this.workFlowItem.IdFlujo;
    if (this.workFlowItem.IdAccion)
      modelo.IdAccion = this.workFlowItem.IdAccion;
    if (this.workFlowItem.IdAccionPost)
      modelo.IdAccionPost = this.workFlowItem.IdAccionPost;
    return modelo;
  }

  public GetForm() {
    return {
      GUID: this.Pagina.IdPagina,
      Name: this.Pagina.Pagina,
      ObjectType: "Form",
    };
  }
  public GetSaveModel(): any {
    let modelo = {
      Token: null,
      Form: this.GetForm(),
      Model: this.modelo.modelo,
      WorkFlowTaskModel: this.modelo.WorkFlowTaskModel,
      IdDetalleFlujo: this.workFlowItem.IdDetalleFlujo,
      IdFlujo: this.workFlowItem.IdFlujo,
      IdAccion: this.workFlowItem.IdAccion,
      IdAccionPost: this.workFlowItem.IdAccionPost,
      NewAction: this.modelo.NewAction,
      NewActionByConfig: this.modelo.NewActionByConfig
    };
    return modelo;
  }

  CifrarModelo() {
    if (this.Pagina.ConfigPage.UIEncryptDencryptDataConfig &&
      this.Pagina.ConfigPage.UIEncryptDencryptDataConfig.UIEncryptDencryptDataConfig) {
      const config = this.Pagina.ConfigPage.UIEncryptDencryptDataConfig.UIEncryptDencryptDataConfig;
      if (this.isModelContainer) {
        Object.keys(config.Fields).forEach(key => {
          switch (config) {
            case 'TripleDES':
              break;

            case 'TripleDES':
              break;
          }

        });
      }
    }
  }
  _Guardar(form: FormGroup, configButtons?: any) {
    
    let obj: any = null;
    try {


      if (this.modelo.modeloVirtual.ActivoVirtual != undefined) {
        this.modelo.modelo.Activo = this.modelo.modeloVirtual.ActivoVirtual;
      }

      if (StaticData.GeneralConfig.ConfigUI.EsPruebaRegistros == true){
        this.modelo.modelo.EsPrueba = true;
      }

      if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
        return;
      if (form.invalid)
        return;


      let _isEdit = this.IsEdit;
      // const that = this;
      let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;

      if (configButtons && configButtons.Actions) {

        this.helperActions.ExecuteActionsControl(configButtons,
          false, null, this.modelo, this.permissionsConfig,
          this, null, this.actionEmitter, this.componentsContainer,
          null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
      }

      this.ExcecuteFuntion(ControllerMethods.Save, false);


      if (_isEdit)
        this.FireFormEvent({ Name: "OnPreUpdate", modelo: this.modelo });
      else
        this.FireFormEvent({ Name: "OnPreInsert", modelo: this.modelo });

      if (this.modelo.modeloMetadata.Items.Count() > 0) {
        this.modelo.modeloMetadata.IdParentObject = this.modelo.modelo.PkValue;
        this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
        this.modelo.modelo.Metadata = this.utility.Clone(this.modelo.modeloMetadata);
        this.modelo.modelo.Metadata.Items = this.modelo.modelo.Metadata.Items.ToArray();
      }
      //this.modelo.modeloMetadata.Id = this.modelo.modelo.PkValue;
      // this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
      // this.modelo.modelo.Metadata = this.modelo.modeloMetadata;


      if (this.Pagina.IsCustom) {
        let modelo: any;

        if (this.Pagina.ConfigPage.SavaModelContainer || this.Pagina.ConfigPage.SaveType == 'SaveModelContainer') {
          modelo = this.GetSaveModelContainer();

          obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, modelo);
        }
        else if (this.Pagina.ConfigPage.SaveType == 'SaveSelectedItems') {
          modelo = this.GetSaveModel();
          modelo.Model = { ModelList: this.componentsContainer.Tables[0].GetSelectedItems() };

          obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, modelo);
        }
        else if (this.Pagina.ConfigPage.SaveType == 'SaveModelContainerSelectedItems') {
          modelo = this.GetSaveModelContainer();
          modelo.ModelContainer.modelo["ModelList"] = this.componentsContainer.Tables[0].GetSelectedItems();

          obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, modelo);
        }
        else {
          modelo = this.GetSaveModel();
          obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, modelo);
        }
      }
      else {
        //Usado en TemaSolicitud para transformar los elementos seleccionados en InfoPermission.
        if (this.modelo.modelo.Configuracion && Array.isArray(this.modelo.modelo.Configuracion)) {
          let model = this.utility.Clone(this.modelo.modelo);
          model.Configuracion = JSON.stringify(this.modelo.modelo.Configuracion);
          obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, model);
        }
        else
          obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
      }

      let isGeneralSave = true;

      if ((this.Pagina.IsCustom || this.Pagina.ConfigPage.SaveByFileBox) && (this.componentsContainer.FileBox || this.componentsContainer.FileBoxWorkFlow)) {

        if (this.componentsContainer.FileBox == null && this.componentsContainer.FileBoxWorkFlow.isSelectedFiles()) {
          isGeneralSave = false;
          this.componentsContainer.FileBoxWorkFlow.uploadFiles(false, obj);
        }
        else if (this.componentsContainer.FileBox.isSelectedFiles()) {
          isGeneralSave = false;
          let externalFiles: any;
          if (this.componentsContainer.FileBoxWorkFlow != null) {
            externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
          }
          this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
        }

      }


      // ////////Meter validacion antes de este
      if (isGeneralSave) {
        this.baseService.InsertUpdate(obj, form, {
          componentName: this.componentName + 'Controller',
          method: 'Guardar', processMessage: processMessage
        },
          false, this.componentName, false, true).then(data => {
            //this.modelo.modelo = data.Data != null ? data.Data : this.modelo.modelo;

            this.FinGuardar(data.Data);

            if (_isEdit)
              this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
            else
              this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });


          }).catch(error => { this.SetProcesando(false); });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: '_Guardar' });
      this.utility.VerModalDanger().then(data => { })
      this.SetProcesando(false);
    }
  }

  public GuardarFormInterno(form: FormGroup, configButtons?: any) {

    this.SetProcesando(true);
    if (this.isUnion == true)
      this.MultiGuardar(form);
    else {
      if (this.IsEdit) {
        let ok = this.ValidarPermisosUsuario(this.modelo.modelo, 'Guardar');
        if (ok)
          this._Guardar(form, configButtons);
      }
      else
        this._Guardar(form, configButtons);
    }
  }

  public GuardarForm(form: FormGroup, configButtons?: any, excludeValidation: boolean = false) {


    try {
      const isVersion = this.ValidarCrearNuevaVersion();
      if (this.Pagina.VerComfirmarGuardar != true) {
        if (isVersion) {
          this.VerObservacionesModificacionVersion(form);
          return;
        }
        this.GuardarFormInterno(form, configButtons);
      }
      else {
        const that = this;
        this.utility.VerModalConfirmacionGuardar(isVersion).then(modelo => {
          if (modelo.dialogResult == true) {
            if (isVersion) {
              this.VerObservacionesModificacionVersion(form);
              return;
            }
            this.GuardarFormInterno(form, configButtons);
          }
        });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'Guardar' });
      this.utility.VerModalDanger().then(data => { });
    }
  }
  public OnSelectedComboBoxEmitterHandler(event) {

    this.OnSelectedComboBox(event.item, event.controlName, event.config);
  }
  public OnSelectedComboBox(item: any, controlName: string, config: any, isParentSelection?: boolean) {
    try {
      this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, { item: item, controlName: controlName, config: config });
      // this.ExecuteCascading(item, controlName, config);
      if (config.IdMetadataPage) {
        this.SetFormControlsFromMetadataPage(config.IdMetadataPage);
      }
      this.comboBoxManager.ExecuteCascading(item, controlName, config, null, null, null || isParentSelection);
      this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, { item: item, controlName: controlName, config: config });
      this.SetModelToControls();

      this.onSelectedComboBoxForRules.emit({ item: item, controlName: controlName, config: config });

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }

  public EditCascading() {
    // const _configs = new List<any>(this.comboBoxManager.configs);
    // let parents = new Array();
    // let parent = new Array();
    // _configs.ForEach(e => { if (e.value.ConfigParent) parents.push("Config" + e.value.ConfigParent.ParentName); });
    // _configs.ForEach(e => { if (parents.filter(a => a === e.key).length > 0 && !e.value.ConfigParent) parent.push(e) });
    // if (parent) {
    //   parent.forEach(uniqueParent => {
    //     if (uniqueParent.value.Context.items) {
    //       let controlName = (uniqueParent.value.PropertyInclude) ? uniqueParent.value.PropertyInclude :
    //       uniqueParent.key.replace("Config", "");
    //       const item = uniqueParent.value.Context.FindItem(this.modelo[controlName] || this.modelo.modeloCascada[controlName])
    //       if (item) {
    //         this.OnSelectedComboBox(item, uniqueParent.value.Context.pkField, uniqueParent.value, true);
    //       }

    //     }

    //   })
    // }
    this.comboBoxManager.EditCascading(this.modelo, null);

  }
  public Ayuda(config: any = null) {

  }

  public CloneEventHandler(event) {


    this.modeloClone = event.modeloClone;
  }
  public AddComponentInstanceHandler(event) {


    let controlToHide: any = null;

    if (event instanceof DataTableComponent) {
      event.MsgValidationRecordManagement = this.Pagina.ConfigPage.MsgValidationRecordManagement;
      event.SearchTagRecordManagement = this.Pagina.ConfigPage.SearchTagRecordManagement;
      event.MsgReleaseRecordManagement = this.Pagina.ConfigPage.MsgReleaseRecordManagement;
      event.MsgBusyRecordManagement = this.Pagina.ConfigPage.MsgBusyRecordManagement;
      this.componentsContainer.Tables.push(event);
      /// OJO O LA OPCION ES EN INTERNAL INIT ELIMINAR EL CONTROL GRILLA DE LA COLECCION DE CONTROLES
      // controlToHide = this.controlsForHiddenList.FirstOrDefault(x => { return x.Name == event.name });
      // if (controlToHide) {
      //   event.hideDataTable = true;
      //   event.cargarGrilla = false;
      // }

    }
    else if (event instanceof WindowComponent) {

      this.componentsContainer.FloatPanels.push(event);
      if (this.Pagina.ConfigPage.CommandBar) {
        let sidePanels = this.Pagina.ConfigPage.CommandBar.Buttons.find(x => { return x.Type == 'FloatPanels' });
        if (sidePanels) {

          if (!sidePanels.Buttons)
            sidePanels.Buttons = [];

          sidePanels.Buttons.push({ Label: event._config.Label, ComponentName: event.name, Type: 'ViewFloatPanel', Class: 'ms-Icon ms-Icon--ViewListTree' });

          // this.commandBar.CreateCommandBarElement(false);
        }
      }

    }
    else if (event instanceof FileUploaderComponent) {
      event.currentComponentName = this.importUrl;
      if (!this.componentsContainer.FileBox) {

        this.componentsContainer.FileBox = event;
        this.componentsContainer.FileBox.ApiResponse.subscribe(event => {
          this.RespuestaCargaDocumento(event);
        })
      }
      else if (!this.componentsContainer.FileBoxWorkFlow && event.isForWorkFlow) {

        this.componentsContainer.FileBoxWorkFlow = event;
        this.componentsContainer.FileBoxWorkFlow.ApiResponse.subscribe(event => {
          this.RespuestaCargaDocumento(event);
        })
      }
      else {
        this.AddComponentInstanceHandlerInGeneral(event);
      }
    }
    else if (event instanceof FormDetailsComponent) {
      this.componentsContainer.FormsDetails.push(event);
    }
    else if (event instanceof MessageBarComponent) {
      this.componentsContainer.MessageBars.push(event);
    }
    else if (event instanceof AttachmentSelectionComponent) {
      event.saveModelContainer = () => {
        return this.GetSaveModelContainer();
      }
      this.AddComponentInstanceHandlerInGeneral(event);
    }
    else {

      if (event.subControl && event.subControl.ControlType == 'DynamicComponentViewer') {
        return;
      }
      this.AddComponentInstanceHandlerInGeneral(event);
    }
  }
  public AddComponentInstanceHandlerInGeneral(event) {
    let _component = new List(this.childComponents).FirstOrDefault(x => { return x.name == event.name });
    if (!_component)
      this.componentsContainer.ChildComponents.push(event);
  }
  public CreateStopWatch() {
    StaticData.StopWatchView = null;
    StaticData.StopWatchView = new Stopwatch();
    StaticData.StopWatchView.start();
    StaticData.StartProcessView = new Date().getTime();
  }
  public OnActionEventHandler(event) {

//debugger


    if (event.Action === FormActionsThroughEmitters.ClearModel) {
      this.Limpiar(null, event.Action);
    }
    else if (
      event.action == "SaveForm" ||
      event.action == ControlActions.SaveForm
    ) {
      this.GuardarForm(this.form);
    }
    else if (event.ControlType == "FileUpload" && event.action == "Error") {
      if (this.componentsContainer.Tables.length > 0)
        this.componentsContainer.Tables[0].ReCargarGrilla(false);
    }
    else if (event.action == "CurrentBusyRecord") {
      this.CurrentBusyRecord = event;
    }
    else if (event.action) {
      if (event.action.Type == "SaveForm") this.GuardarForm(this.form);
    } else if (
      event.action == "ClearForm" ||
      event.action == ControlActions.ClearForm
    ) {
      this.LimpiarConfirm();
    } else if (event.ControlType == "Button") {
      let buttonConfig = event.Option ? event.Option : event.config;
      let action: any;
      if (buttonConfig.Actions) {
        action = buttonConfig.Actions.find((x) => {
          return (
            x.Type == "ExecuteWorkFlowAction" ||
            x.Type == ControlActions.ExecuteWorkFlowAction
          );
        });
      }
      if (action) {
        let subAction: any;
        let responsiblesConfig: any;
        switch (action.ActionFlowExecutionType) {
          case "FromCurrentPage":
            if (this.workFlowItem && this.workFlowItem.CurrentActionFlow) {
              responsiblesConfig = this.workFlowItem.CurrentActionFlow
                .WorkFlowTask.ResponsiblesConfig;
            } else {
              responsiblesConfig = this.Pagina.ConfigPage.WorkFlowTask
                .ResponsiblesConfig;
            }
            break;
          case "FromConfig":
            if (this.workFlowItem && this.workFlowItem.CurrentActionFlow) {
              subAction = this.workFlowItem.CurrentActionFlow.Actions.find(
                (x) => {
                  return x.GUID == action.ActionFlow.GUID;
                }
              );

              responsiblesConfig = subAction.WorkFlowTask.ResponsiblesConfig;
            } else {
              this.utility
                .VerModalWarning({
                  titulo: "Alerta",
                  descripcion: "Accion no implementada",
                  logWarn: true,
                })
                .then((dat) => { });
              return;
            }
            break;
          case "FromConfigControl":
            responsiblesConfig = this.Pagina.ConfigPage.HiddenControls.find(
              (x) => {
                return x.Name == action.HiddenControl;
              }
            );
            break;
        }

        this.VerPermisos(responsiblesConfig, subAction);
      } else {
        this.helperActions.ExecuteActionsControl(
          event.Option ? event.Option : event.config,
          false,
          null,
          this.modelo,
          this.permissionsConfig,
          this,
          null,
          this.actionEmitter,
          this.componentsContainer,
          event.Option,
          this.modelo.modeloMetadata,
          { importUrl: this.importUrl }
        );
      }
    } else if (event.ControlType == "CommandButton") {

      this.CreateStopWatch();
      this.helperActions.ExecuteActionsControl(
        event.Option ? event.Option : event.config,
        false,
        null,
        this.modelo,
        this.permissionsConfig,
        this,
        null,
        this.actionEmitter,
        this.componentsContainer,
        event.Option,
        this.modelo.modeloMetadata,
        { importUrl: this.importUrl }
      );

      if (event.Option.Type == "Clear") {
        this.LimpiarConfirm();
      } else if (event.Option.Type == "Save") {
        this.GuardarForm(this.form);
      } else if (event.Option.Type == "ViewFloatPanel") {
        let floatPanel = this.componentsContainer.FloatPanels.find((x) => {
          return x.name == event.Option.ComponentName;
        });
        if (
          floatPanel._config.Hidden == null ||
          floatPanel._config.Hidden == undefined
        ) {
          floatPanel._config.Hidden = false;
        }
        floatPanel._config.Hidden = !floatPanel._config.Hidden;
      }
      /*  let s = this.componentsContainer;

       let sidePanel = this.componentsContainer.ChildComponents.find(x => {
         return x.subControl &&
           x.subControl.ControlType === 'SidePanel'
       });
       if (sidePanel)
         sidePanel.OpenSidePanel(); */
    }
  }
  public OnDataTableEditHandler(event) {
    this.ExcecuteFuntion(ControllerMethods.Edit, false, this.modelo);

    this.SetModelToControls();
    this.SetProcesando(false);
    this.EditCascading();
    this.ExcecuteFuntion(ControllerMethods.Edit, true, this.modelo);
    // this.parentClearCall.next(this.modelo);
    this.FireFormEvent({ Name: "OnPostEdit", modelo: this.modelo });


    this.RaiseFormEvent('Edit');
  }
  public OnSelectionHandler(event) {


  }

  public OnLoadGridHandler(event) {


  }
  public OnSetConfigHandler(event) {


    if (event.config && !event.isPost) {

      let controlToHide = this.controlsForHiddenList.FirstOrDefault(x => { return x.Name == event.config.Name });
      if (controlToHide) {
        event.config.ConfigControl.HideDataTable = true;
      }
    }
  }


  public FireFormEvent(event) {
    if (this.formEvent) {
      this.formEvent.next(event);
    }
  }
  public Clone() {

    //this.modelo.modelo.PkValue=0;
    //this.modelo[this.pkName] =0;
    this.SetProcesando(false);
  }
  CURRENT_CONTROL: any;
  public VerEditor(tipo: any, subTipo: string) {


    const that = this;

    try {
      if (subTipo == 'modelo') {
        this.utility.VerEditor(tipo, this.modelo, true, "Editor de Modelo").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'workFlowItem') {
        this.utility.VerEditor(tipo, this.workFlowItem, true, "Editor de WorkFlowItem").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'page') {
        this.utility.VerEditor(tipo, this.Pagina, true, "Editor de Pagina").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'form') {
        console.log(this.form);
        // let frm = JSON.stringify(this.form, null, 2);

        var cache = [];
        let frm = JSON.stringify(this.form, function (key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Duplicate reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        }, 2);
        cache = null; // Enable garbage collection
        this.utility.VerEditor(tipo, frm, true, "Editor de Formulario").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'control') {

        let currentControl: any;
        if (this.form.controls[this.CURRENT_CONTROL] instanceof FormControl)
          currentControl = this.form.controls[this.CURRENT_CONTROL];
        else {
          currentControl = this.form.controls[this.CURRENT_CONTROL];
        }
        console.log(currentControl);
        // let frm = JSON.stringify(this.form, null, 2);

        var cache = [];
        let frm = JSON.stringify(currentControl, function (key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Duplicate reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        }, 2);
        cache = null; // Enable garbage collection
        this.utility.VerEditor(tipo, frm, true, "Editor de Control").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'validator') {

        let formControl = this.Pagina.ConfigPage.Controls.find(x => { return x.Name == this.CURRENT_CONTROL });

        formControl = this.Pagina.ConfigPage.Controls.find(x => { return x.Name == this.CURRENT_CONTROL.trim() });
        if (!formControl) {

          StaticData.ShowNotification("No se encontro el control:" + this.CURRENT_CONTROL);
          return;
        }
        let validations = [];
        if (formControl.Required)
          validations.push({ Name: "Required", Value: formControl.Required });
        if (formControl.MaxLength)
          validations.push({ Name: "MaxLength", Value: formControl.MaxLength });
        if (formControl.MinLength)
          validations.push({ Name: "MinLength", Value: formControl.MinLength });
        if (formControl.ControlType == 'NumberBox') {
          if (formControl.Max != null && !isNaN(formControl.Max) && formControl.Max != formControl.Min)
            validations.push(Validators.max(Number(formControl.Max)));
          if (formControl.Min != null && formControl.Max != null && formControl.Min == formControl.Max)
            console.warn(`Se ignoró validación Max dado que es igual que Min, en ${formControl.Name}`);
          if (formControl.Min != null && !isNaN(formControl.Min))
            validations.push(Validators.min(Number(formControl.Min)));
        }
        if (formControl.ControlType == 'EmailBox')
          validations.push({ Name: "EmailBox", Value: "Email" });
        if (formControl.Pattern)
          validations.push({ Name: "Pattern", Value: formControl.Pattern });

        this.utility.VerEditor(tipo, validations, true, "Editor de Validaciones").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
    }
    catch (error) {

      this.utility.logger.LogError(error, 'VerEditor')
    }

  }
  public VerPermisos(responsiblesConfig?: any, action?: any, propPermission: string = "Responsibles") {


    // se incializan las variables de configuracion
    this.modelo.NewAction = null;
    this.modelo.NewActionByConfig = null;


    const config = this.utility.GetConfigWindow(responsiblesConfig.TitleWindow);
    let windowModel: any;

    if (!responsiblesConfig) {

      this.utility.VerModalWarning({
        titulo: "Alerta",
        descripcion: "No Hay Configuracion de para esta operacion", logWarn: true
      }).then(dat => { });
      return;
    }
    if (!responsiblesConfig.PropPermission)
      responsiblesConfig.PropPermission = propPermission;
    if (!this.modelo.WorkFlowTaskModel[responsiblesConfig.PropPermission])
      this.modelo.WorkFlowTaskModel[responsiblesConfig.PropPermission] = [];


    // si se captura por sub accion, se asigna la configuracion de reasignacion, copia, aprobacion
    if (action) {
      this.modelo.NewAction = action;
      this.modelo.NewActionType = action.ObjectType;
    }
    else {
      // si se captura por control Oculto, se asigna la configuracion de reasignacion, copia, aprobacion
      this.modelo.NewActionByConfig = responsiblesConfig;
    }


    config.classWindow = 'info';
    config.titleWindow = responsiblesConfig.TitleWindow;
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';
    windowModel = {
      //project: this.project,
      modelo: this.modelo.WorkFlowTaskModel,
      config: responsiblesConfig
      /*   propPermission: propPermission,
        responsibles: this.Pagina.ConfigPage.WorkFlowTask.ResponsiblesConfig.Responsibles,
        verUsuarios: this.Pagina.ConfigPage.WorkFlowTask.ResponsiblesConfig.AllowUserReassignment,
        verRoles: this.Pagina.ConfigPage.WorkFlowTask.ResponsiblesConfig.AllowRolReassignment,
        verAreasEmpresa: this.Pagina.ConfigPage.WorkFlowTask.ResponsiblesConfig.AllowAreaReassignment,
        verGrupos: this.Pagina.ConfigPage.WorkFlowTask.ResponsiblesConfig.AllowGroupReassignment,
        tipoFiltroResponsable: this.Pagina.ConfigPage.WorkFlowTask.ResponsiblesConfig.ResponsibleFilterType, */
    };
    this.utility.VerModalComponente('InfoPermissionComponent', windowModel, config).then(dataResultOnClose => {
      if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {



      }
      else {

      }
    });

  }

  public get ViewContextMenu(): boolean {
    if (this.Pagina) {
      return (this.Pagina.ConfigPage.ModelViewer ||
        this.Pagina.ConfigPage.FormViewer ||
        this.Pagina.ConfigPage.ModelExport);
    }
    else
      return false;
  }
  public ExportToJson() {

    const filename = this.Pagina.Pagina + '.json';

    const jsonStr = JSON.stringify(this.modelo, null, 2);// JSON.stringify(this.PaginaSeleccionada.ConfigPage);

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  public ImportToJson(evt) {
    try {

      let files = evt.target.files;
      if (!files.length) {
        alert('No selecciono un archivo!');
        return;
      }
      let file = files[0];
      let reader = new FileReader();
      const self = this;
      reader.onload = (event: any) => {



        this.modelo = JSON.parse(event.target.result.toString());
        this.SetModelToControls();

      };
      reader.readAsText(file);
    } catch (err) {
      console.error(err);
    }
  }
  public VerEditorCampo(tipo: any, campo?: any) {

    let configuracionControl = this.Pagina.ConfigPage.Controls.find(x => { return x.Name == "Configuracion" });
    if (configuracionControl) {
      let valorConfiguracionString = this.modelo.modelo["Configuracion"];
      let valorConfiguracion: any = valorConfiguracionString;


      valorConfiguracion = (valorConfiguracionString) ? JSON.parse(valorConfiguracionString) : {};



      this.utility.VerEditor(tipo, valorConfiguracion, false, "Editor Config").then(data => {

        if (data.dialogResult == true) {
          // if (tipo == 1) {
          //   valorConfiguracionString = JSON.stringify(data.modelo);
          // }
          // else {
          //   valorConfiguracionString = data.modelo;
          // }
          valorConfiguracionString = JSON.stringify(data.modelo, null, 2);
        }
        else {

        }

        this.form.controls["Configuracion"].setValue(valorConfiguracionString);
        // this.modelo.modelo["Configuracion"] =valorConfiguracionString;
      });
    }
    else {
      StaticData.ShowNotification("La Pagina No PoseeCampo Configuracion");
    }

  }


  public OnSetConfig() {


    if (this.config) {
      this.name = this.config.Name;
      let queryConfig: any = null;
      let Items: Array<any> = null;
      let NotLoad: boolean = false;
      if (this.config.ConfigControl) {
        this.hideDataTable = this.config.ConfigControl.HideDataTable;
        this.cargarGrilla = !this.config.ConfigControl.HideDataTable;
        Items = this.config.ConfigControl.Items;
        queryConfig = this.config.ConfigControl.QueryConfig;
        NotLoad = this.config.ConfigControl.NotLoad;
      }
      else {
        this.hideDataTable = this.config.HideDataTable;
        this.cargarGrilla = !this.config.HideDataTable;
        Items = this.config.Items;
        queryConfig = this.config.QueryConfig;
        NotLoad = this.config.NotLoad;
      }


      this.processMessage = (this.config.ProcessMessage) ? this.config.ProcessMessage : this.processMessage;
      if (queryConfig) {
        this.MappingConfig(queryConfig);
        this.EjecutarQueryEdicion(queryConfig, Items, NotLoad, null);
      }
    }

    if (!this.name)
      this.name = this.componentName;


  }
  public MappingConfig(queryConfig: any) {
    if (this.config && this.config.MappingValues && this.config.MappingValues.length > 0) {
      this.helperActions.ExecuteMapping(this.config.MappingValues,
        this.modelo.modelo, null, null, false, this.modeloExterno, null, queryConfig);
    }
  }

  public EjecutarQueryEdicion(queryConfig: any, Items: Array<any>, NotLoad: boolean, modelo: any) {

    try {



      if (modelo) {
        this.modelo = modelo;
      }

      if (NotLoad)
        return;

      if (Items && Items.length > 0) {

        this.modelo.modelo = Items[0];
        return;
      }
      var obj = this.utility.GetApiModel('EjecutarQueryEdicion', '');
      obj.QueryConfig = queryConfig
      if (this.config && this.config.Query) {
        obj.QueryConfig.Query = this.config.Query;
      }

      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          processMessage: this.processMessage,
          method: 'OnInit'
        }, this.url, true, true).then(result => {

          this.modeloEdit = result.Data[0];
          if (this.modeloEdit) {
            this.SetModelo(this.modeloEdit, null, 3000);
          }

          // this.MappingModelo();


        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'EjecutarQueryEdicion' });
        })



    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'EjecutarQueryEdicion' });
    }
  }


  /**
   * CAMBIAR VISTA SWAP/LINEAL
   */
  public changeSwap(e) {
    if (!(<any>$(e.target)).hasClass('active')) {
      if ((<any>$(e.target)).hasClass('gform__swap')) {
        console.log('entro a swap')
        let grid = (<any>$('.swap__grid')).height() + 30;
        let form = (<any>$('.swap__form')).height() + 30;
        console.log('grid: ', grid);
        console.log('form: ', form);
        grid > form ? (<any>$('.swap__content')).height(grid) : (<any>$('.swap__content')).height(form);
        console.log('content: ', (<any>$('.swap__content')).height());
      } else {
        (<any>$('.swap__content')).height('auto');
      }
      (<any>$('.gform__swap')).toggleClass('active');
      (<any>$('.gform__lineal')).toggleClass('active');
      (<any>$('.swap__grid')).removeClass('active');
      this.Pagina.ConfigPage.Swap = !this.Pagina.ConfigPage.Swap;
    }
  }

  /**
 * ABRIR/CERRAR JSTREE
 */
  changeTree() {
    console.log("tree en gForm");
    if ((<any>$('.gform__tree')).width() === 0) {
      (<any>$('.gform__tree')).width(406);
    } else {
      (<any>$('.gform__tree')).width('0%');
    }
  }


}

