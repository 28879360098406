import { Component, OnInit,Input,Output,EventEmitter,forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import { ConfigBaseComponent} from '../../.././../models/config-base-component';

@Component({
  selector: 'app-role-filter',
  templateUrl: './role-filter.component.html',
  styleUrls: ['./role-filter.component.css']
})
export class RoleFilterComponent extends ConfigBaseComponent implements OnInit {

 
}
