import { Component, OnInit,Input,Output,EventEmitter,forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import { ConfigBaseComponent} from '../../.././../models/config-base-component';


@Component({
  selector: 'app-role-query',
  templateUrl: './role-query.component.html',
  styleUrls: ['./role-query.component.css']
})
export class RoleQueryComponent extends ConfigBaseComponent implements OnInit {


}
