import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, Inject, forwardRef, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { DataTableColumnTypes, TipoObjetoParaPermisos } from '../models/general.enum';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { LoggerService } from '../services/logger.service'
import { StaticData } from '../helpers/static-data';
import { List } from '../../assets/linqts/compilado/index';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { WindowService } from '../components/window.component/window.service';
import { ConfigWindow } from '../models/config-window';


import { Guid } from 'guid-typescript';
import { Utilities } from './utilities';

import { BaseComponentComponent } from '../components/base-component/base-component.component';
import { BaseServiceService } from '../services/base-service.service';

export class UtilitiesColumns {

    //formControlTypes: List<any> = new List<any>([]);
    constructor(
        @Inject(forwardRef(() => Utilities)) public utility: Utilities) {

    }

    public GetDatosBotonDataTables(event: any): any {
        var element = $(event.currentTarget);
        var id = $(element).data("id");
        var data = $(element).data("dat");
        var index = $(element).data("index");
        var col = $(element).data("col");
        return { id: id, data: data, index: index, col: col };
    }
    public ProcessColumData(modelo: any, column: any, currentColumnData: any, row: any, jsonText: any) {

        return this.ReemplaceValues(modelo, column, currentColumnData, row, jsonText);
    }
    ReemplaceValues(modelo: any, column: any, currentColumnData: any, row: any, jsonText: any) {

        let htmlMsg = currentColumnData;
        if (column && modelo) {
            if (column.HtmlMsg) {
                htmlMsg = column.HtmlMsg;
                if (currentColumnData)
                    htmlMsg = htmlMsg.replace("#CurrentData#", currentColumnData);
                htmlMsg = this.utility.ReplaceTextFromModels(row, htmlMsg);
                if (column.InfoModelsContainer) {
                    let modelsnames: Array<any> = column.InfoModelsContainer.split(',');
                    let tempModelo: any = {};
                    modelsnames.forEach(modelName => {
                        tempModelo[modelName] = modelo[modelName];
                    });
                    htmlMsg = this.utility.ReplaceTextFromModels(tempModelo, htmlMsg);
                }
                else {

                    htmlMsg = this.utility.ReplaceTextFromModels(modelo, htmlMsg);

                }
                if (column.EvalExpression) {
                    htmlMsg = eval(htmlMsg);
                }
            }
            if (column.Script && column.JsonProcessType != 'Options' && column.JsonProcessType == 'OptionsExtract') {

                htmlMsg = this.ExecuteSCript(column.Script, column.EvalScript);
            }

        }
        htmlMsg = this.GetElipse(column, htmlMsg)
        return htmlMsg;
    }
    ExecuteSCript(script, isEval, row?: any, data?: any, index?: any, context?: any) {
        let result = null;
        if (script) {
            if (isEval)
                result = eval(script);
            else {
                let functionGeneric = new Function(script);
                result = functionGeneric();
            }
        }
        return result;
    }
    GetElipse(column: any, data: any): any {
        if (data && column && column.TextMaxlength > 0 && data.length > column.TextMaxlength) {
            return `<span class="column__elipse">${data.substring(0, column.TextMaxlength) + ' ...'}
                        <div>${data}</div>
                    </span>`;
        }
        else {
            return data;
        }
    }
    public GetNoPermitidoIcono() {
        return '../assets/images/jstree/no-permitido.png';
    }
    public GetNoPermitidoGrilla(toolTip) {
        return '<span class="ms-CommandButton-icon ms-fontColor-red" title="No Posee Permisos para ' + toolTip + '" > <i class="ms-Icon ms-Icon--Blocked" aria-hidden="true"></i></span>'
        // return '<a title="No Posee Permisos para ' + toolTip + '" ><img style="height:32px;with:32px;" src="../assets/images/jstree/no-permitido.png"     aria-hidden="true"></a>'
    }
    public GetCheckSeleccion(tableName, index) {
        // let html = '<div name="SeleccionCol' + tableName + '"  class="ms-CheckBox ms-CheckBox-inTable">' +
        //     ' <input tabindex="-1" type="checkbox" class="ms-CheckBox-input">' +
        //     '   <label role="checkbox" class="ms-CheckBox-field" tabindex="0" aria-checked="false" name="checkboxa">' +
        //     '     <span class="ms-Label"></span>' +
        //     '  </label>' +
        //     '   </div>';
        let html = '<input class="data-table-selection-radio data-table-selection-radio2" tabindex="-1" id="SeleccionCol' + tableName + index + '"  name="SeleccionCol' + tableName + '" type="radio" >';
        return html;
    }
    public GetCheckMultiSeleccion(tableName, index, checkColum: boolean = false) {
        //  let html = '<div name="CheckBoxCol' + tableName + '"  class="ms-CheckBox ms-CheckBox-inTable">' +
        //      ' <input tabindex="-1" type="checkbox" id="CheckBoxCol' + tableName + index + '"  class="ms-CheckBox-input">' +
        //      '   <label role="checkbox" for="CheckBoxCol' + tableName + index + '" class="ms-CheckBox-field" tabindex="0" aria-checked="false" name="checkboxa">' +
        //      '     <span class="ms-Label"></span>' +
        //      '  </label>' +
        //      '   </div>';

        var html = '';
        if(checkColum)
          html = ' <input  type="checkbox" id="CheckBoxCol' + tableName + index + '"  class="" checked>';
        else
          html = ' <input  type="checkbox" id="CheckBoxCol' + tableName + index + '"  class="">';

        return html;
        // let html = ' <input  type="checkbox" id="CheckBoxCol' + tableName + index + '"  class="">';
        // return html;
    }
    GetHtmlEspecialColumn(data, type, row, meta, configPermisos: any, pkName: string, that: UtilitiesColumns, column: any,
        validatePermissions: boolean, modelo: any, jsonText: any, _name?:any) {
        let ok = true;
        if (!column.DataField && !column.DataIconField && !column.Image && !column.Icon && !column.ToolTipField) {
            return '';
        }
        if (validatePermissions) {

            if (configPermisos)
                ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, 'Guardar', false, `Columna,Id:${row[pkName]},Nombre:${row.Nombre}`);
        }
        let icono = column.Icon;
        let image = column.Image;
        let toolTip = column.ToolTip;
        let text = "";
        let nombreBoton = column.CustomName + "_principal";
        let nameLink = _name;

        if (ok) {
            if (column.ToolTipField)
                toolTip = row[column.ToolTipField];
            if (jsonText != null)
                text = jsonText;
            else if (column.DataField)
                text = row[column.DataField];

            if (toolTip && toolTip.includes("#"))
                toolTip = that.utility.ReplaceTextFromModels(row, toolTip);

            if (!toolTip)
                toolTip = '';
            if (!text)
                text = '';
            else {
                text = that.ProcessColumData(modelo, column, text, row, jsonText);
                if (column.TextLink)
                    text = '<div class="dt__group--text"><span>' + text + '</span></div>';
                else
                    text = '<div class="dt__group--text"><span data-dat="' + meta.row + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '" name="' + nameLink +'">' + text + '</span></div>';
            }

            if (column.DataIconField && column.SearchField && column.IconField) {
                let _icono = this.utility.GetNodeTypesArr().find(x => { return x[column.SearchField] == row[column.DataIconField] });
                if (_icono) {
                    icono = _icono[column.IconField];
                    if (icono.indexOf("/") !== -1 || icono.indexOf(".") !== -1) {
                        image = this.utility.GetPathImages(icono);
                        icono = "";
                    }
                    else {
                        icono = this.utility.GetPathImages(icono);
                    }
                }
            }
            else if (column.DataIconField) {
                icono = row[column.DataIconField];
                if (icono.indexOf("/") !== -1 || icono.indexOf(".") !== -1) {
                    image = icono;
                    icono = "";
                }

            }
            if (image)
                return '<div class="dt__group--img"><a title="' + toolTip + '"><img src="' + image + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '" name="' + nombreBoton + '" aria-hidden="true"></a></div>' + text;
            else
                return '<span class="ms-CommandButton-icon" title="' + toolTip + '"" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"  class="' + icono + '"  aria-hidden="true"></i></span>' + text;


        }
        else
            return that.GetNoPermitidoGrilla(toolTip);
    }
    GetNotification(notificationConfig, row, text, jsonText) {
        //     let notification = `<div  class="#Type#"  style="width: 100%;">
        //     <div class="ms-MessageBar-content">
        //         <div class="ms-MessageBar-icon">
        //             <i class="#Icon#"></i>
        //         </div>
        //         <div class="ms-MessageBar-text">
        //         #Msg#
        //         </div>
        //     </div>
        //   </div>`;

        let notification = `<div  class="#Type#"  style="width: 100%;">
                <div class="ms-MessageBar-content">
                    <div class="ms-MessageBar-icon">
                        <i class="#Icon#"></i>
                    </div>
                    <div class="ms-MessageBar-text">
                    #Msg#
                    </div>
                </div>
            </div>`;
        if (notificationConfig.Msg) {
            text = notificationConfig.Msg;
        }

        if (jsonText)
            text = jsonText;
        if (text.includes("#"))
            text = this.utility.ReplaceTextFromModels(row, text);
        notification = notification.replace('#Type#', notificationConfig.Type);
        notification = notification.replace('#Icon#', notificationConfig.Icon);
        notification = notification.replace('#Msg#', text);
        return notification;
    }
    public GetChildOptionsColumn(childOptions: Array<any>, componentName: string, pkName: string,
        contextConfigPermisos?: any, title?: string, _name?: string, customName?: string, label?: string, _column?: any, modelo?: any, configTable?: any) {
        const that = this;
        let optionTemplate = `<a class="dropdown-item" >#label#</a>`;

        /*   let template = `<div class="btn-group">
          <button title="Opciones" class="btn btn-primary btn-sm dropdown-toggle" type="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        </button>
          <div class="dropdown-menu">
             #options#
          </div>
        </div>`; */

        let template = `<div class="dt__group--menu">
        <div class="dropdown__wrapper">
            <button class="ms-Button ms-Button-NoMargin ms-Button-NoBorder" type="button">
                <span><i class="ms-Icon ms-Icon--MoreVertical"></i></span>
            </button>
            <div class="dropdown__menu ms-ContextualMenu is-opened ms-ContextualMenu--hasIcons">
                    #options#
            </div>
        </div></div>`;
        let template2 = `<div class="dt__group--menu">
        <div class="dropdown">

            <div class="dropdown-menu ms-ContextualMenu is-opened ms-ContextualMenu--hasIcons">

            </div>
        </div></div>`;

        customName = (customName) ? customName + componentName : 'childOptions' + componentName;
        let templateResult: string = '';
        let column: any = {
            config: { childOptions: childOptions, name: customName },
            columnType: DataTableColumnTypes.Options,
            isCustom: true,
            name: _name,
            customName: customName,
            title: (title) ? title : 'Modales',
            "class": 'dt-body-center',
            data: "",


            render: function (data, type, row, meta) {
                let optionsHtml: string = '';
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                let childIndex = 0;
                let JsonText = null;
                if (_column && (_column.JsonProcessType == 'Options' || _column.JsonProcessType == 'OptionsExtract' ||
                    _column.JsonProcessType == 'Extract')) {

                    JsonText = _column.JsonText;
                    if (!childOptions) {
                        childOptions = [];
                    }
                    if (row[_column.DataField]) {
                        let objectConvert = JSON.parse(row[_column.DataField]);
                        if (_column.JsonProcessType == 'OptionsExtract') {
                            JsonText = objectConvert[_column.JsonText];
                            if (!JsonText)
                                JsonText = _column.JsonText;
                        }
                        if (JsonText && JsonText.includes && JsonText.includes('#')) {
                            JsonText = JsonText.replace("#count#", objectConvert.length);
                            JsonText = that.utility.ReplaceTextFromModels(row, JsonText);
                        }
                        const modelProperties = that.utility.SplitPropertyToModel(_column.JsonProperty);
                        objectConvert = that.utility.GetValueFromPropList(objectConvert, modelProperties.listProps);
                        if (_column.JsonProcessType == 'Options' || _column.JsonProcessType == 'OptionsExtract') {
                            childOptions = [];
                            objectConvert.forEach(itemData => {
                                let _option = { label: '', title: '' };
                                _option.label = that.utility.ReplaceTextFromModels(itemData, _column.JsonHtmlMsg);
                                _option.title = _option.label;
                                childOptions.push(_option);
                            });
                        }
                        else {

                            if (!JsonText)
                                JsonText = "";
                            if (JsonText && JsonText.includes && JsonText.includes('#')) {
                                JsonText = JsonText.replace("#count#", 0);
                                JsonText = that.utility.ReplaceTextFromModels(objectConvert, JsonText);
                                JsonText = that.utility.ReplaceTextFromModels(row, JsonText);
                            }
                            if (_column.JsonUseType == 'Table') {
                                // //creates table
                                // var table = $('<table></table>');

                                // var tr = $('<tr></tr>') //creates row
                                // var th = $('<th></th>') //creates table header cells
                                // var td = $('<td></td>') //creates table cells

                                // var header = tr.clone() //creates header row

                                // //fills header row
                                // Object.keys(ds.data[0]).forEach((d) => {
                                //     header.append(th.clone().text(d))
                                // })

                                // //attaches header row
                                // table.append($('<thead></thead>').append(header))

                                // //creates
                                // var tbody = $('<tbody></tbody>')

                                // //fills out the table body
                                // objectConvert.forEach((d) => {

                                //     var row = tr.clone() //creates a row
                                //     d.forEach((e, j) => {

                                //         row.append(td.clone().text(e)) //fills in the row
                                //     })
                                //     tbody.append(row) //puts row on the tbody
                                // })

                                // $("#" + _column.TagReplaceContainer).append(table.append(tbody))
                            }
                            else if (_column.JsonUseType == 'Value') {

                                _column.Tags.forEach(tag => {

                                    JsonText = JsonText.replace(tag.Tag, objectConvert[tag.Field]);
                                });

                            }
                            else if (_column.JsonUseType == 'ForEach') {

                                let value = "";

                                objectConvert.forEach(item => {
                                    let jsonHtmlMsg = _column.JsonHtmlMsg;
                                    _column.Tags.forEach(tag => {
                                        jsonHtmlMsg = jsonHtmlMsg.replace(tag.Tag, item[tag.Field]);
                                    });
                                    value += jsonHtmlMsg;

                                });
                                JsonText = JsonText.replace(_column.TagReplaceContainer, value);
                            }
                            else {

                            }
                        }
                    }
                    else {
                        if (!JsonText)
                            JsonText = "";

                        if (JsonText.includes && JsonText.includes('#')) {
                            JsonText = JsonText.replace("#count#", 0);
                            JsonText = that.utility.ReplaceTextFromModels(row, JsonText);
                            JsonText = JsonText.replace(_column.TagReplaceContainer, '');
                        }
                    }
                    // column.Script = column.Script.replace("#row#", row);
                    // childOptions = this.ExecuteSCript(column.Script, column.EvalScript);
                }


                $.each(childOptions, function (index: number, option: any) {
                    let ok: boolean = true;
                    if (!option.title)
                        option.title = '';
                    if (option.opcionPermiso != "None" && !option.isDivider && option.DisabledPermissions !== true)
                        ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, option.opcionPermiso, false, `ColumnaOpciones,Id:${row[pkName]},Nombre:${row.Nombre}`);

                    if (option.campoVersiones) {
                        ok = (row[option.campoVersiones] > 1);
                    }

                    if (ok) {
                        if (option.isDivider)
                            optionsHtml += '<div class="ms-ContextualMenu-item ms-ContextualMenu-item--divider"></div>';//
                        // '<div class="dropdown-divider"></div><br/>'; //ms-ContextualMenu-item ms-ContextualMenu-item--divider
                        else {
                            //if(DataField)
                            if (option.infoField) //{
                                option.label = row[option.infoField]
                            //     optionsHtml += '<li class="ms-ContextualMenu-item"><a data-dat="' + index + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  class="ms-ContextualMenu-link" title="' + option.title + '" >' + option.label + '</a></li>'
                            // }
                            // else {
                            //     optionsHtml += '<li class="ms-ContextualMenu-item"><a data-dat="' + index + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '" name="' + customName + '" class="ms-ContextualMenu-link" title="' + option.title + '" >' + option.label + '</a></li>'

                            // }
                            if(option.Type == 'Miniatura' && row[option.infoField]!= ''){

                                var ext =row[_column.DataIconField];
                                optionsHtml += '<li class="ms-ContextualMenu-item"><img src="data:image/'+ext+';base64,'+row[option.infoField]+'" class="dt__group--menu-img"/></li>'
                            }
                            else if(!option.Type || option.Type != 'Miniatura')
                            optionsHtml += '<li class="ms-ContextualMenu-item"><a data-dat="' + index + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '" name="' + customName + '" class="ms-ContextualMenu-link" title="' + option.title + '" >' + option.label + '</a></li>'
                        }
                    }
                    else {
                        optionsHtml += '<li class="ms-ContextualMenu-item"><a class="ms-ContextualMenu-link" >No Posee Permisos ' + option.label + '</a></li>'
                    }


                });
                if (childOptions && childOptions.length > 0)
                    templateResult = template.replace(' #options#', optionsHtml);
                else {
                    templateResult = "";
                }
                if (_column.NotificationsTypeFilter && configTable.Notifications && configTable.Notifications.length > 0) {

                    let tipoAlerta = row[_column.DataField];
                    let noficationConfig = configTable.Notifications.find(x => { return x.Name.toLowerCase() == tipoAlerta.toLowerCase() });
                    if (noficationConfig) {


                        // let htmlgeneral = that.GetHtmlEspecialColumn(data, type, row, meta, configPermisos, pkName, that, _column, false, modelo,JsonText);
                        templateResult = that.GetNotification(noficationConfig, row, tipoAlerta, JsonText)
                        templateResult = '<div class="dt__group">' + templateResult + '</div>';
                        return templateResult;
                    }
                    else {
                        let htmlgeneral = that.GetHtmlEspecialColumn(data, type, row, meta, configPermisos, pkName, that, _column, false, modelo, JsonText);
                        templateResult = '<div class="dt__group">' + htmlgeneral + templateResult + '</div>';
                        return templateResult;
                    }

                }
                else {
                    let htmlgeneral = that.GetHtmlEspecialColumn(data, type, row, meta, configPermisos, pkName, that, _column, false, modelo, JsonText, _name);
                    templateResult = '<div class="dt__group">' + htmlgeneral + templateResult + '</div>';
                    return templateResult;
                }

            }
        }

        return column;
    }
    public GetGeneralEspecialColumn(column: any, modelo: any, jsonText: any): any {
        const that = this;

        column.render = (data, type, row, meta) => {
            let ok = true;


            return that.ProcessColumData(modelo, column, data, row, jsonText);

        }
        return column;
    }
    public GetBooleanColumn(componentName: string, title: string, toolTip: string,
        name: string, pkName: string, propName: string,
        validationPropName: string, isThreeState: boolean = false, contextConfigPermisos?: any, _name?: string, _column?: any): any {
        const that = this;
        let column: any = {
            config: { title: title, name: name, pkName: pkName, propName: propName, validationPropName: validationPropName, isThreeState: isThreeState },
            title: title,
            columnType: DataTableColumnTypes.Boolean,
            isCustom: true,
            name: _name,
            "class": 'dt-body-center dt-small',
            data: "",
            render: function (data, type, row, meta) {
                let ok: boolean = true;
                let DisabledPermissions: boolean = false;
                if (_column && _column)
                    DisabledPermissions = _column.DisabledPermissions;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (validationPropName) {
                    ok = (row[validationPropName] == true || row[validationPropName] === null || row[validationPropName] === '');
                }
                if (ok && !DisabledPermissions !== true) {
                    ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, 'Guardar', false, `ColumnaBool,Id:${row[pkName]},Nombre:${row.Nombre}`);
                }
                if (ok) {
                    if (_column && _column.WithSelection) {
                        if (isThreeState) {
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i   data-jtmulti-values="false,null,true" class="jtoggler" data-jtmulti-dat="' + row[propName] + '" data-jtmulti-id="' + row[pkName] + '" data-jtmulti-index="' + meta.row + '"   data-jtmulti-col="' + meta.col + '"  data-jtmulti-state  data-jtmulti-prop="' + propName + '"   data-jtmulti-name="' + name + componentName + '" aria-hidden="true">' +
                                '</i>' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</a>';
                        }
                        else {
                            if (row[propName])
                                return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[propName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + name + componentName + '" class="fas fa-check-square"  aria-hidden="true">' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</i></a>';
                            else
                                return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[propName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + name + componentName + '"  class="far fa-square"  aria-hidden="true">' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</i></a>';
                        }
                    }
                    else {
                        if (isThreeState) {
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i   data-jtmulti-values="false,null,true" class="jtoggler" data-jtmulti-dat="' + row[propName] + '" data-jtmulti-id="' + row[pkName] + '" data-jtmulti-index="' + meta.row + '"   data-jtmulti-col="' + meta.col + '"  data-jtmulti-state  data-jtmulti-prop="' + propName + '"   data-jtmulti-name="' + name + componentName + '" aria-hidden="true"></i></a>';
                        }
                        else {
                            if (row[propName])
                                return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[propName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + name + componentName + '" class="fas fa-check-square"  aria-hidden="true"></i></a>';
                            else
                                return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[propName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + name + componentName + '"  class="far fa-square"  aria-hidden="true"></i></a>';
                        }
                    }
                }
                else
                    return '<a title="Esta funcionalidad no aplica...." style="cursor:pointer;"><i  class="fa fa-exclamation-triangle"  aria-hidden="true"></i></a>';
            }
        }
        if (!title)
            delete column.title;
        return column;
    }
    public GetColumnaEditar(pkName: string, nombreBotonEditar: string,
        icono?: string, title?: string, toolTip?: string, contextConfigPermisos?: any, _name?: string, _column?: any): any {
        const that = this;
        icono = (icono) ? icono : 'editar.png';
        toolTip = toolTip;
        let column: any = {
            columnType: DataTableColumnTypes.Edit,
            isCustom: true,
            title: title,
            name: _name,
            "class": 'dt-body-center dt-small',
            data: "",
            render: function (data, type, row, meta) {

                let disabledPermissions: boolean = false;
                if (_column && _column)
                    disabledPermissions = _column.DisabledPermissions;



                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos && disabledPermissions !== true) {

                    let textoLog = `ColumnaEditar`;
                    if (pkName && row)
                        textoLog += `,Id:${row[pkName]}`;
                    if (row && row.Nombre)
                        textoLog += `,Nombre:${row.Nombre}`;

                    if(configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Expediente || configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Documento)
                      ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, 'Consultar', false, textoLog);
                    else
                      ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, 'Editar', false, textoLog);

                }
                if (ok) {

                    if (toolTip && toolTip.includes("#"))
                        toolTip = that.utility.ReplaceTextFromModels(row, toolTip);
                    if (_column && _column.WithSelection) {
                        //'+that.GetCheckSeleccion(_column.TableName)+'

                        return '<a title="' + toolTip + '" style="cursor:pointer;"><i name="' + nombreBotonEditar + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"    aria-hidden="true">' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</i></a>'
                    }
                    else {
                        if (icono)
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><img  src="' + that.utility.pathImages + icono + '" name="' + nombreBotonEditar + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>'
                        else
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i name="' + nombreBotonEditar + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  class="fas fa-pen"   aria-hidden="true"></i></a>'
                    }
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);

            }
        };
        return column;
    }
    //<img src="../../../images/dominica-flag-icon.gif" alt="">

    public GetColumnaIcono(pkName: string,
        icono: string,
        nombreBoton: string,
        dataIconField?: string,
        searchField?: string,
        iconField?: string,
        title?: string,
        toolTip?: string,
        lst?: List<any>,
        fieldToolTip?: string,
        contextConfigPermisos?: any,
        _name?: string, _column?: any): any {
        const that = this;
        return {
            columnType: DataTableColumnTypes.Icon,
            isCustom: true,
            title: title,
            name: _name,
            customName: nombreBoton,
            "class": 'dt-body-center dt-small',
            data: "",
            render: function (data, type, row, meta) {
                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = true;//that.utility.TienePemisoUsuario(configPermisos, row, 'Guardar');
                if (ok) {

                    if (dataIconField && lst) {
                        let _icono = lst.Where(x => { return x[searchField] == row[dataIconField] }).FirstOrDefault();
                        if (_icono) {
                            icono = _icono[iconField];
                        }
                        else
                            icono = null;
                    }
                    if (_column && _column.WithSelection) {
                        //'+that.GetCheckSeleccion(_column.TableName)+'
                        if (fieldToolTip) {
                            toolTip = row[fieldToolTip];

                            if (toolTip)
                                return '<a title="' + toolTip + '"" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"    aria-hidden="true">' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</i></a>';
                            else
                                return that.GetCheckSeleccion(_column.TableName, meta.row);

                        }
                        else {

                            return '<a title="' + toolTip + '"" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBoton + '"   aria-hidden="true">' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</i></a>';
                        }
                    }
                    else {
                        if (fieldToolTip) {
                            toolTip = row[fieldToolTip];
                            if (icono) {
                                if (toolTip)
                                    return '<a title="' + toolTip + '" style="cursor:pointer;"><img   src="' + that.utility.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;"   aria-hidden="true"></a>';
                                else
                                    return '';
                            }
                            else {
                                if (toolTip)
                                    return '<a title="' + toolTip + '"" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"  class="fas fa-exclamation-triangle"  aria-hidden="true"></i></a>';
                                else
                                    return '';
                            }
                        }
                        else {
                            if (icono)
                                return '<a title="' + toolTip + '" style="cursor:pointer;"><img   src="' + that.utility.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  name="' + nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;"   aria-hidden="true"></a>';
                            else
                                return '<a title="' + toolTip + '"" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBoton + '"  class="fas fa-exclamation-triangle"  aria-hidden="true"></i></a>';
                        }
                    }
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }

        };
    }
    public GetColumnaSeleccionItem(pkName: string, nombreBoton: string,
        icono?: string, title?: string, toolTip?: string, contextConfigPermisos?: any, _name?: string, _column?: any): any {
        const that = this;
        toolTip = toolTip;
        //icono = (!icono) ? 'seleccionar' : icono;
        let column: any = {
            columnType: DataTableColumnTypes.Selection,
            isCustom: true,
            name: _name,
            title: title,
            "class": 'dt-body-center dt-small',
            data: "",
            render: function (data, type, row, meta) {

                let ok = true;


                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);

                if (configPermisos)
                    ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, 'Editar', false, `ColumnaSeleccion,Id:${row[pkName]},Nombre:${row.Nombre}`);
                if (ok) {

                    if (toolTip && toolTip.includes("#"))
                        toolTip = that.utility.ReplaceTextFromModels(row, toolTip);
                    // let html = '<i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"  aria-hidden="true">' +
                    //     '<div name="SeleccionCol"  class="ms-CheckBox ms-CheckBox-inTable">' +
                    //     ' <input tabindex="-1" type="checkbox" class="ms-CheckBox-input">' +
                    //     '   <label role="checkbox" class="ms-CheckBox-field" tabindex="0" aria-checked="false" name="checkboxa">' +
                    //     '     <span class="ms-Label"></span>' +
                    //     '  </label>' +
                    //     '   </div></i>';
                    // return html;

                    if (_column && _column.WithSelection) {
                      return '<a title="' + toolTip + '" style="cursor:pointer"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"   aria-hidden="true">' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</i></a>';
                    }
                    else if (_column && _column.WithMultiSelection) {

                      ok = false;
                      if(contextConfigPermisos.selectedItems && contextConfigPermisos.selectedItems.length > 0)
                      {
                        contextConfigPermisos.selectedItems.forEach((element) => {

                          if(element[_column.DataField] == row[_column.DataField])
                            ok = true;
                          return;

                        })
                      }
                        //'+that.GetCheckSeleccion(_column.TableName)+'
                        //return '<a title="' + toolTip + '" style="cursor:pointer"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"   aria-hidden="true">' + that.GetCheckMultiSeleccion(_column.TableName, meta.row) + '</i></a>';
                      var multiselectHtml = '<a title="' + toolTip + '" style="cursor:pointer"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"   aria-hidden="true">' + that.GetCheckMultiSeleccion(_column.TableName, meta.row, ok) + '</i></a>';
                      return multiselectHtml; //'<a title="' + toolTip + '" style="cursor:pointer"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"   aria-hidden="true">' + that.GetCheckMultiSeleccion(_column.TableName, meta.row) + '</i></a>';
                    }
                    if (icono)
                        return '<a title="' + toolTip + '" style="cursor:pointer"><img  src="' + that.utility.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;"   aria-hidden="true"></a>';
                    else
                        return '<a title="' + toolTip + '" style="cursor:pointer"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"  class="far fa-hand-pointer"  aria-hidden="true"></i></a>';
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }
        };
        return column;
    }
    public GetColumnaEliminar(pkName: string, nombreBotonEliminar: string,
        icono?: string, title?: string, toolTip?: string, contextConfigPermisos?: any, _name?: string, _column?: any): any {
        const that = this;
        toolTip = (!toolTip) ? 'Eliminar' : toolTip;
        icono = (!icono) ? 'eliminar.png' : icono;
        let column: any = {
            columnType: DataTableColumnTypes.Delete,
            isCustom: true,
            name: _name,
            title: (!title) ? 'Eliminar' : title,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {
                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, 'Eliminar', false, `ColumnaEliminar,Id:${row[pkName]},Nombre:${row.Nombre}`);
                if (ok) {
                    if (_column && _column.WithSelection) {
                        return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '"    aria-hidden="true">' + that.GetCheckSeleccion(_column.TableName, meta.row) + '</i></a>';
                    }
                    else {
                        if (icono)
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><img src="' + that.utility.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBotonEliminar + '"  style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>';
                        else
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '"  class="fas fa-trash"  aria-hidden="true"></i></a>';
                    }
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }
        };
        return column;
    }
    public GetColumnaActivar(pkName: string, nombreBotonEliminar: string,
        iconoActivo?: string,
        iconoInActivo?: string,
        title?: string,
        toolTip?: string, contextConfigPermisos?: any, _name?: string, _column?: any): any {
        const that = this;
        toolTip = (!toolTip) ? 'Activar / Inactivar' : toolTip;
        // iconoActivo = (iconoActivo) ? iconoActivo : "activo.png";
        // iconoInActivo = (iconoInActivo) ? iconoInActivo : "inactivo.png";
        let column: any = {
            columnType: DataTableColumnTypes.Selection,
            isCustom: true,
            name: _name,
            title: (!title) ? 'Activar / Inactivar' : title,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {

                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = that.utility.TienePemisoUsuarioDos(configPermisos, row, 'Eliminar', false, `ColumnaActivar,Id:${row[pkName]},Nombre:${row.Nombre}`);
                if (ok) {

                    if (iconoActivo) {
                        if (row.Activo)
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><img src="' + that.utility.pathImages + iconoActivo + '"  data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '"  style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>';
                        else
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><img src="' + that.utility.pathImages + iconoInActivo + '"  data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBotonEliminar + '"  style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>';
                    }
                    else {
                        if (row.Activo)
                            return '<span class="ms-CommandButton-icon" title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '" class="ms-Icon ms-Icon--CheckboxComposite"  aria-hidden="true"></i></span>';
                        else
                            return '<span class="ms-CommandButton-icon" title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '"  class="ms-Icon ms-Icon--Checkbox"  aria-hidden="true"></i></span>';
                    }
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }
        };
        return column;
    }

    public GetColumnaEditarEliminar(pkName: string, title: string = '', isUnion: boolean = false,
        editar: any = { nombreBoton: null, icono: null, toolTip: null, name: null },
        eliminar: any = { nombreBoton: null, iconoActivo: null, iconoInActivo: null, toolTip: null, name: null },
        seleccionar: any = { nombreBoton: null, icono: null, toolTip: null, name: null }, contextConfigPermisos?: any,
        _name?: string, _column?: any): any {

        const that = this;
        if (seleccionar)
            seleccionar.toolTip = (!seleccionar.toolTip) ? 'Seleccionar' : seleccionar.toolTip;
        if (editar)
            editar.toolTip = (!editar.toolTip) ? 'Editar' : editar.toolTip;
        if (eliminar) {
            if (isUnion)
                eliminar.toolTip = (!eliminar.toolTip) ? 'Eliminar' : eliminar.toolTip;
            else
                eliminar.toolTip = (!eliminar.toolTip) ? 'Activar / Inactivar' : eliminar.toolTip;
        }
        let column: any = {
            columns: [],
            columnType: DataTableColumnTypes.Actions,
            isCustom: true,
            name: _name,
            title: title,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {

                let html = '';
                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (seleccionar) {

                    if (configPermisos)
                        ok = that.utility.TienePemisoUsuario(configPermisos, row, 'Editar');
                    if (ok) {

                        if (seleccionar.icon)
                            html = '<span class="ms-CommandButton-icon" title="' + seleccionar.toolTip + '" style="cursor:pointer;"><i name="' + seleccionar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  class="' + seleccionar.icon + '"   aria-hidden="true"></i></span>';
                        else
                            html = '<span class="ms-CommandButton-icon" title="' + seleccionar.toolTip + '" style="cursor:pointer;"><i name="' + seleccionar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  class="ms-Icon ms-Icon--RadioBtnOff"   aria-hidden="true"></i></span>';
                    }
                    else
                        html = that.GetNoPermitidoGrilla(seleccionar.toolTip);

                    column.columns.push({ columnType: DataTableColumnTypes.Selection, isCustom: true });

                }
                if (editar) {
                    if (configPermisos)
                        ok = that.utility.TienePemisoUsuario(configPermisos, row, 'Editar');
                    if (ok) {
                        // editar.icon = 'editar.png'
                        if (editar.icon)
                            html = html + '<span class="ms-CommandButton-icon"  title="' + editar.toolTip + '" style="cursor:pointer;"><i name="' + editar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" class="' + editar.icon + '"   aria-hidden="true"></i></span>';
                        else
                            html = html + '<span  class="ms-CommandButton-icon" title="' + editar.toolTip + '" style="cursor:pointer;"><i name="' + editar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" class="ms-Icon ms-Icon--EditNote"   aria-hidden="true"></i></span>';
                    }
                    else
                        html = html + that.GetNoPermitidoGrilla(editar.toolTip);

                    column.columns.push({ columnType: DataTableColumnTypes.Edit, isCustom: true });
                }
                if (eliminar) {
                    if (configPermisos)
                        ok = that.utility.TienePemisoUsuario(configPermisos, row, 'Eliminar');
                    if (ok) {
                        if (eliminar.iconoActivo) {

                            if (isUnion) {
                                html = html + '<a title="' + eliminar.toolTip + '" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + eliminar.nombreBoton + '"  class="' + eliminar.iconoActivo + '"  aria-hidden="true"></i></a>';
                                //column.columns.isDelete = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Delete, isCustom: true });
                            }
                            else {
                                if (row.Activo)
                                    html = html + '<span class="ms-CommandButton-icon"  title="' + eliminar.toolTip + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + eliminar.nombreBoton + '" class="' + eliminar.iconoActivo + '"  aria-hidden="true"></i></span>';
                                else
                                    html = html + '<span  class="ms-CommandButton-icon" title="' + eliminar.toolTip + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + eliminar.nombreBoton + '"  class="' + eliminar.iconoInActivo + '"  aria-hidden="true"></i></span>';
                                // column.columns.isActive = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Active, isCustom: true });
                            }
                        }
                        else {
                            if (isUnion) {
                                html = html + '<a title="' + eliminar.toolTip + '" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + eliminar.nombreBoton + '"  class="fas fa-trash"  aria-hidden="true"></i></a>';
                                //column.columns.isDelete = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Delete, isCustom: true });
                            }
                            else {
                                if (row.Activo)
                                    html = html + '<span class="ms-CommandButton-icon"  title="Inactivar" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + eliminar.nombreBoton + '" class="ms-Icon ms-Icon--CheckboxComposite"  aria-hidden="true"></i></span>';
                                else
                                    html = html + '<span class="ms-CommandButton-icon"  title="Activar" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + eliminar.nombreBoton + '"  class="ms-Icon ms-Icon--Checkbox"  aria-hidden="true"></i></span>';
                                // column.columns.isActive = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Active, isCustom: true });
                            }
                        }

                    }
                    else {
                        html = html + that.GetNoPermitidoGrilla(eliminar.toolTip);

                        if (isUnion)
                            column.columns.push({ columnType: DataTableColumnTypes.Delete, isCustom: true });
                        else
                            column.columns.push({ columnType: DataTableColumnTypes.Active, isCustom: true });

                    }

                }
                return html;
            }
        };

        return column;

    }
    public AdicionarColumasEditarEliminar(columns: Array<any>, Pagina: any,
        childOptions: Array<any>,
        config: any = {
            isHtmlDataTable: false,
            hideForm: false,
            isAddCrudColumns: true,
            pkName: null,
            isUnion: false,
            eliminar: { nombreBoton: null, title: null, toolTip: null, iconoActivo: null, iconoInActivo: null, index: 1, name: null },
            editar: { nombreBoton: null, title: null, toolTip: null, icono: null, index: 0, name: null },
            seleccionar: { nombreBoton: null, title: null, toolTip: null, icono: null, index: 0, name: null },
            componentName: '',
            insert: false,
            optionsIndex: 2,
            columnaEditarEliminar: false
        }, contextConfigPermisos?: any, _column?: any
    ) {

        if (Pagina.Valida && !config.isHtmlDataTable && !config.hideForm) {

            // let pkName = config.pkName;

            if (config.isAddCrudColumns) {
                if (config.columnaEditarEliminar) {

                    if (config.insert) {
                        let index = (config.editar.index !== null) ? config.editar.index : ((config.delete.index !== null) ? config.delete.index : 0);

                        columns.splice(index, 0, this.GetColumnaEditarEliminar(config.pkName, 'Acciones', config.isUnion,
                            ((Pagina.Editar && !config.isUnion) ? config.editar : null),
                            ((Pagina.Eliminar) ? config.eliminar : null), config.seleccionar, contextConfigPermisos));
                    }
                    else
                        columns.push(this.GetColumnaEditarEliminar(config.pkName, 'Acciones', config.isUnion,
                            ((Pagina.Editar && !config.isUnion) ? config.editar : null),
                            ((Pagina.Eliminar) ? config.eliminar : null), config.seleccionar, contextConfigPermisos));
                }
                else {
                    if (config.seleccionar) {
                        if (config.insert)
                            columns.splice(config.seleccionar.index, 0, this.GetColumnaSeleccionItem(config.pkName,
                                config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title, config.seleccionar.toolTip, contextConfigPermisos));
                        else
                            columns.push(this.GetColumnaSeleccionItem(config.pkName, config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title,
                                config.seleccionar.toolTip, contextConfigPermisos));
                    }
                    if (Pagina.Editar && !config.isUnion) {
                        if (config.insert)
                            columns.splice(config.editIndex, 0, this.GetColumnaEditar(config.pkName,
                                config.editar.nombreBoton, config.editar.icon, config.editar.title, config.editar.toolTip));
                        else
                            columns.push(this.GetColumnaEditar(config.pkName, config.editar.nombreBoton, config.editar.icon, config.editar.title, config.editar.toolTip));
                    }
                    if (Pagina.Eliminar) {
                        if (config.isUnion) {
                            if (config.insert)
                                columns.splice(config.deleteIndex, 0, this.GetColumnaEliminar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, config.eliminar.title, config.eliminar.toolTip, contextConfigPermisos));
                            else
                                config.columns.push(this.GetColumnaEliminar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, config.eliminar.title, config.eliminar.toolTip, contextConfigPermisos));
                        }
                        else {
                            if (config.insert)
                                columns.splice(config.deleteIndex, 0, this.GetColumnaActivar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, config.eliminar.iconoInActivo, config.eliminar.title, config.eliminar.toolTip, contextConfigPermisos));
                            else
                                columns.push(this.GetColumnaActivar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, config.eliminar.iconoInActivo, config.eliminar.title, config.eliminar.toolTip, contextConfigPermisos));
                        }
                    }
                }
            }
            else if (config.seleccionar) {
                if (config.columnaEditarEliminar) {

                    if (config.insert) {
                        let index = (config.seleccionar.index !== null) ? config.seleccionar.index : 0;
                        columns.splice(index, 0, this.GetColumnaEditarEliminar(config.pkName, 'Acciones', config.isUnion,
                            null, null, config.seleccionar));
                    }
                    else
                        columns.push(this.GetColumnaEditarEliminar(config.pkName, 'Acciones', config.isUnion,
                            null, null, config.seleccionar));
                }
                else {
                    if (config.insert)
                        columns.splice(config.seleccionar.index, 0, this.GetColumnaSeleccionItem(config.pkName,
                            config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title, config.seleccionar.toolTip));
                    else
                        columns.push(this.GetColumnaSeleccionItem(config.pkName, config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title, config.seleccionar.toolTip));
                }

            }
            if (childOptions && childOptions.length > 0) {
                if (config.insert)
                    columns.splice(config.optionsIndex, 0, this.GetChildOptionsColumn(childOptions, config.componentName, config.pkName));
                else
                    columns.push(this.GetChildOptionsColumn(childOptions, config.componentName, config.pkName));
            }
        }



    }

    public GetConfigPermisos(contextConfigPermisos?: any, data?: any): any {
        let configPermisos: any = (contextConfigPermisos) ? contextConfigPermisos.GetConfigPermisos(data) : null;
        return configPermisos;
    }



}
