import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, forwardRef, EventEmitter, Inject } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup, AbstractControl } from "@angular/forms";
import { Utilities } from "../helpers/utilities";
import { EditorAceComponent } from "../components/controls/editor-ace/editor-ace.component";
import { EditorJsonComponent } from "../components/controls/editor-json/editor-json.component";
import { ConfigWindow } from "../models/config-window";
import { ContextMenuComponent } from "ngx-contextmenu";
import { Guid } from "guid-typescript";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";
import { FormActionsThroughEmitters } from "./general.enum";
import { TranslateService } from '@ngx-translate/core';
import { StaticData } from "../helpers/static-data";

export class BaseControl implements OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;

    @Input() currentIndex = -1;
    @Input() isReactive = false
    @Input() type = "text";
    @Input() componentName = "";
    @Input() pkField: string;
    @Input() textField: string;
    @Input() items: Array<any> = [];
    @Input() controlName: string;
    @Input() classControl = "md-form";
    @Input() configData: any;
    @Input() config: any = {};
    @Input() modelo: any;
    @Input() modeloProp = "";
    @Input() control: FormControl;
    @Input() frm: FormGroup;

    @Output() onSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRefreshEmitter: EventEmitter<any> = new EventEmitter<any>();

    imagenEditorJson = "";
    imagenAEditorAce = "";
    ctr: AbstractControl;

    @Input() configPermisos: any;
    constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities,
        @Inject(forwardRef(() => DomSanitizer)) public sanitizer: DomSanitizer, public translate: TranslateService) {  this.translate.setDefaultLang(StaticData.Language);}


    ngOnInit() {


        this.imagenEditorJson = this.utility.GetPathImages(this.utility.GetNodeType("EditorJson"));
        this.imagenAEditorAce = this.utility.GetPathImages(this.utility.GetNodeType("EditorAce"));
        this.InternalOnInit();
        this.OnInit();
    }

    InternalOnInit() {


        //this.type = (!this.type) ? 'text' : this.type;
        this.classControl = (!this.classControl) ? "md-form" : this.classControl;
        if (this.config) {
            this.controlName = (!this.controlName) ? this.config.Name : this.controlName;

            if (!this.type) {

                if (this.config.ControlType == "NumberBox")
                    this.type = "number";
                else if (this.config.ControlType == "TextBox")
                    this.type = "text";

            }
        }
        else
            this.type = (!this.type) ? "text" : this.type;
    }
    public OnInit() {


    }

    SetDataResult(dataResult) {

        const currentValue = (this.config.SourceMappingProperty) ? dataResult.componentInstance.GetValueProperty(this.config.SourceMappingProperty) : dataResult.componentInstance.PkValue;
        if (this.config.ControlType == "ComboBox" && currentValue && !this.items.find(x => { return x[this.pkField] == currentValue; }))
            this.items.push(dataResult.componentInstance.modelo);

        this.ctr.setValue(currentValue);

        this.OnChangeEvent(dataResult.componentInstance.modelo);
    }

    VerBuscar() {

        if (this.config.GetConfigPermisos) {
            this.configPermisos = this.config.GetConfigPermisos();
        }
        const modeloTemp = {
            configPermisos: this.configPermisos,
            config: this.config.SearchTableConfig,
            limpiarInternamente: true
        };

        const componentName = "DataTableComponent";
        const config: ConfigWindow = new ConfigWindow();
        config.classWindow = "Info";
        config.titleWindow = "Búsqueda de " + this.config.Label;
        config.returnInstance = true;
        config.modal = false;
        config.width = 600;
        config.height = 450;
        config.viewOk = false;
        config.cancel = "Cerrar";
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;

        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {


                //if (dataResult.componentInstance instanceof DataTableComponent) {
                if (this.config.SearchTableSelectionType == "OnSelection") {
                    dataResult.componentInstance.postEditEmitter.subscribe(result => {
                        this.SetDataResult(dataResult);

                    });
                    dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
                        if (result.Action === FormActionsThroughEmitters.ClearModel) {
                            this.ctr.setValue(null);
                            this.OnChangeEvent(null);
                        }
                    });
                }

                dataResult.componentInstance.loadedGridEmitter.subscribe(result => {

                    if (result.data && this.config.ControlType == "ComboBox")
                        this.items = result.data;
                });
                //}
                dataResult.onClosePromise.then(dataResultOnClose => {
                    if (this.config.SearchTableSelectionType == "OnClose") {

                        this.SetDataResult(dataResult);

                    }

                    dataResult.componentInstance.ngOnDestroy();
                    dataResult.componentInstance = null;
                });

            });
    }
    ngAfterViewInit() {


        if (this.config && this.frm &&  this.frm.controls[this.config.Name]) {
            const currentTarget = document.getElementById(this.config.Name);
            if (this.config.DisabledPaste)
                currentTarget.onpaste = e => e.preventDefault();
            if (this.config.DisabledCut)
                currentTarget.oncut = e => e.preventDefault();
            if (this.config.DisabledCopy)
                currentTarget.oncopy = e => e.preventDefault();
        }

        if (this.isReactive && this.frm && this.config && this.frm.controls[this.config.Name]) {
            this.ctr = this.frm.controls[this.config.Name];
            this.ctr.valueChanges.subscribe(value => {

                this.value = value;
            });
        }
        /*   
        if (this.frm.controls[this.config.Name]){
          let  ctr =this.frm.controls[this.config.Name];
          ctr.markAsTouched();
          ctr.markAsPristine();
        } */

        //this.control =this.frm.get(this.config.Name);



        // const ctr = new FormControl({ value: this.config.Value, disabled: this.config.disabled });
        //this.frm.addControl(this.config.Name,ctr);
    }
    public Refresh() {
        try {
            if (this.onRefreshEmitter.observers.length > 0)
                this.onRefreshEmitter.emit({ controlName: this.controlName, config: this.config });
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, controlName: this.controlName, config: this.config });
        }
    }

    public VerEditor(tipo: any) {
        let editorTipo = "EditorJsonComponent";
        if (tipo == 1) {
            editorTipo = "EditorJsonComponent";
        }
        else
            editorTipo = "EditorAceComponent";
        const config: ConfigWindow = new ConfigWindow();
        config.classWindow = "info";
        config.titleWindow = "Editor";
        config.returnInstance = false;
        config.modal = false;
        config.width = 600;
        config.height = 500;
        const windowModel: any = {
            modelo: this.modelo
        };

        this.utility.OpenWindow(editorTipo,
            config, windowModel).then(dataResult => {


                dataResult.onClosePromise.then(dataResultOnClose => {

                    if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
                        dataResultOnClose.modelo) {
                        this.modelo = dataResultOnClose.modelo;
                    }
                    else {


                    }
                });

            });

    }
    /**
       * clearInput
       * @param inputId Element ID for input to clear 
       */
    public clearInput(inputId) {

        if (this.isReactive && this.ctr) {
            this.ctr.setValue(this.config.Value);
        }
        else {
            this.value = this.config.Value;
        }
        $("#" + inputId).val("");
    }
    public OnChangeEvent(item: any, data: any = null) {
        try {


            if (this.onSelectedEmitter.observers.length > 0)
                this.onSelectedEmitter.emit({ item: item, controlName: this.controlName, config: this.configData, data: data, isControlEvent: true });
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, controlName: this.controlName, config: this.configData });
        }
    }
    public OnKeyUpEvent(event) {
        
        //event.target.value = event.target.value.toUpperCase();

        if (this.ctr.value && this.config.TextFormat)
            this.ctr.setValue(this.FormatoTexto(this.ctr.value, this.config.TextFormat));
    }


    public FormatoTexto(value: any, textFormat: string) {
        switch (textFormat) {
            case 'Normal':
            case '':

                break;
            case 'Mayusculas':
                value = value.toUpperCase();
                break;
            case 'Minusculas':
                value = value.toLowerCase();
                break;
            case 'PascalCase':
                value = this.utility.ToPascalCase(value);
                break;
            case 'UpperCamelCase':
                value = this.utility.ToUpperCamelCase(value);
                break;
            // case 'CamelCase':
            //     value = value.ToCamelCase();
            //     break;
        }

        return value;
    }

   
    onChange: any = () => { };
    onTouched: any = () => { };

    get value() {
        return this.modelo;
    }

    set value(val) {
        this.modelo = val;
        this.onChange(val);
        this.onTouched();
        // this.SetFoco();

    }


    updateChanges() {

        this.onChange(this.modelo);
        //console.log('updateChanges ' + this.controlName)
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    /*  writeValue(value) {
   
       this.modelo = value;
       console.log('writeValue interno ' + this.controlName)
     } */

    writeValue(value) {

        //  console.log('writeValue interno ' + this.controlName)

        this.modelo = value;
        if (!value && this.config)
            this.modelo = this.config.Value;
        if (this.modelo !== undefined && this.modelo !== null && this.modelo !== "")

            if (this.config && this.config.Value)
                this.updateChanges();

    }



    get IsValid() {

        return this.GetPropFromForm("valid", true);
    }

    get IsDirty() {
        return this.GetPropFromForm("dirty", true);

    }
    get IsTouched() {
        return this.GetPropFromForm("touched", true);

    }
    get IsInvalid() {
        return this.GetPropFromForm("invalid", false);
    }
    get IsPristine() {
        return this.GetPropFromForm("pristine", true);

    }

    get IsGeneralInvalid() {

        return (this.IsInvalid && (this.IsDirty || this.IsTouched));
    }
    get IsEmailError() {
        return this.GetErrorFromForm("email", false);

    }
    get IsPatternError() {
        return this.GetErrorFromForm("pattern", false);

    }
    get IsRequiredError() {
        return this.GetErrorFromForm("required", false);

    }
    get IsMinlengthError() {
        return this.GetErrorFromForm("minlength", false);

    }
    get IsMaxlengthError() {

        return this.GetErrorFromForm("maxlength", false);

    }

    get IsMinError() {
        return this.GetErrorFromForm("min", false);

    }
    get IsMaxError() {

        return this.GetErrorFromForm("max", false);

    }
    get GetCustomError() {
        return this.GetErrorFromForm("custom", null);

    }
    GetControlValue(defaultValue: any = "") {
        if (this.frm) {
            if (!this.frm.controls[this.config.Name]) {
                //console.error(this.config.Name + ' Para error, Prop:' + prop);
                return defaultValue;
            }
            else {
                const value = this.frm.controls[this.config.Name];
                // console.info(this.config.Name + ' Para error, Prop:' + prop + ', valor:' + value);
                return (value.value) ? value.value : "";
            }
        }

        return defaultValue;
    }
    GetErrorFromForm(prop: string, defaultValue: any) {
        if (this.frm) {
            if (!this.frm.controls[this.config.Name]) {
                //console.error(this.config.Name + ' Para error, Prop:' + prop);
            }
            else {
                if (!this.frm.controls[this.config.Name].errors) {
                    // console.error(this.config.Name + ' Para Not error, Prop:' + prop);
                }
                else {
                    const value = this.frm.controls[this.config.Name].errors[prop];
                    // console.info(this.config.Name + ' Para error, Prop:' + prop + ', valor:' + value);
                    return value;
                }
            }
        }

        return defaultValue;
    }
    GetPropFromForm(prop: string, defaultValue: any) {
        if (this.frm) {
            if (!this.frm.controls[this.config.Name]) {
                //console.error(this.config.Name + ' Para Validacion, Prop:' + prop);
            }
            else {
                const value = this.frm.controls[this.config.Name][prop];
                //console.info(this.config.Name + ' Para Validacion, Prop:' + prop + ', valor:' + value);
                return value;
            }
        }

        return defaultValue;
    }

}
