import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

import { NavItem } from "../menu-item/nav-item";
import { Router } from "@angular/router";

declare let $: any;
@Component({
    selector: "app-menu-item2",
    templateUrl: "./menu-item2.component.html",
    styleUrls: ["./menu-item2.component.scss"]
})
export class MenuItem2Component implements OnInit {
    @Input() items: NavItem[];
    @Input() identificador: any;
    @Input() levelInMenu: number;
    @ViewChild("childMenu", { static: true }) public childMenu;
    @Output() navegarEmitter: EventEmitter<any> = new EventEmitter<any>();

    newMarginLeft: number;

    public Navegar(item: any, event: any) {        
        this.subMenu(event);

        this.navegarEmitter.emit(item);
    }

    // public Menu(event){
    //   
    //   if($(event.currentTarget).hasClass('dropdown-toggle')){
    //     if($(event.currentTarget).attr('aria-expanded') != "true"){
    //           if($(event.currentTarget).parents('.dropdown').children('a.dropdown-toggle').attr('aria-expanded') == 'true'){
    //             console.log('hola')
    //           }
    //           else{
    //             $('a.dropdown-toggle.nav-link').attr('aria-expanded','false');
    //             $('.show').removeClass('show')
    //           }
    //         }
    //         else{
    //           console.log('prueba')
    //         }
    //   }
    //   console.log($(event.target));
    // }

    // public MenuInPos(eventElem){
    //   console.log("menuInPos target: ", $(eventElem.target).parentsUntil('div'));
    //   $(".sidebar__text-inpos").toggleClass("sidebar__text-inpos");
    //   $(".inpos").toggleClass("inpos");
    //   $($(eventElem.target).parentsUntil('div')[$(eventElem.target).parentsUntil('div').length-2]).toggleClass("inpos");
    //   if($('span.active > a')["0"].hash){
    //     $($('span.active > a')["0"].hash).collapse("toggle");
    //   }
    //   $(".active").toggleClass("active");
    //   $(".sidebar__text").toggleClass("sidebar__text-inpos");
    // }

    public Menu(event) {        
        $('.sidebar').removeClass('sidebar__openShorCut')
        if ($(event.currentTarget).attr("aria-expanded") == "true") {
            const targetAttr = $(event.currentTarget).attr("href");
            setTimeout(() => {
                //$(targetAttr).collapse('show');
                $(targetAttr).collapse("hide");
                console.log("collapse mi2: ", $(targetAttr));
            }, 400);

        }
    }

    public subMenu(event) {        
        console.log("target: ", $(event.currentTarget));
        if (!$(event.currentTarget).hasClass("dropdown-toggle")) {
            // reset styles
            //$(".sidebar__text").css('font-weight','400');
            $(".nav-link-child").removeClass("menu__inpos");
            $(".dropdown-toggle").removeClass("menu__inpos-parent");

            $(event.currentTarget).addClass("menu__inpos");
            $(event.currentTarget).parents(".dropdown").children(".dropdown-toggle").addClass("menu__inpos-parent");
            if ($(event.currentTarget).parents(".dropdown").children(".dropdown-toggle").attr("aria-expanded") == "true") {
                console.log("is true!!! jojo");
            }
            //* COLLAPSE MENU
            $('.sidebar').removeClass('sidebar__open');
            // $('.inpos').removeClass('inpos');
            // $('.itemsactive').removeClass('itemsactive');
            // $('lipadreselect').remove('lipadreselect');
            // let padre = $(event.currentTarget).parents('.sidebar__item');
            // padre = padre.addClass('inpos');
            // $(event.currentTarget).parent().parent().addClass('lipadreselect');
            // $(event.currentTarget).parent().addClass('select');
            // $(event.currentTarget).css('font-weight','700');
            // $(event.currentTarget).parent().parents('.show').addClass('itemsactive');
            // $(event.currentTarget).siblings().css('font-weight','700');
        }
    }

    constructor(public router: Router) {
    }

    ngOnInit() { //se carga 
        this.newMarginLeft = Number(this.levelInMenu + 15);

    }
    ngAfterViewInit() {
        // $('a.dropdown-toggle').on('click', function(){
        //   
        //   if($(this).attr('aria-expanded') != "true"){
        //     if($(this).parents('.dropdown').children('a.dropdown-toggle').attr('aria-expanded') == 'true'){
        //       console.log('hola')
        //     }
        //     else{
        //       $('a.dropdown-toggle.nav-link').attr('aria-expanded','false');
        //       $('.show').removeClass('show')
        //     }
        //   }
        //   else{
        //     console.log('prueba')
        //   }

        // })
    } //se ejecuta cuando carga el componente
    getImgMarginLeft() {
        //   $('.sidebar .nav-link').not('.sidebar .nav-link.dropdown-toggle').on('click', function(){
        //     $('.inpos').removeClass('inpos');
        //     $('.itemsactive').removeClass('itemsactive');
        //     $('lipadreselect').remove('lipadreselect')
        //     $(this).children().css('font-weight','100')
        //     var padre = $(this).parents('.sidebar__item');
        //     padre = padre.addClass('inpos');
        //     $(this).parent().parent().addClass('lipadreselect')
        //     $(this).addClass('select');
        //     $(this).children().css('font-weight','900')
        //     $(this).parents('.show').addClass('itemsactive')
        //     //css({"background":"white", "border-radius":"5px"});
        // })
        return Number(this.levelInMenu + 10);

    }


}
