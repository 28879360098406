import { Component, OnInit,Input,Output,EventEmitter,forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import { ConfigBaseComponent} from '../../.././../models/config-base-component';

@Component({
  selector: 'app-config-child-page',
  templateUrl: './config-child-page.component.html',
  styleUrls: ['./config-child-page.component.css']
})
export class ConfigChildPageComponent extends ConfigBaseComponent implements OnInit {

 
}
