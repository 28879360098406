import { Component, OnInit, AfterViewInit,AfterViewChecked, Input,Output, forwardRef ,EventEmitter,Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import { Utilities } from '../helpers/utilities';

export class ObservacionesBase implements OnInit,AfterViewInit,AfterViewChecked {


    @Input() public componentName:string='';
    @Input() public Estilos:any;
    @Input() public config: any={Activo:true};
    public modelo: any;

  constructor(public utility:Utilities) { }

  ngOnInit() {
  }

  ngAfterViewInit() {


  }

  ngAfterViewChecked() {
  
}

}
