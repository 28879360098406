import { Component, OnInit, Input } from '@angular/core';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-image-box',
  templateUrl: './image-box.component.html',
  styleUrls: ['./image-box.component.scss']
})
export class ImageBoxComponent implements OnInit {

  @Input() isDesigner: boolean = false;
  @Input() subControl: any;
  @Input() name: string = "ImageBox_" + Guid.create().toString();
  constructor() { }

  ngOnInit() {
    if (this.subControl && this.subControl.Name)
      this.name = this.subControl.Name;
  }

}
