import { Component, OnInit } from "@angular/core";

import { SolicitudRadicadoFacturaComponent } from "../solicitud-radicado-factura/solicitud-radicado-factura.component";

@Component({
    selector: "app-solicitud-radicado-factura-oficio",
    templateUrl: "./solicitud-radicado-factura-oficio.component.html",
    styleUrls: ["./solicitud-radicado-factura-oficio.component.css"]
})
export class SolicitudRadicadoFacturaOficioComponent extends SolicitudRadicadoFacturaComponent {

    public OnInitEnd() {
        this.postSaveEmitter.subscribe(modelo => {
            
            this.utility.VerModalOk("Se asignó un oficio al Radicado de Factura con código: " + "\n\r" + modelo.data.data.Codigo);
        });
    }
}
