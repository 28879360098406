import { Guid } from "guid-typescript";

export class ControlContainerBasico {
  Id?: string = Guid.create().toString();
  Name?: string;
  ControlType?: string = "GridView";
  Title?: string = "";
  Label?: string = "";
  ColSpan?: number = 12;
  Type?: string = "Container";
  Activo?: Boolean = true;
  Controls: any = [];
  Actions: any = [];
  Permissions: any = [];
  Configs: any = [];
  Helps: any = [];
}
export class ControlPaginaBaseBasico {
  Id?: string = Guid.create().toString();
  IdPadre?: string = null;
  IdPagina?: number;
  Name?: string;
  ControlType?: string = "GridView";
  Title?: string = "";
  Label?: string = "";
  ColSpan?: number = 12;
  KeyCache?: string = null;
  ConfigCache?: string = null;
  ConfigControl?: any = null;
  ConfigSearchControl?: any = null;
  Type?: string = "DatTable";
  Activo?: boolean = true;
  Controls: any = [];
  Actions: any = [];
  Permissions: any = [];
  Configs: any = [];
  Query?: any;
  TableName?: string;
  IsMultiple?: boolean = false;
  Helps: any = [];
  IdConsecutivo?: number = 0;
  IdTipoConsecutivo?: number = 0;

  //UniqueKeys: Array<UniqueKeyModel> = [];
  public ActionFunctionValidators: any = {};
}
export class ControlPaginaBase extends ControlPaginaBaseBasico {
  Name?: string;
  IsPk?: boolean = false;
  MinLength?: number = 0;
  MaxLength?: number = 100;
  Min?: number = 0;
  Max?: number = 0;
  Required?: boolean = false;
  Pattern?: any;
  ControlType?: string = "TextBox";
  DataType?: string = "String";
  GeneralDataType?: string = "String";
  Title?: string = "";
  Placeholder?: string = "";
  Label?: string = "";

  Order?: number = 0;
  Rows?: number = null;
  Cols?: number = null;
  KeyCache?: string = null;
  ConfigCache?: string = null;
  ViewSearchControl?: boolean = false;
  ViewSearchButton?: boolean = false;
  Type?: string = "text";
  Mask?: string = null;
  Value?: any = null;
}

export class ControlPagina extends ControlPaginaBase {
  Id?: string = Guid.create().toString();
  IdPadre?: string = null;
  IdPagina?: number;
  Name?: string;
  MinLength?: number = 0;
  MaxLength?: number = 100;
  Min?: number = 0;
  Max?: number = 0;
  Required?: boolean = false;
  Pattern?: any;
  ControlType?: string = "TextBox";
  DataType?: string = "String";
  GeneralDataType?: string = "String";
  Identity?: boolean = false;
  IsFk?: boolean = false;
  ReferenceFK?: any;
  ModelContainer?: string = "modelo";
  Title?: string = "";
  Placeholder?: string = "";
  Label?: string = "";

  Order?: number = 0;
  Rows?: number = null;
  Cols?: number = null;
  InFullTextCatalog?: boolean = false;
  ConfigCache?: string = null;
  ViewSearchControl?: boolean = false;
  ViewSearchButton?: boolean = false;
  HtmlEditor?: boolean = false;
  MultilineEditor?: boolean = false;
  Multiline?: boolean = false;
  Type?: string = "text";
  IdQuery?: number = null;
  Mask?: string = null;
  Value?: any = null;
  IdPaginaAdministracion?: number = null;
  ViewAdminButton?: boolean = false;
}
export class UniqueKeyField {
  public Label = "";
  public Name = "";
  public Value?: any;
}
export class UniqueKeyModel {
  public Message = "";
  public Name = "";
  public Fields: Array<UniqueKeyField> = [];
}
export enum ValidateUserAndPagesTypes {
  All = 1,
  ValidateUser,
  FindPage,
}
/*
export const documentTypes: Array<any> = [
	{ ObjType: 'xlsx', Icon: 'excel.png', NodeText: 'Excel', idDocument: true },
{ ObjType: 'xlx', Icon: 'excel.png', NodeText: 'Excel', idDocument: true },
{ ObjType: 'docx', Icon: 'word.png', NodeText: 'Word', idDocument: true },
{ ObjType: 'doc', Icon: 'word.png', NodeText: 'Word', idDocument: true },
{ ObjType: 'xml', Icon: 'xml.png', NodeText: 'Xml', idDocument: true },
{ ObjType: 'html', Icon: 'html.png', NodeText: 'Html', idDocument: true },
{ ObjType: 'htm', Icon: 'html.png', NodeText: 'Html', idDocument: true },
{ ObjType: 'json', Icon: 'json.png', NodeText: 'Json', idDocument: true },
{ ObjType: 'txt', Icon: 'txt.png', NodeText: 'Txt', idDocument: true },
{ ObjType: 'csv', Icon: 'csv.png', NodeText: 'Csv', idDocument: true },
{ ObjType: 'pdf', Icon: 'pdf.png', NodeText: 'Pdf', idDocument: true },
{ ObjType: 'png', Icon: 'png.png', NodeText: 'Png', idDocument: true },
{ ObjType: 'jpg', Icon: 'jpg.png', NodeText: 'Jpg', idDocument: true },
{ ObjType: 'bmp', Icon: 'bmp.png', NodeText: 'Bmp', idDocument: true },

]; */
export const nodeTypes: Array<any> = [
  { ObjType: "Root", Icon: "project.png", NodeText: "BEXT_DOC" },
  {
    ObjType: "AplicacionesPermitidas",
    Icon: "aplicaciones-permitidas.png",
    NodeText: "Aplicaciones Permitidas",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Roles",
    Icon: "roles.png",
    NodeText: "Roles",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Grupos",
    Icon: "grupos.png",
    NodeText: "Grupos",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Formularios",
    Icon: "forms.png",
    NodeText: "Formularios",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Querys",
    Icon: "querys.png",
    NodeText: "Querys",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Reglas",
    Icon: "reglas.png",
    NodeText: "Reglas",
    GroupType: "Contenedor",
  },
  {
    ObjType: "AreasEmpresa",
    Icon: "areas-empresa.png",
    NodeText: "Areas Empresa",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Series",
    Icon: "series.png",
    NodeText: "Series",
    GroupType: "Container",
  },
  {
    ObjType: "SubSeries",
    Icon: "sub-series.png",
    NodeText: "Sub Series",
    GroupType: "Contenedor",
  },
  {
    ObjType: "TiposDocumentales",
    Icon: "tipos-doccumentales.png",
    NodeText: "Tipos Documentales",
    GroupType: "Contenedor",
  },
  {
    ObjType: "TablasRetencionDocumental",
    Icon: "tablas-retencion.png",
    NodeText: "Tablas Retencion Documental",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Expedientes",
    Icon: "expedientes.png",
    NodeText: "Expedientes",
    GroupType: "Contenedor",
  },
  {
    ObjType: "TiposSolicitud",
    Icon: "tipos-solicitud.png",
    NodeText: "Tipo Solicitud",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Carpetas",
    Icon: "carpetas.png",
    NodeText: "Carpetas",
    GroupType: "Contenedor",
  },
  {
    ObjType: "EstadosSolicitud",
    Icon: "estados-solicitud.png",
    NodeText: "Flujo",
    GroupType: "Contenedor",
  },
  {
    ObjType: "UbicacionesExpediente",
    Icon: "ubicaciones-expediente.png",
    NodeText: "Ubicaciones Expediente",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Usuarios",
    Icon: "usuarios.png",
    NodeText: "Usuarios",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Permisos",
    Icon: "permisos.png",
    NodeText: "Permisos",
    GroupType: "Contenedor",
  },
  {
    ObjType: "Responsables",
    Icon: "responsables.png",
    NodeText: "Responsables",
    GroupType: "Contenedor",
  },
  {
    ObjType: "DocumentosExpediente",
    Icon: "documentos-expediente.png",
    NodeText: "Documentos Expediente",
    GroupType: "Contenedor",
  },
  {
    ObjType: "TiposDocumento",
    Icon: "tipos-documento.png",
    NodeText: "Tipos Documento",
    GroupType: "Contenedor",
  },
  {
    ObjType: "PalabrasClave",
    Icon: "palabras-clave.png",
    NodeText: "Palabras Clave",
    GroupType: "Contenedor",
  },
  // { ObjType: "pptx", Icon: "icos/doc-xls.svg", NodeText: "Excel", GroupType: "TipoArchivo", idDocument: true },
  // { ObjType: "ppt", Icon: "icos/doc-xls.svg", NodeText: "Excel", GroupType: "TipoArchivo", idDocument: true },

  {
    ObjType: "msg",
    Icon: "icos/doc-msg.svg",
    NodeText: "Msg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },

  {
    ObjType: "ai",
    Icon: "icos/doc-ai.svg",
    NodeText: "Ai",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "avi",
    Icon: "icos/doc-avi.svg",
    NodeText: "Avi",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "bin",
    Icon: "icos/doc-bin.svg",
    NodeText: "Bin",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "bmp",
    Icon: "bmp.png",
    NodeText: "Bmp",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "c",
    Icon: "icos/doc-c.svg",
    NodeText: "C",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "cpl",
    Icon: "icos/doc-cpl.svg",
    NodeText: "Cpl",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "cpp",
    Icon: "icos/doc-cpp.svg",
    NodeText: "Cpp",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "css",
    Icon: "icos/doc-css.svg",
    NodeText: "Css",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "csv",
    Icon: "icos/doc-csv.svg",
    NodeText: "Csv",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "dat",
    Icon: "icos/doc-dat.svg",
    NodeText: "Dat",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "dbf",
    Icon: "icos/doc-dbf.svg",
    NodeText: "Dbf",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "doc",
    Icon: "icos/doc-word.svg",
    NodeText: "Word",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "docx",
    Icon: "icos/doc-word.svg",
    NodeText: "Word",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "e01",
    Icon: "icos/doc-e01.svg",
    NodeText: "E01",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "e02",
    Icon: "icos/doc-e02.svg",
    NodeText: "E02",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "eml",
    Icon: "icos/doc-eml.svg",
    NodeText: "Eml",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "exe",
    Icon: "icos/doc-exe.svg",
    NodeText: "Exe",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "flac",
    Icon: "icos/doc-flac.svg",
    NodeText: "Flac",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "gif",
    Icon: "icos/doc-gif.svg",
    NodeText: "Gif",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "gml",
    Icon: "icos/doc-gml.svg",
    NodeText: "Gml",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "gz",
    Icon: "icos/doc-gz.svg",
    NodeText: "Gz",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "h",
    Icon: "icos/doc-h.svg",
    NodeText: "H",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "hpp",
    Icon: "icos/doc-hpp.svg",
    NodeText: "Hpp",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "htm",
    Icon: "html.png",
    NodeText: "Html",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "html",
    Icon: "html.png",
    NodeText: "Html",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "jfi",
    Icon: "icos/doc-jfi.svg",
    NodeText: "Jfi",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "jfif",
    Icon: "icos/doc-jfif.svg",
    NodeText: "Jfif",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "jif",
    Icon: "icos/doc-jif.svg",
    NodeText: "Jif",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "jp2",
    Icon: "icos/doc-jp2.svg",
    NodeText: "Jp2",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "jpg2",
    Icon: "icos/doc-jpg2.svg",
    NodeText: "Jpg2",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "jpg",
    Icon: "icos/doc-jpg.svg",
    NodeText: "Jpg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "jpeg",
    Icon: "icos/doc-jpg.svg",
    NodeText: "Jpg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "js",
    Icon: "javascript.png",
    NodeText: "js",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "json",
    Icon: "json.png",
    NodeText: "Json",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "m1a",
    Icon: "icos/doc-m1a.svg",
    NodeText: "M1a",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "m2a",
    Icon: "icos/doc-m2a.svg",
    NodeText: "M2a",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "m1v",
    Icon: "icos/doc-m1v.svg",
    NodeText: "M1v",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "m4v",
    Icon: "icos/m4v.svg",
    NodeText: "M4v",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mbox",
    Icon: "icos/doc-mbox.svg",
    NodeText: "Mbox",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mbx",
    Icon: "icos/doc-mbx.svg",
    NodeText: "Mbx",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mdbx",
    Icon: "icos/doc-mdbx.svg",
    NodeText: "Mdbx",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mj2",
    Icon: "icos/doc-mj2.svg",
    NodeText: "Mj2",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mj2p",
    Icon: "icos/doc-mj2p.svg",
    NodeText: "Mj2p",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mk3d",
    Icon: "icos/doc-mk3d.svg",
    NodeText: "Mk3d",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mks",
    Icon: "icos/doc-mks.svg",
    NodeText: "Mks",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mkv",
    Icon: "icos/doc-mkv.svg",
    NodeText: "Mkv",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mng",
    Icon: "icos/doc-mng.svg",
    NodeText: "Mng",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mov",
    Icon: "icos/doc-mov.svg",
    NodeText: "Mov",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mp1",
    Icon: "icos/doc-mp1.svg",
    NodeText: "Mp1",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mp2",
    Icon: "icos/doc-mp2.svg",
    NodeText: "Mp2",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mp3",
    Icon: "icos/mp3.svg",
    NodeText: "Mp3",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mp4",
    Icon: "icos/mp4.svg",
    NodeText: "Mp4",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mpa",
    Icon: "icos/doc-mpa.svg",
    NodeText: "Mpa",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mpeg",
    Icon: "icos/doc-mpeg.svg",
    NodeText: "Mpeg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mpg",
    Icon: "icos/doc-mpg.svg",
    NodeText: "Mpg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mpv",
    Icon: "icos/doc-mpv.svg",
    NodeText: "Mpv",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mts",
    Icon: "icos/doc-mts.svg",
    NodeText: "Mts",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "mxf",
    Icon: "icos/doc-mxf.svg",
    NodeText: "Mxf",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "odp",
    Icon: "icos/doc-odp.svg",
    NodeText: "Odp",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "ods",
    Icon: "icos/doc-ods.svg",
    NodeText: "Ods",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "odt",
    Icon: "icos/doc-odt.svg",
    NodeText: "Odt",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "oga",
    Icon: "icos/doc-oga.svg",
    NodeText: "Oga",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "ogg",
    Icon: "icos/doc-ogg.svg",
    NodeText: "Ogg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "ogm",
    Icon: "icos/doc-ogm.svg",
    NodeText: "Ogm",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "ogv",
    Icon: "icos/doc-ogv.svg",
    NodeText: "Ogv",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "ogt",
    Icon: "icos/doc-ogt.svg",
    NodeText: "Ogt",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "ogx",
    Icon: "icos/doc-ogx.svg",
    NodeText: "Ogx",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "opus",
    Icon: "icos/doc-opus.svg",
    NodeText: "Opus",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "pages",
    Icon: "icos/doc-pages.svg",
    NodeText: "Pages",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "pdf",
    Icon: "icos/doc-pdf.svg",
    NodeText: "Pdf",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "php",
    Icon: "icos/doc-php.svg",
    NodeText: "Php",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "png",
    Icon: "icos/doc-png.svg",
    NodeText: "Png",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "ppt",
    Icon: "icos/doc-ppt.svg",
    NodeText: "Ppt",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "pptx",
    Icon: "icos/doc-pptx.svg",
    NodeText: "PowerPoint",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "psd",
    Icon: "icos/doc-psd.svg",
    NodeText: "Psd",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "rar",
    Icon: "icos/zip.svg",
    NodeText: "Rar",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "sbn",
    Icon: "icos/doc-sbn.svg",
    NodeText: "Sbn",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "shp",
    Icon: "icos/doc-shp.svg",
    NodeText: "Shp",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "shx",
    Icon: "icos/doc-shx.svg",
    NodeText: "Shx",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "siard",
    Icon: "icos/doc-siard.svg",
    NodeText: "Siard",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "spx",
    Icon: "icos/doc-spx.svg",
    NodeText: "Spx",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "sql",
    Icon: "sql.png",
    NodeText: "sql",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "svg",
    Icon: "icos/doc-svg.svg",
    NodeText: "Svg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "svgz",
    Icon: "icos/doc-svgz.svg",
    NodeText: "Svgz",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "tiff",
    Icon: "icos/tiff.svg",
    NodeText: "Tiff",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "txt",
    Icon: "icos/doc-txt.svg",
    NodeText: "Txt",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "warc",
    Icon: "icos/doc-warc.svg",
    NodeText: "Warc",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "wav",
    Icon: "icos/doc-wav.svg",
    NodeText: "Wav",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "webm",
    Icon: "icos/doc-webm.svg",
    NodeText: "Webm",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "wma",
    Icon: "icos/doc-wma.svg",
    NodeText: "Wma",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "wmv",
    Icon: "icos/doc-wmv.svg",
    NodeText: "Wmv",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "xlsx",
    Icon: "icos/doc-xls.svg",
    NodeText: "Excel",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "xls",
    Icon: "icos/doc-xls.svg",
    NodeText: "Excel",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "xml",
    Icon: "icos/xml.svg",
    NodeText: "Xml",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "xpdl",
    Icon: "icos/doc-xpdl.svg",
    NodeText: "Xpdl",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "xpm",
    Icon: "icos/doc-xpm.svg",
    NodeText: "Xpm",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "zip",
    Icon: "icos/zip.svg",
    NodeText: "Zip",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "cgm",
    Icon: "icos/doc-cgm.svg",
    NodeText: "Cgm",
    GroupType: "TipoArchivo",
    idDocument: true,
  },
  {
    ObjType: "odg",
    Icon: "icos/doc-odg.svg",
    NodeText: "Odg",
    GroupType: "TipoArchivo",
    idDocument: true,
  },

  {
    ObjType: "AplicacionPermitida",
    Icon: "aplicacion-permitida.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Role",
    Icon: "role.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Grupo",
    Icon: "grupo.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Formulario",
    Icon: "form.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Query",
    Icon: "query.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Regla",
    Icon: "regla.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "AreaEmpresa",
    Icon: "area-empresa.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Serie",
    Icon: "serie.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "SubSerie",
    Icon: "sub-serie.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "TipoDocumental",
    Icon: "tipo-documental.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "TablaRetencionDocumental",
    Icon: "tabla-retencion.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "DocumentoGeneral",
    Icon: "documents.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Expediente",
    Icon: "folder.svg",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "TipoSolicitud",
    Icon: "tipo-solicitud.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Carpeta",
    Icon: "ico-folder.svg",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Cuaderno",
    Icon: "ico-libros.svg",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "EstadoSolicitud",
    Icon: "estado-solicitud.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "UbicacionExpediente",
    Icon: "ubicacion-expediente.png",
    NodeText: "Ubicacion Expediente",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "AgrupadoCarpeta",
    Icon: "agrupado-carpeta.png",
    NodeText: "Agrupado Carpeta",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "Usuario",
    Icon: "usuario.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  { ObjType: "Nulo", Icon: "nulo.png", NodeText: "", GroupType: "Accion" },
  // { ObjType: "Editar", Icon: "editar.png", NodeText: "", GroupType: "Accion" },
  { ObjType: "Editar", Icon: "Edit", NodeText: "", GroupType: "Accion" },
  {
    ObjType: "Eliminar",
    Icon: "eliminar.png",
    NodeText: "",
    GroupType: "Accion",
  },
  { ObjType: "Clonar", Icon: "clonar.png", NodeText: "", GroupType: "Accion" },
  {
    ObjType: "Guardar",
    Icon: "guardar.png",
    NodeText: "",
    GroupType: "Accion",
  },
  {
    ObjType: "Importar",
    Icon: "importar.png",
    NodeText: "",
    GroupType: "Accion",
  },
  {
    ObjType: "Exportar",
    Icon: "exportar.png",
    NodeText: "",
    GroupType: "Accion",
  },
  {
    ObjType: "Consultar",
    Icon: "consultar.png",
    NodeText: "",
    GroupType: "Accion",
  },
  {
    ObjType: "Filtrar",
    Icon: "filtrar.png",
    NodeText: "",
    GroupType: "Accion",
  },
  { ObjType: "Ver", Icon: "ver.png", NodeText: "", GroupType: "Accion" },
  {
    ObjType: "AdminPermisos",
    Icon: "admin-permisos.png",
    NodeText: "",
    GroupType: "Accion",
  },
  {
    ObjType: "VerPermisos",
    Icon: "ver-permisos.png",
    NodeText: "",
    GroupType: "Accion",
  },
  {
    ObjType: "Descargar",
    Icon: "descargar.png",
    NodeText: "",
    GroupType: "Accion",
  },
  {
    ObjType: "CambiarDoc",
    Icon: "refresh.png",
    NodeText: "",
    GroupType: "Accion",
  },
  { ObjType: "CrearDoc", Icon: "crear.png", NodeText: "", GroupType: "Accion" },
  // { ObjType: "Activo", Icon: "activo.png", NodeText: "activo.png", GroupType: "Accion" },
  {
    ObjType: "Activo",
    Icon: "CheckboxCompositeReversed",
    NodeText: "CheckboxCompositeReversed",
    GroupType: "Accion",
  },
  // { ObjType: "Inactivo", Icon: "inactivo.png", NodeText: "inactivo.png", GroupType: "Accion" },
  {
    ObjType: "Inactivo",
    Icon: "delete.png",
    NodeText: "CheckboxComposite",
    GroupType: "Accion",
  },
  {
    ObjType: "Adicionar",
    Icon: "adicionar.png",
    NodeText: "inactivo.png",
    GroupType: "Accion",
  },
  {
    ObjType: "PalabraClave",
    Icon: "palabra-clave.png",
    NodeText: "Palabra Clave",
    GroupType: "General",
  },
  {
    ObjType: "CambioDireccion",
    Icon: "cambio-direcccion.png",
    NodeText: "Cambio Direccion",
    GroupType: "General",
  },
  {
    ObjType: "NombreObjeto",
    Icon: "nombre-objeto.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "Autonumerico",
    Icon: "autonumerico.png",
    NodeText: "",
    GroupType: "General",
  },
  { ObjType: "Valor", Icon: "valor.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "FechaActual",
    Icon: "fecha-actual.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "AnoActual",
    Icon: "ano-actual.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "MesActual",
    Icon: "mes-actual.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "DiaActual",
    Icon: "dia-actual.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "Empresa",
    Icon: "empresa.png",
    NodeText: "",
    GroupType: "General",
  },
  { ObjType: "Modelo", Icon: "campo.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "EditorJson",
    Icon: "editor-json.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "EditorAce",
    Icon: "editor-ace.png",
    NodeText: "",
    GroupType: "General",
  },

  {
    ObjType: "NoPermitido",
    Icon: "no-permitido.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "ZoomIn",
    Icon: "zoom-in.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "ZoomOut",
    Icon: "zoom-out.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "SinImagen",
    Icon: "sin-imagen.png",
    NodeText: "",
    GroupType: "General",
  },

  {
    ObjType: "Field",
    Icon: "Field.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Key_Foreign",
    Icon: "Key_Foreign.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Key_Protected",
    Icon: "Key_Protected.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Property",
    Icon: "property.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Properties",
    Icon: "Properties.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  { ObjType: "Sql", Icon: "sql.png", NodeText: "", GroupType: "RuleEngine" },
  {
    ObjType: "SqlFuction",
    Icon: "sqlfuction.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SqlOrder",
    Icon: "sqlorder.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "StoredProcedure",
    Icon: "StoredProcedure.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Table",
    Icon: "Table.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Notify",
    Icon: "notify.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "MessageBox",
    Icon: "message-box.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Alerta",
    Icon: "messagebox/Warning.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Error",
    Icon: "messagebox/Error.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Informacion",
    Icon: "messagebox/Information.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Exitoso",
    Icon: "messagebox/Success.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Success",
    Icon: "messagebox/Success.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Question",
    Icon: "messagebox/Question.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "warning",
    Icon: "messagebox/Warning.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "error",
    Icon: "messagebox/Error.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "info",
    Icon: "messagebox/Information.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "success",
    Icon: "messagebox/Success.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "question",
    Icon: "messagebox/Question.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "question-warning",
    Icon: "messagebox/Warning.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "question-danger",
    Icon: "messagebox/Error.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "dander",
    Icon: "messagebox/Error.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ActionQuery",
    Icon: "workflow/process.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConditionQuery",
    Icon: "workflow/process.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionValue",
    Icon: "workflow/setqueryvar.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConditionValue",
    Icon: "set_property_ad.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  //set_property_ad
  {
    ObjType: "Stop",
    Icon: "messagebox/Stop.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Hand",
    Icon: "messagebox/Hand.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Exclamation",
    Icon: "messagebox/Exclamation.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Javascript",
    Icon: "javascript.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Correo",
    Icon: "correo.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Plantilla",
    Icon: "plantilla.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ContainsCharactersValidator",
    Icon: "validators/character.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ValueComparisonValidator",
    Icon: "validators/comparison.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConvertValidator",
    Icon: "validators/convert.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RelativeDateTimeValidator",
    Icon: "validators/datetime.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "DomainValidator",
    Icon: "validators/domain.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RangeValidator",
    Icon: "validators/range.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RegexValidator",
    Icon: "validators/regex.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RequiredValidator",
    Icon: "validators/required.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "StringLengthValidator",
    Icon: "validators/stringlength.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "StringFunctionValidator",
    Icon: "validators/function.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "FormatValidator",
    Icon: "validators/format.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Validators",
    Icon: "validators/validators.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionFunctionQuery",
    Icon: "querytype/query.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionFunctionValueToReplace",
    Icon: "value-to-replace.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ActionScript",
    Icon: "javascript.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConditionScript",
    Icon: "javascript.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionCondition",
    Icon: "Condition.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionCondition",
    Icon: "Condition.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  { ObjType: "Project", Icon: "project.png", NodeText: "BEXT_DOC" },

  {
    ObjType: "QuerysNode",
    Icon: "workflow/process.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RulesNode",
    Icon: "reglas.png",
    NodeText: "Reglas",
    GroupType: "RuleEngine",
  },
  { ObjType: "Rule", Icon: "rule2.png", NodeText: "", GroupType: "RuleEngine" },

  {
    ObjType: "TemplatesNode",
    Icon: "templates/templates.bmp",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Template",
    Icon: "templates/templates.bmp",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GroupedTemplate",
    Icon: "templates/template_continer.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WordTemplate",
    Icon: "templates/Word.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ExcelTemplate",
    Icon: "templates/Excel.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "HtmlTemplate",
    Icon: "templates/Html.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "XmlTemplate",
    Icon: "templates/Xml.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "CsvTemplate",
    Icon: "templates/Csv.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "TxtTemplate",
    Icon: "templates/Txt.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "JsonTemplate",
    Icon: "templates/Json.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SqlTemplate",
    Icon: "templates/Sql.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "PdfTemplate",
    Icon: "templates/Pdf.png",
    NodeText: "Plantillas",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ConfigNode",
    Icon: "settings.png",
    NodeText: "Configuracion",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConfigChildNode",
    Icon: "settings1.png",
    NodeText: "Configuracion",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConfigExpedientes",
    Icon: "settings1.png",
    NodeText: "Configuracion",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ConfigUI",
    Icon: "settings1.png",
    NodeText: "Configuracion",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConfigTipoUbicacionTiposDocumentales",
    Icon: "tipo-documental.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "ConfigExpedienteEmpresa",
    Icon: "configempresa.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "ConfigDocumento",
    Icon: "documents.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "ConfigExpediente",
    Icon: "expediente.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "ConfigValidacionExpediente",
    Icon: "ConfigValidacionExpediente.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "ConfigValidacionDocumento",
    Icon: "ConfigValidacionDocumento.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "ConfigUICache",
    Icon: "cache.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "ConfigUILog",
    Icon: "log.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },
  {
    ObjType: "AccessValidationConfig",
    Icon: "acces-validation-web.png",
    NodeText: "",
    GroupType: "GrupoEspecifico",
  },

  {
    ObjType: "AppNode",
    Icon: "applications/apps_folder.png",
    NodeText: "Aplicaciones Permitidas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedMovilAdmin",
    Icon: "applications/apps-admin.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedMovilGeneral",
    Icon: "applications/apps-general.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedMovil",
    Icon: "applications/apps.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ApplicationAllowedWebAdmin",
    Icon: "applications/web-app-admin.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedWebGeneral",
    Icon: "applications/web-app-general.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedWeb",
    Icon: "applications/web-app.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ApplicationAllowedTaskScheduler",
    Icon: "workflow/sheduletasks.jpg",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedService",
    Icon: "applications/service.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedWebService",
    Icon: "appserver/WebService.ico",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedExternal",
    Icon: "applications/external.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ApplicationAllowedSystemNode",
    Icon: "applications/system.png",
    NodeText: "Consultas",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "StaticVarsNode",
    Icon: "staticvars.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GlobalVar",
    Icon: "vars/globalvar.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "StaticVar",
    Icon: "vars/staticvar.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "LocalStorageVar",
    Icon: "vars/local-storage.jpg",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SessionStorageVar",
    Icon: "vars/session-storage.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ContextVar",
    Icon: "vars/context.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "DbVar",
    Icon: "vars/databasevar.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SessionVar",
    Icon: "vars/sessionvar.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "AppVar",
    Icon: "vars/applicationvar.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "GroupedMail",
    Icon: "mailcontainerrules.png",
    NodeText: "Regla Correo Agrupada",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionMail",
    Icon: "workflow/send-email.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Mail",
    Icon: "action-mail.png",
    NodeText: "Variables",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "MailsNode",
    Icon: "action-mails.png",
    NodeText: "AccionesCorreo",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ActiveDirectoryQuery",
    Icon: "querytype/queryactivedirectory.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "AssemblyQuery",
    Icon: "querytype/queryassembly.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "AzureQuery",
    Icon: "querytype/queryazure.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "CSharpQuery",
    Icon: "querytype/querycsharp.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "DirectoryQuery",
    Icon: "querytype/querydirectory.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "FileQuery",
    Icon: "querytype/queryfile.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GroupedQuery",
    Icon: "querytype/querygrouped.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ImportQuery",
    Icon: "querytype/queryimport.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "JavaScriptQuery",
    Icon: "querytype/queryjavascript.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "JoinQuery",
    Icon: "querytype/queryjoin.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "PowerShellQuery",
    Icon: "querytype/querypowershell.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ProcessQuery",
    Icon: "querytype/queryprocess.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SharePointQuery",
    Icon: "querytype/querysharepoint.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SqlQuery",
    Icon: "querytype/querysql.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SystemQuery",
    Icon: "querytype/querysystem.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebServiceQuery",
    Icon: "querytype/querywebservice.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "CrudQuerys",
    Icon: "querytype/crud-querys.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GetAllQuerys",
    Icon: "querytype/getall-querys.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GetByFilterCompareQuerys",
    Icon: "querytype/getbyfiltercompare-querys.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GetByFilterQuerys",
    Icon: "querytype/getbyfilter-querys.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ContainsQuerys",
    Icon: "querytype/Contains-querys.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GetByLikeQuerys",
    Icon: "querytype/getbylike-querys.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "NewDataBaseCnn",
    Icon: "layerdatatype/BaseSqlServerSinConexion.png",
    NodeText: "Conexiones",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConnectionsNode",
    Icon: "connections.png",
    NodeText: "Conexiones",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActiveDirectoryCnn",
    Icon: "layerdatatype/ActiveDirectory.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "AssemblyCnn",
    Icon: "layerdatatype/Assembly.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "DataBaseCnn",
    Icon: "layerdatatype/connection.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "FileCnn",
    Icon: "layerdatatype/file.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "PowerShellCnn",
    Icon: "layerdatatype/powershellcnn.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ProcessCnn",
    Icon: "layerdatatype/processcnn.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SharePointCnn",
    Icon: "layerdatatype/SharePoint.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SharePointOnlineCnn",
    Icon: "layerdatatype/SharePointClient.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebServiceCnn",
    Icon: "layerdatatype/WebService.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SharePointMenu",
    Icon: "layerdatatype/SharePoint2.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SharePointCnns",
    Icon: "layerdatatype/SharePoint2.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "SqlServerCnn",
    Icon: "layerdatatype/SqlServer.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "OracleCnn",
    Icon: "layerdatatype/OracleServer.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "PostgreSQLCnn",
    Icon: "layerdatatype/PostgreSQL.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "MySqlCnn",
    Icon: "layerdatatype/MySql.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SQLLiteCnn",
    Icon: "layerdatatype/SQLLite.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "AccessCnn",
    Icon: "layerdatatype/Access.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "CsvCnn",
    Icon: "layerdatatype/Csv.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "DataCnn",
    Icon: "layerdatatype/Data.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ExcelCnn",
    Icon: "layerdatatype/Excel.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GridCnn",
    Icon: "layerdatatype/Grid.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "HtmlCnn",
    Icon: "layerdatatype/Html.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "JsonCnn",
    Icon: "layerdatatype/Json.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "PdfCnn",
    Icon: "layerdatatype/Pdf.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SqlCnn",
    Icon: "layerdatatype/Sql.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "TxtCnn",
    Icon: "layerdatatype/Txt.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "XmlCnn",
    Icon: "layerdatatype/Xml.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "OneDriveCnn",
    Icon: "layerdatatype/OneDrive.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GoogleDocsCnn",
    Icon: "layerdatatype/GoogleDocs.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GoogleDriveCnn",
    Icon: "layerdatatype/GoogleDrive.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "GoogleSheetsCnn",
    Icon: "layerdatatype/GoogleSheets.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "RestCnn",
    Icon: "layerdatatype/Rest.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WCFCnn",
    Icon: "layerdatatype/WCFService.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "TraditionalCnn",
    Icon: "layerdatatype/WebService.ico",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "TraditionalSoapCnn",
    Icon: "layerdatatype/TraditionalSoap.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WCFSoapCnn",
    Icon: "layerdatatype/WCFSoap.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "Execute",
    Icon: "start.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Conect",
    Icon: "start.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ConfigAuth",
    Icon: "auth.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "UsersNode",
    Icon: "users/users.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "SystemUser",
    Icon: "users/user.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "AnonymousUser",
    Icon: "users/anonymoususer.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ExternalUser",
    Icon: "users/externaluser.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "WebServiceAppsNode",
    Icon: "appserver/iis.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebServiceApp",
    Icon: "appserver/appserver_not_install.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebServicesNode",
    Icon: "appserver/webservices.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebService",
    Icon: "appserver/WebService.ico",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebServiceMethodsNode",
    Icon: "appserver/method.gif",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebServiceMethod",
    Icon: "appserver/unknown.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "DeniedAccessRulesNode",
    Icon: "appserver/deniedrules.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "DeniedAccessRule",
    Icon: "appserver/denied.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "AccessRuleNode",
    Icon: "appserver/access-rules.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "AccessRule",
    Icon: "appserver/access-rule.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RequestsNode",
    Icon: "appserver/request.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RequestIp",
    Icon: "appserver/iprequest.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RequestUrl",
    Icon: "appserver/urlrequest.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RequestHeader",
    Icon: "appserver/headerrequest.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "RequestImei",
    Icon: "appserver/imeirequest.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ParametersNode",
    Icon: "appserver/parameters.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterIn",
    Icon: "appserver/parameterin.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterInFile",
    Icon: "appserver/parameterinfile.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterInCollection",
    Icon: "appserver/parameterincollection.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "InKeyValue",
    Icon: "appserver/parameterinkeyvalue.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterOut",
    Icon: "appserver/parameterout.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterInOut",
    Icon: "appserver/parameterinout.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterReturn",
    Icon: "appserver/parameterreturn.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterReturnModel",
    Icon: "appserver/parameterreturnmodel.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterReturnCollection",
    Icon: "appserver/parameterreturncollection.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterInOut",
    Icon: "appserver/parameterinout.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterInMetadata",
    Icon: "appserver/parameterinmetadata.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ParameterInMappingFile",
    Icon: "appserver/parameterinmapping.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  //request.jpg

  {
    ObjType: "ActionRule",
    Icon: "workflow/rules1.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionTemplate",
    Icon: "workflow/templates.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionForm",
    Icon: "workflow/form.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionTaskScheduler",
    Icon: "workflow/sheduletasks.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionApprovalTask",
    Icon: "workflow/approvaltask.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionStart",
    Icon: "workflow/start.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionEnd",
    Icon: "workflow/end.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionCustom",
    Icon: "workflow/action-custom.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WorkFlowNode",
    Icon: "workflow/workflows.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WorkFlow",
    Icon: "workflow/workflow.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Task",
    Icon: "workflow/task.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionInclude",
    Icon: "workflow/action-include.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ActionCopy",
    Icon: "workflow/action-copy.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "Action",
    Icon: "workflow/action.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WorkFlowStatesNode",
    Icon: "workflow/workflow-states-node.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WorkFlowState",
    Icon: "workflow/workflow-state.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "WebLogSettingsNode",
    Icon: "WebLogSettingsNode.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "WebLogSettings",
    Icon: "WebLogSettings.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ViewFile",
    Icon: "viewfile.png",
    NodeText: "",
    GroupType: "General",
  },
  { ObjType: "View", Icon: "view.png", NodeText: "", GroupType: "General" },

  {
    ObjType: "Tabla",
    Icon: "tabla-retencion.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "PropertyGrid",
    Icon: "property-grid.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  {
    ObjType: "ReportsNode",
    Icon: "reports/reports.bmp",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ReportRdlc",
    Icon: "reports/report.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "ReportCharts",
    Icon: "reports/report-charts.png",
    NodeText: "",
    GroupType: "RuleEngine",
  },

  { ObjType: "Items", Icon: "items.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "DbTable",
    Icon: "db-table.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "ValidateTable",
    Icon: "validate-table.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "ValidateTable2",
    Icon: "validate-table2.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "ValidateTable3",
    Icon: "validate-table3.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "CreateTable",
    Icon: "create-table.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "DeleteTable",
    Icon: "delete-table.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "DeleteTableBackup",
    Icon: "delete-table-backup.png",
    NodeText: "",
    GroupType: "General",
  },

  {
    ObjType: "CreateColumn",
    Icon: "create-column.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "DeleteColumn",
    Icon: "delete-column.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "DeleteColumnBackup",
    Icon: "delete-column-backup.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "ValidateColumn",
    Icon: "validate-column.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "CreateUbicacionArchivos",
    Icon: "crear-ubicacion-archivo.png",
    NodeText: "",
    GroupType: "General",
  },
  //delete-table-backup.png
  { ObjType: "Delete", Icon: "delete.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "Formula",
    Icon: "formula.png",
    NodeText: "",
    GroupType: "General",
  },
  { ObjType: "Backup", Icon: "backup.png", NodeText: "", GroupType: "General" },

  { ObjType: "Menus", Icon: "menus.png", NodeText: "", GroupType: "General" },
  { ObjType: "Menus2", Icon: "menus2.png", NodeText: "", GroupType: "General" },
  { ObjType: "Menu", Icon: "menu.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "MenuAdmin",
    Icon: "menu-admin.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "MenuGeneral",
    Icon: "menu-general.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "MenuAdminGeneral",
    Icon: "menu-admin-general.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "MenuEdit",
    Icon: "menu-edit.png",
    NodeText: "",
    GroupType: "General",
  },
  { ObjType: "Undo", Icon: "undo.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "CheckboxUncheked",
    Icon: "checkbox-uncheked.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "CheckboxCheked",
    Icon: "checkbox-cheked.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "ActualizarDb",
    Icon: "actualizar-db.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "NewNode",
    Icon: "new-node.png",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "TasksSchedulerNode",
    Icon: "tasksheluder/taskscheduler.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  {
    ObjType: "TaskScheduler",
    Icon: "workflow/sheduletasks.jpg",
    NodeText: "",
    GroupType: "RuleEngine",
  },
  { ObjType: "Expand", Icon: "expand.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "Collapse",
    Icon: "collapse.png",
    NodeText: "",
    GroupType: "General",
  },
  { ObjType: "Button", Icon: "button.png", NodeText: "", GroupType: "General" },
  {
    ObjType: "Control",
    Icon: "control.png",
    NodeText: "",
    GroupType: "General",
  },
  { ObjType: "GUID", Icon: "GUID.png", NodeText: "", GroupType: "General" },

  {
    ObjType: "EstadoAbierto",
    Icon: "create-table.png",
    NodeText: "",
    GroupType: "EstadosExpediente",
    Title: "Estado Abierto",
    Id: 1,
    FilterField: "IdEstado",
  },
  {
    ObjType: "EstadoCerrado",
    Icon: "control.png",
    NodeText: "",
    GroupType: "EstadosExpediente",
    Title: "Estado Cerrado",
    Id: 2,
    FilterField: "IdEstado",
  },
  {
    ObjType: "SubEstadoAbierto",
    Icon: "collapse.png",
    NodeText: "",
    GroupType: "SubEstadosExpediente",
    Title: "Estado Temp",
    Id: 1,
    FilterField: "IdSubEstado",
  },
  {
    ObjType: "EstadoNoAsignado",
    Icon: "new-node.png",
    NodeText: "",
    GroupType: "EstadosExpediente",
    Title: "Estado no Asignado",
  },
  {
    ObjType: "subirFolderArchivo",
    Icon: "subir-folder-archivo.svg",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "bestDocSim",
    Icon: "logo-bestdoc-sim.svg",
    NodeText: "",
    GroupType: "General",
  },
  {
    ObjType: "bestDocTexto",
    Icon: "logo-bestdoc-texto.svg",
    NodeText: "",
    GroupType: "General",
  },
  //ConfigAuth
];
// export const nodeSubTypes: Array<any> = [value-to-replace.png
// 	{ ObjType: 'AplicacionPermitida', Icon: 'aplicacion-permitida.png', NodeText: '' },
// 	{ ObjType: 'Role', Icon: 'role.png', NodeText: '' },
// 	{ ObjType: 'Grupo', Icon: 'grupo.png', NodeText: '' },
// 	{ ObjType: 'Formulario', Icon: 'form.png', NodeText: '' },
// 	{ ObjType: 'Query', Icon: 'query.png', NodeText: '' },

// 	{ ObjType: 'AreaEmpresa', Icon: 'area-empresa.png', NodeText: '' },
// 	{ ObjType: 'Serie', Icon: 'serie.png', NodeText: '' },
// 	{ ObjType: 'SubSerie', Icon: 'sub-serie.png', NodeText: '' },
// 	{ ObjType: 'TipoDocumental', Icon: 'tipo-documental.png', NodeText: '' },
// 	{ ObjType: 'TablaRetencionDocumental', Icon: 'tabla-retencion.png', NodeText: '' },
// 	{ ObjType: 'Expediente', Icon: 'expediente.png', NodeText: '' },
// 	{ ObjType: 'TipoSolicitud', Icon: 'tipo-solicitud.png', NodeText: '' },

// 	{ ObjType: 'Carpeta', Icon: 'carpeta.png', NodeText: '' },
// 	{ ObjType: 'EstadoSolicitud', Icon: 'estado-solicitud.png', NodeText: '' },
// 	{ ObjType: 'UbicacionExpediente', Icon: 'ubicacion-expediente.png', NodeText: 'Ubicacion Expediente' },
// 	{ ObjType: 'AgrupadoCarpeta', Icon: 'agrupado-carpeta.png', NodeText: 'Agrupado Carpeta' },
// 	{ ObjType: 'Usuario', Icon: 'usuario.png', NodeText: '' },
// 	{ ObjType: 'Nulo', Icon: 'nulo.png', NodeText: '' },
// 	{ ObjType: 'Editar', Icon: 'editar.png', NodeText: '' },
// 	{ ObjType: 'Eliminar', Icon: 'eliminar.png', NodeText: '' },
// 	{ ObjType: 'Clonar', Icon: 'clonar.png', NodeText: '' },
// 	{ ObjType: 'Guardar', Icon: 'guardar.png', NodeText: '' },
// 	{ ObjType: 'Importar', Icon: 'importar.png', NodeText: '' },
// 	{ ObjType: 'Exportar', Icon: 'exportar.png', NodeText: '' },
// 	{ ObjType: 'Consultar', Icon: 'consultar.png', NodeText: '' },
// 	{ ObjType: 'Filtrar', Icon: 'filtrar.png', NodeText: '' },
// 	{ ObjType: 'Ver', Icon: 'ver.png', NodeText: '' },
// 	{ ObjType: 'AdminPermisos', Icon: 'admin-permisos.png', NodeText: '' },
// 	{ ObjType: 'VerPermisos', Icon: 'ver-permisos.png', NodeText: '' },
// 	{ ObjType: 'Descargar', Icon: 'descargar.png', NodeText: '' },
// 	{ ObjType: 'Activo', Icon: 'activo.png', NodeText: 'activo.png' },
// 	{ ObjType: 'Inactivo', Icon: 'inactivo.png', NodeText: 'inactivo.png' },
// 	{ ObjType: 'Adicionar', Icon: 'adicionar.png', NodeText: 'inactivo.png' },
// 	{ ObjType: 'PalabraClave', Icon: 'palabra-clave.png', NodeText: 'Palabra Clave' },
// 	{ ObjType: 'CambioDireccion', Icon: 'cambio-direcccion.png', NodeText: 'Cambio Direccion' },
// 	{ ObjType: 'NombreObjeto', Icon: 'nombre-objeto.png', NodeText: '' },
// 	{ ObjType: 'Autonumerico', Icon: 'autonumerico.png', NodeText: '' },
// 	{ ObjType: 'Valor', Icon: 'valor.png', NodeText: '' },
// 	{ ObjType: 'FechaActual', Icon: 'fecha-actual.png', NodeText: '' },
// 	{ ObjType: 'AnoActual', Icon: 'ano-actual.png', NodeText: '' },
// 	{ ObjType: 'MesActual', Icon: 'mes-actual.png', NodeText: '' },
// 	{ ObjType: 'DiaActual', Icon: 'dia-actual.png', NodeText: '' },
// 	{ ObjType: 'Empresa', Icon: 'empresa.png', NodeText: '' },
// 	{ ObjType: 'Modelo', Icon: 'campo.png', NodeText: '' },
// 	{ ObjType: 'SinImagen', Icon: 'sin-imagen.png', NodeText: '' },
// 	{ ObjType: 'EditorJson', Icon: 'editor-json.png', NodeText: '' },
// 	{ ObjType: 'EditorAce', Icon: 'editor-ace.png', NodeText: '' },

// 	{ ObjType: 'NoPermitido', Icon: 'no-permitido.png', NodeText: '' },
// 	{ ObjType: 'ZoomIn', Icon: 'zoom-in.png', NodeText: '' },
// 	{ ObjType: 'ZoomOut', Icon: 'zoom-out.png', NodeText: '' },
// ];

//no-permitido.png
// { ObjType: 'AplicacionesPermitidas', Icon: 'aplicaciones-permitidas.png', NodeText: 'Aplicaciones Permitidas' },
// { ObjType: 'Roles', Icon: 'roles.png', NodeText: 'Roles' },
// { ObjType: 'Grupos', Icon: 'grupos.png', NodeText: 'Grupos' },
// { ObjType: 'Formularios', Icon: 'forms.png', NodeText: 'Formularios' },
// { ObjType: 'Querys', Icon: 'query.png', NodeText: 'Querys' },

// { ObjType: 'AreasEmpresa', Icon: 'areas-empresa.png', NodeText: 'Areas Empresa' },
// { ObjType: 'Series', Icon: 'series.png', NodeText: 'Series' },
// { ObjType: 'SubSeries', Icon: 'sub-series.png', NodeText: 'Sub Series' },
// { ObjType: 'TiposDocumentales', Icon: 'tipos-doccumentales.png', NodeText: 'Tipos Documentales' },
// { ObjType: 'TablasRetencionDocumental', Icon: 'tablas-retencion.png', NodeText: 'Tablas Retencion Documental' },
// { ObjType: 'Expedientes', Icon: 'expedientes.png', NodeText: 'Expedientes' },
// { ObjType: 'TiposSolicitud', Icon: 'tipos-solicitud.png', NodeText: 'Tipo Solicitud' },
// { ObjType: 'Carpetas', Icon: 'carpetas.png', NodeText: 'Carpetas' },
// { ObjType: 'EstadosSolicitud', Icon: 'estados-solicitud.png', NodeText: 'Flujo' }
export enum QueryConfigIDETypes {
  Ninguno = 0,
  AdminIDE = 1,
  AplicacionesPermitidas = 2,
  Roles = 3,
  Grupos = 4,
  Formularios = 5,
  Querys = 6,
  AreasEmpresa = 7,
  Series = 8,
  SubSeries = 9,
  TiposDocumentales = 10,
  TablasRetencionDocumental = 11,
  Expedientes = 12,
  TiposSolicitud = 13,
  Carpetas = 14,
  EstadosSolicitud = 14,
  DocumentosExpediete = 15,
  Reglas = 16,
  ConfiguracionGeneral = 17,
  Menus = 18,
  Entidades,
  SeriesSubSeries,
}
export enum TiposUbicacionArchivo {
  FileSystem = 1,
  Table,
  FilesStream,
  FileTable,
  AzureFileStorage,
  SharePoint,
  SharePointOnline,
  WebClient,
  Query,
  FTP,
  Custom,
  CustomByInterface,
  AzureBlobStorage,
  AWSS3,
}
export enum OpcionesPermisosCrearExpediente {
  Ninguna = 1,
  AsignarUsuario,
  AsignarRole,
  AsignarGrupo,
  AsignarAreaEmpresa,
  AsignarUsuarioResponsable,
  AsignarAreaEmpresaPredeterminada,
}
export enum DataTableActions {
  Order = 1,
  Paged,
  Search,
  PageInfo,
  Draw,
  Export,
}

export enum ControlActions {
  None = 0,
  EditInModal = 1,
  InternalNavigation,
  ExternalNavigation = 3,
  SetSelectedItem,
  ViewMessage = 5,
  MappingModel = 6,
  MappingItem,
  MappingContext,
  MappingParentContext,
  MappingGeneral = 10,
  ViewItemInAceEditor,
  ViewItemJsonEditor,
  EditInModalByPk,
  EmitAction,
  EmiteAllAction = 15,
  ViewDetailsControl,
  Search,
  CloneModel,
  /* 	ExecuteRule,
		ExecuteActionRule=19, */
  ExecuteObject,
  DownloadDocument,
  SendToSiteDocument,
  DownloadAsBytesDocument,
  DownloadAsBase64Document,
  DownloadAsUrlDocument,
  ViewDocument,
  DownloadDocumentByConfig,
  Import,
  GetTemplateForImport,
  Export,
  MappingForm,
  SearchModal,
  CustomComponent,
  ViewPage,
  ExecuteWorkFlowAction,
  MappingParentForm,
  OpenSidePanel,
  ActiveInactive,
  MosaicView,
  ListView,
  SaveForm,
  ClearForm,
  ExportGridButton,
  MappingModelToModel,
  OpenFloatPanel,
  CloseFloatPanel,
  ExportJsonToExcel,
  ExportJsonToCsv,
  ReloadDataControls,
  SetControlProperty,
  ExecuteFunction,
  ExecuteScript,
  Print,
  PrintNewWindow,
  Screenshot,
  PdfScreenshot,
  SendToLibrary,
}
export enum MailSendingForms {
  Net,
  Net2,
  Sharepoint,
}
export enum DataTypes {
  String = 0,
  Number,
  Int32,
  DateTime,
  Boolean,
  Object,
  Int64,
  Decimal,
  SemiLargeString,
  LargeString,
  Json,
  QueryResult,
}
export enum DataTableColumnTypes {
  General = 0,
  Edit = 1,
  Delete,
  Options,
  Actions,
  Icon,
  Selection,
  Active,
  Details,
  View,
  DownLoad,
  Import,
  Boolean,
  Custom,
}
export enum TipoParaPermisos {
  Ninguna = 0,
  Usuario = 1,
  Role,
  Grupo,
  AreaEmpresa,
  SedeEmpresa,
  ApplicationAllowed,
}
export enum TipoSeguridad {
  Reservado = 7,
  Privado = 6,
  Publico = 5,
}

export enum TipoAdminPermisos {
  Documento = 1,
  Expediente = 2,
  Pagina = 3,
}
export enum TipoBusquedaAreaEmpresa {
  Ninguna = 0,
  TodasLasAreas = 1,
  MiArea,
  AreasEspecificas,
}

export enum TipoObjetoParaPermisos {
  Pagina = -1,
  General = 1,
  Expediente,
  Documento,
  Ubicacion,
}
export enum AccionesNodos {
  Nuevo = 1,
  Eliminado,
  Restaurar,
  CambioPadre,
  ModificarTexto,
}
export enum ControllerMethods {
  Save = 1,
  Edit = 2,
  Delete = 3,
  Active = 4,
  UpdateProperties = 5,
  Clear = 6,
  LoadGrid = 7,
  ModalFilter = 8,
  ModalCrud = 9,
  ClickChildOptions = 10,
  SelectedComboBox = 11,
  Constructor = 12,
  OnInit = 13,
  SelectedItem = 14,
  LoadedGrid = 15,
  PreSave = 16,
}
export enum TiposCrearNodos {
  Normal = 0,
  Recursiva = 1,
  NormalHijosExternos = 2,
  RecursivaHijosExternos = 3,
  ListadoNodos = 4,
}
export enum CrudActions {
  None = 0,
  Insert = 1,
  UpdateStatus = 2,
  Update = 3,
  Delete = 4,
  GetAll = 5,
  GetById = 6,
  GetByFilter = 7,
  InsertUpdate = 8,
  GetByFilterCompare = 9,
  GetByFilterRemoveAllSpaces = 10,
  GetByFilterCompareRemoveAllSpaces = 11,
  DeleteByFilter = 12,
  GetAllOrderBy = 13,
  GetAllOrderByDesc = 14,
  GetByFilterOrderBy = 15,
  GetByFilterOrderByDesc = 16,
  Inserted = 17,
  Updated = 18,
  Deleted = 19,
  UpdateStatusend = 20,
}

export enum EntityMetadataTypes {
  None = -1,
  Table = 0,
  View,
  StoredProcedure,
  Function,
  SqlFunction,
  Field,
  ParameterIn,
  ParameterOut,
  Result,
  WorkFlowTable,
  WorkFlowDetailsTable,
  WorkFlowNotificationTable,
  ExtraTable,
  WorkFlowActionTable,
}
export enum LogicalOperators {
  And = 0,
  Or = 1,
}

export enum ComparisonOperators {
  None = 0,
  Equal,
  NotEqual,
  In,
  NotIn,
  Contains,
  NotContains,
  StartsWith,
  EndsWith,
  NotStartsWith,
  NotEndsWith,
  GreaterThan,
  LessThan,
  GreaterOrEqual,
  LessOrEqual,
  IsNull,
  IsNotNull,
  IsUndefined,
  IsNotUndefined,
  Range,
}
export enum ReturnQueryTypes {
  None = 0,
  ToListOfDictionary,
  ToDataTable,
  ToListOfType,
}
export enum TiposConsecutivo {
  Ninguna = 0,
  Expediente = 1,
  DocumentoExpediente,
  General,
  VersionDocumentoExpediente,
  DocumentoSolicitud,
  VersionDocumentoSolicitud,
  SolicitudDocumentos = 24,
}
export enum TiposIdExpediente {
  Expediente = 0,
  Trd,
  VersionTrd,
  AreaEmpresa,
  VersionAreaEmpresa,
  Serie,
  VersionSerie,
  SubSerie,
  VersionSubSerie,
  AreaEmpresaResponsable,
  UsuarioResponsable,
  RoleResponsable,
  AreaEmpresaUsuarioResponsable,
  AreaEmpresaRoleResponsable,
}

export enum TiposNombreFolder {
  Ninguno = 0,
  Autonumerico,
  Nombre,
  Codigo,
  Personalizado,
}
export enum FormatosTexto {
  Normal = 0,
  Mayusculas,
  Minusculas,
  UpperCamelCase,
  PascalCase,
}
export enum TiposRelleno {
  Ninguno = 0,
  Derecho,
  Izquierdo,
}
export enum TiposCampoConfigGenerar {
  NombreObjeto = 0,
  Valor = 1,
  Autonumerico = 2,
  FechaActual = 3,
  AnoActual = 4,
  MesActual = 5,
  DiaActual = 6,
  Empresa = 7,
  AreaEmpresaUsuarioActual = 8,
  RoleUsuarioActual = 9,
  GrupoUsuarioActual = 10,
  UsuarioActual = 11,
  IdEmpresa = 12,
  IdAreaEmpresaUsuarioActual = 13,
  IdRoleUsuarioActual = 14,
  IdGrupoUsuarioActual = 15,
  IdUsuarioActual = 16,
  Modelo,
}

export enum DataTableAjaxOrderTypes {
  Default = 0,
  Index,
  Name,
  OrderName,
}

export enum TiposUbicacionCacheIU {
  Aplicacion = 0,
  LocalStorage,
  SessionStorage,
}
export enum Paginas {
  Home = -2,
  TLogApp = 1,
  AplicacionPermitida = 2,
  AreaEmpresa = 3,
  AuditoriaFuncional = 4,
  Carpeta = 5,
  Ciudad = 6,
  ConfigCifradoPagina = 7,
  ConfigSolicitudModificacionPagina = 8,
  Consecutivo = 9,
  Contacto = 10,
  DatoBasico = 11,
  Departamento = 12,
  DiaFestivo = 13,
  DocumentoEstadoSolicitud = 14,
  Empresa = 15,
  EstadoSolicitud = 16,
  EstiloAplicacion = 17,
  Grupo = 18,
  MedioDeContactoContacto = 19,
  MedioDeContactoEmpresa = 20,
  MensajeValidacionIndice = 21,
  Menu = 22,
  ModoTrabajoSolicitud = 23,
  Pagina = 24,
  PaginaMetadatos = 25,
  Pais = 26,
  ParametroGeneral = 27,
  PlantillaSedeEmpresa = 28,
  Query = 29,
  Regla = 30,
  Role = 31,
  SedeEmpresa = 32,
  Serie = 33,
  SubSerie = 34,
  TablaRetencionDocumental = 35,
  Tema = 36,
  TemaTipoSolicitud = 37,
  TipoAreaEmpresa = 38,
  TipoBusquedaAreaEmpresa = 39,
  TipoConsecutivo = 40,
  TipoDatoBasico = 41,
  TipoDisposicion = 42,
  TipoDocumental = 43,
  TipoDocumentalPaginaMetadatos = 44,
  TipoMoneda = 45,
  TipoSeguridadDocumento = 46,
  TipoSolicitud = 47,
  TipoUbicacionArchivo = 48,
  Usuario = 49,
  ValidacionIndiceUnico = 50,
  VersionSubSeriePaginaMetadatos = 51,
  DocumentoExpediente = 52,
  EstadoCarga = 53,
  EstadoCargaDetalle = 54,
  Expediente = 55,
  FiltroQueryUsuario = 56,
  Pqr = 57,
  RadicadoComunicadoInterno = 58,
  RadicadoEntrada = 59,
  RadicadoFactura = 60,
  RadicadoSalida = 61,
  Solicitud = 62,
  SolicitudDocumentoExpediente = 63,
  SolicitudEstado = 64,
  SolicitudModificacionPagina = 65,
  UbicacionFisicaExpediente = 66,
  VersionAreaEmpresa = 67,
  VersionSerie = 68,
  VersionSubSerie = 69,
  VersionTablaRetencionDocumental = 70,
  VersionTablaRetencionTipoDocumental = 71,
  TablaRetencionTipoDocumental = 72,
  BusquedaExpediente = 73,
}

export enum TypesUseForm {
  Normal = 0,
  ModalCreate,
  ModalEdit,
}

export enum FormActionsThroughEmitters {
  ClearModel = "ClearModel",
  Clear = "Clear",
  ClearForm = "ClearForm",
  Edit = "Edit",
  OpenedSidePanel = "OpenedSidePanel",
  AfterViewInit = "AfterViewInit",
  OnDownload = "OnDownload",
  OnDownloadSharePoint = "OnDownloadSharePoint",
  OnDownloadAzureBlob = "OnDownloadAzureBlob",
  OnViewAzureBlob = "OnViewAzureBlob",
  NewDocument = "NewDocument",
  OnViewAttachment = "OnViewAttachment",
  DetailDocument = "DetailDocument",
  MultipleSelection = "MultipleSelection",
  OnViewFileSystem = "OnViewFileSystem",
  OnDownloadFileSystem = "OnDownloadFileSystem",
  ResponseEmmit = "ResponseEmmit",
  OnDownloadAWSS3 = "OnDownloadAWSS3",
  OnViewAWSS3 = "OnViewAWSS3"
}

// export enum ClearModelOptions {
// 	LimpiarFilterControls = "ClearModel",
// 	Edit = "Edit"
// }

export enum OperacionesDeSolicitudes {
  Ninguna = 0,
  IncluirEnExpediente = 1,
  Copiar = 2,
  RespuestaMemorando = 3,
  Aprobar = 4,
  SolicitarConcepto = 5,
  GestionarConcepto = 6,
  RechazarAnulacion = 7,
  AprobarAnulacion = 8,
  ActualizarDocumento = 9,
  ReemplazarDocumento = 10,
}
export enum GeneralPageControlTypes {
  CommandButton = "CommandButton",
  Button = "Button",
}

export enum FiltroProcesoFirma {
  TodosDocumentos = 1129,
  Principal = 1130,
  Adjuntos = 1131,
}

export enum EstadosSolicitud {
  Ninguno = 0,
  Creada = 1,
  Gestion = 2,
  Reasignada = 3,
  PendienteAprobar = 4,
  Finalizada = 5,
  Rechazada = 6,
  Aprobada = 7,
  PendientePorRecibir = 8,
  PendienteAsignarEnvio = 9,
  EnvioAsignado = 10,
  PlanillaGenerada = 11,
  ExpedienteIncluido = 12,
  Copiado = 13,
  PendienteVoBo = 14,
  VoBo = 15,
  CreadaSinDocumento = 16,
  DocumentoAsignado = 17,
  SolicitudRecibida = 18,
  EnvioEntregado = 19,
  EnvioDevuelto = 20,
  PendienteDeOficio = 21,
  RespuestaMemorando = 22,
  RespuestaEnviada = 23,
  Archivado = 24,
  SolicitudActualizada = 25,
  AnulacionAprobada = 26,
  AnulacionRechazada = 27,
  PendienteAprobacionParaAnulacion = 28,
  SolicitudConcepto = 29,
  ConceptoEnviado = 30,
  CerradoPorExpediente = 31,
  ReemplazoDocumento = 32,
  ExcluirDeExpediente = 33,
  Eliminado = 34,
  PendienteFirmaElectronica = 35,
}

// export enum TipoNotificacionServicios {
// 	Ninguno = 0,
// 	Notificaciones,
// 	Modal,
// 	NotificacionesModal,
// }
