import { Component, OnInit, EventEmitter, Output, Input, Inject, forwardRef } from '@angular/core';
import { ConfigBaseComponent } from '../../models/config-base-component';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { Guid } from "guid-typescript";
import { Utilities } from '../../helpers/utilities';


@Component({
  selector: 'app-campos-consecutivo-nombre',
  templateUrl: './campos-consecutivo-nombre.component.html',
  styleUrls: ['./campos-consecutivo-nombre.component.css']
})
export class CamposConsecutivoNombreComponent implements OnInit {


  @Input() componentName: string;
  @Input() subControl: any = null;
  @Input() subControlParent: any = null;
  @Input() modelo: any = null;
  @Input() childComponents: Array<any> = [];
  @Output() onInitialized = new EventEmitter<any>();

  @Output() removeFieldEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectFieldEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() index: number;
  @Input() listToConnect: Array<any>;
  @Input() containerName: string = '';
  imagen: string;
  listName: string;
  items: Array<any> = [{ Id: 1, Text: 'Item 1' },
  { Id: 2, Text: 'Item 2' },
  { Id: 3, Text: 'Item 3' },
  { Id: 4, Text: 'Item 4' },
  { Id: 5, Text: 'Item 5' },
  { Id: 6, Text: 'Item 6' },
  { Id: 7, Text: 'Item 7' },
  { Id: 8, Text: 'Item 8' },
  { Id: 9, Text: 'Item 9' }];

  @Input() verModelo: boolean = false;

  @Input() controls: Array<any> = [];

  constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities) { }


  ngOnInit() {

    if (this.listToConnect && this.subControl &&
      (this.subControl.TipoCampo == 'AcoordionPanel' ||
        this.subControl.TipoCampo == 'TabPanel' || this.subControl.TipoCampo == 'ContainerBox')) {
      this.listName = 'drop-list-' + this.containerName + '-' + Guid.create().toString();
      this.listToConnect.splice(0, 0, this.listName);
    }

    this.SetImagen();
  }
  public SetImagen() {


    this.utility.SetTextCampo(this.subControl,'FormatoTexto');
    var subTipo = this.subControl.TipoCampo;
    if (!this.subControl.Nombre)
      this.subControl.Nombre = this.subControl.TipoCampo;


    // NombreObjeto=0,
    // Valor =1,
    // Autonumerico = 2,
    // FechaActual = 3,
    // AnoActual = 4,
    // MesActual = 5,
    // DiaActual = 6,
    // Empresa = 7,
    // AreaEmpresaUsuarioActual = 8,
    // RoleUsuarioActual = 9,
    // GrupoUsuarioActual = 10,
    // UsuarioActual = 11,
    // IdEmpresa = 12,
    // IdAreaEmpresaUsuarioActual = 13,
    // IdRoleUsuarioActual = 14,
    // IdGrupoUsuarioActual = 15,
    // IdUsuarioActual = 16,

    // Modelo,
   
    switch (this.subControl.TipoCampo) {
      case 'AreaEmpresaUsuarioActual':
        subTipo = 'AreaEmpresa';
        break;
      case 'RoleUsuarioActual':
        subTipo = 'Role';
        break;
      case 'GrupoUsuarioActual':
        subTipo = 'Grupo';
        break;
      case 'UsuarioActual':
        subTipo = 'Usuario';
        break;
      case 'IdEmpresa':
        subTipo = 'Empresa';
        break;
      case 'IdAreaEmpresaUsuarioActual':
        subTipo = 'AreaEmpresa';
        break;
      case 'IdRoleUsuarioActual':
        subTipo = 'Role';
        break;
      case 'IdGrupoUsuarioActual':
        subTipo = 'Grupo';
        break;
      case 'IdUsuarioActual':
        subTipo = 'Usuario';
        break;
    }

    this.imagen = this.utility.GetPathImages(this.utility.GetNodeType(subTipo));
  }
  public addControl() {
    var child = this.utility.GetTabPanelAcoordionPanel(this.subControl.TipoCampo == 'Acoordion');
    this.subControl.Controls.push(child);
  }

  public _selectField(event) {
    // event.stopPropagation();
    this.selectFieldEmitter.emit(event);
  }
  public _removeField(event) {
    if (this.subControlParent) {
      this.subControlParent.Controls.splice(event.index, 1);
    }
    else
      this.removeFieldEmitter.emit(event);
  }

  dragStartInternal(event: CdkDragStart) {

  }

  movedInternal(event: CdkDragMove) {

  }

  itemDroppedInternal(event: CdkDragDrop<any[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Controls, event.previousIndex, event.currentIndex);
    } else {
      this.addFieldInternal(event.item.data, event.currentIndex);
    }
  }


  addFieldInternal(fiedlModel: any, index: number) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName
    let _currentFieldModel = this.utility.GeModelControl(fiedlModel.TipoCampo, fiedlModel.Type);
    if (index == -1)
      this.subControl.Controls.push(_currentFieldModel);
    else
      this.subControl.Controls.splice(index, 0, _currentFieldModel)
  }

}
