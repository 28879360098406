import { Component, OnInit, Input,Output, forwardRef ,EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import {BaseControl } from '../../../models/base-control'

@Component({
  selector: 'app-check-box',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true
    }
  ],
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent extends BaseControl implements OnInit,ControlValueAccessor {

 
 }
 
