import { Component, OnInit, Input } from '@angular/core';

import { TipoParaPermisos, FormActionsThroughEmitters } from 'src/app/models/general.enum';
import { List } from '../../../../../assets/linqts/compilado/index';
import { StaticData } from 'src/app/helpers/static-data';
import { Guid } from 'guid-typescript';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { UtilityRuleEngine } from '../../../../helpers/utility-rule-engine';
import { HelperImages } from 'src/app/helpers/helper-images';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { element } from 'protractor';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent  implements OnInit {

  @Input() modelo: any;
  @Input() items: Array<any> = [];
  @Input() responsibles: Array<any> = [];
  @Input() name: string = Guid.create().toString();
  @Input() propPermission: string = "Permissions";
  @Input() propName: string = "Nombre";
  @Input() propDescription: string = null;
  @Input() label: string = "Nombre";
  @Input() labelDescription: string = null;
  @Input() type: TipoParaPermisos = TipoParaPermisos.Usuario;
  @Input()
  ListaPermisos = new List<any>([]);
  imagen: string;
  filterProp: string = "Id";
  dtOptions: any;
  stringType: string ="";
  @Input() configCampoAsunto: any;
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityRuleEngine: UtilityRuleEngine,
    public helperImages: HelperImages, 
    public comunicationService: GeneralComunicationService) { }

  ngOnInit() {
    
    this.stringType = TipoParaPermisos[this.type];
    this.dtOptions = this.utility.GetDtOptionsHtmlTable();
    this.imagen = this.utility.GetPathImages(this.utility.GetNodeType(TipoParaPermisos[this.type]));
    this.SetSeleccionadosByMapping();

    if (this.type == TipoParaPermisos.ApplicationAllowed) {

    }
    else {
      if (this.responsibles.length > 0)
        this.items =this.utility.Clone( this.responsibles.filter(x => { return x.Tipo == this.type || x.Tipo == this.stringType }));
      else
        this.items = this.utility.GetItemsAreasRolesUsuarioSeries(this.type, false, true);
    }


    if (!this.modelo[this.propPermission])
      this.modelo[this.propPermission] = [];
    this.ListaPermisos = new List<any>(this.modelo[this.propPermission]);
    switch (this.type) {
      case TipoParaPermisos.Usuario:

        this.propDescription = "Descripcion";
        this.label = "Usuario";
        this.labelDescription = "Nombre";
        break;
      case TipoParaPermisos.Role:

        this.propDescription = null;
        this.label = "Role";
        this.labelDescription = null;
        break;
      case TipoParaPermisos.AreaEmpresa:

        this.propDescription = null;
        this.label = "Area Empresa";
        this.labelDescription = null;
        break;
      case TipoParaPermisos.Grupo:

        this.propDescription = null;
        this.label = "Grupo";
        this.labelDescription = null;
        break;
      case TipoParaPermisos.SedeEmpresa:

        this.propDescription = null;
        this.label = "Sede Empresa";
        this.labelDescription = null;
        break;
      case TipoParaPermisos.ApplicationAllowed:

        this.propDescription = null;
        this.label = "Aplicacion Permitida";
        this.labelDescription = null;
        this.filterProp = "GUID";
        break;
    }
    this.SetSeleccionados();
  }

  public SetSeleccionadosByMapping()
  {
    this.comunicationService.receivedFormEvent.subscribe(event => {
      if (event.Action === FormActionsThroughEmitters.Edit) {
        //Reiniciar
        if(this.ListaPermisos.Count() > 0)
          {
            this.ListaPermisos.ForEach(element => {
              let itemEditado = this.items.find(x  => x[this.filterProp] === element[this.filterProp]);
              if (itemEditado)
              {
                  itemEditado.Seleccionado = false;
                  itemEditado.Consultar = false;
                  itemEditado.Editar = false;
                  itemEditado.Eliminar = false;
              }
            });
            this.ListaPermisos = new List<any>();
          }

        //this.modelo no está definido aún
        // this.modelo = event.modelo.modelo;
        let modelo = event.modelo.modelo;
        let isJSONParseable = false;
        try {
            isJSONParseable = JSON.parse(modelo[this.propPermission]);
            if (isJSONParseable && typeof isJSONParseable === "object") {
                isJSONParseable = true;
            }
        }
        catch (e) {
            isJSONParseable = false;
        }
        if (isJSONParseable)
        {
          let seleccionadosDesdeModelo = JSON.parse(modelo[this.propPermission]);
          if(Array.isArray(seleccionadosDesdeModelo))
          {
            seleccionadosDesdeModelo.forEach(element => {
              let itemSeleccionado = this.items.find(x  => x[this.filterProp] === element[this.filterProp]);
              if(itemSeleccionado)
              {
                itemSeleccionado.Seleccionado = true;
                itemSeleccionado.Consultar = true;
                itemSeleccionado.Editar = true;
                itemSeleccionado.Eliminar = true;
                this.ListaPermisos.Add(itemSeleccionado);
              }
            });
          }
        }
      }
    });
  }
  public SetSeleccionados() {
    
    for (let i = 0; i < this.items.length; i++) {
      let permiso = this.ListaPermisos.FirstOrDefault(x => { return x[this.filterProp] == this.items[i][this.filterProp] && (x.Tipo == this.type || x.Tipo == this.stringType ) });

      if (permiso) {
        this.items[i].Seleccionado = true;
        this.items[i].Consultar = true;
        this.items[i].Editar = true;
        this.items[i].Eliminar = true;
        //this.utility.SetPermisosItemActual(this.items[i], permiso, false);

      }
      else {
        this.items[i].Seleccionado = false;
        this.items[i].Consultar = false;
        this.items[i].Editar = false;
        this.items[i].Eliminar = false;
        //this.utility.SetValorPermisosItemActual(this.items[i], false, false, false);
      }
      //permitidos[i].Seleccionado = this.ListaPermisos.Any(x => { return x.Tipo == tipoParaPermisos });
    }
  }

  public Adicionar(item: any) {
    

    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = true;
    item.Consultar = true;
    item.Editar = true;
    item.Eliminar = true;
/**
 * INICIO
 * 10/12/2020 18:41
 * Edward
 * Seleccionar solo un usuario
 */
    this.ListaPermisos.ToArray().length = 0;
     
    for(var i=1;i<=this.items.length;i++){
     
      if(document.getElementById("seleccionar_"+i)){
      (<HTMLInputElement> document.getElementById("seleccionar_"+i)).src = this.imagen;  //"../assets/images/jstree/usuario.png";
    }
    }
    /**
     * FIN
     */
    

    if (!this.ListaPermisos.Any(x => { return x[this.filterProp] == item[this.filterProp] && (x.Tipo == this.type || x.Tipo == this.stringType ) })) {
      this.ListaPermisos.Add(item);
      this.modelo[this.propPermission] = this.ListaPermisos.ToArray();
    }

  }

  public Eliminar(item: any) {
    
    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = false;
    item.Consultar = false;
    item.Editar = false;
    item.Eliminar = false;

    this.ListaPermisos = this.ListaPermisos.RemoveAll(x => { return x[this.filterProp] == item[this.filterProp] && x.Tipo == this.type });
    this.modelo[this.propPermission] = this.ListaPermisos.ToArray();
  }

  public Seleccionar(item: any) {
    
    if (item.Seleccionado == true) {
      this.Eliminar(item);
    }
    else {
      this.Adicionar(item);
    }
  }
}
