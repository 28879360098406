import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  // Método para obtener la traducción
  getTranslation(key: string) {
    return this.translate.instant(key);
  }
}