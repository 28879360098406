import { Component, OnInit, Input, Output, forwardRef, EventEmitter, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control';

//* FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

export enum DateTimePikerTypes {
  DateTime = 1,
  Date,
  Time,
}

@Component({
  selector: 'app-date-box',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateBoxComponent),
      multi: true
    }
  ],
  templateUrl: './date-box.component.html',
  styleUrls: ['./date-box.component.scss']
})

export class DateBoxComponent extends BaseControl implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() dateTimePikerType: DateTimePikerTypes = DateTimePikerTypes.DateTime;
  @Input() format: string = 'DD/MM/YYYY HH:mm';
  @Input() lang: string = 'es';
  @Input() weekStart: number = 1;
  @Input() cancelText: string = 'Cancelar';
  @Input() okText: string;
  @Input() clearText: string = 'Limpiar';
  @Input() minDate: any;
  @Input() maxDate: any;

  fromRight = true;

  //* FUNCIONES ANGULAR

  ngAfterViewInit() {
    let DatePickerElement = (<any>$("#DatePicker_" + this.controlName));
    let min = undefined;
    let max = undefined;
    try {
      min = eval(this.config.Min);
    } catch (error) {
      if (!isNaN(this.config.Max)) {
        max = Number(this.config.Max);
      }
    }
    try {
      max = eval(this.config.Max);
    } catch (error) {
      //Max no es una función ni booleano
      if (!isNaN(this.config.Max)) {
        max = Number(this.config.Max);
      }
    }
    this.config.FabricElement = new fabric['DatePicker'](DatePickerElement,
      {
        onSet: (thingSet) => {
          this.modelo = this.config.FabricElement.picker.get('select', ' dd/mm/yyyy');
          this.ctr.setValue(this.config.FabricElement.picker.get('select', ' dd/mm/yyyy'));
        },
        //ES6: min: min === min,
        min,
        max,
        //Día inicial sea lunes (true === Lunes, false === Domingo)
        firstDay: Number(this.config.FirstDayMonday),
        disable: this.config.DaysToDisable && this.config.DaysToDisable.length > 0 ? this.config.DaysToDisable : undefined


      });
    // var distanceR = $(window).width() - (DatePickerElement.offset().left + DatePickerElement.width());
    // if (distanceR <= 100) {
    //   $('#' + this.controlName + '_root').children('.ms-DatePicker-holder').css('right', '0');
    // }
    if (this.config.StickToSide) {
      switch (this.config.StickToSide) {
        case ('left'):
        case ('right'):
          $('#' + this.controlName + '_root').children('.ms-DatePicker-holder').css(this.config.StickToSide, '0');
          break;
      }
    }

  }

  //* END FUNCIONES ANGULAR

}