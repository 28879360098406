import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import {
  nodeTypes, TipoObjetoParaPermisos, TipoParaPermisos
} from '../models/general.enum';
import { StaticData } from '../helpers/static-data';
import { Utilities } from '../helpers/utilities';
import { List } from '../../assets/linqts/compilado/index';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { HelperImages } from '../helpers/helper-images';

export class BaseTextoSplit implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  @Output() onInitialized = new EventEmitter<any>();
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() config: any;
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;

  @Input() index: number = -1;
  @Input() modelo: any;
  @Input() inAfterViewInit: boolean;
  @Input() name: string = 'datatable-texto-split'
  dtOptions: any;
  // dtOptions: DataTables.Settings = {};
  @Input() prop: string = 'PalabrasClave';
  @Input() splitChar: string = ',';
  @Input() controlName: string = 'PalabrasClave';
  @Input() componentName: string = 'BaseTextoSplit';
  dtTrigger = new Subject<any>();
  @Input() prefijo: string = null;
  items: Array<any> = [];

  texto: string;
  imagenPalabra: string;
  Pagina: any = {
    ConfigPage: {
      Controls: [
        { ColSpan: 6, Activo: true }
      ]
    }
  };
  alertaExiste: string;
  isEmpty: boolean = false;

  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;
  //@Inject(forwardRef(() => Utilities))
  constructor(public utility: Utilities, public helperImages: HelperImages) {

    // this.OnInit();
    this.dtOptions = this.utility.GetDtOptionsHtmlTable();
    this.OnInit();
  }

  OnInit() {



    this.SetModelo(this.modelo, true);
    this.name = this.name + this.index;
    //this.OnInitEnd();
  }
  FireAction(actionType: string) {
    this.onActionEventEmitter.emit({
      modelo: this.modelo,
      type: this.name,
      componentName: this.componentName,
      actionType: actionType,
    });
  }
  public OnSetConfig() {


    if (this.config) {


      this.prop = this.config.Property || this.prop;
      this.splitChar = this.config.SplitChar || this.splitChar;
      this.name = this.config.Name || this.name;
      this.name = this.name + this.index;

      let _config = {
        "Property": "PalabrasClave",
        "SplitChar": ",",
        "Name": "datatable-texto-split"

      };

    }
  }
  public SetModelo(modelo: any, isInit: boolean = false) {

    this.modelo = modelo;

    if (this.modelo) {

      if (this.isModelContainer)
        this.items = this.utility.GetPermitidos(this.modelo.modelo, false, this.prop, this.splitChar);
      else
        this.items = this.utility.GetPermitidos(this.modelo, false, this.prop, this.splitChar);
    }
    else {
      this.items = [];
    }

    if (!isInit) {

      this.rerender();
    }

    this.SetTextoProp();



  }

  public SetTextoProp() {
    if (this.isModelContainer) {
      if (this.modelo)
        this.modelo.modelo[this.prop] = this.items.join(this.splitChar);
    }
    else {
      if (this.modelo)
        this.modelo[this.prop] = this.items.join(this.splitChar);
    }
  }
  public Eliminar(index: number) {

    if (this.Disabled)
      return;
    this.items.splice(index, 1);
    this.rerender();
    //this.dtTrigger.next();

    this.SetTextoProp();
    this.FireAction('Delete');
    // this.dtOptions = this.utility.GetDtOptionsHtmlTable();

  }
  public Adicionar() {
    if (this.Disabled)
      return;
    this.alertaExiste = null;

    if (this.texto) {

      if (this.prefijo && !this.texto.startsWith(this.prefijo)) {
        this.texto = this.prefijo + this.texto;
      }
      this.texto = this.texto.trim();
      if (!(new List<string>(this.items).Any(x => { return x.trim().toLowerCase() == this.texto.toLowerCase() }))) {
        this.items.push(this.texto);

        this.rerender();
        // this.dtTrigger.next();
        this.texto = ''
        this.SetTextoProp();
        // this.dtOptions = this.utility.GetDtOptionsHtmlTable();
        this.FireAction('Add');
      }
      else
        this.alertaExiste = '' + this.texto + ' Ya existe en la coleccion..'
    }


  }
  public OnInitEnd() {

  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

    this.dtTrigger.unsubscribe();
    this.utility.Unsubscribe(this.parentClearCall);
    this.utility.Unsubscribe(this.parentCallRefresh);
  }
  ngOnInit() {

    this.OnSetConfig();
    //this.OnInit();
    // this.dtOptions = {
    //     pagingType: 'full_numbers',
    //     pageLength: 2,
    //     destroy: true,
    // };
    //  if (this.inAfterViewInit)
    this.SetModelo(this.modelo, true);

    if (this.parentClearCall)
      this.parentClearCall.subscribe(event => {
        setTimeout(() => {
          this.modelo = event.modelo;
          this.SetModelo(event.modelo, false);

        }, 100);


      });

    if (this.onInitialized.observers.length) {
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }

  }

  ngAfterViewInit() {

    const that = this;

    this.dtOptions.initComplete = () => {

      $('#' + that.name  + '_filter button').bind('click', function (e) {

        var input = this as HTMLInputElement;

        that.utility.GetDataTabledtInstance(that.dtElement, that.componentName, that.componentName).then(dtInstance => {
          dtInstance.search(input.value).draw();
          $('#msSearchBox_' + that.name + ' > input').focus();
        }).catch(error => { that.utility.logger.LogError(error, { value: input.value, type: 'search' }) })

      });

    }


    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first

      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
