import { ConfigWindow } from '../models/config-window';
import { Utilities } from './utilities';
import { DataTableDirective } from 'angular-datatables';
import { QueryList } from '@angular/core';
// import { DataTableComponent } from '../components/controls/data-table/data-table.component';
// import { DashboardComponent } from '../components/controls/dashboard/dashboard.component';
// import { ChartComponent } from '../components/controls/chart/chart.component';

declare var FileSaver: any;
declare var saveAs: any;
declare var XLSX: any;
declare var XLSXStyle: any;
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const CSV_TYPE = 'text/plain;charset=UTF-8';
const CSV_EXTENSION = '.csv';

export class HelperCharts {

    constructor(public context: any, public getNameById: boolean, public utility: Utilities,
        public property: string, public isTable: boolean) {
        if (this.context[this.property].PagesChild && this.context[this.property].PagesChild.length > 0) {
            this.context[this.property].PagesChild[0].Selected = true;
        }
    }

    public SetlabelsTiposFilter() {
        this.context.labelTipoControlFiltro = (this.context[this.property].ModalFilter) ? "Cambiar a Control Filtros" : "Cambiar a Modal Filtros";
        this.context.labelTipoFiltro = (this.context[this.property].WithQueryBuilder) ? "Cambiar a Filtros Estaticos" : "Cambiar a Filtros Dinamicos";
    }
    public GetFilter(child, filterChildReport, event) {
        if (!child.Filters)
            return filterChildReport;
        if (child.AsParameter) {
            filterChildReport.Parameters = [];
            child.Filters.forEach(filter => {
                filterChildReport.Parameters.push({ Name: filter.DestinationField, Value: event[filter.SourceField] });
            })
        }
        else {
            let strFilter = "";
            let i: number = 0;

            child.Filters.forEach(filter => {


                if (i > 0)
                    strFilter += " AND ";
                let internalValue = event[filter.SourceField];
                switch (filter.DataType) {
                    case 'String':
                        strFilter += " " + filter.DestinationField + " LIKE '%" + internalValue + "%' ";
                        break
                    case 'DateTime':
                        strFilter += " " + filter.DestinationField + " = CONVERT(DATETIME,'" + internalValue + "',103)";
                        break;
                    case 'Boolean':
                        //filter += " CONVERT(DATETIME,'" + value + "',103)";
                        if (internalValue == "true")
                            strFilter += " " + filter.DestinationField + " = 1";
                        else
                            strFilter += " " + filter.DestinationField + " = 0";
                        break;
                    default:
                        strFilter += " " + filter.DestinationField + " = " + internalValue + " ";
                        break


                }
                i++;


            });
            filterChildReport.Filter = strFilter;
        }

        return filterChildReport;
    }

    ProcesarUrlNavigation(item: any) {

        let queryString: Array<string> = [];
        let queryParams = {};
        if (item.MappingParameters && item.MappingParameters.length > 0) {
            item.MappingParameters.forEach(parameter => {

                if (!parameter.ModelContainer)
                    parameter.ModelContainer = "currentItem";
                const value = this.utility.GetParmeterValue(parameter, this.context.modelo, this.context.form, item, null, this.context, this.context.parentContext);
                queryString.push(`'${parameter.Name}'='${value}' `);
                queryParams[parameter.Name] = value;
            });
        }
        
        if (item.PagePath) {
            if (queryString.length > 0) {
                this.utility.router.navigate([item.PagePath], { queryParams: queryParams });
            }
            else
                this.utility.router.navigate([item.PagePath]);
        }
        else if (item.ExtenalNavigation) {

            if (queryString.length > 0) {
                item.ExtenalNavigation += "?" + queryString.join("&");
            }

            if (item.ExtenalNavigation.includes("window.open")) {
                eval(item.ExtenalNavigation);
            }
            else
                window.location.href = item.ExtenalNavigation
        }

    }
    ProcessReportChild(childReport: any, excludeNavigation: any) {

        let filterChildReport: any = { Source: this.context.PaginaPadre.ConfigPage.Ruta };

        filterChildReport = this.GetFilter(childReport, filterChildReport, event);

        if (childReport.Modal) {
            this.ModalChildReport(childReport, filterChildReport);
        }
        else {
            if (!excludeNavigation) {
                filterChildReport.IsFromParentNavigation = true;
                this.utility["DashboardFilterParent"] = filterChildReport;
                this.utility.router.navigate([childReport.Object.Ruta]);
            }
        }
    }
    public FilterChilds(event) {
        


        if (this.context[this.property].ReportsChild && this.context[this.property].ReportsChild.length > 0) {

            this.context[this.property].ReportsChild.forEach(childReport => {

                if (childReport.Selected)
                    this.ProcessReportChild(childReport, true);
            });
        }

        // if (this.context[this.property].ViewAllOnSelection) {

        //     if (this.context[this.property].ReportsChild && this.context[this.property].ReportsChild.length > 0) {

        //         this.context[this.property].ReportsChild.forEach(childReport => {

        //             this.ProcessReportChild(childReport, true);
        //         });
        //     }
        // }
        // else {
        //     if (this.context.childReport)
        //         this.ProcessReportChild(this.context.childReport, false);
        // }


        // if (this.context.childReport) {
        //     let filterChildReport: any = { Source: this.context.PaginaPadre.ConfigPage.Ruta };

        //     filterChildReport = this.GetFilter(this.context.childReport, filterChildReport, event);

        //     if (this.context.childReport.Modal) {
        //         this.ModalChildReport(filterChildReport);
        //     }
        //     else {
        //         filterChildReport.IsFromParentNavigation = true;
        //         this.utility["DashboardFilterParent"] = filterChildReport;
        //         this.utility.router.navigate([this.context.childReport.Object.Ruta]);
        //     }
        // }
        if (this.context[this.property].ChartsChild && this.context[this.property].ChartsChild.length > 0) {

            this.context[this.property].ChartsChild.forEach(chartChild => {
                let filteChartrChild: any = (chartChild.AsParameter) ? { Parameters: [] } : { Filter: "" };

                filteChartrChild = this.GetFilter(chartChild, filteChartrChild, event);
                let child = this.context.componentsContainer.Find(chartChild.Object.Name);

                child.ExcecuteDataSource(filteChartrChild);
                //this.context.SetFilteChartrChild(child,filteChartrChild);


                // if (child instanceof DataTableComponent) {


                // }
                // else if (child instanceof DashboardComponent) {
                //     child.ExcecuteDataSource(filteChartrChild);
                // }
                // else if (child instanceof ChartComponent) {
                //     child.ExcecuteDataSource(filteChartrChild);
                // }
                // else if (child instanceof HtmlComponent) {
                //     child.ExcecuteDataSource(filteChartrChild);
                // }
            })
        }

        if (this.context[this.property].PagesChild && this.context[this.property].PagesChild.length > 0) {

            const pageChild = this.context[this.property].PagesChild.find(x => { return x.Selected });
            if (pageChild)
                this.ProcesarUrlNavigation(this.utility.Clone(pageChild));
            // this.context[this.property].PagesChild.forEach(pageChild => {

            //     this.ProcesarUrlNavigation(this.utility.Clone(pageChild));
            // })
        }
    }
    public ModalChildReport(childReport, filterChildReport) {
        

        let modeloTemp = {
            filterParent: filterChildReport,
            pageId: childReport.Object.IdPagina
        };

        let componentName = "DashboardComponent";
        let config: ConfigWindow = new ConfigWindow();
        config.classWindow = 'Info';
        config.titleWindow = childReport.Object.Name;
        config.returnInstance = true;
        config.modal = false;
        config.width = 800;
        config.height = 450;
        config.viewOk = false;
        config.cancel = 'Cerrar';
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;

        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {

                // dataResult.componentInstance.onSearchEvent.subscribe(result => {
                //   this.context.internalFilter = { Filter: result.filter };
                //   this.context.ExcecuteDataSource();

                // });
                // //}
                // dataResult.onClosePromise.then(dataResultOnClose => {

                // });

            })
    }
    public SetView() {

        if (this.context[this.property].FitContainer) {
            this.context.view = this.context[this.property].View;
            this.context[this.property].View = null;
        }
        else {
            this.context[this.property].View = this.context.view;

        }

    }

    public GetColumnData(data, labelColumnOrIndex, dataColumnOrIndex, extraDataColumnOrIndex, arr) {

        let resultArr = (arr) ? arr : [];
        const withExtraData = (extraDataColumnOrIndex != null && extraDataColumnOrIndex != '' && extraDataColumnOrIndex != undefined) ? true : false;
        if (withExtraData) {
            if (isNaN(labelColumnOrIndex) == true && isNaN(dataColumnOrIndex) == true && isNaN(extraDataColumnOrIndex) == true) {

                data.forEach(item => {
                    resultArr.push({ name: item[labelColumnOrIndex], value: item[dataColumnOrIndex], extra: item[extraDataColumnOrIndex] });
                });
                return resultArr;
            }
        }
        else {
            if (isNaN(labelColumnOrIndex) == true && isNaN(dataColumnOrIndex) == true) {

                data.forEach(item => {
                    resultArr.push({ name: item[labelColumnOrIndex], value: item[dataColumnOrIndex], extra: null });
                });
                return resultArr;
            }
        }

        $.each(data, (key, item) => {

            let indexColumn = -1;
            let name = null;
            let cellData = null;
            let extra = null;
            let extraIsSet = false;
            $.each(item, (keyColumn, value) => {

                indexColumn++;
                if (isNaN(labelColumnOrIndex) == false && indexColumn == labelColumnOrIndex) {
                    name = value;
                }
                else if (isNaN(labelColumnOrIndex) == true && keyColumn == labelColumnOrIndex) {
                    name = value;
                }

                if (isNaN(dataColumnOrIndex) == false && indexColumn == dataColumnOrIndex) {
                    cellData = value;
                }
                else if (isNaN(dataColumnOrIndex) == true && keyColumn == dataColumnOrIndex) {
                    cellData = value;
                }

                if (withExtraData) {
                    if (isNaN(extraDataColumnOrIndex) == false && indexColumn == extraDataColumnOrIndex) {
                        extra = value;
                        extraIsSet = true;
                    }
                    else if (isNaN(extraDataColumnOrIndex) == true && keyColumn == extraDataColumnOrIndex) {
                        extra = value;
                        extraIsSet = true;
                    }
                }
                if (withExtraData) {

                    if (name && cellData && extraIsSet) {

                        resultArr.push({ name: name, value: cellData, extra: extra });
                        name = null;
                        cellData = null;
                        return;
                    }
                } else {
                    if (name && cellData) {

                        resultArr.push({ name: name, value: cellData, extra: extra });
                        name = null;
                        cellData = null;
                        return;
                    }
                }

            });
        });
        return resultArr;
    }

    public GetRowData(data, labelColumnOrIndex, dataColumnOrIndex, extraDataColumnOrIndex, arr) {

        var resultArr = (arr) ? arr : [];

        $.each(data[labelColumnOrIndex], (key, value) => {
            resultArr.push({ name: value, value: null, extra: null });
        });
        let index = 0;
        $.each(data[dataColumnOrIndex], (key, value) => {
            resultArr[index].value = value;
            index++;
        });
        const withExtraData = (extraDataColumnOrIndex != null && extraDataColumnOrIndex != '' && extraDataColumnOrIndex != undefined) ? true : false;
        if (withExtraData) {
            index = 0;
            $.each(data[extraDataColumnOrIndex], (key, value) => {
                resultArr[index].extra = value;
                index++;
            });
        }
        return resultArr;
    }

    public GetData(index) {
        if (this.context[this.property].DataSources[index].UseType == "AllByColumns") {

            return this.GetColumnData(this.context[this.property].DataSources[index].data,
                this.context[this.property].DataSources[index].LabelColumnOrIndex,
                this.context[this.property].DataSources[index].DataColumnOrIndex,
                this.context[this.property].DataSources[index].ExtraDataColumnOrIndex, null);
        }
        else {
            return this.GetRowData(this.context[this.property].DataSources[index].data,
                this.context[this.property].DataSources[index].LabelColumnOrIndex,
                this.context[this.property].DataSources[index].DataColumnOrIndex,
                this.context[this.property].DataSources[index].ExtraDataColumnOrIndex, null);
        }
    }




    public ClearFilterParent() {
        this.context.filterParent = null;
        this.context.ExcecuteDataSource();
    }
    public ChangeFilterModal() {

        this.context[this.property].ModalFilter = !this.context[this.property].ModalFilter;
        this.context.viewFilterControl = (this.context[this.property].ModalFilter) ? false : true;
        this.SetlabelsTiposFilter();

    }
    public ChangeFilterWithQueryBuilder() {

        this.context[this.property].WithQueryBuilder = !this.context[this.property].WithQueryBuilder;
        this.SetlabelsTiposFilter();
    }
    ViewTable() {

        if (this.context[this.property].InfoSeries && this.context[this.property].InfoSeries.length > 0)
            this.context.viewMultiTable = !this.context.viewMultiTable;
        else
            this.context.viewSingleTable = !this.context.viewSingleTable;

        this.context.viewTable = !this.context.viewTable;
    }
    ViewFilter() {
        this.context.viewFilters = !this.context.viewFilters;
    }
    ExpandCollapse(event) {
        if (this.getNameById)
            $("#" + this.context.id).toggleClass("expand");
        else
            $("#" + this.context.name).toggleClass("expand");
        this.context.expandMode = !this.context.expandMode;
        this.context.RefreshData();
    }
    PrintNewWindow(body?: boolean) {
        
        const elementContainer = (body) ? document.body : (this.getNameById) ? document.getElementById(this.context.id) : document.getElementById(this.context.name);

        const w = window.open();
        w.document.write(elementContainer.innerHTML);
        w.print();
        w.close();
    }
    //   PrintNewWindow() {
    //     
    //     const elementContainer = document.getElementById(this.context.id);
    //     const w = window.open();
    //     w.document.write(elementContainer.innerHTML);
    //     w.print();
    //     w.close();
    //   }
    Print() {
        window.print();
    }

    DeleteFilter() {
        this.context.internalFilter = null;
        this.context.filterParent = null;
        this.context.ExcecuteDataSource();
    }
    Filter() {
        if (this.context[this.property].ModalFilter && this.context[this.property].FilterControls && this.context[this.property].FilterControls.length > 0) {
            this.ModalFilter();
        }
        else if (!this.context[this.property].ModalFilter && this.context[this.property].FilterControls && this.context[this.property].FilterControls.length > 0) {

            this.context.viewFilterControl = !this.context.viewFilterControl;

        }
    }

    SetAllReportsChild() {
        this.context[this.property].ViewAllOnSelection = !this.context[this.property].ViewAllOnSelection;
        this.context[this.property].ReportsChild.forEach(report => {
            report.Selected = this.context[this.property].ViewAllOnSelection;
        })

    }
    SetReportsChild(childReport, index) {

        
        // this.context[this.property].ReportsChild.forEach(report => {
        //     report.Selected = false;
        // })
        // if (this.context.childReport && this.context.childReport.Object.Name == childReport.Object.Name) {

        //     this.context.childReport = null;
        // }
        // else {
        //     childReport.Selected = true;
        //     //this.context.childReport = childReport;
        // }
        childReport.Selected = (childReport.Selected) ? false : true;
        this.context[this.property].ViewAllOnSelection =
            (this.context[this.property].ReportsChild.length == this.context[this.property].ReportsChild.filter(item => item.Selected == true).length);

    }
    SetPagesChild(childPage, index) {


        this.context[this.property].PagesChild.forEach(page => {
            page.Selected = false;
        })
        childPage.Selected = (childPage.Selected) ? false : true;


    }
    ModalFilter() {
        

        let modeloTemp = {

            config: this.context[this.property],
        };

        let componentName = "FiltersDataTableComponent";
        let config: ConfigWindow = new ConfigWindow();
        config.classWindow = 'Info';
        config.titleWindow = "Filtros";
        config.returnInstance = true;
        config.modal = false;
        config.width = 800;
        config.height = 400;
        config.viewOk = false;
        config.cancel = 'Cerrar';
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;

        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {

                dataResult.componentInstance.onSearchEvent.subscribe(result => {
                    this.context.internalFilter = { Filter: result.filter };
                    this.context.ExcecuteDataSource();

                });
                //}
                dataResult.onClosePromise.then(dataResultOnClose => {

                });

            })
    }
    Search(event) {
        this.context.internalFilter = { Filter: event.filter };
        this.context.ExcecuteDataSource();
    }
    ClearSearch(event) {
        this.context.internalFilter = null;
        this.context.ExcecuteDataSource();
    }

    OnChangeMinHeight(item) {

        if (this.context.minHeight) {

            if (!this.context[this.property].Style)
                this.context[this.property].Style = {};
            this.context[this.property].Style["min-height"] = this.context.minHeight;
        }
        else {
            delete this.context[this.property].Style["min-height"];
            this.context[this.property].Style = null;
        }
        this.context.RefreshData();
    }



    public Order(order, field = "value") {
        this.context.order = order;
        if (order == "asc") {
            this.context[this.property].Results = this.context[this.property].Results.sort((a, b) => {
                return a[field] - b[field];
            });
        }
        else {
            this.context[this.property].Results = this.context[this.property].Results.sort((a, b) => {
                return b[field] - a[field];
            });
        }
        this.context.RefreshData();
    }


    public GetCurrentDate(separator: any = "-") {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        let fecha = dd + separator + mm + separator + yyyy + ' ' + today.getHours() + today.getMinutes() + today.getSeconds();
        return fecha;
    }

    public ExportAsFile(json: any[], fileName: string, type, setDate: boolean = true): void {

        if (type == "csv") {
            this.ExportAsCsvFile(json, fileName, setDate);
        }
        else {
            this.ExportAsExcelFile(json, fileName, setDate);
        }
    }
    public GetFileName(ext) {
        let fileName: string = "";
        if (this.context[this.property].FileName)
            fileName = this.context[this.property].FileName + ext;
        else
            fileName = this.context[this.property].Name + ext;
        return fileName;
    }
    public ExportAsCsvFile(json: any[], fileName: string, setDate: boolean = true): void {
        if (!fileName) {
            fileName = this.GetFileName(".csv");
        }
        if (this.isTable && !json) {


            if (this.context.dtElement instanceof QueryList) {

                this.context.dtElement.forEach((dtElement: DataTableDirective, _index: number) => {

                    dtElement.dtInstance.then((dtInstance: any) => {
                        if (dtInstance.table().node().id == this.context.name) {

                            json = dtInstance.data();
                            const worksheet: any = XLSX.utils.json_to_sheet(json);
                            const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
                            this.SaveAsFile(csv, fileName, CSV_TYPE, CSV_EXTENSION);
                        }
                    });
                });
            }
            else {
                this.context.dtElement.dtInstance.then((dtInstance: any) => {
                    json = dtInstance.data();
                    const worksheet: any = XLSX.utils.json_to_sheet(json);
                    const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
                    this.SaveAsFile(csv, fileName, CSV_TYPE, CSV_EXTENSION);


                });
            }

        }
        else {
            if (!json) {
                json = this.context[this.property].Results;
            }


            const worksheet: any = XLSX.utils.json_to_sheet(json);
            const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
            this.SaveAsFile(csv, fileName, CSV_TYPE, CSV_EXTENSION);
        }

    }
    public ExportAsExcelFile(json: any[], fileName: string, setDate: boolean = true): void {

        if (!fileName) {
            fileName = this.GetFileName(".xlsx");
        }
        if (this.isTable && !json) {


            if (this.context.dtElement instanceof QueryList) {

                this.context.dtElement.forEach((dtElement: DataTableDirective, _index: number) => {

                    dtElement.dtInstance.then((dtInstance: any) => {
                        if (dtInstance.table().node().id == this.context.name) {

                            json = dtInstance.data();
                            const worksheet: any = XLSX.utils.json_to_sheet(json);

                            const workbook: any = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                            //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
                            this.SaveAsFile(excelBuffer, fileName, EXCEL_TYPE, EXCEL_EXTENSION);
                        }
                    });
                });
            }
            else {
                this.context.dtElement.dtInstance.then((dtInstance: any) => {

                    json = dtInstance.data();
                    const worksheet: any = XLSX.utils.json_to_sheet(json);

                    const workbook: any = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
                    this.SaveAsFile(excelBuffer, fileName, EXCEL_TYPE, EXCEL_EXTENSION);


                });
            }

        }
        else {
            if (!json) {
                json = this.context[this.property].Results;
            }


            const worksheet: any = XLSX.utils.json_to_sheet(json);

            const workbook: any = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            this.SaveAsFile(excelBuffer, fileName, EXCEL_TYPE, EXCEL_EXTENSION);
        }
    }

    private SaveAsFile(buffer: any, fileName: string, type, extension, setDate: boolean = true): void {
        const data: Blob = new Blob([buffer], {
            type: type
        });
        fileName = (setDate) ? fileName + '_' + this.GetCurrentDate() + extension : fileName + fileName
        saveAs(data, fileName);
    }

    public ReplaceQuickFilter(quickFilter) {
        if (quickFilter.Filter) {

            if (quickFilter.InfoModelsContainer && quickFilter.Filter.includes("#")) {
                let modelsnames: Array<any> = this.context[this.property].InfoModelsContainer.split(',');
                let tempModelo: any = {};
                modelsnames.forEach(modelName => {
                    tempModelo[modelName] = this.context.modelo[modelName];
                });
                quickFilter.FilterWithReplace = this.utility.ReplaceTextFromModels(tempModelo, quickFilter.Filter);
            }
            else {
                if (quickFilter.Filter.includes("#"))
                    quickFilter.FilterWithReplace = this.utility.ReplaceTextFromModels(this.context.modelo.modelo, quickFilter.Filter);

            }
        }
    }
}
