import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, OnDestroy, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import {TipoObjetoParaPermisos, TipoParaPermisos
} from '../../models/general.enum';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { ConfigWindow } from '../../models/config-window';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { HelperImages } from 'src/app/helpers/helper-images';
import { BaseServiceService } from 'src/app/services/base-service.service';

@Component({
  selector: 'app-comando-campo',
  templateUrl: './comando-campo.component.html',
  styleUrls: ['./comando-campo.component.css']
})
export class ComandoCampoComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  @Input() name: string = 'datatable-comandos';
  @Input() esComando: boolean = true;
  @Input() esParametro: boolean = false;
  dtOptions: any;
  dtTrigger = new Subject<any>();
  @Input() items: Array<any> = [];

  item: any = { Nombre: '', Etiqueta: '',Modelo:null,Valor:null };

  Pagina: any = {
    ConfigPage: {
      Controls: [

        { ColSpan: 4, Label: 'Etiqueta',Activo:true },
        { ColSpan: 4, Label: 'Nombre',Activo:true  },

      ]
    }
  };
  alertaExiste: string;
  constructor(public utility: Utilities,public helperImages: HelperImages,
    public baseService:BaseServiceService) {

    this.OnInit();
  }


  OnInit() {


    this.dtOptions = this.utility.GetDtOptionsHtmlTable();
  

  }


  public Eliminar(index: number) {

    this.items.splice(index, 1);
    this.rerender();

  }
  public Adicionar() {

    this.alertaExiste = null;
    if (this.item.Nombre) {

      this.item.Nombre = this.item.Nombre.trim();
      if (this.item.Etiqueta)
        this.item.Etiqueta = this.item.Etiqueta.trim();
      if (!(new List<any>(this.items).Any(x => { return x.Nombre.trim().toLowerCase() == this.item.Nombre.toLowerCase() }))) {
        this.items.push(this.item);
        this.item = { Nombre: '', Etiqueta: '' };
        this.rerender();
      }
      else
        this.alertaExiste = 'El Nombre  ' + this.item.Nombre + ' Ya existe..'
    }


  }
  public AdicionarCampo(comando, esParametro=false) {

    if (!comando.Campos)
      comando.Campos = [];

      if (!comando.Parametros)
      comando.Parametros = [];
    const that = this;
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = (esParametro)?"Admin Parametros":"Admin Campos";
    config.returnInstance = false;
    config.modal = false;
    config.width = 750;
    config.height = 400;
    let windowModel: any = {
      items:(esParametro)? comando.Parametros: comando.Campos,
      esComando: false,
      esParametro:esParametro
    };

    this.utility.OpenWindow('ComandoCampoComponent',
      config, windowModel).then(dataResult => {


        dataResult.onClosePromise.then(dataResultOnClose => {

          
          if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

            let o = dataResultOnClose.modelo


          }

        });

      })

  }

  public VerComando(comando) {
    let obj: any = null;
    let componentName = "RConfiguracionGeneral";
    try {

      
      const that = this;


      let processMessage = 'Procesando ';
      let modelo: any = {};
     
      modelo.DataAdd = {
        "NombreComando": comando.Nombre
      };
      obj = this.utility.GetApiModel('VerComando', componentName, null, null, modelo);
      let url = componentName + '/GetNames';
      this.baseService.Ejecutar(obj, null, {
        componentName: componentName + 'Controller',
        method: 'VerComando', processMessage: processMessage
      },
        false, url, false, true).then(data => {
          

          this.utility.VerEditor(2,data.Data[0].ROUTINE_DEFINITION,false,"Comando","sql",true);
        


        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: componentName, method: 'Operations' });
        });
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: componentName, method: 'Operations' });
      this.utility.VerModalDanger().then(data => { })
    }
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  ngOnInit() {

  }

  ngAfterViewInit() {

    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first

      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
