import { Component, OnInit, ViewContainerRef, Compiler, Injector, NgModuleRef, ViewChild, AfterViewInit, OnDestroy, NgModule, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs/Subject';
//import { AppModule } from 'src/app/app.module';
import { GeneralFormComponent } from '../general-form/general-form.component';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
@Component({
  selector: 'app-dynamic-html',
  templateUrl: './dynamic-html.component.html',
  styleUrls: ['./dynamic-html.component.scss']
})

export class DynamicHtmlComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('vc', { read: ViewContainerRef, static: false }) vc: ViewContainerRef;

  @Input() config: any = null;
  @Input() modelo: any = null;
  @Input() modeloExterno: any = { Nombre: "Hoaosd", Apellido: null };
  @Input() template: string = null;
  public stringTemplate = new Subject<string>();
  public stringTemplate$ = this.stringTemplate.asObservable()
  cmpRef: any = null;

  constructor(private _compiler: Compiler,
    private _injector: Injector,
    private _m: NgModuleRef<any>,
    public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) { }

  //* FUNCIONES ANGULAR

  public subscription = this.stringTemplate$.subscribe(data => {
    if (this.cmpRef)
      this.cmpRef.destroy();
    this.template = data;
    this.renderHtml();
  });

  ngOnInit() {

    if (this.config) {
      if (this.config.Template)
        this.template = this.config.Template;
      if (this.config.Modelo)
        this.modeloExterno = this.config.Modelo;
      this.config.stringTemplate = this.stringTemplate;
    }

  }
  renderHtml() {
    
    if (!this.template)
      this.template = '';

    const that = this;
    const selector = "app-anonimus-comp";
    const tmpCmp = Component({ template: this.template, selector: selector })(class Temp extends GeneralFormComponent {
      
      Pagina: any = {
        Controlador: "General",
        Valida: true, ConfigPage:
          { ExtraControls: [], Controls: [] }
      };

      public OnActionEventHandler(event) {

      
      }

      verTabla=false;
       configDataTable:any = {
        Name: 'TablaTemp', PkName: 'Id',
        ConfigControl: {
          NotLoad: true,
          Controller: 'RCiudad'
        },
        Controls: [{ ColumnType: 'General', Name: 'RCiudad.IdCiudad', Title: 'Id', DataField: 'IdCiudad' },
          { ColumnType: 'General', Name: 'RCiudad.Nombre', Title: 'Nombre', DataField: 'Nombre' }]
      };

      constructor() {
        super(that.baseService, that.utility, that.helperImages, that.helperRules, that.helperActions);
      }
     

      GetControlClassByNgForm  (frm, controlName) {
        if (frm) {
          var ctr = frm.controls[controlName];
          if (ctr && (ctr.invalid && !ctr.pristine || ctr.invalid && ctr.pristine)) {
            return 'is-invalid';
          }
          else
            return '';
        }
        else
          return '';
      };
      // config: any = that.config;
      // modelo: any = that.modelo;
      // modeloExterno: any = { Nombre: "Hoaosd", Apellido: null };
    });
    
    const tmpModule = NgModule({
      declarations: [tmpCmp],
      imports: [
        FormsModule,
        CommonModule//,
        //AppModule
      ]
    })(class {
    });

    this._compiler.compileModuleAndAllComponentsAsync(tmpModule)
      .then((factories) => {
        
        const f = factories.componentFactories.find(x => x.selector == selector);
        that.cmpRef = this.vc.createComponent(f);
        
        that.cmpRef.instance.name = 'dynamic';

        if (that.config) {
          // cmpRef.instance.config = this.config;
          /*  if (that.config.Modelo)
             that.modeloExterno = that.config.Modelo;
           if (that.config.Name) */
          that.cmpRef.instance.name = that.config.Name;
        }
        if (that.config.Modelo)
          that.cmpRef.instance.modeloExterno = that.config.Modelo;
        //cmpRef.instance.modelo = that.modelo;
      })

    // const template = '<span>I am {{name}}</span>';

    //   const tmpCmp = Component({template: template})(class {
    //   });
    //   const tmpModule = NgModule({declarations: [tmpCmp]})(class {
    //   });

    // this._compiler.compileModuleAndAllComponentsAsync(tmpModule)
    //     .then((factories) => {
    //         const f = factories.componentFactories[0];
    //         const cmpRef = f.create(this._injector, [], null, this._m);
    //         cmpRef.instance.name = 'B component';
    //         this._container.insert(cmpRef.hostView);
    //     })

  }

  ngAfterViewInit() {

    this.renderHtml();
  }

  ngOnDestroy() {

    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
    this.subscription.unsubscribe();

  }

  //* END FUNCIONES ANGULAR

}
