import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
} from '@angular/core';
import { WindowComponent } from './window.component';


@Injectable()
export class WindowService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) {
  }


  initWindow(containerId?: string): WindowComponent<any> {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(WindowComponent)
      .create(this.injector);
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);
    // Get DOM element from component
    const modal = componentRef.instance as WindowComponent<any>;
    const ref = this.appRef;
    const containerElement = (containerId) ? document.querySelector("#" + containerId) : null;
    modal.title = 'asdasd';
    modal.closeListener = {
      close() {
        ref.detachView(componentRef.hostView);
        if(componentRef.instance && componentRef.instance.config && !componentRef.instance.config.NotApplyDestroyAllSubscriptions){
          componentRef.destroy();
        }

      }
    };
    modal.openEvenet = {
      open() {
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement;
        if (containerElement)
          containerElement.appendChild(domElem);
        else
          // Append DOM element to the body
          document.body.appendChild(domElem);
      }
    }
    return modal;
  }
}

