import { Utilities } from './utilities';
import { forwardRef, Inject } from '@angular/core';

export class HelperImages {


  public ImagenTemplate: string = '';
  public ImagenEditorJson: string = '';
  public ImagenAEditorAce: string = '';
  public ImagenCambioDir: string = '';
  public ImagenEliminar: string = '';
  public ImagenEditar: string = '';
  public ImagenView: string = '';
  public ImagenViewFile: string = '';
  public ImagenAdicionar: string = '';
  public ImagenUnChecked: string = '';
  public ImagenPalabra: string = '';
  public ImagenClonar: string = '';
  public ImagenExpediente: string = '';
  public ImagenExpedientes: string = '';
  public ImagenDocumentosExpediente: string = '';
  public ImageTipoSolicitud: string = '';
  public ImagenNoPermitido: string = '';
  public ImagenTRD: string = '';
  public ImagenImportar: string = '';
  public ImagenExportar: string = '';
  public ImagenField: string = '';
  public ImagenKey_Foreign: string = '';
  public ImagenKey_Protected: string = '';
  public ImagenProperty: string = '';
  public ImagenProperties: string = '';
  public ImagenSql: string = '';
  public ImagenSqlFuction: string = '';
  public ImagenSqlOrder: string = '';
  public ImagenStoredProcedure: string = '';
  public ImagenTable: string = '';
  public ImagenNotify: string = '';
  public ImagenMessageBox: string = '';

  public ImagenPropertyGrid: string = '';

  public ImagenChecked: string = '';

  public ImagenUsuario: string = '';
  public ImagenRole: string = '';
  public ImagenGrupo: string = '';
  public ImagenArea: string = '';
  public ImagenPermisos: string = '';
  public ImagenItems: string = '';

  public ContainsCharactersValidator: string = '';
  public ValueComparisonValidator: string = '';
  public ConvertValidator: string = '';
  public RelativeDateTimeValidator: string = '';
  public DomainValidator: string = '';
  public RangeValidator: string = '';
  public RegexValidator: string = '';
  public RequiredValidator: string = '';
  public StringLengthValidator: string = '';
  public StringFunctionValidator: string = '';
  public FormatValidator: string = '';
  public Validators: string = '';
  public ActionFunctionQuery: string = '';
  public ActionFunctionValueToReplace: string = '';


  public ImagenTask: string = '';

  public ImagenCarpeta: string = '';

  //   { ObjType: 'QuerysNode', Icon: 'querys.png', NodeText: 'Consultas', GroupType: 'RuleEngine' },
  // { ObjType: 'RulesNode', Icon: 'reglas.png', NodeText: 'Reglas', GroupType: 'RuleEngine' },
  // { ObjType: 'Rule', Icon: 'rule2.png', NodeText: '', GroupType: 'RuleEngine' },


  // public QuerysNode: string = '';
  // public RulesNode: string = '';
  // public Rule: string = '';
  // public ActionTemplatesNode: string = '';
  // public GroupedActionTemplate: string = '';
  // public WordTemplate: string = '';
  // public ExcelTemplate: string = '';
  // public HtmlTemplate: string = '';
  // public XmlTemplate: string = '';
  // public CsvTemplate: string = '';
  // public TxtTemplate: string = '';
  // public JsonTemplate: string = '';

  // public ConfigNode: string = '';
  // public ConfigChildNode: string = '';

  // public AppNode: string = '';
  // public ApplicationAllowedMovil: string = '';
  // public ApplicationAllowedWeb: string = '';

  // public StaticVarsNode: string = '';
  // public GlobalVar: string = '';
  // public StaticVar: string = '';
  // public LocalStorageVar: string = '';
  // public SessionStorageVar: string = '';
  // public ContextVar: string = '';
  // public DbVar: string = '';
  // public SessionVar: string = '';
  // public AppVar: string = '';

  // public GroupedActionMail: string = '';
  // public ActionMail: string = '';
  // public ActionMailsNode: string = '';

  // public ConnectionsNode: string = '';
  // public ActiveDirectoryCnn: string = '';
  // public AssemblyCnn: string = '';
  // public DataBaseCnn: string = '';
  // public PowerShellCnn: string = ''
  // public ProcessCnn: string = '';
  // public SharePointCnn: string = '';
  // public SharePointOnlineCnn: string = '';
  // public WebServiceCnn: string = '';


  // public SqlServerCnn: string = '';
  // public OracleCnn: string = '';
  // public PostgreSQLCnn: string = '';
  // public MySqlCnn: string = '';
  // public SQLLiteCnn: string = '';
  // public AccessCnn: string = '';
  // public CsvCnn: string = '';
  // public DataCnn: string = '';
  // public ExcelCnn: string = '';
  // public GridCnn: string = '';
  // public HtmlCnn: string = '';
  // public JsonCnn: string = '';
  // public PdfCnn: string = '';
  // public SqlCnn: string = '';
  // public TxtCnn: string = '';
  // public XmlCnn: string = '';

  // public RestCnn: string = '';
  // public WCFCnn: string = '';
  // public TraditionalCnn: string = '';
  // public TraditionalSoapCnn: string = '';
  // public WCFSoapCnn: string = '';










  constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities) {

    this.ImagenCarpeta = this.utility.GetPathImages(this.utility.GetNodeType('Carpeta'));
    this.ImagenPermisos = this.utility.GetPathImages(this.utility.GetNodeType('Permisos'));
    this.ImagenAdicionar = this.utility.GetPathImages(this.utility.GetNodeType('Adicionar'));
    this.ImagenEliminar = this.utility.GetPathImages(this.utility.GetNodeType('Eliminar'));
    this.ImagenEditar = this.utility.GetPathImages(this.utility.GetNodeType('Editar'));
    this.ImagenCambioDir = this.utility.GetPathImages(this.utility.GetNodeType('CambioDireccion'));
    this.ImagenEditorJson = this.utility.GetPathImages(this.utility.GetNodeType('EditorJson'));
    this.ImagenAEditorAce = this.utility.GetPathImages(this.utility.GetNodeType('EditorAce'));
    this.ImagenUnChecked = this.utility.GetPathImages(this.utility.GetNodeType('Inactivo'));
    this.ImagenPalabra = this.utility.GetPathImages(this.utility.GetNodeType('PalabraClave'));
    this.ImagenClonar = this.utility.GetPathImages(this.utility.GetNodeType('Clonar'));
    this.ImagenExpediente = this.utility.GetPathImages(this.utility.GetNodeType('Expediente'));
    this.ImagenExpedientes = this.utility.GetPathImages(this.utility.GetNodeType('Expedientes'));
    this.ImagenDocumentosExpediente = this.utility.GetPathImages(this.utility.GetNodeType('DocumentosExpediente'));
    this.ImageTipoSolicitud= this.utility.GetPathImages(this.utility.GetNodeType('TipoSolicitud'));

    this.ImagenNoPermitido = this.utility.GetPathImages(this.utility.GetNodeType('NoPermitido'));
    this.ImagenTRD = this.utility.GetPathImages(this.utility.GetNodeType('TablaRetencionDocumental'));
    this.ImagenImportar = this.utility.GetPathImages(this.utility.GetNodeType('Importar'));
    this.ImagenExportar = this.utility.GetPathImages(this.utility.GetNodeType('Exportar'));

    this.ImagenField = this.utility.GetPathImages(this.utility.GetNodeType('Field'));
    this.ImagenKey_Foreign = this.utility.GetPathImages(this.utility.GetNodeType('Key_Foreign'));
    this.ImagenKey_Protected = this.utility.GetPathImages(this.utility.GetNodeType('Key_Protected'));
    this.ImagenProperty = this.utility.GetPathImages(this.utility.GetNodeType('Property'));
    this.ImagenProperties = this.utility.GetPathImages(this.utility.GetNodeType('Properties'));
    this.ImagenSql = this.utility.GetPathImages(this.utility.GetNodeType('Sql'));
    this.ImagenSqlFuction = this.utility.GetPathImages(this.utility.GetNodeType('SqlFuction'));
    this.ImagenSqlOrder = this.utility.GetPathImages(this.utility.GetNodeType('SqlOrder'));
    this.ImagenStoredProcedure = this.utility.GetPathImages(this.utility.GetNodeType('StoredProcedure'));
    this.ImagenTable = this.utility.GetPathImages(this.utility.GetNodeType('Table'));

    this.ImagenTask = this.utility.GetPathImages(this.utility.GetNodeType('Task'));

    this.ImagenChecked = this.utility.GetPathImages(this.utility.GetNodeType('Activo'));

    this.ImagenUsuario = this.utility.GetPathImages(this.utility.GetNodeType('Usuario'));
    this.ImagenRole = this.utility.GetPathImages(this.utility.GetNodeType('Role'));
    this.ImagenGrupo = this.utility.GetPathImages(this.utility.GetNodeType('Grupo'));
    this.ImagenArea = this.utility.GetPathImages(this.utility.GetNodeType('AreaEmpresa'));
    this.ImagenTemplate = this.utility.GetPathImages(this.utility.GetNodeType('Plantilla'));

    this.ImagenView = this.utility.GetPathImages(this.utility.GetNodeType('View'));
    this.ImagenViewFile = this.utility.GetPathImages(this.utility.GetNodeType('ViewFile'));
    this.ImagenItems = this.utility.GetPathImages(this.utility.GetNodeType('Items'));

    this.ContainsCharactersValidator = this.utility.GetPathImages(this.utility.GetNodeType('ContainsCharactersValidator'));
    this.ValueComparisonValidator = this.utility.GetPathImages(this.utility.GetNodeType('ValueComparisonValidator'));
    this.ConvertValidator = this.utility.GetPathImages(this.utility.GetNodeType('ConvertValidator'));
    this.RelativeDateTimeValidator = this.utility.GetPathImages(this.utility.GetNodeType('RelativeDateTimeValidator'));
    this.DomainValidator = this.utility.GetPathImages(this.utility.GetNodeType('DomainValidator'));
    this.RangeValidator = this.utility.GetPathImages(this.utility.GetNodeType('RangeValidator'));
    this.RegexValidator = this.utility.GetPathImages(this.utility.GetNodeType('RegexValidator'));
    this.RequiredValidator = this.utility.GetPathImages(this.utility.GetNodeType('RequiredValidator'));
    this.StringLengthValidator = this.utility.GetPathImages(this.utility.GetNodeType('StringLengthValidator'));
    this.StringFunctionValidator = this.utility.GetPathImages(this.utility.GetNodeType('StringFunctionValidator'));
    this.FormatValidator = this.utility.GetPathImages(this.utility.GetNodeType('FormatValidator'));
    this.Validators = this.utility.GetPathImages(this.utility.GetNodeType('Validators'));

    this.ActionFunctionQuery = this.utility.GetPathImages(this.utility.GetNodeType('ActionFunctionQuery'));
    this.ActionFunctionValueToReplace = this.utility.GetPathImages(this.utility.GetNodeType('ActionFunctionValueToReplace'));

    this.ImagenPropertyGrid =  this.utility.GetPathImages(this.utility.GetNodeType('PropertyGrid'));

    // this.QuerysNode = this.utility.GetPathImages(this.utility.GetNodeType('QuerysNode'));
    // this.RulesNode = this.utility.GetPathImages(this.utility.GetNodeType('RulesNode'));
    // this.Rule = this.utility.GetPathImages(this.utility.GetNodeType('Rule'));
    // this.ActionTemplatesNode = this.utility.GetPathImages(this.utility.GetNodeType('ActionTemplatesNode'));
    // this.GroupedActionTemplate = this.utility.GetPathImages(this.utility.GetNodeType('GroupedActionTemplate'));
    // this.WordTemplate = this.utility.GetPathImages(this.utility.GetNodeType('WordTemplate'));
    // this.ExcelTemplate = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.HtmlTemplate = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.XmlTemplate = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.CsvTemplate = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.TxtTemplate = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.JsonTemplate = this.utility.GetPathImages(this.utility.GetNodeType(''));

    // this.ConfigNode = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ConfigChildNode = this.utility.GetPathImages(this.utility.GetNodeType(''));

    // this.AppNode = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ApplicationAllowedMovil = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ApplicationAllowedWeb = this.utility.GetPathImages(this.utility.GetNodeType(''));

    // this.StaticVarsNode = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.GlobalVar = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.StaticVar = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.LocalStorageVar = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.SessionStorageVar = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ContextVar = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.DbVar = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.SessionVar = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.AppVar = this.utility.GetPathImages(this.utility.GetNodeType(''));

    // this.GroupedActionMail = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ActionMail = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ActionMailsNode = this.utility.GetPathImages(this.utility.GetNodeType(''));

    // this.ConnectionsNode = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ActiveDirectoryCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.AssemblyCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.DataBaseCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.PowerShellCnn: string = ''
    // this.ProcessCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.SharePointCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.SharePointOnlineCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.WebServiceCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));


    // this.SqlServerCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.OracleCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.PostgreSQLCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.MySqlCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.SQLLiteCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.AccessCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.CsvCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.DataCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.ExcelCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.GridCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.HtmlCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.JsonCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.PdfCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.SqlCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.TxtCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.XmlCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));

    // this.RestCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.WCFCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.TraditionalCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.TraditionalSoapCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));
    // this.WCFSoapCnn = this.utility.GetPathImages(this.utility.GetNodeType(''));


  }


  public GetRuleIcon(ObjectType) {


    let subTipo = ObjectType;

    switch (ObjectType) {
      case 'ConditionValue':
     // case 'ActionValue':
        subTipo = 'Property';
        break;
     /*  case 'ActionCondition':
        subTipo = 'Property';
        break; */
     /*  case 'ActionTemplate':
        subTipo = 'Plantilla';
        break; */
     // case 'ActionQuery':
    /*   case 'ConditionQuery':
        subTipo = 'Sql';
        break; */
      case 'ActionNotification':
        subTipo = 'Notify';
        break;
      default:
        break;
    }

    return this.utility.GetPathImages(this.utility.GetNodeType(subTipo));
  }

}


