import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { ConfigWindow } from '../../../models/config-window';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';

import { ContextMenuComponent } from 'ngx-contextmenu';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { PropertyGridComponent } from '../property-grid/property-grid.component';

@Component({
  selector: 'app-property-grid-containers',
  templateUrl: './property-grid-containers.component.html',
  styleUrls: ['./property-grid-containers.component.scss']
})
export class PropertyGridContainersComponent extends PropertyGridComponent implements OnInit {


}

