import {
    Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input,
    Output, EventEmitter, QueryList, forwardRef, Inject, ComponentFactoryResolver, Injector
} from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes } from '../models/general.enum';
import { Utilities } from '../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../services/base-service.service';
import { ModalNotifyComunicationService } from '../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../assets/linqts/compilado/index';
import { StaticData } from '../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { ConfigWindow } from '../models/config-window';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { HelperImages } from '../helpers/helper-images';






export class ConfigBaseComponent implements OnInit, AfterViewInit {



    @Input() componentName: string;
    @Input() Pagina: any = null;
    @Input() frm: NgForm;
    @Input() public isFirst: boolean = false;

    _subControl: any = null;
    @Input() set subControl(value: any) {
        this._subControl = value;
    };
    get subControl(): any {
        return this._subControl;
    }
    @Input() subControlIndex: number = null;
    @Input() subControlParent: any = null;
    @Input() cargarGrilla: boolean = false;
    @Input() modelo: any = null;
    @Input() childComponents: Array<any> = [];
    @Output() onInitialized = new EventEmitter<any>();
    @Input() form: FormGroup;

    // @Input() form: any;

    get IsValid() {

        return this.GetPropFromForm('valid', true);
    }
    //(!this.frm && this.frm.form) ? true : this.frm.form.controls[this.subControl.name].valid; }
    get IsDirty() {
        return this.GetPropFromForm('dirty', true);
        //return (!this.frm && this.frm.form) ? true : this.frm.form.controls[this.subControl.name].dirty; 
    }
    get IsTouched() {
        return this.GetPropFromForm('touched', true);
        // return (!this.frm && this.frm.form) ? true : this.frm.form.controls[this.subControl.name].touched;
    }
    get IsInvalid() {
        return this.GetPropFromForm('invalid', true);
        //return (!this.frm && this.frm.form) ? true : this.frm.form.controls[this.subControl.name].invalid;
    }
    get IsPristine() {
        return this.GetPropFromForm('pristine', true);
        //return (!this.frm && this.frm.form) ? true : this.frm.form.controls[this.subControl.name].invalid;
    }
    //control?.invalid && !control?.pristine || control?.invalid  && control?.pristine
    get IsGeneralInvalid() {
        //return ((this.IsInvalid && !this.IsPristine) || (this.IsInvalid && this.IsPristine));
        //  return ((this.IsInvalid && !this.IsPristine) || (this.IsInvalid && this.IsPristine));
        return (this.IsInvalid && (this.IsDirty || this.IsTouched));
        // form.get(input.controlName).invalid && (form.get(input.controlName).dirty || form.get(input.controlName).touched
        //return (!this.frm && this.frm.form) ? true : this.frm.form.controls[this.subControl.name].invalid;
    }
    get IsRequiredError() {
        return this.GetErrorFromForm('required', false);
        // return (!this.frm && this.frm.form) ? false : this.frm.form.get(this.subControl.name).errors.required; 
    }
    get IsMinlengthError() {
        return this.GetErrorFromForm('minlength', false);
        //return (!this.frm && this.frm.form) ? false : this.frm.form.get(this.subControl.name).errors.minlength; 
    }
    get IsMaxlengthError() {

        return this.GetErrorFromForm('maxlength', false);
        //return (!this.frm && this.frm.form) ? false : this.frm.form.get(this.subControl.name).errors.maxlength; 
    }

    get IsMinError() {
        return this.GetErrorFromForm('min', false);

    }
    get IsMaxError() {

        return this.GetErrorFromForm('max', false);

    }



    constructor(
        @Inject(forwardRef(() => Utilities)) public utility: Utilities,
        @Inject(forwardRef(() => BaseServiceService)) public baseService: BaseServiceService,
        public sanitizer: DomSanitizer,
        @Inject(forwardRef(() => HelperImages)) public helperImages: HelperImages) {

       

    }
    GetErrorFromForm(prop: string, defaultValue: any) {
        if (this.form)
            return this.form.get(this.subControl.Name).errors[prop];
        else if (!this.frm && this.frm.form)
            return this.frm.form.get(this.subControl.Name).errors[prop];
        else
            return defaultValue;
    }
    GetPropFromForm(prop: string, defaultValue: any) {
        if (this.form)
            return this.form.controls[this.subControl.Name][prop];
        else if (!this.frm && this.frm.form)
            return this.frm.form.controls[this.subControl.Name][prop];
        else
            return defaultValue;
    }
    public OnInitializedFunc(event) {

        if (this.subControl) {

            if (!this.childComponents)
                this.childComponents = [];
            let component = new List(this.childComponents).FirstOrDefault(x => { return x.component.componentName == event.componentName });
            if (!component)
                this.childComponents.push({ component: event, config: this.subControl.ParentOption });

        }
    }

    ngOnInit() {


    }

    ngAfterViewInit() {


    }

}
