/* eslint-disable quotes */

import { OpcionesPermisosCrearExpediente, TiposUbicacionArchivo } from "../models/general.enum";

import { Guid } from "guid-typescript";
import { List } from "../../assets/linqts/compilado/index";
import { ModalLoginComponent } from "../components/modal-login/modal-login.component";
import { OverlayComponent } from "../components/controls/overlay/overlay.component";
import { Stopwatch } from "ts-stopwatch";

declare let $: any;
export class StaticData {

    public static Overlay: OverlayComponent
    public static AppKey = "af1d7625-4454-c352-517f-49bb34edde57";
    public static AppName: string;
    public static UrlServer: string;
    public static HostingType: string;
    public static DynamicLoadResources = false;
    public static InSharePoint = false;
    public static ViewLogOut = false;
    public static DefaultUser: string = '';
    public static ViewDomainComboBox: boolean = false;
    public static ViewPasswordBox: boolean = false;
    public static Project: any;
    public static Usuario: any;
    public static Estilos: any;
    public static ParametrosGenerales: List<any>;
    public static BotonesComoMenu: boolean
    public static ModalLogin: ModalLoginComponent;
    public static ProcesosCarga: Array<any> = [];
    public static RolesUsuariosGrupos: List<any>;
    public static Carpetas: List<any>;
    public static AreasEmpresa: List<any>;
    public static Series: List<any>;
    public static SubSeries: List<any>;
    public static ModeloClone: any;
    public static ConsoleLogOk: Array<any> = [];
    public static ConsoleLogInfo: Array<any> = [];
    public static ConsoleLogAlerta: Array<any> = [];
    public static ConsoleLogError: Array<any> = [];
    public static ConsoleLogPermisos: Array<any> = [];
    public static ConsoleLogReglas: Array<any> = [];
    public static LoginLoadAllComponents: boolean;

    public static StopWatch: Stopwatch;
    public static StartProcess: any;
    public static StopWatchView: Stopwatch;
    public static StartProcessView: any;
    public static ApiGetIpClient: string = '';
    public static ConsultarIpClient: boolean = false;
    public static IdGuidAcesso: string = '';
    public static UrlServiciosWebExterno: string = '';

    public static GeneralConfig: any = {
        "Comandos": [],
        "ConfigUI": {
            "VerNavBar": true,
            "VerSideBar": true,
            "VerColorConfig": false,
            "ActiveShortCutSideBar": false,
            "PersonalizarTema": false,
        },
        "ConfigUICache": {
            "TipoUbicacion": "Aplicacion"
        },
        "ConfigUILog": {
            "VerTrazaError": true,

            "ImprimirError": true,
            "ImprimirInfo": true,
            "ImprimirOk": true,
            "ImprimirAlerta": true,

            "ImprimirRespuestaError": true,
            "ImprimirRespuestaInfo": true,
            "ImprimirRespuestaOk": true,
            "ImprimirRespuestaAlerta": true,

            "EnviarServidorError": false,
            "EnviarServidorInfo": false,
            "EnviarServidorOk": false,
            "EnviarServidorAlerta": false,
        },
        "ConfigTipoUbicacionArchivo":
        {
            "TipoUbicacionArchivo": TiposUbicacionArchivo.FileSystem,
            "Ruta": "", "Usuario": "", "Password": null
        },
        "ConfigExpediente": { OpcionesPermisos: OpcionesPermisosCrearExpediente.Ninguna },
        "ConfigDocumento": { OpcionesPermisos: OpcionesPermisosCrearExpediente.Ninguna }
    }
    public static Controls: [
        {
            "Type": "hidden",
            "Label": "HiddenField",
            "Icon": "fas fa-crop",
            "ControlType": "HiddenField"
        },
        {
            "Type": "text",
            "Icon": "fa-font",
            "Label": "Text",
            "ControlType": "TextBox"
        },
        {
            "Type": "email",
            "Icon": "fa-envelope",
            "Label": "Email",
            "ControlType": "EmailBox"
        },
        {
            "Type": "phone",
            "Icon": "fa-phone",
            "Label": "Phone",
            "ControlType": "PhoneBox"
        },
        {
            "Type": "number",
            "Icon": "fas fa-th-list",
            "Label": "Number",
            "ControlType": "NumberBox"
        },
        {
            "Type": "date",
            "Icon": "fa-calendar",
            "Label": "Date",
            "ControlType": "DateBox"
        },
        {
            "Type": "datetime-local",
            "Icon": "fa-calendar",
            "Label": "DateTime",
            "ControlType": "DateTimeBox"
        },
        {
            "Type": "textarea",
            "Icon": "fa-text-width",
            "Label": "TextArea",
            "ControlType": "TextArea"
        },
        {
            "Type": "select",
            "Label": "ComboBox",
            "Icon": "fa-list",
            "ControlType": "ComboBox"
        },
        {
            "Type": "select",
            "Label": "MultiComboBox",
            "Icon": "fas fa-th-list",
            "ControlType": "MultiComboBox"
        },
        {
            "Type": "checkbox",
            "Label": "Checkbox",
            "Icon": "far fa-check-square",
            "ControlType": "CheckBox"
        },
        {
            "Type": "ThreeStateToggle",
            "Label": "ThreeStateToggle",
            "Icon": "far fa-check-square",
            "ControlType": "ThreeStateToggle"
        },
        {
            "Type": "radio",
            "Icon": "fa-list-ul",
            "Label": "Radio",
            "ControlType": "RadioBox"
        },
        {
            "Type": "file",
            "Icon": "fa-file",
            "Label": "File Upload",
            "ControlType": "FileBox"
        },
        {
            "Type": "Label",
            "Icon": "fas fa-tags",
            "Label": "Label",
            "ControlType": "Label"
        },
        {
            "Type": "Paragraph",
            "Icon": "fa-paragraph",
            "Label": "Paragraph",
            "ControlType": "Paragraph"
        },
        {
            "Type": "H1",
            "Icon": "fas fa-heading",
            "Label": "H1",
            "ControlType": "H1"
        },
        {
            "Type": "H2",
            "Icon": "fas fa-heading",
            "Label": "H2",
            "ControlType": "H2"
        },
        {
            "Type": "H3",
            "Icon": "fas fa-heading",
            "Label": "H3",
            "ControlType": "H3"
        },
        {
            "Type": "H4",
            "Icon": "fas fa-heading",
            "Label": "H4",
            "ControlType": "H4"
        },
        {
            "Type": "H5",
            "Icon": "fas fa-heading",
            "Label": "H5",
            "ControlType": "H5"
        },
        {
            "Type": "Acoordion",
            "Icon": "fas fa-chevron-up",
            "Label": "Acoordion",
            "ControlType": "Acoordion"
        },
        {
            "Type": "Tabs",
            "Icon": "fas fa-outdent",
            "Label": "Tabs",
            "ControlType": "Tabs"
        },
        {
            "Type": "ContainerBox",
            "Icon": "fas fa-border-style",
            "Label": "ContainerBox",
            "ControlType": "ContainerBox"
        },
        {
            "Type": "DataTable",
            "Icon": "fas fa-border-style",
            "Label": "GridView",
            "ControlType": "GridView"
        },
        {
            "Type": "CrudButtons",
            "Icon": "fas fa-circle",
            "Label": "CrudButtons",
            "ControlType": "CrudButtons"
        },
        {
            "Type": "Div",
            "Icon": "fas fa-heading",
            "Label": "Div",
            "ControlType": "Div"
        },
        {
            "Type": "I",
            "Icon": "",
            "Label": "I",
            "ControlType": "I"
        }
    ]
    public static WorkFlowTaskConfig: any = null;
    public static ToolBox: any = {
        Id: 0, TipoDato: "", Name: "", Controls: [
            {
                "Type": "text",
                "Icon": "fa-font",
                "Label": "Text",
                "ControlType": "TextBox"
            },
            {
                "Type": "email",
                "Icon": "fa-envelope",
                "Label": "Email",
                "ControlType": "EmailBox"
            },
            {

                "Type": "phone",
                "Icon": "fa-phone",
                "Label": "Phone",
                "ControlType": "PhoneBox"
            },

            {

                "Type": "number",
                "Icon": "fas fa-th-list",
                "Label": "Number",
                "ControlType": "NumberBox"
            },
            {
                "Type": "date",
                "Icon": "fa-calendar",
                "Label": "Date",
                "ControlType": "DateBox"

            },
            {
                "Type": "datetime-local",
                "Icon": "fa-calendar",
                "Label": "DateTime",
                "ControlType": "DateTimeBox"
            },
            {
                "Type": "textarea",
                "Icon": "fa-text-width",
                "Label": "TextArea",
                "ControlType": "TextArea"
            },
            {
                "Type": "select",
                "Label": "ComboBox",
                "Icon": "fa-list",
                "ControlType": "ComboBox"
            },
            {
                "Type": "select",
                "Label": "MultiComboBox",
                "Icon": "fas fa-th-list",
                "ControlType": "MultiComboBox"
            },


            {
                "Type": "checkbox",
                "Label": "Checkbox",
                "Icon": "far fa-check-square",
                "ControlType": "CheckBox"
            },
            {
                "Type": "radio",
                "Icon": "fa-list-ul",
                "Label": "Radio",
                "ControlType": "RadioBox"
            },
            {
                "Type": "file",
                "Icon": "fa-file",
                "Label": "File Upload",
                "ControlType": "FileBox"
            },
            {
                "Type": "Label",
                "Icon": "fas fa-tags",
                "Label": "Label",
                "ControlType": "Label"
            },
            {
                "Type": "Paragraph",
                "Icon": "fa-paragraph",
                "Label": "Paragraph",
                "ControlType": "Paragraph"
            },
            {
                "Type": "H1",
                "Icon": "fas fa-heading",
                "Label": "H1",
                "ControlType": "H1"
            },
            {
                "Type": "H2",
                "Icon": "fas fa-heading",
                "Label": "H2",
                "ControlType": "H2"
            },
            {
                "Type": "H3",
                "Icon": "fas fa-heading",
                "Label": "H3",
                "ControlType": "H3"
            },
            {
                "Type": "H4",
                "Icon": "fas fa-heading",
                "Label": "H4",
                "ControlType": "H4"
            },
            {
                "Type": "H5",
                "Icon": "fas fa-heading",
                "Label": "H5",
                "ControlType": "H5"
            },

            {
                "Type": "Acoordion",
                "Icon": "fas fa-chevron-up",
                "Label": "Acoordion",
                "ControlType": "Acoordion"
            },
            {
                "Type": "Tabs",
                "Icon": "fas fa-outdent",
                "Label": "Tabs",
                "ControlType": "Tabs"
            },
            //<i class="fas fa-border-style"></i>
            {
                "Type": "ContainerBox",
                "Icon": "fas fa-border-style",
                "Label": "ContainerBox",
                "ControlType": "ContainerBox"
            },
            {
                "Type": "DataTable",
                "Icon": "fas fa-border-style",
                "Label": "GridView",
                "ControlType": "GridView"
            },
            {
                "Type": "CrudButtons",
                "Icon": "fas fa-circle",
                "Label": "CrudButtons",
                "ControlType": "CrudButtons"
            },
            {
                "Type": "Div",
                "Icon": "fas fa-heading",
                "Label": "Div",
                "ControlType": "Div"
            },
            {
                "Type": "I",
                "Icon": "",
                "Label": "I",
                "ControlType": "I"
            },

        ],
        UserControls: [],
        Charts: [],
        Widgets: [],
        CommonControls: [],
        Components: []
    }
    static UseWebWorker = false;
    public static Language: string = 'es';
    public static InitData(): void {
        StaticData.Usuario = null;

    }

    static notification: string = '   <div data-notify="container" class="ms-MessageBar col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4" role="alert">' +
        ' <div class="ms-MessageBar-content">' +
        '   <div class="ms-MessageBar-icon">' +
        '     <i class="ms-Icon ms-Icon--Info"></i>' +
        '<span data-notify="title">{1}</span> ' +
        '  </div>' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        ' <span aria-hidden="true">&times;</span>' +
        '</button>' +
        '  <div class="ms-MessageBar-text">' +

        '<span data-notify="message">{2}</span>' +
        '   </div>' +
        ' </div>' +
        '</div>';
    static NotificationTemplate2: string = '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        ' <span aria-hidden="true">&times;</span>' +
        '</button>' +
        '<i class="fa fa-bell" data-notify="icon"></i> ' +
        '<span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>';

    static NotificationTemplate3: string = '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        ' <span aria-hidden="true">&times;</span>' +
        '</button>' +
        '' +
        '<h4 class="alert-heading" data-notify="title"><i class="fa fa-bell" data-notify="icon"></i> {1}</h4> ' +
        '<hr/>' +

        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>';

    static ms_notification: string = '   <div data-notify="container" class="ms-MessageBar ms-MessageBar--{0} col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4" role="alert">' +
        ' <div class="ms-MessageBar-content">' +
        '   <div class="ms-MessageBar-icon">' +
        '     <i class="mx-2 ms-Icon ms-Icon--#Icono#"></i>' +

        '<span data-notify="title">{1}</span> ' +

        '  </div>' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        ' <span aria-hidden="true">&times;</span>' +
        '</button>' +
        '  <div class="pl-3 ms-MessageBar-text">' +
        '<span data-notify="message">{2}</span>' +
        '   </div>' +
        ' </div>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>';

    static notificationChangeColor: string = '   <div data-notify="container" class="ms-MessageBarLoadDocument col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4" role="alert">' +
        ' <div class="ms-MessageBar-content">' +
        '   <div class="ms-MessageBar-icon">' +
        '     <i class="ms-Icon ms-Icon--Info"></i>' +
        '<span data-notify="title">{1}</span> ' +
        '  </div>' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        ' <span aria-hidden="true">&times;</span>' +
        '</button>' +
        '  <div class="ms-MessageBar-text">' +

        '<span data-notify="message">{2}</span>' +
        '   </div>' +
        ' </div>' +
        '</div>';
    public static ShowNotification(message = "Procesando....",
        type = "warning",  from = "top", align = "right",
        title = "Procesando....", delay = 0, addToList = true, changeColor = false): any {
        var template = StaticData.notification;;
        if(changeColor)
            template = StaticData.notificationChangeColor;

        const notify = $.notify({
            icon: "notifications",
            message: message,
            title: title

        }, {
            type: type,

            delay: delay,
            placement: {
                from: from,
                align: align
            },
            template: template
        });

        notify.ID = Guid.create().toString();
        if (addToList)
            StaticData.ProcesosCarga.push(notify);
        return notify;
    }
    public static ShowActionNotification2(message = ".............",
        type = "warning",
        title = "Info", delay = 0): any {
        return StaticData.ShowActionNotification(message, type, "top", "right", title, delay);
    }
    public static ShowActionNotificationWarning(message = ".............",
        title = "Alerta", delay = 0): any {
        return StaticData.ShowActionNotification(message, "warning", "top", "right", title, delay);
    }
    public static ShowActionNotificationError(message = ".............",
        title = "Error", delay = 0): any {
        return StaticData.ShowActionNotification(message, "error", "top", "right", title, delay);
    }
    public static ShowActionNotificationSuccess(message = ".............",

        title = "Informacion", delay = 0): any {
        return StaticData.ShowActionNotification(message, "success", "top", "right", title, delay);
    }
    public static ShowActionNotificationInfo(message = ".............",

        title = "Informacion", delay = 0): any {
        return StaticData.ShowActionNotification(message, "info", "top", "right", title, delay);
    }
    public static ShowActionNotification(message = ".............",
        type = "warning", from = "top", align = "right",
        title = "Alerta", delay = 0, _url?: string, target?: string): any {

        let _ms_notification = "";
        switch (type) {
            case "info":
                _ms_notification = StaticData.ms_notification.replace("#Icono#", "Info");
                break;
            case "success":
                _ms_notification = StaticData.ms_notification.replace("#Icono#", "Completed");
                break;
            case "error":
                _ms_notification = StaticData.ms_notification.replace("#Icono#", "ErrorBadge");
                break;
            case "warning":
                _ms_notification = StaticData.ms_notification.replace("#Icono#", "Warning");
                break;
            case "severeWarning":
                type = "warning";
                _ms_notification = StaticData.ms_notification.replace("#Icono#", "Warning");
                break;
        }
        const notify = $.notify({
            icon: "notifications",
            message: message,
            title: title,
            url: _url,
            target: target

        }, {
            type: type,

            delay: delay,
            placement: {
                from: from,
                align: align
            },
            template: _ms_notification
        });

        notify.ID = Guid.create().toString();

        return notify;
    }

    public static ConfigPaginas: any = [{
        IdPagina: 19, Nombre: "Role", Config: {
            IdRole: { Name: "IdRole", minlength: 0, maxlength: 10, min: 0, max: 0, required: true, pattern: "'[ñáéíóúA-Za-z ]+'", controlType: "", dataType: "Int32", generalDataType: "Number", identity: true, isfk: false, modelContainer: "modelo", defaultvalue: null, title: "Campo IdRole", placeholder: "Digite IdRole" },
            Nombre: { Name: "Nombre", minlength: 0, maxlength: 100, min: 0, max: 0, required: true, pattern: "'[ñáéíóúA-Za-z ]+'", controlType: "TextBox", dataType: "String", generalDataType: "String", identity: false, isfk: false, modelContainer: "modelo", defaultvalue: null, title: "Campo Nombre", placeholder: "Digite Nombre" },
            Descripcion: { Name: "Descripcion", minlength: 0, maxlength: 400, min: 0, max: 0, required: false, pattern: "'[ñáéíóúA-Za-z ]+'", controlType: "TextBox", dataType: "String", generalDataType: "String", identity: false, isfk: false, modelContainer: "modelo", defaultvalue: null, title: "Campo Descripcion", placeholder: "Digite Descripcion" },
            Activo: { Name: "Activo", minlength: 0, maxlength: 0, min: 0, max: 0, required: true, pattern: "'[ñáéíóúA-Za-z ]+'", controlType: "CheckBox", dataType: "Boolean", generalDataType: "Boolean", identity: false, isfk: false, modelContainer: "modelo", defaultvalue: null, title: "Campo Activo", placeholder: "Digite Activo" },
        }
    }]
}
