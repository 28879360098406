import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, fieldName: string, startWith?: string): any[] {

    // return empty array if array is falsy
    if (!items) { return []; }

    // return the original array if search text is empty
    if (!searchText && !startWith) { return items; }

    // convert the searchText to lower case
    if (searchText)
      searchText = searchText.toLowerCase();

    if (startWith)
      startWith = startWith.toLowerCase();
    // retrun the filtered array
    if (startWith) {

      if (searchText) {
        return items.filter(item => {
          if (item && item[fieldName]) {
            return item[fieldName].toLowerCase().startWith(startWith) &&
              item[fieldName].includes(searchText);
          }
          return false;
        });
      }
      else {
        return items.filter(item => {
          if (item && item[fieldName]) {
            return item[fieldName].toLowerCase().toString().startWith(startWith);
          }
          return false;
        });
      }
    }
    else {
      return items.filter(item => {
        if (item && item[fieldName]) {
          return item[fieldName].toLowerCase().includes(searchText);
        }
        return false;
      });
    }
  }
}
