import { EventEmitter, Injectable } from "@angular/core";

import { ModalNotifyTypes } from "../components/modal-notify/modal-notify.component";
import { StaticData } from "../helpers/static-data";
import { Utilities } from "../helpers/utilities";

declare let $: any;
//node --max-old-space-size=X node_modules/@angular/cli/bin/ng build --prod
//NOTA: NG BUILD --PROD FALLA AQUÍ: https://github.com/angular/angular-cli/issues/11359
// @Injectable({
//     providedIn: "root"
// })
export class ScanDocumentsService {

  public onMessage: EventEmitter<any> = new EventEmitter<any>();
  public onOpen: EventEmitter<any> = new EventEmitter<any>();
  public onError: EventEmitter<any> = new EventEmitter<any>();
  public onClose: EventEmitter<any> = new EventEmitter<any>();
  public ws: WebSocket;
  public indexFile = 0;
  public modalNotifyType = ModalNotifyTypes.Info;
  public onStatus: EventEmitter<any> = new EventEmitter<any>();

  public isOpen = false;
  public modeloArchivo: Array<any> = [];

  public currentFileName: string;

  constructor(public utility: Utilities, public port: string = "8181",
    public createSubObject: boolean = true,
    public scanFiles: Array<any> = [],
    public componentName: string = "ScanDocumentsComponent",
    public viewModals: boolean = true, public viewDownloadModal: boolean = true) {

  }


  public StartWebSocekt() {//
      
      this.indexFile = 0;
      

      try {
          const wsImpl = window["WebSocket"] || window["MozWebSocket"];
          const url = `ws://localhost:${this.port}/'`;
          this.ws = new wsImpl(url);
          this.ws.onmessage = (e) => {
              if (typeof e.data === "string") {

                  this.utility.logger.LogInfoText("Onmessage [string]:" + e.data);
                  this.onMessage.emit({ isOpen: false, msg: "Onmessage [string]:" + e.data, data: e });
              }
              else if (e.data instanceof ArrayBuffer) {

                  this.utility.logger.LogInfoText("Onmessage [ArrayBuffer]:" + e.data);
                  this.onMessage.emit({ isOpen: false, msg: "ArrayBuffer [string]:" + e.data, data: e });
              }
              else if (e.data instanceof Blob) {

                  this.utility.logger.LogInfoText("Onmessage [Blob]:" + e.data.size);

                  this.onMessage.emit({ isOpen: this.isOpen, type: "preBlob", msg: "ArrayBuffer [Blob]:" + e.data, data: e, scanFiles: this.scanFiles });


                  const scanFile: any = e.data;

                  if (this.currentFileName) {
                      scanFile.name = this.currentFileName;
                  }
                  else {
                      this.indexFile++;
                      scanFile.name = "File_" + this.indexFile;
                  }
                  this.currentFileName = null;
                  if (this.onMessage.observers.length > 0)
                      this.onMessage.emit({ isOpen: this.isOpen, scanFile: scanFile, type: "postBlob", msg: "ArrayBuffer [Blob]:" + e.data, data: e });
                  else {
                      if (this.createSubObject)
                          this.scanFiles.push({ file: scanFile, src: window.URL.createObjectURL(scanFile) });
                      else
                          this.scanFiles.push(scanFile);
                  }


                  //var reader = new FileReader();

                  // reader.onload = (e: any) => {


                  //   // var html = "<div class=\"col-sm-2 text-center\" style=\"border: 1px solid black; margin-left: 2px;\"><img height=\"200px\" width=\"200px\" src=\"" + e.target.result + "\" data-file='" + scanFile.name + "' class='selFile' title='Click to remove'><br/>" + this.indexFile + "</div>";
                  //   // this.selDiv.append(html);

                  // }
                  // reader.readAsDataURL(scanFile);
              }
          };
          this.ws.onopen = () => {
              this.utility.logger.LogInfoText("Open :" + url);
              this.isOpen = true;
              this.onClose.emit({ isOpen: this.isOpen, scanFiles: this.scanFiles });
              this.onStatus.emit({ status: "Activo", isOpen: this.isOpen,modalNotifyType: ModalNotifyTypes.Success });
          };
          this.ws.onerror = (ev: Event) => {

              this.onError.emit({ isOpen: this.isOpen, error: ev, type: "websocket", scanFiles: this.scanFiles });
              this.onStatus.emit({ status: "Error Conexion", isOpen: this.isOpen ,modalNotifyType: ModalNotifyTypes.Danger});
              const errorScaner = "Error en Lectura Escaner";

              if (this.viewModals)
                  this.utility.VerModalDanger({ titulo: "Error", descripcion: errorScaner });
              this.utility.logger.LogError(ev, "Escaner");

          };
          this.ws.onclose = () => {
              this.isOpen = false;
              this.onClose.emit({ isOpen: this.isOpen, scanFiles: this.scanFiles });
              this.onStatus.emit({ status: " No se encontró ninguna aplicación de escaneo en su máquina, instale y/o ejecute "+
        " primero y luego actualice el navegador", isOpen: this.isOpen,modalNotifyType: ModalNotifyTypes.Warning });
              if (this.viewModals)
                  this.utility.logger.LogInfoText("Close :" + url);
              if (this.viewDownloadModal)
                  $(".dalert").modal("show");
          };

      }
      catch (error) {

          if (this.viewModals)
              this.utility.VerModalError("Error al inicializar conexion");
          this.utility.logger.LogError(error, null, { componentName: this.componentName, method: "StartWebSocekt" });

          this.onStatus.emit({ status: "Error Inicio Conexion", isOpen: this.isOpen,modalNotifyType: ModalNotifyTypes.Danger });
      }
  }

  public ScanImage(fileName?:string) {
    
      try {
          this.currentFileName =fileName;
          const operacion = { Op: "1100", IdUsuario: (StaticData.Usuario) ? StaticData.Usuario.IdUsuario : -1 };
          //this.ws.send(JSON.stringify(operacion));
          this.ws.send("1100");
      }
      catch (error) {
          this.utility.VerModalError("Error al Escanear");
          this.utility.logger.LogError(error, null, { componentName: this.componentName, method: "ScanImage" });
      }
  }


  public ConvertSize(fileSize) {
      

      return fileSize < 1024000
          ? (fileSize / 1024).toFixed(2) + " KB"
          : (fileSize / 1024000).toFixed(2) + " MB";

  }

  public ViewscanFiles(file) {
      
      $(".scandetail").modal("show");
      const c: any = document.getElementById("ModalFileViewer");
      const ctx = c.getContext("2d");
      const img = new Image();
      img.src = (file.src) ? file.src : window.URL.createObjectURL(this.scanFiles[file]);
      img.onload = () => {
          c.width = img.width;
          c.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
      };
  }
  public RemovescanFiles(i) {
      
      this.scanFiles.splice(i, 1);


  }
}
