import { ModelContainerSelectorComponent } from './../../dynamic-templates/model-container-selector/model-container-selector.component';
//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component, ViewChild, ViewChildren, OnInit, AfterViewInit, QueryList, Input, Output, EventEmitter } from '@angular/core';
import { Paginas, ControllerMethods, TipoObjetoParaPermisos, TipoParaPermisos, nodeTypes, TipoSeguridad } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { ConfigBaseTreeComponent, TiposCrearNodos } from '../../../models/config-base-tree-component';
import { OrgChartJsComponent } from '../../org-chart/org-chart-js/org-chart-js.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { JstreeComponent } from '../../jstree/jstree.component';
import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { PalabrasClaveComponent } from '../../palabras-clave/palabras-clave.component';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { DataTableComponent } from '../../controls/data-table/data-table.component';
import { FormDetailsComponent } from '../../controls/form-details/form-details.component';
import { DataTableVersionesCrudTrdComponent } from '../../expediente/data-table-versiones-crud-trd/data-table-versiones-crud-trd.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { Subject } from 'rxjs';
import { DynamicControlsMetadataComponent } from '../../controls/dynamic-controls-metadata/dynamic-controls-metadata.component';
import { HelperActions } from 'src/app/helpers/helper-actions';
import PerfectScrollbar from 'perfect-scrollbar';
import { ComponentsContainer } from 'src/app/models/components-container';
import { AdminPermisosServidorComponent } from '../../admin-permisos-servidor/admin-permisos-servidor.component';
import { ConfigWindow } from 'src/app/models/config-window';
import { ExportImportConfig, ExportImportItemConfig } from '../../../models/rule-engine/rules-models';
import { debug } from 'console';

@Component({
  selector: 'app-texpediente',
  templateUrl: './texpediente.component.html',
  styleUrls: ['./texpediente.component.scss']
})
export class TExpedienteComponent extends GeneralFormComponent implements OnInit, AfterViewInit {

  /**/
  adminPermisosComponent: AdminPermisosServidorComponent;
  palabrasClaveComponent: PalabrasClaveComponent;
  dttTRD: DataTableVersionesCrudTrdComponent;
  //@ViewChild(DataTableVersionesCrudTrdComponent, { static: false }) dttTRD: DataTableVersionesCrudTrdComponent;
  // @ViewChild('jstreeExpediente', { static: false }) jstreeExpediente: JstreeComponent;
  // @ViewChild('jstreeDatosExpediente', { static: false }) jstreeDatosExpediente: JstreeComponent;
  // @ViewChild('DynamicMetadata', { static: false }) DynamicMetadata: DynamicControlsMetadataComponent;

  jstreeExpediente: JstreeComponent;
  jstreeDatosExpediente: JstreeComponent;
  DynamicMetadata: DynamicControlsMetadataComponent;

  @Input() conCamposPermisos: boolean = true;
  @Input() public importUrl: string = null;

  public direction: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'vertical';
  public directionTree: string = 'vertical';
  selectionModeAreas: boolean = true;
  public notClear: boolean = false;

  isModelContainer: boolean = true;

  configBaseTreeComponent: ConfigBaseTreeComponent;
  orgChartJsComponent: OrgChartJsComponent;
  jsonProject: any;
  selectedNodes = new List<any>([]);
  notSelectablesNodesTypes = new List<any>([]);
  nodosAreas: Array<any> = [];
  nodosSeries: Array<any> = [];
  modeloTemp: any = null;
  itemsAreasEmpresa: Array<any> = [];
  itemsRoles: Array<any> = [];
  itemsSeries: Array<any> = [];
  itemsUsuarios: Array<any> = [];
  public sanitizer: DomSanitizer
  EstiloBuscarContenedor: any = { height: "800px" };
  modeloTRD: any = null;
  infoTRD: string = '';
  itemsTRD: Array<any> = [];
  imagenPath: string;
  verAdminPermisos: boolean = true;
  adminPermisos: boolean = true;
  tienePermisoEditar: boolean = true;
  tienePermisoGuardar: boolean = true;

  UsuariosPermitidos = new List<any>([]);
  RolesPermitidos = new List<any>([]);
  GruposPermitidos = new List<any>([]);
  AreasEmpresaPermitidas = new List<any>([]);

  tiposDocumentales: Array<any> = [];
  PermisosTipoSeguridadExpediente: any;
  GridViewAuditoria: any;
  GridViewFiltroAuditoria: any;
  TipoSeguridad: any;

  IdModeloTipoSeguridadGuardar: any;
  @Output() SetTipoSeguridadEmitter = new EventEmitter<any>();



  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);

    this.configBaseTreeComponent = new ConfigBaseTreeComponent(this.utility, this.baseService, this.sanitizer, helperImages);
    this.orgChartJsComponent = new OrgChartJsComponent(this.utility, this.baseService, this.sanitizer, helperImages);
    this.notSelectablesNodesTypes.Add('Series');
    this.notSelectablesNodesTypes.Add('SubSeries');
    this.notSelectablesNodesTypes.Add('AreasEmpresa');

  }

  ngAfterViewInit() {

    this.modelo.modeloVirtual.UsuarioAutenticado = StaticData.Usuario.IdUsuario;
    this.modelo.modelo.IdGrupoTrabajo = StaticData.Usuario.IdAreaEmpresa;
    const elemTree = document.querySelector('.gform__tree') as HTMLElement;
    elemTree.scrollTop = 0;
    let ps = new PerfectScrollbar(elemTree, {
      handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
    });


    this.SetModeloPermisos(false, true);

    if (this.jstreeExpediente) {
      let config = this.Pagina.ConfigPage.Controls.find(x => { return x.Name == this.jstreeExpediente.name })
      if (config)
        this.jstreeExpediente.config = config;

    }
    if (this.jstreeDatosExpediente) {
      let config = this.Pagina.ConfigPage.Controls.find(x => { return x.Name == this.jstreeDatosExpediente.name })
      if (config)
        this.jstreeDatosExpediente.config = config;

    }

    super.ngAfterViewInit();

    setTimeout(() => {

      this.RaiseValidateSaveEvent(this.ValidarBotonGuardarValido());

    }, 500);

  }
  SetModeloPermisos(isEdit: boolean, isAfterViewInit: boolean = false) {

    const that = this;
    if (isEdit) {

      /**
       * Inicio
       * 13/02/2021 - 1255 Edward Morales
       * Ajuste para visualizar mensaje de permisos si no tiene y ocultarlo cuando si aplica
       * Se comenta linea original y se adiciona la que sigue
       *
       */
      //this.verAdminPermisos = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'VerPermisos');
      this.verAdminPermisos = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'AdminPermisos');
      this.adminPermisos = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'AdminPermisos');
      this.tienePermisoEditar = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'Editar');
      //this.tienePermisoGuardar = this.utility.TienePemisoUsuario(this.configPermisos, this.modelo, 'Guardar');
    }
    else {
      this.verAdminPermisos = true;
      this.adminPermisos = true;
      //this.tienePermisoGuardar = true;
    }

    if (this.adminPermisosComponent) {

      this.adminPermisosComponent.verPermitido = this.verAdminPermisos;
      this.adminPermisosComponent.guardarPermitido = this.adminPermisos;
    }
    const crudButtonSave = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtons"; });
    crudButtonSave.Hidden = !this.tienePermisoEditar;
    this.SetModelToControls();
  }
  public OnCreatePermissionsConfig() {

    this.permissionsConfig = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Expediente,
      Pagina: this.Pagina, clear: true, expediente: null, CreatedRowConfig: {}
    };
    this.RaiseFormEvent('OnCreatePermissionsConfig', true);
  }

  public OnInitEnd() {

    //MP 0005357: Seguridad de los expedientes creados (producción)
    // StaticData.Usuario.TodosPermisosDocumentosExpedientes = false;
    // StaticData.Usuario.TodosPermisosExpedientes = false;
    //MP

    this.TipoSeguridad = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoSeguridadDocumento"; });
    //this.ErrorExportar = this.Pagina.Exportar;//this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'Exportar');    

    this.PermisosTipoSeguridadExpediente = StaticData.GeneralConfig.ConfigValidacionExpediente.TipoSeguridadPorPermisos;
    if (this.PermisosTipoSeguridadExpediente) {

      this.TipoSeguridad.Disabled = true;
      this.TipoSeguridad.Readonly = true;

      this.SetModelToControls();
    }

    this.OnCreatePermissionsConfig();
    if (this.dttTRD) {
      this.dttTRD.Limpiar();
    }

    this.loadedComboBoxEmitter.subscribe(modelo => {
      if (this.dttTRD != null) {

        this.modeloTRD = this.dttTRD.modeloTRD;
        if (this.modeloTemp && this.modeloTemp.IdSubSerie)
          this.modelo.modelo.IdSubSerie = this.modeloTemp.IdSubSerie;// this.utility.Clone(this.modeloTemp.IdSubSerie);
        if (this.modeloTRD) {
          this.modelo.modeloCascada.IdSubSerie = this.modeloTRD.IdSubSerie;//this.utility.Clone(this.modeloTRD.IdSubSerie);

          this.SetModelToControls();
        }
      }
      setTimeout(() => {
        if (!this.modelo.modelo.IdUsuarioResponsable && StaticData.Usuario.IdAreaEmpresa == this.modelo.modelo.IdAreaEmpresa) {
          this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
          this.SetModelToControls();
        }
      }, 300);
    });
    this.configs.ConfigIdSubSerie.onLoadedEmitter = this.loadedComboBoxEmitter;
    this.configs.ConfigIdUsuarioResponsable.onLoadedEmitter = this.loadedComboBoxEmitter;

    this.postSaveEmitter.subscribe(modelo => {

      // this.IdModeloTipoSeguridadGuardar = this.modelo.modelo.IdTipoSeguridadDocumento;


      if (!this.IsEdit) {
        this.ReCargarGrilla();
        this.utility.VerModalOk("Se creó el Expediente con código: " + "\n\r" + modelo.data.data.Codigo).then(model => {

          if (model.dialogResult == true) {
            console.error("REsultado de modal guardar", model)
            setTimeout(() => {
              this.ReCargarGrilla();
            }, 4000);
          }
        });

      }
      else {
        // this.ReCargarGrilla();
        this.form.controls.IdAreaEmpresa.enable();
        this.form.controls.IdSerie.enable();
        this.form.controls.IdSubSerie.enable();
        this.form.controls.Nombre.enable();
        this.utility.VerModalOk("Se modificó el Expediente con código: " + "\n\r" + modelo.data.data.Codigo);

      }
	  //this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado; //TipoSeguridad Predeterminado
      //this.SetModelToControls();
      console.error("***");
    });

    this.preSaveEmitter.subscribe(modelo => {

      this.modelo.modelo.PalabrasNormalizadas = JSON.stringify(this.modelo.modeloVirtual.GridPalabrasNormalizadas);
    });

  }
  AddChildComponent(component: any) {

    let _component = new List(this.childComponents).FirstOrDefault(x => { return x.componentName == component.componentName });
    if (!_component)
      this.childComponents.push(component);
  }
  public ValidarGuardarValido() {
    if (this.dttTRD) {
      this.guardarValido = this.dttTRD.ValidarGuardarValido();
    }

    this.RaiseValidateSaveEvent(this.guardarValido);

  }

  public ValidarBotonGuardarValido(): boolean {
    if (this.dttTRD) {
      return this.dttTRD.ValidarGuardarValido();
    }
    else {
      return false;
    }

  }

  public RaiseValidateSaveEvent(isValid) {
    this.helperActions.comunicationService.raiseValidateSaveEvent({ Name: this.name, Ok: isValid });
  }

  public Limpiar(callback: (data: any) => void = null, action?: any) {

    if (this.palabrasClaveComponent.texto) {
      this.palabrasClaveComponent.texto = '';
    }

    if (this.notClear == true)
      return;

    super.Limpiar(callback, action);
    this.ValidarGuardarValido();

    this.SetModeloPermisos(false);
    this.CrearNodosdatosJsTree(true);



    this.ClearModel();
    //debugger
    if (this.dttTRD) {
      this.dttTRD.Limpiar();
      // this.dttTRD.items= new Array();
      this.dttTRD.modelo = this.modelo;
    }
    if (this.palabrasClaveComponent) {
      this.modelo.modelo.PalabrasClave = null;
      this.palabrasClaveComponent.Limpiar();
      this.palabrasClaveComponent.SetModelo(this.modelo);
    }
    // super.Limpiar(callback);
    this.utility.SetFieldsToModel(this.modelo.modelo, null);//this.filterParent);
    if (callback) callback(this.modelo);
    this.SetModelToControls();
    this.RemoveMetadataControls(null);
    this.Pagina.ConfigPage.Metadata = null;
    if (this.DynamicMetadata)
      this.DynamicMetadata.Controls = [];
    //this.parentClearCall.next(this.modelo);
    this.LimpiarSubSerie();
    this.SetProcesando(false);
    //this.componentsContainer.Tables[0].ReCargarGrilla();
    this.modelo.modelo.IdTipoSeguridadDocumento =  StaticData.GeneralConfig.ConfigValidacionExpediente.HeredarTipoSeguridadDeTRD ?
    null : TipoSeguridad.Privado;
    this.SetModelToControls();

  }
  public LimpiarSubSerie() {

    if (this.configs && this.configs.ConfigIdSubSerie) {
      this.configs.ConfigIdSubSerie.Context.SetItemsComboBox([], true);
    }
    if (this.configs && this.configs.ConfigIdSerie) {
      this.configs.ConfigIdSerie.Context.SetItemsComboBox([], true);
      this.configs.ConfigIdSerie.Context.OnChangeEvent();
    }
  }
  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {
      this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, { item: item, controlName: controlName, config: config });
      // this.ExecuteCascading(item, controlName, config);

      this.comboBoxManager.ExecuteCascading(item, controlName, config);
      if (controlName == 'IdAreaEmpresa') {
        if (item) {
          let usuarios = this.utility.GetItemsAreasRolesUsuarioSeries(TipoParaPermisos.Usuario);
          usuarios = usuarios.filter(x => { return x.IdAreaEmpresa == item.IdAreaEmpresa });
          this.configs.ConfigIdUsuarioResponsable.Context.SetItemsComboBox(usuarios);
          this.modelo.modelo.IdAreaEmpresa = item.IdAreaEmpresa;
          this.SetModelToControls();

          if (StaticData.GeneralConfig.ConfigValidacionExpediente.DeptoCiudadAreaEmpresa) {
            var obj = this.utility.GetApiModel('ConsultaDepartamentoCiudadAreaEmpresa', this.componentName);

            obj.QueryConfig.Command = 'ConsultaDepartamentoCiudadAreaEmpresa';
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = { IdAreaEmpresa: item.IdAreaEmpresa };

            this.baseService.Get(obj, null,
              {
                componentName: this.componentName,
                method: 'ConsultaDepartamentoCiudadAreaEmpresa',
                processMessage: 'Consultando datos del Área...'
              }, 'TExpediente')
              .then(data => {
                if (data.Data[0].IdCiudad != null) {
                  this.modelo.modeloCascada.IdDepartamento = data.Data[0].IdDepartamento;
                  this.modelo.modeloVirtual.Ciudad = data.Data[0].IdCiudad;
                  this.SetModelToControls();

                  this.configs.ConfigIdCiudad.Params = { IdDepartamento: data.Data[0].IdDepartamento };
                  this.configs.ConfigIdCiudad.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);

                  setTimeout(() => {
                    this.modelo.modelo.IdCiudad = data.Data[0].IdCiudad;
                    this.SetModelToControls();
                  }, 2000);
                }
                else {
                  this.utility.VerModalDanger({ "titulo": "Area de la entidad", "descripcion": "El Área de la entidad no posee Departamento y Ciudad, favor comuniquese con el administrador del sistema" });
                }
              }).catch(err => {
                this.utility.logger.LogError(err, obj, {
                  componentName: this.componentName,
                  method: 'ConsultaDepartamentoCiudadAreaEmpresa',
                  processMessage: 'Consultando datos del Área...'
                }, 'TExpediente')
              });
          }
        }
        else {
          this.modelo.modeloCascada.IdSubSerie = null;
          this.modelo.modeloCascada.IdSerie = null;
          this.SetModelToControls();
          this.configs.ConfigIdUsuarioResponsable.Context.SetItemsComboBox([]);
          //this.SetModelToControls();
        }

      }

      if (controlName == "Descriptores") {
        //Lina Figueredo - Solucion bug Error Descriptores
        //Se modifica el condicional para que valide si el arreglo this.modelo.modeloVirtual.GridPalabrasNormalizadas existe y si no es asi que lo cree.
        if (item && this.modelo.modeloVirtual.Descriptores)
        {
            if(!this.modelo.modeloVirtual.GridPalabrasNormalizadas)
              this.modelo.modeloVirtual.GridPalabrasNormalizadas = new Array();
            if(!this.modelo.modeloVirtual.GridPalabrasNormalizadas.find(x => { return x.Id == item.IdTesauro; }))
              this.modelo.modeloVirtual.GridPalabrasNormalizadas.push({ Id: item.IdTesauro, Palabra: item.Palabra });     
        }
      }


      if (controlName == 'IdSubSerie' || controlName == 'IdSerie') {

        if(item)
        {
          this.BuscarVersionTRD(false);
        }
      }

      if (controlName == 'IdAreaEmpresa' || controlName == 'IdSubSerie' || controlName == 'IdSerie')
      {
        if(!item)
        {
          this.dttTRD.Limpiar();
          this.modelo.modelo.IdVersionTablaRetencionDocumental = null;
          this.modelo.modelo.IdTablaRetencionDocumental = null;
          this.BuscarVersionTRD(false);

        }
      }

      this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, { item: item, controlName: controlName, config: config });
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }
  public BuscarVersionTRD(esEdicion: boolean) {

    this.dttTRD.BuscarVersionTRD(esEdicion);


  }
  // public SeleccionarTRD(id: number) {

  // 	if (this.modelo.IdTablaRetencionDocumental === id)
  // 		this.modelo.IdTablaRetencionDocumental = null;
  // 	else
  // 		this.modelo.IdTablaRetencionDocumental = id;
  // 	this.ValidarGuardarValido();

  // }


  public OnActionEventHandler(event) {

    // if (event.Action == "ClearModel") {

    // 	this.Limpiar();
    // }
    // else if (event.ControlType == "CommandButton") {

    //


    // 	this.helperActions.ExecuteActionsControl((event.Option) ? event.Option : event.config,
    // 		false, null, this.modelo, this.permissionsConfig,
    // 		this, null, new EventEmitter<any>(), this.componentsContainer,
    // 		event.Option, this.modelo.modeloMetadata, { importUrl: this.importUrl });
    // }
    if(event.Action && event.Action == "ClearModel"){

      setTimeout(() => { this.componentsContainer.ReloadTable("DataTable_VersionesSubSerie", { IdEmpresa: -1, IdSubSerie: -1 }, null, false); }, 1000);
      super.OnActionEventHandler(event);
      //this.Limpiar();

    }
    else if (event.type) {

      switch (event.type) {
        case 'DataTableVersionesCrudTrd':
          this.SetModelToControls();

          break;
        case 'DataTableVersionesCrudTrdComponent':

          if (this.modelo.modelo.ListaPermisos != null)
            //debugger;
            this.adminPermisosComponent.SetModelo(this.modelo);

          switch (event.actionType) {
            case "SeleccionarTRD":

              if (event.data != null) {
                this.SetModelToControls();
                if (!event.data.RemoveMetadata) {
                  this.RemoveMetadataControls(null);
                  this.CreateMetadataControls(event.data.Configuracion);
                  this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;

                  this.SetModelToControls();
                }
                else
                  this.RemoveMetadataControls(null);
              }
              this.ValidarGuardarValido();
              break;

            case "LoadData":
              /*if (this.IdModeloTipoSeguridadGuardar && this.IdModeloTipoSeguridadGuardar > 0)
                this.modelo.modelo.IdTipoSeguridadDocumento = this.IdModeloTipoSeguridadGuardar;*/

              this.SetModelToControls();

              if (this.IsEdit) {
                //debugger
                this.configs.ConfigIdAreaEmpresa.Params = { IdEmpresa: 1, IdAreaEmpresa: this.modelo.modeloCascada.IdAreaEmpresa };
                this.configs.ConfigIdAreaEmpresa.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);

                const crudButtonSave = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtons"; });
                //El expediente cerrado tambien debe permitir ser editado JS
                //if (this.modelo.modelo && this.modelo.modelo.IdEstado == 2)
                  crudButtonSave.Hidden = false;
                //else if (this.modelo.modelo.IdEstado != 2)
                //  crudButtonSave.Hidden = false;
                //debugger;
                this.configs.ConfigIdSerie.Params = { IdEmpresa: 1, IdAreaEmpresa: this.modelo.modeloCascada.IdAreaEmpresa, IdExpediente: this.modelo.modelo.IdExpediente };
                this.configs.ConfigIdSerie.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);


                this.configs.ConfigIdUsuarioResponsable.Params = { IdEmpresa: 1, IdAreaEmpresa: this.modelo.modeloCascada.IdAreaEmpresa };
                this.configs.ConfigIdUsuarioResponsable.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);

                //this.configs.ConfigIdTipoSeguridadDocumento.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);

                this.configs.ConfigIdSubSerie.Params = { IdSerie: this.modelo.modeloCascada.IdSerie,};
                /**
                 * INICIO
                 * 09/02/2021 - 09:06 Edward Morales
                 * Se comenta linea .Entity por .Params para cargar combo de subserie en Edición
                 */
                //this.configs.ConfigIdSubSerie.Entity = { IdSerie: this.modelo.modeloCascada.IdSerie };
                this.configs.ConfigIdSubSerie.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);

                this.configs.ConfigDescriptores.Params = { IdSubSerie: this.modelo.modeloCascada.IdSubSerie,};
                this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
                if (event.data && event.data.Configuracion) {

                  if (event.data.Configuracion.startsWith("[")) {

                    this.utility.VerModalDanger({ titulo: "Error Modelo", descripcion: "Los metadatos estan mal formados" });
                    return;
                  }
                  this.RemoveMetadataControls(null);
                  this.CreateMetadataControls(event.data.Configuracion);
                  // this.SetModelToControls();

                  this.tiposDocumentales = this.ExtractPropertyFromJSON(event.data.Configuracion, "TiposDocumentales")

                  this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;

                  let obj = this.utility.GetApiModel('GetDetalleMetadata', null)
                  obj.QueryConfig = {
                    Command: 'BusquedaValorMetadataExpediente',
                    IsSp: true,
                    Entity: {
                      IdEmpresa: -1,
                      IdExpediente: this.modelo.modelo.IdExpediente
                    }
                  };

                  let info = {
                    componentName: this.componentName, controlName: 'GetDetalleTipoDocumental',
                    processMessage: 'Cargando Config Metadata'
                  };

                  this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

                    this.modelo.modeloMetadata.Items._elements.forEach(_controls => {

                      data.Data.forEach(dataControls => {

                        if (dataControls.NombreControl == _controls.Name) {
                          _controls.IdParentObject = dataControls.IdRegistroVersionSubSeriePaginaMetadatos;
                          _controls.Id = dataControls.IdRegistroVersionSubSeriePaginaMetadatosDetalle;
                          // if (_controls.DataType == "String")
                          // _controls.Value = dataControls.ValorTextoCuatrocientos
                          switch (_controls.DataType) {
                            case "Int32":
                            case "Int64":
                            case "Int16":
                              //ValorEntero
                              _controls.Value = dataControls.ValorEntero; // _controls.Label; //.ValorEntero;
                              break;
                            case "Decimal":
                            case "Number":
                              //ValorNumero

                              _controls.Value = dataControls.ValorNumero;
                              break;
                            case "Boolean":
                              //ValorBoolean
                              _controls.Value = dataControls.ValorBoolean;
                              break;
                            case "DateTime":
                              //ValorFecha
                              _controls.Value = dataControls.ValorFecha;
                              break;
                            case "String":

                              //ValorTextoCuatrocientos
                              _controls.Value = dataControls.ValorTextoCuatrocientos;
                              break;
                            case "SemiLargeString":
                              //ValorTextoMil
                              _controls.Value = dataControls.ValorTextoMil;
                              break;
                            case "LargeString":
                            case "Json":
                              //ValorObservaciones
                              _controls.Value += dataControls.ValorObservaciones;
                              break;
                          }
                        }
                      });
                    })

                    this.SetModelToControls();
                    data.Data;

                  }).catch(err => { });

                  this.SetModelToControls();
                }
                else {
                  this.RemoveMetadataControls(null);
                  this.CreateMetadataControls(null);
                  // this.SetModelToControls();

                  this.tiposDocumentales = [];
                  this.Pagina.ConfigPage.Metadata = null;
                  this.DynamicMetadata.Controls = [];
                  this.SetModelToControls();
                }

              }

              break;
            default:
              super.OnActionEventHandler(event);
              break;
          }
          break;
        default:
          super.OnActionEventHandler(event);
          break;
      }
      super.OnActionEventHandler(event);
    }
    else if (event.ControlType) {

      switch (event.ControlType) {
        case 'CommandButton':
          if (event.Option.Type == "Edit") {

            this.SetTipoSeguridadEmitter.subscribe(data => {
              if (this.modelo.modelo.IdTipoSeguridadDocumento == this.modelo.modeloVirtual.IdTipoSeguridadOriginal){
                this.modelo.modelo.IdTipoSeguridadDocumento = this.modelo.modeloVirtual.IdTipoSeguridadOriginal;
              }
            }
            );

            this.modelo.modeloVirtual.Descriptores = null;
            this.modelo.modeloVirtual.Ciudad = this.modelo.modelo.IdCiudad;
            const CheckActivo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Activo"; });
            CheckActivo.Hidden = this.modelo.modelo.IdUsuarioResponsable != this.modelo.modeloVirtual.UsuarioAutenticado ? true : false;
            this.SetModelToControls();
            this.form.controls.IdAreaEmpresa.disable();
            this.form.controls.IdSerie.disable();
            this.form.controls.IdSubSerie.disable();
            this.form.controls.Nombre.disable();
            if (this.form.controls.NumeroRadicacionProceso)
              this.form.controls.NumeroRadicacionProceso.disable();

            //0005418: Expediente Inactivo

            if (!this.modelo.modelo.Activo) {
              const crudButtonSave = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtons"; });
              crudButtonSave.Hidden = true;
            }



          }
          else if (event.Option.Type == "New") {
            //debugger
            this.newExpediente();
            // if (this.configs.ConfigIdAreaEmpresa.QueryConfig && this.configs.ConfigIdAreaEmpresa.QueryConfig.Entity.IdAreaEmpresa)
            //   this.configs.ConfigIdAreaEmpresa.Params = { IdAreaEmpresa: StaticData.Usuario.IdAreaEmpresa, IdEmpresa: StaticData.Usuario.IdEmpresa };
            // else
            //   this.configs.ConfigIdAreaEmpresa.Params = { IdEmpresa: StaticData.Usuario.IdEmpresa };
            // this.configs.ConfigIdAreaEmpresa.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
            // this.modelo.modeloVirtual.Ciudad = this.modelo.modelo.IdCiudad;
            // //debugger;
            // //this.modelo.modelo.IdExpediente = null;
            // this.configs.ConfigIdSerie.Params = { IdEmpresa: 1, IdAreaEmpresa: -1, IdExpediente: -1 };
            // this.configs.ConfigIdSerie.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
            // this.SetModelToControls();
            // this.form.controls.IdAreaEmpresa.enable();
            // this.form.controls.IdSerie.enable();
            // this.form.controls.IdSubSerie.enable();
            // this.form.controls.Nombre.enable();
            // if (this.form.controls.NumeroRadicacionProceso)
            //   this.form.controls.NumeroRadicacionProceso.enable();

          }
          else if (event.Option.Type == 'Custom') {
            if (event.Option.Name == "InactivarExpediente") {

              if (this.modelo.modelo.IdUsuarioResponsable != StaticData.Usuario.IdUsuario) {
                this.utility.VerModalWarning({
                  titulo: 'Alerta', descripcion: 'El expediente no puede ser Inactivo por el usuario',
                  verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                }).then(data => { });
              } else
                this.VerObservacionesInactivar();

            }
            ///Implementacion de limpiza de Grilla de Auditoria Edwin Alonso 10-11-2021
            ///Se implementa la recarga con parametro de idExpediete -2 para que no retorne datos en su apertura
            if (event.Option.Name == "GeneraAuditoria") {
              this.GridViewFiltroAuditoria = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Filtro"; });
              this.GridViewFiltroAuditoria.QueryConfig.Entity = {
                IdEmpresa: -1, IdExpediente: -2
              };
              this.GridViewFiltroAuditoria.ReCargarGrilla();
            }
          }
          debugger
          if (StaticData.GeneralConfig.ConfigValidacionExpediente.TipoSeguridadPorPermisos && !this.IsEdit) {
            this.SetTipoSeguridad(this.modelo)
            this.SetModelToControls();
          }
          super.OnActionEventHandler(event);
          break;
        case 'Button':
          if (event.Name == "searchTesauro") {
            this.configs.ConfigDescriptores.Params = { PalabraBusqueda: this.modelo.modeloVirtual.palabraTesauro };
            this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          }
          else if (event.Name == "btnBuscar") {
            this.FiltroAuditoria();
          }
          else if (event.Name == "BtnVertodo") {
            this.modelo.modelo.FechaCreacionInicial = null;
            this.modelo.modelo.FechaCreacionFinal = null;
            this.FiltroAuditoria();
          }
          else if (event.Name == "BtnAuditoria") {
            // Manuel Rodriguez 31/08/2021: Se comentan las lineas pues estaba ejecutando nuevamente
            // una acción que ya se encuentra por configuración en el botón y hace que se descarguen dos archivos de la misma auditoria
            this.GridViewAuditoria = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Filtro"; });
            this.GridViewAuditoria.QueryConfig.ExportSettings = new ExportImportConfig();

            var date = new Date();
            let day = ("0" + date.getDate()).slice(-2);
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let year = date.getFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();

            this.GridViewAuditoria.QueryConfig.ExportSettings.FileName = 'BusquedaLTAuditoriaFuncional_' + day + "_" + month + "_" + year + "-" + hours + "-" + minutes + "-" + seconds;
            var ItemConfig = new ExportImportItemConfig();
            ItemConfig.ResultType = event.config.Actions[0].ResultType;
            ItemConfig.DestinationType = 'None';
            ItemConfig.ToType = event.config.Actions[0].ExportType;

            this.GridViewAuditoria.QueryConfig.Entity = {
              IdEmpresa: -1,
              IdExpediente: this.modelo.modelo.IdExpediente
            };


            this.GridViewAuditoria.QueryConfig.ExportSettings.ItemsConfig.push(ItemConfig);

            this.GuardarAuditoriaExpediente('BtnAuditoria', null);
          }
          super.OnActionEventHandler(event);
          break;
        default:
          super.OnActionEventHandler(event);
          break;

      }
    }
    else
      super.OnActionEventHandler(event);

  }

  newExpediente()
  {
    if (this.configs.ConfigIdAreaEmpresa.QueryConfig && this.configs.ConfigIdAreaEmpresa.QueryConfig.Entity.IdAreaEmpresa)
                this.configs.ConfigIdAreaEmpresa.Params = { IdAreaEmpresa: StaticData.Usuario.IdAreaEmpresa, IdEmpresa: StaticData.Usuario.IdEmpresa };
              else
                this.configs.ConfigIdAreaEmpresa.Params = { IdEmpresa: StaticData.Usuario.IdEmpresa };
              this.configs.ConfigIdAreaEmpresa.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
              this.modelo.modeloVirtual.Ciudad = this.modelo.modelo.IdCiudad;

              //this.modelo.modelo.IdExpediente = null;
              //Lina Figueredo. Solucion Bug 1000. Se comentan Lineas 827 y 828, para no cargar el combo de series hasta que el area no sea seleccionada
              //this.configs.ConfigIdSerie.Params = { IdEmpresa: 1, IdAreaEmpresa: -1, IdExpediente: -1 };
              //this.configs.ConfigIdSerie.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
              this.SetModelToControls();
              this.form.controls.IdAreaEmpresa.enable();
              this.form.controls.IdSerie.enable();
              this.form.controls.IdSubSerie.enable();
              this.form.controls.Nombre.enable();
              if (this.form.controls.NumeroRadicacionProceso)
                this.form.controls.NumeroRadicacionProceso.enable();
  }


  VerObservacionesInactivar(): any {
    let config: ConfigWindow = new ConfigWindow();
    let modeloInterno: any = { VersionModelData: { ObservacionesModificacion: '' } };
    config.classWindow = 'info';
    config.titleWindow = "Observaciones";
    config.returnInstance = false;
    config.ok = "Aceptar";
    config.modal = true;
    config.width = 600;
    config.height = 300;
    let windowModel: any = {
      modelo: modeloInterno.VersionModelData,
      config: this.utility.GetConfigObservacionesVersion()
    };

    this.utility.OpenWindow('ObservacionesModificacionVersionComponent',
      config, windowModel).then(dataResult => {

        dataResult.onClosePromise.then(dataResultOnClose => {

          if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

            if (dataResultOnClose.modelo.ObservacionesModificacion) {

              this.utility.VerModalConfirmacionActivar().then(modelo => {
                if (modelo.dialogResult == true) {
                  this.GuardarAuditoriaExpediente('Inactivar', dataResultOnClose.modelo.ObservacionesModificacion);
                  this.Limpiar();
                  //this.componentsContainer.Tables[0].ReCargarGrilla();
                }
              });

            }
            else
              this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
          }
        });
      })

  }

  public GuardarAuditoriaExpediente(option: any, observacion: any) {
    var description: string;
    var spName: string;

    switch (option) {
      case "Inactivar":
        description = observacion //+ ', Expediente con Id: ' + this.modelo.modelo.IdExpediente;
        spName = "CrearAuditoriaInactivarExpediente";
        break;
      case "BtnAuditoria":
        description = 'Exportar auditoria en el expediente con Id: ' + this.modelo.modelo.IdExpediente;
        spName = "CrearAuditoriaExportarAuditoria";
        break;
    }


    this.componentName = "TExpediente"
    let obj = this.utility.GetApiModel(spName, null)
    obj.QueryConfig.Command = spName; // 'CrearAuditoriaInactivar';
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdRegistro: this.modelo.modelo.IdExpediente,
      UsuarioCreacion: StaticData.Usuario.Email,
      IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
      Maquina: -1,
      Descripcion: description
    };
    let info = {
      componentName: this.componentName, controlName: spName,
      processMessage: 'Auditoria Expediente......'
    };
    this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
    }).catch(err => { }).finally();

  }

  public FiltroAuditoria() {
    try {
      this.SetProcesando(true);
      // Ajuste de busqueda de filtro de la grilla de auditoria edwin alonso 12022021
      this.GridViewFiltroAuditoria = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Filtro"; });
      if (this.GridViewFiltroAuditoria) {
        this.GridViewFiltroAuditoria.QueryConfig.Entity = {
          FechaCreacionInicial: this.modelo.modelo.FechaCreacionInicial,
          FechaCreacionFinal: this.modelo.modelo.FechaCreacionFinal,
          IdEmpresa: -1,
          IdExpediente: this.modelo.modelo.IdExpediente
        };
        this.GridViewFiltroAuditoria.cargarGrilla = true;
        this.GridViewFiltroAuditoria._CargarGrilla(true);
      }
      // fin
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }

  public OnDataTableEditHandler(event) {
    //debugger;
    this.modelo.modeloVirtual.UsuarioAutenticado = StaticData.Usuario.IdUsuario;
    this.modelo.modeloVirtual.IdTipoSeguridadOriginal = this.modelo.modelo.IdTipoSeguridadDocumento;
    this.SetModelToControls();
    this.SetProcesando(false);
    //this.EditCascading();
    this.BuscarVersionTRD(true);

    // this.modelo.modeloCascada.IdDepartamento = this.modelo.modelo.IdDepartamento;
    // this.modelo.modeloCascada.IdCiudad = this.modelo.modelo.IdCiudad;


    //this.modelo.modeloCascada["IdCiudad"] = this.modelo.modelo.IdCiudad; // = { IdCiudad: this.modelo.modelo.IdCiudad }; // this.modelo.modelo.IdCiudad;
    // this.modelo.modeloCascada.IdDepartamento = this.modelo.modelo.IdDepartamento;
    //this.SetModelToControls();




    // this.SetModelToControls();

    //this.parentClearCall.next(this.modelo);

    this.CrearNodosdatosJsTree(false);
    this.SetModeloPermisos(true);
    this.ValidarGuardarValido();
    this.FireFormEvent({ Name: "OnPostEdit", modelo: this.modelo });

    this.RaiseFormEvent('Edit');
    this.dttTRD.IsEditModelo = true;

    if (this.palabrasClaveComponent) {
      this.palabrasClaveComponent.SetModelo(this.modelo);
    }
    if (this.adminPermisosComponent)
      this.adminPermisosComponent.SetModelo(this.modelo);

    if (this.modelo.modelo.PalabrasNormalizadas != "") {
      this.modelo.modeloVirtual.GridPalabrasNormalizadas = JSON.parse(this.modelo.modelo.PalabrasNormalizadas);
    }

  }
  public OnSelectionHandler(event) {


  }
  public OnLoadGridHandler(event) {

    this.CrearNodosJsTree(event.data);
  }
  public AddComponentInstanceHandler(event) {


    if (event instanceof DataTableComponent) {
      event.MsgValidationRecordManagement = this.Pagina.ConfigPage.MsgValidationRecordManagement;
      event.SearchTagRecordManagement = this.Pagina.ConfigPage.SearchTagRecordManagement;
      event.MsgReleaseRecordManagement = this.Pagina.ConfigPage.MsgReleaseRecordManagement;
      event.MsgBusyRecordManagement = this.Pagina.ConfigPage.MsgBusyRecordManagement;
      this.componentsContainer.Tables.push(event);
    }
    else if (event instanceof FormDetailsComponent)
      this.componentsContainer.FormsDetails.push(event);
    else if (event instanceof DynamicControlsMetadataComponent) {

      this.DynamicMetadata = event;
      // this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;
      this.DynamicMetadata.form = this.form;
      this.AddComponentInstanceHandlerInGeneral(event);

    }
    else if (event instanceof PalabrasClaveComponent) {
      this.palabrasClaveComponent = event;
      this.palabrasClaveComponent.isModelContainer = true;
      this.AddComponentInstanceHandlerInGeneral(event);

    }
    else if (event instanceof AdminPermisosServidorComponent) {
      this.adminPermisosComponent = event;
      this.adminPermisosComponent.isModelContainer = true;
      this.adminPermisosComponent.onActionEventEmitter.subscribe(data => {
        this.SetTipoSeguridad(data.modelo)
      });
      this.AddComponentInstanceHandlerInGeneral(event);
    }
    else {

      if (event.name == 'jstreeExpediente') {

        this.jstreeExpediente = event;
        this.AddComponentInstanceHandlerInGeneral(event);
        //this.jstreeExpediente.queryConfigIDEType =-1;
        //this.jstreeExpediente.expandAllMode =true;
      }
      else if (event.name == 'jstreeDatosExpediente') {

        this.jstreeDatosExpediente = event;
        this.AddComponentInstanceHandlerInGeneral(event);
        //this.jstreeDatosExpediente.queryConfigIDEType =-1;
        //this.jstreeDatosExpediente.expandAllMode =true;
      }
      // else if (event.name == 'AdminPermisions') {

      // 	this.adminPermisosComponent = event;
      // 	this.AddComponentInstanceHandlerInGeneral(event);
      // }

      else if (event instanceof DataTableVersionesCrudTrdComponent) {

        this.dttTRD = event;
        this.dttTRD.configs = this.configs;
        this.AddComponentInstanceHandlerInGeneral(event);

      }
      else if ( event.name == "SidePanel")
      {
        if(event._subControl && event._subControl.OpenaPanel)
        {
          this.newExpediente();
          if (StaticData.GeneralConfig.ConfigValidacionExpediente.TipoSeguridadPorPermisos && !this.IsEdit) {
            this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;
            this.SetModelToControls();
          }
        }


        super.AddComponentInstanceHandler(event);
      }
      else {
        super.AddComponentInstanceHandler(event);
      }
    }

  }

  public SetTipoSeguridad(modelo: any) {

    let LtsPermisos = modelo.modelo.ListaPermisos;

    let Privado = false;
    let Publico = false;
    let Reservado = false;

    const permisosUsuario = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.Usuario);
    const permisosRol = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.Role);
    const permisosArea = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.AreaEmpresa);

    if (LtsPermisos.Count() == 1) {

      if (permisosUsuario.find(element => element.Nombre == StaticData.Usuario.Email) != undefined|| 
          permisosRol.find(element => element.Nombre == StaticData.Usuario.Role) != undefined||
          permisosArea.find(element => element.Nombre == StaticData.Usuario.AreaEmpresa)!= undefined) {
          Privado = true;
      }
      else if(permisosUsuario.length > 0){
        if(permisosUsuario.find(element => element.AreaEmpresa != StaticData.Usuario.AreaEmpresa) != undefined){
          Reservado = true;
        }else{
          Privado = true;
        }
        
      }
      else{
        Reservado = true;
      }
      
    } else if (LtsPermisos.Count() > 1) {

      

      if (permisosRol.filter(element => element.Nombre != StaticData.Usuario.Role).length > 1 || 
          permisosArea.filter(element => element.Nombre != StaticData.Usuario.AreaEmpresa).length > 1) {
        Publico =true;        
      }else if(permisosRol.filter(element => element.Nombre != StaticData.Usuario.Role).length == 1 || 
      permisosArea.filter(element => element.Nombre != StaticData.Usuario.AreaEmpresa).length == 1){
        Reservado = true;
      }else{
        let cantPermisosUsuario = permisosUsuario.filter(element => element.AreaEmpresa != StaticData.Usuario.AreaEmpresa);
        if(cantPermisosUsuario != undefined && cantPermisosUsuario.length > 1){
          Publico = true;        
        }else if(cantPermisosUsuario != undefined && cantPermisosUsuario.length == 1){
          Reservado = true;
        }else{
          Privado = true;
        }
      }
    }else{
      Privado = true;
    }

    /*
      TipoSeguridad.Publico = Publica
      TipoSeguridad.Reservado = Reservada
      TipoSeguridad.Privado = Clasificada
    */   
    if (Publico)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Publico;
    else if (Reservado)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Reservado;
    else if (Privado)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;

    this.SetModelToControls();
    this.SetTipoSeguridadEmitter.emit();
  }

  public CrearNodosJsTree(items: Array<any>) {
    const that = this;
    if (StaticData.Usuario && this.jstreeExpediente) {
      let root = this.jstreeExpediente.GetNodeByTypeFromTypes('Expedientes', null, 'Resumen Expedientes');//this.jstreeExpediente.GetNode('Root');

      let nodoResponsables = this.jstreeExpediente.GetNodeByTypeFromTypes('Responsables', null, 'Expedientes Asignados', root.id);
      let nodoExpedinetes = this.jstreeExpediente.GetNodeByTypeFromTypes('Expedientes', null, 'Expedientes', root.id);
      let nodoUsuario = this.jstreeExpediente.GetNodeByTypeFromTypes('Usuario', null, 'Por Usuario', nodoResponsables.id);
      //let nodoRole = this.jstreeExpediente.GetNodeByTypeFromTypes('Role', null, 'Por Role', nodoResponsables.id);
      //let nodoEmpresa = this.jstreeExpediente.GetNodeByTypeFromTypes('AreaEmpresa', null, 'Por Area Empresa', nodoResponsables.id);

      $.each(items, function (index, item) {

        let expedinete = that.jstreeExpediente.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoExpedinetes.id);
        //let nodoPermisos = that.jstreeExpediente.GetNodeByTypeFromTypes('Permisos');
        let nodoResponsablesExp = that.jstreeExpediente.GetNodeByTypeFromTypes('Responsables', null, 'Responsable', nodoExpedinetes.id);

        let nodoPalabrasClave = that.jstreeExpediente.GetNodeByTypeFromTypes('PalabrasClave', null, null, expedinete.id);
        if (item.IdUsuarioResponsable) {
          let usu = StaticData.RolesUsuariosGrupos.FirstOrDefault(x => { return x.Id === item.IdUsuarioResponsable && x.Tipo == TipoParaPermisos.Usuario });
          if (usu) {
            let nodoUsuarioResp = that.jstreeExpediente.GetNodeByTypeFromTypes('Usuario', null, usu.Nombre, nodoResponsablesExp.id);
            nodoResponsablesExp.children.push(nodoUsuarioResp);
          }
        }
        if (item.IdRoleResponsable) {
          let role = StaticData.RolesUsuariosGrupos.FirstOrDefault(x => { return x.Id === item.IdRoleResponsable && x.Tipo == TipoParaPermisos.Role });
          if (role) {
            let nodoRoleResp = that.jstreeExpediente.GetNodeByTypeFromTypes('Role', null, role.Nombre, nodoResponsablesExp.id);
            nodoResponsablesExp.children.push(nodoRoleResp);
          }
        }
        if (item.PalabrasClave) {
          let palabras = that.utility.GetPermitidos(item, false, 'PalabrasClave', ',');
          for (let i = 0; i < palabras.length; i++) {
            let nodoPalabra = that.jstreeExpediente.GetNodeByTypeFromTypes('PalabraClave', null, palabras[i], nodoPalabrasClave.id);
            nodoPalabrasClave.children.push(nodoPalabra);
          }

        }
        //let nodoEmpresaResp = this.jstreeExpediente.GetNodeByTypeFromTypes('AreaEmpresa', null, 'Por Area Empresa', nodoResponsablesExp.id);

        // that.jstreeExpediente.CrearPermisos(nodoPermisos, item, 'Configuracion', false);
        // nodoPermisos.parentNodeId = expedinete.id;

        expedinete.children.push(nodoResponsablesExp);
        // expedinete.children.push(nodoPermisos);
        expedinete.children.push(nodoPalabrasClave);


        nodoExpedinetes.children.push(expedinete);


        if (item.IdUsuarioResponsable === StaticData.Usuario.IdUsuario) {

          nodoUsuario.children.push(that.jstreeExpediente.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoUsuario.id));
        }

        // if (item.IdRoleResponsable === StaticData.Usuario.IdRole) {

        // 	nodoRole.children.push(that.jstreeExpediente.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoRole.id));
        // }
        // if (item.IdVersionAreaEmpresaResponsable === StaticData.Usuario.IdVersionAreaEmpresa) {

        // 	nodoEmpresa.children.push(that.jstreeExpediente.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoEmpresa.id));
        // }
      });




      nodoUsuario.text = nodoUsuario.text + ' [' + nodoUsuario.children.length.toString() + ']';
      // nodoRole.text = nodoRole.text + ' [' + nodoRole.children.length.toString() + ']';
      // nodoEmpresa.text = nodoEmpresa.text + ' [' + nodoEmpresa.children.length.toString() + ']';

      nodoResponsables.children.push(nodoUsuario);
      // nodoResponsables.children.push(nodoRole);
      // nodoResponsables.children.push(nodoEmpresa);



      let nodoExpedinetesPorAreas = this.jstreeExpediente.GetNodeByTypeFromTypes('AreasEmpresa', null, 'Por Area', root.id);
      let itemsGroupByList = new List<any>(items).GroupBy(x => { return x.IdAreaEmpresa });
      $.each(itemsGroupByList, function (group, itemsGroup) {


        let area = StaticData.RolesUsuariosGrupos.FirstOrDefault(x => { return x.Id == group && x.Tipo == TipoParaPermisos.AreaEmpresa });
        let nombreArea = (area) ? area.Nombre : group.toString();
        let nodoAreaEmpresa = that.jstreeExpediente.GetNodeByTypeFromTypes('AreaEmpresa', null, nombreArea + ' [' + itemsGroup.length.toString() + ']', nodoExpedinetesPorAreas.id);

        $.each(items, function (indexExp, itemExp) {

          let nodeExpedineteArea = that.jstreeExpediente.GetNodeByTypeFromTypes('Expediente', null, itemExp.Nombre, nodoAreaEmpresa.id);
          nodoAreaEmpresa.children.push(nodeExpedineteArea);
        });
        nodoExpedinetesPorAreas.children.push(nodoAreaEmpresa);
      });

      nodoExpedinetesPorAreas.text = nodoExpedinetesPorAreas.text + ' [' + nodoExpedinetesPorAreas.children.length.toString() + ']';
      nodoExpedinetes.text = nodoExpedinetes.text + ' [' + nodoExpedinetes.children.length.toString() + ']';

      root.children.push(nodoResponsables);
      root.children.push(nodoExpedinetes);
      root.children.push(nodoExpedinetesPorAreas);



      this.jstreeExpediente.jsonData = [root];
      this.jstreeExpediente.InitTree();
    }

  }
  public CrearNodosdatosJsTree(isClear: boolean) {

    if (!this.jstreeDatosExpediente)
      return;
    const that = this;
    if (isClear) {

      this.jstreeDatosExpediente.jsonData = [];
      this.jstreeDatosExpediente.InitTree();
      return;
    }

    if (StaticData.Usuario) {
      if (this.IsEdit) {
        //debugger;

        const that = this;
        let obj = this.utility.GetApiModel('CrearNodosdatosJsTree', null)
        // obj.QueryConfig.Command = 'BusquedaTipoDocumental';
        obj.QueryConfig.Command = 'BusquedaTipoDocumentalEnExpediente';
        obj.QueryConfig.IsSp = true;
        obj.QueryConfig.IsMulti = false;
        obj.QueryConfig.Entity = {
          IdEmpresa: 1,
          IdExpediente: this.modelo.modelo.IdExpediente,
        };


        let info = {
          componentName: this.componentName, controlName: 'CrearNodosdatosJsTree',
          processMessage: 'Cargando Detalles del expediente......'
        };
        this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

          let tiposDocumentales = data.Data;





          let root = this.jstreeDatosExpediente.GetNodeByTypeFromTypes('Expediente', null, this.modelo.modelo.Nombre);
          let nodoTiposDocumentales = this.jstreeDatosExpediente.GetNodeByTypeFromTypes('TiposDocumentales', null, 'Tipos Documentales Asignados ' + ' [' + tiposDocumentales.length.toString() + ']', root.id);

          $.each(tiposDocumentales, function (index, item) {
            nodoTiposDocumentales.children.push(that.jstreeDatosExpediente.GetNodeByTypeFromTypes('TipoDocumental', null, item.Nombre, nodoTiposDocumentales.id));
          });


          setTimeout(() => {


            //CM

            var tiposDocPendientes = new Array();

            this.tiposDocumentales.forEach(tiposDocConf => {
              if (data.Data.find(tdoc => tdoc.IdTipoDocumental == tiposDocConf.IdTipoDocumental) == null)
                tiposDocPendientes.push(tiposDocConf);
            });

            //CM
            let nodoTiposDocumentalesSinAsignar = this.jstreeDatosExpediente.GetNodeByTypeFromTypes('TiposDocumentales', null, 'Tipos Documentales Sin Asignar ' + ' [' + tiposDocPendientes.length.toString() + ']', root.id);

            //CM
            $.each(tiposDocPendientes, function (index, item) {
              nodoTiposDocumentalesSinAsignar.children.push(that.jstreeDatosExpediente.GetNodeByTypeFromTypes('TipoDocumental', null, item.Nombre, nodoTiposDocumentalesSinAsignar.id));
            });


            root.children.push(nodoTiposDocumentalesSinAsignar);
            root.children.push(nodoTiposDocumentales);

            this.jstreeDatosExpediente.jsonData = [root];
            this.jstreeDatosExpediente.InitTree();
          }, 2000);

        }).catch(err => { });


      }
      else {
        let root = this.jstreeDatosExpediente.GetNodeByTypeFromTypes('Expediente', null, '');
        let nodoTiposDocumentales = this.jstreeDatosExpediente.GetNodeByTypeFromTypes('TiposDocumentales', null, 'Tipos Documentales ' + ' [0]', root.id);

        root.children.push(nodoTiposDocumentales)
        this.jstreeDatosExpediente.jsonData = [root];
        this.jstreeDatosExpediente.InitTree();

      }
    }

  }
  public SetProcesandoBuscar(ok: boolean) {
    this.botonProcesandoBuscar = ok;
    if (ok) {
      this.textoBotonBuscar = '';
      this.textoProcesandoBuscar = 'Ejecutando.......';
    }
    else {
      this.textoBotonBuscar = 'Buscar';
      this.textoProcesandoBuscar = '';
    }
  }

  public Buscar() {


    try {
      this.SetProcesandoBuscar(true);
      this.AsignarModeloModeloBusqueda();

      //this.SetProcesandoBuscar(true);

      //this.ReCargarGrilla();

    }
    catch (error) {
      this.SetProcesandoBuscar(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }
  public AsignarModeloModeloBusqueda() {

    if (this.modelo.modeloCascada) {
      let clone = this.utility.Clone(this.modelo.modeloCascada);

      clone.PalabraClave = this.modelo.modelo.PalabraClave;

      this.utility.SetValorPropiedadBusquedaContains(clone, 'PalabrasClave');

      this.QueryConfig.Entity = clone;
      this.QueryConfig.Entity.IdEmpresa = -1;
      this.componentsContainer.Tables[0].QueryConfig = this.QueryConfig;
      this.componentsContainer.Tables[0].ReCargarGrilla();
    }

  }
  public EditCascading() {
  }
  public FillCombos(modelo) {
  }

  public ExtractPropertyFromJSON(json: string, property: string) {
    if (json != null) {
      var config = JSON.parse(json);
      if (config[property].length > 0)
        return config[property];
      else
        return null;
    }
    else {
      return null;
    }
  }

}
