import { Component, OnInit, Input } from '@angular/core';
import { PropertyGridComponent } from '../property-grid/property-grid.component';

@Component({
  selector: 'app-property-grid-mapping-parameters',
  templateUrl: './property-grid-mapping-parameters.component.html',
  styleUrls: ['./property-grid-mapping-parameters.component.css']
})
export class PropertyGridMappingParametersComponent extends PropertyGridComponent implements OnInit {


  @Input() isModelToModel:boolean=false;
  @Input() property: string = "MappingParameters";
  @Input() title: string ="Mapeo de Parametros"
  @Input() set mappingParameters(value: Array<any>) {
   
    this.subControl[this.property] = value;
  }
  get mappingParameters(): Array<any> {
  
    return this.subControl[this.property];
  }

  ngOnInit() {
    this.Validate();
  }

  public Validate() {
    if (this.subControl) {
      if (!this.mappingParameters)
        this.mappingParameters = [];
    }
  }
  public AddMappingParameter() {
    if (this.subControl) {
      this.Validate();

      this.mappingParameters.push({});

    }
  }
  public DeleteMappingParameter(event) {
    if (this.subControl) {
      this.mappingParameters.splice(event, 1);
    }
  }
}
