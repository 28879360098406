import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ConfigBaseTreeComponent, TiposCrearNodos } from '../../../models/config-base-tree-component';
import { ControlActions, ControllerMethods, FormActionsThroughEmitters, Paginas, TipoObjetoParaPermisos, TipoParaPermisos, TypesUseForm, nodeTypes } from '../../../models/general.enum';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Enumerable, List } from '../../../../assets/linqts/compilado/index';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';

import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { BaseCrud } from '../../../bussines/base-crud';
import { BaseServiceService } from '../../../services/base-service.service'
import { ConfigWindow } from 'src/app/models/config-window';
import { CrudActions } from '../../../models/general.enum';
import { DataTableComponent } from '../../controls/data-table/data-table.component';
import { DobleAuthComponent } from '../doble-auth/doble-auth.component';
import { DocumentoAdjuntosComponent } from '../../documento-expediente/documento-adjuntos/documento-adjuntos.component';
import { DynamicControlsMetadataComponent } from '../../controls/dynamic-controls-metadata/dynamic-controls-metadata.component';
import { EdicionExpedienteComponent } from '../edicion-expediente/edicion-expediente.component';
import { ExpedientePanelComponent } from '../expediente-panel/expediente-panel.component';
import { FormDetailsComponent } from '../../controls/form-details/form-details.component';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { Guid } from "guid-typescript";
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { JsTreeDetalleExpedienteComponent } from '../js-tree-detalle-expediente/js-tree-detalle-expediente.component';
import { OrgChartJsComponent } from '../../org-chart/org-chart-js/org-chart-js.component';
import PerfectScrollbar from 'perfect-scrollbar';
import { StaticData } from '../../../helpers/static-data';
import { Utilities } from '../../../helpers/utilities';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { debug } from 'util';
import { utils } from 'protractor';
import { ExportImportConfig, ExportImportItemConfig } from '../../../models/rule-engine/rules-models';

declare var fabric: any;
declare var $: any;

@Component({
  selector: 'app-detalle-expediente',
  templateUrl: './detalle-expediente.component.html',
  styleUrls: ['./detalle-expediente.component.scss']
})
export class DetalleExpedienteComponent extends GeneralFormComponent implements OnInit, AfterViewInit {

  @ViewChild('JsTreeDetalleExpediente', { static: false }) jsTreeDetalleExpedienteComponent: JsTreeDetalleExpedienteComponent;

  // @ViewChild(AdminPermisosComponent, { static: false }) adminPermisosComponent: AdminPermisosComponent;
  // @ViewChild(OrgChartJsComponent, { static: true }) orgChartJsComponent: OrgChartJsComponent;
  //@ViewChild('edicionExpediente', { static: false }) edicionExpediente: EdicionExpedienteComponent;
  @ViewChild(DobleAuthComponent, { static: false }) dobleAuth: DobleAuthComponent;
  //@ViewChild(DobleAuthComponent, { static: false }) dobleAuth: DobleAuthComponent;
  @ViewChild(ExpedientePanelComponent, { static: false }) expedientePanel: ExpedientePanelComponent;

  //@ViewChild(AdminPermisosComponent, { static: false }) adminPermisosComponent: AdminPermisosComponent;
  // @ViewChild(OrgChartJsComponent, { static: true }) orgChartJsComponent: OrgChartJsComponent;


  // edicionExpediente: EdicionExpedienteComponent;


  // dobleAuth: DobleAuthComponent;
  // expedientePanel: ExpedientePanelComponent;

  @Input() conCamposPermisos: boolean = true;

  public direction: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'vertical';
  public directionTree: string = 'vertical';
  selectionModeAreas: boolean = true;
  public notClear: boolean = false;
  DynamicMetadata: DynamicControlsMetadataComponent;
  DynamicMetadataExpediente: DynamicControlsMetadataComponent;
  DynamicMetadataFiltro: DynamicControlsMetadataComponent;

  //queryConfig = { Command: 'BusquedaExpedientes', IsSp: true, Entity: { IdEmpresa: -1 }, WithPagination: true };
  // configBaseTreeComponent: ConfigBaseTreeComponent;
  jsonProject: any;
  selectedNodes = new List<any>([]);
  notSelectablesNodesTypes = new List<any>([]);
  nodosAreas: Array<any> = [];
  nodosSeries: Array<any> = [];
  modeloTemp: any = null;
  itemsAreasEmpresa: Array<any> = [];
  itemsRoles: Array<any> = [];
  itemsSeries: Array<any> = [];
  itemsUsuarios: Array<any> = [];
  public sanitizer: DomSanitizer;
  EstiloBuscarContenedor: any = { height: "800px" };
  modeloTRD: any = null;
  infoTRD: string = '';
  itemsTRD: Array<any> = [];
  imagenPath: string;
  verAdminPermisos: boolean = true;
  adminPermisos: boolean = true;
  tienePermisoGuardar: boolean = true;
  BtnVincular: any;
  pk: any;
  documentosExpedientes: any;
  modeloDetalle: any;


  cargarComponentes: boolean = false;
  verGrilla: boolean = true;
  orgNodos = []
  orgNodosPermisos = []
  orgNodosGeneral = []
  orgNodosGeneral2 = []
  dataSelecetedNodes = []
  dataSelecetedFolders = []
  expediente: any;
  isFistLoad: boolean = true

  CheckTrasladoSeccional: any;
  CmbxIdSedeEmpresa: any;

  configPermisosUbicacion: any;
  configPermisosDocumento: any;

  tiposDocPendientes = new Array();
  tiposDocEnUso = new Array();
  public sidePanel: any;
  public sidePanelNuevoDocumento: any;

  adjuntos = new Array();
  IdExpedientePanel: any;
  // Configuracion = new Array();
  configPermisos: Array<any> = [];
  controlesNuevopanel = new Array();

  viewPanelDocumentos: boolean = false;
  DobleAuthData: any = {};
  configJstree: any = { Label: 'Información', Name: 'Floatpanel-jstree-detalle-expediente', StartMinimized: true, MinimizeOnClose: true };
  GridViewRadicados: any;
  GridViewAuditoria: any;
  GridViewAuditoriaDocumento: any;
  DivPqrs: any;
  DivEntrada: any;
  DivFactura: any;
  DivFactura2: any;
  DivPqrs2: any;
  DivMemorando: any;
  IdTipoDocumental: any;
  DivSalida: any;
  MensajeRadicados: any;
  GridViewFiltroAuditoria: any;
  GridViewFiltroAuditoriaDocumento: any;
  DataTables_TDocumentoExpediente: any;
  totalExpedientes: any;
  listaTipoDocumentalRequeridos: any;
  vistaArbol: boolean = false;
  // HeredaPermisosExpediente: boolean = false;
  cierreSinFirma: any;
  labelComboFiltroJstree = "Cantidad a Filtrar";
  idComboFiltro = 5;
  BusquedaTree: any;
  TotalRegistros: any = 1;
  configDocumento: any;
state = null;
  public itemsComboFiltro: Array<any> = [
    { Name: 5, Label: '5' },
    { Name: 10, Label: '10' },
    { Name: 15, Label: '15' },
    { Name: 25, Label: '25' },
    { Name: 50, Label: '50' },
    { Name: 100, Label: '100' },
    { Name: 200, Label: '200' },
  ];
  isSearch: boolean = null;

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);

    //this.configBaseTreeComponent = new ConfigBaseTreeComponent(this.utility, this.baseService, this.sanitizer, helperImages);
    //
    //this.orgChartJsComponent = new OrgChartJsComponent(this.utility, this.baseService, this.sanitizer, helperImages);
    // this.expedientePanel = new ExpedientePanelComponent(this.baseService, this.utility, helperImages, helperRules, helperActions);
    //this.dobleAuth = new DobleAuthComponent(this.baseService, this.utility, helperImages, helperRules, helperActions);


    //  this.edicionExpediente = new EdicionExpedienteComponent(this.baseService, this.utility, helperImages, helperRules, helperActions);


    this.notSelectablesNodesTypes.Add('Series');
    this.notSelectablesNodesTypes.Add('SubSeries');
    this.notSelectablesNodesTypes.Add('AreasEmpresa');
  }

  public OnInit() {
    this.cierreSinFirma = StaticData.GeneralConfig.ConfigValidacionExpediente.CierreSinFirma;
    this.configDocumento = StaticData.GeneralConfig.ConfigValidacionDocumento
    this.vistaArbol = this.configDocumento.VistaArbolDetalleExpediente;

    // this.HeredaPermisosExpediente = StaticData.GeneralConfig.ConfigValidacionDocumento.HeredaPermisosExpediente;
    this.modelo.modeloVirtual.UsuarioAutenticado = StaticData.Usuario.IdUsuario;

    this.expediente = this.utility.EditItem;

    if (this.utility.ReturnParentDetailsItem) {
      this.expediente = this.utility.ReturnParentDetailsItem;

      this.utility.ReturnParentDetailsItem = null;
    }

    if (this.expediente) {
      //debugger;
      if (this.expediente.IdUsuarioResponsable == this.modelo.modeloVirtual.UsuarioAutenticado){
        StaticData.Usuario.TodosPermisosDocumentosExpedientes = true;
        // StaticData.Usuario.TodosPermisosExpedientes = true;
        this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = true
      }
      // if (this.expediente.IdUsuarioResponsable == this.modelo.modeloVirtual.UsuarioAutenticado){
      //   StaticData.Usuario.TodosPermisosDocumentosExpedientes = false;
        //   StaticData.Usuario.TodosPermisosExpedientes = false;
        //   this.modelo.modeloVirtual.TodosPermisosDocumentosExpedientes = false;
      // }
      if (!this.expediente.PkValue)
        this.expediente.PkValue = this.expediente.IdExpediente;

      this.modelo.modeloVirtual.PermisosHeredadosExpediente = this.configDocumento.HeredaPermisosExpediente;
      if (this.expediente.PalabrasNormalizadas) {
        var Palabras = JSON.parse(this.expediente.PalabrasNormalizadas);
        var PalabrasProcesadas = "";
        Palabras.forEach((Palabra) => {
          Object.entries(Palabra).forEach(([key, value]) => {
            if (key == "Palabra") {
              PalabrasProcesadas += value + ", ";
            }
          });
        });
        this.expediente.PalabrasProcesadas = PalabrasProcesadas.substring(0, PalabrasProcesadas.length - 2);;

      }

      this.modelo.modelo = this.expediente;

      //Miguel Patiño
      //0004789: Ajuste en detalle expediente agregar el número del expediente al titulo de la pagina
      //0004839: Ajustar en detalle expediente agregar el Código Único de Identificación del expediente al titulo de la pagina
      //debugger;
      if (this.modelo.modelo.NumeroRadicacionProceso == undefined) {
        this.Pagina.Titulo = this.expediente.Codigo;
      } else {
        this.Pagina.Titulo = "C.U.I " + this.modelo.modelo.NumeroRadicacionProceso;
      }


      /**se agrega validación para this.utility["EditItemMetadata"] ya que returna  undefined por tanto reescribe modeloMetadata  y lo deja undefined 
       * por lo que al buscar el valor del metadato no setea los valores de cada control de metadatos en el detalle de expediente./ */
       if(this.utility["EditItemMetadata"] != undefined){
        this.modelo.modeloMetadata = this.utility["EditItemMetadata"];
       }
      
      //this.modelo.modeloMetadata = this.expediente.modeloMetadata;
      this.cargarComponentes = true;
      //
      this.modelo.modelo.IdExpediente = this.expediente.IdExpediente;
      //this.modelo.modelo.IdExpediente = this.expediente.modelo.IdExpediente;

      //this.utility.EditItem = null;

      this.configPermisosUbicacion = {
        TipoObjetoParaPermiso: TipoObjetoParaPermisos.Ubicacion, Pagina: this.Pagina, clear: true,
        // expediente: this.expediente.modelo,
        expediente: this.expediente,
        CreatedRowConfig: {}
      };
      this.configPermisosDocumento = {
        TipoObjetoParaPermiso: TipoObjetoParaPermisos.Documento, Pagina: this.Pagina, clear: true,
        // expediente: this.expediente.modelo,
        expediente: this.expediente,
        CreatedRowConfig: {}
      };

      super.OnInit();
    }

  }

  public DestroyJsTree() {
    $("#id_paginator").twbsPagination('destroy')
  }

  public BuscarDocumentoTree() {
    let nodoDocumentos: any = null;
    this.DestroyJsTree();
    this.jsTreeDetalleExpedienteComponent.CrearDocumentosPaginador(nodoDocumentos, true, 0, this.idComboFiltro, false, this.BusquedaTree);
  }
  ngAfterViewInit() {

    //
this.CheckTrasladoSeccional = this.form.controls["CheckTrasladoSeccional"];
    this.SetModeloPermisos(false, true);

    this.sidePanel = document.querySelector("#SidePanelDetalleExpediente");
    // this.sidePanelNuevoDocumento = document.querySelector("#PanelDocumentoExpediente");

    setTimeout(() => {
      if (this.commandBar)
        this.commandBar.SetHiddenOption(false, "Name", "Retornar");
      let nodoDocumentos: any = null;
      if (this.jsTreeDetalleExpedienteComponent) {
        this.jsTreeDetalleExpedienteComponent.CrearDocumentosPaginador(nodoDocumentos, true, 0, 0, false);
        // this.jsTreeDetalleExpedienteComponent.expedinteDetalleLoadesEventEmitter.subscribe(modelo => {
        //   this.TotalRegistros = modelo.recordsTotal;
        //   // this.jsTreeDetalleExpedienteComponent.expedinteDetalleLoadesEventEmitter.unsubscribe();
        // });
      }
      // if (this.vistaArbol)
      // {
      //   this.componentsContainer.Tables[0].QueryConfig.Entity.IdCuaderno = 0;
      //   this.componentsContainer.Tables[0].ReCargarGrilla();
      // }



    }, 1000);

    if (this.CheckTrasladoSeccional) {
      this.CheckTrasladoSeccional.valueChanges.subscribe(value => {
        if (value != null) {
          if (value) {
            const context = this;
            const action = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["IdSedeEmpresa"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: "true",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };
            this.helperActions.SetControlsValue(action, context);

            this.CmbxIdSedeEmpresa.Hidden = false;
            this.modelo.modeloVirtual.EsOtraSeccional = true;
            this.SetModelToControls();
            $("input[id='CheckTrasladoSeccional']").prop('checked', true);

          }
          else {

            const context = this;
            const action = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["IdSedeEmpresa"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };
            this.helperActions.SetControlsValue(action, context);

            this.CmbxIdSedeEmpresa.Hidden = true;
            this.modelo.modeloVirtual.EsOtraSeccional = false;
            this.modelo.modeloVirtual.Juzgado = null;
            this.modelo.modeloVirtual.IdSedeEmpresa = null;
             this.SetModelToControls();
             this.configs.ConfigJuzgado.QueryConfig.Filter = "IdSedeEmpresa = (SELECT IdSedeEmpresa FROM RAreaEmpresa WHERE IdAreaEmpresa =" + this.modelo.modelo.IdAreaEmpresa +")";
             this.configs.ConfigJuzgado.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
            $("input[id='CheckTrasladoSeccional']").prop('checked', false);
          }
        }
        else {
          $("input[id='CheckTrasladoSeccional']").prop('checked', this.modelo.modeloVirtual.EsOtraSeccional);
        }
      });
    }
    if(StaticData.GeneralConfig.ConfigLicence.ValideLicence?StaticData.GeneralConfig.ConfigLicence.ValideLicence : false){
      this.modelo.modelo.IdTipoRole = 1;
      this.SetModelToControls();
      let obj = this.utility.GetApiModel("ConsultaTipoRoleLicencia", null);
        obj.QueryConfig = {
          Command: "ConsultaTipoRoleLicencia",
          IsSp: true,
          Entity: {
            IdRole: StaticData.Usuario.IdRole,
          },
        };
        let info = {
          componentName: this.componentName,
          controlName: "ConsultaTipoRoleLicencia",
          processMessage: "Cargando ConsultaTipoRoleLicencia......",
        };

        this.baseService
          .Get(obj, null, info, this.componentName, false, false)
          .then((data) => {
            this.modelo.modelo.IdTipoRole = data.Data[0].IdTipoRole;
            this.SetModelToControls();
          })
          .catch((err) => {
            this.utility.logger.LogError(err, obj, {
              componentName: this.componentName,
              method: "Consulta",
            });
          });
    }
    super.ngAfterViewInit();

  }

  public OnChangeComboFiltro(event) {
    let nodoDocumentos: any = null;
    this.DestroyJsTree();
    if (this.jsTreeDetalleExpedienteComponent)
      this.jsTreeDetalleExpedienteComponent.CrearDocumentosPaginador(nodoDocumentos, true, 0, this.idComboFiltro, false);
    this.TotalRegistros = this.jsTreeDetalleExpedienteComponent.TotalRegistros;
  }

  SetModeloPermisos(isEdit: boolean, isAfterViewInit: boolean = false) {
    const that = this;


    if (isEdit) {
      this.verAdminPermisos = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'VerPermisos');
      this.adminPermisos = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'AdminPermisos');
      //this.tienePermisoGuardar = this.utility.TienePemisoUsuario(this.configPermisos, this.modelo, 'Guardar');
    }
    else {
      this.verAdminPermisos = true;
      this.adminPermisos = true;
      //this.tienePermisoGuardar = true;
    }

    // if (this.adminPermisosComponent) {
    //   this.adminPermisosComponent.verPermitido = this.verAdminPermisos;
    //   this.adminPermisosComponent.guardarPermitido = this.adminPermisos;
    // }
  }
  public onSpecificStart() {

    let TipoObjetoParaPermiso = TipoObjetoParaPermisos.Expediente;
    if (this.configDocumento.HeredaPermisosExpediente)
      TipoObjetoParaPermiso = TipoObjetoParaPermisos.Documento;

    this.permissionsConfig = {
      TipoObjetoParaPermiso: TipoObjetoParaPermiso,
      Pagina: this.Pagina, clear: true, expediente: this.modelo.modelo, CreatedRowConfig: {}
    };
  }
  public OnInitEnd() {

    this.GridViewRadicados = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "GridViewRadicados" });
    this.DivPqrs = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivPqrs" });
    this.DivEntrada = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivEntrada" });
    this.DivFactura = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivFactura" });
    this.DivFactura2 = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivFactura2" });
    this.DivPqrs2 = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivPqrs2" });
    this.DivSalida = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivSalida" });
    this.DivMemorando = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DivMemorando" });
    this.IdTipoDocumental = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoDocumental" });
    this.BtnVincular = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Vincular" });
    this.CheckTrasladoSeccional = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CheckTrasladoSeccional" });
    this.CmbxIdSedeEmpresa = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdSedeEmpresa" });


    this.postSaveEmitter.subscribe(modelo => {

      //
      /// PARA QUE SE RECARGAN TODAS LAS GRILLAS
      //this.utility.VerModalOk("Se creó el Expediente con código: " + "\n\r" + modelo.data.data.Codigo);
      console.log(modelo);

      this.componentsContainer.Tables.forEach(grilla => {
        //

        grilla.ReCargarGrilla();

      });

    });

    this.postClearEmitter.subscribe(modelo => {
      this.expedientePanel.Limpiar();
    });

    //Acciones de helper-actions
    this.actionEmitter.subscribe(event => {
      this.OnActionEventHandler(event);
    });


  }
  public FuncGetTiposDocumental() {

    var that = this;
  }
  public VerAdjuntos() {

    if (this.modelo && this.modelo.documento && this.modelo.documento.ConfiguracionAdicional) {
      let config = this.modelo.documento.ConfiguracionAdicional;

      this.adjuntos = this.ExtractPropertyFromJSON(config, "Files");

      this.adjuntos.forEach(adj => {
        adj.TienePermisosVisualizacion = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.documento, 'Ver');
        adj.TienePermisosDescarga = this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.documento, 'Descargar');
        adj.Image = this.helperImages.GetRuleIcon(adj.Extension);
      })
      new fabric['Panel'](this.sidePanel);
      console.log("adjuntos detalle", this.adjuntos);
    }
    else {
      let descripcion: string = "El documento no cuenta con adjuntos"
      this.utility.VerModalOk(descripcion)
    };
  }

  public OnDataTableEditHandler(event) {
    //debugger;

    switch (event.name) {
      case 'GridViewRadicados':
        {
        //debugger;
        this.modelo.modeloVirtual.GridRadicadosAsociados = null;
        this.modelo.modelo.IdSolicitud = event.modelo.modeloVirtual.IdSolicitud;
        this.modelo.modelo.TipoRadicado = event.modelo.modeloVirtual.TipoSolicitud;
        this.modelo.modelo.AsociacionMultiple = event.modelo.modeloVirtual.AsociacionMultiple;

        if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
          this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
          /*this.modelo.modeloVirtual.GridRadicadosAsociados.forEach((element) => {
              element.Disabled = true;
              element.DisabledDelete = true;
            });*/
        }

        var obj = this.utility.GetApiModel('ConsultaDetalleMultiRadicados', this.componentName);

        obj.QueryConfig.Command = 'ConsultaDetalleMultiRadicados';
        obj.QueryConfig.IsSp = true;
        obj.QueryConfig.Entity = { TipoRadicado: event.modelo.modeloVirtual.TipoSolicitud, IdSolicitud: event.modelo.modeloVirtual.IdSolicitud };
        this.baseService.Get(obj, null,
            {
              componentName: this.componentName,
              method: 'ConsultaDetalleMultiRadicados',
              processMessage: 'Consultando detalle de radicado...'
            }, 'TExpediente')
          .then(data => {

            if (data.Data[0].Message != null || data.Data[0].Message != undefined) {
              this.utility.VerModalWarning({
                  titulo: 'Alerta', descripcion: data.Data[0].Message,
                  verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                }).then(data => { });
            }
              else {

              /*let obj = this.utility.GetApiModel('GetSolicitudDocuments', null)

                let info = {
                  componentName: this.componentName, controlName: 'ObtenerDocumentosSolicitud',
                  processMessage: 'Obteniendo documentos de la solicitud'
                };*/


              this.modelo.modeloVirtual = data.Data[0];
              this.modelo.modeloVirtual.RadTipoRadicado = event.modelo.modelo.TipoRadicado;
              switch (this.modelo.modeloVirtual.RadTipoRadicado) {
                case 'Radicado Pqrs':
                  this.DivPqrs.Hidden = false;
                  this.DivEntrada.Hidden = true;
                  this.DivFactura.Hidden = true;
                  this.DivFactura2.Hidden = true;
                  this.DivPqrs2.Hidden = false;
                  this.DivSalida.Hidden = true;
                  this.DivMemorando.Hidden = true;
                  /*obj = {
                      ExecutionObject:
                      {
                        ObjectType: 'SystemQuery',
                        GUID: '23a32b13-44d7-3b58-0c8a-7701ad12d2b1'
                      },
                      DataAdd: {
                        IdSolicitudPqrd: this.modelo.modelo.IdSolicitud
                      }
                    };*/

                  break;
                case 'Radicado Entrada':
                  this.DivPqrs.Hidden = true;
                  this.DivEntrada.Hidden = false;
                  this.DivFactura.Hidden = true;
                  this.DivFactura2.Hidden = true;
                  this.DivPqrs2.Hidden = true;
                  this.DivSalida.Hidden = true;
                  this.DivMemorando.Hidden = true;
                  break;
                case 'Radicado Salida':
                  this.DivPqrs.Hidden = true;
                  this.DivEntrada.Hidden = true;
                  this.DivFactura.Hidden = true;
                  this.DivFactura2.Hidden = true;
                  this.DivPqrs2.Hidden = true;
                  this.DivSalida.Hidden = false;
                  this.DivMemorando.Hidden = true;
                  break;
                case 'Radicado Factura':
                  this.DivPqrs.Hidden = true;
                  this.DivEntrada.Hidden = true;
                  this.DivFactura.Hidden = false;
                  this.DivFactura2.Hidden = false;
                  this.DivPqrs2.Hidden = true;
                  this.DivSalida.Hidden = true;
                  this.DivMemorando.Hidden = true;

                  break;
                case 'Radicado Memorando':
                  this.DivPqrs.Hidden = true;
                  this.DivEntrada.Hidden = true;
                  this.DivFactura.Hidden = true;
                  this.DivFactura2.Hidden = true;
                  this.DivPqrs2.Hidden = true;
                  this.DivSalida.Hidden = true;
                  this.DivMemorando.Hidden = false;
                  if (this.modelo.modeloVirtual.RadDestinatariosCopias) {
                    const Anexos = JSON.parse(this.modelo.modeloVirtual.RadDestinatariosCopias);
                    let valorDestinatario = "";
                    Anexos.forEach(anexo => {
                      valorDestinatario += "Nombre: " + anexo.Nombre + "  Email: " + anexo.Email + '\n';
                    });
                    this.modelo.modeloVirtual.RadDestinatariosCopias = valorDestinatario;
                  }

                  break;
              }

              //debugger;
              if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
                this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
                /*this.modelo.modeloVirtual.GridRadicadosAsociados.forEach((element) => {
                    element.Disabled = true;
                    element.DisabledDelete = true;
                  });*/
              }

              this.modelo.modeloVirtual.IdSolicitud = event.modelo.modelo.IdSolicitud;
              this.modelo.modeloVirtual.IdExpedientePrevio = this.modelo.modelo.IdExpediente;

              /*this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/GetSolicitudDocuments', false, false).then(data => {


                  this.modelo.documentosSolicitud = data.Data;
                  this.SetModelToControls();

                }).catch(err => {
                  this.utility.VerModalError(err);

                });*/


              this.SetModelToControls();
            }

            }).catch(err => {
            this.utility.logger.LogError(err, obj, {
                componentName: this.componentName,
                method: 'ConsultaDetalleMultiRadicados',
                processMessage: 'Consultando detalle de radicado...'
              }, 'TExpediente')
          });
        break;
      }
      case 'GrillaAsignarExpediente':
        {
        const trd = JSON.parse(event.data.data.findItem.ConfiguracionTRD);
        const tiposDoc = trd["TiposDocumentales"];
        this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);
        this.BtnVincular.Hidden = true;
        break;
      }
/*se agregan las lineas de codigo de la 666 a la 671 las cuales si la grilla seleccionada es DataTables_TDocumentoExpediente y si PalabrasNormalizadas existe y
        no esta vacio entonces convertimos ese valor JSON en un objeto y con eso objeto se llena la gilla GridPalabrasNormalizadas */
      case 'DataTables_TDocumentoExpediente':
        {
          if (this.modelo.documento.PalabrasNormalizadas && this.modelo.documento.PalabrasNormalizadas != "" && this.modelo.documento.PalabrasNormalizadas != "[]") {
            this.modelo.modeloVirtual.GridPalabrasNormalizadas = JSON.parse(this.modelo.documento.PalabrasNormalizadas);
          }
        }
    }
    super.OnDataTableEditHandler(event);
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {

    switch (controlName) {
      case "IdTipoDocumental": {
        this.BtnVincular.Hidden = false;
        this.modelo.modelo.DataAdd =
        {
          IdExpedienteNuevo: this.modelo.expediente.IdExpediente,
          IdExpedientePrevio: this.modelo.modeloVirtual.IdExpedientePrevio,
          IdRadicado: this.modelo.modelo.IdSolicitud,
          TipoRadicado: this.modelo.modelo.TipoRadicado
        }
        break;
      }
      case "IdSedeEmpresa": {
        //debugger;
        if(item)
        {
          this.modelo.modeloVirtual.Juzgado = null;
          this.configs.ConfigJuzgado.QueryConfig.Filter = "IdSedeEmpresa = " +  item.IdSedeEmpresa;
          this.configs.ConfigJuzgado.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
        }
        else{
          this.modelo.modeloVirtual.Juzgado = null;
        }
        break;
      }

      case "Juzgado": {
        //debugger;
        if(item)
        {
          if(item.IdAreaEmpresa == this.modelo.modelo.IdAreaEmpresa)
          {
            this.modelo.modeloVirtual.Juzgado = null;
            this.utility.VerModalError("Por favor seleccione un Juzgado diferente al actual");
          }
          else
          {
            this.modelo.modeloVirtual.NombreJuzgado = item.Nombre;
          }
        }
        break;
      }

      case "IdTipoDocumentalFiltro": {
        //debugger;
        this.GetDetalleTipoDocumental(item);
        break;
      }
    }
    super.OnSelectedComboBox(item, controlName, config);

  }

  public AddComponentInstanceHandler(event) {
    // console.error("AddComponentInstanceHandler",event)
    if (event instanceof DobleAuthComponent) {
      //
      this.dobleAuth = event;
      // this.dobleAuth.IdExpediente = this.expediente.IdExpediente;

    }

    else if (event instanceof FormDetailsComponent) {


      if (event.config && event.config.Name == "FormDetalleExpediente") {

        event.modelo = this.expediente;
      }


      this.componentsContainer.FormsDetails.push(event);
    }
    else if (event instanceof OrgChartJsComponent) {
      //  this.orgChartJsComponent = event;
    }
    else if (event instanceof DynamicControlsMetadataComponent) {

      if (event.config && event.config.Name == "DynamicMetadataExpediente") {
        this.DynamicMetadataExpediente = event;
        this.DynamicMetadataExpediente.form = this.form;
      }
      else if (event.config && event.config.Name == "DynamicMetadata") {
        this.DynamicMetadata = event;
        this.DynamicMetadata.form = this.form;
      } else if (event.config && event.config.Name == "DynamicMetadataFiltro") {
        this.DynamicMetadataFiltro = event;
        this.DynamicMetadataFiltro.form = this.form;
      }
      this.AddComponentInstanceHandlerInGeneral(event);
    }
    //     else if (event instanceof DataTableComponent) {
    //         if (event.config && event.config.Name == "DataTables_TDocumentoExpediente") {
    // debugger
    //         }
    //         // setTimeout(() => {
    //         super.AddComponentInstanceHandler(event);

    //     }
    else {

      if (event instanceof JsTreeDetalleExpedienteComponent) {
        //
        this.jsTreeDetalleExpedienteComponent = event;
      }
      else {
        /*   let _component = new List(this.childComponents).FirstOrDefault(x => { return x.name == event.name });
          if (!_component)
            this.childComponents.push(event); */
        super.AddComponentInstanceHandler(event);
      }
    }
  }

  public ValidarEstadoRadicadosCierre() {

    var obj = this.utility.GetApiModel('ValidarEstadoRadicados', this.componentName);

    obj.QueryConfig.Command = 'ValidarEstadoRadicadosCierreExpediente';
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = { IdExpediente: this.modelo.modelo.IdExpediente };

    this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          method: 'ValidarEstadoRadicadosCierreExpediente',
          processMessage: 'Verificando estados de radicados...'
        }, 'TExpediente')
      .then(data => {

        if (data.Data[0].Message != null || data.Data[0].Message != undefined) {
          this.MensajeRadicados = data.Data[0].Message;
        }
      }).catch(err => {
        this.utility.logger.LogError(err, obj, {
            componentName: this.componentName,
            method: 'ValidarEstadoRadicadosCierreExpediente',
            processMessage: 'Verificando estados de radicados...'
          }, 'TExpediente')
      });
  }

  public EfectuarProcesoCierre(modeloTemp) {

    const that = this;
    let modeloInterno: any = { VersionModelData: { ObservacionesModificacion: '' } };
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'warning';
    config.titleWindow = 'Observación de cierre';
    config.returnInstance = false;
    config.modal = true;
    config.width = 750;
    config.height = 400;
    config.viewExtra = false;
    config.ok = 'Registrar';
    config.viewOk = true;
    let windowModel: any = {
      modelo: modeloInterno.VersionModelData,
      config: this.utility.GetConfigObservacionesVersion()
    };

    this.utility.OpenWindow('ObservacionesModificacionVersionComponent',
        config, windowModel).then(dataResult => {
        dataResult.onClosePromise.then(dataResultOnClose => {

          if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {
            if (dataResultOnClose.modelo.ObservacionesModificacion) {
              // Cambiar a true la validacion (Solo para pruebas es false)
              // if (!StaticData.GeneralConfig.ConfigValidacionExpediente.CierreSinFirma) {
              //   let obj = this.utility.GetApiModel('CloseExpediente', null)
              //   obj = {
              //     IdExpediente: this.modelo.modelo.IdExpediente,
              //     DataAdd: {
              //       IdCarpeta: this.modelo.modeloVirtual.IdCarpetaFoliado,
              //       IdTipoDocumental: this.modelo.modeloVirtual.IdTipoDocumentalFoliado,
              //       ObservacionCierre: dataResultOnClose.modelo.ObservacionesModificacion,
              //       CodigoExpediente: this.modelo.modelo.Codigo,
              //       TipoProceso: 'Cierre',
              //       TipoBodega: 'Gestión',
              //       GUIDPlantilla: this.modelo.modeloVirtual.PlantillaFoliadoElectronico
              //     }
              //   };

              //   let info = {
              //     componentName: this.componentName, controlName: 'CerrarExpediente',
              //     processMessage: 'Realizando el cierre del expediente'
              //   };
              //   return this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/CloseExpediente', false, false).then(data => {

              //     if (data.Data.estado != '0') {
              //       this.modelo.modelo.IdEstado = 1;
              //       this.utility.VerModalError("No fue posible el cierre del expediente, realice el proceso nuevamente");
              //     }
              //     else {
              //       this.componentsContainer.Tables[0].ReCargarGrilla();
              //       this.componentsContainer.Tables[1].ReCargarGrilla();
              //       this.modelo.modelo.IdEstado = 2;
              //       this.utility.VerModalOk(data.Data.mensaje);
              //     }
              //   }).catch(err => {
              //     this.utility.VerModalError(err);
              //   });
              // }
              // else {
              //   // this.utility.VerEdicionFormulario(modeloTemp, 'DobleAuthComponent', true).then(result => {

              //   //   console.log("DetalleExpedienteComponent -> this.dobleAuth", that.dobleAuth)
              //   //   that.modelo.modelo.IdEstado = 2; //
              //   //   that.componentsContainer.Tables[0].ReCargarGrilla();
              //   //   that.componentsContainer.Tables[1].ReCargarGrilla();
              //   //   if (that.dobleAuth) {
              //   //     that.dobleAuth.onCloseExpediente.subscribe(item => {
              //   //       console.log("Data.Data dobleauth", item);
              //   //       that.componentsContainer.Tables[0].ReCargarGrilla();
              //   //     })
              //   //   }

              //   // })
              this.FirmaDigitalCierreExpediente(dataResultOnClose.modelo.ObservacionesModificacion)
              // }
            }
            else
              this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });

          }
        });
      })
  }

  public FirmaDigitalCierreExpediente(Message?: any) {
    let modeloTemp: any;
    let nodoDocumentos: any;
    if (this.cierreSinFirma) {
      let obj = this.utility.GetApiModel('CloseExpediente', null)
      obj = {
        IdExpediente: this.modelo.modelo.IdExpediente,
        DataAdd: {
          IdCarpeta: this.modelo.modeloVirtual.IdCarpetaFoliado,
          IdTipoDocumental: this.modelo.modeloVirtual.IdTipoDocumentalFoliado,
          ObservacionCierre: '',
          CodigoExpediente: this.modelo.modelo.Codigo,
          TipoProceso: 'Cierre',
          TipoBodega: 'Gestión',
          GUIDPlantilla: this.modelo.modeloVirtual.PlantillaFoliadoElectronico
        }
      };
      if (Message)
        obj.DataAdd.ObservacionCierre = Message

      let info = {
        componentName: this.componentName, controlName: 'CerrarExpediente',
        processMessage: 'Realizando el cierre del expediente'
      };
      return this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/CloseExpediente', false, false).then(data => {

          if (data.Data.estado != '0') {
            this.modelo.modelo.IdEstado = 1;
            this.utility.VerModalError("No fue posible el cierre del expediente, realice el proceso nuevamente");
          }
        else {
            this.componentsContainer.Tables[0].ReCargarGrilla();
            this.componentsContainer.Tables[1].ReCargarGrilla();
            this.modelo.modelo.IdEstado = 2;
            if (this.jsTreeDetalleExpedienteComponent) {
              this.DestroyJsTree();
              this.jsTreeDetalleExpedienteComponent.CrearDocumentosPaginador(nodoDocumentos, true, 0, 0, false);
            }
            this.utility.VerModalOk(data.Data.mensaje);
          }
        }).catch(err => {
          this.utility.VerModalError(err);
        });
    }
    else {
      modeloTemp = {
        typeUseForm: TypesUseForm.ModalCreate,
        pageId: 'DobleAuth',
        IdExpediente: this.modelo.modelo.IdExpediente,
        CodigoExpediente: this.modelo.modelo.Codigo,
        ObservacionCierre: ''
      };
      if (Message)
        modeloTemp.ObservacionCierre = Message
      let onInitializedDobleAuth = new EventEmitter<any>();
      onInitializedDobleAuth.subscribe(item => {
        this.dobleAuth = item;
        this.dobleAuth.onCloseExpediente.subscribe(item => {
          this.modelo.modelo.IdEstado = item.estadoExpediente;
          this.componentsContainer.Tables[0].ReCargarGrilla();
          if (this.jsTreeDetalleExpedienteComponent) {
            this.DestroyJsTree();
            this.jsTreeDetalleExpedienteComponent.CrearDocumentosPaginador(nodoDocumentos, true, 0, 0, false);
          }
        });
      });
      this.utility.VerEdicionFormulario(modeloTemp, 'DobleAuthComponent', true, undefined, undefined, undefined, undefined, onInitializedDobleAuth).then(resp => {
      })
    }
  }


  public EfectuarProcesoApertura(modeloTemp) {

    const that = this;
    let modeloInterno: any = { VersionModelData: { ObservacionesModificacion: '' } };
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'warning';
    config.titleWindow = 'Observación de Reapertura';
    config.returnInstance = false;
    config.modal = true;
    config.width = 750;
    config.height = 400;
    config.viewExtra = false;
    config.ok = 'Registrar';
    config.viewOk = true;
    let windowModel: any = {
      modelo: modeloInterno.VersionModelData,
      config: this.utility.GetConfigObservacionesVersion()
    };

    this.utility.OpenWindow('ObservacionesModificacionVersionComponent',
        config, windowModel).then(dataResult => {

        dataResult.onClosePromise.then(dataResultOnClose => {

          if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {
            if (dataResultOnClose.modelo.ObservacionesModificacion) {
              modeloTemp = {
                typeUseForm: TypesUseForm.ModalCreate,
                pageId: 'DobleAuth',
                IdExpediente: this.modelo.modelo.IdExpediente,
                ObservacionReapertura: dataResultOnClose.modelo.ObservacionesModificacion,
                CodigoExpediente: this.modelo.modelo.Codigo,
              };

              let obj = this.utility.GetApiModel('CloseExpediente', null)
              obj = {
                IdExpediente: this.modelo.modelo.IdExpediente,
                DataAdd: {
                  TipoProceso: 'Reapertura',
                  CodigoExpediente: this.modelo.modelo.CodigoExpediente,
                  TipoBodega: 'Gestión',
                  Descripcion: dataResultOnClose.modelo.ObservacionesModificacion
                }
              };

              let info = {
                componentName: this.componentName, controlName: 'ReaperturaExpediente',
                processMessage: 'Realizando la reapertura del expediente'
              };
              this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/CloseExpediente', false, false).then(data => {

                  if (data.Data.IdEstado != '1')

                    this.utility.VerModalError("El expediente no pudo ser reabierto");

                  else {
                    this.modelo.modelo.IdEstado = data.Data.IdEstado;
                    //this.modelo.modelo.IdEstado = this.DobleAuthData.estadoExpediente;
                    this.utility.VerModalOk("Expediente reabierto correctamente");
                  }
                }).catch(err => {
                  this.utility.VerModalError(err);

                });

              /*
              this.utility.VerEdicionFormulario(modeloTemp, 'DobleAuthComponent', false).then(result => {

                console.log("DetalleExpedienteComponent -> this.dobleAuth", that.dobleAuth)
                that.modelo.modelo.IdEstado = 1; //
                that.componentsContainer.Tables[0].ReCargarGrilla();
                that.componentsContainer.Tables[1].ReCargarGrilla();
                //that.modelo.modelo.IdEstado = that.DobleAuthData.IdEstado;
                that.dobleAuth.onCloseExpediente.subscribe(item => {
                  console.log("Data.Data dobleauth", item);

                  that.componentsContainer.Tables[0].ReCargarGrilla();

                })
              })
              */
            }
            else
              this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });

          }
        });
      })
  }


  public GetMetadatoDetaleDocumento() {

    let obj = this.utility.GetApiModel('GetDetalleTipoDocumental', null)
    obj.QueryConfig = {
      Command: 'BusquedaTipoDocumentalDetalle',
      IsSp: true,
      Entity: {
        IdEmpresa: -1,
        IdTipoDocumental: this.modelo.documento.IdTipoDocumental
      }
    };

    let info = {
      componentName: this.componentName, controlName: 'GetDetalleTipoDocumental',
      processMessage: 'Cargando Config Tipo Documental......'
    };

    this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

        let configDocumento = data.Data;
        this.RemoveMetadataControls(null);
        this.CreateMetadataControls(data.Data[0].Configuracion);

        this.DynamicMetadata.Controls = data.Data[0].Configuracion ? this.Pagina.ConfigPage.Metadata : [];


      let obj = this.utility.GetApiModel('GetDetalleMetadata', null)
        obj.QueryConfig = {
          Command: 'BusquedaValorMetadataDocumentoExpediente',
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdDocumentoExpediente: this.modelo.documento.IdDocumentoExpediente
        }
        };

        let info = {
          componentName: this.componentName, controlName: 'GetMetadata',
          processMessage: 'Cargando Config Metadata'
        };

        this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

            this.modelo.modeloMetadata.Items._elements.forEach(_controls => {

              data.Data.forEach(dataControls => {
                if (dataControls.NombreControl == _controls.Name) {

                  // if (_controls.DataType == "String")
                  //   _controls.Value = dataControls.ValorTextoCuatrocientos

                  switch (_controls.DataType) {
                    case "Int32":
                    case "Int64":
                    case "Int16":
                      //ValorEntero
                      _controls.Value = dataControls.ValorEntero.toString();
                      break;
                    case "Decimal":
                    case "Number":
                      //ValorNumero

                      _controls.Value = dataControls.ValorNumero;
                      break;
                    case "Boolean":
                      //ValorBoolean
                      _controls.Value = dataControls.ValorBoolean;
                      break;
                    case "DateTime":
                      //ValorFecha
                      _controls.Value = dataControls.ValorFecha;
                      break;
                    case "String":

                      //ValorTextoCuatrocientos
                      _controls.Value = dataControls.ValorTextoCuatrocientos;
                      break;
                    case "SemiLargeString":
                      //ValorTextoMil
                      _controls.Value = dataControls.ValorTextoMil;
                      break;
                    case "LargeString":
                    case "Json":
                      //ValorObservaciones
                      _controls.Value += dataControls.ValorObservaciones;
                      break;
                  }
                }
              });
            })

            this.SetModelToControls();
            data.Data;
          })
          .catch((err) => {});

        this.SetModelToControls();

    }).catch(err => { });

  }

  public NavegarPaginaHija(url) {
    this.utility.ParentDetailsItem = this.expediente;
    this.utility.router.navigate([url]);
  }

  public ClearControlsMetadatos() {

    this.RemoveMetadataControls(null);
    if (this.DynamicMetadataExpediente)
      this.DynamicMetadataExpediente.Controls = [];

    if (this.DynamicMetadataFiltro) this.DynamicMetadataFiltro.Controls = [];

    this.Pagina.ConfigPage.Metadata = null;
  }
  public BuscarVersionTRD() {



    this.ClearControlsMetadatos();
    var obj = this.utility.GetApiModel('BuscarVersionTRD', this.componentName);

    obj.QueryConfig.Command = 'BusquedaVersionTRD';
    obj.QueryConfig.IsSp = true;


    obj.QueryConfig.Entity = {
       IdVersionTablaRetencionDocumental: this.modelo.modelo.IdVersionTablaRetencionDocumental,
       IdAreaEmpresa: null, //MySQL Parameter Null
       IdSerie: null, //MySQL Parameter Null
       IdSubSerie: null //MySQL Parameter Null
    };
   // obj.QueryConfig.Entity = { IdVersionTablaRetencionDocumental: this.modelo.modelo.IdVersionTablaRetencionDocumental }; // : this.modelo.modeloCascada;

    this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          method: 'BuscarVersionTRD',
          processMessage: 'Buscando tabla TRD...'
        }, "TExpediente")
      .then(data => {

        const configuracion = data.Data[0].Configuracion;
        this.CreateMetadataControls(configuracion);
        this.DynamicMetadataExpediente.Controls = this.Pagina.ConfigPage.Metadata;
        let obj = this.utility.GetApiModel('GetDetalleMetadata', null)
        obj.QueryConfig = {
          Command: 'BusquedaValorMetadataExpediente',
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdExpediente: this.expediente.IdExpediente
          }
        };

        let info = {
          componentName: this.componentName, controlName: 'GetDetalleTipoDocumental',
          processMessage: 'Cargando Config Metadata'
        };

        this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

            this.modelo.modeloMetadata.Items._elements.forEach(_controls => {

              data.Data.forEach(dataControls => {
                if (dataControls.NombreControl == _controls.Name) {
                  // if (_controls.DataType == "String")
                  //   _controls.Value = dataControls.ValorTextoCuatrocientos
                  switch (_controls.DataType) {
                    case "Int32":
                    case "Int64":
                    case "Int16":
                      //ValorEntero
                      _controls.Value = dataControls.ValorEntero;
                      break;
                    case "Decimal":
                    case "Number":
                      //ValorNumero

                      _controls.Value = dataControls.ValorNumero;
                      break;
                    case "Boolean":
                      //ValorBoolean
                      _controls.Value = dataControls.ValorBoolean;
                      break;
                    case "DateTime":
                      //ValorFecha
                      _controls.Value = dataControls.ValorFecha;
                      break;
                    case "String":

                      //ValorTextoCuatrocientos
                      _controls.Value = dataControls.ValorTextoCuatrocientos;
                      break;
                    case "SemiLargeString":
                      //ValorTextoMil
                      _controls.Value = dataControls.ValorTextoMil;
                      break;
                    case "LargeString":
                    case "Json":
                      //ValorObservaciones
                      _controls.Value += dataControls.ValorObservaciones;
                      break;
                  }
                }
              });
            });

            this.SetModelToControls();
            data.Data;

        }).catch(err => { });
        this.SetModelToControls();

      }).catch(err => {
        this.utility.logger.LogError(err, obj, {
            componentName: this.componentName,
            method: 'BuscarVersionTRD',
            processMessage: 'Buscando tabla TRD...'
          }, this.url)
      });
  }

  public OnActionEventHandler(event) {
    debugger
    if (event.actionType == "OnViewItemExtendableTable") {
      //debugger;
      var consulta = null;
      var parametro = null;
      let obj = this.utility.GetApiModel("GetDetalleSolicitud", null);
      if (this.modelo.modelo.TipoRadicado == "Radicado Pqrs") {
        consulta = "ConsultaDetallePQRSAsociada";
        //parametro = "IdSolicitudPqrdAsociada";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudPqrdAsociada: event.data.Id,
          },
        };
      }
      if (this.modelo.modelo.TipoRadicado == "Radicado Entrada") {
        consulta = "ConsultaDetalleEntradaAsociada";
        //parametro = "IdSolicitudRadicadoEntradaAsociado";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudRadicadoEntradaAsociado: event.data.Id,
          },
        };
      }
      if (this.modelo.modelo.TipoRadicado == "Radicado Factura") {
        consulta = "ConsultaDetalleFacturaAsociada";
        //parametro = "IdSolicitudRadicadoFacturadAsociado";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudRadicadoFacturaAsociado: event.data.Id,
          },
        };
      }
      //debugger;



      let info = {
        componentName: this.componentName,
        controlName: "GetDetalleSolicitud",
        processMessage: "Cargando Detalle de la Solicitud......",
      };

      this.baseService
        .Get(obj, null, info, this.componentName, false, false)
        .then((data) => {

          this.modeloDetalle = data.Data[0];
          if (
            this.modeloDetalle.Anexos &&
            this.modeloDetalle.Anexos != ""
          ) {
            const Anexos = JSON.parse(this.modeloDetalle.Anexos);
            let valorAnexos = "";
            Anexos.forEach((anexo) => {
              valorAnexos += anexo.Nombre + ": " + anexo.Cantidad + "\n";
            });
            this.modeloDetalle.Anexos = valorAnexos;
          }
          //debugger;
          if (this.modelo.modelo.TipoRadicado == "Radicado Pqrs") {
            if (this.componentsContainer.FormsDetails.length > 0) {

              let FormDetalleSolicitud = this.componentsContainer.Find(
                "FormDetalleSolicitud"
              );
              FormDetalleSolicitud.modelo = this.modeloDetalle;
            }

            let sidePanelEx = this.componentsContainer.Find("DetallePQRSAsociada");
            sidePanelEx.OpenSidePanel();
          }

          if (this.modelo.modelo.TipoRadicado == "Radicado Entrada") {
            if (this.componentsContainer.FormsDetails.length > 0) {

              let FormDetalleSolicitud = this.componentsContainer.Find(
                "FormDetalleSolicitudEntrada"
              );
              FormDetalleSolicitud.modelo = this.modeloDetalle;
            }

            let sidePanelEx = this.componentsContainer.Find("DetalleEntradaAsociada");
            sidePanelEx.OpenSidePanel();
          }

          if (this.modelo.modelo.TipoRadicado == "Radicado Factura") {
            if (this.componentsContainer.FormsDetails.length > 0) {

              let FormDetalleSolicitud = this.componentsContainer.Find(
                "FormDetalleSolicitudFactura"
              );
              FormDetalleSolicitud.modelo = this.modeloDetalle;
            }

            let sidePanelEx = this.componentsContainer.Find("DetalleFacturaAsociada");
            sidePanelEx.OpenSidePanel();
          }

        })
        .catch((err) => { });

    }
    if (event.Action === FormActionsThroughEmitters.ClearModel) {
      this.modelo.modeloVirtual.Resumen = null
      if (event.Table == "DataTables_TDocumentoExpediente" || event.Table == "GridViewRadicados") {

        if (event.Table == "DataTables_TDocumentoExpediente") {
          this.modelo.documento = null;
        }

        //super.OnActionEventHandler(event);

        if (event.Table == "GridViewRadicados") {
          this.modelo.modeloVirtual = null;
        }

        this.SetModelToControls();

        return;
      }
      /**
       * INICIO
       * 15022021 - 1631
       * Se comenta la linea de arriba (834), ya que limpiaba el this.modelo.modelo
       * Se reubica en la linea de abajo (843)
       */
      super.OnActionEventHandler(event);
    }
else if (event.Action === FormActionsThroughEmitters.ResponseEmmit)
    {
      this.modelo.modeloVirtual.Resumen = event.Response.result.summary
      this.SetModelToControls();
    }

    switch (event.ControlType) {
      case 'Button':
        let modeloTemp: any;
        switch (event.Name) {

          case 'BtnDocumento':
            {
            //
            modeloTemp = {
              permissionsConfig: this.configPermisosDocumento,
              typeUseForm: TypesUseForm.ModalCreate,
              mappingFields: {
                // IdExpediente: this.expediente.modelo.IdExpediente
                IdExpediente: this.expediente.IdExpediente
              },
              controlsForHidden: [
                {
                  "Name": "IdExpediente",
                  "ControlType": "ComboBox"
                },
                {
                  "Name": "DataTables_TDocumentoExpediente",
                  "ControlType": "GridView"
                  }
              ],
              pageId: 'DocumentoExpediente'

            };
            this.utility.VerEdicionFormulario(modeloTemp, 'TDocumentoExpedienteComponent', true);
            break;
          }
          case 'BtnUbicacion':
            {
            //
            modeloTemp = {
              configPermisos: this.configPermisosUbicacion,
              typeUseForm: TypesUseForm.ModalCreate,
              mappingFields: {
                // IdExpediente: this.expediente.modelo.IdExpediente
                IdExpediente: this.expediente.IdExpediente
              },
              controlsForHidden: [
                {
                  "Name": "IdExpediente",
                  "ControlType": "ComboBox"
                },
                {
                  "Name": "DataTables_TUbicacionFisicaExpediente",
                  "ControlType": "GridView"
                  }
              ],
              pageId: 'UbicacionFisicaExpediente'

            };
            this.utility.VerEdicionFormulario(modeloTemp, 'GeneralFormComponent', true);
            break;
          }
          //Inicio Implementacion case de filtro de auditoria 26-11-2020 Edwin Alonso
          case 'btnBuscar': {
            this.FiltroAuditoria();
            break;
          }
          case 'BtnVertodo': {

            this.modelo.modelo.FechaCreacionInicial = null;
            this.modelo.modelo.FechaCreacionFinal = null;
            this.FiltroAuditoria();
            break;
          }
          case 'BtnAuditoria': {

            this.GridViewAuditoria = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Filtro"; });
            this.GridViewAuditoria.QueryConfig.ExportSettings = new ExportImportConfig();

            var date = new Date();
            let day = ("0" + date.getDate()).slice(-2);
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let year = date.getFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();

            this.GridViewAuditoria.QueryConfig.ExportSettings.FileName = 'BusquedaLTAuditoriaFuncional_' + day + "_" + month + "_" + year + "-" + hours + "-" + minutes + "-" + seconds;
            var ItemConfig = new ExportImportItemConfig();
            ItemConfig.ResultType = event.config.Actions[0].ResultType;
            ItemConfig.DestinationType = 'None';
            ItemConfig.ToType = event.config.Actions[0].ExportType;

            this.GridViewAuditoria.QueryConfig.ExportSettings.ItemsConfig.push(ItemConfig);

            this.GuardarAuditoriaExpedinte('BtnAuditoria');

            super.OnActionEventHandler(event);
            break;
          }
          case 'btnLimpiarTraslado': {

            this.modelo.modeloVirtual.IdSedeEmpresa = null;
            this.modelo.modeloVirtual.Juzgado = null;
            this.CmbxIdSedeEmpresa.Hidden = true;
            this.modelo.modeloVirtual.EsOtraSeccional = false;
            this.SetModelToControls();
            break;
          }
          case 'btnGuardarTraslado': {

            var validform = true;
            let obj = this.utility.GetApiModel('GetCuadernosAbiertosExpediente', null)
            obj.QueryConfig.Command = 'GetCuadernosAbiertosExpediente';
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.IsMulti = false;
            obj.QueryConfig.Entity = {
              IdExpediente: this.modelo.modelo.IdExpediente,
            };

            let info = {
              componentName: this.componentName, controlName: 'GetCuadernosAbiertosExpediente',
              processMessage: 'Validando cuadernos expediente......'
            };
            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
              //Alex Caro - 2023-01-13 - Validar Cerrar cuadernos
              if(data.Data.length > 0)
              {
                let obj2 = this.utility.GetApiModel('GetDocumentosAbiertosPorCuaderno', null)
                obj2.QueryConfig.Command = 'GetDocumentosAbiertosPorCuaderno';
                obj2.QueryConfig.IsSp = true;
                obj2.QueryConfig.IsMulti = false;
                obj2.QueryConfig.Entity = {
                  IdExpediente: this.modelo.modelo.IdExpediente,
                };

                let info2 = {
                  componentName: this.componentName, controlName: 'GetDocumentosAbiertosPorCuaderno',
                  processMessage: 'Validando documentos por cuadernos abiertos......'
                };
                this.baseService.Get(obj2, null, info2, this.componentName, true, false).then(data => {
                  let containDocs = Boolean(false);
                  let cuadernosCon = "";
                  let cuadernosSin = "";
                  let message = "";
                  let cuadernosCerrar = [];
                  data.Data.forEach((value, index)=>{
                    if(value.CantidadDocs > 0)
                    {
                      containDocs = true;
                      cuadernosCon += value.Nombre +', ';
                    }
                    else if(value.CantidadDocs <= 0){
                      containDocs = false;
                      cuadernosSin += value.Nombre +', ';
                      cuadernosCerrar.push(value.IdCuaderno);
                    }
                  });

                  if(containDocs == true){
                    validform = false;
                    message = "Los cuadernos: "+ cuadernosCon +" se encuentran abiertos y contienen documentos, por favor ciérrelos para continuar";
                    this.utility.VerModalWarning({
                      titulo: 'Alerta', descripcion: message,
                      verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                    });
                  }else {

                    message = "Los cuadernos: "+ cuadernosSin +" se van a cerrar automáticamente";
                    this.utility.VerModalWarning({
                      titulo: 'Alerta', descripcion: message,
                      verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                    }).then(data => {
                        //Se cierran los cuadernos
                      cuadernosCerrar.forEach((value, key)=>{
                        let obj = this.utility.GetApiModel('CerrarCuaderno', null)
                        obj.QueryConfig.Command = 'CerrarCuaderno';
                        obj.QueryConfig.IsSp = true;
                        obj.QueryConfig.IsMulti = false;
                        obj.QueryConfig.Entity = {
                          IdCuaderno: value
                        };

                        let info = {
                          componentName: this.componentName, controlName: 'CerrarCuadernos',
                          processMessage: 'Cerrando cuadernos expediente......'
                        };
                        this.baseService.Get(obj, null, info, this.componentName, true, false).then(data => {}).catch(err => { });
                      });
                      validform = true;
                      this.trasladar(validform);
                    });
                  }

                }).catch(err => { });

              }else {
                this.trasladar(validform);
              }
              //Alex Caro - 2023-01-13 -  Fin Validar Cerrar cuadernos

            }).catch(err => { });
            break;
          }
          case 'btnGuardarPermiso': {

            if (this.modelo.modelo.DataAdd) {
              this.modelo.modelo.DataAdd["FechaInicialPermiso"] = this.modelo.modeloVirtual.FechaInicialPermiso;
              this.modelo.modelo.DataAdd["FechaFinalPermiso"] = this.modelo.modeloVirtual.FechaFinalPermiso;
              this.modelo.modelo.DataAdd["CorreoPermiso"] = this.modelo.modeloVirtual.CorreoPermiso;
              this.modelo.modelo.DataAdd["GuidAccesoPermiso"] = Guid.create().toString();
            } else {
              this.modelo.modelo.DataAdd = {
                FechaInicialPermiso: this.modelo.modeloVirtual.FechaInicialPermiso,
                FechaFinalPermiso: this.modelo.modeloVirtual.FechaFinalPermiso,
                CorreoPermiso: this.modelo.modeloVirtual.CorreoPermiso,
                GuidAccesoPermiso: Guid.create().toString()
              };
            }

            this.compartir(true);

            break;
          }
          //fin implementacion case de filtro
          //Lina Figueredo 13/12/2023
          //solucion bug 00806 - AUDITORIA DOCUMENTO
          //Se agrega accion del boton ver todo auditoria documento
          case 'BtnVertodoDocumento': {

            // setTimeout(() => {this.componentsContainer.ReloadTable("DataTables_Auditoria_Documento_Filtro", { IdEmpresa: -1, IdDocumentoExpediente: null}, null, false);  }, 500);
            // this.SetModelToControls();
            this.modelo.modelo.FechaCreacionInicialDocumento = null;
            this.modelo.modelo.FechaCreacionFinalDocumento = null;
            this.FiltroAuditoriaDocumentos();
            break;
          }
            /**
           * Inicio - Edward Morales - 06/02/2023_1415
           * Opciones para Auditoría Documento
           */
          case 'btnBuscarDocumento' : {
            this.FiltroAuditoriaDocumentos();
            break;
          }
          case "BtnAuditoriaDocumento": {
            this.GridViewAuditoriaDocumento =
              this.componentsContainer.Tables.find((x) => {
                return x.name == "DataTables_Auditoria_Documento_Filtro";
              });
            this.GridViewAuditoriaDocumento.QueryConfig.ExportSettings =
              new ExportImportConfig();

            var date = new Date();
            let day = ("0" + date.getDate()).slice(-2);
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let year = date.getFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();

            this.GridViewAuditoriaDocumento.QueryConfig.ExportSettings.FileName =
              "BusquedaLTAuditoriaFuncional_Documento_" +
              day +
              "_" +
              month +
              "_" +
              year +
              "-" +
              hours +
              "-" +
              minutes +
              "-" +
              seconds;
            var ItemConfig = new ExportImportItemConfig();
            ItemConfig.ResultType = event.config.Actions[0].ResultType;
            ItemConfig.DestinationType = "None";
            ItemConfig.ToType = event.config.Actions[0].ExportType;

            this.GridViewAuditoriaDocumento.QueryConfig.ExportSettings.ItemsConfig.push(
              ItemConfig
            );

            //this.GuardarAuditoriaExpedinte('BtnAuditoriaDocumento');

            super.OnActionEventHandler(event);
            break;
          }
          case "BtnBuscarDocumentoFiltro": {
            //debugger;
            this.BuscarDocumento(event.Name);
            break;
          }
          case "BtnLimpiarDocumentoFiltro": {
            //debugger;
            this.controls.ForEach((control) => {
              if (
                control.control &&
                control.control.ModelContainer == "modeloVirtual"
              )
                this.modelo.modeloVirtual[control.control.Name] = null;
            });
            this.SetModelToControls();
            this.ClearControlsMetadatos();
            this.NgAfterViewInitEnd();
            this.BuscarDocumento(event.Name);

            break;
          }
          default:
          {
            this.helperActions.ExecuteActionsControl((event.Option) ? event.Option : event.config,
                false, null, this.modelo, this.permissionsConfig,
                this, null, new EventEmitter<any>(), this.componentsContainer,
                event.Option, this.modelo.modeloMetadata, { importUrl: this.importUrl });
            break;
          }
        }

        break;
      case 'CommandButton':

        let Configuracion = new Array();

        //Lina Figueredo 13/12/2023
        //solucion bug 00806 - AUDITORIA DOCUMENTO
        //Se cargan los parametros para la grilla ver auditoria documento
        if (event.Action == "Click" && event.Option.Name == "AuditoriaDocumento"){
          this.componentsContainer.ReloadTable("DataTables_Auditoria_Documento_Filtro", { IdEmpresa: -1, IdExpediente:  this.modelo.modelo.IdExpediente, IdDocumentoExpediente: this.modelo.documento.IdDocumentoExpediente}, null, false);
        }

        if (event.Option.Label == "Radicados") {
          this.GridViewRadicados.Hidden = false;
          this.modelo.modeloVirtual.IdExpedientePrevio = this.modelo.modelo.IdExpediente;
        }


        if (event.Option.Type == "ViewAttachment") {
          this.VerAdjuntos();
          this.AuditoriaProcesos(); // Auditoría Adjuntos
          this.FireFormEvent({
            Name: FormActionsThroughEmitters.OnViewAttachment,
            modelo: this.modelo,
          });
          return;
        }
        if (event.Option.Type == "Return") {
          this.NavegarPaginaHija('BusquedaExpediente');
          return;
        }
        /// Inicio de modificacion Marlon Granda 29_11_2020
        /// adicion de botones de navegacion a las paginas de documento y ubicacion
        if (event.Option.Name == "CargarDocumento") {
          this.NavegarPaginaHija('DocumentoExpediente');
          return;
        }
        else if (event.Option.Name == "UbicacionFisicaExpediente") {
          this.NavegarPaginaHija('UbicacionFisicaExpediente');
          return;
        }
        /// Fin de modificacion Marlon Granda 29_11_2020

        if(event.Option.Name == "ConsultaExterna")
        {
          debugger;
          let sidePanelCompartir = this.componentsContainer.Find("CompartirExterno");
          const GuidProcesoCreacionPermiso = sidePanelCompartir._subControl.Controls.find(x => x.Name === 'ProcesoCreacionPermiso');
          const GuidNotificacionCreacionPermiso = sidePanelCompartir._subControl.Controls.find(x => x.Name === 'NotificacionCreacionPermiso');
          if (this.modelo.modelo.DataAdd) {
            this.modelo.modelo.DataAdd["ProcesoCreacionPermiso"] = GuidProcesoCreacionPermiso.Value;
            this.modelo.modelo.DataAdd["NotificacionCreacionPermiso"] = GuidNotificacionCreacionPermiso.Value;
          } else {
            this.modelo.modelo.DataAdd = {
              ProcesoCreacionPermiso: GuidProcesoCreacionPermiso.Value,
              NotificacionCreacionPermiso: GuidNotificacionCreacionPermiso.Value
            };
          }

          if(this.modelo.documento)
          {
            //if(this.modelo.documento.VerPublico != 0)
            //{
              if (this.modelo.modelo.DataAdd) {
                this.modelo.modelo.DataAdd["TipoRegistro"] = "Documento";
                this.modelo.modelo.DataAdd["IdentificadoresRegistro"] = this.modelo.documento.IdDocumentoExpediente;
                this.modelo.modelo.DataAdd["Entidad"] = "TDocumentoExpediente";
                this.modelo.modelo.DataAdd["NombrePkEntidad"] = "IdDocumentoExpediente";
              } else {
                this.modelo.modelo.DataAdd = {
                  TipoRegistro: "Documento",
                  IdentificadoresRegistro: this.modelo.modelo.IdDocumentoExpediente,
                  Entidad: "TDocumentoExpediente",
                  NombrePkEntidad: "IdDocumentoExpediente"
                };
              }
              sidePanelCompartir.OpenSidePanel();
            //}
            //else
            //{
              //this.utility.VerModalWarning({
                  //titulo: 'Alerta', descripcion: 'El documento no se puede compartir externamente dado que se encuentra marcado como “No público”, por tanto, debe cambiar esa propiedad sobre este documento.',
                  //verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'Cancelar'
                //}).then(data => {});
            //}

          }
          else if(this.modelo.modeloVirtual.IdSCuadernos)
          {

            var validform = true;
            let obj = this.utility.GetApiModel('GetClasificacionDocumentosCuaderno', null)
            obj.QueryConfig.Command = 'GetClasificacionDocumentosCuaderno';
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.IsMulti = false;
            obj.QueryConfig.Entity = {
              IdSCuadernos: this.modelo.modeloVirtual.IdSCuadernos,
            };

            let info = {
              componentName: this.componentName, controlName: 'GetClasificacionDocumentosCuaderno',
              processMessage: 'Validando documentos a compartir......'
            };
            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

                if(data.Data.length > 0)
              {
                  var docsPublicos = 0;
                  var docsPrivados = 0;
                  data.Data.forEach(docs => {
                    if (docs.VerPublico == 1) {
                      docsPublicos = docs.NroRegistros;
                    }
                  else
                  {
                      docsPrivados = docs.NroRegistros;
                    }
                  });

                  if(docsPublicos > 0 && docsPrivados > 0)
                {
                    this.utility.VerModalWarning({
                        titulo: 'Alerta', descripcion: 'El documento no se puede compartir externamente dado que se encuentra marcado como “No público”, por tanto, debe cambiar esa propiedad sobre este documento, Al Aceptar continuará con el proceso de compartir sin tener en cuenta dichos archivos.',
                        verOk: true, verCancelar: true, ok: 'Aceptar', cancelar: 'Cancelar'
                      }).then(data => {
                        if (this.modelo.modelo.DataAdd) {
                          this.modelo.modelo.DataAdd["TipoRegistro"] = "Cuaderno";
                          this.modelo.modelo.DataAdd["IdentificadoresRegistro"] = this.modelo.modeloVirtual.IdSCuadernos;
                          this.modelo.modelo.DataAdd["Entidad"] = "RCuaderno";
                          this.modelo.modelo.DataAdd["NombrePkEntidad"] = "IdCuaderno";
                        } else {
                          this.modelo.modelo.DataAdd = {
                            TipoRegistro: "Cuaderno",
                            IdentificadoresRegistro: this.modelo.modeloVirtual.IdSCuadernos,
                            Entidad: "RCuaderno",
                            NombrePkEntidad: "IdCuaderno"
                          };
                        }
                        sidePanelCompartir.OpenSidePanel();
                      });
                  }
                else if(docsPublicos <= 0 && docsPrivados > 0)
                {
                    this.utility.VerModalWarning({
                        titulo: 'Alerta', descripcion: 'El documento no se puede compartir externamente dado que se encuentra marcado como “No público”, por tanto, debe cambiar esa propiedad sobre este documento.',
                        verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'Cancelar'
                      }).then(data => {});
                  }
                else if(docsPublicos > 0 && docsPrivados <= 0)
                {
                    if (this.modelo.modelo.DataAdd) {
                      this.modelo.modelo.DataAdd["TipoRegistro"] = "Cuaderno";
                      this.modelo.modelo.DataAdd["IdentificadoresRegistro"] = this.modelo.modeloVirtual.IdSCuadernos;
                      this.modelo.modelo.DataAdd["Entidad"] = "RCuaderno";
                      this.modelo.modelo.DataAdd["NombrePkEntidad"] = "IdCuaderno";
                    } else {
                      this.modelo.modelo.DataAdd = {
                        TipoRegistro: "Cuaderno",
                        IdentificadoresRegistro: this.modelo.modeloVirtual.IdSCuadernos,
                        Entidad: "RCuaderno",
                        NombrePkEntidad: "IdCuaderno"
                      };
                    }
                    sidePanelCompartir.OpenSidePanel();
                  }
                }

            }).catch(err => { });



          }




        }

        /// Fin de modificacion Marlon Granda 29_11_2020

        /// Inicio de modificacion Marlon Granda 29_11_2020 Por error de conparacion
        //if (event.Option.Label == "Mover Radicado") {
        if (event.Option.Name == "MoverRadicado") {
          if (this.modelo.modelo.IdEstado == 1) {
            let sidePanelEx = this.componentsContainer.Find("SidePanelExpediente");
            sidePanelEx.OpenSidePanel();

            const action = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["IdTipoDocumental"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: "true",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };
            const context = this;
            this.helperActions.SetControlsValue(action, context);
            super.SetModelToControls();
          } else {
            this.utility.VerModalWarning({
              titulo: "Alerta",
              descripcion:
                "No se permite mover el radicado, el expediente se encuentra cerrado",
              verOk: true,
              verCancelar: false,
              ok: "Aceptar",
              cancelar: "NO",
            });
          }
        }
        // else if (event.Option.Name == "DetalleDocumento") {
        //   this.GetMetadatoDetaleDocumento();
        //   super.OnActionEventHandler(event);
        // }
        else if (event.Option.Name == "DetalleDocumento") {

          if (this.modelo.documento.PalabrasNormalizadas) {
            var Palabras = JSON.parse(this.modelo.documento.PalabrasNormalizadas);
            var PalabrasProcesadas = "";
            Palabras.forEach((Palabra) => {
              Object.entries(Palabra).forEach(([key, value]) => {
                if (key == "Palabra") {
                  PalabrasProcesadas += value + ", ";
                }
              });
            });
            this.modelo.documento.PalabrasProcesadas = PalabrasProcesadas.substring(0, PalabrasProcesadas.length - 2);;
          }

          let FormDetalle = this.componentsContainer.Find("FormDetalleDocumeto");
          FormDetalle.modelo = this.modelo.documento;

          let sidePanel = this.componentsContainer.Find("SidePanelDocumentoExpediente");
          sidePanel.OpenSidePanel();
          this.GetMetadatoDetaleDocumento();
          this.AuditoriaProcesos(); //Auditoriía Detalle Documento
          this.FireFormEvent({
            Name: FormActionsThroughEmitters.DetailDocument,
            modelo: this.modelo,
          });
        }
        else if (event.Option.Type == "ExpedienteDetalle") {
          this.BuscarVersionTRD();
          super.OnActionEventHandler(event);
        }
        else if (event.Option.Type == "Download" || event.Option.Type == "ViewDocument") {

          this.componentName = "TDocumentoExpediente"
          this.AuditoriaProcesos();//Ejecuta Auditoria
          this.helperActions.ExecuteActionsControl((event.Option) ? event.Option : event.config,
            false, this.modelo.documento, this.modelo, this.permissionsConfig,
            this, null, new EventEmitter<any>(), this.componentsContainer,
            event.Option, this.modelo.modeloMetadata, { importUrl: this.importUrl });

        }
        else if (event.Option.Name == "Indice" || event.Option.Name == "Zip") {

          this.componentName = "TExpediente";
          this.GuardarAuditoriaExpedinte(event.Option.Name);
          super.OnActionEventHandler(event);

        }
        else if (event.Option.Type == "Print") {

          var isSearch = 0;
          var valueSearchGrid = document.getElementsByClassName("ms-SearchBox-field")[1];

          if (valueSearchGrid)
            isSearch = ($(valueSearchGrid).prop("value").trim().length > 0) ? 1 : 0

          var usuario = StaticData.Usuario.IdUsuario;

          // 20240125 - Se debe revisar la siguiente linea por que esta URL no debe estar quemada
          var url = 'http://AdminSQL:d%Q5kh$n@Ug1@reportservice-ramajudicial.eastus.cloudapp.azure.com/Reports/report/Reportes%20BestDocs/Expedientes/RPT_PrintDetalleExpediente?IdUsuario=' + usuario + '&SearchValue=' + isSearch + '&rs:embed=true';

          window.open(url, "_blank");

          return;
        }

        // else if (event.Option.Type == "ExpedienteDetalle") {
        //
        //   this.edicionExpediente.InternalSetModelo(this.modelo);
        //   super.OnActionEventHandler(event);

        // }
        /// Inicio de modificacion Marlon Granda 29_11_2020 Por error de comparacion
        //else if (event.Option.Type == "CierreExpediente") {
        else if (event.Option.Name == "CierreExpediente") {
          let modeloTemp: any;
          //debugger
          if (this.totalExpedientes && this.totalExpedientes.length == 0) {
            this.utility.VerModalWarning({
                titulo: 'Alerta', descripcion: 'El expediente no puede ser cerrado por no tener documentos cargados',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          else if (this.modelo.modelo.IdUsuarioResponsable != StaticData.Usuario.IdUsuario) {
            this.utility.VerModalWarning({
                titulo: 'Alerta', descripcion: 'El expediente no puede ser cerrado por el usuario',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          // else if (this.modelo.modelo.IdEstado == 2 || this.DobleAuthData.estadoExpediente == 2) {
          else if (this.modelo.modelo.IdEstado == 2 /*&& this.modelo.modelo.EstadoText != "Abierto"*/) {
            this.utility.VerModalWarning({
                titulo: 'Alerta', descripcion: 'El Expediente ya fue cerrado',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          else {
            let tittle: string = "Confirmación";
            let message: string = "¿Desea cerrar y firmar el expediente?";
            this.ValidarEstadoRadicadosCierre();


            var tdoc = '';
            this.validarExisteTipoDocumentalRequerido(this.totalExpedientes);
            this.listaTipoDocumentalRequeridos.forEach(a => { tdoc += `\n ${a.Nombre} \n` + ','; });

            if (tdoc.length > 0) {
              tittle = "Advertencia";
              message = `Los Siguientes Tipos Documentales aún no poseen Documentos Asignados:\n${tdoc}\n¿Desea Cerrar y Firmar el Expediente?`;
              this.utility.VerModalConfirmacionGeneral(tittle, message).then(modelo => {

                  if (modelo.dialogResult == true) {

                    // Implementacion

                    if (this.MensajeRadicados) {
                      tittle = "Advertencia";
                      message = this.MensajeRadicados;

                      this.utility.VerModalConfirmacionGeneral(tittle, message).then(modelo => {

                          if (modelo.dialogResult == true) {
                            this.EfectuarProcesoCierre(modeloTemp);
                          }
                        });
                    }
                  else {
                      this.EfectuarProcesoCierre(modeloTemp);
                    }
                  }
                else {
                    return
                  }
                });
            }
            else {
              if (this.MensajeRadicados) {
                tittle = "Advertencia";
                message = this.MensajeRadicados;

                this.utility.VerModalConfirmacionGeneral(tittle, message).then(modelo => {

                    if (modelo.dialogResult == true) {
                      this.utility.VerModalConfirmacionGeneral("Cerrar Expediente", "El Expediente será cerrado, ¿Desea continuar?").then(modelo => {

                          if (modelo.dialogResult == true) {
                            // modeloTemp = {
                            //   typeUseForm: TypesUseForm.ModalCreate,
                            //   pageId: 'DobleAuth',
                            //   IdExpediente: this.modelo.modelo.IdExpediente,
                            //   CodigoExpediente: this.modelo.modelo.Codigo,
                            // };
                            // //Respuesta de DobleAuth
                            // let onInitializedDobleAuth = new EventEmitter<any>();
                            // onInitializedDobleAuth.subscribe(item => {
                            //   debugger
                            //   this.dobleAuth = item;
                            //   this.dobleAuth.onCloseExpediente.subscribe(item => {
                            //     this.modelo.modelo.IdEstado = item.estadoExpediente;
                            //     this.componentsContainer.Tables[0].ReCargarGrilla();
                            //   });
                            // });
                            // this.utility.VerEdicionFormulario(modeloTemp, 'DobleAuthComponent', true, undefined, undefined, undefined, undefined, onInitializedDobleAuth).then(resp => {
                            // })
                            // this.componentsContainer.Tables[0].ReCargarGrilla();
                            this.FirmaDigitalCierreExpediente();
                          }
                      else {
                            return
                          }
                        });
                    }
                  });
              }
              else {
                this.utility.VerModalConfirmacionGeneral("Cerrar Expediente", "El Expediente será cerrado, ¿Desea continuar?").then(modelo => {

                    if (modelo.dialogResult == true) {
                      // modeloTemp = {
                      //   typeUseForm: TypesUseForm.ModalCreate,
                      //   pageId: 'DobleAuth',
                      //   IdExpediente: this.modelo.modelo.IdExpediente,
                      //   CodigoExpediente: this.modelo.modelo.Codigo,
                      // };
                      // //Respuesta de DobleAuth
                      // let onInitializedDobleAuth = new EventEmitter<any>();
                      // onInitializedDobleAuth.subscribe(item => {
                      //   debugger
                      //   this.dobleAuth = item;
                      //   this.dobleAuth.onCloseExpediente.subscribe(item => {
                      //     this.modelo.modelo.IdEstado = item.estadoExpediente;
                      //     this.componentsContainer.Tables[0].ReCargarGrilla();
                      //   });
                      // });
                      // this.utility.VerEdicionFormulario(modeloTemp, 'DobleAuthComponent', true, undefined, undefined, undefined, undefined, onInitializedDobleAuth).then(result => {
                      //   if (this.dobleAuth.IdEstado != 0 || this.dobleAuth.IdEstado != undefined) {
                      //     this.modelo.modelo.IdEstado = this.dobleAuth.IdEstado;
                      //   }

                      //   this.componentsContainer.Tables[0].ReCargarGrilla();
                      //   //this.modelo.modelo.IdEstado = this.DobleAuthData.IdEstado;
                      //   this.dobleAuth.onCloseExpediente.subscribe(item => {
                      //     this.componentsContainer.Tables[0].ReCargarGrilla();

                      //   })
                      // })
                      // // this.utility.VerEdicionFormulario(modeloTemp, 'DobleAuthComponent', true, null, null, null, null, onInitializedDobleAuth).then(resp => {
                      // // })
                      // this.componentsContainer.Tables[0].ReCargarGrilla();
                      this.FirmaDigitalCierreExpediente();
                    }
                  else {
                      return
                    }
                  });
              }

            }
          }

        }
        /// Inicio de modificacion Marlon Granda 29_11_2020 Por error de comparacion
        // else if (event.Option.Type == "AperturaExpediente") {
        else if (event.Option.Name == "AperturaExpediente") {
          if (this.modelo.modelo.IdUsuarioResponsable != StaticData.Usuario.IdUsuario) {
            this.utility.VerModalWarning({
                titulo: 'Alerta', descripcion: 'El expediente no puede ser reabierto por el usuario',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          //else if (this.modelo.modelo.IdEstado == 1 || this.DobleAuthData.estadoExpediente == 2) {
          else if (this.modelo.modelo.IdEstado == 1) {
            this.utility.VerModalWarning({
                titulo: 'Alerta', descripcion: 'El Expediente se encuentra abierto',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          //Subestado 6 significa eliminado, SUBESTADO ELIMINADO
          else if (this.modelo.modelo.IdSubEstado == 6) {
            this.utility.VerModalWarning({
                titulo: 'Alerta', descripcion: 'El Expediente fue eliminado y no puede ser reabierto',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          else {
            this.utility.VerModalConfirmacionGeneral("Reabrir Expediente", "El Expediente será reabierto, ¿Desea continuar?").then(modelo => {

                if (modelo.dialogResult == true) {

                  /**
                   * INICIO
                   * Edward Morales 20-04-2021 1105
                   * Modal Observación para Reapertura: si se requiere volver al
                   * proceso anterior descomentar el bloque siguiente a la función
                   * y a su vez comentar la misma-->
                   * EfectuarProcesoApertura(modeloTemp);
                   */
                  let modeloTemp: any;
                  this.EfectuarProcesoApertura(modeloTemp);
                  /*
                let obj = this.utility.GetApiModel('CloseExpediente', null)
                obj = {
                  IdExpediente: this.modelo.modelo.IdExpediente,
                  DataAdd: {
                    TipoProceso: 'Reapertura',
                    CodigoExpediente: this.modelo.modelo.CodigoExpediente,
                    TipoBodega: 'Gestión'
                  }
                };

                let info = {
                  componentName: this.componentName, controlName: 'ReaperturaExpediente',
                  processMessage: 'Realizando la reapertura del expediente'
                };
                this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/CloseExpediente', false, false).then(data => {

                  if (data.Data.IdEstado != '1')

                    this.utility.VerModalError("El expediente no pudo ser reabierto");

                  else {
                    this.modelo.modelo.IdEstado = data.Data.IdEstado;
                    //this.modelo.modelo.IdEstado = this.DobleAuthData.estadoExpediente;
                    this.utility.VerModalOk("Expediente reabierto correctamente");
                  }
                }).catch(err => {
                  this.utility.VerModalError(err);

                });
                */
                }
              else {
                  return
                }
              });

          }
        }
        /// Inicio de modificacion Marlon Granda 29_11_2020 Por error de comparacion
        // else if (event.Option.Type == "CambioBodega") {
        //else if (event.Option.Name == "CambioBodega") {
        /**
         * INICIO
         * 10/12/2020 10:09
         * Edward
         *
         * Cambio de name para actualizar fase de archivo
         */
        else if (event.Option.Name == "FaseArchivo") {
          /**
           * FIN
           */
          if (this.modelo.modelo.IdUsuarioResponsable != StaticData.Usuario.IdUsuario) {
            this.utility.VerModalWarning({
                titulo: 'Alerta', descripcion: 'El expediente no puede ser cambiado de bodega por el usuario',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          //else if (this.modelo.modelo.IdEstado == 1 || this.DobleAuthData.estadoExpediente == 2) {
          else if (this.expediente.TipoBodega == "Gestión") {
            this.utility.VerModalInfo({
                titulo: 'Mensaje Informativo', descripcion: 'El expediente ya se encuentra en la bodega Gestión',
                verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
              }).then(data => { });
          }
          else if (this.expediente.TipoBodega == "Central") {
            /*Soluciòn a bug 0000561 en "Expediente en "Fase de Archivo", esta debe decir "Bodega Central y no Bodega de Gestión ya que esta en Transferencias Aprobadas y esta cerrado**/
            this.utility.VerModalInfo({
              titulo: 'Mensaje Informativo', descripcion: 'El expediente ya se encuentra en la bodega Central',
              verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
            }).then(data => { });
          }
          else {
            this.utility.VerModalConfirmacionGeneral("Fase de archivo", "Se asignará la bodega Central, ¿Desea continuar?").then(modelo => {

                if (modelo.dialogResult == true) {
                  let obj = this.utility.GetApiModel('FaseArchivo', null)
                  obj = {
                    IdExpediente: this.expediente.IdExpediente
                  };

                  let info = {
                    componentName: this.componentName, controlName: 'FaseArchivo',
                    processMessage: 'Realizando el cambio de bodega del expediente'
                  };
                  this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/FaseArchivo', false, false).then(data => {

                      if (data.Data.TipoBodega != 'Gestión')

                        this.utility.VerModalError("El expediente no pudo ser cambiado de Bodega");

                      else {
                        this.expediente.TipoBodega = data.Data.TipoBodega;
                        this.utility.VerModalOk("El Expediente fue cambiado de bodega correctamente");
                      }
                    }).catch(err => {
                      this.utility.VerModalError(err);

                });
                }
              else {
                  return
              }
            });

                }
              }

        else if (event.Option.Name == "Trasladar") {
          this.configs.ConfigJuzgado.QueryConfig.Filter = "IdSedeEmpresa = (SELECT IdSedeEmpresa FROM RAreaEmpresa WHERE IdAreaEmpresa =" + this.modelo.modelo.IdAreaEmpresa +")";
          this.configs.ConfigJuzgado.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          this.modelo.modeloVirtual.IdSedeEmpresa = null;
          this.modelo.modeloVirtual.Juzgado = null;
          this.CmbxIdSedeEmpresa.Hidden = true;
          this.modelo.modeloVirtual.EsOtraSeccional = false;
          this.SetModelToControls();

          const context = this;
            const action = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: []
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["IdSedeEmpresa"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty]
            };
            this.helperActions.SetControlsValue(action, context);

          setTimeout(() => {
             super.OnActionEventHandler(event);
           },3000);

        }

        else if (event.Option.Name == "Expediente") {
          if (!this.vistaArbol) {
            this.componentsContainer.Tables[0].GetItems().then(data => {
              this.totalExpedientes = data.toArray();
            });
          }
          else
          {
            ///Implementacion de consumo de SP para determinar si existen Documentos
            let spName = "BusquedaDocumentosExpedienteCrud"
            let obj = this.utility.GetApiModel(spName, null)
            obj.QueryConfig.Command = spName;
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = {
              IdEmpresa: -1,
              IdExpediente: this.modelo.modelo.IdExpediente,
              PageSize: null,  //MySQL Parameter null
              Skip: null,  //MySQL Parameter null
              SearchValue: null, //MySQL Parameter null
              SortOrderColumn: null, //MySQL Parameter null
              SortColumnDir: null, //MySQL Parameter null
              OutPutQuery: null, //MySQL Parameter null
              IsCount: null, //MySQL Parameter null
              Filtro: null //MySQL Parameter null
            };
            let info = {
              componentName: "TExpediente", controlName: spName,
              processMessage: 'Consulta Documentos Expediente......'
            };
            this.baseService.Get(obj, null, info, "TExpediente", false, false).then(data => {
                //debugger
                this.totalExpedientes = data.Data;
              }).catch(err => { }).finally();
          }
        }
        else if (event.Option.Name == "CloseNotebook" || event.Option.Name == "OpenNotebook") {
          let msgProceso: any;
          let TipoEstado_: any;
          let msgSalida: any;
          if(event.Option.Name == "CloseNotebook")
          {
            msgProceso = "Los cuadernos seleccionados serán cerrados, ¿Desea continuar?";
            TipoEstado_ = 2;
            msgSalida = "Cuaderno(s) cerrado(s) correctamente";
            this.NotebookProcess(msgProceso, TipoEstado_, msgSalida);
          }
          else if(event.Option.Name == "OpenNotebook")
          {

            let obj = this.utility.GetApiModel('ValidacionAperturaCuaderno', null)

            obj.QueryConfig.Command = 'ValidacionAperturaCuaderno';
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = {
              IdsCuaderno: this.modelo.modeloVirtual.IdSCuadernos,
              IdAreaEmpresa: this.modelo.modelo.IdAreaEmpresa,
            };

            let info = {
              componentName: this.componentName, controlName: 'ValidacionAperturaCuaderno',
              processMessage: 'Validando Información Cuaderno......'
            };

            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
              //debugger;
              var numCuadernos = this.modelo.modeloVirtual.IdSCuadernos.split(',');
              if(data.Data.length < numCuadernos.length)
              {
                this.utility.VerModalWarning({
                  titulo: 'Alerta', descripcion: "Uno o más cuadernos seleccionados no corresponden con el área de la entidad actual, por favor verifique e intentelo nuevamente",
                  verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                }).then(data => { });
              }
              else
              {
                msgProceso = "Los cuadernos seleccionados serán abiertos, ¿Desea continuar?";
                TipoEstado_ = 1;
                msgSalida = "Cuaderno(s) abierto(s) correctamente";
                this.NotebookProcess(msgProceso, TipoEstado_, msgSalida);
              }

            }).catch(err => { });

          }
        }else {
          super.OnActionEventHandler(event);
        }
        break;
      default:

        super.OnActionEventHandler(event);
        break;
    }
  }

  public FiltroAuditoriaDocumentos() {
   try {
      this.SetProcesando(true);
      this.GridViewFiltroAuditoriaDocumento = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Documento_Filtro"; });
      if (this.GridViewFiltroAuditoriaDocumento) {
        this.GridViewFiltroAuditoriaDocumento.QueryConfig.Entity = {
          FechaCreacionInicial: this.modelo.modelo.FechaCreacionInicialDocumento,
          FechaCreacionFinal: this.modelo.modelo.FechaCreacionFinalDocumento,
          IdEmpresa: -1,
          IdDocumentoExpediente: this.modelo.documento.IdDocumentoExpediente
        };
        this.GridViewFiltroAuditoriaDocumento.cargarGrilla = true;
        this.GridViewFiltroAuditoriaDocumento._CargarGrilla(true);
        this.SetModelToControls();
      }
      // fin
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }
  public NotebookProcess(msgProceso, TipoEstado_, msgSalida)
  {
    this.utility.VerModalConfirmacionGeneral("Procesar Cuadernos", msgProceso).then(modelo => {
      if (modelo.dialogResult == true) {
        let obj = this.utility.GetApiModel('ProcesarCuadernos', null)
        obj.QueryConfig.Command = 'ProcesarCuadernos';
        obj.QueryConfig.IsSp = true;
        obj.QueryConfig.Entity = {
          IdEmpresa: -1,
          IdsCuadernos: this.modelo.modeloVirtual.IdSCuadernos,
          TipoEstado: TipoEstado_
        };
        let info = {
          componentName: this.componentName, controlName: 'ProcesarCuadernos',
          processMessage: 'Actualizando Estado de los cuadernos......'
        };
        this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
          this.componentsContainer.Tables[0].ReCargarGrilla();
          this.componentsContainer.Tables[1].ReCargarGrilla();
          if (this.jsTreeDetalleExpedienteComponent) {
            this.DestroyJsTree();
            this.jsTreeDetalleExpedienteComponent.CrearDocumentosPaginador(null, true, 0, 0, false);
            this.modelo.modeloVirtual.IdSCuadernos = null;
          }
          this.utility.VerModalOk(msgSalida);
          //debugger
          if(TipoEstado_ == 2){
            this.commandBar.SetHiddenOption(true, "Name", "CloseNotebook");
          }
          if (TipoEstado_ == 1){
            this.commandBar.SetHiddenOption(true, "Name", "OpenNotebook");
          }

        }).catch(err => { })//.finally(() => subscription.unsubscribe())
      }
      else {
        return
      }
    });
  }

  public validarExisteTipoDocumentalRequerido(listaExpedientes: any): boolean {

    var countTD: number = 0;
    var countExpediente: number = 0;
    if (this.modelo.modelo.ConfiguracionTRD) {
      var tipoDocumentalRequerido = JSON.parse(this.modelo.modelo.ConfiguracionTRD);
      var listaTipoDocumentalRequerido = tipoDocumentalRequerido.TiposDocumentales.filter(x => { return x.Requerido == true; });
      this.listaTipoDocumentalRequeridos = listaTipoDocumentalRequerido;
      countTD = listaTipoDocumentalRequerido.length;

      if (countTD > 0) {

        listaExpedientes.forEach(doc => {

          listaTipoDocumentalRequerido.forEach(element => {
            if (doc.IdTipoDocumental == element.IdTipoDocumental) {
              countExpediente++;
              this.listaTipoDocumentalRequeridos = this.listaTipoDocumentalRequeridos.filter(obj => obj.IdTipoDocumental !== element.IdTipoDocumental);
            }
          });
        });
        return (countTD != countExpediente) ? true : false;
      }
      else
        return false;
    }

    return false;
  }

  public AuditoriaProcesos() {
    const subscription = this.formEvent.subscribe({
      next: event => {
        setTimeout(() => {
          if (event.Name === FormActionsThroughEmitters.OnDownloadSharePoint || event.Name === FormActionsThroughEmitters.OnDownloadAzureBlob) {

            let obj = this.utility.GetApiModel('CrearAuditoriaDescargaDocumentosSharePoint', null)
            obj.QueryConfig.Command = 'CrearAuditoriaDescargaDocumentosSharePoint';
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = {
              IdEmpresa: -1,
              IdRegistro: this.modelo.modelo.IdExpediente,
              IdSubRegistro: this.modelo.documento.IdDocumentoExpediente,
              UsuarioCreacion: StaticData.Usuario.Email,
              IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
              Maquina: -1,
              Descripcion: 'Descarga de archivo del documento ' + (this.modelo.modelo.NombreDocumento || this.modelo.documento.NombreDocumento) + ' en el expediente con Id: ' + this.modelo.modelo.IdExpediente
            };
            let info = {
              componentName: this.componentName, controlName: 'CrearAuditoriaDescargaDocumentosSharePoint',
              processMessage: 'Auditoria Expediente......'
            };
            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
            }).catch(err => { }).finally(() => subscription.unsubscribe())
          }
          else if (event.Name === FormActionsThroughEmitters.OnViewAzureBlob) {

            let obj = this.utility.GetApiModel('CrearAuditoriaVisualizacionDocumentos', null)
            obj.QueryConfig.Command = 'CrearAuditoriaVisualizacionDocumentos';
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = {
              IdEmpresa: -1,
              IdRegistro: this.modelo.modelo.IdExpediente,
              IdSubRegistro: this.modelo.documento.IdDocumentoExpediente,
              UsuarioCreacion: StaticData.Usuario.Email,
              IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
              Maquina: -1,
              Descripcion: 'Visualización de archivo del documento ' + (this.modelo.modelo.NombreDocumento || this.modelo.documento.NombreDocumento) + ' en el expediente con Id: ' + this.modelo.modelo.IdExpediente
            };
            let info = {
              componentName: this.componentName, controlName: 'CrearAuditoriaVisualizacionDocumentos',
              processMessage: 'Auditoria Expediente......'
            };
            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
            }).catch(err => { }).finally(() => subscription.unsubscribe())
          } else if (event.Name === FormActionsThroughEmitters.DetailDocument) {
            let obj = this.utility.GetApiModel(
              "CrearAuditoriaDetalleDocumento",
              null
            );
            obj.QueryConfig.Command = "CrearAuditoriaDetalleDocumento";
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = {
              IdEmpresa: -1,
              IdRegistro: this.modelo.modelo.IdExpediente,
              IdSubRegistro: this.modelo.documento.IdDocumentoExpediente,
              UsuarioCreacion: StaticData.Usuario.Email,
              IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
              Maquina: -1,
              Descripcion:
                "Visualización detalle del documento " +
                (this.modelo.modelo.NombreDocumento ||
                  this.modelo.documento.NombreDocumento) +
                " en el expediente con Id: " +
                this.modelo.modelo.IdExpediente,
              IdSerie: null, //MySQL
              OutPutQuery: null //MySQL
            };
            let info = {
              componentName: this.componentName,
              controlName: "CrearAuditoriaDetalleDocumento",
              processMessage: "Auditoria Documento......",
            };
            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {})
              .catch((err) => {})
              .finally(() => subscription.unsubscribe());
          } else if (event.Name === FormActionsThroughEmitters.OnViewAttachment) {
            let obj = this.utility.GetApiModel(
              "CrearAuditoriaVisualizacionAdjuntos",
              null
            );
            obj.QueryConfig.Command = "CrearAuditoriaVisualizacionAdjuntos";
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = {
              IdEmpresa: -1,
              IdRegistro: this.modelo.modelo.IdExpediente,
              IdSubRegistro: this.modelo.documento.IdDocumentoExpediente,
              UsuarioCreacion: StaticData.Usuario.Email,
              IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
              Maquina: -1,
              Descripcion:
                "Visualización de adjuntos del documento " +
                (this.modelo.modelo.NombreDocumento ||
                  this.modelo.documento.NombreDocumento) +
                " en el expediente con Id: " +
                this.modelo.modelo.IdExpediente,
            };
            let info = {
              componentName: this.componentName,
              controlName: "CrearAuditoriaVisualizacionAdjuntos",
              processMessage: "Auditoria Documento......",
            };
            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {})
              .catch((err) => {})
              .finally(() => subscription.unsubscribe());
          } else { //if (event.Option.Name == 'Indice') {
            let obj = this.utility.GetApiModel('CrearAuditoriaDescargaIndiceExpediente', null)
            obj.QueryConfig.Command = 'CrearAuditoriaDescargaIndiceExpediente';
            obj.QueryConfig.IsSp = true;
            obj.QueryConfig.Entity = {
              IdEmpresa: -1,
              IdRegistro: this.modelo.modelo.IdExpediente,
              UsuarioCreacion: StaticData.Usuario.Email,
              IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
              Maquina: -1,
              Descripcion: 'Descarga de Indice en el expediente con Id: ' + this.modelo.modelo.IdExpediente
            };
            let info = {
              componentName: this.componentName, controlName: 'CrearAuditoriaDescargaIndiceExpediente',
              processMessage: 'Auditoria Expediente......'
            };
            this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
            }).catch(err => { }).finally(() => subscription.unsubscribe())
          }
        }, 7000);
      }
    })
  }

  public GuardarAuditoriaExpedinte(option: any) {

    var description: string;
    var spName: string;

    switch (option) {
      case "Indice":
        description = 'Descarga de Indice en el expediente con Id: ' + this.modelo.modelo.IdExpediente;
        spName = "CrearAuditoriaDescargaIndiceExpediente";
        break;
      case "Zip":
        description = 'Descarga de ZIP en el expediente con Id: ' + this.modelo.modelo.IdExpediente;
        spName = "CrearAuditoriaDescargaZipExpediente";
        break;
      case "BtnAuditoria":
        description = 'Exportar auditoria en el expediente con Id: ' + this.modelo.modelo.IdExpediente;
        spName = "CrearAuditoriaExportarAuditoria";
        break;
    }

    //this.componentName = ""
    let obj = this.utility.GetApiModel(spName, null)
    obj.QueryConfig.Command = spName; // 'CrearAuditoriaDescargaIndiceExpediente';
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdRegistro: this.modelo.modelo.IdExpediente,
      UsuarioCreacion: StaticData.Usuario.Email,
      IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
      Maquina: -1,
      Descripcion: description // 'Descarga de Indice en el expediente con Id: ' + this.modelo.modelo.IdExpediente
    };
    let info = {
      componentName: "TExpediente", controlName: spName,
      processMessage: 'Auditoria Expediente......'
    };
    this.baseService.Get(obj, null, info, "TExpediente", false, false).then(data => {
    }).catch(err => { }).finally();

  }

  // 26-11-2020 Inicoi de Implementacion de metodo de filtro
  public FiltroAuditoria() {


    try {
      this.SetProcesando(true);
      // Ajuste de busqueda de filtro de la grilla de auditoria edwin alonso 12022021
      this.GridViewFiltroAuditoria = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Filtro"; });
      if (this.GridViewFiltroAuditoria) {
        this.GridViewFiltroAuditoria.QueryConfig.Entity = {
          FechaCreacionInicial: this.modelo.modelo.FechaCreacionInicial,
          FechaCreacionFinal: this.modelo.modelo.FechaCreacionFinal,
          IdEmpresa: -1,
          IdExpediente: this.modelo.modelo.IdExpediente
        };
        this.GridViewFiltroAuditoria.cargarGrilla = true;
        this.GridViewFiltroAuditoria._CargarGrilla(true);
      }
      // fin
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }
  // fin de implementacion de metodo de filtro
  /**
   * Inicio - Edward Morales - 06022023_1413
   * Auditoría para Documentos
   */
  public FiltroAuditoriaDocumento() {
    //debugger;

    try {
      this.SetProcesando(true);
      this.GridViewFiltroAuditoriaDocumento =
        this.componentsContainer.Tables.find((x) => {
          return x.name == "DataTables_Auditoria_Documento_Filtro";
        });
      if (this.GridViewFiltroAuditoriaDocumento) {
        this.GridViewFiltroAuditoriaDocumento.QueryConfig.Entity = {
          FechaCreacionInicial:
            this.modelo.modelo.FechaCreacionInicialDocumento,
          FechaCreacionFinal: this.modelo.modelo.FechaCreacionFinalDocumento,
          IdEmpresa: -1,
          IdDocumentoExpediente: this.modelo.documento.IdDocumentoExpediente,
        };
        this.GridViewFiltroAuditoriaDocumento.cargarGrilla = true;
        this.GridViewFiltroAuditoriaDocumento._CargarGrilla(true);
      }
      // fin
    } catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, {
        componentName: this.componentName,
        method: "onInit",
      });
    }
  }

  public OnLoadGridHandler(event) {
    //
    switch (event.controlName) {
      case 'DataTables_TDocumentoExpediente':
        //


        this.FinConsultaGrillaDocumentos(event);
        // setTimeout(() => {
        //   if (this.vistaArbol) {
        //     this.componentsContainer.Tables[0].QueryConfig.Entity.IdCuaderno = 0;
        //   }
        // },1000);

        break;

    }
  }

  public OnSetConfigHandler(event) {
    event.config.ConfigControl.QueryConfig.Entity = { IdEmpresa: 1, IdExpediente: this.expediente.IdExpediente };
    if (!event.isPost) {
      switch (event.config.Name) {
        case 'DataTables_TDocumentoExpediente':
          event.config.permissionsConfig = this.configPermisosDocumento;
          break;
        case 'DataTables_UbicacionesExpediente':
          event.config.permissionsConfig = this.configPermisosUbicacion;
          event.config.ConfigControl.NotLoad = true;
          break;

        case 'DataTables_VersionesDocumento':

          event.config.ConfigControl.NotLoad = true;
          break;

      }
    }
  }

  public SetProcesandoBuscar(ok: boolean) {
    this.botonProcesandoBuscar = ok;
    if (ok) {
      this.textoBotonBuscar = '';
      this.textoProcesandoBuscar = 'Ejecutando.......';
    }
    else {
      this.textoBotonBuscar = 'Buscar';
      this.textoProcesandoBuscar = '';
    }
  }
  public Buscar() {


    try {
      this.SetProcesandoBuscar(true);
      this.AsignarModeloModeloBusqueda();


    }
    catch (error) {
      this.SetProcesandoBuscar(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }
  public AsignarModeloModeloBusqueda() {

    if (this.modelo.modeloCascada) {
      let clone = this.utility.Clone(this.modelo.modeloCascada);

      clone.PalabraClave = this.modelo.modelo.PalabraClave;

      this.utility.SetValorPropiedadBusquedaContains(clone, 'PalabrasClave');

      this.QueryConfig.Entity = clone;
      this.QueryConfig.Entity.IdEmpresa = -1;
      this.componentsContainer.Tables[0].QueryConfig = this.QueryConfig;
      this.componentsContainer.Tables[0].ReCargarGrilla();
    }

  }

  public FinConsultaGrillaDocumentos(event: any) {
    //
    if (this.isFistLoad == true) {
      this.isFistLoad = false;
      setTimeout(() => {
        this.SetTiposDocumentales();
      }, 2000);
    }
    if (this.isSearch == true) {
      this.utility.VerModalOk("Búsqueda Documento Finalizada");
    } else if (this.isSearch == false) {
      this.utility.VerModalOk("Limpieza Campos Finalizada");
    }
    this.isSearch = null;
    // else if (this.jsTreeDetalleExpedienteComponent)
    //   this.jsTreeDetalleExpedienteComponent.CrearNodosDocumentosExpediente(event.data);
  }
  tiposDocumentales: Array<any> = [];
  tiposDocumentalesRequeridos: Array<any> = [];
  public ExpedinteDetalleLoadedEventHandler(event) {
    //



    let config = event.ConfiguracionTRD;
    this.tiposDocumentales = this.ExtractPropertyFromJSON(config, "TiposDocumentales");
    this.expediente = event;
    if (this.componentsContainer.FormsDetails.length > 0) {

      let FormDetalleExpediente = this.componentsContainer.Find("FormDetalleExpediente");
      FormDetalleExpediente.modelo = this.expediente;
      //this.componentsContainer.FormsDetails[0].modelo = this.expediente;
    }
    // this.tiposDocumentales.forEach(tipodoc => {
    //   if(tipodoc && tipodoc.Requerido)
    //   this.tiposDocumentalesRequeridos.push(tipodoc);
    //   console.error("******", this.tiposDocumentalesRequeridos);
    // })

  }


  public SetTiposDocumentales() {
    //

    // if (this.tiposDocumentales != null && this.jsTreeDetalleExpedienteComponent) {



    //   let obj = this.utility.GetApiModel('BusquedaTipoDocumentalEnExpediente', null)
    //   obj.QueryConfig.Command = 'BusquedaTipoDocumentalEnExpediente';
    //   obj.QueryConfig.IsSp = true;
    //   obj.QueryConfig.IsMulti = false;
    //   obj.QueryConfig.Entity = {
    //     IdEmpresa: 1,
    //     // IdExpediente: this.expediente.modelo.IdExpediente,
    //     IdExpediente: this.expediente.IdExpediente,
    //   };
    //   // 040220201 controlador Rtipodocumental a TExpediente Edwin Alonso
    //   // this.url = (!this.url) ? 'RTipoDocumental' : this.url;
    //   this.url = (!this.url) ? 'TExpediente' : this.url;
    //   let info = {
    //     componentName: this.componentName, controlName: 'BusquedaTipoDocumentalEnExpediente',
    //     processMessage: 'Buscando Tipos Documentales del expdiente ...'
    //   };
    //   this.baseService.Get(obj, null, info, this.url, false, false).then(data => {
    //     //
    //     this.tiposDocPendientes = [];
    //     this.tiposDocEnUso = [];
    //     this.tiposDocumentales.forEach(tiposDocConf => {

    //       if (data.Data.find(tdoc => tdoc.IdTipoDocumental == tiposDocConf.IdTipoDocumental) != null)
    //         this.tiposDocEnUso.push(tiposDocConf);
    //       else
    //         this.tiposDocPendientes.push(tiposDocConf);
    //     });

    //     //

    //     // this.jsTreeDetalleExpedienteComponent.SetTiposDocumentalesSinAsignar(this.tiposDocPendientes);
    //     // this.jsTreeDetalleExpedienteComponent.SetTiposDocumentalesAsignados(this.tiposDocEnUso);



    //   }).catch(err => {
    //     //
    //   });


    // }
  }
  public EndLoadTree(event: any) {
    //
    // alert('End');
    //  jsonProject.children.push(nodoResponsables);
    //  jsonProject.children.push(nodoPermisos);
    //  jsonProject.children.push(nodoDocumentos);
    //  jsonProject.children.push(nodoUbicaciones);
    //  jsonProject.children.push(nodoTiposDocumentos);
    // event.jsonProject.children.splice(2,1);
    // event.jsonProject.children.splice(2,1);
    this.orgNodos = event.orgNodos;
    this.orgNodosPermisos = event.orgNodosPermisos;

  }

  public updateChildrenJstree(data: any, add: boolean)
  {
    if(data != null && data.length > 0)
    {
      this.modelo.modeloVirtual.ValidacionStatus = true;
      data.forEach(info => {
        if(info.data.ObjType == 'Cuaderno')
        {
          if(add)
          {
            this.dataSelecetedNodes.push(info.data.IdData);
            if (this.state == null)
              this.state = info.data.NodeState
            if(this.state != info.data.NodeState)
              this.modelo.modeloVirtual.ValidacionStatus = false

            this.state = info.data.NodeState

          }
          else
            this.dataSelecetedNodes = this.dataSelecetedNodes.filter((item) => item !== info.data.IdData)
        }





        else
          //this.updateChildrenJstree(info.data.children, add)
          //Cuando se pasa el nodo de carpeta dentro de info no hay objeto data si no directamente children
          this.updateChildrenJstree(info.children, add)
      });

    }
  }



  public TreeClickChange(data: any) {

    debugger
    if(data.data.selectionMode)
    {
      this.modelo.modeloVirtual.ValidacionStatus = true;
      this.modelo.modeloVirtual.selectionMode = true
      if(data.data.node.data.ObjType == "Cuaderno")
      {
        if(data && data.data && data.data.action == "deselect_node")
          this.dataSelecetedNodes = this.dataSelecetedNodes.filter((item) => item !== data.data.node.data.IdData)
        else
        {
          this.dataSelecetedNodes.push(data.data.node.data.IdData);
          if(this.state != null)
          {
            if(this.state != data.data.node.data.NodeState)
              this.modelo.modeloVirtual.ValidacionStatus = false
          }

          this.state = data.data.node.data.NodeState
        }
      }
      else if (data.data.node.data.ObjType == "Carpeta") {
        if(data.data.node.data.NodeText == "FoliadoElectronico"){
          this.componentsContainer.Tables[0].QueryConfig.Entity.IdFoliado = data.data.node.data.IdData;
          this.modelo.modeloVirtual.IdCuadernoGrilla = ""
        }else{
          if (data && data.data && data.data.action == "deselect_node")
            this.dataSelecetedFolders = this.dataSelecetedFolders.filter((item) => item !== data.data.node.data.IdData)
          else {
            this.dataSelecetedFolders.push(data.data.node.data.IdData);
          }

          var NodoBusqueda = StaticData.GeneralConfig.ConfigValidacionDocumento.PropiedadNombreNodoBusqueda ? StaticData.GeneralConfig.ConfigValidacionDocumento.PropiedadNombreNodoBusqueda : 'Carpeta';
          this.componentsContainer.Tables[0].QueryConfig.Entity.PropiedadBusqueda = NodoBusqueda;
          this.componentsContainer.Tables[0].QueryConfig.Entity.IdSCarpertas = this.dataSelecetedFolders.length > 0 ? this.dataSelecetedFolders.join() : null ;
        }
      }
      else
      {
        if(data && data.data && data.data.action == "deselect_node")
          this.updateChildrenJstree(data.extraData.children, false);
        else
          this.updateChildrenJstree(data.extraData.children, true);
      }
      if(this.dataSelecetedNodes.length > 0){
        this.modelo.modeloVirtual.IdSCuadernos = this.dataSelecetedNodes.join();
        this.modelo.modeloVirtual.ValidacionStatus = true;
      }
      else
      {
        this.modelo.modeloVirtual.IdSCuadernos = 0;
        this.modelo.modeloVirtual.ValidacionStatus = false;
      }


      if(this.modelo.modeloVirtual.ValidacionStatus)
      {
        if (this.state == 1 && this.modelo.modelo.IdUsuarioResponsable == this.modelo.modeloVirtual.UsuarioAutenticado){
          this.modelo.modeloVirtual.ProcesoCuaderno = "Abierto"
          this.commandBar.SetHiddenOption(false, 'Name', 'CloseNotebook');
          this.commandBar.SetHiddenOption(true, 'Name', 'OpenNotebook');
        }


        if (this.state == 2 && this.modelo.modelo.IdUsuarioResponsable == this.modelo.modeloVirtual.UsuarioAutenticado){
          this.modelo.modeloVirtual.ProcesoCuaderno = "Cerrado";
          this.commandBar.SetHiddenOption(true, 'Name', 'CloseNotebook');
          this.commandBar.SetHiddenOption(false, 'Name', 'OpenNotebook');
        }

      }
      else
        this.modelo.modeloVirtual.ProcesoCuaderno  =  "NoProcesar"
    }
    else
    {
    this.orgNodosGeneral2 = [];
    this.componentsContainer.Tables[0].QueryConfig.Entity.IdFoliado = '';

    // 20240125 Manuel Rodriguez: INICIO Devolución de cambios commit 20231101
    // pendiente verificar si las siguientes lineas si deben estar encendidas
    // ya que al día de hoy se habian borrado pero se incluyó nuevo código en donde estaban estas lineas
    var NodoBusqueda = StaticData.GeneralConfig.ConfigValidacionDocumento
      .PropiedadNombreNodoBusqueda
      ? StaticData.GeneralConfig.ConfigValidacionDocumento
          .PropiedadNombreNodoBusqueda
      : "Carpeta";
    this.componentsContainer.Tables[0].QueryConfig.Entity.PropiedadBusqueda =
      NodoBusqueda;
    this.componentsContainer.Tables[0].QueryConfig.Entity.IdPropiedad =
      data.data.node.data.IdData;
    this.componentsContainer.Tables[0].cargarGrilla = true;
    this.componentsContainer.Tables[0]._CargarGrilla(true);
    // 20240125 Manuel Rodriguez: FIN Devolución de cambios commit 20231101

    // if (this.HeredaPermisosExpediente)
    //   this.componentsContainer.Tables[0].QueryConfig.Entity.PermisosHeredadosExpediente = this.HeredaPermisosExpediente
    //this.componentsContainer.Tables[0].QueryConfig.Entity.PermisosHeredadosExpediente = true;

    if (data.data.node.data.ObjType == "Cuaderno")
    // this.componentsContainer.Tables[0].QueryConfig.Entity.IdCuaderno = data.data.node.data.IdData;
      this.modelo.modeloVirtual.IdCuadernoGrilla = data.data.node.data.IdData;
    else if (data.data.node.data.ObjType == "Carpeta" && data.data.node.data.NodeText == "FoliadoElectronico") {
      this.componentsContainer.Tables[0].QueryConfig.Entity.IdFoliado = data.data.node.data.IdData;
    // this.componentsContainer.Tables[0].QueryConfig.Entity.IdCuaderno = null
      this.modelo.modeloVirtual.IdCuadernoGrilla = ""
  }
  else
  // this.componentsContainer.Tables[0].QueryConfig.Entity.IdCuaderno = 0;
    this.modelo.modeloVirtual.IdCuadernoGrilla = 0;

  //this.orgNodosGeneral = (data.extraData) ? [data.extraData] : [];

  //this.utility.ConvertNodesJstreeToOrgChart(data.extraData, this.orgNodosGeneral2, true);
  //this.orgChartJsComponent.LoadNodes(this.orgNodosGeneral2);

  // if (data.data.node.data && data.data.node.data.ObjType === 'Formulario') {
  //   this.ViewPage(data.node.data);
  // }
  // else if (data.data.node.data &&
  //    (data.data.node.data.ObjType === 'AreasEmpresa' ||
  //    data.data.node.data.ObjType === 'Series' ||
  //    data.data.node.data.ObjType === 'SubSeries'||
  //    data.data.node.data.ObjType === 'TiposSolicitud'||
  //    data.data.node.data.ObjType === 'Carpetas' ||
  //    data.data.node.data.ObjType === 'Expedientes' ||
  //    data.data.node.data.ObjType === 'Roles'||
  //    data.data.node.data.ObjType === 'Grupos')) {
  //  this.orgNodosGeneral =[data.extraData];
  // }

  // }

}


this.componentsContainer.Tables[0].cargarGrilla = true;
this.componentsContainer.Tables[0]._CargarGrilla(true);
}

  public ExtractPropertyFromJSON(json: string, property: string) {
    if (json != null) {
      var config = JSON.parse(json);
      if (config[property].length > 0)
        return config[property];
      else
      return null;
    }
  else {
      return null;
    }
  }

  public OnClick(option: any, attachment) {
  }
  public trasladar(valid)
  {
    let validform = valid;
    if(this.modelo.modeloVirtual.EsOtraSeccional)
    {
      if((this.modelo.modeloVirtual.Juzgado == '' || this.modelo.modeloVirtual.Juzgado == null) || (this.modelo.modeloVirtual.IdSedeEmpresa == '' || this.modelo.modeloVirtual.IdSedeEmpresa == null))
      {
        validform = false;
        this.utility.VerModalWarning({
          titulo: 'Alerta', descripcion: "Por favor seleccione una Seccional y un Juzgado para continuar",
          verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
        }).then(data => { });
      }
    }
    else
    {
      if(this.modelo.modeloVirtual.Juzgado == '' || this.modelo.modeloVirtual.Juzgado == null)
      {
        validform = false;
        this.utility.VerModalWarning({
          titulo: 'Alerta', descripcion: "Por favor seleccione un Juzgado para continuar",
          verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
        }).then(data => { });
      }
    }

    if(validform)
    {
      let obj = this.utility.GetApiModel('TrasladoExpediente', null)
      obj = {
        IdExpediente: this.modelo.modelo.IdExpediente,
        IdAreaEmpresa: this.modelo.modelo.IdAreaEmpresa,
        DataAdd: {
          CodigoExpediente: this.modelo.modelo.NumeroRadicacionProceso,
          ReglaCorreoTraslado: this.modelo.modeloVirtual.ReglaCorreoNotificacionTraslado,
          NombreAreaEmpresaDestino: this.modelo.modeloVirtual.NombreJuzgado,
          IdAreaEmpresaDestino: this.modelo.modeloVirtual.Juzgado,
          GUIDPlantilla: this.modelo.modeloVirtual.CorreoNotificacionTraslado
        }
      };

      let info = {
        componentName: this.componentName, controlName: 'CerrarExpediente',
        processMessage: 'Realizando el traslado del expediente'
      };
      this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/TrasladoExpediente', false, false).then(data => {

        if(data.Data)
        {
          this.utility.VerModalOk("Expediente trasladado correctamente");
          this.modelo.modeloVirtual.IdSedeEmpresa = null;
          this.modelo.modeloVirtual.Juzgado = null;
          this.CmbxIdSedeEmpresa.Hidden = true;
          this.modelo.modeloVirtual.EsOtraSeccional = false;
          this.SetModelToControls();
          /*Se oculta Side panel y se oculta botón de trasladar al completar la operación*/
          /*Alex Caro - 2023-05-02*/
          this.componentsContainer.Find('Translado').sidePanelInstance.dismiss();
          this.commandBar.SetHiddenOption(true, 'Name', 'Trasladar');
        }

      }).catch(err => {
        this.utility.VerModalError(err);
      });
    }
  }

  public compartir(valid)
  {
    let validform = valid;
    if(
        (this.modelo.modeloVirtual.FechaInicialPermiso == '' || this.modelo.modeloVirtual.FechaInicialPermiso == null) ||
        (this.modelo.modeloVirtual.FechaFinalPermiso == '' || this.modelo.modeloVirtual.FechaFinalPermiso == null) ||
        (this.modelo.modeloVirtual.CorreoPermiso == '' || this.modelo.modeloVirtual.CorreoPermiso == null)
      )
    {
        validform = false;
        this.utility.VerModalError("Por favor diligencie los datos solicitados para continuar");
    }
    else{
      if(Date.parse(this.modelo.modeloVirtual.FechaInicialPermiso) > Date.parse(this.modelo.modeloVirtual.FechaFinalPermiso))
      {
        validform = false;
        this.utility.VerModalError("La fecha inicial no puede ser superior a la fecha final");
      }
    }

    if(validform)
    {
      let obj = this.utility.GetApiModel('CompartirExpediente', null)
      obj = this.modelo.modelo;

      let info = {
        componentName: this.componentName, controlName: 'CompartirExpediente',
        processMessage: 'Adjudicando el permiso de forma externa'
      };
      this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/CompartirExpediente', false, false).then(data => {

        if(data.Data)
        {
          this.utility.VerModalOk("Permiso adjudicado correctamente");
          this.modelo.modeloVirtual.FechaInicialPermiso = null;
          this.modelo.modeloVirtual.FechaFinalPermiso = null;
          this.modelo.modeloVirtual.CorreoPermiso = null;
          this.SetModelToControls();
        }

      }).catch(err => {
        this.utility.VerModalError(err);
      });
    }
  }

        /**
   * Edward Morales - 20/02/2023
   * Región Filtros Avanzados Documento Expediente
   */

  public NgAfterViewInitEnd() {
    //debugger;
    if (this.expediente && this.configs.ConfigIdTipoDocumentalFiltro) {
      //Asignación de data a combos para filtros
      const configuracion = JSON.parse(this.expediente.ConfiguracionTRD);
      if (configuracion && configuracion.TiposDocumentales) {
        var tiposDoc = configuracion["TiposDocumentales"];
        this.configs.ConfigIdTipoDocumentalFiltro.Context.SetItemsComboBox(
          tiposDoc
        );
      }
      if (configuracion && configuracion.Carpetas) {
        var CarpetasCombo = configuracion["Carpetas"];
        this.configs.ConfigIdCarpetaFiltro.Context.SetItemsComboBox(
          CarpetasCombo
        );
      }
      this.SetModelToControls();
      // fin
    }
  }

  public BuscarDocumento(boton) {
    //debugger;
    try {
      if (
        (boton == "BtnBuscarDocumentoFiltro" &&
          this.modelo.modeloVirtual.FechaCreacionInicialFiltro != null &&
          this.modelo.modeloVirtual.FechaCreacionFinalFiltro != null) ||
        boton == "BtnLimpiarDocumentoFiltro"
      ) {
        this.SetProcesando(true);
        this.componentsContainer.Tables[0].QueryConfig.Entity =
          this.SetModeloFiltro();
        this.componentsContainer.Tables[0].cargarGrilla = true;
        this.componentsContainer.Tables[0]._CargarGrilla(true);
        if (boton == "BtnBuscarDocumentoFiltro") {
          this.isSearch = true;
        } else {
          this.isSearch = false;
        }
      } else {
        this.utility.VerModalWarning({
          titulo: "Alerta",
          descripcion: "Campos Fecha Inicial y Final Documento son requeridos",
        });
      }
    } catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, {
        componentName: this.componentName,
        method: "onInit",
      });
    }
  }

  public SetModeloFiltro() {
    //this.modelo.modelo.FiltroGeneral = '';
    let clone = this.utility.Clone(this.modelo.modeloVirtual);

    //this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modeloVirtual, 'ExtensionFiltro', 'ExtensionFiltro');
    //debugger;
    clone.FiltroMetadatos = null;
    if (
      this.modelo.modeloMetadata &&
      this.modelo.modeloMetadata.Items &&
      this.modelo.modeloMetadata.Items.Count()
    ) {
      this.modelo.modeloMetadata.Items.ToArray().forEach((element) => {
        //0004760: al hacer el filtro de búsqueda de expedientes con metadatos no muestra los expedientes
        //MP cuando los parametro de metadatos son null, no filtra los datos
        //debugger;
        if (
          element.Value != null &&
          (element.DataType != "Boolean"
            ? element.Value.trim().length > 0
            : element.Value)
        ) {
          if (clone.FiltroMetadatos) {
            clone.FiltroMetadatos += " OR ";
          } else clone.FiltroMetadatos = "";

          clone.FiltroMetadatos +=
            " (TRegistroTipoDocumentalPaginaMetadatosDetalle.TipoDato  = '" +
            element.DataType +
            "' AND ";
          clone.FiltroMetadatos +=
            " TRegistroTipoDocumentalPaginaMetadatosDetalle.NombreControl = '" +
            element.Name +
            "' ";

          switch (element.DataType) {
            case "Int32":
            case "Int64":
            case "Int16":
              //ValorEntero
              clone.FiltroMetadatos +=
                " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorEntero = " +
                element.Value +
                ")";
              break;
            case "Decimal":
            case "Number":
              //ValorNumero
              clone.FiltroMetadatos +=
                " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorNumero = " +
                element.Value +
                ")";
              break;
            case "Boolean":
              //ValorBoolean
              if (element.Value == "true" || element.Value == 1)
                clone.FiltroMetadatos +=
                  " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorBoolean = 1)";
              else
                clone.FiltroMetadatos +=
                  " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorBoolean = 0)";
              break;
            case "DateTime":
              //ValorFecha
              clone.FiltroMetadatos +=
                " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorFecha = CONVERT(VARCHAR(10),'" +
                element.Value +
                "',103))";
              break;
            case "String":
              //ValorTextoCuatrocientos
              clone.FiltroMetadatos +=
                " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorTextoCuatrocientos = '" +
                element.Value +
                "')  ";
              break;
            case "SemiLargeString":
              //ValorTextoMil
              clone.FiltroMetadatos +=
                " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorTextoMil = '" +
                element.Value +
                "')  ";
              break;
            case "LargeString":
            case "Json":
              //ValorObservaciones
              clone.FiltroMetadatos +=
                " AND TRegistroTipoDocumentalPaginaMetadatosDetalle.ValorObservaciones = '" +
                element.Value +
                "')  ";
              break;
          }
        }
      });
    }

    //debugger;
    return {
      IdEmpresa: -1,
      IdExpediente: this.expediente.IdExpediente,
      IdCarpeta: clone.IdCarpetaFiltro,
      IdTipoDocumental: clone.IdTipoDocumentalFiltro,
      FiltroMetadatos: clone.FiltroMetadatos,
      PalabrasClave: clone.PalabrasClaveFiltro,
      FechaInicialDocumento: clone.FechaCreacionInicialFiltro,
      FechaFinalDocumento: clone.FechaCreacionFinalFiltro,
      FechaInicialCarga: clone.FechaFinalInicialFiltro,
      FechaFinalCarga: clone.FechaFinalFinalFiltro,
      Extension: clone.ExtensionFiltro,
      EsDigital: clone.EsDigitalFiltro,
      Filtro: clone.Filtro,
    };
  }

  public GetDetalleTipoDocumental(item: any) {
    //debugger;
    const that = this;
    if (!item) return;

    let obj = this.utility.GetApiModel("GetDetalleTipoDocumental", null);
    obj.QueryConfig = {
      Command: "BusquedaTipoDocumentalDetalle",
      IsSp: true,
      Entity: {
        IdEmpresa: -1,
        IdTipoDocumental: item.IdTipoDocumental,
      },
    };

    let info = {
      componentName: this.componentName,
      controlName: "GetDetalleTipoDocumental",
      processMessage: "Cargando Config Tipo Documental......",
    };

    this.baseService
      .Get(obj, null, info, this.componentName, false, false)
      .then((data) => {
        //debugger;
        const configuracion = data.Data[0].Configuracion;
        this.CreateMetadataControls(configuracion);
        this.SetModelToControls();
      })
      .catch((err) => {});
  }
  //20/05/2023
  //Se comentarea el metodo ya que el mismo metodo ya existe en el componente general-component.ts
  //Julian Sarmiento
  // public CreateMetadataControls(_metadata: any, prop: string = 'Metadatos') {
  //   //debugger;
  //   if (!_metadata)
  //     return;
  //   let metadata: any = _metadata;
  //   const formGroup: any = {};
  //   if (typeof _metadata === 'string') {
  //     metadata = JSON.parse(_metadata);
  //   }
  //   else
  //     metadata = _metadata;
  //   if (!Array.isArray(metadata)) {
  //     metadata = metadata[prop];
  //   }
  //   this.Pagina.ConfigPage.Metadata = metadata;

  //   this.utility.InactivarPorSearcheable(this.Pagina.ConfigPage.Metadata);

  //   this.DynamicMetadataFiltro.Controls = this.Pagina.ConfigPage.Metadata//.filter(x => { return x.Searcheable });
  //   //this.modelo.modeloMetadata.IdParentObject = idParent;
  //   const that = this;
  //   if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.Metadata && this.Pagina.ConfigPage.Metadata.length > 0) {

  //     //this.controls = new List([])
  //     // this.SetFormControl(formGroup, this.Pagina.ConfigPage.Metadata);

  //     // let metadatForms = new FormGroup(formGroup);
  //     // this.form.addControl("Metadatos", metadatForms);
  //     //
  //     this.AddControls(this.Pagina.ConfigPage.Metadata, null, true);

  //     // this.CreateModel();
  //     this.SetValueChangesControlsToModel();

  //   }

  // }
}
