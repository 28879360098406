import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { OperacionesDeSolicitudes } from "src/app/models/general.enum";
import { SolicitudCommonComponent } from '../../common/solicitud-common.component';


@Component({
  selector: 'app-solicitud-entrada-reemplazo',
  templateUrl: './solicitud-entrada-reemplazo.component.html',
  styleUrls: ['./solicitud-entrada-reemplazo.component.css']
})
export class SolicitudEntradaReemplazoComponent extends SolicitudCommonComponent {


  public OnInitEnd() {
      this.postSaveEmitter.subscribe(modelo => {
          
          this.utility.VerModalOk("Se asignó un oficio a la Entrada con código: " + "\n\r" + modelo.data.data.Codigo);
      });
  }

  _Guardar(form: FormGroup, configButtons?: any): void {
        
    if (this.modelo.modelo.DataAdd)
          this.modelo.modelo.DataAdd["OperacionSolicitud"] =
            OperacionesDeSolicitudes.ReemplazarDocumento;
        else
          this.modelo.modelo.DataAdd = {
            OperacionSolicitud: OperacionesDeSolicitudes.ReemplazarDocumento
          }; 

    super._Guardar(form, configButtons);

  }

}
