import { Output, EventEmitter, Input } from '@angular/core';
import { ControllerMethods, TypesUseForm } from './general.enum';
import { Utilities } from '../helpers/utilities';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { StaticData } from '../helpers/static-data';
import { List } from '../../assets/linqts/compilado/index';
import { Subject } from 'rxjs';
import { Guid } from 'guid-typescript';

export class GeneralFormBase {

    @Input() public permissionsConfig: any;
    _form: FormGroup;
    @Input() set form(value: FormGroup) {


        this._form = value;
    }
    get form(): FormGroup {

        return this._form;
    }
    @Input() modelo: any;
    @Input() modeloClone: any = null;
    public get IsEdit(): boolean {
        return (this.modelo.modelo.PkValue !== 0 && this.modelo.modelo.PkValue !== null && this.modelo.modelo.PkValue !== '');
    }
    @Input() public componentName: string;
    @Input() Pagina: any = { Valida: false, ConfigPage: { ExtraControls: [], Controls: [] } };
    @Input() PaginaPadre: any;
    @Input() subControl: any;
    @Input() modeloPadre: any;
    @Input() parentContext: any;
    @Input() currentContext: any;
    currentMetadataPage: any;
    public controls: List<any>;
    @Input() public configs: any = {}
    //@Input() documentoAdjuntos: any;
    @Input() modeloEdit: any
    @Input() controlsForHidden: Array<any> = [];
    controlsForHiddenList: List<any> = new List([]);
    @Input() typeUseForm: TypesUseForm = TypesUseForm.Normal;
    @Input() mappingFields: any = {};
    @Input() rules: List<any>;


    onConstructor: (data: any) => void;
    preOnInit: (data: any) => void;
    postOnInit: (data: any) => void;
    preSave: (modelo: any) => void;
    postSave: (modelo: any) => void;
    onSave: (modelo: any) => void;

    preDelete: (modelo: any) => void;
    postDelete: (modelo: any) => void;
    onDelete: (modelo: any) => void;
    preActive: (modelo: any) => void;
    postActive: (modelo: any) => void;
    onActive: (modelo: any) => void;
    preLoadGrid: (modelo: any, data: any) => any;
    postLoadGrid: (modelo: any, data: any) => any;
    onLoadGrid: (modelo: any) => any;
    preUpdateProperties: () => void;
    postUpdateProperties: () => void;
    onUpdateProperties: (modelo: any) => void;
    preClear: () => void;
    postClear: () => void;
    onClear: (modelo: any) => void;
    preModalFilter: () => void;
    postModalFilter: () => void;
    onModalFilter: (modelo: any) => void;
    preModalCrud: () => void;
    postModalCrud: () => void;
    onModalCrud: (modelo: any) => void;
    preClickChildOptions: () => void;
    postClickChildOptions: () => void;
    onClickChildOptions: (modelo: any) => void;
    preSelectedComboBox: () => void;
    postSelectedComboBox: (modelo: any) => void;
    onSelectedComboBox: (modelo: any) => void;



    @Output() preSaveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postSaveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSaveEmitter: EventEmitter<any> = new EventEmitter<any>();


    @Output() preDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preLoadGridEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postLoadGridEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onLoadGridEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preClearEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postClearEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClearEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preModalFilterEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postModalFilterEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onModalFilterEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preModalCrudEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postModalCrudEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onModalCrudEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preSelectedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postSelectedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelectedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();


    preEdit: (modelo: any) => void;
    postEdit: (modelo: any) => void;
    onEdit: (modelo: any) => void;

    preSeleccionItem: (modelo: any) => void;
    postSeleccionItem: (modelo: any) => void;
    onSeleccionItem: (modelo: any) => void;
    onLoadedGridItem: (modelo?: any) => void;



    @Output() preEditEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postEditEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditEmitter: EventEmitter<any> = new EventEmitter<any>();

    @Output() preSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();

    @Output() loadedGridEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public utility: Utilities) {
        if (this.utility)
            this.modelo = this.utility.GetModelWithContainer(null);
    }


    ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null) {
        var result = null;
        switch (controllerMethod) {
            case ControllerMethods.Constructor:

                if (this.onConstructor)
                    this.onConstructor(data);
                break;
            case ControllerMethods.OnInit:

                if (!isPost && this.preOnInit)
                    this.preOnInit(data);
                else if (isPost == true && this.postOnInit)
                    this.postOnInit(data);
                break;
            case ControllerMethods.Save:
                
                if (!isPost && this.preSave)
                    this.preSave(this.modelo);
                else if (isPost == true && this.postSave)
                    this.postSave(this.modelo);

                if (this.onSave)
                    this.onSave({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preSaveEmitter.observers.length > 0)
                    this.preSaveEmitter.emit({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postSaveEmitter.observers.length > 0)
                    this.postSaveEmitter.emit({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSaveEmitter.observers.length > 0)
                    this.onSaveEmitter.emit({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.Edit:

                if (!isPost && this.preEdit)
                    this.preEdit(this.modelo);
                else if (isPost == true && this.postEdit)
                    this.postEdit(this.modelo);

                if (this.onEdit)
                    this.onEdit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preEditEmitter.observers.length > 0)
                    this.preEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postEditEmitter.observers.length > 0)
                    this.postEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onEditEmitter.observers.length > 0)
                    this.onEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.Delete:

                if (!isPost && this.preDelete)
                    this.preDelete(this.modelo);
                else if (isPost == true && this.postDelete)
                    this.postDelete(this.modelo);

                if (this.onDelete)
                    this.onDelete({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preDeleteEmitter.observers.length > 0)
                    this.preDeleteEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postDeleteEmitter.observers.length > 0)
                    this.postDeleteEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onDeleteEmitter.observers.length > 0)
                    this.onDeleteEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.Active:

                if (!isPost && this.preActive)
                    this.preActive(this.modelo);
                else if (isPost == true && this.postActive)
                    this.postActive(this.modelo);

                if (this.onActive)
                    this.onActive({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preActiveEmitter.observers.length > 0)
                    this.preActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postActiveEmitter.observers.length > 0)
                    this.postActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onActiveEmitter.observers.length > 0)
                    this.onActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.SelectedItem:

                if (!isPost && this.preSeleccionItem)
                    this.preSeleccionItem(data);
                else if (isPost == true && this.postSeleccionItem)
                    this.postSeleccionItem(data);

                if (this.onSeleccionItem)
                    this.onSeleccionItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preSeleccionItemEmitter.observers.length > 0)
                    this.preSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postSeleccionItemEmitter.observers.length > 0)
                    this.postSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSeleccionItemEmitter.observers.length > 0)
                    this.onSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.LoadedGrid:

                if (this.onLoadedGridItem)
                    this.onLoadedGridItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (this.loadedGridEmitter.observers.length > 0)
                    this.loadedGridEmitter.emit({ controlName: this.componentName, isPost: isPost, data: data, controllerMethod: controllerMethod });
                break;
            case ControllerMethods.LoadGrid:

                if (!isPost && this.preLoadGrid)
                    result = this.preLoadGrid(this.modelo, data);
                else if (isPost == true && this.postLoadGrid)
                    result = this.postLoadGrid(this.modelo, data);

                if (this.onLoadGrid)
                    result = this.onLoadGrid({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preLoadGridEmitter.observers.length > 0)
                    result = this.preLoadGridEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postLoadGridEmitter.observers.length > 0)
                    result = this.postLoadGridEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onLoadGridEmitter.observers.length > 0)
                    result = this.onLoadGridEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.UpdateProperties:

                if (!isPost && this.preUpdateProperties)
                    this.preUpdateProperties();
                else if (isPost == true && this.postUpdateProperties)
                    this.postUpdateProperties();

                if (this.onUpdateProperties)
                    this.onUpdateProperties({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preUpdatePropertiesEmitter.observers.length > 0)
                    this.preUpdatePropertiesEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postUpdatePropertiesEmitter.observers.length > 0)
                    this.postUpdatePropertiesEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onUpdatePropertiesEmitter.observers.length > 0)
                    this.onUpdatePropertiesEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.Clear:

                if (!isPost && this.preClear)
                    this.preClear();
                else if (isPost == true && this.postClear)
                    this.postClear();

                if (this.onClear)
                    this.onClear({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preClearEmitter.observers.length > 0)
                    this.preClearEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postClearEmitter.observers.length > 0)
                    this.postClearEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onClearEmitter.observers.length > 0)
                    this.onClearEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.ModalFilter:


                if (!isPost && this.preModalFilter)
                    this.preModalFilter();
                else if (isPost == true && this.postModalFilter)
                    this.postModalFilter();

                if (this.onModalFilter)
                    this.onModalFilter({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preModalFilterEmitter.observers.length > 0)
                    this.preModalFilterEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postModalFilterEmitter.observers.length > 0)
                    this.postModalFilterEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onModalFilterEmitter.observers.length > 0)
                    this.onModalFilterEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.ModalCrud:

                if (!isPost && this.preModalCrud)
                    this.preModalCrud();
                else if (isPost == true && this.postModalCrud)
                    this.postModalCrud();

                if (this.onModalCrud)
                    this.onModalCrud({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preModalCrudEmitter.observers.length > 0)
                    this.preModalCrudEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postModalCrudEmitter.observers.length > 0)
                    this.postModalCrudEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onModalCrudEmitter.observers.length > 0)
                    this.onModalCrudEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.ClickChildOptions:

                if (!isPost && this.preClickChildOptions)
                    this.preClickChildOptions();
                else if (isPost == true && this.postClickChildOptions)
                    this.postClickChildOptions();

                if (this.onClickChildOptions)
                    this.onClickChildOptions({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preClickChildOptionsEmitter.observers.length > 0)
                    this.preClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postClickChildOptionsEmitter.observers.length > 0)
                    this.postClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onClickChildOptionsEmitter.observers.length > 0)
                    this.onClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.SelectedComboBox:

                if (!isPost && this.preSelectedComboBox)
                    this.preSelectedComboBox();
                else if (isPost == true && this.postSelectedComboBox)
                    this.postSelectedComboBox({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSelectedComboBox)
                    this.onSelectedComboBox({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preSelectedComboBoxEmitter.observers.length > 0)
                    this.preSelectedComboBoxEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postSelectedComboBoxEmitter.observers.length > 0)
                    this.postSelectedComboBoxEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSelectedComboBoxEmitter.observers.length > 0)
                    this.onSelectedComboBoxEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;

        }
        return result;
    }


    //public ClearModel()

    public ClearModel(...excludeProps: string[]) {
        if (this.form && this.form.controls) {
            this.utility.SetModelWithContainer(this.modelo, this.Pagina.IdPagina, excludeProps);


            if (this.Pagina.ConfigPage.ConfigVersion) {
                this.modelo.modelo.VersionModelData = {
                    ObservacionesModificacion: '',
                    Propiedades: this.Pagina.ConfigPage.ConfigVersion.Properties,
                };
            }

            this.controls.ForEach((control: any, index) => {
                if (control.control.Activo == true) {

                    if (control.control.ModelContainer == 'modeloMetadata') {


                    }
                    else {

                        const controlToHide = this.controlsForHiddenList.FirstOrDefault(x => { return x.Name == control.control.Name });

                        const mappingField = this.mappingFields[control.control.Name];
                        if (mappingField !== undefined)
                            control.control.Value = mappingField;
                        if (control.control.GenerateGuid)
                            control.control.Value = Guid.create().toString();
                        if (control.control.Value &&  control.control.Value.toString().includes("#"))
                            this.modelo[control.control.ModelContainer][control.controlName] = this.utility.ReplaceTextFromCurrentUserData(control.control.Value);
                        else
                            this.modelo[control.control.ModelContainer][control.controlName] = control.control.Value;


                    }
                }
            });



        }
    }
    public CreateModel(createModelo: boolean = true) {
        //

        if (createModelo)
            this.modelo = this.utility.GetModelWithContainer(this.Pagina.IdPagina);


        if (this.Pagina.ConfigPage) {

            this.controls.ForEach((control, index) => {

                // if (control.control.ModelFields) {

                // }
                // else 
                if (control.control.ModelContainer == 'modeloMetadata') {
                    if (control.control.Activo == true) {
                        const ctrMeta = this.modelo.modeloMetadata.Items.FirstOrDefault(x => { return x.Name == control.controlName });
                        if (!ctrMeta)
                            this.modelo.modeloMetadata.Items.Add(this.utility.GetMetadataItemModel(control.control));
                        else {
                            ctrMeta.DataType = control.control.DataType;
                            ctrMeta.Label = control.control.Label;
                            //CM
                            //ctrMeta.Value = control.control.Value;
                            control.control.Value = ctrMeta.Value;
                            //--

                        }
                        /*    this.modelo[control.control.ModelContainer][control.controlName] = {
                               "DataType": this.utility.DataTypesToString(control.control.DataType),
                               "Label": control.control.Label,
                               "Value": control.control.Value
                           }; */

                    }
                }
                else {
                    /*   if (control.control.Name == 'Activo')
                          this.modelo[control.control.ModelContainer][control.controlName] = true;
                      else */
                      if (control.control.Value &&  control.control.Value.toString().includes("#"))
                    // if (control.control.Value && control.control.Value.includes("#"))
                        this.modelo[control.control.ModelContainer][control.controlName] = this.utility.ReplaceTextFromCurrentUserData(control.control.Value);
                    else
                        this.modelo[control.control.ModelContainer][control.controlName] = control.control.Value;
                }

            });
            /* 
                        if(this.Pagina.ConfigPage.PkName && !this.modelo['modelo'].hasOwnProperty(this.Pagina.ConfigPage.PkName)){
                            this.modelo['modelo'][this.Pagina.ConfigPage.PkName] =0;
                        } */

            if (this.Pagina.ConfigPage.ConfigVersion) {
                this.modelo.modelo.VersionModelData = {
                    ObservacionesModificacion: '',
                    Propiedades: this.Pagina.ConfigPage.ConfigVersion.Properties,
                };
            }

            /*     $.each(this.controls, (index, control: any) => {
                    if (control.control.ModelContainer == 'modeloMetadata') {
                        if (control.control.Activo == true)
                            this.modelo.modeloMetadata.Items.Add(this.utility.GetMetadataItemModel(control.control));
                    }
                    else
                        this.modelo[control.control.ModelContainer][name] = control.control.value;
                }); */





        }
        /*   if (this.Pagina.ConfigPage.Model) {
              this.modelo = this.utility.Clone(this.Pagina.ConfigPage.Model);
          }
          else {
              if (this.Pagina.ConfigPage) {
                  $.each(this.controls, (index, control: any) => {
                      if (control.control.ModelContainer == 'modeloMetadata') {
                          if (control.control.Activo == true)
                              this.modelo.modeloMetadata.Items.Add(this.utility.GetMetadataItemModel(control.control));
                      }
                      else
                          this.modelo[control.control.ModelContainer][name] = control.control.value;
                  });
              }
          } */
    }
    public GetFormControl(formControl: any, pageName?: string): any {
        let validations = [];
        if (formControl.Required)
            validations.push(Validators.required);
        if (formControl.MaxLength != null && !isNaN(formControl.MaxLength))
            validations.push(Validators.maxLength(formControl.MaxLength));
        if (formControl.MinLength != null && !isNaN(formControl.MinLength))
            validations.push(Validators.minLength(formControl.MinLength));
        if (formControl.ControlType == 'NumberBox') {
            if (formControl.Max != null && !isNaN(formControl.Max) && formControl.Max != formControl.Min)
                validations.push(Validators.max(Number(formControl.Max)));
            if (formControl.Min != null && formControl.Max != null && formControl.Min == formControl.Max)
                console.warn(`Se ignoró validación Max dado que es igual que Min, en ${formControl.Name}`);
            if (formControl.Min != null && !isNaN(formControl.Min))
                validations.push(Validators.min(Number(formControl.Min)));
        }
        if (formControl.ControlType == 'EmailBox')
            validations.push(Validators.email);
        if (formControl.Pattern)
            validations.push(Validators.pattern(formControl.Pattern));


        const ctr = new FormControl(null, validations);
        const controlName = (pageName) ? formControl.Name + '_' + pageName : formControl.Name;
        //formGroup[conntrolName] = ctr;
        const controlToHide = this.controlsForHiddenList.FirstOrDefault(x => { return x.Name == formControl.Name });

        const mappingField = this.mappingFields[formControl.Name];
        if (mappingField !== undefined)
            formControl.Value = mappingField;
        if (formControl.GenerateGuid)
            formControl.Value = Guid.create().toString();
        if (formControl.Value && formControl.Value.toString().includes("#"))
            formControl.Value = this.utility.ReplaceTextFromCurrentUserData(formControl.Value);
        ctr.setValue(formControl.Value);

        // setTimeout(() => {
        //     ctr.markAsTouched();

        // }, 1400);


        setTimeout(() => {
            ctr.markAsTouched();

            if (formControl.Disabled)
                ctr.disable();
            if (controlToHide) {
                if (this.utility.EsComboBox(formControl, false)) {
                    formControl.Hidden = true;
                }
                else {
                    const $control = $('#' + controlToHide.Name);
                    const $label = $("label[for='" + controlToHide.Name + "']");
                    $control.hide();
                    $label.hide();
                }
            }
        }, 70);
        this.controls.Add({ control: formControl, controlName: controlName, isVirtual: false })
        return { ctr: ctr, controlName: controlName };
    }

    SetFormControlsFromMetadataPage(idPage: any) {

        if (!idPage)
            return;
        const that = this;
        let create: boolean = false;
        let page: any;
        if (!this.currentMetadataPage) {

            page = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', idPage);
            this.currentMetadataPage = { IdPage: idPage };

        }
        else {

            if (this.currentMetadataPage.IdPage == idPage) {
                return;
            }
            page = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', idPage);

            this.RemoveControls(page.ConfigPage.Controls, page.Nombre);

            this.currentMetadataPage = { IdPage: idPage };

        }

        if (create && page.ConfigPage && page.ConfigPage.Controls && page.ConfigPage.Controls.length > 0) {
            this.AddControls(page.ConfigPage.Controls, page.Nombre);

        }
    }

    AddControls(controls: Array<any>, pageName?: string, searcheable?: any) {

        const that = this;
        if (controls && controls.length > 0) {
            controls.forEach(formControl => {
                if (formControl.Activo == true && !formControl.ConfigurationOnly) {
                    if (this.utility.EsControlFormulario(formControl)) {

                        if (!searcheable || (searcheable && formControl.Searcheable)) {
                            const controlName = (pageName) ? formControl.Name + '_' + pageName : formControl.Name;
                            // 
                            this.SetControlMetadata(formControl, controlName, false);

                            const ctr = this.GetFormControl(formControl, pageName);
                            formControl.ModelContainer = 'modeloMetadata';
                            // if (formControl.Activo == true) {

                            this.form.addControl(ctr.controlName, ctr.ctr);
                        }

                    }
                    else {
                        if (formControl.Controls && formControl.Controls.length > 0) {
                            if (formControl.ControlType === 'Pagina') {
                                let pagina = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', formControl.IdPagina);
                                this.AddControls(pagina.Controls, pagina.Nombre)
                            }
                            else
                                this.AddControls(formControl.Controls)
                        }
                    }
                }
            });
        }
    }
    RemoveControls(controls: Array<any>, pageName?: string) {

        const that = this;
        if (controls && controls.length > 0) {
            controls.forEach(formControl => {

                if (this.utility.EsControlFormulario(formControl)) {

                    const controlName = (pageName) ? formControl.Name + '_' + pageName : formControl.Name;
                    const ctr = this.form.controls[controlName];
                    if (ctr)
                        this.form.removeControl(controlName);
                    /*                     const ctr = this.modelo.modeloMetadata.Items.First(x => { return x.Name == controlName });
                                        if (ctr)
                                            this.modelo.modeloMetadata.Items.Remove(ctr); */

                    const _ctr = this.controls.FirstOrDefault(x => { return x.controlName == controlName });
                    if (_ctr)
                        this.controls.Remove(_ctr);

                }
                else {
                    if (formControl.Controls && formControl.Controls.length > 0) {
                        if (formControl.ControlType === 'Pagina') {
                            let pagina = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', formControl.IdPagina);
                            this.RemoveControls(pagina.Controls, pagina.Nombre)
                        }
                        else
                            this.RemoveControls(formControl.Controls)
                    }
                }
            });
        }
    }

    SetControlMetadata(formControl: any, name: string, isFromControl: Boolean) {
        // 

        if (this.modelo.modeloMetadata) {
            const item = this.modelo.modeloMetadata.Items.FirstOrDefault(x => { return x.Name == name });
            if (item) {
                item.Label = formControl.Label;
                item.DataType = formControl.DataType;
                if (!isFromControl)
                    formControl.Value = item.Value;
                else {
                    item.Value = formControl.Value;
                    item.StrValue = formControl.Value;
                }
            }
            else
                this.modelo.modeloMetadata.Items.Add(this.utility.GetMetadataItemModel(formControl));
        }
    }

    SetFormControl(formGroup: any, controls: Array<any>, pageName?: string) {
        const that = this;
        if (controls && controls.length > 0) {

            controls.forEach(formControl => {


                // ADICION MG 21/10/2020 FUNCIONALIDAD DE PERMISOS POR CONTROL
                // O CONFIGURACION POR PERMISOS
                if (formControl.Permissions && formControl.Permissions.length > 0) {
                    if (!this.utility.TienePemisoUsuarioEnObjeto(new List<any>(formControl.Permissions)))
                        formControl.Activo = false;
                }
                if (formControl.Configs && formControl.Configs.length > 0) {
                    let _config: any = null;
                    formControl.Configs.forEach(config => {

                        if (!_config) {
                            if (this.utility.TienePemisoUsuarioEnObjeto(new List<any>(config.Permissions)))
                                _config = config;
                        }
                    });
                    if (_config) {

                        Object.keys(_config).forEach(key => {
                            formControl[key] = _config[key];
                        });

                    }
                }
                //////////////////////////////////////////////////////////////////

                if (formControl.Activo == true && !formControl.ConfigurationOnly) {
                    if (this.utility.EsControlFormulario(formControl)) {

                        const ctr = this.GetFormControl(formControl, pageName);
                        formGroup[ctr.controlName] = ctr.ctr;


                        this.utility.SetControlConfig(formControl, this.configs);

                        if (formControl.SearchTable) {
                            formControl.SearchTableConfig = this.Pagina.ConfigPage.HiddenControls.find(x => { return x.Name == formControl.SearchTable })
                            formControl.GetConfigPermisos = () => { return this.permissionsConfig };
                        }


                        if (formControl.ModelContainer == 'modeloMetadata') {
                            // 
                            this.SetControlMetadata(formControl, formControl.Name, false);
                        }

                    }
                    else {
                        if (formControl.Controls && formControl.Controls.length > 0) {
                            if (formControl.ControlType === 'Pagina') {
                                let pagina = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', formControl.IdPagina);
                                this.SetFormControl(formGroup, pagina.Controls, pagina.Nombre)
                            }
                            else if (this.utility.EsContenedorControles(formControl))
                                this.SetFormControl(formGroup, formControl.Controls)
                        }
                        else if (formControl.ModelFields) {

                            const controlName = (pageName) ? formControl.Name + '_' + pageName : formControl.Name;

                            this.controls.Add({ control: formControl, controlName: controlName, isVirtual: true })
                        }
                    }
                }
            });
        }
    }

}
