import { AfterViewInit, Component, Input, OnInit } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { Guid } from "guid-typescript";
import { ScanDocumentsService } from "src/app/services/scan-documents.service";
import { Utilities } from "src/app/helpers/utilities";

declare let $ : any;
@Component({
    selector: "app-scan-documents",
    templateUrl: "./scan-documents.component.html",
    styleUrls: ["./scan-documents.component.css"]
})
export class ScanDocumentsComponent implements OnInit, AfterViewInit {

  
  @Input() config: any;
  @Input() port = "8181";
  @Input() name: string = "ScanDocuments_" + Guid.create().toString();
  selDiv: any;
  scanFiles = [];
  ws: WebSocket;
  isOpen = false;
  indexFile = 0;
  componentName = "ScanDocumentsComponent";

  scanDocumentsService:ScanDocumentsService = null;
  constructor(public utility: Utilities, public sanitizer: DomSanitizer) { 

   
  }

  ngOnInit() {

      if (this.config) {
          this.port = (this.config.Port) ? this.config.Port : this.port;
          this.name = (this.config.Name) ? this.config.Name : this.name;
      }
      this.scanDocumentsService = new ScanDocumentsService(this.utility, this.port,true,this.scanFiles);
  }
  ngAfterViewInit(): void {


      // this.selDiv = $("#selectedFiles");
      // $("body").on("click", ".selFile", (e) => { this.EditFiles(e) });
      this.scanDocumentsService.StartWebSocekt();
  }


  // StartWebSocekt() {
  //   this.indexFile = 0;

  //   try {
  //     let wsImpl = window['WebSocket'] || window['MozWebSocket'];
  //     const url = `ws://localhost:${this.port}/'`;
  //     this.ws = new wsImpl(url);
  //     this.ws.onmessage = (e) => {
  //       if (typeof e.data === "string") {

  //         this.utility.logger.LogInfoText("Onmessage [string]:" + e.data);
  //       }
  //       else if (e.data instanceof ArrayBuffer) {

  //         this.utility.logger.LogInfoText("Onmessage [ArrayBuffer]:" + e.data);
  //       }
  //       else if (e.data instanceof Blob) {

  //         this.utility.logger.LogInfoText("Onmessage [Blob]:" + e.data.size);
  //         this.indexFile++;

  //         let scanFile: any = e.data;

  //         scanFile.name = "File_" + this.indexFile;

  //         this.scanFiles.push({file:scanFile,src: window.URL.createObjectURL(scanFile)});

  //         //var reader = new FileReader();

  //         // reader.onload = (e: any) => {
          

  //         //   // var html = "<div class=\"col-sm-2 text-center\" style=\"border: 1px solid black; margin-left: 2px;\"><img height=\"200px\" width=\"200px\" src=\"" + e.target.result + "\" data-file='" + scanFile.name + "' class='selFile' title='Click to remove'><br/>" + this.indexFile + "</div>";
  //         //   // this.selDiv.append(html);

  //         // }
  //         // reader.readAsDataURL(scanFile);
  //       }
  //     };
  //     this.ws.onopen = () => {
  //       this.utility.logger.LogInfoText("Open :" + url);
  //       this.isOpen = true;
  //     };
  //     this.ws.onerror = (ev: Event) => {

  //       let errorScaner = "Error en Lectura Escaner";

  //       this.utility.VerModalDanger({ titulo: "Error", descripcion: errorScaner });
  //       this.utility.logger.LogError(ev, "Escaner");

  //     };
  //     this.ws.onclose = () => {
  //       this.isOpen = false;
  //       this.utility.logger.LogInfoText("Close :" + url);
  //       $('.dalert').modal('show');
  //     };

  //   }
  //   catch (error) {
  //     this.utility.VerModalError("Error al inicializar conexion");
  //     this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'StartWebSocekt' });
  //   }
  // }

  // ScanImage() {

  //   try {
  //     const operacion = { Op: "1100", IdUsuario: (StaticData.Usuario) ? StaticData.Usuario.IdUsuario : -1 };
  //     this.ws.send(JSON.stringify(operacion));

  //   }
  //   catch (error) {
  //     this.utility.VerModalError("Error al Escanear");
  //     this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'ScanImage' });
  //   }
  // };


  GetSanitizer(image){
      return  this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  ConvertSize(fileSize) {

      // return fileSize < 1024000
      //   ? (fileSize / 1024).toFixed(2) + " KB"
      //   : (fileSize / 1024000).toFixed(2) + " MB";

      return this.scanDocumentsService.ConvertSize(fileSize);
  }
  // EditFiles(e) {
  //   var file = $(e.currentTarget).data("file");
  //   for (var i = 0; i < this.scanFiles.length; i++) {
  //     if (this.scanFiles[i].name === file) {
  //       $('.scandetail').modal('show');
  //       let c: any = document.getElementById("ModalFileViewer");
  //       var ctx = c.getContext("2d");
  //       var img = new Image();
  //       img.src = window.URL.createObjectURL(this.scanFiles[i]);
  //       img.onload = () => {
  //         c.width = img.width;
  //         c.height = img.height;
  //         ctx.drawImage(img, 0, 0, img.width, img.height);
  //       }
  //       break;

  //     }
  //   }
  // };
  ViewscanFiles(file) {
      
      // $('.scandetail').modal('show');
      // let c: any = document.getElementById("ModalFileViewer");
      // var ctx = c.getContext("2d");
      // var img = new Image();
      // img.src =file.src;// window.URL.createObjectURL(this.scanFiles[i]);
      // img.onload = () => {
      //   c.width = img.width;
      //   c.height = img.height;
      //   ctx.drawImage(img, 0, 0, img.width, img.height);
      // }
      this.scanDocumentsService.ViewscanFiles(file);

  }
  RemovescanFiles(i) {
      //this.scanFiles.splice(i, 1);
      this.scanDocumentsService.RemovescanFiles(i);

  }
}
