import { Pipe, PipeTransform } from '@angular/core';
import { stringify } from 'querystring';

@Pipe({
  name: 'statusFileUploaderTusSpa'
})
export class StatusFileUploaderTusSpaPipe implements PipeTransform {

  transform(value: string): string {

    value = value.toUpperCase();

    switch (value) {
      case 'COMPLETE': {
        console.log(value);
        return 'Completado';
      }
      case 'UPLOADING': {
        console.log(value);
        return 'Subiendo';
        break;
      }
      case 'RETRY': {
        console.log(value);
        return 'Reintentando';
        break;
      }
      case 'ERROR': {
        console.log(value);
        return 'Error';
        break;
      }
      default: {
        console.log(value);
        return ' ';
      }
    }
  }

}
