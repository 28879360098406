import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control'

@Component({
  selector: 'app-text-area',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true
    }
  ],
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent extends BaseControl implements OnInit, ControlValueAccessor {

  /*  writeValue(value) {
 
     this.modelo = value;
     this.SetFoco();
   } */


  public ConteoCaracteres() {

  
    if (this.config && this.control)
      return this.config.MaxLength - this.control.value.length;
    else
      return '';
  }

}

