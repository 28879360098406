import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods, DataTableAjaxOrderTypes, TypesUseForm, ControlActions, TipoParaPermisos, TipoSeguridad } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { ExportImportConfig, ExportImportItemConfig } from '../../../models/rule-engine/rules-models';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { UserSettingsModelComponent } from '../../rule-engine/info-project/user-settings-model/user-settings-model.component';
import { AdminPermisosServidorComponent } from '../../admin-permisos-servidor/admin-permisos-servidor.component';
import { List } from '../../../../assets/linqts/compilado/index';
import { StaticData } from "../../../helpers/static-data";

@Component({
  selector: 'app-rtabla-retencion-documental',
  templateUrl: './rtabla-retencion-documental.component.html',
  styleUrls: ['./rtabla-retencion-documental.component.css']
})
export class RtablaRetencionDocumentalComponent extends GeneralFormComponent implements OnInit {

  adminPermisosComponent: AdminPermisosServidorComponent;
  BusquedaFiltroTRD: any;
  ExportarXmlTRD: any;
  GridViewFiltroTRD: any;
  BotonExportar: any;
  IdAreaEmpresaExport: any;
  ErrorExportar: any;
  RolesPermitidos = new List<any>([]);
  TipoSeguridad: any;
  PermisosTipoSeguridadTRD: any;
  @Output() SetTipoSeguridadEmitter = new EventEmitter<any>();
  public OnInitEnd() {
    
    this.BusquedaFiltroTRD = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "BusquedaFiltroTRD"; });
    this.BotonExportar = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "BotonExportar"; });
    this.TipoSeguridad = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoSeguridadDocumento"; });
    //this.ErrorExportar = this.Pagina.Exportar;//this.utility.TienePemisoUsuario(this.permissionsConfig, this.modelo.modelo, 'Exportar');    

    this.PermisosTipoSeguridadTRD = StaticData.GeneralConfig.ConfigValidacionExpediente.TipoSeguridadPorPermisos;
    if (this.PermisosTipoSeguridadTRD) {

      this.TipoSeguridad.Disabled = true;
      this.TipoSeguridad.Readonly = true;

      this.SetModelToControls();
    }
  }

  public ngOnInit() {
    super.ngOnInit();
  }
// cambio 30/08/2023 Yair Romero - se comento la linea de codigo ya que al dar editar no mostraba el tipo documental y la subserie
  //public OnDataTableEditHandler(event) {
   // this.modelo.modeloVirtual.IdTipoSeguridadOriginal = this.modelo.modelo.IdTipoSeguridadDocumento;
    //this.SetModelToControls();
  //}

  public OnActionEventHandler(event) {
    
    //debugger;
    if(event.Action){
      if(event.Action == "ClearModel"){
        //debugger;
       /* this.modelo.modelo.IdSubSerie = null;
        this.modelo.modelo.PkValue = null;
        this.SetModelToControls();
        super.Limpiar();
        super.OnDataTableEditHandler(event);*/
        setTimeout(() => { this.componentsContainer.ReloadTable("DataTable_VersionesTablaRetencion", { IdEmpresa: -1, IdTablaRetencionDocumental: -1 }, null, false); }, 1000);
      }
    

    }
    // if(event.componentName == "DragAndDropListComponent" || event.componentName == "DragAndDopMetadataComponent" ){
    //     this.modelo.modelo.ConfiguracionTemp =  this.modelo.modelo.Configuracion;
    // }

    switch (event.ControlType) {
      case 'Button':
        let modeloTemp: any;
        switch (event.Name) {

          case 'BusquedaFiltroTRD': {
            
            this.BotonExportar.Hidden = false;
            this.FiltroTRD();
            break;
          }
          case 'BotonVerTodo': {
            
            this.modelo.modelo.IdAreaEmpresaFiltro = null;
            this.modelo.modelo.IdAreaEmpresaPadre = null;
            this.BusquedaFiltroTRD.Hidden = true;
            this.BotonExportar.Hidden = true;
            this.SetModelToControls();
            this.FiltroTRD();            
            this.configs.ConfigIdAreaEmpresaFiltro.Context.SetItemsComboBox([]);
            break;
          }
          case 'BotonExportar': {
            
            this.GridViewFiltroTRD = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_RTablaRetencionDocumental"; });
            this.GridViewFiltroTRD.QueryConfig.ExportSettings = new ExportImportConfig();

            var date = new Date();
            let day = ("0" + date.getDate()).slice(-2);
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let year = date.getFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();

            this.GridViewFiltroTRD.QueryConfig.ExportSettings.FileName = 'DataTables_RTablaRetencionDocumental_' + day + "_" + month + "_" + year + "-" + hours + "-" + minutes + "-" + seconds;
            var ItemConfig = new ExportImportItemConfig();
            ItemConfig.ResultType= event.config.Actions[0].ResultType;
            ItemConfig.ToType='Xml';

            this.GridViewFiltroTRD.QueryConfig.ExportSettings.ItemsConfig.push(ItemConfig);
            //auditoría
            this.AuditoriaProcesos();
            
            break;
          }
        }
      case 'CommandButton':

        if (event.Option && event.Option.Label == 'Nuevo') {
          this.modelo.modelo.Conservar = true;
          this.SetModelToControls();

        }
        if (event.Option && event.Option.Type == "Edit") {
            this.SetTipoSeguridadEmitter.subscribe(data => {
              if (this.modelo.modelo.IdTipoSeguridadDocumento == this.modelo.modeloVirtual.IdTipoSeguridadOriginal){              
                this.modelo.modelo.IdTipoSeguridadDocumento = this.modelo.modeloVirtual.IdTipoSeguridadOriginal;
              }
            }
            );
        }  
        if (StaticData.GeneralConfig.ConfigValidacionExpediente.TipoSeguridadPorPermisos && !this.IsEdit) {
          this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;
          this.SetModelToControls();
        }
        super.OnActionEventHandler(event);
        break;

      default:

        super.OnActionEventHandler(event);
        break;
    }
  }

  public AddComponentInstanceHandler(event) {
    if (event instanceof AdminPermisosServidorComponent) {
      this.adminPermisosComponent = event;
      this.adminPermisosComponent.isModelContainer = true;
      this.adminPermisosComponent.onActionEventEmitter.subscribe(data => {
        this.SetTipoSeguridad(data.modelo)
      });
      this.AddComponentInstanceHandlerInGeneral(event);
    }
    else {
      super.AddComponentInstanceHandler(event);
    }
  }


  public AuditoriaProcesos() {
    // this.InicializaAuditoria = true;
    //
    //const subscription = this.formEvent.subscribe({
      //next: dataEvent => {
        
        this.ErrorExportar = this.Pagina.Exportar;
          if(this.ErrorExportar == true){
            this.ErrorExportar = null;
          }else{
            this.ErrorExportar = 'Message de Error: No posee permisos para Exportar XML';
          }

          let obj = this.utility.GetApiModel('CrearAuditoriaExportarTRD', null)
          obj.QueryConfig.Command = 'CrearAuditoriaExportarTRD';
          obj.QueryConfig.IsSp = true;
          if(this.modelo.modelo.IdAreaEmpresaFiltro == null){
            this.modelo.modelo.IdAreaEmpresaFiltro = this.IdAreaEmpresaExport;
          }
          obj.QueryConfig.Entity = {
            IdEmpresa: -1,
            IdRegistro: this.modelo.modelo.IdAreaEmpresaFiltro,
            UsuarioCreacion: StaticData.Usuario.Email,
            IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
            Error: this.ErrorExportar,
            Maquina: -1,
            Descripcion: 'Exportación de XML en Área Empresa con Id: ' + this.modelo.modelo.IdAreaEmpresaFiltro,
            IdSerie: null, //MySQL parameter Null
            OutPutQuery: null //MySQL parameter Null
          };
          let info = {
            componentName: this.componentName, controlName: 'CrearAuditoriaExportarTRD',
            processMessage: 'Auditoria TRD......'
          };
          this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
            
          }).catch(err => { })//.finally(() => subscription.unsubscribe())
          
        //}        
      
    //})
  }

  public FiltroTRD() {

    try {
      
      this.SetProcesando(true);
      this.GridViewFiltroTRD = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_RTablaRetencionDocumental"; });
      if (this.GridViewFiltroTRD) {
        this.GridViewFiltroTRD.QueryConfig.Entity = {
          IdAreaEmpresa: this.modelo.modelo.IdAreaEmpresaFiltro
        };
        this.IdAreaEmpresaExport = this.modelo.modelo.IdAreaEmpresaFiltro;
        this.GridViewFiltroTRD.cargarGrilla = true;
        this.GridViewFiltroTRD._CargarGrilla(true);
      }
      // fin
    }
    catch (error) {
      
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    switch (controlName) {
      case "IdAreaEmpresaFiltro": {
        if (item) {
          this.BusquedaFiltroTRD.Hidden = false;
          this.SetModelToControls();
        }
        break;
        
      }
      case "IdSerie":{
        
          
          
            this.modelo.modelo.IdSubSerie = null;
            this.modelo.modelo.Procedimiento= null;
            this.modelo.modelo.TiempoAnos = null;
            this.modelo.modelo.TiempoAnosAC = null;

            this.modelo.modelo.Conservar = true;
            this.modelo.modelo.MedioTecnico = false;
            this.modelo.modelo.Eliminar = false;
            this.modelo.modelo.Seleccionar = false;

            this.SetModelToControls();
            break;
              
      
      }
    }
    super.OnSelectedComboBox(item, controlName, config);
  }
  public SetTipoSeguridad(modelo: any) {

    let LtsPermisos = modelo.modelo.ListaPermisos;

    let Privado = false;
    let Publico = false;
    let Reservado = false;

    const permisosUsuario = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.Usuario);
    const permisosRol = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.Role);
    const permisosArea = LtsPermisos._elements.filter(element => element.Tipo === TipoParaPermisos.AreaEmpresa);

    if (LtsPermisos.Count() == 1) {

      if (permisosUsuario.find(element => element.Nombre == StaticData.Usuario.Email) != undefined|| 
          permisosRol.find(element => element.Nombre == StaticData.Usuario.Role) != undefined||
          permisosArea.find(element => element.Nombre == StaticData.Usuario.AreaEmpresa)!= undefined) {
          Privado = true;
      }
      else if(permisosUsuario.length > 0){
        if(permisosUsuario.find(element => element.AreaEmpresa != StaticData.Usuario.AreaEmpresa) != undefined){
          Reservado = true;
        }else{
          Privado = true;
        }
        
      }
      else{
        Reservado = true;
      }
      
    } else if (LtsPermisos.Count() > 1) {

      

      if (permisosRol.filter(element => element.Nombre != StaticData.Usuario.Role).length > 1 || 
          permisosArea.filter(element => element.Nombre != StaticData.Usuario.AreaEmpresa).length > 1) {
        Publico =true;        
      }else if(permisosRol.filter(element => element.Nombre != StaticData.Usuario.Role).length == 1 || 
      permisosArea.filter(element => element.Nombre != StaticData.Usuario.AreaEmpresa).length == 1){
        Reservado = true;
      }else{
        let cantPermisosUsuario = permisosUsuario.filter(element => element.AreaEmpresa != StaticData.Usuario.AreaEmpresa);
        if(cantPermisosUsuario != undefined && cantPermisosUsuario.length > 1){
          Publico = true;        
        }else if(cantPermisosUsuario != undefined && cantPermisosUsuario.length == 1){
          Reservado = true;
        }else{
          Privado = true;
        }
      }
    }else{
      Privado = true;
    }

    /*
      TipoSeguridad.Publico = Publica
      TipoSeguridad.Reservado = Reservada
      TipoSeguridad.Privado = Clasificada
    */   
    if (Publico)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Publico;
    else if (Reservado)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Reservado;
    else if (Privado)
      this.modelo.modelo.IdTipoSeguridadDocumento = TipoSeguridad.Privado;

    this.SetModelToControls();
    this.SetTipoSeguridadEmitter.emit();
  }

  //Lina Figueredo - Solución Bug 1197
  //Se agrega el metodo limpiar, para que al momento de guardar un nuevo registro o limpiar el panel de nueva TRD, se asigne el parametro de tipo de seguridad.
  public Limpiar() {
    super.Limpiar();
    this.SetTipoSeguridad(this.modelo);
  }

  // _Guardar(form: FormGroup, configButtons?: any) {
	// 	// if(this.modelo.modelo.ConfiguracionTemp || this.modelo.modelo.ConfiguracionTemp != null ){
	// 	// 	this.modelo.modelo.Configuracion = this.modelo.modelo.ConfiguracionTemp
	// 	//   }
    

	// 	super._Guardar(form, configButtons);
	// }

}
