import { Component, EventEmitter, OnInit } from "@angular/core";
import {
  ControlActions,
  ControllerMethods,
  FormActionsThroughEmitters,
  GeneralPageControlTypes,
  OperacionesDeSolicitudes,
  TipoObjetoParaPermisos,
} from "src/app/models/general.enum";

import { CheckBoxRComponent } from "src/app/components/controls/check-box-r/check-box-r.component";
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from "src/app/components/controls/file-uploader/file-uploader.component";
import { FormControlName, FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { SolicitudCommonComponent } from "../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { validateContact } from "../../../contacto/contacto.component";

@Component({
  selector: "app-solicitud-radicado-entrada",
  templateUrl: "./solicitud-radicado-entrada.component.html",
  styleUrls: ["./solicitud-radicado-entrada.component.css"],
})
export class SolicitudRadicadoEntradaComponent extends SolicitudCommonComponent { //
  ResponsableManual: any;
  ctrResponsableManual: any;
  ctrRIdTemaSolicitud: any;

  GridViewAsignarUsuario: any;
  GrillaReasignarUsuario: any;

  //PruebasEntradaRadicado:any;

  responsablePorTematica: boolean = true;
  responsableTematica: number;

  IdTipoRadicacion: any;
  ObservacionesAnulacion: any;
  tipoSolicitud = "el Radicado de Entrada";
  GrillaAnexos: any;
  GrillaComentarios: any;
  openModalRadicadoEntrada: boolean;

  public OnInitEnd() {

    // if(this.name == "SolicitudesAnulacionesEntrada"){
    //     setTimeout(() => {
    //         document.getElementById("contentFile").style.display = "none";
    //     }, 100);
    // }
    this.postSaveEmitter.subscribe(event => {      
        setTimeout(() => { 
          super.Limpiar()
          this.MensajeSeleccionarContacto.Hidden = false;
          this.SetModelToControls();
        }, 2000);
		});
    super.OnInitEnd();

  }

  ngAfterViewInit() {
    this.ctrResponsableManual = this.form.controls[
      SolicitudRadicadoEntradaComponent.Controls.ResponsableManual
    ];
    // this.ctrRIdTemaSolicitud = this.form.controls[SolicitudRadicadoEntradaComponent.Controls.IdTemaSolicitud];
    this.ctrRIdTemaSolicitud = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == "IdTemaSolicitud";
      });

    if (this.ctrResponsableManual) {
      let beforeValue;
      this.ctrResponsableManual.valueChanges.subscribe((value) => {

        if (value == beforeValue) {
          return;
        }
        this.configs.ConfigIdTemaSolicitud.Context.CargarItemsComboBoxRemoveNotLoad(
          null,
          true,
          null,
          true,
          false
        );
        this.GridViewAsignarUsuario = this.componentsContainer.Tables.filter(
          (x) =>
            x.name ===
            SolicitudRadicadoEntradaComponent.Controls.GrillaAsignarUsuario
        )[0];
        this.GridViewAsignarUsuario.config.Hidden = value;
        this.GridViewAsignarUsuario.cargarGrilla = !value;
        if (!value) {
          this.GridViewAsignarUsuario.ReCargarGrilla();
          this.modelo.modelo.IdTemaSolicitud = null;
          this.configs.ConfigIdTemaSolicitud.Context.items = new Array();
        }
        //debugger;
        this.responsablePorTematica = value;
        beforeValue = value;
        this.modelo.modeloVirtual.ResponsableManual = value;

        const actionRequired = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: [],
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: [SolicitudCommonComponent.Controls.IdTemaSolicitud],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: value == true ? "true" : "false",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty],
        };
        const context = this;
        this.helperActions.SetControlsValue(actionRequired, context);

        // setTimeout(function () {
        //   this.helperActions.SetControlsValue(actionRequired, context);
        // }, 1500);

        const actionEnabled = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: [],
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: [SolicitudCommonComponent.Controls.IdTemaSolicitud],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "enabled",
          PropertyValue: value == true ? "true" : "false",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty],
        };
        const contextcurrent = this;
        this.helperActions.SetControlsValue(actionEnabled, contextcurrent);
        super.SetModelToControls();
      });
    }
    super.ngAfterViewInit();
  }

  VerBuscarRadicadoEntrada() {
    this.openModalRadicadoEntrada = true;
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[2],
      limpiarInternamente: true,
      modelo: this.utility.Clone(this.modelo),
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Relacionar Radicado Entrada";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility
      .OpenWindow(componentName, config, modeloTemp)
      .then((dataResult) => {


        dataResult.componentInstance.parentContext = this; //
         //debugger;
        dataResult.componentInstance.postSeleccionItemEmitter.subscribe((result) => {

          //debugger;
            if(!this.modelo.modeloVirtual["GridRadicadosAsociados"])
            this.modelo.modeloVirtual["GridRadicadosAsociados"] = new Array();

          if (result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("Codigo") &&
            !this.modelo.modeloVirtual["GridRadicadosAsociados"].find(x => { return x.Codigo == result.data.data.findItem.Codigo; }))
          {
            this.modelo.modeloVirtual["GridRadicadosAsociados"].push({ Id: result.data.data.findItem.IdSolicitud, Codigo: result.data.data.findItem.Codigo });
            //this.PruebasEntradaRadicado = this.modelo.modeloVirtual["GridRadicadosAsociados"];
          }
          //{"data":{"findItem":{"IdSolicitud":317
          /*
          const CodigoRadicadoAsociado =
            result &&
              result.data &&
              result.data.data &&
              result.data.data.findItem &&
              result.data.data.findItem.hasOwnProperty("Codigo")
              ? result.data.data.findItem.Codigo
              : null;

          const IdSolicitud =
            result &&
              result.data &&
              result.data.data &&
              result.data.data.findItem &&
              result.data.data.findItem.hasOwnProperty("IdSolicitud")
              ? result.data.data.findItem.IdSolicitud
              : null;

          this.modelo.modeloVirtual.CodigoRadicadoAsociado =
            CodigoRadicadoAsociado ||
            dataResult.componentInstance.modelo.Codigo;
          this.modelo.modelo.IdSolicitudRadicadoEntradaAsociado =
            IdSolicitud || dataResult.componentInstance.modelo.IdSolicitud;
          this.BtnDetalleSolicitud.Hidden = false;
          */
          this.SetModelToControls();
        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(
          (result) => {
            if (result.Action === FormActionsThroughEmitters.ClearModel) {
              //debugger;
              this.modelo.modeloVirtual.CodigoRadicadoAsociado = null;
              this.modelo.modelo.IdSolicitudRadicadoEntradaAsociado = null;
              this.SetModelToControls();
            }
          }
        );

        dataResult.componentInstance.loadedGridEmitter.subscribe(
          (result) => { }
        );

        dataResult.onClosePromise.then((dataResultOnClose) => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
          this.openModalRadicadoEntrada = false;
        });
      });
  }
  public PostNgAfterViewInit() {
    super.PostNgAfterViewInit();
    this.IdTipoRadicacion = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.IdTipoRadicacion;
      });
    this.ObservacionesAnulacion = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.ObservacionesAnulacion;
      });
    this.ResponsableManual = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.ResponsableManual;
      });
    this.GrillaAnexos = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.GrillaAnexos;
      });
    this.GrillaComentarios = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name === "DataTable_SolicitudEntradaComentario";
      });
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    switch (controlName) {
      case SolicitudCommonComponent.Controls.IdTemaSolicitud: {
        this.SetControlsValueDocument([
          SolicitudCommonComponent.Controls.ResponsableManual,
        ], "enabled", item ? "false" : "true");
        if (item) {
          /*if (item.Configuracion || item.IdUsuarioResponsable) {
            const configUsuarioResp = JSON.parse(item.Configuracion);
            if(item.Configuracion == null){
              this.modelo.modelo.IdUsuarioResponsable = item.IdUsuarioResponsable;
            }
            else if(item.Configuracion && configUsuarioResp.length == 0){
              this.modelo.modelo.IdUsuarioResponsable = item.IdUsuarioResponsable;

            }else{
            this.modelo.modelo.IdUsuarioResponsable = configUsuarioResp[0].Id;
            }*/
          //debugger;
          if (item.IdUsuarioResponsable) {
            this.modelo.modelo.IdUsuarioResponsable = item.IdUsuarioResponsable;
          } else
            this.utility.VerModalError(
              "La temática seleccionada no posee responsable"
            );
        }
        if (!item)
          break;
      }
      default: {
        super.OnSelectedComboBox(item, controlName, config);
        break;
      }
    }
  }

  public OnActionEventHandler(event) {
    debugger;
    if(event.Action === FormActionsThroughEmitters.MultipleSelection &&
      event.Table === "GrillaUsuarioCopia")
      {
        if(event.Items && event.Items.length > 0)
          this.modelo.modeloVirtual.validaCopia = event.Items
       
        this.calledCommandButton = "Copiar"
        this.SetModelToControls();   
      }
    else if ((event.Action === FormActionsThroughEmitters.ClearModel &&
         event.Table === SolicitudRadicadoEntradaComponent.Controls.GrillaAsignarUsuario) ||
         event.Table === SolicitudRadicadoEntradaComponent.Controls.GrillaUsuarioConcepto
    ) {
      return;
    }
    if(event.Action === FormActionsThroughEmitters.ClearModel && event.Table == "GridViewRadicadosEntradaAsignados")
       this.calledCommandButton = null
      
    this.SetControlsValueDocument(["ObligatorioConcepto"],"required",  "false");
    this.SetControlsValueDocument(["ObligatorioReasignar"],"required","false");
    this.SetControlsValueDocument(["validaCopia"],"required",  "false");
    this.SetControlsValueDocument(["Observaciones"],"required", "false");
    if(this.ObservacionesAnulacion)this.ObservacionesAnulacion.required = false;
    this.SetModelToControls();
    switch (event.ControlType) {
      case GeneralPageControlTypes.Button: {
        switch (event.Name) {
          case SolicitudCommonComponent.Controls.BtnContactos: {
            if(!this.modalOpenContacto)
              this.VerBuscarContacto();
            else
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de contactos abierta." });
            break;
          }
          case SolicitudCommonComponent.Controls.BtnVincular:{
            this.modelo.modelo.IdEstadoSolicitud = 12
            var OperacionSolicitud = this.modelo.modelo.DataAdd
            if (this.modelo.selectedItems.length > 0){
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdExpediente',this.modelo.modelo.IdExpediente);
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdTipoDocumental', this.modelo.modelo.IdTipoDocumental);
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdEstadoSolicitud', this.modelo.modelo.IdEstadoSolicitud);
              this.modelo.selectedItems.forEach((objeto) => {
                {
                  this.modelo.modelo = objeto
                  this.modelo.modelo.DataAdd = OperacionSolicitud
            
            let obj: any = null;
            let _isEdit = this.IsEdit;
            // const that = this;
            let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
            obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
            this.baseService.InsertUpdate(obj, null, {
              componentName: this.componentName + 'Controller',
              method: 'Guardar', processMessage: processMessage
            },
              false, this.componentName, true, true)
                  //super.SetModelToControls();
                    //this._Guardar;
                    //super._Guardar;
                }
                
                //if(objeto.)
              })
            this.ReCargarGrilla()
            
            break;
            }
          }
          case SolicitudCommonComponent.Controls.BtnAsociarSolicitud: {
            if(!this.openModalRadicadoEntrada)
              this.VerBuscarRadicadoEntrada();
            else
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de Relacionar Radicado Entrada abierta." });

            break;
          }
          case SolicitudCommonComponent.Controls.BtnDetalleSolicitud: {

            let obj = this.utility.GetApiModel("GetDetalleSolicitud", null);
            obj.QueryConfig = {
              Command: "ConsultaDetalleEntradaAsociada",
              IsSp: true,
              Entity: {
                IdEmpresa: -1,
                IdSolicitudRadicadoEntradaAsociado: this.modelo.modelo
                  .IdSolicitudRadicadoEntradaAsociado,
              },
            };

            let info = {
              componentName: this.componentName,
              controlName: "GetDetalleSolicitud",
              processMessage: "Cargando Detalle de la Solicitud......",
            };

            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {

                this.modeloDetalle = data.Data[0];
                if (
                  this.modeloDetalle.Anexos &&
                  this.modeloDetalle.Anexos != ""
                ) {
                  const Anexos = JSON.parse(this.modeloDetalle.Anexos);
                  let valorAnexos = "";
                  Anexos.forEach((anexo) => {
                    valorAnexos += anexo.Nombre + ": " + anexo.Cantidad + "\n";
                  });
                  this.modeloDetalle.Anexos = valorAnexos;
                }
                if (this.componentsContainer.FormsDetails.length > 0) {

                  let FormDetalleSolicitud = this.componentsContainer.Find(
                    "FormDetalleSolicitud"
                  );
                  FormDetalleSolicitud.modelo = this.modeloDetalle;
                }
              })
              .catch((err) => { });
            break;
          }
          case SolicitudCommonComponent.Controls.BtnGuardarComentario: {
            var lenComentario = this.modelo.modeloVirtual.Comentario;
            if (lenComentario != null && lenComentario.length > 0) {
              if (this.modelo.modelo.DataAdd) {
                this.modelo.modelo.DataAdd[
                  "TextoComentario"
                ] = this.modelo.modeloVirtual.Comentario;
                this.modelo.modelo.DataAdd["GuardaComentario"] = "true";
              } else {
                this.modelo.modelo.DataAdd = {
                  TextoComentario: this.modelo.modeloVirtual.Comentario,
                  GuardaComentario: "true",
                };
              }
              this.utility.VerModalConfirmacionGuardar().then((modelo) => {
                if (modelo.dialogResult == true) {
                  this.SetModelToControls();

                  this.modelo.modelo.Token = StaticData.Usuario.Token;
                  this.modelo.modelo.AppKey = StaticData.AppKey;
                  this.modelo.modelo.PkValue = this.modelo.modelo.IdSolicitudRadicadoEntrada;

                  let obj = this.utility.GetApiModel(
                    "GuardarComentario",
                    this.componentName,
                    null,
                    null,
                    this.modelo.modelo
                  );

                  this.baseService
                    .GuardarComentario(
                      obj,
                      null,
                      {
                        componentName: "SolicitudRadicadoEntradaComponent",
                        method: "GuardarComentario",
                        processMessage: "Procesando credenciales....",
                      },
                      "TSolicitudRadicadoEntrada",
                      false,
                      true
                    )
                    .then((data) => {
                      this.modelo.modeloVirtual.Comentario = null;
                      this.SetModelToControls();

                      const tbGrillaComentario = this.componentsContainer.Tables.find(
                        (x) => {
                          return (
                            x.name == "DataTable_SolicitudEntradaComentario"
                          );
                        }
                      );
                      tbGrillaComentario.cargarGrilla = true;
                      tbGrillaComentario.ReCargarGrilla();

                      this.utility.VerModalOk(
                        "Se agregó comentario al radicado de entrada: " +
                        "\n\r" +
                        this.modelo.modelo.Codigo
                      );
                    });
                }
              });
            } else {
              this.utility.VerModalDanger({
                titulo: "Valor Requerido",
                descripcion: "El campo comentario es requerido.",
              });
            }
          }
        }
        break;
      }
      case GeneralPageControlTypes.CommandButton: {
        this.calledCommandButton = event.Option.Name;

        if (this.IdTipoDocumental) {
          let validacionIncluirEnExpediente = event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente ||event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpedienteMasivo ? "true" : "false";
          this.SetControlsValueDocument([SolicitudCommonComponent.Controls.IdTipoDocumental], "required", validacionIncluirEnExpediente)
          super.SetModelToControls();

          if(event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpedienteMasivo){
            this.BtnVincular.Hidden = false
            this.BtnGuardarVincular.HideSave = true
            super.SetModelToControls();
          }
          if(event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente){
            this.BtnVincular.Hidden = true
            this.BtnGuardarVincular.HideSave = false
            super.SetModelToControls();
          }
        }
        

        switch (event.Option.Name) {
          // case SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente: {
          //   if (this.IdTipoDocumental) {
          //     this.SetControlsValueDocument([SolicitudCommonComponent.Controls.IdTipoDocumental],"required", "false");
          //     super.SetModelToControls();
          //   }
          //   break;
          // }
          case SolicitudCommonComponent.CommandBarActions.Reasignar: {
            this.modelo.modeloVirtual.ObligatorioReasignar = null;
            this.modelo.modelo.IdUsuarioResponsable = null;
            this.modelo.modeloVirtual.ReasignacionJerarquica = StaticData.GeneralConfig.ConfigUI.ReasignacionJerarquica;
            this.modelo.modelo.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
            this.SetControlsValueDocument(["ObligatorioReasignar"],"required", event.Option.Name === "Reasignar" ? "true" : "false");
            super.SetModelToControls();

            var GridName = "GrillaReasignarUsuario";
            var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
            this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

            break;
          }
          case SolicitudCommonComponent.CommandBarActions.Copiar: {
            //this.SetControlsValueDocument(["IdUsuario"],"required", event.Option.Name === "Copiar" ? "true" : "false");
            this.SetControlsValueDocument(["validaCopia"],"required", event.Option.Name === "Copiar" ? "true" : "false");
            super.SetModelToControls();

            var GridName = "GrillaUsuarioCopia";
            var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
            this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

            break;
          }
          case SolicitudCommonComponent.CommandBarActions.SolicitarConcepto: {
            this.modelo.modelo.IdUsuarioResponsable = null;
            this.SetControlsValueDocument(["ObligatorioConcepto"],"required", event.Option.Name === "SolicitarConcepto" ? "true" : "false");
            /**
             * INICIO
             * 03/12/2020 15:58
             * Edward
             * Deshabilitar obligatorio de opción Gestionar Concepto
             */
            this.SetControlsValueDocument(["Observaciones"],"required", "false");
            super.SetModelToControls();
            /**
             * FIN
             */

             var GridName = "GrillaUsuarioConcepto";
             var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
             this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

            break;
          }
          case SolicitudCommonComponent.CommandBarActions.CargarOficio: {
            break;
          }
          case SolicitudCommonComponent.CommandBarActions.SolicitarAnulacion:{
            this.ObservacionesAnulacion.required = true;
            this.SetModelToControls();
          }

          //MP Se comenta porque la validacion del control obligatorio se hace por medio de una regla
          // case SolicitudCommonComponent.CommandBarActions.GestionarConcepto: {
          //   this.SetControlsValueDocument(["ObligatorioConcepto"],"required", event.Option.Name === "SolicitarConcepto" ? "true" : "false");
          //   super.SetModelToControls();
          //   break;
          // }
          default:
            if (
              this.modelo.modelo.IdSolicitudRadicadoEntradaAsociado != null &&
              this.modelo.modelo.IdSolicitudRadicadoEntradaAsociado != ""
            ) {
              if (this.BtnDetalleSolicitud && this.BtnAsociarSolicitud) {
                this.BtnDetalleSolicitud.Hidden = false;
                this.BtnAsociarSolicitud.Hidden = true;
              }
            } else if (this.BtnDetalleSolicitud && this.BtnAsociarSolicitud) {
              this.BtnDetalleSolicitud.Hidden = true;
              this.BtnAsociarSolicitud.Hidden = false;
            }

            if (event.Option.Name === "Detalle") {
              //debugger;
              //SolicitudCommonComponent.PruebasEntradaRadicado = null;
              //this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
              if (this.modelo.modelo.IdUsuarioResponsable !== null) {
                this.SetControlsValueDocument(["ResponsableManual"],"hidden", "true");
              } else {
                this.SetControlsValueDocument(["ResponsableManual"],"hidden", "false");
              }

              if (this.modelo.modelo.IdEstadoSolicitud !== 29){
                this.Oficio.isHidden = false;
              }
              else {
                this.Oficio.isHidden = true;
              }
            }

            if (event.Option.Name === "Nuevo") {
              //debugger;
              //SolicitudCommonComponent.PruebasEntradaRadicado = null;
              if(this.modelo.modeloVirtual.GridRadicadosAsociados){
              this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
            }
            if(!this.modelo.modeloVirtual.GridRadicadosAsociados){
              this.modelo.modeloVirtual.GridRadicadosAsociados =[];
              //this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
            }
              //document.getElementById("AreaEmpresaNombre").style.display = "none";
              //document.getElementById("UsuarioNombre").style.display = "none";
              this.InfoAnexos.Hidden = true;
              this.modelo.modeloVirtual.AnexosRequeridos = "ok";
              this.SetControlsValueDocument(["AreaEmpresaNombre", "UsuarioNombre"],"hidden", event.Option.Name === "Nuevo" ? "true" : "false");
              this.SetControlsValueDocument(["ResponsableManual"],"hidden",  "false");
            }
            //debugger;
            if(this.UsuarioResponsbale){
            this.modelo.modelo.IdUsuarioResponsable = this.UsuarioResponsbale;
            }
            if (this.IdContacto) {
              this.SetControlsValueDocument([SolicitudCommonComponent.Controls.IdContacto],"required",  event.Option.Name ===
              SolicitudCommonComponent.CommandBarActions.Nuevo
                ? "true"
                : "false");
              super.SetModelToControls();
            }
            //Ocultar BtnContactos y Mensaje
            if (this.BtnContactos && this.MensajeSeleccionarContacto) {
              if (
                event.Option.Name ===
                SolicitudCommonComponent.CommandBarActions.VerDetalle
              ) {
                this.BtnContactos.Hidden = true;
                this.MensajeSeleccionarContacto.Hidden = true;
                // document.getElementById("AreaEmpresaNombre").style.display = "block";
                //document.getElementById("UsuarioNombre").style.display = "block";
                this.SetControlsValueDocument(["AreaEmpresaNombre", "UsuarioNombre"],"hidden",  event.Option.Name === "Nuevo" ? "true" : "false");
                if (
                  document.getElementsByClassName(
                    "float-right mb-4 ng-star-inserted"
                  )[0]
                )
                  (document.getElementsByClassName(
                    "float-right mb-4 ng-star-inserted"
                  )[0] as HTMLElement).style.visibility = "hidden";
              } else {
                this.BtnContactos.Hidden = false;
                if(!this.modelo.modelo.IdContacto){
                  this.MensajeSeleccionarContacto.Hidden = false;}
              }
            }
            // if (this.IdTipoDocumental) {
            //   this.SetControlsValueDocument([SolicitudCommonComponent.Controls.IdTipoDocumental],"required",  event.Option.Name ===
            //   SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente
            //     ? "true"
            //     : "false");
            //   super.SetModelToControls();
            // }
            if (this.IdTipoRadicacion) {
              this.SetControlsValueDocument([
                "IdTipoRadicacion",
                //"IdTemaSolicitud",
                // "Asunto",
                "ResponsableManual",
                //"IdPais",
                //"IdDepartamento",
                //"IdCiudad",
                //"Direccion",
                //"Celular",
                //"Telefono",
                //"Ext",
                //"Correo",
                "NumeroFolios",
                // "ComentarioRadicado",
              ],"enabled",  event.Option.Name ===
              SolicitudCommonComponent.CommandBarActions.VerDetalle
                ? "false"
                : "true");

              this.SetControlsValueDocument([
                "Asunto",
                "ComentarioRadicado"
              ],"readonly",  event.Option.Name ===
              SolicitudCommonComponent.CommandBarActions.VerDetalle
                ? "true"
                : "false");

              this.SetControlsValueDocument(["IdTemaSolicitud"],"enabled",  (event.Option.Name ===
                SolicitudCommonComponent.CommandBarActions.VerDetalle || (event.Option.Name ===
                  SolicitudCommonComponent.CommandBarActions.Nuevo && this.modelo.modeloVirtual.ResponsableManual == false))
                  ? "false"
                  : "true");
              super.SetModelToControls();
            }
            debugger;
            if (this.ObservacionesAnulacion) {
              this.SetControlsValueDocument(["ObservacionesAnulacion"],"required",  event.Option.Name ===
              SolicitudCommonComponent.CommandBarActions.SolicitarAnulacion
                ? "true"
                : "false");
                this.SetControlsValueDocument([SolicitudCommonComponent.Controls.IdTemaSolicitud],"required",
                (event.Option.Name === SolicitudCommonComponent.CommandBarActions.Nuevo && this.modelo.modeloVirtual.ResponsableManual == "true")
                  ? "true"
                  : "false");
              this.SetModelToControls();
            }
            if (
              event.Option.Name ===
              SolicitudCommonComponent.CommandBarActions.Responder
            ) {
              if (
                this.modelo.modelo.IdExpediente == null ||
                this.modelo.modelo.IdExpediente == ""
              ) {
                this.utility.VerModalError(
                  "La solicitud no posee un expediente asignado"
                );
                return;
              }
            }
            // else if (event.Option.Label === SolicitudCommonComponent.CommandBarActions.SolicitarConcepto || event.Option.Label === SolicitudCommonComponent.CommandBarActions.Reasignar) {
            //
            //   this.modelo.modelo.IdUsuarioResponsable = null;
            //   this.SetModelToControls();
            // }
            if (
              event.Option.Name ===
              SolicitudCommonComponent.CommandBarActions.Nuevo
            ) {
              //debugger;
              if(this.modelo.modeloVirtual.GridRadicadosAsociados){
              this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
              }

              if(!this.modelo.modeloVirtual.GridRadicadosAsociados){
                this.modelo.modeloVirtual.GridRadicadosAsociados =[];
                //this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
              }
              //Activa BOTON DE TIPO ANEXOS (Adiccionar)
              if (
                document.getElementsByClassName(
                  "float-right mb-4 ng-star-inserted"
                )[0]
              ) {
                (document.getElementsByClassName(
                  "float-right mb-4 ng-star-inserted"
                )[0] as HTMLElement).style.visibility = "visible";
              }
            }
            if (
              event.Option.Name ===
              SolicitudCommonComponent.CommandBarActions.VerDetalle
            ) {
              this.InfoAnexos.Hidden = true;
              this.modelo.modeloVirtual.AnexosRequeridos = "ok";
              //debugger;
              if(this.modelo.selectedItem){
              this.modelo.modeloVirtual.ComentarioRadicado = this.modelo.selectedItem.Comentario;
              }
              this.SetModelToControls();
            }

            break;
        }

        break;
      }
    }

    if (
      event.actionType &&
      event.actionType == "OnLoadedComboBoxExtendableTable"
    ) {

      this.SetControlsValueDocument(["AnexosRequeridos"],"required",   "true");
      this.modelo.modeloVirtual.AnexosRequeridos = null;
      this.InfoAnexos.Hidden = false;
      this.SetModelToControls();
      this.countTipoAnexos = event.data.items.length;
      if (this.modelo.modeloVirtual.GrillaAnexos) {
        var numeroItems = this.modelo.modeloVirtual.GrillaAnexos.length - 1;

        if (event.data.items.length != numeroItems) {
          if (this.modelo.modeloVirtual.GrillaAnexos.length > 1) {
            for (var prop in this.modelo.modeloVirtual.GrillaAnexos) {
              if (Number(prop) < numeroItems) {
                this.errorTipoAnexo = false;
                if (
                  this.modelo.modeloVirtual.GrillaAnexos[prop].Nombre == null
                ) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexos.splice(
                    this.modelo.modeloVirtual.GrillaAnexos.length - 1
                  );
                  this.utility.VerModalDanger({
                    titulo: "Valor Requerido",
                    descripcion: "El campo Tipo anexo es requerido.",
                  });
                  break;
                }
                if (
                  this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad ==
                  null ||
                  !this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad
                ) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexos.splice(
                    this.modelo.modeloVirtual.GrillaAnexos.length - 1
                  );
                  this.utility.VerModalDanger({
                    titulo: "Valor Requerido",
                    descripcion:
                      "El campo Cantidad tiene que ser requerido o mayor a cero",
                  });
                  break;
                }
              }
            }
          }
          // if (!this.errorTipoAnexo) {

          //     this.modelo.modeloVirtual.GrillaAnexos.forEach(datamodelo => {
          //
          //         event.data.items.forEach(element => {
          //             if (datamodelo.TipoAnexo == element.Nombre) {
          //
          //                 const index = event.data.items.findIndex(x => { return x.Nombre == element.Nombre });
          //                 event.data.items.splice(index, 1);
          //             }
          //         });
          //     });
          // }
          if (
            this.countTipoAnexos ==  this.modelo.modeloVirtual.GrillaAnexos.length) {
            //setTimeout(function () {
              if (document.getElementsByClassName("float-right mb-4 ng-star-inserted")[0] != undefined) {
                (document.getElementsByClassName("float-right mb-4 ng-star-inserted")[0] as HTMLElement).style.visibility = "hidden";
              }
            //}, 1500);
          }
        }

        this.modelo.modeloVirtual.GrillaAnexos.forEach((datamodelo) => {

          // if (!this.errorTipoAnexo)
          //   datamodelo.Disabled = true;
          event.data.items.forEach((element) => {
            if (datamodelo.Nombre == element.Nombre) {

              if (!this.errorTipoAnexo) datamodelo.Disabled = true;
              const index = event.data.items.findIndex((x) => {
                return x.Nombre == element.Nombre;
              });
              event.data.items.splice(index, 1);
            }
          });
        });
      }
    } else if (event.actionType == "EliminarItem") {
      if (event.config.Label == "Tipos Anexo") {
        setTimeout(function () {
          if (
            document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0] != undefined
          ) {
            (document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0] as HTMLElement).style.visibility = "visible";
          }
        }, 1500);
      }
      var requeridoAnexosEliminacion = true;
      var requeridoAnexosControl = 1;
      this.modelo.modeloVirtual.GrillaAnexos.forEach((element) => {
        if (!element.Nombre || !element.Cantidad) {
          requeridoAnexosEliminacion = false;
          requeridoAnexosControl = null;
        }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    }
    // else if (event.actionType && event.actionType == "OnClearComboBoxExtendableTable") {
    //   this.utility.VerModalInfo({ "titulo": "Valor Requerido", "descripcion": "Para limpiar el campo elimine el registro desde la opcion eliminar" });
    //   event.data.NotClear = true;
    //   // return;
    // }
    else if (
      event.actionType &&
      event.actionType == "OnSelectedComboBoxExtendableTable"
    ) {

      var requeridoAnexosEliminacion = true;
      var requeridoAnexosControl = 1;
      this.modelo.modeloVirtual.GrillaAnexos.forEach((element) => {
        // if(event.data.items)
        // {
        //   event.data.items.forEach(elementespcificico => {
        //     if (element.Nombre == elementespcificico.Nombre) {
        //
        //       const index = event.data.items.findIndex(x => { return x.Nombre == elementespcificico.Nombre });
        //       event.data.items.splice(index, 1);
        //     }
        //   });
        // }
        if (event.data.item && event.data.item.Nombre) {
          if (element.Nombre == event.data.item.Nombre) {
            if (!element.Cantidad || element.Cantidad < 0) {
              requeridoAnexosEliminacion = false;
              requeridoAnexosControl = null;
            }
          }
        } else {
          requeridoAnexosEliminacion = false;
          requeridoAnexosControl = null;
        }
        // else {
        //   requeridoAnexosEliminacion = false
        //   requeridoAnexosControl = null
        // }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    } else if (
      event.actionType &&
      event.actionType == "OnChangeNumberBoxExtendableTable"
    ) {

      if (
        event.data.valueMetodo &&
        event.data.valueMetodo.Nombre &&
        event.data.valueMetodo.Cantidad &&
        event.data.valueMetodo.Cantidad > 0
      ) {
        this.InfoAnexos.Hidden = true;
        this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      } else {
        this.InfoAnexos.Hidden = false;
        this.modelo.modeloVirtual.AnexosRequeridos = null;
      }
      this.SetModelToControls();
    }

    if (event.Table == "GrillaAsignarExpediente")
      if (event.Action === FormActionsThroughEmitters.ClearModel) {
        this.modelo.modeloVirtual.NombreExpediente = null;
        this.modelo.modeloVirtual.CodigoExpediente = null;
        this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox([], true);
        this.SetModelToControls();
        return;
      }

    if (event.Table == "GrillaUsuarioCopia")
      if (event.Action === FormActionsThroughEmitters.ClearModel) {

        var GridName = "GrillaUsuarioCopia";
        var GridViewUsuarioCopia = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
        GridViewUsuarioCopia.selectedItems = this.modelo.UsuariosCopia;

        this.SetModelToControls();
        return;
      }

    super.OnActionEventHandler(event);
  }
  cambiarValorDeLlave(arr:any, llave: string, nuevoValor: any) {
    arr.forEach((objeto) => {
      for (const key in objeto) {
        if (objeto.hasOwnProperty(key)) {
          if (key == llave ) {
            objeto[llave] = nuevoValor;

          }
        }
      }
      
      //if(objeto.)
    }); // <--- Corregido
  }
  SetUsuarioResponsable() {
    //debugger;
    if (!this.IsEdit) {
      if (this.responsablePorTematica){
      if(!this.modelo.modelo.IdUsuarioResponsable){
        this.modelo.modelo.IdUsuarioResponsable = this.responsableTematica;
        }
      else {
        if (
          this.modelo.modelo.IdUsuarioResponsable == null ||
          this.modelo.modelo.IdUsuarioResponsable == 0 ||
          this.modelo.modelo.IdUsuarioResponsable == ""
        ) {
          this.utility.VerModalError("Debe seleccionar un usuario responsable");
          return;
        }
      }
    }
    else{
      this.UsuarioResponsbale = this.modelo.modelo.IdUsuarioResponsable;
    }
    }
  }

  ConvertAnexos() {
    if (
      this.modelo.modeloVirtual.GrillaAnexos &&
      this.modelo.modeloVirtual.GrillaAnexos.length > 0
    ) {
      this.modelo.modelo.Anexos = JSON.stringify(
        this.modelo.modeloVirtual.GrillaAnexos
      );
    }
  }

  _Guardar(form: FormGroup, configButtons?: any) {

    debugger;
    if(this.modelo.modelo.IdEstadoPrevio)
    {
      this.modelo.modelo.Observaciones = null;
      if (this.modelo.modelo.DataAdd) {
        this.modelo.modelo.DataAdd[
          "IdEstadoPrevio"
        ] = this.modelo.modelo.IdEstadoPrevio;
      } else {
        this.modelo.modelo.DataAdd = {
          IdEstadoPrevio: this.modelo.modelo.IdEstadoPrevio
        };
      }
    }

    if(this.modelo.modeloVirtual.GridRadicadosAsociados){
      this.modelo.modelo.AsociacionMultiple = JSON.stringify(this.modelo.modeloVirtual.GridRadicadosAsociados)
    }

    if (this.ObservacionesAnulacion) {
      if (this.modelo.modelo.ObservacionesAnulacion != "" && this.modelo.modelo.ObservacionesAnulacion != null) {
        this.modelo.modelo.Observaciones = this.modelo.modelo.ObservacionesAnulacion;
      }
    }


    this.HasFiles();
    this.ConvertAnexos();
    this.SetUsuarioResponsable();
    this.GetUsersCopy();
    super._Guardar(form, configButtons);
  }

  public Limpiar(callback: (data: any) => void = null, action?: any) {

    if(this.calledCommandButton && this.calledCommandButton == "IncluirenExpediente"){
      var GridName = "GrillaAsignarExpediente";
      var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
      this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);
      this.SetModelToControls();      
    }else if(this.calledCommandButton && this.calledCommandButton == "Copiar") {
      this.LimpiarModeloGrillaUsariosCopias();      
    }else if(this.calledCommandButton && this.calledCommandButton == "CargaOficio") {
      this.Oficio.clear();
    }else if (this.calledCommandButton && this.calledCommandButton == "Reasignar") {
      this.modelo.modeloVirtual.ReasignacionJerarquica = StaticData.GeneralConfig.ConfigUI.ReasignacionJerarquica;
      this.modelo.modelo.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
      this.GrillaReasignarUsuario = this.componentsContainer.Tables.filter(
        (x) => x.name == "GrillaReasignarUsuario"
      )[0];
      this.GrillaReasignarUsuario.ReCargarGrilla();
      this.SetModelToControls();
    }    
    else if(this.calledCommandButton && this.calledCommandButton == "GestionarConcepto"){
      this.modelo.modelo.Observaciones=null
      this.FileBoxes.forEach((file)=>file.clear());
      this.SetModelToControls();
    }
    else if(this.calledCommandButton && this.calledCommandButton == "SolicitaAnulacion"){
      this.modelo.modelo.ObservacionesAnulacion=null
      this.SetModelToControls();
    }
    else{
      super.Limpiar();
    }
  }

  public LimpiarModeloGrillaUsariosCopias()
  {
    this.modelo.UsuariosCopia = []
    this.modelo.modeloVirtual.validaCopia = null
    var GridName = "GrillaUsuarioCopia";
    var GridViewUsuarioCopia = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
    GridViewUsuarioCopia.ReCargarGrilla()
    this.SetModelToControls();
  }
  public OnDataTableEditHandler(event) {
    debugger;
    //this.modelo.modeloVirtual.GridRadicadosAsociados = null;
    //this.PruebasEntradaRadicado = this.modelo.modeloVirtual.GridRadicadosAsociados;
    if(event.name && event.name != "GrillaAsignarUsuario"){
      if(this.modelo.modeloVirtual.GridRadicadosAsociados){
    this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
    }else{
      this.modelo.modeloVirtual.GridRadicadosAsociados = [];
    }
    }
    if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
      this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
      /*this.modelo.modeloVirtual.GridRadicadosAsociados.forEach((element) => {
        element.Disabled = true;
        element.DisabledDelete = true;
      });*/
    }

    if (event.name == "GridViewRadicadosAsignados") {
      const findItemClone = this.utility.Clone(event.data.data.findItem);
      this.modelo.selectedItem = findItemClone;

      let obj = this.utility.GetApiModel("GetEstadoPrevioSolicitud", null);
            obj.QueryConfig = {
              Command: "ConsultaEstadoPrevioSolicitud",
              IsSp: true,
              Entity: {
                TipoSolicitud: "Entrada",
                IdSolicitud: this.modelo.selectedItem.IdSolicitudRadicadoEntrada,
              },
            };

            let info = {
              componentName: this.componentName,
              controlName: "GetEstadoPrevioSolicitud",
              processMessage: "Cargando Estado previo de la Solicitud......",
            };

            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {

                  this.modelo.modelo.IdEstadoPrevio = data.Data[0].IdEstadoSolicitud;
                  this.SetModelToControls();

              })
              .catch((err) => {});
    }

    if (event.modelo.modelo.Anexos) {
      debugger;
      this.modelo.modeloVirtual.GrillaAnexos = JSON.parse(
        event.modelo.modelo.Anexos
      );
      //Los elementos quedan desactivados (ineditables)
      this.modelo.modeloVirtual.GrillaAnexos.forEach((element) => {
        element.Disabled = true;
        element.DisabledDelete = true;
      });
    }
    super.OnDataTableEditHandler(event);
  }
}
