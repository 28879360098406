import { Component } from "@angular/core";
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { StaticData } from '../../../helpers/static-data';

@Component({
  selector: 'app-file-upload-progress-bar',
  templateUrl: './file-uploader-multipart.component.html',
  styleUrls: ['./file-uploader-multipart.component.css']
})
export class FileUploaderMultipartComponent {
  progress: number;

  constructor(private http: HttpClient) {}

  upload(file) {
    this.progress = 1;
    const formData = new FormData();
    formData.append("file", file);

    this.http
      .post(StaticData.UrlServer + "/api/fileuploadlarge/upload", formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
          }
        }),
        catchError((err: any) => {
          this.progress = null;
          alert(err.message);
          return throwError(err.message);
        })
      )
      .toPromise();
  }
}

