import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, forwardRef, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { Utilities } from '../helpers/utilities';
import { EditorAceComponent } from '../components/controls/editor-ace/editor-ace.component';
import { EditorJsonComponent } from '../components/controls/editor-json/editor-json.component';
import { ConfigWindow } from '../models/config-window';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Guid } from 'guid-typescript';
import { CrudActions, TipoParaPermisos } from './general.enum';
import { BaseControl } from './base-control';
import { BaseServiceService } from '../services/base-service.service';
import { debug } from 'util';
import { List } from '../../assets/linqts/compilado/index';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';


export class BaseComboBox extends BaseControl implements OnInit, AfterViewInit, ControlValueAccessor {

    @Input() keyCache: string;
    @Input() queryConfig: any;
    @Input() executionObject: any;
    @Input() notLoad: boolean = false;
    @Input() processMessage: string = "";
    @Output() onLoadedEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() cascadingLoadEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Input() outPutParam: boolean = false;
    isLoading: boolean = false;


    constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities,
        @Inject(forwardRef(() => BaseServiceService)) public baseService: BaseServiceService,
        @Inject(forwardRef(() => DomSanitizer)) public sanitizer: DomSanitizer, public translate: TranslateService) {
        super(utility, sanitizer, translate);

    }



    ngOnInit() {

        this.InternalOnInit();
        this.OnInit();
        //this.Cargar();
        //let cache:boolean = (this.keyCache !== null && this.keyCache !== ''); //this.configData.keyCache

        if (this.configData && this.configData.processMessage)
            this.processMessage = this.configData.processMessage;
        this.CargarItemsComboBox(null, false, null, false);
    }

    public OnChangeEvent(item: any, data: any = null) {
        try {



            if (item) {
                this.value = item[this.pkField];
            }
            else
                this.value = null;
            if (this.onSelectedEmitter.observers.length > 0)
                this.onSelectedEmitter.emit({
                    item: item, controlName: this.controlName,
                    config: this.configData, data: data, _config: this.config,
                    index: this.currentIndex
                });
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, controlName: this.controlName, config: this.configData });
        }
    }
    public SetConfig(config: any) {
        config.SetCargarItems = this.configData.SetCargarItems;
        //config.CargarItemsComboBox = this.CargarItemsComboBox;
        config.Context = this;
        this.configData = config;
        this.configData.SetConfig = this.SetConfig;
        this.Cargar();
    }

    public FindItem(value?: any, property?: string): any {

        value = (value) ? value : this.modelo;
        property = (property) ? property : this.pkField;
        let _items = new List<any>(this.items);
        let item = _items.FirstOrDefault(x => { return x[property] == value });
        return item;
    }
    public Cargar() {

        this.CargarItemsComboBox(null, true, null, false);
    }
    public SetItemsComboBox(_items: any[], resetModel: boolean = true, currentValue?: any) {
        try {
            this.items = _items;
            this.items = this.items.slice();
            if (currentValue) {
                this.value = currentValue;
                if (this.ctr)
                    this.ctr.setValue(this.modelo);
                this.updateChanges();
            }
            else if (resetModel) {
                if (this.configData.IsMultiple)
                    this.value = [];
                else
                    this.value = null;



                if (this.ctr)
                    this.ctr.setValue(this.modelo);
                this.updateChanges();
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'SetItemsComboBox', controlName: this.controlName });
            this.utility.VerModalDanger().then(data => { });
        }
    }

    public CargarItemsComboBoxRemoveNotLoad(callback: (data: any) => void, reload: boolean, _items: any[],
        notCache: boolean, resetModel: boolean = true) {

        const that = this;
        let notLoad = that.configData.NotLoad;
        that.configData.NotLoad = false;
        that.CargarItemsComboBox(callback, reload, _items, notCache, resetModel);
        that.configData.NotLoad = notLoad;
    }
    public CargarItemsComboBox(callback: (data: any) => void, reload: boolean, _items: any[]
        , notCache: boolean, resetModel: boolean = true) {

        const that = this;// (context)?context:this;
        try {

            if (!that.configData) {
                that.configData = that.config.ConfigControl;
            }
            if (that.config && that.config.NotResetModel == true) {
                resetModel = false
            }

            if (that.config && that.config.StaticList) {
                that.configData.Context = that;
                let tipo: TipoParaPermisos = Number.isInteger(that.config.StaticList) ?
                    parseInt(that.config.StaticList) : TipoParaPermisos["" + that.config.StaticList];
                // let tipo: TipoParaPermisos = parseInt(that.config.StaticList);
                this.items = this.utility.GetItemsAreasRolesUsuarioSeries(tipo);

                return;
            }
           
            if (that.notLoad == true) {

                if (that.configData) {
                    that.configData.NotLoad = true;

                    that.configData.Context = that;
                    /*   if (that.configData.SetCargarItems) {
                          that.configData.Context = that;
                      } */
                }

                return;
            }
            if (!that.configData) {
                let _error = 'La configuracion de datos es nula para el control: ' + that.controlName + ',en el componente:' + that.componentName;
                that.utility.logger.LogError(_error, null, { method: 'CargarItemsComboBox', componentName: that.componentName, controlName: that.controlName, config: that.configData });
                return;

            }
            that.SetItemsComboBox([], resetModel);

            if (that.configData) {
                that.configData.Context = that;
            }

            if (that.configData && !that.configData.SetConfig)
                that.configData.SetConfig = that.SetConfig;
            if (_items) {
                //that.itemsSelectControls['Items'+controlName]=_items;
                that.SetItemsComboBox(_items, resetModel);
                that.SetCache(false);
                return;
            }
            if (!reload && (that.configData.keyCache || that.keyCache)) {
                var temp = that.GetCache();
                if (temp) {
                    // that.itemsSelectControls['Items'+controlName] = temp;
                    that.SetItemsComboBox(temp, resetModel);
                    that.SetCallBack();
                    return;
                }
            }

            if (that.configData && that.configData.Items && that.configData.Items.length >0) {

                
                this.textField ="Text";
                this.pkField ="Id";
                that.SetItemsComboBox(that.configData.Items, resetModel);
                return;
            }
            if (that.configData && that.configData.CallbackLoad) {

                that.configData.CallbackLoad(that.configData, function (__items) {

                    // that.itemsSelectControls['Items'+controlName] = __items;
                    that.SetItemsComboBox(__items, resetModel);
                })
            }
            else {
                //if (!vm.config && !vm.cmd)
                //    throw 'comboBox [' + vm.controlName + '] Config no pude ser nulo...'
                if (that.notLoad || (that.configData && that.configData.NotLoad == true)) {
                }
                else {
                    if (that.configData && that.configData.Items) {
                        that.utility.logger.LogInfoText('ComboBox [' + that.controlName + '] datos desde config.dataSource');

                        that.SetItemsComboBox(that.configData.Items, resetModel);
                        that.SetCallBack();
                    }
                    else {

                        var cmd = that.configData.Cmd;

                        // if (that.controlName == 'IdSubSerie' && that.configData.SearchConfig.Command == 'ConsultaSubSeries') {
                        //     cmd = that.configData.SearchConfig.Command;
                        // }

                        var controller = that.configData.Controller;

                        cmd = (!cmd) ? controller : cmd;
                        that.utility.logger.LogInfoText('ComboBox [' + that.controlName + '] Cmd: [' + cmd + ']');


                        if (!controller) {
                            that.utility.logger.LogWarningText('comboBox [' + that.controlName + '] El Controller y la config con nulo o vacio');
                            return;
                        }

                        if (!controller && !that.configData.Url)
                            throw 'comboBox [' + that.controlName + '] El Controller y la Url no pude ser nulo o vacio';


                        var obj = that.utility.GetApiModel('CargarItemsComboBoxComboBox', cmd);
                        if (that.configData) {
                            if (that.configData.CrudAction)
                                obj.QueryConfig.CrudAction = that.configData.CrudAction;

                            if (that.configData.QueryConfig) {
                                obj.QueryConfig = that.configData.QueryConfig;
                                obj.QueryConfig.AppSettingsKey = cmd;

                                if (obj.QueryConfig.MappingParameters) {
                                    if (!obj.QueryConfig.Entity)
                                        obj.QueryConfig.Entity = {};
                                    obj.QueryConfig.MappingParameters.forEach(parameter => {
                                        obj.QueryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.frm, obj.QueryConfig);
                                    })
                                }
                            }
                            else {
                                if (that.configData.IsSp == true) {

                                    obj.QueryConfig = null;
                                    obj.SActionName = that.configData.Cmd;
                                    if (that.configData.Params) {
                                        obj.Parameters = {};
                                        $.each(that.configData.Params, function (key, val) {
                                            obj.Parameters[key] = val;
                                        });
                                    }
                                }
                                else if (that.configData.Command) {

                                    obj.QueryConfig = null;
                                    obj.Command = that.configData.Command;
                                }
                                else if (that.configData.Filter && that.configData.Filter != '') {

                                    obj.QueryConfig = null;
                                    obj.Filter = that.configData.Filter;
                                    obj.Skip = that.configData.Skip;
                                    obj.Take = that.configData.Take;
                                    obj.OrderBY = that.configData.OrderBY;
                                }
                                else if (that.configData.Params && !that.configData.IsSp) {
                                    obj.QueryConfig = { AppSettingsKey: (!cmd) ? controller : cmd, CrudAction: CrudActions.GetByFilter };
                                }
                            }
                            if (obj.QueryConfig) {
                                if (!obj.QueryConfig.Entity)
                                    obj.QueryConfig.Entity = {};

                                if (that.configData.Params && !that.configData.IsSp) {
                                    $.each(that.configData.Params, function (key, val) {
                                        obj.QueryConfig.Entity[key] = val;
/*
                                        if (that.controlName == 'IdSubSerie' && that.configData.SearchConfig.Command == 'ConsultaSubSeries') {
                                            obj.QueryConfig.Entity[key] = val;
                                            cmd = that.configData.SearchConfig.Command;
                                        }
                                        */
                                    });
                                }
                            }
                        }
                        else {

                            obj.QueryConfig.CrudAction = CrudActions.GetAllOrderByDesc;
                        }
                        if (that.queryConfig) {
                            obj.QueryConfig = that.queryConfig;
                            obj.QueryConfig.AppSettingsKey = cmd;
                        }

                        if (that.outPutParam == true || (that.configData && that.configData.OutPutParam))
                            obj.QueryConfig.OutPutParam = true;
                        if (obj.QueryConfig && that.config.Query) {
                            obj.QueryConfig.Object = that.config.Query;

                        }
                        
                        obj.CtrName = that.controlName;
                        if (that.configData && that.configData.Action) {
                            that.configData.Url = controller + '/' + that.configData.Action;
                        }
                        if (that.configData && that.configData.Url) {
                            that.isLoading = true;
                            that.baseService.Ejecutar(obj, null,
                                {
                                    componentName: that.componentName,
                                    method: 'CargarItemsComboBox Ejecutar',
                                    controlName: that.controlName,
                                    processMessage: that.configData.processMessage,
                                    disableShowLoadNotifyType: that.configData.DisableShowLoadNotifyType
                                }, false, that.configData.Url).then(data => {
                                    that.isLoading = false;
                                    if (that.configData.FilterCallBack) {
                                        that.SetItemsComboBox(that.configData.FilterCallBack(data.Data), resetModel);

                                    }
                                    else {
                                        that.SetItemsComboBox(data.Data, resetModel);

                                    }

                                    that.SetCache(notCache);
                                    if (callback)
                                        callback(that.items);
                                    that.SetCallBack();

                                }).catch(err => { that.isLoading = false; });

                        }
                        else {
                            that.isLoading = true;
                            that.baseService.Get(obj, null,
                                {
                                    componentName: that.componentName,
                                    method: 'CargarItemsComboBox Get',
                                    controlName: that.controlName,
                                    processMessage: that.configData.processMessage,
                                    disableShowLoadNotifyType: that.configData.DisableShowLoadNotifyType
                                }, controller)
                                .then(data => {
                                    that.isLoading = false;
                                    //that.itemsSelectControls['Items'+controlName] = data.Data;
                                    that.SetItemsComboBox(data.Data, resetModel);

                                    that.SetCache(notCache);
                                    if (callback)
                                        callback(that.items);
                                    that.SetCallBack();

                                }).catch(err => { that.isLoading = false; });
                        }
                    }
                }
            }
        }
        catch (error) {
            that.isLoading = false;
            that.utility.logger.LogError(error, null, { method: 'CargarItemsComboBox', componentName: that.componentName, controlName: that.controlName, config: that.configData });
        }
    }
    public LoadedEmitter() {
        if (this.onLoadedEmitter.observers.length > 0)
            this.onLoadedEmitter.emit({ controlName: this.controlName, items: this.items, configData: this.configData, config: this.config })
        if (this.configData.onLoadedEmitter && this.configData.onLoadedEmitter.observers.length > 0)
            this.configData.onLoadedEmitter.emit({ controlName: this.controlName, items: this.items, configData: this.configData, config: this.config })
    }

    public CascadingEmitter() {
        if (this.cascadingLoadEmitter.observers.length > 0)
            this.cascadingLoadEmitter.emit({ controlName: this.controlName, items: this.items, configData: this.configData, config: this.config })
        if (this.configData.cascadingLoadEmitter && this.configData.cascadingLoadEmitter.observers.length > 0)
            this.configData.cascadingLoadEmitter.emit({ controlName: this.controlName, items: this.items, configData: this.configData, config: this.config })
    }

    public SetCache(notCache: boolean) {
        if (this.configData.keyCache && this.items && this.items.length > 0 && notCache != true)
            this.utility.SetCache(this.configData.keyCache + this.controlName, this.items);
        else if (this.keyCache && this.items && this.items.length > 0 && notCache != true)
            this.utility.SetCache(this.keyCache + this.controlName, this.items);
    }
    public GetCache(): any {
        if (this.configData.keyCache)
            return this.utility.GetCache(this.configData.keyCache + this.controlName);
        else if (this.keyCache)
            return this.utility.GetCache(this.keyCache + this.controlName);
        else
            return null;
    }
    public SetCallBack() {

        if (this.configData && this.configData.Callback) {
            this.configData.Callback(this.items);
        }
        this.LoadedEmitter();
        this.CascadingEmitter();
    }

    public ResetComboBox(items: any, reset: boolean = true )
    {
        const that = this;
        that.SetItemsComboBox(items, reset);
    }
}
