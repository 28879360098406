import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { StaticData } from 'src/app/helpers/static-data';
import { FormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ConfigWindow } from 'src/app/models/config-window';
import { ComponentsContainer } from 'src/app/models/components-container';
import { DataTableComponent } from '../data-table/data-table.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HelperCharts } from 'src/app/helpers/helper-charts';

declare var FileSaver: any;
declare var saveAs: any;
declare var XLSX: any;
declare var XLSXStyle: any;
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const CSV_TYPE = 'text/plain;charset=UTF-8';
const CSV_EXTENSION = '.csv';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {

  helperCharts: HelperCharts = null;
  @Input() modelo: any;
  @Input() modeloPadre?: any
  @Input() form: FormGroup;
  @Input() isDesigner: boolean = false;
  @Input() subControl: any;
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Input() name: string;
  @Input() componentsContainer: ComponentsContainer = new ComponentsContainer();
  @Input() PaginaPadre: any

  view = [];
  dataSet: Array<any> = [];
  componentName = "Chart";
  viewSingleTable: boolean = false;
  viewMultiTable: boolean = false;
  viewTable: boolean = false;
  viewFilterControl: boolean = false;
  notLoad: boolean = false;

  viewFilters: boolean = false;
  hasFilters: boolean = false;
  hasChildReports: boolean = false;
  filterParent: any;
  internalFilter: any;

  childReport: any = null;
  minHeight = null;

  private _width: number;
  private _height: number;
  private _left: number;
  private _top: number;
  private _bottom: number;
  public modal: boolean;
  public draggable: boolean;
  public closeable: boolean;
  public resizable: boolean;
  public expandMode: boolean;
  public minimizeMode: boolean;
  public collapseMode: boolean;
  public id: string = "Chart_" + Guid.create().toString();
  private expandData: {};
  private previousEvent: MouseEvent;
  public opacity: any = 1;
  displayContent: string;
  order: string;

  labelTipoControlFiltro: string = "";
  labelTipoFiltro: string = "";

  // currentControlType: string = null;
  previusType: any = null;

  // chartTypes: Array<any> = [
  //   { ControlType: "HorizontalBarChart", Label: "HorizontalBarChart", Selected: false, Icon: "BarChartHorizontal" },
  //   { ControlType: "VerticalBarChart", Label: "VerticalBarChart", Selected: false, Icon: "BarChartVertical" },
  //   { ControlType: "PieChart", Label: "PieChart", Selected: false, Icon: "PieSingle" },
  //   { ControlType: "NumberCardChart", Label: "NumberCardChart", Selected: false, Icon: "NumberField" },
  //   { ControlType: "LineChart", Label: "LineChart", Selected: false, Icon: "LineChart" },
  //   { ControlType: "AreaChart", Label: "AreaChart", Selected: false, Icon: "AreaChart" }];
  viewChartTypes = false;
  chartTypes: Array<any> = [
    { ControlType: "HorizontalBarChart", Label: "HorizontalBarChart", Selected: false, Icon: "BarChartHorizontal" },
    { ControlType: "VerticalBarChart", Label: "VerticalBarChart", Selected: false, Icon: "BarChartVertical" },
    { ControlType: "PieChart", Label: "PieChart", ExplodeSlices: false, Doughnut: false, Selected: false, Icon: "PieSingle" },
    { ControlType: "PieChart", Label: "PieChart Doughnut", Doughnut: true, ExplodeSlices: false, Selected: false, Icon: "PieSingle" },
    { ControlType: "PieChart", Label: "PieChart ExplodeSlices", ExplodeSlices: true, Doughnut: false, Selected: false, Icon: "PieSingle" }];

  public isChart: boolean = true;

  @Input() parentContext: any;
  @Input() currentContext: any;
  quickFilters: Array<any> = [];

  currentItem: any = null;

  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {


    this._width = 500;
    this._height = 200;
    this._left = 50;
    this._top = 50;

    this.draggable = true;
    this.modal = false;
    this.closeable = true;
    this.resizable = true;
    this.expandMode = false;
    this.minimizeMode = false;
    this.collapseMode = false;
    this.displayContent = 'block';
  }


  ngOnInit() {
    this.InitChart();
    //ReportsChild
  }
  public InitChart() {

    
    this.viewChartTypes = (this.subControl.ControlType == "HorizontalBarChart" || this.subControl.ControlType == "VerticalBarChart") ? true : false;
    if (!this.subControl.Style)
      this.subControl.Style = {};


    //this.currentControlType = this.subControl.ControlType;
    // this.chartTypes.push({ ControlType: this.subControl.ControlType, this.subControl.ControlType, Selected: false, Icon: "AreaChart" })
    this.helperCharts = new HelperCharts(this, true, this.utility, "subControl", false);
    this.view = this.subControl.View;
    this.hasFilters = (this.subControl.FilterControls && this.subControl.FilterControls.length > 0) ? true : false;

    this.name = this.subControl.Name;
    this.notLoad = this.subControl.NotLoad;
    this.hasChildReports = (this.subControl.ReportsChild && this.subControl.ReportsChild.length > 0) ? true : false;
    if (this.subControl.Height) {
      this.minHeight = this.subControl.Height + "px";
      this.helperCharts.OnChangeMinHeight(null);
    }

    this.helperCharts.SetlabelsTiposFilter();
  }
  ngAfterViewInit(): void {
    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }

    if (!this.isDesigner) {

      setTimeout(() => {
        this.ExcecuteDataSource();
      }, 1000);
    }
    if (!this.componentsContainer.Charts.find(x => { return x.name == this.name }))
      this.componentsContainer.Charts.push(this);
  }
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }
  OnDeactivateChart(event) {
    if (this.subControl.OnDeactivateEvent)
      console.log('OnDeactivateChart', event);
  }
  OnActivateChart(event) {
    if (this.subControl.OnActivateEvent)
      console.log('OnActivateChart', event);
  }
  OnSelectChart(event) {
    if (this.subControl.OnSelectEvent) {

      
      console.log('OnSelectChart', event);
      this.currentItem = event;
      this.helperCharts.FilterChilds(event);

    }
  }
  OnChangeFitContainer() {
    this.helperCharts.SetView();
  }


  ChangeType(type: any, index: number) {
    
    if (this.previusType)
      this.previusType.Selected = false;
    this.subControl.ControlType = type.ControlType;
    if (type.ControlType == 'PieChart') {
      this.subControl.ExplodeSlices = type.ExplodeSlices;
      this.subControl.Doughnut = type.Doughnut;
    }
    else {
      this.subControl.ExplodeSlices = undefined
      this.subControl.Doughnut = undefined;
    }
    type.Selected = true;
    this.previusType = type;
    //this.RefreshData();
  }
  // RestoreType() {
  //   
  //   if (this.previusType)
  //     this.previusType.Selected = false;
  //   this.subControl.ControlType = this.currentControlType;
  //   this.previusType = null;
  //   //this.RefreshData();
  // }
  ProcessData() {

    try {
      if (this.subControl.InfoSeries && this.subControl.InfoSeries.length > 0) {

        let index = 0;
        this.subControl.Results = [];
        this.subControl.InfoSeries.forEach(_serie => {

          let serie = { name: _serie.Label, series: this.helperCharts.GetData(index) };
          this.subControl.Results.push(serie);

          index++;
        })

      } else {

        this.subControl.Results = this.helperCharts.GetData(0);
        // if (this.subControl.DataSources[0].UseType == "AllByColumns") {

        //   this.subControl.Results = this.GetColumnData(this.dataSet[0].data,
        //     this.subControl.DataSources[0].LabelColumnOrIndex, this.subControl.DataSources[0].DataColumnOrIndex, null);
        // }
        // else {
        //   this.subControl.Results = this.GetRowData(this.dataSet[0].data,
        //     this.subControl.DataSources[0].LabelColumnOrIndex, this.subControl.DataSources[0].DataColumnOrIndex, null);
        // }
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, {}, { componentName: this.componentName, method: 'ProcessData' });
      this.utility.VerModalDanger().then(data => { })
    }
  }

  public ExcecuteDataSource(filterParent?: any) {
    let obj = this.utility.GetApiModel('ExcecuteDataSource', null);
    try {
      if (this.subControl.DataSources.length == 0 || this.notLoad) {
        this.RefreshData();
        return;
      }
      if (filterParent)
        this.filterParent = filterParent;
      obj.QueryConfigs = [];
      this.dataSet = [];
      let index = 0;
      this.subControl.DataSources.forEach(ds => {


        let queryConfig = this.utility.Clone(ds);
        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = index;
          if (queryConfig.CrudAction == 'GetAll' || queryConfig.CrudAction == 'GetAllOrderBy'
            || queryConfig.CrudAction == 'GetAllOrderByDesc') {
            queryConfig.Entity = {};
          }
        }





        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, queryConfig, null, this, this.parentContext);
          })
        }

        if (this.filterParent) {
          if (this.filterParent.Filter) {
            if (queryConfig.Filter)
              queryConfig.Filter += " AND ";
            queryConfig.Filter += " " + this.filterParent.Filter;
          }
          else if (this.filterParent.Parameters) {

            this.filterParent.Parameters.forEach(parameter => {
              queryConfig.Entity[parameter.Name] = parameter.Value;
            });

          }
        }
        // if (this.internalFilter && this.internalFilter.Filter) {
        //   if (this.internalFilter.Filter) {
        //     if (queryConfig.Filter)
        //       queryConfig.Filter += " AND ";
        //     queryConfig.Filter += " " + this.internalFilter.Filter;
        //   }
        //   else if (this.internalFilter.Parameters) {

        //     this.internalFilter.Parameters.forEach(parameter => {
        //       queryConfig.Entity[parameter.Name] = parameter.Value;
        //     });

        //   }
        // }
        if (this.internalFilter) {
          if (this.subControl.SetFilterControlsToEntity) {

            if (this.subControl.WithQueryBuilder) {

              if (queryConfig.Entity["Filter"])
                queryConfig.Entity["Filter"] = " AND " + this.internalFilter.filter;
              else
                queryConfig.Entity["Filter"] = this.internalFilter.filter;
            }
            else {
              $.each(this.internalFilter.modelo, (key, value: any) => {
                queryConfig.Entity[key] = value;
              });
            }
          }
          else {
            queryConfig.Filter = this.internalFilter.filter;
          }
        }
        
        if (this.quickFilters.length > 0) {
          //
          let quickFilter = "";

          this.quickFilters.forEach((item, i) => {

            this.helperCharts.ReplaceQuickFilter(item);
            if ((this.quickFilters.length - 1) == i) {
              quickFilter += (item.FilterWithReplace) ? item.FilterWithReplace + " " : item.Filter + " ";
            }
            else {
              quickFilter += (item.FilterWithReplace) ? item.FilterWithReplace + " " + item.Op : item.Filter + " " + item.Op + " ";
            }
          })
          if (this.subControl.QuickFilterParameterName) {
            if (queryConfig.Entity[this.subControl.QuickFilterParameterName])
              queryConfig.Entity[this.subControl.QuickFilterParameterName] = " AND " + quickFilter;
            else
              queryConfig.Entity[this.subControl.QuickFilterParameterName] = quickFilter;
          }
          else
            queryConfig.Filter = quickFilter;

        }






        obj.QueryConfigs.push(queryConfig);
        //this.dataSet.push({ index: index, data: null });
        index++;
      });

      this.baseService.MultiGet(obj, null,
        {
          componentName: this.componentName,
          method: 'ExcecuteDataSource',
          controlName: this.subControl.Name,
          processMessage: this.subControl.processMessage
        }, this.subControl.Controller)
        .then(data => {
          index = 0;

          data.Data.forEach(dtt => {

            // this.dataSet[index].data = dtt;
            this.subControl.DataSources[index].data = dtt;
            index++;

          });

          this.ProcessData();
        }).catch(err => {
          this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
          this.utility.VerModalDanger().then(data => { })
        });

    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
      this.utility.VerModalDanger().then(data => { })
    }
  }
  RefreshData() {
    this.subControl.Results = this.subControl.Results.slice();
  }


  ClearProcessQuickFilters() {
    this.quickFilters.forEach(quickFilter => {
      quickFilter.Selected = false;
    })
    this.quickFilters = [];
  }
  ProcessQuickFilters(quickFilter: any, index: number) {
    
    const currentIndex = this.quickFilters.findIndex(x => { return x.Label == quickFilter.Label });
    if (currentIndex > -1) {
      quickFilter.Selected = false;
      this.quickFilters.splice(currentIndex, 1);
    }
    else {
      quickFilter.Selected = true;
      this.quickFilters.push(quickFilter);
    }

  }

  // GetFilter(child, filterChildReport, event) {
  //   if (!child.Filters)
  //     return filterChildReport;
  //   if (child.AsParameter) {
  //     filterChildReport.Parameters = [];
  //     child.Filters.forEach(filter => {
  //       filterChildReport.Parameters.push({ Name: filter.DestinationField, Value: event[filter.SourceField] });
  //     })
  //   }
  //   else {
  //     let strFilter = "";
  //     let i: number = 0;

  //     child.Filters.forEach(filter => {


  //       if (i > 0)
  //         strFilter += " AND ";
  //       let internalValue = event[filter.SourceField];
  //       switch (filter.DataType) {
  //         case 'String':
  //           strFilter += " " + filter.DestinationField + " LIKE '%" + internalValue + "%' ";
  //           break
  //         case 'DateTime':
  //           strFilter += " " + filter.DestinationField + " = CONVERT(DATETIME,'" + internalValue + "',103)";
  //           break;
  //         case 'Boolean':
  //           //filter += " CONVERT(DATETIME,'" + value + "',103)";
  //           if (internalValue == "true")
  //             strFilter += " " + filter.DestinationField + " = 1";
  //           else
  //             strFilter += " " + filter.DestinationField + " = 0";
  //           break;
  //         default:
  //           strFilter += " " + filter.DestinationField + " = " + internalValue + " ";
  //           break


  //       }
  //       i++;


  //     });
  //     filterChildReport.Filter = strFilter;
  //   }

  //   return filterChildReport;
  // }
  // FilterChilds(event) {
  //  
  //   if (this.childReport) {
  //     let filterChildReport: any = { Source: this.PaginaPadre.ConfigPage.Ruta };

  //     filterChildReport = this.GetFilter(this.childReport, filterChildReport, event);

  //     if (this.childReport.Modal) {
  //       this.ModalChildReport(filterChildReport);
  //     }
  //     else {
  //       filterChildReport.IsFromParentNavigation = true;
  //       this.utility["DashboardFilterParent"] = filterChildReport;
  //       this.utility.router.navigate([this.childReport.Object.Ruta]);
  //     }
  //   }
  //   if (this.subControl.ChartsChild && this.subControl.ChartsChild.length > 0) {

  //     this.subControl.ChartsChild.forEach(chartChild => {
  //       let filteChartrChild: any = (chartChild.AsParameter) ? { Parameters: [] } : { Filter: "" };

  //       filteChartrChild = this.GetFilter(chartChild, filteChartrChild, event);
  //       let child = this.componentsContainer.Find(chartChild.Object.Name);
  //       if (child instanceof DataTableComponent) {


  //       }
  //       else if (child instanceof DashboardComponent) {
  //         child.ExcecuteDataSource(filteChartrChild);
  //       }
  //       else if (child instanceof ChartComponent) {
  //         child.ExcecuteDataSource(filteChartrChild);
  //       }
  //       else if (child instanceof ChartComponent) {
  //         child.ExcecuteDataSource(filteChartrChild);
  //       }
  //     })
  //   }
  // }
  // ModalChildReport(filterChildReport) {
  //  

  //   let modeloTemp = {
  //     filterParent: filterChildReport,
  //     pageId:this.childReport.Object.IdPagina
  //   };

  //   let componentName = "DashboardComponent";
  //   let config: ConfigWindow = new ConfigWindow();
  //   config.classWindow = 'Info';
  //   config.titleWindow = this.childReport.Object.Name;
  //   config.returnInstance = true;
  //   config.modal = false;
  //   config.width = 800;
  //   config.height = 450;
  //   config.viewOk = false;
  //   config.cancel = 'Cerrar';
  //   config.closeable = true;
  //   config.resizable = true;
  //   config.draggable = true;

  //   this.utility.OpenWindow(componentName,
  //     config, modeloTemp).then(dataResult => {

  //       // dataResult.componentInstance.onSearchEvent.subscribe(result => {
  //       //   this.internalFilter = { Filter: result.filter };
  //       //   this.ExcecuteDataSource();

  //       // });
  //       // //}
  //       // dataResult.onClosePromise.then(dataResultOnClose => {

  //       // });

  //     })
  // }
  // SetView() {

  //   if (this.subControl.FitContainer) {
  //     this.view = this.subControl.View;
  //     this.subControl.View = null;
  //   }
  //   else {
  //     this.subControl.View = this.view;

  //   }

  // }

  // GetColumnData(data, labelColumnOrIndex, dataColumnOrIndex, arr) {

  //   let resultArr = (arr) ? arr : [];
  //   if (isNaN(labelColumnOrIndex) == true && isNaN(dataColumnOrIndex) == true) {

  //     data.forEach(item => {
  //       resultArr.push({ name: item[labelColumnOrIndex], value: item[dataColumnOrIndex] });
  //     });
  //     return resultArr;
  //   }

  //   $.each(data, (key, item) => {

  //     let indexColumn = -1;
  //     let name = null;
  //     let cellData = null;
  //     $.each(item, (keyColumn, value) => {

  //       indexColumn++;
  //       if (isNaN(labelColumnOrIndex) == false && indexColumn == labelColumnOrIndex) {
  //         name = value;
  //       }
  //       else if (isNaN(labelColumnOrIndex) == true && keyColumn == labelColumnOrIndex) {
  //         name = value;
  //       }

  //       if (isNaN(dataColumnOrIndex) == false && indexColumn == dataColumnOrIndex) {
  //         cellData = value;
  //       }
  //       else if (isNaN(dataColumnOrIndex) == true && keyColumn == dataColumnOrIndex) {
  //         cellData = value;
  //       }

  //       if (name && cellData) {

  //         resultArr.push({ name: name, value: cellData });
  //         name = null;
  //         cellData = null;
  //         return;
  //       }

  //     });
  //   });
  //   return resultArr;
  // }

  // GetRowData(data, labelColumnOrIndex, dataColumnOrIndex, arr) {

  //   var resultArr = (arr) ? arr : [];

  //   $.each(data[labelColumnOrIndex], (key, value) => {
  //     resultArr.push({ name: value, value: null });
  //   });
  //   let index = 0;
  //   $.each(data[dataColumnOrIndex], (key, value) => {
  //     resultArr[index] = value;
  //     index++;
  //   });
  //   return resultArr;
  // }

  // GetData(index) {
  //   if (this.subControl.DataSources[index].UseType == "AllByColumns") {

  //     return this.GetColumnData(this.subControl.DataSources[index].data,
  //       this.subControl.DataSources[index].LabelColumnOrIndex, this.subControl.DataSources[index].DataColumnOrIndex, null);
  //   }
  //   else {
  //     return this.GetRowData(this.subControl.DataSources[index].data,
  //       this.subControl.DataSources[index].LabelColumnOrIndex, this.subControl.DataSources[index].DataColumnOrIndex, null);
  //   }
  // }



  // public ClearFilterParent() {
  //   this.filterParent = null;
  //   this.ExcecuteDataSource();
  // }
  // public ChangeFilterModal() {

  //   this.subControl.ModalFilter = !this.subControl.ModalFilter;
  //   this.viewFilterControl = (this.subControl.ModalFilter) ? false : true;
  //   this.SetlabelsTiposFilter();

  // }
  // public ChangeFilterWithQueryBuilder() {

  //   this.subControl.WithQueryBuilder = !this.subControl.WithQueryBuilder;
  //   this.SetlabelsTiposFilter();
  // }
  // ViewTable() {

  //   if (this.subControl.InfoSeries && this.subControl.InfoSeries.length > 0)
  //     this.viewMultiTable = !this.viewMultiTable;
  //   else
  //     this.viewSingleTable = !this.viewSingleTable;

  //   this.viewTable = !this.viewTable;
  // }
  // ViewFilter() {
  //   this.viewFilters = !this.viewFilters;
  // }
  // ExpandCollapse(event) {
  //   $("#" + this.id).toggleClass("expand");
  //   this.expandMode = !this.expandMode;
  //   this.RefreshData();
  // }

  // PrintNewWindow() {
  //  
  //   const elementContainer = document.getElementById(this.id);
  //   const w = window.open();
  //   w.document.write(elementContainer.innerHTML);
  //   w.print();
  //   w.close();
  // }
  // Print() {
  //   window.print();
  // }

  // DeleteFilter() {
  //   this.internalFilter = null;
  //   this.filterParent = null;
  //   this.ExcecuteDataSource();
  // }
  // Filter() {
  //   if (this.subControl.ModalFilter && this.subControl.FilterControls && this.subControl.FilterControls.length > 0) {
  //     this.ModalFilter();
  //   }
  //   else if (!this.subControl.ModalFilter && this.subControl.FilterControls && this.subControl.FilterControls.length > 0) {

  //     this.viewFilterControl = !this.viewFilterControl;

  //   }
  // }
  // SetReportsChild(childReport, index) {

  //   this.subControl.ReportsChild.forEach(report => {
  //     report.Selected = false;
  //   })
  //   if (this.childReport && this.childReport.Object.Name == childReport.Object.Name)
  //     this.childReport = null;
  //   else {
  //     childReport.Selected = true;
  //     this.childReport = childReport;
  //   }
  // }
  // ModalFilter() {
  //  

  //   let modeloTemp = {

  //     config: this.subControl,
  //   };

  //   let componentName = "FiltersDataTableComponent";
  //   let config: ConfigWindow = new ConfigWindow();
  //   config.classWindow = 'Info';
  //   config.titleWindow = "Filtros";
  //   config.returnInstance = true;
  //   config.modal = false;
  //   config.width = 800;
  //   config.height = 400;
  //   config.viewOk = false;
  //   config.cancel = 'Cerrar';
  //   config.closeable = true;
  //   config.resizable = true;
  //   config.draggable = true;

  //   this.utility.OpenWindow(componentName,
  //     config, modeloTemp).then(dataResult => {

  //       dataResult.componentInstance.onSearchEvent.subscribe(result => {
  //         this.internalFilter = { Filter: result.filter };
  //         this.ExcecuteDataSource();

  //       });
  //       //}
  //       dataResult.onClosePromise.then(dataResultOnClose => {

  //       });

  //     })
  // }
  // Search(event) {
  //   this.internalFilter = { Filter: event.filter };
  //   this.ExcecuteDataSource();
  // }
  // ClearSearch(event) {
  //   this.internalFilter = null;
  //   this.ExcecuteDataSource();
  // }
  // minHeight = null;
  // OnChangeMinHeight(item) {

  //   if (this.minHeight) {

  //     if (!this.subControl.Style)
  //       this.subControl.Style = {};
  //     this.subControl.Style["min-height"] = this.minHeight;
  //   }
  //   else {
  //     delete this.subControl.Style["min-height"];
  //     this.subControl.Style = null;
  //   }
  //   this.RefreshData();
  // }



  // public Order(order, field = "value") {
  //   this.order = order;
  //   if (order == "asc") {
  //     this.subControl.Results = this.subControl.Results.sort((a, b) => {
  //       return a[field] - b[field];
  //     });
  //   }
  //   else {
  //     this.subControl.Results = this.subControl.Results.sort((a, b) => {
  //       return b[field] - a[field];
  //     });
  //   }
  //   this.RefreshData();
  // }


  // public GetCurrentDate(separator: any = "-") {

  //   var today = new Date();
  //   var dd = String(today.getDate()).padStart(2, '0');
  //   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  //   var yyyy = today.getFullYear();

  //   let fecha = dd + separator + mm + separator + yyyy + ' ' + today.getHours() + today.getMinutes() + today.getSeconds();
  //   return fecha;
  // }

  // public ExportAsFile(json: any[], fileName: string, type, setDate: boolean = true): void {

  //   if (type == "csv") {
  //     this.ExportAsCsvFile(json, fileName, setDate);
  //   }
  //   else {
  //     this.ExportAsExcelFile(json, fileName, setDate);
  //   }
  // }
  // public ExportAsCsvFile(json: any[], fileName: string, setDate: boolean = true): void {

  //   if (!json) {
  //     json = this.subControl.Results;
  //   }
  //   if (!fileName) {
  //     fileName = this.subControl.Name + ".csv";
  //   }

  //   const worksheet: any = XLSX.utils.json_to_sheet(json);
  //   const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
  //   this.SaveAsFile(csv, fileName, CSV_TYPE, CSV_EXTENSION);

  // }
  // public ExportAsExcelFile(json: any[], fileName: string, setDate: boolean = true): void {
  //   if (!json) {
  //     json = this.subControl.Results;
  //   }
  //   if (!fileName) {
  //     fileName = this.subControl.Name + ".xlsx";
  //   }

  //   const worksheet: any = XLSX.utils.json_to_sheet(json);

  //   const workbook: any = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  //   this.SaveAsFile(excelBuffer, fileName, EXCEL_TYPE, EXCEL_EXTENSION);
  // }

  // private SaveAsFile(buffer: any, fileName: string, type, extension, setDate: boolean = true): void {
  //   const data: Blob = new Blob([buffer], {
  //     type: type
  //   });
  //   fileName = (setDate) ? fileName + '_' + this.GetCurrentDate() + extension : fileName + fileName
  //   saveAs(data, fileName);
  // }

  //// FUNCIONALIDAD DE DRAG Y RESIZE
  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  get height(): number {
    return this._height;
  }

  get innerHeight(): number {
    return this._height - 125;
  }

  set height(value: number) {
    this._height = value;
  }

  @Input() set left(value: number) {
    if (value)
      this._left = value;
  }
  get left(): number {
    return this._left;
  }

  @Input() set top(value: number) {
    if (value)
      this._top = value;
  }
  get top(): number {
    return this._top;
  }
  get bottom(): number {
    return (this.minimize) ? 0 : null;
  }

  public resize() {
    if (!this.resizable) {
      return;
    }
    if (!this.expandMode) {
      this.expandData = {
        left: this.left,
        top: this.top,
        width: this.width,
        height: this.height,
        draggable: this.draggable,
        displayContent: this.displayContent
      };
      this._left = 0;
      this._top = 0;
      this.width = window.innerWidth - 20;
      this.height = window.innerHeight - 20;
      this.draggable = false;
      this.displayContent = 'block';
    } else {
      this._left = this.expandData['left'];
      this._top = this.expandData['top'];
      this.width = this.expandData['width'];
      this.height = this.expandData['height'];
      this.draggable = this.expandData['draggable'];
      this.displayContent = this.expandData['displayContent'];
    }
    this.expandMode = !this.expandMode;
  }
  public minimize() {
    if (!this.resizable) {
      return;
    }
    if (!this.minimizeMode) {
      this.expandData = {
        left: this.left,
        top: this.top,
        bottom: this.bottom,
        width: this.width,
        height: this.height,
        draggable: this.draggable,
        displayContent: this.displayContent
      };
      this._left = 0;
      this._top = window.innerHeight - 55;
      this._bottom = 0;
      this.width = 200;// window.innerWidth;
      this.height = window.innerHeight;
      this.draggable = true;
      this.displayContent = 'none';
      $('#' + this.id).toggleClass('close-modal');
    } else {
      this._left = this.expandData['left'];
      this._top = this.expandData['top'];
      this._bottom = this.expandData['bottom'];
      this.width = this.expandData['width'];
      this.height = this.expandData['height'];
      this.draggable = this.expandData['draggable'];
      this.displayContent = this.expandData['displayContent'];
      $('#' + this.id).toggleClass('close-modal');
    }

    this.minimizeMode = !this.minimizeMode;
  }
  public collapse() {
    if (!this.resizable) {
      return;
    }
    if (!this.collapseMode) {
      this.expandData = {
        left: this.left,
        top: this.top,
        bottom: this.bottom,
        width: this.width,
        height: this.height,
        draggable: this.draggable,
        displayContent: this.displayContent
      };
      this.height = 0;
      this.displayContent = 'none';
    } else {
      this.height = this.expandData['height'];
      this.displayContent = 'block';
    }
    this.collapseMode = !this.collapseMode;
  }
  public startResize() {
    if (!this.resizable) {
      return;
    }
    //this.calculateIndex();
    this.previousEvent = null;
    window.addEventListener('mousemove', this.resizeWindow, false);
    window.addEventListener('mouseup', this.endResize, false);
    window.addEventListener('mouseleave', this.endResize, false);
  }

  public resizeWindow = (event: MouseEvent) => {
    window.getSelection().removeAllRanges();
    if (this.previousEvent) {
      this._width += (event.clientX - this.previousEvent.clientX);
      this._height += (event.clientY - this.previousEvent.clientY);
      this._width = (this._width < 200) ? 200 : this._width;
      this._height = (this._height < 100) ? 100 : this._height;
    }
    this.previousEvent = event;
  }



  public endResize = (event: MouseEvent) => {
    window.removeEventListener('mousemove', this.resizeWindow, false);
    window.removeEventListener('mouseup', this.endResize, false);
    window.removeEventListener('mouseleave', this.endResize, false);
  }

  /* Called after user start draggable */
  public startDraggable() {
    if (!this.draggable) {
      return;
    }
    this.opacity = 0.5;
    // this.calculateIndex();
    this.previousEvent = null;
    window.addEventListener('mousemove', this.dragWindow, false);
    window.addEventListener('mouseup', this.endDraggable, false);
    window.addEventListener('mouseleave', this.endDraggable, false);
  }

  public endDraggable = (event: MouseEvent) => {
    this.opacity = 1;
    window.removeEventListener('mousemove', this.dragWindow, false);
    window.removeEventListener('mouseup', this.endDraggable, false);
    window.removeEventListener('mouseleave', this.endDraggable, false);
    this.checkPosition();
  }

  /* Calculate window position on the screen */
  public checkPosition(): void {
    if ((this._left + this.width) > window.innerWidth) {
      this._left = window.innerWidth - this.width;
    }
    if ((this._top + this.height) > window.innerHeight) {
      this._top = window.innerHeight - this.height;
    }
    this._left = this._left < 0 ? 0 : this._left;
    this._top = this._top < 0 ? 0 : this._top;
  }

  /* Calculate position when user drag window*/
  public dragWindow = (event: MouseEvent) => {
    window.getSelection().removeAllRanges();
    if (this.previousEvent) {
      this._left += (event.clientX - this.previousEvent.clientX);
      this._top += (event.clientY - this.previousEvent.clientY);
    }
    this.previousEvent = event;
  }

  /* Center window position */
  public center(): void {
    this._left = (window.innerWidth - this._width) / 2;
    console.log(window.innerHeight + ' ' + this._height);
    if (window.innerHeight > this._height) {
      this._top = (window.innerHeight - this._height) / 2;
    } else {
      this._top = 0;
    }
  }


}
