import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service'
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { DataTableComponent } from "../../controls/data-table/data-table.component";
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-rcuaderno',
  templateUrl: './rcuaderno.component.html',
  styleUrls: ['./rcuaderno.component.scss']
})
export class RcuadernoComponent extends GeneralFormComponent implements OnInit, AfterViewInit {

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    super(baseService, utility, helperImages, helperRules, helperActions);
  }

  public OnInitEnd() {

    this.preSaveEmitter.subscribe(((modelo) => {
      // setTimeout(() => {

      if (!this.modelo.modelo.IdExpediente && this.utility.ReturnParentDetailsItem && this.utility.ReturnParentDetailsItem.IdExpediente)
        this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente
    }));

  }

  public NavegarPaginaHija(url) {
    this.utility.ParentDetailsItem = this.utility.ReturnParentDetailsItem;
    this.utility.router.navigate([url]);
  }

  public OnActionEventHandler(event) {
    debugger
    if (event.Option) {
      if (event.Option.Type == "Return") {
        this.NavegarPaginaHija('DocumentoExpediente');
        //this.utility.router.navigate(['DocumentoExpediente']);
        return;
      }
      else if (event.Option.Type == "Active" && this.modelo.modelo.Activo) {
        var obj = this.utility.GetApiModel('ConsultaCantidadDocumentosCauderno', this.componentName);

        obj.QueryConfig.Command = 'ConsultaCantidadDocumentosCauderno';
        obj.QueryConfig.IsSp = true;
        obj.QueryConfig.Entity = { IdCuaderno: this.modelo.modelo.IdCuaderno };

        this.baseService.Get(obj, null, {
          componentName: this.componentName, method: 'ConsultaCantidadDocumentosCauderno', processMessage: 'Consultando datos del cuaderno'
        }, 'RCuaderno')
          .then(data => {
            if (data.Data[0].CantidadDocumento == 0) {
              super.OnActionEventHandler(event);
            }
            else {
              this.utility.VerModalWarning({ "titulo": "Validación de Cuadernos", "descripcion": "No es posible inactivar el cuaderno, poosee documentos asociados" });
            }
          }).catch(err => {
            this.utility.logger.LogError(err, obj, {
              componentName: this.componentName, method: 'ConsultaCantidadDocumentosCauderno', processMessage: 'Consultando datos del Cauderno'
            }, 'RCuaderno')
          });
      }
      else
        super.OnActionEventHandler(event);
    }
    else
      super.OnActionEventHandler(event);
  }


  public AddComponentInstanceHandler(event) {
    if (
      this.utility.ReturnParentDetailsItem &&
      event instanceof DataTableComponent
    ) {
      this.componentsContainer.Tables.push(event);
      if (event.name == "DataTables_RCuaderno") {
        this.SetExpediente(this.utility.ReturnParentDetailsItem);
        this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente
        this.SetModelToControls();
      }
    }
    /// Fin de modificacion Marlon Granda 29_11_2020
    else {
      super.AddComponentInstanceHandler(event);
    }

  }

  // CABIAR POR PARMETRIZACION
  public SetExpediente(item: any) {

    if (item) {

      this.modelo.modelo.IdExpediente = item.IdExpediente;
      this.componentsContainer.ReloadTable("DataTables_RCuaderno", {
        IdEmpresa: -1,
        IdExpediente: this.modelo.modelo.IdExpediente,
      });

    } else {

      this.modelo.modelo.IdExpediente = null;
      this.componentsContainer.ReloadTable("DataTables_RCuaderno", {
        IdEmpresa: -1,
        IdExpediente: -2,
      });

    }

  }

  public NgAfterViewInitEnd() {
    if (this.utility.ReturnParentDetailsItem) {
      // 02102021 Inicio Asignar datos a combos carpetas y tipos documentales edwin alonso
      // fin
      setTimeout(() => {
        if (this.commandBar)
          this.commandBar.SetHiddenOption(false, "Name", "Retornar");
      }, 500);
    }
  }

  _Guardar(form: FormGroup, configButtons?: any) {
    //this.cnfgButtons = configButtons;
    if (!this.modelo.modeloVirtual.ActivoVirtual) {
      var obj = this.utility.GetApiModel('ConsultaCantidadDocumentosCauderno', this.componentName);

      obj.QueryConfig.Command = 'ConsultaCantidadDocumentosCauderno';
      obj.QueryConfig.IsSp = true;
      obj.QueryConfig.Entity = { IdCuaderno: this.modelo.modelo.IdCuaderno };

      this.baseService.Get(obj, null, {
        componentName: this.componentName, method: 'ConsultaCantidadDocumentosCauderno', processMessage: 'Consultando datos del cuaderno'
      }, 'RCuaderno')
        .then(data => {
          if (data.Data[0].CantidadDocumento == 0) {
            super._Guardar(form, configButtons);
          }
          else {
            this.SetProcesando(false);
            this.utility.VerModalWarning({ "titulo": "Validación de Cuadernos", "descripcion": "No es posible actualizar el estado del cuaderno a inactivo, poosee documentos asociados" });
          }
        }).catch(err => {
          this.utility.logger.LogError(err, obj, {
            componentName: this.componentName, method: 'ConsultaCantidadDocumentosCauderno', processMessage: 'Consultando datos del Cauderno'
          }, 'RCuaderno')
        });
    }
    else
      super._Guardar(form, configButtons);
  }


}
