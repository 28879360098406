import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PropertyGridComponent } from '../property-grid/property-grid.component';
import {
  CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray,
  copyArrayItem, transferArrayItem
} from '@angular/cdk/drag-drop';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';
import { ConfigWindow } from 'src/app/models/config-window';

@Component({
  selector: 'app-property-grid-column',
  templateUrl: './property-grid-column.component.html',
  styleUrls: ['./property-grid-column.component.scss']
})
export class PropertyGridColumnComponent extends PropertyGridComponent implements OnInit {
  @ViewChild("editorAceChidOptions", { static: false }) public editorAceChidOptions: EditorAceComponent;

  @Input() IsExtendableTable: boolean = false;
  processTypesJson: Array<any> = [{ Label: 'No Aplica', Name: 'None' },
  { Label: 'Como Opciones', Name: 'Options' },
  { Label: 'Como Opciones y Extraccion ', Name: 'OptionsExtract' },
  { Label: 'Extraccion de Datos', Name: 'Extract' }];
  public OnInit() {
    this.subControl.ControlType = 'Column';
    this.ValidateTags();
  }

  // public set subControl(value:any){

  //   this.subControl =value;
  // }
  public UpdateEditor(subControl?: any) {
    this.subControl = subControl;
    if (this.subControl && this.subControl.ControlType == 'Column') {
      if (this.editorAceChidOptions)
        this.editorAceChidOptions.Update(this.subControl.ChildOptions);
    }

  }
  public OnChangeHandlerChildOptions(event) {
    this.subControl.ChildOptions = event;
    //this.UpdateEditor(this.subControl);
  }
  public ControlTypeChanged(event) {

    
    if (this.subControl.ControlType == 'None') {
      this.subControl.Control = null;
    }
    else {
      this.subControl.Control = this.utility.GetBasicControlModel(this.subControl.ControlType, this.subControl.ControlType);
    }
  }
  ValidateTags() {
    if (!this.subControl.Tags)
      this.subControl.Tags = [];
  }
  public EliminarTag(index) {

    this.subControl.Tags.splice(index, 1);
  }
  public AddTag() {
    this.ValidateTags();
    this.subControl.Tags.push({});
  }
  verProperty(index): any {
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Propiedades";
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';
    let windowModel: any = {
      subControl: this.subControl.Control,
      subControlIndex: -1
    };

    this.utility.OpenWindow('PropertyGridControlsComponent',
      config, windowModel).then(dataResult => {
        // console.log(dataResult);
        
        dataResult.onClosePromise.then(dataResultOnClose => {
          // console.log(dataResultOnClose);
          

        });

      })

  }

  public ViewIcons() {
    if (!this.subControl.Icon)
      this.subControl.Icon = "ms-Icon ms-Icon--";
    window.open("https://uifabricicons.azurewebsites.net", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
  }

  OnChangeIcon(item) {

    if (item) {
      if (item.IconClass) {
        this.subControl.Icon = item.IconClass;
        this.subControl.Image = undefined
      }
      else {
        this.subControl.Icon = null;
        
        this.subControl.Image = this.utility.GetPathImages(item);
      }
    }
    else {
      this.subControl.Icon = null
      this.subControl.Image = undefined
    }

  }
  public VerEditorHtmlMsg(tipo: any) {


    this.utility.VerEditor(tipo, this.subControl.HtmlMsg, true, "Editor", "html").then(data => {
      
      if (data.dialogResult == true &&
        data.modelo) {

        this.subControl.HtmlMsg = data.modelo;

      }
    });

  }
}
