import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'app-editor-json',
  templateUrl: './editor-json.component.html',
  styleUrls: ['./editor-json.component.scss']
})

export class EditorJsonComponent extends ConfigBaseComponent implements OnInit {

  public editorOptions: JsonEditorOptions;
  public data: any;
  @Input() esStringJson: boolean = true;
  @ViewChild(JsonEditorComponent, { static: true }) editor: JsonEditorComponent;

  //* FUNCIONES ANGULAR

  ngOnInit() {

    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    if (this.modelo && this.modelo !== '' && this.modelo !== null) {
      if (typeof this.modelo === 'string') {
        this.modelo = this.modelo.replace('\"', '"');
        this.data = JSON.parse(this.modelo);
      }
      else
        this.data = this.modelo;
    }

  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public GetData(event: any) {

    if (event && event !== '' && event !== null)
      this.modelo = JSON.stringify(event);

  }

  //* END FUNCIONES PERSONALIZADAS

}