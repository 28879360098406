import { Component, OnInit, Input } from '@angular/core';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';

@Component({
  selector: 'app-theme-customization',
  templateUrl: './theme-customization.component.html',
  styleUrls: ['./theme-customization.component.css']
})
export class ThemeCustomizationComponent implements OnInit {


  @Input() modelo: any = {
    PrimaryColor: '', SecundaryColor: '', PrimaryColor70: '',
    SideBarBackgroundImage: '', BackgroundImage: '',
    WithSideBarBackgroundImage: true, WithBackgroundImage: false
  };
 
  primaryColorOri: string;
  secundaryColorOri: string;
  primaryColor70Ori: string;
  constructor(public comunicationService: GeneralComunicationService) { }

  ngOnInit() {
    //  this.SetDatosUsuario();
    this.GetColorsVars();


    this.OnSideBarBackgroundImage(this.modelo.SideBarBackgroundImage);
    this.OnBackgroundImage(this.modelo.BackgroundImage);
    this. OnChangeSideBarBackgroundImage();
    this.OnChangeBackgroundImage();
  }



  public GetColorsVars() {
    this.modelo.PrimaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--primaryColor');

      this.modelo.SecundaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--secundaryColor');
      this.modelo.PrimaryColor70 = getComputedStyle(document.documentElement)
      .getPropertyValue('--primaryColor-70');
    this.primaryColorOri = this.modelo.PrimaryColor;
    this.secundaryColorOri = this.modelo.SecundaryColor;
    this.primaryColor70Ori = this.modelo.PrimaryColor70;
  }
  public SetOrigi() {

    this.SetPrimaryColor(this.primaryColorOri);
    this.SetPrimaryColor70(this.primaryColor70Ori);
    this.SetSecundaryColor(this.secundaryColorOri);
  }
  public SetPrimaryColor($event) {
    this.modelo.PrimaryColor = $event;
    document.documentElement.style.setProperty('--primaryColor', this.modelo.PrimaryColor);
    
    this.modelo.PrimaryColor70 = this.ColorLuminance(this.modelo.PrimaryColor, 0.99);
    this.SetPrimaryColor70(this.modelo.PrimaryColor70);

  }
  ColorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }
  public SetPrimaryColor70($event) {
    this.modelo.PrimaryColor70 = $event;
    document.documentElement.style.setProperty('--primaryColor-70', this.modelo.PrimaryColor70);
  }
  public SetSecundaryColor($event) {
    this.modelo.SecundaryColor = $event;
    document.documentElement.style.setProperty('--secundaryColor',this.modelo.SecundaryColor );
  }

  public OnChangeSideBarBackgroundImage() {
    
    if (this.modelo.WithSideBarBackgroundImage)
      (<any>$('#SideBarBackgroundImage')).show();
    else
      (<any>$('#SideBarBackgroundImage')).hide();
  }
  public OnChangeBackgroundImage() {

    if (this.modelo.WithBackgroundImage)
      (<any>$('#BackgroundImage')).show();
    else
      (<any>$('#BackgroundImage')).hide();
  }
  public OnSideBarBackgroundImage(img) {
    
    this.modelo.SideBarBackgroundImage = img;
    let path ="../../../assets/images/"+img
    $('#SideBarBackgroundImage').css("background-image", "url("+path+")");  
  }
  public OnBackgroundImage(img) {
 
    this.modelo.BackgroundImage = img;
    let path ="./../assets/images/"+img
    $('#BackgroundImage').css("background-image", "url("+path+")");  

   
  }
}
