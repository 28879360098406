
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Utilities } from 'src/app/helpers/utilities';
import { List } from '../../../../assets/linqts/compilado/index';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { DragAndDropListComponent } from '../../controls/drag-and-drop-list/drag-and-drop-list.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { PropertyGridComponent } from '../property-grid/property-grid.component';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-property-grid-collections',
  templateUrl: './property-grid-collections.component.html',
  styleUrls: ['./property-grid-collections.component.scss']
})
export class PropertyGridCollectionsComponent extends PropertyGridComponent implements OnInit {

  public direction: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'horizontal';
  EstiloBuscarContenedor: any = { height: "100%" };
  @Input() listToConnect: Array<any> = [];
  @Input() controlType: string;
  @Input() listName: string = "drop-list-selection";
  @Input() currentFieldModel: any

  @Input() items: Array<any> = [];
  @Input() imagen: string;
  @Input() extraPropertys: any;
  ngOnInit() {
    
    if (this.controlType == "Column")
      this.imagen = this.helperImages.ImagenField;
    else if (this.controlType == "Action")
      this.imagen = this.helperImages.GetRuleIcon("Action");
    else if (this.controlType == "Parameter")
      this.imagen = this.helperImages.GetRuleIcon("ParametersNode");
    else if (this.controlType == "Button")
      this.imagen = this.helperImages.GetRuleIcon("Button");
    else if (this.controlType == "CommandButton")
      this.imagen = this.helperImages.GetRuleIcon("Button");
    else if (this.controlType == "ChildOption")
      this.imagen = this.helperImages.GetRuleIcon("ChildOption");
    else if (this.controlType == "AttachmentOption")
      this.imagen = this.helperImages.GetRuleIcon("ChildOption");
    else if (this.controlType == "ExportImportItemConfig")
      this.imagen = this.helperImages.GetRuleIcon("ExportImportItemConfig");
    else if (this.controlType == "ChartsChild")
      this.imagen = this.helperImages.GetRuleIcon("Charts");
    else if (this.controlType == "ReportsChild")
      this.imagen = this.helperImages.GetRuleIcon("Dashboard");
    else if (this.controlType == "PagesChild")
      this.imagen = this.helperImages.GetRuleIcon("ChildOption");
    //AttachmentOption
    else
      this.imagen = this.helperImages.ImagenProperty;
  }

  public Getlabel(item) {

    //{{ (item.Label)? item.Label +' ['+item?.ControlType+']':(item.Name)?item.Name+' ['+item?.ControlType+']':item?.Type+' ['+item?.ControlType+']' }}
    let label: any = "NINGUNO";
    if (this.controlType == "Column")
      label = item.Title + ' [' + item.ColumnType + ']';
    else if (this.controlType == "Action")
      label = '[' + item.Type + ']';
    else if (this.controlType == "Parameter")
      label = item.Name + ' [' + item.ParameterType + ']';
    else if (this.controlType == "ActionMappingModel") {
      if (item.SourceProp)
        label = item.Prop + ' <== [' + item.SourceProp + ']';
      else
        label = item.Prop + ' <== [' + item.Value + ']';

    }
    else if (this.controlType == "MappingModel") {
      if (item.SourceProp)
        label = item.To + ' <== [' + item.From + ']';
      else
        label = item.To + ' <== [' + item.From + ']';

    }
    else if (this.controlType == "Button")
      label = item.Name + ' [' + item.SubControlType + ']';
    else if (this.controlType == "ChildOption")
      label = (item.label) ? item.label : item.infoField;
    else if (this.controlType == "CommandButton")
      label = (item.Label) ? item.Label : '' + ' [' + item.Type + ']';

    else if (this.controlType == "AttachmentOption")
      label = (item.Label) ? item.Label : item.InfoField;
    else if (this.controlType == "ExportImportItemConfig") {


      label = (item.FileName) ? item.FileName : '' + ' [' + item.ToType + '][' + item.DestinationType + ']';

    }
    else if (this.controlType == "ChartsChild")
      label = (item.Object) ? item.Object.Name : "ChartChild";
    else if (this.controlType == "ReportsChild")
      label = (item.Object) ? item.Object.Name : "ReportsChild";
    else if (this.controlType == "PagesChild")
      label = (item.intenalNavigationProperty) ? item.intenalNavigationProperty : ((item.externalNavigationProperty) ? item.externalNavigationProperty : "PagesChild");
    else
      label = item.Name + ' [' + item.ControlType + ']';

    return label;
  }

  public GetlabelSeleccionado() {

    //{{ (item.Label)? item.Label +' ['+item?.ControlType+']':(item.Name)?item.Name+' ['+item?.ControlType+']':item?.Type+' ['+item?.ControlType+']' }}
    let label: any = "NINGUNO";
    if (!this.currentFieldModel)
      return label;
    if (this.controlType == "Column")
      label = this.currentFieldModel.Title + ' [' + this.currentFieldModel.ColumnType + ']';
    else if (this.controlType == "Action")
      label = '[' + this.currentFieldModel.Type + ']';
    else if (this.controlType == "Parameter")
      label = this.currentFieldModel.Name + ' [' + this.currentFieldModel.ParameterType + ']';
    else if (this.controlType == "ActionMappingModel") {
      if (this.currentFieldModel.SourceProp)
        label = this.currentFieldModel.Prop + ' <== [' + this.currentFieldModel.SourceProp + ']';
      else
        label = this.currentFieldModel.Prop + ' <== [' + this.currentFieldModel.Value + ']';

    }
    else if (this.controlType == "MappingModel") {
      if (this.currentFieldModel.SourceProp)
        label = this.currentFieldModel.To + ' <== [' + this.currentFieldModel.From + ']';
      else
        label = this.currentFieldModel.To + ' <== [' + this.currentFieldModel.From + ']';

    }
    else if (this.controlType == "Button")
      label = this.currentFieldModel.Name + ' [' + this.currentFieldModel.SubControlType + ']';
    else if (this.controlType == "ChildOption")
      label = (this.currentFieldModel.label) ? this.currentFieldModel.label : this.currentFieldModel.infoField;
    else if (this.controlType == "CommandButton")
      label = (this.currentFieldModel.Label) ? this.currentFieldModel.Label : '' + ' [' + this.currentFieldModel.Type + ']';
    else if (this.controlType == "AttachmentOption")
      label = (this.currentFieldModel.Label) ? this.currentFieldModel.Label : this.currentFieldModel.InfoField;
    else if (this.controlType == "ExportImportItemConfig")
      label = (this.currentFieldModel.FileName) ? this.currentFieldModel.FileName : '' + ' [' + this.currentFieldModel.ToType + '][' + this.currentFieldModel.DestinationType + ']';
    else if (this.controlType == "ChartsChild")
      label = (this.currentFieldModel.Object) ? this.currentFieldModel.Object.Name : "ChartChild";
    else if (this.controlType == "ReportsChild")
      label = (this.currentFieldModel.Object) ? this.currentFieldModel.Object.Name : "ReportsChild";
    else if (this.controlType == "PagesChild")
      label = (this.currentFieldModel.intenalNavigationProperty) ? this.currentFieldModel.intenalNavigationProperty : ((this.currentFieldModel.externalNavigationProperty) ? this.currentFieldModel.externalNavigationProperty : "PagesChild");
    else
      label = this.currentFieldModel.Name + ' [' + this.currentFieldModel.ControlType + ']';

    return label;
  }
  public RemoveItem(currentIndex) {

    //let currentIndex = this.items.findIndex(x => { return x.Id == item.Id; })
    if (currentIndex > -1) {
      this.items.splice(currentIndex, 1);
    }
  }

  public SelectItem(item) {

    //  if(  this.currentFieldModel )
    //  this.currentFieldModel.IsSelected = undefined;
    if (this.controlType == "Action" && !item.ControlType)
      item.ControlType = "Action";
    if (this.controlType == "CommandButton" && !item.ControlType)
      item.ControlType = "CommandButton";

    this.currentFieldModel = item;
    //this.currentFieldModel.IsSelected =true;
  }
  public AddItem() {

    
    if (!this.items)
      this.items = [];
    if (this.controlType == "Column")
      this.items.push(this.utility.GetColumnModel());
    else if (this.controlType == "Action")
      this.items.push(this.utility.GetColumnActionModel());
  
    else if (this.controlType == "ActionMappingModel") {
      this.items.push(this.utility.GetActionMappingModel());
    }
    else if (this.controlType == "MappingModel") {
      this.items.push(this.utility.GetMappingModel());
    }
    else if (this.controlType == "Button") {
      this.items.push(this.utility.GeModelControl("Button"));
    }
    else if (this.controlType == "CommandButton") {
      this.items.push({
        Actions: [],
        Label: null,
        Activo: true,
        Class: 'ms-Icon ms-Icon--',
        Type: 'Custom',
        IsDivider: false,
        ControlType: 'CommandButton',
      
      });
    }
    else if (this.controlType == "ChildOption") {
      this.items.push({
        Actions: [],

        label: null,
        title: null,
        opcionPermiso: 'None',
        isDivider: false,
        campoVersiones: null
      });
    }
    else if (this.controlType == "AttachmentOption") {
      this.items.push({
        Actions: [],
        Label: null,
        Activo: true,
        Class: 'ms-Icon ms-Icon--',
        FilesField: 'UbicacionArchivo',
        FileNameField: 'Nombre',
        IsDivider: false
      });
    }
    else if (this.controlType == "ExportImportItemConfig") {
      this.items.push({});
    }
    else if (this.controlType == "ChartsChild")
      this.items.push({ Object: null, ChartsChild: [] });
    else if (this.controlType == "ReportsChild")
      this.items.push({ Object: null, ReportsChild: [] });
    else if (this.controlType == "PagesChild")
      this.items.push({});
    else
      this.items.push(this.utility.GeModelControl("TextBox"));
  }


  dragStart(event: CdkDragStart) {
    // this._currentIndex = this.fieldModels.indexOf(event.source.data); // Get index of dragged type
    // this._currentField = this.child.nativeElement.children[this._currentIndex]; // Store HTML field
  }

  moved(event: CdkDragMove) {
    // Check if stored HTML field is as same as current field
    // if (this.child.nativeElement.children[this._currentIndex] !== this._currentField) {
    //   // Replace current field, basically replaces placeholder with old HTML content
    //   // this.child.nativeElement.replaceChild(this._currentField, this.child.nativeElement.children[this._currentIndex]);
    // }
  }

  itemDropped(event: CdkDragDrop<any[]>) {


    if (event.previousContainer === event.container) {
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    } else {

    }
  }


  public ExportToJson() {
    
    const filename = this.controlType + '.json';

    const jsonStr = JSON.stringify(this.currentFieldModel, null, 2);// JSON.stringify(this.PaginaSeleccionada.ConfigPage);

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
