import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { Guid } from 'guid-typescript';
import { StaticData } from 'src/app/helpers/static-data';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare var fabric: any;
@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss']
})
export class NotificationsPanelComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() onProcessItems: EventEmitter<any> = new EventEmitter<any>();
  @Input() config: any;
  @Input() name = 'NotificationsPanel_' + Guid.create().toString();
  panelClass = "ms-Panel";
  sidePanel: any;
  SideNotificationsPanel: any;
  notLoad: boolean = false;
  componentName = "NotificationsPanelComponent";
  @Input() items: Array<any> = [];

  @Input() AllItems: Array<any> = [];
  @Input() groupeItems: Array<any> = [];
  panelColor = true;
  isLoaded = false;
  isSetInterval = false;
  currentItem: any;
  isGrouped = false;
  viewMenu = false;
  viewDays = false;
  OriginalNote: any;
  selectedDay: any;
  showLoadNotifyTypeTemp: boolean;
  ArrDays: Array<any> = [];
  constructor(public baseService: BaseServiceService,
    public utility: Utilities, public router: Router,
    public translate: TranslateService) { }

  ngOnInit() {

    this.SetConfig();
  }
  SetConfig(_config?: any) {
    if (_config)
      this.config = _config;
    if (this.config) {
      if (!this.config.ToolTipButton)
        this.config.ToolTipButton = '';
      if (this.config.Name)
        this.name = this.config.Name;

      if (this.config.Categories && this.config.Categories.length > 0) {
        this.viewMenu = true;
      }

      if (this.config.AllowFilterDays) {
        
        this.viewDays = true;
        this.ArrDays = [];
        for (let i = 1; i <= this.config.FilterDays; i++) {
          this.ArrDays.push(i);
        }
      }

      if(this.config.TagReemplazoNota)
      {
        
        this.OriginalNote =  this.config.Nota;

        if(this.selectedDay)
        {
          this.OriginalNote = this.OriginalNote.replace(this.config.TagReemplazoNota, this.selectedDay);
        }
        else
        {
          this.OriginalNote = this.OriginalNote.replace(this.config.TagReemplazoNota, "15");
        }
      }

    }
  }
  ngAfterViewInit(): void {

    //this.Init();
  }
  public OpenSidePanel() {


    this.SideNotificationsPanel = null;
    if (!this.sidePanel)
      this.sidePanel = document.querySelector("#" + this.name);
    if (this.sidePanel)
      this.SideNotificationsPanel = new fabric['Panel'](this.sidePanel);
  }
  public Init(_config?: any) {

    this.showLoadNotifyTypeTemp = this.baseService.showLoadNotifyType;
    if (_config)
      this.SetConfig(_config);
    this.sidePanel = document.querySelector("#" + this.name);
    this.ExcecuteDataSource();

    //Funcionalidad de auto actualizacion
    if (this.config.AutoUpdate && this.config.AutoUpdateTime) {
      setInterval(() => {
        if (this.selectedDay) {
          this.FilterDays(this.selectedDay, 0);
        }
        else {
          this.ExcecuteDataSource()
        }
      }, this.config.AutoUpdateTime);

    }

  }
  public GetDefault() {
    let notification = { Title: 'No Encotrada', Msg: 'No Encotrada', Icon: '', Type: 'ms-MessageBar' };
    return notification;
  }

  public InternalProcessItems(result, _items: Array<any>, processType?: any) {

    let item: any = {};
    item.Title = (this.config.TitleField) ? result[this.config.TitleField] : '';
    item.Msg = (this.config.MsgField) ? result[this.config.MsgField] : '';
    item.Filter = (this.config.TypeField) ? result[this.config.TypeField] : 'info';
    item.LinkMsg = (this.config.LinkMsgField) ? result[this.config.LinkMsgField] : '';
    item.Category = (this.config.CategoryField) ? result[this.config.CategoryField] : '';
    item.PagePath = result['PagePath'];

    let notification = this.config.Notifications.find(x => x.Name.toLowerCase() == item.Filter.toLowerCase());

    let notificationClone = (!notification) ? this.GetDefault() : this.utility.Clone(notification);
    if (!notificationClone.ToolTipItems)
      notificationClone.ToolTipItems = [];

    notificationClone.Title = (item.Title) ? (item.Title) : notificationClone.Title;
    if (notificationClone.Title && notificationClone.Title.includes("#")) {
      notificationClone.Title = this.utility.ReplaceTextFromModels(result, notificationClone.Title);
    }
    notificationClone.Msg = (item.Msg) ? (item.Msg) : notificationClone.Msg;
    if (notificationClone.Msg && notificationClone.Msg.includes("#")) {
      notificationClone.Msg = this.utility.ReplaceTextFromModels(result, notificationClone.Msg);
    }
    notificationClone.LinkMsg = (item.LinkMsg) ? (item.LinkMsg) : notificationClone.LinkMsg;
    if (notificationClone.LinkMsg && notificationClone.LinkMsg.includes("#")) {
      notificationClone.LinkMsg = this.utility.ReplaceTextFromModels(result, notificationClone.LinkMsg);
    }
    notificationClone.PagePath = (item.PagePath) ? (item.PagePath) : notificationClone.PagePath;
    if (notificationClone.v && notificationClone.PagePath.includes("#")) {
      notificationClone.PagePath = this.utility.ReplaceTextFromModels(result, notificationClone.PagePath);
    }

    notificationClone.Category = (item.Category) ? (item.Category) : "";

    if (notificationClone.Script) {
      if (notificationClone.Script.includes("#")) {
        notificationClone.Script = this.utility.ReplaceTextFromModels(result, notificationClone.LinkMsg);
        if (notificationClone.Script.includes("#msg#"))
          notificationClone.Script = notificationClone.Script.replace("#msg#", notificationClone.Msg);
      }
      notificationClone.Msg = this.ExecuteSCript(notificationClone.Script, notificationClone.IsEValScript)
    }
    if (notificationClone.ToolTipItems.length > 0) {
      notificationClone.ToolTipItems.forEach(toolTipItem => {
        toolTipItem.Text = '';
        toolTipItem.Class = 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 title';
        switch (toolTipItem.Type) {

          case "Field":

            toolTipItem.Text = result[toolTipItem.Value];
            if (toolTipItem.Script) {
              if (toolTipItem.Script.includes("#msg#"))
                toolTipItem.Script = toolTipItem.Script.replace("#msg#", toolTipItem.Text);
              toolTipItem.Text = this.ExecuteSCript(toolTipItem.Script, toolTipItem.IsEValScript)
            }
            break;
          case "Value":
            toolTipItem.Text = toolTipItem.Value;
            if (toolTipItem.Script) {
              if (toolTipItem.Script.includes("#msg#"))
                toolTipItem.Script = toolTipItem.Script.replace("#msg#", toolTipItem.Text);
              toolTipItem.Text = this.ExecuteSCript(toolTipItem.Script, toolTipItem.IsEValScript)
            }
            if (toolTipItem.Text && toolTipItem.Text.includes("#"))
              toolTipItem.Text = this.utility.ReplaceTextFromModels(result, toolTipItem.Text);

            break;
          case "CampoSplit":
            toolTipItem.Text = toolTipItem.Value;
            if (toolTipItem.Text && toolTipItem.Text.includes("#"))
              toolTipItem.Text = this.utility.ReplaceTextFromModels(result, toolTipItem.Text);
            break;
          case "Divider":
            toolTipItem.Class = 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 divider my-3';

            break;
        }



      });
    }
    if (processType)
      this.items.push(notificationClone);
    else
      _items.push(notificationClone);
  }



  // ToolTipItemTypes: Array<any> = [
  //   { Label: 'Campo', Name: 'Field' },
  //   { Label: 'Valor', Name: 'Value' },
  //   { Label: 'CampoSplit', Name: 'SplitField' }
  // ];
  public ProcessItems(dataResults: Array<any>, processType?: any) {

    let _items = [];
    dataResults.forEach(result => {

      this.InternalProcessItems(result, _items, processType);

    });

    //   if (this.IsEdit && _config.msg && _config.msg.includes("#"))
    //   this.msg = this.utility.ReplaceTextFromModels(this.modelo, _config.msg);
    // else
    //   this.msg = _config.Msg;
    if (!processType)
      this.items = _items;
    this.config.count = this.items.length;
    this.config.ToolTipButtonTemp = this.config.ToolTipButton;

    if (this.config.ToolTipButtonTemp && this.config.ToolTipButtonTemp.includes("#"))
      this.config.ToolTipButtonTemp = this.config.ToolTipButtonTemp.replace("#count#", this.config.count);
    this.onProcessItems.emit({ count: this.items.length, name: this.config.Name });

    if (!this.isSetInterval) {

      this.isSetInterval = true;
      // setInterval(() => {
      //   

      //   this.items=[];

      //   setTimeout(() => {
      //     
      //     this.ExcecuteDataSource();
      //   }, 800);
      // }, 2000);
    }
  }
  public Refresh() {
    this.items = [];
    setTimeout(() => {
      
      if (this.selectedDay) {
        this.FilterDays(this.selectedDay, 0);
      }
      else {
        this.ExcecuteDataSource(true);
      }
    }, 800);
  }
  public ExcecuteDataSource(processType?: any) {
    let obj = this.utility.GetApiModel('ExcecuteDataSource', null);

    try {

      this.config.ToolTipButtonTemp = this.config.ToolTipButton;
      if (!this.config || this.config.DataSources.length == 0 || this.notLoad) {
        return;
      }
      this.items = [];
      // if (filterParent)
      //   this.filterParent = filterParent;
      // const worker = new Worker ('./my-worker.worker', {type: 'module'}); 
      obj.QueryConfigs = [];
      let dataResults: Array<any> = [];
      let index = 0;
      this.config.DataSources.forEach(ds => {

        let queryConfig = this.utility.Clone(ds);
        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = index;
          if (queryConfig.CrudAction == 'GetAll' || queryConfig.CrudAction == 'GetAllOrderBy'
            || queryConfig.CrudAction == 'GetAllOrderByDesc') {
            queryConfig.Entity = {};
          }
        }

        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, null, null, queryConfig, null, this, null);
          })
        }

        obj.QueryConfigs.push(queryConfig);
        //this.dataSet.push({ index: index, data: null });
        index++;
      });
      // if (this.config.AutoUpdate && this.config.AutoUpdateTime)
      //   this.baseService.showLoadNotifyType = false;
      this.baseService.MultiGet(obj, null,
        {
          componentName: "NotificationsPanelComponent",
          method: 'ExcecuteDataSource',
          controlName: this.config.Name,
          processMessage: this.config.ProcessMessage
        }, this.config.Controller, false, false, (this.config.AutoUpdate && this.config.AutoUpdateTime) ? 'None' : undefined)
        .then(data => {
          //this.baseService.showLoadNotifyType = this.showLoadNotifyTypeTemp;
          index = 0;

          data.Data.forEach(dtt => {
            if (Array.isArray(dtt)) {
              dataResults = dataResults.concat(dtt);
            }
            else {
              dataResults.push(dtt);
            }
            index++;
          });
          this.isLoaded = true;
          this.ProcessItems(dataResults, processType);

        }).catch(err => {
          // this.baseService.showLoadNotifyType = this.showLoadNotifyTypeTemp;
          this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
          this.utility.VerModalDanger().then(data => { })
        });

    }
    catch (error) {
      //this.baseService.showLoadNotifyType = this.showLoadNotifyTypeTemp;
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
      this.utility.VerModalDanger().then(data => { })
    }
  }

  OnClickLink(item) {

    /*var CurrentUrl = window.location.href;
    
    window.location.href = CurrentUrl.substring(0, CurrentUrl.lastIndexOf("#")+1) + '/' + item.PagePath;*/
    this.router.navigate([item.PagePath]);
  }
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }


  ExecuteSCript(script, isEval) {
    let result = null;
    if (script) {
      if (isEval)
        result = eval(script);
      else {
        let functionGeneric = new Function(script);
        result = functionGeneric();
      }
    }
    return result;
  }

  ProcesarUrlNavigation(item: any) {

    this.currentItem = item;
    let queryString: Array<string> = [];
    let queryParams = {};
    if (item.MappingParameters && item.MappingParameters.length > 0) {
      item.MappingParameters.forEach(parameter => {

        if (!parameter.ModelContainer)
          parameter.ModelContainer = "currentItem";
        const value = this.utility.GetParmeterValue(parameter, item, null, item, null, this, null);
        queryString.push(`'${parameter.Name}'='${value}' `);
        queryParams[parameter.Name] = value;
      });
    }

    if (item.PagePath) {
      if (queryString.length > 0) {
        this.utility.router.navigate([item.PagePath], { queryParams: queryParams });
      }
      else
        this.utility.router.navigate([item.PagePath]);
    }
    else if (item.ExtenalNavigation) {

      if (queryString.length > 0) {
        item.ExtenalNavigation += "?" + queryString.join("&");
      }

      if (item.ExtenalNavigation.includes("window.open")) {
        eval(item.ExtenalNavigation);
      }
      else
        window.location.href = item.ExtenalNavigation
    }

  }
  openTooltip(e: Event) {
    const item = $(e.currentTarget);
    const tooltip = item.find(".np__notification--tooltip");
    if (tooltip) {
      if (item.position().top > $(window).height() / 2) {
        tooltip.css("top", item.position().top - 16 - tooltip.find(".ms-Grid").height() + 58);
      } else {
        item.find(".np__notification--tooltip").css("top", item.position().top - 16);
      }
    }
  }

  public GroupedByCategory() {

    this.groupeItems = [];
    this.isGrouped = !this.isGrouped;

    if (this.isGrouped) {
      this.config.Categories.forEach(category => {
        category.Selected = false;
      })
      if (this.AllItems.length > 0)
        this.items = this.utility.Clone(this.AllItems);
      this.AllItems = [];

      this.config.Categories.forEach(category => {

        const group = { Label: category.Label, Category: category, Items: this.items.filter(x => x.Category == category.Name) };
        group.Label = group.Label + ' -- ' + group.Items.length.toString();
        this.groupeItems.push(group);

      })

    }
    else {

    }
  }

  public FilterCategory(item, index) {


    item.Selected = !item.Selected;
    this.config.Categories.forEach(category => {
      if (item.Name != category.Name)
        category.Selected = false;
    })
    if (item.Selected) {
      this.isGrouped = false;
      this.groupeItems = [];
      if (this.AllItems.length == 0)
        this.AllItems = this.utility.Clone(this.items);

      this.items = this.AllItems.filter(x => x.Category == item.Name);
    }
    else {
      if (this.AllItems.length > 0)
        this.items = this.utility.Clone(this.AllItems);
    }

  }

  public FilterDays(item, index) {

    
    this.selectedDay = item;
    var NotaActual = this.config.Nota;
    this.OriginalNote = NotaActual.replace(this.config.TagReemplazoNota, item);
    //this.config.Nota = NotaActual.replace(this.config.TagReemplazoNota, item);
    let obj = this.utility.GetApiModel('ExcecuteDataSource', null);

    try {

      this.config.ToolTipButtonTemp = this.config.ToolTipButton;
      if (!this.config || this.config.DataSources.length == 0 || this.notLoad) {
        return;
      }
      this.items = [];
      // if (filterParent)
      //   this.filterParent = filterParent;
      // const worker = new Worker ('./my-worker.worker', {type: 'module'}); 
      obj.QueryConfigs = [];
      let dataResults: Array<any> = [];
      let index = 0;
      this.config.DataSources.forEach(ds => {

        let queryConfig = this.utility.Clone(ds);
        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        queryConfig.Entity["queryDays"] = item;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = index;
          if (queryConfig.CrudAction == 'GetAll' || queryConfig.CrudAction == 'GetAllOrderBy'
            || queryConfig.CrudAction == 'GetAllOrderByDesc') {
            queryConfig.Entity = {};
          }
        }

        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, null, null, queryConfig, null, this, null);
          })
        }

        obj.QueryConfigs.push(queryConfig);
        //this.dataSet.push({ index: index, data: null });
        index++;
      });
      // if (this.config.AutoUpdate && this.config.AutoUpdateTime)
      //   this.baseService.showLoadNotifyType = false;
      this.baseService.MultiGet(obj, null,
        {
          componentName: "NotificationsPanelComponent",
          method: 'ExcecuteDataSource',
          controlName: this.config.Name,
          processMessage: this.config.ProcessMessage
        }, this.config.Controller, false, false, (this.config.AutoUpdate && this.config.AutoUpdateTime) ? 'None' : undefined)
        .then(data => {
          //this.baseService.showLoadNotifyType = this.showLoadNotifyTypeTemp;
          index = 0;

          data.Data.forEach(dtt => {
            if (Array.isArray(dtt)) {
              dataResults = dataResults.concat(dtt);
            }
            else {
              dataResults.push(dtt);
            }
            index++;
          });
          this.isLoaded = true;
          this.ProcessItems(dataResults, true);

        }).catch(err => {
          // this.baseService.showLoadNotifyType = this.showLoadNotifyTypeTemp;
          this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
          this.utility.VerModalDanger().then(data => { })
        });

    }
    catch (error) {
      //this.baseService.showLoadNotifyType = this.showLoadNotifyTypeTemp;
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
      this.utility.VerModalDanger().then(data => { })
    }

  }

  getTranslatedMessage(message: string): string {
    // Extraer el número usando una expresión regular
    const numberMatch = message.match(/\d+/);
    const number = numberMatch ? numberMatch[0] : '';

    // Dividir el texto en dos partes: antes y después del número
    const [beforeNumber, afterNumber] = message.split(number);

    // Traducir las partes fija (antes y después del número)
    const translatedBefore = this.translate.instant(beforeNumber.trim());
    const translatedAfter = this.translate.instant(afterNumber.trim());

    // Reconstruir el mensaje
    return `${translatedBefore} ${number} ${translatedAfter}`.trim();
  }
}
