import { Component, OnInit, Input } from '@angular/core';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { ControlActions } from 'src/app/models/general.enum';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit {

  @Input() isDesigner: boolean = false;
  @Input() url: string = null;
  @Input() isImage: boolean = false;
  @Input() isVideo: boolean = false;
  @Input() isAudio: boolean = false;
  @Input() isOffice: boolean = false;
  @Input() isPdf: boolean = false;

  @Input() viewerType: string = "google";
  @Input() config: any;
  @Input() modelo: any;
  @Input() permissionsConfig: any;
  @Input() item: any;
  verAlerta: boolean = false;
  @Input() nameDocument: string = null;
  constructor(public helperActions: HelperActions) { }

  //* FUNCIONES ANGULAR
  ngOnInit() {
  debugger
    this.Init();

  }
  SetExtensionesImagen(extension: string) {
    this.isImage = (extension == "png" || extension == "jpg" || extension == "jpeg" || extension == "gif");
    if (this.isImage)
      //document.oncontextmenu = function(){return false}
      $(document).ready(function () {

        //Disable part of page
        $(".imagenMostrar").on("contextmenu",function(e){
            return false;
        });
    });

    $(document).on('dragstart', 'img', function(evt) {
      evt.preventDefault();
    });
  }
  SetExtensionesVideo(extension: string) {
    this.isVideo = (extension == "mp4" || extension == "ogv" || extension == "webm" || extension == "avi" || extension == "divx" || extension == "mov" || extension == "mpg" || extension == "mkv" || extension == "wmv" || extension == "wpl");
  }
  SetExtensionesAudio(extension: string) {
    this.isAudio = (extension == "mp3" || extension == "ogg" || extension == "wav" || extension == "opus" || extension == "wma" || extension == "flac" || extension == "midi" || extension == "m3u");
  }
  SetExtensionesOffice(extension: string) {
    this.isOffice = (extension == "ppt" || extension == "pptx" || extension == "doc" || extension == "docx" || extension == "xls" || extension == "xlsx");
    //Para TipoUbicacion 'FileSystem' comentar lineas de abajo, el visor de office no funciona, en cambio dejar
    //viewerType = "google"; que viene por defecto (en el visor de goole si se puede ver el archivo)
    /**
     * Inicio Edward Morales 02022024
     * Ajuste provisional por conflicto de seguridad SSL y Dominio
     * 
     * Se realizaron validaciones con viso de office, solo permite visualizar documentos office
     * si el origen tiene certificado SSL y/o Dominio, de lo contrario no permite
     * los demás documentos por no tener extensión office entrar a visor de google para su respectiva
     * visualización.
     */
    if (this.isOffice)
      this.viewerType = "google"; //office 
  }
  SetExtensionesPDF(extension: string) {
    this.isPdf = (extension == "pdf");

    if (this.isPdf && this.viewerType != "Preview")
      this.viewerType = "pdf";
  }
  SetExtension() {
    if (this.url) {
      const extension: string = this.url.substring(this.url.lastIndexOf('.') + 1).toLowerCase();
      this.SetExtensionesImagen(extension);
      if (!this.isImage) {
        this.SetExtensionesPDF(extension)
        if (!this.isPdf)
          this.SetExtensionesVideo(extension);
        if (!this.isVideo)
          this.SetExtensionesAudio(extension)
        if (!this.isAudio)
          this.SetExtensionesOffice(extension)
      }
    }
    if (!this.isImage && this.nameDocument) {
      const extension: string = this.nameDocument.substring(this.nameDocument.lastIndexOf('.') + 1).toLowerCase();
      this.SetExtensionesImagen(extension);
      if (!this.isImage) {
        this.SetExtensionesPDF(extension)
        if (!this.isPdf)
          this.SetExtensionesVideo(extension);
        if (!this.isVideo)
          this.SetExtensionesAudio(extension)
        if (!this.isAudio)
          this.SetExtensionesOffice(extension)
      }
    }
  }
  DeleteUrl() {
    this.url = null;
  }
  height = "";
  width = "";
  public Init() {
    if (this.config) {

      if (!this.config.Style)
        this.config.Style = {};
      if (Object.keys(this.config.Style).length == 0) {
        this.config.Style["width"] = "100%";
        this.config.Style["height"] = "93vh";
      }

      this.height = this.config.Style["height"];
      this.width = this.config.Style["width"];
      if (this.config.Url)
        this.url = this.config.Url;

      if (this.config.ViewerType)
        this.viewerType = this.config.ViewerType;

    }

    this.SetExtension();

    if (this.item)
      this.View();
    else

      this.VerAlert();
  }
  OnChangeHeight() {
    this.config.Style["height"] = this.height;
  }
  OnChangeWidth() {
    this.config.Style["width"] = this.width;
  }

  OnChangeViewerType() {
    this.viewerType = this.config.ViewerType;
  }
  VerAlert() {
    if (!this.url) {
      this.verAlerta = true;
    }
    else
      this.verAlerta = false;
  }
  //* END FUNCIONES ANGULAR

  View() {
    //ControlActions

    let action = { ComponentName: this.config.Controller };

    let option = {};
    this.helperActions.DownloadFile(action, option, this.item, this, null, null, null, ControlActions.ViewDocument, null, {});

    // setTimeout(() => {
    //
    //   this.url = "https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.doc";//"http://localhost:58205/TempDocs/PLANTILLA_FINLA_EXPEDIENTE.docx";
    //   this.VerAlert();
    // }, 500);

  }
}
