import { Component, OnInit } from '@angular/core';
import { SolicitudPqrdComponent } from '../solicitud-pqrd/solicitud-pqrd.component';

@Component({
  selector: 'app-pqrd-recepcion',
  templateUrl: './pqrd-recepcion.component.html',
  styleUrls: ['./pqrd-recepcion.component.css']
})
export class PqrdRecepcionComponent extends SolicitudPqrdComponent implements OnInit {
  public OnInitEnd() {
    this.postSaveEmitter.subscribe(modelo => {
      
      this.utility.VerModalOk("Se recibió la Solicitud con código: " + "\n\r" + modelo.data.data.Codigo);
    });
  }
}
