import "rxjs/add/operator/filter";

import * as $ from "jquery";

//import { Component, ViewChild} from "@angular/core";
import { Component, ViewChild, HostListener } from "@angular/core";

import { DynamicResourceLoaderServiceService } from "./services/dynamic-resource-loader-service.service";
import { GeneralComunicationService } from "./services/general-comunication.service";
import { Location } from "@angular/common";
import { ModalLoginComponent } from "./components/modal-login/modal-login.component";
import { Router } from "@angular/router";
import { StaticData } from "./helpers/static-data";
import { Subscription } from "rxjs/Subscription";
import { Listener } from "selenium-webdriver";
import { BaseServiceService } from "./services/base-service.service";
import { Utilities } from "./helpers/utilities";
import { CrudActions } from "./models/general.enum";

// import { NavbarComponent } from '../../components/navbar/navbar.component';










@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent {
    constructor(
        public comunicationService: GeneralComunicationService,
        public location: Location,
        private router: Router,
        public dynamicResourceLoaderService: DynamicResourceLoaderServiceService,
        public baseService: BaseServiceService, public utility: Utilities
    ) {


    }

    /*@HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event) {
        //debugger;
        var a = StaticData;
        const obj = this.utility.GetApiModel("CloseUserSession", "", CrudActions.None, null, null);
        this.baseService.CloseUserSession(obj, 
            { componentName: "AppComponent", method: "beforeUnloadHandler", processMessage: "" },
            "AuthenticationBase", false, false).then(data => {
            }).catch(error => {
                console.log(error);
            });
    }

    @HostListener('document:visibilitychange', ['$event'])
    loadHandler(event) {
        //debugger;
        if(StaticData.Usuario)
        {
            const obj = this.utility.GetApiModel("GetUserSession", "", CrudActions.None, null, null);
            this.baseService.GetUserSession(obj, 
                { componentName: "AppComponent", method: "focusHandler", processMessage: "" },
                "AuthenticationBase", false, false).then(data => {
                    if(data.Data.SessionActive == 0)
                    {
                        this.utility.VerModalWarning(
                            { titulo: "Mensaje Alerta", descripcion: "Esta sesion ha sido finalizada previamente por el usuario desde otra pestaña/navegador/dispositivo", verOk: false, verCancelar: false, ok: "ACEPTAR", cancelar: "NO" }).then(result => {
                             });
                             setTimeout(function(){
                                    StaticData.Usuario = null;
                                    StaticData.Estilos = {};
                                    StaticData.ConfigPaginas = [];
                                    location.reload();
                              }, 5000);
                    }
                }).catch(error => {
                    console.log(error);
                });
        }       
    }*/    

    inSharePoint = false;
    title = "Holassaas";
    private _router: Subscription;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    @ViewChild(ModalLoginComponent, { static: true })
    modalLogin: ModalLoginComponent;

    dialogModalResult: any;

    public get IsLoged(): any {
        return (StaticData.Usuario != null);
    }

    get VerColorConfig(): boolean {
        return StaticData.GeneralConfig.ConfigUI.VerColorConfig;
    }

    get VerNavBar(): boolean {
        return StaticData.GeneralConfig.ConfigUI.VerNavBar;
    }

    get VerFloatPanelConsolaLog(): boolean {
        return StaticData.GeneralConfig.ConfigUILog.ConsoleLog;
    }
    public _OpenOverly() {

        StaticData.Overlay.OpenOverly();
    }

    private LoadAll() {

        //this.dynamicResourceLoaderService.LoadAll();
    }

    ngOnInit() {

        this.inSharePoint = StaticData.InSharePoint;

        if (this.inSharePoint)
            $('#globalNavBox').hide(); //oculto mediante id
        this.comunicationService.receivedLoginEvent.subscribe(data => {
            this.login();
        });


        // if (StaticData.DynamicLoadResources) {

        //   this.LoadAll();
        // }
        const isWindows = navigator.platform.indexOf("Win") > -1 ? true : false;


        const window_width = $(window).width();
        const $sidebar = $(".sidebar");
        const $sidebar_responsive = $("body > .navbar-collapse");
        const $sidebar_img_container = $sidebar.find(".sidebar-background");

        if (window_width > 767) {
            if ($(".fixed-plugin .dropdown").hasClass("show-dropdown")) {
                $(".fixed-plugin .dropdown").addClass("open");
            }
        }

        $(".fixed-plugin a").click(function (event) {
            
            // Alex if we click on switch, stop propagation of the event, so the dropdown will not be hide, otherwise we set the  section active
            if ($(this).hasClass("switch-trigger")) {
                if (event.stopPropagation) {
                    event.stopPropagation();
                } else if (window.event) {
                    window.event.cancelBubble = true;
                }
            }
        });

        $(".fixed-plugin .badge").click(function () {
            // Cambia color del item seleccionado en el menú


            const $full_page_background = $(".full-page-background");

            $(this)
                .siblings()
                .removeClass("active");
            $(this).addClass("active");

            const new_color = $(this).data("color");

            if ($sidebar.length !== 0) {
                $sidebar.attr("data-color", new_color);
            }

            if ($sidebar_responsive.length != 0) {
                $sidebar_responsive.attr("data-color", new_color);
            }
        });

        $(".background-color div .badge").click(function () {
            // Cambiar Background sidebar
            const newBG = $(this).attr("data-color");
            $("div .sidebar").attr("data-background-color", newBG);
        });

        $(".fixed-plugin .img-holder").click(function () {
            // Cambia fondo (Imágen)
          

            const $full_page_background = $(".full-page-background");

            $(this)
                .parent("li")
                .siblings()
                .removeClass("active");
            $(this)
                .parent("li")
                .addClass("active");

            const new_image = $(this)
                .find("img")
                .attr("src");

            if ($sidebar_img_container.length != 0) {
                $sidebar_img_container.fadeOut("fast", function () {
                    $sidebar_img_container.css(
                        "background-image",
                        "url(\"" + new_image + "\")"
                    );
                    $sidebar_img_container.fadeIn("fast");
                });
            }

            if ($full_page_background.length != 0) {
                $full_page_background.fadeOut("fast", function () {
                    $full_page_background.css(
                        "background-image",
                        "url(\"" + new_image + "\")"
                    );
                    $full_page_background.fadeIn("fast");
                });
            }

            if ($sidebar_responsive.length != 0) {
                $sidebar_responsive.css("background-image", "url(\"" + new_image + "\")");
            }
        });
    }

    ngAfterViewInit() {
        this.runOnRouteChange();
    }

    isMaps(path) {
        let titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        if (path == titlee) {
            return false;
        } else {
            return true;
        }
    }

    runOnRouteChange(): void {
        if (window.matchMedia("(min-width: 960px)").matches && !this.isMac()) {
            const elemMainPanel = document.querySelector(".main-panel") as HTMLElement;
            /*const ps = new PerfectScrollbar(elemMainPanel);
            ps.update();*/
        }
    }

    isMac(): boolean {
        let bool = false;
        if (
            navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
            navigator.platform.toUpperCase().indexOf("IPAD") >= 0
        ) {
            bool = true;
        }
        return bool;
    }

    login() {
        this.modalLogin.open();
    }
    resultLogin(data: any = null) {
        this.dialogModalResult = data;
    }
}




