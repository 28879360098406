import { Component, OnInit } from '@angular/core';
import { GeneralFormBase } from 'src/app/models/general-form-base';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-rtipo-respuesta-pqrd',
  templateUrl: './rtipo-respuesta-pqrd.component.html',
  styleUrls: ['./rtipo-respuesta-pqrd.component.css']
})
export class RtipoRespuestaPqrdComponent extends GeneralFormComponent implements OnInit {

  /*CheckEsCierre: any;
  CheckEsProrroga: any;
  CheckEsAclaracion: any;

  ngAfterViewInit() {
    this.CheckEsCierre = this.form.controls["EsCierre"];
    this.CheckEsProrroga = this.form.controls["EsProrroga"];
    this.CheckEsAclaracion = this.form.controls["EsAclaracion"];

    if (this.CheckEsCierre) {
      debugger;
      this.CheckEsCierre.valueChanges.subscribe(value => {
        debugger;
        if (value != null && value != '') {
          if (!value) {

            if(!this.modelo.modelo.EsProrroga && !this.modelo.modelo.EsAclaracion)
            {
              this.modelo.modelo.CheckSeleccted = null;
            }

            this.SetModelToControls();
            $("input[id='EsCierre']").prop('checked', false);
          }
          else {

            this.modelo.modelo.CheckSeleccted = 'Lleno';
            this.modelo.modelo.EsProrroga = false;
            this.modelo.modelo.EsAclaracion = false;
            this.SetModelToControls();
            $("input[id='EsCierre']").prop('checked', true);
            $("input[id='EsProrroga']").prop('checked', false);
            $("input[id='EsAclaracion']").prop('checked', false);

          }
        }
      });
    }

    if (this.CheckEsAclaracion) {
      debugger;
      this.CheckEsAclaracion.valueChanges.subscribe(value => {
        debugger;
        if (value != null) {
          if (!value) {

            if(!this.modelo.modelo.EsCierre && !this.modelo.modelo.EsProrroga)
            {
              this.modelo.modelo.CheckSeleccted = null;
            }

            this.SetModelToControls();
            $("input[id='EsAclaracion']").prop('checked', false);
          }
          else {

            this.modelo.modelo.CheckSeleccted = 'Lleno';
            this.modelo.modelo.EsProrroga = false;
            this.modelo.modelo.EsCierre = false;
            this.SetModelToControls();
            $("input[id='EsCierre']").prop('checked', false);
            $("input[id='EsProrroga']").prop('checked', false);
            $("input[id='EsAclaracion']").prop('checked', true);

          }
        }
      });
    }

    if (this.CheckEsProrroga) {
      debugger;
      this.CheckEsProrroga.valueChanges.subscribe(value => {
        debugger;
        if (value != null) {
          if (!value) {

            if(!this.modelo.modelo.EsCierre && !this.modelo.modelo.EsAclaracion)
            {
              this.modelo.modelo.CheckSeleccted = null;
            }

            this.SetModelToControls();
            $("input[id='EsProrroga']").prop('checked', false);
          }
          else {

            this.modelo.modelo.CheckSeleccted = 'Lleno';
            this.modelo.modelo.EsProrroga = false;
            this.modelo.modelo.EsAclaracion = false;
            this.SetModelToControls();
            $("input[id='EsCierre']").prop('checked', false);
            $("input[id='EsProrroga']").prop('checked', true);
            $("input[id='EsAclaracion']").prop('checked', false);

          }
        }
      });
    }

    super.ngAfterViewInit();
  }*/
  public GuardarForm(form: FormGroup, configButtons?: any) {
    //debugger;
    if ((!this.modelo.modelo.EsCierre || this.modelo.modelo.EsCierre == '') &&
        (!this.modelo.modelo.EsProrroga || this.modelo.modelo.EsProrroga == '') &&
        (!this.modelo.modelo.EsAclaracion || this.modelo.modelo.EsAclaracion == '') &&
        (!this.modelo.modelo.EsTraslado || this.modelo.modelo.EsTraslado == '')) {
      this.utility.VerModalError("Por favor seleccione una de los cuatro tipos de respuesta disponibles e intentelo nuevamente");
    }
    else{
      super.GuardarForm(form,configButtons);
    }
  }
}
