import {
    Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
    QueryList, forwardRef, Inject, OnDestroy
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, from, Subject, Subscription } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes } from '../models/general.enum';
import { Utilities } from '../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../services/base-service.service';
import { ModalNotifyComunicationService } from '../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../assets/linqts/compilado/index';
//import { asEnumerable } from 'linq-js';
import { StaticData } from '../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { ConfigWindow } from '../models/config-window';
import { ObservacionesModificacionVersionComponent } from '../components/controls/observaciones-modificacion-version/observaciones-modificacion-version.component';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { BaseDataTable } from './base-data-table';
import { ComboBoxManager } from './combo-box-manager';
import { ComponentsContainer } from '../models/components-container';
import { DataTableComponent } from '../components/controls/data-table/data-table.component';
import { FormDetailsComponent } from '../components/controls/form-details/form-details.component';

export class BaseCrud extends BaseDataTable implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

    componentsContainer: ComponentsContainer = new ComponentsContainer();
    formSubscription: Subscription;
    public parentCallRefresh: Subject<any> = new Subject();
    //@ViewChildren(DataTableDirective) dtElement: QueryList<DataTableDirective>;
    @ViewChildren(BaseCrud) subComponentes: QueryList<BaseCrud>;
    @Input() invocarCompHijo = new EventEmitter<any>();
    @Input() funcionInvocacionPadre: (model: any) => void;
    @Output() onInitialized = new EventEmitter<any>();

    // childComponents: Array<any> = [];
    public guardarValido: boolean = false;
    @Input() form: FormGroup;
    itemsSelectControls: any = {};
    // @Input() cargarGrilla: boolean = true;
    // @Input() containerComponentName: string;
    // pkName: string = null;
    // @Input() pkValueEdit: any;
    // modelo: any;
    // @Input() isSetRuleEngine: boolean = false;
    // @Input() isHtmlDataTable: boolean = false;
    // @Input() isAddCrudColumns: boolean = true;
    // @Input() isAddEventsColumns: boolean = false;
    // @Input() withPagination: boolean = false;
    // @Input() hideForm: boolean = false;
    // @Input() hideDataTable: boolean = false;
    @Input() viewComponentHeader: boolean = true;
    @Input() viewComponentTitle: boolean = true;
    //@Input() Pagina: any = { Valida: false, ConfigPage: { ExtraControls: [], Controls: [] } };
    PaginaTemp: any = null;
    // @Input() childOptions: any = null;
    //@Input() configColumnasEditarEliminar: any = null;
    // public exportar: string = null;

    redictInfo: any;

    modeloCascada: any = {};

    // @Input() externalFilter: any = null;
    // @Input() filterParent: any = null;
    // @Input() mappingFilter: any = null;
    // @Input() generalFilter: any = null;
    // isModal: boolean = false;
    // isLoaded: boolean = false;
    // filterModelTemp: any = null;
    // @Input() parentOption: any = null;
    // idGrillaQuery: any = undefined;
    // public filterModal: any = null;
    // dtOptions: any = {};
    // items: any;

    // @Input() columns: any = [];
    // @Input() conFiltroIdEmpresa: boolean = true;
    // arrConfigs: Array<any> = [];

    // botonProcesando: boolean = false;
    // textoBoton: string = 'Guardar';
    // textoBotonProcesando: string = '';


    // nombreBotonEliminar: string = 'btnEliminar';
    // nombreBotonEditar: string = 'btnEditar';
    // nombreBotonChildOptions: string = 'childOptions';


    // dataTableControl: any;
    @Output() loadedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();


    constructor(public baseService: BaseServiceService,
        public utility: Utilities,
        public enumPagina: Paginas,
        public componentName: string,
        public include: any,
        public isUnion: boolean = false,
        public configs: any = {}) {

        super(baseService, utility, enumPagina, componentName, include, isUnion, config);

        // this.nombreBotonChildOptions = this.nombreBotonChildOptions + this.componentName;
        // this.nombreBotonEliminar = this.nombreBotonEliminar + this.componentName;
        // this.nombreBotonEditar = this.nombreBotonEditar + this.componentName;

        // this.Pagina = this.utility.ValidateUserAndPage(this.enumPagina, false, this.componentName, 'Contructor');
        // this.SetProcesando(false);
        // this.Limpiar();

        // this.ExcecuteFuntion(ControllerMethods.Constructor, true);


    }

    public Redireccion(data?: any) {

    }
    public onSpecificStart() {

    }

    public SetProcesando(ok: boolean) {
        this.botonProcesando = ok;
        if (ok) {
            this.textoBoton = '';
            this.textoBotonProcesando = 'Ejecutando.......';
        }
        else {
            this.textoBoton = (this.modelo && (this.modelo.PkValue == 0 || this.modelo.PkValue == undefined) || !this.modelo) ? 'Guardar' : 'Actualizar';
            this.textoBotonProcesando = '';
        }
    }
    ngOnInit() {

        this.utility.logger.LogInfoText(this.GetTextMessage('ngOnInit'));

        if (this.onInitialized.observers.length) {
            setTimeout(() => {
                this.onInitialized.emit(this);
            }, 1000);
        }

        this.OnInit();


        this.comboBoxManager = new ComboBoxManager(this.utility, this.baseService, this.configs, this.componentName, this.modelo, this.modeloCascada);



    }

    AddChildComponent(component: any) {


        let _component = new List(this.childComponents).FirstOrDefault(x => { return x.componentName == component.componentName });
        if (!_component)
            this.childComponents.push(component);

    }

    ngAfterViewInit() {


    }
    ngAfterViewChecked() {

    }
    public GetTextMessage(method: string): string {
        return `componentName: ${this.componentName} method: ${method}`;
    }
    public OnInitParentOption() {

        if (this.parentOption) {
            if (this.parentOption.CargarGrilla == false)
                this.cargarGrilla = false;
            if (this.parentOption.HideDataTable == true)
                this.hideDataTable = true;
            if (this.parentOption.HideForm == true)
                this.hideForm = true;
        }
    }
    // public OnInit() {

    //     try {

    //         this.OnInitParentOption();

    //         this.onSpecificStart();


    //         this.utility.IsUserAuthenticated(this.enumPagina, true, this.componentName, 'onInit', null);

    //         if (this.Pagina.Valida) {

    //             this.childOptions = (this.childOptions && this.childOptions.length > 0) ? this.childOptions : this.utility.GetChildOptions(this.Pagina);
    //             this.AdicionarColumasEditarEliminar();
    //             this.exportar = (this.Pagina.Exportar == true) ? this.componentName : undefined;
    //             this.dataTableControl = this.utility.FindControlPagina(this.Pagina, 'DataTables_' + this.componentName);
    //             this.idGrillaQuery = (this.dataTableControl && this.dataTableControl.IdQuery) ? this.dataTableControl.IdQuery : undefined;

    //             if (this.Pagina.Valida == true && this.Pagina.ConfigPage) {
    //                 this.utility.SetControlsConfig(this.Pagina, this.configs);
    //                 this.SetControlsConfig();
    //             }
    //             this.CargarComboBox();
    //             this._CargarGrilla();

    //         }
    //         else
    //             this.SetDefaultDtOptions();



    //     }
    //     catch (error) {
    //         this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    //     }
    // }
    public SetControlsConfig() {

    }

    public onSelectAllComboBox(controlName: string) {
        try {
            this.modelo[controlName] = this.itemsSelectControls['Items' + controlName].map(item => item[controlName]);
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onSelectAllComboBox', controlName: controlName });
        }
    }

    public onClearAllComboBox(controlName: string) {
        try {
            this.modelo[controlName] = [];
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onClearAllComboBox', controlName: controlName });
        }
    }


    VerObservacionesModificacionVersion(form: FormGroup = null): any {
        let config: ConfigWindow = new ConfigWindow();
        config.classWindow = 'info';
        config.titleWindow = "Observaciones";
        config.returnInstance = false;
        config.ok = "Aceptar";
        config.modal = true;
        config.width = 600;
        config.height = 300;
        let windowModel: any = {
            modelo: this.modelo.VersionModelData,
            config: this.utility.GetConfigObservacionesVersion()
        };
        if (!form)
            return this.utility.OpenWindow(ObservacionesModificacionVersionComponent, config, windowModel);
        else {
            this.utility.OpenWindow(ObservacionesModificacionVersionComponent,
                config, windowModel).then(dataResult => {
                    // console.log(dataResult);

                    dataResult.onClosePromise.then(dataResultOnClose => {
                        // console.log(dataResultOnClose);

                        let limpiar = true;
                        if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
                            dataResultOnClose.modelo.ObservacionesModificacion) {
                            limpiar = false;
                            this.modelo.VersionModelData.ObservacionesModificacion = dataResultOnClose.modelo.ObservacionesModificacion;
                            this.GuardarFormInterno(form);
                        }
                        else {
                            this.modelo.VersionModelData.ObservacionesModificacion = '';
                            //this.VerObservacionesModificacionVersion(form);
                            this.LimpiarConfirm();
                        }
                    });

                })
        }
    }


    public ImportarForm() {

        console.log('Importar', this.Pagina);
    }
    public ModalFilter() {

    }

    public Save(form: FormGroup) {

        this.GuardarForm(form);
    }
    public Limpiar(callback: (data: any) => void = null) {


    }
    public LimpiarConfirm() {



        try {
            if (this.Pagina.VerComfirmarLimpiar != true) {

                this.ExcecuteFuntion(ControllerMethods.Clear, false);
                this.modeloCascada = {};
                this.Limpiar();
                this.SetProcesando(false);
                this.ExcecuteFuntion(ControllerMethods.Clear, true);
            }
            else {
                const that = this;
                this.utility.VerModalConfirmacionLimpiar().then(modelo => {
                    if (modelo.dialogResult == true) {

                        that.ExcecuteFuntion(ControllerMethods.Clear, false);
                        that.modeloCascada = {};
                        that.Limpiar();
                        this.SetProcesando(false);
                        that.ExcecuteFuntion(ControllerMethods.Clear, true);
                    }
                });
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'LimpiarConfirm' });
            this.utility.VerModalDanger().then(data => { })
        }
    }



    public GetListModelos(): Array<any> {

        return [];
    }

    public FinGuardar(that: BaseCrud, data: any) {
        that.Limpiar();
        that.ExcecuteFuntion(ControllerMethods.Clear, true);
       
        this.SetProcesando(false);
        if (that.cargarGrilla)
            this.ReCargarGrilla();
        //that.utility.ReCargarGrilla(that.dtElement, that.GetNombreDataTables(), that.componentName);
        that.utility.VerModalOk().then(value => { });
        that.ExcecuteFuntion(ControllerMethods.Save, true, data);
    }

    public MultiGuardar(form: FormGroup) {
        let obj: any = null;
        try {
            if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
                return;

            const that = this;
            this.ExcecuteFuntion(ControllerMethods.Save, false);
            obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.GetListModelos());
            this.baseService.MultiInsertUpdate(obj, form, {
                componentName: this.componentName + 'Controller',
                method: 'MultiGuardar', processMessage: 'Proceso de Multi Guardado de ' + this.Pagina.Titulo
            },
                false, this.componentName, false, true).then(data => {
                    that.FinGuardar(that, { data: data.Data, isEdit: that.IsEdit });

                }).catch(error => { this.SetProcesando(false); });
        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'MultiGuardar' });
            this.utility.VerModalDanger().then(data => { });
        }
    }
    private _Guardar(form: FormGroup) {
        let obj: any = null;
        try {
            if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
                return;
            const that = this;
            let processMessage = (this.modelo.PkValue == '0' || this.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
            this.ExcecuteFuntion(ControllerMethods.Save, false);
            obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo);
            this.baseService.InsertUpdate(obj, form, {
                componentName: this.componentName + 'Controller',
                method: 'Guardar', processMessage: processMessage
            },
                false, this.componentName, false, true).then(data => {
                    that.FinGuardar(that, data.Data);

                }).catch(error => { this.SetProcesando(false); });
        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: '_Guardar' });
            this.utility.VerModalDanger().then(data => { })
        }
    }

    public GuardarFormInterno(form: FormGroup) {

        this.SetProcesando(true);
        if (this.isUnion == true)
            this.MultiGuardar(form);
        else {
            if (this.IsEdit) {
                let ok = this.ValidarPermisosUsuario(this.modelo, 'Guardar');
                if (ok)
                    this._Guardar(form);
            }
            else
                this._Guardar(form);
        }
    }
    get IsVersion() {
        return (this.HasVersionModelData && this.IsEdit);
    }
    public ValidarCrearNuevaVersion(): boolean {

        if (this.HasPropVersionModelData && this.IsEdit) {
            if (!this.modeloClone)
                throw 'La copia del modelo no puede ser  nula';
            let result = this.utility.ValidateModels(this.modelo, this.modeloClone, this.modelo.VersionModelData.Propiedades);
            this.modelo.VersionModelData.PropiedadesModificadas = result;
            return (result.length > 0)
        }
        return this.IsVersion;
    }
    public GuardarForm(form: FormGroup, excludeValidation: boolean = false) {

        // let temp =form.get(this.controlName);
        try {

   
            if (this.form) {

            }
           
            this.ExcecuteFuntion(ControllerMethods.PreSave,true,this.modelo);
            const isVersion = this.ValidarCrearNuevaVersion();
            if (this.Pagina.VerComfirmarGuardar != true) {
                if (isVersion) {
                    this.VerObservacionesModificacionVersion(form);
                    return;
                }
                this.GuardarFormInterno(form);
            }
            else {
                const that = this;
                this.utility.VerModalConfirmacionGuardar(isVersion).then(modelo => {
                    if (modelo.dialogResult == true) {
                        if (isVersion) {
                            this.VerObservacionesModificacionVersion(form);
                            return;
                        }
                        this.GuardarFormInterno(form);
                    }
                });
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'Guardar' });
            this.utility.VerModalDanger().then(data => { });
        }
    }


    private _Activar(index: number, id: any, activo: boolean, event: any = null) {
        //var items = $scope.dtInstance.DataTable.rows().data();
        const that = this;
        var obj = { PkValue: id, IsActive: !activo, MethodNameUI: 'Activar' };
        try {
            this.ValidarPermisosUsuarioFromItems(this.dtElement, index, id, 'Eliminar').then(result => {
                if (result !== true)
                    return;
                this.ExcecuteFuntion(ControllerMethods.Active, false, { obj: obj });
                this.baseService.Active(obj, null, {
                    componentName: this.componentName + 'Controller',
                    method: 'Activar', processMessage: 'Activando / Inactivando registro de ' + this.Pagina.Titulo
                },
                    this.componentName).then(data => {
                        if (that.cargarGrilla)
                            that.utility.ReCargarGrilla(that.dtElement, that.GetNombreDataTables(), that.componentName);

                        that.ExcecuteFuntion(ControllerMethods.Active, true, { obj: obj });
                    });
            }).catch(error => this.utility.VerModalDanger().then(data => { }));
        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: '_Activar' });
            this.utility.VerModalDanger().then(data => { });
        }
    }

    public Activar(index: number, id: any, activo: boolean) {
        try {
            if (this.Pagina.VerComfirmarEliminar != true)
                this._Activar(index, id, activo)
            else {
                const that = this;
                this.utility.VerModalConfirmacionActivar().then(modelo => {
                    if (modelo.dialogResult == true)
                        that._Activar(index, id, activo)
                });
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, { index: index, id: id, activo: activo }, { componentName: this.componentName, method: 'Activar' });
            this.utility.VerModalDanger().then(data => { });
        }

    }

    private _Eliminar(index: number, id: any) {

        const that = this;
        var obj = { PkValue: id, MethodNameUI: 'Eliminar' };
        try {
            this.ValidarPermisosUsuarioFromItems(this.dtElement, index, id, 'Eliminar').then(result => {
                if (result !== true)
                    return;
                this.ExcecuteFuntion(ControllerMethods.Delete, false, { obj: obj });
                this.baseService.Delete(obj, null, {
                    componentName: this.componentName + 'Controller',
                    method: 'Eliminar', processMessage: 'Eliminado registro de  ' + this.Pagina.Titulo
                },
                    this.componentName).then(data => {
                        if (that.cargarGrilla)
                            that.utility.ReCargarGrilla(that.dtElement, that.GetNombreDataTables(), that.componentName);
                        that.ExcecuteFuntion(ControllerMethods.Delete, true, { obj: obj });
                    });
            }).catch(error => this.utility.VerModalDanger().then(data => { }));
        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: '_Eliminar' });
            this.utility.VerModalDanger();
        }
    }

    public Eliminar(index: number, id: any) {
        try {
            if (this.Pagina.VerComfirmarEliminar != true)
                this._Eliminar(index, id)
            else {
                const that = this;
                this.utility.VerModalConfirmacionEliminar().then(modelo => {
                    if (modelo.dialogResult == true)
                        that._Eliminar(index, id)
                });
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, { index: index, id: id }, { componentName: this.componentName, method: '_Eliminar' });
            this.utility.VerModalDanger().then(data => { })
        }
    }



    public GetValorParaUpdateProperties(campo: string, valor: any, obj: any): any {


        if (typeof valor === 'object' && valor !== null) {
            if (obj)
                $.each(valor, function (key, val) {
                    obj.Parameters[key] = val;
                });
        }
        else {
            if (valor == true)
                valor = false;
            else if (valor == false)
                valor = true;
            else if (valor == 'true')
                valor = false;
            else if (valor == 'false') {
                valor = true;
            }
            else if (valor === 'null')
                valor = null;
            obj.Parameters[campo] = valor;
        }
        return valor;
    }
    public InternalUpdateProperties(index: number, id: any, valor: any, campo: string,
        refres: boolean) {

        const that = this;
        var obj = { PkValue: id, MethodNameUI: 'UpdateProperties', Parameters: {} };
        try {

            this.ValidarPermisosUsuarioFromItems(this.dtElement, index, id, 'Guardar').then(result => {
                if (result !== true)
                    return;
                valor = this.GetValorParaUpdateProperties(campo, valor, obj);


                this.ExcecuteFuntion(ControllerMethods.UpdateProperties, false, { obj: obj });
                this.baseService.UpdateProperties(obj, null, {
                    componentName: this.componentName + 'Controller',
                    method: 'UpdateProperties', processMessage: 'Actualizando registro de  ' + this.Pagina.Titulo
                },
                    this.componentName).then(data => {
                        if (refres == true && that.cargarGrilla)
                            that.utility.ReCargarGrilla(that.dtElement, that.GetNombreDataTables(), that.componentName);
                        else
                            that.utility.RefreshValorDataTable(that.dtElement, index, id, that.GetNombreDataTables(), valor, campo, that.componentName);
                        that.ExcecuteFuntion(ControllerMethods.UpdateProperties, true, { obj: obj });
                    });

            }).catch(error => this.utility.VerModalDanger().then(data => { }));
        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'InternalUpdateProperties' });
            this.utility.VerModalDanger().then(data => { });
        }
    }
    public UpdateProperties = function (index: number, id: any, valor: any, campo: string, refres: boolean = true,
        esPermisos: boolean = false, verParaPermisos: boolean = true) {

        const that = this;

        try {


            if (esPermisos) {
                if (verParaPermisos) {
                    this.utility.VerModalConfirmacionPermisos(campo, valor).then(modelo => {

                        if (modelo.dialogResult == true) {
                            that.InternalUpdateProperties(index, id, valor, campo, refres)
                        }

                    });
                }
                else
                    that.InternalUpdateProperties(index, id, valor, campo, refres)
            }
            else {
                this.utility.VerModalConfirmacionActualizarPropiedad(campo).then(modelo => {

                    if (modelo.dialogResult == true) {
                        that.InternalUpdateProperties(index, id, valor, campo, refres)
                    }

                });
            }

        }
        catch (error) {
            this.utility.logger.LogError(error, { index: index, id: id, valor: valor, campo: campo }, { componentName: this.componentName, method: 'UpdateProperties' });
            this.utility.VerModalDanger().then(data => { });
        }

    }

    public Ayuda(config: any = null) {

    }



    public OnSelectedComboBox(item: any, controlName: string, config: any) {
        try {
            this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, { item: item, controlName: controlName, config: config });
            // this.ExecuteCascading(item, controlName, config);

            this.comboBoxManager.ExecuteCascading(item, controlName, config);
            this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, { item: item, controlName: controlName, config: config });
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
            this.utility.VerModalDanger().then(data => { });
        }
    }



    public EditCascading() {

        this.comboBoxManager.EditCascading(this.modelo, this.modeloCascada);

    }



    ///CHILDOPTIONS



    onConstructor: (data: any) => void;
    preOnInit: (data: any) => void;
    postOnInit: (data: any) => void;
    preSave: (modelo: any) => void;
    prePreSave: (modelo: any) => void;
    postSave: (modelo: any) => void;
    onSave: (modelo: any) => void;

    preDelete: (modelo: any) => void;
    postDelete: (modelo: any) => void;
    onDelete: (modelo: any) => void;
    // preActive: (modelo: any) => void;
    // postActive: (modelo: any) => void;
    // onActive: (modelo: any) => void;
    preLoadGrid: (modelo: any, data: any) => any;
    postLoadGrid: (modelo: any, data: any) => any;
    onLoadGrid: (modelo: any) => any;
    preUpdateProperties: () => void;
    postUpdateProperties: () => void;
    onUpdateProperties: (modelo: any) => void;
    preClear: () => void;
    postClear: () => void;
    onClear: (modelo: any) => void;
    preModalFilter: () => void;
    postModalFilter: () => void;
    onModalFilter: (modelo: any) => void;
    preModalCrud: () => void;
    postModalCrud: () => void;
    onModalCrud: (modelo: any) => void;
    // preClickChildOptions: () => void;
    // postClickChildOptions: () => void;
    // onClickChildOptions: (modelo: any) => void;
    preSelectedComboBox: () => void;
    postSelectedComboBox: (modelo: any) => void;
    onSelectedComboBox: (modelo: any) => void;


    @Output() prePreSaveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preSaveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postSaveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSaveEmitter: EventEmitter<any> = new EventEmitter<any>();


    @Output() preDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
    // @Output() preActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    // @Output() postActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    // @Output() onActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preLoadGridEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postLoadGridEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onLoadGridEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preClearEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postClearEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClearEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preModalFilterEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postModalFilterEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onModalFilterEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preModalCrudEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postModalCrudEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onModalCrudEmitter: EventEmitter<any> = new EventEmitter<any>();
    // @Output() preClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    // @Output() postClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    // @Output() onClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preSelectedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postSelectedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelectedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();



    ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null) {
        var result = null;
        switch (controllerMethod) {
            case ControllerMethods.Constructor:

                if (this.onConstructor)
                    this.onConstructor(data);
                break;
            case ControllerMethods.OnInit:

                if (!isPost && this.preOnInit)
                    this.preOnInit(data);
                else if (isPost == true && this.postOnInit)
                    this.postOnInit(data);
                break;
            case ControllerMethods.Save:

                if (!isPost && this.preSave)
                    this.preSave(this.modelo);
                else if (isPost == true && this.postSave)
                    this.postSave(this.modelo);

                if (this.onSave)
                    this.onSave({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preSaveEmitter.observers.length > 0)
                    this.preSaveEmitter.emit({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postSaveEmitter.observers.length > 0)
                    this.postSaveEmitter.emit({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSaveEmitter.observers.length > 0)
                    this.onSaveEmitter.emit({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.PreSave:

                if (this.prePreSave)
                    this.prePreSave(this.modelo);



                if (this.prePreSaveEmitter.observers.length > 0)
                    this.prePreSaveEmitter.emit({ modelo: this.modelo, isEdit: this.IsEdit, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.Edit:

                if (!isPost && this.preEdit)
                    this.preEdit(this.modelo);
                else if (isPost == true && this.postEdit)
                    this.postEdit(this.modelo);

                if (this.onEdit)
                    this.onEdit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preEditEmitter.observers.length > 0)
                    this.preEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postEditEmitter.observers.length > 0)
                    this.postEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onEditEmitter.observers.length > 0)
                    this.onEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.Delete:

                if (!isPost && this.preDelete)
                    this.preDelete(this.modelo);
                else if (isPost == true && this.postDelete)
                    this.postDelete(this.modelo);

                if (this.onDelete)
                    this.onDelete({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preDeleteEmitter.observers.length > 0)
                    this.preDeleteEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postDeleteEmitter.observers.length > 0)
                    this.postDeleteEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onDeleteEmitter.observers.length > 0)
                    this.onDeleteEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.Active:

                if (!isPost && this.preActive)
                    this.preActive(this.modelo);
                else if (isPost == true && this.postActive)
                    this.postActive(this.modelo);

                if (this.onActive)
                    this.onActive({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preActiveEmitter.observers.length > 0)
                    this.preActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postActiveEmitter.observers.length > 0)
                    this.postActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onActiveEmitter.observers.length > 0)
                    this.onActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.SelectedItem:

                if (!isPost && this.preSeleccionItem)
                    this.preSeleccionItem(data);
                else if (isPost == true && this.postSeleccionItem)
                    this.postSeleccionItem(data);

                if (this.onSeleccionItem)
                    this.onSeleccionItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preSeleccionItemEmitter.observers.length > 0)
                    this.preSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postSeleccionItemEmitter.observers.length > 0)
                    this.postSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSeleccionItemEmitter.observers.length > 0)
                    this.onSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.LoadedGrid:

                if (this.onLoadedGridItem)
                    this.onLoadedGridItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (this.loadedGridEmitter.observers.length > 0)
                    this.loadedGridEmitter.emit({ controlName: this.GetNombreDataTables(), isPost: isPost, data: data, controllerMethod: controllerMethod });
                break;
            case ControllerMethods.LoadGrid:

                if (!isPost && this.preLoadGrid)
                    result = this.preLoadGrid(this.modelo, data);
                else if (isPost == true && this.postLoadGrid)
                    result = this.postLoadGrid(this.modelo, data);

                if (this.onLoadGrid)
                    result = this.onLoadGrid({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preLoadGridEmitter.observers.length > 0)
                    result = this.preLoadGridEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postLoadGridEmitter.observers.length > 0)
                    result = this.postLoadGridEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onLoadGridEmitter.observers.length > 0)
                    result = this.onLoadGridEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.UpdateProperties:

                if (!isPost && this.preUpdateProperties)
                    this.preUpdateProperties();
                else if (isPost == true && this.postUpdateProperties)
                    this.postUpdateProperties();

                if (this.onUpdateProperties)
                    this.onUpdateProperties({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preUpdatePropertiesEmitter.observers.length > 0)
                    this.preUpdatePropertiesEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postUpdatePropertiesEmitter.observers.length > 0)
                    this.postUpdatePropertiesEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onUpdatePropertiesEmitter.observers.length > 0)
                    this.onUpdatePropertiesEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.Clear:

                if (!isPost && this.preClear)
                    this.preClear();
                else if (isPost == true && this.postClear)
                    this.postClear();

                if (this.onClear)
                    this.onClear({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preClearEmitter.observers.length > 0)
                    this.preClearEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postClearEmitter.observers.length > 0)
                    this.postClearEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onClearEmitter.observers.length > 0)
                    this.onClearEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.ModalFilter:


                if (!isPost && this.preModalFilter)
                    this.preModalFilter();
                else if (isPost == true && this.postModalFilter)
                    this.postModalFilter();

                if (this.onModalFilter)
                    this.onModalFilter({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preModalFilterEmitter.observers.length > 0)
                    this.preModalFilterEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postModalFilterEmitter.observers.length > 0)
                    this.postModalFilterEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onModalFilterEmitter.observers.length > 0)
                    this.onModalFilterEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.ModalCrud:

                if (!isPost && this.preModalCrud)
                    this.preModalCrud();
                else if (isPost == true && this.postModalCrud)
                    this.postModalCrud();

                if (this.onModalCrud)
                    this.onModalCrud({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preModalCrudEmitter.observers.length > 0)
                    this.preModalCrudEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postModalCrudEmitter.observers.length > 0)
                    this.postModalCrudEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onModalCrudEmitter.observers.length > 0)
                    this.onModalCrudEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.ClickChildOptions:

                if (!isPost && this.preClickChildOptions)
                    this.preClickChildOptions();
                else if (isPost == true && this.postClickChildOptions)
                    this.postClickChildOptions();

                if (this.onClickChildOptions)
                    this.onClickChildOptions({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preClickChildOptionsEmitter.observers.length > 0)
                    this.preClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postClickChildOptionsEmitter.observers.length > 0)
                    this.postClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onClickChildOptionsEmitter.observers.length > 0)
                    this.onClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.SelectedComboBox:

                if (!isPost && this.preSelectedComboBox)
                    this.preSelectedComboBox();
                else if (isPost == true && this.postSelectedComboBox)
                    this.postSelectedComboBox({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSelectedComboBox)
                    this.onSelectedComboBox({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (!isPost && this.preSelectedComboBoxEmitter.observers.length > 0)
                    this.preSelectedComboBoxEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postSelectedComboBoxEmitter.observers.length > 0)
                    this.postSelectedComboBoxEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSelectedComboBoxEmitter.observers.length > 0)
                    this.onSelectedComboBoxEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;

        }
        return result;
    }

    public CreatedFormEventHandler(event: any) {


        this.form = event;
    }

    ngOnDestroy() {

        if (this.formSubscription)
            this.formSubscription.unsubscribe();
    }

    public CreateModel() {
        this.modelo = {};
       
        if (this.Pagina) {
            if (this.Pagina && this.Pagina.ConfigPage.Model) {
                this.modelo = (this.Pagina.ConfigPage.Model.modelo) ? this.utility.Clone(this.Pagina.ConfigPage.Model.modelo) :
                    this.utility.Clone(this.Pagina.ConfigPage.Model);
            }
            else {
                this.modelo[this.pkName] = 0;
                this.modelo.PkValue = 0;
                this.SetControlToModel(this.Pagina.ConfigPage.Controls);


            }


           this.SetModeloVersion();
        }
    }
    public SetModeloVersion(){
        if (this.Pagina.ConfigPage.ConfigVersion) {
            this.modelo.VersionModelData = {
                ObservacionesModificacion: '',
                Propiedades: this.Pagina.ConfigPage.ConfigVersion.Properties,
            };
        }
    }
    public SetModelToControls() {
        if (this.form && this.form.controls) {
            if (this.formSubscription)
                this.formSubscription.unsubscribe();
            $.each(this.form.controls, (name, control: any) => {

                control.setValue(this.modelo[name])
            });
            this.SetControlsToModel();
        }
    }
    public SetControlsToModel() {
        this.formSubscription = this.form.valueChanges.subscribe(values => {

           
            $.each(values, (name, value: any) => {
                this.modelo[name] = value;
            });
        })
    }


    SetControlToModel(controls: Array<any>) {

        const that = this;
        if (controls && controls.length > 0) {
            controls.forEach(formControl => {

                if (this.utility.EsControlFormulario(formControl)) {

                    this.modelo[formControl.Name] = formControl.Value;
                }
                else {
                    if (formControl.Controls && formControl.Controls.length > 0) {
                        this.SetControlToModel(formControl.Controls)
                    }
                }
            });
        }
    }

    SetFormControl(formGroup: any, controls: Array<any>) {

        const that = this;
        if (controls && controls.length > 0) {
            controls.forEach(formControl => {

                if (this.utility.EsControlFormulario(formControl)) {
                    //const ctr = new FormControl({ value: formControl.Value, disabled: formControl.disabled });
                    //if (formControl.Activo)
                    let validations = [];
                    if (formControl.Required)
                        validations.push(Validators.required);
                    if (formControl.MaxLength)
                        validations.push(Validators.maxLength(formControl.MaxLength));
                    if (formControl.MinLength)
                        validations.push(Validators.minLength(formControl.MinLength));
                    if(formControl.ControlType == 'NumberBox')
                    {
                        if (formControl.Max  != null && !isNaN(formControl.Max) && formControl.Max != formControl.Min)
                        validations.push(Validators.max(Number(formControl.Max)));
                        if(formControl.Min != null && formControl.Max != null && formControl.Min == formControl.Max)
                            console.warn(`Se ignoró validación Max dado que es igual que Min, en ${formControl.Name}`);
                        if (formControl.Min  != null && !isNaN(formControl.Min))
                            validations.push(Validators.min(Number(formControl.Min)));
                    }
                    if (formControl.ControlType == 'EmailBox')
                        validations.push(Validators.email);
                    if (formControl.Pattern)
                        validations.push(Validators.pattern(formControl.Pattern));


                    const ctr = new FormControl(null, validations);
                    formGroup[formControl.Name] = ctr;
                    ctr.setValue(formControl.Value);
                    setTimeout(() => {

                        ctr.markAsTouched();

                        if (formControl.Disabled)
                            ctr.disable();
                    }, 600);

					/* 	  ctr.setValue(formControl.Value);
						  setTimeout(() => {
							ctr.markAsTouched();
						  }, 3000); */
                }
                else {
                    if (formControl.Controls && formControl.Controls.length > 0) {
                        this.SetFormControl(formGroup, formControl.Controls)
                    }
                }
            });
        }
    }


    public CreateControls() {
        const formGroup: any = {};
        if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.Controls && this.Pagina.ConfigPage.Controls.length > 0) {

            this.SetFormControl(formGroup, this.Pagina.ConfigPage.Controls);

            this.form = new FormGroup(formGroup);
            this.SetControlsToModel();
        }
    }




    public OnActionMetadatacEventHandler(event) {
		
	}
	public OnActionTiposDocEventHandler(event) {
		
	}
	public OnActionEventHandler(event) {
		
	}
	public OnSelectionHandler(event) {

		
	}

	public OnLoadGridHandler(event) {

		
	}
	public OnSetConfigHandler(event) {

		
	}
	public CloneEventHandler(event) {

		
		this.modeloClone = event.modeloClone;
	}
	public AddComponentInstanceHandler(event) {

		if (event instanceof DataTableComponent)
			this.componentsContainer.Tables.push(event);
		else if (event instanceof FormDetailsComponent)
			this.componentsContainer.FormsDetails.push(event);
	}
}
