import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control';
import { BaseComboBox } from '../../../models/base-combo-box';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service';
import { ConfigWindow } from 'src/app/models/config-window';
import { DataTableComponent } from '../data-table/data-table.component';

@Component({
  selector: 'app-combo-box-r',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxRComponent),
      multi: true
    }
  ],
  templateUrl: './combo-box-r.component.html',
  styleUrls: ['./combo-box-r.component.scss']
})

export class ComboBoxRComponent extends BaseComboBox implements OnInit, ControlValueAccessor {
  
  ngAfterViewInit() {

    // let element = $('#' + this.controlName);
    // element.mouseenter(function () {
    //   if (element.hasClass("ng-select-opened"))
    //     element.tooltip('hide');
    // });
    // element.children(".ng-select-container").mouseleave(function () {
    //   element.tooltip('hide');
    // });

    if (this.isReactive && this.frm && this.config && this.frm.controls[this.config.Name]) {
      this.ctr = this.frm.controls[this.config.Name];
      // this.ctr.valueChanges.subscribe(value => {

      //   this.value = value;
      // })
    }
  }


}
