//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods, DataTableAjaxOrderTypes, TypesUseForm, ControlActions } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { StaticData } from 'src/app/helpers/static-data';
import { Enumerable, List } from "../../../../assets/linqts/compilado/index";

@Component({
	selector: 'app-rrole',
	templateUrl: './rrole.component.html',
	styleUrls: ['./rrole.component.css']
})
export class RRoleComponent extends GeneralFormComponent implements OnInit, AfterViewInit {
	
  ConfigTodosPermisosExpedientes:any
  ConfigTodosPermisosDocumentosExpedientes:any
  ConfigTodosPermisosPaginas:any
  ConfigTodosPermisosMenus:any
  ConfigTodosPermisosQuerys:any
  ConfigTodosPermisosReglas:any
  ConfigIdAreaEmpresa:any
  ConfigIdTipoBusquedaAreaEmpresa:any
  ConfigbtnGuardar:any
  ConfigIdTipoRole:any
  ConfigDescripcion:any
  ConfigNombre:any
  ConfigCodigo:any
  ConfigActivo:any
	
	constructor(public baseService: BaseServiceService, public utility: Utilities,
		public helperImages: HelperImages,
		public helperRules: HelperRules,
		public helperActions: HelperActions) {

		super(baseService, utility, helperImages, helperRules, helperActions);
	}
	
	// ngAfterViewInit() {
		// 	this.userModel.Nueva();
	// }

  public Limpiar() {
  //debugger;
  super.Limpiar();
  this.modelo.modelo.Activo = true;
  this.SetModelToControls();
  }

	public OnInitEnd() {
		
				

			this.postSaveEmitter.subscribe(data => {
				
				let obj = this.utility.GetApiModel("ConsultaRolesGruposUsuariosAreasEmpresaTipo", null);
        obj.QueryConfig = {
          Command: "ConsultaRolesGruposUsuariosAreasEmpresaTipo",
          IsSp: true,
          Entity: {
            IdEmpresa: 1
          },
        };
        let info = {
          componentName: this.componentName,
          controlName: "ConsultaRolesGruposUsuariosAreasEmpresaTipo",
          processMessage: "Cargando ConsultaIndicativo......",
        };

        this.baseService
          .Get(obj, null, info, this.componentName, false, false)
          .then((data) => {
            
			StaticData.RolesUsuariosGrupos = new List<any>(data.Data);            
          })
          .catch((err) => {
            this.utility.logger.LogError(err, obj, {
              componentName: this.componentName,
              method: "Consulta",
            });
          });				
				
			});

      this.ConfigTodosPermisosExpedientes = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosExpedientes" });
      this.ConfigTodosPermisosDocumentosExpedientes = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosDocumentosExpedientes" });
      this.ConfigTodosPermisosPaginas = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosPaginas" });
      this.ConfigTodosPermisosMenus = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosMenus" });
      this.ConfigTodosPermisosQuerys = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosQuerys" });
      this.ConfigTodosPermisosReglas = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosReglas" });
      this.ConfigIdAreaEmpresa = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdAreaEmpresa" });
      this.ConfigIdTipoBusquedaAreaEmpresa = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoBusquedaAreaEmpresa" });
      this.ConfigbtnGuardar = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "883bff68-3867-cf2b-55f9-2a8a4f7c836a" });
      this.ConfigDescripcion = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Descripcion" });
      this.ConfigNombre = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Nombre" });
      this.ConfigCodigo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Codigo" });
      this.ConfigActivo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Activo" });

      this.ConfigIdTipoRole = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoRole" });
      this.ConfigIdTipoRole.Activo = StaticData.GeneralConfig.ConfigLicence.ValideLicence?StaticData.GeneralConfig.ConfigLicence.ValideLicence : false
      this.ConfigIdTipoRole.Required = StaticData.GeneralConfig.ConfigLicence.ValideLicence?StaticData.GeneralConfig.ConfigLicence.ValideLicence : false
	}	
  
  public ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null){
    debugger
    this.licenciasConfigOnEvent()
    if(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1){
      this.modelo.modelo.TodosPermisosDocumentosExpedientes = null
      this.modelo.modelo.TodosPermisosExpedientes = null
      this.modelo.modelo.TodosPermisosMenus = null
      this.modelo.modelo.TodosPermisosPaginas = null
      this.modelo.modelo.TodosPermisosQuerys = null
      this.modelo.modelo.TodosPermisosReglas = null
    }
    super.SetModelToControls();
    super.ExcecuteFuntion(controllerMethod,isPost,data);
  }

  public OnActionEventHandler(event) {
    switch (event.ControlType) {
      case "CommandButton": {
        switch (event.Option.Label) {
            case "Editar": {
              this.licenciasConfigOnEvent()
              this.licenciasConfigOnlyEventButton()
              super.SetModelToControls();
              break;
            }
            case "Nuevo": {
              this.licenciasConfigOnEvent()
              super.SetModelToControls();
              break;
            }
        }
        break;
      }
    }
    super.OnActionEventHandler(event);
  }

  licenciasConfigOnEvent(){
    this.ConfigTodosPermisosExpedientes.Readonly = (this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosDocumentosExpedientes.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosPaginas.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosMenus.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosQuerys.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosReglas.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
  }

  licenciasConfigOnlyEventButton(){
    this.ConfigDescripcion.Readonly =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true : false
    this.ConfigNombre.Readonly =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true : false
    this.ConfigCodigo.Readonly =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true : false
    this.ConfigIdAreaEmpresa.Readonly =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true : false
    this.ConfigIdTipoBusquedaAreaEmpresa.Readonly =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true : false
    this.ConfigIdTipoRole.Readonly =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true : false
    this.ConfigActivo.Readonly =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true : false

    this.ConfigbtnGuardar.Activo =(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? false : true
  }
}
