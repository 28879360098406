import { Injectable,EventEmitter } from '@angular/core';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import {NavItem} from '../components//menu-item/nav-item';
import { OverlayComponent } from '../components/controls/overlay/overlay.component';

@Injectable({
  providedIn: 'root'
})
export class ModalNotifyComunicationService {

  receivedEvent: EventEmitter<ModalNotifyComponent> = new EventEmitter<ModalNotifyComponent>();
  public modalNotify: ModalNotifyComponent;
  
  receivedOverlayEvent: EventEmitter<OverlayComponent> = new EventEmitter<OverlayComponent>();
    constructor() {;
    }

    // 2
    raiseEvent(modalNotify: ModalNotifyComponent): void {
       
        this.receivedEvent.emit(modalNotify);
    }

    raiseOverlayEvent(overlay: OverlayComponent): void {
       
      this.receivedOverlayEvent.emit(overlay);
  }


}
