import { Component, OnInit, Input, Output, EventEmitter, QueryList } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { BotonesFormularioBase } from '../../../models/botones-formulario-base';
import { StaticData } from 'src/app/helpers/static-data';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { ComponentsContainer } from 'src/app/models/components-container';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-botones-data-table',
  templateUrl: './botones-data-table.component.html',
  styleUrls: ['./botones-data-table.component.scss']
})

export class BotonesDataTableComponent extends BotonesFormularioBase implements OnInit {

  @Input() name: string = '';
  @Input() dtElement: QueryList<DataTableDirective>;
  @Input() tableName: string = '';
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() componentsContainer: ComponentsContainer;
  @Input() config: any;
  @Input() configPermisos: any;
  @Input() ComponentName: any;
  @Input() botonProcesandoExportar: boolean = false;
  @Input() textoBotonExportar: string = 'Exportar';
  @Input() textoBotonProcesandExportar: string = '';
  @Output() Exportar: EventEmitter<any> = new EventEmitter<any>();
  Estilos: any = StaticData.Estilos;
  @Input() viewButton: any;

  constructor(public helperActions: HelperActions) {

    super();
    // public baseService: BaseServiceService,
    // public utility: Utilities,
    // public helperImages: HelperImages,
    // public helperRules: HelperRules,
    // public helperActions: HelperActions
    let ConfigButtons = {
      TitleOptions: '', LabelOptions: '', IconOptions: '', Buttons: [

        { label: 'Exportar', icono: '', opcionPermiso: 'Exportar', ControlType: 'Button' },
        {
          label: 'Ver', icono: '', downloadOpcion: "View", opcionPermiso: 'Exportar', ControlType: 'ButtonOption',
          Actions: []
        },
        { label: 'Descargar Base64', downloadOpcion: "Base64", icono: '', opcionPermiso: 'Exportar', ControlType: '' },
        { label: 'Descargar ByteArray', downloadOpcion: 'ByteArray', icono: '', opcionPermiso: 'Exportar', ControlType: '' },
        { label: 'Descargar Url', downloadOpcion: "Original", icono: '', opcionPermiso: 'Exportar', ControlType: '' },
        { label: 'Descargar Sitio Personal', downloadOpcion: "Site", icono: '', opcionPermiso: 'Exportar', ControlType: '' },
        { label: 'Ver Versiones', icono: '', campoVersiones: 'VersionAutonumerico', opcion: 'VerVersion', ControlType: '' }]
    }

  }

  //* FUNCIONES ANGULAR

  ngOnInit() {

    if (this.config) {
      this.name = this.config.Name;
      if (!this.config.Buttons)
        this.config.Buttons = [];
      this.viewButton = this.config.Buttons.find(item => item.SubControlType == 'ButtonOption');
    }

  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public ViewButtons(controlType) {

    return this.config.Buttons.filter(item => item.SubControlType == controlType).length;

  }

  public GetButtons(controlType) {

    return this.config.Buttons.filter(item => item.SubControlType == controlType);

  }

  public OnClick(option) {

    this.config.ControlType = (!this.config.ControlType) ? "Button" : this.config.ControlType;
    this.config.ComponentName = this.ComponentName;

    if (option.NotEmitAction) {
      this.helperActions.ExecuteActionsControl(option, false, null, null, this.configPermisos,
        this, null, this.onActionEventEmitter, this.componentsContainer, option, null, this.config);
    }
    else {
      this.onActionEventEmitter.emit({
        config: this.config,
        ControlType: this.config.ControlType, Name: this.config.Name, Action: 'Click',
        Option: option, TableName: this.tableName,
        SubControlType: this.config.SubControlType
      });
    }

  }

  //* END FUNCIONES PERSONALIZADAS

}