
import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, Inject, forwardRef, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, find } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { DataTableColumnTypes, ControlActions, TypesUseForm, TiposUbicacionArchivo, FormActionsThroughEmitters } from '../models/general.enum';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { LoggerService } from '../services/logger.service'
import { StaticData } from '../helpers/static-data';
import { List } from '../../assets/linqts/compilado/index';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { WindowService } from '../components/window.component/window.service';
import { ConfigWindow } from '../models/config-window';


import { Guid } from 'guid-typescript';
import { Utilities } from './utilities';

import { BaseComponentComponent } from '../components/base-component/base-component.component';
import { ComponentsContainer } from '../models/components-container';
import { BaseServiceService } from '../services/base-service.service';
import { GeneralComunicationService } from '../services/general-comunication.service';
import { debug } from 'util';
import { GeneralFormComponent } from '../components/controls/general-form/general-form.component';
import { AbstractControl, Validators } from '@angular/forms';
import { PrintComponent } from '../components/controls/print/print.component';

// import { ExecutionObject } from '../models/rule-engine/rules-models';
declare var saveAs: any;
declare var html2canvas: any;
declare var jsPDF: any;
declare var AzureStorage: any;

export class HelperActions {

    componentsContainerImport: any;
    isTypeImport: boolean = false;

    print: PrintComponent = null;
    constructor(
        @Inject(forwardRef(() => Utilities)) public utility: Utilities,
        @Inject(forwardRef(() => BaseServiceService)) public baseService: BaseServiceService,
        @Inject(forwardRef(() => GeneralComunicationService)) public comunicationService: GeneralComunicationService) {

        this.print = new PrintComponent();
    }


    ExecuteAction(action: any, control: any, isPost: boolean, findItem: any, modelo: any, configPermisos: any,
        context: any, parentContext: any, onEventEmitter: EventEmitter<any>,
        componentsContainer: ComponentsContainer, option: any, modeloMetadata: any, config: any) {

        let actionType: ControlActions = Number.isInteger(action.Type) ? parseInt(action.Type) : ControlActions["" + action.Type];///parseInt(action.Type);
        let propToSet = 'EditItem';
        let url = '';
        let modeloTemp;
        let exportItems: Array<any> = [];
        let queryString: Array<string> = [];
        let crudForm: any = null;
        let floatPanel: any = null;
        if (parentContext && parentContext.form)
            crudForm = parentContext.form;
        else if (context && context.form)
            crudForm = context.form;

        let rules: List<any>;
        if (action.Rules && action.Rules.length > 0) {
            let rulesId = new List<any>(action.Rules);
            rules = new List<any>(StaticData.Usuario.Rules).Where(x => { return rulesId.Any(y => y.GUID == x.GUID) })
        }
        //let currentModel: any = null;
        //this.utility.EditItem = findItem;
        let CustomComponent: any;
        switch (actionType) {
            case ControlActions.Print:
                if (action.ComponentName)
                    this.print.config.Container = action.ComponentName;
                if (action.FileName)
                    this.print.config.FileName = action.FileName;
                this.print.Print();
                break;
            case ControlActions.PrintNewWindow:
                if (action.ComponentName)
                    this.print.config.Container = action.ComponentName;
                if (action.FileName)
                    this.print.config.FileName = action.FileName;
                this.print.PrintNewWindow();
                break;
            case ControlActions.Screenshot:
                if (action.ComponentName)
                    this.print.config.Container = action.ComponentName;
                if (action.FileName)
                    this.print.config.FileName = action.FileName;
                this.print.GenerateImageScreenshot();
                break;
            case ControlActions.PdfScreenshot:
                if (action.ComponentName)
                    this.print.config.Container = action.ComponentName;
                if (action.FileName)
                    this.print.config.FileName = action.FileName;
                this.print.GeneratePdfScreenshot();
                break;
            case ControlActions.ExecuteScript:
                eval(action.PropToSet);
                break;
            case ControlActions.ExportJsonToCsv:
            case ControlActions.ExportJsonToExcel:
                if (!action.FileName)
                    action.FileName = "ArchivoExport";
                if (action.UseCurrentGrid) {
                    if (action.UseSelectedItems) {
                        if (context as GeneralFormComponent) {
                            exportItems = componentsContainer.Tables[0].GetSelectedItems();
                        }
                        else
                            exportItems = context.GetSelectedItems();
                        exportItems = this.utility.Clone(exportItems);
                        this.ConvertExportItems(exportItems, action.ColumnsToDelete);
                        this.utility.ExportAsFile(exportItems, action.FileName, actionType);
                    }
                    else {
                        context.GetItems().then(items => {
                            items = this.utility.Clone(items);
                            this.ConvertExportItems(items, action.ColumnsToDelete);
                            this.utility.ExportAsFile(items, action.FileName, actionType);
                        })
                    }
                }
                else if (action.ComponentName) {
                    let table = componentsContainer.Tables.find(x => {
                        return x.name == action.ComponentName
                    });
                    if (table) {
                        if (action.UseSelectedItems) {
                            exportItems = table.GetSelectedItems();
                            exportItems = this.utility.Clone(exportItems);
                            this.ConvertExportItems(exportItems, action.ColumnsToDelete);
                            this.utility.ExportAsFile(exportItems, action.FileName, actionType);
                        }
                        else {
                            table.GetItems().then(items => {
                                items = this.utility.Clone(items);
                                this.ConvertExportItems(items, action.ColumnsToDelete);
                                this.utility.ExportAsFile(items, action.FileName, actionType);
                            })
                        }
                    }
                    else {
                        this.utility.VerModalDanger({
                            titulo: 'Error',
                            descripcion: 'No se existe la Grilla :' + action.ComponentName, logErr: true
                        })
                    }
                }
                else if (action.ModelContainer) {
                    if (action.PropToSet) {
                        if (Array.isArray(modelo[action.ModelContainer][action.PropToSet]))
                            exportItems = modelo[action.ModelContainer][action.PropToSet];
                        else
                            exportItems.push(modelo[action.ModelContainer][action.PropToSet])
                    }
                    else {
                        if (Array.isArray(modelo[action.ModelContainer]))
                            exportItems = modelo[action.ModelContainer];
                        else
                            exportItems.push(modelo[action.ModelContainer]);
                    }
                    exportItems = this.utility.Clone(exportItems);
                    this.ConvertExportItems(exportItems, action.ColumnsToDelete);
                    this.utility.ExportAsFile(exportItems, action.FileName, actionType);
                }
                else {
                    this.utility.VerModalDanger({
                        titulo: 'Error',
                        descripcion: 'No hay funcionalidad para la accion :' + actionType, logErr: true
                    })
                }
                break;
            case ControlActions.ExportGridButton:
                if (!context) {
                    this.utility.VerModalDanger({
                        titulo: 'Error',
                        descripcion: 'El Contexto de la Grilla no puede ser nulo ', logErr: true
                    })
                    return;
                }
                if (action.IndexExportGridButton == null || action.IndexExportGridButton === undefined) {
                    this.utility.VerModalDanger({
                        titulo: 'Error',
                        descripcion: 'El Indice del formato exportar, de la Grilla no puede ser nulo ', logErr: true
                    })
                    return;
                }
                context.ExportButtonsDataTable(action.IndexExportGridButton);

                break;
            case ControlActions.MosaicView:
                this.comunicationService.raiseChangeGridViewEvent({ IsMosaicView: true, Name: action.ComponentName });
                break;
            case ControlActions.ListView:
                this.comunicationService.raiseChangeGridViewEvent({ IsMosaicView: false, Name: action.ComponentName });
                break;
            case ControlActions.OpenSidePanel:


                this.OpenSidePanel(action, componentsContainer, context, modelo, findItem, crudForm, parentContext);
                // if (action.SidePanelIntNotActive) {

                // }
                // else {
                //     let sidePanel: any = null;
                //     if (action.ComponentName) {
                //         sidePanel = componentsContainer.ChildComponents.find(x => {
                //             return x.subControl &&
                //                 x.subControl.ControlType === 'SidePanel' && x.subControl.Name == action.ComponentName
                //         });
                //     }
                //     else if (action.IndexSidePanel) {
                //         sidePanel = componentsContainer.ChildComponents.filter(x => {
                //             return x.subControl &&
                //                 x.subControl.ControlType === 'SidePanel'
                //         })[action.IndexSidePanel];
                //     }
                //     else {
                //         sidePanel = componentsContainer.ChildComponents.find(x => {
                //             return x.subControl &&
                //                 x.subControl.ControlType === 'SidePanel'
                //         });
                //     }

                //     if (sidePanel) {

                //         if (!sidePanel.subControl.Activo) {
                //             if (!action.ActiveOpenPanelInterval)
                //                 action.ActiveOpenPanelInterval = 300;
                //             sidePanel.subControl.Activo = true;
                //             if (action.ExecuteCreateControls)
                //                 context.CreateControls();
                //             console.log("Asignar activo");

                //             setTimeout(() => {
                //                 sidePanel.OpenSidePanel();
                //                 if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                //                     this.ReloadDataInControl(action, modelo, findItem, context, crudForm, true, componentsContainer, parentContext);
                //                 }
                //             }, action.ActiveOpenPanelInterval);

                //         }
                //         else {
                //             sidePanel.OpenSidePanel();
                //             if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                //                 this.ReloadDataInControl(action, modelo, findItem, context, crudForm, true, componentsContainer, parentContext);
                //             }
                //         }
                //     }
                //     else
                //         this.utility.VerModalDanger({
                //             titulo: 'Error',
                //             descripcion: 'No se existe el panel :' + action.ComponentName, logErr: true
                //         })
                // }
                break;
            case ControlActions.OpenFloatPanel:
            case ControlActions.CloseFloatPanel:

                if (action.ComponentName) {
                    floatPanel = componentsContainer.FloatPanels.find(x => {
                        return x.subControl && x.subControl.Name == action.ComponentName
                    });
                }
                if (floatPanel)
                    floatPanel._config.Hidden = (actionType == ControlActions.OpenFloatPanel) ? false : true;
                else
                    this.utility.VerModalDanger({
                        titulo: 'Error',
                        descripcion: 'No se existe el panel :' + action.ComponentName, logErr: true
                    })

                break;

            case ControlActions.ActiveInactive:

                let dataTable: any = null;
                if (action.ComponentName) {
                    dataTable = componentsContainer.Tables.find(x => {
                        return x.name == action.ComponentName
                    });
                }

                else if (componentsContainer.Tables.length > 0) {
                    dataTable = componentsContainer.Tables[0];
                }
                if (dataTable) {
                    dataTable.Activar(modelo.modelo.CurrentItemIndex, modelo.modelo.PkValue, modelo.modelo.Activo);
                }
                else
                    this.utility.VerModalDanger({
                        titulo: 'Error',
                        descripcion: 'No existe la tabla  :' + action.ComponentName, logErr: true
                    })

                break;
            case ControlActions.ReloadDataControls:
                if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                    this.ReloadDataInControl(action, modelo, findItem, context, crudForm, true, componentsContainer, parentContext);
                }
                break;
            case ControlActions.SetControlProperty:
                if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                    this.SetControlsValue(action, context);
                }
                break;
            case ControlActions.EditInModal:

                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);

                if (context.isModelContainer) {
                    modeloTemp = {
                        configPermisos: configPermisos,
                        typeUseForm: TypesUseForm.ModalEdit,
                        modeloEdit: {
                            modelo: currentModel,//findItem,
                            modeloCascada: modelo.modeloCascada,
                            modeloMetadata: modelo.modeloMetadata,
                            modeloVirtual: modelo.modeloVirtual
                        },
                        controlsForHidden: action.ControlsForHidden,
                        pageId: action.PageId,
                        inMainPage: false,
                        rules: rules,

                    };
                    if (action.PermissionProperty)
                        modeloTemp.permissionsConfig = context[action.PermissionProperty];
                    if (!action.ComponentName)
                        action.ComponentName = "GeneralFormComponent";
                    if (!currentModel)
                        modeloTemp.modeloEdit = modelo;

                    // data: any, componentName: string,
                    // isWindowModel?: boolean, context?: any, modal: boolean = false,
                    // resizable: boolean = true, draggable: boolean = true, onInitialized?: any
                    // this.utility.VerEdicionFormulario(modeloTemp, action.ComponentName + 'Component', true);
                    this.utility.VerEdicionFormulario(modeloTemp, action.ComponentName, true, null, false, true, true,
                        ((action.WhithInicialized) ? context.onExtraInitialized : null)).then(result => {

                            let componentsContainer: ComponentsContainer = context.componentsContainer;
                            if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                                this.ReloadDataInControl(action, modelo, findItem, context, crudForm, true, componentsContainer, parentContext);
                            }
                            if (action.RemoveOnClose) {
                                // OJO FALTA EL NOMBRE DEL COMPONENTE
                                // componentsContainer.Remove("AJSJASJJAS");
                            }
                        });
                }
                else {
                    modeloTemp = {
                        configPermisos: configPermisos, modeloEdit: findItem,
                        controlsForHidden: action.ControlsForHidden
                    };
                    this.utility.VerEdicionFormulario(modeloTemp, action.ComponentName + 'Component', true);
                }
                break;

            case ControlActions.SearchModal:

                if (action.CustomComponent) {
                    CustomComponent = context.Pagina.ConfigPage.HiddenControls.find(x => { return x.Id == action.CustomComponent.GUID });
                    if (!CustomComponent)
                        CustomComponent = StaticData.ToolBox.Components.find((x: any) => { return x.Id == action.CustomComponent.GUID });
                    modeloTemp = {
                        config: CustomComponent,
                        rules: rules,
                        parentContext: context
                    };
                    if (!CustomComponent) {
                        this.utility.VerModalDanger({
                            titulo: 'Error',
                            descripcion: 'No existe componente  :' + action.ComponentName, logErr: true
                        })
                    }
                }
                if (action.HiddenControl) {
                    CustomComponent = context.Pagina.ConfigPage.HiddenControls.find(x => { return x.Name == action.HiddenControl });

                    modeloTemp = {
                        config: CustomComponent,
                        rules: rules,
                        parentContext: context
                    };
                    if (!CustomComponent) {
                        this.utility.VerModalDanger({
                            titulo: 'Error',
                            descripcion: 'No existe componente  :' + action.ComponentName, logErr: true
                        })
                    }
                }
                else {
                    modeloTemp = {
                        config: action.ConfigSearchModal,
                        rules: rules,
                        parentContext: context
                    };
                }

                this.utility.VerEdicionFormulario(modeloTemp, "DataTableComponent", true);
                break;
            case ControlActions.CustomComponent:

                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);

                if (!action.ComponentName)
                    action.ComponentName = "GeneralFormComponent";
                if (action.HiddenControl) {
                    CustomComponent = context.Pagina.ConfigPage.HiddenControls.find(x => {
                        return x.Id == action.HiddenControl ||
                            x.Name == action.HiddenControl
                    });

                }
                else if (action.CustomComponent) {
                    CustomComponent = context.Pagina.ConfigPage.HiddenControls.find(x => {
                        return x.Id == action.CustomComponent.GUID ||
                            x.Name == action.CustomComponent.Name
                    });
                    if (!CustomComponent)
                        CustomComponent = StaticData.ToolBox.Components.find((x: any) => { return x.Id == action.CustomComponent.GUID });
                }
                if (action.CustomComponentContainer) {

                }
                else {
                    modeloTemp = {
                        configPermisos: configPermisos,

                        controlsForHidden: action.ControlsForHidden,
                        inMainPage: false,
                        config: CustomComponent,
                        rules: rules,
                        //saveModelContainer:context.GetSaveModelContainer,
                        //parentContext: context,

                    };
                    modeloTemp.saveModelContainer = () => {
                        return context.GetSaveModelContainer();
                    }
                    if (action.SetModelo) {
                        if (!currentModel)
                            modeloTemp.modelo = modelo;
                        else {
                            modeloTemp.modelo = {
                                modelo: currentModel,//findItem,
                                modeloCascada: modelo.modeloCascada,
                                modeloMetadata: modelo.modeloMetadata,
                                modeloVirtual: modelo.modeloVirtual
                            }
                        }
                    }
                    if (!action.PageId) {
                        modeloTemp.Pagina = context.Pagina;
                    }
                    if (action.PermissionProperty)
                        modeloTemp.permissionsConfig = context[action.PermissionProperty];
                    if (action.PageId)
                        modeloTemp.pageId = action.PageId;

                    if (action.Name)
                        modeloTemp.Name = action.Name;
                    // OJO FALTA ASIGNAR FUNCIONALIDAD DE MAPEO DE PROPIEDADES

                    this.utility.VerEdicionFormulario(modeloTemp, action.ComponentName, true, null, false, true, true,
                        ((action.WhithInicialized) ? context.onExtraInitialized : null), action.ConfigWindow).then(result => {

                            let componentsContainer: ComponentsContainer = context.componentsContainer;
                            if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                                this.ReloadDataInControl(action, modelo, findItem, context, crudForm, true, componentsContainer, parentContext);
                            }
                            if (action.RemoveOnClose) {
                                // OJO FALTA EL NOMBRE DEL COMPONENTE
                                // componentsContainer.Remove("AJSJASJJAS");
                            }
                        });
                }
                break;
            case ControlActions.ViewPage:


                if (action.CustomComponentContainer) {

                }
                else {
                    modeloTemp = {
                        configPermisos: configPermisos,
                        typeUseForm: TypesUseForm.Normal,

                        controlsForHidden: action.ControlsForHidden,
                        pageId: action.PageId,
                        rules: rules,
                        parentContext: context
                    };
                    this.utility.VerEdicionFormulario(modeloTemp, "GeneralFormComponent", true);
                }
                break;
            case ControlActions.InternalNavigation:
                if (action.PropToSet) {
                    if (action.ModelContainer == 'currentItem')
                        this.utility[action.PropToSet] = findItem;
                    else if (action.ModelContainer == 'modelo')
                        this.utility[action.PropToSet] = modelo.modelo;
                    else if (action.ModelContainer == 'staticModel')
                        this.utility[action.PropToSet] = modelo;
                    else if (action.ModelContainer == 'staticDataModel')
                        StaticData[action.PropToSet] = modelo;
                    else if (!action.ModelContainer)
                        this.utility[action.PropToSet] = findItem;
                    else if (action.ModelContainer)
                        this.utility[action.PropToSet] = modelo[action.ModelContainer];
                    if (modeloMetadata)
                        this.utility[action.PropToSet + 'Metadata'] = modeloMetadata;
                }
                let urlNavigation = action.Url;
                if (action.NavigationProp) {
                    if (action.ModelContainer == 'currentItem')
                        urlNavigation = findItem[action.NavigationProp];
                    else if (action.ModelContainer == 'currentModel')
                        urlNavigation = modelo.modelo[action.NavigationProp];
                    else if (action.ModelContainer == 'staticModel')
                        urlNavigation = this.utility[action.NavigationProp];
                    else if (action.ModelContainer == 'staticDataModel')
                        urlNavigation = StaticData[action.NavigationProp];
                    else if (!action.ModelContainer)
                        urlNavigation = findItem[action.NavigationProp];
                    else if (action.ModelContainer)
                        urlNavigation = modelo[action.ModelContainer][action.NavigationProp];
                }


                queryString = [];
                if (action.MappingParameters && action.MappingParameters.length > 0) {
                    action.MappingParameters.forEach(parameter => {

                        const value = this.utility.GetParmeterValue(parameter, modelo, crudForm, null, null, context, parentContext);
                        if (!action.SendQueryString)
                            this.utility.SetValueParmeterValue(parameter, value, modelo, crudForm, null, null, context, parentContext);
                        else {
                            queryString.push(`'${parameter.Name}'='${value}' `);
                        }
                    });
                }
                if (queryString.length > 0) {
                    urlNavigation = "?" + queryString.join("&");
                }

                this.utility.router.navigate([urlNavigation]);
                break;
            case ControlActions.ExternalNavigation:

                url = action.Url;
                if (action.SendQueryString) {
                    propToSet = (action.PropToSet) ? action.PropToSet : propToSet;
                    let data = (action.ToBase64) ? btoa(JSON.stringify(findItem)) : JSON.stringify(findItem);
                    url = `${url}?${propToSet}=${data}`;
                }

                queryString = [];
                if (action.MappingParameters && action.MappingParameters.length > 0) {
                    action.MappingParameters.forEach(parameter => {

                        const value = this.utility.GetParmeterValue(parameter, modelo, crudForm, null, null, context, parentContext);
                        if (!action.SendQueryString)
                            this.utility.SetValueParmeterValue(parameter, value, modelo, crudForm, null, null, context, parentContext);
                        else {
                            queryString.push(`'${parameter.Name}'='${value}' `);
                        }
                    });
                }
                if (queryString.length > 0) {
                    url += "?" + queryString.join("&");
                }
                if (url.includes("window.open"))
                    eval(url);
                else
                    window.location.href = url;
                break;
            case ControlActions.SetSelectedItem:
                propToSet = (action.PropToSet) ? action.PropToSet : propToSet;
                if (action.ModelContainer) {

                    modelo[propToSet] = findItem;

                }
                else
                    this.utility[propToSet] = findItem;
                break;
            case ControlActions.MappingModel:
                if (context.isModelContainer)
                    this.ExecuteMapping(action.MappingValues, modelo, action.PropToSet, findItem, false, modelo, crudForm, null, false, action, componentsContainer);
                else
                    this.ExecuteMapping(action.MappingValues, modelo, action.PropToSet, findItem, false, modelo, crudForm, null, false, action, componentsContainer);
                break;
            case ControlActions.MappingItem:
                this.ExecuteMapping(action.MappingValues, findItem, action.PropToSet, findItem, false, modelo, crudForm, null, false, action, componentsContainer);
                break;
            case ControlActions.MappingContext:
                this.ExecuteMapping(action.MappingValues, context, action.PropToSet, findItem, false, modelo, crudForm, null, false, action, componentsContainer);
                break;
            case ControlActions.MappingParentContext:
                this.ExecuteMapping(action.MappingValues, parentContext, action.PropToSet, findItem, false, modelo, crudForm, null, false, action, componentsContainer);
                break;
            case ControlActions.MappingGeneral:
                propToSet = (action.PropToSet) ? action.PropToSet : propToSet;
                this.ExecuteMapping(action.MappingValues, this.utility, propToSet, findItem, false, modelo, crudForm, null, false, action, componentsContainer);
                break;
            case ControlActions.MappingForm:
                this.ExecuteMapping(action.MappingValues, context.form, action.PropToSet, findItem, true, modelo, context.form, null, false, action, componentsContainer);
                break;

            case ControlActions.MappingParentForm:
                this.ExecuteMapping(action.MappingValues, parentContext.form, action.PropToSet, findItem, true, modelo, parentContext.form, null, false, action, componentsContainer);
                break;
            case ControlActions.MappingModelToModel:
                propToSet = (action.PropToSet) ? action.PropToSet : propToSet;
                this.ExecuteMapping(action.MappingValues, context, propToSet, findItem, false, modelo, crudForm, null, true, action, componentsContainer);
                break;
            case ControlActions.ViewMessage:

                let infoMessage: any;
                let modalTypes: any = ModalNotifyTypes;
                switch (action.MessageType) {
                    case 'success':
                        infoMessage = ModalNotifyTypes.Success;
                        break;
                    case 'info':
                        infoMessage = ModalNotifyTypes.Info;
                        break;
                    case 'warning':
                        infoMessage = ModalNotifyTypes.Warning;
                        break;
                    case 'danger':
                        infoMessage = ModalNotifyTypes.Danger;
                        break;
                    case 'primary':
                        infoMessage = ModalNotifyTypes.Primary;
                        break;
                    case 'secondary':
                        infoMessage = ModalNotifyTypes.Secondary;
                        break;
                    case 'question':
                        infoMessage = ModalNotifyTypes.Question;
                        break;
                    case 'question-warning':
                        infoMessage = ModalNotifyTypes.QuestionWarning;
                        break;
                    case 'question-danger':
                        infoMessage = ModalNotifyTypes.QuestionDanger;
                        break;
                }


                this.utility.VerModalGeneral(infoMessage, {
                    titulo: action.TitleMessage,
                    descripcion: action.Message
                }).then(modalResult => {

                    let _action: any;
                    if (infoMessage.Id == ModalNotifyTypes.Question || infoMessage.Id == ModalNotifyTypes.QuestionDanger ||
                        infoMessage.Id == ModalNotifyTypes.QuestionWarning) {
                        if (modalResult.dialogResult) {
                            _action = control.Actions[action.IndexActionOk];
                            this.ExecuteAction(_action, control, isPost, findItem, modelo, configPermisos, context,
                                parentContext, onEventEmitter, componentsContainer, option, modeloMetadata, config);
                        }
                        else if (action.IndexActionNot) {

                            _action = control.Actions[action.IndexActionNot];
                            this.ExecuteAction(_action, control, isPost, findItem, modelo, configPermisos,
                                context, parentContext, onEventEmitter, componentsContainer, option, modeloMetadata, config);
                        }
                    }
                    else if (action.IndexActionOk) {

                        _action = control.Actions[action.IndexActionOk];
                        this.ExecuteAction(_action, control, isPost, findItem, modelo,
                            configPermisos, context, parentContext, onEventEmitter, componentsContainer, option, modeloMetadata, config);
                    }
                }).catch(err => {
                    this.utility.logger.LogError(err, action);
                })
                break;
            case ControlActions.EmitAction:
            case ControlActions.SaveForm:
            case ControlActions.ClearForm:
                onEventEmitter.emit({ action: action, control: control, findItem: findItem });
                break;
            case ControlActions.ViewDetailsControl:
            case ControlActions.Search:

                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);

                this.ExecuteActionDetails(action, control, isPost, currentModel, componentsContainer);


                break;
            case ControlActions.CloneModel:

                if (context.isModelContainer) {

                    if (parentContext && (parentContext.modeloClone == null || parentContext.modeloClone)) {
                        parentContext.modeloClone = {
                            modelo: this.utility.Clone(findItem),
                            modeloCascada: this.utility.Clone(modelo.modeloCascada),
                            modeloMetadata: this.utility.Clone(modelo.modeloMetadata),
                            modeloVirtual: this.utility.Clone(modelo.modeloVirtual)
                        }

                        this.utility.SetClone(modelo, parentContext.modeloClone);

                        context.onCloneModelEmitter.emit({ action: action, control: control, modeloClone: parentContext.modeloClone });
                    }
                    else {
                        context.modeloClone = {
                            modelo: this.utility.Clone(findItem),
                            modeloCascada: this.utility.Clone(modelo.modeloCascada),
                            modeloMetadata: this.utility.Clone(modelo.modeloMetadata),
                            modeloVirtual: this.utility.Clone(modelo.modeloVirtual)
                        }

                        this.utility.SetClone(modelo, context.modeloClone);
                        if (context.onCloneModelEmitter)
                            context.onCloneModelEmitter.emit({ action: action, control: control, modeloClone: context.modeloClone });
                    }
                }
                else {
                    if (parentContext && (parentContext.modeloClone == null || parentContext.modeloClone)) {
                        parentContext.modeloClone = this.utility.Clone(findItem);
                        context.onCloneModelEmitter.emit({ action: action, control: control, modeloClone: parentContext.modeloClone });
                    }
                    else {
                        context.modeloClone = this.utility.Clone(findItem);
                        context.onCloneModelEmitter.emit({ action: action, control: control, modeloClone: context.modeloClone });
                    }
                }
                break;
            case ControlActions.ViewDocument:
            // if (findItem && (findItem.UnidadTamano == "MB" && findItem.Tamano > 20
            //         || findItem.UnidadTamano == "KB" && findItem.Tamano > 20000)) {
            //     this.utility.VerModalWarning({
            //         titulo: 'Alerta', descripcion: 'El tamaño del archivo excede 20 megas, no es permitida la visualización, por favor descargue el archivo',
            //         verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
            //     }).then(data => { });
            // }
            // else
            // {
            //     var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);
            //     this.DownloadFile(action, option, currentModel, context,
            //         parentContext, onEventEmitter, componentsContainer, actionType, config);
            // }
            // break;
            case ControlActions.DownloadDocument:
            case ControlActions.SendToSiteDocument:
            case ControlActions.DownloadAsBytesDocument:
            case ControlActions.DownloadAsBase64Document:
            case ControlActions.DownloadAsUrlDocument:

            case ControlActions.DownloadDocumentByConfig:

                debugger
                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);
                this.DownloadFile(action, option, currentModel, context,
                    parentContext, onEventEmitter, componentsContainer, actionType, config);
                break;
            case ControlActions.Export:

                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);

                this.ExecuteObject(action, option, currentModel, context,
                    parentContext, onEventEmitter, componentsContainer, actionType, config, "Export");
                break;
            case ControlActions.Import:

                this.componentsContainerImport = '';
                this.isTypeImport = true;
                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);
                this.ViewLoadWindow(action, option, currentModel, context,
                    parentContext, onEventEmitter, componentsContainer, actionType, config);
                break;
            case ControlActions.GetTemplateForImport:
                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);
                this.ExecuteObject(action, option, currentModel, context,
                    parentContext, onEventEmitter, componentsContainer, actionType, config, "GetTemplateForImport");

                break;
            case ControlActions.ExecuteObject:

                var currentModel = this.GetModeloModelContainer(action, modelo, findItem, context, crudForm);

                this.ExecuteObject(action, option, currentModel, context,
                    parentContext, onEventEmitter, componentsContainer, actionType, config, "ExecuteObject");

                break;
            default:


                break;

        }
    }

    public OpenSidePanel(action: any, componentsContainer: ComponentsContainer, context: any, modelo: any, findItem: any, crudForm: any, parentContext: any, triggeredSuscription: any = null) {

        if (triggeredSuscription && !triggeredSuscription.closed) {
            triggeredSuscription.unsubscribe();
        }

        if (action.SidePanelIntNotActive) {

        }
        else {
            let sidePanel: any = null;
            if (action.ComponentName) {
                sidePanel = componentsContainer.ChildComponents.find(x => {
                    return x.subControl &&
                        x.subControl.ControlType === 'SidePanel' && x.subControl.Name == action.ComponentName
                });
            }
            else if (action.IndexSidePanel) {
                sidePanel = componentsContainer.ChildComponents.filter(x => {
                    return x.subControl &&
                        x.subControl.ControlType === 'SidePanel'
                })[action.IndexSidePanel];
            }
            else {
                sidePanel = componentsContainer.ChildComponents.find(x => {
                    return x.subControl &&
                        x.subControl.ControlType === 'SidePanel'
                });
            }

            if (sidePanel) {

                if (!sidePanel.subControl.Activo) {
                    if (!action.ActiveOpenPanelInterval)
                        action.ActiveOpenPanelInterval = 300;
                    sidePanel.subControl.Activo = true;
                    if (action.ExecuteCreateControls)
                        context.CreateControls();
                    console.log("Asignar activo");

                    setTimeout(() => {
                        sidePanel.OpenSidePanel();
                        if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                            this.ReloadDataInControl(action, modelo, findItem, context, crudForm, true, componentsContainer, parentContext);
                        }
                    }, action.ActiveOpenPanelInterval);

                }
                else {
                    sidePanel.OpenSidePanel();
                    if (action.ControlsToUpdate && action.ControlsToUpdate.length > 0) {

                        this.ReloadDataInControl(action, modelo, findItem, context, crudForm, true, componentsContainer, parentContext);
                    }
                }
            }
            else {
                if (context && (triggeredSuscription == null || triggeredSuscription.closed)) {
                    const suscription = this.comunicationService.receivedFormEvent.subscribe(
                        (x) => {
                            if (
                                x.Action ===
                                FormActionsThroughEmitters.AfterViewInit
                            ) {
                                return this.OpenSidePanel(
                                    action,
                                    componentsContainer,
                                    context,
                                    modelo,
                                    findItem,
                                    crudForm,
                                    parentContext,
                                    suscription
                                );
                            }
                        }
                    );
                }
                else {
                    this.utility.VerModalDanger({
                        titulo: 'Error',
                        descripcion: 'No se existe el panel :' + action.ComponentName, logErr: true
                    })
                }
            }
            this.comunicationService.raiseFormEvent({
                Action: FormActionsThroughEmitters.OpenedSidePanel,
            });

            //JUANBR: Las datatables cargadas en SidePanel ocultas no salen ajustadas
            $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
        }
    }
    ReloadDataInControl(action, modelo, findItem, context, form, clearModel, componentsContainer?: ComponentsContainer, parentContext?: any) {

        if (!componentsContainer)
            componentsContainer = context.componentsContainer;
        action.ControlsToUpdate.forEach(element => {


            let currentcomponent = componentsContainer.Find(element);
            if (currentcomponent) {
                this.componentsContainerImport = currentcomponent;

                if (action.SetContext)
                    currentcomponent.currentContext = context;
                if (action.SetParentContext)
                    currentcomponent.parentContext = parentContext;
                if (action.OnlyFirst && currentcomponent.IsReloadData && currentcomponent.name != 'GrillaUsuarioConcepto') {
                    // implementar las lineas de acciones
                } else {
                    if (currentcomponent.config && currentcomponent.config.ControlType == 'GridView') {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.cargarGrilla = true;
                        currentcomponent.ReCargarGrilla(clearModel);

                    }
                    else if (currentcomponent.config && currentcomponent.config.ControlType == 'AttachmentFileContainer') {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.ExcecuteDataSource();
                    }
                    //FunctionalAudit
                    else if (currentcomponent.config && currentcomponent.config.ControlType == 'FunctionalAudit') {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.LoadAll();
                    }
                    else if (currentcomponent.subControl && currentcomponent.subControl.ControlType == 'Pagina') {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.ExcecuteDataSource();
                    }
                    else if (currentcomponent.subControl && currentcomponent.isHtml) {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.LoadAll();
                    }
                    //isChart
                    else if (currentcomponent.subControl && currentcomponent.isChart) {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.ExcecuteDataSource();
                    }
                    else if (currentcomponent.subControl && currentcomponent.isDashboard) {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.ExcecuteDataSource();
                    }
                    else if (currentcomponent.ReloadGeneral) {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.ReloadGeneral();
                    }
                    else if (currentcomponent.LoadAll) {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.LoadAll();
                    }
                    else if (currentcomponent.ExcecuteDataSource) {
                        //
                        currentcomponent.IsReloadData = true;
                        currentcomponent.notLoad = false;
                        currentcomponent.ExcecuteDataSource();
                    }
                }
            }
            else {
                this.utility.logger.LogErrorText("Control no encntrado para recargar, Nombre: " + element);
            }

        });
    }
    GetModeloModelContainer(action, modelo, findItem, context, form) {

        let currentModel: any = findItem;
        switch (action.ModelContainer) {
            case 'modelo':
                if (context.isModelContainer)
                    currentModel = modelo.modelo;
                else
                    currentModel = modelo;
                break;
            case 'currentItem':
                currentModel = (findItem==null)? modelo.currentItem : findItem ;
                break;
            case 'staticModel':
                currentModel = this.utility[action.PropStaticModel];
                break;
            case 'staticDataModel':
                currentModel = StaticData[action.PropStaticModel];
                break;
            case 'formModel':
                currentModel = form.controls;
                break;
            default:
                if (action.ModelContainer) {
                    if (context.isModelContainer)
                        currentModel = modelo[action.ModelContainer];
                    else
                        currentModel = modelo;
                }
                else {
                    currentModel = findItem;
                }
                break;
        }
        return currentModel;
    }


    ExecuteActionsControlTimer(actions: Array<any>, control: any, isPost: boolean, findItem: any, modelo: any, configPermisos: any,
        context: any, parentContext: any, onEventEmitter: EventEmitter<any>,
        componentsContainer: ComponentsContainer, option: any, modeloMetadata: any, config: any) {

        actions.forEach((action: any, index: number) => {
            if (!option)
                option = action;
            if (action.TimerAction) {
                setTimeout(() => {
                    this.ExecuteAction(action, control, isPost, findItem, modelo,
                        configPermisos, context, parentContext, onEventEmitter, componentsContainer, option, modeloMetadata, config);
                }, action.TimerAction);
            }
            else
                this.ExecuteAction(action, control, isPost, findItem, modelo, configPermisos,
                    context, parentContext, onEventEmitter, componentsContainer, option, modeloMetadata, config);

        });
    }
    public ExecuteActionsControl(control: any, isPost: boolean, findItem: any, modelo: any, configPermisos: any,
        context: any, parentContext: any, onEventEmitter: EventEmitter<any>, componentsContainer: ComponentsContainer,
        option: any, modeloMetadata: any, config: any) {


        if (control && control.Actions) {

            if (control.TimerToAllActions) {
                control.Actions.forEach((action: any, index: number) => {
                    action.TimerAction = control.TimerToAllActions;
                });
            }

            let listOfActions = new List<any>(control.Actions);
            if (listOfActions.Any(x => { return x.Type == ControlActions.EmiteAllAction })) {
                onEventEmitter.emit({ control: control, findItem: findItem });
                return;
            }
            let messageActions = new List<any>(control.Actions.filter(x => x.Type == ControlActions.ViewMessage && x.IndexActionOk));

            let actions: Array<any> = listOfActions.Where(x => { return !messageActions.Contains(x) }).ToArray();



            if (control.TimerActions) {
                setTimeout(() => {
                    this.ExecuteActionsControlTimer(actions, control, isPost, findItem, modelo,
                        configPermisos, context, parentContext, onEventEmitter, componentsContainer, option, modeloMetadata, config);
                }, control.TimerActions);
            }
            else {
                this.ExecuteActionsControlTimer(actions, control, isPost, findItem,
                    modelo, configPermisos, context, parentContext, onEventEmitter, componentsContainer, option, modeloMetadata, config);
            }

        }
    }

    public ExecuteMappingComponets(action: any, context: any, item: any, modelo?: any,
        form?: any, queryConfig?: any, isModelToModel?: boolean, componentsContainer?: ComponentsContainer) {

        let value = null;


        action.MappingValues.forEach((mapping: any, _index: number) => {


            let SourceProp = mapping.SourceProp;
            let InternalSourceProp = null;
            let SourcePropList = (SourceProp) ? mapping.SourceProp.split('.') : null;
            if (SourcePropList && SourcePropList.length > 1) {
                SourceProp = SourcePropList[0];
                InternalSourceProp = SourcePropList[1];
            }
            let Prop = mapping.Prop;
            let InternalProp = null;
            let PropList = (Prop) ? mapping.Prop.split('.') : null;
            if (PropList && PropList.length > 1) {
                Prop = PropList[0];
                InternalProp = PropList[1];
            }

            if (isModelToModel) {
                if (mapping.ModelContainerSource == "currentItem") {
                    value = item;
                }
                else if (mapping.ModelContainerSource == "staticModel") {
                    if (InternalSourceProp)
                        value = this.utility[SourceProp][InternalSourceProp];
                    else
                        value = this.utility[SourceProp];
                }
                else if (mapping.ModelContainerSource == "staticDataModel") {
                    if (InternalSourceProp)
                        value = StaticData[SourceProp][InternalSourceProp];
                    else
                        value = StaticData[SourceProp];
                }
                else if (mapping.ModelContainerSource == "formModel") {
                    if (InternalSourceProp) {
                        if (mapping.SourceProp)
                            value = form.controls[SourceProp][InternalSourceProp];
                        else
                            value = form.controls[InternalSourceProp];
                    }
                    else {
                        if (mapping.SourceProp)
                            value = form.controls[SourceProp];
                        else
                            value = form.controls;
                    }
                }
                else if (mapping.ModelContainerSource == "queryParameters") {
                    if (InternalSourceProp)
                        value = queryConfig.Entity[InternalSourceProp];
                    else
                        value = queryConfig.Entity;
                }
                else if (mapping.ModelContainerSource == "modeloInterno") {
                    if (InternalSourceProp)
                        value = context[SourceProp][InternalSourceProp];
                    else
                        value = context[SourceProp];
                }
                else {
                    value = modelo[mapping.ModelContainerSource];
                }


                action.MappingComponents.forEach(elementName => {

                    let element: any = componentsContainer.Find(elementName);
                    if (!element) {
                        element = context[elementName];
                    }
                    if (mapping.FunctionName) {

                        if (mapping.ModelContainer == "staticModel") {
                            this.utility[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "staticDataModel") {
                            StaticData[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "formModel") {

                            element.form[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "queryParameters") {
                            element.QueryConfig[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "modeloInterno") {
                            element[mapping.FunctionName](value);
                        }
                        else {
                            context[mapping.FunctionName](value);
                        }

                    } else {
                        if (mapping.ModelContainer == "currentItem") {
                            item = value;
                        }
                        else if (mapping.ModelContainer == "staticModel") {
                            if (InternalProp)
                                this.utility[Prop][InternalProp] = value
                            else
                                this.utility[Prop] = value
                        }
                        else if (mapping.ModelContainer == "staticDataModel") {
                            if (InternalProp)
                                StaticData[Prop][InternalProp] = value
                            else
                                StaticData[Prop] = value
                        }
                        else if (mapping.ModelContainer == "formModel") {
                            if (InternalProp) {
                                element.form.controls[Prop][InternalProp].setValue(value);
                            }
                            else {
                                if (Prop)
                                    element.form.controls[Prop].setValue(value);
                                else
                                    element.form.controls = value;
                            }
                        }
                        else if (mapping.ModelContainer == "queryParameters") {
                            if (InternalProp) {
                                element.QueryConfig.Entity[Prop][InternalProp] = value;
                            }
                            else {
                                if (Prop)
                                    element.QueryConfig.Entity[Prop] = value;
                                else
                                    element.QueryConfig.Entity = value;
                            }
                        }
                        else if (mapping.ModelContainer == "modeloInterno") {
                            if (InternalProp)
                                element[Prop][InternalProp] = value;
                            else
                                element[Prop] = value;
                        }
                        else {
                            if (InternalProp)
                                element.modelo[mapping.ModelContainer][InternalProp] = value;
                            else
                                element.modelo[mapping.ModelContainer] = value;
                        }
                    }
                });
            }
            else {
                if (mapping.ModelContainerSource == "currentItem") {
                    if (InternalSourceProp)
                        value = this.utility.ConvertValue(item[SourceProp][InternalSourceProp], mapping.DataType)
                    else {
                        value = (SourceProp) ? this.utility.ConvertValue(item[SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    }
                }
                else if (mapping.ModelContainerSource == "staticModel") {
                    if (InternalSourceProp)
                        value = this.utility.ConvertValue(this.utility[SourceProp][InternalSourceProp], mapping.DataType);
                    else
                        value = (SourceProp) ? this.utility.ConvertValue(this.utility[SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                }
                else if (mapping.ModelContainerSource == "staticDataModel") {
                    if (InternalSourceProp)
                        value = this.utility.ConvertValue(StaticData[SourceProp][InternalSourceProp], mapping.DataType);
                    else
                        value = (SourceProp) ? this.utility.ConvertValue(StaticData[SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                }
                else if (mapping.ModelContainerSource == "formModel") {
                    if (InternalSourceProp)
                        value = this.utility.ConvertValue(form.controls[SourceProp][InternalSourceProp].value, mapping.DataType);
                    else
                        value = (SourceProp) ? this.utility.ConvertValue(form.controls[SourceProp].value, mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                }
                else if (mapping.ModelContainerSource == "queryParameters") {
                    if (InternalSourceProp)
                        value = this.utility.ConvertValue(queryConfig.Entity[SourceProp][InternalSourceProp], mapping.DataType);
                    else
                        value = (SourceProp) ? this.utility.ConvertValue(queryConfig.Entity[SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                }
                else if (mapping.ModelContainerSource == "modeloInterno") {
                    if (InternalSourceProp)
                        value = this.utility.ConvertValue(context[SourceProp][InternalSourceProp], mapping.DataType);
                    else
                        value = (SourceProp) ? this.utility.ConvertValue(context[SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                }
                else {
                    if (InternalSourceProp)
                        value = this.utility.ConvertValue(modelo[mapping.ModelContainerSource][SourceProp][InternalSourceProp], mapping.DataType);
                    else
                        value = (SourceProp) ? this.utility.ConvertValue(modelo[mapping.ModelContainerSource][SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                }

                action.MappingComponents.forEach(elementName => {

                    let element: any = componentsContainer.Find(elementName);
                    if (!element) {
                        element = context[elementName];
                    }
                    if (mapping.FunctionName) {

                        if (mapping.ModelContainer == "staticModel") {
                            this.utility[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "staticDataModel") {
                            StaticData[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "formModel") {

                            element.form[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "queryParameters") {
                            element.QueryConfig[mapping.FunctionName](value);
                        }
                        else if (mapping.ModelContainer == "modeloInterno") {
                            element[mapping.FunctionName](value);
                        }
                        else {
                            context[mapping.FunctionName](value);
                        }

                    } else {
                        if (mapping.ModelContainer == "currentItem") {
                            item[Prop] = value;
                        }
                        else if (mapping.ModelContainer == "staticModel") {
                            if (InternalProp)
                                this.utility[Prop][InternalProp] = value
                            else
                                this.utility[Prop] = value
                        }
                        else if (mapping.ModelContainer == "staticDataModel") {
                            if (InternalProp)
                                StaticData[Prop][InternalProp] = value
                            else
                                StaticData[Prop] = value
                        }
                        else if (mapping.ModelContainer == "formModel") {
                            if (InternalProp)
                                element.form.controls[Prop][InternalProp].setValue(value);
                            else
                                element.form.controls[Prop].setValue(value);
                        }
                        else if (mapping.ModelContainer == "queryParameters") {
                            if (InternalProp)
                                element.QueryConfig.Entity[Prop][InternalProp] = value;
                            else
                                element.QueryConfig.Entity[Prop] = value;
                        }
                        else if (mapping.ModelContainer == "modeloInterno") {
                            if (InternalProp)
                                element[Prop][InternalProp] = value;
                            else
                                element[Prop] = value;
                        }
                        else {
                            if (InternalProp)
                                element.modelo[mapping.ModelContainer][Prop][InternalProp] = value;
                            else
                                element.modelo[mapping.ModelContainer][Prop] = value;
                        }
                    }

                });
            }


        });
    }
    public InternalExecuteMapping(mapping: any, context: any, item: any, modelo?: any, form?: any, queryConfig?: any, isModelToModel?: boolean) {

        let value = null;

        if (isModelToModel) {
            if (mapping.ModelContainerSource == "currentItem") {
                value = item;
            }
            else if (mapping.ModelContainerSource == "staticModel") {
                value = this.utility[mapping.SourceProp];
            }
            else if (mapping.ModelContainerSource == "staticDataModel") {
                value = StaticData[mapping.SourceProp];
            }
            else if (mapping.ModelContainerSource == "formModel") {
                if (mapping.SourceProp)
                    value = form.controls[mapping.SourceProp];
                else
                    value = form.controls;
            }
            else if (mapping.ModelContainerSource == "queryParameters") {
                value = queryConfig.Entity;
            }
            else if (mapping.ModelContainerSource == "modeloInterno") {
                value = context[mapping.SourceProp];
            }
            else {
                value = modelo[mapping.ModelContainerSource];
            }

            if (mapping.ModelContainer == "currentItem") {
                item = value;
            }
            else if (mapping.ModelContainer == "staticModel") {
                this.utility[mapping.Prop] = value
            }
            else if (mapping.ModelContainer == "staticDataModel") {
                StaticData[mapping.Prop] = value
            }
            else if (mapping.ModelContainer == "formModel") {
                if (mapping.Prop)
                    form.controls[mapping.Prop].setValue(value);
                else
                    form.controls = value;
            }
            else if (mapping.ModelContainer == "queryParameters") {
                if (mapping.Prop)
                    queryConfig.Entity[mapping.Prop] = value;
                else
                    queryConfig.Entity = value;
            }
            else if (mapping.ModelContainer == "modeloInterno") {
                context[mapping.Prop] = value;
            }
            else {
                modelo[mapping.ModelContainer] = value;
            }
        }
        else {
            if (mapping.ModelContainerSource == "currentItem") {
                value = (mapping.SourceProp) ? this.utility.ConvertValue(item[mapping.SourceProp], mapping.DataType)
                    : this.utility.ConvertValue(mapping.Value, mapping.DataType);
            }
            else if (mapping.ModelContainerSource == "staticModel") {
                value = (mapping.SourceProp) ? this.utility.ConvertValue(this.utility[mapping.SourceProp], mapping.DataType)
                    : this.utility.ConvertValue(mapping.Value, mapping.DataType);
            }
            else if (mapping.ModelContainerSource == "staticDataModel") {
                value = (mapping.SourceProp) ? this.utility.ConvertValue(StaticData[mapping.SourceProp], mapping.DataType)
                    : this.utility.ConvertValue(mapping.Value, mapping.DataType);
            }
            else if (mapping.ModelContainerSource == "formModel") {
                value = (mapping.SourceProp) ? this.utility.ConvertValue(form.controls[mapping.SourceProp].value, mapping.DataType)
                    : this.utility.ConvertValue(mapping.Value, mapping.DataType);
            }
            else if (mapping.ModelContainerSource == "queryParameters") {
                value = (mapping.SourceProp) ? this.utility.ConvertValue(queryConfig.Entity[mapping.SourceProp], mapping.DataType)
                    : this.utility.ConvertValue(mapping.Value, mapping.DataType);
            }
            else if (mapping.ModelContainerSource == "modeloInterno") {
                value = (mapping.SourceProp) ? this.utility.ConvertValue(context[mapping.SourceProp], mapping.DataType)
                    : this.utility.ConvertValue(mapping.Value, mapping.DataType);
            }
            else {
                value = (mapping.SourceProp) ? this.utility.ConvertValue(modelo[mapping.ModelContainerSource][mapping.SourceProp], mapping.DataType)
                    : this.utility.ConvertValue(mapping.Value, mapping.DataType);
            }

            if (mapping.ModelContainer == "currentItem") {
                item[mapping.Prop] = value;
            }
            else if (mapping.ModelContainer == "staticDataModel") {
                StaticData[mapping.Prop] = value
            }
            else if (mapping.ModelContainer == "staticModel") {
                this.utility[mapping.Prop] = value
            }
            else if (mapping.ModelContainer == "formModel") {
                form.controls[mapping.Prop].setValue(value);
            }
            else if (mapping.ModelContainer == "queryParameters") {
                queryConfig.Entity[mapping.Prop] = value;
            }
            else if (mapping.ModelContainer == "modeloInterno") {
                context[mapping.Prop] = value;
            }
            else {
                modelo[mapping.ModelContainer][mapping.Prop] = value;
            }
        }
    }
    public ExecuteMapping(mappingValues: Array<any>, context: any, propToSet: string, item: any,
        isForm?: boolean, modelo?: any, form?: any, queryConfig?: any, isModelToModel?: boolean,
        action?: any, componentsContainer?: ComponentsContainer) {

        //MappingComponents
        if (action &&
            action.MappingComponents && action.MappingComponents.length > 0) {

            this.ExecuteMappingComponets(action, context, item, modelo, form, queryConfig, isModelToModel, componentsContainer);
            return;
        }
        if (isForm || isModelToModel) {
            mappingValues.forEach((mapping: any, _index: number) => {

                let value = null;
                if (mapping.ModelContainerSource && mapping.ModelContainer) {
                    this.InternalExecuteMapping(mapping, context, item, modelo, form, queryConfig, isModelToModel);
                }
                else if (mapping.ModelContainerSource) {

                    if (mapping.ModelContainerSource == "currentItem") {
                        value = (mapping.SourceProp) ? this.utility.ConvertValue(item[mapping.SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    }
                    else if (mapping.ModelContainerSource == "staticModel") {
                        value = (mapping.SourceProp) ? this.utility.ConvertValue(this.utility[mapping.SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    }
                    else if (mapping.ModelContainerSource == "staticDataModel") {
                        value = (mapping.SourceProp) ? this.utility.ConvertValue(StaticData[mapping.SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    }
                    else if (mapping.ModelContainerSource == "formModel") {
                        value = (mapping.SourceProp) ? this.utility.ConvertValue(queryConfig.controls[mapping.SourceProp].value, mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    }
                    else if (mapping.ModelContainerSource == "queryParameters") {
                        value = (mapping.SourceProp) ? this.utility.ConvertValue(queryConfig.Entity[mapping.SourceProp].value, mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    }
                    else {
                        value = (mapping.SourceProp) ? this.utility.ConvertValue(modelo[mapping.ModelContainerSource][mapping.SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    }
                    context.controls[mapping.Prop].setValue(value);
                }
                else {


                    value = (mapping.SourceProp) ? this.utility.ConvertValue(item[mapping.SourceProp], mapping.DataType)
                        : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                    context.controls[mapping.Prop].setValue(value);

                }
            });
        }
        else {
            mappingValues.forEach((mapping: any, _index: number) => {

                let value = null;
                if (mapping.ModelContainerSource && mapping.ModelContainer) {
                    this.InternalExecuteMapping(mapping, context, item, modelo, form, queryConfig, isModelToModel);
                } else {
                    if (mapping.ModelContainerSource) {

                        if (mapping.ModelContainerSource == "currentItem") {
                            value = (mapping.SourceProp) ? this.utility.ConvertValue(item[mapping.SourceProp], mapping.DataType)
                                : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                        }
                        else if (mapping.ModelContainerSource == "staticModel") {
                            value = (mapping.SourceProp) ? this.utility.ConvertValue(this.utility[mapping.SourceProp], mapping.DataType)
                                : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                        }
                        else if (mapping.ModelContainerSource == "staticDataModel") {
                            value = (mapping.SourceProp) ? this.utility.ConvertValue(StaticData[mapping.SourceProp], mapping.DataType)
                                : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                        }
                        else if (mapping.ModelContainerSource == "formModel") {
                            value = (mapping.SourceProp) ? this.utility.ConvertValue(form.controls[mapping.SourceProp].value, mapping.DataType)
                                : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                        }
                        else if (mapping.ModelContainerSource == "queryParameters") {
                            value = (mapping.SourceProp) ? this.utility.ConvertValue(queryConfig.Entity[mapping.SourceProp].value, mapping.DataType)
                                : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                        }
                        else {
                            value = (mapping.SourceProp) ? this.utility.ConvertValue(modelo[mapping.ModelContainerSource][mapping.SourceProp], mapping.DataType)
                                : this.utility.ConvertValue(mapping.Value, mapping.DataType);
                        }
                    }
                    else
                        value = (mapping.SourceProp) ? this.utility.ConvertValue(item[mapping.SourceProp], mapping.DataType)
                            : this.utility.ConvertValue(mapping.Value, mapping.DataType);


                    if (mapping.ModelContainer) {
                        if (propToSet && mapping.Prop)
                            context[mapping.ModelContainer][propToSet][mapping.Prop] = value;
                        else if (propToSet)
                            context[mapping.ModelContainer][propToSet] = value;
                        else if (mapping.Prop)
                            context[mapping.ModelContainer][mapping.Prop] = value;
                        else
                            context[mapping.ModelContainer] = value;
                    }
                    else {
                        if (propToSet && mapping.Prop)
                            context[propToSet][mapping.Prop] = value;
                        else if (propToSet)
                            context[propToSet] = value;
                        else if (mapping.Prop)
                            context[mapping.Prop] = value;
                    }
                }
            });
        }
    }

    public ExecuteMappingFromTo(mappingValues: Array<any>, source: any, dest: any) {

        mappingValues.forEach((mapping: any, _index: number) => {
            let value: any = source[mapping.From];
            value = this.utility.ConvertValue(value, mapping.DataType);
            if (mapping.ModelContainer)
                dest[mapping.ModelContainer][mapping.To] = value;
            else
                dest[mapping.To] = value;

        });
    }

    public ViewComponent(componentName: string, _config: any, title: string, modelo: any = null): Promise<any> {

        return new Promise((resolve, rejection) => {


            let config: ConfigWindow = new ConfigWindow();
            config.classWindow = 'info';
            config.titleWindow = title;
            config.returnInstance = false;
            config.modal = false;
            config.width = 600;
            config.height = 500;
            let windowModel: any = {
                config: _config,
                modelo: modelo
            }

            this.utility.OpenWindow(componentName,
                config, windowModel).then(dataResult => {


                    dataResult.onClosePromise.then(dataResultOnClose => {

                        if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
                            dataResultOnClose.modelo) {


                            resolve(dataResultOnClose);
                        }
                        else {

                            resolve(dataResultOnClose);
                        }
                    });

                })
        });
    }



    public ExecuteActionDetails(action: any, control: any, isPost: boolean,
        findItem: any, componentsContainer: ComponentsContainer) {

        if (action.ControlDetails.Name) {
            if (!componentsContainer) {
                this.utility.logger.LogWarningText('El objeto componentsContainer es nulo');
                return;
            }

            if (componentsContainer.Tables) {
                componentsContainer.Tables.forEach((component, _index: number) => {

                    if (component.name == action.ControlDetails.Name) {
                        if (component.QueryConfig) {
                            this.ExecuteMappingFromTo(action.ControlDetails.MappingFilter, findItem, component.QueryConfig.Entity);
                            component.cargarGrilla = true;
                            component.ReCargarGrilla();
                        }
                        else
                            this.utility.logger.LogWarningText('El objeto QueryConfig es nulo');
                    }


                });
            }
            else
                this.utility.logger.LogWarningText('El objeto contenedor de tablas es nulo');

            if (componentsContainer.FormsDetails) {
                componentsContainer.FormsDetails.forEach((component, _index: number) => {

                    if (component.name == action.ControlDetails.Name) {
                        if (component.QueryConfig) {
                            this.ExecuteMappingFromTo(action.ControlDetails.MappingFilter, findItem, component.QueryConfig.Entity);

                        }
                        else
                            this.utility.logger.LogWarningText('El objeto QueryConfig es nulo');
                        component.notLoad = false;
                        component.CargarDatos(findItem);


                    }


                });
            }
            else
                this.utility.logger.LogWarningText('El objeto contenedor de Forms es nulo');

        }
        else if (action.ControlDetails.Control) {

            let component = (action.ControlDetails.ControlType == 'GridView') ? 'DataTableComponent' : 'FormDetailsComponent';
            let configControl = null;
            if (action.ControlDetails.ControlType == 'GridView') {
                configControl = this.utility.Clone(action.ControlDetails.Control);
                this.ExecuteMappingFromTo(action.ControlDetails.MappingFilter, findItem, configControl.ConfigControl.QueryConfig.Entity);

            }
            else {
                configControl = this.utility.Clone(action.ControlDetails);
                this.ExecuteMappingFromTo(action.ControlDetails.MappingFilter, findItem, configControl.Control.ConfigControl.QueryConfig.Entity);

            }
            this.ViewComponent(component, configControl, configControl.Label, findItem).then(result => {

            });


        }
    }



    public GetComponentName(action: any, context: any, config: any) {
        let componentName: string = '';
        if (action.ComponentName)
            componentName = action.ComponentName;
        else if (config && config.importUrl)
            componentName = config.importUrl;
        else if (config && config.ConfigControl && config.ConfigControl.Controller)
            componentName = config.ConfigControl.Controller;
        else if (context.componentName)
            componentName = context.componentName;
        else if (context.name)
            componentName = context.name;

        // if (!action.Url)
        //     action.Url = "General";
        if (!action.Url)
            action.Url = componentName;
        return componentName
    }

    public DownloadFile(action: any, option: any, findItem: any, context: any,
        parentContext: any, onEventEmitter: EventEmitter<any>, componentsContainer: ComponentsContainer,
        actionType: ControlActions, config: any, docViewerConfig?: any) {


        if (!findItem) {
            return console.warn("ERROR: Se intentó descargar archivo sin parámetro findItem");
        }
        let w: any = null;
        // DownloadFileRequest: any = { "FileModelType": "Documents", "ResultType": "Url", "DownloadOption": "SendToScreen" };

        //  { label: 'Descargar Base64', downloadOpcion:"Base64", icono: '', opcionPermiso: '' },
        //  { label: 'Descargar ByteArray', downloadOpcion:'ByteArray', icono: '', opcionPermiso: '' },
        //  { label: 'Descargar Url', downloadOpcion:"Url", icono: '', opcionPermiso: '' },
        //  { label: 'Descargar Sitio Personal', downloadOpcion:"Site",  icono: '', opcionPermiso: '' },
        let isDownload: Boolean = false;

        isDownload = (ControlActions.DownloadDocument == actionType ||
            ControlActions.SendToSiteDocument == actionType ||
            ControlActions.DownloadAsBytesDocument == actionType ||
            ControlActions.DownloadAsBase64Document == actionType ||
            ControlActions.DownloadAsUrlDocument == actionType ||
            ControlActions.DownloadDocumentByConfig == actionType);


        //////////////////INICIO COMENTARIO MANUEL RODRIGUEZ 04/12/2020/////////////////////////////////////////////////
        // COMETAREADO DADO QUE EN DETALLE DE EXPEDIENTE LOS DOCUMENTOS PUEDEN SER DE CUALQUIER TIPO DE RADICADO Y
        // FUE NECESARIO TRAER EL NOMBRE DEL COMPONENTE DESDE LA BASE DE DATOS DADO QUE EL COMPONENTE DE ATTACHMENT FILE SOLO PERMITE ASOCIAR UN CONTROLADOR
        // PERO EL SP CONSULTAARCHIVOSMULTIRADICADOS TRAE LOS ARCHIVOS DEPENDIENDO DEL TIPO DE RADICADO SELECCIONADO Y EL ID DE LA SOLICITUD
        // MANUEL RODRIGUEZ - 04/12/2020
        //
        //let componentName: string = this.GetComponentName(action, context, config);
        //
        //////////////////INICIO COMENTARIO MANUEL RODRIGUEZ 04/12/2020/////////////////////////////////////////////////

        let componentName: string = findItem && findItem.Modelo ? (findItem.Modelo['ComponentName']) ? findItem.Modelo['ComponentName'] : this.GetComponentName(action, context, config) : this.GetComponentName(action, context, config);

        if (!option.downloadFileRequest)
            option.downloadFileRequest = { ResultType: "Url", DownloadOption: "SendToScreen" };

        option.downloadFileRequest.IsDownload = isDownload;
        if (actionType == ControlActions.DownloadDocument) {

            if (option.downloadOpcion == "Base64" ||
                option.downloadOpcion == "ByteArray" ||
                option.downloadOpcion == "Url") {
                option.downloadFileRequest.ResultType = option.downloadOpcion;
                option.downloadFileRequest.DownloadOption = "SendToScreen";
            }
            else if (option.resultType == "Base64" ||
                option.resultType == "ByteArray" ||
                option.resultType == "Url") {
                option.downloadFileRequest.ResultType = option.resultType;
                option.downloadFileRequest.DownloadOption = "SendToScreen";
            }
            else if (option.downloadOpcion == "View") {
                option.downloadFileRequest.ResultType = "Url";
                option.downloadFileRequest.DownloadOption = "SendToSite";
            }
            else if (option.downloadOpcion == "Site") {
                option.downloadFileRequest.ResultType = "Url";
                option.downloadFileRequest.DownloadOption = "SendToSite";
            }

        }
        else if (actionType == ControlActions.SendToSiteDocument) {

            if (!option.downloadFileRequest) {
                option.downloadFileRequest = {
                    "FileModelType": "Documents",
                    "ResultType": "Url",
                    "DownloadOption": "SendToSite"
                };
            }
            else {
                option.downloadFileRequest.ResultType = "Url";
                option.downloadFileRequest.DownloadOption = "SendToSite";
            }
        }
        else if (actionType == ControlActions.SendToLibrary) {


            if (!option.downloadFileRequest) {
                option.downloadFileRequest = {
                    "FileModelType": "Documents",
                    "ResultType": "Url",
                    "DownloadOption": "SendToLibrary"
                };
            }
            else {
                option.downloadFileRequest.ResultType = "Url";
                option.downloadFileRequest.DownloadOption = "SendToLibrary";
            }

            option.downloadFileRequest.SHPSiteUrl = null;
            option.downloadFileRequest.SHPLibrary = null;
            option.downloadFileRequest.SHPUser = null;
            option.downloadFileRequest.SHPPassword = null;
            option.downloadFileRequest.SHPDomain = null;
        }

        else if (actionType == ControlActions.DownloadAsBytesDocument) {
            if (!option.downloadFileRequest) {
                option.downloadFileRequest = {
                    "FileModelType": "Documents",
                    "ResultType": "ByteArray",
                    "DownloadOption": "SendToScreen"
                };
            }
            else {
                option.downloadFileRequest.ResultType = "ByteArray";
                option.downloadFileRequest.DownloadOption = "SendToScreen";
            }
        }
        else if (actionType == ControlActions.DownloadAsBase64Document) {
            if (!option.downloadFileRequest) {
                option.downloadFileRequest = {
                    "FileModelType": "Documents",
                    "ResultType": "Base64",
                    "DownloadOption": "SendToScreen"
                };
            }
            else {
                option.downloadFileRequest.ResultType = "Base64";
                option.downloadFileRequest.DownloadOption = "SendToScreen";
            }
        }
        else if (actionType == ControlActions.DownloadAsUrlDocument) {

            if (!option.downloadFileRequest) {
                option.downloadFileRequest = {
                    "FileModelType": "Documents",
                    "ResultType": "Url",
                    "DownloadOption": "SendToScreen"
                };
            }
            else {
                option.downloadFileRequest.ResultType = "Url";
                option.downloadFileRequest.DownloadOption = "SendToScreen";
            }
        }
        else if (actionType == ControlActions.ViewDocument) {

            if (!option.downloadFileRequest) {
                option.downloadFileRequest = {
                    "FileModelType": "Documents",
                    "ResultType": "Url",
                    "DownloadOption": "SendToScreen"
                };
            }
            else {
                option.downloadFileRequest.ResultType = "Url";
                option.downloadFileRequest.DownloadOption = "SendToScreen";
            }
        }

        if (findItem.FileToSearch) {
            option.downloadFileRequest.FileToSearch = findItem.FileToSearch;
        }

        if ((findItem.IdTipoDocumental == null) && (findItem.IdTipoUbicacionArchivo == null || findItem.IdTipoUbicacionArchivo == "") && (!findItem.FileToSearch) && (!findItem.Ruta) && (!findItem.UbicacionArchivo)) {
            let errorAccionSinPrincipal = (actionType == ControlActions.ViewDocument) ? "vea" : "descargue";
            errorAccionSinPrincipal
            this.utility.VerModalError(`El documento seleccionado no tiene archivo principal definido. Por favor asigne un archivo principal o ${errorAccionSinPrincipal} sus adjuntos únicamente.`);
            return;
        }
        findItem['DataAdd'] = { DownloadFileRequest: JSON.stringify(option.downloadFileRequest) };



        // COMETAREADO POARA MEJORA DE CODIGO
        // MARLON GRANDA - 22/10/2020
        //VALIDAR SI ES SHP
        // let isJSONParseable = false;
        // try {
        //     isJSONParseable = JSON.parse(findItem.UbicacionArchivo);
        //     if (isJSONParseable && typeof isJSONParseable === "object") {
        //         isJSONParseable = true;
        //     }
        // }
        // catch (e) {
        //     isJSONParseable = false;
        // }

        // if (findItem.UbicacionArchivo && isJSONParseable && JSON.parse(findItem.UbicacionArchivo).Ruta && JSON.parse(findItem.UbicacionArchivo).Ruta != '') {
        //     // if (actionType == ControlActions.ViewDocument) {
        //     //     this.PreviewDocumentSharePoint(JSON.parse(findItem.UbicacionArchivo).Ruta);
        //     // }
        //     // else {

        //     let SPDomain = JSON.parse(findItem.UbicacionArchivo).Cnn;
        //     SPDomain += (JSON.parse(findItem.UbicacionArchivo).Cnn.toString().endsWith("/") ? "" : "/") + "_layouts/download.aspx?SourceUrl=";
        //     SPDomain += JSON.parse(findItem.UbicacionArchivo).Ruta;
        //     this.utility.DownloadByUrl({ Url: SPDomain });
        //     findItem['DataAdd'] = { type: "Sharepoint" }
        //     // }
        //     return;
        // }
        // if (findItem.IdTipoDocumental && isJSONParseable && JSON.parse(findItem.IdTipoDocumental).Ruta && JSON.parse(findItem.IdTipoDocumental).Ruta != '') {
        //     // if (actionType == ControlActions.ViewDocument) {
        //     //     this.PreviewDocumentSharePoint(JSON.parse(findItem.UbicacionArchivo).Ruta);
        //     // }
        //     // else {
        //     let SPDomain = JSON.parse(findItem.UbicacionArchivo).Cnn;
        //     SPDomain += (JSON.parse(findItem.UbicacionArchivo).Cnn.toString().endsWith("/") ? "" : "/") + "_layouts/download.aspx?SourceUrl=";
        //     SPDomain += JSON.parse(findItem.UbicacionArchivo).Ruta;
        //     this.utility.DownloadByUrl({ Url: SPDomain });
        //     findItem['DataAdd'] = { type: "Sharepoint" }
        //     // }
        //     return;
        // }

        ///////////////////FIN COMETAREADO MARLON GRANDA 22/10/2020/////////////////////////////////////////////////

        //////////////////INICIO COMENTARIO MANUEL RODRIGUEZ 04/12/2020/////////////////////////////////////////////////
        // COMETAREADO DADO QUE EN DETALLE DE EXPEDIENTE LOS DOCUMENTOS PUEDEN SER DE CUALQUIER TIPO DE RADICADO Y
        // AL CAMBIAR DE TIPO DE RADICADO PARA DESCARGAR LOS DOCUMENTOS DEL OTRO EL OBJETO action.Url DEL CONTROL QUEDA CON
        // EL COMPONENTE DEL RADICADO ANTERIOR
        // MANUEL RODRIGUEZ - 04/12/2020
        //
        //if (!action.Url)
        //    action.Url = componentName;
        //else
        //    componentName = action.Url;
        //////////////////INICIO COMENTARIO MANUEL RODRIGUEZ 04/12/2020/////////////////////////////////////////////////


        if (findItem.Modelo) {
            if (findItem.Modelo['ComponentName']) {
                action.Url = componentName;
            }
            else {
                if (!action.Url)
                    action.Url = componentName;
                else
                    componentName = action.Url;
            }

        }
        else {
            if (!action.Url)
                action.Url = componentName;
            else
                componentName = action.Url;
        }




        let isJSONParseable = false;
        let ubicacionArchivo: any = null;


        try {
            if (findItem.UbicacionArchivo && findItem.UbicacionArchivo.includes('{')) {
                ubicacionArchivo = JSON.parse(findItem.UbicacionArchivo);
                if (ubicacionArchivo.Files && ubicacionArchivo.Files.length > 0) {
                  if(findItem.DownloadFile){
                    ubicacionArchivo = ubicacionArchivo.Files.find(x => { return x.Nombre == findItem.DownloadFile.Nombre; });
                    if (ubicacionArchivo && ubicacionArchivo.ConfigTipoUbicacionArchivo) {
                        ubicacionArchivo = ubicacionArchivo.ConfigTipoUbicacionArchivo;
                        findItem.NombreDocumento = findItem.DownloadFile.Nombre;
                    }
                  }
                  else{
                    //var firstInArray = ubicacionArchivo.Files.filter(x=> x.EsAdjunto == false);
                    var firstInArray = ubicacionArchivo.Files.filter(x=> x.EsAdjunto == false || x.EsPlantilla == true);
                    ubicacionArchivo = firstInArray[0].ConfigTipoUbicacionArchivo;
                    findItem.Nombre = firstInArray[0].Nombre;
                  }

                }
                if (ubicacionArchivo && typeof ubicacionArchivo === "object") {
                    isJSONParseable = true;
                }
            }
            else if (findItem.Modelo.UbicacionArchivo && findItem.Modelo.UbicacionArchivo.includes('{')) {
                let Archivos = JSON.parse(findItem.Modelo.UbicacionArchivo);
                if (Archivos && typeof Archivos === "object") {
                    ubicacionArchivo = Archivos.Files.find(x => { return x.Nombre == findItem.Nombre; });
                    if(findItem.Url == null && ubicacionArchivo.Url != null){
                        findItem.Url = ubicacionArchivo.Url
                    }
                    if (ubicacionArchivo && ubicacionArchivo.ConfigTipoUbicacionArchivo) {
                        ubicacionArchivo = ubicacionArchivo.ConfigTipoUbicacionArchivo;
                        findItem.NombreDocumento = findItem.Nombre;
                        isJSONParseable = true;
                    }
                }
            }

        }
        catch (e) {
            isJSONParseable = false;
        }

        if (isJSONParseable && (ubicacionArchivo.TipoUbicacionArchivo == 'SharePoint' ||
            ubicacionArchivo.TipoUbicacionArchivo == 'SharePointOnline')) {
            // if (actionType == ControlActions.ViewDocument) {
            //     this.PreviewDocumentSharePoint(JSON.parse(findItem.UbicacionArchivo).Ruta);
            // }
            // else {


            if (findItem.IdDocumentoExpediente) {
                this.GetDownloadSettings(ubicacionArchivo, action, actionType, option, docViewerConfig, componentName, findItem, context);
            }
            else if (actionType == ControlActions.DownloadAsUrlDocument && findItem.Url != null) {
                let w: any = window.open("");
                var downloadLinkSHP = findItem.Url;
                //W ES UNDEFINED SI EL NAVEGADOR BLOQUEÓ EL POPUP
                if (w) {
                    w.location.href = downloadLinkSHP;
                }
            }
            else
                this.OpenInSharePoint(ubicacionArchivo, findItem);

            // let SPDomain = JSON.parse(findItem.UbicacionArchivo).Cnn;
            // SPDomain += (JSON.parse(findItem.UbicacionArchivo).Cnn.toString().endsWith("/") ? "" : "/") + "_layouts/download.aspx?SourceUrl=";
            // SPDomain += JSON.parse(findItem.UbicacionArchivo).Ruta;
            // this.utility.DownloadByUrl({ Url: SPDomain });
            // findItem['DataAdd'] = { type: "Sharepoint" }
            // }
            return;
        }

        if (isJSONParseable && ubicacionArchivo.TipoUbicacionArchivo == 'AzureBlobStorage') {
            var ConnectionSplit = ubicacionArchivo.Cnn.split(';');
            var AccountName = ConnectionSplit[1].substring(ConnectionSplit[1].lastIndexOf('=') + 1, ConnectionSplit[1].length);
            var sasToken = ubicacionArchivo.Token;
            debugger
            if (findItem.IdDocumentoExpediente)
                sasToken = StaticData.GeneralConfig.ConfigExpediente.ConfigDocumento.ConfigTipoUbicacionArchivo.Token
            else if (findItem.Modelo && findItem.Modelo.IdTipoSolicitud)
            {
                var configDocumentoSearch = StaticData.GeneralConfig.ConfigUbicaciones.find(x => { return x.IdTipoSolicitud == findItem.Modelo.IdTipoSolicitud; });
                sasToken = configDocumentoSearch.ConfigTipoUbicacionArchivo.Token
            }
            var blobUri = 'https://' + AccountName + '.blob.core.windows.net';

            //var blobService = AzureStorage.Blob.createBlobServiceWithSas(blobUri, sasToken);
            //var contenedor = ubicacionArchivo.Tabla;
            var blobname = findItem.NombreDocumento;
            var urlDocument = '';

            if(findItem && findItem.EsAdjunto !== undefined)
                urlDocument = findItem.Url;
            else {
              var archivos = JSON.parse(findItem.Archivos);
              archivos = archivos.filter(x=> x.EsAdjunto == false);
              urlDocument = archivos[0].Url;
            }

            //var downloadLink = blobUri + '/' + contenedor + '/' + blobname + sasToken;
            //MP ahora se trae la url de campo configuracion
            var downloadLink = urlDocument + sasToken; // archivos[0].Url + sasToken;
            if (actionType != ControlActions.ViewDocument) {
                if (context && context.FireFormEvent) {
                    context.FireFormEvent({
                        Name: FormActionsThroughEmitters.OnDownloadAzureBlob,
                        modelo: context.modelo,
                    });

                }
                if (option.downloadFileRequest.ResultType == "Url" || option.downloadFileRequest.ResultType == "Base64") {
                    if (option.downloadFileRequest.DownloadOption != 'SendToSite') {
                        let w: any = window.open("");
                        //W ES UNDEFINED SI EL NAVEGADOR BLOQUEÓ EL POPUP
                        if (w) {
                            w.location.href = downloadLink;
                        }
                    }
                }
            }
            else {
                if (option.downloadFileRequest.ResultType == "Url" || option.downloadFileRequest.ResultType == "Base64") {
                    if (option.Type == 'ViewDocument') {
                        if (context && context.FireFormEvent) {
                            context.FireFormEvent({
                                Name: FormActionsThroughEmitters.OnViewAzureBlob,
                                modelo: context.modelo,
                            });

                        }
                        this.OpenDocumentViewer(downloadLink, action.ViewerType, blobname);
                    }
                }
            }

            return;
        }
        if (isJSONParseable && (ubicacionArchivo.TipoUbicacionArchivo == "FileSystem" || ubicacionArchivo.TipoUbicacionArchivo == "AWSS3")) {
            //   var filySytemName = findItem.NombreDocumento;
            //   var fileSystemUrl = ubicacionArchivo.Cnn + ubicacionArchivo.CampoArchivo; //ubicacionArchivo.Ruta;


            if (actionType != ControlActions.ViewDocument) {
                if (context && context.FireFormEvent) {
                    context.FireFormEvent({
                        //Name: FormActionsThroughEmitters.OnDownloadFileSystem,
                        Name: (ubicacionArchivo.TipoUbicacionArchivo == "AWSS3" ? FormActionsThroughEmitters.OnDownloadAWSS3 : FormActionsThroughEmitters.OnDownloadFileSystem),
                        modelo: context.modelo,
                    });

                }
                //     if (option.downloadFileRequest.ResultType == "Url" || option.downloadFileRequest.ResultType == "Base64") {
                //         if (option.downloadFileRequest.DownloadOption != 'SendToSite') {
                //             let w: any = window.open("");
                //             //W ES UNDEFINED SI EL NAVEGADOR BLOQUEÓ EL POPUP
                //             if (w) {
                //                 w.location.href = fileSystemUrl;
                //             }
                //         }
                //     }
                // }
                // else {

                //   if (option.downloadFileRequest.ResultType == "Url" || option.downloadFileRequest.ResultType == "Base64") {
                //     if(option.Type == "ViewDocument"){
                //       if (context && context.FireFormEvent) {
                //         context.FireFormEvent({
                //             Name: FormActionsThroughEmitters.OnViewFileSystem,
                //             modelo: context.modelo,
                //         });
                //     }
                //       this.OpenDocumentViewer(fileSystemUrl, action.ViewerType, filySytemName);
                //     }
                //   }

            }
            this.InternalDownloadFile(action, actionType, option, docViewerConfig, componentName, findItem, context);
            return;
        }

        // Creado por Marlon Granda
        //22/10/2020
        this.InternalDownloadFile(action, actionType, option, docViewerConfig, componentName, findItem, context);


    }
    // Creado por Marlon Granda
    //22/10/2020
    public GetDownloadSettings(ubicacionArchivo, action, actionType, option, docViewerConfig, componentName, findItem, context) {




        let obj = this.utility.GetApiModel('GetDownloadSettings', null)
        obj.QueryConfig = {
            Command: 'ConsultarDocumentoExpedienteConfiguracion',
            IsSp: true,
            NotSetDefault: true,
            Entity: {
                IdEmpresa: -1,
                IdDocumentoExpediente: findItem.IdDocumentoExpediente
            }
        };

        let info = {
            componentName: "DocumentDownloadSettingsContainerComponent", method: 'GetDownloadSettings',
            processMessage: 'Cargando Config Documento Para Descargas......'
        };

        this.baseService.Get(obj, null, info, componentName, false, false).then(data => {


            let configDownloadSettings: any = null;
            if (data.Data && data.Data.length > 0 && data.Data[0].Configuracion) {
                configDownloadSettings = JSON.parse(data.Data[0].Configuracion);
            }

            if (configDownloadSettings && configDownloadSettings.DownloadSettings && configDownloadSettings.DownloadSettings.length > 0) {


                let currentDocSettings: any = null;
                configDownloadSettings.DownloadSettings.forEach(docSettings => {

                    if (!currentDocSettings && docSettings.Active) {
                        if (this.utility.TienePemisoUsuarioEnObjeto(new List<any>(docSettings.Permissions)))
                            currentDocSettings = docSettings;
                    }
                });
                if (currentDocSettings) {
                    if (currentDocSettings.Type == 'DocumentReplacement')
                        this.OpenInSharePoint(currentDocSettings.File.ConfigTipoUbicacionArchivo, findItem, context);
                    else
                        this.InternalDownloadFile(action, actionType, option, docViewerConfig, componentName, findItem, context);
                }
                else
                    this.OpenInSharePoint(ubicacionArchivo, findItem, context);


            }
            else
                this.OpenInSharePoint(ubicacionArchivo, findItem, context);

        }).catch(err => { });

    }
    // Creado por Marlon Granda
    //22/10/2020
    OpenInSharePoint(ubicacionArchivo, findItem, context = null) {

        if (context && context.FireFormEvent) {
            context.FireFormEvent({
                Name: FormActionsThroughEmitters.OnDownloadSharePoint,
                modelo: context.modelo,
            });

        }

        let SPDomain = ubicacionArchivo.Cnn;
        SPDomain += (ubicacionArchivo.Cnn.toString().endsWith("/") ? "" : "/") + "_layouts/download.aspx?SourceUrl=";
        SPDomain += ubicacionArchivo.Ruta;
        this.utility.DownloadByUrl({ Url: SPDomain });
        findItem['DataAdd'] = { type: "Sharepoint" }
    }
    // Creado por Marlon Granda
    //22/10/2020
    InternalDownloadFile(action, actionType, option, docViewerConfig, componentName, findItem, context) {

        let obj = this.utility.GetApiModel('Descargar', 'Download', null, null, findItem);
        this.baseService.Download(obj, {
            componentName: componentName + 'Controller',
            method: 'Descargar', processMessage: (action.ProcessMessage) ? action.ProcessMessage : 'Descargando Documento....'
        }, action.Url, false, false).then(data => {

            data.Data.Extension = data.Data.Extension.replace('.', '');
            if (actionType == ControlActions.ViewDocument) {

                if (docViewerConfig)
                    context.url = data.Data.Url;
                else
                    this.OpenDocumentViewer(data.Data.Url, action.ViewerType);

            }
            else {
                if (option.downloadFileRequest.ResultType == 'ByteArray') {
                    this.utility.DownloadByBytes(data.Data);
                }
                else if (option.downloadFileRequest.ResultType == "Base64") {

                    this.utility.DownloadByBase64(data.Data);
                }
                else if (option.downloadFileRequest.ResultType == "Url") {
                    if (option.downloadOpcion == 'View') {
                        this.OpenDocumentViewer(data.Data.Url, action.ViewerType);
                    }
                    else if (option.downloadFileRequest.DownloadOption != 'SendToSite') {
                        this.utility.DownloadByUrl(data.Data);
                    }
                }
            }

        }).catch(error => {



            this.utility.logger.LogError(error, obj);
        });
    }
    public LocalDocumentViewer(url, extension, viewOnly = false) {

        let protocol;
        //ofv: Open for view, ofe: Open for editing
        let viewerOptions = viewOnly ? "ofv|u|" : "ofe|u|";
        if (extension === "doc" || extension === "docx") {
            protocol = "ms-word";
        }
        else if (extension === "xls" || extension === "xlsx") {
            protocol = "ms-excel"
        }
        else {
            return false;
        }

        // OJO NO ENTIENDO POR QUE LA DE MODAL Y NO LA HREF QUE ES LA QUE ESTA EN EL EJEMPLO
        // let newWindow = window.open("");
        // if (newWindow) {
        //     window.location.href = `${protocol}:${viewerOptions}${url}`;


        let newWindow = window.open(`${protocol}:${viewerOptions}${url}`, "_self");
        if (newWindow)
            return true;
        //En caso de que se niegue la petición (pop-up).
        return false;

    }

    public PreviewDocumentSharePoint(url, ViewerType?: string) {
        const extension: string = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        if (extension === "doc" || extension === "docx" || extension === "xls" || extension === "xlsx") {
            if (!this.LocalDocumentViewer(url, extension, false))
                return this.utility.VerModalError("No se pudo previsualizar el archivo")
        }
        else {
            let newWindow = window.open(url, "_blank");
            if (!newWindow) {
                return this.utility.VerModalError("No se pudo previsualizar el archivo")
            }
        }

    }

    public OpenDocumentViewer(url, ViewerType?: string, nameDocument?: string) {
        const configWindow = this.utility.GetConfigWindow('Visor de Documentos');
        configWindow.ok = "Aceptar";
        // let extension: string = url.substring(url.lastIndexOf('.') + 1);
        // extension = extension.toLowerCase();
        // const isImage: boolean = (extension == "png" || extension == "jpg" || extension == "jpeg" || extension == "gif");
        let windowModel: any = {
            url: url,
            viewerType: (ViewerType) ? ViewerType : "google",
            nameDocument: nameDocument
            // isImage: isImage

        };
        this.utility.VerModalComponente("DocViewerComponent", windowModel, configWindow).then(dataResultOnClose => {
            if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

            }
            else {

            }
        });
    }
    public ExecuteObject(action: any, option: any, modelo: any, context: any,
        parentContext: any, onEventEmitter: EventEmitter<any>, componentsContainer: ComponentsContainer,
        actionType: ControlActions, config: any, method: string = "ExecuteObject") {

        let currentModel: any = (!modelo) ? { Token: "", ExecutionObject: null } : this.utility.Clone(modelo);
        let executeObject: any = null;

        if (action.ObjectSystem) {
            executeObject = {};
            //SubObjectSystem
            executeObject.GUID = action.ObjectSystem.GUID;
            executeObject.Name = action.ObjectSystem.Name;
            executeObject.ObjectType = action.ObjectSystem.ObjectType;
            executeObject.FilterMetadata = action.FilterMetadata;
            if (!action.ResultType)
                action.ResultType = 'None';
            executeObject.ResultType = action.ResultType;


            executeObject.ExportImportItem = action.ExportImportItem;
            executeObject.DestinationTemplate = action.DestinationTemplate;

            executeObject.Model = modelo;
            if (action.SubObjectSystem) {
                executeObject.ObjectsToRun.push(action.SubObjectSystem);
            }
            executeObject.Token = currentModel.Token;
            executeObject.AppKey = StaticData.AppKey;
            if (action.ReturnQueryType) {
                executeObject.ReturnQueryType = action.ReturnQueryType;
            }
            currentModel.ExecutionObject = executeObject;
        }

        let w: any = null;
        let componentName: string = this.GetComponentName(action, context, config);

        let obj = this.utility.GetApiModel(method, method, null, null, currentModel);
        if (method == "Export") {


            let component = componentsContainer.Tables.find(t => { return t.name == action.ControlDetails.Name });
            if (component) {
                if (component.QueryConfig) {
                    obj.QueryConfig = component.QueryConfig;
                }
                else
                    this.utility.logger.LogWarningText('El objeto QueryConfig es nulo');
            }
            else {
                this.utility.VerModalDanger({ titulo: 'Error', descripcion: 'No se encontrl el componente ' + action.ControlDetails.Name })
                this.utility.logger.LogWarningText('No se encontrl el componente ' + action.ControlDetails.Name);
                return;
            }
        }


        if (action.ObjectSystem && action.ObjectSystem.ObjectType == 'Rule' && StaticData.Usuario.Rules && context) {
            let rulesList: List<any> = new List<any>(StaticData.Usuario.Rules);
            let rule = rulesList.FirstOrDefault(x => {
                return x.IdPage == context.Pagina.IdPagina && x.RuleType == 'UI' && x.IsExternal !== true &&
                    (x.GUID == action.ObjectSystem.GUID || x.Name == action.ObjectSystem.Name)
            });
            if (rule) {

                context.helperRulesContext.ExecuteRule(rule, {}, { Name: 'ExecuteObject' }, null, null, null);
                return;
            }
        }
        if (method == "GetTemplateForImport")
            obj.QueryConfig.ExportXmlTemplate = action.ExportXmlTemplate ? action.ExportXmlTemplate : false
        this.baseService[method](obj, {
            componentName: componentName + 'Controller',
            method: method,
            processMessage: (action.ProcessMessage) ? action.ProcessMessage : 'Procesando....'
        }, action.Url, false, false).then(data => {
            if (action.ObjectSystem && action.ObjectSystem.ObjectType == "Template") {
                let fileResult: any = null;
                if (action.ObjectSystem) {
                    //fileResult = (data.Data) ? data.Data.FileResult[0] : data.FileResult[0];
                    fileResult = (data.Data) ? (data.Data.FileResult) ? data.Data.FileResult[0] : (data.FileResult) ? data.FileResult[0] : null : (data.FileResult) ? data.FileResult[0] : null;
                    if (fileResult)
                        fileResult.Extension = fileResult.Extension.replace('.', '');

                }
                else {
                    if (Array.isArray(data.Data))
                        fileResult = data.Data[0];
                    else
                        fileResult = data.Data;
                }

                if (action.DestinationSystemObject == "View") {
                    this.OpenDocumentViewer(fileResult.Url, action.ViewerType);
                }
                else {
                    if (fileResult && fileResult.ResultType) {
                        if (fileResult.ResultType == 'ByteArray') {
                            this.utility.DownloadByBytes(fileResult);
                        }
                        else if (fileResult.ResultType == "Base64") {

                            this.utility.DownloadByBase64(fileResult);
                        }
                        else if (fileResult.ResultType == "Url" || fileResult.ResultType == "None") {
                            this.utility.DownloadByUrl(fileResult);
                        }
                    }
                }
            }
            else if (action.ObjectSystem && action.ObjectSystem.ObjectType == "Rule") {


                context.helperRulesContext.ExecuteRule(data.Rule, {}, { Name: 'ExecuteObject' }, null, null, null);
                return;


            }
            else {
                //console.log(data.Data);

                let fileResult: any = null;
                if (action.ObjectSystem) {
                    //fileResult = (data.Data) ? data.Data.FileResult[0] : data.FileResult[0];
                    fileResult = (data.Data) ? (data.Data.FileResult) ? data.Data.FileResult[0] : (data.FileResult) ? data.FileResult[0] : null : (data.FileResult) ? data.FileResult[0] : null;
                    if (fileResult)
                        fileResult.Extension = fileResult.Extension.replace('.', '');

                }
                else {
                    if (Array.isArray(data.Data))
                        fileResult = data.Data[0];
                    else
                        fileResult = data.Data;
                }

                if (action.DestinationSystemObject == "View") {
                    this.OpenDocumentViewer(fileResult.Url, action.ViewerType);
                }
                else {
                    if (fileResult && fileResult.ResultType) {
                        if (fileResult.ResultType == 'ByteArray') {
                            this.utility.DownloadByBytes(fileResult);
                        }
                        else if (fileResult.ResultType == "Base64") {

                            this.utility.DownloadByBase64(fileResult);
                        }
                        else if (fileResult.ResultType == "Url" || fileResult.ResultType == "None") {
                            this.utility.DownloadByUrl(fileResult);
                        }
                        else
                            this.utility.VerEditor(2, data.Data, false, "Resultado", "json", true)
                    }
                }

            }
            // else
            //     this.utility.DownloadByUrl(data.Data);
            if(action.EventEmmitResponse)           
                onEventEmitter.emit({ Action: FormActionsThroughEmitters.ResponseEmmit, Response: data.Data});

        }).catch(error => { this.utility.logger.LogError(error, obj) });

    }

    public ViewLoadWindow(action: any, option: any, modelo: any, context: any,
        parentContext: any, onEventEmitter: EventEmitter<any>,
        componentsContainer: ComponentsContainer,
        actionType: ControlActions, config: any) {

        let componentName: string = this.GetComponentName(action, context, config);

        const configWindow = this.utility.GetConfigWindow('');
        configWindow.viewCancel = false;
        configWindow.ok = "Cerrar";
        configWindow.NotApplyDestroyAllSubscriptions = action.NotApplyDestroyAllSubscriptions?action.NotApplyDestroyAllSubscriptions:false;
        let windowModel: any = {
            pageId: "EstadoCarga",
            importUrl: componentName,
            context: context.modelo,
            exportXmlTemplate: action.ExportXmlTemplate ? action.ExportXmlTemplate : false
        };

        this.utility.VerModalComponente("EstadoCargaComponent", windowModel, configWindow).then(dataResultOnClose => {
            if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {



            }
            else {

            }
        });

    }

    MultiSetControlsValue(actions, context) {
        const actionTemplate = {
            ControlDetails: {
                MappingData: [],
                MappingFilter: [],
            },
            ControlType: "Action",
            ControlsForHidden: [],
            ControlsToUpdate: [],
            Guid: Guid.create().toString(),
            IsPost: false,
            IsParentModel: false,
            MappingValues: [],
            MappingComponents: [],
            Property: "",
            PropertyValue: "",
            ResultType: "Url",
            SendQueryString: false,
            TimerAction: 0,
            Type: ControlActions[ControlActions.SetControlProperty],
        }
        if (Array.isArray(actions) && actions.forEach) {
            actions.forEach(action => {
                const { PropertiesToSet, ControlsToUpdate } = action;
                if (PropertiesToSet) {
                    actionTemplate.ControlsToUpdate = ControlsToUpdate;
                    for (const property in PropertiesToSet) {
                        {
                            const PropertyValue = PropertiesToSet[property];
                            actionTemplate.Property = property + "";
                            actionTemplate.PropertyValue = PropertyValue + "";
                            this.SetControlsValue(actionTemplate, context);
                        }
                    }
                }
            })
        }
    }

    SetControlsValue(action, context) {
        action.ControlsToUpdate.forEach(controlName => {
            let formControl: any = context.controls.find ? context.controls.find(x => { return x.control.Name == controlName }) : context.controls.FirstOrDefault(x => { return x.control.Name == controlName });
            formControl = formControl && formControl.control ? formControl.control : formControl;
            let $control = $('#' + controlName);
            let $label = $("label[for='" + controlName + "']")
            let valueToSet = action.PropertyValue;
            let isInput = ($control.length > 0 || formControl);
            if (isInput) {
                this.SetInputControlValue($control, $label, formControl, controlName, action, context);
            }
            else {
                //
                let component: any = null;

                let componentsContainer: ComponentsContainer = context.componentsContainer;

                if (formControl == null) {
                    this.utility.logger.LogErrorText("SetControlValue,No se encontro el componente:" + controlName);
                    return;
                }
                component = componentsContainer.Find(formControl.Name);


                if (!component) {
                    this.utility.logger.LogErrorText("SetControlValue,No se encontro el componente:" + controlName);
                    return;
                }
                switch (action.Property.toLowerCase()) {
                    case 'visible':

                        if (valueToSet == 'true') {

                            component.Hidden = false;
                        }
                        else {
                            component.Hidden = true;
                        }
                        break;
                    case 'active':
                        let isactive = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        formControl.Activo = isactive;
                        component.Disabled = isactive;
                        break;
                    case 'hidden':
                        let ishidden = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        formControl.Hidden = ishidden;
                        component.Hidden = ishidden;
                        break;
                    case 'enabled':
                        let Isdisabled = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        Isdisabled = !Isdisabled;
                        $control.attr("disabled", Isdisabled.toString());
                        $label.attr("disabled", Isdisabled.toString());


                        component.Disabled = !Isdisabled;
                        break;
                    case 'readonly':
                        let isreadonly = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        $control.attr("readonly", isreadonly.toString());
                        component.Disabled = isreadonly;
                        break;
                    case 'pattern':

                        break;
                    case 'maxlength':


                        break;
                    case 'minlength':


                        break;
                    case 'max':

                        break;
                    case 'min':

                        break;
                    case 'required':


                        break;
                    case 'datasource':

                        break;
                    case 'config':

                        break;
                    case 'configcontrol':

                        break;
                    case 'configprop':

                        break;
                    case 'configpropjson':

                        break;
                    //
                    case 'value':

                        break;
                    case 'text':
                        //control.val(reglaData[2]);
                        $label.text(valueToSet);
                        break;
                    case 'title':
                        $control.attr("title", valueToSet);
                        break;
                    case 'placeholder':
                        $control.attr("placeholder", valueToSet);
                        break;

                }
            }
        });




    }
    public SetValidations(action, context, formControl) {
        let ctr: AbstractControl = context.form.controls[formControl.Name];
        ctr.clearValidators();
        let validations = [];
        if (formControl.Required)
            validations.push(Validators.required);
        if (formControl.MaxLength)
            validations.push(Validators.maxLength(formControl.MaxLength));
        if (formControl.MinLength)
            validations.push(Validators.minLength(formControl.MinLength));
        if (formControl.ControlType == 'NumberBox') {
            if (formControl.Max != null && !isNaN(formControl.Max) && formControl.Max != formControl.Min)
                validations.push(Validators.max(Number(formControl.Max)));
            if (formControl.Min != null && formControl.Max != null && formControl.Min == formControl.Max)
                console.warn(`Se ignoró validación Max dado que es igual que Min, en ${formControl.Name}`);
            if (formControl.Min != null && !isNaN(formControl.Min))
                validations.push(Validators.min(Number(formControl.Min)));
        }
        if (formControl.ControlType == 'EmailBox')
            validations.push(Validators.email);
        if (formControl.Pattern)
            validations.push(Validators.pattern(formControl.Pattern));


        ctr.setValidators(validations);

        //const ctr = new FormControl(null, validations);
    }

    SetInputControlValue($control, $label, formControl, controlName, action, context) {
        if (!action.Property)
            throw `SetControlValue  EL VALOR DE LA PROPIEDAD A SIGNAR NO PUEDE SER NULO EN EL CONTROL :'${controlName}' '`;
        let ctr: AbstractControl;

        let _valueToSet = Array.isArray(action.PropertyValue) ? 'Array de ' + action.PropertyValue.length + ' Elementos' : action.PropertyValue;
        let valueToSet = action.PropertyValue;
        this.utility.logger.LogInfoText(`SetControlValue  asignar la propiedad '${action.Property}' el valor  '${action.PropertyValue}' del Control:'${controlName}' `);

        //
        switch (action.Property.toLowerCase()) {
            case 'visible':

                if (valueToSet == 'true') {

                    $control.show();
                    $label.show();
                }
                else {
                    $control.hide();
                    $label.hide();
                }
                break;
            case 'active':
                let isactive = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                formControl.Activo = isactive;
                break;
            case 'hidden':
                let ishidden = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                formControl.Hidden = ishidden;
                break;
            case 'enabled':
                let Isdisabled = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                $control.attr("disabled", !Isdisabled);
                $label.attr("disabled", !Isdisabled);
                //controlConfig.Disabled =!Isdisabled;
                ctr = context.form.controls[formControl.Name];
                if (Isdisabled)
                    ctr.enable();
                else
                    ctr.disable();
                break;
            case 'readonly':
                let isreadonly = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                $control.attr("readonly", isreadonly);
                break;
            case 'pattern':
                if (valueToSet) {
                    formControl.Pattern = valueToSet;
                }
                else {
                    formControl.Pattern = '';

                }
                this.SetValidations(action, context, formControl);

                break;
            case 'maxlength':

                if (valueToSet) {
                    formControl.MaxLength = parseInt(valueToSet);
                }
                else {
                    formControl.MaxLength = 0;
                }
                this.SetValidations(action, context, formControl);

                break;
            case 'minlength':

                if (valueToSet) {
                    formControl.MinLength = parseInt(valueToSet);
                }
                else {
                    formControl.MinLength = 0;
                }
                this.SetValidations(action, context, formControl);

                break;
            case 'max':
                //SetAttributo($control, controlConfig, 'Max', valueToSet, 0, true);
                if (valueToSet) {
                    formControl.Max = parseInt(valueToSet);
                }
                else {
                    formControl.Max = 0;
                }
                this.SetValidations(action, context, formControl);
                break;
            case 'min':
                //SetAttributo($control, controlConfig, 'Min', valueToSet, 0, true);
                if (valueToSet) {
                    formControl.Min = parseInt(valueToSet);
                }
                else {
                    formControl.Min = 0;
                }
                this.SetValidations(action, context, formControl);
                break;
            case 'required':

                let Isrequired = (valueToSet == 'true') ? true : false;

                //SetAttributo($control, controlConfig, 'Required', Isrequired, false, false);

                if (valueToSet == 'true') {
                    formControl.Required = Isrequired;
                }
                else {
                    formControl.Required = false;
                }
                this.SetValidations(action, context, formControl);
                break;
            case 'datasource':
                //control.val(reglaData[2]);
                if (formControl.ControlType == 'ComboBox' || formControl.ControlType == 'MultiselectBox') {
                    //throw 'Asiganar dataSource no implementado'


                    if (context.configs['Config' + formControl.Name]) {
                        setTimeout(() => {

                            let items = JSON.parse(valueToSet);
                            context.configs['Config' + formControl.Name].Context.CargarItemsComboBox(null, true, items, true);
                            //.CargarItems(null, false, valueToSet);
                        }, 100);
                    }


                }
                break;
            case 'config':
                //control.val(reglaData[2]);
                if (formControl.ControlType == 'ComboBox' || formControl.ControlType == 'MultiselectBox') {
                    //throw 'Asiganar dataSource no implementado'

                    if (context.configs['Config' + formControl.Name]) {
                        setTimeout(() => {
                            let _config = JSON.parse(valueToSet);
                            context.configs['Config' + formControl.Name].Context.SetConfig(_config);
                            context.configs['Config' + formControl.Name] = _config;
                            context.configs['Config' + formControl.Name].Context.CargarItemsComboBox(null, true, null, false);
                        }, 100);
                    }

                }
                else {
                    formControl = JSON.parse(valueToSet);
                }
                break;
            case 'configcontrol':
                formControl.ConfigControl = JSON.parse(valueToSet);
                break;
            case 'configprop':
                let dataProp = valueToSet.split('|');
                formControl[dataProp[0]] = dataProp[1];
                break;
            case 'configpropjson':
                let dataPropJson = valueToSet.split('|');
                formControl[dataPropJson[0]] = JSON.parse(dataPropJson[1]);
                break;
            //
            case 'value':
                //control.val(reglaData[2]);
                if (formControl.ControlType == 'ComboBox' || formControl.ControlType == 'ComboBox') {

                }
                if (context.form) {
                    ctr = context.form.controls[formControl.Name];
                    if (ctr) {
                        ctr.setValue(valueToSet);

                    }


                }
                else {
                    setTimeout(() => {
                        if (context.isModelContainer)
                            context.modelo[formControl.ModelContainer][formControl.Name] = valueToSet;
                        else
                            context[formControl.ModelContainer][formControl.Name] = valueToSet;
                    }, 100);
                }

                break;
            case 'text':
                //control.val(reglaData[2]);
                $label.text(valueToSet);
                break;
            case 'title':
                $control.attr("title", valueToSet);
                break;
            case 'placeholder':
                $control.attr("placeholder", valueToSet);
                break;

        }
    }

    ConvertExportItems(exportItems: Array<any>, columnsToDelete: Array<any>) {

        exportItems.forEach(item => {
            item.hasOwnProperty("PkValue") && delete item.PkValue;
            columnsToDelete && columnsToDelete.length > 0 && columnsToDelete.forEach(i => item.hasOwnProperty(i) && delete item[i])
        })
    }
}
