import * as core from '@angular/core';
import * as common from '@angular/common';
import * as forms from '@angular/forms';
import * as router from '@angular/router';
import * as rxjs from 'rxjs';
import * as tslib from 'tslib';

// import * as current from 'src/app/app.module';
import * as generalForm from '../../components/controls/general-form/general-form.component';
import * as dataTableComp from '../../components/controls/data-table/data-table.component';

export const PLUGIN_EXTERNALS_MAP = {
    'core': core,
    'common': common,
    'forms': forms,
    'router': router,
    rxjs,
    tslib,
    // currentApp: { currentModule: current, generalForm: generalForm }
};

export const PLUGIN_CURRENT_APP_MAP = {

    currentModule: null,
    generalForm: generalForm,
    //dataTableComp:dataTableComp,
   // dataTableType:dataTableComp.DataTableComponent
};
