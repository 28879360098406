import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods, TipoObjetoParaPermisos, OpcionesPermisosCrearExpediente } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes, } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { PalabrasClaveComponent } from '../../palabras-clave/palabras-clave.component';
import { FileUploaderComponent } from '../../controls/file-uploader/file-uploader.component';
import { StaticData } from '../../../helpers/static-data';
import { ConfigWindow } from '../../../models/config-window';
import { debug } from 'util';

@Component({
  selector: 'app-documento-adjuntos',
  templateUrl: './documento-adjuntos.component.html',
  styleUrls: ['./documento-adjuntos.component.css']
})
export class DocumentoAdjuntosComponent implements OnInit, AfterViewInit {
  @ViewChild('documento', { static: false }) documentoComponente: FileUploaderComponent;
  // CM
  // @ViewChild('adjuntos', { static: false }) adjuntoComponente: FileUploaderComponent;
  @Output() accionesEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() isEdit: boolean;
  @Input() tienePermisoGuardar: boolean = true;
  @Input() tienePermisoCambiarDoc: boolean = true;
  @Input() form: FormGroup;
  @Input() modelo: any;
  @Input() configDocumento: any
  @Output() limpiarFormulario: EventEmitter<any> = new EventEmitter();
  @Output() habilitaGuardado: EventEmitter<any> = new EventEmitter();
  @Output() recargarGrillaFormulario: EventEmitter<any> = new EventEmitter();
  configFileUploader: any;
  configFileUploaderAdjunto: any;
  @Output() onAfterViewInit: EventEmitter<any> = new EventEmitter();

  @Output() public onClearParentForm = new EventEmitter<any>();
  public stopClear: boolean = false;
  constructor(public baseService: BaseServiceService, public utility: Utilities) { }
  ngAfterViewInit() {
    this.onAfterViewInit.emit();
  }
  ngOnInit() {

  }


  public VerFileUpLoad(): boolean {
    return true;
    // if (this.modelo.modelo.IdDocumentoExpediente == 0)
    //   return true;
    // else {
    //   if (this.configDocumento == null) {
    //     return (StaticData.GeneralConfig.ConfigDocumento && (
    //       StaticData.GeneralConfig.ConfigDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento ||
    //       StaticData.GeneralConfig.ConfigDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento));
    //   }
    //   else {
    //     return (this.configDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento ||
    //       this.configDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento);
    //   }
    // }
  }

  public TienePermisosActualizar(): boolean {
    if (this.modelo.modelo.IdDocumentoExpediente == 0)
      return true;
    else {
      if (!this.configDocumento) {
        return (StaticData.GeneralConfig.ConfigDocumento && (StaticData.GeneralConfig.ConfigDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento));
      }
      else
        return (this.configDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento);
    }
  }


  public TienePermisosNuevaVersion(): boolean {
    if (this.modelo.modelo.IdDocumentoExpediente == 0)
      return true;
    else {
      if (!this.configDocumento) {
        return (StaticData.GeneralConfig.ConfigDocumento && (StaticData.GeneralConfig.ConfigDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento));
      }
      else
        return (this.configDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento);
    }
  }



  public RespuestaCargaDocumento(event) {

  //COMMENTb 4 5

    if (event.response) {
      let obj: any = JSON.parse(event.response);
      if (obj.Ok) {
        this.limpiarFormulario.emit();
        this.recargarGrillaFormulario.emit({ obj: obj, modelo: this.modelo.modelo });
      }
      else {
        this.utility.logger.LogError(obj, 'RespuestaCargaDocumento')
        this.documentoComponente.selectedFiles = [];
      }
    }
  }
  public EventoProgresoCargaDocumento(event) {
  }

  public EventoCargarDocumento(event) {

    this.GuardarArchivo(null);
  }
  public EventoSeleccionArchivo(event) {


    this.accionesEventEmitter.emit({ action: 'SeleccionArchivo', event: event });
  }
  public EventoRemoverArchivo(event) {


    this.accionesEventEmitter.emit({ action: 'RemoverArchivo', event: event });
  }
  public EventoLimpiar(event) {


    this.accionesEventEmitter.emit({ action: 'LimpiarTodoArchivo', event: event });
  }
  public GuardarArchivo(event) {

    const that = this;
    let token: string = StaticData.Usuario.Token;
    let pkValue: any;
    let idExpediente: any;
    if (event) {
      pkValue = event.data.data.IdDocumentoExpediente;
      idExpediente = event.data.data.IdExpediente;
    }
    else {
      pkValue = this.modelo.modelo.IdDocumentoExpediente;
      idExpediente = this.modelo.modelo.IdExpediente;
    }
    //if (pkValue) {

    let model: any = {
      IdDocumentoExpediente: pkValue,
      PkValue: pkValue,
      IdExpediente: idExpediente,
      Token: token,
      MethodNameUI: 'Guardar',
      DataAdd: { FileModelType: 'Documents' }
    }
    if (!this.documentoComponente.isSelectedFiles()) {
      this.utility.logger.LogWarningText('No Hay Archivos para cargar');
      this.stopClear = false;
      return;
    }

    //this.configFileUploade r.uploadAPI.headers.modelo = JSON.stringify(model);
    if (this.isEdit || this.modelo.modelo.IdDocumentoExpediente > 0) {

      if (this.modelo.modelo.Extension && this.documentoComponente.principalFile) {



        if (!this.configDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento &&
          !this.configDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento) {
          this.stopClear = false;
          return;
        }

        // this.utility.VerModalConfirmacionActualiazionDoc().then(modelo => {

        //   if (modelo.dialogResult == true) {
        //     model.DataAdd = { Actualizar: true }
        //     that.documentoComponente.uploadFiles(false, model);
        //   }
        //   else if (modelo.dialogResult == 'extra') {
        //     model.DataAdd = { Version: true }
        //     that.documentoComponente.uploadFiles(false, model);
        //   }

        // });
        let titleWindow: string = "Desea Actualizar o crear una nueva version del documento?";

        if (this.configDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento &&
          !this.configDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento)
          titleWindow = "Desea Actualizar el documento?";
        else if (!this.configDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento &&
          this.configDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento)
          titleWindow = "Desea crear una nueva version el documento?";

        this.stopClear = true;
        let modeloInterno: any = { VersionModelData: { ObservacionesModificacion: '' } };
        let config: ConfigWindow = new ConfigWindow();
        config.classWindow = 'warning';
        config.titleWindow = titleWindow;
        config.returnInstance = false;
        config.modal = true;
        config.width = 750;
        config.height = 400;
        config.viewExtra = this.configDocumento.ConfigValidacionDocumento.PermitirGenerarNuevaVersionDocumento;
        config.extra = 'Crear Nueva Version';
        config.ok = 'Actualizar Archivo';        
        config.viewOk = this.configDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento;
        config.Okextra = 'Cargar Documentos como adjuntos';
        config.viewOkExtra = this.configDocumento.ConfigValidacionDocumento.PermitirNuevosAdjuntos;
        // config.viewOkExtra = false;
        config.OkextraValue = "okExtra";
        config.extraValue = 'extra';
        let windowModel: any = {
          modelo: modeloInterno.VersionModelData,
          config: this.utility.GetConfigObservacionesVersion()
        };

        this.utility.OpenWindow('ObservacionesModificacionVersionComponent',
          config, windowModel).then(dataResult => {


            dataResult.onClosePromise.then(dataResultOnClose => {


              let limpiar = true;
              if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {
                if (dataResultOnClose.modelo.ObservacionesModificacion) {
                  model.DataAdd = { FileModelType: 'Documents', Actualizar: true }
                  model.VersionModelData = dataResultOnClose.modelo;
                  that.documentoComponente.uploadFiles(false, model);
                }
                else
                  that.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { that.utility.logger.LogError(err, {}) });
              }
              if (!dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == false) {
                this.habilitaGuardado.emit();
              }
              else if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == 'extra') {
                if (dataResultOnClose.modelo.ObservacionesModificacion) {
                  model.DataAdd = { FileModelType: 'Documents', Version: true }
                  model.VersionModelData = dataResultOnClose.modelo;
                  that.documentoComponente.uploadFiles(false, model);
                }
                else
                  that.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { that.utility.logger.LogError(err, {}) });

              }
              else if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == 'okExtra') {                
                if (dataResultOnClose.modelo.ObservacionesModificacion) {
                  model.DataAdd = { FileModelType: 'Documents', Actualizar: true, Adjuntos:true }
                  model.VersionModelData = dataResultOnClose.modelo;
                  that.documentoComponente.uploadFiles(false, model);
                }
                else
                  that.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { that.utility.logger.LogError(err, {}) });

              }
              else {

              }
              this.stopClear = false;
            });

          })

      }
      else {

        this.documentoComponente.uploadFiles(false, model);
        this.stopClear = false;
      }

    }
    else {
      this.documentoComponente.uploadFiles(false, model);
      this.stopClear = false;
    }
    //}
  }

  public RespuestaCargaAdjunto(event) {



    if (event.response) {
      let obj: any = JSON.parse(event.response);
      if (obj.Ok) {
        this.limpiarFormulario.emit();

        this.recargarGrillaFormulario.emit();
      }
      // CM
      /*else {
        this.adjuntoComponente.selectedFiles = [];
      }*/
    }
  }
  public EventoProgresoCargaAdjunto(event) {


  }

  // CM
  /*public EventoCargarAdjunto(event) {
    
    this.GuardarArchivoAdjunto(null);
  }*/

  public EventoSeleccionAdjunto(event) {


  }

  // CM
  /*public GuardarArchivoAdjunto(event) {

    const that = this;
    let token: string = StaticData.Usuario.Token;
    let pkValue: any;
    let idExpediente: any;
    if (event) {
      pkValue = event.data.data.IdDocumentoExpediente;
      idExpediente = event.data.data.IdExpediente;
    }
    else {
      pkValue = this.modelo.modelo.IdDocumentoExpediente;
      idExpediente = this.modelo.modelo.IdExpediente;
    }
    //if (pkValue) {

    let model: any = {
      IdDocumentoExpediente: pkValue,
      PkValue: pkValue,
      IdExpediente: idExpediente,
      Token: token,
      MethodNameUI: 'Guardar',
      DataAdd: { FileModelType: 'Attachments' }
    }
    if (!this.adjuntoComponente.isSelectedFiles()) {
      this.utility.logger.LogWarningText('No Hay Archivos para cargar');
      return;
    }

    this.adjuntoComponente.uploadFiles(false, model);

    //}
  }*/

  public LimpiarDocumentos() {
    if (this.documentoComponente)
      this.documentoComponente.clear();
    //CM
    /*if (this.adjuntoComponente)
      this.adjuntoComponente.clear();*/
  }
  public SetConfigDocumento(configDocumento: any) {

    let config = this.GetConfigFileUpload((configDocumento) ? configDocumento : StaticData.GeneralConfig.ConfigDocumento);
    config["IsPrincipal"] = true;
    this.configFileUploader = config;
    this.configFileUploaderAdjunto = this.GetConfigFileUploadAdjuntos((configDocumento) ? configDocumento : StaticData.GeneralConfig.ConfigDocumento);

  }
  public GetConfigFileUpload(configDocumento) {

    // configDocumento.ValidatePrincipal  = true;
    let principal
    let validatePrincipal
    if (this.isEdit && !StaticData.GeneralConfig.ConfigDocumento.ConfigValidacionDocumento.PermitirActualizarDocumento)
      principal = true
    else
      principal = !this.tienePermisoCambiarDoc

    if (!principal && StaticData.GeneralConfig.ConfigDocumento.ValidatePrincipal)
      validatePrincipal = true;
    else
      validatePrincipal = false;
    let configFileUploader = {
      NotManageResponseInternal: true,
      multiple: StaticData.GeneralConfig.ConfigDocumento.NotMultiple ? !StaticData.GeneralConfig.ConfigDocumento.NotMultiple : true,
      formatsAllowed: configDocumento.ExtensionesValidas || StaticData.GeneralConfig.ExtensionesValidas,
      //formatsAllowed: ".txt,.csv,.docx,.xlsx,.pptx,.odt,.ods,.odp,.pdf,.xml,.html,.json,.xpdl,.jpeg,.jpg,.jpe,.jfif,.jfi,.jif,.jpg2,.jp2,.mng,.odg,.png,.tiff,.xpm,.cgm,.svg,.svgz,.mp3,.bwf,.wav,.mp4,.mpeg,.m4v,.mkv,.mka,.mks,.mk3d,.mj2.mjp2,.mpg,.mpeg,.mp1,.mp2,.mp3,.m1v,.m1a,.m2a,.mpa,.mpv,.mp2,.mxf,.webm,.ogg,.ogv,.oga,.ogx,.ogm,.spx,.opus,.gml,.shp,.shx,.sbn,.shp,.xml,.gz,.zip,.dbf,.mdbx,.siard,.warc,.eml,.mbox,.mbx,.xls,.doc",
      maxSize: configDocumento.TamanoMaximo,
      AllowsConvertDocument: configDocumento.AllowsConvertDocument,
      AllowsConvertDocumentText: configDocumento.AllowsConvertDocumentText,
      CamposConfigConvertirCorreo: (configDocumento && configDocumento.ConfigConvertirCorreo
        && configDocumento.ConfigConvertirCorreo.Campos.length > 0) ? configDocumento.ConfigConvertirCorreo.Campos : null,

      VerInfoConfigConvertirCorreo: (configDocumento && configDocumento.ConfigConvertirCorreo
        && configDocumento.ConfigConvertirCorreo.VerInfoConfigConvertirCorreo == true) ? true : false,

      TituloInfoConfigConvertirCorreo: (configDocumento && configDocumento.ConfigConvertirCorreo
        && configDocumento.ConfigConvertirCorreo.TituloInfoConfigConvertirCorreo) ? configDocumento.ConfigConvertirCorreo.TituloInfoConfigConvertirCorreo : 'Mapeo de campos desde archivo de correo',
      Required: true,
      Label: "Documento",
      ControlType: "FileBox",
      Name: 'FileBoxDocumentoExpediente',
      // NotPrincipal: !this.tienePermisoCambiarDoc,
      NotPrincipal: principal,
      uploadAPI: {
        url: StaticData.UrlServer + "/api/TDocumentoExpediente/UploadFile",
        headers: {
          modelo: ""
        }
      },
      WithScanner: true,
      ViewStatusScannerNotify: true,
      ViewStatusScanner: true,
      theme: "dragNDrop",
      hideProgressBar: false,
      hideResetBtn: false,
      //hideSelectBtn: false,
      replaceTexts: {
        selectFileBtn: 'Seleccione el documento',
        resetBtn: 'Limpiar',
        uploadBtn: 'Cargar Documento',
        dragNDropBox: 'Drag & Drop',
        attachPinBtn: 'Adjunte Documento...',
        afterUploadMsg_success: ' ',
        afterUploadMsg_error: 'Error al cargar !'
      },
      invalidPrincipalMessage: StaticData.GeneralConfig.ConfigDocumento.invalidPrincipalMessage ? StaticData.GeneralConfig.ConfigDocumento.invalidPrincipalMessage : "Archivo Principal Requerido",
      ValidatePrincipal: validatePrincipal,
      DefaultPrincipal: StaticData.GeneralConfig.ConfigDocumento.DefaultPrincipal ? StaticData.GeneralConfig.ConfigDocumento.DefaultPrincipal : false,
      CambiarNotificacionCarga: configDocumento.CambiarNotificacionCarga

    };

    return configFileUploader;
  }

  public GetConfigFileUploadAdjuntos(configDocumento) {

    let configFileUploaderAdjunto = {
      NotManageResponseInternal: true,
      multiple: true,
      Required: true,
      ControlType: "FileBox",
      formatsAllowed: configDocumento.ExtensionesValidas,
      maxSize: configDocumento.TamanoMaximo,
      uploadAPI: {
        url: StaticData.UrlServer + "/api/TDocumentoExpediente/UploadFile",
        headers: {
          modelo: ""
        }
      },
      theme: "dragNDrop",
      hideProgressBar: false,
      hideResetBtn: false,

      //hideSelectBtn: false,
      replaceTexts: {
        selectFileBtn: 'Seleccione el documento',
        resetBtn: 'Limpiar',
        uploadBtn: 'Cargar Documento',
        dragNDropBox: 'Drag & Drop',
        attachPinBtn: 'Adjunte Documento...',
        afterUploadMsg_success: 'Carga Correcta !',
        afterUploadMsg_error: 'Error al cargar !'
      }
    };

    return configFileUploaderAdjunto;
  }

  public RaiseForceValidateSaveEvent(isValid: boolean = true, required: boolean = true) {

    if (this.documentoComponente) {
      this.documentoComponente.RaiseForceValidateSaveEvent(isValid, required);
    }

  }
}
