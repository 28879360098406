import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-details-table',
  templateUrl: './form-details-table.component.html',
  styleUrls: ['./form-details-table.component.css']
})
export class FormDetailsTableComponent implements OnInit {

  @Input() Pagina: any;
  @Input() modelo: any = {};
  @Input() config: any;
  @Input() name: string = "";

  constructor() { }

  ngOnInit() {
  }

}
