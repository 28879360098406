import {
    Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
    QueryList, forwardRef, Inject
} from '@angular/core';
import { TipoObjetoParaPermisos, TipoParaPermisos
} from '../models/general.enum';
import { StaticData } from '../helpers/static-data';
import { Utilities } from '../helpers/utilities';
import { List } from '../../assets/linqts/compilado/index';
import { ConfigWindow } from '../models/config-window';
import { ConfigBase } from './config-base';

export class ConfigExpedienteDocumento extends ConfigBase implements OnInit {

    @Input() setOpcionesPermisos: boolean = true;
    @Input() setTiposIdExpediente: boolean = true;
    @Input() extraName: string = '';
    @Input() verGenerarNuevoCodigo: boolean = false;
    TiposIdExpediente: Array<any> = [];
    OpcionesPermisosCrearExpediente: Array<any> = [];

    Pagina: any = {
        ConfigPage: {
            Controls: [
                { ColSpan: 12, Label: 'Tipo IdExpediente', Activo: true, Name: 'TipoIdExpediente' },
                { ColSpan: 12, Label: 'Id', Activo: true, Name: 'Id' },
                { ColSpan: 12, Label: 'Opciones Permisos', Activo: true, Name: 'OpcionesPermisos' },
                { ColSpan: 12, Label: 'Activo', Activo: true, Name: 'Activo' }


            ]
        }
    };

    nombreHeaderTabs: string = 'tabConfigHeader';
    nombreContainerTabs: string = 'tabConfigContent';

    nombreHeaderUno: string = 'tabUnoHeader';
    nombreContainerUno: string = 'tabUnoContent';
    nombreHrefContainerUno: string = '#tabUnoContent';

    nombreHeaderDos: string = 'tabDosHeader';
    nombreContainerDos: string = 'tabDosContent';
    nombreHrefContainerDos: string = '#tabDosContent';

    nombreHeaderTres: string = 'tabTresHeader';
    nombreContainerTres: string = 'tabTresContent';
    nombreHrefContainerTres: string = '#tabTresContent';


    nombreHeaderCuatro: string = 'tabCuatroHeader';
    nombreContainerCuatro: string = 'tabCuatroContent';
    nombreHrefContainerCuatro: string = '#tabCuatroContent';


    nombreListaCamposNombreDocumentos: string = 'listaCamposNombreDocumentos';
    nombreListaCamposNombreFolder: string = 'listaCamposNombreFolder';
    ngOnInit() {
        this.OnInitStart();

        // if (this.index === -1)
        //     this.extraName = (this.extraName) ? this.extraName + '_uno' : '_uno';
        // else
        //     this.extraName = (this.extraName) ? this.extraName + '_' + this.index.toString() : '_' + this.index.toString();

        // if (this.extraName) {
        //     for (let i: number = 0; i < this.Pagina.ConfigPage.Controls.length; i++)
        //         this.Pagina.ConfigPage.Controls[i].Name = this.Pagina.ConfigPage.Controls[i].Name + this.extraName;
        // }

        if (this.setTiposIdExpediente) {
            this.TiposIdExpediente = this.utility.GetTiposIdExpedienteText();
            this.utility.SetTextTipoIdExpediente(this.modelo);
        }
        if (this.setOpcionesPermisos) {
            this.utility.SetTextOpcionesPermisos(this.modelo);
            this.OpcionesPermisosCrearExpediente = this.utility.GetOpcionesPermisosCrearExpedienteText();
        }
        if (this.index > -1) {
            this.extraName = '_' + this.index.toString() + this.extraName;
        }


        this.nombreHeaderTabs = this.nombreHeaderTabs + this.extraName;
        this.nombreContainerTabs = this.nombreContainerTabs + this.extraName;

        this.nombreHeaderUno = this.nombreHeaderUno + this.extraName;
        this.nombreContainerUno = this.nombreContainerUno + this.extraName;
        this.nombreHrefContainerUno = this.nombreHrefContainerUno + this.extraName;

        this.nombreHeaderDos = this.nombreHeaderDos + this.extraName;
        this.nombreContainerDos = this.nombreContainerDos + this.extraName;
        this.nombreHrefContainerDos = this.nombreHrefContainerDos + this.extraName;

        this.nombreHeaderTres = this.nombreHeaderTres + this.extraName;
        this.nombreContainerTres = this.nombreContainerTres + this.extraName;
        this.nombreHrefContainerTres = this.nombreHrefContainerTres + this.extraName;

        this.nombreHeaderCuatro = this.nombreHeaderCuatro + this.extraName;
        this.nombreContainerCuatro = this.nombreContainerCuatro + this.extraName;
        this.nombreHrefContainerCuatro = this.nombreHrefContainerCuatro + this.extraName;

        this.nombreListaCamposNombreDocumentos = this.nombreListaCamposNombreDocumentos + this.extraName;
        this.nombreListaCamposNombreFolder = this.nombreListaCamposNombreFolder + this.extraName;
        this.OnInitEnd();
    }

    public OnInitStart() {

    }
    public OnInitEnd() {

    }

    public ConfigDocumentoEventHandler(event) {
        
        this.modelo.ConfigDocumento = event.modelo;
    }
}  
