import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';

import { StaticData } from '../../../helpers/static-data';
import { Utilities } from '../../../helpers/utilities';
import { List } from '../../../../assets/linqts/compilado/index';
import { HelperImages } from 'src/app/helpers/helper-images';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';

declare var fabric: any;

@Component({
  selector: 'app-console-log-table',
  templateUrl: './console-log-table.component.html',
  styleUrls: ['./console-log-table.component.css']
})
export class ConsoleLogTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  @ViewChild("editorModeloPermisos", { static: false })
  editorModeloPermisos: EditorAceComponent;
  @ViewChild("editorConfigPermisos", { static: false })
  editorConfigPermisos: EditorAceComponent;
  @ViewChild("editorItemPermisos", { static: false })
  editorItemPermisos: EditorAceComponent;

  @Input() name: string = 'datatable-texto-split'
  @Input() searcName: string = 'datatable-texto-split'
  @Input() conTipoObjeto: boolean = false;
  @Input() conRegla: boolean = false;
  dtOptions: any;
  // dtOptions: DataTables.Settings = {};
  @Input() property: string = '';

  dtTrigger = new Subject<any>();
  modeloLog: string = "";
  items: Array<any> = [];
  searchText: string = "";
  constructor(public utility: Utilities, public helperImages: HelperImages,
    public generalComunicationService: GeneralComunicationService) {
    this.dtOptions = this.utility.GetDtOptionsHtmlTable();
  }

  ActivarLog() {

    StaticData.GeneralConfig.ConfigUILog.ConsoleLogPermisos = !StaticData.GeneralConfig.ConfigUILog.ConsoleLogPermisos;
  }
  ngOnInit() {
    this.searcName = "SearchBox_" + this.name;

    this.utility.logger.ReceivedConsoleLog.subscribe(event => {

      if (event.Type == this.property) {
        // this.Rerender();
        // this.items =this.GetItems();
        // this.Rerender();
      }
    })

    this.items = this.GetItems();
    this.generalComunicationService.receivedClearConsoleLog.subscribe(item => {
      this.Limpiar();
    })
  }
  Limpiar() {
    StaticData[this.property] = [];
    this.items = this.GetItems();
    this.LimpiarSeleccion();
    //this.Rerender();

  }
  LimpiarSeleccion() {
    this.modeloLog = "";
    this.SetEditor(null, null, null);
  }
  GetItems() {
    return StaticData[this.property];
  }

  SetEditor(modelo, modelo2, modelo3) {

    if (this.editorModeloPermisos) {
      this.editorModeloPermisos.Update(modelo);
    }
    if (this.editorConfigPermisos) {
      this.editorConfigPermisos.Update(modelo2);
    }
    if (this.editorItemPermisos)
      this.editorItemPermisos.Update(modelo3);
  }
  currentOrder = null;
  Order(campo: string) {
    
    if (!this.currentOrder) {
      this.currentOrder = { Field: campo, Order: "Asc" };
    }
    else {
      if (this.currentOrder.Field == campo) {
        this.currentOrder.Order = (this.currentOrder.Order == "Asc") ? "Desc" : "Asc";
      }
      else
        this.currentOrder = { Field: campo, Order: "Asc" };
    }
    if (this.currentOrder.Order == "Asc") {
      this.items = this.items.sort((a, b) => {
        return a[campo] - b[campo];
      });
    }
    else {
      this.items = this.items.sort((a, b) => {
        return b[campo] - a[campo];
      });
    }

  }
  GetFilteredItems() {
    if (this.searchText) {

      if (this.conTipoObjeto) {

        return this.items.filter(item => {
          return (item.Tipo && item.Tipo.toLowerCase().includes(this.searchText)) ||
            (item.Id && item.Id.toString().includes(this.searchText)) ||
            (item.Log && item.Log.toLowerCase().includes(this.searchText)) ||
            (item.TipoObjeto && item.TipoObjeto.toLowerCase().includes(this.searchText)) ||
            (item.FiltroPermiso && item.FiltroPermiso.toLowerCase().includes(this.searchText)) ||
            (item.NombreFiltro && item.NombreFiltro.toLowerCase().includes(this.searchText));


        });
      }
      else if (this.conRegla) {
        return this.items.filter(item => {
          return (item.Tipo && item.Tipo.toLowerCase().includes(this.searchText)) ||
            (item.Id && item.Id.toString().includes(this.searchText)) ||
            (item.Regla && item.Regla.toString().includes(this.searchText)) ||
            (item.Log && item.Log.toLowerCase().includes(this.searchText));
        });
      }
      else {
        return this.items.filter(item => {
          return (item.Tipo && item.Tipo.toLowerCase().includes(this.searchText)) ||
            (item.Id && item.Id.toString().includes(this.searchText)) ||
            (item.Log && item.Log.toLowerCase().includes(this.searchText));
        });
      }
    }
    else
      return this.items;
  }
  ngAfterViewInit() {


    //this.dtTrigger.next();
    // this.SetEditor({}, {});

    let SearchBoxElement = document.querySelector("#" + this.searcName);
    new fabric['SearchBox'](SearchBoxElement);

  }

  Rerender(): void {
    
    this.items = this.GetItems();

    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   // Destroy the table first

    //   dtInstance.draw();
    //  //this.dtTrigger.next();

    // });
    // setTimeout(() => {
    //   

    //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     // Destroy the table first

    //     dtInstance.destroy();
    //    this.dtTrigger.next();

    //   });
    // }, 500);
    //this.dtTrigger.next();

  }

  ngOnDestroy(): void {

    this.utility.Unsubscribe(this.dtTrigger);
  }

  VerLog(item) {
    this.modeloLog = item.Log;
    this.SetEditor(item.Modelo, item.ConfigPermisos, item.Item);

  }
}
