import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import { TipoObjetoParaPermisos, TipoParaPermisos
} from '../models/general.enum';
import { StaticData } from '../helpers/static-data';
import { Utilities } from '../helpers/utilities';
import { List } from '../../assets/linqts/compilado/index';
import { ConfigWindow } from '../models/config-window';
import { HelperImages } from 'src/app/helpers/helper-images';
// import { Project } from 'src/app/models/rule-engine/rules-models';

export class ConfigBase implements OnInit {

  @Input() tiposSolicitud: Array<any> = [];
  @Input() project:any;
  @Input() verActivo:boolean;
  @Input() index: number = -1;
  @Input() modelo: any;
  @Input() modeloJson: string;
  @Input() comandos: Array<any> = [];
  @Input() estilos: any;
  public direction: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'horizontal';
  EstiloBuscarContenedor: any = { height: "600px" };

  comandosInterno: Array<any> = [];
  @Output() modeloEventEmitter = new EventEmitter();

  nombreHeaderTabs: string = 'tabConfigHeaderGeneralConfig';
  nombreContainerTabs: string = 'tabConfigContentGeneralConfig';

  nombreHeaderUno: string = 'tabUnoHeaderGeneralConfig';
  nombreContainerUno: string = 'tabUnoContentGeneralConfig';
  nombreHrefContainerUno: string = '#tabUnoContentGeneralConfig';

  nombreHeaderDos: string = 'tabDosHeaderGeneralConfig';
  nombreContainerDos: string = 'tabDosContentGeneralConfig';
  nombreHrefContainerDos: string = '#tabDosContentGeneralConfig';

  
  nombreHeaderTres: string = 'tabTresHeaderGeneralConfig';
  nombreContainerTres: string = 'tabTresContentGeneralConfig';
  nombreHrefContainerTres: string = '#tabTresContentGeneralConfig';


  nombreHeaderCuatro: string = 'tabCuatroHeaderGeneralConfig';
  nombreContainerCuatro: string = 'tabCuatroContentGeneralConfig';
  nombreHrefContainerCuatro: string = '#tabCuatroContentGeneralConfig';


  nombreHeaderCinco: string = 'tabCincoHeaderGeneralConfig';
  nombreContainerCinco: string = 'tabCincoContentGeneralConfig';
  nombreHrefContainerCinco: string = '#tabCincoContentGeneralConfig';


  nombreHeaderSeis: string = 'tabSeisHeaderGeneralConfig';
  nombreContainerSeis: string = 'tabSeisContentGeneralConfig';
  nombreHrefContainerSeis: string = '#tabSeisContentGeneralConfig';

  nombreHeaderSiete: string = 'tabSieteHeaderGeneralConfig';
  nombreContainerSiete: string = 'tabSieteContentGeneralConfig';
  nombreHrefContainerSiete: string = '#tabSieteContentGeneralConfig';

  /*   nombreHeaderTres: string = 'tabTresHeader';
    nombreContainerTres: string = 'tabTresContent';
    nombreHrefContainerTres: string = '#tabTresContent'; */

  constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities,
  @Inject(forwardRef(() => HelperImages)) public helperImages: HelperImages) {

 
  }

  ngOnInit() {

    if (!this.estilos)
      this.estilos = StaticData.Estilos;
    this.OnInit();

  }
  public OnInit() {

  }
  public ConfigTipoUbicacionArchivoEventHandler(event) {
    this.modelo.ConfigTipoUbicacionArchivo = event.modelo;
  }

  public Clonar() {

  
     let modeloClone = this.utility.Clone(this.modelo) ;
    this.modeloEventEmitter.emit({ modelo: modeloClone, nuevo: true, index: -1, esClone:true });
  }
  public CrudModelo(nuevo: boolean, tipo?: string, subTipo?: string, _index?: number) {

    if (nuevo) {
      this.modelo = this.utility.GetModeloConfig(tipo, subTipo);
    }
    else {
      this.modelo = null;
    }
    _index = (_index !== null && _index !== undefined) ? _index : this.index;
    this.modeloEventEmitter.emit({ modelo: this.modelo, nuevo: nuevo, index: _index });
  }


  public VerEditor(tipo: any) {
    let editorTipo = 'EditorJsonComponent';
    if (tipo == 1) {
      editorTipo = 'EditorJsonComponent';
    }
    else
      editorTipo = 'EditorAceComponent';
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Editor";
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 500;
    let windowModel: any = {
      modelo: this.modelo
    };

    this.utility.OpenWindow(editorTipo,
      config, windowModel).then(dataResult => {


        dataResult.onClosePromise.then(dataResultOnClose => {

          if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
            dataResultOnClose.modelo) {
            // this.modelo = dataResultOnClose.modelo;
          }
          else {


          }
        });

      })

  }


}