import { Component, OnInit } from '@angular/core';
import { ControlActions, ControllerMethods, EstadosSolicitud, FormActionsThroughEmitters, TipoObjetoParaPermisos } from "src/app/models/general.enum";

import { AnimationMetadataType } from "@angular/animations";
import { BaseServiceService } from "src/app/services/base-service.service";
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from 'src/app/components/controls/file-uploader/file-uploader.component';
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { HelperActions } from "src/app/helpers/helper-actions";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { SolicitudCommonComponent } from "../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { Utilities } from "src/app/helpers/utilities";
import { validateContact } from "../../../contacto/contacto.component";

@Component({
  selector: 'app-anulaciones-aprobadas',
  templateUrl: './anulaciones-aprobadas.component.html',
  styleUrls: ['./anulaciones-aprobadas.component.css']
})
export class AnulacionesAprobadasComponent extends GeneralFormComponent implements OnInit {
  modeloDetalle: any;
  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);
  }

  public OnDataTableEditHandler(event) { //

    //debugger;    
    this.modelo.modeloVirtual.GridRadicadosAsociados = null;
    if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
      this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
      /*this.modelo.modeloVirtual.GridRadicadosAsociados.forEach((element) => {
        element.Disabled = true;
        element.DisabledDelete = true;
      });*/
    }
    
    super.OnDataTableEditHandler(event);
  }

  public OnActionEventHandler(event) {
    //debugger;
    if(event.actionType == "OnViewItemExtendableTable")
    {
      //debugger;
      var consulta = null;
      var parametro = null;
      let obj = this.utility.GetApiModel("GetDetalleSolicitud", null);
      if(this.name == "AnulacionesAprobadasPQRS"){
        consulta = "ConsultaDetallePQRSAsociada";
        //parametro = "IdSolicitudPqrdAsociada";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudPqrdAsociada: event.data.Id,
          },
        };
      }
      if(this.name == "AnulacionesAprobadasEntrada"){
        consulta = "ConsultaDetalleEntradaAsociada";
        //parametro = "IdSolicitudRadicadoEntradaAsociado";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudRadicadoEntradaAsociado: event.data.Id,
          },
        };
      }
      if(this.name == "AnulacionesAprobadasFactura"){
        consulta = "ConsultaDetalleFacturaAsociada";
        //parametro = "IdSolicitudRadicadoFacturadAsociado";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudRadicadoFacturaAsociado: event.data.Id,
          },
        };
      }
      //debugger;
      
            

            let info = {
              componentName: this.componentName,
              controlName: "GetDetalleSolicitud",
              processMessage: "Cargando Detalle de la Solicitud......",
            };

            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {
                
                this.modeloDetalle = data.Data[0];
                if (
                  this.modeloDetalle.Anexos &&
                  this.modeloDetalle.Anexos != ""
                ) {
                  const Anexos = JSON.parse(this.modeloDetalle.Anexos);
                  let valorAnexos = "";
                  Anexos.forEach((anexo) => {
                    valorAnexos += anexo.Nombre + ": " + anexo.Cantidad + "\n";
                  });
                  this.modeloDetalle.Anexos = valorAnexos;
                }
                //debugger;
                if(this.name == "AnulacionesAprobadasPQRS"){
                if (this.componentsContainer.FormsDetails.length > 0) {
                  
                  let FormDetalleSolicitud = this.componentsContainer.Find(
                    "FormDetalleSolicitud"
                  );
                  FormDetalleSolicitud.modelo = this.modeloDetalle;
                }

                let sidePanelEx = this.componentsContainer.Find("DetallePQRSAsociada");
                sidePanelEx.OpenSidePanel();
              }

              if(this.name == "AnulacionesAprobadasEntrada"){
                if (this.componentsContainer.FormsDetails.length > 0) {
                  
                  let FormDetalleSolicitud = this.componentsContainer.Find(
                    "FormDetalleSolicitudEntrada"
                  );
                  FormDetalleSolicitud.modelo = this.modeloDetalle;
                }

                let sidePanelEx = this.componentsContainer.Find("DetalleEntradaAsociada");
                sidePanelEx.OpenSidePanel();
              }

              if(this.name == "AnulacionesAprobadasFactura"){
                if (this.componentsContainer.FormsDetails.length > 0) {
                  
                  let FormDetalleSolicitud = this.componentsContainer.Find(
                    "FormDetalleSolicitudFactura"
                  );
                  FormDetalleSolicitud.modelo = this.modeloDetalle;
                }

                let sidePanelEx = this.componentsContainer.Find("DetalleFacturaAsociada");
                sidePanelEx.OpenSidePanel();
              }

              })
              .catch((err) => {});
    
    }
    switch (event.ControlType) {
      case "CommandButton": {
        if (event.Option.Name == "Acta") {

          if(this.name == "AnulacionesAprobadasPQRS")
          {
            if (this.modelo.modelo.DataAdd) {
              this.modelo.modelo.DataAdd[
                "IdSolicitudRadicado"
              ] = this.modelo.modelo.IdSolicitudPqrd;
              this.modelo.modelo.DataAdd[
                "TipoRadicado"
              ] = "PQRS";
            } else {
              this.modelo.modelo.DataAdd = {
                IdSolicitudRadicado: this.modelo.modelo.IdSolicitudPqrd,
                TipoRadicado: "PQRS"
              };
            } 
          }
          if(this.name == "AnulacionesAprobadasEntrada")
          {
            if (this.modelo.modelo.DataAdd) {
              this.modelo.modelo.DataAdd[
                "IdSolicitudRadicado"
              ] = this.modelo.modelo.IdSolicitudRadicadoEntrada;
              this.modelo.modelo.DataAdd[
                "TipoRadicado"
              ] = "Entrada";
            } else {
              this.modelo.modelo.DataAdd = {
                IdSolicitudRadicado: this.modelo.modelo.IdSolicitudRadicadoEntrada,
                TipoRadicado: "Entrada"
              };
            } 
          }
          if(this.name == "AnulacionesAprobadasFactura")
          {
            if (this.modelo.modelo.DataAdd) {
              this.modelo.modelo.DataAdd[
                "IdSolicitudRadicado"
              ] = this.modelo.modelo.IdSolicitudRadicadoFactura;
              this.modelo.modelo.DataAdd[
                "TipoRadicado"
              ] = "Factura";
            } else {
              this.modelo.modelo.DataAdd = {
                IdSolicitudRadicado: this.modelo.modelo.IdSolicitudRadicadoFactura,
                TipoRadicado: "Factura"
              };
            } 
          }
          
          this.SetModelToControls();
        }
        if(this.name == "AnulacionesAprobadasFactura"){
          this.SetControlsValueDocument(["Asunto"],"enabled",  event.Option.Label === "Ver Detalle"
              ? "true"
              : "false");
        }
        this.SetModelToControls();
        break;
      }
    }

    super.OnActionEventHandler(event);

  }
}
