import {
    ComparisonOperators, LogicalOperators, DataTypes, CrudActions,
    ReturnQueryTypes, MailSendingForms, TiposUbicacionArchivo
} from '../general.enum';
import { Guid } from 'guid-typescript';
import { List, Enumerable } from '../../../assets/linqts/compilado/index';
import { StaticData } from 'src/app/helpers/static-data';


// export enum TemplateSourceTypes {
//     Template = 1,
//     DocumentaryType,
//     Document,
//     DocumentContainer,
//     Custom,
// }

// export enum ExportImportFormatTypes {
//     None = 0,
//     Original = 1,
//     Url,
//     Pdf,
//     Base64,
//     Content,
//     ByteArray,
//     Stream,
//     Xml,
//     Json
// }
// export enum FilterTypesLocationFile {
//     Ninguno = 0,
//     IdSedeEmpresa,
//     IdAreaEmpresaUsuarioActual,
//     IdRoleUsuarioActual,
//     IdGrupoUsuarioActual,
//     IdUsuarioActual
// }

// export class SimpleField {
//     public Name: string = null;
//     public Label: string = null;
//     public DataType: string = "String";
// }
export class ObjectSystem {
    public Label?: string = null;
    public Name?: string = '';
    public GUID: string = Guid.create().toString();
    public ObjectType: string = 'None';
    public Value?: any;
    public FilterMetadata?: string;
    public Active: Boolean = true;

    Get() {

        let obj: ObjectSystem = new ObjectSystem();

        obj.Label = this.Label;
        obj.Name = this.Name,
            obj.ObjectType = this.ObjectType;
        obj.Value = this.Value;
        obj.GUID = this.GUID
        return obj;
    };
  
    SetName(obj: ObjectSystem) {
        if (obj != null) {
            this.Name = obj.Name;
            this.GUID = obj.GUID;
        }
    }

}



export class ActionObject extends ObjectSystem {
    public Object: ObjectSystem = new ObjectSystem();   
    public Actions: Array<any> = [];
    public Permissions: Array<any> = [];
}
export class BaseTemplate extends ActionObject {
    public Content: string = null;
    public ObjectType: string = 'Template';
}
export class ActionTemplate extends BaseTemplate {
    //public Template: ObjectSystem = new ObjectSystem();
    public ObjectType: string = 'ActionTemplate';
    // public ActionTemplates: Array<ActionTemplate> = [];

}
export class ExternalMethod extends ObjectSystem {
    public ObjectType: string = 'ExternalMethod';
    public Method: string;
}
export class ExternalMethodConfig {
    public ExternalMethods: Array<ExternalMethod> = [];
    public JsonConfig: string;
}
// export class GroupedObjectSystem extends ObjectSystem {

//     public Permissions: Array<any> = [];
// }
// export class ObjectSystemMetadata extends ObjectSystem {
//     public ObjectType: string = 'ObjectSystemMetadata';
// }

// export class ObjectSystemToRun extends ObjectSystem {

//     public ResultManagementExecution: any;
// }
// export class ADProperty extends ObjectSystem{
//     public ObjectType: string = 'ADProperty';
// }

// export class ObjectValue {
//     public ObjectType: string = 'None';
//     public GUID: string = null;
//     public Object: string = null;
//     public Property: string = null;
//     public DataType: string = "String";
//     public DefaultValue: any;
//     public ParentObject: any;
//     public ObjectValues: Array<ObjectValue> = [];
//     public AutoGenerateFieldsConfig?: any;
// }

// export class AutoGenerateField extends ObjectSystem {
//     public ObjectType: string = 'AutoGenerateField';
//     public Fields: Array<AutoGenerateField> = [];
//     public CharacterSeparation?: string;
//     public ReplacementValues: any = {};
//     public TextFormat: string = "Normal";
//     public MaxLength: number = 0;
// }

// // export class AutoGenerateFieldsConfig extends ObjectSystem {
// //     public ObjectType: string = 'AutoGenerateFieldsConfig';
// //     public Fields: Array<AutoGenerateField> = [];
// //     public CharacterSeparation?: string;
// //     public ReplacementValues: any={};
// //     public TextFormat: string = "Normal";
// //     public MaxLength: number = 0;
// // }
// export class BasicValue extends ObjectSystem {
//     public LeftObject: ObjectValue = new ObjectValue();
//     public RightObject: ObjectValue = new ObjectValue();
//     // public LeftObject: string = '';
//     // public RightObject: string = '';
//     // public LeftType: string = 'None';
//     // public RightType: string = 'None';
//     // public LeftProperty: string = '';
//     // public RightProperty: string = '';
// }

// export class ValueToSet extends BasicValue {
//     public Character: string = '';
//     public ValuesOrFields: Array<any> = [];
//     public DataType: DataTypes = DataTypes.String;
// }

// export class ValueToReplace extends BasicValue {
//     public IsPK: Boolean = false;
// }
// export class ValueToCompare extends BasicValue {
//     public Op: ComparisonOperators = ComparisonOperators.None;
//     public LogicalOp: LogicalOperators = LogicalOperators.And;
//     public DataType: DataTypes = DataTypes.String;

// }

// export class KeyMessage extends ObjectSystem {
//     public Key: string = '';
//     public Message: string = '';
//     public Type: string = '';
//     public ObjectType: string = 'KeyMessage';
// }
// export class FileFieldConfiguration {
//     public Name: string = null;
//     public FormatType: string = "Base64";
//     public LocationFieldName: string = "UbicacionArchivo";
//     public FieldData: string = null;
//     public UseUserLibrary: Boolean = false;
//     public LocationExtraFieldName: string = null;
//     public IncludeAttachment: Boolean = false;
//     public FileTypeFieldName: string = null;
//     //public DestinationFile: TypeLocationFile= null;
// }
// export class FieldFromTo extends ObjectSystem {
//     public ObjectType: string = "FieldFromTo";
//     public From: string;
//     public To: string;
//     public DataType: string = "String";
// }
// export class ChildQuery extends ObjectSystem {
//     public Fields: Array<FieldFromTo> = [];
//     public ResultFieldName: string;
// }

// export class BaseQuery extends ObjectSystem {
//     public Command?: string = null;
//     public PreCommands: Array<string> = [];
//     public PostCommands: Array<string> = [];
//     // public QuerySelected: ObjectSystem = new ObjectSystem();
//     public QueryType: string = null;
//     public TypeOperationQuery: string = 'Select';
//     public Cnn: BaseCnn = new BaseCnn();
//     public Permissions: Array<any> = [];
//     public ReturnQueryType: string = "ToDataTable";//ReturnQueryTypes.ToDataTable;
//     public ValuesToReplace: Array<ValueToReplace> = [];
//     public ValuesToSet: Array<ValueToSet> = [];
//     public TagsToReplace: Array<ValueToReplace> = [];
//     public ExportSettings: ExportImportConfig = null;
//     public CachingUiVar: SystemVar;
//     public CachingServerVar: SystemVar;
//     public WithPaged: Boolean = false;
//     public PaginationType: string = "None";
//     public Filter?: string;
//     public EntityName?: string;
//     public FileField: any = null;
//     public MetadataField: string;
//     public MetadataFieldJson: string;
//     public WorkFlow: ObjectSystem;
//     public ChildQuerys: Array<ChildQuery> = [];
//     public ObjectsToRun: Array<any> = [];
//     public FiltersMetadata: Array<ObjectSystemMetadata> = [];
// }
// export class SqlQuery extends BaseQuery {
//     public ObjectType: string = "SqlQuery";
//     public QueryType: string = "Sql";
//     public WithPagination: Boolean = false;
//     public QueryConfig: SqlQuery = null;
//     //public ConnectionString: string = '';
//     //public ConnectionStringName: string = '';
//     //public ConnectionStringAppSettings: string = '';
//     public IsOracle: Boolean = false;
//     //public ValuesToReplace: Array<ValueToReplace> = [];
//     public Order: number = 0;
//     public Permitidos: Array<any> = [];
//     // public Command?: string;
//     public OrderFields?: any
//     public CacheInfo?: string;
//     public CacheDataInfo?: string;
//     public ValidationMsg?: string;
//     public AppSettingsKey?: string;

//     public CrudAction: CrudActions = CrudActions.None;
//     public Fields?: any;
//     public FieldsTypes?: any;

//     public DateFormat: string = "103";
//     public WithTransaction: Boolean = false;
//     public ExecuteAsDataTable: Boolean = false;
//     public ReturnDataSet: Boolean = false;
//     public ReturnDataTable: Boolean = false;

//     public QueryConfigs?: Array<SqlQuery>;
//     public IsMulti: Boolean = false;
//     public IsSp?: Boolean;
//     public OutPutParam: Boolean = false;
//     public SetInMemory: Boolean = false;

//     public SetDefaultParameters: Boolean = false;

// }

// export class QueryTemplate extends ObjectSystem {
//     public Query: ObjectSystem = new ObjectSystem();
//     public ObjectType: string = 'QueryTemplate';
//     public Group?: string;
//     public RowIndex: number = 0;
//     public Proces: Boolean = false;
//     public ProcessQueryTemplateType: string = 'Value';
//     // public OnlyRows: Boolean = false;
//     public TagOrTable: string = null;
//     public ValuesToSet: Array<ValueToSet> = [];
//     public TagsToReplace: Array<ValueToReplace> = [];
//     public Columns: Array<string> = [];
// }



export class MailSettings {
    public WaysOfSendingMail: Array<MailSendingForms> = [];
    public SMTPServer: string = '';
    public SMTPPort: string = '';
    public SMTPUser: string = '';
    public SMTPPassword: string = '';
    public SMTPDomine: string = '';
    public EmailFrom: string = '';
    public Ssl: Boolean = false;
    public IsParallel: Boolean = false;
}
// export class BaseGeneralMail extends ObjectSystem {
//     public Permissions: Array<any> = [];
// }
// export class Mail extends BaseGeneralMail {


//     public TagsToReplaceBody: Array<ValueToReplace> = [];
//     public TagsToReplaceSubJect: Array<ValueToReplace> = [];
//     public TagsToReplaceEmailsTo: Array<ValueToReplace> = [];
//     public TagsToReplaceEmailsCC: Array<ValueToReplace> = [];
//     public TagsToReplaceEmailsBcc: Array<ValueToReplace> = [];
//     public QueryBody: ObjectSystem = null;
//     public QuerySubject: ObjectSystem = null;
//     public Body: string = '';
//     public Subject: string = '';
//     public EmailTo: string = '';
//     public IsIsHtmlBody: Boolean = false;
//     public EmailsTo: Array<string> = [];
//     public EmailsCC: Array<string> = [];
//     public EmailsBcc: Array<string> = [];
//     public QueryEmailsTo: ObjectSystem = null;
//     public QueryEmailsCC: ObjectSystem = null;
//     public QueryEmailsBcc: ObjectSystem = null;
//     public AttachmentsPaths: Array<string> = [];
//     public TagsToReplaceAttachments: Array<ValueToReplace> = [];
//     public AttachmentTemplates: Array<ObjectSystem> = [];
//     public TemplateSubject: ObjectSystem = null;
//     public TemplateBody: ObjectSystem = null;
//     public AttachmentQuery: ObjectSystem = null;
//     public Settings: MailSettings;
//     public ObjectType: string = 'Mail';
//     public CertificateService: ObjectSystem = null;
// }

// export class GroupedMail extends BaseGeneralMail {
//     public ObjectType: string = 'GroupedMail';
//     public Mails: Array<Mail> = [];
//     public SystemMails: Array<GroupedObjectSystem> = [];
// }

// export class MailContainer {

//     public Mails: Array<Mail> = [];
//     public GroupedMails: Array<GroupedMail> = [];
// }

// export class TypeLocationFile extends ObjectSystem {

//     public StorageFormat: string = "Original";
//     public Id: string = null;
//     public FilterType: string = "Ninguno";
//     public TypeLocation: string = "FileSystem";
//     // public Ruta: string = null;
//     // public Usuario: string = null;
//     // public Password: string = null;
//     // public Dominio: string = null;

//     public Cnn: BaseCnn = null;
//     public Tabla: string = null;

//     public CampoGuid: string = null;
//     public CampoAutonumerico: string = null;
//     public CampoArchivo: string = null;
//     public CampoNombreArchivo: string = null;
//     public CampoIdDocumento: string = null;
//     public CampoUsuario: string = null;
//     public CampoIdEmpresa: string = null;
//     public CampoIdSedeEmpresa: string = null;
//     public FileResult: any;
//     public OnTemporaryRoute: Boolean = false
// }
// export class DestinationTemplate extends TypeLocationFile {
//     public ResultFormat: ExportImportFormatTypes = ExportImportFormatTypes.Original;

//     public DestinationType = "None";
//     public DestinationObjectSystem: ObjectSystem = null;
//     public DestinationFolderPath: string = null;
//     public ResultPropertyName: string = "TEMPLATE_FILE";
//     public FiltersMetadata: Array<ObjectSystemMetadata> = [];

// }
// export class RuleConvertTemplate {

//     public SourceExtensions: string = "";
//     public ResultFormat: string = "None";
// }
// export class HelpConfig extends ObjectSystem {
//     public ObjectType: string = 'HelpConfig';
//     public Object: ObjectSystem;
//     public ToolTip: string = '';

//     public HelpType: string = 'Text';
//     public Description: string = '';
// }
// export class WorkFlowNotification extends ObjectSystem {
//     public ObjectType: string = 'WorkFlowNotification';
//     public Mail: ObjectSystem;
//     public Notification: ActionNotification;
//     public SendToContact: Boolean = false;
//     public SendToPreviusResponsible: Boolean = false;
//     public SendToNextResponsible: Boolean = false;
//     public SendToCurrentResponsible: Boolean = false;
//     public SendingIndividual: Boolean = false;
// }
// export class WorkFlowCategoryObject extends ObjectValue {
//     public ObjectType: string = 'WorkFlowCategoryObject';
//     public QueryForValue: ObjectSystem;
//     public EntityModel: string;

// }
// export class DelayResponseAlert extends ObjectSystem {
//     public ObjectType: string = 'DelayResponseAlert';

//     public StartDeadline: string = "Current";
//     public StartDeadlineUnit: string = "Current";

//     public EndDeadline: string = "Current";
//     public EndDeadlineUnit: string = "Current";

//     public CategoryObject: WorkFlowCategoryObject;// = new WorkFlowCategoryObject();

//     public Notifications: Array<WorkFlowNotification> = [];

// }
// export class ResponsibleSettings {
//     public Responsibles: Array<BaseInfo> = [];
//     public Responsible: WorkFlowCategoryObject;

//     public AllowUserReassignment: Boolean = true;
//     public AllowRolReassignment: Boolean = true;
//     public AllowAreaReassignment: Boolean = true;
//     public AllowGroupReassignment: Boolean = true;
//     public ResponsibleFilterType: string = "None";
// }
// export class WorkFlowState extends ObjectSystem {
//     public ObjectType: string = 'WorkFlowState';
// }
// export class WorkFlowTask extends ObjectSystem {
//     public ObjectType: string = 'WorkFlowTask';



//     public AllowRejection: Boolean = true;



//     public AllowEndTask: Boolean = true;
//     public AllowRemarks: Boolean = true;
//     public AllowAttachments: Boolean = true;

//     public AllowKeywords: Boolean = true;

//     public ExternalInvocation: Boolean = false;

//     public Description: string = '';
//     public Helps: Array<any> = [];
//     public LocationFiles: TypeLocationFile = null;

//     public ResponsiblesConfig: ResponsibleSettings = new ResponsibleSettings();
//     public ApproversConfig: ResponsibleSettings = new ResponsibleSettings();
//     public Notifications: Array<WorkFlowNotification> = [];
//     public DelayResponseAlerts: Array<DelayResponseAlert> = [];
//     public WorkflowTaskExecutionType: string = "AutoRunAutoFinish";

//     public State: string;
//     public WorkFlowState: string;

// }

// export class ActionObject extends ObjectSystem {
//     public Object: ObjectSystem = new ObjectSystem();
//     public WorkFlowTask: WorkFlowTask = null;
//     public Actions: Array<any> = [];
//     public Permissions: Array<any> = [];
//     public BPMNElementName: string;
//     public BPMNElementId: string;
//     public BPMNElementType: string;

// }
// export class BaseTemplate extends ActionObject {


//     public TagsToReplace: Array<ValueToReplace> = [];
//     public QueriesToReplace: Array<QueryTemplate> = [];

//     public Destinations: Array<DestinationTemplate> = [];
//     public ObjectType: string = 'Template';

//     public Asynchronous?: Boolean = false;

//     public TagToReplace: string = null;
//     public Content: string = null;
//     public DocumentaryTypes: Array<any> = [];
//     public Headquarters: Array<any> = [];
//     public Processes: Array<any> = [];
//     public CompanyAreas: Array<any> = [];
//     public FiltersMetadata: Array<ObjectSystemMetadata> = [];
// }

// export class ZipProceedingsConfig {
//     public GUID: string = Guid.create().toString();
//     public ObjectType: string = 'None';
//     public ExportAllDouments: Boolean = true;
//     public ExportAllAttachments: Boolean = true;
//     public ExportCloseFile: Boolean = false;
//     //  public SelectedTemplate: ObjectSystem;
//     //  public Template: Template ;
//     // public SignatureService: ObjectSystem = null;
//     // public DocumentsQuery: ObjectSystem = null;
// }
// export class TemplateProcessing {

//     public Object: ObjectSystem = null;
//     public SubObject: ObjectSystem = null;
//     public ResultFormat: string = 'None';
//     public ZipFolderName: string = null;
//     public FilesField: string = null;
//     public AttachmentField: string = null;
//     public TemplateProcessingType: string = 'None';
//     public RulesConvertTemplate: Array<any> = [];
//     public FiltersMetadata: Array<ObjectSystemMetadata> = [];
//     public Active?: Boolean = true;
// }
// export class Template extends BaseTemplate {

//     constructor(templateType?: string) {
//         super();
//         this.TemplateType = templateType;
//     }
//     public TemplateType: string = 'Word';
//     public TemplateSourceType: string = "Template";// TemplateSourceTypes.Template;
//     public SourceLoction: TypeLocationFile = new TypeLocationFile();
//     public ObjectType: string = 'Template';
//     public Permissions: Array<any> = [];
//     public Templates: Array<Template> = [];
//     public SelectedTemplates: Array<ObjectSystem> = [];
//     public ZipProceedingsConfig: ZipProceedingsConfig = null;

//     // public SignatureService: ObjectSystem = null;
//     public DocumentsQuery: ObjectSystem = null;
//     public TemplateProcessings: Array<TemplateProcessing> = [];
//     public TemplateReplacementType: string = 'None';
//     public PropertyReplacementName: string = null;
// }
// export class GroupedTemplate extends ObjectSystem {
//     public ObjectType: string = 'GroupedTemplate';
//     public Templates: Array<Template> = [];

//     public SystemTemplates: Array<GroupedObjectSystem> = [];
// }
// export class TemplateContainer {
//     public Templates: Array<Template> = [];
//     public GroupedTemplates: Array<GroupedTemplate> = [];
// }

// export class LoadField extends ObjectSystem {
//     public MapField: string = null;
//     public ActionFunctionsValidators: ActionFunctionValidator = new ActionFunctionValidator();
//     public MapQuery: ObjectSystem = null;
//     public IsPk: Boolean;
//     public ControlType: string;
//     public DataType: string;
//     public DefaultValue: any;
//     public RecordId: number = 0;
//     public Hidden: Boolean;
// }

// export class LoadSettings extends ObjectSystem {

//     public ObjectType:string ="LoadSettings";
//     public TypeLoadingProcess: string = 'ValidateAllAndBulkCopyIfAllIsValid';
//     public TableName: string = null;
//     public EndByError: Boolean = false;
//     public EndByValidation: Boolean = false;
//     public PrintDefaultValues?: Boolean = false;
//     public SendEmailWithStatus: Boolean = false;
//     public Email: string = null;
//     public UseFormFieldValidation: Boolean = false;
//     public ExportReferenceTables: Boolean = false;
//     public SetPostControls: Boolean = false;
//     public Asynchronous: Boolean = false;
//     public UseLocalFunction: Boolean = false;
//     public Query: ObjectValue = new ObjectValue();
//     public IdPagina?: number = null;
//     public LoadFields: Array<LoadField> = [];
//     public PKName: string = null; string = null;
//     public ReadingFileType: string = 'Default';
//     public Sheets: Array<string> = [];
//     public SheetsIndex: number = 0;

//     public ApplicationAllowed: ObjectSystem = null;
//     public LoadSettingsChild: Array<any> = [];
//     public LoadSettingsObjectsChild: Array<any> = [];
//     //public List<Models.UniqueKeyModel> UniqueKeys { get; set; }

// }

// export class Resource extends ObjectSystem {

//     public ObjectType:string ="Resource";
//     public Type: string = 'StyleSheet';
//     public Src: string = null;
//     public Group: string = null;
//     public Description: string = null;
//     public IsFirst: Boolean = false;



// }
// export class ActionQuery extends ActionObject {

//     public ObjectType: string = 'ActionQuery';
//     public ReturnQueryType: string = "ToDataTable";//ReturnQueryTypes.ToDataTable;
//     public ValuesToReplace: Array<ValueToReplace> = [];
//     public ValuesToSet: Array<ValueToSet> = [];
//     public TagsToReplace: Array<ValueToReplace> = [];
//     public ExportSettings: ExportImportConfig = null;
//     public Group?: string;

// }
// export class ActionRule extends ActionObject {

//     public ObjectType: string = 'ActionRule';
// }
// export class ActionForm extends ActionObject {
//     public ObjectType: string = 'ActionForm';
// }
// export class ActionNotification extends ActionObject {

//     public Title: string = '';
//     public Message: string = '';
//     public Delay?: number;
//     public PositionY: string = '';
//     public PositionX: string = '';
//     public Type: string = '';
//     public Url?: string;
//     public Target?: string;
//     public IdQuery?: number;
//     public Source: ObjectSystem = null;
//     public ActionOk: ActionCondition = null;
//     public ActionCancel: ActionCondition = null;
//     public ObjectType: string = 'ActionNotification';

// }
// export class ActionMail extends ActionObject {

//     public ObjectType: string = 'ActionMail';
// }
// export class ActionCondition extends ActionObject {
//     public IsPost: Boolean = false;
//     public RulesValidation: Array<string> = [];
//     public RuleCondition: Condition = new Condition();
//     public RuleAction: Action = new Action();
//     public RuleActionElse: Action = new Action();
//     public ObjectType: string = 'ActionCondition';
// }

// export class ActionTemplate extends BaseTemplate {
//     //public Template: ObjectSystem = new ObjectSystem();
//     public ObjectType: string = 'ActionTemplate';
//     // public ActionTemplates: Array<ActionTemplate> = [];

// }
// export class ActionFunctionQuery extends ActionQuery {
//     public ObjectType: string = "ActionFunctionQuery";
// }
// export class ActionFunctionValueToReplace extends ActionObject {

//     public ObjectType: string = "ActionFunctionValueToReplace";
//     public TagsToReplace: Array<ValueToReplace> = [];
// }
// export class ActionFunctionValidator extends ActionObject {

//     public ObjectType: string = "ActionFunctionValidator";

//     public ActionsOrder: Array<any> = [];

//     public ActionFunctionsValueToReplace: Array<any> = [];
//     public ActionFunctionQuerys: Array<any> = [];

//     public ConvertValidators: Array<any> = [];
//     public ContainsCharactersValidators: Array<any> = [];
//     public DomainValidators: Array<any> = [];
//     public FormatValidators: Array<any> = [];
//     public RangeValidators: Array<any> = [];
//     public RegexValidators: Array<any> = [];
//     public RelativeDateTimeValidators: Array<any> = [];
//     public RequiredValidators: Array<any> = [];
//     public StringFunctionValidators: Array<any> = [];
//     public StringLengthValidators: Array<any> = [];
//     public ValueComparisonValidators: Array<any> = [];
//     public JsonStructureValidators: Array<any> = [];
//     public CustomValidators: Array<any> = [];
//     public FileValidators: Array<any> = [];
//     public LoadSettingsValidators: Array<any> = [];
//     public CSharpScriptValidators: Array<any> = [];
//     public ExecuteSystemObjectValidators: Array<any> = [];
// }


// export class ActionScripting extends ActionObject {
//     public Script: string = '';
//     public ObjectType: string = 'ActionScript';
// }
// export class ActionTaskScheduler extends ActionObject {

//     public ObjectType: string = 'ActionTaskScheduler';
// }
// export class ActionApprovalTask extends ActionObject {

//     public ObjectType: string = 'ActionApprovalTask';
//     public ApprovalTaskType: string = "Approval";
//     public RunSerially: Boolean = false;
// }
// export class ActionInclude extends ActionObject {

//     public ObjectType: string = 'ActionInclude';
// }
// export class ActionCopy extends ActionObject {

//     public ObjectType: string = 'ActionCopy';
// }
// export class ActionService extends ActionObject {
//     public ObjectType: string = 'ActionService';
// }
// export class ActionEnd extends ActionObject {

//     public ObjectType: string = 'ActionEnd';
// }
// export class ActionStart extends ActionObject {

//     public ObjectType: string = 'ActionStart';
//     public Entity: string = null;
// }
// export class ActionCustom extends ActionObject {

//     public ObjectType: string = 'ActionCustom';
// }
// export class Action extends ObjectSystem {

//     public ActionsOrder: Array<ActionObject> = [];
//     public ActionQuerys: Array<ActionQuery> = [];
//     public ActionValuesToSet: Array<ValueToSet> = [];
//     public ActionMails: Array<ActionMail> = [];
//     public ActionNotifications: Array<ActionNotification> = [];
//     public ActionConditions: Array<ActionCondition> = [];
//     public ActionTemplates: Array<ActionTemplate> = [];
//     public ActionScripts: Array<ActionScripting> = [];
//     public ActionRules: Array<ActionRule> = [];
//     public ActionForms: Array<ActionForm> = [];
//     public ActionTaskSchedulers: Array<ActionTaskScheduler> = [];
//     public ActionApprovalTasks: Array<ActionApprovalTask> = [];
//     public ActionCustoms: Array<ActionCustom> = [];
//     public ActionIncludes: Array<ActionInclude> = [];
//     public ActionCopys: Array<ActionCopy> = [];
//     public ActionServices: Array<ActionService> = [];
//     public ActionFunctionValidators: ActionFunctionValidator = new ActionFunctionValidator();

//     public Message: string = '';
//     public CodeResult?: string;
//     public PreScript?: string;
//     public PostScript?: string;
//     public SendToLog: Boolean = false;
//     public ObjectType: string = 'Action';
//     public RunQueriesFirst: Boolean = false;
//     public ExitCurrentOperation: Boolean = false;

// }


// export class ConditionQuery extends ObjectSystem {



//     public Query: ObjectSystem = new ObjectSystem();
//     /*  get Query(): ObjectSystem {
 
//          return this._query;
//      }
//      set Query(value: ObjectSystem) {
 
//          this._query = value;
//          this.SetName(value);
//      } */

//     public ReturnQueryType: string = "ToDataTable";//ReturnQueryTypes.ToDataTable;
//     public ValuesToCompare: Array<ValueToCompare> = [];
//     public ValuesToReplace: Array<ValueToReplace> = [];
//     public ValuesToSet: Array<ValueToSet> = [];
//     public TagsToReplace: Array<ValueToReplace> = [];

//     public ReturnValidationMessage: Boolean = false;
//     public Count: number = 0;
//     public OpCount: ComparisonOperators = ComparisonOperators.None;
//     public Message: string;
//     public LogicalOp: LogicalOperators = LogicalOperators.And;
//     public ObjectType: string = 'ConditionQuery';

// }
// export class Scripting extends ObjectSystem {

//     public Script: string = '';
//     public ObjectType: string = 'Script';
// }

// export class ConditionObject extends ObjectSystem {

// }
// export class Condition extends ObjectSystem {
//     public ConditionsOrder: Array<ConditionObject> = [];
//     public ConditionsQuerys: Array<ConditionQuery> = [];
//     public ConditionsValues: Array<ValueToCompare> = [];
//     public Scripts: Array<Scripting> = [];
//     public ExistForFirstValidation: Boolean = false;
//     public IsNegated: Boolean = false;
//     public Script: string;
//     public ObjectType: string = 'Condition';
// }
// export class RuleBase extends ObjectSystem {
//     public IdRule: number = 0;
//     public Order: number = 0;
//     public Description: string;
//     public IdPage?: number;
//     public IsNotifications: Boolean = false;
//     public TitlePage: string;
//     public IdEmpresa: number = 0;
//     public ObjectType: string = 'Rule';
//     public RuleType: string;
// }


// export class EntityRuleConfig extends RuleBase {

//     public Key: string = '';

//     public CrudActions: Array<string> = [];
//     public IsPost: Boolean = false;
//     public RulesValidation: Array<string> = [];
//     public RuleCondition: Condition = new Condition();
//     public RuleAction: Action = new Action();
//     public RuleActionElse: Action = new Action();
//     public Pages: Array<ObjectSystem> = [];
//     public Controls: Array<ObjectSystem> = [];
//     public MetadataControls: Array<ObjectSystem> = [];
//     public Events: Array<ObjectSystem> = [];
//     public FormEvents: Array<ObjectSystem> = [];
//     public Component: ObjectSystem;
//     public Permitidos: Array<any> = [];
//     public UsuariosPermitidos: string;
//     public RolesPermitidos: string;
//     public GruposPermitidos: string;
//     public AreasEmpresaPermitidas: string;
//     public ConfiguracionPermisos: string;
//     public RuleType: string = '';

// }

// export class Validator extends ObjectSystem {
//     public DataType: string = 'String';
//     public AllowEmptyOrNull: Boolean = false;
//     public Negated: Boolean = false;
//     public CSharpScript?: string;
// }

// export class ContainsCharactersValidator extends Validator {
//     public ObjectType: string = "ContainsCharactersValidator";
// }
// export class ConvertValidator extends Validator {
//     public ObjectType: string = "ConvertValidator";
// }
// export class DomainValidator extends Validator {
//     public ObjectType: string = "DomainValidator";
// }
// export class FormatValidator extends Validator {
//     public ObjectType: string = "FormatValidator";
// }
// export class RangeValidator extends Validator {
//     public ObjectType: string = "RangeValidator";
// }
// export class RegexValidator extends Validator {
//     public ObjectType: string = "RegexValidator";
// }
// export class RelativeDateTimeValidator extends Validator {
//     public ObjectType: string = "RelativeDateTimeValidator";
// }
// export class RequiredValidator extends Validator {
//     public ObjectType: string = "RequiredValidator";
// }
// export class StringFunctionValidator extends Validator {
//     public ObjectType: string = "StringFunctionValidator";
// }
// export class StringLengthValidator extends Validator {
//     public ObjectType: string = "StringLengthValidator";
// }
// export class ValueComparisonValidator extends Validator {
//     public ObjectType: string = "ValueComparisonValidator";
// }
// export class JsonStructureValidator extends Validator {
//     public ObjectType: string = "JsonStructureValidator";
// }
// export class CustomValidator extends Validator {
//     public ObjectType: string = "CustomValidator";
// }
// export class FileValidator extends Validator {
//     public ObjectType: string = "FileValidator";
// }
// export class LoadSettingsValidator extends Validator {
//     public ObjectType: string = "LoadSettingsValidator";
// }
// export class CSharpScriptValidator extends Validator {
//     public ObjectType: string = "CSharpScriptValidator";
// }
// export class ExecuteSystemObjectValidator extends Validator {
//     public ObjectType: string = "ExecuteSystemObjectValidator";
//     public ObjectsToRun: Array<any> = [];
// }
// export class Credential {
//     public User: string = null;
//     public Password: string = null;
//     public Domain: string = null;

// }
// export class SystemVar extends ObjectSystem {
//     constructor(objectType?: string) {
//         super();
//         this.ObjectType = objectType;
//     }
//     public PkValue: any = null;
//     public DataType: DataTypes.String;
//     public DefaultValue: any;
//     public CacheItemPolicy: string = null;
// }

// export class BaseCnn extends ObjectSystem {
//     public DataLayerType: string = 'SqlServer'
//     public ConnectionString: string = null;
//     public ConnectionStringName: string = null;
//     public ConnectionStringAppSettings: string = null;
//     public Credential: Credential = new Credential();
//     public Trusted: Boolean = false;

// }
// export class DataBaseCnn extends BaseCnn {

//     constructor(dataLayerType: string) {
//         super();
//         this.DataLayerType = dataLayerType;
//         this.ObjectType = 'DataBaseCnn';
//     }
//     public DataBase: string = null;

//     public ProviderName: string = null;

//     public Server: string = null;

//     public Port: number = 0;

//     public CommandTimeout: number = 0;

//     public Timeout: number = 0;

//     public OraNames: Boolean = false;

//     public HostOrIp: string = null;
// }
// export class FileCnn extends BaseCnn {
//     constructor(dataLayerType: string) {
//         super();
//         this.DataLayerType = dataLayerType;
//         this.ObjectType = 'FileCnn';
//     }
//     public Path: string = null;
//     public Delimiter: string = null;
//     public Imex: Boolean = false;
//     public Fmt: string = 'CsvDelimited';
//     public Hrd: string = 'Yes';
// }
// export class ProcessCnn extends BaseCnn {
//     constructor() {
//         super();
//         this.DataLayerType = 'Process';
//         this.ObjectType = 'ProcessCnn';
//     }
// }
// export class PowerShellCnn extends ProcessCnn {
//     constructor() {
//         super();
//         this.DataLayerType = 'PowerShell';
//         this.ObjectType = 'PowerShellCnn';
//     }

// }

// export class SharePointCnn extends BaseCnn {

//     constructor(dataLayerType: string) {
//         super();
//         this.DataLayerType = dataLayerType;
//         this.ObjectType = 'SharePointCnn';
//     }
//     public List: string = null;
// }
// export class GoogleDocsCnn extends BaseCnn {

//     constructor() {
//         super();
//         this.DataLayerType = 'GoogleDocs';
//         this.ObjectType = 'GoogleDocsCnn';
//     }

// }
// export class OneDriveCnn extends BaseCnn {

//     constructor() {
//         super();
//         this.DataLayerType = 'OneDrive';
//         this.ObjectType = 'OneDriveCnn';
//     }

// }
// export class WebServiceCnn extends BaseCnn {
//     constructor() {
//         super();
//         this.DataLayerType = 'WebService';
//         this.ObjectType = 'WebServiceCnn';
//     }
//     public WebServiceType: string = 'Rest';
// }
// export class ActiveDirectoryCnn extends BaseCnn {
//     constructor() {
//         super();
//         this.DataLayerType = 'ActiveDirectory';
//         this.ObjectType = 'ActiveDirectoryCnn';
//     }
//     // public SettingsKeyConfig: string = null;
//     //public Domain: string = null;
//     public OU: string = null;
//     // public ServiceUser: string = null;
//     // public ServicePassword: string = null;

//     public ContextOption: string = 'Negotiate';
// }
// export class AssemblyCnn extends BaseCnn {
//     constructor() {
//         super();
//         this.DataLayerType = 'Assembly';
//         this.ObjectType = 'AssemblyCnn';
//     }

// }
// export class XsdCnn extends BaseCnn {
//     constructor() {
//         super();
//         this.DataLayerType = 'Xsd';
//         this.ObjectType = 'XsdCnn';
//     }
//     public ConnectionStringSecondFile?: string = null;
// }
// export class CnnContainer {
//     public ActiveDirectoryConnections: Array<ActiveDirectoryCnn> = [];
//     public DataBaseConnections: Array<DataBaseCnn> = [];
//     public AssemblyConnections: Array<AssemblyCnn> = [];
//     public FileConnections: Array<FileCnn> = [];
//     public WebServiceConnections: Array<WebServiceCnn> = [];
//     public ProcessConnections: Array<ProcessCnn> = [];
//     public PowerShellConnections: Array<PowerShellCnn> = [];
//     public SharePointConnections: Array<SharePointCnn> = [];
//     public GoogleDocsConnections: Array<GoogleDocsCnn> = [];
//     public OneDriveConnections: Array<OneDriveCnn> = [];
//     public XsdConnections: Array<XsdCnn> = [];
// }

// export class ActiveDirectoryQuery extends BaseQuery {
//     public QueryType: string = 'ActiveDirectory';
//     public ObjectType: string = 'ActiveDirectoryQuery';
//     public Action: string = 'None';
//     public ADGroupScope: string = 'Local';
//     public PKValue: string = null;
//     public IsSecurityGroup: Boolean = false;
//     public Properties: Array<ADProperty> = [];
//     public MultiProperties: Array<Array<ADProperty>> = [];
//     public Groups: Array<string> = [];
//     public Users: Array<string> = [];
// }

// export class Parameter extends ObjectSystem {
//     public ObjectType: string = 'Parameter';
//     public DataType: string = 'String';
//     public Properties: Array<Parameter> = [];
//     public ParameterType: string = 'In';
//     public IsComplexType: Boolean = false;
//     public ActionFunctionsValidators: any = null;
//     public JsonFields: Array<any> = [];
// }
// export interface IWebServiceAssembly {
//     ObjectType: string;
//     GUID: string;
//     ClassName: string;
//     Method: string;
//     Parameters: Array<Parameter>;
//     Cnn: BaseCnn;
// }
// export class WebServiceAssemblyBaseQuery extends BaseQuery implements IWebServiceAssembly {
//     public ClassName: string = null;
//     public Method: string = null;
//     public Parameters: Array<Parameter> = [];
// }
// export class AssemblyQuery extends WebServiceAssemblyBaseQuery implements IWebServiceAssembly {
//     public QueryType: string = 'Assembly';
//     public ObjectType: string = 'AssemblyQuery';
// }
// export class CSharpQuery extends BaseQuery {
//     public QueryType: string = 'CSharp';
//     public ObjectType: string = 'CSharpQuery';
// }
// export class DirectoryQuery extends BaseQuery {

//     public QueryType: string = 'Directory';
//     public ObjectType: string = 'DirectoryQuery';
//     // se usa Command para este campo SourcePaths
//     // public string SourcePaths { get; set; }
//     public DestinationPaths: string = null;
//     public DirectoryAction: string = 'Create';
//     public SearchPattern: string = null;
//     public SearchOption: string = 'TopDirectoryOnly';
// }
// export class FileQuery extends BaseQuery {
//     public QueryType: string = 'File';
//     public ObjectType: string = 'FileQuery';
//     public FileType: string = 'Excel';
//     public ReadingFileType: string = 'Default';
//     public Sheets: Array<string> = [];
//     public SheetsIndex: number = 0;
// }
// export class FieldMappingBase extends ObjectSystem {
//     public ObjectType: string = 'FieldMappingBase';
//     public DataType: string = "String";
//     public Query: ObjectSystem = null;
//     public DefaultValue: any;
//     public ActionFunctionsValidators: ActionFunctionValidator = new ActionFunctionValidator();
// }
// export class FromFieldMapping extends FieldMappingBase {
//     public ObjectType: string = 'FromFieldMapping';
// }

// export class FieldMapping extends FieldMappingBase {
//     public ObjectType: string = 'FieldMapping';
//     public To: string = null;
//     public PreIntegrationFunction: string = null;
//     public PostIntegrationFunction: string = null;
//     public FromMappings: Array<FromFieldMapping> = [];

// }

// export class ImportQuery extends BaseQuery {
//     public QueryType: string = 'Import';
//     public ObjectType: string = 'ImportQuery';
//     public TakeNumberRecordsType: string = "Minimum";
//     public ImportProcessType: string = "BulkCopy";
//     public DestinationQuery: ObjectSystem = new ObjectSystem();
//     public GenerateValidations: Boolean = false;
//     public EndProcessByError: Boolean = false;
//     public LogOnlyNotValidAndErrors: Boolean = false;
//     public ParallelLoad: Boolean = false;
//     public Asynchronous: Boolean = false;
//     public WithCahing: Boolean = false;
//     public FieldsToMapping: Array<FieldMapping> = [];
//     public DataSources: Array<ObjectSystem> = [];
//     public DestinationType: string = "None";
//     public ExportConfig: ExportImportConfig;
// }
// export class JavaScriptQuery extends BaseQuery {
//     public QueryType: string = 'JavaScript';
//     public ObjectType: string = 'JavaScriptQuery';
// }
// export class JoinField extends ObjectSystem {
//     public ObjectType: string = 'JoinField';
//     public LeftField: string = null;
//     public RightField: string = null;
//     public DataType: string = 'String';
// }
// export class JoinQuery extends BaseQuery {
//     public QueryType: string = 'Join';
//     public ObjectType: string = 'JoinQuery';
//     public LeftQuery: ObjectSystem = new ObjectSystem();
//     public RightQuery: ObjectSystem = new ObjectSystem();
//     public JoinType: string = 'InnerJoin';
//     public JoinFields: Array<JoinField> = [];
// }
// export class PowerShellQuery extends BaseQuery {
//     public QueryType: string = 'PowerShell';
//     public ObjectType: string = 'PowerShellQuery';
// }
// export class ProcessQuery extends BaseQuery {
//     public QueryType: string = 'Process';
//     public ObjectType: string = 'ProcessQuery';
// }

// export class SharePointQuery extends BaseQuery {
//     public QueryType: string = 'SharePoint';
//     public ObjectType: string = 'SharePointQuery';
//     public List: string = null;
//     public IncludeFields: Array<SimpleField> = [];
// }
// export class SystemQuery extends BaseQuery {
//     public QueryType: string = 'System';
//     public ObjectType: string = 'SystemQuery';
// }
// export class WebServiceQuery extends WebServiceAssemblyBaseQuery {
//     public QueryType: string = 'WebService';
//     public ObjectType: string = 'WebServiceQuery';

//     public RestMethod: string = 'Post';
//     public AuthenticationSchema: string = '';
//     public Headers: Array<string> = [];
//     public Accepts: Array<string> = [];
// }
// export class XsdQuery extends BaseQuery {
//     public QueryType: string = 'XsdTrasnformation';
//     public ObjectType: string = 'XsdQuery';
//     public CommandSecondFile?: string;
// }
// export class QueryContainer {
//     public ActiveDirectoryQuerys: Array<ActiveDirectoryQuery> = [];
//     public AssemblyQuerys: Array<AssemblyQuery> = [];
//     public CSharpQuerys: Array<CSharpQuery> = [];
//     public DirectoryQuerys: Array<DirectoryQuery> = [];
//     public FileQuerys: Array<FileQuery> = [];
//     public GroupedQuerys: Array<GroupedQuery> = [];
//     public ImportQuerys: Array<ImportQuery> = [];
//     public JavaScriptQuerys: Array<JavaScriptQuery> = [];
//     public JoinQuerys: Array<JoinQuery> = [];
//     public PowerShellQuerys: Array<PowerShellQuery> = [];
//     public ProcessQuerys: Array<ProcessQuery> = [];
//     public SharePointQuerys: Array<SharePointQuery> = [];
//     public SqlQuerys: Array<SqlQuery> = [];
//     public SystemQuerys: Array<SystemQuery> = [];
//     public WebServiceQuerys: Array<WebServiceQuery> = [];
//     public XsdQuerys: Array<WebServiceQuery> = [];
// }
// export class GroupedQuery extends BaseQuery {
//     public QueryType: string = 'Grouped';
//     public ObjectType: string = 'GroupedQuery';
//     public Querys: QueryContainer = new QueryContainer();

//     public SystemQuerys: Array<GroupedObjectSystem> = [];
// }


// export class RequestAccessKey extends ObjectSystem {
//     public ObjectType: string = 'RequestAccessKey';
//     public Name: string = "TEMP_NAME";
//     public AccessKey: string = Guid.create().toString();
//     public Url: string = ''
//     public RequestAccessKeyType: string = ''
//     public IsDenied: Boolean = false;
//     public RegularExpression: string = null;
//     public DeniedObsertvations: string = null;
// }
// export class SessionConfig {

//     public GUID: string = Guid.create().toString();
//     public SessionTime: number = 5;
//     public SessionUnitTime: string = 'Minutos'

//     public Id: number = 0;
//     public Name: string = ''
//     public Type: string = 'Usuario';
// }

// export class WebObjectBase extends ObjectSystem {
//     public Permissions: Array<any> = [];
//     public Requests: Array<RequestAccessKey> = [];
//     public DeniedAccessRules: Array<any> = [];
//     public Description: string = ''
// }
// export class DeniedAccessRule extends WebObjectBase {
//     public ObjectType: string = 'DeniedAccessRule';
//     public DeniedObsertvations: string = null;
//     public Name: string = "TEMP_NAME";
// }
// export class WebServiceMethod extends WebObjectBase {
//     public ObjectType: string = 'WebServiceMethod';
//     public ExecuteObject: ObjectSystem = null;
//     public LogSettings: Array<any> = [];
//     public Fields: Array<SimpleField> = [];
//     public Parameters: Array<Parameter> = [];
//     public Action: string = null;
//     public UseFieldsPageAsParameters: Boolean = false;
//     public FileField: any = null;
// }
// export class WebService extends WebObjectBase {
//     public ObjectType: string = 'WebService';
//     public Methods: Array<WebServiceMethod> = [];
//     public LogSettings: Array<any> = [];
//     public Controller: string = null;
// }
// export class WebServiceApp extends WebObjectBase {
//     public ObjectType: string = 'WebServiceApp';
//     public WebServices: Array<WebService> = [];
// }
// export class AccessRule extends WebObjectBase {
//     public ObjectType: string = 'AccessRule';
// }

export class AccessValidationConfig extends ObjectSystem {
    public ObjectType: string = 'AccessValidationConfig';

    public NotValidateValidRequests: Boolean = false;
    public NotValidateDeniedRequests: Boolean = false;
    public NotValidateValidAppAllowed: Boolean = false;
    public NotValidateDeniedAppAllowed: Boolean = false;
    public NotValidateValidUsers: Boolean = false;
    public NotValidateDeniedUsers: Boolean = false;
    public EncryptionType: string = "None";
}

// export class AppServer extends ObjectSystem {
//     public ObjectType: string = 'AppServer';

//     public ServerType: string = 'AppServer';
//     public Ip: string = '';
//     public Url: string = '';
//     public Description: string = '';
//     public TaskFolder: string = '';
//     public Credentials: Credential = null;
// }
// export class DbJob extends ObjectSystem {
//     public ObjectType: string = 'DbJob';

//     public Folder: string;
//     public Command: string;
//     public SubSystem: string = 'TSQL';

//     // 1 Once
//     //4	Daily
//     //8	Weekly
//     //16	Monthly
//     //32	Monthly, relative to frequency_interval.
//     //64	Run when the SQL Server Agent service starts.
//     //128	Run when the computer is idle.
//     public FreqType: number = 0;
//     //        1 (once)	frequency_interval is unused.
//     //4 (daily)	Every frequency_interval days.
//     //8 (weekly)	frequency_interval is one or more of the following(combined with an OR logical operator):

//     //1 = Sunday

//     //2 = Monday

//     //4 = Tuesday

//     //8 = Wednesday

//     //16 = Thursday

//     //32 = Friday

//     //64 = Saturday
//     //16 (monthly)	On the frequency_interval day of the month.
//     //32 (monthly relative)	frequency_interval is one of the following:

//     //1 = Sunday

//     //2 = Monday

//     //3 = Tuesday

//     //4 = Wednesday

//     //5 = Thursday

//     //6 = Friday

//     //7 = Saturday

//     //8 = Day

//     //9 = Weekday

//     //10 = Weekend day
//     //64 (when the SQL Server Agent service starts)	frequency_interval is unused.
//     //128	frequency_interval is unused.
//     public FreqInterval: number = 0;
//     //        0x1	At the specified time
//     //0x4	Minutes
//     //0x8	Hours
//     public FreqSubdayType: number = 0;

//     public FreqSubdayInterval: number = 0;
//     //        1	First
//     //2	Second
//     //4	Third
//     //8	Fourth
//     //16	Last
//     public FreqRelativeInterval: number = 0;

//     public FreqRecurrenceFactor: number = 0;
//     //The date is formatted as YYYYMMDD
//     public ActiveStartDate?: number;
//     //The date is formatted as YYYYMMDD
//     public ActiveEndDate?: number;
//     //formatted as HHMMSS on a 24-hour clock
//     public ActiveStartTime?: number;
//     //formatted as HHMMSS on a 24-hour clock
//     public ActiveEndTime?: number;

// }
// export class ApplicationAllowed extends ObjectSystem {

//     public ObjectType: string = 'ApplicationAllowed';
//     public ApplicationAllowedType: string = 'Web';
//     public Nit: string = ''
//     public Description: string = ''
//     public IsPrincipal: Boolean = false;
//     public AllowAll: Boolean = false;
//     public AccessRules: Array<AccessRule> = [];

//     public AccessKey: string = ''
//     public AccessValidationConfig: AccessValidationConfig = null;
//     public SessionTime: number = 0;
//     public SessionUnitTime: string = ''
//     public SessionConfigs: Array<SessionConfig> = [];
//     public LogSettings: Array<any> = [];

// }
// export class WorkFlow extends ObjectSystem {

//     public WorkFlowTriggerType: string = "None";
//     public WorkflowFinishType: string = "AutoError";

//     public ActionStart: ActionStart = null;
//     public ActionEnd: ActionEnd = null;
//     public ActionsOrder: Array<ActionObject> = [];
//     public ActionQuerys: Array<ActionQuery> = [];
//     public ActionValuesToSet: Array<ValueToSet> = [];
//     public ActionMails: Array<ActionMail> = [];
//     public ActionNotifications: Array<ActionNotification> = [];
//     public ActionConditions: Array<ActionCondition> = [];
//     public ActionTemplates: Array<ActionTemplate> = [];
//     public ActionScripts: Array<ActionScripting> = [];
//     public ActionRules: Array<ActionRule> = [];
//     public ActionForms: Array<ActionForm> = [];
//     public ActionTaskSchedulers: Array<ActionTaskScheduler> = [];
//     public ActionApprovalTasks: Array<ActionApprovalTask> = [];
//     public ActionCustoms: Array<ActionCustom> = [];
//     public ActionIncludes: Array<ActionInclude> = [];
//     public ActionCopys: Array<ActionCopy> = [];
//     public ActionServices: Array<ActionService> = [];
//     public ActionFunctionValidators: ActionFunctionValidator = new ActionFunctionValidator();
//     public ActionFunctionQuerys: Array<ActionFunctionQuery> = [];
//     public ActionFunctionValueToReplaces: Array<ActionFunctionValueToReplace> = [];

//     public IsTableCreated: Boolean = false;
//     public IsPublished: Boolean = false;
//     public Helps: Array<any> = [];
//     public ObjectType: string = 'WorkFlow';
//     public TableName: string;
//     public FullTextCatalog: string;
//     public LocationFiles: TypeLocationFile = null;
//     public NotificationsTableName: string;
//     public Notifications: Array<WorkFlowNotification> = [];
//     public DelayResponseAlerts: Array<DelayResponseAlert> = [];

//     public Xml: string;
// }
// export class TaskScheduler extends ObjectSystem {

//     public UserId: number = 1;
//     public Description: string;
//     public ResultManagementExecution?: any;
//     public ObjectsToRun: Array<any> = [];
//     public Asynchronous: boolean = false;
//     public Parallel: boolean = false;
//     public JsonModel: string;
//     public ObjectType: string = 'TaskScheduler';
// }
// export class DbCodeErrorAction extends ObjectSystem {

//     public Code: number = 0;
//     public Description: string;
//     public DbObjects: string;
//     public ResultManagementExecution?: any;
//     public ObjectsToRun: Array<any> = [];
//     public ObjectType: string = 'DbCodeErrorAction';
// }
// export class Report extends ObjectSystem {

//     constructor(objectType?: string) {
//         super();
//         this.ObjectType = objectType;
//     }

// }
// export class Project extends ObjectSystem {

//     public ObjectType: string = 'Project';
//     public IdEmpresa: number = -1;
//     public IdConfiguracionGeneral: number = 0;
//     public ApplicationsAllowed: Array<ApplicationAllowed> = [];
//     public Rules: Array<EntityRuleConfig> = [];
//     public GeneralConfig: any = {};
//     public SystemVars: Array<SystemVar> = [];
//     public Connections: CnnContainer = new CnnContainer();
//     public Querys: QueryContainer = new QueryContainer();
//     public Mails: MailContainer = new MailContainer();
//     public Templates: TemplateContainer = new TemplateContainer();
//     public LogSettings: Array<any> = [];
//     public WorkFlows: Array<WorkFlow> = [];
//     public WebServiceApps: Array<WebServiceApp> = [];
//     public WorkFlowStates: Array<ObjectSystem> = [];
//     public Reports: Array<Report> = [];
//     public TasksSchedulers: Array<TaskScheduler> = [];
//     public DbCodeErrorActions: Array<DbCodeErrorAction> = [];
//     public ObjectSystemMetadatas: Array<ObjectSystemMetadata> = [];

//     public AppServers: Array<AppServer> = [];
//     public DbJobs: Array<DbJob> = [];
//     public LoadSettings: Array<any> = [];

//     public Resources : Array<any> = [];
// }

// export class CampoConfig {
//     public Etiqueta: string = null;
//     public Nombre: string = null;
//     public Destino: string = null;
//     public Comando: string = null;
//     public IndiceComando: number = -1;
// }

// export class CampoConfigGenerar extends CampoConfig {

//     public TipoCampo: string = 'Valor';
//     public Valor: string = null;
//     public Cultura: string = null;
//     public Formato: string = null;
//     public CaracterRelleno: string = null;
//     public AnchoRelleno: number = 0;
//     public TipoRelleno: string = 'Ninguno';
//     public ValoresReemplazo: any = {};
//     public FormatoTexto: string = 'Normal';
//     public TamanoMaximo: number = 0;
// }
// export class ConfigRenombrarArchivo {

//     public ObjectType: string = 'ConfigRenombrarArchivo';
//     public Nombre: string = null;
//     public CamposNombre: Array<CampoConfigGenerar> = [];
//     public CaracterSeparacion: string = null;
//     public ValoresReemplazoNombre: any = {};
//     public FormatoTextoNombre: string = 'Normal';
//     public TamanoMaximoNombre: number = 0

// }

export class CampoConfig {
    public Etiqueta: string = null;
    public Nombre: string = null;
    public Destino: string = null;
    public Comando: string = null;
    public IndiceComando: number = -1;
}

export class CampoConfigGenerar extends CampoConfig {

    public TipoCampo: string = 'Valor';
    public Valor: string = null;
    public Cultura: string = null;
    public Formato: string = null;
    public CaracterRelleno: string = null;
    public AnchoRelleno: number = 0;
    public TipoRelleno: string = 'Ninguno';
    public ValoresReemplazo: any = {};
    public FormatoTexto: string = 'Normal';
    public TamanoMaximo: number = 0;
}

export class ConfigRenombrarArchivo {

    public ObjectType: string = 'ConfigRenombrarArchivo';
    public Nombre: string = null;
    public CamposNombre: Array<CampoConfigGenerar> = [];
    public CaracterSeparacion: string = null;
    public ValoresReemplazoNombre: any = {};
    public FormatoTextoNombre: string = 'Normal';
    public TamanoMaximoNombre: number = 0

}

export class ExportImportItemConfig extends ObjectSystem {


    public ObjectType: string = "ExportImportItemConfig";
    public CnnSelected: ObjectSystem = null;
    public FileName: string = null;
    public Asynchronous: Boolean = false;
    public EspecificIndex: number = -1
    public ToType: string = 'Excel';
    public DestinationType = "None";
    public FieldMappingType: string = "None"
    public ResultType: string = "None";
    public FieldsToMapping: Array<any> = []
    public Metadata: string = '';
    public OtherOption: Boolean = false;
    public Delimiter: string = ",";
    public Fmt: string = "TabDelimited"
    public RenameSettings: ConfigRenombrarArchivo = null;
    public DestinationObjectSystem: ObjectSystem = null;
    public DestinationFolderPath: string = null;
    public Active: Boolean = true;
    public ResultPropertyName: string = "EXPORT_FILE"
    public FilterGroups: Array<any> = []


}
// export class ExportImportItemConfig extends ObjectSystem {


//     public ObjectType: string = "ExportImportItemConfig";
//     public CnnSelected: ObjectSystem = null;
//     public FileName: string = null;
//     public Asynchronous: Boolean = false;
//     public EspecificIndex: number = -1
//     public ToType: string = 'Excel';
//     public DestinationType = "None";
//     public FieldMappingType: string = "None"
//     public ResultType: string = "None";
//     public FieldsToMapping: Array<any> = []
//     public Metadata: string = '';
//     public OtherOption: Boolean = false;
//     public Delimiter: string = ",";
//     public Fmt: string = "TabDelimited"
//     public RenameSettings: ConfigRenombrarArchivo = null;
//     public DestinationObjectSystem: ObjectSystem = null;
//     public DestinationFolderPath: string = null;
//     public Active: Boolean = true;
//     public ResultPropertyName: string = "EXPORT_FILE"
//     public FilterGroups: Array<any> = []


// }
// export class ExecutionObject extends ObjectSystem {

//     public ObjectType: string = "ExecutionObject";

//     public MethodNameUI: string = '';
//     public Token: string = '';
//     public AppKey: string = null;
//     public ReturnQueryType: string = 'None';
//     public EventName: string = '';
//     public ControlName: string = '';
//     public IsRule: Boolean = false;
//     public ObjectValues: Array<ValueToSet> = []
//     public ObjectsToRun: Array<ExecutionObject> = [];
//     public Model: any;
//     public ExtraObject: any;
//     public ValuesToReplace: Array<ValueToReplace> = [];
//     public TagsToReplace: Array<ValueToReplace> = [];
//     public ValuesToSet: Array<ValueToSet> = [];
//     public IsTest: Boolean = false;

//     public Asynchronous?: Boolean = false;
//     public ResultType: string = '';
//     public Parameters: Array<any> = [];
// }

export class ExportImportConfig {

    public GUID: string = Guid.create().toString();
    public IsImport: Boolean = false;
    public Asynchronous?: Boolean = null;
    public Parallel: Boolean = false;
    public FileName: string = null;
    public ItemsConfig: Array<ExportImportItemConfig> = [];
    public ExternalExportExecutor: ExternalMethodConfig;
}
// export class ExportImportConfig {

//     public GUID: string = Guid.create().toString();
//     public IsImport: Boolean = false;
//     public Asynchronous?: Boolean = null;
//     public Parallel: Boolean = false;
//     public FileName: string = null;
//     public ItemsConfig: Array<ExportImportItemConfig> = [];
// }
// export class UserSettingsModel {
//     public GUID: string = Guid.create().toString();

//     public ObjectType: string = 'UserSettingsModel';
//     public SessionConfig: any = {};
//     public ApplicationsAllowed: Array<ApplicationAllowed> = [];
//     public UserType: string = "System";
//     public AuthenticationMode: any = {};
//     public ReturnOnlyToken: Boolean = true;
//     public SignatureService: ObjectSystem = null;
//     public SiteQuery: ObjectSystem = null;
//     public SignatureTypes: Array<any> = [];
// }

// export class BaseInfo {
//     public Id: number = 0;
//     public GUID: string = null;
//     public IdEmpresa: number = 0;
//     public Nombre: string = null;
//     public Tipo: string = "None";
//     public IsDenied: Boolean = true;
//     public RegularExpression: string = null;
//     public DeniedObsertvations: string = null;
// }
// export class WebLogSettings extends BaseInfo {
//     public Asynchronous: Boolean = false;
//     public EventViewerLogName: string = null;
//     public EventViewerSource: string = null;

//     //public bool GenerateFileByDay { get; set; }
//     public DirectoryPath: string = null;
//     public EmailToLogs: string = null;


//     public LogOptions: Array<any> = [];

//     public DisableLog: Boolean = false;
//     public AsynchronousLogAccess: Boolean = false;
//     public EnabledLogAccess: Boolean = false;
//     public AsynchronousLogAudit: Boolean = false;
//     public EnabledLogAudit: Boolean = false;
//     public AsynchronousLogAccessModules: Boolean = false;
//     public EnabledLogAccessModules: Boolean = false;
//     public AsynchronousLogProcess: Boolean = false;
//     public EnabledLogProcess: Boolean = false;
//     public LogTable: string = "LTLogApp";
//     public LogTableAccess: string = "LTGuidAcceso";
//     public LogTableAccessModules: string = "LTAuditoriaAccesoModulo";
//     public LogTableProcess: string = "LTAuditoriaProcesos";
//     public LogTableAudit: string = "LTAuditoria";
//     public TxtKey: string;
//     public DateFormatTxtFileName: string = "dd_MM_yyyy";

//     public AuditModels: Array<any> = [];
//     public ReturnErrorTrace: Boolean = false;
// }
