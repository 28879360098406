import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { Guid } from "guid-typescript";
import { StaticData } from '../../../helpers/static-data';
import { ContextMenuComponent } from 'ngx-contextmenu';

@Component({
  selector: 'app-dynamic-controls-ide',
  templateUrl: './dynamic-controls-ide.component.html',
  styleUrls: ['./dynamic-controls-ide.component.scss']
})
export class DynamicControlsIDEComponent extends ConfigBaseComponent implements OnInit {

  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  @Output() removeFieldEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectFieldEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() convertToCOmpoenteEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() index: number = -1;
  @Input() listToConnect: Array<any>;
  @Input() containerName: string = '';
  @Input() disabled: boolean = false;
  @Input() notDelete: boolean = false;
  @Input() isMetadata: boolean = false;
  @Input() isCustomComponent: boolean = false;
  @Input() viewAddComponent: boolean = false;
  @Input() formControls: Array<any> = [];
  @Input() containerControls: Array<any> = [];
  @Input() hiddenFormControls: Array<any> = [];
  listName: string;
  items: Array<any> = [{ Id: 1, Text: 'Item 1' },
  { Id: 2, Text: 'Item 2' },
  { Id: 3, Text: 'Item 3' },
  { Id: 4, Text: 'Item 4' },
  { Id: 5, Text: 'Item 5' },
  { Id: 6, Text: 'Item 6' },
  { Id: 7, Text: 'Item 7' },
  { Id: 8, Text: 'Item 8' },
  { Id: 9, Text: 'Item 9' }];

  jsonData: Array<any> = [
    'Simple root node',
    {
      'text': 'Root node 2',
      'state': {
        'opened': true,
        'selected': true
      },
      'children': [
        { 'text': 'Child 1' },
        'Child 2'
      ]
    }
  ];

  columnDropped(event: CdkDragDrop<any[]>) {


    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Controls, event.previousIndex, event.currentIndex);
    } else {

    }
  }

  get IsNotDelete(): boolean {

    return false;

    // if (this.isMetadata) {

    //   if (this.subControlParent) {
    //     if (this.utility.EsControlFormulario(this.subControl)) {
    //       return true;
    //     }
    //     else
    //       return false;
    //   }
    //   else {
    //     return false;
    //   }
    // }
    // else
    //   return this.notDelete;

  }
  ngOnInit() {

    if (this.listToConnect && this.subControl &&
      (this.subControl.ControlType == 'UserControl' || this.utility.EsContenedorControlesGeneral(this.subControl))) {
      if (!this.containerName)
        this.containerName = 'Container_' + this.index.toString();

      this.listName = 'drop-list-' + this.containerName + '-' + this.index.toString() + '-' + Guid.create().toString();
      this.listToConnect.splice(0, 0, this.listName);
    }
    if (this.subControl && this.subControl.ControlType === 'GridView') {
      this.listToConnect.splice(0, 0, this.subControl.Name + '-FilterControls');
    }
    //
    if (this.subControl && this.subControl.ControlType === 'Pagina') {

      let pagina = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', this.subControl.IdPagina) //this.utility.FindAndConfigPage(this.subControl .IdPagina);
      if (pagina) {
        this.subControl.Controls = pagina.ConfigPage.Controls;
        this.subControl.View = false;
      }
      else {
        this.utility.logger.LogWarningText('No se encontro la pagina: ' + this.subControl.Name + ', Con Id:' + this.subControl.IdPagina + ' Para DynamicControlsComponent');
      }
    }
    
    if (this.subControl && this.subControl.ConfigControl && this.subControl.ConfigControl.Items && this.subControl.ConfigControl.Items.length > 0) {

      this.items = this.subControl.ConfigControl.Items;
    }



  }
modeloCombo:any= null;
  public ViewPage() {
    this.subControl.View = !this.subControl.View;
  }
  public addControl() {
    let child = this.utility.GetChildControl(this.subControl.ControlType);
    this.subControl.Controls.push(child);
  }

  public _selectField(event) {
    // event.stopPropagation();
    this.selectFieldEmitter.emit(event);
  }
  public _removeField(event) {
    if (this.isMetadata) {
      this.removeFieldEmitter.emit(event);
    }
    else {
      if (this.subControlParent) {
        this.subControlParent.Controls.splice(event.index, 1);
      }
      else
        this.removeFieldEmitter.emit(event);
    }
  }
  public _removeFieldFilterControls(event) {
    this.subControlParent.FilterControls.splice(event.index, 1);
  }

  dragStartInternal(event: CdkDragStart) {

  }

  movedInternal(event: CdkDragMove) {

  }
  itemDropped(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Controls, event.previousIndex, event.currentIndex);
    } else {

    }
  }
  itemDroppedInternal(event: CdkDragDrop<any[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Controls, event.previousIndex, event.currentIndex);
    } else {
      this.addFieldInternal(event.item.data, event.currentIndex);
    }
  }


  ///FilterControls

  addFieldInternal(fiedlModel: any, index: number) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName
    let _currentFieldModel = this.utility.GeModelControl(fiedlModel.ControlType, fiedlModel.Type);
    _currentFieldModel.ModelContainer = 'modeloVirtual';
    if (index == -1)
      this.subControl.Controls.push(_currentFieldModel);
    else
      this.subControl.Controls.splice(index, 0, _currentFieldModel)

    if (this.utility.EsContenedorControlesGeneral(_currentFieldModel))
      this.containerControls.push(_currentFieldModel);
  }

  itemDroppedInternalFilterControls(event: CdkDragDrop<any[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      if (!this.subControl.FilterControls)
        this.subControl.FilterControls = [];
      moveItemInArray(this.subControl.FilterControls, event.previousIndex, event.currentIndex);
    } else {
      this.addFieldInternalFilterControls(event.item.data, event.currentIndex);
    }
  }
  addFieldInternalFilterControls(fiedlModel: any, index: number) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName
    if (!this.subControl.FilterControls)
      this.subControl.FilterControls = [];
    let _currentFieldModel = this.utility.GeModelControl(fiedlModel.ControlType, fiedlModel.Type);
    _currentFieldModel.ModelContainer = 'modeloVirtual';
    if (index == -1)
      this.subControl.FilterControls.push(_currentFieldModel);
    else
      this.subControl.FilterControls.splice(index, 0, _currentFieldModel)
  }

  get ColSpan(): string {
    /*    if (this.subControlParent && (this.subControlParent.ControlType == 'DragContainerBox' ||
         this.subControlParent.ControlType == 'DragPanel')) {
         return '12';
       } */
    if (this.subControlParent || this.isFirst) {
      return '12';
    }
    return this.subControl.ColSpan;
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  // moveItemInArray(this.subControl.Controls, event.previousIndex, event.currentIndex);
  MoveUp() {

    let newIndex = this.index - 1;
    this.MoveUpDown(newIndex);
  }
  MoveDown() {
    let newIndex = this.index + 1;

    this.MoveUpDown(newIndex);
  }
  MoveUpDown(newIndex) {


    if (this.subControlParent) {
      this.arraymove(this.subControlParent.Controls, this.index, newIndex);
      //moveItemInArray(this.subControlParent.Controls, this.index, newIndex);
      //this.index = newIndex;
    }
    else {
      // PaginaSeleccionada?.ConfigPage?.Controls
      this.arraymove(this.Pagina.ConfigPage.Controls, this.index, newIndex);
      //moveItemInArray(this.Pagina.ConfigPage.Controls, this.index, newIndex);
      // this.index = newIndex;
    }
  }
  MovLeft() {
    if (this.subControl.ColSpan > 0)
      this.subControl.ColSpan--;
  }
  MoveRigth() {

    if (this.subControl.ColSpan < 12)
      this.subControl.ColSpan++;
  }

  CloneControl() {

    let colone = this.utility.Clone(this.subControl);
    colone.Id = Guid.create().toString();
    colone.Name = "";
    if (this.subControlParent) {

      this.subControlParent.Controls.push(colone);
    }
    else {
      if (this.Pagina.ConfigPage.HiddenControls.find(x => { return x.Id == this.subControl.Id })) {
        this.Pagina.ConfigPage.HiddenControls.push(colone);
      }
      else
        this.Pagina.ConfigPage.Controls.push(colone);

    }

  }
  MoveToContainerRigth(container, containerIndex) {

    var s = container;

    if (this.subControlParent) {

      this.subControlParent.Controls.splice(this.index, 1);
    }
    else {
      this.Pagina.ConfigPage.Controls.splice(this.index, 1);

    }
    if (container.ControlType == 'Pagina') {
      this.Pagina.ConfigPage.Controls.push(this.subControl);
    }
    else if (container.ControlType == 'HiddenControls') {
      this.Pagina.ConfigPage.HiddenControls.push(this.subControl);
    }
    else {
      container.Controls.push(this.subControl);
    }
  }
  // GetContainers() {

  //   let resultControls: Array<any> = [];
  //   resultControls = this.utility.GetAllContaines(this.Pagina.ConfiPage)
  //   return resultControls;
  // }
}
