import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter, forwardRef, Inject } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { ConfigWindow } from '../../../models/config-window';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { Guid } from 'guid-typescript';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, QueryConfigIDETypes, TipoParaPermisos, TiposCrearNodos
} from '../../../models/general.enum';
import {ConfigBaseComponent} from '../../../models/config-base-component';

@Component({
  selector: 'app-org-chart-js-config',
  templateUrl: './org-chart-js-config.component.html',
  styleUrls: ['./org-chart-js-config.component.css']
})
export class OrgChartJsConfigComponent extends ConfigBaseComponent implements OnInit {

  @Output() zoomInEventEmitter = new EventEmitter<any>();
  @Output() zoomOutEventEmitter = new EventEmitter<any>();
  @Output() onSelectedComboBoxEventEmitter = new EventEmitter<any>();
 
  imagenZoomIn:string;
  imagenZoomOut:string;

  ngOnInit() {
    this.imagenZoomIn = this.utility.GetPathImages(this.utility.GetNodeType('ZoomIn'));
    this.imagenZoomOut = this.utility.GetPathImages(this.utility.GetNodeType('ZoomOut'));
  }


  estilos: Array<any> = [
    { Id: 'luba' },
    { Id: 'olivia' },
    { Id: 'derek' },
    { Id: 'diva' },
    { Id: 'mila' },
    { Id: 'polina' },
    { Id: 'mery' },
    { Id: 'rony' },
    { Id: 'belinda' },
    { Id: 'ula' },
    { Id: 'ana' },
    { Id: 'isla' }];
  orientaciones: Array<any> = [
    { Id: 0, Text: 'Arriba' },
    { Id: 1, Text: 'Abajo' },
    { Id: 2, Text: 'Izquierda' },
    { Id: 3, Text: 'Derecha' },];



  public ZoomIn() {
    this.zoomInEventEmitter.emit();
  }
  public ZoomOut() {
    this.zoomOutEventEmitter.emit();
  }
  public OnSelectedComboBox(item, controlName, config) {

    if (item) {
      this.onSelectedComboBoxEventEmitter.emit({ item: item, controlName: controlName });

    }
  }
}
