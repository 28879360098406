import { Component, OnInit, Input, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';

@Component({
  selector: 'app-kpi-estado-solicitud-base',
  templateUrl: './kpi-estado-solicitud-base.component.html',
  styleUrls: ['./kpi-estado-solicitud-base.component.css']
})
export class KpiEstadoSolicitudBaseComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @Input() items: Array<any>;
  @Input() modelo: any;
  @Input() config: any;
  @Input() url: string = null;
  @Input() public name: string;
  @Input() public processMessage: string = 'Procesando ........';
  @Input() public componentName: string = "FormDetailsComponent"
  @Input() QueryConfig: any;
  @Input() notLoad: boolean = true;


  constructor(public baseService: BaseServiceService,
    public utility: Utilities) {

  }
  ngOnInit() {

    this.OnSetConfig();
    this.CargarDatos();

  }
  ngAfterViewInit() {
  }
  ngAfterViewChecked() {

  }
  ngOnDestroy() {

  }

  public OnSetConfig() {


    if (this.config) {
      const that = this;
      this.name = this.config.Name;
      this.notLoad = this.config.Control.ConfigControl.NotLoad;
      if (!this.url)
        this.url = this.config.Control.ConfigControl.Controller;
      if (this.config.Control.ConfigControl.QueryConfig.Command) {
        this.QueryConfig = this.config.Control.ConfigControl.QueryConfig;
      }
      this.processMessage = (this.config.Control.ProcessMessage) ? this.config.Control.ProcessMessage : this.processMessage;

      this.name = this.config.Name;
    }

    if (!this.name)
      this.name = this.componentName;


  }


  public CargarDatos(modelo?: any) {

    try {

      if(this.items && this.items.length>0){
        return
      }
      if (!this.QueryConfig)
        return;

   

      if (this.notLoad)
        return;
    

      var obj = this.utility.GetApiModel('CargarDatos', '');
      obj.QueryConfig = this.QueryConfig;

      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          processMessage: this.processMessage,
          method: 'OnInit'
        }, this.url, true, true).then(result => {

          this.modelo = result.Data[0];

         // this.MappingModelo();


        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'CargarDatos' });
        })



    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'CargarDatos' });
    }
  }

  arrayOne(st: number, fn: number): any[] {
    if (fn === 0) {
      fn = this.modelo.Items.length;
    }
    return this.modelo.Items.slice(st, fn);
  }

}
