
import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  OnDestroy,
  QueryList, forwardRef, Inject, DebugElement
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, DataTableColumnTypes, DataTableAjaxOrderTypes,
  DataTableActions
} from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { ComboBoxManager } from '../../../bussines/combo-box-manager';
import { ConfigWindow } from '../../../models/config-window';
import { UtilitiesColumns } from '../../../helpers/utilities-columns';
import { BaseComponentComponent } from '../../../components/base-component/base-component.component';
import { GeneralBaseDataTable } from 'src/app/models/general-base-data-table';
import { ComponentsContainer } from 'src/app/models/components-container';
import { HelperActions } from 'src/app/helpers/helper-actions';

@Component({
  selector: 'app-form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.css']
})
export class FormDetailsComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @Input() Pagina: any;
  @Input() public componentsContainer: ComponentsContainer;
  @Input() modelo: any = {};
  @Input() modeloExterno: any;
  @Input() config: any;
  @Input() url: string = null;
  @Input() public name: string;
  @Input() public processMessage: string = 'Procesando ........';
  @Input() public componentName: string = "FormDetailsComponent"
  @Input() QueryConfig: any;
  @Input() notLoad: boolean = true;
  @Input() isModelContainer: boolean = false;
  @Input() isTable: boolean = false;
  @Output() onInitialized = new EventEmitter<any>();
  @Input() form: FormGroup;
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public helperActions: HelperActions) {

  }
  ngOnInit() {

    this.OnSetConfig();
    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }
    this.CargarDatos();

  }
  ngAfterViewInit() {
  }
  ngAfterViewChecked() {

  }
  ngOnDestroy() {

  }

  public OnSetConfig() {


    if (this.config) {
      const that = this;
      this.name = this.config.Name;
      this.isTable = this.config.IsTable;
      this.isModelContainer = this.config.IsModelContainer;
      this.notLoad = this.config.ConfigControl.NotLoad;
      if (!this.url)
        this.url = this.config.ConfigControl.Controller;
      if (this.config.ConfigControl.QueryConfig.Command) {
        this.QueryConfig = this.config.ConfigControl.QueryConfig;
      }
      this.processMessage = (this.config.ProcessMessage) ? this.config.ProcessMessage : this.processMessage;

    
      this.Mapping();
    }

    if (!this.name)
      this.name = this.componentName;


  }
  public Mapping() {
    if (this.config.MappingValues && this.config.MappingValues.length > 0) {
      this.helperActions.ExecuteMapping(this.config.MappingValues, this.modelo, null, null, false, this.modeloExterno, null, this.QueryConfig);
    }
  }
  /*   public MappingModelo() {
      this.configs.forEach((control: any, index: number) => {
  
        control.Value = '';
        let from = this.config.MappingData.filter(x => x.To == control.Name);
        if (from) {
          control.Value = this.modelo[from.From];
        }
  
      });
    } */
  public CargarDatos(modelo?: any) {

    try {



      if (modelo) {
        this.modelo = modelo;
      }

      if (this.notLoad)
        return;
      if (this.QueryConfig && !this.QueryConfig.Command && this.modelo) {

        return;
      }
      if (this.config && this.config.ConfigControl.Items) {

        this.modelo = (this.isTable) ? this.config.ConfigControl.Items : this.config.ConfigControl.Items[0];
        return;
      }
      var obj = this.utility.GetApiModel('CargarDatos', '');
      obj.QueryConfig = this.QueryConfig;
      if (this.config && this.config.Query) {
        obj.QueryConfig.Query = this.config.Query;
      }
      if( obj.QueryConfig.MappingParameters){
        obj.QueryConfig.MappingParameters.forEach(parameter=>{
          obj.QueryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter,this.modeloExterno,this.form, obj.QueryConfig);
        })
      }
      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          processMessage: this.processMessage,
          method: 'OnInit'
        }, this.url, true, true).then(result => {

          if (this.isTable)
            this.modelo = result.Data;
          else {
            this.modelo = result.Data[0];
            if (!this.modelo) {
              if (this.isTable)
                this.modelo = [];
              else
                this.modelo = {};
            }
            this.Mapping();

          }
          // this.MappingModelo();


        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'CargarDatos' });
        })



    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'CargarDatos' });
    }
  }

  minimizeMode: boolean = true;
  _colSpan: string;
  resize() {
    if (this.minimizeMode) {
      this._colSpan = this.config.ColSpan;
      this.config.ColSpan = 12;

    }
    else
      this.config.ColSpan = this._colSpan;

    this.minimizeMode = !this.minimizeMode;
  }
}
