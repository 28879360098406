import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Guid } from 'guid-typescript';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { Utilities } from 'src/app/helpers/utilities';
import { StaticData } from 'src/app/helpers/static-data';
import { List } from '../../../../assets/linqts/compilado/index';
import PerfectScrollbar from 'perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';

//* FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-command-bar',
  templateUrl: './command-bar.component.html',
  styleUrls: ['./command-bar.component.scss']
})

export class CommandBarComponent implements OnInit, AfterViewInit {

  @Input() parentName: string;
  @Input() modelo: any;
  @Input() extraModelo: any;
  @Input() config: any;
  @Input() name: string;
  @Input() pkName: string = "PkValue";
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() type: string = 'General';
  @Input() modelContainer: string = "modelo";
  @Input() withMosaics: boolean;
  @Input() permissionsConfig: any;
  @Input() Pagina: any = { Valida: false, ConfigPage: { ExtraControls: [], Controls: [] } };

  @Input() inSharePoint = false;
  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;
  @Input() notHiddenByPermissions: boolean = false;
  classNot: string = "ms-Icon ms-Icon--Blocked";

  @Input() isDesigner: boolean = false;
  @Input() isComponent: boolean = false;
  constructor(public comunicationService: GeneralComunicationService, public utility: Utilities, public translate: TranslateService) {
    this.translate.setDefaultLang(StaticData.Language);
   }

  //* PERFECTSCROLL ELEMENT
  ps: any;

  //* FUNCIONES ANGULAR

  ngOnInit() {
    if (this.isDesigner || this.isComponent) {
      this.inSharePoint = true;
    }
    else
      this.inSharePoint = StaticData.InSharePoint;
    if (!this.name)
      this.name = 'CommandBar_' + Guid.create().toString();

    this.comunicationService.receivedViewChangeViewCommandEvent.subscribe(data => {
      this.withMosaics = data.WithMosaics;
    })
    // this.config = {
    //   Name: '', Buttons: [
    //     { Label: 'Nuevo', Type: 'New', Class: 'ms-Icon ms-Icon--CalculatorAddition' },
    //     { Label: 'Editar', Type: 'Edit', Class: 'ms-Icon ms-Icon--EditNote' },
    //     { Label: 'InActivar', Type: 'Active', Class: 'ms-Icon ms-Icon--CheckboxComposite' },
    //     { Label: 'Cargar Documento', Type: 'Upload', Class: 'ms-Icon ms-Icon--Upload' },
    //     { Label: 'Descargar Documento', Type: 'Download', Class: 'ms-Icon ms-Icon--Download' },
    //     { Label: 'Filtro', Type: 'Filter', Class: 'ms-Icon ms-Icon--Filter' },
    //     {
    //       Label: 'Vista', Type: 'View', Class: 'ms-Icon ms-Icon--ViewOriginal',
    //       Buttons: [
    //         { Label: 'Mosaicos', Type: 'Mosaicos', Class: 'ms-Icon ms-Icon--LargeGrid' },
    //         { Label: 'Lista', Type: 'Lista', Class: 'ms-Icon ms-Icon--List' }
    //       ]
    //     }
    //   ]
    // }
    //ActiveInactive


    if (this.config && this.config.Buttons && this.config.Buttons && this.config.Buttons.length > 0) {
      this.config.Buttons.forEach(element => {
        this.SetName(element);
      });
    }
    if (!this.config || !this.config.Buttons || this.config.Buttons.length == 0) {
      if (this.type.toLowerCase() == 'general') {
        this.config = {
          Name: '', Buttons: []
        };
        // this.config = {
        //   Name: '', Buttons: [
        //     {
        //       Label: 'Nuevo', Type: 'New', Class: 'ms-Icon ms-Icon--CalculatorAddition',
        //       Actions: [{ "Type": 'OpenSidePanel' }]
        //     },
        //     {
        //       Label: 'Editar', Type: 'Edit', Class: 'ms-Icon ms-Icon--EditNote',
        //       Actions: [{ "Type": 'OpenSidePanel' }]
        //     },
        //     {
        //       Label: 'InActivar', Type: 'Active', Class: 'ms-Icon ms-Icon--CheckboxComposite',
        //       Actions: [{ "Type": 'ActiveInactive' }]
        //     },
        //     {
        //       Label: 'Vista', Type: 'View', Class: 'ms-Icon ms-Icon--ViewOriginal',
        //       Buttons: [
        //         {
        //           Label: 'Mosaicos', Type: 'Mosaicos', Class: 'ms-Icon ms-Icon--LargeGrid',
        //           Actions: [{ "Type": 'MosaicView' }]
        //         },
        //         {
        //           Label: 'Lista', Type: 'Lista', Class: 'ms-Icon ms-Icon--List',
        //           Actions: [{ "Type": 'ListView' }]
        //         }
        //       ]
        //     }

        //   ]
        // }
      }
      else if (this.type.toLowerCase() == 'buscarexpediente') {
        this.config = {
          Name: '', Buttons: [
            { Label: 'Filtro', Type: 'Filter', ViewBySelection: false, Class: 'ms-Icon ms-Icon--Filter', Actions: [{ "Type": 'OpenSidePanel' }] },
            { Label: 'Exportar', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--Installation' },
            { Label: 'Indice', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--Installation' },
            { Label: 'Zip', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--ZipFolder' },

            {
              Label: 'Detalle', Type: 'Details', ViewBySelection: true, Class: 'ms-Icon ms-Icon--DatabaseView',
              "Actions": [
                {
                  "Guid": "9446cd88-f365-ccd0-d0ea-fef0be3c033c",
                  "Type": "InternalNavigation",
                  "ComponentName": "",
                  "Url": "DetalleExpediente",
                  "PropToSet": "EditItem",
                  "ModelContainer": "expediente",
                  "MappingValues": [],

                }
              ],
            },

            {
              Label: 'Vistas', Type: 'View', Class: 'ms-Icon ms-Icon--ViewOriginal',
              Buttons: [
                { Label: 'Vista Tree', Type: 'ViewTree', ViewBySelection: false, Class: 'ms-Icon ms-Icon--ViewListTree' },
                {
                  Label: 'Mosaicos', Type: 'Mosaicos', Class: 'ms-Icon ms-Icon--LargeGrid',
                  Actions: [{ "Type": 'MosaicView' }]
                },
                {
                  Label: 'Lista', Type: 'Lista', Class: 'ms-Icon ms-Icon--List',
                  Actions: [{ "Type": 'ListView' }]
                }

              ]
            }

          ]
        }
      }
      else if (this.type.toLowerCase() == 'expediente') {
        this.config = {
          Name: '', Buttons: [
            { Label: 'Nuevo', Type: 'New', Class: 'ms-Icon ms-Icon--CalculatorAddition', Actions: [{ "Type": 'OpenSidePanel' }] },
            { Label: 'Editar', Type: 'Edit', Class: 'ms-Icon ms-Icon--EditNote', Actions: [{ "Type": 'OpenSidePanel' }] },
            { Label: 'InActivar', Type: 'Active', Class: 'ms-Icon ms-Icon--CheckboxComposite', Actions: [{ "Type": 'ActiveInactive' }] },
            { Label: 'Exportar', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--Installation' },
            { Label: 'Indice', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--Installation' },
            { Label: 'Zip', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--ZipFolder' },

            {
              Label: 'Vistas', Type: 'View', Class: 'ms-Icon ms-Icon--ViewOriginal',
              Buttons: [
                { Label: 'Vista Tree', Type: 'ViewTree', ViewBySelection: false, Class: 'ms-Icon ms-Icon--ViewListTree' },
                {
                  Label: 'Mosaicos', Type: 'Mosaicos', Class: 'ms-Icon ms-Icon--LargeGrid',
                  Actions: [{ "Type": 'MosaicView' }]
                },
                {
                  Label: 'Lista', Type: 'Lista', Class: 'ms-Icon ms-Icon--List',
                  Actions: [{ "Type": 'ListView' }]
                }
              ]
            }

          ]
        }
      }
      else if (this.type.toLowerCase() == 'documentoexpediente') {
        this.config = {
          Name: '', Buttons: [
            { Label: 'Nuevo', Type: 'New', Class: 'ms-Icon ms-Icon--CalculatorAddition', Actions: [{ "Type": 'OpenSidePanel' }] },
            { Label: 'Editar', Type: 'Edit', Class: 'ms-Icon ms-Icon--EditNote', Actions: [{ "Type": 'OpenSidePanel' }] },
            { Label: 'InActivar', Type: 'Active', Class: 'ms-Icon ms-Icon--CheckboxComposite', Actions: [{ "Type": 'ActiveInactive' }] },
            {
              Label: 'Vista Grilla', Type: 'View', Class: 'ms-Icon ms-Icon--ViewOriginal',
              Buttons: [
                {
                  Label: 'Mosaicos', Type: 'Mosaicos', Class: 'ms-Icon ms-Icon--LargeGrid',
                  Actions: [{ "Type": 'MosaicView' }]
                },
                {
                  Label: 'Lista', Type: 'Lista', Class: 'ms-Icon ms-Icon--List',
                  Actions: [{ "Type": 'ListView' }]
                }
              ]
            }
          ]
        }
      }
      else if (this.type.toLowerCase() == 'detalleexpediente') {
        this.config = {
          Name: '', Buttons: [
            { Label: 'Nuevo', Type: 'New', Class: 'ms-Icon ms-Icon--CalculatorAddition', Actions: [{ "Type": 'OpenSidePanel', ComponentName: 'SidePanelDoc' }] },
            { Label: 'Editar', Type: 'Edit', Class: 'ms-Icon ms-Icon--EditNote', Actions: [{ "Type": 'OpenSidePanel', ComponentName: 'SidePanelDoc' }] },
            { Label: 'Cargar Documento', Type: 'Upload', HideBySelection: true, Class: 'ms-Icon ms-Icon--Upload' },

            {
              Label: 'Documentos', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--Download',
              Buttons: [
                { Label: 'Descargar Documento', Type: 'Download', Class: 'ms-Icon ms-Icon--Download' },
                { Label: 'Ver Documento', Type: 'ViewDocument', Class: 'ms-Icon ms-Icon--Download' },
                { Label: 'Descargar en Libreria', Type: 'Custom', Class: 'ms-Icon ms-Icon--Download' },
                { Label: 'Ver Adjuntos', Type: 'ViewAttachment', Class: 'ms-Icon ms-Icon--Attach' },
                { Label: 'Descargar Adjuntos', Type: 'DownloadAttachment', Class: 'ms-Icon ms-Icon--Download' },
              ]
            },
            {
              Label: 'Versiones', Type: 'Custom', ViewBySelection: true, Class: 'ms-Icon ms-Icon--VersionControlPush',
              Actions: [{ "Type": 'OpenSidePanel', ComponentName: 'SidePanelVersione' }]
            },
            {
              Label: 'Expediente', Type: 'Custom', Class: 'ms-Icon ms-Icon--BulletedList2',
              Buttons: [
                { Label: 'Exportar', Type: 'Custom', ViewBySelection: false, Class: 'ms-Icon ms-Icon--Installation' },
                { Label: 'Indice', Type: 'Custom', ViewBySelection: false, Class: 'ms-Icon ms-Icon--Installation' },
                { Label: 'Zip', Type: 'Custom', ViewBySelection: false, Class: 'ms-Icon ms-Icon--ZipFolder' },
              ]
            },
            {
              Label: 'Info', Type: 'Custom', Class: 'ms-Icon ms-Icon--Info',
              Buttons: [

                {
                  Label: 'Detalle', Type: 'Custom', ViewBySelection: false, Class: 'ms-Icon ms-Icon--Installation',
                  Actions: [{ "Type": 'OpenSidePanel', ComponentName: 'SidePanelDetalle' }]
                },
                {
                  Label: 'Auditoria', Type: 'Custom', ViewBySelection: false, Class: 'ms-Icon ms-Icon--Installation',
                  Actions: [{ "Type": 'OpenSidePanel', ComponentName: 'SidePanelAuditoria' }]
                },
                {
                  Label: 'Ubicacion', Type: 'Custom', ViewBySelection: false, Class: 'ms-Icon ms-Icon--Installation',
                  Actions: [{ "Type": 'OpenSidePanel', ComponentName: 'SidePanelUbicacion' }]
                },
                {
                  Label: 'Grafico', Type: 'Custom', ViewBySelection: false, Class: 'ms-Icon ms-Icon--VisioDiagram',
                  Actions: [{ "Type": 'OpenSidePanel', ComponentName: 'SidePanelGrafico' }]
                },
              ]
            },

            {
              Label: 'Vistas', Type: 'View', Class: 'ms-Icon ms-Icon--ViewOriginal',
              Buttons: [
                { Label: 'Vista Tree', Type: 'ViewTree', ViewBySelection: false, Class: 'ms-Icon ms-Icon--ViewListTree' },
                {
                  Label: 'Mosaicos', Type: 'Mosaicos', Class: 'ms-Icon ms-Icon--LargeGrid',
                  Actions: [{ "Type": 'MosaicView' }]
                },
                {
                  Label: 'Lista', Type: 'Lista', Class: 'ms-Icon ms-Icon--List',
                  Actions: [{ "Type": 'ListView' }]
                }
              ]
            }
          ]
        }
      }
    }
    else {
      this.pkName = (this.config.PkName) ? this.config.PkName : this.pkName;
      if (!this.pkName)
        this.pkName = "PkValue";
      this.modelContainer = (this.config.ModelContainer) ? this.config.ModelContainer : this.modelContainer;

      // if(this.config.NotHiddenByPermissions != null  && this.config.NotHiddenByPermissions.)
      this.notHiddenByPermissions = (this.config.NotHiddenByPermissions != null && this.config.NotHiddenByPermissions != undefined) ? this.config.NotHiddenByPermissions : false;
      this.ValidatarPermisosHiddenOrConfig();
    }

  }
  CommandBar: any;

  SetHiddenOrConfig(option) {

    if (option.Permissions && option.Permissions.length > 0) {
      if (!this.utility.TienePemisoUsuarioEnObjeto(new List<any>(option.Permissions)))
        option.Hidden = true;
    }

    if (option.Configs && option.Configs.length > 0) {
      let _config: any = null;
      option.Configs.forEach(config => {

        if (!_config) {
          if (this.utility.TienePemisoUsuarioEnObjeto(new List<any>(config.Permissions)))
            _config = config;
        }
      });
      if (_config) {

        Object.keys(_config).forEach(key => {
          option[key] = _config[key];
        });

      }
    }
  }

  public ValidatarPermisosHiddenOrConfig() {
    this.config.Buttons.forEach(option => {
      if (!option.Title)
        option.Title = "";
      this.SetHiddenOrConfig(option);
      if (option.Buttons) {
        option.Buttons.forEach(_option => {
          if (!_option.Title)
            _option.Title = "";
          this.SetHiddenOrConfig(_option);
        })
      }
    })
  }

  ngAfterViewInit() {
    //* PERFECT SCROLL
    const elemBar = document.querySelector("#" + this.name + '-scroll') as HTMLElement;
    // elemBar.scrollLeft = 0;
    this.ps = new PerfectScrollbar(elemBar, {
      handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch'],
      suppressScrollY: true
    });
    // if (window.innerWidth <= 1024) {
    //   const elemBar = document.querySelector("#" + this.name + '-scroll') as HTMLElement;
    //   // elemBar.scrollLeft = 0;
    //   this.ps = new PerfectScrollbar(elemBar, {
    //     suppressScrollY: true
    //   });
    // }
    

    let time = 1100;
    let sidePanels = this.config.Buttons.find(x => { return x.Type == 'FloatPanels' });
    if (sidePanels) {
      time = 1100;
    }

    setTimeout(() => {

      this.CreateCommandBarElement(time);

    }, 200);

  }

  SetName(commandButton: any) {
    if (!commandButton.Name) {
      commandButton.Name = "cmdButton_" + Guid.create().toString();
    }
    if (commandButton.Buttons && commandButton.Buttons.length > 0) {
      commandButton.Buttons.forEach(element => {
        this.SetName(element);
      });
    }
  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public get IsEdit(): boolean {

    if (!this.modelo || !this.modelo[this.modelContainer])
      return false;
    else {
      return (this.modelo[this.modelContainer] && this.modelo[this.modelContainer][this.pkName] !== 0 && this.modelo[this.modelContainer][this.pkName]);
    }

  }
  public SetModelo(_modelo: any) {
    this.modelo = _modelo;
  }
  public SetExtraModelo(_extraModelo: any) {
    this.extraModelo = _extraModelo;
  }

  public SetConfigPermisosOption(configPermisos: any, property: string, value: string) {
    let option = this.config.Buttons.find(x => { return x[property] == value });
    if (option)
      option.PermissionsConfig = configPermisos;

    else {
      this.config.Buttons.forEach(_option => {

        if (!option && _option.Buttons) {
          option = _option.Buttons.find(x => { return x[property] == value });
          if (option)
            option.PermissionsConfig = configPermisos;

        }
      })
    }
  }
  public SetHiddenOption(hidden: boolean, filterProperty: string, value: string, validarPermisos?: any) {
    
    let option = this.config.Buttons.find(x => { return x[filterProperty] == value });
    if (option) {
      if (validarPermisos && this.ValidarPermisos(option))
        option.Hidden = hidden;
      else
        option.Hidden = hidden;
    }

    else {
      this.config.Buttons.forEach(_option => {

        if (!option && _option.Buttons) {
          option = _option.Buttons.find(x => { return x[filterProperty] == value });
          if (option) {
            if (validarPermisos && this.ValidarPermisos(option))
              option.Hidden = hidden;
            else
              option.Hidden = hidden;
          }

        }
      })
    }
  }
  public SetHiddenOptionByLabel(hidden: boolean, value: string) {
    this.SetHiddenOption(hidden, 'Label', value);
  }
  public SetHiddenOptionByType(hidden: boolean, value: string) {
    this.SetHiddenOption(hidden, 'Type', value);
  }
  public SetConfigPermisosOptionByType(configPermisos: any, value: string) {
    this.SetConfigPermisosOption(configPermisos, 'Type', value);
  }
  GetModel() {
    if (!this.modelo || !this.modelo[this.modelContainer])
      return null;
    else {
      return (this.modelo[this.modelContainer] && this.modelo[this.modelContainer][this.pkName] !== 0 && this.modelo[this.modelContainer][this.pkName]) ? this.modelo[this.modelContainer] : null;
    }
  }
  HiddenOption(option: any) {
    let ok = false;
    if (option.HiddenScript)
      ok = eval(option.HiddenScript);
    if (this.ps) {
      this.ps.update();
    }
    // alert(this.ps);
    return ok;

  }

  ValidarPermisos(option: any) {
    let okk = true;
    if (option.PermissionsScript)
      okk = eval(option.PermissionsScript);

    if (!option.OpcionPermiso) {
      return okk;
    }

    //StaticData.Usuario.MenusUsuario
    let item = this.GetModel();
    if (!item) {
      item = {};
      item[this.pkName] = 0
    }
    if (option.OpcionPermiso == 'Menu') {

      const menu = StaticData.Usuario.MenusUsuario.find(x => { return x.IdMenu == option.DatoOpcionPermiso })
      return (menu) ? true : false;
    }

    let fromPage: boolean = (!option.NotPermissionsPage || option.NotPermissionsPage == true) ? false : true;
    let textoLog = `CommandBar${option.Label},Id:${item[this.pkName]},Nombre:${item.Nombre}`
    let _permissionsConfig = (option.PermissionsConfig) ? option.PermissionsConfig : this.permissionsConfig;
    let ok = this.utility.TienePemisoUsuario(_permissionsConfig, item, option.OpcionPermiso, 'Configuracion', false, fromPage, textoLog);
    if (ok && okk)
      return true;
    else
      return false;

  }

  public ViewOption(option: any) {
    if (option.Type == 'Edit') {
      let gg = "";
      //
    }
    if (option.Hidden)
      return true;
    option.HasPermissions = this.ValidarPermisos(option);
    if (!option.TempClass)
      option.TempClass = option.Class;
    option.Class = (option.HasPermissions) ? option.TempClass : this.classNot;
    // if (option.Type == 'New') {
    //   option.HiddenScript = '(this.extraModelo && this.extraModelo.IdExpediente > 0)?false:true';
    // }
    let isHidden = this.HiddenOption(option);
    if (this.config)
      if (option.Type == 'New' && this.IsEdit) {
        return true;
      }
      else if (option.Type == 'Edit' && !this.IsEdit) {
        return true;
      }
      else if (option.Type == 'View' && !this.withMosaics) {
        return true;
      }
      else if (option.Type == 'Active') {
        if (this.IsEdit) {
          if (this.modelo[this.modelContainer].Activo) {
            option.Class = 'ms-Icon ms-Icon--CheckboxComposite';

            option.Label = 'Inactivar';
          }
          else {
            option.Class = 'ms-Icon ms-Icon--Checkbox';
            option.Label = 'Activar';
          }
          if (!option.HasPermissions && !this.notHiddenByPermissions)
            return true;
          else
            return isHidden; //false;
        }
        else
          return true;
      }
      else if (option.ViewBySelection) {
        if (this.IsEdit) {
          if (!option.HasPermissions && !this.notHiddenByPermissions)
            return true;
          else
            return isHidden; //false;
        }
        else
          return true;
      }
      else if (option.HideBySelection) {
        if (this.IsEdit)
          return true;
        else {
          if (!option.HasPermissions && !this.notHiddenByPermissions)
            return true;
          else
            return isHidden; //false;
        }
      }
    if (!option.HasPermissions && !this.notHiddenByPermissions)
      return true;
    else
      return isHidden; //false;

  }

  public CreateCommandBarElement(time) {

    setTimeout(() => {
      var CommandBarElement = document.querySelector("#" + this.name);
      this.CommandBar = new fabric['CommandBar'](CommandBarElement);
      // if (isFirst) {
      //   if ((<any>$("#" + this.name)).parents('.modal-body').length) {
      //     (<any>$("#" + this.name)).parent().addClass("cbar__in-modal");
      //   }
      // }
    }, time);

  }

  public OnClickMenus() {

    let s = this.CommandBar;

  }
  public OnClickTest() {


    let data = 'this.modelo.modelo.IdExpediente > 0';
    let result = eval(data);
    let s = result;
  }
  public OnClick(option: any, close: any = false) {

    if (close) {
      //console.log((<any>$(".ms-ContextualHost")));
      (<any>$(".ms-ContextualHost")).removeClass('is-open');
    }

    if (this.Disabled || !option.HasPermissions)
      return;

    if (option.Type == 'ViewTree') {
      this.changeTree();
    }
    else {

      this.onActionEventEmitter.emit({
        config: this.config,
        ControlType: 'CommandButton',
        Name: this.config.Name, Action: 'Click',
        Option: option

      });
    }

  }

  /**
 * ABRIR/CERRAR JSTREE
 */
  public changeTree() {

    if ((<any>$('.gform__tree')).width() === 0) {
      (<any>$('.gform__tree')).width(406);
    } else {
      (<any>$('.gform__tree')).width('0%');
    }

  }

  //* END FUNCIONES PERSONALIZADAS

}
