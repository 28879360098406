import { APP_INITIALIZER, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import {
  MatButtonModule,
  MatExpansionModule,
  MatIconModule,
  MatMenuModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatStepperModule,
  MatTabsModule,
  MatToolbarModule,
} from "@angular/material";

import { AccessibleFormDirective } from "./directives/accessible-form.directive";
import { AceEditorModule } from "ng2-ace-editor";
import { AdminPermisosComponent } from "./components/admin-permisos/admin-permisos.component";
import { AdminPermitidosComponent } from "./components/admin-permitidos/admin-permitidos.component";
import { AdminTodoPermisosComponent } from "./components/admin-todo-permisos/admin-todo-permisos.component";
import { AngularFileUploaderModule } from "angular-file-uploader";
import { AngularSplitModule } from "angular-split";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routes";
import { AsignarCarpetasTrdComponent } from "./components/tabla-retencion-documental/asignar-carpetas-trd/asignar-carpetas-trd.component";
import { AsignarTiposDocumentalesComponent } from "./components/tabla-retencion-documental/asignar-tipos-documentales/asignar-tipos-documentales.component";
import { AttachmentSelectionComponent } from "./components/controls/attachment-selection/attachment-selection.component";
import { AuthBaseComponent } from "./components/rule-engine/info-project/auth-base/auth-base.component";
import { AuthenticationModeComponent } from "./components/rule-engine/info-project/authentication-mode/authentication-mode.component";
import { BaseComponentComponent } from "./components/base-component/base-component.component";
import { BaseServiceService } from "./services/base-service.service";
import { BotonesDataTableComponent } from "./components/controls/botones-data-table/botones-data-table.component";
import { BotonesFormularioCrudComponent } from "./components/controls/botones-formulario-crud/botones-formulario-crud.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { BusquedaExpedienteComponent } from "./components/expediente/busqueda-expediente/busqueda-expediente.component";
import { ButtonComponent } from "./components/controls/button/button.component";
import { CamposConsecutivoNombreComponent } from "./general-config/campos-consecutivo-nombre/campos-consecutivo-nombre.component";
import { PropertGridCampoComponent } from "./general-config/propert-grid-campo/propert-grid-campo.component";
import { ChartComponent } from "./components/controls/chart/chart.component";
import { CheckBoxComponent } from "./components/controls/check-box/check-box.component";
import { CheckBoxRComponent } from "./components/controls/check-box-r/check-box-r.component";
import { ComandoCampoComponent } from "./general-config/comando-campo/comando-campo.component";
import { ComboBoxComponent } from "./components/controls/combo-box/combo-box.component";
import { ComboBoxRComponent } from "./components/controls/combo-box-r/combo-box-r.component";
import { CommandBarComponent } from "./components/controls/command-bar/command-bar.component";
import { ConfigCamposComponent } from "./general-config/config-campos/config-campos.component";
import { ConfigChildPageComponent } from "./components/controls/config-page/config-child-page/config-child-page.component";
import { ConfigConsecutivoComponent } from "./config-consecutivo/config-consecutivo/config-consecutivo.component";
import { ConfigConsecutivoDocumentoComponent } from "./config-consecutivo/config-consecutivo-documento/config-consecutivo-documento.component";
import { ConfigConsecutivoExpedienteComponent } from "./config-consecutivo/config-consecutivo-expediente/config-consecutivo-expediente.component";
import { ConfigControlsPageComponent } from "./components/controls/config-page/config-controls-page/config-controls-page.component";
import { ConfigPageComponent } from "./components/controls/config-page/config-page/config-page.component";
import { ConsoleLogComponent } from "./components/console-log/console-log.component";
import { ConsoleLogTableComponent } from "./components/console-log/console-log-table/console-log-table.component";
import { ContactoComponent } from "./components/bussines/contacto/contacto.component";
import { ContextMenuModule } from "ngx-contextmenu";
import { DashboardComponent } from "./components/controls/dashboard/dashboard.component";
import { DataTableComponent } from "./components/controls/data-table/data-table.component";
import { DataTableMosaicComponent } from "./components/controls/data-table-mosaic/data-table-mosaic.component";
import { DataTableNgForComponent } from "./components/controls/data-table-ng-for/data-table-ng-for.component";
import { DataTableVersionesCrudTrdComponent } from "./components/expediente/data-table-versiones-crud-trd/data-table-versiones-crud-trd.component";
import { DataTablesModule } from "angular-datatables";
import { DateBoxComponent } from "./components/controls/date-box/date-box.component";
import { DateBoxRComponent } from "./components/controls/date-box-r/date-box-r.component";
import { DetalleExpedienteComponent } from "./components/expediente/detalle-expediente/detalle-expediente.component";
import { DobleAuthComponent } from "./components/expediente/doble-auth/doble-auth.component";
import { DocViewerComponent } from "./components/controls/doc-viewer/doc-viewer.component";
import { DocumentDownloadSettingsComponent } from "./components/document-download/document-download-settings/document-download-settings.component";
import { DocumentDownloadSettingsContainerComponent } from "./components/document-download/document-download-settings-container/document-download-settings-container.component";
import { DocumentTextReplacementSettingsComponent } from "./components/document-download/document-text-replacement-settings/document-text-replacement-settings.component";
import { DocumentoAdjuntosComponent } from "./components/documento-expediente/documento-adjuntos/documento-adjuntos.component";
import { DragAndDopMetadataComponent } from "./components/controls/drag-and-dop-metadata/drag-and-dop-metadata.component";
import { DragAndDropListComponent } from "./components/controls/drag-and-drop-list/drag-and-drop-list.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DynamicChartsComponent } from "./components/controls/dynamic-charts/dynamic-charts.component";
import { DynamicControlsComponent } from "./components/controls/dynamic-controls/dynamic-controls.component";
import { DynamicControlsContainerComponent } from "./components/controls/dynamic-controls-container/dynamic-controls-container.component";
import { DynamicControlsIDEComponent } from "./components/dynamic-templates/dynamic-controls-ide/dynamic-controls-ide.component";
import { DynamicControlsMetadataComponent } from "./components/controls/dynamic-controls-metadata/dynamic-controls-metadata.component";
import { DynamicHtmlComponent } from "./components/controls/dynamic-html/dynamic-html.component";
import { DynamicJsonControlsComponent } from "./components/controls/dynamic-json-controls/dynamic-json-controls.component";
import { DynamicPluginComponent } from "./components/controls/dynamic-plugin/dynamic-plugin.component";
import { EdicionExpedienteComponent } from "./components/expediente/edicion-expediente/edicion-expediente.component";
import { EditorAceComponent } from "./components/controls/editor-ace/editor-ace.component";
import { EditorJsonComponent } from "./components/controls/editor-json/editor-json.component";
import { EstadoCargaComponent } from "./components/bussines/estado/estado-carga.component";
import { ExpedientePanelComponent } from "./components/expediente/expediente-panel/expediente-panel.component";
import { ExtendableTableComponent } from "./components/controls/extendable-table/extendable-table.component";
import { ExternalMethodConfigComponent } from "./components/document-download/external-method-config/external-method-config.component";
import { FateModule } from "fate-editor";
import { FileUploaderChunkComponent } from "./components/controls/file-uploader-chunk/file-uploader-chunk.component";
import { FileUploaderComponent } from "./components/controls/file-uploader/file-uploader.component";
import { FileUploaderMultipartComponent } from "./components/controls/file-uploader-multipart/file-uploader-multipart.component";
import { FileUploaderTusComponent } from "./components/controls/file-uploader-tus/file-uploader-tus.component";
import { FilterPipe } from "./pipes/filter.pipe";
import { FiltersDataTableComponent } from "./components/controls/filters-data-table/filters-data-table.component";
import { FormDetailsComponent } from "./components/controls/form-details/form-details.component";
import { FormDetailsTableComponent } from "./components/controls/form-details/form-details-table/form-details-table.component";
import { FormJsonComponent } from "./components/controls/form-json/form-json.component";
import { FormMetadataComponent } from "./components/dynamic-templates/form-metadata/form-metadata.component";
import { FunctionalAuditComponent } from "./components/controls/functional-audit/functional-audit.component";
import { GeneralComponentForBuilderComponent } from "./components/controls/dynamic-templates/general-component-for-builder/general-component-for-builder.component";
import { GeneralComponentForIDEComponent } from "./components/dynamic-templates/general-component-for-ide/general-component-for-ide.component";
import { GeneralComunicationService } from "./services/general-comunication.service";
import { GeneralConfigComponent } from "./general-config/general-config/general-config.component";
import { GeneralFormComponent } from "./components/controls/general-form/general-form.component";
import { HeaderFormComponent } from "./components/header-form/header-form.component";
import { HelperActions } from "./helpers/helper-actions";
import { HelperImages } from "./helpers/helper-images";
import { HelperRules } from "./helpers/helper-rules";
import { HomeComponent } from "./components/home/home.component";
import { HtmlComponent } from "./components/controls/html/html.component";
import { ImageBoxComponent } from "./components/controls/image-box/image-box.component";
import { InfoPermissionComponent } from "./components/controls/permission/info-permission/info-permission.component";
import { JsTreeDetalleExpedienteComponent } from "./components/expediente/js-tree-detalle-expediente/js-tree-detalle-expediente.component";
import { JstreeComponent } from "./components/jstree/jstree.component";
import { KpiEstadoSolicitudBaseComponent } from "./components/controls/kpi-estado-solicitud-base/kpi-estado-solicitud-base.component";
import { KpiEstadoSolicitudComponent } from "./components/controls/kpi-estado-solicitud/kpi-estado-solicitud.component";
import { KpiEstadoSolicitudContenedorComponent } from "./components/controls/kpi-estado-solicitud-contenedor/kpi-estado-solicitud-contenedor.component";
import { LTLogAppComponent } from "./components/bussines/ltlog-app/ltlog-app.component";
import { LinkBoxComponent } from "./components/controls/link-box/link-box.component";
import { LoadWindowComponent } from "./components/load-window/load-window.component";
import { LoggerService } from "./services/logger.service";
import { LoginComponent } from "./components/login/login.component";
import { MappingFieldComponent } from "./components/controls/config-page/mapping-field/mapping-field.component";
import { MappingFieldContainerComponent } from "./components/controls/config-page/mapping-field-container/mapping-field-container.component";
import { MenuItem2Component } from "./components/menu-item2/menu-item2.component";
import { MenuItemComponent } from "./components/menu-item/menu-item.component";
import { MessageBarComponent } from "./components/controls/message-bar/message-bar.component";
import { MetadatosComponent } from "./components/bussines/metadatos/metadatos.component";
import { ModalLoginComponent } from "./components/modal-login/modal-login.component";
import { ModalNotifyComponent } from "./components/modal-notify/modal-notify.component";
import { ModalNotifyComunicationService } from "./services/modal-notify-comunication.service";
import { MultiCheckDataTableComponent } from "./components/controls/multi-check-data-table/multi-check-data-table.component";
import { MultiComboBoxComponent } from "./components/controls/multi-combo-box/multi-combo-box.component";
import { MultiComboBoxRComponent } from "./components/controls/multi-combo-box-r/multi-combo-box-r.component";
import { MustMatchDirective } from "./directives/must-match.directive";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { NgDragDropModule } from "ng-drag-drop";
import { NgJsonEditorModule } from "ang-jsoneditor";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NotificationsPanelComponent } from "./components/controls/notifications-panel/notifications-panel.component";
import { ObservacionesModificacionVersionComponent } from "./components/controls/observaciones-modificacion-version/observaciones-modificacion-version.component";
import { OrgChartJsComponent } from "./components/org-chart/org-chart-js/org-chart-js.component";
import { OrgChartJsConfigComponent } from "./components/org-chart/org-chart-js-config/org-chart-js-config.component";
import { OverlayComponent } from "./components/controls/overlay/overlay.component";
import { PalabrasClaveComponent } from "./components/palabras-clave/palabras-clave.component";
import { PermissionComponent } from "./components/controls/permission/permission/permission.component";
import { PqrdRecepcionComponent } from "./components/bussines/solicitudes/pqrs/pqrd-recepcion/pqrd-recepcion.component";
import { PrintComponent } from "./components/controls/print/print.component";
import { ProcesoRespuestaFisicoComponent } from "./components/bussines/solicitudes/salida/proceso-respuesta-fisico/proceso-respuesta-fisico.component";
import { ProgressIndicatorComponent } from "./components/controls/progress-indicator/progress-indicator.component";
import { PropertyGridColumnComponent } from "./components/dynamic-templates/property-grid-column/property-grid-column.component";
import { PropertyGridComponent } from "./components/dynamic-templates/property-grid/property-grid.component";
import { PropertyGridConfigControlComponent } from "./components/dynamic-templates/property-grid-config-control/property-grid-config-control.component";
import { PropertyGridContainersComponent } from "./components/dynamic-templates/property-grid-containers/property-grid-containers.component";
import { PropertyGridControlsComponent } from "./components/dynamic-templates/property-grid-controls/property-grid-controls.component";
import { PropertyGridCustomComponentsComponent } from "./components/dynamic-templates/property-grid-custom-components/property-grid-custom-components.component";
import { QRCodeBoxComponent } from "./components/controls/qrcode-box/qrcode-box.component";
import { QueryBuilderModule } from "angular2-query-builder";
import { RConsecutivoComponent } from "./components/bussines/rconsecutivo/rconsecutivo.component";
import { RPaginaComponent } from "./components/bussines/rpagina/rpagina.component";
import { RTipoDocumentalComponent } from "./components/bussines/rtipo-documental/rtipo-documental.component";
import { RUsuarioComponent } from "./components/bussines/rusuario/rusuario.component";
import { RRoleComponent } from "./components/bussines/rrole/rrole.component";
import { RGrupoComponent } from "./components/bussines/rgrupo/rgrupo.component";
import { RadicadoEntradaRecepcionComponent } from "./components/bussines/solicitudes/entrada/radicado-entrada-recepcion/radicado-entrada-recepcion.component";
import { RadicadoFacturaRecepcionComponent } from "./components/bussines/solicitudes/factura/radicado-factura-recepcion/radicado-factura-recepcion.component";
import { RoleFilterComponent } from "./components/controls/config-page/role-filter/role-filter.component";
import { RoleQueryComponent } from "./components/controls/config-page/role-query/role-query.component";
import { ScanDocumentsComponent } from "./components/controls/scan-documents/scan-documents.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SessionConfigComponent } from "./components/rule-engine/info-project/session-config/session-config.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SolicitudCommonComponent } from "./components/bussines/solicitudes/common/solicitud-common.component";
import { SolicitudEntradaReemplazoComponent } from "./components/bussines/solicitudes/entrada/solicitud-entrada-reemplazo/solicitud-entrada-reemplazo.component";
import { SolicitudFacturaReemplazoComponent } from "./components/bussines/solicitudes/factura/solicitud-factura-reemplazo/solicitud-factura-reemplazo.component";
import { SolicitudPqrdComponent } from "./components/bussines/solicitudes/pqrs/solicitud-pqrd/solicitud-pqrd.component";
import { SolicitudPqrdOficioComponent } from "./components/bussines/solicitudes/pqrs/solicitud-pqrd-oficio/solicitud-pqrd-oficio.component";
import { SolicitudPqrdReemplazoComponent } from "./components/bussines/solicitudes/pqrs/solicitud-pqrd-reemplazo/solicitud-pqrd-reemplazo.component";
import { SolicitudRadicadoEntradaComponent } from "./components/bussines/solicitudes/entrada/solicitud-radicado-entrada/solicitud-radicado-entrada.component";
import { SolicitudRadicadoEntradaOficioComponent } from "./components/bussines/solicitudes/entrada/solicitud-radicado-entrada-oficio/solicitud-radicado-entrada-oficio.component";
import { SolicitudRadicadoFacturaComponent } from "./components/bussines/solicitudes/factura/solicitud-radicado-factura/solicitud-radicado-factura.component";
import { SolicitudRadicadoFacturaOficioComponent } from "./components/bussines/solicitudes/factura/solicitud-radicado-factura-oficio/solicitud-radicado-factura-oficio.component";
import { SolicitudRadicadoMemorandoComponent } from "./components/bussines/solicitudes/memorando/solicitud-radicado-memorando/solicitud-radicado-memorando.component";
import { SolicitudRadicadoSalidaComponent } from "./components/bussines/solicitudes/salida/solicitud-radicado-salida/solicitud-radicado-salida.component";
import { SpinnerComponent } from "./components/controls/spinner/spinner.component";
import { StaticData } from "./helpers/static-data";
import { StatusFileUploaderTusSpaPipe } from "./pipes/status-file-uploader-tus-spa.pipe";
import { SwapFormComponent } from "./components/controls/swap-form/swap-form.component";
import { SwithComponentsComponent } from "./components/bussines/swith-components/swith-components.component";
import { TDocumentoExpedienteComponent } from "./components/bussines/tdocumento-expediente/tdocumento-expediente.component";
import { TExpedienteComponent } from "./components/bussines/texpediente/texpediente.component";
import { TUbicacionFisicaExpedienteComponent } from "./components/bussines/tubicacion-fisica-expediente/tubicacion-fisica-expediente.component";
import { TextAreaComponent } from "./components/controls/text-area/text-area.component";
import { TextAreaRComponent } from "./components/controls/text-area-r/text-area-r.component";
import { TextBoxComponent } from "./components/controls/text-box/text-box.component";
import { TextBoxRComponent } from "./components/controls/text-box-r/text-box-r.component";
import { ThemeCustomizationComponent } from "./components/theme-customization/theme-customization.component";
import { ThreeStateToggleComponent } from "./components/controls/three-state-toggle/three-state-toggle.component";
import { ThreeStateToggleRComponent } from "./components/controls/three-state-toggle-r/three-state-toggle-r.component";
import { ToolTipValidationComponent } from "./components/controls/tool-tip-validation/tool-tip-validation.component";
import { TopNavbarComponent } from "./components/top-navbar/top-navbar.component";
import { TransferenciaExpedienteComponent } from "./components/expediente/transferencia-expediente/transferencia-expediente.component";
import { UploadxModule } from "ngx-uploadx";
import { UserNavComponent } from "./components/user-nav/user-nav.component";
import { UserSettingsModelComponent } from "./components/rule-engine/info-project/user-settings-model/user-settings-model.component";
import { Utilities } from "./helpers/utilities";
import { UtilitiesColumns } from "./helpers/utilities-columns";
import { UtilityRuleEngine } from "./helpers/utility-rule-engine";
import { WindowComponent } from "./components/window.component/window.component";
import { WindowService } from "./components/window.component/window.service";
import { environment } from "./../environments/environment";
import { PermissionTableComponent } from './components/controls/new-admin-permisos/permission-table/permission-table.component';
import { PermissionTabComponent } from './components/controls/new-admin-permisos/permission-tab/permission-tab.component';
import { SolicitudDocumentosComponent } from "./components/bussines/solicitudes/documento/solicitud-documentos/solicitud-documentos.component";
import { ConfigExtensionesComponent } from "./components/config-extensiones/config-extensiones.component";
import { EliminacionExpedienteComponent } from "./components/expediente/eliminacion-expediente/eliminacion-expediente.component";
import { RtablaRetencionDocumentalComponent } from './components/bussines/rtabla-retencion-documental/rtabla-retencion-documental.component';
import { RTipoSolicitudComponent } from './components/bussines/rtipo-solicitud/rtipo-solicitud.component';
import { REstadoSolicitudComponent } from './components/bussines/restado-solicitud/restado-solicitud.component';
import { FormTipoSolicitudComponent } from './components/dynamic-templates/form-tipo-solicitud/form-tipo-solicitud.component';
import { PropertyGridMappingParametersComponent } from "./components/dynamic-templates/property-grid-mapping-parameters/property-grid-mapping-parameters.component";
import { PropertyGridCollectionsComponent } from "./components/dynamic-templates/property-grid-collections/property-grid-collections.component";
import { ModelContainerSelectorComponent } from "./components/dynamic-templates/model-container-selector/model-container-selector.component";
import { WysiwygEditorComponent } from "./components/controls/wysiwyg-editor/wysiwyg-editor.component";
import { RPlantillaUsuarioComponent } from "./components/bussines/rplantilla-usuario/rplantilla-usuario.component";
import { AdminPermisosServidorComponent } from "./components/admin-permisos-servidor/admin-permisos-servidor.component";
import { RcuadernoComponent } from './components/bussines/rcuaderno/rcuaderno.component';
import {
  NgxExtendedPdfViewerModule
} from 'ngx-extended-pdf-viewer';
import { RsubserieComponent } from './components/bussines/rsubserie/rsubserie.component';
import { AnulacionesAprobadasComponent } from './components/bussines/solicitudes/anulacion/anulaciones-aprobadas/anulaciones-aprobadas.component';
import { RtipoRespuestaPqrdComponent } from './components/bussines/rtipo-respuesta-pqrd/rtipo-respuesta-pqrd.component';
import { RtemaSolicitudComponent } from './components/bussines/rtema-solicitud/rtema-solicitud.component';
import { SolicitudPqrdAdjuntosComponent } from './components/bussines/solicitudes/pqrs/solicitud-pqrd-adjuntos/solicitud-pqrd-adjuntos.component';
import { RcausalrechazoComponent } from './components/bussines/rcausalrechazo/rcausalrechazo.component';
import { SolicitudFlujoDocumentalComponent } from './components/bussines/solicitudes/documento/solicitud-flujo-documental/solicitud-flujo-documental.component';
import { FlujoDocumentosComponent } from "./components/bussines/solicitudes/documento/flujo-documentos/flujo-documentos.component";
import { BusquedaRadicadoComponent } from './components/bussines/solicitudes/busqueda-radicado/busqueda-radicado.component';
import { PrestamoDocumentalComponent } from './components/expediente/prestamo-documental/prestamo-documental.component';
import { RZonaEmpresaComponent } from './components/bussines/rzona-empresa/rzona-empresa.component';
import { TEncargoComponent } from './components/bussines/tencargo/tencargo.component';
import { RPlantillaComponent } from './components/bussines/rplantilla/rplantilla.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:04/23/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

//Angular Material

//Componentes

//Rutas

//Controles

//Directivas

//Helpers

//Servicios

// import { OverlayModule } from '@angular/cdk/overlay';
// import { PortalModule } from '@angular/cdk/portal';
// import { ScrollDispatchModule } from '@angular/cdk/scrolling';

declare let WebConfigData;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderFormComponent,
    HomeComponent,
    MustMatchDirective,
    AccessibleFormDirective,
    JstreeComponent,
    ModalNotifyComponent,
    ModalLoginComponent,
    MenuItemComponent,
    ComboBoxComponent,
    WindowComponent,
    CheckBoxComponent,
    TextAreaComponent,
    MultiComboBoxComponent,
    DateBoxComponent,
    TextBoxComponent,
    BotonesFormularioCrudComponent,
    MultiCheckDataTableComponent,
    ThreeStateToggleComponent,
    ObservacionesModificacionVersionComponent,
    BotonesDataTableComponent,
    SwithComponentsComponent,

    ConfigPageComponent,
    ConfigChildPageComponent,
    RoleQueryComponent,
    RoleFilterComponent,
    ConfigControlsPageComponent,
    MappingFieldComponent,
    MappingFieldContainerComponent,
    DynamicControlsComponent,
    DynamicControlsMetadataComponent,
    GeneralComponentForBuilderComponent,
    GeneralComponentForIDEComponent,
    DynamicControlsIDEComponent,
    PropertyGridComponent,
    PropertyGridControlsComponent,

    DynamicControlsContainerComponent,
    DataTableComponent,

    TExpedienteComponent,
    TDocumentoExpedienteComponent,

    RUsuarioComponent,
    RRoleComponent,
    RGrupoComponent,
    ContactoComponent,

    RTipoDocumentalComponent,

    RConsecutivoComponent,

    LTLogAppComponent,
    EditorJsonComponent,
    EditorAceComponent,
    BusquedaExpedienteComponent,
    TransferenciaExpedienteComponent,
    EliminacionExpedienteComponent,
    JsTreeDetalleExpedienteComponent,
    LoginComponent,
    OrgChartJsComponent,
    OrgChartJsConfigComponent,
    AdminPermisosComponent,
    AdminPermisosServidorComponent,
    PalabrasClaveComponent,
    FileUploaderComponent,

    AsignarTiposDocumentalesComponent,
    AsignarCarpetasTrdComponent,
    PropertyGridColumnComponent,

    BaseComponentComponent,
    FormDetailsComponent,
    TextBoxRComponent,
    TextAreaRComponent,
    CheckBoxRComponent,
    ComboBoxRComponent,
    MultiComboBoxRComponent,
    GeneralFormComponent,
    DateBoxRComponent,
    DragAndDropListComponent,
    DragAndDopMetadataComponent,
    FormMetadataComponent,
    ThreeStateToggleRComponent,
    AdminPermitidosComponent,

    DataTableNgForComponent,
    DataTableVersionesCrudTrdComponent,
    SidebarComponent,
    TopNavbarComponent,
    MenuItem2Component,
    AuthBaseComponent,
    AuthenticationModeComponent,
    SessionConfigComponent,
    UserSettingsModelComponent,
    DocumentoAdjuntosComponent,
    ButtonComponent,
    DetalleExpedienteComponent,
    EdicionExpedienteComponent,
    DobleAuthComponent,

    LoadWindowComponent,

    InfoPermissionComponent,
    PermissionComponent,

    DynamicJsonControlsComponent,
    DynamicHtmlComponent,
    ExtendableTableComponent,
    FormJsonComponent,
    FiltersDataTableComponent,

    ToolTipValidationComponent,
    KpiEstadoSolicitudComponent,
    KpiEstadoSolicitudContenedorComponent,
    KpiEstadoSolicitudBaseComponent,
    DataTableMosaicComponent,
    DynamicChartsComponent,

    PropertyGridComponent,
    PropertyGridControlsComponent,
    PropertyGridColumnComponent,

    UserNavComponent,
    SwapFormComponent,
    FilterPipe,
    DocViewerComponent,
    FormDetailsTableComponent,
    CommandBarComponent,
    ProgressIndicatorComponent,
    AttachmentSelectionComponent,
    ExpedientePanelComponent,
    ThemeCustomizationComponent,
    AdminTodoPermisosComponent,
    ConsoleLogComponent,
    ConsoleLogTableComponent,
    SpinnerComponent,
    OverlayComponent,
    PropertyGridConfigControlComponent,
    PropertyGridCustomComponentsComponent,
    PropertyGridContainersComponent,
    SolicitudRadicadoSalidaComponent,
    SolicitudDocumentosComponent,
    ProcesoRespuestaFisicoComponent,
    SolicitudRadicadoEntradaComponent,
    SolicitudRadicadoFacturaComponent,
    SolicitudRadicadoMemorandoComponent,
    SolicitudCommonComponent,
    GeneralConfigComponent,
    ConfigConsecutivoComponent,
    ConfigConsecutivoExpedienteComponent,
    ConfigConsecutivoDocumentoComponent,
    CamposConsecutivoNombreComponent,
    PropertGridCampoComponent,
    ConfigCamposComponent,
    ComandoCampoComponent,
    ConfigPageComponent,
    ConfigPageComponent,
    ConfigPageComponent,
    CommandBarComponent,
    SolicitudPqrdComponent,
    EstadoCargaComponent,
    RPaginaComponent,
    QRCodeBoxComponent,
    MessageBarComponent,
    PrintComponent,
    ChartComponent,
    DashboardComponent,
    HtmlComponent,
    ImageBoxComponent,
    LinkBoxComponent,
    SolicitudRadicadoEntradaOficioComponent,
    NotificationsPanelComponent,
    SolicitudPqrdOficioComponent,
    SolicitudPqrdReemplazoComponent,
    SolicitudEntradaReemplazoComponent,
    SolicitudFacturaReemplazoComponent,
    PqrdRecepcionComponent,
    RadicadoEntradaRecepcionComponent,
    SolicitudRadicadoFacturaOficioComponent,
    RadicadoFacturaRecepcionComponent,
    FunctionalAuditComponent,
    ScanDocumentsComponent,
    MetadatosComponent,
    DynamicPluginComponent,
    DocumentTextReplacementSettingsComponent,
    DocumentDownloadSettingsComponent,
    DocumentDownloadSettingsContainerComponent,
    ExternalMethodConfigComponent,
    FileUploaderTusComponent,
    StatusFileUploaderTusSpaPipe,
    FileUploaderMultipartComponent,
    FileUploaderChunkComponent,
    TUbicacionFisicaExpedienteComponent,
    PermissionTableComponent,
    PermissionTabComponent,
    ConfigExtensionesComponent,
    RtablaRetencionDocumentalComponent,
    RTipoSolicitudComponent,
    REstadoSolicitudComponent,
    FormTipoSolicitudComponent,
    PropertyGridMappingParametersComponent,
    PropertyGridCollectionsComponent,
    ModelContainerSelectorComponent,
    WysiwygEditorComponent,
    RPlantillaUsuarioComponent,
    RcuadernoComponent,
    RsubserieComponent,
    AnulacionesAprobadasComponent,
    RtipoRespuestaPqrdComponent,
    RtemaSolicitudComponent,
    SolicitudPqrdAdjuntosComponent,
    RcausalrechazoComponent,
    FlujoDocumentosComponent,
    SolicitudFlujoDocumentalComponent,
    BusquedaRadicadoComponent,
    PrestamoDocumentalComponent,
    RZonaEmpresaComponent,
    TEncargoComponent,
    RPlantillaComponent
  ],
  // exports: [OverlayModule,
  //   PortalModule,
  //   ScrollDispatchModule],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    AppRoutes,
    BrowserAnimationsModule,
    NgSelectModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatExpansionModule,
    MatStepperModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    DragDropModule,
    FateModule,
    NgDragDropModule.forRoot(),
    ContextMenuModule.forRoot(),
    QueryBuilderModule,
    NgJsonEditorModule,
    AceEditorModule,
    AngularSplitModule.forRoot(),
    AngularFileUploaderModule,
    NgxDocViewerModule,
    NgxChartsModule,
    UploadxModule,
    NgxExtendedPdfViewerModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    GeneralComunicationService,
    Utilities,
    UtilitiesColumns,
    HelperImages,
    UtilityRuleEngine,
    HelperActions,
    BaseServiceService,
    WindowService,
    ModalNotifyComunicationService,
    LoggerService,
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [HttpClient],
    },
    HelperRules,
  ],
  entryComponents: [
    WindowComponent,
    PropertyGridControlsComponent,
    ObservacionesModificacionVersionComponent,
    JstreeComponent,
    OrgChartJsConfigComponent,
    OrgChartJsComponent,
    DataTableComponent,
    EditorJsonComponent,
    EditorAceComponent,
    ComandoCampoComponent,
    PropertGridCampoComponent,
    DataTableComponent,
    DataTableVersionesCrudTrdComponent,
    GeneralFormComponent,
    EdicionExpedienteComponent,
    DobleAuthComponent,

    DocumentoAdjuntosComponent,
    UserSettingsModelComponent,
    DynamicControlsComponent,
    DynamicControlsMetadataComponent,
    InfoPermissionComponent,
    FormMetadataComponent,
    DocViewerComponent,
    AttachmentSelectionComponent,
    ThemeCustomizationComponent,
    ConsoleLogComponent,
    ConsoleLogTableComponent,
    SpinnerComponent,
    QRCodeBoxComponent,
    FiltersDataTableComponent,
    DashboardComponent,
    DynamicControlsContainerComponent,
    FunctionalAuditComponent,
    DocumentDownloadSettingsContainerComponent,
    PropertyGridCollectionsComponent,
    PropertyGridColumnComponent,
    WysiwygEditorComponent,
    RPlantillaUsuarioComponent
  ],
  exports: [DataTableComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function onAppInit(_http: HttpClient): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        StaticData.UrlServer = environment.APIEndpoint;
        StaticData.AppName = "BextDocUI";
        StaticData.HostingType = environment.HostingType;

        if (WebConfigData) {
          StaticData.UrlServer =
            WebConfigData.APIEndpoint != null
              ? WebConfigData.APIEndpoint
              : StaticData.UrlServer;
          StaticData.AppName =
            WebConfigData.AppName != null
              ? WebConfigData.APIEndpoint
              : StaticData.AppName;
          StaticData.HostingType =
            WebConfigData.HostingType != null
              ? WebConfigData.HostingType
              : StaticData.HostingType;
          StaticData.InSharePoint =
            WebConfigData.InSharePoint != null
              ? WebConfigData.InSharePoint
              : StaticData.InSharePoint;
          environment.PathImages =
            WebConfigData.PathImages != null
              ? WebConfigData.PathImages
              : environment.PathImages;

          StaticData.DefaultUser =
            WebConfigData.DefaultUser != null
              ? WebConfigData.DefaultUser
              : StaticData.DefaultUser;

          StaticData.ViewPasswordBox =
            WebConfigData.ViewPasswordBox != null
              ? WebConfigData.ViewPasswordBox
              : StaticData.ViewPasswordBox;

          StaticData.ViewDomainComboBox =
            WebConfigData.ViewDomainComboBox != null
              ? WebConfigData.ViewDomainComboBox
              : StaticData.ViewDomainComboBox;
          StaticData.ViewLogOut =
            WebConfigData.ViewLogOut != null
              ? WebConfigData.ViewLogOut
              : StaticData.ViewLogOut;
          if (
            WebConfigData.DynamicLoadResources != null &&
            WebConfigData.DynamicLoadResources != undefined
          )
            StaticData.DynamicLoadResources =
              WebConfigData.DynamicLoadResources;
          StaticData.UseWebWorker =
            WebConfigData.UseWebWorker != null
              ? WebConfigData.UseWebWorker
              : StaticData.UseWebWorker;
          StaticData.LoginLoadAllComponents =
            WebConfigData.LoginLoadAllComponents != null
              ? WebConfigData.LoginLoadAllComponents
              : StaticData.LoginLoadAllComponents;

          StaticData.ApiGetIpClient =
            WebConfigData.ApiGetIpClient != null
              ? WebConfigData.ApiGetIpClient
              : StaticData.ApiGetIpClient;

          StaticData.ConsultarIpClient =
            WebConfigData.ConsultarIP != null
              ? WebConfigData.ConsultarIP
              : StaticData.ConsultarIpClient;

          StaticData.IdGuidAcesso =
            WebConfigData.IdGuidAcesso != null
              ? WebConfigData.IdGuidAcesso
              : StaticData.IdGuidAcesso;

          StaticData.UrlServiciosWebExterno =
            WebConfigData.UrlWebServiceEditPlantilla != null
              ? WebConfigData.UrlWebServiceEditPlantilla
              : StaticData.UrlServiciosWebExterno;

        }
        resolve(null);
      }, 400);
    });
  };
}
