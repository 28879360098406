import { Component, OnInit, Input } from '@angular/core';
import { Guid } from 'guid-typescript';

declare var FileSaver: any;
declare var saveAs: any;
declare var html2canvas: any;
declare var jsPDF: any;
@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintComponent implements OnInit {

  @Input() name: string = "Print_" + Guid.create().toString();
  @Input() config: any = {
    Name: this.name,
    Container: "Body",
    FileName: "General",
    ViewPrint: true,
    ViewPrintNewWindow: true,
    ViewScreenshot: true,
    ViewPdfScreenshot: true,
    LabelPrint: "",
    LabelPrintNewWindow: "",
    LabelScreenshot: "",
    LabelPdfScreenshot: "",
  };

  constructor() { }

  ngOnInit() {
    // if (this.config)
    //   this.config = {
    //     Name: this.name,
    //     Container: "Body",
    //     FileName: "General",
    //     ViewPrint: true,
    //     ViewScreenshot: true,
    //     ViewPdfScreenshot: true,
    //     LabelPrint:"",
    //     LabelScreenshot:"",
    //     LabelPdfScreenshot:"",
    //   }
    // else {
    //   this.name = this.config.Name;
    // }
  }

  get IsBody(): boolean {
    return (this.config.Container == "Body" || !this.config.Container || this.config.Container == "body");
  }

  PrintDiv(divName) {
    let newWindow = window.open("", "PRINT", "height=720,width=1080");
    newWindow.document.write("<!DOCTYPE html>");
    newWindow.document.write("<html>");
    newWindow.document.write("<head>");
    //newWindow.document.write(document.head.innerHTML);
    newWindow.document.write("<style>\n");
    newWindow.document.write("  #QRCodeBoxResult > img{");
    newWindow.document.write("width: 105px;");
    newWindow.document.write("height: 105px;");
    newWindow.document.write("padding-top: 25px;");
    newWindow.document.write("}");
    newWindow.document.write("</style>");
    newWindow.document.write("</head>");
    newWindow.document.write("<body style='font-size: 11px !important; margin-left: 10px;margin-top:50px;'>");
    newWindow.document.write('<div id="result" style="margin-top:45px;display: flex; margin-bottom: 5px; margin-left: 50px; flex-direction: row; margin: 100px;text-align: right">');
    newWindow.document.write(document.getElementById(divName).innerHTML);
    newWindow.document.write("</div>");
    newWindow.document.write("</body>");
    newWindow.document.write("</html>");

    newWindow.document.close(); // necessary for IE >= 10
    newWindow.focus(); // necessary for IE >= 10*/

    //newWindow.print();
    //newWindow.close();
// Luego de cargar la vista, activa la impresión
    //newWindow.onload = () => {
    //  newWindow.print();
    //  //newWindow.close();
    //};
    
    /**
     * Inicio Edward Morales 30012024
     * No aparece ventana previa para impresión en SharePoint
     * Se agrega siguiente línea con onpageshow ya que onload genera 
     * inconvenientes de caché y no aparece a tiempo la ventana previa de impresión
     * 
     * Se inactiva llamado a .close() para no cerrar la ventana cuando imprima
     * */
    newWindow.onpageshow = function () {      
      newWindow.print();      
      //newWindow.close();
      };
  }

  Print() {
    
    if (this.IsBody)
      window.print();
    else
      this.PrintDiv(this.config.Container);
  }
  PrintNewWindow() {
    const elementContainer = (this.IsBody) ? document.body : document.getElementById(this.config.Container);
    const w = window.open();
    w.document.write(elementContainer.innerHTML);
    w.print();
    w.close();
  }
  GenerateImageScreenshot() {
    
    const elementContainer = (this.IsBody) ? document.body : document.getElementById(this.config.Container);

    
    html2canvas(elementContainer, {
      background: '#FFFFFF'
    }).then((canvas) => {

      if (navigator.userAgent.indexOf("MSIE ") > 0 ||
        navigator.userAgent.match(/Trident.*rv\:11\./)) {
        var blob = canvas.msToBlob();
        const _win = window.navigator as any;
        
        //window.navigator.msSaveBlob(blob, this.config.FileName + '.png');
       //window.navigator.msSaveBlob(blob, this.config.FileName + '.pdf');
       _win.navigator.msSaveBlob(blob, this.config.FileName + '.pdf');
      }
      else {
        

        canvas.toBlob((blob) => {
            
            //saveAs(blob, this.config.FileName + ".png");
            saveAs(blob, this.config.FileName + ".pdf");
        });

      }
    });

  }
  GeneratePdfScreenshot() {
    
    const elementContainer = (this.IsBody) ? document.body : document.getElementById(this.config.Container);
    
    html2canvas(elementContainer, {
      background: '#FFFFFF'
    }).then((canvas) => {

      if (navigator.userAgent.indexOf("MSIE ") > 0 ||
        navigator.userAgent.match(/Trident.*rv\:11\./)) {
        var blob = canvas.msToBlob();
        const _win = window.navigator as any;

        //window.navigator.msSaveBlob(blob, this.config.FileName + '.png');
        //window.navigator.msSaveBlob(blob, this.config.FileName + '.pdf');
        _win.navigator.msSaveBlob(blob, this.config.FileName + '.pdf');
      }
      else {
        var imgData = canvas.toDataURL("image/jpeg");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth / canvas.width);
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm');
        var position = 0;

        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {

          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }



        doc.save(this.config.FileName + ".pdf");

      }
    });


  }
}

