import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-authentication-mode',
  templateUrl: './authentication-mode.component.html',
  styleUrls: ['./authentication-mode.component.css']
})
export class AuthenticationModeComponent  implements OnInit {


  @Input() modelo: any;

  @Input() querys: Array<any> = [];
  @Input() itemsAplications: Array<any> = [];
  ngOnInit() {
  }

}
