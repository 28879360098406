import { Component, OnInit } from '@angular/core';
import { KpiEstadoSolicitudBaseComponent } from '../kpi-estado-solicitud-base/kpi-estado-solicitud-base.component';

@Component({
  selector: 'app-kpi-estado-solicitud-contenedor',
  templateUrl: './kpi-estado-solicitud-contenedor.component.html',
  styleUrls: ['./kpi-estado-solicitud-contenedor.component.scss']
})
export class KpiEstadoSolicitudContenedorComponent extends KpiEstadoSolicitudBaseComponent implements OnInit {
  items: Array<any> = [
    {
      'Label': 'Registro',
      'Total': '51',
      'Color': "#2ecc71",
      'Items': [{
        'Label': 'PQRD',
        'Total': '6'
      },{
        'Label': 'Radicado Entrada',
        'Total': '12'
      },{
        'Label': 'Radicado Salida',
        'Total': '1'
      },{
        'Label': 'Factura',
        'Total': '8'
      },{
        'Label': 'Factura 2',
        'Total': '9'
      },{
        'Label': 'Factura 3',
        'Total': '15'
      },{
        'Label': 'Factura 4',
        'Total': '6'
      }]
    },
    {
      'Label': 'Pendiente Firma',
      'Total': '12',
      'Color': "#9b59b6",
      'Items': [{
        'Label': 'PQRD',
        'Total': '6'
      },
      {
        'Label': 'Radicado Entrada',
        'Total': '6'
      },{
        'Label': 'Radicado Salida',
        'Total': '6'
      }]
    },
    {
      'Label': 'Reasignado',
      'Total': '3',
      'Color': "#f1c40f",
      'Items': [{
        'Label': 'PQRD',
        'Total': '3'
      }]
    },
    {
      'Label': 'Finalizado',
      'Total': '999999',
      'Color': "#e74c3c",
      'Items': [{
        'Label': 'PQRD',
        'Total': '333333'
      },
      {
        'Label': 'Radicado Entrada',
        'Total': '6'
      },{
        'Label': 'Radicado Salida',
        'Total': '6'
      },{
        'Label': 'Factura',
        'Total': '6'
      }]
    }
  ]
  ngOnInit() {

  }
  public CargarDatos(modelo?: any) {

    try {

      if (this.items && this.items.length > 0) {
        return
      }
      if (!this.QueryConfig)
        return;



      if (this.notLoad)
        return;


      var obj = this.utility.GetApiModel('CargarDatos', '');
      obj.QueryConfig = this.QueryConfig;

      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          processMessage: this.processMessage,
          method: 'OnInit'
        }, this.url, true, true).then(result => {

          this.items = result.Data;




        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'CargarDatos' });
        })



    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'CargarDatos' });
    }
  }

}
