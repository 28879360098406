import { Injectable, Component, ViewChild, OnInit, Input, AfterViewInit, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoggerService } from '../services/logger.service'
import { Utilities } from '../helpers/utilities'
import { StaticData } from '../helpers/static-data';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { FormGroup } from '@angular/forms';
import { QueryConfigIDETypes } from '../models/general.enum';

import { promise } from 'protractor';
import { Stopwatch } from "ts-stopwatch";

export class ResultObject {

  public Ok: boolean = true;
  public Error: string
  public CodeError: string
  public TrazaError: string
  public Data: Object
  public ValidationUI: string
  public IsAuthenticated: boolean
  public ResultValue: string;
  public InfoRule: any;
  public Metadata: any = {};
  public QMetadata: any = {};
}

export class DataTablesResponse extends ResultObject {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class BaseServiceService {

  public showLoadNotifyType: boolean = false;
  public notifyDelay: number = 0;
  ipAddress: string = '';
  constructor(public http: HttpClient, public logger: LoggerService, public utility: Utilities) {

    let that = this;
    logger.baseService = this;

  }

  //public modalNotify:ModalNotifyComponent;
  /**
   * Metodo para ejecucion de servicios de WebApi
   * @param obj modelo o entodad a enviar.
   * @param url url de accesoa al servicios WebApi.
   * @param form  FormGroup de la pagina que esta gestionado.
   * @param verModal especifica si se visualiza el modal de respuesta correcta o errores.
   * @param rechazar especifica si cuando hay un error se rechaza la promesa.
   * @param infoLog informacion de log de auditoria.
   * @param isFormData especifica si se trata como FormData o no.
   * @param notValidate especifica si se valida o no el formulario.
   * @param isLog especifica si el envio del servicio es para log, si lo es no se valida la propiedad Ok, se retorna todo el objeto devuelto.
   * @param async especifica si se trata o no como asincrono.
   * @param loadNotifyType especifica si se visauliza la notificacion de procesamiento.
   */
  public ExecuteService(obj: any,
    url: string,
    form: FormGroup,
    verModal: boolean,
    rechazar: boolean,
    infoLog: any = null,
    isFormData: boolean = false,
    notValidate: boolean = false, isLog: boolean = false, async: boolean = true,
    loadNotifyType?: string): Promise<any> {


    return new Promise((resolve, rejection) => {
      const that = this;
      var startProcess = new Date().getTime();
      const stopwatch = new Stopwatch();
      stopwatch.start();
      let notify: any = null;

      if (form !== undefined && form != null && !form.valid) {

        //deugger;
        // this.modalNotify.open(ModalNotifyTypes.Warning,{titulo:'Alerta',descripcion: 'El formulario tiene campos invalidos'});
        this.utility.VerModalWarning({
          titulo: 'Alerta Validacion Formulario',
          descripcion: 'El formulario tiene campos invalidos'
        }).then(value => { });
        //return;
        if (rechazar)
          rejection({ validacion: 'El formulario tiene campos invalidos' })

      }
      else {
        let urlServer = url;
        let mensaje = 'Procesando' + url;
        if (infoLog) {
          if (infoLog.processMessage)
            mensaje = infoLog.processMessage;
          else if (infoLog.componentName)
            mensaje = 'Procesando  ' + infoLog.componentName;
          else if (infoLog.controlName)
            mensaje = 'Procesando  ' + infoLog.controlName;
        }
        /*   if (!isLog && (loadNotifyType || this.showLoadNotifyType)) {
            notify = StaticData.ShowNotification(mensaje);
          } */

        if (!isLog) {
          if ((loadNotifyType == undefined && this.showLoadNotifyType) || loadNotifyType == 'ByControl' ||
            (loadNotifyType == null && this.showLoadNotifyType))
            notify = StaticData.ShowNotification(mensaje);

        }
        try {
          urlServer = StaticData.UrlServer + url;
          let _ok: boolean = true;
          if (!notValidate) {
            _ok = this.utility.ValidateUser(obj, isLog);
            if (!_ok) {
              if (rechazar)
                rejection({ validacion: 'Usuario No Autenticado' })

            }
          }
          // VER LOADING

          if (isFormData && _ok) {

          }
          else if (_ok) {
            this.http.post<ResultObject>(urlServer, obj, {}
            ).subscribe((resultObject: any) => {
              var endProcess = new Date().getTime();

              stopwatch.stop();

              that.EndNotify(notify);
              // OCULTAR LOADING
              let resultObjectClone = null;
              if (resultObject) {
                resultObjectClone = {
                  CodeError: resultObject.CodeError,
                  Error: resultObject.Error,
                  ExecutionObject: resultObject.ExecutionObject,
                  FrontEndTime1: resultObject.FrontEndTime1,
                  FrontEndTime2: resultObject.FrontEndTime2,
                  InfoRule: resultObject.InfoRule,
                  IsAuthenticated: resultObject.IsAuthenticated,
                  Metadata: resultObject.Metadata,
                  QMetadata: resultObject.QMetadata,
                  Ok: resultObject.Ok,
                  ResultValue: resultObject.ResultValue,
                  TrazaError: resultObject.TrazaError,
                  ValidationUI: resultObject.ValidationUI
                };
              }
              else
                this.logger.LogError('el objeto resultObject es nulo ', obj, infoLog, urlServer, stopwatch.getTime() + ' ms');

              if (isLog) {
                resolve(resultObject);
                return;
              }
              // var _titulo = (data.ValidationUI) ? 'Mensaje Alerta Validación' : 'Mensaje Error';
              resultObject['FrontEndTime1'] = this.utility.FormatStopWatch(endProcess - startProcess);
              resultObject['FrontEndTime2'] = this.utility.FormatStopWatch(stopwatch.getTime());
              resultObjectClone.FrontEndTime1 = resultObject.FrontEndTime1;
              resultObjectClone.FrontEndTime2 = resultObject.FrontEndTime2;

              if (resultObject.Ok) {
                if (resolve) {
                  if (verModal)
                    this.utility.VerModalOk().then(value => { });
                  resolve(resultObject);
                }
                else
                  this.utility.VerModalOk().then(value => { });
                console.log('Tiempo: ', (endProcess - startProcess), urlServer)
                if (!isLog)
                  this.logger.LogSuccess(resultObjectClone, obj, infoLog, urlServer, stopwatch.getTime() + ' ms');

              }
              else if (resultObject.IsAuthenticated) {

                this.utility.VerModalWarning({
                  titulo: 'No Autenticado',
                  descripcion: ((resultObject.ValidationUI) ? resultObject.ValidationUI : resultObject.Error)
                }).then(value => { });
                console.log('Tiempo: ', (endProcess - startProcess), urlServer)
                if (!isLog)
                  this.logger.LogWarning(resultObjectClone, obj, infoLog, urlServer, stopwatch.getTime() + ' ms');
                if (rechazar)
                  rejection(resultObject);
              } 
              else {
                let titulo = (resultObject.InfoRule) ? 'Excepción de Regla de Negocio' : 'Excepción de Negocio';

                // if (resultObject.ValidationUI) {
                //   this.utility.VerModalDanger({ titulo: titulo, descripcion: resultObject.ValidationUI }).then(value => { });
                // }

                if (rechazar) {
                  if(resultObject.CodeError && resultObject.CodeError == "warm"){
                    this.utility.VerModalWarning({
                      titulo: 'Advertencia', descripcion: resultObject.ValidationUI.replace(" - Código :warm", ""),
                      verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                    }).then(value => { });       
                  }
                  else if (resultObject.ValidationUI) {
                    this.utility.VerModalDanger({ titulo: titulo, descripcion: resultObject.ValidationUI }).then(value => { });
                  }
                  else
                    this.utility.VerModalError(resultObject.Error).then(value => { });
                  rejection(resultObject);
                }
                else {
                  if(resultObject.CodeError && resultObject.CodeError == "warm"){
                    this.utility.VerModalWarning({
                      titulo: 'Advertencia', descripcion: resultObject.ValidationUI.replace(" - Código :warm", ""),
                      verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                    }).then(value => { });       
                  }
                  else if (resultObject.ValidationUI) {
                    this.utility.VerModalDanger({ titulo: titulo, descripcion: resultObject.ValidationUI }).then(value => { });
                    //rejection(resultObject);
                  }
                  else
                    this.utility.VerModalError(resultObject.Error).then(value => { });
                }
                console.log('Tiempo: ', (endProcess - startProcess), urlServer)
                if (!isLog)
                  this.logger.LogError(resultObjectClone, obj, infoLog, urlServer, stopwatch.getTime() + ' ms');
              }

            }, error => {
              // OCULTAR LOADING
              var endProcess = new Date().getTime();

              that.EndNotify(notify);
              if (rechazar)
                rejection(error);
              console.log('Tiempo: ', (endProcess - startProcess), urlServer)
              if (!isLog)
                this.logger.LogError(error, obj, infoLog, urlServer, stopwatch.getTime() + ' ms');
              if (verModal) {
                if (error.message)
                  this.utility.VerModalError(error.message).then(value => { });
                else
                  this.utility.VerModalError().then(value => { });
              }
            });
          }
        }
        catch (error) {
          // OCULTAR LOADING
          var endProcess = new Date().getTime();
          stopwatch.stop();
          console.log('Tiempo: ', (endProcess - startProcess), urlServer)
          if (rechazar)
            rejection(error);
          if (!isLog)
            this.logger.LogError(error, obj, infoLog, urlServer, stopwatch.getTime() + ' ms');
          if (verModal) {
            if (error.message)
              this.utility.VerModalError(error.message).then(value => { });
            else
              this.utility.VerModalError().then(value => { });
          }

        }
      }
    });
  }
  /**
     * Metodo para jecucion de servicios de WebApi
     * @param obj modelo o entodad a enviar.
     * @param form  FormGroup de la pagina que esta gestionado.
     * @param infoLog informacion de log de auditoria.
     * @param isFormData especifica si se trata como FormData o no.
     * @param url url de accesoa al servicios WebApi.

     * @param verModal especifica si se visualiza el modal de respuesta correcta o errores.
     * @param rechazar especifica si cuando hay un error se rechaza la promesa.
     * @param isLog especifica si el envio del servicio es para log, si lo es no se valida la propiedad Ok, se retorna todo el objeto devuelto.

     */
  public Ejecutar(obj: any, form: FormGroup, infoLog: any, isFormData: boolean, url: string, verModal: boolean = false, rechazar: boolean = false, isLog: boolean = false): Promise<any> {
    url = '/api/' + url;
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, isFormData, false, isLog);
  };
  public Get(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = (url) ? url : 'Base';
    url = '/api/' + url + '/Get';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog);
  };
  public MultiGet(obj: any, form: FormGroup, infoLog: any = null, url: string = null,
    verModal: boolean = false, rechazar: boolean = false, loadNotifyType?: string): Promise<any> {
    url = (url) ? url : 'Base';
    url = '/api/' + url + '/MultiGet';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog,
      false,
      false,
      false, true, loadNotifyType);
  };
  public UpdateProperties(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/UpdateProperties';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog);
  };
  public InsertUpdate(obj: any, form: FormGroup, infoLog: any = null, isFormData: boolean = false, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/InsertUpdate';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, isFormData);
  };
  public MultiInsertUpdate(obj: any, form: FormGroup, infoLog: any = null, isFormData: boolean = false, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/MultiInsertUpdate';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, isFormData);
  };
  public Delete(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Delete';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog);
  };
  public MultiDelete(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/MultiDelete';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog);
  };
  public Active(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Active';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog);
  };
  public MultiActive(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/MultiActive';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog);
  };
  public Multi(obj: any, form: FormGroup, infoLog: any = null, isFormData: boolean = false, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Multi';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, isFormData);
  };
  public Login(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Login';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, false, true);
  };

  public LoadComplementaryData(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/LoadComplementaryData';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, false, true);
  };

  public CloseUserSession(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/CloseUserSession';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, false);
  };

  public GetUserSession(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/GetUserSession';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, false);
  };

  public RunRules(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/RunRules';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, false);
  };

  public ExecuteObject(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/ExecuteObject';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, false);
  };
  public Import(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Import';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog);
  };
  public Export(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Export';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog);
  };
  public GetTemplateForImport(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/GetTemplateForImport';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog);
  };
  public DeleteFile(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/DeleteFile';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, false);
  };
  //ExecuteObject

  public GetNames(obj: any, infoLog: any = null, verModal: boolean = false, rechazar: boolean = false, url: string = null,): Promise<any> {
    if (url)
      url = '/api/' + url + '/GetNames';
    else
      url = '/api/RConfiguracionGeneral/GetNames';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, false);
  };
  public GetMetadata(obj: any, infoLog: any = null, verModal: boolean = false, rechazar: boolean = false, url: string = null,): Promise<any> {
    if (url)
      url = '/api/' + url + '/GetMetadata';
    else
      url = '/api/RConfiguracionGeneral/GetMetadata';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, false);
  };

  public LoadCache(obj: any, infoLog: any = null, isFormData: boolean = false, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    const url = '/api/RParametroGeneral/LoadCache';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, isFormData);
  };

  public SaveToCache(obj: any, infoLog: any = null, isFormData: boolean = false, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    const url = '/api/RParametroGeneral/SaveToCache';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, isFormData);
  };

  public GetSystemFiles(obj: any, infoLog: any = null, isFormData: boolean = false, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    const url = '/api/RPagina/GetSystemFiles';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, isFormData);
  };
  public GetFileContent(obj: any, infoLog: any = null, isFormData: boolean = false, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    const url = '/api/RPagina/GetFileContent';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog, isFormData);
  };

  public SaveFileContent(obj: any, infoLog: any = null, isFormData: boolean = false, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    const url = '/api/RPagina/SaveFileContent';
    return this.ExecuteService(obj, url, null, verModal, rechazar, rechazar, infoLog, isFormData);
  };

  public SaveLog(obj: any, infoLog: any = null): Promise<any> {
    const url = '/api/Log/SaveLog';
    return this.ExecuteService(obj, url, null, false, false, infoLog, false, false, true);
  };
  public Download(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Download';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog);
  };

  public EjecutarPlantilla(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/EjecutarPlantilla';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog);
  };


  public DescargarIndice(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/DescargarIndice';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, false, true);
  };

  public GuardarComentario(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/GuardarComentario';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, false, true);
  };

  public DescargarTemplateBase64(obj: any, form: FormGroup, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/Documento/Get';
    return this.ExecuteService(obj, url, form, verModal, rechazar, infoLog, false, true);
  };

  //Export
  public Prueba(obj: any, infoLog: any = null, url: string = null, verModal: boolean = false, rechazar: boolean = false): Promise<any> {
    url = '/api/' + url + '/Prueba';
    return this.ExecuteService(obj, url, null, verModal, rechazar, infoLog);
  };
  public GetHttp(): HttpClient {
    return this.http;
  }
  public AjaxDataTables(urlServer: string, dttName: string, componentName: string, obj: any,
    isHtmlDataTable: boolean, asignarEventosEditarEliminar: (_context: any) => void, _contex: any,
    callbackOk: (context: any, items: any) => void, callbackError: (context: any, error: any) => void = null,
    useCallBack?: boolean, notLoad?: boolean, onLoadedEmitter?: EventEmitter<any>): (dataTablesParameters: any, callback) => void {
    const that = this;
    console.log('isHtmlDataTable', isHtmlDataTable);
    const startProcess = new Date().getTime();
    const stopwatch = new Stopwatch();
    stopwatch.start();
    let notify: any = null;
    let ajax = (dataTablesParameters: any, callback) => {



      let objTemp = _contex.GetDataTableFilter();
      let mensaje = (objTemp.processMessage) ? objTemp.processMessage : 'Consultando Datos de ' + componentName;
      if (objTemp.loadNotifyType == "ByControl")
        notify = StaticData.ShowNotification(mensaje);
      obj = objTemp.obj;
      obj.PaginationSettingsModel = dataTablesParameters;
      obj.PaginationSettingsModel.notLoad = objTemp.notLoad;
      obj.PaginationSettingsModel.StartIndexColumn = objTemp.startIndexColumn;
      obj.PaginationSettingsModel.ConfigColumns = objTemp.columns;
      obj.PaginationSettingsModel.DataTableAjaxOrderType = objTemp.dataTableAjaxOrderType;
      if (obj.QueryConfig && objTemp.outPutParam)
        obj.QueryConfig.OutPutParam = objTemp.outPutParam;
      if (_contex.IsSimpleSearch) {
        if (dataTablesParameters.search.value) {

          obj.PaginationSettingsModel.notLoad = false;
          _contex.config.ConfigControl.SimpleSearch.Fields.forEach(field => {
            obj.QueryConfig.Entity[field] = dataTablesParameters.search.value;
          });
        }
        else
          obj.PaginationSettingsModel.notLoad = true;
      }
      that.http
        .post<DataTablesResponse>(
          urlServer,
          obj, {}
        ).subscribe(resp => {
          const endProcess = new Date().getTime();
          stopwatch.stop();
          that.EndNotify(notify);

          let FrontEndTime1: any = this.utility.FormatStopWatch(endProcess - startProcess);
          let FrontEndTime2: any = this.utility.FormatStopWatch(stopwatch.getTime());

          _contex.processTime = {
            FrontEndTime1: FrontEndTime1,
            FrontEndTime2: FrontEndTime2,
            TiempoServer1: resp.Metadata['TiempoServer1'],
            TiempoServer2: resp.Metadata['TiempoServer2'],
            TiempoServer3: resp.Metadata['TiempoServer3'],
            QMetadata: resp.QMetadata,
          }
          if (resp.data) {
            if (isHtmlDataTable) {
              if (callbackOk)
                callbackOk(_contex, resp.data);
            }
            that.utility.logger.LogSuccess(resp.data, obj, {
              method: 'AjaxDataTables', componentName: componentName, controlName: dttName, url: urlServer
              , recordsTotal: resp.recordsTotal,
              paged: resp.data.length,
              processTime: _contex.processTime
            }, urlServer, stopwatch.getTime() + ' ms');
          }
          else {
            if (isHtmlDataTable) {
              if (callbackError)
                callbackError(_contex, []);
            }
            else {
              if (resp.CodeError != null && resp.CodeError == "0033") {
                this.utility.VerModalWarning({
                  titulo: 'Sesión Caducó', descripcion: (resp.ValidationUI) ? resp.ValidationUI : resp.Error,
                  verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
                }).then(value => { });
              }
              else {
                //debugger;
                if (resp.Error != "La secuencia no contiene elementos" && resp.Error != "Sequence contains no elements") {
                  this.utility.VerModalDanger({ titulo: 'Error consulta Grilla', descripcion: (resp.ValidationUI) ? resp.ValidationUI : resp.Error }).then(value => { });
                }
              }
            }
            that.utility.logger.LogError(resp, obj, {
              method: 'AjaxDataTables', componentName: componentName,
              Name: dttName, Url: urlServer,
              processTime: _contex.processTime
            }, urlServer, stopwatch.getTime() + ' ms');
          }
          console.log('AjaxDataTables Tiempo: ', (endProcess - startProcess));
          console.log('Response Api Tiempo: ', (resp.Metadata['TiempoServer2']));
          callback({
            recordsTotal: (resp.recordsTotal) ? resp.recordsTotal : 0,
            recordsFiltered: (resp.recordsFiltered) ? resp.recordsFiltered : 0,
            data: (isHtmlDataTable ? [] : ((resp.data) ? resp.data : []))
          });
          // data:(isHtmlDataTable?[]:((resp.data)?resp.data:[]))
          if (asignarEventosEditarEliminar)
            asignarEventosEditarEliminar(_contex);

          if (callbackOk && useCallBack)
            callbackOk(_contex, {});
          if (onLoadedEmitter.observers.length > 0)
            onLoadedEmitter.emit({ controlName: dttName, data: resp.data });



        }, error => {
          that.EndNotify(notify);
          var endProcess = new Date().getTime();
          stopwatch.stop();
          if (isHtmlDataTable) {
            if (callbackError)
              callbackError(_contex, error);
          }
          console.log('AjaxDataTables Tiempo: ', (endProcess - startProcess));
          that.utility.logger.LogError(error, obj, {
            method: 'AjaxDataTables',
            componentName: componentName, controlName: dttName, url: urlServer,
            processTime: _contex.processTime
          }, urlServer, stopwatch.getTime() + ' ms');
        });
    }

    return ajax;
  }


  public EndNotify(notify: any) {
    if (notify) {

      notify.close();
      setTimeout(() => {
        notify.close();
      }, 200);

    }
  }
  public ConsultaQueryConfigIDE(queryConfigIDEType: QueryConfigIDETypes,
    componentName: string, url: string, idParent?: number, isMulti: boolean = true,
    command: string = 'ConsultaDatosConfigIDE', processMessage?: string): Promise<any> {
    var obj = this.utility.GetApiModel('CargarDatos', '');
    obj.QueryConfig.Command = command;
    obj.QueryConfig.IsMulti = isMulti;
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = { QueryConfigIDEType: queryConfigIDEType };
    if (idParent) {
      obj.QueryConfig.Entity.IdParent = idParent;
    }
    return this.Get(obj, null,
      { componentName: componentName, processMessage: processMessage, method: 'ConsultaQueryConfigIDE' }, url, false)


  }

  public ConsultarEntidades(componentName: string, addSp?: Boolean, queryConfig?: any): Promise<any> {
    var obj = this.utility.GetApiModel('ConsultarEntidades', '');

    obj.QueryConfig = queryConfig;
    if (addSp) {
      if (!obj.DataAdd)
        obj.DataAdd = {};
      obj.DataAdd["AddSp"] = true;
    }
    return this.GetNames(obj,
      {
        componentName: componentName,
        processMessage: 'Cargando Entidades',
        method: 'ConsultarEntidades'
      }, true, true);


  }

  public getIPAddress(): Observable<any> {

    return this.http.get<any>(StaticData.ApiGetIpClient);

  }

  public executeServiceExtenalTemplate(obj: any): void {

    const startProcess = new Date().getTime();
    const stopwatch = new Stopwatch();
    stopwatch.start();

    this.http.post<any>(StaticData.UrlServiciosWebExterno, obj, {})
      .subscribe(resp => {
        const endProcess = new Date().getTime();
        stopwatch.stop();
        let FrontEndTime1: any = this.utility.FormatStopWatch(endProcess - startProcess);
        let FrontEndTime2: any = this.utility.FormatStopWatch(stopwatch.getTime());

        console.log('Tiempo servicio externo: ', (endProcess - startProcess));

      });

  }

}
