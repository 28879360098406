import { Component, EventEmitter, OnInit } from "@angular/core";
import {
  ControlActions,
  ControllerMethods,
  FormActionsThroughEmitters,
  GeneralPageControlTypes,
  TipoObjetoParaPermisos,
} from "src/app/models/general.enum";

import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from "src/app/components/controls/file-uploader/file-uploader.component";
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { QRCodeBoxComponent } from "src/app/components/controls/qrcode-box/qrcode-box.component";
import { SolicitudCommonComponent } from "../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { validateContact } from "../../../contacto/contacto.component";

@Component({
  selector: "app-solicitud-radicado-factura",
  templateUrl: "./solicitud-radicado-factura.component.html",
  styleUrls: ["./solicitud-radicado-factura.component.css"],
})
export class SolicitudRadicadoFacturaComponent extends SolicitudCommonComponent {
  IdTipoRadicacion: any;
  tipoSolicitud = "la Factura";
  UsuarioResponsable: any;
  GrillaAnexos: any;
  GrillVincularExpediente : any;
  GridViewFacturasAsignadas : any;
  ObservacionesAnulacion: any;
  openModalRadicadoFactura: boolean;

  public OnInitEnd() {

    // if(this.name == "SolicitudesAnulacionesFactura"){
    //     setTimeout(() => {
    //         document.getElementById("contentFile").style.display = "none";
    //     }, 100);
    // }

    this.postSaveEmitter.subscribe(event => {      
        setTimeout(() => { 
          super.Limpiar()
          this.MensajeSeleccionarContacto.Hidden = false;
          this.SetModelToControls();
        }, 2000);
		});
    super.OnInitEnd();
  }

  public PostNgAfterViewInit() {
    super.PostNgAfterViewInit();
    this.IdTipoRadicacion = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.IdTipoRadicacion;
      });
    this.GrillaAnexos = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.GrillaAnexos;
      });
    this.ObservacionesAnulacion = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == SolicitudCommonComponent.Controls.ObservacionesAnulacion;
      });
  }

  public async OnActionEventHandler(event) {

    switch (event.ControlType) {
      case GeneralPageControlTypes.Button: {
        switch (event.Name) {
          case SolicitudCommonComponent.Controls.BtnVincular:{
            this.modelo.modelo.IdEstadoSolicitud = 12
            var OperacionSolicitud = this.modelo.modelo.DataAdd
            if (this.modelo.selectedItems.length > 0){
              
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdExpediente',this.modelo.modelo.IdExpediente);
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdTipoDocumental', this.modelo.modelo.IdTipoDocumental);
              super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdEstadoSolicitud', this.modelo.modelo.IdEstadoSolicitud);
              
              const promises = this.modelo.selectedItems.map(async (objeto) => {
                this.modelo.modelo = objeto
                  this.modelo.modelo.DataAdd = OperacionSolicitud
            
                let obj: any = null;
                let _isEdit = this.IsEdit;
                let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
                obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
                await this.baseService.InsertUpdate(obj, null, {
                  componentName: this.componentName + 'Controller',
                  method: 'Guardar', processMessage: processMessage
                },
                false, this.componentName, true, true)
                })
                await Promise.all(promises).then(() => {
                  setTimeout(() => {
                    this.ReCargarGrilla()
                  }, 2000);
                });
            //   this.modelo.selectedItems.forEach((objeto) => {
            //     {
            //       this.modelo.modelo = objeto
            //       this.modelo.modelo.DataAdd = OperacionSolicitud
            // let obj: any = null;
            // let _isEdit = this.IsEdit;
            // // const that = this;
            // let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
            // obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
            // this.baseService.InsertUpdate(obj, null, {
            //   componentName: this.componentName + 'Controller',
            //   method: 'Guardar', processMessage: processMessage
            // },
            //   false, this.componentName, true, true)
            //       //super.SetModelToControls();
            //         //this._Guardar;
            //         //super._Guardar;
            //     }
                
            //     //if(objeto.)
            //   })
            // this.ReCargarGrilla()
            break;
            }
          }
          case SolicitudCommonComponent.Controls.BtnContactos: {
            if(!this.modalOpenContacto)
              this.VerBuscarContacto();
            else
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de contactos abierta." });
            break;
          }
          case SolicitudCommonComponent.Controls.BtnAsociarSolicitud: {
            if(!this.openModalRadicadoFactura)
              this.VerBuscarRadicadoFactura();
            else
              this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de Relacionar Radicado Factura abierta." });

            break;
          }
          case SolicitudCommonComponent.Controls.BtnDetalleSolicitud: {

            let obj = this.utility.GetApiModel("GetDetalleSolicitud", null);
            obj.QueryConfig = {
              Command: "ConsultaDetalleFacturaAsociada",
              IsSp: true,
              Entity: {
                IdEmpresa: -1,
                IdSolicitudRadicadoFacturaAsociado: this.modelo.modelo
                  .IdSolicitudRadicadoFacturaAsociado,
              },
            };

            let info = {
              componentName: this.componentName,
              controlName: "GetDetalleSolicitud",
              processMessage: "Cargando Detalle de la Solicitud......",
            };

            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {

                this.modeloDetalle = data.Data[0];
                if (
                  this.modeloDetalle.Anexos &&
                  this.modeloDetalle.Anexos != ""
                ) {
                  const Anexos = JSON.parse(this.modeloDetalle.Anexos);
                  let valorAnexos = "";
                  Anexos.forEach((anexo) => {
                    valorAnexos += anexo.Nombre + ": " + anexo.Cantidad + "\n";
                  });
                  this.modeloDetalle.Anexos = valorAnexos;
                }
                if (this.componentsContainer.FormsDetails.length > 0) {

                  let FormDetalleSolicitud = this.componentsContainer.Find(
                    "FormDetalleSolicitud"
                  );
                  FormDetalleSolicitud.modelo = this.modeloDetalle;
                }
              })
              .catch((err) => {});
            break;
          }
          case SolicitudCommonComponent.Controls.BtnGuardarComentario: {
            var lenComentario = this.modelo.modeloVirtual.Comentario;
            if (lenComentario != null && lenComentario.length > 0) {
              if (this.modelo.modelo.DataAdd) {
                this.modelo.modelo.DataAdd[
                  "TextoComentario"
                ] = this.modelo.modeloVirtual.Comentario;
                this.modelo.modelo.DataAdd["GuardaComentario"] = "true";
              } else {
                this.modelo.modelo.DataAdd = {
                  TextoComentario: this.modelo.modeloVirtual.Comentario,
                  GuardaComentario: "true",
                };
              }
              this.utility.VerModalConfirmacionGuardar().then((modelo) => {
                if (modelo.dialogResult == true) {
                  this.SetModelToControls();

                  this.modelo.modelo.Token = StaticData.Usuario.Token;
                  this.modelo.modelo.AppKey = StaticData.AppKey;
                  this.modelo.modelo.PkValue = this.modelo.modelo.IdSolicitudRadicadoEntrada;

                  let obj = this.utility.GetApiModel(
                    "GuardarComentario",
                    this.componentName,
                    null,
                    null,
                    this.modelo.modelo
                  );

                  this.baseService
                    .GuardarComentario(
                      obj,
                      null,
                      {
                        componentName: "SolicitudRadicadoFacturaComponent",
                        method: "GuardarComentario",
                        processMessage: "Procesando credenciales....",
                      },
                      "TSolicitudRadicadoFactura",
                      false,
                      true
                    )
                    .then((data) => {
                      this.modelo.modeloVirtual.Comentario = null;
                      this.SetModelToControls();

                      const tbGrillaComentario = this.componentsContainer.Tables.find(
                        (x) => {
                          return (
                            x.name == "DataTable_SolicitudFacturaComentario"
                          );
                        }
                      );
                      tbGrillaComentario.cargarGrilla = true;
                      tbGrillaComentario.ReCargarGrilla();

                      this.utility.VerModalOk(
                        "Se agregó comentario al radicado de factura: " +
                          "\n\r" +
                          this.modelo.modelo.Codigo
                      );
                    });
                }
              });
            } else {
              this.utility.VerModalDanger({
                titulo: "Valor Requerido",
                descripcion: "El campo comentario es requerido.",
              });
            }
          }
        }
        break;
      }
      case GeneralPageControlTypes.CommandButton: {
        debugger;
        this.calledCommandButton = event.Option.Name;
        let validacionCommandBar = event.Option.Name == SolicitudCommonComponent.CommandBarActions.Copiar ||
        event.Option.Name == SolicitudCommonComponent.CommandBarActions.CopiarPQRS ? "true" : "false";
        this.SetControlsValueDocument("IdUsuario", "required", validacionCommandBar);
        super.SetModelToControls();
        let validacionNuevo = event.Option.Name === "Nuevo" ? "true" : "false";
        //this.SetControlsValueDocument("NumeroFolios", "required", validacionNuevo)
        super.SetModelToControls();

        if (
          this.modelo.modelo.IdSolicitudRadicadoFacturaAsociado != null &&
          this.modelo.modelo.IdSolicitudRadicadoFacturaAsociado != ""
        ) {
          if (this.BtnDetalleSolicitud) {
            this.BtnDetalleSolicitud.Hidden = false;
          }
          if (this.BtnAsociarSolicitud) {
            this.BtnAsociarSolicitud.Hidden = true;
          }
        } else {
          if (this.BtnDetalleSolicitud) {
            this.BtnDetalleSolicitud.Hidden = true;
          }
          if (this.BtnAsociarSolicitud) {
            this.BtnAsociarSolicitud.Hidden = false;
          }
        }
        if (!this.modelo.modelo.IdUsuarioResponsable)
          this.modelo.modelo.IdUsuarioResponsable = this.UsuarioResponsable;
        if (this.IdContacto) {
          this.SetControlsValueDocument("IdContacto", "required", validacionNuevo)
          super.SetModelToControls();
        }
        //Ocultar BtnContactos y Mensaje
        if (this.BtnContactos && this.MensajeSeleccionarContacto) {
          if (
            event.Option.Name ===
            SolicitudCommonComponent.CommandBarActions.VerDetalle
          ) {
            this.modelo.modeloVirtual.ComentarioRadicado = this.modelo.selectedItem.Comentario;
            this.BtnContactos.Hidden = true;
            this.MensajeSeleccionarContacto.Hidden = true;
            //OCULTA BOTON DE TIPO ANEXOS (Adiccionar)
            if (
              document.getElementsByClassName(
                "float-right mb-4 ng-star-inserted"
              )[0]
            ) {
              (document.getElementsByClassName(
                "float-right mb-4 ng-star-inserted"
              )[0] as HTMLElement).style.visibility = "hidden";
            }
          } else {
            this.BtnContactos.Hidden = false;
            this.MensajeSeleccionarContacto.Hidden = false;
          }
        }
        if (this.IdTipoDocumental) {
          //let validacionIncluirEnExpediente = event.Option.Name === "IncluirEnExpediente" ? "true" : "false";
          let validacionIncluirEnExpediente = event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente ||event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpedienteMasivo  ? "true" : "false";
          //this.SetControlsValueDocument("IdTipoDocumental", "required", validacionIncluirEnExpediente)
          this.SetControlsValueDocument([SolicitudCommonComponent.Controls.IdTipoDocumental], "required", validacionIncluirEnExpediente)
          super.SetModelToControls();
          
          if(event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpedienteMasivo){
            this.BtnVincular.Hidden = false
            this.BtnGuardarVincular.HideSave = true
            super.SetModelToControls();
          }
          if(event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente){
            this.BtnVincular.Hidden = true
            this.BtnGuardarVincular.HideSave = false
            super.SetModelToControls();
          }
        }
        if (this.IdTipoRadicacion) {
          this.SetControlsValueDocument([
            "IdTipoRadicacion",
              "IdTemaSolicitud",
              "Asunto",
              "IdTipoMoneda",
              "Valor",
              "FechaEmision",
              "FechaVencimiento",
              "NumeroFactura",
              "NumeroContrato",
              "NumeroFolios",
              "ComentarioRadicado"], "enabled", validacionNuevo);
          super.SetModelToControls();
        }
        if(this.ObservacionesAnulacion){
          debugger
          let validacionSolicitarAnulacion = event.Option.Name === "SolicitarAnulacion" ? "true" : "false";
          this.SetControlsValueDocument("ObservacionesAnulacion", "required", validacionSolicitarAnulacion);
          this.SetModelToControls();
        }
        if (
          event.Option.Name ===
          SolicitudCommonComponent.CommandBarActions.Responder
        ) {
          if (
            this.modelo.modelo.IdExpediente == null ||
            this.modelo.modelo.IdExpediente == ""
          ) {
            this.utility.VerModalError(
              "La solicitud no posee un expediente asignado"
            );
            return;
          }
        }
        if (
          event.Option.Name ===
            SolicitudCommonComponent.CommandBarActions.Reasignar ||
          event.Option.Name ===
            SolicitudCommonComponent.CommandBarActions.SolicitarConcepto
        ) {
          this.modelo.modeloVirtual.ReasignacionJerarquica = StaticData.GeneralConfig.ConfigUI.ReasignacionJerarquica;
          this.modelo.modelo.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
          this.modelo.modelo.IdUsuarioResponsable = null;
          this.SetModelToControls();
        }
        if (
          event.Option.Name === SolicitudCommonComponent.CommandBarActions.Nuevo
        ) {
          this.InfoAnexos.Hidden = true;
          this.modelo.modeloVirtual.AnexosRequeridos = "ok";
          this.SetModelToControls();
          //Activa BOTON DE TIPO ANEXOS (Adiccionar)
          if (
            document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0]
          ) {
            (document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0] as HTMLElement).style.visibility = "visible";
          }
        }

        switch(event.Option.Name)
        {
            case SolicitudCommonComponent.CommandBarActions.Reasignar:
            {
              var GridName = "GrillaReasignarUsuario";
              var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
              this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);
              break;
            }
            case SolicitudCommonComponent.CommandBarActions.SolicitarConcepto:
            {
              var GridName = "GrillaUsuarioConcepto";
              var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
              this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);
              break;
            }
            case SolicitudCommonComponent.CommandBarActions.Copiar:
            {
              var GridName = "GrillaUsuarioCopia";
              var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
              this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);
              break;
            }
            case SolicitudCommonComponent.CommandBarActions.VerDetalle: {

              if (this.modelo.modelo.IdEstadoSolicitud !== 29){
                this.Oficio.isHidden = false;
              }
              else {
                this.Oficio.isHidden = true;
              }
              break;
            }
        }

        break;
      }
    }
    if (
      event.Table == "GrillaReasignarUsuario" ||
      event.Table == "GrillaUsuarioConcepto"
    )
      if (event.Action === FormActionsThroughEmitters.ClearModel) {
        this.modelo.modelo.IdUsuarioResponsable = null;
        this.SetModelToControls();
        return;
      }

    if (
      event.actionType &&
      event.actionType == "OnLoadedComboBoxExtendableTable"
    ) {

      this.errorTipoAnexo = false;
      this.countTipoAnexos = event.data.items.length;
      this.SetControlsValueDocument("AnexosRequeridos", "required", "true");
      this.modelo.modeloVirtual.AnexosRequeridos = null;
      this.InfoAnexos.Hidden = false;
      this.SetModelToControls();
      if (this.modelo.modeloVirtual.GrillaAnexos) {
        var numeroItems = this.modelo.modeloVirtual.GrillaAnexos.length - 1;
        if (event.data.items.length != numeroItems) {
          if (this.modelo.modeloVirtual.GrillaAnexos.length > 1) {
            for (var prop in this.modelo.modeloVirtual.GrillaAnexos) {
              if (Number(prop) < numeroItems) {
                this.errorTipoAnexo = false;
                if (
                  this.modelo.modeloVirtual.GrillaAnexos[prop].Nombre == null
                ) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexos.splice(
                    this.modelo.modeloVirtual.GrillaAnexos.length - 1
                  );
                  this.utility.VerModalDanger({
                    titulo: "Valor Requerido",
                    descripcion: "El campo Tipo anexo es requerido.",
                  });
                  break;
                }
                if (
                  this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad ==
                    null ||
                  !this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad
                ) {
                  this.errorTipoAnexo = true;
                  this.modelo.modeloVirtual.GrillaAnexos.splice(
                    this.modelo.modeloVirtual.GrillaAnexos.length - 1
                  );
                  this.utility.VerModalDanger({
                    titulo: "Valor Requerido",
                    descripcion:
                      "El campo Cantidad tiene que ser requerido o mayor a cero",
                  });
                  break;
                }
              }
            }
          }
          // if (!this.errorTipoAnexo) {

          //     this.modelo.modeloVirtual.GrillaAnexos.forEach(datamodelo => {
          //
          //         event.data.items.forEach(element => {
          //             if (datamodelo.TipoAnexo == element.Nombre) {
          //
          //                 const index = event.data.items.findIndex(x => { return x.Nombre == element.Nombre });
          //                 event.data.items.splice(index, 1);
          //             }
          //         });
          //     });
          // }
          if (this.countTipoAnexos == this.modelo.modeloVirtual.GrillaAnexos.length) {
            //setTimeout(function () {
              if (document.getElementsByClassName("float-right mb-4 ng-star-inserted")[0] != undefined) {
                (document.getElementsByClassName("float-right mb-4 ng-star-inserted")[0] as HTMLElement).style.visibility = "hidden";
              }
            //}, 1500);
          }
        }

        this.modelo.modeloVirtual.GrillaAnexos.forEach((datamodelo) => {

          event.data.items.forEach((element) => {
            if (datamodelo.Nombre == element.Nombre) {
              if (!this.errorTipoAnexo) datamodelo.Disabled = true;
              const index = event.data.items.findIndex((x) => {
                return x.Nombre == element.Nombre;
              });
              event.data.items.splice(index, 1);
            }
          });
        });
      }
    } else if (event.actionType == "EliminarItem") {
      if (event.config.Label == "Tipos Anexo") {
        setTimeout(function () {
          if (
            document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0] != undefined
          ) {
            (document.getElementsByClassName(
              "float-right mb-4 ng-star-inserted"
            )[0] as HTMLElement).style.visibility = "visible";
          }
        }, 1500);
      }
      var requeridoAnexosEliminacion = true;
      var requeridoAnexosControl = 1;
      this.modelo.modeloVirtual.GrillaAnexos.forEach((element) => {
        if (!element.Nombre || !element.Cantidad) {
          requeridoAnexosEliminacion = false;
          requeridoAnexosControl = null;
        }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    } else if (
      event.actionType &&
      event.actionType == "OnSelectedComboBoxExtendableTable"
    ) {
      // this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
      //     if (event.data.item && event.data.item.Nombre)
      //         if (element.Nombre == event.data.item.Nombre)
      //             if (element.Cantidad || element.Cantidad > 0)
      //             {
      //                 this.InfoAnexos.Hidden = true;
      //                 this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      //             }
      //             else
      //             {
      //                 this.InfoAnexos.Hidden = false;
      //                 this.modelo.modeloVirtual.AnexosRequeridos = null;
      //             }

      //         else
      //         {
      //             this.InfoAnexos.Hidden = false;
      //             this.modelo.modeloVirtual.AnexosRequeridos = null;
      //         }
      //     else
      //     {
      //         this.InfoAnexos.Hidden = false;
      //         this.modelo.modeloVirtual.AnexosRequeridos = null;
      //     }
      //     this.SetModelToControls();
      // });
      var requeridoAnexosEliminacion = true;
      var requeridoAnexosControl = 1;
      this.modelo.modeloVirtual.GrillaAnexos.forEach((element) => {
        if (event.data.item && event.data.item.Nombre) {
          if (element.Nombre == event.data.item.Nombre) {
            if (!element.Cantidad || element.Cantidad < 0) {
              requeridoAnexosEliminacion = false;
              requeridoAnexosControl = null;
            }
          }
        } else {
          requeridoAnexosEliminacion = false;
          requeridoAnexosControl = null;
        }
        // else {
        //   requeridoAnexosEliminacion = false
        //   requeridoAnexosControl = null
        // }
      });
      this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
      this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
      this.SetModelToControls();
    } else if (
      event.actionType &&
      event.actionType == "OnChangeNumberBoxExtendableTable"
    ) {
      if (
        event.data.valueMetodo &&
        event.data.valueMetodo.Nombre &&
        event.data.valueMetodo.Cantidad &&
        event.data.valueMetodo.Cantidad > 0
      ) {
        this.InfoAnexos.Hidden = true;
        this.modelo.modeloVirtual.AnexosRequeridos = "ok";
      } else {
        this.InfoAnexos.Hidden = false;
        this.modelo.modeloVirtual.AnexosRequeridos = null;
      }
      this.SetModelToControls();
    }

    if (event.Table == "GrillaAsignarExpediente")
      if (event.Action === FormActionsThroughEmitters.ClearModel) {
        this.modelo.modeloVirtual.NombreExpediente = null;
        this.modelo.modeloVirtual.CodigoExpediente = null;
        this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox([], true);
        this.SetModelToControls();
        return;
      }

    super.OnActionEventHandler(event);
  }

  /**
     *INICIO
     * 09/12/2020 16:46
     Edward

     Método que valida los controles con su respectivo required y commandBar
  public validateRequired(commandName: string) {
    const action = {
      ControlDetails: {
        MappingData: [],
        MappingFilter: [],
      },
      ControlType: "Action",
      ControlsForHidden: [],
      ControlsToUpdate: ["IdUsuario"],
      Guid: Guid.create().toString(),
      IsPost: false,
      IsParentModel: false,
      MappingValues: [],
      MappingComponents: [],
      Property: "required",
      PropertyValue:
        commandName == SolicitudCommonComponent.CommandBarActions.Copiar ||
        commandName == SolicitudCommonComponent.CommandBarActions.CopiarPQRS
          ? "true"
          : "false",
      ResultType: "Url",
      SendQueryString: false,
      TimerAction: 0,
      Type: ControlActions[ControlActions.SetControlProperty],
    };
    const context = this;
    this.helperActions.SetControlsValue(action, context);
    super.SetModelToControls();
  }*/
  /**
   * FIN
   */
  VerBuscarRadicadoFactura() {
    this.openModalRadicadoFactura = true;
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[2],
      limpiarInternamente: true,
      modelo: this.utility.Clone(this.modelo),
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Relacionar Radicado Factura";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility
      .OpenWindow(componentName, config, modeloTemp)
      .then((dataResult) => {
        dataResult.componentInstance.parentContext = this;//

        dataResult.componentInstance.postSeleccionItemEmitter.subscribe((result) => {

          //debugger;

          //debugger;
          if(!this.modelo.modeloVirtual["GridRadicadosAsociados"])
          this.modelo.modeloVirtual["GridRadicadosAsociados"] = new Array();

          if (result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("Codigo") &&
            !this.modelo.modeloVirtual["GridRadicadosAsociados"].find(x => { return x.Codigo == result.data.data.findItem.Codigo; }))
          {
            this.modelo.modeloVirtual["GridRadicadosAsociados"].push({ Id: result.data.data.findItem.IdSolicitud, Codigo: result.data.data.findItem.Codigo });
          }
          /*const CodigoRadicadoAsociado =
            result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("Codigo")
              ? result.data.data.findItem.Codigo
              : null;

          const IdSolicitud =
            result &&
            result.data &&
            result.data.data &&
            result.data.data.findItem &&
            result.data.data.findItem.hasOwnProperty("IdSolicitud")
              ? result.data.data.findItem.IdSolicitud
              : null;

          this.modelo.modeloVirtual.CodigoRadicadoAsociado =
            CodigoRadicadoAsociado ||
            dataResult.componentInstance.modelo.Codigo;
          this.modelo.modelo.IdSolicitudRadicadoFacturaAsociado =
            IdSolicitud || dataResult.componentInstance.modelo.IdSolicitud;

          this.BtnDetalleSolicitud.Hidden = false;
          */
          this.SetModelToControls();
        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(
          (result) => {
            if (result.Action === FormActionsThroughEmitters.ClearModel) {
              this.modelo.modeloVirtual.CodigoRadicadoAsociado = null;
              this.modelo.modelo.IdSolicitudRadicadoFacturaAsociado = null;
              this.SetModelToControls();
            }
          }
        );

        dataResult.componentInstance.loadedGridEmitter.subscribe(
          (result) => {}
        );

        dataResult.onClosePromise.then((dataResultOnClose) => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
          this.openModalRadicadoFactura = false;
        });
      });
  }

  ConvertAnexos() {
    if (
      this.modelo.modeloVirtual.GrillaAnexos &&
      this.modelo.modeloVirtual.GrillaAnexos.length > 0
    ) {
      this.modelo.modelo.Anexos = JSON.stringify(
        this.modelo.modeloVirtual.GrillaAnexos
      );
    }
  }

  _Guardar(form: FormGroup, configButtons?: any) {

    //debugger;
    if(this.modelo.modelo.IdEstadoPrevio)
    {
      this.modelo.modelo.Observaciones = null;
      if (this.modelo.modelo.DataAdd) {
        this.modelo.modelo.DataAdd[
          "IdEstadoPrevio"
        ] = this.modelo.modelo.IdEstadoPrevio;
      } else {
        this.modelo.modelo.DataAdd = {
          IdEstadoPrevio: this.modelo.modelo.IdEstadoPrevio
        };
      }
    }

    if(this.ObservacionesAnulacion)
    {
      if (this.modelo.modelo.ObservacionesAnulacion != "" && this.modelo.modelo.ObservacionesAnulacion != null) {
        this.modelo.modelo.Observaciones = this.modelo.modelo.ObservacionesAnulacion;
      }
    }

    this.HasFiles();
    this.ConvertAnexos();
    this.GetUsersCopy();
    super._Guardar(form, configButtons);
  }

  public OnDataTableEditHandler(event) { //

    //debugger;
    this.modelo.modeloVirtual.GridRadicadosAsociados = null;
    if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
      this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);
      /*this.modelo.modeloVirtual.GridRadicadosAsociados.forEach((element) => {
        element.Disabled = true;
        element.DisabledDelete = true;
      });*/
    }

    if (event.name == "GridViewRadicadosAsignados") {
      //debugger;
      const findItemClone = this.utility.Clone(event.data.data.findItem);
      this.modelo.selectedItem = findItemClone;

      let obj = this.utility.GetApiModel("GetEstadoPrevioSolicitud", null);
            obj.QueryConfig = {
              Command: "ConsultaEstadoPrevioSolicitud",
              IsSp: true,
              Entity: {
                TipoSolicitud: "Factura",
                IdSolicitud: this.modelo.selectedItem.IdSolicitudRadicadoFactura,
              },
            };

            let info = {
              componentName: this.componentName,
              controlName: "GetEstadoPrevioSolicitud",
              processMessage: "Cargando Estado previo de la Solicitud......",
            };

            this.baseService
              .Get(obj, null, info, this.componentName, false, false)
              .then((data) => {

                  this.modelo.modelo.IdEstadoPrevio = data.Data[0].IdEstadoSolicitud;
                  this.SetModelToControls();

              })
              .catch((err) => {});
    }

    this.UsuarioResponsable = this.modelo.modelo.IdUsuarioResponsable;
    if (event.modelo.modelo.Anexos) {
      this.modelo.modeloVirtual.GrillaAnexos = JSON.parse(
        event.modelo.modelo.Anexos
      );
      //Los elementos quedan desactivados (ineditables)
      this.modelo.modeloVirtual.GrillaAnexos.forEach((element) => {
        element.Disabled = true;
        element.DisabledDelete = true;
      });
    }
    super.OnDataTableEditHandler(event);
  }

  public Limpiar(callback: (data: any) => void = null, action?: any) {
    if(this.calledCommandButton && this.calledCommandButton == "IncluirenExpediente"){
      this.GrillVincularExpediente = this.componentsContainer.Tables.filter(
        (x) => x.name == "GrillaAsignarExpediente"
      )[0];
      this.GrillVincularExpediente.ReCargarGrilla();
      this.SetModelToControls();
    }if(this.calledCommandButton && this.calledCommandButton == "SolicitarAnulacion"){
      this.modelo.modelo.ObservacionesAnulacion=null
      this.SetModelToControls();
    }else{
      super.Limpiar();
    }
  }
}
