import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { ControlActions, ControllerMethods, EstadosSolicitud, FormActionsThroughEmitters, TipoObjetoParaPermisos, TiposConsecutivo } from "src/app/models/general.enum";

import { AnimationMetadataType } from "@angular/animations";
import { BaseServiceService } from "src/app/services/base-service.service";
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from 'src/app/components/controls/file-uploader/file-uploader.component';
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { HelperActions } from "src/app/helpers/helper-actions";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { SolicitudCommonComponent } from "../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { Utilities } from "src/app/helpers/utilities";
import { validateContact } from "../../../contacto/contacto.component";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { DataTableDirective } from "angular-datatables";
import { CheckBoxRComponent } from "src/app/components/controls/check-box-r/check-box-r.component";
import { CheckBoxComponent } from "src/app/components/controls/check-box/check-box.component";

declare let fabric: any;

@Component({
    selector: "app-flujo-documentos",
    templateUrl: "./flujo-documentos.component.html",
    styleUrls: ["./flujo-documentos.component.css"]
})
export class FlujoDocumentosComponent extends SolicitudCommonComponent implements OnInit {


    IdCausalRechazo: any;
    IdCuaderno: any;
    crudButtonGestionVoBo: any;
    Observaciones: any;
    Tipofirma: any;
    TipofirmaEnvio: any;
    configPin: any;
    FiltroFirma: any;
    TipoFiltro: any
    configArchivos: FileUploaderComponent;
    GridViewAprobacionDocumento: any;
    GridViewVistaBuenoDocumento: any;
    DivInfoFirma: any;
    modalOpenUsuario: boolean;
    UsuariosCopias: any;

    SinFirmaDigital: any = null;
    FirmaDigital: any;
    FirmaElectronica: any;

    usuariosIntervinientes: any;
    selectedItems: any;


    constructor(public baseService: BaseServiceService, public utility: Utilities,
        public helperImages: HelperImages,
        public helperRules: HelperRules,
        public helperActions: HelperActions) {
        super(baseService, utility, helperImages, helperRules, helperActions);

    }

    public OnInitEnd() {

        this.SinFirmaDigital = StaticData.GeneralConfig.ConfigUbicaciones.find(x => { return x.IdTipoSolicitud == 29; }).ProcesarSinFirmaDigital;
        this.FirmaDigital = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "FirmaDigital" });
        this.FirmaElectronica = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "FirmaElectronica" });
        this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Pagina);

        this.modelo.modelo.AreaEmpresaResponsable = StaticData.Usuario.AreaEmpresa;
        this.modelo.modelo.IdAreaEmpresaResponsable = StaticData.Usuario.IdAreaEmpresa;
        this.SetModelToControls();
    }

    /* public OnSelectedComboBox(item: any, controlName: string, config: any) {
        debugger

        if (controlName == "UsuariosResponsables") {
            if(this.modelo.modeloVirtual["UsuariosIntervinientes"].length < 10 )
            {
                this.modelo.modeloVirtual["UsuariosIntervinientes"].push({ IdUsuario: item.IdUsuario, Nombre: item.Nombre, Email: item.Email, Celular: item.Celular, NotificarEmail: false, Aprobador: false });
                super.SetModelToControls();
                this.ValidaOrdenFlujo();
                this.validaAprobador();
            }
            else
                this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'Máximo permitido de usuarios (10)' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });

        }
        super.OnSelectedComboBox(item, controlName, config);
    } */


    public OnDataTableEditHandler(event) {

        super.OnDataTableEditHandler(event);
    }

    public ValidaOrdenFlujo() {
        var AlertaOrdenFlujo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AlertaOrdenFlujo"; });
        if (this.modelo.modeloVirtual["UsuariosIntervinientes"].length > 0) {
            this.modelo.modeloVirtual["UsuariosIntervinientes"].forEach(element => {
                if (this.modelo.modeloVirtual.ValidacionOrdenFlujo != null) {

                    if (element.OrdenFlujo && element.OrdenFlujo != null && element.OrdenFlujo != "") {
                        this.modelo.modeloVirtual.ValidacionOrdenFlujo = "ok";
                        AlertaOrdenFlujo.HiddenByDefault = true
                    }
                    else {
                        this.modelo.modeloVirtual.ValidacionOrdenFlujo = null;
                        AlertaOrdenFlujo.HiddenByDefault = false
                        AlertaOrdenFlujo.Msg = "Verifique el orden del flujo";
                    }
                }
            });
        }
        else {
            this.modelo.modeloVirtual.ValidacionOrdenFlujo = null;
            AlertaOrdenFlujo.HiddenByDefault = false
            AlertaOrdenFlujo.Msg = "Verifique el orden del flujo";
        }
        super.SetModelToControls();

    }

    public validaAprobador() {
        var AlertaAprobador = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AlertaAprobador"; });
        const _indexAprobador = this.modelo.modeloVirtual["UsuariosIntervinientes"].findIndex(x => { return x.Aprobador == true; });
        if (_indexAprobador != -1) {
            this.modelo.modeloVirtual.ValidacionAprobador = "ok";
            AlertaAprobador.HiddenByDefault = true
            this.validaOrganizacionAprobador(null);
        }
        else {
            this.modelo.modeloVirtual.ValidacionAprobador = null;
            AlertaAprobador.HiddenByDefault = false
            AlertaAprobador.Msg = "Verifique el orden del flujo";
        }
        super.SetModelToControls();
    }

    public validaOrganizacionAprobador(ArrayUsuarios: any) {
        debugger
        const UsuariosOriginales = this.utility.Clone(this.modelo.modeloVirtual["UsuariosIntervinientes"]);
        var AlertaOrdenAprobador = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AlertaOrdenAprobador"; });
        if (ArrayUsuarios == null)
            ArrayUsuarios = this.modelo.modeloVirtual["UsuariosIntervinientes"]
        const tamañoArrayUser = ArrayUsuarios.length
        const _indexAprobador = ArrayUsuarios.findIndex(x => { return x.OrdenFlujo == tamañoArrayUser && x.Aprobador == true; });
        if (_indexAprobador != -1) {
            ArrayUsuarios.splice(_indexAprobador, 1);
            this.modelo.modeloVirtual.ValidacionAprobador = "ok";
            AlertaOrdenAprobador.HiddenByDefault = true
            const _indexAprobadorSecond = ArrayUsuarios.findIndex(x => { return x.Aprobador == true; });
            if (_indexAprobadorSecond != -1)
                this.validaOrganizacionAprobador(ArrayUsuarios)

            this.modelo.modeloVirtual["UsuariosIntervinientes"] = UsuariosOriginales;
        }
        else {
            this.modelo.modeloVirtual.ValidacionAprobador = null;
            AlertaOrdenAprobador.HiddenByDefault = false
        }
        super.SetModelToControls();
    }

    public OnActionEventHandler(event) {
        /*if (event.controlName && event.controlName == "FirmaElectronica") {
            if (this.modelo.modelo.FirmaElectronica)
                this.modelo.modelo.FirmaDigital = false
            else
                this.modelo.modelo.FirmaDigital = true

            this.SetModelToControls();
        }
        if (event.controlName && event.controlName == "FirmaDigital") {
            if (this.modelo.modelo.FirmaDigital)
                this.modelo.modelo.FirmaElectronica = false
            else
                this.modelo.modelo.FirmaElectronica = true

            this.SetModelToControls();
        }*/
        if (event.actionType && event.actionType == "OnLoadedComboBoxExtendableTable") {
            debugger
        }
        if (event.actionType && event.actionType == "OnChangeCheckBoxxtendableTable") {
            debugger;

            if(event.actionName == "Aprobador" && event.data.Aprobador)
            {
                let obj = this.utility.GetApiModel('ValidateSignPermission', null)
                obj.QueryConfig = {
                    Command: 'ValidateSignPermission',
                    IsSp: true,
                    Entity: {
                        IdUsuarioInterviniente: event.data.IdUsuario
                    }
                };
                let info = {
                    componentName: this.componentName, controlName: 'ValidateSignPermission',
                    processMessage: 'Validando Permisos......'
                };

                this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

                    debugger;
                    var PuedeFirmar = data.Data[0]['PermiteFirmar'];
                    if(PuedeFirmar == 0)
                    {
                        event.data.Aprobador = false;
                        this.utility.VerModalWarning({ titulo: 'Verificación autorización firma', descripcion: 'El usuario no tiene autorización para firmar flujos electrónicos' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                    }                        
                    this.validaAprobador();

                }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });
            }
            else
            {
                this.validaAprobador();
            }
            
        }

        if (event.actionType && event.actionType == "OnSelectedComboBoxExtendableTable") {
            if (!event.data.item) {
                this.ValidaOrdenFlujo();
            }
            else {
                if (event.data.item > this.modelo.modeloVirtual["UsuariosIntervinientes"].length)
                    event.data.item = this.modelo.modeloVirtual["UsuariosIntervinientes"].length.toString()

                const _indexequal = this.modelo.modeloVirtual["UsuariosIntervinientes"].findIndex(x => { return x.OrdenFlujo == event.data.item; });
                if (_indexequal != -1) {
                    this.utility.VerModalWarning({ titulo: 'Verificacion del orden', descripcion: 'El orden seleccionado ya existe en el flujo' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                }
                else {
                    this.modelo.modeloVirtual["UsuariosIntervinientes"][event.data.index].OrdenFlujo = event.data.item
                    this.modelo.modeloVirtual.ValidacionOrdenFlujo = "ok";
                    this.ValidaOrdenFlujo();
                    this.validaAprobador();
                }
            }
        }
        if (event.actionType && event.actionType == "EliminarItem") {
            this.modelo.modeloVirtual.ValidacionOrdenFlujo = "ok";
            this.ValidaOrdenFlujo();
            this.validaAprobador();
        }
        switch (event.ControlType) {
            case "Button": {
                switch (event.Name) {
                    case "SelectUser": {
                        if(!this.modalOpenUsuario)
                            this.BuscarUsuario();                       
                        else
                            this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de usuarios." });                    
                        break;
                    }
                }
                break;
            }
            case "CommandButton": {
                this.FirmaDigital.Hidden = this.SinFirmaDigital != true ? false : true;
                this.FirmaElectronica.Hidden = this.SinFirmaDigital != true ? false : true;
                switch (event.Option.Name) {
                    case "Nuevo":
                        this.modelo.modelo.FirmaElectronica = false;
                        this.modelo.modelo.FirmaDigital = true;
                        this.SetControlsValueDocument("FirmaDigital", "enabled", "false");
                        this.SetControlsValueDocument("UsuarioExterno", "enabled", "false");
                        this.SetModelToControls();
                        /* this.configs.ConfigUsuariosResponsables.Params = { IdAreaEmpresaResponsable: 0, IdEmpresa: -1 };
                        this.configs.ConfigUsuariosResponsables.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true); */
                        break;
                    case "Editar":
                        /* this.configs.ConfigUsuariosResponsables.Params = { IdAreaEmpresaResponsable: this.modelo.modelo.IdAreaEmpresaResponsable, IdEmpresa: -1 };
                        this.configs.ConfigUsuariosResponsables.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true); */
                        this.configArchivos.RaiseForceValidateSaveEvent(true, false);
                        if (this.modelo.modelo.Configuracion) {
                            var Config = JSON.parse(this.modelo.modelo.Configuracion);
                            this.modelo.modeloVirtual["UsuariosIntervinientes"] = Config.UsuariosIntervinientes;
                            this.modelo.modeloVirtual.ValidacionOrdenFlujo = "ok";
                            this.SetModelToControls();
                            this.ValidaOrdenFlujo();
                            this.validaAprobador();
                        }
                        break;
                }
            }
        }
        super.OnActionEventHandler(event);
    }

    BuscarUsuario() {

        this.modalOpenUsuario = true;

        debugger
        const modeloTemp = {
            configPermisos:{
                TipoObjetoParaPermiso: TipoObjetoParaPermisos.General,
                Pagina: this.Pagina, clear: true, CreatedRowConfig: {}
            },
            config: this.Pagina.ConfigPage.HiddenControls[0],
            limpiarInternamente: true
        };

        const componentName = "DataTableComponent";
        const config: ConfigWindow = new ConfigWindow();
        config.classWindow = "Info";
        config.titleWindow = "Selección de Usuarios";
        config.returnInstance = true;
        config.modal = false;
        config.width = 750;
        config.height = 450;
        config.viewOk = false;
        config.cancel = "Cerrar";
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;
        debugger
        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {
                var AlertaOrdenFlujo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AlertaOrdenFlujo"; });
                dataResult.componentInstance.parentContext = this;

                dataResult.componentInstance.postSeleccionItemEmitter.subscribe((result) => {
                    
                    if(result.data.data.column.CheckBoxCol.Value)
                    {
                        if(this.modelo.modeloVirtual["UsuariosIntervinientes"].length < 10 )
                        {
                            this.modelo.modeloVirtual["UsuariosIntervinientes"].push({ IdUsuario: result.data.data.findItem.IdUsuario, TipoIdentificacion: result.data.data.findItem.TipoIdentificacion, Identificacion: result.data.data.findItem.Identificacion, Nombre: result.data.data.findItem.Nombre, Email: result.data.data.findItem.Email, Celular: result.data.data.findItem.Celular, NotificarEmail: false, Aprobador: false, TipoInterviniente: 'usuario' });
                            this.usuariosIntervinientes = this.modelo.modeloVirtual["UsuariosIntervinientes"];
                            this.selectedItems = dataResult.componentInstance.selectedItems;
                            super.SetModelToControls();
                            this.ValidaOrdenFlujo();
                            this.validaAprobador();
                        }
                        else
                            this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'Máximo permitido de usuarios (10)' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                    }                    

                });
                
                /* dataResult.componentInstance.postEditEmitter.subscribe(result => {
                    this.modelo.modeloVirtual["UsuariosIntervinientes"] = new Array;
                    AlertaOrdenFlujo.HiddenByDefault = false
                    AlertaOrdenFlujo.Msg = "Verifique el orden del flujo";
                    this.modelo.modelo.AreaEmpresaResponsable = result.modelo.Nombre
                    this.modelo.modelo.IdAreaEmpresaResponsable = result.modelo.IdAreaEmpresa
                    this.SetModelToControls();
                    this.configs.ConfigUsuariosResponsables.Params = { IdAreaEmpresaResponsable: this.modelo.modelo.IdAreaEmpresaResponsable, IdEmpresa: -1 };
                    this.configs.ConfigUsuariosResponsables.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);

                }); */
                dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
                    if (result.Action === FormActionsThroughEmitters.ClearModel) {
                        //this.modelo.modeloVirtual["UsuariosIntervinientes"] = new Array;
                        this.modelo.modeloVirtual["UsuariosIntervinientes"] = this.usuariosIntervinientes;
                        dataResult.componentInstance.selectedItems = this.selectedItems;
                        AlertaOrdenFlujo.HiddenByDefault = false
                        AlertaOrdenFlujo.Msg = "Verifique el orden del flujo";
                        //this.modelo.modelo.AreaEmpresaResponsable = null;
                        //this.modelo.modelo.IdAreaEmpresaResponsable = null;
                        this.SetModelToControls();
                    }
                });
                dataResult.componentInstance.loadedGridEmitter.subscribe(result => {


                });

                dataResult.onClosePromise.then(dataResultOnClose => {
                    dataResult.componentInstance.ngOnDestroy();
                    dataResult.componentInstance = null;
                    this.modalOpenUsuario = false;
                });
            });

    }

    public OnCreatePermissionsConfig(TipoObjeto: any) {

        this.permissionsConfig = {
            TipoObjetoParaPermiso: TipoObjeto,
            Pagina: this.Pagina, clear: true, expediente: null, CreatedRowConfig: {}
        };
        this.RaiseFormEvent('OnCreatePermissionsConfig', true);
    }

    public Limpiar() {
       
        super.Limpiar()
        this.modelo.modelo.AreaEmpresaResponsable = StaticData.Usuario.AreaEmpresa;
        this.modelo.modelo.IdAreaEmpresaResponsable = StaticData.Usuario.IdAreaEmpresa;
        this.modelo.modeloVirtual.UsuariosIntervinientes = new Array;
        this.SetModelToControls();
        this.ValidaOrdenFlujo();
        this.validaAprobador();
    }

    _Guardar(form: FormGroup, configButtons?: any) {

        var IdUsuarioGestionActual = this.modelo.modelo.IdUsuarioGestionActual;
        var Config = JSON.parse(this.modelo.modelo.Configuracion);
        if (!Config)
            Config = {};
        debugger;
        Config.UsuariosIntervinientes = this.modelo.modeloVirtual.UsuariosIntervinientes;
        this.modelo.modelo.Configuracion = JSON.stringify(Config);
        this.modelo.modelo.FirmaElectronica = this.modelo.modelo.FirmaElectronica == "" ? false : this.modelo.modelo.FirmaElectronica;
        this.modelo.modelo.UsuarioExterno = this.modelo.modelo.UsuarioExterno == "" ? false : this.modelo.modelo.UsuarioExterno;
          let obj: any = null;
        try {
            if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
                return;
            if (form.invalid)
                return;


            const _isEdit = this.IsEdit;
            // const that = this;
            const processMessage = (this.modelo.modelo.PkValue == "0" || this.modelo.modelo.PkValue == "") ? "Creando Registro de " + this.Pagina.Titulo : "Actualizando registro de " + this.Pagina.Titulo;

            if (configButtons && configButtons.Actions) {

                this.helperActions.ExecuteActionsControl(configButtons,
                    false, null, this.modelo, this.permissionsConfig,
                    this, null, new EventEmitter<any>(), this.componentsContainer,
                    null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
            }

            this.ExcecuteFuntion(ControllerMethods.Save, false);


            if (_isEdit) {
                this.FireFormEvent({ Name: "OnPreUpdate", modelo: this.modelo });
                this.modelo.modelo.IdUsuarioResponsable =
                    this.modelo.modelo.IdUsuarioResponsable ||
                    StaticData.Usuario.IdUsuario;
            }
            else {
                this.FireFormEvent({ Name: "OnPreInsert", modelo: this.modelo });
                this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
            }

            obj = this.utility.GetApiModel("Guardar", this.componentName, null, null, this.modelo.modelo);

            let isGeneralSave = true;
            if (this.componentsContainer.FileBox && this.componentsContainer.FileBox.isSelectedFiles()) {

                isGeneralSave = false;
                let externalFiles: any;
                if (this.componentsContainer.FileBoxWorkFlow != null) {
                    externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
                }
                var principal = this.componentsContainer.FileBox.modeloArchivo.find(x => { return x.EsAdjunto == false; });
                var ConfigSolicitudDocumentos = StaticData.GeneralConfig.ConfigUbicaciones.find(x => { return x.IdTipoSolicitud == TiposConsecutivo.SolicitudDocumentos; });
                if (_isEdit && principal && ConfigSolicitudDocumentos.ConfigValidacionDocumento.PermitirActualizarDocumento) {

                    // if (this.modelo.modelo.Extension && this.documentoComponente.principalFile) {
                    let titleWindow: string = "Desea Actualizar el archivo principal del documento?";
                    let modeloInterno: any = { VersionModelData: { ObservacionesModificacion: '' } };
                    let config: ConfigWindow = new ConfigWindow();
                    config.classWindow = 'warning';
                    config.titleWindow = titleWindow;
                    config.returnInstance = false;
                    config.modal = true;
                    config.width = 750;
                    config.height = 400;
                    config.extra = 'Crear Nueva Version';
                    config.ok = 'Actualizar Archivo';
                    config.extraValue = 'extra';
                    config.Okextra = 'Cargar Documentos como adjuntos';
                    config.viewOkExtra = true;
                    // config.viewOkExtra = false;
                    config.OkextraValue = "okExtra";
                    let windowModel: any = {
                        modelo: modeloInterno.VersionModelData,
                        config: this.utility.GetConfigObservacionesVersion()
                    };

                    this.utility.OpenWindow('ObservacionesModificacionVersionComponent',
                        config, windowModel).then(dataResult => {
                                dataResult.onClosePromise.then(dataResultOnClose => {
                                debugger;
                                let limpiar = true;
                                if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {
                                    if (dataResultOnClose.modelo.ObservacionesModificacion) {
                                        obj.DataAdd = { FileModelType: 'Documents', Actualizar: true }
                                        obj.Observaciones = dataResultOnClose.modelo.ObservacionesModificacion;
                                        this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
                                    }
                                    else
                                        this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                                }
                                else if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == 'okExtra') {
                                    if (dataResultOnClose.modelo.ObservacionesModificacion) {
                                        obj.DataAdd = { FileModelType: 'Documents', Actualizar: true, Adjuntos: true }
                                        obj.VersionModelData = dataResultOnClose.modelo;
                                        this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
                                    }
                                    else
                                        this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });

                                }
                                else
                                {
                                    this.SetProcesando(false);
                                }
                            });

                        })
                }
                else {
                    this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
                    let validacionFileUploader = true;
                    this.configArchivos.RaiseForceValidateSaveEvent(!validacionFileUploader, validacionFileUploader);
                }
            }


            // ////////Meter validacion antes de este
            if (isGeneralSave) {

                this.baseService.InsertUpdate(obj, form, {
                    componentName: this.componentName + "Controller",
                    method: "Guardar", processMessage: processMessage
                },
                    false, this.componentName, false, true).then(data => {
                        //this.modelo.modelo = data.Data != null ? data.Data : this.modelo.modelo;

                        this.FinGuardar(data.Data);
                        if (_isEdit)
                            this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
                        else
                            this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });

                        // this.utility.VerModalOk(`Proceso realizado correctamente para el Radicado de salida con código: ${data.Data.Codigo}`).then(() => true);
                        // this.solicitudEntrada = null;
                        this.OnInitEnd();
                    }).catch(error => { this.SetProcesando(false); });
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: "_Guardar" });
            this.utility.VerModalDanger().then(data => { });
            this.SetProcesando(false);
        }
    }
    //JS Se agrega para validar requerido control fileuploader 
    public AddComponentInstanceHandler(event) {

       if (event instanceof FileUploaderComponent) {

            this.configArchivos = event;
        }
        super.AddComponentInstanceHandler(event);
    }
}
