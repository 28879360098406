
import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  OnDestroy,
  QueryList, forwardRef, Inject, DebugElement
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, DataTableColumnTypes, DataTableAjaxOrderTypes,
  DataTableActions,
  TipoParaPermisos,
  FormActionsThroughEmitters,
  TipoObjetoParaPermisos
} from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { ComboBoxManager } from '../../../bussines/combo-box-manager';
import { ConfigWindow } from '../../../models/config-window';
import { UtilitiesColumns } from '../../../helpers/utilities-columns';
import { BaseComponentComponent } from '../../../components/base-component/base-component.component';
import { GeneralBaseDataTable } from 'src/app/models/general-base-data-table';
import { FormDetailsComponent } from '../form-details/form-details.component';
import { ComponentsContainer } from 'src/app/models/components-container';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { DataTableMosaicComponent } from '../data-table-mosaic/data-table-mosaic.component';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { Guid } from 'guid-typescript';
import { HelperCharts } from 'src/app/helpers/helper-charts';
import { TranslateService } from '@ngx-translate/core';
//import { SolicitudCommonComponent } from '../../bussines/solicitudes/common/solicitud-common.component';
//import { HelperActionColumn } from 'src/app/helpers/helper-action-column';
declare var fabric: any;

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
/* {
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityColumns: UtilitiesColumns) {

    super(baseService,utility,utilityColumns);



  }
} */ {
  @Input() public isModelContainer: boolean = false;
  @Input() public parentCallRefresh: Subject<any>;
  @Input() public parentClearCall: Subject<any>;

  @ViewChild(DataTableMosaicComponent, { static: false }) dataTableMosaic: DataTableMosaicComponent;
  @ViewChildren(DataTableDirective) dtElement: QueryList<DataTableDirective>;

  @Output() onInitialized = new EventEmitter<any>();
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() childComponents: Array<any> = [];
  @Input() cargarGrilla: boolean = true;
  @Input() containerComponentName: string;
  @Input() pkName: string = null;
  @Input() pkValueEdit: any;
  @Input() modelo: any;
  @Input() modeloClone: any = null;
  @Input() modeloMetadata: any;
  @Input() parentContext: any;

  @Input() modeloEdit: any
  @Input() isHtmlDataTable: boolean = false;
  @Input() hideForm: boolean = false;
  @Input() hideDataTable: boolean = false;
  @Input() public notView: boolean = false;
  @Input() Pagina: any = { Valida: true, ConfigPage: { ExtraControls: [], Controls: [] } };
  @Input() childOptions: any = null;
  @Input() componentsContainer: ComponentsContainer;




  @Input() public exportar: string = null;
  @Input() url: string = null;

  @Input() public SearchTagRecordManagement: string = null;
  @Input() public MsgValidationRecordManagement: string = null;
  @Input() public MsgReleaseRecordManagement: string = null;
  @Input() public MsgBusyRecordManagement: string = null;
  currentBusyRecord: any

  @Input() externalFilter: any = null;
  @Input() filterParent: any = null;
  @Input() mappingFilter: any = null;
  @Input() generalFilter: any = null;
  isModal: boolean = false;
  isLoaded: boolean = false;
  filterModelTemp: any = null;
  @Input() parentOption: any = null;
  @Input() idGrillaQuery: any = undefined;
  public filterModal: any = null;
  dtOptions: any = {};


  @Input() config: any = null;
  @Input() columns: any = [];
  @Input() conFiltroIdEmpresa: boolean = true;
  @Input() QueryConfig: any = null;

  @Input() withPagination: boolean = false;

  @Input() dataTableAjaxOrderType: DataTableAjaxOrderTypes = DataTableAjaxOrderTypes.Default;
  @Input() outPutParam: boolean = false;
  @Input() processTime: any = {
    FrontEndTime1: '',
    FrontEndTime2: '',
    TiempoServer1: '',
    TiempoServer2: '',
    TiempoServer3: ''
  }

  botonProcesando: boolean = false;
  textoBoton: string = 'Guardar';
  textoBotonProcesando: string = '';

  public botonProcesandoBuscar: boolean = false;
  public textoBotonBuscar: string = 'Buscar';
  public textoProcesandoBuscar: string = '';


  nombreBotonSeleccion: string = 'btnSeleccion';
  nombreBotonEliminar: string = 'btnEliminar';
  nombreBotonActivar: string = 'btnActivarInactivar';
  nombreBotonEditar: string = 'btnEditar';
  nombreBotonChildOptions: string = 'childOptions';

  @Input() public name: string;

  @Input() public processMessage: string = 'Procesando ........';
  public startIndexColumn: number = 0;

  @Input() public configPermisos: any;
  @Input() public createdRowConfig: any
  @Input() public componentName: string

  @Input() public comboBoxManager: ComboBoxManager;
  @Output() onConfigEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() enumPagina: Paginas;

  @Output() onCloneModelEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMetadataEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() items: Array<any> = [];
  simpleSearchModel: string = "";
  //filterModel: any = null;
  @Input() selectedItems: Array<any> = [];
  @Input() public workFlowItem: any = null;

  isLoading: boolean = false;
  @Input() limpiarInternamente: boolean = false;
  @Input() form: FormGroup;

  public id: string = "Chart_" + Guid.create().toString();
  helperCharts: HelperCharts = null;
  viewFilterControl: boolean = false;
  viewFilters: boolean = false;
  hasFilters: boolean = false;
  hasChildReports: boolean = false;

  internalFilter: any = null;
  childReport: any = null;
  labelTipoControlFiltro: string = "";
  labelTipoFiltro: string = "";

  minHeight = null;
  order: string = "";
  isLocalData: Boolean = false;
  public resizable: boolean;
  public expandMode: boolean;
  public minimizeMode: boolean;
  public collapseMode: boolean;
  public ids: any = null;
  quickFilters: Array<any> = [];
  enableSearchIndex : boolean = false;
  public filterRequest: any = null;
  searchIndex : string = "";
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityColumns: UtilitiesColumns,
    public helperActions: HelperActions, 
    public translate: TranslateService) {

    //super();
    this.resizable = true;
    this.expandMode = false;
    this.minimizeMode = false;
    this.collapseMode = false;

    this.ExcecuteFuntion(ControllerMethods.Constructor, true);
    this.translate.setDefaultLang(StaticData.Language);  

  }
  public InitChart() {
    this.helperCharts = new HelperCharts(this, true, this.utility, "config", true);

    this.hasFilters = (this.config.FilterControls && this.config.FilterControls.length > 0) ? true : false;


    this.hasChildReports = (this.config.ReportsChild && this.config.ReportsChild.length > 0) ? true : false;
    this.helperCharts.SetlabelsTiposFilter();
  }

  public RefreshData() {

  }
  public ExcecuteDataSource(filterParent?: any) {

    if (filterParent)
      this.filterParent = filterParent;

    this.ReCargarGrilla(true);
  }
  ngOnInit() {

    this.OnInit();

    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }

    this.helperActions.comunicationService.receivedChangeGridViewEvent.subscribe(config => {
      if (config.IsMosaicView)
        this.VerMosaico();
      else
        this.VerGrilla();
    })
  }
  ngAfterViewInit() {


    if (this.IsBasicTable) {

      setTimeout(() => {
        $("#" + this.name + "_length").hide();
        $("#" + this.name + "_filter").hide();
        $("#" + this.name + "_info").hide();
        $("#" + this.name + "_paginate").hide();
      }, 1000);

    }
    else if (this.config && this.config.WithScrolling) {
      setTimeout(() => {


        $("#" + this.name + "_length").hide();
        $("#" + this.name + "_paginate").hide();
      }, 1000);

    }
    // setTimeout(() => {

    //   var msSearchBox = document.querySelector('#msSearchBox_' + this.name);
    //   if (msSearchBox)
    //     new fabric['SearchBox'](msSearchBox);

    // }, 1000);
    setTimeout(() => {

      // $("#" + this.name + "_wrapper").children(".dt-buttons").hide();
      //  $("#" + this.name + "_wrapper :first-child").hide();

    }, 300);
    if (!this.pkName && !this.config.IsExtendableTable) {
      this.utility.VerModalWarning({ titulo: 'Alerta', descripcion: 'La Configuracion de Llave Primaria, no puede ser nula,en el componente ' + this.name }).then(x => { });
    }

    setTimeout(() => {

      this.RaiseValidateSaveEvent(false);

    }, 1200);
  }
  public RaiseValidateSaveEvent(isValid) {
    if (this.config.Required) {
      this.helperActions.comunicationService.raiseValidateSaveEvent({ Name: this.name, Ok: isValid });
    }
  }
  ngAfterViewChecked() {

  }

  public dropdownBehavior() {
    if ($('.dropdown__wrapper').length) {

      $('.dropdown__wrapper').on('click', function () {
        let btn = $(this);
        let offset = btn.offset().left;
        let menu = btn.children(".dropdown__menu");
        menu.css('display', 'inherit');
        menu.css('left', offset);
      })

      $('.dropdown__wrapper').on('mouseleave', function () {
        let btn = $(this);
        let menu = btn.children(".dropdown__menu");
        menu.css('display', 'none');
      })
    }
  }

  public GetConfigPermisos(data?: any): any {
    if (this.config && this.config.permissionsConfig)
      return this.config.permissionsConfig;
    else
      return this.configPermisos;
  }
  public GetTextMessage(method: string): string {
    return `componentName: ${this.componentName} method: ${method}`;
  }

  public OnInitParentOption() {

    if (this.parentOption) {
      if (this.parentOption.CargarGrilla == false)
        this.cargarGrilla = false;
      if (this.parentOption.HideDataTable == true)
        this.hideDataTable = true;
      if (this.parentOption.HideForm == true)
        this.hideForm = true;
    }

  }
  public OnInitEnd() {

  }
  public onSpecificStart() {

  }
  public GetIcon(_icon: string, setDEfault: boolean = true): string {

    let icon: string;
    if (_icon && _icon.includes('.'))
      icon = _icon;
    else {
      if (_icon && setDEfault) {
        let obj = this.utility.GetNodeType(_icon);
        icon = obj.Icon;
      }
    }
    return icon;
  }
  withActiveColumn: boolean = false;
  withDeleteColumn: boolean = false;
  withEditColumn: boolean = false;
  withSelectionColumn: boolean = false;
  withBoolColumn: boolean = false;
  withOptions: boolean = false;
  withIcon: boolean = false;
  withSelectionInColumn: boolean = false;
  selectionColumn: any = null;
  executeRowEvent: boolean = true;
  public IsGeneralTimeLabelTop() {
    if (this.config && this.config.ConfigControl && this.config.ConfigControl.TimeProcessGeneralPosition &&
      this.config.ConfigControl.TimeProcessGeneralPosition != 'None') {

      if (this.config.ConfigControl.TimeProcessGeneralPosition == 'Top') {
        return true;
      }
      else if (this.config.ConfigControl.TimeProcessGeneralPosition == 'Bottom') {
        return false;
      }
    }
    return null;
  }
  public IsServerTimeLabelTop() {
    if (this.config && this.config.ConfigControl && this.config.ConfigControl.TimeProcessServerPosition &&
      this.config.ConfigControl.TimeProcessServerPosition != 'None') {

      if (this.config.ConfigControl.TimeProcessServerPosition == 'Top') {
        return true;
      }
      else if (this.config.ConfigControl.TimeProcessServerPosition == 'Bottom') {
        return false;
      }
    }
    return null;
  }

  public GetServerTimeLabel() {
    if (this.config && this.config.ConfigControl && this.config.ConfigControl.TimeProcessServerType != 'None') {

      return this.config.ConfigControl.TimeProcessServerLabel + "  " + this.processTime[this.config.ConfigControl.TimeProcessServerType];
    }
    return '';
  }
  public GetGeneralTimeLabel() {
    if (this.config && this.config.ConfigControl && this.config.ConfigControl.TimeProcessGeneralType != 'None') {

      return this.config.ConfigControl.TimeProcessGeneralLabel + "  " + this.processTime[this.config.ConfigControl.TimeProcessGeneralType];
    }
    return '';
  }
  public get IsBasicTable(): boolean {
    return (this.config && this.config.ConfigControl && this.config.ConfigControl.IsBasicTable);
  }

  public get IsSimpleSearch(): boolean {
    return (this.config && !this.IsBasicTable && this.config.ConfigControl && this.config.ConfigControl.SimpleSearch);
  }
  public get IsFilterControls(): boolean {
    return (this.config && this.config.FilterControls && this.config.FilterControls.length > 0);
  }

  public OnSetConfig() {


    this.onConfigEmitter.emit({ configPermisos: this.configPermisos, config: this.config, isPost: false });
    if (this.hideDataTable) {
      this.cargarGrilla = false;
      return;
    }

    if (this.config) {
      if (this.config.permissionsConfig) {
        this.configPermisos = this.config.permissionsConfig;
        this.config.permissionsConfig = undefined;
      }
      if (this.config.Style)
        this.config.Style = {};
      const that = this;
      this.name = this.config.Name;
      this.pkName = this.config.PkName;


      if (this.config.ConfigControl.HideDataTable !== undefined) {
        this.hideDataTable = this.config.ConfigControl.HideDataTable;
        if (this.hideDataTable) {
          this.config.ConfigControl.NotLoad = true;
          this.cargarGrilla = false;
          return;
        }
      }

      this.nombreBotonChildOptions = this.nombreBotonChildOptions + this.name;
      this.nombreBotonEliminar = this.nombreBotonEliminar + this.name;
      this.nombreBotonActivar = this.nombreBotonActivar + this.name;
      this.nombreBotonEditar = this.nombreBotonEditar + this.name;
      this.nombreBotonSeleccion = this.nombreBotonSeleccion + this.name;

      if (!this.componentName)
        this.componentName = this.config.ConfigControl.Controller;
      if (this.config.ConfigControl.QueryConfig &&
        (this.config.ConfigControl.QueryConfig.Command || this.config.Query ||
          this.config.ConfigControl.QueryConfig.CrudAction && this.config.ConfigControl.QueryConfig.CrudAction != 'None')) {
        this.QueryConfig = this.config.ConfigControl.QueryConfig;
      }



      this.outPutParam = this.config.ConfigControl.QueryConfig.OutPutParam;
      this.withPagination = this.config.ConfigControl.QueryConfig.WithPagination;
      this.cargarGrilla = !this.config.ConfigControl.NotLoad;
      this.processMessage = (this.config.ProcessMessage) ? this.config.ProcessMessage : this.processMessage;


      let tables = ["RAuditoriaFuncional",
        "RCiudad",
        "RDepartamento",
        "RDiaFestivo",
        "RDevolucion",
        "RMensajeValidacionIndice",
        "RModoTrabajoSolicitud",
        "RPais",
        "RTipoBusquedaAreaEmpresa",
        "RTipoConsecutivo",
        "RTipoDisposicion",
        "RTipoFirma",
        "RTipoMoneda",
        "RTipoSeguridadDocumento",
        "RTipoUbicacionArchivo",
        "REstadoSolicitud",
        "RCausalRechazo",
        "RTipoSolicitud"];
      // if (this.name == "RPais" || this.name == "RDepartamento" || this.name == "RCiudad") {

      //   this.conFiltroIdEmpresa = false;
      // }

      let entityName = (this.config.ConfigControl.QueryConfig) ? this.config.ConfigControl.QueryConfig.EntityName : "";
      if (this.config.ExcludeIdEmpresaFilter == true ||
        tables.find(x => { return x == this.name || x == this.config.TableName || x == entityName }))
        this.conFiltroIdEmpresa = false;

      if (this.IsSimpleSearch) {
        this.cargarGrilla = false;

        this.QueryConfig.CrudAction = this.config.ConfigControl.SimpleSearch.CrudAction;
        this.QueryConfig.EntityName = this.config.ConfigControl.SimpleSearch.EntityName;
      }
      if (this.config.WithFilterControls) {
        //COMENTAREADO POR MARLON GRANDA 07-07-2020
        //this.cargarGrilla = false;

        this.viewFilterControl = (this.config.ModalFilter) ? false : true;
      }
      if (this.config.ConfigControl.Items) {
        this.cargarGrilla = false;
        if (typeof this.config.ConfigControl.Items === 'string') {
          this.items = JSON.parse(this.config.ConfigControl.Items);
        }
        else
          this.items = this.config.ConfigControl.Items;
        this.isLocalData = true;
      }
      else if (this.config.StaticList) {
        this.cargarGrilla = false;
        let tipo: TipoParaPermisos = Number.isInteger(that.config.StaticList) ?
          parseInt(that.config.StaticList) : TipoParaPermisos["" + that.config.StaticList];

        this.items = this.utility.GetItemsAreasRolesUsuarioSeries(tipo);
        this.isLocalData = true;
      }
      if (!this.configPermisos) {
        this.configPermisos = {
          TipoObjetoParaPermiso: TipoObjetoParaPermisos.Pagina,
          Pagina: this.Pagina, clear: true, CreatedRowConfig: {}
        };
      }
      if (this.config.WithMosaics)
        this.helperActions.comunicationService.raiseViewChangeViewCommandEvent({ WithMosaics: true, Name: this.name });
      this.columns = [];
      if (!this.config.ReadColumnFormData) {
        this.config.Controls.forEach((column: any, index: number) => {

          if (column.Hidden)
            return;
          let columnType: DataTableColumnTypes = Number.isInteger(column.ColumnType) ? parseInt(column.ColumnType) : DataTableColumnTypes["" + column.ColumnType];  //parseInt(column.ColumnType);
          let icon;
          let _colum: any
          column.TableName = this.name;
          column.PkName = this.pkName;
          debugger
          switch (columnType) {

            case DataTableColumnTypes.Active:
              icon = this.GetIcon(column.Icon, false);
              this.withActiveColumn = true;
              let icon2 = this.GetIcon(column.Icon2);
              _colum = this.GetColumnaActivar(icon, icon2, column.Title, column.ToolTip, this, column.Name);

              if (column.buttonName)
                this.nombreBotonActivar = column.buttonName;
              break;
            case DataTableColumnTypes.Actions:

              icon = this.GetIcon(column.Icon);
              this.withActiveColumn = true;
              this.withEditColumn = true;
              _colum = this.GetColumnaEditarEliminar(icon, column.Title, column.ToolTip, this, column.Name);
              if (column.buttonActiveName)
                this.nombreBotonActivar = column.buttonActiveName;
              if (column.buttonDeleteName)
                this.nombreBotonEliminar = column.buttonDeleteName;
              if (column.buttonActiveName)
                this.nombreBotonEditar = column.buttonEditName;
              if (column.buttonSelectionName)
                this.nombreBotonSeleccion = column.buttonSelectionName;
              break;
            case DataTableColumnTypes.Boolean:
              icon = this.GetIcon(column.Icon);
              this.withBoolColumn = true;
              break;
            case DataTableColumnTypes.Custom:

              break;
            case DataTableColumnTypes.Delete:
              icon = this.GetIcon(column.Icon, false);
              this.withDeleteColumn = true;
              var translatedTitle = column.Title ? this.translate.instant(column.Title, { defaultValue: column.Title }) : column.Title
              var translatedToolTip = column.ToolTip ? this.translate.instant(column.ToolTip, { defaultValue: column.ToolTip }) : column.ToolTip
              _colum = this.GetColumnaEliminar(icon, translatedTitle, translatedToolTip, this, column.Name);
              break;
            case DataTableColumnTypes.DownLoad:

              break;
            case DataTableColumnTypes.Edit:
              icon = this.GetIcon(column.Icon, false);
              this.withEditColumn = true;
              var translatedTitle = column.Title ? this.translate.instant(column.Title, { defaultValue: column.Title }) : column.Title
              var translatedToolTip = column.ToolTip ? this.translate.instant(column.ToolTip, { defaultValue: column.ToolTip }) : column.ToolTip
              _colum = this.GetColumnaEditar(icon, translatedTitle, translatedToolTip, this, column.Name, column);  
			  break;
            case DataTableColumnTypes.Icon:
              icon = this.GetIcon(column.Icon);
              column.Title = (column.Title) ? this.translate.instant(column.Title) : 'SIN TITULO';
              column.ToolTip = (column.ToolTip) ? this.translate.instant(column.ToolTip) : 'SIN TOOLTIP';
              column.SearchField = (column.SearchField) ? column.SearchField : 'ObjType';
              column.IconField = (column.IconField) ? column.IconField : 'Icon';
              const customName = column.CustomName + this.componentName;
              if (column.IconByField) {
                var translatedTitle = column.Title ? this.translate.instant(column.Title, { defaultValue: column.Title }) : column.Title
                var translatedToolTip = column.ToolTip ? this.translate.instant(column.ToolTip, { defaultValue: column.ToolTip }) : column.ToolTip
                _colum = this.utilityColumns.GetColumnaIcono(this.pkName, null, customName, column.DataIconField,
                  column.SearchField, column.IconField, translatedTitle, translatedToolTip, this.utility.GetNodeTypes(), column.ToolTipField, null, column.Name, column);
              }
              else {
                var translatedTitle = column.Title ? this.translate.instant(column.Title, { defaultValue: column.Title }) : column.Title
                var translatedToolTip = column.ToolTip ? this.translate.instant(column.ToolTip, { defaultValue: column.ToolTip }) : column.ToolTip
                _colum = this.utilityColumns.GetColumnaIcono(this.pkName, icon, customName, column.DataIconField,
                  column.SearchField, column.IconField, translatedTitle, translatedToolTip, null, column.ToolTipField, null, column.Name, column);
              }
              this.withIcon = (column.Actions && column.Actions.length > 0);

              break;
            case DataTableColumnTypes.Import:


              break;
            case DataTableColumnTypes.Options:

              column.Title = (column.Title) ? this.translate.instant(column.Title) : 'SIN TITULO';
              //column.ToolTip = (column.ToolTip) ? column.ToolTip : 'SIN TOOLTIP';
              this.withOptions = true;
              this.nombreBotonChildOptions = column.Name;
              column.TextMaxlength = (column.TextMaxlength) ? parseInt(column.TextMaxlength) : column.TextMaxlength;
              var translatedTitle = column.Title ? this.translate.instant(column.Title, { defaultValue: column.Title }) : column.Title
              _colum = this.utilityColumns.GetChildOptionsColumn(column.ChildOptions, this.componentName, this.pkName,
                this, translatedTitle, column.Name, column.CustomName, null, column, this.modelo, this.config);
              break;
            case DataTableColumnTypes.Selection:
              icon = this.GetIcon(column.Icon);
              this.withSelectionColumn = true;
              var translatedTitle = column.Title ? this.translate.instant(column.Title, { defaultValue: column.Title }) : column.Title
              var translatedToolTip = column.ToolTip ? this.translate.instant(column.ToolTip, { defaultValue: column.ToolTip }) : column.ToolTip
              _colum = this.GetColumnaSeleccionItem(icon, translatedTitle, translatedToolTip, this, column.Name, column);
              break;
            case DataTableColumnTypes.View:

              break;
            default:
              var translatedTitle = column.Title ? this.translate.instant(column.Title, { defaultValue: column.Title }) : column.Title

              _colum = { data: column.DataField, title: translatedTitle, name: column.Name };

              _colum.TextMaxlength = (column.TextMaxlength) ? parseInt(column.TextMaxlength) : column.TextMaxlength;
              _colum.HtmlMsg = column.HtmlMsg;
              _colum.InfoModelsContainer = column.InfoModelsContainer;
              _colum.Script = column.Script;
              _colum.JsonProperty = column.JsonProperty;
              _colum.EvalExpression = column.EvalExpression;
              if (_colum.TextMaxlength > 0 || _colum.HtmlMsg || _colum.Script)// || _colum.JsonProperty)
                _colum = this.utilityColumns.GetGeneralEspecialColumn(_colum, this.modelo, null);



              break;

          }

          if (_colum) {

            _colum.PkName = this.pkName;
            _colum.TextMaxlength = (column.TextMaxlength) ? parseInt(column.TextMaxlength) : column.TextMaxlength;
            _colum.DisabledPermissions = column.DisabledPermissions;
            _colum.ControlType = 'Column';
            _colum.Actions = column.Actions;
            _colum.TimerActions = column.TimerActions;
            _colum.TimerToAllActions = column.TimerToAllActions;
            _colum.Type = column.Type;
            _colum.WithSelection = column.WithSelection;
            if (column.WithSelection) {
              this.withSelectionInColumn = true;
              this.selectionColumn = _colum;
            }
            _colum.ExternalModel = column.ExternalModel;
            _colum.WithMultiSelection = column.WithMultiSelection;
            if (_colum.WithMultiSelection) {
              this.selectionColumn = _colum;
            }
            if (column.NotOrderable)
              _colum.orderable = false;
            /*    if (Number.isInteger(column.ColumnType))
                 _colum.StrColumnType = DataTableColumnTypes[parseInt(column.ColumnType)];
               else */
            _colum.StrColumnType = DataTableColumnTypes[columnType];

            _colum.FieldDataName = column.FieldDataName;

            this.columns.push(_colum);
          }
          else {
            console.error('Columna no encontrada: ', column);
          }

        });
      }
      else if (this.items.length > 0) {

        $.each(this.items[0], (key: string, value: any) => {
          let _colum: any = {
            data: key, title: key, name: key,
            ControlType: 'Column',
            Actions: [],
            Type: "General"
          };
          this.columns.push(_colum);
        });

      }
      // $.each(this.config.Columns, function (index: number, column: any) {

      //     for (var value of array) {
      //         console.log(value);
      //       }

      // });

      if (this.config.SetStringIds || this.config.SetListIds) {

        this.loadedGridEmitter.subscribe(result => {

          if (result.data) {
            if (this.config.SetStringIds) {
              this.ids = result.data.map(item => {
                return item[this.pkName]
              }).join();
            }
            else if (this.config.SetListIds) {
              this.ids = result.data.map(item => {
                return item[this.pkName]
              });
            }
          }

        });
      }

    }


  }
  SelectionCheckBoxElements: any;
  SelectionCheckBoxs: Array<any> = [];
  SeleccionCol: any = null;
  public OnInit() {

    try {


      if (this.parentCallRefresh) {
        // console.error("DataTable "+this.name+",parentCallRefresh, observers: "+this.parentCallRefresh.observers.length);
        this.parentCallRefresh.subscribe(event => {
          // console.error('parentCallRefresh ' + this.componentName);
          if (this.config.NotRefreshParentCalls !== true)
            this.ReCargarGrilla();
        });
      }
      if (this.parentClearCall) {

        // console.error("DataTable "+this.name+",parentClearCall, observers: "+this.parentClearCall.observers.length);
        this.parentClearCall.subscribe(event => {

          if (event.action && event.action != FormActionsThroughEmitters.ClearModel)
            this.ReCargarGrilla();
          //this.ClearMultiSelection();

        });
      }

      this.OnInitParentOption();

      this.OnSetConfig();
      this.InitChart();
      this.onSpecificStart();

      //if (this.enumPagina)
      this.utility.IsUserAuthenticated(this.enumPagina, true, this.componentName, 'onInit', null);

      if (!this.name)
        this.name = this.GetNombreDataTables();

      if (this.Pagina.Valida) {

        this.childOptions = (this.childOptions && this.childOptions.length > 0) ? this.childOptions : this.utility.GetChildOptions(this.Pagina);
        // this.AdicionarColumasEditarEliminar();
        this.exportar = (this.Pagina.Exportar == true) ? this.componentName : undefined;

        this.idGrillaQuery = (this.config && this.config.IdQuery) ? this.config.IdQuery : undefined;
        this.OnInitEnd();
        this.onConfigEmitter.emit({ columns: this.columns, startIndexColumn: this.startIndexColumn, configPermisos: this.configPermisos, config: this.config, isPost: true });
        this._CargarGrilla();
        if (this.config.WithMosaics) {
          if (this.items.length > 0) {
            if (this.dataTableMosaic && this.config.WithMosaics)
              this.dataTableMosaic.SetItems(this.items);

            this.ClearSelection(this.IsSelection);
          }
          else {
            this.loadedGridEmitter.subscribe(result => {
              this.isLoading = false;
              this.dropdownBehavior();
              if (this.dataTableMosaic)
                this.dataTableMosaic.SetItems((result.data) ? result.data : []);
              this.ClearSelection(this.IsSelection);

            });
          }
        }
        else {
          this.loadedGridEmitter.subscribe(result => {
            this.isLoading = false;
            this.ClearSelection(this.IsSelection);
          });
        }
      }
      else {
        this.SetDefaultDtOptions();
      }



      // let tables=["RAuditoriaFuncional",
      // "RCiudad",
      // "RDepartamento",
      // "RDiaFestivo",
      // "RMensajeValidacionIndice",
      // "RModoTrabajoSolicitud",
      // "RPais",
      // "RTipoBusquedaAreaEmpresa",
      // "RTipoConsecutivo",
      // "RTipoDisposicion",
      // "RTipoFirma",
      // "RTipoMoneda",
      // "RTipoSeguridadDocumento",
      // "RTipoUbicacionArchivo",
      // "REstadoSolicitud",
      // "RCausalRechazo",
      // "RTipoSolicitud"];
      // // if (this.name == "RPais" || this.name == "RDepartamento" || this.name == "RCiudad") {

      // //   this.conFiltroIdEmpresa = false;
      // // }

      // this.ExcecuteFuntion(ControllerMethods.OnInit,true);
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }
  }


  public get IsEdit(): boolean {
    if (this.isModelContainer)
      return (this.modelo.modelo.PkValue !== 0 && this.modelo.modelo.PkValue !== null && this.modelo.modelo.PkValue !== '');
    else
      return (this.modelo.PkValue !== 0 && this.modelo.PkValue !== null && this.modelo.PkValue !== '');
  }

  public get IsSelection(): boolean {

    return ((this.GetSelectedItems() && this.GetSelectedItems().length > 0) || this.SeleccionCol);
  }

  public get PkValue(): any {

    // if (this.isModelContainer) {
    //   if (this.modelo && this.modelo.modelo)
    //     return this.modelo.modelo[this.pkName];
    //   else
    //     return null;
    // }
    // else {
    //   if (this.modelo)
    //     return this.modelo[this.pkName];
    //   else
    //     return null;
    // }

    return this.GetValueProperty(this.pkName);
  }

  public GetValueProperty(property: string): any {

    if (this.isModelContainer) {
      if (this.modelo && this.modelo.modelo)
        return this.modelo.modelo[property];
      else
        return null;
    }
    else {
      if (this.modelo)
        return this.modelo[property];
      else
        return null;
    }

  }

  public GetNombreDataTables(): string {

    return 'DataTables_' + this.componentName;
  }
  public SetDefaultDtOptions() {
    debugger  
    this.dtOptions = this.utility.GetDefaultDtOptions();
  }

  public SetModeloToNull() {
    if (this.limpiarInternamente) {
      if (this.isModelContainer)
        this.modelo.modelo = null;
      else
        this.modelo = null;
    }
  }
  public SimpleSearch() {
    this.ReCargarGrilla();
  }
  public Buscar(event) {
    this.cargarGrilla = true;
    //this.filterModel = event;
    this.internalFilter = event;// { Filter: event.filter };
    this.ExcecuteDataSource();
    // this.ReCargarGrilla();
  }
  public LimpiarBuscar(event) {
    this.internalFilter = null;
    this.cargarGrilla = true;
    this.ExcecuteDataSource();
    //this.ReCargarGrilla();
    this.LimpiarFilterControls();
  }

  public LimpiarFilterControls() {

    this.config.FilterControls.forEach(element => {
      switch (element.ControlType) {
        case 'ComboBox':
          element.ConfigControl.Context.modelo = "";
          break;
      }
    });
  }

  public ReplaceQuickFilter(quickFilter) {
    if (quickFilter.Filter) {

      if (quickFilter.InfoModelsContainer && quickFilter.Filter.includes("#")) {
        let modelsnames: Array<any> = this.config.InfoModelsContainer.split(',');
        let tempModelo: any = {};
        modelsnames.forEach(modelName => {
          tempModelo[modelName] = this.modelo[modelName];
        });
        quickFilter.FilterWithReplace = this.utility.ReplaceTextFromModels(tempModelo, quickFilter.Filter);
      }
      else {
        if (quickFilter.Filter.includes("#"))
          quickFilter.FilterWithReplace = this.utility.ReplaceTextFromModels(this.modelo.modelo, quickFilter.Filter);

      }
    }
  }
  public GetDataTableFilter(): any {


    this.isLoading = true;
    let filter = '';
    this.isLoaded = false;
    //this.filtroIdEmpresa = (this.filtroIdEmpresa == null) ? null : this.componentName + '.' + this.filtroIdEmpresa + ' = ' +StaticData.Usuario.IdEmpresa;
    let filterParent = null;
    if (!this.QueryConfig) {
      if (this.conFiltroIdEmpresa) {
        if (StaticData.Usuario)
          filter = this.componentName + '.IdEmpresa = ' + StaticData.Usuario.IdEmpresa;
        else
          filter = this.componentName + '.IdEmpresa = 0 ';
      }
      if (this.pkValueEdit)
        filter = (filter) ? filter + ' AND ' + this.componentName + '.' + this.pkName + ' = ' + this.pkValueEdit : this.componentName + '.' + this.pkName + ' = ' + this.pkValueEdit;

      if (this.externalFilter)
        filter = (filter) ? filter + ' AND ' + this.externalFilter : this.externalFilter;
      if (this.filterModal)
        filter = (filter) ? filter + ' AND ' + this.filterModal : this.filterModal;

      filterParent = (this.parentOption && this.parentOption.NotApplyToFilterGrid == true) ? null : this.filterParent;
    }
    let obj = this.utility.GetApiModelGrillaValidation('_CargarGrilla', (this.idGrillaQuery) ? this.idGrillaQuery : this.componentName, filterParent, this.mappingFilter, this.generalFilter, this.Pagina, null, filter);
    if (this.QueryConfig) {
      obj.QueryConfig = this.utility.Clone(this.QueryConfig);

      if (this.QueryConfig.MappingParameters) {
        if (!obj.QueryConfig.Entity)
          obj.QueryConfig.Entity = {};
        this.QueryConfig.MappingParameters.forEach(parameter => {
          obj.QueryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, this.QueryConfig, null, this, this.parentContext);
        })
      }
    }
    if (this.config && this.config.Query) {
      obj.QueryConfig.Query = this.config.Query;
    }
    /*   if (this.include)
          obj.QueryConfig.Include = this.include; */
    if (!this.QueryConfig)
      obj.QueryConfig.WithPagination = this.withPagination;




    if (this.config.WithFilterControls && this.internalFilter) {
      if (this.config.SetFilterControlsToEntity) {
        if (!obj.QueryConfig.Entity)
          obj.QueryConfig.Entity = {};
        if (this.config.WithQueryBuilder) {

          if (obj.QueryConfig.Entity["Filter"])
            obj.QueryConfig.Entity["Filter"] = obj.QueryConfig.Entity["Filter"] + " AND " + this.internalFilter.filter;
          else
            obj.QueryConfig.Entity["Filter"] = this.internalFilter.filter;
        }
        else {
          $.each(this.internalFilter.modelo, (key, value: any) => {
            obj.QueryConfig.Entity[key] = value;
          });
        }
      }
      else {
        obj.QueryConfig.Filter = (obj.QueryConfig.Filter) ? obj.QueryConfig.Filter + " AND " + this.internalFilter.filter : this.internalFilter.filter;
      }
    }

    if (this.quickFilters.length > 0) {
      //
      let quickFilter = "";

      this.quickFilters.forEach((item, i) => {

        this.ReplaceQuickFilter(item);
        if ((this.quickFilters.length - 1) == i) {
          quickFilter += (item.FilterWithReplace) ? item.FilterWithReplace + " " : item.Filter + " ";
        }
        else {
          quickFilter += (item.FilterWithReplace) ? item.FilterWithReplace + " " + item.Op : item.Filter + " " + item.Op + " ";
        }
      })
      if (this.config.QuickFilterParameterName) {
        if (obj.QueryConfig.Entity[this.config.QuickFilterParameterName])
          obj.QueryConfig.Entity[this.config.QuickFilterParameterName] = obj.QueryConfig.Entity[this.config.QuickFilterParameterName] + " AND " + quickFilter;
        else
          obj.QueryConfig.Entity[this.config.QuickFilterParameterName] = quickFilter;
      }
      else
        obj.QueryConfig.Filter = (obj.QueryConfig.Filter) ? obj.QueryConfig.Filter + " AND " + quickFilter : quickFilter;

    }

    if (this.filterRequest) {

      if (this.filterRequest.FilterParameter) {
        if (!obj.QueryConfig.Entity)
          obj.QueryConfig.Entity = {};

        obj.QueryConfig.Entity[this.filterRequest.FilterParameter] = (obj.QueryConfig.Entity[this.filterRequest.FilterParameter]) ?
          obj.QueryConfig.Entity[this.filterRequest.FilterParameter] + " AND " + this.filterRequest.FilterSentence : this.filterRequest.FilterSentence;

      }
      else {
        if (this.filterRequest.Entity) {
          if (!obj.QueryConfig.Entity)
            obj.QueryConfig.Entity = {};


          for (let prop in this.filterRequest.Entity)
            obj.QueryConfig.Entity[prop] = this.filterRequest.Entity[prop];

        }
        else
          obj.QueryConfig.Filter = (obj.QueryConfig.Filter) ? obj.QueryConfig.Filter + " AND " + this.filterRequest.FilterSentence : this.filterRequest.FilterSentence;
      }
    }



    var resultExecute = this.ExcecuteFuntion(ControllerMethods.LoadGrid, false, obj);
    if (resultExecute)
      obj = resultExecute;

    if (this.Pagina.IsCustom) {
      obj.Form = {
        GUID: this.Pagina.IdPagina,
        Name: this.Pagina.Nombre,
        ObjectType: "Form",
      }
    };
    var okUser = this.utility.ValidateUser(obj);
    this.isLoaded = true;
    obj.CtrName = this.name;
    this.processMessage = (this.processMessage) ? this.processMessage : ((this.Pagina.Titulo) ? 'Cargando datos de ' + this.Pagina.Titulo : null);
    return {
      obj: obj,
      notLoad: !this.cargarGrilla,
      processMessage: this.processMessage,
      startIndexColumn: this.startIndexColumn,
      columns: this.columns,
      dataTableAjaxOrderType: this.dataTableAjaxOrderType,
      outPutParam: this.outPutParam,
      loadNotifyType: this.config.LoadNotifyType
    };
  }
  // OJO MIRAR SI SE QUITA ESTO
  public GetByPkValue(pkValueEdit?: any) {
    if (pkValueEdit)
      this.pkValueEdit = pkValueEdit;
    let result = this.GetDataTableFilter();
    let info = { componentName: this.componentName, method: 'GetByPkValue', processMessage: 'Consultando par editar el registro de ' + this.Pagina.Titulo };
    this.baseService.Get(result.obj, null,
      info, this.componentName, true, true).then(data => {

        // this.SetModelo(data.Data[0]);

      }).catch(error => {
        this.utility.logger.LogError(error, 'Consulta de Edicion', info);
      })
  }
  //order([0, 'desc'])

  public ActionDataTable(value: any, type: DataTableActions, onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>()) {
    const that = this;
    try {
      that.utility.GetDataTabledtInstance(that.dtElement, that.name, that.componentName).then(dtInstance => {


        switch (type) {
          case DataTableActions.Draw:
            dtInstance.draw();

            break;
          case DataTableActions.Order:
            dtInstance.order(value).draw();
            //dtInstance.scroller.toPosition( 100 );
            break;
          case DataTableActions.Paged:
            dtInstance.page.len(value).draw();
            break;
          case DataTableActions.Search:
            dtInstance.search(value).draw();
            break;
          case DataTableActions.Export:
            dtInstance.button(value).trigger();
            break;
          case DataTableActions.PageInfo:


            let sample = {
              "page": 1,
              "pages": 6,
              "start": 10,
              "end": 20,
              "length": 10,
              "recordsTotal": 57,
              "recordsDisplay": 57,
              "serverSide": false
            }
            onActionEventEmitter.emit(dtInstance.page.info());
            break;
        }

      }).catch(error => {
        that.utility.logger.LogError(error, { value: value, type: type });
      })
    }
    catch (error) {
      that.utility.logger.LogError(error, { value: value, type: type });
    }
  }
  public OrderDataTable(value: any) {
    this.ActionDataTable(value, DataTableActions.Order);
  }
  public PageDataTable(value: any) {
    this.ActionDataTable(value, DataTableActions.Paged);
  }
  public SearchDataTable(value: any) {
    this.ActionDataTable(value, DataTableActions.Search);
  }
  public PageInfoDataTable(value: any, onActionEventEmitter: EventEmitter<any>) {
    this.ActionDataTable(value, DataTableActions.PageInfo, onActionEventEmitter);
  }
  public DrawDataTable() {
    this.ActionDataTable(null, DataTableActions.Draw);
  }
  public ExportButtonsDataTable(index) {
    this.ActionDataTable(index, DataTableActions.Export);
  }
  public ClickDataTableMosaicHandler(event) {

    //this.dataTableExpediente.SeleccionarItemPorItem(event.item,event.index,event.item.IdExpediente);
  }

  public PageDataTableMosaicHandler(event) {


    this.PageDataTable(event.pageSize);
  }
  public SerachDataTableMosaicHandler(event) {

    this.SearchDataTable(event.searchText);
  }

  public OrderDataTableMosaicHandler(event) {


    this.OrderDataTable(event.order);
  }


  public _CargarGrilla(refresh?: boolean) {
debugger
    try {

      if (this.hideDataTable == true) {

        this.SetDefaultDtOptions();
        if (this.pkValueEdit) {
          this.GetByPkValue()
        }
        return;
      }
      const that = this;
      let url = (this.Pagina.IsCustom) ? "General" : (this.url) ? this.url : this.componentName;
      if (this.config.ConfigControl && this.config.ConfigControl.Controller)
        url = this.config.ConfigControl.Controller;

      let urlServer = this.utility.GetUrlServer(url);
      // this.config.ExportButtons = [
      //   'copy', 'csv', 'excel', 'pdf', 'print'
      // ]
      this.dtOptions = this.utility.GetDtOptions(this.columns, true, this.config.WithScrolling,
        this.config.ScrollY, this.config.ExportButtons, (this.selectionColumn != null),
        this.config.RowGroupColumns, this.config.IsResponsive);



      // if (this.name == "DTT_PRUEBA") {
      //

      //   var dataSet = [
      //     ["Tiger Nixon", "System Architect", "Edinburgh", "5421", "2011/04/25", "$320,800"],
      //     ["Garrett Winters", "Accountant", "Tokyo", "8422", "2011/07/25", "$170,750"],
      //     ["Ashton Cox", "Junior Technical Author", "San Francisco", "1562", "2009/01/12", "$86,000"],
      //     ["Cedric Kelly", "Senior Javascript Developer", "Edinburgh", "6224", "2012/03/29", "$433,060"],
      //     ["Airi Satou", "Accountant", "Tokyo", "5407", "2008/11/28", "$162,700"],
      //     ["Brielle Williamson", "Integration Specialist", "New York", "4804", "2012/12/02", "$372,000"],
      //     ["Herrod Chandler", "Sales Assistant", "San Francisco", "9608", "2012/08/06", "$137,500"],
      //     ["Rhona Davidson", "Integration Specialist", "Tokyo", "6200", "2010/10/14", "$327,900"],
      //     ["Colleen Hurst", "Javascript Developer", "San Francisco", "2360", "2009/09/15", "$205,500"],
      //     ["Sonya Frost", "Software Engineer", "Edinburgh", "1667", "2008/12/13", "$103,600"],
      //     ["Jena Gaines", "Office Manager", "London", "3814", "2008/12/19", "$90,560"],
      //     ["Quinn Flynn", "Support Lead", "Edinburgh", "9497", "2013/03/03", "$342,000"],
      //     ["Charde Marshall", "Regional Director", "San Francisco", "6741", "2008/10/16", "$470,600"],
      //     ["Haley Kennedy", "Senior Marketing Designer", "London", "3597", "2012/12/18", "$313,500"],
      //     ["Tatyana Fitzpatrick", "Regional Director", "London", "1965", "2010/03/17", "$385,750"],
      //     ["Michael Silva", "Marketing Designer", "London", "1581", "2012/11/27", "$198,500"],
      //     ["Paul Byrd", "Chief Financial Officer (CFO)", "New York", "3059", "2010/06/09", "$725,000"],
      //     ["Gloria Little", "Systems Administrator", "New York", "1721", "2009/04/10", "$237,500"],
      //     ["Bradley Greer", "Software Engineer", "London", "2558", "2012/10/13", "$132,000"],
      //     ["Dai Rios", "Personnel Lead", "Edinburgh", "2290", "2012/09/26", "$217,500"],
      //     ["Jenette Caldwell", "Development Lead", "New York", "1937", "2011/09/03", "$345,000"],
      //     ["Yuri Berry", "Chief Marketing Officer (CMO)", "New York", "6154", "2009/06/25", "$675,000"],
      //     ["Caesar Vance", "Pre-Sales Support", "New York", "8330", "2011/12/12", "$106,450"],
      //     ["Doris Wilder", "Sales Assistant", "Sydney", "3023", "2010/09/20", "$85,600"],
      //     ["Angelica Ramos", "Chief Executive Officer (CEO)", "London", "5797", "2009/10/09", "$1,200,000"],
      //     ["Gavin Joyce", "Developer", "Edinburgh", "8822", "2010/12/22", "$92,575"],
      //     ["Jennifer Chang", "Regional Director", "Singapore", "9239", "2010/11/14", "$357,650"],
      //     ["Brenden Wagner", "Software Engineer", "San Francisco", "1314", "2011/06/07", "$206,850"],
      //     ["Fiona Green", "Chief Operating Officer (COO)", "San Francisco", "2947", "2010/03/11", "$850,000"],
      //     ["Shou Itou", "Regional Marketing", "Tokyo", "8899", "2011/08/14", "$163,000"],
      //     ["Michelle House", "Integration Specialist", "Sydney", "2769", "2011/06/02", "$95,400"],
      //     ["Suki Burks", "Developer", "London", "6832", "2009/10/22", "$114,500"],
      //     ["Prescott Bartlett", "Technical Author", "London", "3606", "2011/05/07", "$145,000"],
      //     ["Gavin Cortez", "Team Leader", "San Francisco", "2860", "2008/10/26", "$235,500"],
      //     ["Martena Mccray", "Post-Sales support", "Edinburgh", "8240", "2011/03/09", "$324,050"],
      //     ["Unity Butler", "Marketing Designer", "San Francisco", "5384", "2009/12/09", "$85,675"]
      //   ];
      //   // this.dtOptions.data=dataSet;//[["1","Unos"],["2","Dos"]];// [{"Id":"1","Nombre":"Unos"},{"Id":"2","Nombre":"Dos"}];//[[1,"Unos"],[2,"Dos"]];//[{"Id":1,"Nombre":"Unos"},{"Id":2,"Nombre":"Dos"}];
      //   //   this.dtOptions.columns= [
      //   //     { title: "Name" },
      //   //     { title: "Position" },
      //   //     { title: "Office" },
      //   //     { title: "Extn." },
      //   //     { title: "Start date" },
      //   //     { title: "Salary" }
      //   // ];


      //   let items = [];
      //   for (let i = 0; i < 50; i++) {
      //     items.push({ "Id": i, "Nombre": "Dato_" + i })
      //   }

      //   this.dtOptions.data = items;//[{"Id":"1","Nombre":"Unos"},{"Id":"2","Nombre":"Dos"}];//[["1","Unos"],["2","Dos"]];// [{"Id":"1","Nombre":"Unos"},{"Id":"2","Nombre":"Dos"}];//[[1,"Unos"],[2,"Dos"]];//[{"Id":1,"Nombre":"Unos"},{"Id":2,"Nombre":"Dos"}];
      //   this.dtOptions.serverSide = false;

      //   return;
      // }

      // const testScript: string = "var _data =data;var _row =row;;var _index =index;console.error(_data,_row,_index)";
      if (this.configPermisos && this.configPermisos.CreatedRowConfig) {

        if (this.config.ClassAlerts && this.config.ClassAlerts.length > 0) {

          if (!this.config.AlertFiled)
            this.config.AlertFiled = "TipoAlerta"
          this.dtOptions.createdRow = (row, data, index) => {

            let tipoAlerta = data[this.config.AlertFiled];
            if (tipoAlerta) {
              let classAlert = this.config.ClassAlerts.find(x => { return x.Name.toLowerCase() == tipoAlerta.toLowerCase() });
              if (classAlert) {
                $(row).addClass(classAlert.Class);
                if (classAlert.ToolTip) {
                  let ToolTip = classAlert.ToolTip;
                  if (ToolTip.includes("#"))
                    ToolTip = this.utility.ReplaceTextFromModels(data, ToolTip);

                  $(row).attr('title', ToolTip);
                }
              }
            }




            if (this.config.ScriptCreatedRow)
              this.utilityColumns.ExecuteSCript(this.config.ScriptCreatedRow, this.config.ScriptCreatedRowIsEval);
            //this.utilityColumns.ExecuteSCript(this.config.ScriptCreatedRow, this.config.ScriptCreatedRowIsEval, row, data, index);
          }

          this.dtOptions.rowCallback = (row: Node, data: any[] | Object, index: number) => {


            if (this.config.ScriptRowCallback)
              this.utilityColumns.ExecuteSCript(this.config.ScriptRowCallback, this.config.ScriptRowCallbackIsEval);
            //this.utilityColumns.ExecuteSCript(this.config.ScriptCreatedRow, this.config.ScriptCreatedRowIsEval, row, data, index);

          }

        }
        else {

          this.dtOptions.createdRow = (row, data, index) => {
            if (this.config.ScriptCreatedRow)
              this.utilityColumns.ExecuteSCript(this.config.ScriptCreatedRow, this.config.ScriptCreatedRowIsEval);
            //this.utilityColumns.ExecuteSCript(this.config.ScriptCreatedRow, this.config.ScriptCreatedRowIsEval, row, data, index);

            // let ok = that.utility.TienePemisoUsuario(that.configPermisos, data, 'Editar');
            // if (!ok) {
            //   let className = 'alert alert-warning';
            //   // $('td', row).addClass(className);
            //   $(row).addClass(className);
            // }
          }

          this.dtOptions.rowCallback = (row: Node, data: any[] | Object, index: number) => {
            if (this.config.ScriptRowCallback)
              this.utilityColumns.ExecuteSCript(this.config.ScriptRowCallback, this.config.ScriptRowCallbackIsEval);
            //this.utilityColumns.ExecuteSCript(this.config.ScriptRowCallback, this.config.ScriptRowCallbackIsEval, row, data, index);

          }
        }
      }

      this.dtOptions.initComplete = () => {

        if (!this.isLocalData) {
          $('#' + that.name + '_filter input').off();

          // $('#msSearchBox_' + that.name + ' > input').on('focus', function(e){
          //   $('#msSearchBox_' + that.name).addClass('is-active');
          //   console.log("Is active");
          // });

          // $('#msSearchBox_' + that.name + ' > input').on('blur', function(e){
          //   e.preventDefault();
          //   // console.log("blur: ", e);
          //   if (e.relatedTarget != null) {
          //     const btn = e.relatedTarget as HTMLElement
          //     if (btn.classList.contains('ms-CommandButton-button')) {
          //       return;
          //     }
          //   } else {
          //     $('#msSearchBox_' + that.name).removeClass('is-active');
          //     console.log("Remove active");
          //   }
          // });

          $('#' + that.name + '_filter input').on('keypress', function (e) {

            var input = this as HTMLInputElement;

            if (e.keyCode == 13) { //|| input.value == '' || input.value === undefined ) {

              e.preventDefault();
              e.stopPropagation();
              that.utility.GetDataTabledtInstance(that.dtElement, that.name, that.componentName).then(dtInstance => {
                dtInstance.search(input.value).draw();

              }).catch(error => { that.utility.logger.LogError(error, { value: input.value, type: 'search' }) })

            }
          });

          $('#' + that.name + '_filter button').bind('click', function (e) {

            var input = this as HTMLInputElement;
            that.utility.GetDataTabledtInstance(that.dtElement, that.name, that.componentName).then(dtInstance => {
              dtInstance.search(input.value).draw();
              $('#msSearchBox_' + that.name + ' > input').focus();
            }).catch(error => { that.utility.logger.LogError(error, { value: input.value, type: 'search' }) })
          });
        }
        if (this.config.ScriptInitComplete)
          this.utilityColumns.ExecuteSCript(this.config.ScriptInitComplete, this.config.ScriptInitCompleteIsEval);
      }
      if (this.selectionColumn) {
        this.dtOptions.rowCallback = (row: Node, data: any[] | Object, index: number) => {
          const self = this;

          // Unbind first in order to avoid any duplicate handler
          // (see https://github.com/l-lin/angular-datatables/issues/87)
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {

            if (!this.executeRowEvent) {
              this.executeRowEvent = true;
              return;
            }

            let ColName = (this.selectionColumn.WithMultiSelection ? "CheckBoxCol" : "SeleccionCol") + this.name + index;
            let CheckBoxCol = $('#' + ColName);





            this.selectionColumn.CheckBoxCol = undefined;
            if (this.selectionColumn.WithMultiSelection) {
              if ($(row).hasClass('selected')) {
                CheckBoxCol.prop("checked", false);
              }
              else {
                CheckBoxCol.prop("checked", true);
              }
              this.selectionColumn.CheckBoxCol = { Value: CheckBoxCol.prop("checked"), Name: ColName };
              this._Editar(index, data[this.pkName], null, true, this.selectionColumn);
            }
            else if (this.withSelectionInColumn) {

              // let _SeleccionCol = $('[id="SeleccionCol' + _context.name + data.index + '"]');
              let checked = false

              if ($(row).hasClass('selected')) {
                CheckBoxCol.prop("checked", false);                
              }
              else {
                CheckBoxCol.prop("checked", true);
                checked = true;
              }


              // if (this.SeleccionCol && this.SeleccionCol.id == CheckBoxCol[0].id) {
              if (!checked) {

                //this.SetModeloToNull();
                // CheckBoxCol.prop("checked", false);
                this.onActionEventEmitter.emit({ Action: FormActionsThroughEmitters.ClearModel, Table: this.name });
                this.SeleccionCol = null;

              }
              else {
                this.SeleccionCol = CheckBoxCol[0];
                this._Editar(index, data[this.pkName], null, false, this.selectionColumn);
              }

            }


          });


          if (this.config.ScriptRowCallback)
            this.utilityColumns.ExecuteSCript(this.config.ScriptRowCallback, this.config.ScriptRowCallbackIsEval);
          //this.utilityColumns.ExecuteSCript(this.config.ScriptRowCallback, this.config.ScriptRowCallbackIsEval, row, data, index);

          return row;
        }
      }

      if (!this.isLocalData) {
        this.dtOptions.ajax = this.baseService.AjaxDataTables(urlServer, this.name, this.componentName, null,
          this.isHtmlDataTable, this.AsignarEventosEditarEliminar, this, this.CallbackDataTablesOk, this.CallbackDataTablesError,
          (this.pkValueEdit), !this.cargarGrilla, this.loadedGridEmitter);

      }
      else {

        this.dtOptions.data = this.items;
        this.dtOptions.serverSide = false;
        setTimeout(() => {
          this.AsignarEventosEditarEliminar(this);
          this.loadedGridEmitter.emit({ controlName: name, data: this.items });
        }, 500);

      }
      if ((this.parentOption || refresh) && this.cargarGrilla) {
        if (this.dtElement)
          this.utility.ReCargarGrilla(this.dtElement, this.name, this.componentName);
        else {
          this.utility.logger.LogErrorText('dtElement es nulo en el conmponente: ' + this.componentName);
        }
      }


      this.ExcecuteFuntion(ControllerMethods.LoadGrid, true, null);
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: '_CargarGrilla', controlName: this.name });
    }
  }

  public teste() {

  }


  public ReCargarGrilla(clearModel?: any) {
    if (this.dtElement) {

      if (!this.isLocalData) {
        this.cargarGrilla = true;
        this.utility.ReCargarGrilla(this.dtElement, this.name, this.componentName);
      }
      this.ClearSelection(clearModel);


    }
    else
      throw 'dtElement es nulo  en el metodo ReCargarGrilla , componente:' + this.componentName;
  }


  public CargarGrilla(filterModel: any) {//,_filterParent: any, mappingFilter: any, filter: any, isModal: boolean, parentOption: any) {

    if (filterModel) {
      this.filterParent = filterModel.filterParent;
      this.isModal = filterModel.isModal;
      this.mappingFilter = filterModel.mappingFilter;
      this.generalFilter = filterModel.filter;
      this.parentOption = filterModel.parentOption;
      if (this.parentOption)
        this.parentOption.CargarGrilla = true;
      this.cargarGrilla = true;
      this._CargarGrilla();
      // OJO  ACTIVAR LA FUNCIONALIDAD DE
      if (this.comboBoxManager)
        this.comboBoxManager.FilterControlConfigs();
    }
    else {

      this.utility.logger.LogInfoText('filterModel es nulo en CargarGrilla en el componente:  ' + this.componentName);

    }
  }

  public AsignarEventosColumnas(_context: any) {

    setTimeout(() => {

      try {

        if (_context.withBoolColumn && !_context.hideDataTable) {
          (<any>$('.jtoggler')).jtoggler({
            onClick: function (event, resultValue) {

              _context.UpdateProperties(resultValue.index, resultValue.id, resultValue.value, resultValue.prop, false, true, false);
            }
          });
        }
        if (!_context.hideDataTable && (_context.withIcon || _context.withOptions || (this.childOptions && this.childOptions.length > 0))) {

          /*     this.config.Columns.forEach((column: any, index: number) => {

              }); */
          $.each(_context.columns, (index: number, column: any) => {

            if (column.config) {
              if (column.config.childOptions) {
                let filtro: string = "a[name='" + column.config.name + "']";
                $(filtro).off("click");
                $(filtro).on("click", (event) => {

                  var data = _context.utility.GetDatosBotonDataTables(event);
                  var _option = column.config.childOptions[data.data];
                  _context.OnClickChildOptions(_option, null, data.index, column);
                });
              }
              else if (!column.config.isThreeState) {
                let filtro: string = "i[name='" + column.config.name + _context.componentName + "']";
                $(filtro).off("click");
                $(filtro).on("click", (event) => {

                  var data = _context.utility.GetDatosBotonDataTables(event);
                  _context.UpdateProperties(data.index, data.id, data.data, column.config.propName, true, true, true, column);
                });
              }
            }
            else if (column.Actions && column.Actions.length > 0 && column.columnType === DataTableColumnTypes.Icon) {

              try {

                $("[name='" + column.customName + "']").off("click");
                $("[name='" + column.customName + "']").on("click", (event) => {

                  var data = _context.utility.GetDatosBotonDataTables(event);
                  _context._Editar(data.index, data.id, null, true, column);
                });

              }
              catch (error) {
                _context.utility.logger.LogError(error, null, { componentName: _context.componentName, method: 'AsignarEventosColumnas', controlName: _context.name });
              }

            }

          });
        }
        /*   setTimeout(() => {


          }, 20); */

        //if(childOptions)
      }
      catch (error) {
        this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AsignarEventosColumnas', controlName: this.name });
      }
    }, 50);



  }
  public AsignarEventosEditarEliminar(_context: any) {

    //const that =this;
    // let columns = new List<any>(_context.columns);

    // let crudColumns = columns.Where(x => {
    //     return x.columnType == DataTableColumnTypes.Active ||
    //         (x.columns && new List<any>(x.columns).Any(c => { return c.columnType == DataTableColumnTypes.Selection }));
    // }).FirstOrDefault();

    if ((!_context.hideForm && !_context.hideDataTable) && (_context.withDeleteColumn || _context.withActiveColumn || _context.withEditColumn)) {
      setTimeout(() => {

        try {
          //ELiminar eventos

          $("[name='" + _context.nombreBotonEditar + "']").off("click");
          $("[name='" + _context.nombreBotonEliminar + "']").off("click");
          $("[name='" + _context.nombreBotonActivar + "']").off("click");
          // Asignar Eventos
          $("[name='" + _context.nombreBotonEditar + "']").on("click", (event) => {

            var data = _context.utility.GetDatosBotonDataTables(event);
            let column = _context.columns[data.col];

            if (_context.withSelectionInColumn) {
              _context.executeRowEvent = false;
              let _SeleccionCol = $('[id="SeleccionCol' + _context.name + data.index + '"]');
              if (_context.SeleccionCol && _context.SeleccionCol.id == _SeleccionCol[0].id) {
                //if (event.target.checked == true) {

                _SeleccionCol.prop("checked", false);
                //this.SetModeloToNull();
                _context.onActionEventEmitter.emit({ Action: "ClearModel", Table: _context.name });
                //_context.onActionEventEmitter.emit({ Action: FormActionsThroughEmitters.ClearModel, Table: this.name });
                _context.SeleccionCol = null;
                // }
              }
              else {
                _context.SeleccionCol = _SeleccionCol[0];

                _context.Editar(data.index, data.id, column);
              }
            }
            else {

              _context.Editar(data.index, data.id, column);
            }


          });
          if (_context.withDeleteColumn) {
            $("[name='" + _context.nombreBotonEliminar + "']").on("click", (event) => {

              var data = _context.utility.GetDatosBotonDataTables(event);
              let column = _context.columns[data.col];
              _context.Eliminar(data.index, data.id, column);
            });
          }
          if (_context.withActiveColumn) {
            $("[name='" + _context.nombreBotonActivar + "']").on("click", (event) => {

              var data = _context.utility.GetDatosBotonDataTables(event);
              let column = _context.columns[data.col];
              _context.Activar(data.index, data.id, data.data, column);
            });
          }
          if (_context.withOptions) {

            $("[name='" + _context.nombreBotonChildOptions + "']").on("click", (event) => {

              var data = _context.utility.GetDatosBotonDataTables(event);
              let column = _context.columns[data.col];
              //_context.SeleccionarItem(_context.items, data.index, data.id, column);
              //_context.SeleccionCol = $('[id="SeleccionCol' + _context.name + data.index + '"]');
              _context.Editar(data.index, data.id, column);
            });
          }


        }
        catch (error) {
          _context.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AsignarEventosEditarEliminar', controlName: this.name });
        }
      }, 50);

    }


    /*    let seleccinar = columns.Where(x => {
           return x.columnType == DataTableColumnTypes.Selection ||
               (x.columns && new List<any>(x.columns).Any(c => { return c.columnType == DataTableColumnTypes.Selection }))
       }).FirstOrDefault(); */
    if (_context.withSelectionColumn) {
      setTimeout(() => {
        try {

          $("[name='" + _context.nombreBotonSeleccion + "']").off("click");
          $("[name='" + _context.nombreBotonSeleccion + "']").on("click", (event) => {

            var data = _context.utility.GetDatosBotonDataTables(event);
            let column = _context.columns[data.col];
            column.CheckBoxCol = undefined;
            if (column.WithMultiSelection) {
              _context.executeRowEvent = false;
              let CheckBoxCol = $('[id="CheckBoxCol' + _context.name + data.index + '"]');
              column.CheckBoxCol = { Value: CheckBoxCol.prop("checked"), Name: 'CheckBoxCol' + _context.name + data.index };
            }
            _context._Editar(data.index, data.id, null, true, column);
          });

        }
        catch (error) {
          _context.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AsignarEventosEditarEliminar', controlName: this.name });
        }
      }, 50);

    }
    _context.AsignarEventosColumnas(_context);

    //// _context.ExcecuteFuntion(ControllerMethods.LoadedGrid, true, null);
  }


  public CallbackDataTablesError(context: any, error: any) {



  }
  public CallbackDataTablesOk(context: any, items: any) {
    if (context.isHtmlDataTable)
      context.items = items;

    if (context.dataTableMosaic && context.config.WithMosaics)
      context.dataTableMosaic.SetItems(items);
    if (context.pkValueEdit) {
      setTimeout(() => {
        context._Editar(-1, context.pkValueEdit);
      }, 60);
    }
  }

  public GetEditarConfig(): any {
    return {
      nombreBoton: this.nombreBotonEditar,
      title: null,
      toolTip: null,
      icono: null,
      index: 0,
      name: null,
    }
  }
  public GetEliminarConfig(): any {
    return {
      nombreBoton: this.nombreBotonEliminar,
      title: null,
      toolTip: null,
      icono: null,
      index: 1,
      name: null,
    }
  }
  public GetSeleccionarConfig(): any {
    return {
      nombreBoton: this.nombreBotonSeleccion,
      title: null,
      toolTip: null,
      icono: null,
      index: 0,
      name: null,
    }
  }
  public GetActivarInactivarConfig(): any {
    return {
      nombreBoton: this.nombreBotonActivar,
      title: null,
      toolTip: null,
      icono: null,
      index: 1,
      name: null,
    }
  }


  public GetBooleanColumn(title: string, toolTip: string,
    name: string, pkName: string, propName: string,
    validationPropName: string, isThreeState: boolean = false, contextConfigPermisos?: any, _name?: string): any {

    return this.utilityColumns.GetBooleanColumn(this.componentName, title, toolTip, name, pkName, propName,
      validationPropName, isThreeState, (contextConfigPermisos) ? contextConfigPermisos : this, _name);

  }
  public GetColumnaEditarEliminar(icono: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string, isUnion?: boolean): any {

    return this.utilityColumns.GetColumnaEditarEliminar(this.pkName, title, isUnion,
      this.GetEditarConfig(), (isUnion) ? this.GetEliminarConfig() : this.GetActivarInactivarConfig(), null, (contextConfigPermisos) ? contextConfigPermisos : this, this.name);
  }
  public GetColumnaEditarEliminarNombre(_name: string, contextConfigPermisos?: any, isUnion?: boolean): any {

    return this.utilityColumns.GetColumnaEditarEliminar(this.pkName, 'Acciones', isUnion,
      this.GetEditarConfig(), (isUnion) ? this.GetEliminarConfig() : this.GetActivarInactivarConfig(), null, (contextConfigPermisos) ? contextConfigPermisos : this, this.name);
  }
  public GetColumnaEditar(icono: string = null, title: string = null, toolTip: string = null,
    contextConfigPermisos?: any, _name?: string, _column?: any): any {

    return this.utilityColumns.GetColumnaEditar(this.pkName, this.nombreBotonEditar, icono, title, toolTip,
      (contextConfigPermisos) ? contextConfigPermisos : this, _name, _column);
  }
  public GetColumnaEditarNombre(_name: string, contextConfigPermisos?: any, _column?: any): any {

    return this.utilityColumns.GetColumnaEditar(this.pkName, this.nombreBotonEditar, null, null, null,
      (contextConfigPermisos) ? contextConfigPermisos : this, _name, _column);
  }
  public GetColumnaEliminar(icono: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string): any {
    return this.utilityColumns.GetColumnaEliminar(this.pkName, this.nombreBotonEliminar, icono, title, toolTip,
      (contextConfigPermisos) ? contextConfigPermisos : this, _name);
  }
  public GetColumnaActivar(iconoActivo: string = null, iconoInActivo: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string): any {
    return this.utilityColumns.GetColumnaActivar(this.pkName, this.nombreBotonActivar, iconoActivo, iconoInActivo, title, toolTip,
      (contextConfigPermisos) ? contextConfigPermisos : this, _name);
  }
  public GetColumnaSeleccionItem(icono: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string, _column?: any): any {
    return this.utilityColumns.GetColumnaSeleccionItem(this.pkName, this.nombreBotonSeleccion, icono, title, toolTip,
      (contextConfigPermisos) ? contextConfigPermisos : this, _name, _column);
  }
  public GetColumnaSeleccionItemNombre(_name: string, contextConfigPermisos?: any, _column?: any): any {
    return this.utilityColumns.GetColumnaSeleccionItem(this.pkName, this.nombreBotonSeleccion, null, null, null,
      (contextConfigPermisos) ? contextConfigPermisos : this, _name, _column);
  }



  public Exportar(excel: any) {

    console.log('Exportar', excel, this.Pagina);
  }
  public ExportarGrid(excel: any) {

    console.log('Exportar', excel, this.Pagina);
  }
  public ValidarPermisosUsuarioFromItems(dtElements: any, index: any, id: any, permisosField: string): Promise<any> {

    let ok = true;
    const that = this;
    let controlName: string = this.name;
    let _pkName = this.pkName;
    return new Promise((resolve, rejection) => {
      try {
        if (!this.configPermisos)
          resolve(ok);
        else {
          if (dtElements instanceof QueryList) {
            dtElements.forEach((dtElement: DataTableDirective, _index: number) => {
              dtElement.dtInstance.then((dtInstance: any) => {
                if (dtInstance.table().node().id == controlName) {
                  let findItem = that.utility.FindItem(dtInstance.data(), _pkName, id);
                  ok = that.ValidarPermisosUsuario(findItem, permisosField, "ValidarPermisosUsuarioFromItems");
                  resolve(ok);
                }
              });
            });
          }
          else {
            dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
              let findItem = that.utility.FindItem(dtInstance.data(), _pkName, id);
              ok = that.ValidarPermisosUsuario(findItem, permisosField, "ValidarPermisosUsuarioFromItems");
              resolve(ok);
            });
          }
        }
      }
      catch (error) {
        this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'EditarDataTable' });
        rejection(error);
      }
    });
  }
  public ValidarPermisosUsuario(item: any, permisoField: string, tipoObejeto): boolean {

    let ok = true;
    if (this.configPermisos) {
      if (!this.configPermisos.Pagina)
        this.configPermisos.Pagina = this.Pagina;
      if (this.configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Expediente || this.configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Documento)
        permisoField = "Consultar";
      ok = this.utility.TienePemisoUsuarioDos(this.configPermisos, item, permisoField, false, tipoObejeto);
    }
    if (ok == false) {
      //this.SetProcesando(false);
      this.utility.VerModalAlertaPermisos().then(data => { }).catch(err => console.log(err));
    }
    return ok;
  }
  public SeleccionarItemPorItem(findItem: any, index: number, id: any, colIndex?: number, column?: any) {
    const that = this;
    try {
      this.ExcecuteFuntion(ControllerMethods.SelectedItem, false, { index: index, id: id });

      let ok = this.ValidarPermisosUsuario(findItem, 'Editar', "SeleccionarItemPorItem");
      if (ok) {
        findItem.PkValue = findItem[this.pkName];

        if (colIndex > -1 || column) {
          column = (column) ? column : this.columns[colIndex];
          this.helperActions.ExecuteActionsControl(column, false, findItem, this.modelo, this.configPermisos,
            this, this.parentContext, this.onActionEventEmitter, this.componentsContainer, null, null, this.config);
        }
        this.ExcecuteFuntion(ControllerMethods.SelectedItem, true, { findItem: findItem, index: index, id: id });

        // this.utilityColumns.ExecuteActionsColumn(column, true, findItem, this.modelo, this.configPermisos, this, this.parentContext);
        //this.utility.EditItem = findItem;
        //this.utility.router.navigate(['DetalleExpediente']);
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'SeleccionarItem' });
      this.utility.VerModalDanger().then(data => { });
    }
  }
  public SeleccionarItem(items: any, index: number, id: any, column?: any) {

    const that = this;
    try {


      this.ExcecuteFuntion(ControllerMethods.SelectedItem, false, { index: index, id: id, column: column });
      var findItem = this.utility.FindItem(items, this.pkName, id);
      if (!this.MensajeItemNoEncontrado(findItem, id))
        return;
      let ok = this.ValidarPermisosUsuario(findItem, 'Editar', "SeleccionarItem");
      if (ok) {
        findItem.PkValue = findItem[this.pkName];

        if (column.WithMultiSelection) {
          this.MultiSelection(index, column, findItem);
        }

        if (this.config.ConfigControl.QueryConfigMetadata && this.config.ConfigControl.QueryConfigMetadata.Command) {
          this.CargarMetadata(this.config.ConfigControl.QueryConfigMetadata, column, findItem, index, id, true);
        }
        else
          this.ExcecuteActionIntenalEditSelectionSelection(column, findItem, index, id, null, true);

        // this.helperActions.ExecuteActionsControl(column, false, findItem, this.modelo,
        //   this.configPermisos, this, this.parentContext, this.onActionEventEmitter, this.componentsContainer, null, null);

        // this.ExcecuteFuntion(ControllerMethods.SelectedItem, true, { findItem: findItem, index: index, id: id, column: column });


      }

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'SeleccionarItem' });
      this.utility.VerModalDanger().then(data => { });
    }
  }
  public CargarMetadata(queryConfig: any, column: any, findItem, index, id, isSelection: Boolean = false) {

    try {

      let obj = this.utility.GetApiModel('CargarMetadata', '');
      obj.QueryConfig = queryConfig;
      const parameterName = Object.keys(obj.QueryConfig.Entity)[0];
      obj.QueryConfig.Entity[parameterName] = findItem.PkValue;
      obj.QueryConfig.IsMetadata = true;
      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          processMessage: 'Cargando Metadatos...',
          method: 'OnInit'
        }, this.componentName, false, true).then(result => {


          let modeloMetadata: any;

          if (isSelection) {


            modeloMetadata = result.Data;
            modeloMetadata.Items = new List<any>(modeloMetadata.Items);
            this.ExcecuteActionIntenalEditSelectionSelection(column, findItem, index, id, modeloMetadata, isSelection);

          }
          else {
            if (this.isModelContainer == true) {
              this.modelo.modeloMetadata = result.Data;
              this.modelo.modeloMetadata.Items = new List<any>(this.modelo.modeloMetadata.Items);
            }
            else {
              this.modeloMetadata = result.Data[0];
              this.modeloMetadata.Items = new List<any>(this.modeloMetadata.Items);
            }

            this.ExcecuteActionIntenalEditSelectionSelection(column, findItem, index, id, modeloMetadata, isSelection);
          }


        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'CargarMetadata' });
        })
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'CargarMetadata' });
      this.utility.VerModalDanger().then(data => { });
    }

  }
  ExcecuteActionIntenalEditSelectionSelection(column: any, findItem, index, id, modeloMetadata: any, isSelection: Boolean) {

    this.helperActions.ExecuteActionsControl(column, false, findItem, this.modelo,
      this.configPermisos, this, this.parentContext, this.onActionEventEmitter, this.componentsContainer, null, modeloMetadata, this.config);
    if (isSelection) {
      this.ExcecuteFuntion(ControllerMethods.SelectedItem, true, { findItem: findItem, index: index, id: id, column: column });
    } else {
      this.ExcecuteFuntion(ControllerMethods.Edit, true, { findItem: findItem, index: index, id: id, column: column });
    }
  }

  public LiberarRegistro(_id: any, findItem: any, items: any, index: number, id: any, esSeleccion: boolean = false, column?: any) {

    this.currentBusyRecord = null;
    let obj = this.utility.GetApiModel('ProcesoDeRegistroEnGestion', null)
    obj.QueryConfig.Command = 'ProcesoDeRegistroEnGestion';
    obj.QueryConfig.IsSp = true;

    if (id == undefined) { id = null; }

    obj.QueryConfig.NotSetDefault = true;
    obj.QueryConfig.Entity = {
      IdTipoProceso: 2,
      IdEmpresa: -1,
      IdUsuario: -1,
      Maquina: -1,
      IdRegistro: _id,
      TagBusqueda: this.SearchTagRecordManagement
    };

    if (!this.MsgReleaseRecordManagement)
      this.MsgReleaseRecordManagement = 'Liberando Registro......';

    let info = {
      componentName: this.componentName, controlName: 'LiberarRegistro',
      processMessage: this.MsgReleaseRecordManagement
    };
    this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

      if (findItem != null)
        this.ValidarRegistro(findItem, items, index, id, esSeleccion, column);
    }).catch(err => { });
  }
  public _EditarInterno2(findItem: any, items: any, index: number, id: any, esSeleccion: boolean = false, column?: any) {
    if (this.isModelContainer) {
      if (!this.modelo)
        this.modelo = { modelo: null, modeloCasccada: {}, modeloMetadata: {} };
      if (column.ExternalModel) {
        this.modelo[column.ExternalModel] = this.utility.Clone(findItem);
        this.modelo[column.ExternalModel].PkValue = this.modelo[column.ExternalModel][this.pkName];
        this.modelo[column.ExternalModel].CurrentItemIndex = index;
        this.ExcecuteActionIntenalEditSelectionSelection(column, findItem, index, id, null, false);
      }
      else {
        this.modelo.modelo = (this.modelo && this.modelo.modelo) ? this.utility.SetFieldsValue(this.modelo.modelo, findItem) : findItem;
        this.modelo.modelo.PkValue = this.modelo.modelo[this.pkName];
        this.modelo.modelo.CurrentItemIndex = index;
        findItem.PkValue = this.modelo.modelo.PkValue;

        if (this.config.ConfigControl.QueryConfigMetadata && this.config.ConfigControl.QueryConfigMetadata.Command) {
          this.CargarMetadata(this.config.ConfigControl.QueryConfigMetadata, column, findItem, index, id);
        }
        else
          this.ExcecuteActionIntenalEditSelectionSelection(column, findItem, index, id, null, false);
      }
    }
    else {
      this.modelo = (this.modelo) ? this.utility.SetFieldsValue(this.modelo, findItem) : findItem;
      this.modelo.PkValue = this.modelo[this.pkName];
      this.modelo.CurrentItemIndex = index;
      findItem.PkValue = this.modelo.PkValue;

      if (this.config.ConfigControl.QueryConfigMetadata && this.config.ConfigControl.QueryConfigMetadata.Command) {
        this.CargarMetadata(this.config.ConfigControl.QueryConfigMetadata, column, findItem, index, id);
      }
      else
        this.ExcecuteActionIntenalEditSelectionSelection(column, findItem, index, id, null, false);
    }
  }

  public ValidarRegistro(findItem: any, items: any, index: number, id: any, esSeleccion: boolean = false, column?: any) {

    let obj = this.utility.GetApiModel('ProcesoDeRegistroEnGestion', null)
    obj.QueryConfig.Command = 'ProcesoDeRegistroEnGestion';
    obj.QueryConfig.IsSp = true;

    if (id == undefined) { id = null; }

    obj.QueryConfig.NotSetDefault = true;
    obj.QueryConfig.Entity = {

      IdTipoProceso: 3,
      IdEmpresa: -1,
      IdUsuario: -1,
      Maquina: -1,
      IdRegistro: id,
      TagBusqueda: this.SearchTagRecordManagement
    };

    if (!this.MsgBusyRecordManagement)
      this.MsgBusyRecordManagement = 'El registro se encuentra en gestión de otro usuario';

    if (!this.MsgValidationRecordManagement)
      this.MsgValidationRecordManagement = 'Validando ocupación del registro......';

    let info = {
      componentName: this.componentName, controlName: 'ProcesoDeRegistroEnGestion',
      processMessage: this.MsgValidationRecordManagement
    };
    this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

      if (data.Data[0].Valido == 0) {
        this.currentBusyRecord = { action: 'CurrentBusyRecord', findItem: findItem, pkName: this.pkName, id: id };
        this._EditarInterno2(findItem, items, index, id, esSeleccion, column);
        this.onActionEventEmitter.emit({ action: 'CurrentBusyRecord', findItem: findItem, pkName: this.pkName, id: id });
      }
      else {
        //this.utility.VerModalWarning({
        //  titulo: 'Alerta',
         // descripcion: this.MsgBusyRecordManagement
       // });
        //this.onActionEventEmitter.emit({ action: 'RegisterBusy', findItem: findItem, pkName: this.pkName, id: id });
        this.currentBusyRecord = { action: 'CurrentBusyRecord', findItem: findItem, pkName: this.pkName, id: id };
        this._EditarInterno2(findItem, items, index, id, esSeleccion, column);
        this.onActionEventEmitter.emit({ action: 'CurrentBusyRecord', findItem: findItem, pkName: this.pkName, id: id });

      }

    }).catch(err => { });
  }
  public _EditarInterno(items: any, index: number, id: any, esSeleccion: boolean = false, column?: any) {
    const that = this;
    try {


      this.ExcecuteFuntion(ControllerMethods.Edit, false, { index: index, id: id, column: column });

      //var items =  $scope.dtInstance.DataTable.rows().data();//($scope.IsModal ==true)?$scope.Items: $scope.dtInstance.DataTable.rows().data();

      let findItem = this.utility.FindItem(items, this.pkName, id);

      //SearchTagRecordManagement
      if (!this.MensajeItemNoEncontrado(findItem, id))
        return;
      let ok = this.ValidarPermisosUsuario(findItem, 'Editar', "_EditarInterno");
      if (ok) {

        if (column.columnType == DataTableColumnTypes.Edit) {
          if (this.SearchTagRecordManagement) {

            if (this.currentBusyRecord)
              this.LiberarRegistro(this.currentBusyRecord.id, findItem, items, index, id, esSeleccion, column);
            else
              this.ValidarRegistro(findItem, items, index, id, esSeleccion, column);
          }
          else {
            this._EditarInterno2(findItem, items, index, id, esSeleccion, column);
          }
        }
        else {
          this._EditarInterno2(findItem, items, index, id, esSeleccion, column);
          //this.ExcecuteActionIntenalEditSelectionSelection(column, findItem, index, id, null, false);
        }



        ///  this.utilityColumns.ExecuteActionsColumn(column, true, findItem, this.modelo, this.configPermisos, this, this.parentContext);

      }
      else {
        //this.SetProcesando(false);
      }

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: '_EditarInterno' });
      this.utility.VerModalDanger().then(data => { });
    }


    /*  if (this.Pagina.IsModalCrud == true)
         this.ModalCrud(); */
  }

  public EditarDataTable(dtElements: any, index: any, id: any, controlName: string, esSeleccion: boolean = false, column?: any) {


    try {


      if (dtElements instanceof QueryList) {

        dtElements.forEach((dtElement: DataTableDirective, _index: number) => {

          dtElement.dtInstance.then((dtInstance: any) => {
            if (!controlName || dtInstance.table().node().id == controlName) {
              if (esSeleccion)
                this.SeleccionarItem(dtInstance.data(), index, id, column);
              else
                this._EditarInterno(dtInstance.data(), index, id, false, column);

            }
          });
        });
      }
      else {
        dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
          if (esSeleccion)
            this.SeleccionarItem(dtInstance.data(), index, id, column);
          else
            this._EditarInterno(dtInstance.data(), index, id, false, column);

        });
      }

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'EditarDataTable' });
      this.utility.VerModalDanger().then(data => { });
    }
  }

  public _Editar(index: number, id: any, dataTablesName: string = null, esSeleccion: boolean = false, column?: any) {

    //this.UncheckedSelection(index, column);

    if (this.isHtmlDataTable) {
      if (esSeleccion) {

        this.SeleccionarItem(this.items, index, id, column);
      }
      else
        this._EditarInterno(this.items, index, id, false, column);
    }
    else {
      dataTablesName = (dataTablesName) ? dataTablesName : this.name;
      this.EditarDataTable(this.dtElement, index, id, dataTablesName, esSeleccion, column);
    }
  }
  public MultiSelection(index, column, findItem) {

    if (column.ExternalModel && column.ExternalModelProperty) {
      if (!this.modelo[column.ExternalModel])
        this.modelo[column.ExternalModel] = {};
      if (!this.modelo[column.ExternalModel][column.ExternalModelProperty])
        this.modelo[column.ExternalModel][column.ExternalModelProperty] = [];
      this.InternalMultiSelection(this.modelo[column.ExternalModel][column.ExternalModelProperty], column, findItem);
    }
    else if (column.ExternalModel) {
      if (!this.modelo[column.ExternalModel])
        this.modelo[column.ExternalModel] = [];

      this.InternalMultiSelection(this.modelo[column.ExternalModel], column, findItem);
    }
    else
      this.InternalMultiSelection(this.selectedItems, column, findItem);
  }


  InternalMultiSelection(selectedItems: Array<any>, column, findItem) {

    let index: number = selectedItems.findIndex(item => { return item[this.pkName] == findItem[this.pkName]; });

    /*
      AUTOR: MANUEL EDUARDO RODRIGUEZ RAMIREZ
      FECHA: 05-08-2020 18:21
      MOTIVO: Las grillas con datos locales (static list o items json) no estaban guardando la selección de las paginas diferentes a 1 en el modelo asignado
      CASO: SolicitudRadicadoMemorando GridViewDestinatarios

    if (index > -1 && !column.CheckBoxCol.Value)
      selectedItems.splice(index, 1);
    else if (index == -1 && column.CheckBoxCol.Value)
      selectedItems.push(findItem);
     */

    if (index > -1)
      selectedItems.splice(index, 1);
    else if (index == -1)
      selectedItems.push(findItem);

    this.RaiseValidateSaveEvent((selectedItems.length > 0));
    this.onActionEventEmitter.emit({ Action: FormActionsThroughEmitters.MultipleSelection, Table: this.name, Items: selectedItems });
  }

  public ClearSelection(clearModel?: any) {

    if (this.withSelectionColumn) {
      let column = this.config.Controls.find(x => {
        return x.ColumnType == DataTableColumnTypes.Selection ||
          x.ColumnType == 'Selection'
      });
      if (column && !column.KeepSelectedItems) {
        column.CheckBoxCol = null;
        if (column.ExternalModel && column.ExternalModelProperty) {
          if (this.modelo)
            this.modelo[column.ExternalModel][column.ExternalModelProperty] = [];
        }
        else if (column.ExternalModel && this.modelo) {
          if (this.modelo)
            this.modelo[column.ExternalModel] = [];
        }
        else
          this.selectedItems = [];
      }
      if (clearModel) {
        //JUANBR: Esto es un componente general, no debería ir eso ahí
        // if (this.name == "GrillaSolicitudAprobacion") {
        //   this.LimpiarFilterControls();
        //   this.internalFilter = null;
        // } else if (this.name == 'GrillaUsuarioAprobador' || this.name == 'GrillaReasignarUsuario' || this.name == 'GrillaUsuarioConcepto' || this.name == 'GrillaUsuarioVistoBueno') {
        //   this.LimpiarFilterControls();
        //   this.internalFilter = null;
        //   return;
        // }
        if (this.ValidateClearModelOptions())
          return;

        this.onActionEventEmitter.emit({ Action: FormActionsThroughEmitters.ClearModel, Table: this.name });
      }

      //  28/08/2023
      //  Lina Figueredo
      //  Esta validacion se agrega con la finalidad de no hacer requerido el item de la grilla, cuando y se hace el proceso de multiple seleccion en la grilla en diferentes paginas.

      //this.modelo.modelo.length > 0 ? this.RaiseValidateSaveEvent(true): this.RaiseValidateSaveEvent(false);
      /**
       * Inicio Edward Morales 20022024
       * Se comentarea linea anterior ya que genera conflicto con procesos de búsqueda
       * Se valida requerimiento descrito en el comentario de arriba y sigue funcionando correctamente.
       * Dicha codificación no debe ir en este tipo de componentes transversales.
       */
      this.RaiseValidateSaveEvent(false);
      
    }
    else if (this.SeleccionCol) {
      this.SeleccionCol = null;

      if (clearModel) {
        this.onActionEventEmitter.emit({ Action: FormActionsThroughEmitters.ClearModel, Table: this.name });
      }
      this.RaiseValidateSaveEvent(false);
    }
  }

  public ValidateClearModelOptions() {

    if (this.config.ClearModelOptions && this.config.ClearModelOptions.length > 0) {
      let isReturn = false;
      this.config.ClearModelOptions.forEach(option => {

        if (option.Name == 'LimpiarFilterControls')
          this.LimpiarFilterControls();
        else if (option.Name == 'LimpiarInternalFilter')
          this.internalFilter = null;
        else if (option.Name == 'ExcecuteDataSource') {
          this.cargarGrilla = true;
          this.ExcecuteDataSource();
        }
        else if (option.Name == 'SetCargarGrillaTrue')
          this.cargarGrilla = true;
        else if (option.Name == 'SetCargarGrillaFalse')
          this.cargarGrilla = false;
        else if (option.Name == 'Return') {
          isReturn = true;
          return isReturn;
        }
      });


      return isReturn;
    }
    else
      return false;
  }

  public GetSelectedItems() {
    if (this.withSelectionColumn) {
      let column = this.config.Controls.find(x => {
        return x.ColumnType == DataTableColumnTypes.Selection ||
          x.ColumnType == 'Selection'
      });
      if (column) {

        if (column.ExternalModel && column.ExternalModelProperty) {
          return (this.modelo) ? this.modelo[column.ExternalModel][column.ExternalModelProperty] : null;
        }
        else if (column.ExternalModel) {
          return (this.modelo) ? this.modelo[column.ExternalModel] : null;
        }
        else
          return this.selectedItems;
      }
      else
        return this.selectedItems;
    }
    else
      return this.selectedItems;
  }


  public GetItems(): Promise<any> {

    return new Promise((resolve, rejection) => {
      try {

        this.dtElement.forEach((dtElement: DataTableDirective, _index: number) => {

          dtElement.dtInstance.then((dtInstance: any) => {
            if (!this.name || dtInstance.table().node().id == this.name) {
              resolve(dtInstance.data());
            }
          });
        });
      }
      catch (error) {
        this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'GetItems' });
        this.utility.VerModalDanger().then(data => { });
        rejection(error);
      }
    });
  }
  // public UncheckedSelection(index, column) {
  //   if (this.SelectionCheckBoxElements && column && column.WithSelection) {
  //     let count = 0;
  //     this.SelectionCheckBoxElements.forEach(checkBox => {

  //       if (index != count) {
  //         $(checkBox).prop("checked", false);//  checkBox.unCheck();
  //       }
  //       count++;
  //     });
  //   }
  // }
  public Editar(index: number, id: any, column?: any) {
    try {


      if (this.Pagina.VerComfirmarEditar != true)
        this._Editar(index, id, null, false, column);
      else {
        const that = this;
        this.utility.VerModalConfirmacionEditar().then(modelo => {
          if (modelo.dialogResult == true)
            that._Editar(index, id, null, false, column);
        });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, { inde: index, id: id }, { componentName: this.componentName, method: 'Editar' });
      this.utility.VerModalDanger()
    }
  }


  private _Activar(index: number, id: any, activo: boolean, column?: any) {
    //var items = $scope.dtInstance.DataTable.rows().data();
    const that = this;
    var obj = { PkValue: id, IsActive: !activo, MethodNameUI: 'Activar' };
    this.SetExtraDaata(obj);
    try {
      this.ValidarPermisosUsuarioFromItems(this.dtElement, index, id, 'Eliminar').then(result => {
        if (result !== true)
          return;
        this.ExcecuteFuntion(ControllerMethods.Active, false, { obj: obj, column: column });
        this.baseService.Active(obj, null, {
          componentName: this.componentName + 'Controller',
          method: 'Activar', processMessage: 'Activando / Inactivando registro de ' + this.Pagina.Titulo
        },
          this.componentName).then(data => {
            if (that.cargarGrilla)
              that.utility.ReCargarGrilla(that.dtElement, that.name, that.componentName);
            if (this.IsEdit)
              this.modelo.modelo.Activo = !activo;
            that.ExcecuteFuntion(ControllerMethods.Active, true, { obj: obj, column: column });
          });
      }).catch(error => this.utility.VerModalDanger().then(data => { }));
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: '_Activar' });
      this.utility.VerModalDanger().then(data => { });
    }
  }

  public Activar(index: number, id: any, activo: boolean, column?: any) {
    try {
      if (this.Pagina.VerComfirmarEliminar != true)
        this._Activar(index, id, activo)
      else {
        const that = this;
        this.utility.VerModalConfirmacionActivar().then(modelo => {
          if (modelo.dialogResult == true)
            that._Activar(index, id, activo, column)
        });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, { index: index, id: id, activo: activo }, { componentName: this.componentName, method: 'Activar' });
      this.utility.VerModalDanger().then(data => { });
    }

  }

  private _Eliminar(index: number, id: any, column?: any) {

    const that = this;
    var obj = { PkValue: id, MethodNameUI: 'Eliminar' };
    this.SetExtraDaata(obj);
    try {
      this.ValidarPermisosUsuarioFromItems(this.dtElement, index, id, 'Eliminar').then(result => {
        if (result !== true)
          return;
        this.ExcecuteFuntion(ControllerMethods.Delete, false, { obj: obj, column: column });
        this.baseService.Delete(obj, null, {
          componentName: this.componentName + 'Controller',
          method: 'Eliminar', processMessage: this.translate.instant('Eliminado registro de  ') + this.Pagina.Titulo
        },
          this.componentName).then(data => {
            if (that.cargarGrilla)
              that.utility.ReCargarGrilla(that.dtElement, that.name, that.componentName);
            that.ExcecuteFuntion(ControllerMethods.Delete, true, { obj: obj, column: column });
          });
      }).catch(error => this.utility.VerModalDanger().then(data => { }));
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: '_Eliminar' });
      this.utility.VerModalDanger();
    }
  }

  public Eliminar(index: number, id: any, column?: any) {
    try {
      if (this.Pagina.VerComfirmarEliminar != true)
        this._Eliminar(index, id, column)
      else {
        const that = this;
        this.utility.VerModalConfirmacionEliminar().then(modelo => {
          if (modelo.dialogResult == true)
            that._Eliminar(index, id, column)
        });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, { index: index, id: id }, { componentName: this.componentName, method: '_Eliminar' });
      this.utility.VerModalDanger().then(data => { })
    }
  }

  public SetExtraDaata(obj: any) {
    if (this.Pagina.IsCustom) {
      obj.Token = null,
        obj.Form = {
          GUID: this.Pagina.IdPagina,
          Name: this.Pagina.Pagina,
          ObjectType: "Form",
        };

      this.SetWorkFlowExtraDaata(obj, "WorkFlowTaskModel");
      this.SetWorkFlowExtraDaata(obj, "WorkFlowTaskDetailsModel");

    }
  }
  public SetWorkFlowExtraDaata(obj: any, key: any) {
    if (this.Pagina.IsCustom) {

      if (this.modelo[key].IdDetalleFlujo)
        obj.IdDetalleFlujo = this.modelo[key].IdDetalleFlujo;
      if (this.modelo[key].IdFlujo)
        obj.IdFlujo = this.modelo[key].IdFlujo;
      if (this.modelo[key].IdAccion)
        obj.IdAccion = this.modelo[key].IdAccion;
      if (this.modelo[key].IdAccionPost)
        obj.IdAccionPost = this.modelo[key].IdAccionPost;
    }
  }

  public GetValorParaUpdateProperties(campo: string, valor: any, obj: any): any {


    if (typeof valor === 'object' && valor !== null) {
      if (obj)
        $.each(valor, function (key, val) {
          obj.Parameters[key] = val;
        });
    }
    else {
      if (valor == true)
        valor = false;
      else if (valor == false)
        valor = true;
      else if (valor == 'true')
        valor = false;
      else if (valor == 'false') {
        valor = true;
      }
      else if (valor === 'null')
        valor = null;
      obj.Parameters[campo] = valor;
    }
    return valor;
  }
  public InternalUpdateProperties(index: number, id: any, valor: any, campo: string,
    refres: boolean, column?: any) {

    const that = this;
    var obj = { PkValue: id, MethodNameUI: 'UpdateProperties', Parameters: {} };
    try {

      this.ValidarPermisosUsuarioFromItems(this.dtElement, index, id, 'Guardar').then(result => {
        if (result !== true)
          return;
        valor = this.GetValorParaUpdateProperties(campo, valor, obj);


        this.ExcecuteFuntion(ControllerMethods.UpdateProperties, false, { obj: obj, column: column });
        this.baseService.UpdateProperties(obj, null, {
          componentName: this.componentName + 'Controller',
          method: 'UpdateProperties', processMessage: 'Actualizando registro de  ' + this.Pagina.Titulo
        },
          this.componentName).then(data => {
            if (refres == true && that.cargarGrilla)
              that.utility.ReCargarGrilla(that.dtElement, that.name, that.componentName);
            else
              that.utility.RefreshValorDataTable(that.dtElement, index, id, that.name, valor, campo, that.componentName);
            that.ExcecuteFuntion(ControllerMethods.UpdateProperties, true, { obj: obj, column: column });
          });

      }).catch(error => this.utility.VerModalDanger().then(data => { }));
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'InternalUpdateProperties' });
      this.utility.VerModalDanger().then(data => { });
    }
  }
  public UpdateProperties = function (index: number, id: any, valor: any, campo: string, refres: boolean = true,
    esPermisos: boolean = false, verParaPermisos: boolean = true, column?: any) {

    const that = this;

    try {

      if (esPermisos) {
        if (verParaPermisos) {
          this.utility.VerModalConfirmacionPermisos(campo, valor).then(modelo => {

            if (modelo.dialogResult == true) {
              that.InternalUpdateProperties(index, id, valor, campo, refres, column)
            }

          });
        }
        else
          that.InternalUpdateProperties(index, id, valor, campo, refres, column)
      }
      else {
        this.utility.VerModalConfirmacionActualizarPropiedad(campo).then(modelo => {

          if (modelo.dialogResult == true) {
            that.InternalUpdateProperties(index, id, valor, campo, refres, column)
          }

        });
      }

    }
    catch (error) {
      this.utility.logger.LogError(error, { index: index, id: id, valor: valor, campo: campo }, { componentName: this.componentName, method: 'UpdateProperties' });
      this.utility.VerModalDanger().then(data => { });
    }

  }

  MensajeItemNoEncontrado(findItem, id) {
    if (!findItem) {
      this.utility.VerModalWarning({ titulo: 'Alerta', descripcion: 'Item no encontrado por el  ' + id }).then(x => { });
      return false;
    }
    else
      return true;
  }
  ///////// OJO CON ESTPS METODOS SI SE USAN O NOP
  /*
      public InternalSetModelo(_modelo: any, configPermisos?: any) {

          if (configPermisos)
              this.configPermisos = configPermisos;
          this.modelo = (this.modelo) ? this.utility.SetFieldsValue(this.modelo, _modelo) : _modelo;
          this.modelo.PkValue = this.modelo[this.pkName];
          this.SetProcesando(false);
          this.SetFocusModelo();

          this.EditCascading();
          this.ExcecuteFuntion(ControllerMethods.Edit, true, { index: 0, id: this.modelo.PkValue });
      }

      public SetModelo(_modelo: any, configPermisos?: any, timeout?: number) {

          if (timeout) {
              setTimeout(() => {
                  this.InternalSetModelo(_modelo, configPermisos);
              }, timeout);
          }
          else
              this.InternalSetModelo(_modelo, configPermisos);
      } */








  public OnClickChildOptions(option: any, _item: any, index: number, column?: any) {


    this.utility.GetDataTabledtInstance(this.dtElement, this.name, this.componentName).then(dtInstance => {
      var items = dtInstance.data();
      // var findItem = items[index];
      // var filterParent = this.utility.GetFilterParent(option.filterParent, findItem);
      // var mappingFilter = this.utility.GetFilterParent(option.mappingFilter, findItem);
      // option.Option.ConfigControlsToFilter = this.utility.GetControlFilterParent(option.Option.ControlsToFilter, findItem);

      if (option.Option) {
        let windowModel = this.utility.GetModelForFilterparent(option, null, index, items);

        this.ExcecuteFuntion(ControllerMethods.ClickChildOptions, false, { items: items, findItem: windowModel.currentItemParent, filterParent: windowModel.filterParent, mappingFilter: windowModel.mappingFilter, option: option });

        let config: ConfigWindow = new ConfigWindow();
        config.classWindow = 'info';
        config.titleWindow = option.label;
        config.returnInstance = false;
        config.modal = false;
        config.width = 800;
        config.height = 700;
        config.cancel = 'Cerrar';
        config.viewOk = false;
        // let windowModel: any = {
        //     isModal: true,
        //     parentOption: option.Option,
        //     filterParent: filterParent,
        //     mappingFilter: mappingFilter,
        //     generalFilter: option.filter,
        //     hideForm: option.Option.HideForm,
        //     hideDataTable: option.Option.HideDataTable,
        // };

        var componentName: string = (option.Option.Component) ? option.Option.Component : ((option.pagina.ModalUrl) ? option.pagina.ModalUrl : option.pagina.Controlador);
        if (componentName.includes('Controller'))
          componentName = componentName.replace('Controller', 'Component');
        if (!componentName.includes('Component'))
          componentName = componentName + 'Component';

        this.ExcecuteFuntion(ControllerMethods.ClickChildOptions, true, {
          items: items, findItem: windowModel.currentItemParent,
          filterParent: windowModel.filterParent, mappingFilter: windowModel.mappingFilter, option: option
        });

      }
      else {
        let findItem = items[index];
        this.helperActions.ExecuteActionsControl(option, false, findItem, this.modelo,
          this.configPermisos, this, this.parentContext, this.onActionEventEmitter, this.componentsContainer, option, null, column.config);

      }



      /*  this.utility.ModalChild(componentName, config, windowModel).then(dataResult => {
         // console.log(dataResult);

         dataResult.onClosePromise.then(dataResultOnClose => {
           console.log(dataResultOnClose);

           this.ExcecuteFuntion(ControllerMethods.ClickChildOptions, true, { items: items, findItem: windowModel.currentItemParent, filterParent: windowModel.filterParent, mappingFilter: windowModel.mappingFilter, option: option });
         });

       }) */
      // Utilidades.ModalChild(option.pagina.ModalUrl, option.pagina.Controlador, function (result) {;
      //     //result.result  es resultado falso o verdadero del boton ok y cancelar / cerrar;
      //     //var ok =result.result;
      //     //result.modelo  es el modelo del formulario
      //     //var modelo =result.modelo;

      //     ExcecuteFuntion(General.Enums.ControllerMethods.ClickChildOptions, true, { items: items, findItem: findItem, filterParent: filterParent, mappingFilter: mappingFilter, option: option });
      // }, filterParent, mappingFilter, option.filter,option.Option);
    });
  }
  preEdit: (modelo: any) => void;
  postEdit: (modelo: any) => void;
  onEdit: (modelo: any) => void;
  preDelete: (modelo: any) => void;
  postDelete: (modelo: any) => void;
  onDelete: (modelo: any) => void;
  preActive: (modelo: any) => void;
  postActive: (modelo: any) => void;
  onActive: (modelo: any) => void;
  preSeleccionItem: (modelo: any) => void;
  postSeleccionItem: (modelo: any) => void;
  onSeleccionItem: (modelo: any) => void;
  onLoadedGridItem: (modelo?: any) => void;

  preUpdateProperties: () => void;
  postUpdateProperties: () => void;
  onUpdateProperties: (modelo: any) => void;

  preClickChildOptions: () => void;
  postClickChildOptions: () => void;
  onClickChildOptions: (modelo: any) => void;


  @Output() preUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdatePropertiesEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() preEditEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postEditEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() preDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() preActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() preSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() loadedGridEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() preClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();

  public ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null): any {

    var result = null;
    switch (controllerMethod) {
      case ControllerMethods.Edit:

        if (!isPost && this.preEdit)
          this.preEdit(this.modelo);
        else if (isPost == true && this.postEdit)
          this.postEdit(this.modelo);

        if (this.onEdit)
          this.onEdit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });


        if (!isPost && this.preEditEmitter.observers.length > 0)
          this.preEditEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        else if (isPost == true && this.postEditEmitter.observers.length > 0)
          this.postEditEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (this.onEditEmitter.observers.length > 0)
          this.onEditEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        break;
      case ControllerMethods.Delete:

        if (!isPost && this.preDelete)
          this.preDelete(this.modelo);
        else if (isPost == true && this.postDelete)
          this.postDelete(this.modelo);

        if (this.onDelete)
          this.onDelete({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (!isPost && this.preDeleteEmitter.observers.length > 0)
          this.preDeleteEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        else if (isPost == true && this.postDeleteEmitter.observers.length > 0)
          this.postDeleteEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (this.onDeleteEmitter.observers.length > 0)
          this.onDeleteEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        break;
      case ControllerMethods.Active:

        if (!isPost && this.preActive)
          this.preActive(this.modelo);
        else if (isPost == true && this.postActive)
          this.postActive(this.modelo);

        if (this.onActive)
          this.onActive({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });


        if (!isPost && this.preActiveEmitter.observers.length > 0)
          this.preActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        else if (isPost == true && this.postActiveEmitter.observers.length > 0)
          this.postActiveEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (this.onActiveEmitter.observers.length > 0)
          this.onActiveEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        break;
      case ControllerMethods.SelectedItem:

        if (!isPost && this.preSeleccionItem)
          this.preSeleccionItem(data);
        else if (isPost == true && this.postSeleccionItem)
          this.postSeleccionItem(data);

        if (this.onSeleccionItem)
          this.onSeleccionItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });


        if (!isPost && this.preSeleccionItemEmitter.observers.length > 0)
          this.preSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        else if (isPost == true && this.postSeleccionItemEmitter.observers.length > 0)
          this.postSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (this.onSeleccionItemEmitter.observers.length > 0)
          this.onSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        break;
      case ControllerMethods.LoadedGrid:

        if (this.onLoadedGridItem)
          this.onLoadedGridItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });


        if (this.loadedGridEmitter.observers.length > 0)
          this.loadedGridEmitter.emit({ controlName: this.name, isPost: isPost, data: data, controllerMethod: controllerMethod, name: this.name });
        break;
      case ControllerMethods.UpdateProperties:

        if (!isPost && this.preUpdateProperties)
          this.preUpdateProperties();
        else if (isPost == true && this.postUpdateProperties)
          this.postUpdateProperties();

        if (this.onUpdateProperties)
          this.onUpdateProperties({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (!isPost && this.preUpdatePropertiesEmitter.observers.length > 0)
          this.preUpdatePropertiesEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        else if (isPost == true && this.postUpdatePropertiesEmitter.observers.length > 0)
          this.postUpdatePropertiesEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (this.onUpdatePropertiesEmitter.observers.length > 0)
          this.onUpdatePropertiesEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        break;
      case ControllerMethods.ClickChildOptions:

        if (!isPost && this.preClickChildOptions)
          this.preClickChildOptions();
        else if (isPost == true && this.postClickChildOptions)
          this.postClickChildOptions();

        if (this.onClickChildOptions)
          this.onClickChildOptions({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });


        if (!isPost && this.preClickChildOptionsEmitter.observers.length > 0)
          this.preClickChildOptionsEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });
        else if (isPost == true && this.postClickChildOptionsEmitter.observers.length > 0)
          this.postClickChildOptionsEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        if (this.onClickChildOptionsEmitter.observers.length > 0)
          this.onClickChildOptionsEmitter.emit({ modelo: this.modelo, modeloMetadata: this.modeloMetadata, isPost: isPost, data: { data: data, controllerMethod: controllerMethod }, name: this.name });

        break;
    }
    return result;
  }


  public VerEditorModelo(tipo: any, tipoModelo: any = 1) {

    this.utility.VerEditor(tipo, this.modelo);

  }

  public CambioVista() {
    if (this.notView)
      this.VerGrilla();
    else
      this.VerMosaico();
  }
  public VerMosaico() {
    this.notView = true;
    $("#" + this.name + "_wrapper").hide();
  }
  public VerGrilla() {
    this.notView = false;
    $("#" + this.name + "_wrapper").show();
    this.OrderDataTable([0, 'desc']);
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    this.utility.Unsubscribe(this.parentCallRefresh);
    this.utility.Unsubscribe(this.parentClearCall);

  }


  //minimizeMode: boolean = true;
  _colSpan: string;
  resize() {
    if (this.minimizeMode) {
      this._colSpan = this.config.ColSpan;
      this.config.ColSpan = 12;

    }
    else
      this.config.ColSpan = this._colSpan;

    this.minimizeMode = !this.minimizeMode;
  }
  ClearProcessQuickFilters() {
    this.quickFilters.forEach(quickFilter => {
      quickFilter.Selected = false;
    })
    this.quickFilters = [];
    this.ReCargarGrilla();
  }
  ClearProcessSearchIndex()
  {
    this.QueryConfig.SearchIndex = false;
    this.enableSearchIndex = false;
    this.ReCargarGrilla();
  }
  ProcessSearchIndex()
  {
    if(this.searchIndex == "")
      this.utility.VerModalError("Digite el valor a buscar")
    else
    {
      this.QueryConfig.SearchIndex = true;
      this.QueryConfig.SearchIndexValue = this.searchIndex;
      this.ReCargarGrilla();
    }

    
  }
  ProcessQuickFilters(quickFilter: any, index: number) {

    const currentIndex = this.quickFilters.findIndex(x => { return x.Label == quickFilter.Label });
    if (currentIndex > -1) {
      quickFilter.Selected = false;
      this.quickFilters.splice(currentIndex, 1);
    }
    else {
      quickFilter.Selected = true;
      this.quickFilters.push(quickFilter);
    }

  }

  onResize(event) {
    const tables = $('#'+this.name+'_wrapper').find('.ms-Table').each(function() {
      // console.log("table for reload: ", $(this))
      // $(this).DataTable().columns.adjust();
      $(this).DataTable().ajax.reload();
    })
    // console.log('adjust tables: ', tables);
    // console.log('adjust table id: ', $('#'+this.name));
    // $('#'+this.name).DataTable().columns.adjust();
  }

}

