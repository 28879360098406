import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { ConfigWindow } from '../../../models/config-window';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';

import { ContextMenuComponent } from 'ngx-contextmenu';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { PropertyGridComponent } from '../property-grid/property-grid.component';

@Component({
  selector: 'app-property-grid-custom-components',
  templateUrl: './property-grid-custom-components.component.html',
  styleUrls: ['./property-grid-custom-components.component.css']
})
export class PropertyGridCustomComponentsComponent extends PropertyGridComponent implements OnInit {
  @ViewChild("editorAceConfigGeneral", { static: false }) public editorAceConfigGeneral: EditorAceComponent;

  public OnChangeSubControlHandler(event, setUpdate: boolean = true) {


    
    this.subControl.ConfigControl = event;
    if (setUpdate)
      this.UpdateEditor(this.subControl);
  }

  public UpdateEditor(subControl?: any) {
    this._subControl = subControl;
    if (this.subControl) {
      if (this.editorAceConfigGeneral)
        this.editorAceConfigGeneral.Update(this.subControl.ConfigControl);
    }
  }

  actionsDropped(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Actions, event.previousIndex, event.currentIndex);

    } else {

    }
  }
  public AddAction() {
    if (!this.subControl.Actions)
      this.subControl.Actions = [];
    this.subControl.Actions.push(this.utility.GetColumnActionModel());
  }

  public DeleteAction(event) {
    if (this.subControl && this.subControl.Actions) {
      this.subControl.Actions.splice(event, 1);
    }
  }
  DefaultTypesAdminPermitidos: Array<any> = [
    { Name: "General", Label: "General" },
    { Name: "Menu", Label: "Menu" },
    { Name: "Grupo", Label: "Grupo" },
    { Name: "AreasEmpresa", Label: "AreasEmpresa" },
    { Name: "AplicacionPermitida", Label: "AplicacionPermitida" }
  ];
}
