//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:04/37/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component,ViewChild, OnInit, AfterViewInit ,Input,Output,EventEmitter } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';
import { HttpClient, HttpResponse,HttpHeaders } from '@angular/common/http';
import { Paginas,ControllerMethods } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService} from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { ConfigBaseComponent } from '../../../models/config-base-component';


@Component({
  selector: 'app-swith-components',
  templateUrl: './swith-components-component.html',
  styleUrls: []
})
export class SwithComponentsComponent extends ConfigBaseComponent implements OnInit {


}
