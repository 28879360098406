import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utilities } from 'src/app/helpers/utilities';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.css']
})
export class MessageBarComponent implements OnInit {

  @Input() pkName: string = "PkValue";
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() modelContainer: string = "modelo";
  _modelo: any;
  @Input() set modelo(value: any) {
    this._modelo = value;
  }
  get modelo(): any {
    return this._modelo;
  }
  @Input() config: any;
  @Input() name: any;
  title: string = "";
  msg: string = "";
  type: string = "ms-MessageBar";
  icon: string = "ms-Icon ms-Icon--Info";
  constructor(public utility: Utilities, public comService: GeneralComunicationService) { }



  ngOnInit() {
    if (this.config) {
      this.name = this.config.Name;
      this.pkName = (this.config.PkName) ? this.config.PkName : this.pkName;
      if (!this.pkName)
        this.pkName = "PkValue";
      this.modelContainer = (this.config.ModelContainer) ? this.config.ModelContainer : this.modelContainer;
      if (this.config.Delay) {
        setTimeout(() => {
          this.Execute();
        }, this.config.Delay);
      }
      else
        this.Execute();
    }
  }
  public get IsEdit(): boolean {

    if (!this.modelo || !this.modelo[this.modelContainer])
      return false;
    else {
      return (this.modelo[this.modelContainer] && this.modelo[this.modelContainer][this.pkName] !== 0 && this.modelo[this.modelContainer][this.pkName]);
    }

  }
  public get InnerHtmlText(): string {

    if (this.modelo) {

      const data = (this.modelContainer) ? this.modelo[this.modelContainer][this.config.InnerHtmlProperty] : this.modelo[this.config.InnerHtmlProperty];
      return (data)?data:"";
    }
    else
      return ""



  }

  public View() {
    if (this.config) {
      if (this.config.ExecuteOnView)
        this.Execute();
      if (this.config.ViewBySelection) {
        if (this.IsEdit)
          false;
        else
          return true;
      }
      else if (this.config.HideBySelection) {
        if (this.IsEdit)
          return true;
        else false
      }
      else if (this.config.HiddenByDefault)
        return this.config.HiddenByDefault;

    }

    return false;

  }

  SetData(_config) {
    //this.config.HiddenByDefault = false;
    this.type = _config.Type;
    this.icon = _config.Icon;
    this.title = _config.Title;
    if (this.IsEdit && _config.msg && _config.msg.includes("#"))
      this.msg = this.utility.ReplaceTextFromModels(this.modelo, _config.msg);
    else
      this.msg = _config.Msg;
  }
  public Execute() {

    if (this.config) {
      
      if (this.config.Scripts && this.config.Scripts.length > 0) {

        let ok = false;
        this.config.Scripts.forEach(scriptConfig => {
          if (!ok) {

            ok = eval(scriptConfig.Script);
            if (ok) {
              this.SetData(scriptConfig)
            }
          }
        });
      }
      else {
        this.SetData(this.config)

      }
    }
  }
}
