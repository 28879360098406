import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { OperacionesDeSolicitudes } from "src/app/models/general.enum";
import { SolicitudRadicadoFacturaComponent } from "../solicitud-radicado-factura/solicitud-radicado-factura.component";

@Component({
    selector: "app-solicitud-factura-reemplazo",
    templateUrl: "./solicitud-factura-reemplazo.component.html",
    styleUrls: ["./solicitud-factura-reemplazo.component.css"]
})
export class SolicitudFacturaReemplazoComponent extends SolicitudRadicadoFacturaComponent {

    public OnInitEnd() {
        this.postSaveEmitter.subscribe(modelo => {
            
            this.utility.VerModalOk("Se asignó un oficio al Radicado de Factura con código: " + "\n\r" + modelo.data.data.Codigo);
        });
    }

    _Guardar(form: FormGroup, configButtons?: any): void {
        
        if (this.modelo.modelo.DataAdd)
              this.modelo.modelo.DataAdd["OperacionSolicitud"] =
                OperacionesDeSolicitudes.ReemplazarDocumento;
            else
              this.modelo.modelo.DataAdd = {
                OperacionSolicitud: OperacionesDeSolicitudes.ReemplazarDocumento
              }; 
    
        super._Guardar(form, configButtons);
    
    }
}
