import { Component, EventEmitter, OnInit } from "@angular/core";
// import { ControllerMethods, TipoObjetoParaPermisos } from "src/app/models/general.enum";
import { BaseServiceService } from "src/app/services/base-service.service";
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { StaticData } from "src/app/helpers/static-data";
import { Utilities } from "src/app/helpers/utilities";
import { Guid } from "guid-typescript";
import { FileUploaderComponent } from 'src/app/components/controls/file-uploader/file-uploader.component';
import { ControlActions, ControllerMethods, TipoObjetoParaPermisos, EstadosSolicitud, FormActionsThroughEmitters } from "src/app/models/general.enum";
import { debug } from 'util';
import { SolicitudCommonComponent } from '../../common/solicitud-common.component';
import { HelperActions } from "src/app/helpers/helper-actions";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { DocumentTemplateSocketService } from "src/app/services/document-template-socket-service";

@Component({
    selector: "app-solicitud-radicado-memorando",
    templateUrl: "./solicitud-radicado-memorando.component.html",
    styleUrls: ["./solicitud-radicado-memorando.component.css"],
    providers: [DocumentTemplateSocketService]
})
export class SolicitudRadicadoMemorandoComponent extends GeneralFormComponent implements OnInit {


    IdCausalRechazo: any;
    Oficio: FileUploaderComponent;
    calledCommandButton: string;
    configSeleccionDestinatarios: any;
    tipoOperacion: any = null;
    IdTipoDocumentalRadicacion: any;
    CodigoMemorandoAsociado: any;
    TipoDocumental: any;
    configMensajeInfoExpediente: any;
    configMensajeInfoDestinatario: any;
    GrillaUsuarioVistoBueno: any;
    GrillaReasignarUsuario: any;
    GrillSolicitarAprobacion : any;
    GrillSolicitarVistoBueno : any;
    configDestinatario: any;
    IdTipoDocumentalRadicacionAsignacion: any;
    DestinatarioCopiaRespuesta: Array<any> = [];
    errorTipoAnexo: any;
    countTipoAnexos: any;
    AnexosRequeridos: any;
    InfoAnexos:any;
    Observaciones:any;
    openModalBusquedaExpediente: boolean;
    FileBoxes: Array<FileUploaderComponent> = [];

    sent = [];
    received = [];

    constructor(public baseService: BaseServiceService, public utility: Utilities,
        public helperImages: HelperImages,
        public helperRules: HelperRules,
        public helperActions: HelperActions,
        private DocumentTemplateSocketService: DocumentTemplateSocketService) {
        super(baseService, utility, helperImages, helperRules, helperActions);
    
        DocumentTemplateSocketService.messages.subscribe(msg => {
          this.received.push(msg);
          console.log("Response from websocket: " + msg);
        });
    
    }
    
    public OnInitEnd() {

        this.IdTipoDocumentalRadicacion = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoDocumental"; });
        this.IdTipoDocumentalRadicacionAsignacion = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdTipoDocumentalAsignacion"; });
        // this.IdCausalRechazo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCausalRechazo"; });
        this.Observaciones = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Observaciones"; });
        this.TipoDocumental = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TipoDocumental"; });
        this.configSeleccionDestinatarios = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "SeleccionDestinatarios"; });
        this.CodigoMemorandoAsociado = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CodigoMemorandoAsociado"; });
        this.configMensajeInfoExpediente = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "MensajeInfoExpediente"; });
        this.configMensajeInfoDestinatario = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "MensajeInfoDestinatario"; });
        this.GrillaUsuarioVistoBueno = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "GrillaUsuarioVistoBueno"; });
        this.configDestinatario = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Destinatario"; });
        this.AnexosRequeridos = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "AnexosRequeridos"; });
        this.InfoAnexos = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "InfoAnexos"; });
        this.postSaveEmitter.subscribe(modelo => {

            if (!this.IsEdit) {
                this.utility.VerModalOk("Se creó el memorando con código: " + "\n\r" + modelo.data.data.Codigo);
                this.VerSticker(modelo.data.data);
            }
            else
                this.utility.VerModalOk("Se modificó el memorando con código: " + "\n\r" + modelo.data.data.Codigo);
        });
    }

    public PostNgAfterViewInit() {

        this.IdCausalRechazo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCausalRechazo"; });
    }

    public AddComponentInstanceHandler(event) {

        if (event instanceof DataTableComponent) {

            if (event.name == "GrillaReasignarUsuario" || event.name == "GrillaUsuariosRechazo" || event.name == "GrillaUsuarioVistoBueno") {
                event.parentContext = this;
            }
            this.componentsContainer.Tables.push(event);
        }
        else if (event instanceof FileUploaderComponent) {

            //this.Oficio = event;
            if (event.name == "ArchivosAdjuntos")
                this.Oficio = event;
            else
                this.FileBoxes.push(event);
        }
        super.AddComponentInstanceHandler(event);
    }

    public Limpiar(callback: (data: any) => void = null, action?: any) {
        if (this.calledCommandButton == "Reasignar") {
          this.modelo.modeloVirtual.ReasignacionJerarquica = StaticData.GeneralConfig.ConfigUI.ReasignacionJerarquica;
          this.modelo.modelo.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
          this.GrillaReasignarUsuario = this.componentsContainer.Tables.filter(
            (x) => x.name == "GrillaReasignarUsuario"
          )[0];
          this.GrillaReasignarUsuario.ReCargarGrilla();
          this.SetModelToControls();
        }  
        if (this.calledCommandButton == "SolicitarAprobación") {                        
            this.GrillSolicitarAprobacion = this.componentsContainer.Tables.filter(
              (x) => x.name == "GrillaUsuarioAprobador"
            )[0];
            this.GrillSolicitarAprobacion.ReCargarGrilla();
            this.modelo.modeloVirtual.ObligatorioAprobacion = null;
            this.SetModelToControls();
          }    
        if (this.calledCommandButton == "SolicitarVistoBueno") {                        
            this.GrillSolicitarVistoBueno = this.componentsContainer.Tables.filter(
                (x) => x.name == "GrillaUsuarioVistoBueno"
            )[0];
            this.GrillSolicitarVistoBueno.ReCargarGrilla();
            this.modelo.modeloVirtual.ObligatorioVB = null;
            this.SetModelToControls();
        }  
        else{
          super.Limpiar();
        }
      }

    // VerBuscarExpediente() {
    //

    //     const modeloTemp = {
    //         configPermisos: this.GetConfigPermisos(),
    //         config: this.Pagina.ConfigPage.HiddenControls[3],
    //         limpiarInternamente: true
    //     };

    //     const componentName = "DataTableComponent";
    //     const config: ConfigWindow = new ConfigWindow();
    //     config.classWindow = "Info";
    //     config.titleWindow = "Búsqueda de Expedientes";
    //     config.returnInstance = true;
    //     config.modal = false;
    //     config.width = 750;
    //     config.height = 450;
    //     config.viewOk = false;
    //     config.cancel = "Cerrar";
    //     config.closeable = true;
    //     config.resizable = true;
    //     config.draggable = true;

    //     this.utility.OpenWindow(componentName,
    //         config, modeloTemp).then(dataResult => {
    //
    //             dataResult.componentInstance.parentContext = this;

    //             dataResult.componentInstance.postEditEmitter.subscribe(result => {
    //
    //                 if (result.data.data.findItem.ConfiguracionTRD != "") {
    //                     const trd = JSON.parse(result.data.data.findItem.ConfiguracionTRD);
    //                     const tiposDoc = trd["TiposDocumentales"];
    //                     this.configs.ConfigTipoDocumentalRadicacion.Context.SetItemsComboBox(tiposDoc);
    //                 }
    //                 if (this.modelo.modelo["DataAdd"])
    //                     this.modelo.modelo["DataAdd"].OperacionSolicitud = "IncluirExpdiente";
    //                 else
    //                     this.modelo.modelo["DataAdd"] = {
    //                         OperacionSolicitud: "IncluirExpdiente"
    //                     };


    //                 this.modelo.modelo.IdExpediente = result.data.data.findItem.IdExpediente;
    //                 this.modelo.modeloVirtual.NombreExpediente = result.data.data.findItem.Nombre;
    //                 this.modelo.modeloVirtual.CodigoExpediente = result.data.data.findItem.Codigo;
    //                 this.modelo.modeloVirtual.CodExpe = result.data.data.findItem.Codigo;
    //                 this.SetModelToControls();


    //             });

    //             dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
    //                 if (result.Action == "ClearModel") {

    //                     this.modelo.modelo.IdExpediente = null;
    //                     this.modelo.modeloVirtual.NombreExpediente = null;
    //                     this.modelo.modeloVirtual.CodigoExpediente = null;
    //                     this.modelo.modeloVirtual.CodExpe = null;
    //                     this.modelo.modelo.IdTipoDocumental = null;
    //                     this.modelo.modeloVirtual.TipoDocumental = null;

    //                     if (this.modelo.modelo["DataAdd"])
    //                         this.modelo.modelo["DataAdd"]["OperacionSolicitud"] = null;
    //                     this.SetModelToControls();
    //                 }
    //             });

    //             dataResult.componentInstance.loadedGridEmitter.subscribe(result => {
    //             });

    //             dataResult.onClosePromise.then(dataResultOnClose => {
    //                 dataResult.componentInstance.ngOnDestroy();
    //                 dataResult.componentInstance = null;
    //             });

    //         });
    // }

    VerBuscarExpediente() {
        this.openModalBusquedaExpediente = true;

        const modeloTemp = {
            configPermisos: this.GetConfigPermisos(),
            config: this.Pagina.ConfigPage.HiddenControls[0],
            limpiarInternamente: true
        };

        const componentName = "DataTableComponent";
        const config: ConfigWindow = new ConfigWindow();
        config.classWindow = "Info";
        config.titleWindow = "Búsqueda de Expedientes";
        config.returnInstance = true;
        config.modal = false;
        config.width = 750;
        config.height = 450;
        config.viewOk = false;
        config.cancel = "Cerrar";
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;

        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {
                // dataResult.componentInstance.parentContext = this;
                dataResult.componentInstance.postEditEmitter.subscribe(result => {

                    this.modelo.modelo.IdExpediente = dataResult.componentInstance.modelo.IdExpediente;
                    this.modelo.modeloVirtual.NombreExpediente = dataResult.componentInstance.modelo.Nombre;
                    this.modelo.modeloVirtual.CodigoExpediente = dataResult.componentInstance.modelo.Codigo;
                    this.modelo.modelo.IdTipoDocumental = null;
                    this.modelo.modeloVirtual.TipoDocumental = null;
                    this.modelo.modeloVirtual.IdTipoDocumentalAsignacion = null;
                    this.IdTipoDocumentalRadicacion.Hidden = false;
                    this.TipoDocumental.Hidden = true;
                    this.validateRequiredControls(["IdTipoDocumental"],"true");
                    this.SetModelToControls();
                    this.tipoOperacion = "IncluirExpdiente";
                    const trd = JSON.parse(dataResult.componentInstance.modelo.ConfiguracionTRD);
                    const tiposDoc = trd["TiposDocumentales"];
                    this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);
                    this.configMensajeInfoExpediente.Hidden = true;


                });
                dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
                    if (result.Action == FormActionsThroughEmitters.ClearModel) {
                      this.modelo.modelo.IdExpediente = null;
                      this.modelo.modeloVirtual.NombreExpediente = null;
                      this.modelo.modeloVirtual.CodigoExpediente = null;
                      // this.modelo.modeloVirtual.CodExpe = null;
                      this.modelo.modelo.IdTipoDocumental = null;
                      this.modelo.modeloVirtual.TipoDocumental = null;

                      if (this.modelo.modelo["DataAdd"])
                        this.modelo.modelo["DataAdd"][
                          "OperacionSolicitud"
                        ] = null;

                      this.configMensajeInfoExpediente.Hidden = false;
                      this.SetModelToControls();
                    }
                });


                dataResult.componentInstance.loadedGridEmitter.subscribe(result => {


                });

                dataResult.onClosePromise.then(dataResultOnClose => {
                    dataResult.componentInstance.ngOnDestroy();
                    dataResult.componentInstance = null;
                    this.openModalBusquedaExpediente = false;
                });

            });
    }

    /**
     *INICIO
     * 15/12/2020 18:46
     Edward

     Método que valida los controles con su respectivo required y commandBar
     */
    public validateRequiredControls(controlsName: any, yesNo: any){

        const action = {
            ControlDetails: {
                MappingData: [],
                MappingFilter: []
            },
            ControlType: "Action",
            ControlsForHidden: [],
            ControlsToUpdate: controlsName,
            Guid: Guid.create().toString(),
            IsPost: false,
            IsParentModel: false,
            MappingValues: [],
            MappingComponents: [],
            Property: "required",
            PropertyValue: yesNo,
            ResultType: "Url",
            SendQueryString: false,
            TimerAction: 0,
            Type: ControlActions[ControlActions.SetControlProperty]
        };

        const context = this;
        this.helperActions.SetControlsValue(action, context);
        super.SetModelToControls();

    }

    public OnActionEventHandler(event) {

        switch (event.ControlType) {

            case "Button": {
                switch (event.Name) {
                    case "BtnMemorandoAsociado": {
                        this.VerBuscarMemorandoAsociado();
                        break;
                    }
                    case "BtnAprobador": {
                        this.VerBuscarAprobador();
                        break;
                    }
                    case "BtnExpedientes": {
                        if(!this.openModalBusquedaExpediente)
                            this.VerBuscarExpediente();
                        else
                            this.utility.VerModalWarning({ "titulo": "Ventana abierta", "descripcion": "Ya existe una ventana de Búsqueda de Expediente abierta." });

                        break;
                    }
                }
                break;
            }
            case "CommandButton": {

                // this.InfoAnexos.Hidden = true;
                // this.modelo.modeloVirtual.AnexosRequeridos = "ok";
                this.Oficio.RaiseForceValidateSaveEvent(true, false);
                this.calledCommandButton = event.Option.Name;

                /**
                 * INICIO
                 * 15/12/2020 18:20
                 * Edward
                 * método para validar required por commandBar
                 */
                //debugger;
                this.validateRequiredControls(["IdCausalRechazo","Observaciones"],"false"); //Rechazar
                this.validateRequiredControls(["ObligatorioReasignar"],"false"); //Reasignar
                this.validateRequiredControls(["ObligatorioAprobacion"],"false"); //Solicitar Aprobación
                this.validateRequiredControls(["ObligatorioVB"],"false"); //Solicitar Visto Bueno

                //this.utility.validateRequiredControls(["Observaciones","IdCausalRechazo"],true);
                /**
                 * FIN
                 */

                if (this.configDestinatario) {
                    const action = {
                        ControlDetails: {
                            MappingData: [],
                            MappingFilter: []
                        },
                        ControlType: "Action",
                        ControlsForHidden: [],
                        ControlsToUpdate: ["Destinatario"],
                        Guid: Guid.create().toString(),
                        IsPost: false,
                        IsParentModel: false,
                        MappingValues: [],
                        MappingComponents: [],
                        Property: "required",
                        PropertyValue: event.Option.Name === SolicitudCommonComponent.CommandBarActions.Nuevo ? "true" : "false",
                        ResultType: "Url",
                        SendQueryString: false,
                        TimerAction: 0,
                        Type: ControlActions[ControlActions.SetControlProperty]
                    };
                    const context = this;
                    this.helperActions.SetControlsValue(action, context);
                }
                if (this.IdTipoDocumentalRadicacion) {
                    const action = {
                        ControlDetails: {
                            MappingData: [],
                            MappingFilter: []
                        },
                        ControlType: "Action",
                        ControlsForHidden: [],
                        ControlsToUpdate: ["IdTipoDocumental"],
                        Guid: Guid.create().toString(),
                        IsPost: false,
                        IsParentModel: false,
                        MappingValues: [],
                        MappingComponents: [],
                        Property: "required",
                        PropertyValue: event.Option.Name === SolicitudCommonComponent.CommandBarActions.Nuevo ? "true" : "false",
                        ResultType: "Url",
                        SendQueryString: false,
                        TimerAction: 0,
                        Type: ControlActions[ControlActions.SetControlProperty]
                    };
                    const context = this;
                    this.helperActions.SetControlsValue(action, context);
                }/*
                if (this.Observaciones) {
                    const actionObservaciones = {
                        ControlDetails: {
                            MappingData: [],
                            MappingFilter: []
                        },
                        ControlType: "Action",
                        ControlsForHidden: [],
                        ControlsToUpdate: ["Observaciones"],
                        Guid: Guid.create().toString(),
                        IsPost: false,
                        IsParentModel: false,
                        MappingValues: [],
                        MappingComponents: [],
                        Property: "required",
                        PropertyValue: event.Option.Label === "Rechazar" ? "true" : "false",
                        ResultType: "Url",
                        SendQueryString: false,
                        TimerAction: 0,
                        Type: ControlActions[ControlActions.SetControlProperty]
                    };
                    const contextObservaciones = this;
                    this.helperActions.SetControlsValue(actionObservaciones, contextObservaciones);
                }
                if (this.IdCausalRechazo) {
                    const actionIdCausalRechazo = {
                        ControlDetails: {
                            MappingData: [],
                            MappingFilter: []
                        },
                        ControlType: "Action",
                        ControlsForHidden: [],
                        ControlsToUpdate: ["IdCausalRechazo"],
                        Guid: Guid.create().toString(),
                        IsPost: false,
                        IsParentModel: false,
                        MappingValues: [],
                        MappingComponents: [],
                        Property: "required",
                        PropertyValue: event.Option.Label === "Rechazar" ? "true" : "false",
                        ResultType: "Url",
                        SendQueryString: false,
                        TimerAction: 0,
                        Type: ControlActions[ControlActions.SetControlProperty]
                    };
                    const contextIdCausalRechazo = this;
                    this.helperActions.SetControlsValue(actionIdCausalRechazo, contextIdCausalRechazo);
                }
                */
                this.SetModelToControls();
                // // this.configDestinatario.Required = false;
                // this.SetModelToControls();
                this.CodigoMemorandoAsociado.Hidden = true;
                switch (event.Option.Name) {
                    /*case "Autorizar":
                        {


                            this.modelo.modelo.IdUsuarioResponsable = this.modelo.selectedItem.IdUsuarioCreacion;

                            if (this.modelo.modelo.IdExpediente == null || this.modelo.modelo.IdExpediente == "") {
                                this.utility.VerModalError("La solicitud no posee un expediente asignado");
                                return;
                            }
                            else if (this.modelo.modelo.IdEstadoSolicitud == 4) {
                                this.modelo.modelo.DataAdd = { OperacionSolicitud: "Copiar" };
                                this.modelo.modelo.Aprobado = 1;
                                this.modelo.modelo.IdEstadoSolicitud = 7;
                            }
                            else if (this.modelo.modelo.IdEstadoSolicitud == 14)
                                this.modelo.modelo.IdEstadoSolicitud = 15;

                            break;
                        }*/
                    case SolicitudCommonComponent.CommandBarActions.Responder:
                        {
                            this.InfoAnexos.Hidden = true;
                            //this.Oficio.RaiseForceValidateSaveEvent(false, true);
                            this.Oficio.RaiseForceValidateSaveEvent(true, false);
                            const crudButtonSave = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtonSave"; });
                            crudButtonSave.Hidden = false;

                            const previousModel = this.utility.Clone(this.modelo.selectedItem);

                            // this.modelo.modelo.IdSolicitudMemorandoAsociado = previousModel.IdSolicitudMemorandoAsociado;
                            // this.modelo.modelo.IdExpediente = previousModel.IdExpediente;
                            // this.modelo.modelo.IdTipoDocumental = previousModel.IdTipoDocumental;
                            // this.modelo.modelo.IdUsuarioResponsable = null;
                            // this.modelo.modelo.IdEstadoSolicitud = 22;
                            // this.modelo.modelo.IdTipoSolicitud = previousModel.IdTipoSolicitud;
                            // this.modelo.modelo.Activo = 1;


                            // this.modelo.modeloVirtual.CodigoMemorandoAsociado = previousModel.Codigo;
                            // this.modelo.modeloVirtual.TipoDocumental = previousModel.TipoDocumental;
                            // this.modelo.modeloVirtual.NombreExpediente = previousModel.NombreExpediente;
                            // this.modelo.modeloVirtual.CodigoExpediente = previousModel.CodigoExpediente;
                            // this.modelo.modeloVirtual.DestinatariosCopias = new Array();

                            /*
                            this.modelo.modelo =
                            {
                                IdSolicitudMemorandoAsociado: previousModel.IdSolicitudRadicadoMemorando,
                                IdExpediente: previousModel.IdExpediente,
                                IdTipoDocumental: previousModel.IdTipoDocumental,
                                IdUsuarioResponsable: previousModel.IdUsuarioResponsable,
                                IdEstadoSolicitud: 22,
                                IdTipoSolicitud: previousModel.IdTipoSolicitud,
                                Activo: 1

                            };
                            */
                           // Nuevo para no limpiar todo el modelo
                           this.modelo.modelo.IdSolicitudMemorandoAsociado = previousModel.IdSolicitudRadicadoMemorando;
                           this.modelo.modelo.IdExpediente = previousModel.IdExpediente;
                           this.modelo.modelo.IdTipoDocumental = previousModel.IdTipoDocumental;
                           this.modelo.modelo.IdUsuarioResponsable = previousModel.IdUsuarioResponsable;
                           this.modelo.modelo.IdEstadoSolicitud = 22;
                           this.modelo.modelo.IdTipoSolicitud = previousModel.IdTipoSolicitud;
                           this.modelo.modelo.Activo = 1;

                            this.modelo.modeloVirtual =
                            {
                                CodigoMemorandoAsociado: previousModel.Codigo,
                                TipoDocumental: previousModel.TipoDocumental,
                                NombreExpediente: previousModel.NombreExpediente,
                                CodigoExpediente: previousModel.CodigoExpediente,
                                DestinatariosCopias: new Array()
                            };

                            this.IdTipoDocumentalRadicacion.Hidden = true;

                            const CodigoMemorandoAsociado = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CodigoMemorandoAsociado"; });
                            CodigoMemorandoAsociado.Hidden = false;


                            this.modelo.UsuariosCopia = [
                                {
                                    Id: previousModel.IdUsuarioGestionActual,
                                    Nombre: previousModel.UsuarioAprobadorEmail,
                                    Descripcion: previousModel.UsuarioAprobadorNombre,
                                    Grupo: previousModel.UsuarioAprobadorAreaEmpresa
                                }
                            ];

                            this.modelo.modeloVirtual.DestinatariosCopias.push({ IdUsuario: this.modelo.UsuariosCopia[0].Id, Nombre: this.modelo.UsuariosCopia[0].Descripcion, Email: this.modelo.UsuariosCopia[0].Nombre, Grupo: this.modelo.UsuariosCopia[0].Grupo });
                            // $("#GridViewDestinatarios i[data-id=" + this.modelo.UsuariosCopia[0].Id + "] input[type=\"checkbox\"]").attr("checked", "true");
                            // $("#GridViewDestinatarios i[data-id=" + this.modelo.UsuariosCopia[0].Id + "] input[type=\"checkbox\"]").attr("disabled", "true");
                            this.modelo.modeloVirtual.DestinatariosCopias.forEach(element => {
                                element.Disabled = true;
                            });
                            this.SetModelToControls();
                            break;
                        }
                    /**
                     * INICIO
                     *
                     * 26/11/2020 12:45
                     * Edward Morales
                     * Deshabilitar required de IdTipoDocumentalAsignacion (Incluir Expediente)
                     * para poder habilitar botón de Reasignar (Reasignar)
                     */
                    case SolicitudCommonComponent.CommandBarActions.Reasignar: {
                        this.modelo.modeloVirtual.ReasignacionJerarquica = StaticData.GeneralConfig.ConfigUI.ReasignacionJerarquica;
                        this.modelo.modelo.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
                        this.validateRequiredControls(["ObligatorioReasignar"],"true");
                        this.validateRequiredControls(["IdTipoDocumentalAsignacion"],"false");
                        /*
                        const actionTipoDoc = {
                            ControlDetails: {
                                MappingData: [],
                                MappingFilter: []
                            },
                            ControlType: "Action",
                            ControlsForHidden: [],
                            ControlsToUpdate: ["IdTipoDocumentalAsignacion"],
                            Guid: Guid.create().toString(),
                            IsPost: false,
                            IsParentModel: false,
                            MappingValues: [],
                            MappingComponents: [],
                            Property: "required",
                            PropertyValue: "false",
                            ResultType: "Url",
                            SendQueryString: false,
                            TimerAction: 0,
                            Type: ControlActions[ControlActions.SetControlProperty]
                        };
                        const contextTipoDoc = this;
                        this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);
                        */
                        this.modelo.modeloVirtual.ObligatorioReasignar = null;
                        /*
                        const reasignar = {
                            ControlDetails: {
                                MappingData: [],
                                MappingFilter: []
                            },
                            ControlType: "Action",
                            ControlsForHidden: [],
                            ControlsToUpdate: ["ObligatorioReasignar"],
                            Guid: Guid.create().toString(),
                            IsPost: false,
                            IsParentModel: false,
                            MappingValues: [],
                            MappingComponents: [],
                            Property: "required",
                            PropertyValue: event.Option.Label === "Reasignar" ? "true" : "false",
                            //PropertyValue: event.Option.Label === "Solicitar Anulación" ? "true" : "false",
                            ResultType: "Url",
                            SendQueryString: false,
                            TimerAction: 0,
                            Type: ControlActions[ControlActions.SetControlProperty]
                        };*/
                        const context = this;
                        //this.helperActions.SetControlsValue(reasignar, context);
                        super.SetModelToControls();

                        var InputValue = "";
                        var GridName = "GrillaReasignarUsuario";
                        var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
                        this.utility.GetDataTabledtInstance(GridViewClearSearch.dtElement, GridName, this.componentName).then(dtInstance => {
                            dtInstance.search(InputValue).draw();
                        }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })

                        break;
                    }
                    /**
                     * FIN
                     */
                    case "Rechazar":
                        {
                            this.modelo.modelo.IdUsuarioResponsable = this.modelo.selectedItem.IdUsuarioCreacion;

                            this.validateRequiredControls(["IdCausalRechazo","Observaciones"],"true");
                            break;
                        }
                    case SolicitudCommonComponent.CommandBarActions.Nuevo: {
                        this.InfoAnexos.Hidden = true;
                        this.modelo.modeloVirtual.AnexosRequeridos = "ok";
                        this.modelo.modelo.DataAdd = { OperacionSolicitud: "IncluirEnExpediente" };
                        this.InfoAnexos.Hidden = true;
                        this.modelo.modeloVirtual.DestinatariosCopias = new Array();
                        this.IdTipoDocumentalRadicacion.Hidden = false;
                        this.IdTipoDocumentalRadicacion.Required = true;
                        this.TipoDocumental.Hidden = true;
                        this.configMensajeInfoDestinatario.Hidden = false;
                        this.configMensajeInfoExpediente.Hidden = false;
                        this.configDestinatario.Required = true;
                        this.SetModelToControls()
                        //this.Oficio.RaiseForceValidateSaveEvent(false, true);
                        this.Oficio.RaiseForceValidateSaveEvent(true, false);
                        this.validateRequiredControls(["IdTipoDocumentalAsignacion"],"false");
                        /*const actionTipoDoc = {
                            ControlDetails: {
                                MappingData: [],
                                MappingFilter: []
                            },
                            ControlType: "Action",
                            ControlsForHidden: [],
                            ControlsToUpdate: ["IdTipoDocumentalAsignacion"],
                            Guid: Guid.create().toString(),
                            IsPost: false,
                            IsParentModel: false,
                            MappingValues: [],
                            MappingComponents: [],
                            Property: "required",
                            PropertyValue: "false",
                            ResultType: "Url",
                            SendQueryString: false,
                            TimerAction: 0,
                            Type: ControlActions[ControlActions.SetControlProperty]
                        };
                        const contextTipoDoc = this;
                        this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);
                        */
                        break;
                    }
                    case SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente: {
                        //! revisar (por correcciones)
                        let table = <any>$('.ms-Panel table');
                        if (table.length != 0) {
                        table = table.DataTable();
                        table.rows().invalidate().draw();
                        }

                        break;
                    }
                    //case "Solicitar VB": {
                    /**
                     * INICIO
                     * Edward Morales
                     * 30/11/2020 - 18:21
                     * Solicitud cambio label abreviado por completo (VB - Visto Bueno)
                     */
                    case SolicitudCommonComponent.CommandBarActions.SolicitarVistoBueno: {
                        /**
                         * FIN
                         */
                        //debugger;
                        this.modelo.modeloVirtual.ObligatorioVB = null;
                        this.validateRequiredControls(["ObligatorioAprobacion"],"false"); //Solicitar Aprobación
                        this.validateRequiredControls(["ObligatorioVB"],"false");
                        this.validateRequiredControls(["ObligatorioVB"],"true");


                        //this.modelo.modeloVirtual.ObligatorioVB = null;
                       /*
                        const vistoBueno = {
                            ControlDetails: {
                                MappingData: [],
                                MappingFilter: []
                            },
                            ControlType: "Action",
                            ControlsForHidden: [],
                            ControlsToUpdate: ["ObligatorioVB"],
                            Guid: Guid.create().toString(),
                            IsPost: false,
                            IsParentModel: false,
                            MappingValues: [],
                            MappingComponents: [],
                            Property: "required",
                            PropertyValue: event.Option.Label === "Solicitar Visto Bueno" ? "true" : "false",
                            //PropertyValue: event.Option.Label === "Solicitar Anulación" ? "true" : "false",
                            ResultType: "Url",
                            SendQueryString: false,
                            TimerAction: 0,
                            Type: ControlActions[ControlActions.SetControlProperty]
                        };
                        */
                        const context = this;
                        //this.helperActions.SetControlsValue(vistoBueno, context);

                        this.GrillaUsuarioVistoBueno.Fi = true;
                        this.SetModelToControls();

                        var InputValue = "";
                        var GridName = "GrillaUsuarioVistoBueno";
                        var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
                        this.utility.GetDataTabledtInstance(GridViewClearSearch.dtElement, GridName, this.componentName).then(dtInstance => {
                            dtInstance.search(InputValue).draw();
                        }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })

                        break;
                    }
                    case SolicitudCommonComponent.CommandBarActions.VerDetalle: {
                        /**
                         * INICIO
                         * 17/12/2020 08:51
                         * Edward
                         *
                         * Visualizar control de respuesta
                         */
                        if(this.modelo.modeloVirtual.CodigoMemorandoAsociado &&
                        this.modelo.modeloVirtual.CodigoMemorandoAsociado != "" &&
                        //this.modelo.modelo.Codigo!= this.modelo.modeloVirtual.CodigoMemorandoAsociado &&
                        (this.modelo.modelo.IdEstadoSolicitud == 7 ||
                            this.modelo.modelo.IdEstadoSolicitud == 6 ||
                            this.modelo.modelo.IdEstadoSolicitud == 1))
                        {
                        this.CodigoMemorandoAsociado.Hidden = false;
                        }
                        /**
                         * FIN
                         */
                        this.InfoAnexos.Hidden = true;
                        this.modelo.modeloVirtual.AnexosRequeridos = "ok";
                        if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0])
                            (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.visibility = 'hidden';
                            this.AnexosRequeridos.Hidden =  true;
                            this.SetModelToControls()
                        break;
                    }
                    case SolicitudCommonComponent.CommandBarActions.SolicitarAprobacion: {
                        //debugger;
                        this.validateRequiredControls(["ObligatorioAprobacion"],"true"); //Solicitar Aprobación
                        this.validateRequiredControls(["ObligatorioVB"],"false");
                        this.modelo.modeloVirtual.ObligatorioAprobacion = null;

                        /*
                        const solicitarAprobacion = {
                            ControlDetails: {
                                MappingData: [],
                                MappingFilter: []
                            },
                            ControlType: "Action",
                            ControlsForHidden: [],
                            ControlsToUpdate: ["ObligatorioAprobacion"],
                            Guid: Guid.create().toString(),
                            IsPost: false,
                            IsParentModel: false,
                            MappingValues: [],
                            MappingComponents: [],
                            Property: "required",
                            PropertyValue: event.Option.Label === SolicitudCommonComponent.CommandBarActions.SolicitarAprobacion ? "true" : "false",
                            ResultType: "Url",
                            SendQueryString: false,
                            TimerAction: 0,
                            Type: ControlActions[ControlActions.SetControlProperty]
                        };
                        */
                        const context = this;
                        //this.helperActions.SetControlsValue(solicitarAprobacion, context);
                        this.SetModelToControls();

                        var InputValue = "";
                        var GridName = "GrillaUsuarioAprobador";
                        var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
                        this.utility.GetDataTabledtInstance(GridViewClearSearch.dtElement, GridName, this.componentName).then(dtInstance => {
                            dtInstance.search(InputValue).draw();
                        }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })

                        break;
                    }
                    case SolicitudCommonComponent.CommandBarActions.EditarPlantilla:{
                        const previousModel = this.utility.Clone(this.modelo.selectedItem);
                        console.log("aqui es ......"+previousModel.IdSolicitudRadicadoMemorando+"  " +previousModel.IdTipoSolicitud)
                        this.getPlantillaMemorando(previousModel.IdSolicitudRadicadoMemorando,previousModel.IdTipoSolicitud);
                        //this.getPlantillaMemorando(56706,7);
                        break;
                    }
                }
                break;
            }
        }
        if (event.actionType == "EliminarItem") {
            if (this.modelo.modeloVirtual.DestinatariosCopias.length == 0) {
                this.modelo.modeloVirtual.Destinatario = '';
                this.SetModelToControls()
            }
        }

        // if (event.Table == "GrillaUsuarioVistoBueno" || event.Table == "GrillaUsuarioAprobador" || event.Table == "GrillaAsignarExpedienteVincular" || event.Table == "GrillaReasignarUsuario")
        //
        if (
          event.Action === FormActionsThroughEmitters.ClearModel &&
          event.Table == "GrillaAsignarExpedienteVincular"
        ) {
          this.modelo.modelo.IdExpediente = null;
          this.modelo.modeloVirtual.NombreExpediente = null;
          this.modelo.modeloVirtual.CodigoExpediente = null;
          // this.modelo.modeloVirtual.CodExpe = null;
          this.modelo.modelo.IdTipoDocumental = null;
          this.modelo.modeloVirtual.TipoDocumental = null;
          this.modelo.modeloVirtual.IdTipoDocumentalAsignacion = null;
          this.configMensajeInfoExpediente.Hidden = false;
          this.SetModelToControls();
          return;
        }
        if (event.Action === FormActionsThroughEmitters.ClearModel && event.Table == "GrillaReasignarUsuario") {
            this.modelo.modeloVirtual.ObligatorioReasignar = null;
            this.SetModelToControls();
            return
        }
        // else if (event.Action == "ClearModel")
        //     return;

        if (
          event.Table == "GrillaUsuarioVistoBueno" &&
          event.Action === FormActionsThroughEmitters.ClearModel
        ) {
            //debugger;
          this.modelo.modeloVirtual.ObligatorioVB = null;
          this.SetModelToControls();
          return;
        }

        if (
          event.Table == "GrillaUsuarioAprobador" &&
          event.Action === FormActionsThroughEmitters.ClearModel
        ) {
          this.modelo.modeloVirtual.ObligatorioAprobacion = null;
          this.SetModelToControls();
          return;
        }


        if (
          event.Table == "GridViewMemorandosAsignados" &&
          event.Action == FormActionsThroughEmitters.ClearModel
        ) {
            super.Limpiar()
          const crudButtonSave = this.utility
            .GetAllFormControls(this.Pagina.ConfigPage.Controls)
            .find((x) => {
              return x.Name == "CrudButtonSave";
            });
          crudButtonSave.Hidden = false;

          //INICIO
          //Miguel Patiño 30-11-2020
          //Activa BOTON DE TIPO ANEXOS (Adiccionar)
          // if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0]) {
          //     (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.visibility = 'visible';
          // }
          setTimeout(function () {
            if (
              document.getElementsByClassName(
                "float-right mb-4 ng-star-inserted"
              )[0]
            ) {
              (document.getElementsByClassName(
                "float-right mb-4 ng-star-inserted"
              )[0] as HTMLElement).style.visibility = "visible";
            }
          }, 1500);
          //FIN
        }

        if (event.actionType && event.actionType == "OnLoadedComboBoxExtendableTable") {

            this.errorTipoAnexo = false;
            this.countTipoAnexos = event.data.items.length;
            // this.AnexosRequeridos.required = true;
            const actionTipoDoc = {
                ControlDetails: {
                    MappingData: [],
                    MappingFilter: []
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["AnexosRequeridos"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: "true",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty]
            };
            const contextTipoDoc = this;
            this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);

            this.modelo.modeloVirtual.AnexosRequeridos = null;
            this.InfoAnexos.Hidden = false;
            this.SetModelToControls();
            if (this.modelo.modeloVirtual.GrillaAnexos) {
                var numeroItems = this.modelo.modeloVirtual.GrillaAnexos.length - 1;
                if (event.data.items.length != numeroItems) {

                    if (this.modelo.modeloVirtual.GrillaAnexos.length > 1) {
                        for (var prop in this.modelo.modeloVirtual.GrillaAnexos) {
                            if (Number(prop) < numeroItems) {
                                this.errorTipoAnexo = false;
                                if (this.modelo.modeloVirtual.GrillaAnexos[prop].TipoAnexo == null) {
                                    this.errorTipoAnexo = true;
                                    this.modelo.modeloVirtual.GrillaAnexos.splice(this.modelo.modeloVirtual.GrillaAnexos.length - 1);
                                    this.utility.VerModalDanger({ "titulo": "Valor Requerido", "descripcion": "El campo Tipo anexo es requerido." });
                                    break;
                                }
                                if (this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad == null || !this.modelo.modeloVirtual.GrillaAnexos[prop].Cantidad) {
                                    this.errorTipoAnexo = true;
                                    this.modelo.modeloVirtual.GrillaAnexos.splice(this.modelo.modeloVirtual.GrillaAnexos.length - 1);
                                    this.utility.VerModalDanger({ "titulo": "Valor Requerido", "descripcion": "El campo Cantidad tiene que ser requerido o mayor a cero" });
                                    break;
                                }
                            }
                        }
                    }
                    // if (!this.errorTipoAnexo) {

                    //     this.modelo.modeloVirtual.GrillaAnexos.forEach(datamodelo => {
                    //
                    //         event.data.items.forEach(element => {
                    //             if (datamodelo.TipoAnexo == element.Nombre) {
                    //
                    //                 const index = event.data.items.findIndex(x => { return x.Nombre == element.Nombre });
                    //                 event.data.items.splice(index, 1);
                    //             }
                    //         });
                    //     });
                    // }
                    if (this.countTipoAnexos == this.modelo.modeloVirtual.GrillaAnexos.length) {
                        //setTimeout(function () {
                        if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] != undefined) {
                            (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.visibility = 'hidden';
                        }
                        //}, 1500);
                    }
                }

                this.modelo.modeloVirtual.GrillaAnexos.forEach(datamodelo => {

                    event.data.items.forEach(element => {
                      if (datamodelo.TipoAnexo == element.Nombre) {

                        if (!this.errorTipoAnexo)
                        datamodelo.Disabled = true;
                        const index = event.data.items.findIndex(x => { return x.Nombre == element.Nombre });
                        event.data.items.splice(index, 1);
                      }
                    });
                  });
            }

        } else if (event.actionType == "EliminarItem") {
            if (event.config.Label == "Tipos Anexo") {
                setTimeout(function () {
                    if (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] != undefined) {
                        (document.getElementsByClassName('float-right mb-4 ng-star-inserted')[0] as HTMLElement).style.visibility = 'visible';
                    }
                }, 1500);
            }
            var requeridoAnexosEliminacion = true
            var requeridoAnexosControl = 1
            this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
                if (!element.TipoAnexo || !element.Cantidad) {
                    requeridoAnexosEliminacion = false;
                    requeridoAnexosControl = null;
                }
            });
            this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
            this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
            this.SetModelToControls();
        }
        else if (event.actionType && event.actionType == "OnSelectedComboBoxExtendableTable") {

            // this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
            //     if (event.data.item && event.data.item.Nombre)
            //         if (element.TipoAnexo == event.data.item.Nombre)
            //             if (element.Cantidad || element.Cantidad > 0)
            //             {
            //                 this.InfoAnexos.Hidden = true;
            //                 this.modelo.modeloVirtual.AnexosRequeridos = "ok";
            //             }

            //             else
            //             {
            //                 this.InfoAnexos.Hidden = false;
            //                 this.modelo.modeloVirtual.AnexosRequeridos = null;

            //             }
            //         else
            //         {
            //             this.modelo.modeloVirtual.AnexosRequeridos = null;
            //             this.InfoAnexos.Hidden = false;
            //         }

            //     else
            //     {
            //         this.InfoAnexos.Hidden = false;
            //         this.modelo.modeloVirtual.AnexosRequeridos = null;
            //     }

            //     this.SetModelToControls();
            // });

            var requeridoAnexosEliminacion = true
            var requeridoAnexosControl = 1
            this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
              if (event.data.item && event.data.item.Nombre) {
                if (element.TipoAnexo == event.data.item.Nombre) {
                  if (!element.Cantidad || element.Cantidad < 0) {
                    requeridoAnexosEliminacion = false
                    requeridoAnexosControl = null
                  }
                }
              }
              else {
                requeridoAnexosEliminacion = false
                requeridoAnexosControl = null
              }
              // else {
              //   requeridoAnexosEliminacion = false
              //   requeridoAnexosControl = null
              // }
            });
            this.InfoAnexos.Hidden = requeridoAnexosEliminacion;
            this.modelo.modeloVirtual.AnexosRequeridos = requeridoAnexosControl;
            this.SetModelToControls();
        }
        else if (event.actionType && event.actionType == "OnChangeNumberBoxExtendableTable") {

            if (event.data.valueMetodo && event.data.valueMetodo.TipoAnexo && event.data.valueMetodo.Cantidad && event.data.valueMetodo.Cantidad > 0)
            {
                this.InfoAnexos.Hidden = true;
                this.modelo.modeloVirtual.AnexosRequeridos = "ok";
            }
            else
            {
                this.InfoAnexos.Hidden = false;
                this.modelo.modeloVirtual.AnexosRequeridos = null;
            }

            this.SetModelToControls();
        }

        super.OnActionEventHandler(event);
    }
   /**
   * Andres Correa- 19/10/2023
   * @param idSolicitud 
   * @param tipoSolicitud 
    * Historia de HU Edicion Plantillas Ferrocarriles. Agregar funcion Editar Web Socket
    */
    getPlantillaMemorando(idSolicitud : number, tipoSolicitud: number) {
// Base de creacion
        var serviceModel : any;
        var arrayservice = {
          "token": StaticData.Usuario.Token,
          "AppKey": StaticData.AppKey,
          "ExecutionObject": {
            "Name": "Documentos",
            "WebServiceMethod": {
              "Name": "GetPlantillaSolicitud",
              "Parameters": {
                "Codigo": idSolicitud,
                "TipoSolicitud": tipoSolicitud
              }
            }
          }
        };
    
        let info = {
          componentName: this.componentName, controlName: 'ConsultaAccionTipoRespuestaPqrs',
          processMessage: 'Cargando acción respuesta......'
        };
        console.log( "nombre del componente   "+this.componentName);
        let obj = this.utility.GetApiModel("GetPlantillaSolicitud", this.componentName, null, null, this.modelo.modelo);
        this.baseService.DescargarTemplateBase64(arrayservice, null,
          { componentName: 'SolicitudRadicadoMemorandoComponent', method: 'GetPlantillaSolicitud', processMessage: 'Procesando Plantilla....' },
          'TSolicitudRadicadoMemorando', false, true).then(data => {
    
            serviceModel = data.Data[0].Archivo[0];
    
            let message = {
              IdAreaEmpresa: StaticData.Usuario.IdAreaEmpresa,
              IdRadicado: this.modelo.modelo.IdSolicitudRadicadoMemorando,
              Nombre: serviceModel.Archivo,
              Codigo: this.modelo.modelo.Codigo,
              TipoSolicitud: this.modelo.modelo.IdTipoSolicitud,
              TipoRadicado: 'Memorando',
              Token: StaticData.Usuario.Token,
              Appkey: StaticData.AppKey,
              IdUsuario:  StaticData.Usuario.IdUsuario,
              UsuarioAutenticado: StaticData.Usuario.Email,
              Base64: serviceModel.DatosArchivo,
              ConfiguracionWebsocket: {
                RutaArchivosTemporales:"C:\\Temp\\",
                RutaArchivosLogs:"C:\\Temp\\Logs\\",
                ExtensionArchivosWord:".docx",
                NombreDocumentoTemporalWord:"DocumentoTemporalBestDoc",
                UrlServer: StaticData.UrlServer,
                WebSocketUrl:"ws://0.0.0.0:8282"
    
              }
            };
    
            this.sent.push(message);
            this.DocumentTemplateSocketService.messages.next(message);
    
          }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });
    
      }

    GetConfigPermisos() {
        const permissionsConfig: any = {
            TipoObjetoParaPermiso: TipoObjetoParaPermisos.General,
            Pagina: this.Pagina, clear: true, CreatedRowConfig: {}
        };
        return permissionsConfig;
    }

    VerBuscarMemorandoAsociado() {


        const modeloTemp = {
            configPermisos: this.GetConfigPermisos(),
            config: this.Pagina.ConfigPage.HiddenControls[1],
            limpiarInternamente: true
        };

        const componentName = "DataTableComponent";
        const config: ConfigWindow = new ConfigWindow();
        config.classWindow = "Info";
        config.titleWindow = "Relacionar Memorando";
        config.returnInstance = true;
        config.modal = false;
        config.width = 750;
        config.height = 450;
        config.viewOk = false;
        config.cancel = "Cerrar";
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;

        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {


                dataResult.componentInstance.parentContext = this;

                dataResult.componentInstance.postEditEmitter.subscribe(result => {

                    this.modelo.modeloVirtual.CodigoMemorandoAsociado = dataResult.componentInstance.modelo.Codigo;
                    this.modelo.modelo.IdSolicitudMemorandoAsociado = dataResult.componentInstance.modelo.IdSolicitudRadicadoMemorando;

                    this.SetModelToControls();

                });
                dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
                    if (
                      result.Action == FormActionsThroughEmitters.ClearModel
                    ) {

                      this.modelo.modeloVirtual.CodigoMemorandoAsociado = null;
                      this.modelo.modelo.IdSolicitudMemorandoAsociado = null;
                      this.SetModelToControls();
                    }
                });


                dataResult.componentInstance.loadedGridEmitter.subscribe(result => {


                });

                dataResult.onClosePromise.then(dataResultOnClose => {
                    dataResult.componentInstance.ngOnDestroy();
                    dataResult.componentInstance = null;
                });
            });
    }
    VerBuscarAprobador() {


        const modeloTemp = {
            config: this.Pagina.ConfigPage.HiddenControls[2],
            limpiarInternamente: true,
            configPermisos: this.GetConfigPermisos()
        };

        const componentName = "DataTableComponent";
        const config: ConfigWindow = new ConfigWindow();
        config.classWindow = "Info";
        config.titleWindow = "Relacionar Usuario Aprobador";
        config.returnInstance = true;
        config.modal = false;
        config.width = 750;
        config.height = 450;
        config.viewOk = false;
        config.cancel = "Cerrar";
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;

        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {


                dataResult.componentInstance.parentContext = this;

                dataResult.componentInstance.postEditEmitter.subscribe(result => {

                    this.modelo.modeloVirtual.UsuarioAprobador = dataResult.componentInstance.modelo.Nombre + " - " + dataResult.componentInstance.modelo.Email;
                    this.modelo.modelo.IdUsuarioResponsable = dataResult.componentInstance.modelo.IdUsuario;

                    this.SetModelToControls();

                });
                dataResult.componentInstance.onActionEventEmitter.subscribe(result => {
                    if (
                      result.Action == FormActionsThroughEmitters.ClearModel
                    ) {
                      this.modelo.modeloVirtual.UsuarioAprobador = null;
                      this.modelo.modelo.IdUsuarioResponsable = null;
                      this.SetModelToControls();
                    }
                });


                dataResult.componentInstance.loadedGridEmitter.subscribe(result => {


                });

                dataResult.onClosePromise.then(dataResultOnClose => {
                    dataResult.componentInstance.ngOnDestroy();
                    dataResult.componentInstance = null;
                });
            });
    }

    VerSticker(modelo) {


        const container = this.Pagina.ConfigPage.HiddenControls[3];
        container.Controls[2].Text = modelo.Codigo;
        const radicacion = new Date(modelo.FechaCreacion);

        container.Controls[3].Value = `<ul class='ul__style'>
                                    <li>Usuario: ${StaticData.Usuario.Nombre} - ${StaticData.Usuario.Email}<br></li>
                                    <li>Código Solicitud: ${modelo.Codigo}</li>
                                    <li>Fecha: ${radicacion.toLocaleString()}</li>
                                  </ul>`;

        const modeloTemp = {
            configPermisos: this.GetConfigPermisos(),
            config: this.Pagina.ConfigPage.HiddenControls[2],
            limpiarInternamente: true,
            containerControl: container
        };

        const componentName = "DynamicControlsContainerComponent";
        const config: ConfigWindow = new ConfigWindow();
        config.classWindow = "Info";
        config.titleWindow = "Resultado";
        config.returnInstance = true;
        config.modal = false;
        config.width = 750;
        config.height = 450;
        config.viewOk = false;
        config.cancel = "Cerrar";
        config.closeable = true;
        config.resizable = true;
        config.draggable = true;

        this.utility.OpenWindow(componentName,
            config, modeloTemp).then(dataResult => {


                dataResult.componentInstance.parentContext = this;

                // dataResult.componentInstance.AddComponentInstanceHandler(result => {
                //

                // });

                dataResult.onClosePromise.then(dataResultOnClose => {
                    dataResult.componentInstance.ngOnDestroy();
                    dataResult.componentInstance = null;
                });
            });
    }

    GetUsersCopy() {

        // if (this.modelo.UsuariosCopia && this.modelo.UsuariosCopia.length > 0) {
        //     //this.modelo.modelo.DataAdd = { OperacionSolicitud: "Copiar" };
        //     const UsrCopy = this.modelo.UsuariosCopia.map(data => ({ IdUsuario: data.Id, Nombre: data.Descripcion, Email: data.Nombre }));
        //     this.modelo.modelo.DestinatariosCopias = JSON.stringify(UsrCopy);
        // }

        this.modelo.modelo.DestinatariosCopias = JSON.stringify(this.modelo.modeloVirtual.DestinatariosCopias);
    }

    ConvertAnexos() {
        this.modelo.modelo["DataAdd"] = {
            
            Pin: this.modelo.modeloVirtual.Pin,
            IdTipoIdentificacion: 1,
            GuidServicioFirma: this.modelo.modeloVirtual.GuidServicioFirma,
            GuidServicioMail: this.modelo.modeloVirtual.GuidServicioMail,
            GiudPlantilla: this.modelo.modeloVirtual.GiudPlantilla,
            GuidPlantillaFinal: this.modelo.modeloVirtual.GuidPlantillaFinal,
            OperacionSolicitud: this.tipoOperacion,
            Proyector: this.modelo.modelo.NombreProyector,
            IdSolicitudRadicadoMemorando: this.modelo.modelo.IdSolicitudRadicadoMemorando,
            TextoComentario: this.modelo.modeloVirtual.ComentarioRadicado,
            GuardaComentario: "false",            
             AsociacionMultipleNuevo: JSON.stringify(this.modelo.modeloVirtual.GridRadicadosAsociados), //Requerimiento Asociación Radicados
            GuidTemplatePlantilla: this.modelo.modeloVirtual.GiudPlantilla //Guid para obtener la plantilla(template) del radicado
          };
        if (this.modelo.modeloVirtual.GrillaAnexos && this.modelo.modeloVirtual.GrillaAnexos.length > 0) {
            this.modelo.modelo.Anexos = JSON.stringify(this.modelo.modeloVirtual.GrillaAnexos);
        }
    }

    _Guardar(form: FormGroup, configButtons?: any) {


        this.GetUsersCopy();
        this.ConvertAnexos();
        if (this.modelo.modelo.IdEstadoSolicitud == 22) // Respuesta de un memorando
        {
            if (this.modelo.modelo.DataAdd)
                this.modelo.modelo.DataAdd["OperacionSolicitud"] = "RespuestaMemorando";
            else
                this.modelo.modelo.DataAdd = { OperacionSolicitud: "RespuestaMemorando" };
        }
        if (this.calledCommandButton == 'Rechazar') {
            this.modelo.modelo.IdUsuarioResponsable = this.modelo.selectedItem.IdUsuarioCreacion;
        }
// Ajuste para command bar autorizar
        if (this.calledCommandButton == 'Autorizar') {
            this.modelo.modelo.IdUsuarioResponsable = this.modelo.selectedItem.IdUsuarioCreacion;
            if (this.modelo.modelo.IdExpediente == null || this.modelo.modelo.IdExpediente == "") {
                this.utility.VerModalError("La solicitud no posee un expediente asignado");
                return;
            }
            else if (this.modelo.modelo.IdEstadoSolicitud == 4) {
                this.modelo.modelo.DataAdd = { OperacionSolicitud: "Copiar" };
                this.modelo.modelo.Aprobado = 1;
                this.modelo.modelo.IdEstadoSolicitud = 7;
            }
            else if (this.modelo.modelo.IdEstadoSolicitud == 14)
                this.modelo.modelo.IdEstadoSolicitud = 15;


        }

        /*if (this.calledCommandButton == 'Responder') {

            debugger;
        }*/
        //
        let obj: any = null;
        try {
            if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
                return;
            if (form.invalid)
                return;

            //this.modelo.modelo["DataAdd"] = {
            //    OperacionSolicitud: this.tipoOperacion,
            //};


            const _isEdit = this.IsEdit;
            // const that = this;
            const processMessage = (this.modelo.modelo.PkValue == "0" || this.modelo.modelo.PkValue == "") ? "Creando Registro de " + this.Pagina.Titulo : "Actualizando registro de " + this.Pagina.Titulo;

            if (configButtons && configButtons.Actions) {

                this.helperActions.ExecuteActionsControl(configButtons,
                    false, null, this.modelo, this.permissionsConfig,
                    this, null, new EventEmitter<any>(), this.componentsContainer,
                    null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
            }

            this.ExcecuteFuntion(ControllerMethods.Save, false);


            if (_isEdit)
                this.FireFormEvent({ Name: "OnPreUpdate", modelo: this.modelo });
            else
                this.FireFormEvent({ Name: "OnPreInsert", modelo: this.modelo });

            if (this.modelo.modeloMetadata.Items.Count() > 0) {
                this.modelo.modeloMetadata.IdParentObject = this.modelo.modelo.PkValue;
                this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
                this.modelo.modelo.Metadata = this.utility.Clone(this.modelo.modeloMetadata);
                this.modelo.modelo.Metadata.Items = this.modelo.modelo.Metadata.Items.ToArray();
            }
            //this.modelo.modeloMetadata.Id = this.modelo.modelo.PkValue;
            // this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
            // this.modelo.modelo.Metadata = this.modelo.modeloMetadata;

            //Actualizacion de solicitud
            if (this.modelo.modelo.IdEstadoSolicitud == 1 && this.modelo.modelo.PkValue > 0)
                this.modelo.modelo.IdEstadoSolicitud = 25;


            obj = this.utility.GetApiModel("Guardar", this.componentName, null, null, this.modelo.modelo);

            let isGeneralSave = true;

            if (this.componentsContainer.FileBox && this.componentsContainer.FileBox.isSelectedFiles()) {

                isGeneralSave = false;
                let externalFiles: any;
                if (this.componentsContainer.FileBoxWorkFlow != null) {
                    externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
                }
                this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);

            }


            // ////////Meter validacion antes de este
            if (isGeneralSave) {
                this.baseService.InsertUpdate(obj, form, {
                    componentName: this.componentName + "Controller",
                    method: "Guardar", processMessage: processMessage
                },
                    false, this.componentName, false, true).then(data => {
                        //this.modelo.modelo = data.Data != null ? data.Data : this.modelo.modelo;

                        this.FinGuardar(data.Data);

                        if (_isEdit)
                            this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
                        else
                            this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });
                        this.ReCargarGrilla();
                        super.Limpiar();

                    }).catch(error => { this.SetProcesando(false); });
            }


        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: "_Guardar" });
            this.utility.VerModalDanger().then(data => { });
            this.SetProcesando(false);
        }
    }

    public OnSelectedComboBox(item: any, controlName: string, config: any) {

        switch (controlName) {
            case "IdTipoDocumental":
            case "TipoDocumentalRadicacion": {
                this.modelo.modelo.IdTipoDocumental = item.IdTipoDocumental;
                this.modelo.modeloVirtual.TipoDocumental = item.Nombre;
                break;
            }
            case "IdTipoDocumentalAsignacion": {
                this.modelo.modelo.IdTipoDocumental = item.IdTipoDocumental;
                this.modelo.modeloVirtual.TipoDocumental = item.Nombre;
                break;
            }
            case "SeleccionDestinatarios": {

                if (item && this.modelo.modeloVirtual.DestinatariosCopias &&
                    !this.modelo.modeloVirtual["DestinatariosCopias"].find(x => { return x.IdUsuario == item.IdUsuario; })) {
                    this.modelo.modeloVirtual["DestinatariosCopias"].push({ IdUsuario: item.IdUsuario, Nombre: item.Nombre, Email: item.Email, Grupo: item.Grupo });
                    this.modelo.modeloVirtual.Destinatario = 'ok';
                    this.configMensajeInfoDestinatario.Hidden = true;
                    // (<HTMLInputElement> document.getElementById("MensajeInfoDos")).hidden = true;
                    this.SetModelToControls()
                }
                else {
                    // (<HTMLInputElement>document.getElementById("Destinatario")).value = null;
                    // (<HTMLInputElement> document.getElementById("MensajeInfoDos")).hidden = false;

                    this.configMensajeInfoDestinatario.Hidden = true;
                }


            }
            // if (item){
            // (<HTMLInputElement>document.getElementById("Destinatario")).value = item.Nombre;
            // (<HTMLInputElement> document.getElementById("MensajeInfoDos")).hidden = true;
            // }else{
            // (<HTMLInputElement>document.getElementById("Destinatario")).value = null;
            // (<HTMLInputElement> document.getElementById("MensajeInfoDos")).hidden = false;

            // }
            // break;
            // }
        }
        super.OnSelectedComboBox(item, controlName, config);
    }

    public OnDataTableEditHandler(event) {


        switch (event.name) {
            case "GridViewMemorandosAsignados":
                {


                    //Config IdCausalRechazo
                    this.configs.ConfigIdCausalRechazo.Params = { IdEmpresa: -1, IdTipoSolicitud: event.data.data.findItem.IdTipoSolicitud };
                    this.configs.ConfigIdCausalRechazo.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);

                    this.modelo.selectedItem = this.utility.Clone(event.data.data.findItem);

                    this.modelo.modeloVirtual.CodigoMemorandoAsociado = event.data.data.findItem.CodigoMemorandoAsociado;
                    this.modelo.modeloVirtual.UsuarioAprobador = event.data.data.findItem.UsuarioAprobador;

                    this.IdTipoDocumentalRadicacion.Hidden = true;
                    this.IdTipoDocumentalRadicacion.Required = false;
                    this.TipoDocumental.Hidden = false;

                    this.modelo.modeloVirtual.NombreExpediente = event.data.data.findItem.NombreExpediente;
                    this.modelo.modeloVirtual.CodigoExpediente = event.data.data.findItem.CodigoExpediente;
                    this.modelo.modeloVirtual.TipoDocumental = event.data.data.findItem.TipoDocumental;

                    this.configMensajeInfoExpediente.Hidden = true
                    this.modelo.modeloVirtual.TipoDocumentalRadicacion = event.data.data.findItem.IdTipoDocumental;
                    const crudButtonSave = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CrudButtonSave"; });

                    if (event.data.data.findItem.Aprobado)
                        crudButtonSave.Hidden = true;
                    else if (!event.data.data.findItem.Aprobado)
                        crudButtonSave.Hidden = false;

                    if (this.modelo.modelo.DestinatariosCopias != undefined) {
                        this.modelo.modeloVirtual.DestinatariosCopias = JSON.parse(this.modelo.modelo.DestinatariosCopias);
                    }
                    if (this.modelo.modeloVirtual.DestinatariosCopias.length > 0) {
                        this.modelo.modeloVirtual.Destinatario = 'ok';
                        this.configMensajeInfoDestinatario.Hidden = true;
                        // (<HTMLInputElement> document.getElementById("MensajeInfoDos")).hidden = true;
                    }

                    const actionTipoDoc = {
                        ControlDetails: {
                            MappingData: [],
                            MappingFilter: []
                        },
                        ControlType: "Action",
                        ControlsForHidden: [],
                        ControlsToUpdate: ["IdTipoDocumentalAsignacion"],
                        Guid: Guid.create().toString(),
                        IsPost: false,
                        IsParentModel: false,
                        MappingValues: [],
                        MappingComponents: [],
                        Property: "required",
                        PropertyValue: this.modelo.modelo.IdTipoDocumental === "" ? "true" : "false",
                        ResultType: "Url",
                        SendQueryString: false,
                        TimerAction: 0,
                        Type: ControlActions[ControlActions.SetControlProperty]
                    };
                    const contextTipoDoc = this;
                    this.helperActions.SetControlsValue(actionTipoDoc, contextTipoDoc);


                    super.OnDataTableEditHandler(event);

                    break;
                }
            case "GrillaAsignarExpedienteVincular":
                {
                    this.modelo.modeloVirtual.NombreExpediente = event.modelo.expediente.Nombre;
                    this.modelo.modeloVirtual.CodigoExpediente = event.modelo.expediente.Codigo;
                    this.modelo.modelo.DataAdd = { OperacionSolicitud: "IncluirEnExpediente" };
                    if (event.data.data.findItem.ConfiguracionTRD == "")
                        event.data.data.findItem.ConfiguracionTRD = "{\"TiposDocumentales\":[]}";
                    const trd = JSON.parse(event.data.data.findItem.ConfiguracionTRD);
                    const tiposDoc = trd["TiposDocumentales"];
                    this.configs.ConfigIdTipoDocumentalAsignacion.Context.SetItemsComboBox(tiposDoc);
                    // const action = {
                    //     ControlDetails: {
                    //         MappingData: [],
                    //         MappingFilter: []
                    //     },
                    //     ControlType: "Action",
                    //     ControlsForHidden: [],
                    //     ControlsToUpdate: ["IdTipoDocumentalAsignacion"],
                    //     Guid: Guid.create().toString(),
                    //     IsPost: false,
                    //     IsParentModel: false,
                    //     MappingValues: [],
                    //     MappingComponents: [],
                    //     Property: "required",
                    //     PropertyValue: "true",
                    //     ResultType: "Url",
                    //     SendQueryString: false,
                    //     TimerAction: 0,
                    //     Type: ControlActions[ControlActions.SetControlProperty]
                    // };
                    // const context = this;
                    // this.helperActions.SetControlsValue(action, context);
                    this.modelo.modelo.IdExpediente = event.modelo.expediente.IdExpediente;
                    break;
                }

            case "GrillaUsuarioVistoBueno": {
                //debugger;
                this.modelo.modeloVirtual.ObligatorioVB = 1;
                this.modelo.modelo.IdUsuarioResponsable = event.data.data.findItem.IdUsuario;
                this.SetModelToControls();
                break;

            }
            case "GrillaUsuarioAprobador": {
                this.modelo.modeloVirtual.ObligatorioAprobacion = 1;
                this.modelo.modelo.IdUsuarioResponsable = event.data.data.findItem.IdUsuario;
                this.SetModelToControls();
                break;
            }
            case "GrillaReasignarUsuario": {
                this.modelo.modeloVirtual.ObligatorioReasignar = 1;
                this.SetModelToControls();
                break;
            }
        }
        if (event.Table && event.Table == "GridViewMemorandosAsignados") {
            return;
        }
        //INICION
        //30-11-2020
        //Miguel Patiño

        if (event.modelo.modelo.Anexos) {
            this.modelo.modeloVirtual.GrillaAnexos = JSON.parse(event.modelo.modelo.Anexos);
            this.modelo.modeloVirtual.GrillaAnexos.forEach(element => {
                element.Disabled = true;
                element.DisabledDelete = true;
            });
        } else {
            this.modelo.modeloVirtual.GrillaAnexos = null;
        }
        //FIN

        super.OnDataTableEditHandler(event);
    }

    // public OnSelectionHandler(event) {

    //     super.OnSelectionHandler(event);
    //
    //     switch (event.name) {
    //         case "GridViewDestinatarios": {
    //             this.GetUsersCopy();
    //             break;
    //         }
    //     }

    // }

}
