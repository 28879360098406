import {
  Component, ComponentFactoryResolver, Injector, OnInit, ViewChild, ViewContainerRef, Input, Output, EventEmitter
} from '@angular/core';
import { ICloseEvent } from './closewindow.event';
import { IOpenEvent } from './openwindow.event';
import { DomSanitizer } from '@angular/platform-browser';
import { IAfterComponetCreateEvent } from './aftercomponentcreate.event';
import { ConfigWindow } from '../../models/config-window';
import { ConsoleLogTableComponent } from '../console-log/console-log-table/console-log-table.component';


@Component({
  selector: 'app-modal-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
})

export class WindowComponent<T> implements OnInit {

  get index(): number {
    return this._index;
  }

  public onClose: (dialogResult: boolean, data: any) => void;
  public static COUNTER = 0;
  private _title: string;
  private _bodyComponent: any;
  private _bodyHtml: any = '';
  private _closeEvenet: ICloseEvent;
  private _afterComponentCreate: IAfterComponetCreateEvent<T>;
  private _openEvenet: IOpenEvent;
  private _width: number;
  private _height: number;
  private _left: number;
  private _top: number;
  private _bottom: number;
  public modal: boolean;
  public draggable: boolean;
  public closeable: boolean;
  public resizable: boolean;
  public expandMode: boolean;
  public minimizeMode: boolean;
  public collapseMode: boolean;
  private _index: number;
  public id: string;
  private expandData: {};
  private previousEvent: MouseEvent;
  public opacity: any = 1;
  public displayContent: string;
  public viewComponentHeader: boolean = null;
  public viewComponentTitle: boolean = null;
  public config: ConfigWindow;
  const_Z_Index = 1070;


  @Input() startMinimized: boolean = false;
  @Input() isFloatPanel: boolean = false;
  @Input() name: string;
  @Output() onInitialized = new EventEmitter<any>();
  @Input() public _config: any;
  @Input() position: any;
  @Input() minimizeOnClose: boolean = false;
  @Input() isOverlay: boolean = false;
  @ViewChild('body', { read: ViewContainerRef, static: true }) vc: ViewContainerRef;

  constructor(private sanitizer: DomSanitizer,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector) {
    this._width = 500;
    this._height = 200;
    this._left = 50;
    this._top = 50;

    this.draggable = true;
    this.modal = false;
    this.closeable = true;
    this.resizable = true;
    this.expandMode = false;
    this.minimizeMode = false;
    this.collapseMode = false;
    this.displayContent = 'block';
    this._index = document.getElementsByTagName('app-modal-window').length + this.const_Z_Index;

    this.id = 'modal-' + WindowComponent.COUNTER++;
  }

  ngOnInit() {

    if (this._config)
      this.name = this._config.Name;
    if (this.onInitialized.observers.length && this.isFloatPanel) {
      setTimeout(() => {
        this.onInitialized.emit(this);

      }, 1000);
    }


    if (this.startMinimized) {
      setTimeout(() => {
        this.minimize();
      }, 40);
    }

    if (this.position) {

      switch (this.position) {
        case 'none':

          break;
        case 'bottom_left':
          this._top = window.innerHeight - 40;
          this._left = 40;
          break;
        case 'bottom_right':
          this._top = window.innerHeight - 40;
          this._left = window.innerWidth - 40;
          break;
        case 'bottom_center':
          this._top = window.innerHeight - 40;
          this._left = 200;
          break;
        case 'top_left':
          this._top = 40;
          this._left = 40;
          break;
        case 'top_righ':
          this._top = 40;
          this._left = window.innerWidth - 40;
          break;
        case 'top_center':
          this._top = 40;
          this._left = 200;
          break;
      }
      /*       { Name: 'none', Label: 'Por defecto', Top: 40, Left: 40 },
            { Name: 'bottom_left', Label: 'Inferior Izquierda', Top:window.innerHeight- 40, Left: 40 },
            { Name: 'bottom_right', Label: 'Inferior Derecha', Top: window.innerHeight- 40, Left: window.innerWidth- 40 },
            { Name: 'bottom_center', Label: 'Inferior Centro', Top: window.innerHeight- 40, Left: 200 },
            { Name: 'top_left', Label: 'Superior Izquierda', Top: 40, Left: 40 },
            { Name: 'top_righ', Label: 'Superior Derecho', Top: 40, Left:  window.innerWidth- 40 },
            { Name: 'top_center', Label: 'Superior Centro', Top: 40, Left: 200 }, */

    }
  }


  public dragTesr() {
    this.draggable = !this.draggable;
  }

  public modalTest() {
    this.modal = !this.modal;
  }
  public resizeTest() {
    this.resizable = !this.resizable;
  }
  public resize() {
    if (!this.resizable) {
      return;
    }
    if (!this.expandMode) {
      this.expandData = {
        left: this.left,
        top: this.top,
        width: this.width,
        height: this.height,
        draggable: this.draggable,
        displayContent: this.displayContent
      };
      this._left = 0;
      this._top = 12;
      this.width = window.innerWidth - 20;
      this.height = window.innerHeight - 20;
      this.draggable = false;
      this.displayContent = 'block';
    } else {
      this._left = this.expandData['left'];
      this._top = this.expandData['top'];
      this.width = this.expandData['width'];
      this.height = this.expandData['height'];
      this.draggable = this.expandData['draggable'];
      this.displayContent = this.expandData['displayContent'];
    }
    this.expandMode = !this.expandMode;
    //! revisar (por correcciones)
    let tables = <any>$('app-modal-window table'); //* get all tables in modal window
    if (tables.length != 0) {
      // console.log("tables: ", tables);
      $.each(tables, function(index, value) {
        if (value.attributes.datatable)
        $(value).DataTable().ajax.reload();
      })
    }
  }
  public minimize() {
    if (!this.resizable) {
      return;
    }
    if (!this.minimizeMode) {
      //Para que mantenga el tamaño normal
      if (this.expandMode) {
        this.resize();
      }
      this.expandData = {
        left: this.left,
        top: this.top,
        bottom: this.bottom,
        width: this.width,
        height: this.height,
        draggable: this.draggable,
        displayContent: this.displayContent
      };
      this._left = 0;
      this._top = window.innerHeight - 55;
      this._bottom = 0;
      this.width = 200;// window.innerWidth;
      this.height = window.innerHeight;
      this.draggable = true;
      this.displayContent = 'none';
      $('#' + this.id).toggleClass('close-modal');
    } else {
      this._left = this.expandData['left'];
      this._top = this.expandData['top'];
      this._bottom = this.expandData['bottom'];
      this.width = this.expandData['width'];
      this.height = this.expandData['height'];
      this.draggable = this.expandData['draggable'];
      this.displayContent = this.expandData['displayContent'];
      $('#' + this.id).toggleClass('close-modal');
    }

    this.minimizeMode = !this.minimizeMode;
  }
  public collapse() {
    if (!this.resizable) {
      return;
    }
    if (!this.collapseMode) {
      this.expandData = {
        left: this.left,
        top: this.top,
        bottom: this.bottom,
        width: this.width,
        height: this.height,
        draggable: this.draggable,
        displayContent: this.displayContent
      };
      this.height = 0;
      this.displayContent = 'none';
    } else {
      this.height = this.expandData['height'];
      this.displayContent = 'block';
    }
    this.collapseMode = !this.collapseMode;
  }

  public SetCurrentBottom() {
    var $bot, top, bottom;
    $bot = $('#' + this.id);
    top = $bot.position().top;
    bottom = top + $bot.outerHeight(true);
    this._bottom;
  }
  /* Close modal window immediate */
  public closeModal(dialogResult: boolean): void {

    if (this._closeEvenet)
      this._closeEvenet.close();
    else {
      //this.minimize();
      if (this._config) {
        if (this._config.MinimizeOnClose)
          this.minimize();
        else
          this._config.Hidden = true;
      }
      else if (this.minimizeOnClose)
        this.minimize();
    }
    if (this.onClose)
      this.onClose(dialogResult, {});

  }

  /* Append window to the DOM */
  public showWindow(): void {
    this._openEvenet.open();
  }

  public startResize() {
    if (!this.resizable) {
      return;
    }
    this.calculateIndex();
    this.previousEvent = null;
    window.addEventListener('mousemove', this.resizeWindow, false);
    window.addEventListener('mouseup', this.endResize, false);
    window.addEventListener('mouseleave', this.endResize, false);
  }

  public resizeWindow = (event: MouseEvent) => {
    window.getSelection().removeAllRanges();
    if (this.previousEvent) {
      this._width += (event.clientX - this.previousEvent.clientX);
      this._height += (event.clientY - this.previousEvent.clientY);
      this._width = (this._width < 200) ? 200 : this._width;
      this._height = (this._height < 100) ? 100 : this._height;
    }
    this.previousEvent = event;
  }

  public calculateIndex(): void {
    console.log('calculate z-index');
    const l = document.getElementsByTagName('app-modal-window');
    const arr = [];
    for (let i = 0, ref = arr.length = l.length; i < ref; i++) {

      arr[i] = l[i].firstChild as HTMLElement;

    }
    arr.sort(function (a, b) {
      return (Number(a.childNodes[0].style.zIndex) - Number(b.childNodes[0].style.zIndex));
    });
    let current;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].childNodes[0].id === this.id) {
        current = arr[i];
      }
      arr[i].childNodes[0].style.zIndex = this.const_Z_Index + i;
      arr[i].style.zIndex = this.const_Z_Index + i;
    }
    current.childNodes[0].style.zIndex = this.const_Z_Index + arr.length;
    current.style.zIndex = this.const_Z_Index + arr.length;
  }

  public endResize = (event: MouseEvent) => {
    window.removeEventListener('mousemove', this.resizeWindow, false);
    window.removeEventListener('mouseup', this.endResize, false);
    window.removeEventListener('mouseleave', this.endResize, false);
  }

  /* Called after user start draggable */
  public startDraggable() {
    if (!this.draggable) {
      return;
    }
    this.opacity = 0.5;
    this.calculateIndex();
    this.previousEvent = null;
    window.addEventListener('mousemove', this.dragWindow, false);
    window.addEventListener('mouseup', this.endDraggable, false);
    window.addEventListener('mouseleave', this.endDraggable, false);
  }

  public endDraggable = (event: MouseEvent) => {
    this.opacity = 1;
    window.removeEventListener('mousemove', this.dragWindow, false);
    window.removeEventListener('mouseup', this.endDraggable, false);
    window.removeEventListener('mouseleave', this.endDraggable, false);
    this.checkPosition();
  }

  /* Calculate window position on the screen */
  public checkPosition(): void {
    if ((this._left + this.width) > window.innerWidth) {

      this._left = window.innerWidth - this.width;
      this._left = 0;
    }
    if ((this._top + this.height) > window.innerHeight) {
      this._top = window.innerHeight - this.height;
    }
    this._left = this._left < 0 ? 0 : this._left;
    this._top = this._top < 0 ? 0 : this._top;
  }

  /* Calculate position when user drag window*/
  public dragWindow = (event: MouseEvent) => {
    window.getSelection().removeAllRanges();
    if (this.previousEvent) {
      this._left += (event.clientX - this.previousEvent.clientX);
      this._top += (event.clientY - this.previousEvent.clientY);
    }
    this.previousEvent = event;
  }

  /* Center window position */
  public center(): void {
    this._left = (window.innerWidth - this._width) / 2;
    console.log(window.innerHeight + ' ' + this._height);
    if (window.innerHeight > this._height) {
      this._top = (window.innerHeight - this._height) / 2;
    } else {
      this._top = 0;
    }
  }

  set closeListener(listener: ICloseEvent) {
    this._closeEvenet = listener;
  }

  set openEvenet(value: IOpenEvent) {
    this._openEvenet = value;
  }

  @Input() set title(title: string) {
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  get height(): number {
    return this._height;
  }

  get innerHeight(): number {
    return this._height - 125;
  }

  set height(value: number) {
    this._height = value;
  }

  @Input() set left(value: number) {
    if (value)
      this._left = value;
  }
  get left(): number {
    return this._left;
  }

  @Input() set top(value: number) {
    if (value)
      this._top = value;
  }
  get top(): number {
    return this._top;
  }
  get bottom(): number {
    return (this.minimize) ? 0 : null;
  }

  get bodyHtml(): any {
    return this.sanitizer.bypassSecurityTrustHtml(this._bodyHtml);
  }

  set bodyHtml(value: any) {
    this._bodyHtml = value;
  }

  set afterComponentCreate(value: IAfterComponetCreateEvent<T>) {
    this._afterComponentCreate = value;
  }

  set bodyComponent(value: any) {

    if (typeof value === 'string' || value instanceof String) {
      var factories = Array.from(this.componentFactoryResolver['_factories'].keys());
      this._bodyComponent = factories.find((x: any) => x.name === value);
    }
    else
      this._bodyComponent = value;

    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory<T>(this._bodyComponent)
      .create(this.injector);
    const component: any = componentRef.instance as T;
    if (component) {
      if (component.viewComponentHeader != null && component.viewComponentHeader != undefined)
        component.viewComponentHeader = this.viewComponentHeader;
      if (component.viewComponentTitle != null && component.viewComponentTitle != undefined)
        component.viewComponentTitle = this.viewComponentTitle;
      if (this._afterComponentCreate) {
        this._afterComponentCreate.afterCreate(<T>component);
      }
    }
    this.vc.insert(componentRef.hostView);
  }
}
