import { Component, OnInit } from "@angular/core";

import { SolicitudPqrdComponent } from "../solicitud-pqrd/solicitud-pqrd.component";

@Component({
    selector: "app-solicitud-pqrd-oficio",
    templateUrl: "./solicitud-pqrd-oficio.component.html",
    styleUrls: ["./solicitud-pqrd-oficio.component.css"]
})
export class SolicitudPqrdOficioComponent extends SolicitudPqrdComponent {


    public OnInitEnd() {
        this.postSaveEmitter.subscribe(modelo => {
            
            this.utility.VerModalOk("Se asignó un oficio a la Solicitud con código: " + "\n\r" + modelo.data.data.Codigo);
        });
    }
}
