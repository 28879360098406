import { Component, OnInit, Input, Output, forwardRef, EventEmitter, AfterViewInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control';
import { DateTimePikerTypes } from '../date-box/date-box.component';
import { GeneralComunicationService } from "src/app/services/general-comunication.service";
import {
  DomSanitizer
} from "@angular/platform-browser";
import { Utilities } from 'src/app/helpers/utilities';
import { FormActionsThroughEmitters } from 'src/app/models/general.enum';
import { TranslateService } from '@ngx-translate/core';

//* FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: "app-date-box-r",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateBoxRComponent),
      multi: true,
    },
  ],
  templateUrl: "./date-box-r.component.html",
  styleUrls: ["./date-box-r.component.scss"],
})
export class DateBoxRComponent
  extends BaseControl
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() dateTimePikerType: DateTimePikerTypes = DateTimePikerTypes.DateTime;
  @Input() format: string = "YYYY-MM-DD HH:mm";
  @Input() lang: string = "es";
  @Input() weekStart: number = 1;
  @Input() cancelText: string = "Cancelar";
  @Input() okText: string;
  @Input() clearText: string = "Limpiar";
  @Input() minDate: any;
  @Input() maxDate: any;

  fromRight = true;
  subscription: any;

  constructor(
    public utility: Utilities,
    public sanitizer: DomSanitizer,
    public comunicationService: GeneralComunicationService,
    public translate: TranslateService
  ) {
    super(utility, sanitizer, translate);
  }

  //* FUNCIONES ANGULAR

  ngAfterViewInit() {
    if (
      this.isReactive &&
      this.frm &&
      this.config &&
      this.frm.controls[this.config.Name]
    ) {
      this.ctr = this.frm.controls[this.config.Name];

     
    }


    // (<any>$('#' + this.controlName)).datepicker({
    //   showOtherMonths: true,
    //   selectOtherMonths: true
    // });

    // (<any>$('#' + this.controlName)).datepicker();

    ///MArlon Granda 18/12/2020
    this.config.Format = this.config.Format ? this.config.Format : "yyyy-mm-dd";

    let min = undefined;
    let max = undefined;

    try {
      min = eval(this.config.Min);
    } catch (error) {
      if (!isNaN(this.config.Max)) {
        max = Number(this.config.Max);
      }
    }
    try {
      max = eval(this.config.Max);
    } catch (error) {
      //Max no es una función ni booleano
      if (!isNaN(this.config.Max)) {
        max = Number(this.config.Max);
      }
    }
    const that = this;
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    let DatePickerElement = <any>$("#DatePicker_" + this.controlName);
    if (DatePickerElement[0]) {
       
       this.subscription = this.ctr.valueChanges.subscribe((value) => {
         this.value = value;
         ///\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/ es el Regex de la base de datos
         if (
           value != null &&
           !isNaN(Date.parse(value)) &&
           value.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/)
         ) {
           this.setFechas(new Date(Date.parse(value)));
         }
       });
      
      this.config.FabricElement = new fabric["DatePicker"](DatePickerElement, {
        onSet: (thingSet) => {
          ///Inicio MArlon Granda 18/12/2020
          this.modelo = this.config.FabricElement.picker.get(
            "select",
            this.config.Format
          );
          this.ctr.setValue(
            this.config.FabricElement.picker.get("select", this.config.Format)
          );
          ///Fin MArlon Granda 18/12/2020

          // this.modelo = this.config.FabricElement.picker.get('select', ' yyyy-mm-dd');
          // this.ctr.setValue(this.config.FabricElement.picker.get('select', ' yyyy-mm-dd'));
        },
        //ES6: min: min === min,
        min,
        max,
        //Día inicial sea lunes (true === Lunes, false === Domingo)
        firstDay: Number(this.config.FirstDayMonday),
        disable:
          this.config.DaysToDisable && this.config.DaysToDisable.length > 0
            ? this.config.DaysToDisable
            : undefined,
      });

      //Inicia con valor
      if (this.ctr.value) {
        const value = this.ctr.value;
        this.value = value;
        if (
          value != null &&
          !isNaN(Date.parse(value)) &&
          value.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/)
        ) {
          this.setFechas(new Date(Date.parse(value)));
        }
      }
      // var distanceR = $(window).width() - (DatePickerElement.offset().left + DatePickerElement.width());
      // if (distanceR <= 100) {
      //   $('#' + this.controlName + '_root').children('.ms-DatePicker-holder').css('right', '0');
      // }
      if (this.config.StickToSide) {
        switch (this.config.StickToSide) {
          case "left":
          case "right":
            $("#" + this.controlName + "_root")
              .children(".ms-DatePicker-holder")
              .css(this.config.StickToSide, "0");
            break;
        }
      }

      // this.config.FabricElement = new fabric['DatePicker'](DatePickerElement,
      //   {
      //     onSet: (thingSet) => {

      //       //this.modelo =this.config.FabricElement.picker.get('select', ' dd/mm/yyyy');
      //       //  this.ctr.setValue(this.config.FabricElement.picker.get('select', ' dd/mm/yyyy'));
      //     }
      //   });

      // this.config.FabricElement.picker.trigger(event => {
      //   
      // })

      // this.config.FabricElement.picker.component.settings.onSet= function(thingSet) {
      //   console.log('Set stuff:', thingSet)
      //   alert(thingSet);
      // }
      // if (this.isReactive && this.frm && this.config && this.frm.controls[this.config.Name]) {
      //   this.ctr = this.frm.controls[this.config.Name];
      //   this.ctr.valueChanges.subscribe(value => {

      //     
      //     this.value = value;
      //     this.config.FabricElement.picker.set('select', value);
      //   })
      // }
    } else {
      this.comunicationService.receivedFormEvent.subscribe((x) => {
        if (x.Action === FormActionsThroughEmitters.OpenedSidePanel) {
          this.ngAfterViewInit();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.config) this.config.FabricElement = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  //* END FUNCIONES ANGULAR

  InternalChanges(event) {
    console.log(event);
    alert("Handler for .InternalChanges() called.");
    this.value = event.target.value;
    //console.log('updateChanges ' + this.controlName)
  }

  setFechas(fecha: Date) {
    if (this.config.FabricElement) {
      this.config.FabricElement.picker.set("select", fecha);
    } else
      console.warn(
        `Se intentó asignar las fechas al Datebox ${this.controlName} sin tener definido Fabric`
      );
    // var d = new Date();
    // var n = d.getDate();
    // this.config.FabricElement.picker.set('select', '11-11-2020');
  }

  getFechas() {
    const that = this;
    let select = this.config.FabricElement.picker.get("select", " yyyy-mm-dd");
    let valor = this.config.FabricElement.picker.get();
    alert(select);
  }

  //* END FUNCIONES PERSONALIZADAS
}