import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { DataTableComponent } from '../../controls/data-table/data-table.component';
import { FormActionsThroughEmitters, TipoObjetoParaPermisos } from 'src/app/models/general.enum';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { StaticData } from "../../../helpers/static-data";

@Component({
  selector: 'app-tubicacion-fisica-expediente',
  templateUrl: './tubicacion-fisica-expediente.component.html',
  styleUrls: ['./tubicacion-fisica-expediente.component.css']
})
export class TUbicacionFisicaExpedienteComponent extends GeneralFormComponent implements OnInit, AfterViewInit {

  @Input() detalleExpediente: any;
  urlReturn: any = null;



  public OnInitEnd() {

    // Insercion de permisos
    //debugger;
    this.modelo.modeloVirtual.UsuarioAutenticado = StaticData.Usuario.IdUsuario;
    this.OnCreatePermissionsConfig();

    if (this.utility.ParentDetailsItem) {

      this.utility.ReturnParentDetailsItem = this.utility.ParentDetailsItem;
      /**
       * Inicio Edward Morales 02/03/2032
       * Valida el nombre de la página entrante para posterior Retorno commandBar
       * si no existe la propiedad continúa proceso anterior (DetalleExpediente)
       */
      if(this.utility.ParentDetailsItem.Pagina){
        this.urlReturn = this.utility.ParentDetailsItem.Pagina
      }
      this.utility.ParentDetailsItem = null;
      const DataTables_BusquedaExpediente = this.Pagina.ConfigPage.Controls.find(x => { return x.Name == 'DataTables_BusquedaExpediente' && x.Activo == true });
      if (DataTables_BusquedaExpediente) {
        DataTables_BusquedaExpediente.Activo = false;
      }
      else {
        const IdExpediente = this.Pagina.ConfigPage.Controls.find(x => { return x.Name == 'IdExpediente' && x.Activo == true });
        IdExpediente.Activo = false;
      }
    }

    this.preSaveEmitter.subscribe(event => {
      //debugger;
      if (this.utility.ReturnParentDetailsItem) {
        this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente;
      }

		});
    /// Fin de modificacion Marlon Granda 29_11_2020
    this.postSaveEmitter.subscribe((modelo) => {
      //debugger;      
      this.modelo.modelo.Caja = null;
      this.modelo.modelo.Cara = null;
      this.modelo.modelo.Carpeta = null;
      this.modelo.modelo.Entrepano = null;
      this.modelo.modelo.Estante = null;
      this.modelo.modelo.FechaFinal = null;
      this.modelo.modelo.FechaInicial = null;
      this.modelo.modelo.Folios = null;
      this.modelo.modelo.IdUbicacionFisicaExpediente = null;
      this.modelo.modelo.Observaciones = null;
      this.modelo.modelo.CurrentItemIndex = null;
      this.modelo.modelo.PkValue = 0;      

      this.SetModelToControls();
    });

  }

  // Agrega metdo de validacion de permisos Edwin Alonso 09022021

  public OnCreatePermissionsConfig() {
    this.permissionsConfig = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Documento,
      Pagina: this.Pagina,
      clear: true,
      expediente: null,
      CreatedRowConfig: {},
    };
    this.RaiseFormEvent("OnCreatePermissionsConfig", true);
  }
  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    if(controlName == 'IdExpediente')
    {
      try {
        this.SetExpediente(item);
        this.comboBoxManager.ExecuteCascading(item, controlName, config);
      } catch (error) {
        this.utility.logger.LogError(error, null, {
          componentName: this.componentName,
          method: "OnSelectedComboBox",
          controlName: controlName,
        });
        this.utility.VerModalDanger().then((data) => { });
      }
    }
  }


  public NgAfterViewInitEnd() {
    if (this.utility.ReturnParentDetailsItem) {
      setTimeout(() => {

        if (this.commandBar)
          this.commandBar.SetHiddenOption(false, 'Name', 'Retornar');
      }, 500);
    }
  }
  public Limpiar(callback: (data: any) => void = null, action?: any) {
    //debugger;
    this.modelo.modelo.Caja = null;
      this.modelo.modelo.Cara = null;
      this.modelo.modelo.Carpeta = null;
      this.modelo.modelo.Entrepano = null;
      this.modelo.modelo.Estante = null;
      this.modelo.modelo.FechaFinal = null;
      this.modelo.modelo.FechaInicial = null;
      this.modelo.modelo.Folios = null;
      this.modelo.modelo.IdUbicacionFisicaExpediente = null;
      this.modelo.modelo.Observaciones = null;
      this.modelo.modelo.CurrentItemIndex = null;
      this.modelo.modelo.PkValue = 0;

      this.SetModelToControls();

    /**
     * INICIO
     * Edward Morales 14042021-1529
     *
     * Refrescar grilla después de crud de registro, linea original comentada (91)
     */
    //debugger;
    var IdExpedienteGestion = -1;
    //if(this.utility.ReturnParentDetailsItem){
      if(this.utility.ReturnParentDetailsItem.IdExpediente){
        IdExpedienteGestion = this.utility.ReturnParentDetailsItem.IdExpediente;
      //}
    }
    super.Limpiar(callback, action);

    //this.componentsContainer.ReloadTable('DataTables_TUbicacionFisicaExpediente', { IdEmpresa: -1, IdExpediente: -1 });
    this.componentsContainer.ReloadTable('DataTables_TUbicacionFisicaExpediente', { IdEmpresa: -1, IdExpediente: IdExpedienteGestion });


    if (this.utility.ReturnParentDetailsItem) {
      this.modelo.modelo.IdExpediente = this.utility.ReturnParentDetailsItem.IdExpediente;
    }

  }
  public SetExpediente(item: any) {
    // item.ListaPermisos.Array().forEach(permisos => {
    //   permisos.Editar = true
    // });
    //debugger;    
    if (item != undefined) {
      this.modelo.modeloVirtual.IdUsuarioResponsable = item.IdUsuarioResponsable;
    let ConfigPermisos = JSON.parse(item.Configuracion)
    ConfigPermisos.forEach(permisos => {
      permisos.Editar = true
    });
    item.Configuracion = JSON.stringify(ConfigPermisos);

    this.detalleExpediente = item;
    this.permissionsConfig.expediente = item;

    this.componentsContainer.SetTableProperty(
      "DataTables_TUbicacionFisicaExpediente",
      "configPermisos",
      this.permissionsConfig
    );

      this.modelo.modelo.IdExpediente = item.IdExpediente;

      /**
       * INICIO
       * Edward Morales - 14042021_1535
       * Cargar registros creados para el expediente seleccionado
       */

      if(this.detalleExpediente.IdExpediente == undefined){
        this.detalleExpediente.IdExpediente = this.modelo.modelo.IdExpediente;
      }
      this.componentsContainer.ReloadTable('DataTables_TUbicacionFisicaExpediente',
        { IdEmpresa: -1, IdExpediente: this.detalleExpediente.IdExpediente }, null, false);
        //{ IdEmpresa: -1, IdExpediente: this.modelo.modelo.IdExpediente }, null, false);
    }
    else {
      this.modelo.modelo.IdExpediente = null;
      this.componentsContainer.SetTableProperty('DataTables_TUbicacionFisicaExpediente', 'cargarGrilla', false, false);

      this.componentsContainer.ReloadTable('DataTables_TUbicacionFisicaExpediente',
      { IdEmpresa: -1, IdExpediente: -2 }, null, false);
    }
    this.RaiseFormEvent("OnUpdatePermissionsConfig", true);
  }
  public OnDataTableEditHandler(event) {

    //debugger;
    if (event && event.name == 'DataTables_BusquedaExpediente') {
      this.SetExpediente(this.modelo.expediente);
      delete this.modelo.expediente;
    }

  }
  public OnActionEventHandler(event) {
    //debugger;
    if (
      event.Action === FormActionsThroughEmitters.ClearModel &&
      event.Table == "DataTables_BusquedaExpediente"
    ) {
      this.componentsContainer.ReloadTable(
        "DataTables_TUbicacionFisicaExpediente",
        { IdEmpresa: -1, IdExpediente: -1 }
      );
    }

    /// adicion de botones de navegacion a las paginas de documento y ubicacion
    // else if (event.Option.Type == "Return") {
    //   this.utility.router.navigate(['DetalleExpediente']);
    // }

    //Modificado Miguel Patiño (Da error cuando event.Option es undefined)
    if (event.Option) {
      if (event.Option.Type == "Return") {
        /**
       * Inicio Edward Morales 02/03/2032
       * Valida el nombre de la página entrante para posterior Retorno commandBar
       * si es null continúa proceso anterior (DetalleExpediente)
       */
        if(this.urlReturn){
          this.utility.router.navigate([this.urlReturn]);
        }else{
        this.utility.router.navigate(['DetalleExpediente']);
      }
        return;
      }
    }
    if (event.Action === FormActionsThroughEmitters.ClearModel && event.Table == 'DataTables_TUbicacionFisicaExpediente') {
      this.modelo.modelo.Caja = null;
      this.modelo.modelo.Cara = null;
      this.modelo.modelo.Carpeta = null;
      this.modelo.modelo.Entrepano = null;
      this.modelo.modelo.Estante = null;
      this.modelo.modelo.FechaFinal = null;
      this.modelo.modelo.FechaInicial = null;
      this.modelo.modelo.Folios = null;
      this.modelo.modelo.IdUbicacionFisicaExpediente = null;
      this.modelo.modelo.Observaciones = null;
      this.modelo.modelo.CurrentItemIndex = null;
      this.modelo.modelo.PkValue = 0;

      this.SetModelToControls();
      return;
    }


    this.SetModelToControls();

    super.OnActionEventHandler(event);
  }

  public AddComponentInstanceHandler(event) {
    if (this.utility.ReturnParentDetailsItem && (event instanceof DataTableComponent)) {
      event.MsgValidationRecordManagement = this.Pagina.ConfigPage.MsgValidationRecordManagement;
      event.SearchTagRecordManagement = this.Pagina.ConfigPage.SearchTagRecordManagement;
      event.MsgReleaseRecordManagement=this.Pagina.ConfigPage.MsgReleaseRecordManagement;
			event.MsgBusyRecordManagement=this.Pagina.ConfigPage.MsgBusyRecordManagement;
      this.componentsContainer.Tables.push(event);
      if (event.name == 'DataTables_TUbicacionFisicaExpediente') {
        this.SetExpediente(this.utility.ReturnParentDetailsItem);

      }

    }

    else {

      super.AddComponentInstanceHandler(event);

    }
  }
}
