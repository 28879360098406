import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalNotifyComunicationService } from '../../services/modal-notify-comunication.service';
import { StaticData } from 'src/app/helpers/static-data';
declare var fabric: any;



// export const ModalNotifyTypes = {
//   Success: { Id: 1, Icon: 'fas fa-check-circle fa-w-16 fa-7x', Image: '', Class: 'success' },
//   Info: { Id: 2, Icon: 'fas fa-info-circle fa-w-16 fa-5x', Image: '', Class: 'info' },
//   Warning: { Id: 3, Icon: 'fas fa-exclamation-triangle fa-w-16 fa-5x', Image: '', Class: 'warning' },
//   Danger: { Id: 4, Icon: 'fas fa-exclamation-circle fa-w-16 fa-5x', Image: '', Class: 'danger' },
//   Primary: { Id: 5, Icon: '', Image: '', Class: 'primary' },
//   Secondary: { Id: 6, Icon: '', Image: '', Class: 'secondary' },
//   Light: { Id: 6, Icon: 'far fa-sticky-note fa-w-16 fa-5x', Image: '', Class: 'light' },
//   Dark: { Id: 6, Icon: 'fas fa-sticky-note fa-w-16 fa-5x', Image: '', Class: 'dark' },
//   Link: { Id: 6, Icon: 'fas fa-link fa-w-16 fa-5x', Image: '', Class: 'link' },
//   Question: { Id: 7, Icon: 'fas fa-question-circle fa-w-16 fa-5x', Image: '', Class: 'primary' },
//   QuestionWarning: { Id: 8, Icon: 'fas fa-question-circle fa-w-16 fa-5x', Image: '', Class: 'warning' },
//   QuestionDanger: { Id: 9, Icon: 'fas fa-question-circle fa-w-16 fa-5x', Image: '', Class: 'danger' },
// }

export const ModalNotifyTypes = {
  Success: { Id: 1, Icon: 'ms-Icon ms-Icon--SkypeCircleCheck', Image: '', Class: 'success' },
  Info: { Id: 2, Icon: 'ms-Icon ms-Icon--InfoSolid', Image: '', Class: 'info' },
  Warning: { Id: 3, Icon: 'ms-Icon ms-Icon--WarningSolid', Image: '', Class: 'warning' },
  Danger: { Id: 4, Icon: 'ms-Icon ms-Icon--StatusErrorFull', Image: '', Class: 'danger' },
  Primary: { Id: 5, Icon: '', Image: '', Class: 'primary' },
  Secondary: { Id: 6, Icon: '', Image: '', Class: 'secondary' },
  Light: { Id: 6, Icon: 'ms-Icon ms-Icon--QuickNoteSolid', Image: '', Class: 'light' },
  Dark: { Id: 6, Icon: 'ms-Icon ms-Icon--QuickNoteSolid', Image: '', Class: 'dark' },
  Link: { Id: 6, Icon: 'ms-Icon ms-Icon--Link', Image: '', Class: 'link' },
  Question: { Id: 7, Icon: 'ms-Icon ms-Icon--UnknownSolid', Image: '', Class: 'primary' },
  QuestionWarning: { Id: 8, Icon: 'ms-Icon ms-Icon--UnknownSolid', Image: '', Class: 'warning' },
  QuestionDanger: { Id: 9, Icon: 'ms-Icon ms-Icon--UnknownSolid', Image: '', Class: 'danger' },
}



@Component({
  selector: 'app-modal-notify',
  templateUrl: './modal-notify.component.html',
  styleUrls: ['./modal-notify.component.scss']
})
export class ModalNotifyComponent implements OnInit, AfterViewInit {


  @Input() config: any = undefined;
  @Input() descripcion: string = '';
  @Input() titulo: string = 'Informacion';
  @Input() ok: string = 'Ok';
  @Input() extra: string = '';
  @Input() cancel: string = 'Cancelar';
  @Input() modalNotifyType = ModalNotifyTypes.Info;
  @Input() name: string = 'modal-notify-bext_doc';
  @Output() showEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() shownEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() hiddenEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() html: any = null;
  dialogResult: boolean = false;

  viewCancel: boolean = false;
  viewOk: boolean = true;
  viewComponet: boolean = false;
  dataSend: any = {};
  viewExtra: boolean = false;
  isOpen: boolean = false;
  internalDescription: string = "";
  public onClose: (modelo: any) => void;

  constructor(public modalNotifyComunicationService: ModalNotifyComunicationService) {
    if (this.modalNotifyComunicationService.modalNotify == null)
      this.modalNotifyComunicationService.modalNotify = this;
  }

  ngOnInit() {

  }
  private setConfigProp() {

  }
  public setConfig(_modalNotifyType?) {


    if (_modalNotifyType)
      this.modalNotifyType = _modalNotifyType;
    if (this.config) {
      if (this.config.titulo)
        this.titulo = this.config.titulo;
      this.html = this.config.html;
      if (this.config.descripcion)
        this.descripcion = this.config.descripcion;
      if (this.config.ok)
        this.ok = this.config.ok;
      if (this.config.cancelar)
        this.cancel = this.config.cancelar;
      if (this.config.modalNotifyType)
        this.modalNotifyType = this.config.modalNotifyType;
      this.viewCancel = (this.modalNotifyType.Id == ModalNotifyTypes.Question.Id);
      if (this.config.verCancelar != undefined)
        this.viewCancel = this.config.verCancelar;
      if (this.config.verOk != undefined)
        this.viewOk = this.config.verOk;
      if (this.config.extra)
        this.extra = this.config.extra;
      this.viewExtra = (this.config.verExtra == true) ? true : false;
    }
  }
  private clear() {
    this.dialogResult = false;
  }
  private initModal() {

    const that = this;
    $('#' + this.name).off('hidden.bs.modal');
    $('#' + this.name).off('shown.bs.modal');
    $('#' + this.name).off('hide.bs.modal');
    $('#' + this.name).off('show.bs.modal');

    $('#' + this.name).on('hidden.bs.modal', function () {
      if (that.hiddenEmitter && that.hiddenEmitter.observers.length > 0)
        that.hiddenEmitter.emit(that.getDataResult());
      that.clear();
    });
    $('#' + this.name).on('shown.bs.modal', function () {
      if (that.shownEmitter && that.shownEmitter.observers.length > 0)
        that.shownEmitter.emit(that.getDataResult());

      that.viewComponet = true;
      that.dataSend = { Text: 'Soy edata to send' };
      that.clear();
    });
    $('#' + this.name).on('hide.bs.modal', function () {
      if (that.hideEmitter && that.hideEmitter.observers.length > 0)
        that.hideEmitter.emit(that.getDataResult());

      if (that.onClose)
        that.onClose(that.getDataResult());
      that.clear();
    });

    $('#' + this.name).on('show.bs.modal', function () {
      if (that.showEmitter && that.showEmitter.observers.length > 0)
        that.showEmitter.emit(that.getDataResult());
      that.clear();
    });

  }

  getDataResult(): any {
    return { name: this.name, dialogResult: this.dialogResult, modalNotifyType: this.modalNotifyType };
  }
  open(modalNotifyType = null, config: any = null) {
    const that = this;
    this.internalDescription = "";

    if (modalNotifyType)
      that.modalNotifyType = modalNotifyType;
    if (config)
      that.config = config;
    that.setConfig();


    // switch (this.modalNotifyType.Id) {
    //   case ModalNotifyTypes.Question.Id:
    //   case ModalNotifyTypes.QuestionDanger.Id:
    //   case ModalNotifyTypes.QuestionWarning.Id:
    //     //this.modalNotifyComponent.close();

    //     setTimeout(() => {
    //       console.error("InternalOpen", this.modalNotifyType)
    //       this.InternalOpen();
    //     }, 50);
    //     break;
    //   case ModalNotifyTypes.Success.Id:

    //     StaticData.ShowActionNotificationSuccess(this.config.descripcion, this.config.titulo);
    //     //this.InternalOpen();
    //     break;
    //   case ModalNotifyTypes.Info.Id:
    //     console.error("ShowActionNotificationInfo")
    //     StaticData.ShowActionNotificationInfo(this.config.descripcion, this.config.titulo);
    //     //this.InternalOpen();
    //     break;
    //   case ModalNotifyTypes.Warning.Id:
    //     console.error("ShowActionNotificationWarning")
    //     StaticData.ShowActionNotificationWarning(this.config.descripcion, this.config.titulo);
    //     //this.InternalOpen();
    //     break;
    //   case ModalNotifyTypes.Danger.Id:
    //     console.error("ShowActionNotificationError")
    //     StaticData.ShowActionNotificationError(this.config.descripcion, this.config.titulo);
    //     //this.InternalOpen();
    //     break;
    // }
    let isquestions = (this.modalNotifyType.Id == ModalNotifyTypes.Question.Id ||
      this.modalNotifyType.Id == ModalNotifyTypes.QuestionDanger.Id ||
      this.modalNotifyType.Id == ModalNotifyTypes.QuestionWarning.Id);

    if (StaticData.GeneralConfig.ConfigUI.VerMensajesAdicionalModalEnNotifi != true) {
      if (this.isOpen) {
        // this.modalNotifyComponent.close();
        this.isOpen = true;
        setTimeout(() => {

          this.InternalOpen();
        }, 70);
      }
      else
        this.InternalOpen();
    }
    else if (StaticData.GeneralConfig.ConfigUI.VerMensajesAdicionalModalEnNotifi && (this.isOpen || isquestions)) {
      switch (this.modalNotifyType.Id) {
        case ModalNotifyTypes.Question.Id:
        case ModalNotifyTypes.QuestionDanger.Id:
        case ModalNotifyTypes.QuestionWarning.Id:
          // this.modalNotifyComponent.close();
          this.isOpen = true;
          this.InternalOpen();
          // setTimeout(() => {
          //   console.error("InternalOpen",this.modalNotifyType)
          //   this.InternalOpen();
          // }, 50);
          break;
        case ModalNotifyTypes.Success.Id:

          StaticData.ShowActionNotificationSuccess(this.config.descripcion, this.config.titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
          //this.InternalOpen();
          break;
        case ModalNotifyTypes.Info.Id:

          StaticData.ShowActionNotificationInfo(this.config.descripcion, this.config.titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
          //this.InternalOpen();
          break;
        case ModalNotifyTypes.Warning.Id:

          StaticData.ShowActionNotificationWarning(this.config.descripcion, this.config.titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
          //this.InternalOpen();
          break;
        case ModalNotifyTypes.Danger.Id:

          StaticData.ShowActionNotificationError(this.config.descripcion, this.config.titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
          //this.InternalOpen();
          break;
      }
    }
    else {

      this.InternalOpen();
    }

  }
  InternalOpen(modalNotifyType = null, config: any = null) {
    this.isOpen = true;
    
    setTimeout(() => {
      this.modalNotifyElem.open();
      
      setTimeout(() => {
        this.internalDescription = "";
        // obtener cantidad de caracteres para aplicar estilos necesarios
        if (this.descripcion.length <= 38) {
          $('.ms-Dialog-subText-notify').css("padding-top", "12px");
        } else {
          $('.ms-Dialog-subText-notify').css("padding-top", "0");
        }
        // obtener cantidad de caracteres por palabra para aplicar estilos necesarios
        let words = this.descripcion.split(" ");
        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          if (word.length >= 30) {
            <any>$("#"+this.name).addClass("big");
            this.internalDescription = this.internalDescription.concat(`<span>${word}</span>`, " ");
          } else {
            this.internalDescription = this.internalDescription.concat(word, " ");
          }
        }

      }, 20);

    }, 120);
  }
  close(dialogResult: any = false) {
    this.dialogResult = dialogResult;
    this.isOpen = false;
    this.internalDescription = "";
    setTimeout(() => {


      if (this.hideEmitter && this.hideEmitter.observers.length > 0) {
        this.hideEmitter.emit(this.getDataResult());
      }

      if (this.onClose) {
        this.onClose(this.getDataResult());
      }

      this.clear();

      //$('#' + this.name).modal('hide');

    }, 60);

  }

  modalNotifyElem: any;
  ngAfterViewInit() {
    if (!this.modalNotifyElem) {
      const modal = document.querySelector(".ms-Dialog-Notify");
      this.modalNotifyElem = new fabric['Dialog'](modal);
    }
    if (this.modalNotifyComunicationService.modalNotify == null)
      this.modalNotifyComunicationService.modalNotify = this;

    this.initModal();
  }
}
