import { AccessValidationConfig, MailSettings } from "src/app/models/rule-engine/rules-models";
import {
    Component,
    Input,
    OnInit
} from "@angular/core";

import { ConfigBase } from "../config-base";
import { Guid } from "guid-typescript";
import { StaticData } from "../../helpers/static-data";

@Component({
    selector: "app-general-config",
    templateUrl: "./general-config.component.html",
    styleUrls: ["./general-config.component.css"]
})
export class GeneralConfigComponent extends ConfigBase implements OnInit {

  @Input() verGenerarNuevoCodigo = false;
  generalConfigStaticData: any;
  OpcionesPermisosCrearExpediente: Array<any> = [];


  Pagina: any = {
      ConfigPage: {
          Controls: [
              { ColSpan: 6, Label: "Opciones Permisos", Activo: true, Name: "OpcionesPermisos" },
              { ColSpan: 12, Label: "Opciones Permisos", Activo: true, Name: "OpcionesPermisos" }


          ]
      }
  };

  configDocumentoSeleccionada: any;
  public SetModeloFromModeloJson() {
      
      if (this.modeloJson) {
          this.modeloJson = this.modeloJson.replace("\"", "\"");
          this.modelo = JSON.parse(this.modeloJson);
      }
      else {
          if (!this.modelo)
              this.modelo = { ConfigsExpediente: [] };
      }

  }


  ngOnInit() {
      
      if (!this.estilos)
          this.estilos = StaticData.Estilos;
      this.SetModeloFromModeloJson();
      if (this.modelo && !this.modelo.ConfigsExpediente) {
          this.modelo.ConfigsExpediente = [];
      }

      if (this.modelo && !this.modelo.ConfigValidacionExpediente)
          this.modelo.ConfigValidacionExpediente = this.utility.GetModeloConfigValidacionExpediente();

      if (this.modelo && !this.modelo.ConfigValidacionDocumento)
          this.modelo.ConfigValidacionDocumento = this.utility.GetModeloConfigValidacionDocumento();

      this.utility.SetTextOpcionesPermisos(this.modelo, "OpcionesPermisosExpediente", "OpcionesPermisosDocumento");
      this.OpcionesPermisosCrearExpediente = this.utility.GetOpcionesPermisosCrearExpedienteText();


  }
  public SetModeloJson(modeloJson: string) {
      this.modeloJson = modeloJson;
      this.SetModeloFromModeloJson();
      if (this.modelo && !this.modelo.ConfigsExpediente) {
          this.modelo.ConfigsExpediente = [];
      }

  }
  public GetModeloJson() {

      if (this.modelo) {
          return JSON.stringify(this.modelo);
      }
      return null;
  }
  public ConfigExpedienteEventHandler(event) {
      this.modelo.ConfigExpediente = event.modelo;
  }
  public ConfigExpedienteEmpresaEventHandler(event) {
      this.modelo.ConfigExpedienteEmpresa = event.modelo;
  }
  public ConfigDocumentoEventHandler(event) {
      this.modelo.ConfigDocumento = event.modelo;
  }
  public ConfigTipoUbicacionTiposDocumentalesEventHandler(event) {
      this.modelo.ConfigTipoUbicacionTiposDocumentales = event.modelo;
  }
  public ConfigTipoUbicacionPlantillasEventHandler(event) {
      this.modelo.ConfigTipoUbicacionPlantillas = event.modelo;
  }

  public ConfigTipoUbicacionEventHandler(event, property) {
      this.modelo[property] = event.modelo;
  }
  //ConfigTipoUbicacionTiposDocumentales
  public ConfigExpedientesEventHandler(event) {
      if (!event.nuevo)
          this.modelo.ConfigsExpediente.splice(event.index, 1);
      else if (event.esClone)
          this.modelo.ConfigsExpediente.push(event.modelo);
  }

  public AddConfigExpedientesEventHandler() {
      
      this.modelo.ConfigsExpediente.push(this.utility.GetModeloConfigExpediente());
  }
  public AsignarStaticData() {
      if (!this.generalConfigStaticData) {
          this.generalConfigStaticData = this.utility.Clone(StaticData.GeneralConfig);
      }
      StaticData.GeneralConfig = this.modelo;
  }
  public RevertirStaticData() {
      if (this.generalConfigStaticData) {
          StaticData.GeneralConfig = this.generalConfigStaticData;
      }
  }


  public ViewMailSettings() {

      //FieldMappingConfigComponent
      if (!this.modelo.MailSettings)
          this.modelo.MailSettings = new MailSettings();
      const config = this.utility.GetConfigWindow("");
      const isNew = false;
      const windowModel: any = {

          modelo: this.modelo.MailSettings,
          project: this.project
      };

      this.utility.VerModalComponente("ActionMailSettingsFormComponent", windowModel, config).then(dataResultOnClose => {
          if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

              

          }
          else {

          }
      });
  }

  public NuevoAccessValidationConfig() {

      this.modelo.AccessValidationConfig = new AccessValidationConfig();
  }



  ValidarConfigUbicaciones() {

      if (!this.modelo.ConfigUbicaciones)
          this.modelo.ConfigUbicaciones = [];
  }
  public EliminarConfigUbicaciones(index) {

      this.modelo.ConfigUbicaciones.splice(index, 1);
  }
  public AddConfigUbicaciones() {

      this.ValidarConfigUbicaciones();

      this.configDocumentoSeleccionada = null;
      setTimeout(() => {
          this.configDocumentoSeleccionada = this.utility.GetModeloConfig("ConfigDocumento", null);
          this.configDocumentoSeleccionada.GUID = Guid.create().toString();
          this.modelo.ConfigUbicaciones.push(this.configDocumentoSeleccionada);
      }, 200);

  }
  public EditConfigUbicaciones(item) {

      this.ValidarConfigUbicaciones();

      this.configDocumentoSeleccionada = null;
      setTimeout(() => {
          this.configDocumentoSeleccionada = item;

      }, 200);

  }
  DeseleccionarConfigUbicaciones() {
      this.configDocumentoSeleccionada = null;
  }
}
