import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, Inject, forwardRef, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import {
    Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, ControlPagina,
    nodeTypes, DataTableColumnTypes, TipoParaPermisos, TipoObjetoParaPermisos, TipoBusquedaAreaEmpresa,
    FormatosTexto, TiposRelleno, TiposCampoConfigGenerar, TiposIdExpediente, TiposNombreFolder, OpcionesPermisosCrearExpediente,
    TiposUbicacionArchivo, TiposUbicacionCacheIU, ControlPaginaBaseBasico, DataTypes, ControlActions, ControlContainerBasico
} from '../models/general.enum';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { LoggerService } from '../services/logger.service'
import { StaticData } from '../helpers/static-data';
import { List } from '../../assets/linqts/compilado/index';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { WindowService } from '../components/window.component/window.service';
import { ConfigWindow } from '../models/config-window';
import { ModalNotifyComunicationService } from '../services/modal-notify-comunication.service';
import { TabHost } from 'dock-spawn-ts/lib/js/TabHost';
import { Guid } from 'guid-typescript';
import { debug } from 'util';
import { OverlayComponent } from '../components/controls/overlay/overlay.component';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


declare var WebConfigData;
declare var FileSaver: any;
declare var saveAs: any;
declare var XLSX: any;
declare var XLSXStyle: any;
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const CSV_TYPE = 'text/plain;charset=UTF-8';
const CSV_EXTENSION = '.csv';

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position) {
        var subjectString = this.toString();
        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}
if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function (search, rawPos) {
            var pos = rawPos > 0 ? rawPos | 0 : 0;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}


export class Utilities {

    public SidePanelTypes: Array<any> = [
        { Name: 'RightSidePanel', Label: 'Normal Derecha', Class: "ms-Panel" },
        { Name: 'RightMediumSidePanel', Label: 'Medio Derecha', Class: "ms-Panel ms-Panel--md" },
        { Name: 'RightLargeSidePanel', Label: 'Largo Derecha', Class: "ms-Panel ms-Panel--lg" },
        { Name: 'RightFixedSidePanel', Label: 'Ajustado Derecha', Class: "ms-Panel ms-Panel--lg ms-Panel--fixed" },
        { Name: 'RightExtralargeSidePanel', Label: 'Extra Largo Derecha', Class: "ms-Panel ms-Panel--xl" },
        { Name: 'LeftSidePanel', Label: 'Normal Izquierda', Class: "ms-Panel ms-Panel--left" },
        { Name: 'LeftMediumSidePanel', Label: 'Medio Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--md" },
        { Name: 'LeftLargeSidePanel', Label: 'Largo Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--lg" },
        { Name: 'LeftFixedSidePanel', Label: 'Ajustado Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--lg ms-Panel--fixed" },
        { Name: 'LeftExtralargeSidePanel', Label: 'Extra Largo Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--xl" },
    ];
    formControlTypes: List<any> = new List<any>([]);
    // public overlay:OverlayComponent= null;
    constructor(@Inject(forwardRef(() => Router)) public router: Router,
        @Inject(forwardRef(() => LoggerService)) public logger: LoggerService,
        @Inject(forwardRef(() => WindowService)) private windowService: WindowService,
        @Inject(forwardRef(() => ModalNotifyComunicationService)) public modalNotifyComunicationService: ModalNotifyComunicationService,
        public translate: TranslateService) {
        this.setModalNotify();
        try {
            this.formControlTypes.Add('TextBox');
            this.formControlTypes.Add('NumberBox');
            this.formControlTypes.Add('PasswordBox');
            this.formControlTypes.Add('DateBox');
            this.formControlTypes.Add('CheckBox');
            this.formControlTypes.Add('RadioBox');
            this.formControlTypes.Add('TextArea');
            this.formControlTypes.Add('EmailBox');
            this.formControlTypes.Add('PhoneBox');
            this.formControlTypes.Add('ComboBox');
            this.formControlTypes.Add('MultiComboBox');
            this.formControlTypes.Add('HiddenField');
            this.formControlTypes.Add('ThreeStateToggle');
            //three-state-toggle

            // this.modalNotifyComunicationService.receivedOverlayEvent.subscribe(over=>{
            //     this.overlay =over;
            // })
        }
        catch (err) {
            console.log(' Eeror Cargando formControlTypes ', err);
        }
    }
    private setModalNotify(modalNotifyTypes?) {
        if (this.modalNotify == null) {
            this.modalNotify = this.modalNotifyComunicationService.modalNotify;
        }
        if (this.modalNotify && modalNotifyTypes)
            this.modalNotify.setConfig(modalNotifyTypes);
    }



    ////ControlActions.ExportJsonToExcel

    public ExportAsFile(json: any[], fileName: string, controlAction: ControlActions, setDate: boolean = true): void {

        if (controlAction == ControlActions.ExportJsonToCsv) {
            this.ExportAsCsvFile(json, fileName, setDate);
        }
        else {
            this.ExportAsExcelFile(json, fileName, setDate);
        }
    }
    public ExportAsExcelFile(json: any[], fileName: string, setDate: boolean = true): void {


        const worksheet: any = XLSX.utils.json_to_sheet(json);

        const workbook: any = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.SaveAsFile(excelBuffer, fileName, EXCEL_TYPE, EXCEL_EXTENSION);
    }

    private SaveAsFile(buffer: any, fileName: string, type, extension, setDate: boolean = true): void {
        const data: Blob = new Blob([buffer], {
            type: type
        });
        fileName = (setDate) ? fileName + '_' + this.GetCurrentDate() + extension : fileName + fileName
        saveAs(data, fileName);
    }
    public ExportAsCsvFile(json: any[], fileName: string, setDate: boolean = true): void {


        const worksheet: any = XLSX.utils.json_to_sheet(json);
        const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
        this.SaveAsFile(csv, fileName, CSV_TYPE, CSV_EXTENSION);

    }

    // private SaveAsExcelFile(buffer: any, fileName: string, setDate: boolean = true): void {
    //     const data: Blob = new Blob([buffer], {
    //         type: EXCEL_TYPE
    //     });
    //     fileName = (setDate) ? fileName + '_' + this.GetCurrentDate() + EXCEL_EXTENSION : fileName + fileName
    //     saveAs(data, fileName);
    // }

    private returnParentDetailsItem: any;
    set ReturnParentDetailsItem(value) {
        this.returnParentDetailsItem = value;
    }
    get ReturnParentDetailsItem() {
        return this.returnParentDetailsItem;
    }


    private parentDetailsItem: any;
    set ParentDetailsItem(value) {
        this.parentDetailsItem = value;
    }
    get ParentDetailsItem() {
        return this.parentDetailsItem;
    }


    private editItem: any;
    set EditItem(value) {
        this.editItem = value;
    }
    get EditItem() {
        return this.editItem;
    }

    private workFlowItem: any;

    set WorkFlowItem(value) {
        this.workFlowItem = value;
    }
    get WorkFlowItem() {
        return this.workFlowItem;
    }

    modalNotify: ModalNotifyComponent;

    private _cache: any = {};

    public GetCache(key: string): any {
        var data = this._cache[key];
        return data;
    }

    public SetCache(key: string, data: any): any {
        this._cache[key] = data;
        return data;
    }
    public GetAlias(text: string, index: number): string {
        var alias = (index) ? text.substr(index) : text;
        var arr = alias.split(/(?=[A-Z])/);
        alias = arr.join(' ');
        return alias;
    }
    public GetFieldAlias(tablename: string, columnName: string) {
        let tableName = tablename.substr(1);

        var columnNameArr = columnName.split(/(?=[A-Z])/);
        //var tableNameArr = tableName.split(/(?=[A-Z])/);
        // tableName = tableNameArr.join(' ');
        columnName = columnNameArr.join(' ');
        var alias = columnName + ' ' + tableName;
        return alias;
    }
    public ToUpperCase(data: string): string {

        // insert a space before all caps
        data = data.replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, function (str) { return str.toUpperCase(); });

        return data;
    }

    public GetUsuario(): any {
        return StaticData.Usuario;
    }
    public SetUsuario(usuario: any): any {
        StaticData.Usuario = usuario;
    }
    public SetUrlServer(urlServer: string) {
        StaticData.UrlServer = urlServer;
    }

    public ConvertObjectToArray(obj: any): Array<any> {
        let elements: Array<any> = [];
        $.each(obj, function (key, value) {
            elements.push({ key: key, value: value });
        });

        return elements;
    }
    public IsString(data: any): boolean {
        return (typeof data === 'string');
    }
    private _SetControlsConfig(configs: any, ConfigControl: any, ControlType: string, key: string, processMessage: string) {

        if (ConfigControl) {

            configs[key] = this.IsString(ConfigControl) ? JSON.parse(ConfigControl) : ConfigControl;
            configs[key].processMessage = processMessage;
            if (ControlType == 'MultiselectBox') {
                configs[key].IsMultiple = true;
                // configs[ key].ExtraSettings = _ExtraSettings();
                //configs[key].TranslationTexts = _TranslationTexts(configs[key].TranslationText);
            }
        }
    };
    public SetFieldsToModel(model: any, source: any, findByValue?: boolean) {

        if (source) {

            if (findByValue) {
                for (var name in model) {
                    if (name != 'VersionModelData' && source[model[name]] !== undefined)
                        model[name] = source[model[name]];
                }

                return model;
            }
            if (typeof source === 'string' || source instanceof String) {
                var _model = {};
                for (var name in source.split(',')) {
                    if (name != 'VersionModelData')
                        _model[name] = source[name];
                }

                return _model;
            }
            else if (Array.isArray(source) == true) {

                $.each(source, function (key, item) {

                    for (var name in item) {
                        if (name != 'VersionModelData')
                            model[name] = item[name];
                    }

                });

                return model;
            }
            else {
                for (var name in source) {
                    if (name != 'VersionModelData')
                        model[name] = source[name];
                }

                return model;
            }
        }
    };

    private _GetApiModel(methodNameUI: string, appSettingsKey: string, crudAction: CrudActions, filter: any, _obj: any, entityName: string): any {

        if (!crudAction)
            crudAction = CrudActions.None;
        if (_obj) {

            _obj.Filter = filter;
            _obj.MethodNameUI = methodNameUI;
            _obj.QueryConfig = {
                AppSettingsKey: appSettingsKey, EntityName: entityName,
                CrudAction: crudAction,
                Filter: filter
            };

            return _obj;
        }
        else {
            var obj = {
                Filter: filter,
                MethodNameUI: methodNameUI, QueryConfig: {
                    AppSettingsKey: appSettingsKey, EntityName: entityName,
                    CrudAction: crudAction,
                    Filter: filter
                }
            };

            return obj;
        }


    };

    /*   public SetObjectToOject(modelSource: any, modelDest: any) {
          if (modelSource) {
              if (!modelDest)
                  modelDest = {};
              $.each(modelSource, function (key: string, value) {

                  modelDest[key] = value;

              });
          }
      } */
    public GetConfigFromPagina(propName: string, pagina: any) {
        var result = null;

        $.each(pagina.ConfigPage, function (key: string, value) {

            if (key === propName) {
                result = value;
                return;
            }

        });

        return result;
    }
    public GetConfigObservacionesVersion() {
        var result = { Required: true, MaxLength: 900, MinLength: 0, Label: 'Observación', Activo: true };
        return result;
    }

    public GetDataTabledtInstance(dtElements: any, controlName: string, componentName: string): Promise<any> {

        const that = this;
        return new Promise((resolve, rejection) => {
            try {
                if (dtElements instanceof QueryList) {

                    dtElements.forEach((dtElement: DataTableDirective, index: number) => {

                        dtElement.dtInstance.then((dtInstance: any) => {
                            if (dtInstance.table().node().id == controlName)
                                resolve(dtInstance);
                        });
                    });
                }
                else {
                    dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
                        resolve(dtInstance);
                    });
                }

            }
            catch (error) {
                this.logger.LogError(error, null, { componentName: componentName, method: 'GetDataTabledtInstance', controlName: controlName });
                rejection(error);
            }
        });
    }
    public ModalChild(componet: any, config: ConfigWindow = null, modelo: any = null, containerId?: string): Promise<any> {

        return this.OpenWindow(componet, config, modelo, containerId);
    }
    public OpenWindow(componet: any, config: ConfigWindow = null, modelo: any = null, containerId?: string): Promise<any> {
        const that = this;
        return new Promise((resolve, rejection) => {
            const window = this.windowService.initWindow(containerId);
            let componentInstance: any;

            config = (!config) ? new ConfigWindow() : config;
            window.config = config;
            window.title = config.titleWindow;
            window.viewComponentHeader = config.viewComponentHeader;
            window.viewComponentTitle = config.viewComponentTitle;

            window.draggable = config.draggable;
            window.closeable = config.closeable;
            window.resizable = config.resizable;


            let onClosePromise = new Promise((resolveOnClose, rejectionOnClose) => {
                window.onClose = (dialogResult: boolean, data: any) => {

                    if (config.returnInstance)
                        resolveOnClose({ dialogResult: dialogResult, componentInstance: componentInstance, type: 'close', config: config });
                    else
                        resolveOnClose({ dialogResult: dialogResult, modelo: componentInstance.modelo, type: 'close', config: config });
                };
            });

            window.afterComponentCreate = {
                afterCreate(instance: any) {

                    componentInstance = instance;
                    if (modelo) {
                        that.SetFieldsToModel(componentInstance, modelo);

                    }
                    if (config.returnInstance)
                        resolve({ onClosePromise: onClosePromise, componentInstance: componentInstance, type: 'afterCreate', config: config });
                    else
                        resolve({ onClosePromise: onClosePromise, modelo: componentInstance.modelo, type: 'afterCreate', config: config });



                    //that._instance.SetDatos('asjdaskdjsa');
                }
            }


            window.bodyComponent = componet;
            // window.bodyHtml = '<b>This is the best message over the world ;) </b>'
            window.bodyHtml = config.bodyHtml;
            window.width = config.width;
            window.height = config.height;
            if (config.center)
                window.center();
            window.modal = config.modal;
            window.showWindow();


        });
    }
    public VerModalGeneral(modalNotifyTypes = ModalNotifyTypes.Info,
        _modelo: any = null, data: any = null): Promise<any> {
        this.setModalNotify(modalNotifyTypes);
        if (_modelo) {
            if (_modelo.logErr == true)
                this.logger.LogErrorText(_modelo.descripcion);
            else if (_modelo.logWarn == true)
                this.logger.LogWarningText(_modelo.descripcion);
            else if (_modelo.logInfo == true)
                this.logger.LogInfoText(_modelo.descripcion);

        }

        let ok = true;
        switch (modalNotifyTypes.Id) {
            case ModalNotifyTypes.Question.Id:
            case ModalNotifyTypes.QuestionDanger.Id:
            case ModalNotifyTypes.QuestionWarning.Id:
                ok = true;
                break;
            case ModalNotifyTypes.Success.Id:

                if (StaticData.GeneralConfig.ConfigUI.VerOkEnNotificacion) {
                    ok = false;
                    StaticData.ShowActionNotificationSuccess(_modelo.descripcion, _modelo.titulo, StaticData.GeneralConfig.ConfigUI.TiempoNotificacionOk);
                }

                break;
            case ModalNotifyTypes.Info.Id:

                if (StaticData.GeneralConfig.ConfigUI.VerInfoEnNotificacion) {
                    ok = false;
                    StaticData.ShowActionNotificationInfo(_modelo.descripcion, _modelo.titulo, StaticData.GeneralConfig.ConfigUI.TiempoNotificacionInfo);
                }
                break;
            case ModalNotifyTypes.Warning.Id:

                if (StaticData.GeneralConfig.ConfigUI.VerAlertaEnNotificacion) {
                    ok = false;
                    StaticData.ShowActionNotificationWarning(_modelo.descripcion, _modelo.titulo, StaticData.GeneralConfig.ConfigUI.TiempoNotificacionAlerta);
                }
                break;
            case ModalNotifyTypes.Danger.Id:

                if (StaticData.GeneralConfig.ConfigUI.VerErrorEnNotificacion) {
                    ok = false;
                    StaticData.ShowActionNotificationError(_modelo.descripcion, _modelo.titulo, StaticData.GeneralConfig.ConfigUI.TiempoNotificacionError);
                }
                break;
        }

        if (ok) {
            return new Promise((resolve, rejection) => {
                const that = this;
                if (resolve) {
                    this.modalNotify.onClose = function (modelo) {

                        that.modalNotify.onClose = undefined;
                        resolve(modelo);
                        try {
                            $(".ms-Overlay").remove();
                        } catch (error) { }
                    }
                }
                this.modalNotify.open(modalNotifyTypes, _modelo);

            });
        }
        else {
            return new Promise((resolve, rejection) => {
                const that = this;
                if (resolve) {
                    resolve(_modelo);
                    try {
                        $(".ms-Overlay").remove();
                    } catch (error) { }
                }
            });
        }
    };
    public VerModalWarning(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Warning, _modelo);
    };
    public VerModalAlertaPermisos(_modelo: any = null): Promise<any> {
        _modelo = (_modelo) ? _modelo : { titulo: 'Alerta Permisos', descripcion: 'No posee permisos para esta operación' };
        return this.VerModalGeneral(ModalNotifyTypes.Danger, _modelo);
    };
    public VerModalDanger(_modelo: any = null): Promise<any> {
        _modelo = (_modelo) ? _modelo : { titulo: 'Error Proceso', descripcion: 'Ocurrio un error al ejecutar este proceso..' };
        return this.VerModalGeneral(ModalNotifyTypes.Danger, _modelo);
    };
    public VerModalDark(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Dark, _modelo);
    };
    public VerModalInfo(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Info, _modelo);
    };
    public VerModalLight(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Light, _modelo);
    };
    public VerModalLink(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Link, _modelo);
    };
    public VerModalPrimary(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Primary, _modelo);
    };
    public VerModalSecondary(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Secondary, _modelo);
    };
    public VerModalSuccess(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Success, _modelo);
    };



    public VerModalInformativo(_modelo: any,): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Info, _modelo);
    };
    public VerModalOk(descripcion: string = null) {

        return this.VerModalGeneral(ModalNotifyTypes.Success,
            {
                titulo: this.translate.instant("Mensaje Informativo", { defaultValue: "Mensaje Informativo" }),
                descripcion: (descripcion) ? this.translate.instant(descripcion, { defaultValue: descripcion }) : this.translate.instant('Proceso ejecutado correctamente', { defaultValue:'Proceso ejecutado correctamente' }),
                verOk: true, verCancelar: false, ok: this.translate.instant("ACEPTAR", { defaultValue: "ACEPTAR" }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalError(descripcion: string = null): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Danger,
            {
                titulo: this.translate.instant('Mensaje Error', { defaultValue:'Mensaje Error' }),
                descripcion: (descripcion) ? this.translate.instant(descripcion, { defaultValue: descripcion }) : this.translate.instant('Ocurrio un error en el proceso...', { defaultValue:'Ocurrio un error en el proceso...' }),
                verOk: true, verCancelar: false, ok: this.translate.instant("ACEPTAR", { defaultValue: "ACEPTAR" }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacionGeneral(titulo: string, descripcion: string, modalNotifyTypes = ModalNotifyTypes.Question): Promise<any> {

        return this.VerModalGeneral(modalNotifyTypes,
            {
                titulo: this.translate.instant(titulo, { defaultValue:titulo }),
                descripcion: this.translate.instant(descripcion, { defaultValue:descripcion }),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacion(descripcion: string = null): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Question,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant(descripcion, { defaultValue:descripcion }),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacionContinuar(): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Question,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant('¿Está seguro que desea continuar esta operación?', { defaultValue:'¿Está seguro que desea continuar esta operación?' }),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacionGuardar(isVersion: boolean = false): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Question,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: (isVersion == true) ? this.translate.instant('¿Está seguro que desea guardar esta Información y Generar una nueva versión?') : this.translate.instant('¿Está seguro que desea guardar esta información?'),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacionEditar(): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Question,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant('¿Está seguro que desea editar esta información?', { defaultValue:'¿Está seguro que desea editar esta información?' }),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };

    public VerModalConfirmacionActivar(): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.QuestionWarning,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant('¿Está seguro que desea cambiar el estado del registro?', { defaultValue:'¿Está seguro que desea cambiar el estado del registro?' }),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };

    public VerModalConfirmacionEliminar(): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.QuestionWarning,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant('¿Está seguro que desea eliminar el registro?', { defaultValue:'¿Está seguro que desea eliminar el registro?' }),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacionActualiazionDoc(): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.QuestionWarning,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant('Desea Actualizar o crear una nueva version del documento?', { defaultValue:'Desea Actualizar o crear una nueva version del documento?' }),
                verOk: true, verCancelar: true, verExtra: true, ok: this.translate.instant('ACTUALIZAR', { defaultValue:'ACTUALIZAR' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" }), extra: 'NUEVA VERSION'
            });

    };
    public VerModalConfirmacionActualizarPropiedad(propiedad: string): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.QuestionWarning,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant('¿Está seguro que desea actualizar la propiedad ', { defaultValue:'¿Está seguro que desea actualizar la propiedad ' }) + this.translate.instant(propiedad, { defaultValue:propiedad }) + '?',
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacionPermisos(propiedad: string, value: any): Promise<any> {


        return this.VerModalGeneral(ModalNotifyTypes.QuestionWarning,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: (value == true || value === 'true') ? this.translate.instant('¿Está seguro que desea inactivar el permiso de ', { defaultValue:'¿Está seguro que desea inactivar el permiso de ' }) + this.translate.instant(propiedad, { defaultValue:propiedad }) + '?' :
                    ((value == false || value === 'false') ? this.translate.instant('¿Está seguro que desea activar el permiso de ', { defaultValue:'¿Está seguro que desea activar el permiso de ' }) + this.translate.instant(propiedad, { defaultValue:propiedad }) + '?' :
                    this.translate.instant('¿Está seguro que desea eliminar el permiso de ', { defaultValue:'¿Está seguro que desea eliminar el permiso de ' }) + this.translate.instant(propiedad, { defaultValue:propiedad }) + '?'),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };

    public VerModalConfirmacionCerrar(): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Question,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: '¿Está seguro que desea cancelar?',
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public VerModalConfirmacionLimpiar(): Promise<any> {

        return this.VerModalGeneral(ModalNotifyTypes.Question,
            {
                titulo: this.translate.instant('Mensaje Confirmación', { defaultValue:'Mensaje Confirmación' }),
                descripcion: this.translate.instant('¿Está seguro que desea limpiar esta información?', { defaultValue:'¿Está seguro que desea limpiar esta información?' }),
                verOk: true, verCancelar: true, ok: this.translate.instant('SI', { defaultValue:'SI' }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
            });

    };
    public FindControlPagina(pagina: any, nombre: string, esExtra: boolean = false): any {

        let control;
        if (esExtra) {
            if (pagina.ConfigPage && pagina.ConfigPage.ExtraControls) {
                control = new List<any>(pagina.ConfigPage.ExtraControls).Where(x => { return x.Name === nombre }).FirstOrDefault();
            }
        }
        else {
            if (pagina.ConfigPage && pagina.ConfigPage.Controls) {
                control = new List<any>(pagina.ConfigPage.Controls).Where(x => { return x.Name === nombre }).FirstOrDefault();
            }
        }
        return control;
    }
    public SetControlConfig(control: any, configs: any) {

        if (control.ConfigControl && (control.ControlType == 'ComboBox' || control.ControlType == 'MultiComboBox')) {
            let processMessage = 'Cargando ' + control.Label;
            this._SetControlsConfig(configs, control.ConfigControl, control.ControlType, 'Config' + control.Name, processMessage);
            this._SetControlsConfig(configs, control.ConfigSearchControl, control.ControlType, 'ConfigSearch' + control.Name, processMessage)
        }
    }
    public SetControlsConfig(pagina: any, configs: any) {
        const that = this;

        if (pagina.ConfigPage && pagina.ConfigPage.Controls) {
            let controls = new List<any>(pagina.ConfigPage.Controls).Where(x => { return x.ConfigControl }).ToArray();
            $.each(controls, function (key: number, field) {
                let processMessage = 'Cargando ' + field.Label;
                that._SetControlsConfig(configs, field.ConfigControl, field.ControlType, 'Config' + field.Name, processMessage);
                that._SetControlsConfig(configs, field.ConfigSearchControl, field.ControlType, 'ConfigSearch' + field.Name, processMessage)

            });
        }
    };
    // public SetControlsConfig(pagina: any, configs: any) {
    //     const that = this;
    //     if (pagina.Config) {
    //         $.each(pagina.Config, function (key: string, field) {

    //             that._SetControlsConfig(configs, field.ConfigControl, field.ControlType, 'Config' + key);
    //             that._SetControlsConfig(configs, field.ConfigSearchControl, field.ControlType, 'ConfigSearch' + key)

    //         });
    //     }
    // };
    public GetUrlServer(url: string): string {
        return StaticData.UrlServer + '/api/' + url + '/Get';
    }

    public FindPageForComponentIDE(name: string, type: string): any {
        let _paginas = new List<any>(StaticData.Usuario.Paginas);
        var pagina = _paginas.Where(function (x) {
            return x.Nombre === name;
        }).FirstOrDefault();

        if (pagina) {
            return {
                IdPagina: pagina.IdPagina,
                Nombre: pagina, Titulo: pagina.Titulo,
                Descripcion: pagina.Descripcion,
                Estilos: StaticData.Estilos,
                ColSpan: 12,
                ControlType: name,
                Type: type
            };
        }
        else {
            return null;
        }
    }
    //public FindPageForDesigner
    public ProcesarPaginas(_camposPaginas: any[]): Array<any> {


        let paginas: Array<any> = [];
        let camposPaginas = new List<any>(_camposPaginas);
        //let camposRole = new List<any>(_camposRole);
        const that = this;

        $.each(StaticData.Usuario.Paginas, function (index, _pagina) {
            let pagina = that.Clone(_pagina);
            pagina.Valida = true;
            pagina.Guardar = true;
            pagina.Eliminar = true;
            pagina.Editar = true;
            pagina.Importar = true;
            pagina.Filtrar = true;
            pagina.Exportar = true;
            if (StaticData.Estilos) {
                pagina.Estilos = StaticData.Estilos;
            }
            pagina.Config = camposPaginas.Where(x => x.IdPagina == pagina.IdPagina).ToArray();
            //pagina.Controls = {};



            if (pagina.Configuracion && !pagina.ConfigPage) {

                pagina.ConfigPage = JSON.parse(pagina.Configuracion);
                pagina.Configuracion = null;
                if (pagina.ConfigPage.QuerysByRole && pagina.ConfigPage.QuerysByRole.length > 0) {
                    let _rolesQuery = new List<any>(pagina.ConfigPage.QuerysByRole);
                    pagina.ConfigPage.QueryByRole = _rolesQuery.Where(function (x) {
                        return x.IdRole == StaticData.Usuario.IdRole;
                    }).FirstOrDefault();

                    pagina.ConfigPage.QuerysByRole = null;
                }
                if (pagina.ConfigPage.RoleFilters && pagina.ConfigPage.RoleFilters.length > 0) {

                    let _rolesFilters = new List<any>(pagina.ConfigPage.RoleFilters);
                    pagina.ConfigPage.RoleFilter = _rolesFilters.Where(function (x) {
                        return x.IdRole == StaticData.Usuario.IdRole && x.Filter;
                    }).FirstOrDefault();

                    pagina.ConfigPage.RoleFilters = null;
                }
                let _controls = new List<any>(pagina.ConfigPage.Controls);
                pagina.ConfigPage.Controls = _controls.Where(function (x) { return (x.Activo == true) }).ToArray();
            }


            // if (pagina.ConfiguracionFiltroUnion && !pagina.ConfigFilter) {

            //     pagina.ConfigFilter = JSON.parse(pagina.ConfiguracionFiltroUnion);
            //     let _filterFields = new List<any>(pagina.ConfigFilter.FilterFields);
            //     pagina.ConfigFilter.FilterFields = _filterFields.Where(function (x) { return x.Active == true }).ToArray();
            // }
            // else if (pagina && pagina.ConfiguracionFiltro && !pagina.ConfigFilter) {
            //     pagina.ConfigFilter = JSON.parse(pagina.ConfiguracionFiltro);
            //     let _filterFields = new List<any>(pagina.ConfigFilter.FilterFields);
            //     pagina.ConfigFilter.FilterFields = _filterFields.Where(function (x) { return x.Active == true }).ToArray();
            //     if (pagina.Filtro != true && pagina.FiltroGeneral == true)
            //         pagina.Filtro = true;
            // }


            paginas.push(pagina);
        });

        //return this.Clone(result);
        //return paginas;
        return paginas;
    };


    public ProcesarPaginasPromise(_camposPaginas: any[]): Promise<any> {
        return new Promise((resolve, rejection) => {
            let paginas: Array<any> = this.ProcesarPaginas(_camposPaginas);

            resolve(paginas);
        });
    };
    public SetValorPermisosItemActual(result: any, value: boolean, setvalida: boolean = true, noEspagina?: boolean) {
        if (setvalida == true)
            //    result.Valida = value;

            result.Guardar = value;
        result.Editar = value;
        result.Eliminar = value;
        result.Importar = value;
        result.Exportar = value;
        result.Consultar = value;
        result.Filtrar = value;
        result.Descargar = value;
        result.Ver = value;
        if (noEspagina == true) {
            result.Ver = value;
            result.VerPermisos = value;
            result.AdminPermisos = value;
            result.CrearDoc = value;
            result.CambiarDoc = value;
            result.Firmar = value;
        }
    }
    public SetPermisosItemActual(result: any, permisoUsuario: any, noEspagina?: boolean) {
        if (permisoUsuario) {
            result.Guardar = permisoUsuario.Guardar;
            result.Editar = permisoUsuario.Editar;
            result.Eliminar = permisoUsuario.Eliminar;
            result.Importar = permisoUsuario.Importar;
            result.Exportar = permisoUsuario.Exportar;
            result.Consultar = permisoUsuario.Consultar;
            result.Filtrar = permisoUsuario.Filtrar;
            result.Descargar = permisoUsuario.Descargar;
            result.Ver = permisoUsuario.Ver;
            if (noEspagina == true) {

                result.Ver = permisoUsuario.Ver;
                result.VerPermisos = permisoUsuario.VerPermisos;
                result.AdminPermisos = permisoUsuario.AdminPermisos;
                result.CrearDoc = permisoUsuario.CrearDoc;
                result.CambiarDoc = permisoUsuario.CambiarDoc;
                result.Firmar = permisoUsuario.Firmar;
            }
        }
    }

    TienePemisoUsuarioInterno(Permisos: List<any>, configPermisos: any, permisoField: any, onlyPermisoUsuario: boolean = false,
        field: string = 'Configuracion', clear: boolean = false, tipoObjetoLog?: string, item?: any) {
        // Se ajuste valor por defecto ya los permisos los dejaba OK edwin Alonso 17022021
        let ok = false;
        this.logger.ConsoleLogPermisos(`Total Permisos:${Permisos.Count()}, Buscar`, tipoObjetoLog, permisoField, ok, "", "", Permisos, configPermisos, item);
        if (!tipoObjetoLog) {

        }
        if (configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Documento && StaticData.GeneralConfig.ConfigValidacionDocumento.HeredaPermisosExpediente && configPermisos.expediente) {
            if (configPermisos.expediente.Configuracion) {
                let permisosExpediente = new List<any>();
                let permisos = JSON.parse(configPermisos.expediente.Configuracion);
                permisos.forEach(element => {
                    permisosExpediente.Add(element);
                });
                Permisos = permisosExpediente;
            }
        }
        let permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdUsuario && (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') });
        if (permisoUsuario) {
            ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'Usuario', permisoUsuario.Nombre, item);
            if (!ok) {
                permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdRole && (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') });
                if (permisoUsuario)
                    ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'Role', permisoUsuario.Nombre, item);
                if (!ok) {
                    permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdAreaEmpresa && (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') });
                    if (permisoUsuario)
                        ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'AreaEmpresa', permisoUsuario.Nombre, item);
                    if (!ok) {
                        permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdSedeEmpresa && (x.Tipo == TipoParaPermisos.SedeEmpresa || x.Tipo == 'SedeEmpresa') });
                        if (permisoUsuario)
                            ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'SedeEmpresa', permisoUsuario.Nombre, item);
                    }
                }
            }
        }
        else {
            permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdRole && (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') });
            if (permisoUsuario) {
                ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'Role', permisoUsuario.Nombre, item);
                if (!ok) {
                    permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdAreaEmpresa && (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') });
                    if (permisoUsuario) {
                        ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'AreaEmpresa', permisoUsuario.Nombre, item);
                        if (!ok) {
                            permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdSedeEmpresa && (x.Tipo == TipoParaPermisos.SedeEmpresa || x.Tipo == 'SedeEmpresa') });
                            if (permisoUsuario)
                                ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'SedeEmpresa', permisoUsuario.Nombre, item);
                        }
                    }
                }
            }
            else {
                permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdAreaEmpresa && (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') });
                if (permisoUsuario) {
                    ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'AreaEmpresa', permisoUsuario.Nombre, item);
                    if (!ok) {
                        permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdSedeEmpresa && (x.Tipo == TipoParaPermisos.SedeEmpresa || x.Tipo == 'SedeEmpresa') });
                        if (permisoUsuario)
                            ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'SedeEmpresa', permisoUsuario.Nombre, item);
                    }
                }
                else {
                    permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdSedeEmpresa && (x.Tipo == TipoParaPermisos.SedeEmpresa || x.Tipo == 'SedeEmpresa') });
                    if (permisoUsuario)
                        ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'SedeEmpresa', permisoUsuario.Nombre, item);
                    else {
                        let setPermiso = false;
                        if (StaticData.Usuario.IdGrupos && StaticData.Usuario.IdGrupos.split('|').length > 0) {
                            let idGrupos: Array<any> = StaticData.Usuario.IdGrupos.split('|');
                            for (var i = 0; i < idGrupos.length; i++) {
                                let idGrupo = idGrupos[i];
                                permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == idGrupo && (x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo') });
                                if (permisoUsuario) {
                                    ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'Grupo', permisoUsuario.Nombre, item);
                                    setPermiso = true;
                                    break;
                                }

                            }
                        }
                        // else if (configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Documento && StaticData.GeneralConfig.ConfigValidacionDocumento.HeredaPermisosExpediente) {
                        //     if (configPermisos.expediente && configPermisos.expediente.Configuracion) {
                        //         let permisosExpediente = JSON.parse(configPermisos.expediente.Configuracion);
                        //         let permisoProceso = permisosExpediente.find(x => { return x.Id == StaticData.Usuario.IdUsuario && (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') });
                        //         if (!permisoProceso) {
                        //             permisoProceso = permisosExpediente.find(x => { return x.Id == StaticData.Usuario.IdRole && (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') });
                        //             if (!permisoProceso) {
                        //                 permisoProceso = permisosExpediente.find(x => { return x.Id == StaticData.Usuario.IdAreaEmpresa && (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') });
                        //                 if (!permisoProceso) {
                        //                     permisoProceso = permisosExpediente.find(x => { return x.Id == StaticData.Usuario.IdSedeEmpresa && (x.Tipo == TipoParaPermisos.SedeEmpresa || x.Tipo == 'SedeEmpresa') });
                        //                 }
                        //             }

                        //         }
                        //         if (permisoProceso) {
                        //             permisoUsuario = permisoProceso
                        //             if (permisoUsuario.Editar) {
                        //                 permisoUsuario.Descargar = true;
                        //                 permisoUsuario.Ver = true;
                        //             }
                        //             var permisosDocumentos: any
                        //             permisosDocumentos = JSON.parse(item.Configuracion);
                        //             let permisoUsuarioDocumento = permisosDocumentos.find(x => { return x.Id == StaticData.Usuario.IdUsuario && (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') });
                        //             if (!permisoUsuarioDocumento)
                        //                 permisosDocumentos.push(permisoUsuario)
                        //             item.Configuracion = JSON.stringify(permisosExpediente)
                        //             ok = this.GetPermisoInheritance(configPermisos, permisoUsuario, permisoField, onlyPermisoUsuario, field, clear, tipoObjetoLog, 'Usuario', "PermisoHeredadoExpediente", item);
                        //             setPermiso = ok;
                        //         }

                        //     }
                        // }
                        if (setPermiso == false && configPermisos.Pagina && configPermisos.Pagina[permisoField] != true) {
                            ok = false;
                            this.logger.ConsoleLogPermisos(`Ultima Búsqueda,Pagina:${configPermisos.Pagina.Ruta}`, tipoObjetoLog, permisoField, ok, "Pagina", "Pagina", null, configPermisos, item);
                        }
                    }
                }
            }
        }
        return ok;
    }
    GetPermisoInheritance(configPermisos: any, permisoUsuario: any, permisoField: any, onlyPermisoUsuario: boolean = false,
        field: string = 'Configuracion', clear: boolean = false, tipoObjetoLog?: string, tipoFiltro?: string, nombreFiltro?: any, item?: any) {
        let ok: any = false;

        if (onlyPermisoUsuario) {
            this.logger.ConsoleLogPermisos(`SoloPermisosUsuario:${onlyPermisoUsuario}`, tipoObjetoLog, permisoField, ok, tipoFiltro, nombreFiltro, permisoUsuario, configPermisos, item);
            ok = permisoUsuario[permisoField];
            return ok;
        }


        if (configPermisos.expediente && permisoUsuario.HeredarExpediente) {
            let permiso = permisoUsuario.HeredarExpediente.find(x => { return x.Nombre == permisoField && x.Valor == true });
            if (permiso) {
                let Permisos = this.GetListaPermisos(configPermisos.expediente, field, clear);
                this.logger.ConsoleLogPermisos(`Validar desde Heredar Expediente,permisos desde Expediente:${configPermisos.expediente.Nombre},Id:${configPermisos.expediente.IdExpediente}`, tipoObjetoLog, permisoField, ok, tipoFiltro,
                    nombreFiltro, permisoUsuario, configPermisos, item);
                ok = this.TienePemisoUsuarioInterno(Permisos, configPermisos, permisoField, true, field, clear, tipoObjetoLog, item);
                return ok;
            }
        }
        if (permisoUsuario.Heredar) {
            let permiso = permisoUsuario.Heredar.find(x => { return x.Nombre == permisoField && x.Valor == true });
            if (permiso && configPermisos.Pagina) {
                this.logger.ConsoleLogPermisos(`Validar desde Heredar Pagina,Pagina:${configPermisos.Pagina.Ruta}`, tipoObjetoLog, permisoField, ok,
                    tipoFiltro, nombreFiltro, permisoUsuario, configPermisos, item);
                ok = configPermisos.Pagina[permisoField];
                return ok;
            }

        }
        if (permisoUsuario.Custom) {
            let permiso = permisoUsuario.Custom.find(x => { return x.Nombre == permisoField });
            if (permiso) {

                ok = permiso.Valor;
                this.logger.ConsoleLogPermisos(`Validar desde Custom`, tipoObjetoLog, permisoField, ok, tipoFiltro, nombreFiltro, permisoUsuario, configPermisos, item);
                return ok;
            }

        }
        if (configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Documento && StaticData.GeneralConfig.ConfigValidacionDocumento.HeredaPermisosExpediente && configPermisos.expediente) {
            permisoUsuario.Ver = permisoUsuario.Editar
            permisoUsuario.Descargar = permisoUsuario.Editar
            if(StaticData.GeneralConfig.ConfigUI.UnificarPermisos)
            {
                permisoUsuario.Guardar = permisoUsuario.Editar
                permisoUsuario.CrearDoc = permisoUsuario.Editar
            }
        }
        ok = permisoUsuario[permisoField];
        this.logger.ConsoleLogPermisos(`Validar Objeto Permiso`, tipoObjetoLog, permisoField, ok, tipoFiltro, nombreFiltro, permisoUsuario, configPermisos, item);

        return ok;
    }
    public TienePemisoUsuarioDos(configPermisos: any, item: any, permisoField, fromPage, tipoObjetoLog): boolean {

        return this.TienePemisoUsuario(configPermisos, item, permisoField, 'Configuracion', false, fromPage, tipoObjetoLog);
    }

    public TienePemisoUsuario(configPermisos: any, item: any, permisoField: string = null, field: string = 'Configuracion', clear: boolean = false,
        fromPage: boolean = false, tipoObjetoLog?: string): boolean {
        let ok = true;
        if (!tipoObjetoLog) {

        }
        this.logger.ConsoleLogPermisos(`Inicio Validacion`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
        if (!configPermisos) {

            this.logger.ConsoleLogPermisos(`Permisos es Nulo`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
            return ok;
        }

        clear = (configPermisos.clear) ? configPermisos.clear : clear;
        if (!item) {
            this.logger.ConsoleLogPermisos(`Modelo es Nulo`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
            return ok;
        }
        let Permisos = (configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Pagina) ? new List<any>() : this.GetListaPermisos(item, field, clear);
        // // let Permisos = this.GetListaPermisos(item, field, clear);
        if (Permisos.Count() == 0 && configPermisos.expediente) {
            this.logger.ConsoleLogPermisos(`Buscando permisos desde Expediente:${configPermisos.expediente.Nombre}​,Id:${configPermisos.expediente.IdExpediente}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
            Permisos = this.GetListaPermisos(configPermisos.expediente, field, clear);
        }
        //this.logger.ConsoleLogPermisos(`Total:${​Permisos.Count()}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos);
        if (!configPermisos.TipoObjetoParaPermiso || configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.General) {

            this.logger.ConsoleLogPermisos(`TipoObjetoParaPermiso:General`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
            return ok;

        }
        // StaticData.Usuario.TodosPermisosExpedientes = false;
        // StaticData.Usuario.TodosPermisosDocumentosExpedientes = false;
        if ((configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Expediente || configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Ubicacion) &&
            StaticData.Usuario.TodosPermisosExpedientes == true && StaticData.GeneralConfig.ConfigValidacionExpediente.AdminFuncionalExpedientes) {
            this.logger.ConsoleLogPermisos(`TodosPermisosExpedientes:true, TipoObjetoParaPermiso:${TipoObjetoParaPermisos[configPermisos.TipoObjetoParaPermiso]}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
            return ok;
        }

        // Pendiente ajuste de permiso false en paginas y documentos Edwin Alonso Ramirez 17022021
        //         else if ((configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Expediente || configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Ubicacion) &&
        //             StaticData.Usuario.TodosPermisosExpedientes == false) {​
        //             ok = false;
        //             this.logger.ConsoleLogPermisos(`TodosPermisosExpedientes:false, TipoObjetoParaPermiso:${​TipoObjetoParaPermisos[configPermisos.TipoObjetoParaPermiso]}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
        //             return ok;
        //         }​
        else if (configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Documento &&
            StaticData.Usuario.TodosPermisosDocumentosExpedientes == true && StaticData.GeneralConfig.ConfigValidacionExpediente.AdminFuncionalDocumentos) {

            this.logger.ConsoleLogPermisos(`TodosPermisosDocumentosExpedientes:true, TipoObjetoParaPermiso:Documento`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
            return ok;
        }
        //         else if (configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Documento &&
        //             StaticData.Usuario.TodosPermisosDocumentosExpedientes == false) {​
        //             ok = false;
        //             this.logger.ConsoleLogPermisos(`TodosPermisosDocumentosExpedientes:false, TipoObjetoParaPermiso:Documento`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
        //             return ok;
        //         }​
        else {



            if (configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Pagina &&
                StaticData.Usuario.TodosPermisosPaginas == true) {
                this.logger.ConsoleLogPermisos(`TodosPermisosPaginas:true, TipoObjetoParaPermiso:${TipoObjetoParaPermisos[configPermisos.TipoObjetoParaPermiso]}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
                return ok;
            }
            //             else if (configPermisos.TipoObjetoParaPermiso == TipoObjetoParaPermisos.Pagina  &&
            //                 StaticData.Usuario.TodosPermisosPaginas == false) {​
            //                 ok = false;
            //                 this.logger.ConsoleLogPermisos(`TodosPermisosPaginas:false, TipoObjetoParaPermiso:${​TipoObjetoParaPermisos[configPermisos.TipoObjetoParaPermiso]}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
            //                 return ok;
            //             }​

            if (fromPage) {
                if (configPermisos.Pagina && configPermisos.Pagina[permisoField] == false) {
                    ok = false;
                    this.logger.ConsoleLogPermisos(`Valida Desde La Pagina:true,Pagina:${configPermisos.Pagina.Ruta}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
                    return ok;
                }
                Permisos = configPermisos.Pagina.Permisos;
            }

            permisoField = (permisoField) ? permisoField : configPermisos.Field;
            if (Permisos.Count() == 0) {
                if (configPermisos.Pagina && configPermisos.Pagina[permisoField] == false) {
                    ok = false;
                    this.logger.ConsoleLogPermisos(`Permisos:0,Valida Desde La Pagina:true,Pagina:${configPermisos.Pagina.Ruta}​`, tipoObjetoLog, permisoField, ok, "", "", null, configPermisos, item);
                    return ok;
                }
                Permisos = configPermisos.Pagina.Permisos;
            }

            ok = this.TienePemisoUsuarioInterno(Permisos, configPermisos, permisoField, false, field, clear, tipoObjetoLog, item);
        }

        if (ok === undefined)
            ok = false;
        if (!tipoObjetoLog) {

        }
        return ok;
    }

    TienePemisoUsuarioEnObjeto(Permisos: List<any>) {
        let ok = false;

        let permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdUsuario && (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') });
        if (permisoUsuario)
            ok = true;
        else {
            permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdRole && (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') });
            if (permisoUsuario)
                ok = true;
            else {
                permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdAreaEmpresa && (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') });
                if (permisoUsuario)
                    ok = true;
                else {
                    permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdSedeEmpresa && (x.Tipo == TipoParaPermisos.SedeEmpresa || x.Tipo == 'SedeEmpresa') });
                    if (permisoUsuario)
                        ok = true;
                    else {
                        let setPermiso = false;
                        if (StaticData.Usuario.IdGrupos && StaticData.Usuario.IdGrupos.split('|').length > 0) {
                            let idGrupos: Array<any> = StaticData.Usuario.IdGrupos.split('|');
                            for (var i = 0; i < idGrupos.length; i++) {
                                let idGrupo = idGrupos[i];
                                permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == idGrupo && (x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo') });
                                if (permisoUsuario) {
                                    ok = true;
                                    setPermiso = true;
                                    break;
                                }

                            }
                        }

                    }
                }
            }
        }
        return ok;
    }
    public GetArrayPermisos(item: any, field: string = 'Configuracion', clear: boolean = false): List<any> {
        let items = this.GetPermisos(item, false, field, clear);
        return items;
    }
    public GetListaPermisos(item: any, field: string = 'Configuracion', clear: boolean = false): List<any> {
        let items = this.GetPermisos(item, true, field, clear);
        return items;
    }
    public GetPermisos(item: any, isList: boolean, field: string = 'Configuracion', clear: boolean = false, internalField?: string, force: boolean = false): any {

        let Configuracion: Array<any> = [];
        let ConfiguracionList = new List<any>([]);
        if (item.ListaPermisos && item.ListaPermisos.Count() > 0 && force !== true) {
            return (isList) ? item.ListaPermisos : item.ListaPermisos.ToArray();
        }
        else {
            let strConfiguracion = '';
            let fields = field.split('.');
            if (fields.length > 1) {
                internalField = fields[1];
            }

            strConfiguracion = item[field];
            if (strConfiguracion) {

                if (internalField) {
                    let config: any = JSON.parse(strConfiguracion);
                    ConfiguracionList = new List<any>(config[internalField]);
                }
                else {
                    Configuracion = JSON.parse(strConfiguracion);
                    ConfiguracionList = new List<any>(Configuracion);
                }
                if (clear == true) {
                    // item[field] = null;
                    item.ListaPermisos = ConfiguracionList;
                }
            }
            return (isList) ? ConfiguracionList : Configuracion;
        }
    }
    public GetPermitidos(item: any, isList: boolean, field: string, splitChar: string = '|', clear: boolean = false): any {

        let Configuracion: Array<any> = [];
        let ConfiguracionList = new List<any>([]);
        if (item['Lista' + field]) {
            return (isList) ? item['Lista' + field] : item['Lista' + field].ToArray();
        }
        else {
            let strConfiguracion: string = item[field];
            Configuracion = (strConfiguracion) ? strConfiguracion.split(splitChar) : [];
            if (Configuracion.length > 0) {
                // Configuracion = strConfiguracion.split(splitChar);
                ConfiguracionList = new List<any>(Configuracion);
                if (clear == true) {
                    item[field] = null;
                    item['Lista' + field] = ConfiguracionList;
                }
            }
            return (isList) ? ConfiguracionList : Configuracion;
        }
    }
    public FindPage(items, key, compareValue, toUpperCase = false) {

        var result = null;
        const that = this;
        $.each(items, function (index, item) {
            if (toUpperCase == true) {
                if (item[key].toUpperCase() == compareValue.toUpperCase()) {
                    result = item;
                    return;
                }
            }
            else {
                if (item[key] == compareValue) {
                    result = item;
                    return;
                }
            }
        });
        if (result == null) {
            result = { Titulo: 'NO POSEE PERMISOS SOBRE LA PAGINA', Exportar: false, Guardar: false, Editar: false, Eliminar: false, Importar: false, Valida: false };
            result[key] = compareValue;
        }
        else {

            result.Valida = true;
            if (StaticData.Estilos) {
                result.Estilos = StaticData.Estilos;
            }
            result.BotonesComoMenu = StaticData.BotonesComoMenu;
            //if (!result.ModalUrl) {
            //    var dataUrl = result.Url.split('/');
            //    var url = dataUrl[dataUrl.length - 1];
            //    dataUrl[dataUrl.length - 1] = 'Modal' + url;
            //    result.ModalUrl = dataUrl.join('/');
            //}

            if (result) {

                if (result.IsModalCrud != true && result.IsModalCrud != false)
                    result.IsModalCrud = result.ConModalCrud;
                if (result.IsModalCrud != true && result.IsModalCrud != false)
                    result.IsModalCrud = false;

            }



            // if (result && StaticData.ConfigPaginas && !result.Config) {
            //     let _paginas = new List<any>(StaticData.ConfigPaginas);
            //     var Config = _paginas.Where(function (x) {
            //         return x.IdPagina == compareValue;
            //     }).FirstOrDefault();
            //     if (Config) {
            //         result.Config = Config.Config;
            //         // if (result.IsCustom) {
            //         //     result.Config = [];
            //         //     $.each(Config.Config, function (index, item) {
            //         //         result.Config.push(item);
            //         //     });
            //         // }
            //         // else
            //         //     result.Config = Config.Config
            //     }


            // }

            if (result && result.ConfiguracionPermisos && !result.Permisos) {
                var permisos = JSON.parse(result.ConfiguracionPermisos);
                result.Permisos = new List<any>(permisos);
                result.ConfiguracionPermisos = null;

                if (StaticData.Usuario.TodosPermisosPaginas == true) {
                    this.SetValorPermisosItemActual(result, true);
                    this.logger.ConsoleLogPermisos(`TodosPermisosPaginas:true,Pagina:${result.Ruta}`, "Pagina", "", true, "Pagina", "Pagina", null, null);
                }
                else {
                    let permisoUsuario = result.Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdUsuario && (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') });
                    if (permisoUsuario) {
                        this.SetPermisosItemActual(result, permisoUsuario);
                        this.logger.ConsoleLogPermisos(`Pagina:${result.Ruta}`, "Pagina", "", true, "Usuario", permisoUsuario.Nombre, permisoUsuario, { Pagina: result });
                    }
                    else {
                        permisoUsuario = result.Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdRole && (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') });
                        if (permisoUsuario) {
                            this.SetPermisosItemActual(result, permisoUsuario);
                            this.logger.ConsoleLogPermisos(`Pagina:${result.Ruta}`, "Pagina", "", true, "Role", permisoUsuario.Nombre, permisoUsuario, { Pagina: result });
                        }
                        else {
                            permisoUsuario = result.Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdAreaEmpresa && (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'UsuAreaEmpresaario') });
                            if (permisoUsuario) {
                                this.SetPermisosItemActual(result, permisoUsuario);
                                this.logger.ConsoleLogPermisos(`Pagina:${result.Ruta}`, "Pagina", "", true, "AreaEmpresa", permisoUsuario.Nombre, permisoUsuario, { Pagina: result });
                            }
                            else {
                                permisoUsuario = result.Permisos.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdSedeEmpresa && (x.Tipo == TipoParaPermisos.SedeEmpresa || x.Tipo == 'SedeEmpresa') });
                                if (permisoUsuario) {
                                    this.SetPermisosItemActual(result, permisoUsuario);
                                    this.logger.ConsoleLogPermisos(`Pagina:${result.Ruta}`, "Pagina", "", true, "SedeEmpresa", permisoUsuario.Nombre, permisoUsuario, { Pagina: result });
                                }
                                else {
                                    if (StaticData.Usuario.IdGrupos && StaticData.Usuario.IdGrupos.split('|').length > 0) {
                                        let idGrupos: Array<any> = StaticData.Usuario.IdGrupos.split('|');
                                        for (var i = 0; i < idGrupos.length; i++) {
                                            let idGrupo = idGrupos[i];
                                            permisoUsuario = result.Permisos.FirstOrDefault(x => { return x.Id == idGrupo && (x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo') });
                                            if (permisoUsuario) {
                                                this.SetPermisosItemActual(result, permisoUsuario);
                                                this.logger.ConsoleLogPermisos(`Pagina:${result.Ruta}`, "Pagina", "", true, "Grupo", permisoUsuario.Nombre, permisoUsuario, { Pagina: result });
                                                break;
                                            }
                                            else {
                                                this.SetValorPermisosItemActual(result, false);
                                                this.logger.ConsoleLogPermisos(`Pagina:${result.Ruta}`, "Pagina", "", false, "NegadoTodo", '', null, { Pagina: result });
                                            }
                                        }
                                    }
                                    else {
                                        this.SetValorPermisosItemActual(result, false);
                                        this.logger.ConsoleLogPermisos(`Pagina:${result.Ruta}`, "Pagina", "", false, "NegadoTodo", '', null, { Pagina: result });
                                    }

                                }
                            }
                        }
                    }

                }
            }



            if (result && result.Configuracion && !result.ConfigPage) {
                result.ConfigPage = JSON.parse(result.Configuracion);
                if (result.ConfigPage.Views && result.ConfigPage.Views.length > 0) {
                    let currentView: any = null;
                    result.ConfigPage.Views.forEach(view => {

                        if (!currentView) {
                            if (this.TienePemisoUsuarioEnObjeto(new List<any>(view.Permissions)))
                                currentView = view;
                        }
                    });
                    if (currentView)
                        result.ConfigPage = currentView;
                }
                result.ConfigPage.WorkFlowTask = StaticData.WorkFlowTaskConfig[compareValue];
                result.Configuracion = null;
                if (result.ConfigPage.QuerysByRole && result.ConfigPage.QuerysByRole.length > 0) {
                    let _rolesQuery = new List<any>(result.ConfigPage.QuerysByRole);
                    result.ConfigPage.QueryByRole = _rolesQuery.Where(function (x) {
                        return x.IdRole == StaticData.Usuario.IdRole;
                    }).FirstOrDefault();

                    result.ConfigPage.QuerysByRole = null;
                }
                if (result.ConfigPage.RoleFilters && result.ConfigPage.RoleFilters.length > 0) {

                    let _rolesFilters = new List<any>(result.ConfigPage.RoleFilters);
                    result.ConfigPage.RoleFilter = _rolesFilters.Where(function (x) {
                        return x.IdRole == StaticData.Usuario.IdRole && x.Filter;
                    }).FirstOrDefault();

                    result.ConfigPage.RoleFilters = null;
                }
                // let _controls = new List<any>(result.ConfigPage.Controls);
                // result.ConfigPage.Controls = _controls.Where(function (x) { return (x.Activo == true) }).ToArray();
                //let _meta = new List<any>(result.ConfigPage.ExtraControls);
                // result.ConfigPage.ExtraControls = _meta.Where(function (x) { return (x.Activo == true) }).ToArray();
            }


            if (result && result.ConfiguracionFiltroUnion && !result.ConfigFilter) {

                result.ConfigFilter = JSON.parse(result.ConfiguracionFiltroUnion);
                let _filterFields = new List<any>(result.ConfigFilter.FilterFields);
                result.ConfigFilter.FilterFields = _filterFields.Where(function (x) { return x.Active == true }).ToArray();
            }
            else if (result && result.ConfiguracionFiltro && !result.ConfigFilter) {
                result.ConfigFilter = JSON.parse(result.ConfiguracionFiltro);
                let _filterFields = new List<any>(result.ConfigFilter.FilterFields);
                result.ConfigFilter.FilterFields = _filterFields.Where(function (x) { return x.Active == true }).ToArray();
                if (result.Filtro != true && result.FiltroGeneral == true)
                    result.Filtro = true;
            }

        }

        return this.Clone(result);
    };
    public FindAndConfigPage(enumPagina: any): any {

        if (enumPagina == Paginas.Home) {
            return { IdPagina: -2, Pagina: 'Home', Url: 'Home.html', Ruta: 'Home', Valida: true, Titulo: 'Home' };

        }
        var paginaResult = (typeof enumPagina === 'string') ? this.FindPage(StaticData.Usuario.PaginasUsuario, 'Ruta', enumPagina) : this.FindPage(StaticData.Usuario.PaginasUsuario, 'IdPagina', enumPagina);
        if (paginaResult && paginaResult.Valida == true && !paginaResult.ConfigPage && StaticData.ConfigPaginas) {
            let _configPaginas = new List<any>(StaticData.ConfigPaginas);
            var Config = _configPaginas.Where(function (x) {
                return x.IdPagina == this.enumPagina;
            }).FirstOrDefault();
            if (Config)
                paginaResult.Config = Config.Config
        }
        return paginaResult;
    }

    public IsUserAuthenticated(enumPagina: any, verModal: boolean, controllerName: string, metodo: string,
        metodoInterno: string, gotoHome: boolean = true): boolean {
        if (!StaticData.Usuario || (StaticData.Usuario && !StaticData.Usuario.Token)) {

            const that = this;
            metodoInterno = (!metodoInterno) ? 'IsUserAuthenticated' : metodoInterno;
            if (enumPagina)
                this.logger.LogError(`Usuario No Autenticado Pagina: ${enumPagina.toString()},Clase: ${controllerName},Metodo: ${metodo},MetodoInterno: ${metodoInterno}`, null);
            else
                this.logger.LogError(`Usuario No Autenticado Clase: ${controllerName},Metodo: ${metodo},MetodoInterno: ${metodoInterno}`, null);
            //this.logger.LogError('Usuario No Autenticado Pagina:'+enumPagina.toString()+,null);
            //console.log('Usuario No Autenticado');
            // window.location = UrlIndexPage;
            if (enumPagina == Paginas.Home) {

            }
            else {
                if (verModal) {
                    this.VerModalWarning({
                        titulo: this.translate.instant('Alerta'), descripcion: this.translate.instant('EL Usuario No Se Encuentra Autenticado'),
                        verOk: true, verCancelar: false, ok: this.translate.instant("ACEPTAR", { defaultValue: "ACEPTAR" }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
                    }).then(data => { that.router.navigate(['home']); });
                }
            }
            if (gotoHome) {
                StaticData["MessajeNoAutenticado"] = true;
                this.router.navigate(['Home']);
            }
            return false;
        }
        return true;
    }

    public ValidateUserAndPage(enumPagina: any, verModal: boolean, controllerName: string, metodo: string): any {
        if (!this.IsUserAuthenticated(enumPagina, verModal, controllerName, metodo, 'ValidateUserAndPage')) {

            return { Valida: false, Guardar: false, Editar: false, Eliminar: false, Config: {} };
        }
        else
            return this.FindAndConfigPage(enumPagina);


    }


    public GetFilterParent(parent: any, source: any) {

        var filter = null;
        if (parent && source && parent.length > 0) {
            filter = {};
            $.each(parent, function (key, item) {

                filter[item.Name] = (item.Value) ? item.Value : source[item.Name];
            });
        }
        return filter;
    };

    public GetControlFilterParent(parent: any, source: any) {

        var filters = null;
        if (parent && source && parent.length > 0) {
            filters = [];
            $.each(parent, function (key, item) {
                var filter = { Name: item.Name, ControlName: item.StaticData, FilterField: (item.FkFieldName) ? item.FkFieldName : item.Name, Value: (item.Value) ? item.Value : source[item.Name] };
                filters.push(filter);
            });
        }
        return filters;
    };
    public IsValidChildOption(childOption: any): boolean {

        return ((childOption.FiltersParent && childOption.FiltersParent.length > 0) ||
            (childOption.ControlsToFilter && childOption.ControlsToFilter.length > 0) ||
            (childOption.MappingFilters && childOption.MappingFilters.length > 0) || childOption.Filter);
    }
    public GetChildOptions(pagina: any, callback: any = null, withClick: boolean = false): Array<any> {
        let options: Array<any> = null;

        const that = this;
        if (pagina.Valida == true && pagina.ConfigPage &&
            pagina.ConfigPage.ChildPages && pagina.ConfigPage.ChildPages.length > 0) {

            $.each(pagina.ConfigPage.ChildPages, function (index, childOption) {

                if (that.IsValidChildOption(childOption)) {
                    if (!options)
                        options = [];
                    var paginaResult = (childOption.IdPage) ? that.FindPage(StaticData.Usuario.PaginasUsuario, 'IdPagina', childOption.IdPage) : null;
                    if ((paginaResult && paginaResult.Valida == true) || (childOption.Component)) {
                        var label = (childOption.Label) ? childOption.Label : paginaResult.Titulo;

                        options.push({
                            label: label,
                            filterParent: (childOption.FiltersParent && childOption.FiltersParent.length > 0) ? childOption.FiltersParent : null,
                            mappingFilter: (childOption.MappingFilters && childOption.MappingFilters.length > 0) ? childOption.MappingFilters : null,
                            filter: childOption.Filter,
                            Option: childOption,
                            pagina: paginaResult,
                            click: (withClick == true) ? function (option) {

                                throw 'No implemetada la funcionalidad de _ModalChild para la childOption';
                                //_ModalChild(paginaResult.Url, paginaResult.Controlador, null, null, null, callback, null, option.filterParent, option.mappingFilter, option.filter, true, pagina);
                            } : null
                        });
                    }
                }

            });


        }
        return options;
    }

    public GetApiModel(methodNameUI: string, appSettingsKey: string, crudAction: CrudActions = CrudActions.None, filter: any = null, _obj: any = null, tableName: string = null): any {
        return this._GetApiModel(methodNameUI, appSettingsKey, crudAction, filter, _obj, tableName);
    };
    public GetApiModelGrilla(methodNameUI, appSettingsKey, crudAction, filter, _obj, tableName): any {
        if (!crudAction)
            crudAction = CrudActions.GetAllOrderByDesc;
        return this._GetApiModel(methodNameUI, appSettingsKey, crudAction, filter, _obj, tableName);
    };
    public GetApiModelGrillaValidation(methodNameUI: string, appSettingsKey: string, filterParent: any, mappingFilter: any, generalFilter: any, pagina: any, crudAction: CrudActions, filter: any, _obj: any = null, tableName: string = null): any {


        if (!crudAction)
            crudAction = CrudActions.GetAllOrderByDesc;

        var idQuery = (pagina && pagina.ConfigPage && pagina.ConfigPage.QueryByRole && pagina.ConfigPage.QueryByRole.IdQuery) ? pagina.ConfigPage.QueryByRole.IdQuery : appSettingsKey;
        var obj = this._GetApiModel(methodNameUI, idQuery, crudAction, filter, _obj, tableName);
        obj.Filter = null;
        if (pagina) {
            if (filterParent || mappingFilter || generalFilter || (pagina.ConfigPage && pagina.ConfigPage.Filter) ||
                (pagina.ConfigPage && pagina.ConfigPage.RoleFilter)) {
                obj.QueryConfig.CrudAction = CrudActions.GetByFilterOrderByDesc;
                obj.QueryConfig.Entity = (obj.QueryConfig.Entity) ? obj.QueryConfig.Entity : {};
                obj.QueryConfig.Filter = (obj.QueryConfig.Filter) ? obj.QueryConfig.Filter : '';
                if (filterParent)
                    obj.QueryConfig.Entity = this.SetFieldsToModel(obj.QueryConfig.Entity, filterParent);
                if (mappingFilter)
                    obj.QueryConfig.Entity = this.SetFieldsToModel(obj.QueryConfig.Entity, mappingFilter);
                if (generalFilter)
                    obj.QueryConfig.Filter += (obj.QueryConfig.Filter) ? ' AND ' + generalFilter : generalFilter;
                if (pagina.ConfigPage && pagina.ConfigPage.Filter)
                    obj.QueryConfig.Filter += (obj.QueryConfig.Filter) ? ' AND ' + pagina.ConfigPage.Filter : pagina.ConfigPage.Filter;
                if (pagina.ConfigPage && pagina.ConfigPage.RoleFilter)
                    obj.QueryConfig.Filter += (obj.QueryConfig.Filter) ? ' AND ' + pagina.ConfigPage.RoleFilter.Filter : pagina.ConfigPage.RoleFilter.Filter;

                //if (pagina.QueryGrilla && pagina.QueryGrilla.GridFilters && pagina.QueryGrilla.GridFilters.length > 0)
                //    obj.QueryConfig.Entity = _SetFieldsToModel(obj.QueryConfig.Entity, pagina.QueryGrilla.GridFilters);
            }
        }
        else {

            if (filterParent || mappingFilter || generalFilter) {
                obj.QueryConfig.CrudAction = CrudActions.GetByFilterOrderByDesc;
                obj.QueryConfig.Entity = (obj.QueryConfig.Entity) ? obj.QueryConfig.Entity : {};
                obj.QueryConfig.Filter = (obj.QueryConfig.Filter) ? obj.QueryConfig.Filter : '';
                if (filterParent)
                    obj.QueryConfig.Entity = this.SetFieldsToModel(obj.QueryConfig.Entity, filterParent);
                if (mappingFilter)
                    obj.QueryConfig.Entity = this.SetFieldsToModel(obj.QueryConfig.Entity, mappingFilter);
                if (generalFilter)
                    obj.QueryConfig.Filter += (obj.QueryConfig.Filter) ? ' AND ' + generalFilter : generalFilter;

            }
        }

        return obj;
    };


    public GetLanguaje() {
        let result = {
            "sEmptyTable": this.translate.instant("No Hay datos", { defaultValue: "No Hay datos" }),
            "sSearchPlaceholder": this.translate.instant('Buscar', { defaultValue: 'Buscar' }),
            "sInfo": this.translate.instant("Registros _START_ de _END_ de un total de _TOTAL_ registros", { defaultValue: "Registros _START_ de _END_ de un total de _TOTAL_ registros" }),
            "sInfoEmpty": this.translate.instant("Registros 0 de 0 de un total de 0 registros", { defaultValue: "Registros 0 de 0 de un total de 0 registros" }),
            "sInfoFiltered": this.translate.instant("(filtrado de un total de _MAX_ registros)", { defaultValue: "(filtrado de un total de _MAX_ registros)" }),
            "sInfoPostFix": "",
            "sInfoThousands": ",",
            "sLengthMenu": this.translate.instant("Mostrando _MENU_ registros", { defaultValue: "Mostrando _MENU_ registros" }),
            "sLoadingRecords": this.translate.instant("Cargando...", { defaultValue: "Cargando..." }),
            "sProcessing": "",

            "sSearch": "",
            "sZeroRecords": "",
            "oPaginate": {
                "sFirst": '<i class="fa fa-backward"></i>',
                "sLast": '<i class="fa fa-forward"></i>',
                "sNext": '<i class="fa fa-step-forward"></i>',
                "sPrevious": '<i class="fa fa-step-backward"></i>'
            },
            "oAria": {
                "sSortAscending": ": activate to sort column ascending",
                "sSortDescending": ": activate to sort column descending"
            },
            select: {
                rows: {
                    _: ''
                }
            }
        }
        return result;
    }

    public GetDtOptionsee(columns: any, serverSide: boolean = true) {
        let dtOptions = {
            pagingType: 'full_numbers',
            "lengthMenu": [5, 10, 25, 50, 100, 200, 300, 400, 500, 1000],
            serverSide: serverSide,
            processing: serverSide,
            responsive: false,
            "scrollX": serverSide,
            "order": [[0, "desc"]],
            language: this.GetLanguaje(),
            columns: columns,
            scrollY: 300,
            scroller: {
                loadingIndicator: true
            }
        };
        if (!columns)
            delete dtOptions.columns;
        return dtOptions;
    }
    public GetDtOptions(columns: any, serverSide: boolean = true, withScrolling?: boolean, scrollY?: string,
        exportButtons?: Array<any>, withSelection?: boolean, rowGroup?: any, responsive?: boolean) {
        let dtOptions: any;

        if (!scrollY)
            scrollY = "200";
        if (withScrolling == true) {
            dtOptions = {
                // pagingType: 'full_numbers',
                // "lengthMenu": [5, 10, 25, 50, 100, 200, 300, 400, 500, 1000],
                serverSide: serverSide,
                processing: true,
                responsive: false,
                // fixedHeader: {
                //     header: true,
                //     footer: true
                // },
                // fixedColumns:   {
                //     leftColumns: 1,
                //     rightColumns: 1
                // },
                "scrollX": serverSide,
                "order": [[0, "desc"]],
                language: this.GetLanguaje(),
                columns: columns,
                scrollY: scrollY,
                deferRender: true,
                //scrollCollapse: true,
                scroller: {
                    loadingIndicator: true
                },
                select: withSelection
            };
        }
        else {
            dtOptions = {
                pagingType: 'full_numbers',
                "lengthMenu": [5, 10, 25, 50, 100, 200, 300, 400, 500, 1000],
                serverSide: serverSide,
                processing: true,
                responsive: false,
                // fixedHeader: {
                //     header: true,
                //     footer: true
                // },
                // fixedColumns:   {
                //     leftColumns: 1,
                //     rightColumns: 1
                // },
                "scrollX": serverSide,
                "order": [[0, "desc"]],
                language: this.GetLanguaje(),
                columns: columns,
                select: withSelection
            };
        }
        if (exportButtons && exportButtons.length > 0) {
            //, 'pageLength'
            //  exportButtons.push('pageLength');
            dtOptions.dom = 'Bfrtip';
            dtOptions.buttons = exportButtons;
        }

        if (rowGroup) {
            dtOptions.rowGroup = {
                dataSrc: rowGroup.split(',')
            };
        }
        if (responsive) {
            dtOptions.responsive = responsive;
        }

        if (!columns)
            delete dtOptions.columns;
        return dtOptions;
    }

    public GetDtOptionsHtmlTable(pageLength?: any) {
        let dtOptions: any = {
            pagingType: 'full_numbers',

            "lengthMenu": [5, 10, 25, 50, 100, 200, 300, 400, 500, 1000],
            language: this.GetLanguaje()
        };
        if (pageLength)
            dtOptions.pageLength = pageLength;
        return dtOptions;
    }
    public GetDefaultDtOptions() {
        let dtOptions = {
            /*    pagingType: 'full_numbers',
               "lengthMenu": [5, 10, 25, 50, 100, 200, 300, 400, 500, 1000], */
            language: this.GetLanguaje(),
            data: [],
            columns: [{ name: 'id', title: '' }]
        };
        return dtOptions;
    }


    /*  public  GetDtOptions (columns:any,
      ajaxFunc:(restQueryUrl:string,dttName:string,obj:any,
          callbackOk:(items:any) => void, callbackError:(error:any) => (dataTablesParameters: any, callback) =>void)=>void ):any {
       let dttOptions=  {
          pagingType: 'full_numbers',
          "lengthMenu": [10, 25, 50, 100,200,300],
          serverSide: true,
          processing: true,
          ajax: ajaxFunc,
          "order": [[ 0, "desc" ]],
          language : this.GetLanguaje(),
          columns: columns,
          // Declare the use of the extension in the dom parameter
          // Configure the buttons
          buttons: [
            'columnsToggle',
            'colvis',
            'copy',
            'print',
            'excel',
            {
              text: 'Some button',
              key: '1',
              action: function (e, dt, node, config) {
                alert('Button activated');
              }
            }
          ]
        };

       return dttOptions;
       } */

    public SetGeneralData(obj: any) {

        obj.Token = StaticData.Usuario.Token;
        obj.AppKey = StaticData.AppKey;
        //if (obj.IdEmpresa == null || obj.IdEmpresa == -1)
        obj.IdEmpresa = StaticData.Usuario.IdEmpresa;
        if (obj.Filter) {
            if (obj.Filter.toString().includes('IdEmpresa=-1'))
                obj.Filter = obj.Filter.toString().replace('IdEmpresa=-1', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
            if (obj.Filter.toString().includes('IdEmpresa = -1'))
                obj.Filter = obj.Filter.toString().replace('IdEmpresa = -1', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
            if (obj.Filter.toString().includes('IdEmpresa=null'))
                obj.Filter = obj.Filter.toString().replace('IdEmpresa=null', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
            if (obj.Filter.toString().includes('IdEmpresa = null'))
                obj.Filter = obj.Filter.toString().replace('IdEmpresa = null', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
        }
        if (obj.SActionName) {
            if (!obj.Parameters)
                obj.Parameters = {};
            obj.Parameters['IdEmpresa'] = StaticData.Usuario.IdEmpresa;
        }

        //SActionName
        if (obj.QueryConfig) {
            if (obj.QueryConfig.Entity && (obj.QueryConfig.Entity.IdEmpresa === null || obj.QueryConfig.Entity.IdEmpresa == -1))
                obj.QueryConfig.Entity.IdEmpresa = StaticData.Usuario.IdEmpresa;
            if (obj.QueryConfig.Params && (obj.QueryConfig.Params.IdEmpresa === null || obj.QueryConfig.Params.IdEmpresa == -1))
                obj.QueryConfig.Params.IdEmpresa = StaticData.Usuario.IdEmpresa;
            if (obj.QueryConfig.Filter) {
                if (obj.QueryConfig.Filter.toString().includes('IdEmpresa=-1'))
                    obj.QueryConfig.Filter = obj.QueryConfig.Filter.toString().replace('IdEmpresa=-1', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
                if (obj.QueryConfig.Filter.toString().includes('IdEmpresa = -1'))
                    obj.QueryConfig.Filter = obj.QueryConfig.Filter.toString().replace('IdEmpresa = 1', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
                if (obj.QueryConfig.Filter.toString().includes('IdEmpresa=null'))
                    obj.QueryConfig.Filter = obj.QueryConfig.Filter.toString().replace('IdEmpresa=null', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
                if (obj.QueryConfig.Filter.toString().includes('IdEmpresa = null'))
                    obj.QueryConfig.Filter = obj.QueryConfig.Filter.toString().replace('IdEmpresa = null', 'IdEmpresa=' + StaticData.Usuario.IdEmpresa);
            }
        }
    }
    public ValidateUser(obj: any, isLog: boolean = false): boolean {
        const that = this;
        if (!StaticData.Usuario || (StaticData.Usuario && !StaticData.Usuario.Token)) {

            if (isLog) {
                return true;
            }
            else {
                this.logger.LogWarning('EL Usuario No Se Encuentra Autentica', obj);


                this.VerModalWarning({
                    titulo: this.translate.instant('Alerta'), descripcion: this.translate.instant('EL Usuario No Se Encuentra Autenticado'),
                    verOk: true, verCancelar: false, ok: this.translate.instant("ACEPTAR", { defaultValue: "ACEPTAR" }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
                }).then(data => { that.router.navigate(['home']); });
                /*
                             this.VerModalGeneral( function (data) {


                               that.router.navigate(['home']);
                            },ModalNotifyTypes.Warning, {titulo: 'Alerta', descripcion: 'No Autenticado',VerOk: true, VerCancelar: false, ok: this.translate.instant("ACEPTAR", { defaultValue: "ACEPTAR" }), cancelar: this.translate.instant("NO", { defaultValue: "NO" })
                            }); */
                return false;
            }
        }
        else {

            if (obj) {

                if (Array.isArray(obj)) {

                    $.each(obj, function (key, item) {

                        that.SetGeneralData(item);

                    });
                }
                else
                    that.SetGeneralData(obj);
            }
            return true;
        }

    };


    public GetQueryStringParameter(url: string, notSplit: boolean): any {
        var vars = [], hash;
        var hashes = url.slice(url.indexOf('?') + 1).split('&');
        if (notSplit == null || notSplit == undefined || notSplit == false) {
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
        }
        else {
            if (hashes.length == 1 && hashes[0].startsWith("http"))
                hashes = [];
            return hashes;
        }
        return vars;
    };


    public Clone(data: any): any {
        return (Array.isArray(data) ? jQuery.extend(true, [], data) : jQuery.extend(true, {}, data));
    };

    public SetClone(sourceModelo: any, modeloClone: any) {
        for (var prop in sourceModelo) {
            if (prop != 'modelo' &&
                prop != 'modeloCascada' &&
                prop != 'modeloMetadata' &&
                prop != 'modeloVirtual' &&
                prop != 'modeloVirtual') {
                modeloClone[prop] = this.Clone(sourceModelo[prop]);
            }
        }
    }
    public FormatDate(jsonDate, setFormat): any {
        var dateReturn = '';
        var re = /-?\d+/;
        var m = re.exec(jsonDate);
        var d = new Date(parseInt(m[0]));
        var month = (parseInt(d.getMonth().toString()) + 1).toString().length > 1 ? parseInt(d.getMonth().toString()) + 1 : "0" + (parseInt(d.getMonth().toString()) + 1).toString();
        var day = d.getDate().toString().length > 1 ? d.getDate() : "0" + d.getDate();

        switch (setFormat) {
            case 'dd/mm/yyyy':
                dateReturn = day + "/" + month + "/" + d.getFullYear();
                break;
            case 'yyyy-mm-dd':
                dateReturn = d.getFullYear() + "-" + month + "-" + day;
                break;
            case 'mm-dd-yyyy':
                dateReturn = day + "-" + month + "-" + d.getFullYear();
                break;
        }

        return dateReturn;

    };

    public SetFormatDate(modelo: any, setFormat: string) {
        if (!setFormat)
            setFormat = 'dd/mm/yyyy';
        if (modelo.FechaCreacion && modelo.FechaCreacion != '')
            modelo.FechaCreacion = this.FormatDate(modelo.FechaCreacion, setFormat);
        if (modelo.FechaActualizacion && modelo.FechaActualizacion != '')
            modelo.FechaActualizacion = this.FormatDate(modelo.FechaActualizacion, setFormat);
    };

    public FindItem(items: any, key: string, compareValue: string, toUpperCase: boolean = false) {

        var result = null;

        $.each(items, function (index, item) {

            if (toUpperCase == true) {
                if (item[key].toUpperCase() == compareValue.toUpperCase()) {
                    result = item;
                    return;
                }
            }
            else {
                if (item[key] == compareValue) {
                    result = item;
                    return;
                }
            }
        });
        return result;
    };


    public SetFieldsValue(model: any, result: any, all?: any) {

        if (typeof model === 'string' || model instanceof String) {
            var _model = {};
            if (all) {
                for (var name in result.split(',')) {
                    if (name != 'VersionModelData')
                        _model[name] = result[name];
                }
            }
            else {
                for (var name in model.split(',')) {
                    if (name != 'VersionModelData')
                        _model[name] = result[name];
                }
            }
            return _model;
        }
        else {
            if (all) {
                for (var name in result) {
                    if (name != 'VersionModelData')
                        model[name] = result[name];
                }
            }
            else {
                for (var name in model) {
                    if (name != 'VersionModelData')
                        model[name] = result[name] != 'undefined' ? result[name] : model[name];
                }
            }

            return model;
        }
    };
    public ValidateModels(model: any, clone: any, properties: Array<any>, modelContainer?: string, controls?: List<any>): Array<any> {

        let validaciones: Array<any> = [];

        for (let index in properties) {
            const property = properties[index];
            if (model[property] !== clone[property]) {
                let Etiqueta = property;
                if (controls) {
                    const ctr = controls.FirstOrDefault(x => { return x.control.Name == property && x.control.ModelContainer == modelContainer });
                    if (ctr)
                        Etiqueta = ctr.control.Label;
                }
                validaciones.push({ Nombre: property, ValorNuevoValor: model[property], ValorAnterior: clone[property], Etiqueta: Etiqueta });
            }
        }
        return validaciones;

    };
    public GetMetadataItemModel(control: any): any {
        let value = this.ConvertValue(control.Value, control.DataType);
        return { Id: 0, Name: control.Name, Label: control.Label, Value: value, DataType: control.DataType };
    }
    public GetModelWithContainer(idPage: any) {
        //modelo: { PkValue: 0,IdEmpresa:null, Token:null,QueryConfig:null,MethodNameUI:null,Filter:null },
        let modelo: any = {
            modelo: { PkValue: 0, IdEmpresa: null, AppKey: null, Token: null, QueryConfig: null, MethodNameUI: null, Filter: null },
            modeloCascada: {},
            modeloMetadata: { Id: 0, IdPage: idPage, IdParentObject: null, Name: null, Items: new List([]) },
            modeloVirtual: {},
            WorkFlowTaskModel: {},
            WorkFlowTaskDetailsModel: {}
        };
        return modelo;
    }
    public SetModelWithContainer(modelo: any, idPage: any, excludeProps?: Array<any>) {
        //modelo: { PkValue: 0,IdEmpresa:null, Token:null,QueryConfig:null,MethodNameUI:null,Filter:null },


        modelo.modelo.QueryConfig = null;
        modelo.modelo.MethodNameUI = null;
        modelo.modelo.Filter = null;
        modelo.modeloCascada = {};
        if (modelo.modeloMetadata) {
            modelo.modeloMetadata.Id = 0;
            modelo.modeloMetadata.IdPage = 0;
            modelo.modeloMetadata.IdParentObject = null;
            modelo.modeloMetadata.Name = null;
            modelo.modeloMetadata.Items = new List([]);
        }
        else
            modelo.modeloMetadata = { Id: 0, IdPage: idPage, IdParentObject: null, Name: null, Items: new List([]) };
        modelo.modeloVirtual = {};
        modelo.WorkFlowTaskModel = {};
        modelo.WorkFlowTaskDetailsModel = {};
        if (excludeProps && excludeProps.length > 0) {
            for (var prop in modelo.modelo) {
                if (!excludeProps.find(x => { return x == prop }))
                    modelo.modelo[prop] = null;
            }
        } else {
            for (var prop in modelo.modelo) {
                modelo.modelo[prop] = null;
            }
        }
        modelo.modelo.PkValue = 0;
        for (var prop in modelo) {
            if (prop != 'modelo' &&
                prop != 'modeloCascada' &&
                prop != 'modeloMetadata' &&
                prop != 'modeloVirtual' &&
                prop != 'WorkFlowTaskModel' &&
                prop != 'WorkFlowTaskDetailsModel') {

                if (Array.isArray(modelo[prop]) == true) {
                    modelo[prop] = [];
                }
                else {
                    for (var _prop in modelo[prop]) {
                        modelo[prop][_prop] = null;
                    }
                }
            }
            //this.PaginaSeleccionada.ConfigPage[prop] = importObject[prop];
        }
        return modelo;
    }
    public GetActionMappingModel() {
        return { Prop: '', Value: null, DataType: 'String' };
    }
    public GetMappingModel() {
        return { From: null, To: null, DataType: 'String' };
    }
    public GetColumnActionModel() {
        return {
            Guid: Guid.create().toString(),
            Type: "EditInModal",
            ComponentName: '',
            Url: '',
            PropToSet: '',
            SendQueryString: false,
            IsPost: false,
            ToBase64: false,
            ControlsForHidden: [],
            MappingValues: [],
            ControlDetails: { Name: '', ControlType: null, Control: null, MappingFilter: [], MappingData: [] },
            MessageType: "success",
            TitleMessage: '',
            Message: '',
            IndexActionOk: '',
            IndexActionNot: '',
            TimerAction: 0,
            SubAction: null,
            PropStaticModel: null,
            ResultType: 'Url',
            SystemObjectType: null,
            ObjectSystem: null,
            SubObjectSystem: null,
        };
    }
    public GetColumnModel() {
        return {
            Guid: Guid.create().toString(),
            Title: '',
            Name: '',
            DataField: '',
            CustomName: '',
            ColumnType: 0,
            Icon: '',
            Icon2: '',
            ToolTip: '',
            ControlType: 'Column',
            SearchField: '',
            IconField: '',
            DataIconField: '',
            Actions: [],
            TimerActions: 0,
            TimerToAllActions: 0,
            ChildOptions: []
        };
    }
    public GetColumnModel2() {
        return {
            Title: '',
            Name: '',
            DataField: '',
            "CustomName": "",
            "ColumnType": 0,
            "Icon": "",
            "ToolTip": "",
            "ControlType": "Column",
            "SearchField": "",
            "IconField": "",
            "DataIconField": "",
            "Actions": [],
            "ChildOptions": []
        };
    }
    public GetChildControl(controlType: string): any {
        let child;
        if (controlType == 'GridView') {
            child = this.GetColumnModel();

        }
        else {
            child = this.GetTabPanelAcoordionPanel(controlType == 'Accordion');
        }
        return child;
    }
    public GetTabPanelAcoordionPanel(isPanel: boolean): any {
        let controlPagina: ControlPagina = new ControlPagina();
        controlPagina.ControlType = (isPanel) ? 'AccordionPanel' : 'TabPanel';
        controlPagina.Type = controlPagina.ControlType;
        controlPagina.Label = controlPagina.ControlType;
        return controlPagina;
    }
    public GetCharts(): Array<any> {

        let list: Array<any> = (StaticData.ToolBox) ? StaticData.ToolBox.Charts : [];
        return list;
    }

    public GetBasicFields(): Array<any> {

        let list: Array<any> = (StaticData.ToolBox) ? StaticData.ToolBox.Controls : [];
        let temp = {
            "Id": 0, "Controls": [
                {
                    "Type": "text",
                    "Icon": "fa-font",
                    "Label": "Text",
                    "ControlType": "TextBox"
                },
                {
                    "Type": "email",
                    "Icon": "fa-envelope",
                    "Label": "Email",
                    "ControlType": "EmailBox"
                },
                {

                    "Type": "phone",
                    "Icon": "fa-phone",
                    "Label": "Phone",
                    "ControlType": "PhoneBox"
                },

                {

                    "Type": "number",
                    "Icon": "fas fa-th-list",
                    "Label": "Number",
                    "ControlType": "NumberBox"
                },
                {
                    "Type": "date",
                    "Icon": "fa-calendar",
                    "Label": "Date",
                    "ControlType": "DateBox"

                },
                {
                    "Type": "datetime-local",
                    "Icon": "fa-calendar",
                    "Label": "DateTime",
                    "ControlType": "DateTimeBox"
                },
                {
                    "Type": "textarea",
                    "Icon": "fa-text-width",
                    "Label": "TextArea",
                    "ControlType": "TextArea"
                },
                {
                    "Type": "select",
                    "Label": "ComboBox",
                    "Icon": "fa-list",
                    "ControlType": "ComboBox"
                },
                // {
                //     "Type": "select",
                //     "Label": "MultiComboBox",
                //     "Icon": "fas fa-th-list",
                //     "ControlType": "MultiComboBox"
                // },


                {
                    "Type": "checkbox",
                    "Label": "Checkbox",
                    "Icon": "far fa-check-square",
                    "ControlType": "CheckBox"
                },
                // {
                //     "Type": "radio",
                //     "Icon": "fa-list-ul",
                //     "Label": "Radio",
                //     "ControlType": "RadioBox"
                // },
                // {
                //     "Type": "file",
                //     "Icon": "fa-file",
                //     "Label": "File Upload",
                //     "ControlType": "FileBox"
                // },
                {
                    "Type": "Label",
                    "Icon": "fas fa-tags",
                    "Label": "Label",
                    "ControlType": "Label"
                },
                {
                    "Type": "Paragraph",
                    "Icon": "fa-paragraph",
                    "Label": "Paragraph",
                    "ControlType": "Paragraph"
                },
                {
                    "Type": "H1",
                    "Icon": "fas fa-heading",
                    "Label": "H1",
                    "ControlType": "H1"
                },
                {
                    "Type": "H2",
                    "Icon": "fas fa-heading",
                    "Label": "H2",
                    "ControlType": "H2"
                },
                {
                    "Type": "H3",
                    "Icon": "fas fa-heading",
                    "Label": "H3",
                    "ControlType": "H3"
                },
                {
                    "Type": "H4",
                    "Icon": "fas fa-heading",
                    "Label": "H4",
                    "ControlType": "H4"
                },
                {
                    "Type": "H5",
                    "Icon": "fas fa-heading",
                    "Label": "H5",
                    "ControlType": "H5"
                },

                {
                    "Type": "Acoordion",
                    "Icon": "fas fa-chevron-up",
                    "Label": "Acoordion",
                    "ControlType": "Acoordion"
                },
                {
                    "Type": "Tabs",
                    "Icon": "fas fa-outdent",
                    "Label": "Tabs",
                    "ControlType": "Tabs"
                },
                {
                    "Type": "ContainerBox",
                    "Icon": "fas fa-border-style",
                    "Label": "ContainerBox",
                    "ControlType": "ContainerBox"
                },
                // {
                //     "Type": "DataTable",
                //     "Icon": "fas fa-border-style",
                //     "Label": "GridView",
                //     "ControlType": "GridView"
                // },
                // {
                //     "Type": "CrudButtons",
                //     "Icon": "fas fa-circle",
                //     "Label": "CrudButtons",
                //     "ControlType": "CrudButtons"
                // },
                {
                    "Type": "Div",
                    "Icon": "fas fa-heading",
                    "Label": "Div",
                    "ControlType": "Div"
                },
                {
                    "Type": "I",
                    "Icon": "",
                    "Label": "I",
                    "ControlType": "I"
                }

            ], "UserControls": []
        }
        return list;
    }
    public GetBasicControlModel(controlType, type) {

        let controlPagina = new ControlPaginaBaseBasico();
        controlPagina.Name = controlPagina.Id;
        controlPagina.ControlType = controlType;
        controlPagina.Type = type;
        controlPagina.Label = controlType;
        controlPagina.ConfigControl = this.GetConfigControlModel();
        controlPagina['ProcessMessage'] = '';
        controlPagina['PkName'] = '';
        return controlPagina;
    }
    public GetModeloAdminPermisos() {
        let modelo = {
            "Label": this.translate.instant("Permisos para Heredar"),
            "ConfigInternalProp": "",
            "ConfigProp": "Configuracion",
            "UsersTitle": "",
            "RolesTitle": "",
            "AreasTitle": "",
            "GroupsTitle": "",
            "UsersProp": "UsuariosPermitidos",
            "RolesProp": "RolesPermitidos",
            "AreasProp": "AreasEmpresaPermitidas",
            "GroupsProp": "GruposPermitidos",
            "WithPermisionsField": true,
            "NotIsPage": true,
            "IsDoc": false,
            "MaxWith": 900

        }
    }
    public GetModeloAdminPermitidos() {
        let modelo = {
            "Label": this.translate.instant("Permisos para Heredar"),
            "UsersTitle": "",
            "RolesTitle": "",
            "AreasTitle": "",
            "GroupsTitle": "",
            "UsersProp": "UsuariosPermitidos",
            "RolesProp": "RolesPermitidos",
            "AreasProp": "AreasEmpresaPermitidas",
            "GroupsProp": "GruposPermitidos",
            "MaxWith": 900

        }
    }
    public GetModeloPermisosTiposDocMetadatos() {
        return {
            "AdminPermisions": {
                "Label": this.translate.instant("Permisos para Heredar", { defaultValue: "Permisos para Heredar" }),
                "ConfigInternalProp": "",
                "ConfigProp": "Configuracion",
                "UsersTitle": "",
                "RolesTitle": "",
                "AreasTitle": "",
                "GroupsTitle": "",
                "UsersProp": "UsuariosPermitidos",
                "RolesProp": "RolesPermitidos",
                "AreasProp": "AreasEmpresaPermitidas",
                "GroupsProp": "GruposPermitidos",
                "WithPermisionsField": true,
                "NotIsPage": true,
                "IsDoc": false,
                "MaxWith": 900

            },
            "DocTypes": {
                "Label": this.translate.instant("Tipos Documentales", { defaultValue: "Tipos Documentales" }),

                "ProcessMessage": "",
                "PkField": "Id",
                "TextField": "Nombre",
                "ConfigInternalProp": "",
                "ConfigProp": "Configuracion",
                "TitleSelectionItems": "",
                "TitleSelectedItems": "",
                "MappingProps": [],
                "ConfigControl": {
                    "Controller": "",
                    "QueryConfigSelection": {
                        "Url": "",
                        "ProcessMessage": "",
                        "Command": "",
                        "IsSp": true, "Entity": {}
                    },
                    "QueryConfigSelected": {
                        "Url": "",
                        "ProcessMessage": "",
                        "Command": "",
                        "IsSp": true, "Entity": {}
                    }
                }
            },
            "AdminMetadata": {
                "Label": this.translate.instant("Metadatos para Heredar", { defaultValue: "Metadatos para Heredar" }),
                "ProcessMessage": "",
                "PkField": "Id",
                "TextField": "Nombre",
                "ConfigInternalProp": "",
                "ConfigProp": "Configuracion",
                "TitleSelectionItems": "",
                "TitleSelectedItems": "",
                "ConfigControl": {
                    "Controller": "",
                    "QueryConfig": {
                        "Url": "",
                        "ProcessMessage": "",
                        "Command": "",
                        "IsSp": true, "Entity": {}
                    },
                    "QueryConfigSelection": {
                        "Url": "",
                        "ProcessMessage": "",
                        "Command": "",
                        "IsSp": true, "Entity": {}
                    }
                }

            },
            "AdminCarpetasH": {
                "Label": this.translate.instant("Carpetas", { defaultValue: "Carpetas" }),

                "ProcessMessage": "",
                "PkField": "Id",
                "TextField": "Nombre",
                "ConfigInternalProp": "",
                "ConfigProp": "Configuracion",
                "TitleSelectionItems": "",
                "TitleSelectedItems": "",
                "MappingProps": [],
                "ConfigControl": {
                    "Controller": "",
                    "QueryConfigSelection": {
                        "Url": "",
                        "ProcessMessage": "",
                        "Command": "",
                        "IsSp": true, "Entity": {}
                    },
                    "QueryConfigSelected": {
                        "Url": "",
                        "ProcessMessage": "",
                        "Command": "",
                        "IsSp": true, "Entity": {}
                    }
                }
            }
        };
    }
    public GeModelControl(controlType: string, type: string = '', model: any = null): any {

        // if(esControlPagina)
        if (type === 'component') {
            let pagina = this.FindPageForComponentIDE(controlType, type);

            return pagina;

        }
        let ctr;
        if (type == 'UserControl' || type == 'CustomComponent' || type == 'ConfigurationControl') {
            ctr = this.Clone(model);
            ctr.Id = Guid.create().toString();
            ctr.Name = ctr.Id;
            // switch (controlType) {
            //     case 'PermisosTiposDocMetadatos':
            //         ctr.ConfigControl = this.GetModeloPermisosTiposDocMetadatos();
            //         break;
            //     case 'AdminPermisos':
            //         break;
            //     case 'AdminPermitidos':
            //         break;
            // }
            if (controlType === 'PermisosTiposDocMetadatos') {
                // ctr.ConfigControl = this.GetModeloPermisosTiposDocMetadatos();
            }
            return ctr;
        }
        else if (type == 'DynamicComponentViewer') {
            ctr = this.Clone(model);
            ctr.Id = Guid.create().toString();
            return ctr;
        }

        let controlPagina: ControlPagina = new ControlPagina();
        controlPagina.Name = controlPagina.Id;
        controlPagina.ControlType = controlType;
        controlPagina.Type = type;
        controlPagina.Label = controlType;
        switch (controlType) {
            case 'Pagina':
                controlPagina.IdPagina = model.IdPagina;
                controlPagina.Name = model.Name;
                controlPagina.ColSpan = model.ColSpan;
                controlPagina.Label = model.Label;
                controlPagina.DataType = 'Object';
                controlPagina.GeneralDataType = 'Object';

                break
            case 'TextBox':
            case 'TextArea':
            case 'EmailBox':
                controlPagina.DataType = 'String';
                controlPagina.GeneralDataType = 'String';
                controlPagina.Type = (controlType == 'EmailBox') ? 'email' : 'text';
                if (controlType === 'TextArea')
                    controlPagina.Rows = 5;

                break;
            case 'FileBox':

                controlPagina["SendCurrentModel"] = false;
                controlPagina["multiple"] = true,
                    controlPagina["formatsAllowed"] = "*",
                    controlPagina["maxSize"] = '10',
                    controlPagina["uploadAPI"] = {
                        "url": StaticData.UrlServer + "/api/General/UploadFile",
                        "headers": {
                            "modelo": ""
                        }
                    },
                    controlPagina["invalidFormatMsg"] = "Extension No Permitida",
                    controlPagina["invalidSizeMsg"] = "Tamaño Limite Invalido",
                    controlPagina["theme"] = "dragNDrop",
                    controlPagina["hideProgressBar"] = false,
                    controlPagina["hideResetBtn"] = false,
                    controlPagina["IsPrincipal"] = false,
                    controlPagina["IsExtendableFileTable"] = false,
                    controlPagina["IsForWorkFlow"] = false,
                    controlPagina["WithScanner"] = false,
                    controlPagina["ScannerPort"] = "8181",
                    //IsExtendableTable
                    //hideSelectBtn: false,
                    controlPagina["replaceTexts"] = {
                        "selectFileBtn": this.translate.instant("Seleccione el documento", { defaultValue: "Seleccione el documento" }),
                        "resetBtn": this.translate.instant("Limpiar", { defaultValue: "Limpiar" }),
                        "uploadBtn": this.translate.instant("Cargar Documento", { defaultValue: "Cargar Documento" }),
                        "dragNDropBox": this.translate.instant("Drag & Drop", { defaultValue: "Drag & Drop" }),
                        "attachPinBtn": this.translate.instant("Adjunte Documento...", { defaultValue: "Adjunte Documento..." }),
                        "afterUploadMsg_success": this.translate.instant("Carga Correcta !", { defaultValue: "Carga Correcta !" }),
                        "afterUploadMsg_error": this.translate.instant("Error al cargar !", { defaultValue: "Error al cargar !" })
                    }

                break;
            case 'DateBox':
            case 'DateTimeBox':
                controlPagina.DataType = 'DateTime';
                controlPagina.GeneralDataType = 'DateTime';
                controlPagina.Type = (controlType == 'DateBox') ? 'date' : 'datetime-local';
                break;
            case 'NumberBox':
                controlPagina.DataType = 'Int32';
                controlPagina.GeneralDataType = 'Number';
                controlPagina.Type = 'number';

                break;
            case 'ComboBox':
            case 'MultiComboBox':
                controlPagina.DataType = 'Int32';
                controlPagina.GeneralDataType = 'Number';
                controlPagina.Type = 'Select';
                controlPagina.ConfigControl = this.GetConfigControlModel();
                controlPagina['ProcessMessage'] = '';


                break;
            case 'FormDetails':
            case 'FormJson':
                controlPagina = this.GetBasicControlModel(controlType, type);
                break;
            case 'GridView':
                controlPagina = this.GetBasicControlModel(controlType, type);
                controlPagina["FilterControls"] = [];
                controlPagina["WithFilterControls"] = false;
                controlPagina["WithQueryBuilder"] = false;
                controlPagina["IsExtendableTable"] = false;
                controlPagina["WithMosaics"] = false;
                controlPagina["FieldMosaicLabel"] = null;
                controlPagina["ReadColumnFormData"] = false;
                break;
            case 'ContainerBox':
            case 'ContainerBoxIcon':
            case 'ContainerBoxHeader':
            case 'DragContainerBox':
            case 'Accordion':
            case 'Tabs':
            case 'AccordionPanel':
            case 'TabPanel':
            case 'DragPanel':
            case 'Div':
            case 'FloatPanel':
                controlPagina = new ControlPaginaBaseBasico();
                controlPagina.Name = controlType + "_" + controlPagina.Id;
                controlPagina.ControlType = controlType;
                controlPagina.Type = controlType;
                controlPagina.Label = controlType;
                break;
            case 'DynamicHtml':
                controlPagina = new ControlPaginaBaseBasico();
                controlPagina.Name = controlType + "_" + controlPagina.Id;
                controlPagina.ControlType = controlType;
                controlPagina.Type = controlType;
                controlPagina.Label = controlType;
                controlPagina["Modelo"] = {};
                controlPagina["Template"] = '';
                break;
            //DynamicHtml
            case 'Button':
                controlPagina = new ControlPaginaBaseBasico();
                controlPagina.Name = controlType + "_" + controlPagina.Id;
                controlPagina.ControlType = controlType;
                controlPagina.Type = controlType;
                controlPagina.Label = controlType;
                controlPagina["TitleOptions"] = null;
                controlPagina["IconOptions"] = null;
                controlPagina["LabelOptions"] = null;

                break;
            case 'CheckBox':
                controlPagina.DataType = 'Boolean';
                controlPagina.GeneralDataType = 'Boolean';
                controlPagina.Type = 'checkbox';
                break;
            default:

                if (type == 'Container') {
                    ctr = new ControlContainerBasico();
                    ctr.Name = controlType + "_" + ctr.Id;
                    ctr.ControlType = controlType;
                    ctr.Type = type;
                    return ctr;
                }

                var tipo = new List<any>(this.GetBasicFields()).Where(x => { return x.ControlType == controlType }).FirstOrDefault();
                if (!tipo) {
                    let pagina = this.FindPageForComponentIDE(controlType, type);

                    return pagina;
                }
                break;
        }


        return controlPagina;
    }

    public GetQueryConfig() {
        return {
            "Command": "",
            "IsSp": false,
            "Entity": {},
            "WithPagination": false,
            "OutPutParam": false,
        };
    }
    public GetConfigControlModel(): any {
        let ConfigControl = {
            "Controller": "",
            "Params": {},
            "QueryConfig": this.GetQueryConfig(),
            "NotLoad": false
        };
        return ConfigControl;
    }
    public GetModelForFilterparent(parentOption: any, _findItem?: any, index?: number, items?: Array<any>): any {


        let parentFilterModel: any;
        if (!parentOption) {
            this.logger.LogInfoText('parentOption es nulo en GetModelForFilterparent');
            return parentFilterModel;
        }
        let parentOptionClone = this.Clone(parentOption);
        //throw 'parentOption no puede ser nulo en';


        if (parentOptionClone.Option) {

            var findItem = (_findItem) ? _findItem : items[index];
            var filterParent = this.GetFilterParent(parentOptionClone.filterParent, findItem);
            var mappingFilter = this.GetFilterParent(parentOptionClone.mappingFilter, findItem);
            parentOptionClone.Option.ConfigControlsToFilter = this.GetControlFilterParent(parentOptionClone.Option.ControlsToFilter, findItem);

            parentFilterModel = {
                isModal: true,
                parentOption: parentOptionClone.Option,
                filterParent: filterParent,
                mappingFilter: mappingFilter,
                generalFilter: parentOptionClone.filter,
                hideForm: parentOptionClone.Option.HideForm,
                hideDataTable: parentOptionClone.Option.HideDataTable,
                currentItemParent: findItem
            };
        }
        else {
            var findItem = (_findItem) ? _findItem : items[index];
            var filterParent = this.GetFilterParent(parentOptionClone.FiltersParent, findItem);
            var mappingFilter = this.GetFilterParent(parentOptionClone.MappingFilters, findItem);
            parentOptionClone.ConfigControlsToFilter = this.GetControlFilterParent(parentOptionClone.ControlsToFilter, findItem);
            parentFilterModel = {
                isModal: false,
                parentOption: parentOptionClone,
                filterParent: filterParent,
                mappingFilter: mappingFilter,
                generalFilter: parentOptionClone.filter,
                hideForm: parentOptionClone.HideForm,
                hideDataTable: parentOptionClone.HideDataTable,
                currentItemParent: findItem
            };
        }

        return parentFilterModel;
    }

    public RefreshValorDataTable(dtElements: any, index: any, id: any, controlName: string, valor: any, campo: string, componentName: string) {

        try {

            if (dtElements instanceof QueryList) {


                dtElements.forEach((dtElement: DataTableDirective, index: number) => {

                    dtElement.dtInstance.then((dtInstance: any) => {
                        if (!controlName || dtInstance.table().node().id == controlName) {
                            dtInstance.data()[index][campo] = valor;
                        }

                    });
                });
            }
            else {
                dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.data()[index][campo] = valor;

                });
            }

        }
        catch (error) {
            this.logger.LogError(error, null, { componentName: componentName, method: 'EditarDataTable' });
            this.VerModalDanger().then(data => { });
        }
    }
    public ReCargarGrilla(dtElements: any, controlName: string, componentName: string) {
        try {
            if (dtElements instanceof QueryList) {

                dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                    dtElement.dtInstance.then((dtInstance: any) => {
                        if (!controlName || dtInstance.table().node().id == controlName)
                        {
                            dtInstance.search("").draw();
                            dtInstance.ajax.reload();                            
                        }                            
                    });
                });
            }
            else {
                dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.ajax.reload();
                });
            }
        }
        catch (error) {
            this.logger.LogError(error, null, { componentName: componentName, method: 'AdicionarCoReCargarGrillalumasEditarEliminar', controlName: controlName });
            this.VerModalDanger().then(data => { });
        }
    }

    public GetDatosBotonDataTables(event: any): any {
        var element = $(event.currentTarget);
        var id = $(element).data("id");
        var data = $(element).data("dat");
        var index = $(element).data("index");
        var col = $(element).data("col");
        return { id: id, data: data, index: index, col: col };
    }

    public GetNoPermitidoIcono() {
        return '../assets/images/jstree/no-permitido.png';
    }
    public GetNoPermitidoGrilla(toolTip) {
        return '<a title="No Posee Permisos para ' + toolTip + '" ><img style="height:32px;with:32px;" src="../assets/images/jstree/no-permitido.png"     aria-hidden="true"></a>'
    }
    public GetChildOptionsColumn(childOptions: Array<any>, componentName: string, pkName: string,
        contextConfigPermisos?: any, title?: string, _name?: string, customName?: string) {
        const that = this;
        let optionTemplate = `<a class="dropdown-item" >#label#</a>`;

        let template = `<div class="btn-group">
        <button title="Opciones" class="btn btn-primary btn-sm dropdown-toggle" type="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      </button>
        <div class="dropdown-menu">
           #options#
        </div>
      </div>`;
        customName = (customName) ? customName + componentName : 'childOptions' + componentName;
        let templateResult: string = '';
        let column: any = {
            config: { childOptions: childOptions, name: customName },
            columnType: DataTableColumnTypes.Options,
            isCustom: true,
            name: _name,
            customName: customName,
            title: (title) ? title : 'Modales',
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {
                let optionsHtml: string = '';

                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                $.each(childOptions, function (index: number, option: any) {
                    let ok: boolean = that.TienePemisoUsuario(configPermisos, row, option.opcionPermiso);

                    if (option.campoVersiones) {
                        ok = (row[option.campoVersiones] > 1);
                    }

                    if (ok) {
                        if (option.isDivider)
                            optionsHtml += '<div class="dropdown-divider"></div><br/>';
                        else
                            optionsHtml += '<a data-dat="' + index + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '" name="' + customName + '" class="dropdown-item" >' + option.label + '</a><br/>'
                    }
                });
                templateResult = template.replace(' #options#', optionsHtml);
                return templateResult;
            }
        }

        return column;
    }
    public GetBooleanColumn(componentName: string, title: string, toolTip: string,
        name: string, pkName: string, propName: string,
        validationPropName: string, isThreeState: boolean = false, contextConfigPermisos?: any, _name?: string): any {
        const that = this;
        let column: any = {
            config: { title: title, name: name, pkName: pkName, propName: propName, validationPropName: validationPropName, isThreeState: isThreeState },
            title: title,
            columnType: DataTableColumnTypes.Boolean,
            isCustom: true,
            name: _name,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {
                let ok: boolean = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (validationPropName) {
                    ok = (row[validationPropName] == true || row[validationPropName] === null || row[validationPropName] === '');
                }
                if (ok) {
                    ok = that.TienePemisoUsuario(configPermisos, row, 'Guardar');
                }
                if (ok) {

                    if (isThreeState) {
                        return '<a title="' + toolTip + '" style="cursor:pointer;"><i   data-jtmulti-values="false,null,true" class="jtoggler" data-jtmulti-dat="' + row[propName] + '" data-jtmulti-id="' + row[pkName] + '" data-jtmulti-index="' + meta.row + '"   data-jtmulti-col="' + meta.col + '"  data-jtmulti-state  data-jtmulti-prop="' + propName + '"   data-jtmulti-name="' + name + componentName + '" aria-hidden="true"></i></a>';
                    }
                    else {
                        if (row[propName])
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[propName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + name + componentName + '" class="fas fa-check-square"  aria-hidden="true"></i></a>';
                        else
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row[propName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + name + componentName + '"  class="far fa-square"  aria-hidden="true"></i></a>';
                    }
                }
                else
                    return '<a title="Esta funcionalidad no aplica...." style="cursor:pointer;"><i  class="fa fa-exclamation-triangle"  aria-hidden="true"></i></a>';
            }
        }
        if (!title)
            delete column.title;
        return column;
    }
    public GetColumnaEditar(pkName: string, nombreBotonEditar: string,
        icono?: string, title?: string, toolTip?: string, contextConfigPermisos?: any, _name?: string): any {
        const that = this;
        icono = (icono) ? icono : 'editar.png';
        toolTip = (!toolTip) ? 'Editar' : toolTip;
        let column: any = {
            columnType: DataTableColumnTypes.Edit,
            isCustom: true,
            title: (!title) ? 'Editar' : title,
            name: _name,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {

                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = that.TienePemisoUsuario(configPermisos, row, 'Editar');
                if (ok) {
                    if (icono)
                        return '<a title="' + toolTip + '" style="cursor:pointer;"><img  src="' + that.pathImages + icono + '" name="' + nombreBotonEditar + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>'
                    else
                        return '<a title="' + toolTip + '" style="cursor:pointer;"><i name="' + nombreBotonEditar + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  class="fas fa-pen"   aria-hidden="true"></i></a>'
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);

            }
        };
        return column;
    }
    //<img src="../../../images/dominica-flag-icon.gif" alt="">

    public GetColumnaIcono(pkName: string,
        icono: string,
        nombreBoton: string,
        fieldData?: string,
        fieldSearch?: string,
        fieldIcon?: string,
        title?: string,
        toolTip?: string,
        lst?: List<any>,
        fieldToolTip?: string,
        contextConfigPermisos?: any,
        _name?: string): any {
        const that = this;
        return {
            columnType: DataTableColumnTypes.Icon,
            isCustom: true,
            title: title,
            name: _name,
            customName: nombreBoton,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {
                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = true;//that.TienePemisoUsuario(configPermisos, row, 'Guardar');
                if (ok) {

                    if (fieldData && lst) {
                        let _icono = lst.Where(x => { return x[fieldSearch] == row[fieldData] }).FirstOrDefault();
                        if (_icono) {
                            icono = _icono[fieldIcon];
                        }
                        else
                            icono = null;
                    }
                    if (fieldToolTip) {
                        toolTip = row[fieldToolTip];
                        if (icono) {
                            if (toolTip)
                                return '<a title="' + toolTip + '" style="cursor:pointer;"><img   src="' + that.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;"   aria-hidden="true"></a>';
                            else
                                return '';
                        }
                        else {
                            if (toolTip)
                                return '<a title="' + toolTip + '"" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"  class="fas fa-exclamation-triangle"  aria-hidden="true"></i></a>';
                            else
                                return '';
                        }
                    }
                    else {
                        if (icono)
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><img   src="' + that.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  name="' + nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;"   aria-hidden="true"></a>';
                        else
                            return '<a title="' + toolTip + '"" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBoton + '"  class="fas fa-exclamation-triangle"  aria-hidden="true"></i></a>';
                    }

                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }

        };
    }
    public GetColumnaSeleccionItem(pkName: string, nombreBoton: string,
        icono?: string, title?: string, toolTip?: string, contextConfigPermisos?: any, _name?: string): any {
        const that = this;
        toolTip = (!toolTip) ? 'Seleccionar' : toolTip;
        //icono = (!icono) ? 'seleccionar' : icono;
        let column: any = {
            columnType: DataTableColumnTypes.Selection,
            isCustom: true,
            name: _name,
            title: (!title) ? 'Seleccionar' : title,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {
                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = that.TienePemisoUsuario(configPermisos, row, 'Editar');
                if (ok) {
                    if (icono)
                        return '<a title="' + toolTip + '" style="cursor:pointer"><img  src="' + that.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;"   aria-hidden="true"></a>';
                    else
                        return '<a title="' + toolTip + '" style="cursor:pointer"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBoton + '"  class="far fa-hand-pointer"  aria-hidden="true"></i></a>';
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }
        };
        return column;
    }
    public GetColumnaEliminar(pkName: string, nombreBotonEliminar: string,
        icono?: string, title?: string, toolTip?: string, contextConfigPermisos?: any, _name?: string): any {
        const that = this;
        toolTip = (!toolTip) ? this.translate.instant('Eliminar') : this.translate.instant(toolTip);
        icono = (!icono) ? 'eliminar.png' : icono;
        let column: any = {
            columnType: DataTableColumnTypes.Delete,
            isCustom: true,
            name: _name,
            title: (!title) ? this.translate.instant('Eliminar') : this.translate.instant(title),
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {
                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = that.TienePemisoUsuario(configPermisos, row, 'Eliminar');
                if (ok) {
                    if (icono)
                        return '<a title="' + this.translate.instant(toolTip) + '" style="cursor:pointer;"><img src="' + that.pathImages + icono + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + this.translate.instant(nombreBotonEliminar) + '"  style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>';
                    else
                        return '<a title="' + this.translate.instant(toolTip) + '" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + this.translate.instant(nombreBotonEliminar) + '"  class="fas fa-trash"  aria-hidden="true"></i></a>';
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }
        };
        return column;
    }
    public GetColumnaActivar(pkName: string, nombreBotonEliminar: string,
        iconoActivo?: string,
        iconoInActivo?: string,
        title?: string,
        toolTip?: string, contextConfigPermisos?: any, _name?: string): any {
        const that = this;
        toolTip = (!toolTip) ? this.translate.instant('Activar / Inactivar') : toolTip;
        iconoActivo = (iconoActivo) ? iconoActivo : "activo.png";
        iconoInActivo = (iconoInActivo) ? iconoInActivo : "inactivo.png";
        let column: any = {
            columnType: DataTableColumnTypes.Selection,
            isCustom: true,
            name: _name,
            title: (!title) ? this.translate.instant('Activar / Inactivar') : title,
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {

                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (configPermisos)
                    ok = that.TienePemisoUsuario(configPermisos, row, 'Eliminar');
                if (ok) {
                    if (iconoActivo) {
                        if (row.Activo)
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><img src="' + that.pathImages + iconoActivo + '"  data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '"  style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>';
                        else
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><img src="' + that.pathImages + iconoInActivo + '"  data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + nombreBotonEliminar + '"  style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>';
                    }
                    else {
                        if (row.Activo)
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '" class="fas fa-check-square"  aria-hidden="true"></i></a>';
                        else
                            return '<a title="' + toolTip + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + nombreBotonEliminar + '"  class="far fa-square"  aria-hidden="true"></i></a>';
                    }
                }
                else
                    return that.GetNoPermitidoGrilla(toolTip);
            }
        };
        return column;
    }

    public GetColumnaEditarEliminar(pkName: string, title: string = '', isUnion: boolean = false,
        editar: any = { nombreBoton: null, icono: null, toolTip: null, name: null },
        eliminar: any = { nombreBoton: null, iconoActivo: null, iconoInActivo: null, toolTip: null, name: null },
        seleccionar: any = { nombreBoton: null, icono: null, toolTip: null, name: null }, contextConfigPermisos?: any,
        _name?: string): any {

            debugger
        const that = this;
        if (seleccionar)
            seleccionar.toolTip = (!seleccionar.toolTip) ? 'Seleccionar' : seleccionar.toolTip;
        if (editar)
            editar.toolTip = (!editar.toolTip) ? 'Editar' : editar.toolTip;
        if (eliminar) {
            if (isUnion)
                eliminar.toolTip = (!eliminar.toolTip) ? this.translate.instant('Eliminar') : this.translate.instant(eliminar.toolTip);
            else
                eliminar.toolTip = (!eliminar.toolTip) ? this.translate.instant('Activar / Inactivar') : this.translate.instant(eliminar.toolTip);
        }
        let column: any = {
            columns: [],
            columnType: DataTableColumnTypes.Actions,
            isCustom: true,
            name: _name,
            title: this.translate.instant(title),
            "class": 'dt-body-center',
            data: "",
            render: function (data, type, row, meta) {

                let html = '';
                let ok = true;
                let configPermisos: any = that.GetConfigPermisos(contextConfigPermisos);
                if (seleccionar) {

                    if (configPermisos)
                        ok = that.TienePemisoUsuario(configPermisos, row, 'Editar');
                    if (ok) {
                        if (seleccionar.icon)
                            html = '<a title="' + this.translate.instant(seleccionar.toolTip) + '" style="cursor:pointer;"><img src="' + that.pathImages + seleccionar.icon + '" name="' + seleccionar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  style="cursor:pointer;height:32px;with:32px;" aria-hidden="true"></a>';
                        else
                            html = '<a title="' + this.translate.instant(seleccionar.toolTip) + '" style="cursor:pointer;"><i name="' + seleccionar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  class="far fa-hand-pointer"   aria-hidden="true"></i></a>';
                    }
                    else
                        html = that.GetNoPermitidoGrilla(seleccionar.toolTip);

                    column.columns.push({ columnType: DataTableColumnTypes.Selection, isCustom: true });

                }
                if (editar) {
                    if (configPermisos)
                        ok = that.TienePemisoUsuario(configPermisos, row, 'Editar');
                    if (ok) {
                        // editar.icon = 'editar.png'
                        editar.icon = 'Edit';
                        if (editar.icon)
                            // html = html + '<a title="' + editar.toolTip + '" style="cursor:pointer;"><img src="' + that.pathImages + editar.icon + '" name="' + editar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '" style="cursor:pointer;height:32px;with:32px;"   aria-hidden="true"></a>';
                            html = html + `<div class="mx-2 d-inline-block" title="${editar.toolTip}"><span style="font-size: 1.5rem; cursor: pointer;"><i class="ms-Icon ms-Icon--${editar.icon}"name="${editar.nombreBoton}" data-dat="${row[pkName]}" data-id="${row[pkName]}" data-index="${meta.row}" data-col="${meta.col}"></i></span></div>`;
                        else
                            html = html + '<a title="' + editar.toolTip + '" style="cursor:pointer;"><i name="' + editar.nombreBoton + '"  data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" class="fas fa-pen"   aria-hidden="true"></i></a>';
                    }
                    else
                        html = html + that.GetNoPermitidoGrilla(editar.toolTip);

                    column.columns.push({ columnType: DataTableColumnTypes.Edit, isCustom: true });
                }
                if (eliminar) {
                    if (configPermisos)
                        ok = that.TienePemisoUsuario(configPermisos, row, 'Eliminar');
                    if (ok) {
                        // eliminar.iconoActivo = "activo.png";
                        // eliminar.iconoInActivo = "inactivo.png";
                        eliminar.iconoActivo = "CheckboxCompositeReversed";
                        eliminar.iconoInActivo = "CheckboxComposite";
                        if (eliminar.iconoActivo) {

                            if (isUnion) {
                                eliminar.iconoActivo = "eliminar.png";
                                html = html + '<a title="' + this.translate.instant(eliminar.toolTip) + '" style="cursor:pointer;"><img src="' + that.pathImages + eliminar.iconoActivo + '" data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + this.translate.instant(eliminar.nombreBoton) + '"   style="cursor:pointer;height:32px;with:32px;" aria-hidden="true"></a>';
                                // column.columns.isDelete = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Delete, isCustom: true });
                            }
                            else {
                                // eliminar.iconoActivo = "activo.png";
                                // eliminar.iconoInActivo = "inactivo.png";
                                eliminar.iconoActivo = "CheckboxCompositeReversed";
                                eliminar.iconoInActivo = "CheckboxComposite";
                                if (row.Activo)
                                    // html = html + '<a title="' + eliminar.toolTip + '" style="cursor:pointer;"><img src="' + that.pathImages + eliminar.iconoActivo + '" data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"   name="' + eliminar.nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;"  aria-hidden="true"></a>';
                                    html = html + `<div class="mx-2 d-inline-block" title="${this.translate.instant(eliminar.toolTip)}"><span style="font-size: 1.5rem; cursor: pointer; color: #66C372;"><i class="ms-Icon ms-Icon--${eliminar.iconoActivo}" name="${this.translate.instant(eliminar.nombreBoton)}" data-dat="${row.Activo}" data-id="${row[pkName]}" data-index="${meta.row}"></i></span></div>`;
                                else
                                    // html = html + '<a title="' + eliminar.toolTip + '" style="cursor:pointer;"><img src="' + that.pathImages + eliminar.iconoInActivo + '" data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '" data-col="' + meta.col + '"  name="' + eliminar.nombreBoton + '"  style="cursor:pointer;height:32px;with:32px;" aria-hidden="true"></a>';
                                    html = html + `<div class="mx-2 d-inline-block" title="${this.translate.instant(eliminar.toolTip)}"><span style="font-size: 1.5rem; cursor: pointer; color:#ff2323;"><i class="ms-Icon ms-Icon--${eliminar.iconoInActivo}" name="${this.translate.instant(eliminar.nombreBoton)}" data-dat="${row.Activo}" data-id="${row[pkName]}" data-index="${meta.row}" data-col="${meta.col}"></i></span></div>`;
                                // column.columns.isActive = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Active, isCustom: true });
                            }
                        }
                        else {
                            if (isUnion) {
                                html = html + '<a title="' + this.translate.instant(eliminar.toolTip) + '" style="cursor:pointer;"><i data-dat="' + row[pkName] + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + this.translate.instant(eliminar.nombreBoton) + '"  class="fas fa-trash"  aria-hidden="true"></i></a>';
                                //column.columns.isDelete = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Delete, isCustom: true });
                            }
                            else {
                                if (row.Activo)
                                    html = html + '<a title="' + this.translate.instant(eliminar.toolTip) + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '"  name="' + eliminar.nombreBoton + '" class="fas fa-check-square"  aria-hidden="true"></i></a>';
                                else
                                    html = html + '<a title="' + this.translate.instant(eliminar.toolTip) + '" style="cursor:pointer;"><i data-dat="' + row.Activo + '" data-id="' + row[pkName] + '" data-index="' + meta.row + '"  data-col="' + meta.col + '" name="' + eliminar.nombreBoton + '"  class="far fa-square"  aria-hidden="true"></i></a>';
                                // column.columns.isActive = true;
                                column.columns.push({ columnType: DataTableColumnTypes.Active, isCustom: true });
                            }
                        }
                    }
                    else {
                        html = html + that.GetNoPermitidoGrilla(this.translate.instant(eliminar.toolTip));

                        if (isUnion)
                            column.columns.push({ columnType: DataTableColumnTypes.Delete, isCustom: true });
                        else
                            column.columns.push({ columnType: DataTableColumnTypes.Active, isCustom: true });

                    }

                }
                return html;
            }
        };

        return column;

    }
    public AdicionarColumasEditarEliminar(columns: Array<any>, Pagina: any,
        childOptions: Array<any>,
        config: any = {
            isHtmlDataTable: false,
            hideForm: false,
            isAddCrudColumns: true,
            pkName: null,
            isUnion: false,
            eliminar: { nombreBoton: null, title: null, toolTip: null, iconoActivo: null, iconoInActivo: null, index: 1, name: null },
            editar: { nombreBoton: null, title: null, toolTip: null, icono: null, index: 0, name: null },
            seleccionar: { nombreBoton: null, title: null, toolTip: null, icono: null, index: 0, name: null },
            componentName: '',
            insert: false,
            optionsIndex: 2,
            columnaEditarEliminar: false
        }, contextConfigPermisos?: any
    ) {
debugger
        if (Pagina.Valida && !config.isHtmlDataTable && !config.hideForm) {

            // let pkName = config.pkName;

            if (config.isAddCrudColumns) {
                if (config.columnaEditarEliminar) {

                    if (config.insert) {
                        let index = (config.editar.index !== null) ? config.editar.index : ((config.delete.index !== null) ? config.delete.index : 0);

                        columns.splice(index, 0, this.GetColumnaEditarEliminar(config.pkName, this.translate.instant('Acciones'), config.isUnion,
                            ((Pagina.Editar && !config.isUnion) ? config.editar : null),
                            ((Pagina.Eliminar) ? this.translate.instant(config.eliminar) : null), config.seleccionar, contextConfigPermisos));
                    }
                    else
                        columns.push(this.GetColumnaEditarEliminar(config.pkName, this.translate.instant('Acciones'), config.isUnion,
                            ((Pagina.Editar && !config.isUnion) ? config.editar : null),
                            ((Pagina.Eliminar) ? this.translate.instant(config.eliminar) : null), config.seleccionar, contextConfigPermisos));
                }
                else {
                    if (config.seleccionar) {
                        if (config.insert)
                            columns.splice(config.seleccionar.index, 0, this.GetColumnaSeleccionItem(config.pkName,
                                config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title, this.translate.instant(config.seleccionar.toolTip), contextConfigPermisos));
                        else
                            columns.push(this.GetColumnaSeleccionItem(config.pkName, config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title,
                                this.translate.instant(config.seleccionar.toolTip), contextConfigPermisos));
                    }
                    if (Pagina.Editar && !config.isUnion) {
                        if (config.insert)
                            columns.splice(config.editIndex, 0, this.GetColumnaEditar(config.pkName,
                                config.editar.nombreBoton, config.editar.icon, config.editar.title, config.editar.toolTip));
                        else
                            columns.push(this.GetColumnaEditar(config.pkName, config.editar.nombreBoton, config.editar.icon, config.editar.title, config.editar.toolTip));
                    }
                    if (Pagina.Eliminar) {
                        if (config.isUnion) {
                            if (config.insert)
                                columns.splice(config.deleteIndex, 0, this.GetColumnaEliminar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, this.translate.instant(config.eliminar.title), this.translate.instant(config.eliminar.toolTip), contextConfigPermisos));
                            else
                                config.columns.push(this.GetColumnaEliminar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, this.translate.instant(config.eliminar.title), this.translate.instant(config.eliminar.toolTip), contextConfigPermisos));
                        }
                        else {
                            if (config.insert)
                                columns.splice(config.deleteIndex, 0, this.GetColumnaActivar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, config.eliminar.iconoInActivo, this.translate.instant(config.eliminar.title), this.translate.instant(config.eliminar.toolTip), contextConfigPermisos));
                            else
                                columns.push(this.GetColumnaActivar(config.pkName, config.eliminar.nombreBoton,
                                    config.eliminar.iconoActivo, config.eliminar.iconoInActivo, this.translate.instant(config.eliminar.title), this.translate.instant(config.eliminar.toolTip), contextConfigPermisos));
                        }
                    }
                }
            }
            else if (config.seleccionar) {
                if (config.columnaEditarEliminar) {

                    if (config.insert) {
                        let index = (config.seleccionar.index !== null) ? config.seleccionar.index : 0;
                        columns.splice(index, 0, this.GetColumnaEditarEliminar(config.pkName, this.translate.instant('Acciones'), config.isUnion,
                            null, null, config.seleccionar));
                    }
                    else
                        columns.push(this.GetColumnaEditarEliminar(config.pkName, this.translate.instant('Acciones'), config.isUnion,
                            null, null, config.seleccionar));
                }
                else {
                    if (config.insert)
                        columns.splice(config.seleccionar.index, 0, this.GetColumnaSeleccionItem(config.pkName,
                            config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title, this.translate.instant(config.seleccionar.toolTip)));
                    else
                        columns.push(this.GetColumnaSeleccionItem(config.pkName, config.seleccionar.nombreBoton, config.seleccionar.icon, config.seleccionar.title, this.translate.instant(config.seleccionar.toolTip)));
                }

            }
            if (childOptions && childOptions.length > 0) {
                if (config.insert)
                    columns.splice(config.optionsIndex, 0, this.GetChildOptionsColumn(childOptions, config.componentName, config.pkName));
                else
                    columns.push(this.GetChildOptionsColumn(childOptions, config.componentName, config.pkName));
            }
        }



    }

    public GetConfigPermisos(contextConfigPermisos?: any, data?: any): any {
        let configPermisos: any = (contextConfigPermisos) ? contextConfigPermisos.GetConfigPermisos(data) : null;
        return configPermisos;
    }
    public GetNodeTypes(): List<any> {
        return new List(nodeTypes);
    }
    public GetNodeTypesArr(): Array<any> {
        return nodeTypes;
    }
    /*    public GetNodeSubTypes(): List<any> {
           return new List(nodeSubTypes);
       }
       public GetDocumentTypes(): List<any> {
           return new List(documentTypes);
       }
    */

    public VerEdicionFormulario(data: any, componentName: string,
        isWindowModel?: boolean, context?: any, modal: boolean = false,
        resizable: boolean = true, draggable: boolean = true, onInitialized?: any,
        configExtra?: any, containerId?: string): Promise<any> {
        const that = (context) ? context : this;
        return new Promise((resolve, rejection) => {

            let config: ConfigWindow = new ConfigWindow();
            config.classWindow = 'Info';
            config.titleWindow = "Edición";
            config.returnInstance = (onInitialized != null && onInitialized != undefined);
            config.viewComponentTitle = true;
            config.viewComponentHeader = true;
            config.modal = modal;
            config.width = 800;
            config.height = 450;
            config.viewOk = false;
            config.cancel = 'Cerrar';
            config.closeable = true;
            config.resizable = resizable;
            config.draggable = draggable;
            if (configExtra) {

                config.titleWindow = configExtra.TitleWindow || config.titleWindow;
                config.width = configExtra.Width || config.width;
                config.height = configExtra.Height || config.height;
                config.viewOk = configExtra.ViewOk || config.viewOk;
                config.ok = configExtra.LabelOk || config.ok;
                config.viewCancel = configExtra.ViewCancel || config.viewCancel;
                config.cancel = configExtra.LabelCancel || config.cancel;

                config.viewExtra = configExtra.ViewExtra || config.viewExtra;
                config.extra = configExtra.LabelExtra || config.extra;
                config.extraValue = configExtra.ExtraValue || config.extraValue;
                if (configExtra.ContainerId)
                    containerId = configExtra.ContainerId;

            }
            let windowModel: any = {};
            if (isWindowModel) {
                windowModel = data;
            } else {
                if (data.UniqueID) {
                    windowModel = {
                        pkValueEdit: data.UniqueID
                    };
                }
                if (data.PkValue) {
                    windowModel = {
                        pkValueEdit: data.PkValue
                    };
                }
            }
            this.OpenWindow(componentName,
                config, windowModel, containerId).then(dataResult => {

                    if (onInitialized)
                        onInitialized.emit(dataResult.componentInstance);
                    dataResult.onClosePromise.then(dataResultOnClose => {
                        resolve(dataResultOnClose);

                    });

                })
        });
    }

    public GetConfigWindow(titulo: string): ConfigWindow {
        let config: ConfigWindow = new ConfigWindow();
        config.classWindow = 'info';
        config.titleWindow = titulo;
        config.returnInstance = false;
        config.modal = false;
        config.width = 800;
        config.height = 400;
        return config;
    }
    //     public VerEditor(tipo: any, modelo: any, setResult: boolean = false,
    //         titulo: string = 'Editor', mode: any = null, withModes: Boolean = false, containerId?: string): Promise<any> {

    //         return new Promise((resolve, rejection) => {

    //             let editorTipo = 'EditorJsonComponent';
    //             if (tipo == 1) {
    //                 editorTipo = 'EditorJsonComponent';
    //             }
    //             else if (tipo == 3)
    //                 editorTipo = "WysiwygEditorComponent";
    //             else
    //                 editorTipo = 'EditorAceComponent';
    //             let config: ConfigWindow = new ConfigWindow();
    //             config.classWindow = 'info';
    //             config.titleWindow = titulo;
    //             config.returnInstance = false;
    //             config.modal = false;
    //             config.width = 600;
    //             config.height = 500;
    //             let windowModel: any = {
    //                 modelo: modelo
    //             };
    //             if (mode)
    //                 windowModel['mode'] = mode;
    //             if (withModes)
    //                 windowModel['withModes'] = withModes;

    //             this.OpenWindow(editorTipo,
    //                 config, windowModel, containerId).then(dataResult => {

    //
    //                     dataResult.onClosePromise.then(dataResultOnClose => {

    //                         if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
    //                             dataResultOnClose.modelo) {

    //                             if (setResult)
    //                                 modelo = dataResultOnClose.modelo;
    //                             resolve(dataResultOnClose);
    //                         }
    //                         else {

    //                             resolve(dataResultOnClose);
    //                         }
    //                     });

    //                 })
    //         });
    //     }

    public VerEditor(tipo: any, modelo: any, setResult: boolean = false, titulo: string = 'Editor', mode: any = null, withModes: Boolean = false): Promise<any> {

        return new Promise((resolve, rejection) => {

            let editorTipo = 'EditorJsonComponent';
            if (tipo == 1) {
                editorTipo = 'EditorJsonComponent';
            }
            else if (tipo == 3)
                editorTipo = "WysiwygEditorComponent";
            else
                editorTipo = 'EditorAceComponent';
            let config: ConfigWindow = new ConfigWindow();
            config.classWindow = 'info';
            config.titleWindow = titulo;
            config.returnInstance = false;
            config.modal = false;
            config.width = 700;
            config.height = 500;
            if (editorTipo == 'WysiwygEditorComponent') {
                config.height = 600;
                config.width = 850;
            }
            let windowModel: any = {
                modelo: modelo
            };
            if (mode)
                windowModel['mode'] = mode;
            if (tipo == 3) {
                windowModel["timer"] = 400;
            }
            if (withModes)
                windowModel['withModes'] = withModes;

            this.OpenWindow(editorTipo,
                config, windowModel).then(dataResult => {


                    dataResult.onClosePromise.then(dataResultOnClose => {

                        if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
                            dataResultOnClose.modelo) {

                            if (setResult)
                                modelo = dataResultOnClose.modelo;
                            resolve(dataResultOnClose);
                        }
                        else {

                            resolve(dataResultOnClose);
                        }
                    });

                })
        });
    }


    public VerModalComponente(componente: any, windowModel: any, config: ConfigWindow, containerId?: string): Promise<any> {

        return new Promise((resolve, rejection) => {
            this.OpenWindow(componente,
                config, windowModel, containerId).then(dataResult => {
                    dataResult.onClosePromise.then(dataResultOnClose => {
                        if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true &&
                            dataResultOnClose.modelo) {
                            resolve(dataResultOnClose);
                        }
                        else {
                            resolve(dataResultOnClose);
                        }
                    });
                })
        });
    }

    public pathImages = WebConfigData.PathImages;//environment.PathImages;// '../assets/images/jstree/';
    public GetNodeType(objType: string, setGuid: boolean = true, _default: string = 'SinImagen'): any {

        let nodeType = new List<any>(nodeTypes).FirstOrDefault(x => { return x.ObjType.toLowerCase() === objType.toLowerCase() });
        if (!nodeType && _default)
            nodeType = new List<any>(nodeTypes).FirstOrDefault(x => { return x.ObjType.toLowerCase() === _default.toLowerCase() });
        // if (setGuid)
        //     nodeType.UniqueID = Guid.create().toString();
        return nodeType;
    }
    // public GetNodeSubType(objType: string, setGuid: boolean = true, _default: string = 'SinImagen'): any {

    //     let nodeType = new List<any>(nodeSubTypes).FirstOrDefault(x => { return x.ObjType == objType });
    //     if (!nodeType && _default)
    //         nodeType = new List<any>(nodeTypes).FirstOrDefault(x => { return x.ObjType == _default });

    //     return nodeType;
    // }
    // public GetDocumentType(objType: string, setGuid: boolean = true, _default: string = 'SinImagen'): any {

    //     let nodeType = this.GetDocumentTypes().FirstOrDefault(x => { return x.ObjType == objType });
    //     if (!nodeType && _default)
    //         nodeType = new List<any>(nodeTypes).FirstOrDefault(x => { return x.ObjType == _default });

    //     return nodeType;
    // }
    public GetPathImages(nodeTypeData: any, context?: any) {
        let icon;
        let path = (context) ? context.pathImages : this.pathImages;
        if (typeof nodeTypeData === 'string') {
            icon = nodeTypeData;
        }
        else if (nodeTypeData.Icon)
            icon = nodeTypeData.Icon;
        if (!icon)
            return icon;
        else {
            if (icon.indexOf("/") !== -1 || icon.indexOf(".") !== -1)
                return path + icon;
            else
                return icon;

        }
    }


    public ConvertNodesJstreeToOrgChart(node: any, nodes: Array<any>, isFirst: boolean = false) {
        const that = this;
        if (node.parentNodeId && isFirst !== true)
            node.pid = node.parentNodeId;
        /*  if (node.data && node.data.nodeConfigEspecific) {

         } */

        nodes.push(node);
        if (node.children) {
            $.each(node.children, function (index, _childernNone) {

                that.ConvertNodesJstreeToOrgChart(_childernNone, nodes);

            });
        }

    }
    public GetAreasEmpresaParaFiltro(): Array<any> {

        let lst: Array<any> = [];
        if (StaticData.Usuario) {
            if (StaticData.Usuario.IdTipoBusquedaAreaEmpresa == TipoBusquedaAreaEmpresa.TodasLasAreas)
                return StaticData.AreasEmpresa.ToArray();
            else if (StaticData.Usuario.IdTipoBusquedaAreaEmpresa == TipoBusquedaAreaEmpresa.MiArea) {
                let area = StaticData.AreasEmpresa.FirstOrDefault(x => { return x.Id == StaticData.Usuario.IdAreaEmpresa && x.Tipo == TipoParaPermisos.AreaEmpresa });
                if (area)
                    lst.push(area);
            }
            else if (StaticData.Usuario.IdTipoBusquedaAreaEmpresa == TipoBusquedaAreaEmpresa.AreasEspecificas) {
                if (StaticData.Usuario.IdAreasEmpresa && StaticData.Usuario.IdAreasEmpresa.split('|').length > 0) {
                    let items: Array<any> = StaticData.Usuario.IdAreasEmpresa.split('|');
                    for (var i = 0; i < items.length; i++) {
                        let _id = items[i];
                        let area = StaticData.AreasEmpresa.FirstOrDefault(x => { return x.Id == _id && x.Tipo == TipoParaPermisos.AreaEmpresa });
                        if (area)
                            lst.push(area);
                        // let permisoUsuario = Permisos.FirstOrDefault(x => { return x.Id == idGrupo && x.Tipo == TipoParaPermisos.Grupo });


                    }
                }
            }
        }
        return lst;
    }


    public SetItemsArea(context: any, notFilter?: boolean, clone?: boolean) {

        context.itemsAreasEmpresa = (notFilter == true) ? this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') }).ToArray())
            : this.Clone(this.GetAreasEmpresaParaFiltro());
    }

    public SetItemsAreasRolesUsuarioSeries(context: any, notFilter?: boolean, clone?: boolean) {

        if (StaticData.Usuario) {
            if (clone == true) {


                if (context.itemsRoles)
                    context.itemsRoles = this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') }).ToArray());
                if (context.itemsAreasEmpresa)
                    context.itemsAreasEmpresa = (notFilter == true) ? this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') }).ToArray())
                        : this.Clone(this.GetAreasEmpresaParaFiltro());
                if (context.itemsUsuarios)
                    context.itemsUsuarios = this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') }).ToArray());
                if (context.itemsSeries)
                    context.itemsSeries = this.Clone(StaticData.Series.ToArray());
                if (context.itemsGrupos)
                    context.itemsGrupos = this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo') }).ToArray());
            }
            else {
                if (context.itemsRoles)
                    context.itemsRoles = StaticData.RolesUsuariosGrupos.Where(x => { return x.Tipo == TipoParaPermisos.Role }).ToArray();
                if (context.itemsAreasEmpresa)
                    context.itemsAreasEmpresa = (notFilter == true) ? StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') }).ToArray()
                        : this.GetAreasEmpresaParaFiltro();
                if (context.itemsUsuarios)
                    context.itemsUsuarios = StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') }).ToArray();
                if (context.itemsSeries)
                    context.itemsSeries = StaticData.Series.ToArray();
                if (context.itemsGrupos)
                    context.itemsGrupos = StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo') }).ToArray();
            }
        }
    }


    public GetItemsAreasRolesUsuarioSeries(type: TipoParaPermisos, notFilter?: boolean, clone?: boolean): Array<any> {

        let items: Array<any> = [];
        if (StaticData.Usuario) {


            switch (type) {
                case TipoParaPermisos.Ninguna:
                    items = (clone == true) ? this.Clone(StaticData.Series.ToArray()) : StaticData.Series.ToArray();
                    break
                case TipoParaPermisos.Role:
                    items = (clone == true) ? this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') }).ToArray()) :
                        StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role') }).ToArray();
                    break
                case TipoParaPermisos.AreaEmpresa:
                    if (clone == true) {
                        items = (notFilter == true) ? this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') }).ToArray())
                            : this.Clone(this.GetAreasEmpresaParaFiltro());
                    }
                    else {
                        items = (notFilter == true) ? StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa') }).ToArray()
                            : this.GetAreasEmpresaParaFiltro();
                    }
                    break
                case TipoParaPermisos.Usuario:
                    items = (clone == true) ? this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') }).ToArray()) :
                        StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario') }).ToArray();
                    break
                case TipoParaPermisos.Grupo:
                    items = (clone == true) ? this.Clone(StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo') }).ToArray()) :
                        StaticData.RolesUsuariosGrupos.Where(x => { return (x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo') }).ToArray();
                    break
            }
        }

        return items;
    }


    public SetValorPropiedadBusquedaIn(cloneModelo: any, modelo: any, propiedad: string, inPropiedad: string) {
        if (cloneModelo[propiedad]) {
            if (cloneModelo[propiedad].length == 1) {
                cloneModelo[propiedad] = modelo[propiedad][0];
            }
            else if (cloneModelo[propiedad].length > 0) {
                cloneModelo[inPropiedad] = modelo[propiedad].join(',');
                cloneModelo[propiedad] = null;
            }
            else
                cloneModelo[propiedad] = null;
        }
    }
    public SetValorPropiedadBusquedaContains(cloneModelo: any, propiedad: string) {
        if (cloneModelo[propiedad] && cloneModelo[propiedad].split(',').length > 0) {
            cloneModelo[propiedad] = cloneModelo[propiedad].split(',').join(' OR ');
        }
    }

    public SetTextFormatosTextoCampo(campo: any, nombreCampo: string) {
        if (nombreCampo) {
            if (campo && Number.isInteger(campo[nombreCampo])) {
                campo[nombreCampo] = FormatosTexto[campo[nombreCampo]];
            }
        }
        else {
            if (campo && Number.isInteger(campo.FormatoTexto)) {
                campo.FormatoTexto = FormatosTexto[campo.FormatoTexto];
            }
        }
        // FormatosTexto,TiposRelleno,TiposCampoConfigGenerar
    }
    public SetTextTiposRellenoCampo(campo: any) {
        if (campo && Number.isInteger(campo.TipoRelleno)) {
            campo.TipoRelleno = TiposRelleno[campo.TipoRelleno];
        }
    }
    public SetTextTiposCampoConfigGenerarCampo(campo: any) {
        if (campo && Number.isInteger(campo.TipoCampo)) {
            campo.TipoCampo = TiposCampoConfigGenerar[campo.TipoCampo];
        }
    }
    public SetTextCampo(campo: any, nombreCampo: string) {
        this.SetTextFormatosTextoCampo(campo, nombreCampo);
        this.SetTextTiposRellenoCampo(campo);
        this.SetTextTiposCampoConfigGenerarCampo(campo);
    }
    public SetTextTipoIdExpediente(campo: any) {
        if (campo && Number.isInteger(campo.TipoIdExpediente)) {
            campo.TipoIdExpediente = TiposIdExpediente[campo.TipoIdExpediente];
        }
    }
    public SetTextTipoUbicacionArchivo(campo: any) {
        if (campo && Number.isInteger(campo.TipoUbicacionArchivo)) {
            campo.TipoUbicacionArchivo = TiposUbicacionArchivo[campo.TipoUbicacionArchivo];
        }
    }
    public SetTextOpcionesPermisos(campo: any, propUno?: any, propDos?: any) {
        if (propUno) {
            if (campo && Number.isInteger(propUno)) {
                campo[propUno] = OpcionesPermisosCrearExpediente[campo[propUno]];
            }

            if (propDos) {
                if (campo && Number.isInteger(propDos)) {
                    campo[propDos] = OpcionesPermisosCrearExpediente[campo[propDos]];
                }
            }
        }
        else {
            if (campo && Number.isInteger(campo.OpcionesPermisos)) {
                campo.OpcionesPermisos = OpcionesPermisosCrearExpediente[campo.OpcionesPermisos];
            }
        }
    }
    public SetTextTipoNombreFolder(campo: any) {
        if (campo && Number.isInteger(campo.TipoNombreFolder)) {
            campo.TipoNombreFolder = TiposNombreFolder[campo.TipoNombreFolder];
        }
    }

    public SetTextTipoUbicacionCacheIU(campo: any) {
        if (campo && Number.isInteger(campo.TipoUbicacion)) {
            campo.TipoUbicacion = TiposUbicacionCacheIU[campo.TipoUbicacion];
        }
    }


    public DataTypesToString(dataType: any) {
        // DataTypes;
        let result = dataType;
        if (dataType && Number.isInteger(dataType)) {
            result = DataTypes[dataType];
        }
        return result;
    }


    public GetEnumArraryText(enumeration: any): Array<any> {
        let arr: Array<any> = [];

        for (let enumMember in enumeration) {
            var isValueProperty = parseInt(enumMember, 10) >= 0
            if (isValueProperty) {
                arr.push(enumeration[enumMember]);
            }
        }

        return arr;
    }

    public GetTiposUbicacionArchivo(): Array<any> {
        return this.GetEnumArraryText(TiposUbicacionArchivo);
    }
    public GetOpcionesPermisosCrearExpedienteText(): Array<any> {
        return this.GetEnumArraryText(OpcionesPermisosCrearExpediente);
    }
    public GetTiposCampoConfigGenerarText(): Array<any> {
        return this.GetEnumArraryText(TiposCampoConfigGenerar);
    }
    public GetTiposTiposNombreFolderText(): Array<any> {
        return this.GetEnumArraryText(TiposNombreFolder);
    }
    public GetTiposUbicacionCacheIUText(): Array<any> {
        return this.GetEnumArraryText(TiposUbicacionCacheIU);
    }
    public GetTiposIdExpedienteText(): Array<any> {
        return this.GetEnumArraryText(TiposIdExpediente);
    }

    public GetModeloConfigDestinoDocumento(): any {

        let modelo: any = {
            CamposNombreFolder: [],
            ConfigTipoUbicacionArchivo: null,
            Nombre: null,
            TipoNombreFolder: 'Ninguno',
            CaracterSeparacionFolder: null,
            ValoresReemplazoNombreFolder: {},
            FormatoTextoNombreFolder: 'Normal',
            TamanoMaximoNombreFolder: 0
        };

        return modelo;
    }

    public GetModeloConfigDocumento(): any {

        let modelo: any = this.GetModeloConfigDestinoDocumento();
        modelo.OpcionesPermisos = 'Ninguna';
        modelo.CamposNombreDocumentos = [];
        modelo.TamanoMaximo = null;
        modelo.ExtensionesValidas = null;
        modelo.CaracterSeparacionNombreDocumento = null;
        modelo.ValoresReemplazoNombreDocumento = {};
        modelo.FormatoTextoNombreDocumento = 'Normal';
        modelo.TamanoMaximoNombreDocumento = 0;
        modelo.ConfigValidacionDocumento = this.GetModeloConfigValidacionDocumento();
        return modelo;
    }
    public GetModeloConfigExpediente(): any {

        let modelo: any = this.GetModeloConfigDestinoDocumento();
        modelo.OpcionesPermisos = 'Ninguna';
        modelo.Id = 0;
        modelo.ConfigDocumento = null;
        modelo.TipoIdExpediente = 'Expediente';
        modelo.ConfigValidacionExpediente = this.GetModeloConfigValidacionExpediente();
        return modelo;
    }

    public GetModeloConfigTipoUbicacionArchivo(tipo: any): any {

        let modelo: any = {
            TipoUbicacionArchivo: 'FileSystem',
            Ruta: null,
            Cnn: null,
            Tabla: null,
            CampoGuid: null,
            CampoAutonumerico: null,
            CampoArchivo: null,
            CampoNombreArchivo: null,
            CampoIdDocumento: null,
            CampoUsuario: null,
            CampoIdEmpresa: null,
        };

        return modelo;
    }


    public GetModeloBaseConfigConsecutivo(): any {

        let modelo: any = {
            Campos: [],
            CaracterSeparacion: null,
            ValoresReemplazo: {},
            FormatoTexto: 'Normal',
            TamanoMaximo: 0
        };

        return modelo;
    }

    public GetModeloConfigConsecutivoExpediente(): any {

        let modelo: any = this.GetModeloBaseConfigConsecutivo();
        modelo.Id = 0;
        modelo.ConfigDocumento = null;
        modelo.TipoIdExpediente = 'Expediente';
        return modelo;
    }
    public GetModeloConfigConsecutivoDocumento(): any {

        let modelo: any = this.GetModeloBaseConfigConsecutivo();

        return modelo;
    }

    public GetModeloConfigValidacionExpedienteDocumento(): any {

        let modelo: any = {
            CodigoUnico: false,
            NombreUnico: false
        };
        return modelo;
    }
    public GetModeloConfigValidacionExpediente(): any {

        let modelo: any = this.GetModeloConfigValidacionExpedienteDocumento();
        return modelo;
    }
    public GetModeloConfigValidacionDocumento(): any {

        let modelo: any = this.GetModeloConfigValidacionExpedienteDocumento();
        modelo.FirmaHashUnico = false;
        modelo.FirmaHashUnicoPorExpediente = false;
        modelo.CodigoUnicoPorExpediente = false;
        modelo.NombreUnicoPorExpediente = false;
        modelo.GenerarFirmaHash = true;
        modelo.PermitirActualizarDocumento = true;
        modelo.PermitirGenerarNuevaVersionDocumento = true;
        return modelo;
    }
    public GetModeloConfig(tipo: string, subTipo: string): any {

        let modelo: any = {};
        switch (tipo.toLowerCase()) {
            case 'ConfigValidacionDocumento'.toLowerCase():
                modelo = this.GetModeloConfigValidacionDocumento();
                break;
            case 'ConfigValidacionExpediente'.toLowerCase():
                modelo = this.GetModeloConfigValidacionExpediente();
                break;
            case 'ConfigTipoUbicacionArchivo'.toLowerCase():
                modelo = this.GetModeloConfigTipoUbicacionArchivo(subTipo);
                break;
            case 'ConfigExpediente'.toLowerCase():
                modelo = this.GetModeloConfigExpediente();
                break;
            case 'ConfigDocumento'.toLowerCase():
                modelo = this.GetModeloConfigDocumento();
                break;
            case 'ConfigExpedienteEmpresa'.toLowerCase():
                modelo = this.GetModeloConfigDestinoDocumento();
                break;
            case 'ConfigConsecutivoExpediente'.toLowerCase():
                modelo = this.GetModeloConfigConsecutivoExpediente();
                break;
            case 'ConfigConsecutivoDocumento'.toLowerCase():
                modelo = this.GetModeloConfigConsecutivoDocumento();
                break;
            //
        }

        return modelo;
    }
    public GetBoolean(value) {
        switch (value) {
            case true:
            case "true":
            case 1:
            case "1":
            case "on":
            case "yes":
                return true;
            default:
                return false;
        }
    }
    public ConvertValue(_value: any, dataType: string) {
        let value = _value;
        if (_value !== undefined && _value != null) {
            if (!dataType || dataType == 'String')
                value = _value;
            else if (dataType == 'Int32')
                value = parseInt(_value);
            else if (dataType == 'Object')
                value = JSON.parse(_value);
            else if (dataType == 'Boolean')
                value = this.GetBoolean(_value);
            else
                value = _value;
        }
        return value;
    }




    public EsControlFormulario(control: any): boolean {

        return this.formControlTypes.Any(x => { return x.toLowerCase() == control.ControlType.toLowerCase() });
    }

    public EsControlBasico(control: any): boolean {

        return new List<any>(this.GetBasicFields()).Any(x => { return x.ControlType.toLowerCase() == control.ControlType.toLowerCase() });
    }
    public EsComboBox(control: any, inclideGrid: boolean): boolean {

        let ok = false;
        switch (control.ControlType) {
            case 'ComboBox':
            case 'MultiComboBox':
                ok = true;
                break;
            case 'GridView':
                ok = inclideGrid;
                break;

        }
        return ok;
    }

    public EsContenedorControlesGeneral(control: any): boolean {


        let ok = this.EsContenedorControles(control);
        if (!ok) {
            switch (control.ControlType) {
                case 'FormJson':
                case 'FormView':

                    ok = true;
                    break;

            }
        }
        return ok;
    }
    public EsContenedorControles(control: any): boolean {


        let ok = false;
        switch (control.ControlType) {
            // case 'FormJson':
            // case 'FormView':
            case 'FloatPanel':
            case 'PanelMicrosoft':
            case 'SidePanel':
            case 'SwapContainer':
            case 'SwapGridPanel':
            case 'SwapFormPanel':
            case 'FormContainer':
            case 'FormGroup':
            // case 'FormSubmit':
            case 'WorkFlowTask':
            case 'JsonComponent':
            case 'CustomComponent':
            case 'ContainerBox':
            case 'ContainerBoxIcon':
            case 'ContainerBoxIconHeader':
            case 'DragContainerBox':
            case 'Accordion':
            case 'Tabs':
            case 'AccordionPanel':
            case 'TabPanel':
            case 'Div':
            case 'DragPanel':
                ok = true;
                break;

        }
        return ok;
    }

    public Unsubscribe(sus: Subject<any>, setNull?: boolean) {
        if (sus)
            sus.unsubscribe();
        if (setNull)
            sus = null;
    }

    public IsClientVar(objectType): boolean {
        return (objectType == 'StaticVar' ||
            objectType == 'LocalStorageVar' ||
            objectType == 'SessionStorageVar' ||
            objectType == 'ContextVar'
        )
    }
    public IsVar(objectType): boolean {
        return (objectType == 'StaticVar' ||
            objectType == 'LocalStorageVar' ||
            objectType == 'SessionStorageVar' ||
            objectType == 'DbVar' ||
            objectType == 'ContextVar' ||
            objectType == 'SessionVar' ||
            objectType == 'AppVar'
        )
    }
    public IsServerVar(objectType): boolean {
        return (
            objectType == 'DbVar' ||

            objectType == 'SessionVar' ||
            objectType == 'AppVar'
        )
    }

    public GetAllControls(_controls: Array<any>): Array<any> {

        let controls: Array<any> = new List<any>(this.GetAllFormControls(_controls)).Select((x) => {
            return (x.ContainerName) ? { Name: x.Name, Label: x.Name + " [" + x.ControlType + "][" + x.ContainerName + "]", ControlType: x.ControlType } :
                { Name: x.Name, Label: x.Name + " [" + x.ControlType + "]", ControlType: x.ControlType }
        }).ToArray();
        return controls;
    }
    public GetAllContaines(formControls: Array<any>, onlyActive: boolean = false): Array<any> {
        let resultControls: Array<any> = [];
        this.SearchFormControl(formControls, resultControls, false, null, false, "", true, onlyActive);
        return resultControls.filter(x => this.EsContenedorControlesGeneral(x));
    }
    public GetFormControlsParents(formControls: Array<any>): Array<any> {
        let resultControls: Array<any> = [];
        this.SearchFormControl(formControls, resultControls, true);
        //resultControls.find()
        return resultControls.filter(x => this.EsComboBox(x, true));
    }
    public GetFormControls(formControls: Array<any>, excludePK: boolean = false,
        allControls: boolean = false, onlyActive: boolean = false): Array<any> {
        let resultControls: Array<any> = [];
        this.SearchFormControl(formControls, resultControls, false, null, excludePK, "", allControls, onlyActive);
        return resultControls.filter(x => this.EsControlFormulario(x));
    }
    public GetAllFormControls(formControls: Array<any>): Array<any> {
        let resultControls: Array<any> = [];
        this.SearchFormControl(formControls, resultControls, false, null, false, "", true, false);
        return resultControls;
    }
    public SearchFormControl(controls: Array<any>, resultControls: Array<any>, includeGrid: boolean,
        include?: Array<any>, excludePK: boolean = false, containerName: string = "", allControls: boolean = false,
        onliActonlyActiveive: boolean = false) {
        if (controls && controls.length > 0) {
            if (allControls) {
                controls.forEach(formControl => {

                    resultControls.push(formControl);
                    if (this.EsContenedorControles(formControl) && formControl.Controls && formControl.Controls.length > 0) {

                        this.SearchFormControl(formControl.Controls, resultControls, includeGrid, include, excludePK, formControl.Name, allControls);
                    }
                    else if (formControl.ControlType == "CrudButtons") {

                        resultControls.push({ Id: formControl.NombreBotonGuardar, Name: formControl.NombreBotonGuardar, Label: formControl.NombreBotonGuardar, ControlType: "Button", Activo: true });
                        resultControls.push({ Id: formControl.NombreBotonLimpiar, Name: formControl.NombreBotonLimpiar, Label: formControl.NombreBotonLimpiar, ControlType: "Button", Activo: true });
                    }

                });
            }
            else {
                controls.forEach(formControl => {
                    if (this.EsControlFormulario(formControl) || includeGrid && formControl.ControlType == "GridView") {
                        formControl.ContainerName = containerName;
                        if (formControl.Name != "IdEmpresa") {
                            if (excludePK && formControl.IsPk) {
                            }
                            else {

                                resultControls.push(formControl);
                            }
                        }
                    }
                    else {
                        if (formControl.Controls && formControl.Controls.length > 0) {
                            if (formControl.ControlType === 'Pagina') {
                                let pagina = this.FindPage(StaticData.Usuario.Paginas, 'IdPagina', formControl.IdPagina);
                                if (allControls)
                                    resultControls.push(formControl);
                                this.SearchFormControl(pagina.Controls, resultControls, includeGrid, include, excludePK, formControl.Name, allControls)
                            }
                            else if (this.EsContenedorControles(formControl)) {
                                if (allControls)
                                    resultControls.push(formControl);
                                this.SearchFormControl(formControl.Controls, resultControls, includeGrid, include, excludePK, formControl.Name, allControls);
                            }
                        }
                        else if (formControl.ControlType == "CrudButtons") {
                            resultControls.push({ Id: formControl.NombreBotonGuardar, Name: formControl.NombreBotonGuardar, Label: formControl.NombreBotonGuardar, ControlType: "Button", Activo: true });
                            resultControls.push({ Id: formControl.NombreBotonLimpiar, Name: formControl.NombreBotonLimpiar, Label: formControl.NombreBotonLimpiar, ControlType: "Button", Activo: true });
                            // resultControls.push({ Id: 'btnGuardar', Name: 'btnGuardar', Label: 'btnGuardar', ControlType: "Button", Activo: true });
                            // resultControls.push({ Id: 'btnLimpiar', Name: 'btnLimpiar', Label: 'btnLimpiar', ControlType: "Button", Activo: true });
                        }
                    }
                });

            }
        }
    }
    // public GetAllControls(_controls: Array<any>): Array<any> {

    //     let controls: Array<any> = new List<any>(this.GetAllFormControls(_controls)).Select((x) => {
    //         return (x.ContainerName) ? { Name: x.Name, Label: x.Name + " [" + x.ControlType + "][" + x.ContainerName + "]", ControlType: x.ControlType } :
    //             { Name: x.Name, Label: x.Name + " [" + x.ControlType + "]", ControlType: x.ControlType }
    //     }).ToArray();
    //     return controls;
    // }
    // public GetFormControlsParents(formControls: Array<any>): Array<any> {
    //     let resultControls: Array<any> = [];
    //     this.SearchFormControl(formControls, resultControls, true);
    //     //resultControls.find()
    //     return resultControls.filter(x => this.EsComboBox(x, true));
    // }
    // public GetFormControls(formControls: Array<any>, excludePK: boolean = false, allControls: boolean = false): Array<any> {
    //     let resultControls: Array<any> = [];
    //     this.SearchFormControl(formControls, resultControls, false, null, excludePK, "", allControls);
    //     return resultControls.filter(x => this.EsControlFormulario(x));
    // }
    // public GetAllFormControls(formControls: Array<any>): Array<any> {
    //     let resultControls: Array<any> = [];
    //     this.SearchFormControl(formControls, resultControls, false, null, false, "", true);
    //     return resultControls;
    // }
    // public SearchFormControl(controls: Array<any>, resultControls: Array<any>, includeGrid: boolean,
    //     include?: Array<any>, excludePK: boolean = false, containerName: string = "", allControls: boolean = false) {
    //     if (controls && controls.length > 0) {
    //         //  controls.forEach(formControl => {

    //         if (allControls) {
    //             controls.forEach(formControl => {

    //                 resultControls.push(formControl);
    //                 if (this.EsContenedorControles(formControl) && formControl.Controls && formControl.Controls.length > 0) {

    //                     this.SearchFormControl(formControl.Controls, resultControls, includeGrid, include, excludePK, formControl.Name, allControls);
    //                 }
    //                 else if (formControl.ControlType == "CrudButtons") {
    //                     resultControls.push({ Id: 'btnGuardar', Name: 'btnGuardar', Label: 'btnGuardar', ControlType: "Button", Activo: true });
    //                     resultControls.push({ Id: 'btnLimpiar', Name: 'btnLimpiar', Label: 'btnLimpiar', ControlType: "Button", Activo: true });
    //                 }

    //             });
    //         }
    //         else {
    //             controls.forEach(formControl => {
    //                 if (this.EsControlFormulario(formControl) || includeGrid && formControl.ControlType == "GridView") {
    //                     formControl.ContainerName = containerName;
    //                     if (formControl.Name != "IdEmpresa") {
    //                         if (excludePK && formControl.IsPk) {
    //                         }
    //                         else {

    //                             resultControls.push(formControl);
    //                         }
    //                     }
    //                 }
    //                 else {
    //                     if (formControl.Controls && formControl.Controls.length > 0) {
    //                         if (formControl.ControlType == 'Pagina') {
    //                             let pagina = this.FindPage(StaticData.Usuario.Paginas, 'IdPagina', formControl.IdPagina);
    //                             if (allControls)
    //                                 resultControls.push(formControl);
    //                             this.SearchFormControl(pagina.Controls, resultControls, includeGrid, include, excludePK, formControl.Name, allControls)
    //                         }
    //                         else if (this.EsContenedorControles(formControl)) {
    //                             if (allControls)
    //                                 resultControls.push(formControl);
    //                             this.SearchFormControl(formControl.Controls, resultControls, includeGrid, include, excludePK, formControl.Name, allControls);
    //                         }
    //                     }
    //                     else if (formControl.ControlType == "CrudButtons") {
    //                         resultControls.push({ Id: 'btnGuardar', Name: 'btnGuardar', Label: 'btnGuardar', ControlType: "Button", Activo: true });
    //                         resultControls.push({ Id: 'btnLimpiar', Name: 'btnLimpiar', Label: 'btnLimpiar', ControlType: "Button", Activo: true });
    //                     }
    //                 }
    //             });

    //         }
    //         //});
    //     }
    // }


    public InactivarPorSearcheable(controls: Array<any>) {
        if (controls && controls.length > 0) {

            controls.forEach(formControl => {


                if (this.EsContenedorControles(formControl) && formControl.Controls && formControl.Controls.length > 0) {

                    this.InactivarPorSearcheable(formControl.Controls);
                }
                else if (this.EsControlFormulario(formControl)) {
                    if (!formControl.Searcheable) {
                        formControl.Activo = false;
                    }
                }

            });

        }
    }

    public DownloadByBytes(fileResult: any) {

        var bytes = new Uint8Array(fileResult.Data); // pass your byte response to this constructor

        var blob = new Blob([bytes], { type: "application/" + fileResult.Extension });// change resultByte to bytes

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileResult.NombreUrl}.${fileResult.Extension}`;
        link.click();
    }
    public DownloadByBase64(fileResult: any) {

        const linkSource = `data:application/${fileResult.Extension};base64,${fileResult.Data}`;
        const downloadLink = document.createElement("a");
        const extension: string = fileResult.NombreUrl.substring(fileResult.NombreUrl.lastIndexOf('.') + 1).toLowerCase();

        if (extension == fileResult.Extension)
            fileResult.NombreUrl = fileResult.NombreUrl.replace('.' + extension, '');

        const fileName = `${fileResult.NombreUrl}.${fileResult.Extension}`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public DownloadByUrl(fileResult: any) {
        let w: any = window.open("");
        //W ES UNDEFINED SI EL NAVEGADOR BLOQUEÓ EL POPUP
        if (w) {
            w.location.href = fileResult.Url;
        }
    }


    public FormatStopWatch(millisec: any) {
        let seconds: any = (millisec / 1000).toFixed(0);
        let minutes: any = Math.floor(seconds / 60);
        var hours: any = "";
        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            hours = (hours >= 10) ? hours : "0" + hours;
            minutes = minutes - (hours * 60);
            minutes = (minutes >= 10) ? minutes : "0" + minutes;
        }

        seconds = Math.floor(seconds % 60);
        seconds = (seconds >= 10) ? seconds : "0" + seconds;
        if (hours != "") {
            return hours + ":" + minutes + ":" + seconds;
        }
        return minutes + ":" + seconds;
    }







    public GetCurrentDate(separator: any = "-") {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        let fecha = dd + separator + mm + separator + yyyy + ' ' + today.getHours() + today.getMinutes() + today.getSeconds();
        return fecha;
    }


    public ViewResultGridView(items, title: any = "Resultados") {


        const config = this.GetConfigWindow(title);

        let configGrid: any = {
            Name: "TablaResultados",
            ReadColumnFormData: true,
            IsHtml: true,
            ConfigControl: {
                QueryConfig: {},
                Items: items,
                NotLoad: true
            }
        };
        let windowModel: any = {
            config: configGrid,
        };

        this.VerModalComponente('DataTableComponent', windowModel, config).then(dataResultOnClose => {
            if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {



            }
            else {

            }
        });


    }

    public ConcatMap(items: Array<any>, resultItems: Array<any>): Array<any> {
        if (!items)
            items = [];
        resultItems = resultItems.concat(items.map(item =>
        ({
            Label: item.Name + '--[' + item.ObjectType + ']',
            Name: item.Name,
            GUID: item.GUID,
            ObjectType: item.ObjectType,
            RuleType: item.RuleType
        })
        ));
        return resultItems;
    }

    public GetQuerys(project: any, typeSelected?: string): Array<any> {
        let items: Array<any> = [];

        if (typeSelected) {

            switch (typeSelected) {
                case 'ActiveDirectoryQuery':
                    items = this.ConcatMap(project.Querys.ActiveDirectoryQuerys, items);
                    break;
                case 'AssemblyQuery':
                    items = this.ConcatMap(project.Querys.AssemblyQuerys, items);
                    break;
                case 'CSharpQuery':
                    items = this.ConcatMap(project.Querys.CSharpQuerys, items);
                    break;
                case 'DirectoryQuery':
                    items = this.ConcatMap(project.Querys.DirectoryQuerys, items);
                    break;
                case 'FileQuery':
                    items = this.ConcatMap(project.Querys.FileQuerys, items);
                    break;
                case 'GroupedQuery':
                    items = this.ConcatMap(project.Querys.GroupedQuerys, items);
                    break;
                case 'ImportQuery':
                    items = this.ConcatMap(project.Querys.ImportQuerys, items);
                    break;
                case 'JavaScriptQuery':
                    items = this.ConcatMap(project.Querys.JavaScriptQuerys, items);
                    break;
                case 'JoinQuery':
                    items = this.ConcatMap(project.Querys.JoinQuerys, items);
                    break;
                case 'PowerShellQuery':
                    items = this.ConcatMap(project.Querys.PowerShellQuerys, items);
                    break;
                case 'ProcessQuery':
                    items = this.ConcatMap(project.Querys.ProcessQuerys, items);
                    break;
                case 'SharePointQuery':
                    items = this.ConcatMap(project.Querys.SharePointQuerys, items);
                    break;
                case 'SqlQuery':
                    items = this.ConcatMap(project.Querys.SqlQuerys, items);
                    break;
                case 'SystemQuery':
                    items = this.ConcatMap(project.Querys.SystemQuerys, items);
                    break;
                case 'WebServiceQuery':
                    items = this.ConcatMap(project.Querys.WebServiceQuerys, items);
                    break;
                case 'XsdQuery':
                    items = this.ConcatMap(project.Querys.XsdQuerys, items);
                    break;
            }
        }
        else {
            items = this.ConcatMap(project.Querys.ActiveDirectoryQuerys, items);
            items = this.ConcatMap(project.Querys.AssemblyQuerys, items);
            items = this.ConcatMap(project.Querys.CSharpQuerys, items);
            items = this.ConcatMap(project.Querys.DirectoryQuerys, items);
            items = this.ConcatMap(project.Querys.FileQuerys, items);
            items = this.ConcatMap(project.Querys.GroupedQuerys, items);
            items = this.ConcatMap(project.Querys.ImportQuerys, items);
            items = this.ConcatMap(project.Querys.JavaScriptQuerys, items);
            items = this.ConcatMap(project.Querys.JoinQuerys, items);
            items = this.ConcatMap(project.Querys.PowerShellQuerys, items);
            items = this.ConcatMap(project.Querys.ProcessQuerys, items);
            items = this.ConcatMap(project.Querys.SharePointQuerys, items);
            items = this.ConcatMap(project.Querys.SqlQuerys, items);
            items = this.ConcatMap(project.Querys.SystemQuerys, items);
            items = this.ConcatMap(project.Querys.WebServiceQuerys, items);
            items = this.ConcatMap(project.Querys.XsdQuerys, items);
        }
        return items;
    }
    public GetTemplates(project: any): Array<any> {
        let items: Array<any> = [];
        items = this.ConcatMap(project.Templates.Templates, items);
        items = this.ConcatMap(project.Templates.GroupedTemplates, items);
        return items;

    }
    public ReplaceTextFromModels(modelo: any, msg: string) {

        let today = new Date();

        for (var prop in modelo) {


            if (Array.isArray(modelo[prop]) == true) {

            }
            else if (typeof modelo[prop] === 'object') {
                for (var _prop in modelo[prop]) {
                    msg = msg.replace("#" + _prop + "#", modelo[prop][_prop]);
                }
            }
            else {
                msg = msg.replace("#" + prop + "#", modelo[prop]);
            }

        }

        msg = this.ReplaceTextFromCurrentUserData(msg);
        return msg;
    }

    public ReplaceTextFromCurrentUserData(msg: string) {

        let today = new Date();
        if (StaticData.Usuario) {
            msg = msg.replace("#IdUsuario#", StaticData.Usuario.IdUsuario);

            msg = msg.replace("#IdRoleUsuario#", StaticData.Usuario.IdRole);
            msg = msg.replace("#RoleUsuario#", StaticData.Usuario.Role);
            msg = msg.replace("#Usuario#", StaticData.Usuario.Usuario);
            msg = msg.replace("#NombreUsuario#", StaticData.Usuario.Nombre);
            msg = msg.replace("#EmailUsuario#", StaticData.Usuario.Email);
            msg = msg.replace("#AreaEmpresaUsuario#", StaticData.Usuario.AreaEmpresa);

            msg = msg.replace("#IdAreaEmpresaUsuario#", StaticData.Usuario.IdAreaEmpresa);

            msg = msg.replace("#IdEmpresa#", StaticData.Usuario.IdEmpresa);
        }

        msg = msg.replace("#AnoActual#", today.getFullYear().toString());
        msg = msg.replace("#DiaActual#", today.getDay().toString());
        msg = msg.replace("#MesActual#", today.getMonth().toString());

        return msg;
    }
    public SplitPropertyToModel(property) {
        let model = { property: property, listProps: null };
        model.listProps = (property) ? property.split('.') : null;
        return model;
    }
    public GetValueFromPropList(modelo: any, listProps: Array<any>) {
        let value: any = null;

        if (modelo && listProps && listProps.length > 0) {
            value = modelo[listProps[0]];
            listProps.splice(0, 1);
            listProps.forEach(property => {
                value = value[property]
            });
        }
        else
            value = modelo;

        return value;
    }

    SetValueFromPropList(modelo: any, listProps: Array<any>, value: any) {
        let currentModel: any = modelo;

        if (listProps && listProps.length > 0) {
            if (listProps && listProps.length == 1) {

                if (modelo[listProps[0]].setValue)
                    modelo[listProps[0]].setValue(value);
                else
                    modelo[listProps[0]] = value;

            }
            else {
                listProps.forEach(property => {
                    if (!currentModel[property])
                        currentModel[property] = {};
                    currentModel = currentModel[property];
                });
                if (currentModel.setValue)
                    currentModel.setValue(value);
                else
                    currentModel = value
            }
        }
        else
            modelo = value;

        return value;
    }

    public GetQueryParameter(key: string): string {

        const urls = window.location.hash.split("?");
        const parameters = new URLSearchParams((urls.length > 1) ? urls[1] : window.location.hash);

        return parameters.get(key);
    }
    public GetParmeterValue(parameter, modelo: any, form: FormGroup, QueryConfig: any, modeloPadre?: any, context?: any, parentContext?: any) {
        let value: any = null;

        if (parameter.Value) {
            value = parameter.Value;
            return value;
        }

        let model = this.SplitPropertyToModel(parameter.Property);
        if (parameter.ModelContainer == "currentItem" && context && context.currentItem) {

            value = this.GetValueFromPropList(context.currentItem, model.listProps);
            return value;
        }
        else if (parameter.ModelContainer == "currentItem" && modelo.modelo) {

            value = this.GetValueFromPropList(modelo.modelo, model.listProps);
            return value;
        }
        if (parameter.ModelContainer == "queryString") {

            value = this.GetQueryParameter(model.listProps[0]);
            return value;
        }
        else if (parameter.ModelContainer == "staticModel") {
            value = this.GetValueFromPropList(this, model.listProps);
            return value;
        }
        else if (parameter.ModelContainer == "staticDataModel") {
            value = this.GetValueFromPropList(StaticData, model.listProps);
            return value;
        }
        else if (parameter.ModelContainer == "formModel" && form) {

            value = this.GetValueFromPropList(form.controls, model.listProps);
            if (value.value)
                value = value.value;
            //Es un FormControl sin valor
            else if (typeof value == "object") {
                value = null;
            }
            return value;
        }
        else if (parameter.ModelContainer == "formInternalModel") {
            value = this.GetValueFromPropList(form, model.listProps);
            return value;
        }
        else if (parameter.ModelContainer == "queryParameters") {
            if (QueryConfig) {

                value = this.GetValueFromPropList(QueryConfig.Entity, model.listProps);
            }

            else
                throw new Error("No se encontro configuracion para lectura de valor")

            return value;

        }
        else if (parameter.ModelContainer == "queryConfig") {
            if (QueryConfig) {

                value = this.GetValueFromPropList(QueryConfig, model.listProps);
            }

            else
                throw new Error("No se encontro configuracion para lectura de valor")
            return value;

        }
        else if (parameter.ModelContainer == "modeloInterno") {

            value = this.GetValueFromPropList(context, model.listProps);
            return value;
        }
        else if (parameter.ModelContainer == "control") {

            const control = context.componentsContainer.Find(model.listProps[0]);
            if (control) {
                if (control.config && control.config.ControlType == 'GridView') {
                    value = control.ids;
                }
            }

            return value;
        }
        else if (parameter.ModelContainer == "currentContext") {

            value = this.GetValueFromPropList(context.currentContext, model.listProps);
            return value;
        }
        else if (parameter.ModelContainer == "parentContext") {

            value = this.GetValueFromPropList(context.parentContext, model.listProps);
            return value;
        }
        else {
            if (parameter.IsParentModel) {

                if (!modeloPadre)
                    throw new Error("No se proporciono el Modelo Padre Para Extraccion de valor");
                else {
                    if (!parameter.ModelContainer) {
                        value = this.GetValueFromPropList(modeloPadre, model.listProps);
                    }
                    else {
                        value = this.GetValueFromPropList(modeloPadre[parameter.ModelContainer], model.listProps);
                    }
                }
                return value;
            }
            else {
                if (modelo == null) {
                    return null;
                }
                if (!parameter.ModelContainer) {
                    value = this.GetValueFromPropList(modelo, model.listProps);
                }
                else {
                    value = this.GetValueFromPropList(modelo[parameter.ModelContainer], model.listProps);
                }
                return value;
            }
        }

    }
    public SetValueParmeterValue(parameter: any, value: any, modelo?: any, form?: FormGroup, QueryConfig?: any, modeloPadre?: any, context?: any, parentContext?: any) {

        let model = this.SplitPropertyToModel(parameter.PropertyDestination);
        if (parameter.ModelContainerDestination == "staticModel") {
            this.SetValueFromPropList(this, model.listProps, value);
        }
        else if (parameter.ModelContainerDestination == "staticDataModel") {
            this.SetValueFromPropList(StaticData, model.listProps, value);

        }
        else if (parameter.ModelContainerDestination == "modeloInterno") {
            this.SetValueFromPropList(this, model.listProps, value);

        }
        else if (parameter.ModelContainerDestination == "formModel" && form) {
            this.SetValueFromPropList(form.controls, model.listProps, value);


        }
        else if (parameter.ModelContainerDestination == "formInternalModel") {

            this.SetValueFromPropList(form, model.listProps, value);
        }
        else if (parameter.ModelContainerDestination == "modeloInterno") {

            this.SetValueFromPropList(context, model.listProps, value);
        }
        else if (parameter.ModelContainerDestination == "currentContext") {
            this.SetValueFromPropList(context.currentContext, model.listProps, value);

        }
        else if (parameter.ModelContainerDestination == "parentContext") {
            this.SetValueFromPropList(context.parentContext, model.listProps, value);

        }
        else {
            if (parameter.IsParentModelDestination) {

                if (!modeloPadre)
                    throw new Error("No se proporciono el Modelo Padre Para Extraccion de valor");
                else {
                    if (!parameter.ModelContainerDestination) {
                        if (model.listProps && model.listProps.length > 0)
                            this.SetValueFromPropList(modeloPadre, model.listProps, value);
                        else {
                            if (value)
                                modeloPadre = this.SetFieldsValue(modeloPadre, value);
                        }

                    }
                    else {
                        if (model.listProps && model.listProps.length > 0)
                            this.SetValueFromPropList(modeloPadre[parameter.ModelContainerDestination], model.listProps, value);
                        else {
                            if (value)
                                modeloPadre[parameter.ModelContainerDestination] = this.SetFieldsValue(modeloPadre[parameter.ModelContainerDestination], value);
                        }
                    }
                }
            }
            else {
                if (!parameter.ModelContainerDestination) {
                    if (model.listProps && model.listProps.length > 0)
                        this.SetValueFromPropList(modelo, model.listProps, value);
                    else {
                        if (value)
                            modelo = this.SetFieldsValue(modelo, value);
                    }
                }
                else {
                    if (model.listProps && model.listProps.length > 0)
                        this.SetValueFromPropList(modelo[parameter.ModelContainerDestination], model.listProps, value);
                    else {
                        if (value)
                            modelo[parameter.ModelContainerDestination] = this.SetFieldsValue(modelo[parameter.ModelContainerDestination], value);
                    }
                }
            }
        }

    }

    public SplitMultiple(str, ...tokens: string[]) {
        var tempChar = tokens[0]; // We can use the first token as a temporary join character
        for (var i = 1; i < tokens.length; i++) {
            str = str.split(tokens[i]).join(tempChar);
        }
        str = str.split(tempChar);
        return str;
    }
    public splitMulti(str, tokens: Array<string> = ['=', ',', ':', '|', ';']) {
        var tempChar = tokens[0]; // We can use the first token as a temporary join character
        for (var i = 1; i < tokens.length; i++) {
            str = str.split(tokens[i]).join(tempChar);
        }
        str = str.split(tempChar);
        return str;
    }


    public ToPascalCase(value: any) {
        if (value == null || value.length < 2)
            return value;
        const words = value.match(/[a-z]+/gi);
        if (!words) return "";
        return words
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
            })
            .join("");
    };
    public ToUpperCamelCase(value: any, removeBlank: any = true) {
        if (value == null || value.length < 2)
            return value;

        var words = value.split(" ");

        words = words.filter(function (str) {
            return /\S/.test(str);
        });


        let result = words[0].substring(0, 1).toUpperCase() + words[0].substring(1);// words[0].toLowerCase();
        if (removeBlank) {
            for (let i = 1; i < words.length; i++) {
                result +=
                    words[i].substring(0, 1).toUpperCase() +
                    words[i].substring(1);
            }

        }
        else {
            if (words.length > 1)
                result += ' ';
            for (let i = 1; i < words.length; i++) {
                result +=
                    words[i].substring(0, 1).toUpperCase() +
                    words[i].substring(1) + ' ';
            }

        }



        // if(removeBlank)
        // {
        //     result =  result.trim().replace(/\s+/g, "")
        // }
        return result.trim();

    }

    //     public Culturas:Array<any>=[

    // Colombia	es-CO
    // Costa Rica	es-CR
    // Croatia	hr-HR
    // Cyrillic, Azerbaijan	az-Cyrl-AZ
    // Cyrillic, Bosnia and Herzegovina	sr-Cyrl-BA
    // Cyrillic, Bosnia and Herzegovina	bs-Cyrl-BA
    // Cyrillic, Mongolia	mn-MN
    // Cyrillic, Montenegro	sr-Cyrl-ME
    // Cyrillic, Serbia	sr-Cyrl-RS
    // Cyrillic, Serbia and Montenegro (Former	sr-Cyrl-CS
    // Cyrillic, Tajikistan	tg-Cyrl-TJ
    // Cyrillic, Uzbekistan	uz-Cyrl-UZ
    // Czech Republic	cs-CZ
    // Denmark	da-DK
    // Dominican Republic	es-DO
    // Ecuador	quz-EC
    // Ecuador	es-EC
    // Egypt	ar-EG
    // El Salvador	es-SV
    // Estonia	et-EE
    // Ethiopia	am-ET
    // Faroe Islands	fo-FO
    // Finland	fi-FI
    // Finland	sv-FI
    // Finland	se-FI
    // Finland	sms-FI
    // Finland	smn-FI
    // Former Yugoslav Republic of Macedonia	mk-MK
    // France	fr-FR
    // France	br-FR
    // France	oc-FR
    // France	co-FR
    // France	gsw-FR
    // Galician	gl-ES
    // Georgia	ka-GE
    // Germany	de-DE
    // Germany	hsb-DE
    // Germany	dsb-DE
    // Greece	el-GR
    // Greenland	kl-GL
    // Guatemala	qut-GT
    // Guatemala	es-GT
    // Honduras	es-HN
    // Hungary	hu-HU
    // Iceland	is-IS
    // India	hi-IN
    // India	bn-IN
    // India	pa-IN
    // India	gu-IN
    // India	or-IN
    // India	ta-IN
    // India	te-IN
    // India	kn-IN
    // India	ml-IN
    // India	as-IN
    // India	mr-IN
    // India	sa-IN
    // India	kok-IN
    // India	en-IN
    // Indonesia	id-ID
    // Iran	fa-IR
    // Iraq	ar-IQ
    // Ireland	ga-IE
    // Ireland	en-IE
    // Islamic Republic of Pakistan	ur-PK
    // Israel	he-IL
    // Italy	it-IT
    // Jamaica	en-JM
    // Japan	ja-JP
    // Jordan	ar-JO
    // Kazakhstan	kk-KZ
    // Kenya	sw-KE
    // Korea	ko-KR
    // Kuwait	ar-KW
    // Kyrgyzstan	ky-KG
    // Lao P.D.R.	lo-LA
    // Latin, Algeria	tzm-Latn-DZ
    // Latin, Azerbaijan	az-Latn-AZ
    // Latin, Bosnia and Herzegovina	hr-BA
    // Latin, Bosnia and Herzegovina	bs-Latn-BA
    // Latin, Bosnia and Herzegovina	sr-Latn-BA
    // Latin, Canada	iu-Latn-CA
    // Latin, Montenegro	sr-Latn-ME
    // Latin, Nigeria	ha-Latn-NG
    // Latin, Serbia	sr-Latn-RS
    // Latin, Serbia and Montenegro (Former	sr-Latn-CS
    // Latin, Uzbekistan	uz-Latn-UZ
    // Latvia	lv-LV
    // Lebanon	ar-LB
    // Libya	ar-LY
    // Liechtenstein	de-LI
    // Lithuania	lt-LT
    // Luxembourg	lb-LU
    // Luxembourg	de-LU
    // Luxembourg	fr-LU


    //     ];
}
