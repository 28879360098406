import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  forwardRef
} from '@angular/core';
import { ConfigBaseTreeComponent, TiposCrearNodos } from '../../../models/config-base-tree-component';
import {
  ControllerMethods,
  CrudActions,
  Paginas,
  TipoObjetoParaPermisos,
  TipoParaPermisos,
  ValidateUserAndPagesTypes
} from '../../../models/general.enum';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Enumerable, List } from '../../../../assets/linqts/compilado/index';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { BaseBusqueda } from '../../../bussines/base-busqueda'
import { BaseServiceService } from '../../../services/base-service.service';
import { ComboBoxManager } from 'src/app/bussines/combo-box-manager';
import { ConfigWindow } from '../../../models/config-window';
import { DataTableComponent } from '../../controls/data-table/data-table.component';
import { DynamicControlsMetadataComponent } from '../../controls/dynamic-controls-metadata/dynamic-controls-metadata.component';
import { FormDetailsComponent } from '../../controls/form-details/form-details.component';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { JstreeComponent } from '../../jstree/jstree.component';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { OrgChartJsComponent } from '../../org-chart/org-chart-js/org-chart-js.component';
import PerfectScrollbar from 'perfect-scrollbar';
import { StaticData } from '../../../helpers/static-data';
import { Utilities } from '../../../helpers/utilities';
import value from '*html';

//import { DataTableVersionesCrudTrdComponent } from '../../expediente/data-table-versiones-crud-trd/data-table-versiones-crud-trd.component';
//import { DynamicControlsMetadataComponent } from '../../controls/dynamic-controls-metadata/dynamic-controls-metadata.component';

@Component({
  selector: 'app-busqueda-expediente',
  templateUrl: './busqueda-expediente.component.html',
  styleUrls: ['./busqueda-expediente.component.scss']
})
export class BusquedaExpedienteComponent extends GeneralFormComponent implements OnInit, OnChanges {
  //@ViewChild(DataTableExpedienteComponent, { static: true }) dataTableExpediente: DataTableExpedienteComponent;
  @ViewChild(JstreeComponent, { static: false }) jstreeComponent: JstreeComponent;
  //vistaExpediente: VistaExpedienteComponent;

  //dttTRD: DataTableVersionesCrudTrdComponent;
  public direction: string = 'horizontal';
  public directionTree: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'vertical';
  selectionModeAreas: boolean = true;


  // queryConfig = { Command: 'BusquedaExpedientes', IsSp: true, Entity: { IdEmpresa: -1 }, WithPagination: true };
  configBaseTreeComponent: ConfigBaseTreeComponent;
  orgChartJsComponent: OrgChartJsComponent;
  jsonProject: any;
  selectedNodes = new List<any>([]);
  notSelectablesNodesTypes = new List<any>([]);
  nodosAreas: Array<any> = [];
  nodosSeries: Array<any> = [];
  modeloTemp: any = null;
  itemsAreasEmpresa: Array<any> = [];
  itemsRoles: Array<any> = [];
  itemsSeries: Array<any> = [];
  itemsUsuarios: Array<any> = [];
  public sanitizer: DomSanitizer;
  imagenCambioDir: string;
  imagenEditorJson: string;
  verGrilla: boolean = true;
  sizeL: number = 10;
  sizeR: number = 90;
  displayTreeView: string = 'block';
  @Output() loadedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();
  isSearch: boolean = false;
  // @Input() public configPermisos: any;

  EstiloBuscarContenedor: any = { height: "1500px" };

  columns: Array<any> = [];
  startIndexColumn: number = 0;
  configJstree: any = { Label: 'Información', Name: 'Floatpanel-jstree-detalle-expediente', StartMinimized: true, MinimizeOnClose: true };

  DynamicMetadata: DynamicControlsMetadataComponent;
  filtroGeneral = "";

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules, public helperActions: HelperActions) {

    super(baseService, utility, helperImages, helperRules, helperActions);
    // super(baseService, utility, Paginas.BusquedaExpediente, 'TExpediente');

    this.pkName = 'IdExpediente';
    // this.imagenCambioDir = this.utility.GetPathImages(this.utility.GetNodeType('CambioDireccion'));
    // this.imagenEditorJson = this.utility.GetPathImages(this.utility.GetNodeType('EditorJson'));
    //this.Buscar();
    this.configBaseTreeComponent = new ConfigBaseTreeComponent(this.utility, this.baseService, this.sanitizer, helperImages);
    this.orgChartJsComponent = new OrgChartJsComponent(this.utility, this.baseService, this.sanitizer, helperImages);
    this.notSelectablesNodesTypes.Add('Series');
    this.notSelectablesNodesTypes.Add('SubSeries');
    this.notSelectablesNodesTypes.Add('AreasEmpresa');

    this.utility.SetItemsArea(this);
  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  public OnInitEnd() {
    //MP 0005357: Seguridad de los expedientes creados (producción)
    // StaticData.Usuario.TodosPermisosDocumentosExpedientes = false;
    // StaticData.Usuario.TodosPermisosExpedientes = false;
    //MP
    if (this.utility.ReturnParentDetailsItem)
      this.utility.ReturnParentDetailsItem = null;
    // this.expediente = null;
    this.permissionsConfig = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Expediente,
      Pagina: this.Pagina, clear: true, CreatedRowConfig: {}
    };
    this.loadedComboBoxEmitter.subscribe(modelo => {

      if (this.modeloTemp && this.modeloTemp.IdSubSerie)
        this.modelo.modelo.IdSubSerie = this.utility.Clone(this.modeloTemp.IdSubSerie);
    });
    this.configs.ConfigIdSubSerie.onLoadedEmitter = this.loadedComboBoxEmitter;
  }

  // ngAfterViewInit() {
  // }


  public OnActionEventHandler(event) {
    if (event.Action == 'SetLogicalOp' || event.Action == 'SetOp') {

      this.filtroGeneral = this.SetModelo().Filtro;

    }
    else if (event.Name == 'btnBuscar') {
      this.Buscar(event.Name);
    }
    else if (event.Name == 'BtnLimpiar') {
      // Ajuste para que limpie cualquier control en el modelo modelo
      // Edwin Alonso 04062021
      this.controls.ForEach(control => {
        if (control.control && control.control.ModelContainer == "modelo")
          this.modelo.modelo[control.control.Name] = null;
      }
      );
      this.modelo.modelo.FiltroGeneral = "";
      this.filtroGeneral = "";
      this.ClearControlsMetadatos();
      this.SetModelToControls();
      this.Buscar(event.Name);
    }
    else if (event.Name == 'btnCambioVista') {

      // this.CambioVista();
    }
    else if (event.Option && (event.Option.Label == "Marcar como favorito" || event.Option.Label == "Desmarcar como favorito")) {
      this.modelo.modelo.PkValue = this.modelo.expediente.PkValue;
      super.OnActionEventHandler(event);
    }
    else if (event.Option && event.Option.Type == "Print") {

      var isSearch = 0;
      var valueSearchGrid = document.getElementsByClassName("ms-SearchBox-field")[1];

      if (valueSearchGrid)
        isSearch = ($(valueSearchGrid).prop("value").trim().length > 0) ? 1 : 0


      var url = 'http://AdminSQL:d%Q5kh$n@Ug1@reportservice-ramajudicial.eastus.cloudapp.azure.com/Reports/report/Reportes%20BestDocs/Expedientes/RPT_PrintExpediente?IdUsuario=' + StaticData.Usuario.IdUsuario + '&SearchValue=' + isSearch + '&rs:embed=true';

      window.open(
        url, "_blank");

      return;

    }
    else {
      super.OnActionEventHandler(event);
    }

  }
  //public OnDataTableEditHandler(event) {

    // this.SetModelToControls();
    // this.SetProcesando(false);
    // this.EditCascading();
    // this.parentClearCall.next(this.modelo);

    // this.BuscarVersionTRD(true);
    // this.CrearNodosdatosJsTree(false);
    // this.SetModeloPermisos(true);

    // this.ValidarGuardarValido();
    //debugger
  //}

  public OnSelectionHandler(event) {


  }
  /*
  public BuscarVersionTRD(esEdicion: boolean) {

    this.dttTRD.BuscarVersionTRD(esEdicion);


  }*/

  public OnLoadGridHandler(event) {

    this.FinConsultaGrilla(event);

  }
  public OnSetConfigHandler(event) {

    //
    this.SetColumnsHandler(event);
  }

  public AddComponentInstanceHandler(event) {

    //
    if (event instanceof DataTableComponent) {
      this.componentsContainer.Tables.push(event);

      // if(event.name){
      //   event.QueryConfig.Entity.IdContacto= StaticData.Usuario.IdUsuario
      //   event.ReCargarGrilla();
      // }

    }
    else if (event instanceof DynamicControlsMetadataComponent) {

      this.DynamicMetadata = event;
      // this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;
      this.DynamicMetadata.form = this.form;
      this.AddComponentInstanceHandlerInGeneral(event);

    }
    // else if (event instanceof VistaExpedienteComponent) {
    //   //
    //   this.vistaExpediente = event;
    //   this.vistaExpediente.prop = "Nombre";
    //   this.vistaExpediente.configPermisos = this.permissionsConfig;
    //   this.vistaExpediente.columns = this.columns;
    //   this.vistaExpediente.startIndexColumn = this.startIndexColumn;
    //   this.vistaExpediente.onPagedEmitter.subscribe(event => {
    //     this.PageDataTableHandler(event);
    //   })
    //   this.vistaExpediente.onSearchEmitter.subscribe(event => {
    //     this.SerachDataTableHandler(event);
    //   })

    //   this.vistaExpediente.onOrderEmitter.subscribe(event => {
    //     this.OrderDataTableHandler(event);
    //   })
    //   this.vistaExpediente.onClickEmitter.subscribe(event => {
    //     this.SeleccionExpediente(event);
    //   })
    // }
    else {
      super.AddComponentInstanceHandler(event);
    }
  }

  /*   public CambioVista() {
      this.componentsContainer.Tables[0].notView = this.verGrilla;
      this.vistaExpediente.view = this.verGrilla;
      this.verGrilla = !this.verGrilla;
    } */
  public CrearNodosAreas() {
    this.nodosAreas = [];
    this.utility.ConvertNodesJstreeToOrgChart(this.jsonProject.children[0], this.nodosAreas, true);

    //this.orgChartJsComponent.CreateNodeTree(this.nodosAreas, this.itemsAreasEmpresa, 'AreasEmpresa', this.orgChartJsComponent, TiposCrearNodos.Recursiva);

  }
  public CrearNodosSeries() {
    this.nodosSeries = [];
    this.utility.ConvertNodesJstreeToOrgChart(this.jsonProject.children[0], this.nodosSeries, true);

  }
  public FinConsultaGrilla(event: any) {
    this.SetProcesando(false);

    if (this.isSearch) {
      this.utility.VerModalOk("Búsqueda Finalizada");
      this.isSearch = false;
    }

    // this.CrearNodosJsTree(event.data);
    //this.vistaExpediente.SetItems(event.data);
  }

  public SeleccionExpediente(event) {

    this.componentsContainer.Tables[0].SeleccionarItemPorItem(event.item, event.index, event.item.IdExpediente);
  }

  public PageDataTableHandler(event) {


    this.componentsContainer.Tables[0].PageDataTable(event.pageSize);
  }
  public SerachDataTableHandler(event) {

    this.componentsContainer.Tables[0].SearchDataTable(event.searchText);
  }
  public SetColumnsHandler(event) {
    this.columns = event.columns;
    this.startIndexColumn = event.startIndexColumn;
  }
  public OrderDataTableHandler(event) {


    this.componentsContainer.Tables[0].OrderDataTable(event.order);
  }
  public CreateNodoAgrupadoPorEnum(items: Array<any>, tipoParaPermisos: TipoParaPermisos, root: any, extraType: any) {

    if (!this.jstreeComponent)
      return;
    let tipo: string;

    let tipoInterno: string;
    let titulo: string;
    let campo: string;
    if (tipoParaPermisos == TipoParaPermisos.AreaEmpresa) {
      tipo = "AreasEmpresa";
      tipoInterno = "AreaEmpresa";
      titulo = 'Por Area';
      campo = "IdAreaEmpresa"
    }
    else if (tipoParaPermisos == TipoParaPermisos.Usuario) {
      tipo = "Responsables";
      tipoInterno = "Usuario";
      titulo = 'Por Responsable';
      campo = "IdUsuarioResponsable"
    }
    else if (extraType == "IdEstado") {
      tipo = "EstadosSolicitud";
      tipoInterno = "Usuario";
      titulo = 'Por Estado';
      campo = extraType
    }
    let nodoExpedinetesPor = this.jstreeComponent.GetNodeByTypeFromTypes(tipo, null, titulo, root.id);
    let itemsGroupByList = new List<any>(items).GroupBy(x => { return x[campo] });
    $.each(itemsGroupByList, (group, itemsGroup) => {


      let objeto: any = null;
      let nodoObjeto: any = null;
      let nombreObjeto: any = null;
      if (!group) {

        if (extraType) {
          objeto = { Nombre: 'Estado no Asignado' };
          tipoInterno = "EstadoNoAsignado";
          titulo = 'Por Estados no Asignados';
          nombreObjeto = "Estado no Asignado";
        }
        else {
          if (tipoParaPermisos == TipoParaPermisos.Usuario)
            nombreObjeto = "Resposanable no Asignado";
          else
            nombreObjeto = "Area Empresa no Asignada";
        }
      }
      else {
        if (extraType) {
          objeto = this.utility.GetNodeTypes().FirstOrDefault(x => {
            return x.FilterField &&
              x.FilterField == extraType &&
              x.Id.toString() == group.toString()
          });
          if (objeto) {
            tipoInterno = objeto.ObjType;
            titulo = 'Por ' + objeto.Title;
            objeto.Nombre = 'Por ' + objeto.Title;
          }
          else {

            tipoInterno = "EstadoAbierto";
            titulo = 'Por ' + extraType + ":" + group.toString();
            objeto = { Nombre: titulo };
          }
        }
        else
          objeto = StaticData.RolesUsuariosGrupos.FirstOrDefault(x => { return x.Id == group && x.Tipo == tipoParaPermisos });


        nombreObjeto = (objeto) ? objeto.Nombre : group.toString();
        if (tipoParaPermisos == TipoParaPermisos.Usuario) {

          nombreObjeto += ' [' + objeto.Role + '][' + objeto.AreaEmpresa + ']'
        }
      }
      nodoObjeto = this.jstreeComponent.GetNodeByTypeFromTypes(tipoInterno, null, nombreObjeto + ' [' + itemsGroup.length.toString() + ']', nodoExpedinetesPor.id);

      $.each(itemsGroup, (indexExp, itemExp) => {

        let nodeExpedineteHijo = this.jstreeComponent.GetNodeByTypeFromTypes('Expediente', null, itemExp.Nombre, nodoObjeto.id);
        nodoObjeto.children.push(nodeExpedineteHijo);
      });
      nodoExpedinetesPor.children.push(nodoObjeto);
    });

    nodoExpedinetesPor.text = nodoExpedinetesPor.text + ' [' + nodoExpedinetesPor.children.length.toString() + ']';

    return nodoExpedinetesPor;
  }
  public CrearNodosJsTree(items: Array<any>) {

    if (!this.jstreeComponent)
      return;

    const that = this;
    if (StaticData.Usuario) {
      let label = "Expedientes";
      let js = this.Pagina.ConfigPage.Controls.find(x => { return x.ControlType == 'TreeView' });
      if (js)
        label = js.label;
      let root = this.jstreeComponent.GetNodeByTypeFromTypes('Expedientes', null, label);//this.jstreeComponent.GetNode('Root');


      let nodoExpedinetes = this.jstreeComponent.GetNodeByTypeFromTypes('Expedientes', null, 'Expedientes', root.id);
      //let nodoUsuario = this.jstreeComponent.GetNodeByTypeFromTypes('Usuario', null, 'Por Usuario', nodoResponsables.id);
      //let nodoRole = this.jstreeComponent.GetNodeByTypeFromTypes('Role', null, 'Por Role', nodoResponsables.id);
      //let nodoEmpresa = this.jstreeComponent.GetNodeByTypeFromTypes('AreaEmpresa', null, 'Por Area Empresa', nodoResponsables.id);

      $.each(items, (index, item) => {

        let expedinete = that.jstreeComponent.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoExpedinetes.id);
        //let nodoPermisos = that.jstreeComponent.GetNodeByTypeFromTypes('Permisos');
        // let nodoResponsablesExp = that.jstreeComponent.GetNodeByTypeFromTypes('Responsables', null, 'Responsable', nodoExpedinetes.id);
        let nodoUsuarioResp = null;
        if (item.IdUsuarioResponsable) {
          let usu = StaticData.RolesUsuariosGrupos.FirstOrDefault(x => { return x.Id === item.IdUsuarioResponsable && x.Tipo == TipoParaPermisos.Usuario });
          if (usu) {
            nodoUsuarioResp = that.jstreeComponent.GetNodeByTypeFromTypes('Usuario', null, usu.Nombre, expedinete.id);
            expedinete.children.push(nodoUsuarioResp);
            // nodoResponsablesExp.children.push(nodoUsuarioResp);
          }
        }
        // if (item.IdRoleResponsable) {
        //   let role = StaticData.RolesUsuariosGrupos.FirstOrDefault(x => { return x.Id === item.IdRoleResponsable && x.Tipo == TipoParaPermisos.Role });
        //   if (role) {
        //     let nodoRoleResp = that.jstreeComponent.GetNodeByTypeFromTypes('Role', null, role.Nombre, nodoResponsablesExp.id);
        //     nodoResponsablesExp.children.push(nodoRoleResp);
        //   }
        // }
        //let nodoEmpresaResp = this.jstreeComponent.GetNodeByTypeFromTypes('AreaEmpresa', null, 'Por Area Empresa', nodoResponsablesExp.id);

        //that.jstreeComponent.CrearPermisos(nodoPermisos, item, 'Configuracion', false);
        //nodoPermisos.parentNodeId = -expedinete.id;

        // expedinete.children.push(nodoResponsablesExp);
        //expedinete.children.push(nodoPermisos);

        nodoExpedinetes.children.push(expedinete);


        // if (item.IdUsuarioResponsable === StaticData.Usuario.IdUsuario) {

        //   nodoUsuario.children.push(that.jstreeComponent.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoUsuario.id));
        // }

        // if (item.IdRoleResponsable === StaticData.Usuario.IdRole) {

        //   nodoRole.children.push(that.jstreeComponent.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoRole.id));
        // }
        // if (item.IdAreaEmpresaResponsable === StaticData.Usuario.IdVersionAreaEmpresa) {

        //   nodoEmpresa.children.push(that.jstreeComponent.GetNodeByTypeFromTypes('Expediente', null, item.Nombre, nodoEmpresa.id));
        // }
      });

      nodoExpedinetes.text = nodoExpedinetes.text + ' [' + nodoExpedinetes.children.length.toString() + ']';


      // nodoUsuario.text = nodoUsuario.text + ' [' + nodoUsuario.children.length.toString() + ']';
      // nodoRole.text = nodoRole.text + ' [' + nodoRole.children.length.toString() + ']';
      // nodoEmpresa.text = nodoEmpresa.text + ' [' + nodoEmpresa.children.length.toString() + ']';

      // nodoResponsables.children.push(nodoUsuario);
      // nodoResponsables.children.push(nodoRole);
      // nodoResponsables.children.push(nodoEmpresa);



      // let nodoExpedinetesPorAreas = this.jstreeComponent.GetNodeByTypeFromTypes('AreasEmpresa', null, 'Por Area', root.id);
      // let itemsGroupByList = new List<any>(items).GroupBy(x => { return x.IdAreaEmpresa });
      // $.each(itemsGroupByList, (group, itemsGroup) => {

      //
      //   let area = StaticData.RolesUsuariosGrupos.FirstOrDefault(x => { return x.Id == group && x.Tipo == TipoParaPermisos.AreaEmpresa });
      //   let nombreArea = (area) ? area.Nombre : group.toString();
      //   let nodoAreaEmpresa = that.jstreeComponent.GetNodeByTypeFromTypes('AreaEmpresa', null, nombreArea + ' [' + itemsGroup.length.toString() + ']', nodoExpedinetesPorAreas.id);

      //   $.each(itemsGroup, (indexExp, itemExp) => {

      //     let nodeExpedineteArea = that.jstreeComponent.GetNodeByTypeFromTypes('Expediente', null, itemExp.Nombre, nodoAreaEmpresa.id);
      //     nodoAreaEmpresa.children.push(nodeExpedineteArea);
      //   });
      //   nodoExpedinetesPorAreas.children.push(nodoAreaEmpresa);
      // });

      // nodoExpedinetesPorAreas.text = nodoExpedinetesPorAreas.text + ' [' + nodoExpedinetesPorAreas.children.length.toString() + ']';

      let nodoExpedinetesPorAreas = this.CreateNodoAgrupadoPorEnum(items, TipoParaPermisos.AreaEmpresa, root, null);
      let nodoResponsables = this.CreateNodoAgrupadoPorEnum(items, TipoParaPermisos.Usuario, root, null);//this.jstreeComponent.GetNodeByTypeFromTypes('Responsables', null, 'Por Resposanable', root.id);

      let nodoEstados = this.CreateNodoAgrupadoPorEnum(items, undefined, root, "IdEstado");




      // const distinctIdsAreas = items.filter(
      //   (item, i, arr) => arr.findIndex(t => t.IdAreaEmpresa === item.IdAreaEmpresa) === i
      // );

      //let areasEmpresa: Array<any> = StaticData.RolesUsuariosGrupos.Where(x => { return  x.Tipo == TipoParaPermisos.AreaEmpresa &&  });
      this.jstreeComponent.CreateNodeTree(root, StaticData.AreasEmpresa.ToArray(), 'AreasEmpresa', this.jstreeComponent, TiposCrearNodos.Recursiva, null,
        null, null,
        (nodeTypeData, item, context, node, childrenItems) => {

          const itemsExpedientes = items.filter(x => { return x.IdAreaEmpresa == item.IdAreaEmpresa });
          itemsExpedientes.forEach(area => {
            const nodeExpedineteArea = that.jstreeComponent.GetNodeByTypeFromTypes('Expediente', null, area.Nombre, node.id);
            node.children.push(nodeExpedineteArea);
          })

          node.text = node.text + ' [' + item.IdAreaEmpresa + '][' + itemsExpedientes.length.toString() + ']';
          if (itemsExpedientes.length > 0 || (node.children.length > 0))
            return true;
          else
            return false;
        });



      root.children.push(nodoExpedinetesPorAreas);
      root.children.push(nodoResponsables);
      root.children.push(nodoEstados);



      this.jstreeComponent.jsonData = [root];
      this.jstreeComponent.InitTree();
    }

  }
  public Buscar(boton) {

    try {

      this.SetProcesando(true);
      this.componentsContainer.Tables[0].QueryConfig.Entity = this.SetModelo();
      this.componentsContainer.Tables[0].cargarGrilla = true;
      this.componentsContainer.Tables[0]._CargarGrilla(true);
      //if(boton == 'btnBuscar')
      this.isSearch = true;
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }

  /*   public SetValorPropiedadBusquedaIn(cloneModelo: any, modelo: any, propiedad: string, inPropiedad: string) {
      if (cloneModelo[propiedad]) {
        if (cloneModelo[propiedad].length == 1) {
          cloneModelo[propiedad] = modelo[propiedad][0];
        }
        else if (cloneModelo[propiedad].length > 0) {
          cloneModelo[inPropiedad] = modelo[propiedad].join(',');
          cloneModelo[propiedad] = null;
        }
        else
          cloneModelo[propiedad] = null;
      }
    }
    public SetValorPropiedadBusquedaContains(cloneModelo: any, propiedad: string) {
      if (cloneModelo[propiedad] && cloneModelo[propiedad].split(',').length > 0) {
        cloneModelo[propiedad] = cloneModelo[propiedad].split(',').join(' OR ');
      }
    } */
  // { Name: 'Equal', Label: 'Igual A' },
  // { Name: 'NotEqual', Label: 'No Igual A' },
  // { Name: 'In', Label: 'Esta en la Lista' },
  // { Name: 'NotIn', Label: 'No esta en la Lista' },
  // { Name: 'Contains', Label: 'Contiene' },
  // { Name: 'NotContains', Label: 'No Contiene' },
  // { Name: 'StartsWith', Label: 'Inicia Con' },
  // { Name: 'EndsWith', Label: 'Finaliza Con' },
  // { Name: 'NotStartsWith', Label: 'No Incial Con' },
  // { Name: 'NotEndsWith', Label: 'No Finaliza Con' },
  // { Name: 'GreaterThan', Label: 'Mayor Que' },
  // { Name: 'LessThan', Label: 'Menor Que' },
  // { Name: 'GreaterOrEqual', Label: 'Mayor o Igual A' },
  // { Name: 'LessOrEqual', Label: 'Menor o Igual A' },
  // { Name: 'IsNull', Label: 'Es Nulo' },
  // { Name: 'IsNotNull', Label: 'No Es Nulo' },
  // { Name: 'IsUndefined', Label: 'Es Indefinido' },
  // { Name: 'IsNotUndefined', Label: 'No Es Indefinido' },
  // { Name: 'Range', Label: 'Rango' }];
  IsNullableOp(opName) {
    return (opName == 'IsNull' || opName == 'IsNotNull' || opName == 'IsUndefined' || opName == 'IsNotUndefined'
      || opName == 'IsEmpty' || opName == 'IsNotEmpty');

  }
  GetOp(opName) {
    if (opName == 'Equal')
      return "=";
    else if (opName == 'NotEqual')
      return "<>";
    else if (opName == 'GreaterThan')
      return ">";
    else if (opName == 'LessThan')
      return "<"
    else if (opName == 'GreaterOrEqual')
      return ">=";
    else if (opName == 'LessOrEqual')
      return "<="
  }
  GetOpTexto(opName) {
    if (opName == 'Equal')
      return "ES IGUAL";
    else if (opName == 'NotEqual')
      return "NO ES IGUAL";
    else if (opName == 'GreaterThan')
      return "MAYOR QUE";
    else if (opName == 'LessThan')
      return "MENOR QUE"
    else if (opName == 'GreaterOrEqual')
      return "MAYOR IGUAL QUE";
    else if (opName == 'LessOrEqual')
      return "MENOR IGUAL QUE"
  }
  public GetFiltro(control) {
    //debugger;
    let filtro = "";
    let filtroGeneral = "";
    //" TRegistroVersionSubSeriePaginaMetadatosDetalle.TipoDato  = '" + element.Name + "' AND ";


    var datatemp = '';
    var dateArr = [];
    if (control.Op == "In" || control.Op == "Range") {

      switch (control.DataType) {
        case 'String':
          control.Value.forEach(_value => {
            dateArr.push("'" + _value + "'");
          });

          datatemp = dateArr.join(',');
          filtro += control.SearcheableFieldName + " IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + datatemp + ")";
          break
        case 'DateTime':
          control.Value.forEach(_value => {
            dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
          });

          datatemp = dateArr.join(',');
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + " IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + datatemp + ")";
          //str += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.htmlEntities(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          break;

        default:
          filtro += control.SearcheableFieldName + " IN  (" + control.Value + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + control.Value + ")";
          break


      }

    }
    else if (control.Op == "NotIn") {

      switch (control.DataType) {
        case 'String':
          control.Value.forEach(_value => {
            dateArr.push("'" + _value + "'");
          });

          datatemp = dateArr.join(',');
          filtro += control.SearcheableFieldName + " NOT IN   (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + datatemp + ")";
          break
        case 'DateTime':
          control.Value.forEach(_value => {
            dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
          });

          datatemp = dateArr.join(',');
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + " NOT IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + datatemp + ")";
          //str += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.htmlEntities(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          break;

        default:
          filtro += control.SearcheableFieldName + "NOT IN  (" + control.Value + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + control.Value + ")";
          break


      }
      //str += control.SearcheableFieldName + " NOT IN (" + control.Value + ")";
    }
    else if (control.Op == 'ContainsText') {
      filtro += " Contains(" + control.SearcheableFieldName + " , '" + control.Value + "')";
      filtroGeneral += control.Label + " <strong> CONTIENE TEXTO </strong>  (" + control.Value + ")";
    }
    else if (control.Name == "IdTesauro" && control.ControlType == "MultiComboBox") {
      control.Value.forEach(_value => {
        filtro += control.SearcheableFieldName + " LIKE  '%" + _value + "%'" + " OR ";
        filtroGeneral += control.Label + " <strong> CONTIENE </strong>  (" + _value + ")" + " O ";
      });
      filtro = filtro.substr(0, filtro.length - 4);
      filtroGeneral = filtroGeneral.substr(0, filtroGeneral.length - 3);

    }
    else if (control.Op == "Contains" || control.ControlType == "TextArea") {
      filtro += control.SearcheableFieldName + " LIKE  '%" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> CONTIENE </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotContains") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '%" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> NO CONTIENE  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "StartsWith") {
      filtro += control.SearcheableFieldName + " LIKE  '" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> INICIA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotStartsWith") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> NO INICIA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "EndsWith") {
      filtro += control.SearcheableFieldName + " LIKE  '%" + control.Value + "'";
      filtroGeneral += control.Label + " <strong> FINALIZA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotEndsWith") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '%" + control.Value + "'";
      filtroGeneral += control.Label + " <strong> NO FINALIZA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "IsNull" || control.Op == "IsUndefined") {
      filtro += control.SearcheableFieldName + " IS NULL  ";
      filtroGeneral += control.Label + " <strong> ES NULO  </strong>";
    }
    else if (control.Op == "IsNotNull" || control.Op == "IsNotUndefined") {
      filtro += control.SearcheableFieldName + " NOT IS NULL  ";
      filtroGeneral += control.Label + " <strong> NO ES NULO  </strong>";
    }
    else if (control.Op == "IsEmpty") {
      filtro += control.SearcheableFieldName + " =  '' ";
      filtroGeneral += control.SearcheableFieldName + " <strong> ES VACIO  </strong>";
    }
    else if (control.Op == "IsNotEmpty") {
      filtro += control.SearcheableFieldName + " <>  '' ";
      filtroGeneral += control.Label + " <strong> NO ES VACIO  </strong>";
    }
    ///    str += control.SearcheableFieldName.Name + " LIKE '%" + group.rules[i].data + "%'";
    else {
      switch (control.DataType) {
        case 'String':
          filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " '" + control.Value + "' ";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          break
        case 'DateTime':
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.GetOp(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          break;
        case 'Boolean':
          let boolValue = (control.Value == "true" || control.Value == true) ? 1 : 0;
          filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " " + boolValue + " ";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          //filter += " CONVERT(DATETIME,'" + value + "',103)";
          /*     if (value == "true")
                this.filter += " " + control.Name + " = 1";
              else
                this.filter += " " + control.Name + " = 0"; */
          break;
        default:
          if (Array.isArray(control.Value) && control.Op === "Equal") {
            filtroGeneral +=
              control.Label +
              " ( <strong> " +
              this.GetOpTexto(control.Op) +
              "  </strong>  ";

            filtro += " ( "
            control.Value.forEach((element, index) => {
              filtro +=
                control.SearcheableFieldName +
                " " +
                this.GetOp(control.Op) +
                " " +
                element +
                " ";
              filtroGeneral += `'${element}'`
              if (index + 1 != control.Value.length) {
                filtro += " OR "
                filtroGeneral +=
                  " O " +
                  " <strong> " +
                  this.GetOpTexto(control.Op) +
                  "  </strong>  ";
              }
            });
            filtro += " ) ";
            filtroGeneral += ")"
          }
          else {
            filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " " + control.Value + " ";
            filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          }
          break


      }
    }

    return { filtroGeneral: filtroGeneral, filtro: filtro };
  }

  public SetModelo() {
    this.modelo.modelo.FiltroGeneral = '';
    let clone = this.utility.Clone(this.modelo.modelo);
    let filtro = "";
    let filtroGeneral = "";
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdAreaEmpresa', 'AreaEmpresa');
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdSerie', 'Serie');
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdSubSerie', 'SubSerie');
    //this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdTesauro', 'PalabrasNormalizadas');
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdUsuarioResponsable', 'UsuarioResponsable');

    if (this.SearcheableConfigs && this.SearcheableConfigs.length > 0) {


      //let IdAreaEmpresa = this.SearcheableConfigs.find(x => x.Name == 'IdAreaEmpresa');
      // if (!IdAreaEmpresa) {
      //   IdAreaEmpresa = {
      //     Name: 'IdAreaEmpresa', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'IdAreaEmpresa',
      //     DataType: 'Int32', ControlType: 'NumberBox', Value: clone.IdAreaEmpresa
      //   };
      // }
      //let IdSerie = this.SearcheableConfigs.find(x => x.Name == 'IdSerie');
      // if (!IdSerie) {
      //   IdSerie = {
      //     Name: 'IdSerie', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'IdSerie',
      //     DataType: 'Int32', ControlType: 'NumberBox', Value: clone.IdSerie
      //   };
      // }
      //let IdSubSerie = this.SearcheableConfigs.find(x => x.Name == 'IdSubSerie');
      // if (!IdSubSerie) {
      //   IdSubSerie = {
      //     Name: 'IdSubSerie', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'IdSubSerie',
      //     DataType: 'Int32', ControlType: 'NumberBox', Value: clone.IdSerie
      //   };
      // }
      //let IdUsuarioResponsable = this.SearcheableConfigs.find(x => x.Name == 'IdUsuarioResponsable');
      // if (!IdUsuarioResponsable) {
      //   IdUsuarioResponsable = {
      //     Name: 'IdUsuarioResponsable', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'IdUsuarioResponsable',
      //     DataType: 'Int32', ControlType: 'NumberBox', Value: clone.IdSerie
      //   };
      // }
      //let Codigo = this.SearcheableConfigs.find(x => x.Name == 'Codigo');
      // if (!Codigo) {
      //   Codigo = {
      //     Name: 'Codigo', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'Codigo',
      //     DataType: 'String', ControlType: 'TextBox', Value: clone.Codigo
      //   };
      // }
      //let Nombre = this.SearcheableConfigs.find(x => x.Name == 'Nombre');
      // if (!Nombre) {
      //   Nombre = {
      //     Name: 'Nombre', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'Nombre',
      //     DataType: 'String', ControlType: 'TextBox', Value: clone.Nombre
      //   };
      // }
      //let PalabrasClave = this.SearcheableConfigs.find(x => x.Name == 'PalabrasClave');
      // if (!PalabrasClave) {
      //   PalabrasClave = {
      //     Name: 'PalabrasClave', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'PalabrasClave',
      //     DataType: 'String', ControlType: 'TextBox', Value: clone.PalabrasClave
      //   };
      // }
      //let FechaCreacionInicial = this.SearcheableConfigs.find(x => x.Name == 'FechaCreacionInicial');
      // if (!FechaCreacionInicial) {
      //   FechaCreacionInicial = {
      //     Name: 'FechaCreacionInicial', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'FechaCreacionInicial',
      //     DataType: 'DateTime', ControlType: 'DateBox', Value: clone.FechaCreacionInicial
      //   };
      // }
      //let FechaCreacionFinal = this.SearcheableConfigs.find(x => x.Name == 'FechaCreacionFinal');
      // if (!FechaCreacionFinal) {
      //   FechaCreacionFinal = {
      //     Name: 'FechaCreacionFinal', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'FechaCreacionFinal',
      //     DataType: 'DateTime', ControlType: 'DateBox', Value: clone.FechaCreacionFinal
      //   };
      // }
      //let FechaFinalInicial = this.SearcheableConfigs.find(x => x.Name == 'FechaFinalInicial');
      // if (!FechaFinalInicial) {
      //   FechaFinalInicial = {
      //     Name: 'FechaFinalInicial', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'FechaFinalInicial',
      //     DataType: 'DateTime', ControlType: 'DateBox', Value: clone.FechaFinalInicial
      //   };
      // }
      //let FechaFinalFinal = this.SearcheableConfigs.find(x => x.Name == 'FechaFinalFinal');
      // if (!FechaFinalFinal) {
      //   FechaFinalFinal = {
      //     Name: 'FechaFinalFinal', Op: "Equal", LogicOp: 'And', SearcheableFieldName: 'FechaFinalFinal',
      //     DataType: 'DateTime', ControlType: 'DateBox', Value: clone.FechaFinalFinal
      //   };
      // }

      //clone.Filtro = "";
      let i: number = 0;
      let logicalOp = "";
      let logicalOp_ = "";
      $.each(this.modelo.modelo, (key, value: any) => {




        const config = this.SearcheableConfigs.find(x => x.Name == key);

        if (config) {


          if (this.modelo.modelo[key] || this.IsNullableOp(config.Op)) {

            if (i > 0 && filtro) {
              filtro += " " + logicalOp + " ";
              filtroGeneral += " " + logicalOp_ + " ";
            }


            config.Value = this.modelo.modelo[key];
            const filter = this.GetFiltro(config);
            filtro += filter.filtro;
            filtroGeneral += filter.filtroGeneral;

            logicalOp = ((config.LogicOp == "Or") ? "OR" : "AND");
            logicalOp_ = ((config.LogicOp == "Or") ? "<strong>  O  </strong>" : "<strong>  Y  </strong>");
          }

          clone[key] = undefined;
          if (key == 'IdAreaEmpresa')
            clone.AreaEmpresa = undefined;
          else if (key == 'IdSerie')
            clone.Serie = undefined;
          else if (key == 'IdSubSerie')
            clone.SubSerie = undefined;
          else if (key == 'IdUsuarioResponsable')
            clone.UsuarioResponsable = undefined;

        }
        else {
          //logicalOp = "AND";
        }

        i++;
      });
      clone.Filtro = filtro;
      this.modelo.modelo.FiltroGeneral = filtroGeneral;

      this.modelo.modelo.Filter = filtro;
      // if (IdAreaEmpresa) {

      //   if (clone.IdAreaEmpresa || this.IsNullableOp(IdAreaEmpresa.Op)) {

      //     IdAreaEmpresa.Value = clone.IdAreaEmpresa;
      //     clone.Filtro += this.GetFiltro(IdAreaEmpresa) + " " + (IdAreaEmpresa.LogicOp == "Or") ? "OR" : "AND";
      //   }

      //   clone.IdAreaEmpresa = undefined;
      //   clone.AreaEmpresa = undefined;
      // }
      // if (IdSerie) {

      //   if (clone.IdSerie || this.IsNullableOp(IdSerie.Op)) {
      //     IdSerie.Value = clone.IdSerie;
      //     clone.Filtro += this.GetFiltro(IdSerie) + " " + (IdSerie.LogicOp == "Or") ? "OR" : "AND";
      //   }

      //   clone.IdSerie = undefined;
      //   clone.Serie = undefined;
      // }
      // if (IdSubSerie) {

      //   if (clone.IdSubSerie || this.IsNullableOp(IdSubSerie.Op)) {

      //     IdSubSerie.Value = clone.IdSubSerie;
      //     clone.Filtro += this.GetFiltro(IdSubSerie) + " " + (IdSubSerie.LogicOp == "Or") ? "OR" : "AND";
      //   }

      //   clone.IdSubSerie = undefined;
      //   clone.SubSerie = undefined;
      // }
      // if (IdUsuarioResponsable) {

      //   if (clone.IdUsuarioResponsable || this.IsNullableOp(IdUsuarioResponsable.Op)) {

      //     IdUsuarioResponsable.Value = clone.IdUsuarioResponsable;
      //     clone.Filtro += this.GetFiltro(IdUsuarioResponsable) + " " + (IdUsuarioResponsable.LogicOp == "Or") ? "OR" : "AND";
      //   }

      //   clone.IdUsuarioResponsable = undefined;
      //   clone.UsuarioResponsable = undefined;
      // }
      // if (Codigo) {
      //   if (clone.Codigo || this.IsNullableOp(Codigo.Op)) {

      //     Codigo.Value = clone.Codigo;
      //     clone.Filtro += this.GetFiltro(Codigo) + "  " + ((Codigo.LogicOp == "Or") ? "OR " : "AND ");
      //   }
      //   clone.Codigo = undefined;
      // }
      // if (Nombre) {
      //   if (clone.Nombre || this.IsNullableOp(Codigo.Nombre)) {

      //     Nombre.Value = clone.Nombre;

      //     clone.Filtro += this.GetFiltro(Nombre) + "  " + ((Nombre.LogicOp == "Or") ? "OR " : "AND ");
      //   }
      //   clone.Nombre = undefined;
      // }
      // if (PalabrasClave) {
      //   if (clone.PalabrasClave || this.IsNullableOp(PalabrasClave.Op)) {

      //     PalabrasClave.Value = clone.PalabrasClave;
      //     clone.Filtro += this.GetFiltro(PalabrasClave) + " " + ((PalabrasClave.LogicOp == "Or") ? "OR " : "AND ");
      //   }
      //   clone.PalabrasClave = undefined;
      // }
      // if (FechaCreacionInicial) {
      //   if (clone.FechaCreacionInicial || this.IsNullableOp(FechaCreacionInicial.Op)) {

      //     FechaCreacionInicial.Value = clone.FechaCreacionInicial;
      //     clone.Filtro += this.GetFiltro(FechaCreacionInicial) + " " + ((FechaCreacionInicial.LogicOp == "Or") ? "OR " : "AND ");
      //   }

      //   clone.FechaCreacionInicial = undefined;
      // }

      // if (FechaCreacionFinal) {
      //   if (clone.FechaCreacionFinal || this.IsNullableOp(FechaCreacionFinal.Op)) {

      //     FechaCreacionFinal.Value = clone.FechaCreacionFinal;
      //     clone.Filtro += this.GetFiltro(FechaCreacionFinal) + " " + ((FechaCreacionFinal.LogicOp == "Or") ? "OR " : "AND ");
      //   }
      //   clone.FechaCreacionFinal = undefined;
      // }
      // if (FechaFinalInicial) {
      //   if (clone.FechaFinalInicial || this.IsNullableOp(FechaFinalInicial.Op)) {

      //     FechaFinalInicial.Value = clone.FechaFinalInicial;
      //     clone.Filtro += this.GetFiltro(FechaFinalInicial) + " " + ((FechaFinalInicial.LogicOp == "Or") ? "OR " : "AND ");
      //   }
      //   clone.FechaFinalInicial = undefined;
      // }
      // if (FechaFinalFinal) {
      //   if (clone.FechaFinalFinal || this.IsNullableOp(FechaFinalFinal.Op)) {

      //     FechaFinalFinal.Value = clone.FechaFinalFinal;
      //     clone.Filtro += this.GetFiltro(FechaFinalFinal) + " " + (FechaFinalFinal.LogicOp == "Or") ? "OR " : "AND ";
      //   }
      //   clone.FechaFinalFinal = undefined;
      // }
      //clone.Filtro += " TRegistroVersionSubSeriePaginaMetadatosDetalle.TipoDato  '' '" + element.Name + "' AND ";
    }
    else {

    }

    // this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdRoleResponsable', 'RoleResponsable');
    // this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdAreaEmpresaResponsable', 'AreaEmpresaResponsable');

    clone.FiltroMetadatos = null;
    if (this.modelo.modeloMetadata && this.modelo.modeloMetadata.Items && this.modelo.modeloMetadata.Items.Count()) {
      this.modelo.modeloMetadata.Items.ToArray().forEach(element => {
        //0004760: al hacer el filtro de búsqueda de expedientes con metadatos no muestra los expedientes
        //MP cuando los parametro de metadatos son null, no filtra los datos
        //debugger;
        if (element.Value != null && (element.DataType != 'Boolean' ? element.Value.trim().length > 0 : element.Value)) {

          if (clone.FiltroMetadatos) {
            clone.FiltroMetadatos += " OR ";
          }
          else
            clone.FiltroMetadatos = '';

          clone.FiltroMetadatos += " (TRegistroVersionSubSeriePaginaMetadatosDetalle.TipoDato  = '" + element.DataType + "' AND ";
          clone.FiltroMetadatos += " TRegistroVersionSubSeriePaginaMetadatosDetalle.NombreControl = '" + element.Name + "' ";

          switch (element.DataType) {
            case "Int32":
            case "Int64":
            case "Int16":
              //ValorEntero
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorEntero = " + element.Value + ")";
              break;
            case "Decimal":
            case "Number":
              //ValorNumero
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorNumero = " + element.Value + ")";
              break;
            case "Boolean":
              //ValorBoolean
              if (element.Value == 'true' || element.Value == 1)
                clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorBoolean = 1)";
              else
                clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorBoolean = 0)";
              break;
            case "DateTime":
              //ValorFecha
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorFecha = CONVERT(VARCHAR(10),'" + element.Value + "',103))";
              break;
            case "String":
              //ValorTextoCuatrocientos
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorTextoCuatrocientos = '" + element.Value + "')  ";
              break;
            case "SemiLargeString":
              //ValorTextoMil
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorTextoMil = '" + element.Value + "')  ";
              break;
            case "LargeString":
            case "Json":
              //ValorObservaciones
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorObservaciones = '" + element.Value + "')  ";
              break;
          }
        }

      });
    }

    // String = 0,
    // Number,
    // Int32,
    // DateTime,
    // Boolean,
    // Object,
    // Int64,
    // Decimal,
    // SemiLargeString,
    // LargeString,
    // Json,
    // QueryResult,
    // File,

    // Int16,
    // Float,
    // Double,
    // Single,
    // Money,
    // Char,
    // StringSiNo,
    // Image,
    // Null,
    // Entidad,
    // DataTable,
    // this.utility.SetValorPropiedadBusquedaContains(clone, 'PalabrasClave');


    //this.queryConfig.Entity = clone;
    return {
      IdEmpresa: -1,
      IdAreaEmpresaUsuario: -1,
      IdAreaEmpresa: clone.IdAreaEmpresa,
      AreaEmpresa: clone.AreaEmpresa,
      IdSerie: clone.IdSerie,
      Serie: clone.Serie,
      IdSubSerie: clone.IdSubSerie,
      SubSerie: clone.SubSerie,
      Codigo: clone.Codigo,
      Nombre: clone.Nombre,
      PalabrasClave: clone.PalabrasClave,
      PalabrasNormalizadas: clone.PalabrasNormalizadas,
      FechaCreacionInicial: clone.FechaCreacionInicial,
      FechaCreacionFinal: clone.FechaCreacionFinal,
      FechaFinalInicial: clone.FechaFinalInicial,
      Extensiones: clone.Extensiones,
      FechaFinalFinal: clone.FechaFinalFinal,
      IdUsuarioResponsable: clone.IdUsuarioResponsable,
      UsuarioResponsable: clone.UsuarioResponsable,
      FiltroMetadatos: clone.FiltroMetadatos,
      Filtro: clone.Filtro,
    };


  }




  public CrearNodos() {

    if (!this.configBaseTreeComponent)
      return;

    this.jsonProject = this.configBaseTreeComponent.GetNewNode(null);
    this.configBaseTreeComponent.CreateNodeTree(this.jsonProject, this.itemsAreasEmpresa, 'AreasEmpresa', this.configBaseTreeComponent, TiposCrearNodos.Recursiva);

    let configSeries = {
      items: StaticData.SubSeries.ToArray(),
      idParent: 'IdParent',
      idParentExternal: 'IdSerie',
      nodeTypeData: this.utility.GetNodeType('SubSeries'),
      TiposCrearNodo: TiposCrearNodos.Recursiva
    };

    this.configBaseTreeComponent.CreateNodeTree(this.jsonProject, StaticData.Series.ToArray(), 'Series', this.configBaseTreeComponent, TiposCrearNodos.Normal, configSeries);

  }
  public VerEditorModelo(tipo: any, tipoModelo: any = 1) {

    if (tipoModelo == 1)
      this.utility.VerEditor(tipo, this.modelo.modelo);

  }
  public VerFiltro(index: number) {

    const that = this;
    this.modeloTemp = null;
    this.selectedNodes = new List<any>([]);
    let configWindow = new ConfigWindow();

    let h = document.documentElement.getBoundingClientRect().height;
    configWindow.height = 400;
    configWindow.width = 900;
    configWindow.titleWindow = (index === 0) ? 'Filtro de Areas Empresas' : 'Filtro Series Sub Series';
    configWindow.resizable = true;
    configWindow.modal = false;
    configWindow.closeable = true;
    configWindow.draggable = true

    this.SetNodoSeleccionadosParaJstree(index);


    let modelo = {
      selectionMode: true,
      queryConfigIDEType: -1,
      data: [this.jsonProject.children[index]],
      selectedNodes: this.selectedNodes,
      notSelectablesNodesTypes: this.notSelectablesNodesTypes
    };

    this.utility.OpenWindow('OrgChartIDEComponent', configWindow, modelo).then(cretedModel => {

      cretedModel.onClosePromise.then(_modelo => {

        if (_modelo.dialogResult == true) {
          this.SetNodosSeleccionadosJstreeParaModelo(index);

        }

        //this.selectedNodes = new List<any>([]);
        //this.CrearNodos();
      })

    });
  }

  public VerFiltroNodosOrgChart(index: number) {

    const that = this;
    this.modeloTemp = null;
    this.selectedNodes = new List<any>([]);
    let configWindow = new ConfigWindow();

    let h = document.documentElement.getBoundingClientRect().height;
    configWindow.height = 400;
    configWindow.width = 900;
    configWindow.titleWindow = 'Filtro de Areas Empresas';
    configWindow.resizable = true;
    configWindow.modal = false;
    configWindow.closeable = true;
    configWindow.draggable = true

    if (index == 0) {
      this.SetNodosAreasSeleccionados();

    }
    else {

      this.SetNodosSeriesSeleccionados();

    }


    let modelo = {
      selectionMode: true,

      nodes: (index === 0) ? this.nodosAreas : this.nodosSeries,
      selectedNodes: this.selectedNodes,
      notSelectablesNodesTypes: this.notSelectablesNodesTypes
    };

    this.utility.OpenWindow('OrgChartJsComponent', configWindow, modelo).then(cretedModel => {

      cretedModel.onClosePromise.then(_modelo => {

        if (_modelo.dialogResult == true) {

          this.SetNodosSeleccionadosJstreeParaModelo(index);
          /*   if (index == 0) {
              this.SetNodosAreasSeleccionadosOrgChartParaModelo();
            }
            else {

              this.SetNodosSeriesSeleccionadosParaOrgChartParaModelo();
            } */
        }

        // this.LimpiarNodos();
      })

    });
  }
  public VerFiltroNodosJstree(index: number) {

    const that = this;
    this.modeloTemp = null;
    this.selectedNodes = new List<any>([]);
    let configWindow = new ConfigWindow();

    let h = document.documentElement.getBoundingClientRect().height;
    configWindow.height = 400;
    configWindow.width = 500;
    configWindow.titleWindow = (index === 0) ? 'Filtro de Areas Empresas' : 'Filtro Series Sub Series';
    configWindow.resizable = true;
    configWindow.modal = false;
    configWindow.closeable = true;
    configWindow.draggable = true

    if (index == 0) {
      this.SetNodosAreasSeleccionados();

    }
    else {

      this.SetNodosSeriesSeleccionados();

    }



    let modelo = {
      selectionMode: true,
      queryConfigIDEType: -1,
      jsonData: [this.jsonProject.children[index]],
      selectedNodes: this.selectedNodes,
      notSelectablesNodesTypes: this.notSelectablesNodesTypes,
      autoInit: true
    };

    this.utility.OpenWindow('JstreeComponent', configWindow, modelo).then(cretedModel => {

      cretedModel.onClosePromise.then(_modelo => {

        if (_modelo.dialogResult == true) {
          this.SetNodosSeleccionadosJstreeParaModelo(index);

        }

        //this.selectedNodes = new List<any>([]);
        //this.CrearNodos();
      })

    });
  }


  public LimpiarNodos() {
    this.selectedNodes = new List<any>();
    this.CrearNodos();
    this.CrearNodosAreas();
    this.CrearNodosSeries();
  }

  public SetNodosSeleccionadosJstreeParaModelo(index: number) {

    const that = this;
    if (index == 0) {

      this.modelo.modelo.IdAreaEmpresa = [];
      this.modelo.modelo.IdAreaEmpresa = this.selectedNodes.Select(x => { return x.node.data.IdAreaEmpresa }).ToArray();

    }
    else {

      this.modelo.modelo.IdSerie = [];
      this.modelo.modelo.IdSubSerie = [];
      this.selectedNodes.ForEach(function (value, index) {

        if (value.node.data.ObjType == 'Serie')
          that.modelo.IdSerie.push(value.node.data.IdSerie);
        else if (value.node.data.ObjType == 'SubSerie') {
          that.modelo.IdSubSerie.push(value.node.data.IdSubSerie);
        }

      });
      this.modeloTemp = this.utility.Clone(this.modelo.modelo);
      if (that.modelo.IdSerie.length > 0) {

        this.comboBoxManager.OnSelectedComboBox(new List<any>(that.modelo.IdSerie).Select(x => { return { IdSerie: x } }).ToArray(), 'IdSerie', that.configs.ConfigIdSerie);
      }
    }
  }
  public SetNodoSeleccionadosParaJstree(index: number) {

    const that = this;
    if (index === 0) {
      $.each(this.modelo.modelo.IdAreaEmpresa, function (i, IdAreaEmpresa) {

        $.each(that.jsonProject.children[index].children, function (index, node) {

          if (node.data.IdAreaEmpresa == IdAreaEmpresa) {
            that.selectedNodes.Add({ node: node, class: node.class });
            node.class = StaticData.Estilos.ClassNodosNuevos;
          }

        });

      });
    }
    else {
      $.each(this.modelo.modelo.IdSerie, function (i, IdSerie) {

        $.each(that.jsonProject.children[index].children, function (index, node) {

          if (node.data.ObjType == 'Serie') {
            if (node.data.IdSerie == IdSerie) {
              that.selectedNodes.Add({ node: node, class: node.class });
              node.class = StaticData.Estilos.ClassNodosNuevos;
            }

          }
          else if (node.data.ObjType == 'SubSerie') {
            $.each(this.modelo.modelo.IdSubSerie, function (_index, IdSubSerie) {
              if (node.data.IdSubSerie == IdSubSerie) {

                that.selectedNodes.Add({ node: node, class: node.class });
                node.class = StaticData.Estilos.ClassNodosNuevos;
              }
            });
          }

        });

      });
    }
  }


  public SetNodosSeriesSeleccionadosParaOrgChartParaModelo() {
    const that = this;
    this.modeloTemp = null;
    this.modelo.modelo.IdSerie = [];
    this.modelo.modelo.IdSubSerie = [];
    this.selectedNodes.ForEach(function (value, index) {

      if (value.node.data.ObjType == 'Serie')
        that.modelo.IdSerie.push(value.node.data.IdSerie);
      else if (value.node.data.ObjType == 'SubSerie')
        that.modelo.IdSubSerie.push(value.node.data.IdSubSerie);

    });
    this.modeloTemp = this.utility.Clone(this.modelo);
    if (that.modelo.IdSerie.length > 0) {

      this.comboBoxManager.OnSelectedComboBox(new List<any>(that.modelo.IdSerie).Select(x => { return { IdSerie: x } }).ToArray(), 'IdSerie', that.configs.ConfigIdSerie);
    }

    //this.LimpiarNodos();
  }

  public SetNodosAreasSeleccionadosOrgChartParaModelo() {
    this.modelo.modelo.IdAreaEmpresa = [];
    this.modelo.modelo.IdAreaEmpresa = this.selectedNodes.Select(x => { return x.node.data.IdAreaEmpresa }).ToArray();
    //this.LimpiarNodos();

  }
  public SetNodosSeriesSeleccionados() {
    const that = this;
    $.each(this.modelo.modelo.IdSerie, function (i, IdSerie) {

      $.each(that.jsonProject.children[1].children, function (index, node) {

        if (node.data.ObjType == 'Serie') {
          if (node.data.IdSerie == IdSerie) {
            that.selectedNodes.Add({ node: node, class: node.class });
            node.tags = ["Selected"];
          }

        }
        else if (node.data.ObjType == 'SubSerie') {
          $.each(this.modelo.modelo.IdSubSerie, function (_index, IdSubSerie) {
            if (node.data.IdSubSerie == IdSubSerie) {

              that.selectedNodes.Add({ node: node, class: node.class });
              node.tags = ["Selected"];
            }
          });
        }

      });

    });
  }
  public SetNodosAreasSeleccionados() {
    const that = this;
    $.each(this.modelo.modelo.IdAreaEmpresa, function (i, IdAreaEmpresa) {

      $.each(that.nodosAreas, function (index, node) {

        if (node.data.IdAreaEmpresa == IdAreaEmpresa) {
          that.selectedNodes.Add({ node: node, class: node.class });
          node.tags = ["Selected"];
        }

      });

    });
  }



  public SelectedNodeAreasEventHandler(event) {
    this.selectedNodes = event.selectedNodes;
    this.SetNodosAreasSeleccionadosOrgChartParaModelo();
  }
  public SelectedNodeSeriesEventHandler(event) {
    this.selectedNodes = event.selectedNodes;
    this.SetNodosSeriesSeleccionadosParaOrgChartParaModelo();
  }

  public ClearControlsMetadatos() {

    this.RemoveMetadataControls(null);
    if (this.DynamicMetadata)
      this.DynamicMetadata.Controls = [];

    this.Pagina.ConfigPage.Metadata = null;
  }


  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {

      if (controlName == 'IdSubSerie') {

        this.BuscarVersionTRD();
      }

      else if (controlName == 'IdAreaEmpresa') {
        if (item.length > 0) {
          this.comboBoxManager.ExecuteCascading(item, controlName, config);
        }
      }
      else {
        super.OnSelectedComboBox(item, controlName, config);
      }



    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }

  public BuscarVersionTRD() {
    if (this.modelo.modelo.IdAreaEmpresa && this.modelo.modelo.IdAreaEmpresa.length > 0 &&
      this.modelo.modelo.IdSerie && this.modelo.modelo.IdSerie.length > 0 &&
      this.modelo.modelo.IdSubSerie && this.modelo.modelo.IdSubSerie.length > 0) {
      this.ClearControlsMetadatos();
      var obj = this.utility.GetApiModel('BuscarVersionTRD', "TExpediente");

      obj.QueryConfig.Command = 'BusquedaVersionTRD';
      obj.QueryConfig.IsSp = true;


      obj.QueryConfig.Entity = {
        IdAreaEmpresa: this.modelo.modelo.IdAreaEmpresa[0],
        IdSerie: this.modelo.modelo.IdSerie[0],
        IdSubSerie: this.modelo.modelo.IdSubSerie[0],
        IdVersionTablaRetencionDocumental: null //MySQL Parameter Null
      };

      this.baseService.Get(obj, null,
        {
          componentName: "TExpediente",
          method: 'BuscarVersionTRD',
          processMessage: 'Buscando tabla TRD...'
        }, "TExpediente")
        .then(data => {

          const configuracion = data.Data[0].Configuracion;
          this.CreateMetadataControls(configuracion);
          // this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata;
          this.SetModelToControls();

        }).catch(err => {
          this.utility.logger.LogError(err, obj, {
            componentName: this.componentName,
            method: 'BuscarVersionTRD',
            processMessage: 'Buscando tabla TRD...'
          }, this.url)
        });

    }
  }


  public CreateMetadataControls(_metadata: any, prop: string = 'Metadatos') {
    if (!_metadata)
      return;
    let metadata: any = _metadata;
    const formGroup: any = {};
    if (typeof _metadata === 'string') {
      metadata = JSON.parse(_metadata);
    }
    else
      metadata = _metadata;
    if (!Array.isArray(metadata)) {
      metadata = metadata[prop];
    }
    this.Pagina.ConfigPage.Metadata = metadata;

    this.utility.InactivarPorSearcheable(this.Pagina.ConfigPage.Metadata);

    this.DynamicMetadata.Controls = this.Pagina.ConfigPage.Metadata//.filter(x => { return x.Searcheable });
    //this.modelo.modeloMetadata.IdParentObject = idParent;
    const that = this;
    if (this.Pagina && this.Pagina.ConfigPage && this.Pagina.ConfigPage.Metadata && this.Pagina.ConfigPage.Metadata.length > 0) {

      //this.controls = new List([])
      // this.SetFormControl(formGroup, this.Pagina.ConfigPage.Metadata);

      // let metadatForms = new FormGroup(formGroup);
      // this.form.addControl("Metadatos", metadatForms);
      //
      this.AddControls(this.Pagina.ConfigPage.Metadata, null, true);

      // this.CreateModel();
      this.SetValueChangesControlsToModel();

    }

  }

}
