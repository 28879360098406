import { Component, OnInit, Input } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-box',
  templateUrl: './link-box.component.html',
  styleUrls: ['./link-box.component.scss']
})
export class LinkBoxComponent implements OnInit {

  @Input() isDesigner: boolean = false;
  isContainer: boolean = false;
  @Input() subControl: any;
  @Input() name: string = "LinkBox_" + Guid.create().toString();
  constructor(public router: Router) { }

  ngOnInit() {
    if (this.subControl && this.subControl.Name)
      this.name = this.subControl.Name;

    if (this.subControl && this.subControl.Controls && this.subControl.Controls.length > 0)
      this.isContainer = true;
  }
  OnClick(){

    if (this.subControl.IsExternal) {
      if (this.subControl.Url.includes("window.open")) {
        eval(this.subControl.Url);
      }
      else
        window.location.href = this.subControl.Url
    }
    else
    this.router.navigate([this.subControl.Url]);
  }
}
