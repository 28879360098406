import { Component, OnInit } from '@angular/core';
import { Tus, UploadState, UploadxControlEvent, UploadxOptions } from 'ngx-uploadx';
import { Ufile } from './ufile';
import { StaticData } from '../../../helpers/static-data';

@Component({
  selector: 'app-file-uploader-tus',
  templateUrl: './file-uploader-tus.component.html',
  styleUrls: ['./file-uploader-tus.component.scss']
})
export class FileUploaderTusComponent implements OnInit {
  mostrarEstadoJson: false;

  control!: UploadxControlEvent;
  state!: UploadState;
  uploads: Ufile[] = [];

  options: UploadxOptions = {
    endpoint: StaticData.UrlServer + "/api/fileuploadlarge/upload",
    uploaderClass: Tus,
    multiple: true,
    chunkSize: 5242880,
    metadata(file): Record<string, string> {
      return { original_name: file.name, contentType: file.type };
    }
  };

  constructor() { }

  ngOnInit() {
  }

  onUpload(state: UploadState) {
    console.log(state);
  }

  cancel(uploadId?: string): void {
    this.control = { action: 'cancel', uploadId };
  }

  pause(uploadId?: string): void {
    this.control = { action: 'pause', uploadId };
  }

  upload(uploadId?: string): void {
    this.control = { action: 'upload', uploadId };
  }

  onStateChanged(state: UploadState): void {
    this.state = state;
    const file = this.uploads.find(item => item.uploadId === state.uploadId);
    file ? file.update(state) : this.uploads.push(new Ufile(state));
    console.log(state);
  }

}
