import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { ConfigWindow } from '../../../models/config-window';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';

import { ContextMenuComponent } from 'ngx-contextmenu';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { PropertyGridComponent } from '../property-grid/property-grid.component';
import { List } from '../../../../assets/linqts/compilado/index';
import { Guid } from 'guid-typescript';
declare var fabric: any;



@Component({
  selector: 'app-property-grid-controls',
  templateUrl: './property-grid-controls.component.html',
  styleUrls: ['./property-grid-controls.component.scss']
})
export class PropertyGridControlsComponent extends PropertyGridComponent implements OnInit {


  PivotElement: any;
  public createTab: boolean = true;
  @Input() tabName = "Tab_" + Guid.create().toString();

  public ComparisonOperatorsArray: Array<any> = [{ Name: 'None', Label: 'Ninguno' },
  { Name: 'Equal', Label: 'Igual A' },
  { Name: 'NotEqual', Label: 'No Igual A' },
  { Name: 'In', Label: 'Esta en la Lista' },
  { Name: 'NotIn', Label: 'No esta en la Lista' },
  { Name: 'ContainsText', Label: 'Contiene Texto' },
  { Name: 'Contains', Label: 'Contiene' },
  { Name: 'NotContains', Label: 'No Contiene' },
  { Name: 'StartsWith', Label: 'Inicia Con' },
  { Name: 'EndsWith', Label: 'Finaliza Con' },
  { Name: 'NotStartsWith', Label: 'No Incial Con' },
  { Name: 'NotEndsWith', Label: 'No Finaliza Con' },
  { Name: 'GreaterThan', Label: 'Mayor Que' },
  { Name: 'LessThan', Label: 'Menor Que' },
  { Name: 'GreaterOrEqual', Label: 'Mayor o Igual A' },
  { Name: 'LessOrEqual', Label: 'Menor o Igual A' },
  { Name: 'IsNull', Label: 'Es Nulo' },
  { Name: 'IsNotNull', Label: 'No Es Nulo' },
  { Name: 'IsUndefined', Label: 'Es Indefinido' },
  { Name: 'IsNotUndefined', Label: 'No Es Indefinido' },
  { Name: 'IsEmpty', Label: 'Es Vacio' },
  { Name: 'IsNotEmpty', Label: 'No Es Vacio' },
  { Name: 'Range', Label: 'Rango' }];



  public OnInit() {
    if (this.subControl) {
      if (this.subControl.ControlType == 'GridView') {
        if (!this.subControl.ExportButtons)
          this.subControl.ExportButtons = [];
        if (!this.subControl.ClassAlerts)
          this.subControl.ClassAlerts = [];
        if (!this.subControl.Notifications)
          this.subControl.Notifications = [];

        if (!this.subControl.ClearModelOptions)
          this.subControl.ClearModelOptions = [];
      }

      if (this.subControl.ControlType == 'FileBox' && !this.subControl.RequiredExtensions)
        this.subControl.RequiredExtensions = [];
      if (this.subControl.ControlType == 'FormSubmit') {

        if (this.subControl.ControlType == 'FileBox' && (this.subControl.NotTemplate == null ||
          this.subControl.NotTemplate == undefined))
          this.subControl.NotTemplate = true;


      }

    }

    this.CreateTabs();
  }
  ngAfterViewInit() {

    setTimeout(() => {

    }, 400);

    this.CreateTabs();
  }
  CreateTabs() {

    
    if (this.createTab) {

      let _PivotElement = document.querySelector("#" + this.tabName);
      if (_PivotElement) {
        this.createTab = false;
        new fabric['Pivot'](_PivotElement);
      }
    }

  }
  ValidarOperadores() {
    if (!this.subControl.ComparisonOp)
      this.subControl.ComparisonOp = [];
  }
  public EliminarComparisonOp(i: number) {

    this.subControl.ComparisonOp.splice(i, 1);
  }

  public AdicionarComparisonOp(event) {
    //this.ValidarOperadores();
    
    event.preventDefault();
    event.stopPropagation();

    if (!this.subControl.ComparisonOp)
      this.subControl.ComparisonOp = [];
    
      this.subControl.ComparisonOp.push({ });
    //this.subControl.ComparisonOp.push({ Name: 'Equal', Label: 'Igual A' });
    //return false
  }

  public NuevaQueryConfig() {
    this.subControl.ConfigControl.QueryConfig = {
      "Command": "",
      "IsSp": false,
      "Entity": {},
      "WithPagination": false,
      "OutPutParam": false,
    };
  }
  public NuevaConfig() {
    this.subControl.ExportSettings = {};
  }
  public EliminarConfig() {
    this.subControl.ExportSettings = null;
  }

  public OnChangeEntities() {

    if (this.subControl.ConfigControl.Controller) {
      this.subControl.ConfigControl.QueryConfig.CrudAction = "GetByFilterOrderBy";
      //this.subControl.ConfigControl.QueryConfig.EntityName = this.subControl.ConfigControl.Controller;
    }
  }
  public OnChangeProcedures() {
    if (this.subControl.ConfigControl.QueryConfig.Command) {
      this.subControl.ConfigControl.QueryConfig.IsSp = true;
      this.subControl.ConfigControl.QueryConfig.CrudAction = "None";
      this.subControl.ConfigControl.QueryConfig.EntityName = undefined;
    }
    else {
      this.subControl.ConfigControl.QueryConfig.IsSp = false;
      this.subControl.ConfigControl.QueryConfig.CrudAction = "GetByFilterOrderBy";
      //this.subControl.ConfigControl.QueryConfig.EntityName = this.subControl.ConfigControl.Controller;
    }
  }
  public OnChangeHandlerGidViewParams(event) {

    this.subControl.ConfigControl.Params = event;
    this.UpdateEditor(this.subControl);
  }
  public OnChangeHandlerGidViewParamsQueryConfig(event) {

    this.subControl.ConfigControl.QueryConfig.Entity = event;
    this.UpdateEditor(this.subControl);
  }

  public AddColumn() {
    if (this.subControl) {
      if (!this.subControl.Controls)
        this.subControl.Controls = [];
      if (this.subControl.IsExtendableTable || this.subControl.IsExtendableFileTable) {
        this.subControl.Controls.push(this.utility.GeModelControl("TextBox"));
      }
      else
        this.subControl.Controls.push(this.utility.GetColumnModel());
    }
  }
  public DeleteColumn(event) {
    if (this.subControl && this.subControl.Controls) {
      this.subControl.Controls.splice(event, 1);
    }
  }

  public ChangeWithQueryBuilder(WithQueryBuilder) {
    if (WithQueryBuilder)
      this.subControl.WithFilterControls = true;
  }
  public AddControlFiltro() {

    if (this.subControl) {
      if (!this.subControl.FilterControls)
        this.subControl.FilterControls = [];

      this.subControl.FilterControls.push(this.utility.GeModelControl("TextBox"));

    }
  }
  public DeleteControlFiltro(event) {
    if (this.subControl && this.subControl.FilterControls) {
      this.subControl.FilterControls.splice(event, 1);
    }
  }
  public Update() {
    this.UpdateEditor(this.subControl);
  }
  public UpdateEditor(subControl?: any) {
    this.subControl = subControl;
    if (this.subControl && this.subControl.ControlType !== 'Column') {

      if (this.editorAce)
        this.editorAce.Update(this.subControl.ConfigControl);
      //if (this.propGridConfigControl)
      //this.propGridConfigControl.UpdateEditor(this.subControl);
      /*  if (this.IsControlWithConfigControl()) {
         if (this.editorAceEntity)
           this.editorAceEntity.Update(this.subControl.ConfigControl.QueryConfig.Entity);
         if (this.editorAceParams)
           this.editorAceParams.Update(this.subControl.ConfigControl.Params);
       } */
    }
  }


  public OnChangeHandlerButtons(event) {
    this.subControl.Controls = event;
    //this.UpdateEditor(this.subControl);
  }



  public get ParentName(): string {

    if (this.subControl && this.subControl.ConfigControl && this.subControl.ConfigControl.ConfigParent)
      return this.subControl.ConfigControl.ConfigParent.ParentName;
    else
      return '';
  }
  public set ParentName(value: string) {

    if (this.subControl) {
      if (!this.subControl.ConfigControl) {
        if (value)
          this.subControl.ConfigControl = { ConfigParent: { ParentName: value } };
        else
          this.subControl.ConfigControl = { ConfigParent: null };
      }
      else if (!this.subControl.ConfigControl.ConfigParent) {
        if (value)
          this.subControl.ConfigControl.ConfigParent = { ParentName: value };
        else
          this.subControl.ConfigControl.ConfigParent = null;
      }
      else if (this.subControl.ConfigControl.ConfigParent) {
        if (value)
          this.subControl.ConfigControl.ConfigParent = { ParentName: value };
        else
          this.subControl.ConfigControl.ConfigParent = null;
      }
    }
  }
  public OnChangeParent(event) {



    let controles = new List<any>(this.resultParentControls);
    // if(event)
    //const child =controles.FirstOrDefault(x=> { x.ConfigControl.ConfigParent && })
  }


  verProperty(control, index): any {
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Propiedades";
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';
    let windowModel: any = {
      subControl: control,
      subControlIndex: -1
    };

    this.utility.OpenWindow('PropertyGridControlsComponent',
      config, windowModel).then(dataResult => {
        // console.log(dataResult);

        dataResult.onClosePromise.then(dataResultOnClose => {
          // console.log(dataResultOnClose);


        });

      })

  }

  SetDefaultPkName() {
    this.subControl.PkField = 'Id';
    this.subControl.TextField = 'Text';
  }
  public AddItemCombo() {
    
    this.SetDefaultPkName();
    if (!this.subControl.ConfigControl)
      this.subControl.ConfigControl = { Items: [] };
    if (!this.subControl.ConfigControl.Items)
      this.subControl.ConfigControl.Items = [];
    this.subControl.ConfigControl.Items.push({ Id: null, Text: null });

  }
  public DelteItemCombo(index) {

    this.SetDefaultPkName();
    this.subControl.ConfigControl.Items.splice(index, 1);
  }
}



