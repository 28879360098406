import {
    Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
    QueryList, forwardRef, Inject, DebugElement
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, DataTableColumnTypes, DataTableAjaxOrderTypes } from '../models/general.enum';
import { Utilities } from '../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../services/base-service.service';
import { ModalNotifyComunicationService } from '../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../assets/linqts/compilado/index';
import { StaticData } from '../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { ComboBoxManager } from './combo-box-manager';
import { ConfigWindow } from '../models/config-window';



export class BaseDataTable implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChildren(DataTableDirective) dtElement: QueryList<DataTableDirective>;

    childComponents: Array<any> = [];
    @Input() cargarGrilla: boolean = true;
    @Input() containerComponentName: string;
    @Input() pkName: string = null;
    @Input() pkValueEdit: any;
    modelo: any;
    modeloClone: any = null;
    modeloMetadata: {};

    @Input() modeloEdit: any
    @Input() isHtmlDataTable: boolean = false;
    @Input() isAddCrudColumns: boolean = true;
    @Input() isAddEventsColumns: boolean = false;
    @Input() withPagination: boolean = false;
    @Input() hideForm: boolean = false;
    @Input() hideDataTable: boolean = false;
    @Input() Pagina: any = { Valida: false, ConfigPage: { ExtraControls: [], Controls: [] } };
    @Input() childOptions: any = null;
    @Input() configColumnasEditarEliminar: any = null;
    @Input() columnaEditarEliminar: boolean = true;
    @Input() columnaEditarEliminarIndex: number = null;


    @Input() public exportar: string = null;
    @Input() url: string = null;

    @Input() externalFilter: any = null;
    @Input() filterParent: any = null;
    @Input() mappingFilter: any = null;
    @Input() generalFilter: any = null;
    isModal: boolean = false;
    isLoaded: boolean = false;
    filterModelTemp: any = null;
    @Input() parentOption: any = null;
    @Input() idGrillaQuery: any = undefined;
    public filterModal: any = null;
    dtOptions: any = {};
    items: any;

    @Input() config: any = null;
    @Input() columns: any = [];
    @Input() conFiltroIdEmpresa: boolean = true;
    @Input() QueryConfig: any = null;

    @Input() dataTableAjaxOrderType: DataTableAjaxOrderTypes = DataTableAjaxOrderTypes.Default;
    @Input() outPutParam: boolean = false;

    botonProcesando: boolean = false;
    textoBoton: string = 'Guardar';
    textoBotonProcesando: string = '';

    public botonProcesandoBuscar: boolean = false;
    public textoBotonBuscar: string = 'Buscar';
    public textoProcesandoBuscar: string = '';


    nombreBotonSeleccion: string = 'btnSeleccion';
    nombreBotonEliminar: string = 'btnEliminar';
    nombreBotonEditar: string = 'btnEditar';
    nombreBotonChildOptions: string = 'childOptions';
    dataTableControl: any;

    @Input() public nombreDataTable: string;
    @Input() public processMessage: string;
    public startIndexColumn: number = 0;

    @Input() public configPermisos: any;
    @Input() public createdRowConfig: any
    comboBoxManager: ComboBoxManager;

    @Output() onConfigEmitter: EventEmitter<any> = new EventEmitter<any>();


    constructor(public baseService: BaseServiceService,
        public utility: Utilities,
        public enumPagina: Paginas,
        public componentName: string,
        public include: any,
        public isUnion: boolean = false,
        public configs: any = {}) {

        
        if (!this.nombreDataTable)
            this.nombreDataTable = this.GetNombreDataTables();

        this.nombreBotonChildOptions = this.nombreBotonChildOptions + this.componentName;
        this.nombreBotonEliminar = this.nombreBotonEliminar + this.componentName;
        this.nombreBotonEditar = this.nombreBotonEditar + this.componentName;
        this.nombreBotonSeleccion = this.nombreBotonSeleccion + this.componentName;
        if (this.enumPagina)
            this.Pagina = this.utility.ValidateUserAndPage(this.enumPagina, false, this.componentName, 'Contructor');

        this.SetProcesando(false);
        this.Limpiar();

        this.ExcecuteFuntion(ControllerMethods.Constructor, true);


    }

    ngOnInit() {

        this.OnInit();

    }
    ngAfterViewInit() {


    }
    ngAfterViewChecked() {

    }

    public GetConfigPermisos(data?: any): any {
        return this.configPermisos;
    }
    public GetTextMessage(method: string): string {
        return `componentName: ${this.componentName} method: ${method}`;
    }

    public OnInitParentOption() {

        if (this.parentOption) {
            if (this.parentOption.CargarGrilla == false)
                this.cargarGrilla = false;
            if (this.parentOption.HideDataTable == true)
                this.hideDataTable = true;
            if (this.parentOption.HideForm == true)
                this.hideForm = true;
        }

    }
    public OnInitEnd() {

    }
    public OnInit() {

        try {

            this.OnInitParentOption();

            if (this.Pagina.Valida == true && this.Pagina.ConfigPage) {
                this.utility.SetControlsConfig(this.Pagina, this.configs);
                this.SetControlsConfig();
            }


            this.onSpecificStart();

            if (this.enumPagina)
                this.utility.IsUserAuthenticated(this.enumPagina, true, this.componentName, 'onInit', null);

            if (this.Pagina.Valida) {

                this.childOptions = (this.childOptions && this.childOptions.length > 0) ? this.childOptions : this.utility.GetChildOptions(this.Pagina);
                this.AdicionarColumasEditarEliminar();
                this.exportar = (this.Pagina.Exportar == true) ? this.componentName : undefined;
                this.dataTableControl = this.utility.FindControlPagina(this.Pagina, 'DataTables_' + this.componentName);
                this.idGrillaQuery = (this.dataTableControl && this.dataTableControl.IdQuery) ? this.dataTableControl.IdQuery : undefined;

                //this.CargarComboBox();
                if (this.modeloEdit) {

                    this.cargarGrilla = false;
                    this.hideDataTable = true;
                    this.SetModelo(this.modeloEdit, null, 3000);


                }
                this.OnInitEnd();
                this._CargarGrilla();

            }
            else
                this.SetDefaultDtOptions();


            // this.ExcecuteFuntion(ControllerMethods.OnInit,true);
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
        }


    }






    public get IsEdit(): boolean {
        return (this.modelo.PkValue !== 0 && this.modelo.PkValue !== null && this.modelo.PkValue !== '');
    }

    public get HasVersionModelData() {
        return (this.modelo.hasOwnProperty('VersionModelData'));
    }
    public get HasPropVersionModelData() {
        
        return (this.HasVersionModelData && this.modelo.VersionModelData.hasOwnProperty('Propiedades'));
    }
    public GetNombreDataTables(): string {

        return 'DataTables_' + this.componentName;
    }
    public SetDefaultDtOptions() {
        this.dtOptions = this.utility.GetDefaultDtOptions();
    }

    public GetDataTableFilter(): any {

        let filter = '';
        this.isLoaded = false;
        //this.filtroIdEmpresa = (this.filtroIdEmpresa == null) ? null : this.componentName + '.' + this.filtroIdEmpresa + ' = ' +StaticData.Usuario.IdEmpresa;
        let filterParent = null;
        if (!this.QueryConfig) {
            if (this.conFiltroIdEmpresa) {
                if (StaticData.Usuario)
                    filter = this.componentName + '.IdEmpresa = ' + StaticData.Usuario.IdEmpresa;
                else
                    filter = this.componentName + '.IdEmpresa = 0 ';
            }
            if (this.pkValueEdit)
                filter = (filter) ? filter + ' AND ' + this.componentName + '.' + this.pkName + ' = ' + this.pkValueEdit : this.componentName + '.' + this.pkName + ' = ' + this.pkValueEdit;

            if (this.externalFilter)
                filter = (filter) ? filter + ' AND ' + this.externalFilter : this.externalFilter;
            if (this.filterModal)
                filter = (filter) ? filter + ' AND ' + this.filterModal : this.filterModal;

            filterParent = (this.parentOption && this.parentOption.NotApplyToFilterGrid == true) ? null : this.filterParent;
        }
        let obj = this.utility.GetApiModelGrillaValidation('_CargarGrilla', (this.idGrillaQuery) ? this.idGrillaQuery : this.componentName, filterParent, this.mappingFilter, this.generalFilter, this.Pagina, null, filter);
        if (this.QueryConfig)
            obj.QueryConfig = this.QueryConfig;
        if (this.include)
            obj.QueryConfig.Include = this.include;
        if (!this.QueryConfig)
            obj.QueryConfig.WithPagination = this.withPagination;

        var resultExecute = this.ExcecuteFuntion(ControllerMethods.LoadGrid, false, obj);
        if (resultExecute)
            obj = resultExecute;

        var okUser = this.utility.ValidateUser(obj);
        this.isLoaded = true;
        this.processMessage = (this.processMessage) ? this.processMessage : ((this.Pagina.Titulo) ? 'Cargando datos de ' + this.Pagina.Titulo : null);
        return {
            obj: obj,
            notLoad: !this.cargarGrilla,
            processMessage: this.processMessage,
            startIndexColumn: this.startIndexColumn,
            columns: this.columns,
            dataTableAjaxOrderType: this.dataTableAjaxOrderType,
            outPutParam: this.outPutParam
        };
    }
    public GetByPkValue(pkValueEdit?: any) {
        if (pkValueEdit)
            this.pkValueEdit = pkValueEdit;
        let result = this.GetDataTableFilter();
        let info = { componentName: this.componentName, method: 'GetByPkValue', processMessage: 'Consultando par editar el registro de ' + this.Pagina.Titulo };
        this.baseService.Get(result.obj, null,
            info, this.componentName, true, true).then(data => {

                this.SetModelo(data.Data[0]);

            }).catch(error => {
                this.utility.logger.LogError(error, 'Consulta de Edicion', info);
            })
    }
    //order([0, 'desc'])

    public OrderDataTable(value: any) {
        const that = this;
        try {
            that.utility.GetDataTabledtInstance(that.dtElement, that.GetNombreDataTables(), that.componentName).then(dtInstance => {


                dtInstance.order(value).draw();
            }).catch(error => {
                that.utility.logger.LogError(error, value);
            })
        }
        catch (error) {
            that.utility.logger.LogError(error, value);
        }
    }
    public PageDataTable(value: any) {
        const that = this;
        try {
            that.utility.GetDataTabledtInstance(that.dtElement, that.GetNombreDataTables(), that.componentName).then(dtInstance => {


                dtInstance.page.len(value).draw();
            }).catch(error => {
                that.utility.logger.LogError(error, value);
            })
        }
        catch (error) {
            that.utility.logger.LogError(error, value);
        }
    }
    public SearchDataTable(value: any) {
        const that = this;
        try {
            that.utility.GetDataTabledtInstance(that.dtElement, that.GetNombreDataTables(), that.componentName).then(dtInstance => {
                dtInstance.search(value).draw();
            }).catch(error => {
                that.utility.logger.LogError(error, value)
            })

        }
        catch (error) {
            that.utility.logger.LogError(error, value);
        }
    }
    public _CargarGrilla(refresh?: boolean) {


        try {

            if (this.hideDataTable == true) {

                this.SetDefaultDtOptions();
                if (this.pkValueEdit) {
                    this.GetByPkValue()
                }
                return;
            }

            const that = this;
            let urlServer = this.utility.GetUrlServer((this.url) ? this.url : this.componentName);

            this.dtOptions = this.utility.GetDtOptions(this.columns);

            if (this.configPermisos && this.configPermisos.CreatedRowConfig) {

                this.dtOptions.createdRow = function (row, data, index) {
                    
                    let ok = that.utility.TienePemisoUsuario(that.configPermisos, data, 'Editar');
                    if (!ok) {
                        let className = 'alert alert-warning';
                        // $('td', row).addClass(className);
                        $(row).addClass(className);
                    }
                }
            }

            this.dtOptions.initComplete = function () {


                $('#' + that.GetNombreDataTables() + '_filter input').off();

                $('#' + that.GetNombreDataTables() + '_filter input').on('keypress', function (e) {

                    
                    var input = this as HTMLInputElement;
                    if (e.keyCode == 13) {
                        e.preventDefault();
                        e.stopPropagation();

                        that.utility.GetDataTabledtInstance(that.dtElement, that.GetNombreDataTables(), that.componentName).then(dtInstance => {


                            dtInstance.search(input.value).draw();
                        }).catch(error => { that.utility.logger.LogError(error, {}) })
                        //    if(dtInstance)
                        //     dtInstance.DataTable.search(this.value).draw();
                        //    else if(callbackDtInstance)
                        //    {
                        //        var _dtInstance = callbackDtInstance();
                        //        _dtInstance.DataTable.search(this.value).draw();
                        //    }
                        //   else
                        //     $scope.dtInstance.DataTable.search(this.value).draw();
                    }
                });
            }
            this.dtOptions.ajax = this.baseService.AjaxDataTables(urlServer, this.GetNombreDataTables(), this.componentName, null,
                this.isHtmlDataTable, this.AsignarEventosEditarEliminar, this, this.CallbackDataTablesOk, this.CallbackDataTablesError,
                (this.pkValueEdit), !this.cargarGrilla, this.loadedGridEmitter);


            if ((this.parentOption || refresh) && this.cargarGrilla) {
                if (this.dtElement)
                    this.utility.ReCargarGrilla(this.dtElement, this.GetNombreDataTables(), this.componentName);
                else {
                    this.utility.logger.LogErrorText('dtElement es nulo en el conmponente: ' + this.componentName);
                }
            }




            this.ExcecuteFuntion(ControllerMethods.LoadGrid, true, null);
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: '_CargarGrilla', controlName: this.GetNombreDataTables() });
        }
    }


    public ReCargarGrilla() {
        if (this.dtElement)
            this.utility.ReCargarGrilla(this.dtElement, this.GetNombreDataTables(), this.componentName);
        else
            throw 'dtElement es nulo  en el metodo ReCargarGrilla , componente:' + this.componentName;
    }


    public CargarGrilla(filterModel: any) {//,_filterParent: any, mappingFilter: any, filter: any, isModal: boolean, parentOption: any) {

        if (filterModel) {
            this.filterParent = filterModel.filterParent;
            this.isModal = filterModel.isModal;
            this.mappingFilter = filterModel.mappingFilter;
            this.generalFilter = filterModel.filter;
            this.parentOption = filterModel.parentOption;
            if (this.parentOption)
                this.parentOption.CargarGrilla = true;
            this.cargarGrilla = true;
            this._CargarGrilla();
            // OJO  ACTIVAR LA FUNCIONALIDAD DE
            this.comboBoxManager.FilterControlConfigs();
        }
        else {

            this.utility.logger.LogInfoText('filterModel es nulo en CargarGrilla en el componente:  ' + this.componentName);

        }
    }

    public AsignarEventosColumnas(_context: any) {

        
        setTimeout(() => {
            
            try {


                if (_context.isAddEventsColumns && !_context.hideDataTable) {
                    (<any>$('.jtoggler')).jtoggler({
                        onClick: function (event, resultValue) {

                            _context.UpdateProperties(resultValue.index, resultValue.id, resultValue.value, resultValue.prop, false, true, false);


                        }
                    });
                }
                if (!_context.hideDataTable && (_context.isAddEventsColumns || (this.childOptions && this.childOptions.length > 0))) {
                    $.each(_context.columns, function (index: number, column: any) {

                        if (column.config) {


                            if (column.config.childOptions) {
                                let filtro: string = "a[name='" + column.config.name + "']"; //"a[name='" + column.config.name + _context.componentName + "']";
                                $(filtro).off("click");
                                $(filtro).on("click", (event) => {

                                    var data = _context.utility.GetDatosBotonDataTables(event);
                                    var _option = column.config.childOptions[data.data];
                                    _context.OnClickChildOptions(_option, null, data.index);
                                });
                            }
                            else if (!column.config.isThreeState) {
                                let filtro: string = "i[name='" + column.config.name + _context.componentName + "']";
                                $(filtro).off("click");
                                $(filtro).on("click", (event) => {

                                    var data = _context.utility.GetDatosBotonDataTables(event);
                                    _context.UpdateProperties(data.index, data.id, data.data, column.config.propName, true, true, true);
                                });
                            }
                        }

                    });
                }
                /*   setTimeout(() => {
                     
 
                  }, 20); */

                //if(childOptions)
            }
            catch (error) {
                this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AsignarEventosColumnas', controlName: this.GetNombreDataTables() });
            }
        }, 50);



    }
    public AsignarEventosEditarEliminar(_context: any) {
        //const that =this;

        if (_context.isAddCrudColumns && !_context.hideForm && !_context.hideDataTable) {
            setTimeout(() => {

                try {
                    //ELiminar eventos
                    $("[name='" + _context.nombreBotonEditar + "']").off("click");
                    $("[name='" + _context.nombreBotonEliminar + "']").off("click");
                    // Asignar Eventos
                    $("[name='" + _context.nombreBotonEditar + "']").on("click", (event) => {
                        var data = _context.utility.GetDatosBotonDataTables(event);
                        _context.Editar(data.index, data.id);
                    });

                    $("[name='" + _context.nombreBotonEliminar + "']").on("click", (event) => {
                        var data = _context.utility.GetDatosBotonDataTables(event);
                        if (_context.isUnion)
                            _context.Eliminar(data.index, data.id);
                        else
                            _context.Activar(data.index, data.id, data.data);
                    });


                }
                catch (error) {
                    _context.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AsignarEventosEditarEliminar', controlName: this.GetNombreDataTables() });
                }
            }, 50);

        }

        let tempConfig = _context.GetConfigAdicionarColumnasEditarEliminar();
        let columns = new List<any>(_context.columns);
        let seleccinar = columns.Where(x => {
            return x.columnType == DataTableColumnTypes.Selection ||
                (x.columns && new List<any>(x.columns).Any(c => { return c.columnType == DataTableColumnTypes.Selection }))
        }).FirstOrDefault();
        if ((tempConfig && tempConfig.seleccionar) || seleccinar) {
            setTimeout(() => {
                try {

                    $("[name='" + _context.nombreBotonSeleccion + "']").off("click");
                    $("[name='" + _context.nombreBotonSeleccion + "']").on("click", (event) => {
                        var data = _context.utility.GetDatosBotonDataTables(event);
                        _context._Editar(data.index, data.id, null, true);
                    });
                    // $("i[name='" + _context.nombreBotonSeleccion + "']").off("click");
                    // $("i[name='" + _context.nombreBotonSeleccion + "']").on("click", (event) => {
                    //     var data = _context.utility.GetDatosBotonDataTables(event);
                    //     _context._Editar(data.index, data.id, null, true);
                    // });
                }
                catch (error) {
                    _context.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AsignarEventosEditarEliminar', controlName: this.GetNombreDataTables() });
                }
            }, 50);

        }
        _context.AsignarEventosColumnas(_context);

        // _context.ExcecuteFuntion(ControllerMethods.LoadedGrid, true, null);
    }


    public CallbackDataTablesError(context: any, error: any) {



    }
    public CallbackDataTablesOk(context: any, items: any) {
        if (context.isHtmlDataTable)
            context.items = items;

        if (context.pkValueEdit) {
            setTimeout(() => {
                context._Editar(-1, context.pkValueEdit);
            }, 60);
        }
    }

    public GetEditarConfig(): any {
        return {
            nombreBoton: this.nombreBotonEditar,
            title: null,
            toolTip: null,
            icono: null,
            index: (this.columnaEditarEliminarIndex !== null) ? this.columnaEditarEliminarIndex : 0,
            name: null,
        }
    }
    public GetEliminarConfig(): any {
        return {
            nombreBoton: this.nombreBotonEliminar,
            title: null,
            toolTip: null,
            icono: null,
            index: 1,
            name: null,
        }
    }
    public GetSeleccionarConfig(): any {
        return {
            nombreBoton: this.nombreBotonSeleccion,
            title: null,
            toolTip: null,
            icono: null,
            index: 0,
            name: null,
        }
    }

    public GetConfigAdicionarColumnasEditarEliminar(): any {
        if (this.configColumnasEditarEliminar)
            return this.configColumnasEditarEliminar;
        else
            return {
                isHtmlDataTable: this.isHtmlDataTable,
                hideForm: this.hideForm,
                isAddCrudColumns: this.isAddCrudColumns,
                pkName: this.pkName,
                isUnion: this.isUnion,
                eliminar: this.GetEliminarConfig(),
                editar: this.GetEditarConfig(),
                seleccionar: null,// { nombreBoton: this.nombreBotonSeleccion, title: null, toolTip: null, icono: null, index: 0 },
                componentName: this.componentName,
                insert: (this.columnaEditarEliminarIndex !== null),
                optionsIndex: 2,
                columnaEditarEliminar: this.columnaEditarEliminar,

            };

    }

    public GetBooleanColumn(title: string, toolTip: string,
        name: string, pkName: string, propName: string,
        validationPropName: string, isThreeState: boolean = false, contextConfigPermisos?: any, _name?: string): any {

        return this.utility.GetBooleanColumn(this.componentName, title, toolTip, name, pkName, propName,
            validationPropName, isThreeState, (contextConfigPermisos) ? contextConfigPermisos : this, _name);

    }
    public GetColumnaEditarEliminar(icono: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string): any {

        return this.utility.GetColumnaEditarEliminar(this.pkName, 'Acciones', this.isUnion,
            this.GetEditarConfig(), this.GetEliminarConfig(), null, (contextConfigPermisos) ? contextConfigPermisos : this, "DataTables");// this.name);
    }
    public GetColumnaEditarEliminarNombre(_name: string, contextConfigPermisos?: any): any {

        return this.utility.GetColumnaEditarEliminar(this.pkName, 'Acciones', this.isUnion,
            this.GetEditarConfig(), this.GetEliminarConfig(), null, (contextConfigPermisos) ? contextConfigPermisos : this, "DataTables");// this.name);
    }
    public GetColumnaEditar(icono: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string): any {

        return this.utility.GetColumnaEditar(this.pkName, this.nombreBotonEditar, icono, title, toolTip,
            (contextConfigPermisos) ? contextConfigPermisos : this, _name);
    }
    public GetColumnaEditarNombre(_name: string, contextConfigPermisos?: any): any {

        return this.utility.GetColumnaEditar(this.pkName, this.nombreBotonEditar, null, null, null,
            (contextConfigPermisos) ? contextConfigPermisos : this, _name);
    }
    public GetColumnaEliminar(icono: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string): any {
        return this.utility.GetColumnaEliminar(this.pkName, this.nombreBotonEliminar, icono, title, toolTip,
            (contextConfigPermisos) ? contextConfigPermisos : this, _name);
    }
    public GetColumnaActivar(iconoActivo: string = null, iconoInActivo: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string): any {
        return this.utility.GetColumnaActivar(this.pkName, this.nombreBotonEliminar, iconoActivo, iconoInActivo, title, toolTip,
            (contextConfigPermisos) ? contextConfigPermisos : this, _name);
    }
    public GetColumnaSeleccionItem(icono: string = null, title: string = null, toolTip: string = null, contextConfigPermisos?: any, _name?: string): any {
        return this.utility.GetColumnaSeleccionItem(this.pkName, this.nombreBotonSeleccion, icono, title, toolTip,
            (contextConfigPermisos) ? contextConfigPermisos : this, _name);
    }
    public GetColumnaSeleccionItemNombre(_name: string, contextConfigPermisos?: any): any {
        return this.utility.GetColumnaSeleccionItem(this.pkName, this.nombreBotonSeleccion, null, null, null,
            (contextConfigPermisos) ? contextConfigPermisos : this, _name);
    }

    public AdicionarColumasEditarEliminarConfic(_configColumnasEditarEliminar: any, contextConfigPermisos?: any) {
        try {
            this.utility.AdicionarColumasEditarEliminar(this.columns, this.Pagina, this.childOptions, _configColumnasEditarEliminar, (contextConfigPermisos) ? contextConfigPermisos : this);

        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AdicionarColumasEditarEliminar', controlName: this.GetNombreDataTables() });
        }
    }

    public AdicionarColumasEditarEliminar(contextConfigPermisos?: any) {
        try {
            let _configColumnasEditarEliminar = this.GetConfigAdicionarColumnasEditarEliminar();

            this.utility.AdicionarColumasEditarEliminar(this.columns, this.Pagina, this.childOptions, _configColumnasEditarEliminar, (contextConfigPermisos) ? contextConfigPermisos : this);

        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'AdicionarColumasEditarEliminar', controlName: this.GetNombreDataTables() });
        }
    }




    public Exportar(excel: any) {

        console.log('Exportar', excel, this.Pagina);
    }
    public ExportarGrid(excel: any) {

        console.log('Exportar', excel, this.Pagina);
    }
    public ValidarPermisosUsuarioFromItems(dtElements: any, index: any, id: any, permisosField: string): Promise<any> {

        let ok = true;
        const that = this;
        let controlName: string = this.GetNombreDataTables();
        let _pkName = this.pkName;
        return new Promise((resolve, rejection) => {
            try {
                if (!this.configPermisos)
                    resolve(ok);
                else {
                    if (dtElements instanceof QueryList) {
                        dtElements.forEach((dtElement: DataTableDirective, _index: number) => {
                            dtElement.dtInstance.then((dtInstance: any) => {
                                if (dtInstance.table().node().id == controlName) {
                                    let findItem = that.utility.FindItem(dtInstance.data(), _pkName, id);
                                    ok = that.ValidarPermisosUsuario(findItem, permisosField);
                                    resolve(ok);
                                }
                            });
                        });
                    }
                    else {
                        dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
                            let findItem = that.utility.FindItem(dtInstance.data(), _pkName, id);
                            ok = that.ValidarPermisosUsuario(findItem, permisosField);
                            resolve(ok);
                        });
                    }
                }
            }
            catch (error) {
                this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'EditarDataTable' });
                rejection(error);
            }
        });
    }
    public ValidarPermisosUsuario(item: any, permisoField: string): boolean {
        let ok = true;
        if (this.configPermisos) {
            this.configPermisos.Pagina = this.Pagina;
            ok = this.utility.TienePemisoUsuario(this.configPermisos, item, permisoField);
        }
        if (ok == false) {
            this.SetProcesando(false);
            this.utility.VerModalAlertaPermisos().then(data => { }).catch(err => console.log(err));
        }
        return ok;
    }
    public SeleccionarItem(items: any, index: number, id: any) {
        const that = this;
        try {

            this.ExcecuteFuntion(ControllerMethods.SelectedItem, false, { index: index, id: id });
            var findItem = this.utility.FindItem(items, this.pkName, id);
            let ok = this.ValidarPermisosUsuario(findItem, 'Editar');
            if (ok) {
                findItem.PkValue = findItem[this.pkName];
                this.ExcecuteFuntion(ControllerMethods.SelectedItem, true, { findItem: findItem, index: index, id: id });
            }

        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'SeleccionarItem' });
            this.utility.VerModalDanger().then(data => { });
        }
    }

    public _EditarInterno(items: any, index: number, id: any, esSeleccion: boolean = false) {
        
        const that = this;
        try {
            this.ExcecuteFuntion(ControllerMethods.Edit, false, { index: index, id: id });

            //var items =  $scope.dtInstance.DataTable.rows().data();//($scope.IsModal ==true)?$scope.Items: $scope.dtInstance.DataTable.rows().data();
            
            let findItem = this.utility.FindItem(items, this.pkName, id);
            let ok = this.ValidarPermisosUsuario(findItem, 'Editar');
            if (ok) {

                this.modelo = (this.modelo) ? this.utility.SetFieldsValue(this.modelo, findItem) : findItem;
                this.modelo.PkValue = this.modelo[this.pkName];

                //this.SetFocusModelo();
                this.SetProcesando(false);
                
                if (this.HasPropVersionModelData) {
                    this.modeloClone = this.utility.Clone(this.modelo);
                }

                if (this.childComponents && this.childComponents.length > 0) {
                    $.each(this.childComponents, function (key: number, item) {

                        let filterModel = that.utility.GetModelForFilterparent(item.config, findItem);
                        item.component.CargarGrilla(filterModel);

                    });

                }
                this.EditCascading();
                this.ExcecuteFuntion(ControllerMethods.Edit, true, { index: index, id: id });
            }
            else
                this.SetProcesando(false);

        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: '_EditarInterno' });
            this.utility.VerModalDanger().then(data => { });
        }


        /*  if (this.Pagina.IsModalCrud == true)
             this.ModalCrud(); */
    }

    public EditarDataTable(dtElements: any, index: any, id: any, controlName: string, esSeleccion: boolean = false) {
        

        try {
            
            if (dtElements instanceof QueryList) {

                dtElements.forEach((dtElement: DataTableDirective, _index: number) => {

                    dtElement.dtInstance.then((dtInstance: any) => {
                        if (!controlName || dtInstance.table().node().id == controlName) {
                            if (esSeleccion)
                                this.SeleccionarItem(dtInstance.data(), index, id);
                            else
                                this._EditarInterno(dtInstance.data(), index, id);

                        }
                    });
                });
            }
            else {
                dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
                    if (esSeleccion)
                        this.SeleccionarItem(dtInstance.data(), index, id);
                    else
                        this._EditarInterno(dtInstance.data(), index, id);

                });
            }

        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'EditarDataTable' });
            this.utility.VerModalDanger().then(data => { });
        }
    }

    public _Editar(index: number, id: any, dataTablesName: string = null, esSeleccion: boolean = false) {
        
        if (this.isHtmlDataTable) {
            if (esSeleccion)
                this.SeleccionarItem(this.items, index, id);
            else
                this._EditarInterno(this.items, index, id);
        }
        else {
            dataTablesName = (dataTablesName) ? dataTablesName : this.GetNombreDataTables();
            this.EditarDataTable(this.dtElement, index, id, dataTablesName, esSeleccion);
        }
    }

    public Editar(index: number, id: any, event: any = null) {
        try {


            if (this.Pagina.VerComfirmarEditar != true)
                this._Editar(index, id)
            else {
                const that = this;
                this.utility.VerModalConfirmacionEditar().then(modelo => {
                    if (modelo.dialogResult == true)
                        that._Editar(index, id)
                });
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, { inde: index, id: id }, { componentName: this.componentName, method: 'Editar' });
            this.utility.VerModalDanger()
        }
    }


    public SetFocusModelo() {
        $.each(this.modelo, function (key: string, item) {
            setTimeout(() => {
                $('#' + key).focus();
            }, 20);
        });
    }


    public InternalSetModelo(_modelo: any, configPermisos?: any) {

        if (configPermisos)
            this.configPermisos = configPermisos;
        this.modelo = (this.modelo) ? this.utility.SetFieldsValue(this.modelo, _modelo) : _modelo;
        this.modelo.PkValue = this.modelo[this.pkName];
        this.SetProcesando(false);
        this.SetFocusModelo();

        this.EditCascading();
        this.ExcecuteFuntion(ControllerMethods.Edit, true, { index: 0, id: this.modelo.PkValue });
    }

    public SetModelo(_modelo: any, configPermisos?: any, timeout?: number) {

        if (timeout) {
            setTimeout(() => {
                this.InternalSetModelo(_modelo, configPermisos);
            }, timeout);
        }
        else
            this.InternalSetModelo(_modelo, configPermisos);
    }




    public EditCascading() { }

    public CargarComboBox() {

    }
    public onSpecificStart() {

    }

    public SetControlsConfig() {

    }
    public Limpiar(callback: (data: any) => void = null) {

    }
    public SetProcesando(ok: boolean) {
    }

    public SetProcesandoBuscar(ok: boolean) {
    }

    public OnClickChildOptions(option: any, _item: any, index: number) {


        this.utility.GetDataTabledtInstance(this.dtElement, this.GetNombreDataTables(), this.componentName).then(dtInstance => {
            var items = dtInstance.data();
            // var findItem = items[index];
            // var filterParent = this.utility.GetFilterParent(option.filterParent, findItem);
            // var mappingFilter = this.utility.GetFilterParent(option.mappingFilter, findItem);
            // option.Option.ConfigControlsToFilter = this.utility.GetControlFilterParent(option.Option.ControlsToFilter, findItem);

            let windowModel = this.utility.GetModelForFilterparent(option, null, index, items);

            this.ExcecuteFuntion(ControllerMethods.ClickChildOptions, false, { items: items, findItem: windowModel.currentItemParent, filterParent: windowModel.filterParent, mappingFilter: windowModel.mappingFilter, option: option });

            let config: ConfigWindow = new ConfigWindow();
            config.classWindow = 'info';
            config.titleWindow = option.label;
            config.returnInstance = false;
            config.modal = false;
            config.width = 800;
            config.height = 700;
            config.cancel = 'Cerrar';
            config.viewOk = false;
            // let windowModel: any = {
            //     isModal: true,
            //     parentOption: option.Option,
            //     filterParent: filterParent,
            //     mappingFilter: mappingFilter,
            //     generalFilter: option.filter,
            //     hideForm: option.Option.HideForm,
            //     hideDataTable: option.Option.HideDataTable,
            // };

            var componentName: string = (option.Option.Component) ? option.Option.Component : ((option.pagina.ModalUrl) ? option.pagina.ModalUrl : option.pagina.Controlador);
            if (componentName.includes('Controller'))
                componentName = componentName.replace('Controller', 'Component');
            if (!componentName.includes('Component'))
                componentName = componentName + 'Component';
            this.utility.ModalChild(componentName, config, windowModel).then(dataResult => {
                // console.log(dataResult);

                dataResult.onClosePromise.then(dataResultOnClose => {
                    console.log(dataResultOnClose);

                    this.ExcecuteFuntion(ControllerMethods.ClickChildOptions, true, { items: items, findItem: windowModel.currentItemParent, filterParent: windowModel.filterParent, mappingFilter: windowModel.mappingFilter, option: option });
                });

            })
            // Utilidades.ModalChild(option.pagina.ModalUrl, option.pagina.Controlador, function (result) {;
            //     //result.result  es resultado falso o verdadero del boton ok y cancelar / cerrar;
            //     //var ok =result.result;
            //     //result.modelo  es el modelo del formulario
            //     //var modelo =result.modelo;

            //     ExcecuteFuntion(General.Enums.ControllerMethods.ClickChildOptions, true, { items: items, findItem: findItem, filterParent: filterParent, mappingFilter: mappingFilter, option: option });
            // }, filterParent, mappingFilter, option.filter,option.Option);
        });
    }
    preEdit: (modelo: any) => void;
    postEdit: (modelo: any) => void;
    onEdit: (modelo: any) => void;
    preActive: (modelo: any) => void;
    postActive: (modelo: any) => void;
    onActive: (modelo: any) => void;
    preSeleccionItem: (modelo: any) => void;
    postSeleccionItem: (modelo: any) => void;
    onSeleccionItem: (modelo: any) => void;
    onLoadedGridItem: (modelo?: any) => void;

    preClickChildOptions: () => void;
    postClickChildOptions: () => void;
    onClickChildOptions: (modelo: any) => void;

    @Output() preEditEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postEditEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onActiveEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();

    @Output() loadedGridEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() preClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() postClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClickChildOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();

    public ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null): any {

        var result = null;
        switch (controllerMethod) {
            case ControllerMethods.Edit:

                if (!isPost && this.preEdit)
                    this.preEdit(this.modelo);
                else if (isPost == true && this.postEdit)
                    this.postEdit(this.modelo);

                if (this.onEdit)
                    this.onEdit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preEditEmitter.observers.length > 0)
                    this.preEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postEditEmitter.observers.length > 0)
                    this.postEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onEditEmitter.observers.length > 0)
                    this.onEditEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.Active:

                if (!isPost && this.preActive)
                    this.preActive(this.modelo);
                else if (isPost == true && this.postActive)
                    this.postActive(this.modelo);

                if (this.onActive)
                    this.onActive({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preActiveEmitter.observers.length > 0)
                    this.preActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postActiveEmitter.observers.length > 0)
                    this.postActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onActiveEmitter.observers.length > 0)
                    this.onActiveEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
            case ControllerMethods.SelectedItem:

                if (!isPost && this.preSeleccionItem)
                    this.preSeleccionItem(data);
                else if (isPost == true && this.postSeleccionItem)
                    this.postSeleccionItem(data);

                if (this.onSeleccionItem)
                    this.onSeleccionItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preSeleccionItemEmitter.observers.length > 0)
                    this.preSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postSeleccionItemEmitter.observers.length > 0)
                    this.postSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onSeleccionItemEmitter.observers.length > 0)
                    this.onSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                break;
            case ControllerMethods.LoadedGrid:

                if (this.onLoadedGridItem)
                    this.onLoadedGridItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (this.loadedGridEmitter.observers.length > 0)
                    this.loadedGridEmitter.emit({ controlName: this.GetNombreDataTables(), isPost: isPost, data: data, controllerMethod: controllerMethod });
                break;
            case ControllerMethods.ClickChildOptions:

                if (!isPost && this.preClickChildOptions)
                    this.preClickChildOptions();
                else if (isPost == true && this.postClickChildOptions)
                    this.postClickChildOptions();

                if (this.onClickChildOptions)
                    this.onClickChildOptions({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


                if (!isPost && this.preClickChildOptionsEmitter.observers.length > 0)
                    this.preClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
                else if (isPost == true && this.postClickChildOptionsEmitter.observers.length > 0)
                    this.postClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                if (this.onClickChildOptionsEmitter.observers.length > 0)
                    this.onClickChildOptionsEmitter.emit({ modelo: this.modelo, isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

                break;
        }
        return result;
    }


    public VerEditorModelo(tipo: any, tipoModelo: any = 1) {

        this.utility.VerEditor(tipo, this.modelo);

    }


    public OnMetadaEditEventHandler(event) {
        
        this.modeloMetadata = event;
    }

}
