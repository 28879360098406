//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:04/23/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import {
    AdminTodoPermisosComponent
} from "./components/admin-todo-permisos/admin-todo-permisos.component";
import {
    BusquedaExpedienteComponent
} from "./components/expediente/busqueda-expediente/busqueda-expediente.component";
import { ContactoComponent } from "./components/bussines/contacto/contacto.component";
import {
    DetalleExpedienteComponent
} from "./components/expediente/detalle-expediente/detalle-expediente.component";
import { DobleAuthComponent } from "./components/expediente/doble-auth/doble-auth.component";
import {
    EdicionExpedienteComponent
} from "./components/expediente/edicion-expediente/edicion-expediente.component";
import { EstadoCargaComponent } from "./components/bussines/estado/estado-carga.component";
import {
    ExpedientePanelComponent
} from "./components/expediente/expediente-panel/expediente-panel.component";
import { GeneralFormComponent } from "./components/controls/general-form/general-form.component";
import { HomeComponent } from "./components/home/home.component";
import { LTLogAppComponent } from "./components/bussines/ltlog-app/ltlog-app.component";
import { MetadatosComponent } from "./components/bussines/metadatos/metadatos.component";
import { OrgChartJsComponent } from "./components/org-chart/org-chart-js/org-chart-js.component";
import {
    PqrdRecepcionComponent
} from "./components/bussines/solicitudes/pqrs/pqrd-recepcion/pqrd-recepcion.component";
import {
    ProcesoRespuestaFisicoComponent
} from "./components/bussines/solicitudes/salida/proceso-respuesta-fisico/proceso-respuesta-fisico.component";
import { RConsecutivoComponent } from "./components/bussines/rconsecutivo/rconsecutivo.component";
import { RPaginaComponent } from "./components/bussines/rpagina/rpagina.component";
import {
    RTipoDocumentalComponent
} from "./components/bussines/rtipo-documental/rtipo-documental.component";
import { RUsuarioComponent } from "./components/bussines/rusuario/rusuario.component";
import { RRoleComponent } from "./components/bussines/rrole/rrole.component";
import { RGrupoComponent } from "./components/bussines/rgrupo/rgrupo.component";
import {
    RadicadoEntradaRecepcionComponent
} from "./components/bussines/solicitudes/entrada/radicado-entrada-recepcion/radicado-entrada-recepcion.component";
import {
    RadicadoFacturaRecepcionComponent
} from "./components/bussines/solicitudes/factura/radicado-factura-recepcion/radicado-factura-recepcion.component";
import {
    SolicitudEntradaReemplazoComponent
} from "./components/bussines/solicitudes/entrada/solicitud-entrada-reemplazo/solicitud-entrada-reemplazo.component";
import {
    SolicitudFacturaReemplazoComponent
} from "./components/bussines/solicitudes/factura/solicitud-factura-reemplazo/solicitud-factura-reemplazo.component";
import {
    SolicitudPqrdComponent
} from "./components/bussines/solicitudes/pqrs/solicitud-pqrd/solicitud-pqrd.component";
import {
    SolicitudPqrdOficioComponent
} from "./components/bussines/solicitudes/pqrs/solicitud-pqrd-oficio/solicitud-pqrd-oficio.component";
import {
    SolicitudPqrdReemplazoComponent
} from "./components/bussines/solicitudes/pqrs/solicitud-pqrd-reemplazo/solicitud-pqrd-reemplazo.component";
import {
    SolicitudRadicadoEntradaComponent
} from "./components/bussines/solicitudes/entrada/solicitud-radicado-entrada/solicitud-radicado-entrada.component";
import {
    SolicitudRadicadoEntradaOficioComponent
} from "./components/bussines/solicitudes/entrada/solicitud-radicado-entrada-oficio/solicitud-radicado-entrada-oficio.component";
import {
    SolicitudRadicadoFacturaComponent
} from "./components/bussines/solicitudes/factura/solicitud-radicado-factura/solicitud-radicado-factura.component";
import {
    SolicitudRadicadoFacturaOficioComponent
} from "./components/bussines/solicitudes/factura/solicitud-radicado-factura-oficio/solicitud-radicado-factura-oficio.component";
import {
    SolicitudRadicadoMemorandoComponent
} from "./components/bussines/solicitudes/memorando/solicitud-radicado-memorando/solicitud-radicado-memorando.component";
import {
    SolicitudRadicadoSalidaComponent
} from "./components/bussines/solicitudes/salida/solicitud-radicado-salida/solicitud-radicado-salida.component";
import {
    TDocumentoExpedienteComponent
} from "./components/bussines/tdocumento-expediente/tdocumento-expediente.component";
import { TExpedienteComponent } from "./components/bussines/texpediente/texpediente.component";
import { TUbicacionFisicaExpedienteComponent } from './components/bussines/tubicacion-fisica-expediente/tubicacion-fisica-expediente.component';
import {
    TransferenciaExpedienteComponent
} from "./components/expediente/transferencia-expediente/transferencia-expediente.component";
import { SolicitudDocumentosComponent } from "./components/bussines/solicitudes/documento/solicitud-documentos/solicitud-documentos.component";
import { ConfigExtensionesComponent } from "./components/config-extensiones/config-extensiones.component";
import { EliminacionExpedienteComponent } from "./components/expediente/eliminacion-expediente/eliminacion-expediente.component";
import { RtablaRetencionDocumentalComponent } from "./components/bussines/rtabla-retencion-documental/rtabla-retencion-documental.component";
import { REstadoSolicitudComponent } from "./components/bussines/restado-solicitud/restado-solicitud.component";
import { RTipoSolicitudComponent } from "./components/bussines/rtipo-solicitud/rtipo-solicitud.component";
import { RPlantillaUsuarioComponent } from "./components/bussines/rplantilla-usuario/rplantilla-usuario.component";
import { RcuadernoComponent } from "./components/bussines/rcuaderno/rcuaderno.component";
import { RsubserieComponent } from "./components/bussines/rsubserie/rsubserie.component";
import { AnulacionesAprobadasComponent } from "./components/bussines/solicitudes/anulacion/anulaciones-aprobadas/anulaciones-aprobadas.component";
import { RtipoRespuestaPqrdComponent } from "./components/bussines/rtipo-respuesta-pqrd/rtipo-respuesta-pqrd.component";
import { RtemaSolicitudComponent } from "./components/bussines/rtema-solicitud/rtema-solicitud.component";
import { SolicitudPqrdAdjuntosComponent } from "./components/bussines/solicitudes/pqrs/solicitud-pqrd-adjuntos/solicitud-pqrd-adjuntos.component";
import { RcausalrechazoComponent } from "./components/bussines/rcausalrechazo/rcausalrechazo.component";
import { FlujoDocumentosComponent } from "./components/bussines/solicitudes/documento/flujo-documentos/flujo-documentos.component";
import { SolicitudFlujoDocumentalComponent } from './components/bussines/solicitudes/documento/solicitud-flujo-documental/solicitud-flujo-documental.component';
import { BusquedaRadicadoComponent } from "./components/bussines/solicitudes/busqueda-radicado/busqueda-radicado.component";
import { PrestamoDocumentalComponent } from "./components/expediente/prestamo-documental/prestamo-documental.component";
import { RZonaEmpresaComponent } from "./components/bussines/rzona-empresa/rzona-empresa.component";
import { TEncargoComponent } from "./components/bussines/tencargo/tencargo.component";
import { RPlantillaComponent } from "./components/bussines/rplantilla/rplantilla.component";

export const APP_ROUTES: Routes = [
  { path: "", redirectTo: "Home", pathMatch:"full" },
  { path: "OrgChartJs", component: OrgChartJsComponent },
  { path: "Home", component: HomeComponent },
  // { path: "", component: HomeComponent },
  //{ path: "**", component: HomeComponent },

  { path: "DetalleExpediente", component: DetalleExpedienteComponent }, //TExpedienteDetalleComponent  DetalleExpedienteComponent
  { path: "BusquedaExpediente", component: BusquedaExpedienteComponent },
  {
    path: "SolicitarTransferencias",
    component: TransferenciaExpedienteComponent,
  },
  {
    path: "TransferenciasAprobadas",
    component: TransferenciaExpedienteComponent,
  },
  {
    path: "TransferenciasAH",
    component: TransferenciaExpedienteComponent,
  },
  {
    path: "SolicitarEliminacion",
    component: EliminacionExpedienteComponent,
  },
  {
    path: "EliminacionesAprobadas",
    component: EliminacionExpedienteComponent,
  },
  {
    path: "SolicitarEliminacionAC",
    component: EliminacionExpedienteComponent,
  },
  {
    path: "EliminacionesAprobadasAC",
    component: EliminacionExpedienteComponent,
  },
  {
    path: "SolicitarEliminacionAG",
    component: EliminacionExpedienteComponent,
  },
  {
    path: "EliminacionesAprobadasAG",
    component: EliminacionExpedienteComponent,
  },
  {
    path: "SolicitarPrestamos",
    component: PrestamoDocumentalComponent,
  },
  {
    path: "SolicitudesPrestamo",
    component: PrestamoDocumentalComponent,
  },
  {
    path: "SolicitudesPrestamoAC",
    component: PrestamoDocumentalComponent,
  },

  {
    path: "UbicacionFisicaExpediente",
    component: TUbicacionFisicaExpedienteComponent,
  }, // GeneralFormComponent TUbicacionFisicaExpedienteComponent

  { path: "Expediente", component: TExpedienteComponent },
  { path: "ExpedientePanel", component: ExpedientePanelComponent },

  { path: "EstadoCarga", component: EstadoCargaComponent },
  { path: "DocumentoExpediente", component: TDocumentoExpedienteComponent },
  { path: "Usuario", component: RUsuarioComponent }, //RUsuarioComponent

  { path: "TipoSeguridadDocumento", component: GeneralFormComponent }, //RTipoSeguridadDocumentoComponent
  // { path: 'TipoMoneda', component: RTipoMonedaComponent },
  { path: "TipoDocumental", component: RTipoDocumentalComponent }, //RTipoDocumentalComponent
  // { path: 'TipoDisposicion', component: RTipoDisposicionComponent },
  { path: "TipoDatoBasico", component: GeneralFormComponent }, //RTipoDatoBasicoComponent
  { path: "Devolucion", component: GeneralFormComponent }, //RDevolucion

  { path: "TipoAreaEmpresa", component: GeneralFormComponent }, //RTipoAreaEmpresaComponent
  { path: "TemaSolicitud", component: RtemaSolicitudComponent },
  { path: "Tema", component: GeneralFormComponent }, //RTemaComponent
  { path: "TablaRetencionDocumental", component: RtablaRetencionDocumentalComponent }, // RTablaRetencionDocumentalComponent },
  { path: "SubSerie", component: RsubserieComponent }, //RSubSerieComponent
  { path: "Serie", component: GeneralFormComponent }, //RSerieComponent
  { path: "SedeEmpresa", component: GeneralFormComponent }, //RSedeEmpresaComponent
  { path: "ZonaEmpresa", component: RZonaEmpresaComponent }, //RSedeEmpresaComponent
  //{ path: "Role", component: GeneralFormComponent }, //RRoleComponent
  { path: "Role", component: RRoleComponent }, //RRoleComponent

  { path: "Pais", component: GeneralFormComponent }, //RPaisComponent

  { path: "Menu", component: GeneralFormComponent }, //RMenuComponent

  { path: "MedioDeContactoEmpresa", component: GeneralFormComponent }, //RMedioDeContactoEmpresaComponent
  { path: "MedioDeContactoContacto", component: GeneralFormComponent }, //RMedioDeContactoContactoComponent
  //{ path: "Grupo", component: GeneralFormComponent }, //RGrupoComponent
  { path: "Grupo", component: RGrupoComponent }, //RGrupoComponent
  { path: "Empresa", component: GeneralFormComponent }, //REmpresaComponent

  { path: "DiaFestivo", component: GeneralFormComponent },
  { path: "Departamento", component: GeneralFormComponent }, //RDepartamentoComponent
  { path: "DatoBasico", component: GeneralFormComponent }, //RDatoBasicoComponent
  { path: "Contacto", component: ContactoComponent }, //RContactoComponent
  { path: "Consecutivo", component: RConsecutivoComponent },

  { path: "Ciudad", component: GeneralFormComponent }, //RCiudadComponent
  { path: "Carpeta", component: GeneralFormComponent }, //RCarpetaComponent
  { path: "Cuaderno", component: RcuadernoComponent }, //RCarpetaComponent

  { path: "AreaEmpresa", component: GeneralFormComponent }, // RAreaEmpresaComponent },
  { path: "CausalRechazo", component: RcausalrechazoComponent },
  { path: "TipoSolicitud", component: RTipoSolicitudComponent },
  { path: "EstadoSolicitud", component: REstadoSolicitudComponent },
  { path: "EstadoExpediente", component: GeneralFormComponent },

  { path: "TLogApp", component: LTLogAppComponent },
  { path: "EdicionExpediente", component: EdicionExpedienteComponent }, //EdicionExpedienteComponent
  { path: "DobleAuth", component: DobleAuthComponent }, //DobleAuthComponent
  { path: "AdminTodoPermisos", component: AdminTodoPermisosComponent },
  {
    path: "SolicitudRadicadoSalida",
    component: SolicitudRadicadoSalidaComponent,
  },
  {
    path: "SolicitudesAnulaciones",
    component: SolicitudRadicadoSalidaComponent,
  },
  {
    path: "SolicitudesAnulacionesPQRS",
    component: SolicitudPqrdComponent,
  },
  {
    path: "SolicitudesAnulacionesEntrada",
    component: SolicitudRadicadoEntradaComponent,
  },
  {
    path: "SolicitudesAnulacionesFactura",
    component: SolicitudRadicadoFacturaComponent,
  },
  {
    path: "RadicadoPendienteRecibir",
    component: ProcesoRespuestaFisicoComponent,
  },
  //{ path: 'AsignacionEnvios', component: GeneralFormComponent }, //RSerieComponent
  { path: "RecepcionEnvios", component: ProcesoRespuestaFisicoComponent },
  { path: "AsignacionEnvios", component: ProcesoRespuestaFisicoComponent },
  { path: "AsignacionPlanilla", component: ProcesoRespuestaFisicoComponent },
  {
    path: "RadicadoSalidaPendienteOficio",
    component: ProcesoRespuestaFisicoComponent,
  },

  { path: "SolicitudPqrs", component: SolicitudPqrdComponent },
  {
    path: "SolicitudRadicadoEntrada",
    component: SolicitudRadicadoEntradaComponent,
  },
  {
    path: "SolicitudRadicadoFactura",
    component: SolicitudRadicadoFacturaComponent,
  },
  {
    path: "SolicitudRadicadoMemorando",
    component: SolicitudRadicadoMemorandoComponent,
  },
  { path: "Pagina", component: RPaginaComponent },
  { path: "ExternalPQRContact", component: GeneralFormComponent },
  { path: "PqrdPendientesEntrega", component: GeneralFormComponent },
  { path: "RadicadoEntradaPendientesEntrega", component: GeneralFormComponent },
  { path: "RadicadoFacturaPendientesEntrega", component: GeneralFormComponent },
  {
    path: "SolicitudRadicadoEntradaOficio",
    component: SolicitudRadicadoEntradaOficioComponent,
  },
  { path: "SolicitudPqrdOficio", component: SolicitudPqrdOficioComponent },
  { path: "AgregarAdjuntosPQR", component: SolicitudPqrdAdjuntosComponent },
  {
    path: "SolicitudPqrdReemplazo",
    component: SolicitudPqrdReemplazoComponent,
  },
  {
    path: "SolicitudEntradaReemplazo",
    component: SolicitudEntradaReemplazoComponent,
  },
  {
    path: "SolicitudFacturaReemplazo",
    component: SolicitudFacturaReemplazoComponent,
  },
  { path: "PqrdRecepcion", component: PqrdRecepcionComponent },
  {
    path: "SolicitudRadicadoFacturaOficio",
    component: SolicitudRadicadoFacturaOficioComponent,
  },
  {
    path: "RadicadoFacturaRecepcion",
    component: RadicadoFacturaRecepcionComponent,
  },
  {
    path: "RadicadoEntradaRecepcion",
    component: RadicadoEntradaRecepcionComponent,
  },

  { path: "ParametroGeneral", component: MetadatosComponent },
  {
    path: "SolicitudDocumentos",
    component: SolicitudDocumentosComponent,
  },
  {
    path: "SolicitudFlujoDocumental",
    component: SolicitudFlujoDocumentalComponent
  },
  {
    path: "ProcesarSolicitudFlujoDocumental",
    component: SolicitudFlujoDocumentalComponent,
  },
  {
    path: "ProcesarSolicitudDocumento",
    component: SolicitudDocumentosComponent,
  },
  {
    path: "ConfigExtensiones",
    component: ConfigExtensionesComponent,
  },
  { path: "ParametroPQR", component: GeneralFormComponent },
  { path: "TipoRespuestaPQR", component: RtipoRespuestaPqrdComponent },
  { path: "PlantillaUsuario", component: RPlantillaUsuarioComponent },
  { path: "Orientaciones", component: GeneralFormComponent },
  { path: "Correo", component: GeneralFormComponent },

  { path: "TesauroDescriptor", component: GeneralFormComponent },
  { path: "TesauroNoDescriptor", component: GeneralFormComponent },
  { path: "AnulacionesAprobadas", component: AnulacionesAprobadasComponent },
  { path: "AnulacionesAprobadasPQRS", component: AnulacionesAprobadasComponent },
  { path: "AnulacionesAprobadasEntrada", component: AnulacionesAprobadasComponent },
  { path: "AnulacionesAprobadasFactura", component: AnulacionesAprobadasComponent },
  { path: "FlujoDocumentos", component: FlujoDocumentosComponent },

  { path: "BusquedaRadicado", component: BusquedaRadicadoComponent },
  { path: "UsuarioCargo", component: GeneralFormComponent }, //RTipoSeguridadDocumentoComponent
  { path: "Encargo", component: TEncargoComponent },
  { path: "TipoDatoDocumento", component: GeneralFormComponent },
  { path: "ExpedientesConsultaExterna", component: GeneralFormComponent },

  { path: "Plantilla", component: RPlantillaComponent },

  // { path: '**', pathMatch: 'full', redirectTo: 'Home' }
  //{ path: 'TEST', component: GeneralFormComponent },
  // { path: '**', pathMatch: 'full', redirectTo: 'Home' }

  //0005022 No cargan las paginas de "validación de transferencias", "transferencia aprobadas" y "validación eliminaciones"
  //{ path: "**", component: HomeComponent }
];

//treetest
export const AppRoutes: any = RouterModule.forRoot(APP_ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules });
