import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";

import { GeneralComunicationService } from "src/app/services/general-comunication.service";
import { NotificationsPanelComponent } from "../controls/notifications-panel/notifications-panel.component";
import { StaticData } from "../../helpers/static-data";
import { Utilities } from "src/app/helpers/utilities";
import { Router } from "@angular/router";
import { BaseServiceService } from "../../services/base-service.service";
import { CrudActions } from "../../models/general.enum";

declare let fabric: any;

@Component({
    selector: "app-user-nav",
    templateUrl: "./user-nav.component.html",
    styleUrls: ["./user-nav.component.scss"]
})
export class UserNavComponent implements OnInit {
    // @ViewChild("notificationsPanel", { static: false }) notificationsPanel: NotificationsPanelComponent;
    @ViewChildren(NotificationsPanelComponent) notificationsPanelComponents: QueryList<NotificationsPanelComponent>;
    textoLogin = "Iniciar sesión";
    textoLogout = "Cerrar sesión";
    viewColors = false;
    viewNotifications = false;
    notificationsControlConfigs: Array<any> = [];
    //notificationsPanelComponents :Array<NotificationsPanelComponent> = [];
    panelName = "SideNotificationsPanel";
    panelClass = "ms-Panel";
    sidePanel: any;
    SideNotificationsPanel: any;
    openNotifications = false;
    notificationsCount = 0;
    @Input() infoUsuario: any = { Usuario: null, Email: "", Nombre: "Usuario NO Autenticado", Empresa: "Empresa" };
    @Input() inSharePoint = false;
    @Input() ViewLogOut = false;
    dedicatedWorker: Worker;

    //constructor(public utility: Utilities, public comunicationService: GeneralComunicationService, public router: Router){}
    constructor(public utility: Utilities, public comunicationService: GeneralComunicationService, public router: Router, public baseService: BaseServiceService) { }

    public NavegarLogin() {

        if (this.textoLogin === "Iniciar sesión") {
            this.comunicationService.raiseLoginEvent();
        }
        else {
            this.textoLogin = "Iniciar sesión";
            StaticData.Usuario = null;
            StaticData.Estilos = {};
            StaticData.ConfigPaginas = [];
        }
    }


    public LimpiarLogin() {
        this.utility.VerModalConfirmacionGeneral("Cerrar Sesión", "El sistema cerrará la sesión de usuario, ¿esta seguro que desea continuar?").then(modelo => {
            
            if (modelo.dialogResult == true) {

                const obj = this.utility.GetApiModel("CloseUserSession", "", CrudActions.None, null, null);
                this.baseService.CloseUserSession(obj, 
                { componentName: "AppComponent", method: "beforeUnloadHandler", processMessage: "" },
                "AuthenticationBase", false, false).then(data => {
                }).catch(error => {
                    console.log(error);
                });

                StaticData.Usuario = null;
                StaticData.Estilos = {};
                StaticData.ConfigPaginas = [];
                this.notificationsControlConfigs = [];
                this.router.navigate(['Home']);
                location.reload();
            }
        });
    }


    public get IsLoged(): any {
        return (StaticData.Usuario != null);
    }

    public GetName() {
        if (StaticData.Usuario)
            return StaticData.Usuario.Usuario;
        else
            return "Usuario NO Autenticado";
    }
    public GetEmail() {
        if (StaticData.Usuario)
            return StaticData.Usuario.Email;
        else
            return "Usuario NO Autenticado";
    }

    SetDatosUsuario() {
        if (StaticData.Usuario) {
            this.infoUsuario.Nombre = StaticData.Usuario.Nombre;
            this.infoUsuario.Email = StaticData.Usuario.Email;
            this.infoUsuario.Empresa = StaticData.Usuario.Empresa;
        }
        else {
            this.infoUsuario = { Usuario: null, Email: "", Nombre: "Usuario NO Autenticado", Empresa: "Empresa" };
        }
    }

    ngOnInit() {

        this.inSharePoint = StaticData.InSharePoint;
        this.ViewLogOut = StaticData.ViewLogOut;
        if (this.inSharePoint)
            $('#globalNavBox').hide(); //oculto mediante id
        this.SetDatosUsuario();
        this.comunicationService.receivedFormEvent.subscribe(x => {
            if (x.event === "LoginWorkedSet" && x.value === "Usuario") {
                console.log("Received event", { event: x.event, value: x.value });
                this.SetDatosUsuario();
            }
        });


        this.comunicationService.receivedNavItemsEvent.subscribe(data => {
            if (data && data.length > 0) {
                const homePage = this.utility.FindAndConfigPage("Home");
                if (
                    homePage &&
                    homePage.ConfigPage &&
                    Array.isArray(homePage.ConfigPage.HiddenControls)
                ) {
                    this.notificationsControlConfigs = homePage.ConfigPage.HiddenControls.filter(
                        (x) => {
                            return x.ControlType == "NotificationsPanel";
                        }
                    );
                    setTimeout(() => {
                        if (
                            this.notificationsControlConfigs &&
                            this.notificationsControlConfigs.length > 0
                        ) {
                            this.viewNotifications = true;
                            setTimeout(() => {
                                let index = 0;
                                this.notificationsPanelComponents.forEach(
                                    (notificationsPanel) => {
                                        setTimeout(() => {
                                            const notificationsControlConfig: any = this
                                                .notificationsControlConfigs[index];
                                            notificationsPanel.Init(
                                                notificationsControlConfig
                                            );
                                            index++;
                                        }, 600);
                                    }
                                );
                            }, 200);
                        }
                    }, 1000);

                    // if (this.notificationsControlConfig) {
                    //   this.viewNotifications = true;

                    //   setTimeout(() => {

                    //     this.notificationsPanel.Init(this.notificationsControlConfig);
                    //   }, 800);
                    // }
                }
            }
            this.SetDatosUsuario();
        });
        this.comunicationService.receivedLoginEvent.subscribe(data => {

            this.SetDatosUsuario();
        });
        this.comunicationService.receivedLogOutEvent.subscribe(data => {
            this.viewNotifications = false;
            this.SetDatosUsuario();
        });
    }

    OpenSidePanel(config) {

        if (!this.openNotifications) {
            this.openNotifications = !this.openNotifications;
        }
        const notificationsPanel = this.notificationsPanelComponents.find(x => { return x.name == config.Name; });
        notificationsPanel.OpenSidePanel();
        //this.notificationsPanel.OpenSidePanel();
    }
    AfterViewInit() {
        const CommandBarElements = document.querySelectorAll(".ms-CommandBar");
        for (let i = 0; i < CommandBarElements.length; i++) {
            new fabric["CommandBar"](CommandBarElements[i]);
        }

        //this.sidePanel = document.querySelector("#" + this.panelName);
    }

    public SetViewColors() {
        this.viewColors = !this.viewColors;
    }


    public ViewThemeCustomizationComponent() {

        if(StaticData.GeneralConfig.ConfigUI.PersonalizarTema)
        {
            const config = this.utility.GetConfigWindow("personalizar Tema");
            const isNew = false;
            const windowModel: any = {        
            };
    
            this.utility.VerModalComponente("ThemeCustomizationComponent", windowModel, config).then(dataResultOnClose => {
                if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {            
                }
                else {
    
                }
            });
        }
    }

    public OnProcessItemshandler(event, config) {
        // this.notificationsCount = event.count;
    }

}
