/**
 * Checks if the argument passed is an object
 */
export const isObj = (x) => !!x && typeof x === 'object';
/**
 * Determine if two objects are equal
 */
export const equal = (a, b) => Object.entries(a).every(([key, val]) => (isObj(val) ? equal(b[key], val) : b[key] === val));
/**
 * Creates a function that negates the result of the predicate
 */
export const negate = (predicate) => (...args) => !predicate(...args);
/**
 * Comparer helpers
 */
export const composeComparers = (previousComparer, currentComparer) => (a, b) => previousComparer(a, b) || currentComparer(a, b);
export const keyComparer = (_keySelector, descending) => (a, b) => {
    const sortKeyA = _keySelector(a);
    const sortKeyB = _keySelector(b);
    if (sortKeyA > sortKeyB) {
        return !descending ? 1 : -1;
    }
    else if (sortKeyA < sortKeyB) {
        return !descending ? -1 : 1;
    }
    else {
        return 0;
    }
};
