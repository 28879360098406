import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { ConfigBaseTreeComponent, TiposCrearNodos } from '../../../models/config-base-tree-component';
import { Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, QueryConfigIDETypes, TipoParaPermisos } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { ConfigWindow } from '../../../models/config-window';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Guid } from 'guid-typescript';
import { JstreeComponent } from '../../jstree/jstree.component';

declare var $: any;
@Component({
  selector: 'app-js-tree-detalle-expediente',
  templateUrl: './js-tree-detalle-expediente.component.html',
  styleUrls: ['./js-tree-detalle-expediente.component.css']
})
export class JsTreeDetalleExpedienteComponent extends JstreeComponent implements OnInit, AfterViewInit {

  @Input() url: string;
  @Input() documentosExpediente: Array<any>;
  @Input() ublicacionesExpediente: Array<any>;
  @Input() detalleExpediente: any;
  @Input() pageSize: number = 100;
  @Input() skip: number = 0;
  @Input() totalDocumentos: number = 0;
  @Input() agrupadoCarpeta: Array<any>;
  @Input() agrupadoTipoDocumental: Array<any>;
  @Input() agrupadoTipoDocumentalAsignados: Array<any>;
  @Input() agrupadoExtension: Array<any>;
  @Input() tiposDocumentales: Array<any>;
  @Input() tiposDocumentalesAsignados: Array<any> = [];

  vernodoTipoDocumental: boolean = false;

  conteoDocumentos: number = 0;
  listDocumentosExpediente: List<any>
  statusCarga: string;
  isPaginator: boolean;
  ejecucionProceso: boolean = true;
  isFirst: boolean = false;
  numberFiltro: any = 0;
  SearchPaginator: any


  @Input() TotalRegistros: any;
  @Output() endLoadEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() expedinteDetalleLoadesEventEmitter: EventEmitter<any> = new EventEmitter();
  CarpetaExpediente: any[];
  ngAfterViewInit() {
    this.nodeTypesAllowMenu.Add('Serie');
    this.nodeTypesAllowMenu.Add('SubSerie');
    this.nodeTypesAllowMenu.Add('Expediente');
    this.nodeTypesAllowDrop.Add('Expediente');
    this.GeneralLoad();
  }

  public GeneralLoad() {

    this.componentName = (!this.componentName) ? 'JsTreeDetalleExpedienteComponent' : this.componentName;
    const that = this;


    setTimeout(() => {

      this.GetNodosTodoDetalleExpediente();
    }, 200);

  }
  public ReLoad() {
    this.GetNodosTodoDetalleExpediente();
  }

  public GetNodosTodoDetalleExpediente() {

    this.statusCarga = null;
    this.documentosExpediente = [];
    this.ublicacionesExpediente = [];
    this.carepetas = [];
    this.series = [];
    this.subSeries = [];
    this.areasEmpresa = [];
    this.detalleExpediente = null;;
    this.pageSize = 100;
    this.skip = 0;
    this.totalDocumentos = 0;
    this.conteoDocumentos = 0;
    if (!this.parentOption)
      return;
    let nodos: Array<any> = []
    const that = this;
    let obj = this.utility.GetApiModel('GetNodosTodoDetalleExpediente', null)
    obj.QueryConfig.Command = 'BusquedaTodoDetalleExpediente';
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.IsMulti = true;
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdExpediente: this.parentOption.IdExpediente,
      // IdExpediente: this.parentOption.modelo.IdExpediente,
      PageSize: this.pageSize, Skip: this.skip
    };
    this.url = (!this.url) ? 'TExpediente' : this.url;
    //this.processMessage ='Buscando documentos del expediente......';
    let info = {
      componentName: this.componentName, controlName: 'GetNodosTodoDetalleExpediente',
      processMessage: 'Cargando Detalles del expediente......'
    };
    this.baseService.Get(obj, null, info, this.url, false, false).then(data => {


      this.detalleExpediente = data.Data.Table[0];
      // if (this.expedinteDetalleLoadesEventEmitter.observers.length > 0)
      //   this.expedinteDetalleLoadesEventEmitter.emit(this.detalleExpediente);
      this.totalDocumentos = data.Data.Table1[0].CONTEO_TOTAL;
      // this.documentosExpediente = data.Data.Table2;
      this.agrupadoCarpeta = data.Data.Table2;
      this.agrupadoTipoDocumental = data.Data.Table3;
      this.agrupadoExtension = data.Data.Table4;

      //CM
      //this.tiposDocumentales = data.Data.Table5;


      // this.ublicacionesExpediente = data.Data.Table3;
      // this.carepetas = data.Data.Table4;
      // this.areasEmpresa = data.Data.Table5;
      // this.series = data.Data.Table6;
      // this.subSeries = data.Data.Table7;

      this.conteoDocumentos = this.documentosExpediente.length;
      this.listDocumentosExpediente = new List<any>(this.documentosExpediente);
      this.GetJsonTreeData();


    }).catch(err => { });

  }

  public CargarDocumentosPaginados(nodoDocumentos: any): Promise<any> {
    const that = this;
    return new Promise((resolve, rejection) => {
      setTimeout(() => {


        while (this.conteoDocumentos <= this.totalDocumentos) {


          this.skip = this.pageSize;
          this.pageSize = this.pageSize + this.pageSize;
          //this.conteoDocumentos = this.conteoDocumentos + this.skip;
          let obj = this.utility.GetApiModel('CargarDocumentosPaginados', null)
          obj.QueryConfig.Command = 'BusquedaDocumentosExpediente';
          obj.QueryConfig.IsSp = true;
          obj.QueryConfig.Entity = {
            IdEmpresa: -1,
            IdExpediente: this.parentOption.IdExpediente,
            PageSize: this.pageSize, Skip: this.skip,
            Filtro: null //MySQL
          };

          this.url = (!this.url) ? 'TExpediente' : this.url;
          let info = {
            componentName: this.componentName, controlName: 'GetNodosTodoDetalleExpediente',
            processMessage: 'Cargando ' + this.skip + ' documentos del expediente......'
          };
          this.baseService.Get(obj, null, info, this.url, false, false).then(data => {


            let items = data.Data.Table;
            this.conteoDocumentos = this.conteoDocumentos + items.length;
            let configNodeType = { ObjTypeField: 'Extension', NodeTextField: 'Nombre', GroupBy: { ParentField: 'IdCarpeta', NodeTextField: 'Carpeta', ObjType: 'Carpeta' } };
            this.ConvertItemsToNodes(items, nodoDocumentos, configNodeType);

          });



        }

        resolve(nodoDocumentos);
      }, 500);
    });
  }

  public CrearDocumentosPaginados(nodoDocumentos: any, isfirst: boolean = true): Promise<any> {
    const that = this;
    return new Promise((resolve, rejection) => {
      setTimeout(() => {


        this.skip = this.pageSize;
        //this.pageSize = this.pageSize + this.pageSize;
        //this.conteoDocumentos = this.conteoDocumentos + this.skip;
        let obj = this.utility.GetApiModel('CargarDocumentosPaginados', null)
        obj.QueryConfig.Command = 'BusquedaDocumentosExpediente';
        obj.QueryConfig.IsSp = true;
        obj.QueryConfig.Entity = {
          IdEmpresa: -1,
          IdExpediente: this.parentOption.IdExpediente,
          PageSize: this.pageSize, Skip: this.skip,
          Filtro: null //MySQL
        };

        this.url = (!this.url) ? 'TExpediente' : this.url;
        let info = {
          componentName: this.componentName, controlName: 'GetNodosTodoDetalleExpediente',
          processMessage: 'Cargando ' + this.skip + ' documentos del expediente......'
        };
        this.baseService.Get(obj, null, info, this.url, false, false).then(data => {


          let items = data.Data;
          this.conteoDocumentos = this.conteoDocumentos + items.length;
          let configNodeType = { ObjTypeField: 'Extension', NodeTextField: 'Nombre', GroupBy: { ParentField: 'IdCarpeta', NodeTextField: 'Carpeta', ObjType: 'Carpeta' } };
          this.ConvertItemsToNodes(items, nodoDocumentos, configNodeType);
          if (this.conteoDocumentos < this.totalDocumentos)
            this.CrearDocumentosPaginados(nodoDocumentos, false).then(dat => {
              resolve(nodoDocumentos);
            })
          else
            resolve(nodoDocumentos);
        });

      }, 200);
    });
  }

  public CrearDocumentosPaginadosList(isfirst: boolean = true): Promise<any> {
    const that = this;
    return new Promise((resolve, rejection) => {
      setTimeout(() => {


        if (that.statusCarga === 'exit') {
          resolve(true);
        }
        else {
          //
          this.skip = this.pageSize + this.skip;
          //this.pageSize = this.pageSize + this.pageSize;
          //this.conteoDocumentos = this.conteoDocumentos + this.skip;
          let obj = this.utility.GetApiModel('CargarDocumentosPaginados', null)
          obj.QueryConfig.Command = 'BusquedaDocumentosExpediente';
          obj.QueryConfig.IsSp = true;
          obj.QueryConfig.Entity = {
            IdEmpresa: -1,
            IdExpediente: this.parentOption.IdExpediente,
            PageSize: this.pageSize, Skip: this.skip,
            Filtro: null //MySQL
          };

          this.url = (!this.url) ? 'TExpediente' : this.url;
          let info = {
            componentName: this.componentName, controlName: 'GetNodosTodoDetalleExpediente',
            processMessage: '  ' + this.skip + ' documentos del total ' + this.totalDocumentos + ' del expediente ...'
          };
          if (that.statusCarga === 'exit') {
            resolve(true);

          }
          this.baseService.Get(obj, null, info, this.url, false, false).then(data => {


            let items = data.Data;
            let documentos = new List<any>(items);
            this.conteoDocumentos = this.conteoDocumentos + items.length;
            let configNodeType = { ObjTypeField: 'Extension', NodeTextField: 'Nombre', GroupBy: { ParentField: 'IdCarpeta', NodeTextField: 'Carpeta', ObjType: 'Carpeta' } };
            // this.ConvertItemsToNodes(items, null, configNodeType, false);
            this.listDocumentosExpediente.AddRange(items);
            if (this.conteoDocumentos < this.totalDocumentos)
              this.CrearDocumentosPaginadosList(false).then(_documentos => {

                resolve(true);
              })
            else
              resolve(true);
          });
        }

      }, 100);
    });
  }


  public GetJsonTreeData() {
    const that = this;



    this.InternalGetJsonTreData(that);

    // if (this.totalDocumentos > this.listDocumentosExpediente.Count()) {
    //   this.statusCarga = 'start';
    //   console.log('(this.totalDocumentos > this.listDocumentosExpediente.Count())');
    //   setTimeout(() => {
    //     console.log(' this.CrearDocumentosPaginadosList().then(documentos => {');
    //     this.CrearDocumentosPaginadosList().then(documentos => {

    //      
    //       that.InternalGetJsonTreData(that);


    //     });
    //   }, 10000);
    // }



    return this.jsonData;
  }


  // InternalGetJsonTreData(that: JsTreeDetalleExpedienteComponent, isEnd: boolean, jsonProject: any,
  //   nodoResponsables: any,
  //   nodoPermisos: any,
  //   nodoDocumentos: any,
  //   nodoUbicaciones: any,
  //   nodoAgrupadoTiposDocumentos: any,
  //   nodoAgrupadoTiposDocumentales: any,
  //   nodoAgrupadoCarpetas: any,
  //   nodoTablaRetencion: any,
  //   nodoRole: any,
  //   nodoUsuario: any) {
  nodoDocumentos: any = null
  nodoTiposDocumentalesSinAsignar: any = null
  nodoTiposDocumentalesAsignados: any = null
  public CrearNodosDocumentosExpediente(documentosExpediente: Array<any>) {

    if (this.nodoDocumentos && this.jsonData && this.jsonData.length > 0) {
      // this.DeleteNode(this.nodoDocumentos.id);

      this.documentosExpediente = documentosExpediente;


      this.nodoDocumentos.children = [];



      let configNodeType = {
        ObjTypeField: 'Extension', NodeTextField: 'Nombre',
        GroupBy: { ParentField: 'IdCarpeta', NodeTextField: 'Carpeta', ObjType: 'Carpeta' },
        SubGroupBy: {
          ObjTypeField: 'Extension', NodeTextField: 'Nombre',
          GroupBy: { ParentField: 'IdCuaderno', NodeTextField: 'Cuaderno', ObjType: 'Cuaderno', State : 'IdEstado' },
          executeItemsGrupo : true
        },        
      };

      if (!StaticData.GeneralConfig.ConfigValidacionDocumento.NodoCuaderno)
        delete configNodeType.SubGroupBy

      this.ConvertItemsToNodes(this.documentosExpediente, this.nodoDocumentos, configNodeType, true, true, false);

      this.nodoDocumentos.text = 'Documentos Expediente [' + this.documentosExpediente.length.toString() + ']';
      this.expandAllMode = StaticData.GeneralConfig.ConfigValidacionDocumento.expandAllModeTree;
      this.jsonData[0].children.push(this.nodoDocumentos);
      this.selectionMode = true;
      this.InitTree();
      //this.PostCargarDatos();
      /*  this.nodoDocumentos = this.GetNodeByTypeFromTypes('DocumentosExpediente', null,
        'Documentos Expediente [' + this.documentosExpediente.length.toString() + ']', this.jsonData[0].id); */
      // let parentNode = this.jsonData[0];
      // this.AddNode(parentNode.id, this.nodoDocumentos);
    }

  }

  public CrearNodosCarpetas(Carpetas: Array<any>) {

    if (this.jsonData && this.jsonData.length > 0) {
      this.jsonData[0].children = new Array;      
      this.CarpetaExpediente = Carpetas;
      let configNodeType = {
        ObjTypeField: 'Extension', NodeTextField: 'Nombre',
        GroupBy: { ParentField: 'IdCarpeta', NodeTextField: 'Carpeta', ObjType: 'Carpeta' },
        SubGroupBy: {          
          GroupBy: { ParentField: 'IdCuaderno', NodeTextField: 'Cuaderno', ObjType: 'Cuaderno', State : 'IdEstado' },
          executeItemsGrupo : false
        }
      };    
      let nodeCarpeta = this.ConvertItemsToNodes(this.CarpetaExpediente, this.jsonData[0], configNodeType, true, false, false);      
      this.jsonData[0].children.forEach(nodo => {         
        if(nodo.data.NodeText == "FoliadoElectronico")
        nodo.children = [];
      });
      //let NodoFoliado = that.jsonData[0].children.find(x => { return x.text == "FoliadoElectronico"; }).delete;
      this.expandAllMode = StaticData.GeneralConfig.ConfigValidacionDocumento.expandAllModeTree;      
      this.selectionMode = true;
      this.plugins = [];
      this.PostCargarDatos();
      this.InitTree();
      
    }

  }

  public SetTiposDocumentalesSinAsignar(items: Array<any>) {

    items.forEach(item => {

      let node = this.GetNodeForObjectRule({ Name: item.Nombre, ObjectType: "TipoDocumental", GUID: null }, null, null, null);
      node.parentNodeId = this.nodoTiposDocumentalesSinAsignar.id;
      this.AddNode(this.nodoTiposDocumentalesSinAsignar.id, node);
    })
  }

  public SetTiposDocumentalesAsignados(items: Array<any>) {

    items.forEach(item => {

      let node = this.GetNodeForObjectRule({ Name: item.Nombre, ObjectType: "TipoDocumental", GUID: null }, null, null, null);
      node.parentNodeId = this.nodoTiposDocumentalesAsignados.id;
      this.AddNode(this.nodoTiposDocumentalesAsignados.id, node);
    })
  }


  InternalGetJsonTreData(that: JsTreeDetalleExpedienteComponent) {

    // const that = this;    
    let PropiedadNombreNodoExpediente = StaticData.GeneralConfig.ConfigValidacionExpediente.PropiedadNombreNodoExpediente
    //let PropiedadNombreNodoExpediente = "NumeroRadicacionProceso" ///Cambiar por configuracion que esta arriba
    try {
      let jsonProject = null;      
      if(PropiedadNombreNodoExpediente == undefined || PropiedadNombreNodoExpediente == "")
         PropiedadNombreNodoExpediente = "Nombre"
      jsonProject = this.GetNodeByTypeFromTypes('Expediente', this.detalleExpediente, this.detalleExpediente[PropiedadNombreNodoExpediente]);
      let jsonProjectPermisos = this.GetNodeByTypeFromTypes('Expediente', this.detalleExpediente, this.detalleExpediente.Nombre);
      let jsonProjectInfo = this.GetNodeByTypeFromTypes('Expediente', this.detalleExpediente, this.detalleExpediente.Nombre);

      let nodoResponsables = this.GetNodeByTypeFromTypes('Responsables');
      let nodoPermisos = this.GetNodeByTypeFromTypes('Permisos');
      let nodoPalabrasClave = that.GetNodeByTypeFromTypes('PalabrasClave', null, null, jsonProject.id);
      this.nodoDocumentos = this.GetNodeByTypeFromTypes('DocumentosExpediente', null, 'Documentos Expediente [' + this.documentosExpediente.length.toString() + ']', jsonProject.id);
      //let nodoDocumentos = this.GetNodeByTypeFromTypes('DocumentosExpediente', null, 'Documentos Expediente [' + this.documentosExpediente.length.toString() + ']');
      //let nodoUbicaciones = this.GetNodeByTypeFromTypes('UbicacionesExpediente', null, 'Ubicaciones Expediente [' + this.ublicacionesExpediente.length.toString() + ']');
      let nodoAgrupadoTiposDocumentos = this.GetNodeByTypeFromTypes('TiposDocumento', null, 'Agrupado Por Tipos Documentos');

      //CM
      let nodoAgrupadoTiposDocumentales = this.GetNodeByTypeFromTypes('TiposDocumentales', null, 'Agrupado Por Tipos Documentales');
      // let nodoAgrupadoTiposDocumentalesAsignados = this.GetNodeByTypeFromTypes('TiposDocumentales', null, 'Tipos Documentales');
      let nodoAgrupadoCarpetas = this.GetNodeByTypeFromTypes('AgrupadoCarpeta', null, 'Agrupado Por Carpetas');

      let textoTablaRetencion = (this.detalleExpediente.TablaRetencion) ? '[' + this.detalleExpediente.TablaRetencion + ']' : '';
      textoTablaRetencion = textoTablaRetencion + '[Version:' + this.detalleExpediente.VersionTablaRetencion + ']' + this.detalleExpediente.TiempoAnos + 'Años ' + this.detalleExpediente.TiempoMeses + 'Meses ' + this.detalleExpediente.TiempoMeses + 'Dias';
      let nodoTablaRetencion = this.GetNodeByTypeFromTypes('TablaRetencionDocumental', null, textoTablaRetencion);



      this.CrearPermisos(nodoPermisos, this.detalleExpediente, 'Configuracion', false, false);
      let nodoUsuario = null;
      if (this.detalleExpediente.UsuarioResponsable) {
        let _nodoUsuario = this.GetNodeByTypeFromTypes('Usuario', null, this.detalleExpediente.UsuarioResponsable, nodoResponsables.id);
        nodoResponsables.children.push(_nodoUsuario);
        nodoUsuario = this.utility.Clone(_nodoUsuario)
        nodoUsuario.id = Guid.create().toString();
      }
      let nodoRole = null;
      if (this.detalleExpediente.RoleResponsable) {
        let _nodoRole = this.GetNodeByTypeFromTypes('Role', null, this.detalleExpediente.RoleResponsable, nodoResponsables.id);
        nodoResponsables.children.push(_nodoRole);
        nodoRole = this.utility.Clone(_nodoRole);
        nodoRole.id = Guid.create().toString();
      }
      let nodoAreaEmpresaResponsable = null;
      if (this.detalleExpediente.AreaEmpresaResponsable) {
        let _nodoAreaEmpresaResponsable = this.GetNodeByTypeFromTypes('AreaEmpresa', null, this.detalleExpediente.AreaEmpresaResponsable, nodoResponsables.id);
        nodoResponsables.children.push(_nodoAreaEmpresaResponsable);
        nodoAreaEmpresaResponsable = this.utility.Clone(_nodoAreaEmpresaResponsable)
        nodoAreaEmpresaResponsable.id = Guid.create().toString();

      }

      /*    $.each(this.ublicacionesExpediente, function (i, item) {
           var node = that.GetNodeByTypeFromTypes('UbicacionExpediente', item, item.Estante + ' ' + item.Estante);
           node.parentNodeId = nodoUbicaciones.id;
           nodoUbicaciones.children.push(node);
         }); */

      if (this.detalleExpediente.PalabrasClave) {
        let palabras = that.utility.GetPermitidos(this.detalleExpediente, false, 'PalabrasClave', ',');
        for (let i = 0; i < palabras.length; i++) {
          let nodoPalabra = that.GetNodeByTypeFromTypes('PalabraClave', null, palabras[i], nodoPalabrasClave.id);
          nodoPalabrasClave.children.push(nodoPalabra);
        }

      }



      nodoTablaRetencion.children.push(this.GetNodeByTypeFromTypes('AreaEmpresa', null, this.detalleExpediente.AreaEmpresa, nodoTablaRetencion.id));
      nodoTablaRetencion.children.push(this.GetNodeByTypeFromTypes('Serie', null, this.detalleExpediente.Serie, nodoTablaRetencion.id));
      nodoTablaRetencion.children.push(this.GetNodeByTypeFromTypes('SubSerie', null, this.detalleExpediente.SubSerie, nodoTablaRetencion.id));

      ////////////////////////////////////////////////////////



      /*  this.documentosExpediente = this.listDocumentosExpediente.ToArray();
       let configNodeType = {
         ObjTypeField: 'Extension', NodeTextField: 'Nombre', ConfigField: 'Configuracion',
         GroupBy: { ParentField: 'IdCarpeta', NodeTextField: 'Carpeta', ObjType: 'Carpeta' }
       };
       this.ConvertItemsToNodes(this.documentosExpediente, nodoDocumentos, configNodeType); */


      /* let itemsGroupByList = this.listDocumentosExpediente.GroupBy(x => { return x.Extension });
      $.each(itemsGroupByList, function (group, itemsGroup) {
        let groupNodeText = group.toString(); //(configNodeType.GroupBy.NodeTextField) ? itemsGroup[0][configNodeType.GroupBy.NodeTextField] : group.toString();
        let groupObjType = group.toString();
        let nodoGrupo = that.GetNodeByTypeFromTypes(groupObjType, null, itemsGroup.length.toString());
        nodoAgrupadoTiposDocumentos.children.push(nodoGrupo);
      }); */


      $.each(this.agrupadoExtension, function (index, grupo) {
        let nodoGrupo = that.GetNodeByTypeFromTypes(grupo.Tipo, null, grupo.Total.toString(), nodoAgrupadoTiposDocumentos.id);
        nodoAgrupadoTiposDocumentos.children.push(nodoGrupo);
      });

      //CM

      if (this.vernodoTipoDocumental) {
        $.each(this.agrupadoTipoDocumental, (index, grupo) => {
          let nodoGrupo = that.GetNodeByTypeFromTypes('TipoDocumental', null, grupo.Tipo + ' [' + grupo.Total.toString() + ']', nodoAgrupadoTiposDocumentales.id);
          nodoAgrupadoTiposDocumentales.children.push(nodoGrupo);
          this.tiposDocumentalesAsignados.push(grupo.Tipo);
        });
        jsonProject.children.push(nodoAgrupadoTiposDocumentales);
      }



      // $.each(this.agrupadoTipoDocumentalAsignados, function (index, grupo) {
      //   let nodoGrupo = that.GetNodeByTypeFromTypes('TipoDocumental', null, grupo.Tipo + ' [' + grupo.Total.toString() + ']', nodoAgrupadoTiposDocumentalesAsignados.id);
      //   nodoAgrupadoTiposDocumentalesAsignados.children.push(nodoGrupo);
      // });

      $.each(this.agrupadoCarpeta, function (index, grupo) {
        //let nodoGrupo = that.GetNodeByTypeFromTypes('Carpeta', null, grupo.Tipo + ' [' + grupo.Total.toString() + ']');
        let nodoGrupo = (grupo.Tipo != 'null' && grupo.Tipo) ? that.GetNodeByTypeFromTypes('Carpeta', null, grupo.Tipo + ' [' + grupo.Total.toString() + ']', nodoAgrupadoCarpetas.id) :
          that.GetNodeByTypeFromTypes('Nulo', null, 'Sin Carpeta [' + grupo.Total.toString() + ']', nodoAgrupadoCarpetas.id);
        nodoAgrupadoCarpetas.children.push(nodoGrupo);
      });


      // let itemsGroupByTiposDocumentalesList = this.listDocumentosExpediente.GroupBy(x => { return x.TipoDocumental });
      // $.each(itemsGroupByTiposDocumentalesList, function (group, itemsGroup) {
      //   let groupNodeText = group.toString(); //(configNodeType.GroupBy.NodeTextField) ? itemsGroup[0][configNodeType.GroupBy.NodeTextField] : group.toString();
      //   let groupObjType = group.toString();
      //   let nodoGrupo = that.GetNodeByTypeFromTypes('TipoDocumental', null, groupNodeText + ' [' + itemsGroup.length.toString() + ']');
      //   nodoAgrupadoTiposDocumentales.children.push(nodoGrupo);
      // });
      // let itemsGroupByCarpetasList = this.listDocumentosExpediente.GroupBy(x => { return x.Carpeta });
      // $.each(itemsGroupByCarpetasList, function (group, itemsGroup) {
      //   let groupNodeText = group.toString(); //(configNodeType.GroupBy.NodeTextField) ? itemsGroup[0][configNodeType.GroupBy.NodeTextField] : group.toString();
      //   let groupObjType = group.toString();
      //   let nodoGrupo = (groupNodeText != 'null') ? that.GetNodeByTypeFromTypes('Carpeta', null, groupNodeText + ' [' + itemsGroup.length.toString() + ']') :
      //     that.GetNodeByTypeFromTypes('Nulo', null, 'Sin Carpeta [' + itemsGroup.length.toString() + ']');
      //   nodoAgrupadoCarpetas.children.push(nodoGrupo);
      // });




      // jsonProject.children.push(nodoResponsables);
      // jsonProject.children.push(nodoPermisos);

      //jsonProject.children.push(nodoDocumentos);
      //jsonProject.children.push(nodoUbicaciones);
      // jsonProject.children.push(nodoAgrupadoTiposDocumentos);

      //CM    
      // jsonProject.children.push(nodoAgrupadoTiposDocumentalesAsignados);

      // jsonProject.children.push(nodoAgrupadoCarpetas);
      // jsonProject.children.push(nodoTablaRetencion);

      let nodoExpedinteAreas = this.GetNodeByTypeFromTypes('Expediente', this.detalleExpediente, this.detalleExpediente.Nombre);
      let nodoExpedinteSeries = this.GetNodeByTypeFromTypes('Expediente', this.detalleExpediente, this.detalleExpediente.Nombre);
      let configAreas = {

        nodeConfig: [{ id: this.detalleExpediente.IdAreaEmpresa, fieldItem: 'UniqueID', node: nodoExpedinteAreas }],
        nodeConfigEspecific: []
      };


      if (this.detalleExpediente.IdAreaEmpresaResponsable) {

        configAreas.nodeConfigEspecific.push({
          id: this.detalleExpediente.IdAreaEmpresaResponsable,
          fieldItem: 'UniqueID', objType: 'AreaEmpresa', responsableTexto: '',
          tags: ['Responsable']
        });
      }

      if (this.detalleExpediente.IdAreaEmpresaRoleResponsable) {

        // nodoRole.text = 'Role Responsable [' + nodoRole.text + ']';
        nodoRole.tags = ['Responsable'];
        configAreas.nodeConfig.push({ id: this.detalleExpediente.IdAreaEmpresaRoleResponsable, fieldItem: 'UniqueID', node: nodoRole });

        configAreas.nodeConfigEspecific.push({
          id: this.detalleExpediente.IdAreaEmpresaRoleResponsable,
          fieldItem: 'UniqueID', objType: 'AreaEmpresa',
          tags: ['Responsable']
        });
      }
      if (this.detalleExpediente.IdAreaEmpresaUsuarioResponsable) {
        // nodoUsuario.text = 'Usuario Responsable [' + nodoUsuario.text + ']';
        nodoUsuario.tags = ['Responsable'];
        configAreas.nodeConfig.push({ id: this.detalleExpediente.IdAreaEmpresaUsuarioResponsable, fieldItem: 'UniqueID', node: nodoUsuario });

        configAreas.nodeConfigEspecific.push({
          id: this.detalleExpediente.IdAreaEmpresaUsuarioResponsable,
          fieldItem: 'UniqueID', objType: 'AreaEmpresa',
          tags: ['Responsable']
        });
      }
      //nodeConfig

      //this.CreateNodeTree(jsonProject, StaticData.AreasEmpresa.ToArray(), 'AreasEmpresa', that, TiposCrearNodos.Recursiva, configAreas);

      let _configAreas = { Id: this.detalleExpediente.IdAreaEmpresa };
      // this.CreateNodeTree(jsonProject, StaticData.AreasEmpresa.ToArray(), 'AreasEmpresa', that, TiposCrearNodos.RecursivaInversa, _configAreas,
      //   null, null, (nodeTypeData, item, context, node, childrenItems) => {

      //     const nodeExpedineteArea = this.GetNodeByTypeFromTypes('Expediente', null, this.detalleExpediente.Nombre, node.id);
      //     node.children.push(nodeExpedineteArea);

      //     return true;

      //   });

      let configSeries = {
        items: StaticData.SubSeries.ToArray(),
        idParent: 'IdParent',
        idParentExternal: 'IdSerie',
        nodeTypeData: this.utility.GetNodeType('SubSeries'),
        TiposCrearNodo: TiposCrearNodos.Recursiva,
        nodeConfig: [{ id: this.detalleExpediente.IdSubSerie, fieldItem: 'IdSubSerie', node: nodoExpedinteSeries }]
      };

      // this.CreateNodeTree(jsonProject, StaticData.Series.ToArray(), 'Series', that, TiposCrearNodos.Normal, configSeries);
      // let configCarpetas = {

      //   count: { items: this.listDocumentosExpediente, field: 'Carpeta', fieldItem: 'NodeText' }
      // };
      // this.CreateNodeTree(jsonProject, StaticData.Carpetas.ToArray(), 'Carpetas', that, TiposCrearNodos.Recursiva, configCarpetas);

      //CM
      this.nodoTiposDocumentalesSinAsignar = this.GetNodeByTypeFromTypes('TiposDocumentales', null, 'Tipos Documentales sin Asignar', jsonProject.id);

      $.each(this.tiposDocumentales, function (index, item) {
        this.nodoTiposDocumentales.children.push(that.GetNodeByTypeFromTypes('TipoDocumental', null, item.Nombre, this.nodoTiposDocumentales.id));
      });

      //CM
      this.nodoTiposDocumentalesAsignados = this.GetNodeByTypeFromTypes('TiposDocumentales', null, 'Tipos Documentales Asignados', jsonProject.id);


      // $.each(this.tiposDocumentalesAsignados, function (index, item) {
      //   this.nodoTiposDocumentalesAsignados.children.push(that.GetNodeByTypeFromTypes('TipoDocumental', null, item.Nombre, this.nodoTiposDocumentales.id));
      // });


      // $.each(this.tiposDocumentalesAsignados,  (index, item)=> {
      //   this.nodoTiposDocumentalesAsignados.children.push(that.GetNodeByTypeFromTypes('TipoDocumental', null, item.Nombre, this.nodoTiposDocumentalesAsignados.id));
      // });



      // jsonProject.children.push(nodoPalabrasClave);
      let countSinAsignar = 0;
      //MG
      // this.tiposDocumentalesAsignados.forEach(item => {
      //   this.nodoTiposDocumentalesAsignados.children.push(that.GetNodeByTypeFromTypes('TipoDocumental', null, item, this.nodoTiposDocumentalesAsignados.id));
      // })
      // if (this.detalleExpediente.ConfiguracionTRD) {
      //   let ConfiguracionTRD = JSON.parse(this.detalleExpediente.ConfiguracionTRD);
      //   if (ConfiguracionTRD && ConfiguracionTRD.TiposDocumentales) {

      //     ConfiguracionTRD.TiposDocumentales.forEach(item => {
      //       if (!this.tiposDocumentalesAsignados.find(x => { return x == item.Nombre })) {
      //         countSinAsignar++;
      //         this.nodoTiposDocumentalesSinAsignar.children.push(that.GetNodeByTypeFromTypes('TipoDocumental', null, item.Nombre, this.nodoTiposDocumentalesSinAsignar.id));
      //       }
      //     })
      //   }
      //   else
      //     countSinAsignar = this.tiposDocumentalesAsignados.length;
      // }
      // else
      //   countSinAsignar = this.tiposDocumentalesAsignados.length;

      this.nodoTiposDocumentalesSinAsignar.text = "Tipos Documentales sin Asignar [" + countSinAsignar + "]";

      this.nodoTiposDocumentalesAsignados.text = "Tipos Documentales Asignados [" + this.tiposDocumentalesAsignados.length + "]";
      //CM
      // jsonProject.children.push(this.nodoTiposDocumentalesSinAsignar);
      // jsonProject.children.push(this.nodoTiposDocumentalesAsignados);

      // jsonProject.children.push(this.nodoDocumentos);

      /* jsonProjectInfo.children.push(nodoResponsables);
      jsonProjectInfo.children.push(nodoAgrupadoTiposDocumentos);
      jsonProjectPermisos.children.push(nodoPermisos); */


      //orgNodosPermisos
      this.jsonData = [jsonProject];
      this.SetcontextMenuItems();
      this.PostCargarDatos();
      this.InitTree();      
    }
    catch (error) {

      console.log(error);
      this.utility.logger.LogError(error, '');

    }
    // if (this.endLoadEventEmitter.observers.length > 0)
    //   this.endLoadEventEmitter.emit({ orgNodosPermisos: [jsonProjectPermisos], orgNodos: [jsonProjectInfo] });

  }
  public SetcontextMenuItems() {

    if (!this.contextMenuItems)
      this.contextMenuItems = this.GetCustomMenu;
  }

  public EsExpediente(node: any): boolean {

    return (node.data && node.data &&
      (node.data.ObjType == 'Expediente'
      ));
  }
  public GetMenuAdministracion(node: any, context?: any): any {

    const that = (context) ? context : this;

    // that.AccionBasicaMenu('Editar', node)
    //return;
    let items;
    items = {
      verVentana: { // The "rename" menu item
        label: "Ver Administracion",
        icon: that.utility.GetPathImages('admin_page.png', context),
        action: function () {
          that.AccionBasicaMenu('VerAdministracion', node)
        }
      }
    }
    return items;
  }
  public GetCustomMenu(node: any, context?: any): any {
    var items: any;
    const that = (context) ? context : this;    
    if (node) {
      //
      // items = that.GetBasicMenus(node);

      // if (that.EsExpediente(node)) {
      //   debugger
      //   items = {
      //     recargar: { // The "rename" menu item
      //       label: "Recargar",
      //       icon: that.utility.GetPathImages('refresh.png'),
      //       action: function () {
      //         that.ReLoad()
      //       }
      //     },
      //     iniciar: { // The "rename" menu item
      //       label: "ReIniciar",
      //       icon: that.utility.GetPathImages('refresh.png'),
      //       action: function () {
      //         that.GetJsonTreeData()
      //       }
      //     },
      //     detrner: { // The "rename" menu item
      //       label: "Detener",
      //       icon: that.utility.GetPathImages('refresh.png'),
      //       action: function () {
      //         that.statusCarga = 'exit'
      //       }
      //     }
      //   }

      // }

    }
    // else if (that.EsTipoPrimerNivel(node))
    //   items = that.GetMenuAdministracion(node);

    return items;
  }

  // jsonProject.children.push(nodoResponsables);
  // jsonProject.children.push(nodoPermisos);
  // jsonProject.children.push(nodoDocumentos);
  // jsonProject.children.push(nodoUbicaciones);
  // jsonProject.children.push(nodoAgrupadoTiposDocumentos);
  // jsonProject.children.push(nodoAgrupadoTiposDocumentales);
  // jsonProject.children.push(nodoAgrupadoCarpetas);
  // jsonProject.children.push(nodoTablaRetencion);


  public ChangedTree(e: any, data: any) {
    if (this.treeClickChange.observers.length > 0) {
      var _data = { e: e, data: data, isSelectNode: (data && data.action == "select_node") ? true : false };
      if ((data && data.action == "select_node")) {
        if (data.node.data && data.node.data.ObjType === 'Responsables') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[0]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'Permisos') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[1]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'TiposDocumento') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[2]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'TiposDocumentales') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[3]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'AgrupadoCarpeta') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[4]) });
        }
        //
        else if (data.node.data && data.node.data.ObjType === 'TablaRetencionDocumental') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[5]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'AreasEmpresa') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[6]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'Series') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[7]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'SubSeries') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[7]) });
        }
        else if (data.node.data && data.node.data.ObjType === 'Carpetas') {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[8]) });
        }
        else if (data.node.data && (data.node.data.ObjType === 'Carpeta' || data.node.data.ObjType === 'Cuaderno')) {
          data.selectionMode = this.selectionMode
          var a = this.jsonData[0].children.filter((item) => item.id == data.node.id);
          this.treeClickChange.emit({ data: data, extraData: a[0] });
        }
        else if (data.node.data && (data.node.data.ObjType === 'Expediente'))
        {
          data.selectionMode = this.selectionMode
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0]) });
        }
      }
      else if (data && data.action == "deselect_node"){        
          data.selectionMode = this.selectionMode
          if (data.node.data && (data.node.data.ObjType === 'Carpeta' || data.node.data.ObjType === 'Cuaderno')) 
          {
            var a = this.jsonData[0].children.filter((item) => item.id == data.node.id);
            this.treeClickChange.emit({ data: data, extraData: a[0] });
          }
          else if (data.node.data && (data.node.data.ObjType === 'Expediente'))
          {
          this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0]) });
          }
          else{
            this.treeClickChange.emit({ data: data, extraData: this.utility.Clone(this.jsonData[0].children[0]) });
          }
      }
        
    }
    else
      console.log(data.selected);
  }

  public DoubleClickTree(node: any) {

  }

  public CrearDocumentosPaginador = function (nodoDocumentos: any, isfirst: boolean = false, numberpage: any = 0, numberFiltro: any = 0, ejecutaCreacionDocumentos: boolean = true, search?: any) {
    const that = this;
    that.isPaginator = ejecutaCreacionDocumentos
    that.isFirst = isfirst;
    that.numberFiltro = numberFiltro;
    that.SearchPaginator = search
    let TodosPermisosDocumentos = false;
    if (StaticData.Usuario.IdUsuario == this.parentOption.IdUsuarioResponsable)
      TodosPermisosDocumentos = true;
    let obj = this.utility.GetApiModel('CargarDocumentosPaginados', null)
    //obj.QueryConfig.Command = 'BusquedaDocumentosExpedienteCrud';
    obj.QueryConfig.Command = 'BusquedaCarpetaExpediente';
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.WithPagination = true
    obj.QueryConfig.OutPutParam = true
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdExpediente: this.parentOption.IdExpediente,
      //TodosPermisosDocumentosExpedientes: TodosPermisosDocumentos,
      Filtro: null //MySQL
    };
    obj.PaginationSettings = new Object();
    obj.PaginationSettings.notLoad = false
    obj.PaginationSettings.SearchValue = search
    obj.PaginationSettings.PageSize = numberFiltro > 0 ? numberFiltro : 5
    that.pageSize = obj.PaginationSettings.PageSize
    if (numberpage == 0 || numberpage == 1)
      obj.PaginationSettings.skip = 0
    else
      obj.PaginationSettings.skip = (numberpage - 1) * that.pageSize

    obj.PaginationSettings.SortOrderColumn = 1
    obj.PaginationSettings.SortColumnDir = "desc"
    // obj.PaginationSettings.draw = (numberpage > 0) ? numberpage : ""
    this.url = (!this.url) ? 'TExpediente' : this.url;
    let info = {
      componentName: this.componentName, controlName: 'GetNodosTodoDetalleExpediente',
      processMessage: 'Cargando los documentos del expediente......'
    };
    this.baseService.Get(obj, null, info, this.url, false, false).then(data => {
      // if (that.expedinteDetalleLoadesEventEmitter.observers && that.expedinteDetalleLoadesEventEmitter.observers.length > 0 && isFirst )
      //   that.expedinteDetalleLoadesEventEmitter.emit(data);
      let recordsTotal = Math.round((data.recordsTotal + that.pageSize) / that.pageSize);
      let TotalRecords = that.SearchPaginator != '' || that.SearchPaginator != null ? data.recordsFiltered : data.recordsTotal
      let VisiblePages;
      for (var i = 1; i <= TotalRecords; i++) {
        TotalRecords = TotalRecords - that.pageSize
        if (TotalRecords > 1 && TotalRecords <= that.pageSize) {
          recordsTotal = i + 1
          TotalRecords = 1;
        }
        else if (TotalRecords < 1) {
          recordsTotal = 1
          TotalRecords = 1;
        }
      }

      if (recordsTotal > 6)
        VisiblePages = 6
      else
        VisiblePages = recordsTotal
      // recordsTotal = recordsTotal > 1 ? recordsTotal : 1;
      that.TotalRegistros = data.recordsTotal;
      $("#id_paginator").twbsPagination({
        totalPages: recordsTotal,
        visiblePages: VisiblePages,
        prev: '<span class="fa fa-step-backward"></span>',
        next: '<span class="fa fa-step-forward"></span>',
        last: '<span class="fa fa-forward"></span>',
        first: '<span class="fa fa-backward"></span>',
        onPageClick: function (event, page) {
          if (that.isPaginator) {
            let nodoDocumentos: any = null
            that.CrearDocumentosPaginador(nodoDocumentos, false, page, that.numberFiltro, false);
          }
          that.isPaginator = true
        }
      }, 
      that.CrearNodosCarpetas(data.data));
      //that.CrearNodosDocumentosExpediente(data.data));
    });
    // });
  }
}


