import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, DebugElement } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { ConfigBaseComponent } from './config-base-component';
import {
    Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, QueryConfigIDETypes, TipoParaPermisos
} from './general.enum';

import { BaseServiceService } from '../services/base-service.service';
import { ModalNotifyComunicationService } from '../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../assets/linqts/compilado/index';
import { StaticData } from '../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { ConfigWindow } from './config-window';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Guid } from 'guid-typescript';



export enum TiposCrearNodos {
    Normal = 0,
    Recursiva = 1,
    NormalHijosExternos = 2,
    RecursivaHijosExternos = 3,
    ListadoNodos = 4,
    RecursivaInversa = 5,
}

export class ConfigBaseTreeComponent extends ConfigBaseComponent implements OnInit, AfterViewInit {

    @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
    currentNode: any = {};
    @Input() modelItems: any = { items: [] };
    @Input() name: string = 'jstree-bext_doc';
    @Input() jsonData: any = [];
    @Input() initTime: number = 0;
    @Input() contextMenuItems: any;
    @Input() queryConfigIDEType: QueryConfigIDETypes = QueryConfigIDETypes.Ninguno;
    @Input() autoLoad: boolean = true;
    @Input() activo: boolean = true;
    @Input() currentProject: any = {};
    @Input() paginasProcesadas: Array<any>;
    @Input() paginas: Array<any>;
    @Input() paginasRoles: Array<any>;
    @Input() controles: Array<any>;
    @Input() controlesRoles: Array<any>;
    @Input() roles: Array<any>;
    @Input() querys: Array<any>;
    @Input() reglas: Array<any>;
    @Input() areasEmpresa: Array<any>;
    @Input() series: Array<any>;
    @Input() subSeries: Array<any>;
    @Input() tiposDocumentales: Array<any>;
    @Input() tablasRetencionDocumental: Array<any>;

    @Input() aplicacionesPermitidas: Array<any>;
    @Input() grupos: Array<any>;
    @Input() expedinetes: Array<any>;
    @Input() tiposSolicitud: Array<any>;
    @Input() carepetas: Array<any>;
    @Input() estadosSolicitud: Array<any>;



    @Input() nodesForUpdate: Array<any>;
    //pathImages = '../assets/images/jstree/';

    @Output() treeClickChange = new EventEmitter<any>();
    @Output() moveNodeChange = new EventEmitter<any>();

    @Input() selectionMode: boolean = false;
    @Input() expandAllMode: boolean = false;
    @Input() selectedNodes = new List<any>([]);
    @Input() notSelectablesNodesTypes = new List<any>([]);
    @Output() selectedNodeEventEmitter = new EventEmitter<any>();
    @Input() autoInit: boolean = false;

    @Input() parentOption: any;
    externalFilter: any = null;
    filterParent: any = null;
    mappingFilter: any = null;
    generalFilter: any = null;
    isModal: boolean = false;
    isLoaded: boolean = false;
    filterModelTemp: any = null;
    idQuery: any = null;
    currentItemParent: any = null;

    nodeTypesAllowDrop = new List<any>([]);
    nodeTypesAllowMenu = new List<any>([]);

    items: Array<any> = [];




    public InitTree() {

    }


    public EsTipoSegundoNivel(node: any): boolean {

        return (node.data && node.data &&
            (node.data.ObjType == 'Formulario' ||
                node.data.ObjType == 'Role' ||
                node.data.ObjType == 'Query' ||
                node.data.ObjType == 'AreaEmpresa' ||
                node.data.ObjType == 'Serie' ||
                node.data.ObjType == 'SubSerie' ||
                node.data.ObjType == 'TipoDocumental' ||
                node.data.ObjType == 'TablaRetencionDocumental' ||
                node.data.ObjType == 'AplicacionPermitida' ||
                node.data.ObjType == 'Grupo' ||
                node.data.ObjType == 'Expediente' ||
                node.data.ObjType == 'TipoSolicitud' ||
                node.data.ObjType == 'Carpeta' ||
                node.data.ObjType == 'EstadoSolicitud'
            ));
    }
    public EsTipoPrimerNivel(node: any): boolean {

        return (node.data && node.data &&
            (node.data.ObjType == 'Formularios' ||
                node.data.ObjType == 'Roles' ||
                node.data.ObjType == 'Querys' ||
                node.data.ObjType == 'AreasEmpresa' ||
                node.data.ObjType == 'Series' ||
                node.data.ObjType == 'SubSeries' ||
                node.data.ObjType == 'TiposDocumentales' ||
                node.data.ObjType == 'TablasRetencionDocumental' ||
                node.data.ObjType == 'AplicacionesPermitidas' ||
                node.data.ObjType == 'Grupos' ||
                node.data.ObjType == 'Expedientes' ||
                node.data.ObjType == 'TiposSolicitud' ||
                node.data.ObjType == 'Carpetas' ||
                node.data.ObjType == 'EstadosSolicitud'
            ));
    }
    // public GetNodeType(objType: string, setGuid: boolean = true): any {

    //     let nodeType = new List<any>(this.localNodeTypes).Where(x => { return x.ObjType === objType }).FirstOrDefault();
    //     // if (setGuid)
    //     //     nodeType.UniqueID = Guid.create().toString();
    //     return nodeType;
    // }




    public GetDataNewNode(parent: any) {
        let data = { isNew: true, ObjType: '' };
        if (!parent)
            return data;
        switch (parent.data.ObjType) {
            case 'Expedientes':
            case 'Expediente':
                data.ObjType = 'Expediente';
                break;
            case 'TablasRetencionDocumental':
            case 'TablaRetencionDocumental':
                data.ObjType = 'TablaRetencionDocumental';
                break;
            case 'TiposDocumentales':
            case 'TipoDocumental':
                data.ObjType = 'TipoDocumental';
                break;
            case 'SubSeries':
            case 'SubSerie':
                data.ObjType = 'SubSerie';
                break;
            case 'Series':
            case 'Serie':
                data.ObjType = 'Serie';
                break;
            case 'AreasEmpresa':
            case 'AreaEmpresa':
                data.ObjType = 'AreaEmpresa';
                break;
            case 'EstadosSolicitud':
            case 'EstadoSolicitud':
                data.ObjType = 'EstadoSolicitud';
                break;
            case 'TiposSolicitud':
            case 'TipoSolicitud':
                data.ObjType = 'TipoSolicitud';
                break;
            case 'Carpetas':
            case 'Carpeta':
                data.ObjType = 'Carpeta';
                break;
            case 'Roles':
            case 'Role':
                data.ObjType = 'Role';
                break;
            case 'Grupos':
            case 'Grupo':
                data.ObjType = 'Grupo';
                break;
            case 'AplicacionesPermitidas':
            case 'AplicacionPermitida':
                data.ObjType = 'AplicacionPermitida';
                break;
            case 'Querys':
            case 'Query':
                data.ObjType = 'Query';
                break;
            case 'Formularios':
            case 'Formulario':
                data.ObjType = 'Formulario';
                break;
        }

        return data;
    }

    public GetNodeForObjectRule(objectRule: any, nodeText: string, parent: any, index: number, icon?: string,
        extraObjectType?: string, parentIndex?: number) {
        if (!objectRule.GUID)
            objectRule.GUID = Guid.create().toString();

        if (!icon) {
            icon = this.utility.GetPathImages(this.GetNodeByTypeFromTypesWithDefault((extraObjectType) ? extraObjectType : objectRule.ObjectType, nodeText));
        }
        if (icon == 'default')
            icon = null;
        if (!nodeText && !objectRule.Name)
            nodeText = index.toString();
        let resultNode: any = {
            id: objectRule.GUID,
            text: (nodeText) ? nodeText : objectRule.Name,
            icon: icon,
            // data: {
            //     GUID: objectRule.GUID,
            //     ObjectType: objectRule.ObjectType,
            //     Name: objectRule.Name,
            //     Index: index,
            //     ParentIndex: parentIndex
            // },
            data: objectRule,
            class: StaticData.Estilos.ClassNodosNuevos,// class: 'container alert alert-success',
            actionType: 1,
            parentNodeId: (parent) ? parent.id : null,
            children: [],
            config: { allowDrop: true }
        }
        resultNode.data.Index = index;
        resultNode.data.ParentIndex = parentIndex;
        if (parent)
            parent.children.push(resultNode);
        return resultNode;
    }
    public GetNewNode(parent: any) {
        let resultNode = {
            id: Guid.create().toString(),// (nodeTypeData.UniqueID) ? nodeTypeData.UniqueID : nodeTypeData.ObjType,
            text: 'Nuevo',
            icon: this.utility.GetPathImages('new-node.png'),
            data: this.GetDataNewNode(parent),
            class: StaticData.Estilos.ClassNodosNuevos,// class: 'container alert alert-success',
            actionType: 1,
            parentNodeId: (parent) ? parent.id : null,
            children: [],
            config: { allowDrop: true }
        }
        return resultNode;
    }
    public GetNodeByType(nodeTypeData: any, item?: any, parentId?: any): any {
        if (item && !item.ObjType)
            item.ObjType = nodeTypeData.ObjType;
        let resultNode = {
            id: Guid.create().toString(),// (nodeTypeData.UniqueID) ? nodeTypeData.UniqueID : nodeTypeData.ObjType,
            text: nodeTypeData.NodeText,
            icon: this.utility.GetPathImages(nodeTypeData),
            data: (item) ? item : nodeTypeData,
            class: StaticData.Estilos.ClassNodos,// 'container alert alert-primary',
            parentNodeId: (parentId) ? parentId : null,
            children: [],
            config: {
                allowDrop: this.nodeTypesAllowDrop.Any(x => { return x == nodeTypeData.ObjType }),
                allowMenu: this.nodeTypesAllowMenu.Any(x => { return x == nodeTypeData.ObjType })
            }

        }
        return resultNode;
    }
    _GetnodeTypeDataFromTypes(objType: string, nodeText?: string, createNewType: boolean = true): any {

        let nodeTypeData = this.GetNodeTypeDataFromTypes(this.utility.GetNodeTypes(), objType, nodeText, null, null, createNewType);

        return nodeTypeData;
    }

    public GetNodeByTypeFromTypesWithDefault(objType: string, nodeText?: string, createNewType: boolean = true, defaultObjType?: string): any {

        let nodeTypeData = this._GetnodeTypeDataFromTypes(objType, nodeText, createNewType);
        if (!nodeTypeData) {
            if (defaultObjType)
                nodeTypeData = this._GetnodeTypeDataFromTypes(defaultObjType, nodeText, createNewType);

            if (!nodeTypeData)
                nodeTypeData = this._GetnodeTypeDataFromTypes('SinImagen', nodeText, createNewType);
        }
        return nodeTypeData;
    }
    public GetNodeByTypeFromTypes(objType: string, item?: any, nodeText?: string, parentId?: any,
        defaultObjType?: string, createNewType: boolean = true): any {

        let nodeTypeData = this.GetNodeByTypeFromTypesWithDefault(objType, nodeText, createNewType, defaultObjType);
        if (!nodeTypeData)
            throw 'No se encontro el tipo de objeto ' + objType + ' en el metodo GetNodeByTypeFromTypes';
        // if (!nodeTypeData) {
        //     if (defaultObjType)
        //         nodeTypeData = this._GetnodeTypeDataFromTypes(defaultObjType, nodeText,createNewType);

        //     if (!nodeTypeData)
        //         nodeTypeData = this._GetnodeTypeDataFromTypes('SinImagen', nodeText,createNewType);
        // }
        /*  let nodeTypeData = this.GetNodeTypeDataFromTypes(this.utility.GetNodeSubTypes(), objType, nodeText);
         if (!nodeTypeData)
             nodeTypeData = this.GetNodeTypeDataFromTypes(this.utility.GetNodeTypes(), objType, nodeText);
         if (!nodeTypeData)
             nodeTypeData = this.GetNodeTypeDataFromTypes(this.utility.GetDocumentTypes(), objType, nodeText);
         if (!nodeTypeData) {
 
         } */
        let resultNode = this.GetNodeByType(nodeTypeData, item, parentId);
        return resultNode;
    }

    public GetNodeTypeDataFromTypes(lst: List<any>, objType: string, nodeText?: string,
        icon?: string, uniqueID?: any, createNewType: boolean = true): any {

        let _nodeTypeData = lst.Where(x => { return x.ObjType == objType }).FirstOrDefault();
        if (_nodeTypeData && createNewType) {
            let nodeTypeData = {
                UniqueID: (uniqueID) ? uniqueID : Guid.create().toString(),
                ObjType: objType,
                Icon: (icon) ? icon : _nodeTypeData.Icon,
                NodeText: (nodeText) ? nodeText : _nodeTypeData.NodeText
            };
            return nodeTypeData;
        }
        return null;
    }
    public GetNodeTypeData(uniqueID: any, objType: string, icon: string, nodeText: string, IdData?: any ) {

        let _nodeTypeData = this.utility.GetNodeType(objType);//new List<any>(this.localNodeSubTypes).Where(x => { return x.ObjType == objType }).FirstOrDefault();
        let nodeTypeData = { UniqueID: (uniqueID) ? uniqueID : Guid.create().toString(), ObjType: objType, Icon: icon, NodeText: nodeText, IdData: IdData };
        if (_nodeTypeData && !icon)
            nodeTypeData.Icon = _nodeTypeData.Icon;        
        return nodeTypeData;
    }

    public GetNode(nodeType: any, item?: any, childrenItems?: Array<any>, allItems?: Array<any>,
        config?: any, configNodeType?: any, callBackNodeTypeData?: any, callBackPost?: any): any {
        const that = this;
        let resultNode: any;
        let resultGeneralContent: any;
        let iconData: any;
        nodeType = (nodeType) ? nodeType : item.ObjType;
        let nodeTypeData = this.GetNodeByTypeFromTypesWithDefault(nodeType, null); //new List<any>(this.localNodeTypes).Where(x => { return x.ObjType == nodeType }).FirstOrDefault();
        if (!nodeTypeData) {
            nodeTypeData = { UniqueID: item.UniqueID, ObjType: item.ObjType, Icon: item.Icon, NodeText: item.NodeText };
        }
        else if (item && item.NodeText) {
            nodeTypeData.NodeText = item.NodeText;
        }
        if (configNodeType) {
            let separator = (configNodeType.Separator) ? configNodeType.Separator : ' ';
            if (configNodeType.NodeTextField) {
                if (configNodeType.NodeTextField.split(';').length > 0) {
                    nodeTypeData.NodeText = '';
                    $.each(configNodeType.NodeTextField.split(';'), function (index, nodeTextField) {
                        nodeTypeData.NodeText += item[nodeTextField] + separator;
                    });
                }
                else
                    nodeTypeData.NodeText = item[configNodeType.NodeTextField];
            }
            else if (configNodeType.NodeTextFields && configNodeType.NodeTextFields.length > 0) {

                nodeTypeData.NodeText = '';
                $.each(configNodeType.NodeTextFields.split(';'), function (index, nodeTextField) {
                    let _separator = (nodeTextField.Separator) ? nodeTextField.Separator : separator;
                    let _pre = (nodeTextField.Prefix) ? nodeTextField.Prefix : '';
                    let _post = (nodeTextField.Postfix) ? nodeTextField.Postfix : '';
                    nodeTypeData.NodeText += _pre + item[nodeTextField.Field] + _post + _separator;
                });
            }


            nodeTypeData.Icon = (configNodeType.Icon) ? configNodeType.Icon : nodeTypeData.Icon;
            nodeTypeData.ObjType = (configNodeType.ObjType) ? configNodeType.ObjType : nodeTypeData.ObjType;
            nodeTypeData.UniqueID = (configNodeType.UniqueIDField) ? item[configNodeType.UniqueIDField] : nodeTypeData.UniqueID;
        }


        if (callBackNodeTypeData) {
            callBackNodeTypeData(nodeTypeData, item, this, null);
        }
        resultNode = (item && item.children) ? item : this.GetNodeByType(nodeTypeData, item);


        if (item && config && config.items && config.items.length > 0) {

            let idParent = (config.idParent) ? config.idParent : 'IdParent';
            let firstItem = config.items[0];
            if (firstItem.ObjType !== item.ObjType) {
                idParent = (config.idParentExternal) ? config.idParentExternal : 'IdParent';
            }
            let externalChildrenItems = new List<any>(config.items).Where(x => { return x[idParent] == item.UniqueID }).ToArray();
            if (config.nodeTypeData && externalChildrenItems.length > 0) {

                //let parentResultNode = that.GetNodeByType(config.nodeTypeData);
                const node = that.GetNode(config.nodeTypeData.ObjType, null, externalChildrenItems, allItems, config, null, callBackNodeTypeData, callBackPost);
                node.parentNodeId = resultNode.id;
                if (callBackPost) {
                    if (callBackPost(nodeTypeData, item, this, node, externalChildrenItems))
                        resultNode.children.push(node);
                }
                else
                    resultNode.children.push(node);
            }
        }


        if (childrenItems && childrenItems.length > 0) {

            $.each(childrenItems, function (i, children) {

                let _childrenItems = (allItems) ? new List<any>(allItems).Where(x => { return x.IdParent == children.UniqueID && x.ObjType == children.ObjType }).ToArray() : new List<any>(childrenItems).Where(x => { return x.IdParent == children.UniqueID }).ToArray();

                const node = that.GetNode(null, children, _childrenItems, allItems, config, null, callBackNodeTypeData, callBackPost);
                node.parentNodeId = resultNode.id;
                if (callBackPost) {
                    if (callBackPost(nodeTypeData, children, this, node, _childrenItems))
                        resultNode.children.push(node);
                }
                else
                    resultNode.children.push(node);

            });

        }

        // switch (nodeType) {
        //   case 'Root':
        //   case 'Roles':
        //   case 'Formularios':
        //     resultNode = this.GetNodeByType(nodeTypeData, item);
        //     break;
        //   default:
        //     resultNode = this.GetNodeByType(nodeTypeData, item);
        //     break;
        // }
        if (config) {
            if (config.count && item) {

                let tempText = item[config.count.fieldItem];
                resultNode.text += ' [' + config.count.items.Where(x => { return x[config.count.field] == tempText }).Count() + ']';
            }
            if (config.nodeConfig && item) {

                $.each(config.nodeConfig, function (i, _nodeConfig) {

                    let isChild = item[_nodeConfig.fieldItem] == _nodeConfig.id;
                    if (isChild) {
                        _nodeConfig.node.parentNodeId = resultNode.id;
                        resultNode.children.push(_nodeConfig.node);
                    }

                });
            }
            if (config.nodeConfigEspecific && item) {

                $.each(config.nodeConfigEspecific, function (i, _nodeConfig) {

                    let isChild = (item[_nodeConfig.fieldItem] == _nodeConfig.id && item.ObjType == _nodeConfig.objType);
                    if (isChild) {

                        if (_nodeConfig.responsableTexto)
                            resultNode.text = _nodeConfig.responsableTexto + ' [' + resultNode.text + ']';
                        // item.nodeConfigEspecific = _nodeConfig;
                        if (_nodeConfig.tags)
                            resultNode.tags = _nodeConfig.tags;

                    }

                });
            }
            //nodeConfigEspecific
        }

        return resultNode;
    }


    public GetNodosDocumento(parentNode: any, items: any[], that: ConfigBaseTreeComponent) {

        that = (that) ? that : this;
        let nodes: Array<any> = [];
        $.each(items, function (i, item) {
            var node = that.GetNode(item.Extension, item, null, null, null);
            node.parentNodeId = parentNode.id;
            nodes.push(node);
        });
        return nodes;
    }
    CreateRecursivaInversa(childNode: any, childItem: any, items: any[], config?: any, callBackPost?: any) {

        if (!childItem[config.ParentField]) {
            return childNode;
        }
        const parentItem = items.find(x => { return x[config.FilterField] == childItem[config.ParentField] });
        let prentNode = this.GetNode(null, parentItem);

        childNode.parentNodeId = prentNode.id;
        prentNode.children.push(childNode);

        if (!parentItem[config.ParentField])
            return prentNode;
        else {
            prentNode = this.CreateRecursivaInversa(prentNode, parentItem, items, config, callBackPost);
            return prentNode;
        }
    }
    public CreateNodeTree(parentNode: any, items: any[], objContainerType: string, that: ConfigBaseTreeComponent,
        tiposCrearNodo: TiposCrearNodos = TiposCrearNodos.Normal, config?: any, callBack?: any, callBackProcessNode?: any, callBackPost?: any) {

        if (tiposCrearNodo == TiposCrearNodos.ListadoNodos) {
            let nodes: Array<any> = [];
            $.each(items, function (i, item) {
                var node = that.GetNode(null, item, null, null, null);
                node.parentNodeId = parentNode.id;
                nodes.push(node);
            });
            return nodes;
        }

        if (parentNode) {


            let AllItems = this.utility.Clone(items);
            let nodeChild = this.GetNode(objContainerType);
            nodeChild.parentNodeId = nodeChild.id;
            parentNode.children.push(nodeChild);
            if (tiposCrearNodo == TiposCrearNodos.Normal) {
                $.each(items, function (i, item) {

                    var node = that.GetNode(null, item, null, AllItems, config, null, callBack, callBackPost);
                    node.parentNodeId = parentNode.children[parentNode.children.length - 1].id;
                    parentNode.children[parentNode.children.length - 1].children.push(node);
                });
            }
            else if (tiposCrearNodo == TiposCrearNodos.Recursiva) {

                $.each(items, (i, item) => {

                    if (!item.IdParent) {

                        let _childrenItems = new List<any>(items).Where(x => { return x.IdParent == item.UniqueID }).ToArray();

                        const node = that.GetNode(null, item, _childrenItems, AllItems, config, null, callBack, callBackPost);

                        node.parentNodeId = parentNode.children[parentNode.children.length - 1].id;
                        if (callBackPost) {
                            if (callBackPost(null, item, this, node, _childrenItems))
                                parentNode.children[parentNode.children.length - 1].children.push(node);
                        }
                        else
                            parentNode.children[parentNode.children.length - 1].children.push(node);
                    }
                });

            }

            else if (tiposCrearNodo == TiposCrearNodos.RecursivaInversa) {


                config.FilterField = (!config.FilterField) ? "UniqueID" : config.FilterField;
                config.ParentField = (!config.ParentField) ? "IdParent" : config.ParentField;
                const firstItems = items.find(x => { return x[config.FilterField] == config.Id });
                const firstNode = that.GetNode(null, firstItems);

                const node = (firstNode[config.ParentField]) ? this.CreateRecursivaInversa(firstNode, firstItems, items, config, callBackPost) :
                    firstNode;
                if (node) {
                    node.parentNodeId = parentNode.children[parentNode.children.length - 1].id;
                    if (callBackPost) {
                        if (callBackPost(null, firstItems, this, firstNode, null))
                            parentNode.children[parentNode.children.length - 1].children.push(node);
                    }
                    else
                        parentNode.children[parentNode.children.length - 1].children.push(node);
                }
            }
        }
        else {
            let AllItems = this.utility.Clone(items);
            let nodeChild = this.GetNode(objContainerType);


            if (tiposCrearNodo == TiposCrearNodos.Normal) {
                $.each(items, function (i, item) {

                    var node = that.GetNode(null, item, null, AllItems, config);
                    if (callBackProcessNode)
                        callBackProcessNode(node);
                    node.parentNodeId = nodeChild.id;
                    nodeChild.children.push(node);
                });
            }
            else if (tiposCrearNodo == TiposCrearNodos.Recursiva) {

                $.each(items, function (i, item) {
                    if (!item.IdParent) {
                        let _childrenItems = new List<any>(items).Where(x => { return x.IdParent == item.UniqueID }).ToArray();
                        var node = that.GetNode(null, item, _childrenItems, AllItems, config, null, callBack, callBackPost);
                        node.parentNodeId = nodeChild.id;
                        nodeChild.children.push(node);
                    }
                });
            }
            return nodeChild;
        }
    }


    public CargarDatos(queryConfigIDEType: QueryConfigIDETypes, idParent?: number, isMulti: boolean = true, refresh: boolean = false) {
        if (StaticData.Usuario) {
            this.componentName = (!this.componentName) ? 'RPagina' : this.componentName;
            this.PreCargarDatos();
            if (this.modelItems.items.length > 0) {
                this.SetcontextMenuItems();
                this.PostCargarDatos();
                this.jsonData = this.GetJsonTreeData();
                this.PostGetJsonTreeData();
                this.InitTree();
            }
            else if (this.jsonData.length > 0 && !refresh) {


                this.SetcontextMenuItems();
                this.PostCargarDatos();

                this.PostGetJsonTreeData();
                this.InitTree();

            }
            else {
                if (this.parentOption) {
                    idParent = this.parentOption.PkValue;
                    isMulti = this.parentOption.isMulti;
                }
                let command: string = 'ConsultaDatosConfigIDE';
                let processMessage;
                if (queryConfigIDEType == QueryConfigIDETypes.Formularios) {
                    command = 'ConsultaDatosConfigPaginasIDE';
                    processMessage = 'Cargando formularios .....';
                }
                else if (queryConfigIDEType == QueryConfigIDETypes.Reglas) {
                    command = 'ConsultaDatosConfigReglasIDE';
                    processMessage = 'Cargando Reglas .....';
                }
                else if (queryConfigIDEType == QueryConfigIDETypes.ConfiguracionGeneral) {

                    command = 'ConsultaConfiguracionGeneral';
                    processMessage = 'Cargando Configuracion General .....';
                }
                this.baseService.ConsultaQueryConfigIDE(queryConfigIDEType, 'JstreeComponent', this.componentName, idParent, isMulti, command, processMessage).then(data => {
                    if (queryConfigIDEType == QueryConfigIDETypes.AdminIDE) {
                        this.paginas = data.Data.Table;
                        this.paginasRoles = data.Data.Table1;
                        this.controles = data.Data.Table2;
                        this.controlesRoles = data.Data.Table3;
                        this.roles = data.Data.Table4;
                        this.querys = data.Data.Table5;

                        this.areasEmpresa = data.Data.Table6;
                        this.series = data.Data.Table7;
                        this.subSeries = data.Data.Table8;
                        this.tiposDocumentales = data.Data.Table9;
                        this.tablasRetencionDocumental = data.Data.Table10;

                        this.aplicacionesPermitidas = data.Data.Table11;
                        this.grupos = data.Data.Table12
                        this.expedinetes = data.Data.Table13;
                        this.tiposSolicitud = data.Data.Table14;
                        this.carepetas = data.Data.Table15;
                        this.estadosSolicitud = data.Data.Table16;

                        this.paginasProcesadas = this.utility.ProcesarPaginas(this.controles);

                    }
                    else if (queryConfigIDEType == QueryConfigIDETypes.Formularios) {
                        this.paginas = data.Data;
                    }
                    else if (queryConfigIDEType == QueryConfigIDETypes.Reglas) {
                        this.reglas = data.Data;
                    }
                    else if (queryConfigIDEType == QueryConfigIDETypes.ConfiguracionGeneral) {
                        // COLOCAR LA LECTURA DE PROYECTO

                        this.currentProject = null;
                        this.currentProject = JSON.parse(data.Data[0].Configuration);
                        this.currentProject.Name = data.Data[0].Name;
                        this.currentProject.IdConfiguracionGeneral = data.Data[0].Id;
                        this.currentProject.IdEmpresa = data.Data[0].IdEmpresa;
                        this.FireAction('LoadProject', this.currentProject);
                    }
                    this.nodeTypesAllowMenu.Add('Serie');
                    this.nodeTypesAllowMenu.Add('SubSerie');
                    this.nodeTypesAllowMenu.Add('Expediente');
                    this.nodeTypesAllowDrop.Add('Expediente');
                    this.SetcontextMenuItems();
                    this.PostCargarDatos();
                    this.jsonData = this.GetJsonTreeData();
                    this.PostGetJsonTreeData();
                    this.InitTree();
                });
            }
        }
    }

    public FireAction(actionType, data) {
        this.onActionEventEmitter.emit(
            {
                name: this.name,
                data: data,
                actionType: actionType
            }
        );
    }
    public GetNodosDocumentosExpedinte(modeloExpedinte, parentNode?: any): Promise<any> {

        return new Promise((resolve, rejection) => {
            let nodos: Array<any> = []

            let idParent;
            if (modeloExpedinte.PkValue)
                idParent = modeloExpedinte.PkValue
            else if (modeloExpedinte.UniqueID)
                idParent = modeloExpedinte.UniqueID
            else if (modeloExpedinte.idParent)
                idParent = modeloExpedinte.idParent
            else if (modeloExpedinte.data && modeloExpedinte.data.UniqueID)
                idParent = modeloExpedinte.data.UniqueID

            else
                throw 'No Hay Llave para el filtro de documentos de expedinete';


            this.componentName = (!this.componentName) ? 'TDocumentoExpedinte' : this.componentName;
            this.baseService.ConsultaQueryConfigIDE(QueryConfigIDETypes.DocumentosExpediete,
                'JstreeComponent', this.componentName,
                idParent, false, 'ConsultaDatosConfigDocumentosExpedientesIDE').then(data => {

                    let items = data.Data;
                    parentNode = (parentNode) ? parentNode : modeloExpedinte;
                    var nodes = this.GetNodosDocumento(parentNode, items, this);
                    resolve(nodes);
                });

        });


    }

    public CrearPermisosEspecifico(nodoPadre: any, ConfiguracionList: List<any>, tipoParaPermisos: TipoParaPermisos, esDocumento: boolean,
        crearNodosPermisoEspecifico: boolean = true) {

        const that = this;
        let tipoContenedor: string;
        let tipoEspecifico: string;
        switch (tipoParaPermisos) {

            case TipoParaPermisos.Usuario:
                tipoContenedor = 'Usuarios';
                tipoEspecifico = 'Usuario';
                break;
            case TipoParaPermisos.Role:
                tipoContenedor = 'Roles';
                tipoEspecifico = 'Role';
                break;
            case TipoParaPermisos.Grupo:
                tipoContenedor = 'Grupos';
                tipoEspecifico = 'Grupo';
                break;
            case TipoParaPermisos.AreaEmpresa:
                tipoContenedor = 'AreasEmpresa';
                tipoEspecifico = 'AreaEmpresa';
                break;
        }

        let nodoPermisos = this.GetNodeByTypeFromTypes(tipoContenedor);
        let permisos = ConfiguracionList.Where(x => { return x.Tipo == tipoParaPermisos }).ToArray();


        $.each(permisos, (i, item) => {
            var node = that.GetNodeByTypeFromTypes(tipoEspecifico, item, item.Nombre);

            if (crearNodosPermisoEspecifico) {
                node.children.push(that.GetNodeByTypeFromTypes('Exportar', null, 'Exportar: ' + item.Exportar, node.id));
                node.children.push(that.GetNodeByTypeFromTypes('Filtrar', null, 'Filtrar: ' + item.Filtrar, node.id));
                node.children.push(that.GetNodeByTypeFromTypes('Eliminar', null, 'Eliminar: ' + item.Eliminar, node.id));
                node.children.push(that.GetNodeByTypeFromTypes('Editar', null, 'Editar: ' + item.Editar, node.id));
                node.children.push(that.GetNodeByTypeFromTypes('Guardar', null, 'Guardar: ' + item.Guardar, node.id));
                node.children.push(that.GetNodeByTypeFromTypes('Importar', null, 'Importar: ' + item.Importar, node.id));
                node.children.push(that.GetNodeByTypeFromTypes('Consultar', null, 'Consultar: ' + item.Consultar, node.id));
                if (esDocumento) {
                    node.children.push(that.GetNodeByTypeFromTypes('Ver', null, 'Ver: ' + item.Consultar, node.id));
                    node.children.push(that.GetNodeByTypeFromTypes('Descargar', null, 'Descargar: ' + item.Descargar, node.id));
                }
                node.children.push(that.GetNodeByTypeFromTypes('VerPermisos', null, 'Ver Permisos: ' + item.Consultar, node.id));
                node.children.push(that.GetNodeByTypeFromTypes('AdminPermisos', null, 'Admin Permisos: ' + item.Consultar, node.id));
                node.parentNodeId = nodoPermisos.id;
            }
            nodoPermisos.children.push(node);
        });

        nodoPermisos.parentNodeId = nodoPadre.id;
        nodoPadre.children.push(nodoPermisos);

    }
    public CrearPermisos(nodoPadre: any, item: any, field: string = 'Configuracion', esDocumento: boolean,
        crearNodosPermisoEspecifico: boolean = true) {

        const that = this;
        let ConfiguracionList = this.utility.GetListaPermisos(item, field);
        if (ConfiguracionList.Count() > 0) {
            this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.Usuario, esDocumento, crearNodosPermisoEspecifico);
            this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.Role, esDocumento, crearNodosPermisoEspecifico);
            this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.Grupo, esDocumento, crearNodosPermisoEspecifico);
            this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.AreaEmpresa, esDocumento, crearNodosPermisoEspecifico);
        }

        // let strConfiguracion = item[field];
        // if (strConfiguracion) {
        //     let Configuracion: Array<any> = JSON.parse(strConfiguracion);
        //     let ConfiguracionList = new List<any>(Configuracion);
        //     if (ConfiguracionList.Count() > 0) {
        //         this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.Usuario);
        //         this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.Role);
        //         this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.Grupo);
        //         this.CrearPermisosEspecifico(nodoPadre, ConfiguracionList, TipoParaPermisos.AreaEmpresa);
        //     }

        // }
    }



    public ConvertItemsToNodes(items: Array<any>, parentNode: any, configNodeType: any, addToParent: boolean = true, esDocumento: boolean = false, crearNodosPermisoEspecifico: boolean = true, executeItemsGrupo: boolean = true): Array<any> {

        const that = this;
        let nodes: Array<any> = [];
        let objTypeField = (configNodeType && configNodeType.ObjTypeField) ? configNodeType.ObjTypeField : null;
        let configField = (configNodeType && configNodeType.ConfigField) ? configNodeType.ConfigField : null;
        if (configNodeType && configNodeType.GroupBy) {


            let ParentField = configNodeType.GroupBy.ParentField;
            let NameFiled = configNodeType.GroupBy.NodeTextField;


            let itemsGroupByList = new List<any>(items).Where(x => { return x[ParentField] != null && x[ParentField] !== '' }).GroupBy(x => { return x[NameFiled] });
            $.each(itemsGroupByList, function (group, itemsGroup) {


                let groupNodeText = (configNodeType.GroupBy.NodeTextField) ? itemsGroup[0][configNodeType.GroupBy.NodeTextField] : group.toString();
                let groupObjType = (configNodeType.GroupBy.ObjType) ?
                    configNodeType.GroupBy.ObjType :
                    ((configNodeType.GroupBy.ObjTypeField) ? itemsGroup[0][configNodeType.GroupBy.ObjTypeField] : null);
                let NodeIdData = (configNodeType.GroupBy.ParentField) ? itemsGroup[0][configNodeType.GroupBy.ParentField] : null;

                let _nodoGrupoData = that.GetNodeTypeData(null, groupObjType, null, groupNodeText, NodeIdData);
                let nodoGrupo = that.GetNodeByType(_nodoGrupoData);
                if (configNodeType.SubGroupBy)
                    that.ConvertItemsToNodes(itemsGroup, nodoGrupo, configNodeType.SubGroupBy, true, false, false, configNodeType.SubGroupBy.executeItemsGrupo);
                else if (executeItemsGrupo) {
                    $.each(itemsGroup, function (i, item) {
                        let objType = (objTypeField) ? item[objTypeField] : null;
                        var node = that.GetNode(objType, item, null, null, null, configNodeType);
                        node.parentNodeId = parentNode.id;
                        if (configField)
                            that.CrearPermisos(node, item, configField, esDocumento, crearNodosPermisoEspecifico);
                        if (addToParent)
                            nodoGrupo.children.push(node);
                        else
                            nodes.push(node);
                    });
                }
                if (addToParent)
                    parentNode.children.push(nodoGrupo);
                else
                    nodes.push(nodoGrupo);
            });




            // se cargan los items propios no pertenecen al grupo
            $.each(items, function (i, item) {
                let objType = (objTypeField) ? item[objTypeField] : null;
                var node = that.GetNode(objType, item, null, null, null, configNodeType);
                node.parentNodeId = parentNode.id;
                addToParent = StaticData.GeneralConfig.ConfigValidacionDocumento.NodoCuaderno ? true : false
                
                if (configField)
                    that.CrearPermisos(node, item, configField, esDocumento, crearNodosPermisoEspecifico);
                if (item[ParentField] == null || item[ParentField] === '') {
                    if (addToParent)
                        parentNode.children.push(node);
                    else
                        nodes.push(node);
                }
            });

        }
        else {
            $.each(items, function (i, item) {
                let objType = (objTypeField) ? item[objTypeField] : null;
                var node = that.GetNode(objType, item, null, null, null, configNodeType);
                node.parentNodeId = parentNode.id;
                if (addToParent)
                    parentNode.children.push(node);
                else
                    nodes.push(node);
            });
        }
        return nodes;
    }
    public GetNodosFromParentOption(): Promise<any> {

        return new Promise((resolve, rejection) => {
            let nodos: Array<any> = []

            let idParent;


            if (this.parentOption.Config) {



                let nodeText = (this.parentOption.NodeTextField) ? this.currentItemParent[this.parentOption.NodeTextField] : this.currentItemParent.Nombre;
                let _nodoPadreData = this.GetNodeTypeData(this.currentItemParent.PkValue, this.parentOption.ObjType, null,
                    nodeText);
                let nodoPadre = this.GetNodeByType(_nodoPadreData);


                if (this.parentOption.Config.Items) {

                    let nodes = this.ConvertItemsToNodes(this.parentOption.Config.Items, nodoPadre, this.parentOption.Config.ConfigChildNodeType);
                    resolve(nodoPadre);
                }
                else {
                    var cmd = this.parentOption.Config.Cmd;
                    var controller = this.parentOption.Config.Controller;

                    cmd = (!cmd) ? controller : cmd;
                    this.utility.logger.LogInfoText('GetNodosFromParentOption para el padre tipo [' + this.parentOption.ObjType + '] Cmd: [' + cmd + ']');


                    if (!controller) {
                        this.utility.logger.LogWarningText('GetNodosFromParentOption para el padre tipo [' + this.parentOption.ObjType + '] El Controller y la config con nulo o vacio');
                        return;
                    }

                    if (!controller && !this.parentOption.Config.Url)
                        throw 'GetNodosFromParentOption para el padre tipo [' + this.parentOption.ObjType + '] El Controller y la Url no pude ser nulo o vacio';


                    var obj = this.utility.GetApiModel('GetNodosFromParentOption', cmd);

                    if (this.parentOption.Config.ByFileterParent && !this.parentOption.Config.QueryConfig) {


                        obj.QueryConfig.CrudAction = CrudActions.GetByFilterOrderBy;
                        if (!obj.QueryConfig.Entity)
                            obj.QueryConfig.Entity = {};
                        $.each(this.filterParent, function (key, val) {
                            obj.QueryConfig.Entity[key] = val;
                        });

                        if (this.parentOption.Config.Params) {
                            obj.QueryConfig.Entity = this.utility.SetFieldsToModel(obj.QueryConfig.Entity, this.parentOption.Config.Params);
                        }
                    }
                    else if (this.parentOption.Config.ByFileterParent && this.parentOption.Config.QueryConfig) {
                        this.parentOption.Config.QueryConfig.MethodNameUI = obj.QueryConfig.MethodNameUI;
                        obj.QueryConfig = this.parentOption.Config.QueryConfig;
                        if (!obj.QueryConfig.Entity) {
                            obj.QueryConfig.Entity = {};
                            obj.QueryConfig.Entity = this.utility.SetFieldsToModel(obj.QueryConfig.Entity, this.filterParent);
                        }
                        else {
                            obj.QueryConfig.Entity = this.utility.SetFieldsToModel(obj.QueryConfig.Entity, this.filterParent, true);
                        }
                        if (this.parentOption.Config.Params) {
                            obj.QueryConfig.Entity = this.utility.SetFieldsToModel(obj.QueryConfig.Entity, this.parentOption.Config.Params);
                        }
                    }
                    else {
                        if (this.parentOption.Config.CrudAction)
                            obj.QueryConfig.CrudAction = this.parentOption.Config.CrudAction;

                        if (this.parentOption.Config.QueryConfig)
                            obj.QueryConfig = this.parentOption.Config.QueryConfig;

                        if (this.parentOption.Config.IsSp == true) {

                            obj.QueryConfig = null;
                            obj.SActionName = this.parentOption.Config.Cmd;
                            if (this.parentOption.Config.Params) {
                                obj.Parameters = {};
                                $.each(this.parentOption.Config.Params, function (key, val) {
                                    obj.Parameters[key] = val;
                                });
                            }
                        }
                        else if (this.parentOption.Config.Command) {

                            obj.QueryConfig = null;
                            obj.Command = this.parentOption.Config.Command;
                        }
                        else if (this.parentOption.Config.Filter && this.parentOption.Config.Filter != '') {

                            obj.QueryConfig = null;
                            obj.Filter = this.parentOption.Config.Filter;
                            obj.Skip = this.parentOption.Config.Skip;
                            obj.Take = this.parentOption.Config.Take;
                            obj.OrderBY = this.parentOption.Config.OrderBY;
                        }
                        else if (this.parentOption.Config.Params && !this.parentOption.Config.IsSp) {

                            obj.QueryConfig = { AppSettingsKey: (!cmd) ? controller : cmd, CrudAction: CrudActions.GetByFilter };
                        }

                        if (obj.QueryConfig) {
                            if (!obj.QueryConfig.Entity && this.parentOption.Config.Params)
                                obj.QueryConfig.Entity = {};

                            if (this.parentOption.Config.Params && !this.parentOption.Config.IsSp) {
                                $.each(this.parentOption.Config.Params, function (key, val) {
                                    obj.QueryConfig.Entity[key] = val;
                                });
                            }
                        }
                    }
                    if (obj.QueryConfig && this.parentOption.Config.Include)
                        obj.QueryConfig.Include = this.parentOption.Config.Include;
                    if (this.parentOption.Config.Url) {


                        this.baseService.Ejecutar(obj, null,
                            { componentName: this.componentName, method: 'GetNodosFromParentOption Ejecutar', controlName: this.parentOption.ObjType },
                            false, this.parentOption.Config.Url).then(data => {

                                let nodes = this.ConvertItemsToNodes(data.Data, nodoPadre, this.parentOption.Config.ConfigChildNodeType);
                                resolve(nodoPadre);
                            });

                    }
                    else {
                        this.baseService.Get(obj, null,
                            { componentName: this.componentName, method: 'GetNodosFromParentOption Get', controlName: this.parentOption.objType }, controller)
                            .then(data => {
                                let nodes = this.ConvertItemsToNodes(data.Data, nodoPadre, this.parentOption.Config.ConfigChildNodeType);
                                resolve(nodoPadre);
                            });
                    }


                }
            }

        });


    }

    public SetcontextMenuItems() {

    }
    public GetJsonTreeData() {

    }

    public PreCargarDatos() {

    }
    public PostCargarDatos() {

    }
    public PostGetJsonTreeData() {

    }

    public verEdicionFormulario(data: any, context?: any): any {
        const that = (context) ? context : this;
        let config: ConfigWindow = new ConfigWindow();
        config.classWindow = 'Info';
        config.titleWindow = "Edicion";
        config.returnInstance = false;
        config.viewComponentTitle = true;
        config.viewComponentHeader = true;
        config.modal = true;
        config.width = 950;
        config.height = 650;
        config.viewOk = false;
        config.cancel = 'Cerrar';
        config.closeable = true;
        config.resizable = false;
        config.draggable = false;
        let windowModel: any = {};
        if (data.UniqueID) {
            windowModel = {
                pkValueEdit: data.UniqueID
            };
        }

        that.utility.OpenWindow(data.componentName,
            config, windowModel).then(dataResult => {
                // console.log(dataResult);

                dataResult.onClosePromise.then(dataResultOnClose => {
                    // console.log(dataResultOnClose);


                });

            })

    }
    public GetInfoNavegacion(node: any, context?: any): any {
        let result = { UniqueID: node.data.UniqueID, navPath: '', componentName: '' };

        switch (node.data.ObjType) {
            case 'Formulario':
            case 'Formularios':
                result.navPath = 'Pagina';
                result.componentName = 'RPaginaComponent';
                break;
            case 'AplicacionPermitida':
            case 'AplicacionesPermitidas':
                result.navPath = 'AplicacionPermitida';
                result.componentName = 'RAplicacionPermitida';
                break;
            case 'Grupo':
            case 'Grupos':
                result.navPath = 'GrupoUsuario';
                result.componentName = 'RGrupoUsuario'
                break;
            case 'Role':
            case 'Roles':
                result.navPath = 'Role';
                result.componentName = 'RRoleComponent'
                break;
            case 'AreaEmpresa':
            case 'AreasEmpresa':
                result.navPath = 'AreaEmpresa';
                result.componentName = 'RAreaEmpresaComponent'
                break;
            case 'Series':
            case 'Serie':
                result.navPath = 'Serie';
                result.componentName = 'RSerieComponent'
                break;
            case 'SubSeries':
            case 'SubSerie':
                result.navPath = 'SubSerie';
                result.componentName = 'RSubSerieComponent'
                break;
            case 'TiposDocumentales':
            case 'TipoDocumental':
                result.navPath = 'TipoDocumental';
                result.componentName = 'RTipoDocumentalComponent'
                break;
            case 'TablasRetencionDocumental':
            case 'TablaRetencionDocumental':
                result.navPath = 'TablaRetencionDocumental';
                result.componentName = 'RTablaRetencionDocumentalComponent' //
                break;
            case 'Querys':
            case 'Query':
                result.navPath = 'Query';
                result.componentName = 'RQueryComponent' //
                break;
            case 'Expedientes':
            case 'Expediente':
                result.navPath = 'Expediente';
                result.componentName = 'TExpediente' //
                break;
            case 'TiposSolicitud':
            case 'TipoSolicitud':
                result.navPath = 'TipoSolicitud';
                result.componentName = 'RTipoSolicitud' //
                break;
            case 'EstadosSolicitud':
            case 'EstadoSolicitud':
                result.navPath = 'EstadoSolicitud';
                result.componentName = 'REstadoSolicitud' //
                break;
            default:

                break
        }

        return result;
    }

    public AccionBasicaMenu(tipo: string, node: any, context?: any, queryConfigIDEType?: QueryConfigIDETypes) {

        //let pkValue = node = node.UniqueID;
        let result;
        const that = (context) ? context : this;
        let _queryConfigIDEType: QueryConfigIDETypes = (queryConfigIDEType) ? queryConfigIDEType : that.queryConfigIDEType


        switch (tipo) {
            case 'Editar':
            case 'VerAdministracion':
                result = that.GetInfoNavegacion(node, context);
                if (that.EsTipoPrimerNivel(node)) {
                    delete result.UniqueID;
                }
                setTimeout(() => {
                    that.verEdicionFormulario(result, context);
                }, 10);
                break;


        }

    }


    public SeleccionNodo(data: any) {

        let current = this.selectedNodes.FirstOrDefault(x => { return x.node.id == data.node.id });
        if (current) {
            data.node.class = current.class;//;
            this.selectedNodes.Remove(current);
        }
        else {

            if (!this.notSelectablesNodesTypes.Any(x => { return x == data.node.data.ObjType })) {
                this.selectedNodes.Add({ node: data.node, class: data.node.class });
                data.node.class = StaticData.Estilos.ClassNodosNuevos;
            }
        }

        if (this.selectedNodeEventEmitter.observers.length > 0)
            this.selectedNodeEventEmitter.emit({ node: data.node, selectedNodes: this.selectedNodes });
    }
}
