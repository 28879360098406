import {
  Component, OnInit, ViewChild, AfterViewInit, ElementRef, AfterViewChecked, Input,
  Output, EventEmitter, QueryList, forwardRef, Inject, OnDestroy
} from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { MatList } from '@angular/material';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem }
  from '@angular/cdk/drag-drop';
import { DockManager } from "dock-spawn-ts/lib/js/DockManager";
import { PanelContainer } from "dock-spawn-ts/lib/js/PanelContainer";
import { PanelType } from "dock-spawn-ts/lib/js/enums/PanelContainerType";
import { StaticData } from '../../../helpers/static-data';
import { ConfigWindow } from '../../../models/config-window';
import { Guid } from 'guid-typescript';
import { QueryBuilderConfig, QueryBuilderComponent } from 'angular2-query-builder';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, QueryConfigIDETypes
} from '../../../models/general.enum';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BaseCrud } from '../../../bussines/base-crud';

import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { debug } from 'util';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { PropertyGridControlsComponent } from '../../dynamic-templates/property-grid-controls/property-grid-controls.component';
import { JstreeComponent } from '../../jstree/jstree.component';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';
import { HelperImages } from 'src/app/helpers/helper-images';



@Component({
  selector: 'app-form-metadata',
  templateUrl: './form-metadata.component.html',
  styleUrls: ['./form-metadata.component.scss']
})
export class FormMetadataComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toolbox_controls', { static: true }) child: ElementRef;
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  @ViewChild(PropertyGridControlsComponent, { static: false }) propertyGridControls: PropertyGridControlsComponent;
  @ViewChild(JstreeComponent, { static: false }) jstree: JstreeComponent;
  @ViewChild("editorAcePrincipal", { static: false }) public editorAcePrincipal: EditorAceComponent;
  
  @Output() onUpdateEmitter: EventEmitter<any> = new EventEmitter<any>();

  

  public direction: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'horizontal';
  EstiloBuscarContenedor: any = { height: "600px" };
  @Input() project: any;
  @Input() isCustomComponent: boolean = false;

  label: string = "Metadatos";
  labelAdd: string = "Adicionar o Actualizar en Metadatos";

  controlesBasiscos: Array<any> = [];
  _modelo: any = { Id: 0, Controls: [], UserControls: [], Components: [] };
  @Input() UserControls: Array<any> = [];
  //@Input() UserControl: any;
  @Input() set modeloJson(value: any) {
    if (value) {
      this._modelo = JSON.parse(value);

      this.controlesBasiscos = this._modelo.Controls.filter(x => { return x.Groups == 'Metadatos' });
    }
  }
  get modeloJson(): any {    
    return JSON.stringify(this.modelo);
  }
  @Input() set modelClear(value: boolean) {
    if (value) {
      this.UserControls = new Array();
      this.propertyGridControls.UpdateEditor(null);
    }
  }
  @Input() set modelo(value: any) {
    this._modelo = value;
  }
  get modelo(): any {
    return this._modelo;
  }

  @Input() componentName: string;
  currentBusyRecord: any = null;



  @Input() set CurrentBusyRecord(value: any) {
    this.currentBusyRecord = value;
    if (this.currentBusyRecord)
      localStorage.setItem('Metadata_CurrentBusyRecord', JSON.stringify(this.currentBusyRecord));
    else
      localStorage.setItem('Metadata_CurrentBusyRecord', null);
  }
  get CurrentBusyRecord(): any {
    if (this.currentBusyRecord)
      return this.currentBusyRecord;
    else {
      const _currentBusyRecord = localStorage.getItem('Metadata_CurrentBusyRecord');
      if (_currentBusyRecord)
        this.currentBusyRecord = JSON.parse(_currentBusyRecord);
      return this.currentBusyRecord;
    }
  }

  Estilos: any;

  listToConnect: Array<any> = ['drop-list-form-metadata', 'drop-list-extra-metadata', 'drop-list-meta-metadata', 'drop-list-list-metadata', 'drop-list-metadata'];
  adminPermisos: boolean = true;
  constructor(public baseService: BaseServiceService, public utility: Utilities, public helperImages: HelperImages) {

  }

  public VerEditor(tipo: any) {


    // this.utility.VerEditor(tipo, this.UserControl.ConfigPage, false, "Editor Config [" + this.UserControl.Nombre + ']').then(data => {

    // });

  }

  public LimpiarPagina() {


    this.UserControls = [];
    // this.UserControl = {
    //   "Label": "",
    //   "Name": "",
    //   "Icon": "",
    //   "ControlType": 'UserControl',
    //   "Controls": []

    // };
    this.currentFieldModel = null;

    this.selectField({ control: null, index: 0 });
  }



  public GetToolBox() {

    if (this.isCustomComponent) {
      return this.modelo.Components;
    }
    else {
      return this.modelo.UserControls;
    }
  }
  public UpdateEditor() {

    if (this.editorAcePrincipal)
      this.editorAcePrincipal.Update(this.modelo);

  }
  public PropertyGridChangeHandler(event) {


  }

  ngAfterViewInit() {

  }

  ngOnInit() {

    if (this.isCustomComponent) {
      this.label = "Componentes";
      this.labelAdd = "Adicionar o Actualizar en Componentes";
    }
    this.Estilos = StaticData.Estilos;
    this.LimpiarPagina();

    if (this.modelo)
      this.controlesBasiscos = this.modelo.Controls.filter(x => { return x.Groups == 'Metadatos' });

    if (this.CurrentBusyRecord)
      this.LiberarRegistro(this.CurrentBusyRecord.Id).then(data => {
        this.CurrentBusyRecord = null;

      }).catch(err => { });
  }

  ngOnDestroy() {
    
    if (this.CurrentBusyRecord)
      this.LiberarRegistro(this.CurrentBusyRecord.Id).then(data => {
        this.CurrentBusyRecord = null;

      }).catch(err => { });
  }

  ////////////////// DRAG AND ROP CONTROLS/////////////////////////////////




  _currentIndex;
  _currentField;
  currentFieldModel;
  currentIndexFieldModel;


  dragStart(event: CdkDragStart) {
    // this._currentIndex = this.fieldModels.indexOf(event.source.data); // Get index of dragged type
    //this._currentField = this.child.nativeElement.children[this._currentIndex]; // Store HTML field
  }

  moved(event: CdkDragMove) {
    // Check if stored HTML field is as same as current field

    // if (this.child.nativeElement.children[this._currentIndex] !== this._currentField) {
    //   // Replace current field, basically replaces placeholder with old HTML content
    //   // this.child.nativeElement.replaceChild(this._currentField, this.child.nativeElement.children[this._currentIndex]);
    // }
  }
  itemDroppedControls(event: CdkDragDrop<any[]>) {
    console.error('itemDropped:  ', event);

    if (event.previousContainer === event.container) {
      moveItemInArray(this.UserControls, event.previousIndex, event.currentIndex);
    } else {
      this.addField(event.item.data, event.currentIndex, 1);
    }
  }


  Add() {


    if (this.isCustomComponent) {
      this.UserControls.forEach((_control, index) => {

        let currentIndex = this.modelo.Components.findIndex(c => { return c.Id == _control.Id });
        if (currentIndex > -1) {
          this.modelo.Components[currentIndex] = this.utility.Clone(_control);
          this.utility.VerModalInfo({ titulo: "Info", descripcion: "Componente Actualizado" })
        }
        else {
          let clone = this.utility.Clone(_control);
          clone.DataType = "Json";
          clone.ControlType = "JsonComponent";
          this.modelo.Components.push(this.utility.Clone(clone));
          this.modelo.Components = this.modelo.Components.slice();
          this.utility.VerModalInfo({ titulo: "Info", descripcion: "Componente Creado" })
        }

      })
    }
    else {
      this.UserControls.forEach((_control, index) => {

        let currentIndex = this.modelo.UserControls.findIndex(c => { return c.Id == _control.Id });
        if (currentIndex > -1) {
          this.modelo.UserControls[currentIndex] = this.utility.Clone(_control);
          this.modelo.UserControls = this.modelo.UserControls.slice();
          this.utility.VerModalInfo({ titulo: "Info", descripcion: "Componente Actualizado" })
        }
        else {
          this.modelo.UserControls.push(this.utility.Clone(_control));
          this.utility.VerModalInfo({ titulo: "Info", descripcion: "Componente Creado" })
        }

      })
    }
    this.onUpdateEmitter.emit(this.modeloJson);
  }
  Delete(control, index: number) {
    
    if (this.isCustomComponent)
      this.modelo.Components.splice(index, 1);
    else
      this.modelo.UserControls.splice(index, 1);
    this.onUpdateEmitter.emit(this.modeloJson);
    this.utility.VerModalInfo({ titulo: "Info", descripcion: "Componente Eliminado" })
  }
  ActivarInactivar(control) {
    
    control.Activo = !control.Activo;
    this.onUpdateEmitter.emit(this.modeloJson);
  }

  public ValidarRegistro(item: any) {
    let obj = this.utility.GetApiModel('ProcesoDeRegistroEnGestion', null)
    obj.QueryConfig.Command = 'ProcesoDeRegistroEnGestion';
    obj.QueryConfig.IsSp = true;

    obj.QueryConfig.NotSetDefault = true;
    obj.QueryConfig.Entity = {

      IdTipoProceso: 3,
      IdEmpresa: -1,
      IdUsuario: -1,
      Maquina: -1,
      IdRegistro: -2,
      TagBusqueda: 'FormMetadata_' + item.Id
    };


    const MsgBusyRecordManagement = 'El registro se encuentra en gestión de otro usuario';


    const MsgValidationRecordManagement = 'Validando ocupación del registro......';

    let info = {
      componentName: this.componentName, controlName: 'ProcesoDeRegistroEnGestion',
      processMessage: MsgValidationRecordManagement
    };
    return this.baseService.Get(obj, null, info, this.componentName, false, false)
  }
  public LiberarRegistro(name: any): Promise<any> {

    let obj = this.utility.GetApiModel('ProcesoDeRegistroEnGestion', null)
    obj.QueryConfig.Command = 'ProcesoDeRegistroEnGestion';
    obj.QueryConfig.IsSp = true;

    obj.QueryConfig.NotSetDefault = true;
    obj.QueryConfig.Entity = {
      IdTipoProceso: 2,
      IdEmpresa: -1,
      IdUsuario: -1,
      Maquina: -1,
      IdRegistro: -2,
      TagBusqueda: 'FormMetadata_' + name
    };
    const MsgReleaseRecordManagement = 'Liberando Registro......';


    let info = {
      componentName: 'FormMetadata', controlName: 'LiberarRegistro',
      processMessage: MsgReleaseRecordManagement
    };
    return this.baseService.Get(obj, null, info, this.componentName, false, false);
  }
  removeField(event, tipo?: number) {
    
    if (tipo === 1) {

      this.UserControls.splice(event.index, 1);

      if (this.CurrentBusyRecord)
        this.LiberarRegistro(this.CurrentBusyRecord.Id).then(data => {
          this.CurrentBusyRecord = null;

        }).catch(err => { });
    }
    //
    /*   if (tipo === 1)
       this._modelo.UserControls.splice(event.index, 1);
      else if (tipo === 2)
        this.UserControl.ConfigPage.ExtraControls.splice(event.index, 1);
      else
        this.UserControl.ConfigPage.Metadata.splice(event.index, 1); */

    this.currentFieldModel = null;
    this.currentIndexFieldModel = null;
  }
  EditMetadata(control: any, index?: number) {

    let event = { control: control, index: index };
    this.selectField(event);
  }
  selectField(event: any) {


    this.internalsSelectField({ control: null, index: 0 });
    setTimeout(() => {
      this.internalsSelectField(event);
    }, 50);
  }

  internalsSelectField(event: any) {

    if (event.control && event.control.ControlType == 'GridView') {
      // if (!event.control.Columns)
      //   event.control.Columns = [];
      if (!event.control.ConfigControl)
        event.control.ConfigControl = {
          "Controller": "",
          "Params": {},
          "QueryConfig": {
            "Command": "",
            "IsSp": false,
            "Entity": {},
            "WithPagination": false,
            "OutPutParam": false,
          },
          "NotLoad": false
        };
    }
    this.currentFieldModel = event.control;// this.model.Controls[event.index];
    this.currentIndexFieldModel = event.index;
    if (this.propertyGridControls)
      this.propertyGridControls.UpdateEditor(this.currentFieldModel);
    if (event.isWindow) {
      this.verProperty(event.index);
    }

  }


  verProperty(index): any {
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Propiedades";
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';
    let windowModel: any = {
      subControl: this.currentFieldModel,
      subControlIndex: this.currentIndexFieldModel
    };

    this.utility.OpenWindow('PropertyGridControlsComponent',
      config, windowModel).then(dataResult => {
        // console.log(dataResult);

        dataResult.onClosePromise.then(dataResultOnClose => {
          // console.log(dataResultOnClose);


        });

      })

  }
  public EditorControlesBasicos() {
    this.utility.VerEditor(2, this.modelo.Controls, true, "Campos basicos").then(dataResult => {
      // console.log(dataResult);

      if (dataResult.dialogResult)
        this.modelo.Controls = dataResult.modelo;

    })
  }
  addFieldEspecifProp(index: number, prop: string, _currentFieldModel: any) {
    if (index == -1)
      this.UserControls.push(_currentFieldModel);
    else
      this.UserControls.splice(index, 0, _currentFieldModel);
  }
  addField(fiedlModel: any, index: number, tipo: number) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName

    let _currentFieldModel: any = null;
    /* 
        let currentIndex = (this.isCustomComponent) ? this.modelo.Components.findIndex(c => { return c.Id == fiedlModel.Id })
          : this.modelo.UserControls.findIndex(c => { return c.Id == fiedlModel.Id });
        if (currentIndex > -1) {
          _currentFieldModel = fiedlModel;
          this.UserControls[currentIndex] = _currentFieldModel;
          return;
        } */

    let currentIndex = this.UserControls.findIndex(c => { return c.Id == fiedlModel.Id });

    if (currentIndex > -1) {
      _currentFieldModel = fiedlModel;
      this.UserControls[currentIndex] = _currentFieldModel;
      return;
    }

    if (tipo === 2 && this.UserControls.length == 1) {
      return;
    }

    if (this.CurrentBusyRecord && tipo === 2) {
      if (this.CurrentBusyRecord)
        this.LiberarRegistro(this.CurrentBusyRecord.id).then(data => {
          this.CurrentBusyRecord = null;
          this.addFieldInternal(fiedlModel, index, tipo, currentIndex);

        }).catch(err => { });
    }
    else {
      this.addFieldInternal(fiedlModel, index, tipo, currentIndex);
    }


  }

  addFieldInternal(fiedlModel: any, index: number, tipo: number, currentIndex: number) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName

    let _currentFieldModel: any = null;




    _currentFieldModel = this.utility.GeModelControl(fiedlModel.ControlType, fiedlModel.Type, fiedlModel);
    if (!this.isCustomComponent)
      _currentFieldModel.Type = 'UserControl';


    if (tipo === 1) {
      if (!this.utility.EsContenedorControles(fiedlModel)) {
        StaticData.ShowNotification("Para crear Componentes debe arrastrar inicialmente un control Contenedor", null, "top", "right", "Notificaci&oacute;n", 3000);
        return;
      }
      if (!_currentFieldModel.Icon) {
        let currentIndex = StaticData.ToolBox.Controls.findIndex(x => { return x.ControlType == _currentFieldModel.ControlType });
        if (currentIndex > -1) {
          _currentFieldModel.Icon = StaticData.ToolBox.Controls[currentIndex].Icon;
        }
      }
      // _currentFieldModel.Name =
      if (!this.isCustomComponent)
        _currentFieldModel.ModelContainer = 'modeloMetadata';
      else
        _currentFieldModel.ModelContainer = 'modelo';
      this.addFieldEspecifProp(index, 'Controls', _currentFieldModel);

    }
    else if (tipo === 2) {
      _currentFieldModel = this.utility.Clone(fiedlModel);

      this.ValidarRegistro(_currentFieldModel).then(data => {

        if (data.Data[0].Valido == 0) {
          this.CurrentBusyRecord = _currentFieldModel;
          this.UserControls.push(_currentFieldModel);

        }
        else {
          this.utility.VerModalWarning({
            titulo: 'Alerta',
            descripcion: 'Metadado en edicion por otro usuario'
          });

        }

      }).catch(err => { });
      // this.addFieldEspecifProp(index, 'ExtraControls', _currentFieldModel);

    }
    else if (tipo === 3) {

      // this.addFieldEspecifProp(index, 'Metadata', _currentFieldModel);

    }
    else {


    }
  }

  get GetlistToConnect(): Array<any> {
    return this.listToConnect;
  }


  public CreateListToConnect(name: string) {
    this.listToConnect.splice(0, 0, name);

  }

  optionsAce: any = { printMargin: true };
  public OnChangeHandlerAceEditor(event) {

    //this.UserControl.ConfigPage = event;

  }

  public ExportToJson() {

    const filename = (this.modelo.Nombre) ? this.modelo.Nombre + '.json' : this.modelo.Id + '.json';

    const jsonStr = '';// JSON.stringify(this.UserControl.ConfigPage, null, 2);// JSON.stringify(this.UserControl.ConfigPage);

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  public ImportToJson(evt) {
    try {

      let files = evt.target.files;
      if (!files.length) {
        alert('No selecciono un archivo!');
        return;
      }
      let file = files[0];
      let reader = new FileReader();
      const self = this;
      reader.onload = (event: any) => {



        // this.UserControl.ConfigPage = JSON.parse(event.target.result.toString());

        this.UpdateEditor();
      };
      reader.readAsText(file);
    } catch (err) {
      console.error(err);
    }
  }
}
