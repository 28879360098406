import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import 'brace';
import 'brace/mode/sql';
import 'brace/mode/text';
import 'brace/mode/html';
import 'brace/mode/css';
import 'brace/mode/javascript';

@Component({
  selector: 'app-editor-ace',
  templateUrl: './editor-ace.component.html',
  styleUrls: ['./editor-ace.component.scss']
})

export class EditorAceComponent extends ConfigBaseComponent implements OnInit {

  @Input() withModes: boolean = false;
  @Input() mode: string = "json";
  @Input() text: string = "";
  @Input() internalProp: string = "";
  @Input() options: any = { maxLines: 1000, printMargin: false };
  @Output() onChangeEmitter = new EventEmitter<any>();

  //* FUNCIONES ANGULAR

  ngOnInit() {

    this.Update(this.modelo);

  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  onChange(code) {

    try {
      if (this.internalProp) {
        if (typeof this.modelo[this.internalProp] === 'string') {
          this.modelo[this.internalProp] = code;
        }
        else
          this.modelo[this.internalProp] = JSON.parse(code);

        this.onChangeEmitter.emit(this.modelo);
      }
      else {
        if (typeof this.modelo === 'string') {
          this.modelo = code;
        }
        else
          this.modelo = JSON.parse(code);

        this.onChangeEmitter.emit(this.modelo);
      }
    }

    catch (error) {
      //this.utility.logger.LogError(error, code);
    }

  }

  public Update(_modelo: any) {

    this.modelo = _modelo;
    if (this.modelo && this.modelo !== '' && this.modelo !== null) {
      if (this.internalProp) {
        if (typeof this.modelo[this.internalProp] === 'string') {
          this.modelo[this.internalProp] = this.modelo[this.internalProp].replace('\"', '"');
          this.text = this.modelo[this.internalProp];
        }
        else
          this.text = JSON.stringify(this.modelo[this.internalProp], null, 2);
      }
      else {
        if (typeof this.modelo === 'string') {
          this.modelo = this.modelo.replace('\"', '"');
          this.text = this.modelo;
        }
        else
          this.text = JSON.stringify(this.modelo, null, 2);
      }
    }
    else
    this.text = "";

  }

  //* END FUNCIONES PERSONALIZADAS

}
