import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { Utilities } from 'src/app/helpers/utilities';
import { Guid } from 'guid-typescript';
import { TranslateService } from '@ngx-translate/core';
import { StaticData } from 'src/app/helpers/static-data';
declare var fabric: any;

@Component({
  selector: 'app-header-form',
  /*   providers: [
      {
        provide: null,
        useExisting: forwardRef(() => HeaderFormComponent),
        multi: true
      }
    ], */
  templateUrl: './header-form.component.html',
  styleUrls: ['./header-form.component.scss']
})
export class HeaderFormComponent implements OnInit {


  @Input() Pagina: any;
  @Input() viewComponentTitle: boolean = true;
  @Input() viewComponentHeader: boolean = true;
  @Output() ayuda: EventEmitter<any> = new EventEmitter<any>();
  panelName ='';
  panelClass = "ms-Panel";
  sidePanel: any;
  withSidePanel: boolean =false;
  public SidePanelTypes: Array<any> = [
    { Name: 'RightSidePanel', Label: 'Normal Derecha', Class: "ms-Panel" },
    { Name: 'RightMediumSidePanel', Label: 'Medio Derecha', Class: "ms-Panel ms-Panel--md" },
    { Name: 'RightLargeSidePanel', Label: 'Largo Derecha', Class: "ms-Panel ms-Panel--lg" },
    { Name: 'RightFixedSidePanel', Label: 'Ajustado Derecha', Class: "ms-Panel ms-Panel--lg ms-Panel--fixed" },
    { Name: 'RightExtralargeSidePanel', Label: 'Extra Largo Derecha', Class: "ms-Panel ms-Panel--xl" },
    { Name: 'LeftSidePanel', Label: 'Normal Izquierda', Class: "ms-Panel ms-Panel--left" },
    { Name: 'LeftMediumSidePanel', Label: 'Medio Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--md" },
    { Name: 'LeftLargeSidePanel', Label: 'Largo Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--lg" },
    { Name: 'LeftFixedSidePanel', Label: 'Ajustado Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--lg ms-Panel--fixed" },
    { Name: 'LeftExtralargeSidePanel', Label: 'Extra Largo Izquierda', Class: "ms-Panel ms-Panel--left ms-Panel--xl" },
  ];
  constructor(public translate: TranslateService) { this.translate.setDefaultLang(StaticData.Language);}

  ngOnInit() {
    this.panelName ='CommandBar_'+ Guid.create().toString();
    if (this.Pagina && this.Pagina.ConfigPage &&  this.Pagina.ConfigPage.HelpPanelType) {
      let item = this.SidePanelTypes.find(x => { return x.Name == this.Pagina.ConfigPage.HelpPanelType });
      if (item){
        this.panelClass = item.Class;
        this.withSidePanel =true;
      }
    }
  }
  OpenSidePanel() {
    if (this.sidePanel)
      new fabric['Panel'](this.sidePanel);
  }
  ngAfterViewInit() {
    (<any>$('[data-toggle="tooltip"]')).tooltip({
      sanitizeFn: function (content) { return content; }
    });

    if (this.withSidePanel)
      this.sidePanel = document.querySelector("#" + this.panelName);
  }

}
