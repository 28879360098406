import {
    ComparisonOperators,
    CrudActions,
    DataTypes,
    LogicalOperators,
    MailSendingForms,
    ReturnQueryTypes,
    TiposUbicacionArchivo
} from '../models/general.enum';
import { Enumerable, List } from '../../assets/linqts/compilado/index';

import { Guid } from 'guid-typescript';
import { StaticData } from 'src/app/helpers/static-data';

export class UtilityRuleEngine {


    public ActionTypes: Array<string> = [];

    public DelaysArray: Array<any> = [{ Name: null, Label: 'Cosntante' }];

    constructor() {
        for (let i = 1; i < 900; i = i + 10) {
            this.DelaysArray.push({ Value: i });
        }
    }
    public ResponsiblesActionsArray: Array<any> = [
        { Name: 'None', Label: 'Ninguna' },
        { Name: 'ActionCopy', Label: 'Accion Copiar' },
        { Name: 'ActionReasign', Label: 'Accion Reasignar' },
        { Name: 'ActionApprovalTask', Label: 'Accion Aprobación' },
        { Name: 'ActionCustom', Label: 'Accion Personalizada' },
    ];
    public ApprovalTaskTypes: Array<any> = [
        { Name: 'Approval', Label: 'Aprobacion' },
        { Name: 'Concept', Label: 'Concepto' },
      ];
      public ComplianceTypes: Array<any> = [
        { Name: 'ApprovedIfAllApproved', Label: 'Aprobar si Todos Aprobados' },
        { Name: 'ApprovedByMinNumberApproved', Label: 'Aprobar por numero minimo' },
        { Name: 'ApprovedByMinPercentajeApproved', Label: 'Aprobar por porcentaje minimo' },
      ];
    public ReturnParamsValidationsTypesArr: Array<any> = [

        { Name: 'None', Label: 'Ninguno' },
        { Name: 'Validation', Label: 'Texto Validacion' },
        { Name: 'Error', Label: 'Texto Error de Validacion' },
        { Name: 'ValidationError', Label: 'Texto Validacion y Error' },
        { Name: 'ObjectValidation', Label: 'Objeto Validacion' },
        { Name: 'ObjectError', Label: 'Objeto Error' },
        { Name: 'ObjectValidationError', Label: 'Objeto Validacion y Error' }


    ]
    // { Name: 'TVersionAreaEmpresa', Label: 'Area Empresa' },
    // { Name: 'TVersionSerie', Label: 'Serie' },
    // { Name: 'TVersionSubSerie', Label: 'SubSerie' },
    // { Name: 'TVersionTablaRetencionDocumental', Label: 'Tabla Retencion Documental' },
    public PaginationTypesArr: Array<any> = [

        { Name: 'None', Label: 'Ninguna' },
        { Name: 'Memory', Label: 'En Memoria de la App' },
        { Name: 'InBack', Label: 'Por el Back Externo' }
    ]
    public MailFieldTypesArr: Array<any> = [

        { Name: 'EmailsTo', Label: 'EmailsTo' },
        { Name: 'EmailsCC', Label: 'EmailsCC' },
        { Name: 'EmailsBcc', Label: 'EmailsBcc' }
    ]
    public ApprovalTaskTypesArr: Array<any> = [

        { Name: 'Approval', Label: 'Aprobación' },
        { Name: 'Concept', Label: 'Concepto' }
    ]
    public EntitiesArr: Array<any> = [

        { Name: '', Label: 'Ninguno' },
        { Name: 'RAreaEmpresa', Label: 'Area Empresa' },
        { Name: 'RSerie', Label: 'Serie' },
        { Name: 'RSubSerie', Label: 'Sub Serie' },
        { Name: 'RTablaRetencionDocumental', Label: 'Tabla Retencion Documental' },
        { Name: 'TExpediente', Label: 'Expediente' },
        { Name: 'TDocumentoExpediente', Label: 'Documento Expediente' }


    ]
    public AcionsArr: Array<any> = [
        { Name: 'Get', Label: 'Consultas' },
        { Name: 'Insert', Label: 'Creacion' },
        { Name: 'Update', Label: 'Actualizacion' }
    ]
    public ControllersArr: Array<any> = [
        { Name: 'AreaEmpresaController', Label: 'AreaEmpresaController' },
        { Name: 'SerieController', Label: 'SerieController' },
        { Name: 'SubSerieController', Label: 'SubSerieController' },
        { Name: 'TablaRetencionDocumentalController', Label: 'TablaRetencionDocumentalController' },
        { Name: 'DocumentoController', Label: 'DocumentoController' },
        { Name: 'ExpedienteController', Label: 'ExpedienteController' },
        { Name: 'MaestroController', Label: 'MaestroController' },
        { Name: 'ChatController', Label: 'ChatController' },
        { Name: 'TipoDocumentalController', Label: 'TipoDocumentalController' },
        { Name: 'RadicadoEntradaController', Label: 'RadicadoEntradaController' },
        { Name: 'RadicadoSalidaController', Label: 'RadicadoSalidaController' },
        { Name: 'RadicadoFacturaController', Label: 'RadicadoFacturaController' },
        { Name: 'PqrdController', Label: 'PqrdController' },
        { Name: 'RadicadoController', Label: 'RadicadoController' },
        { Name: 'AdministracionController', Label: 'AdministracionController' },
        { Name: 'SystemObjectController', Label: 'SystemObjectController' },
        { Name: 'FlujoController', Label: 'FlujoController' }
    ]
    public WorkflowFinishTypesArr: Array<any> = [

        { Name: 'AutoError', Label: 'Automatica / Error' },
        { Name: 'Auto', Label: 'Automatica' },
        { Name: 'Error', Label: 'Error' },
        { Name: 'Manual', Label: 'Manual' },
        { Name: 'ManualError', Label: 'Manual / Error' }
    ]
    public WorkflowTaskExecutionTypeArr: Array<any> = [
        { Name: '', Label: 'Ninguna' },
        { Name: 'AutoRunAutoFinish', Label: 'Ejecucion Automatica / Fin Automatico' },
        { Name: 'AutoRunManualFinish', Label: 'Ejecucion Automatica / Fin Manual' },
        { Name: 'ManulaRunManualFinish', Label: 'Ejecucion Manual / Fin Manual' },
        { Name: 'ManualRunAutoFinish', Label: 'Manual Automatica / Fin Automatico' }
    ]
    public WorkFlowTriggerTypeArr: Array<any> = [

        { Name: 'None', Label: 'Ninguno' },
        { Name: 'FormInserted', Label: 'Al Crear Registro Por Formulario' },
        { Name: 'Manual', Label: 'Manual' },
        { Name: 'ScheduledTask', Label: 'Tarea Programada' },
        { Name: 'Inserting', Label: 'Antes de Crear Registro' },
        { Name: 'Inserted', Label: 'Despues de Crear Registro' },
        { Name: 'Updating', Label: 'Antes de Actualizar Registro' },
        { Name: 'Updated', Label: 'Despues de Actualizar Registro' },
        { Name: 'Deleting', Label: 'Antes de Eliminar Registro' },

        { Name: 'Deleted', Label: 'Despues de Eliminar Registro' },
        { Name: 'UpdatingField', Label: 'Antes de Actualizar Campo' },
        { Name: 'UpdatingField', Label: 'Antes de Actualizar Campo' },

    ]
    public WorkFlowDeadlineUnitsArr: Array<any> = [

        { Name: 'Current', Label: 'Fecha Actual' },

        { Name: 'DateTime', Label: 'Fecha y Hora' },
        { Name: 'Months', Label: 'Meses' },
        { Name: 'Days', Label: 'Dias' },
        { Name: 'Hours', Label: 'Horas' },
        { Name: 'Minutes', Label: 'Minutos' },
        { Name: 'Seconds', Label: 'Segundos' },

        { Name: 'AddCurrentMonths', Label: 'Adcionar Meses a Fecha-Hora Actual' },
        { Name: 'AddCurrentDays', Label: 'Adcionar Dias a Fecha-Hora Actual' },
        { Name: 'AddCurrentHours', Label: 'Adcionar Horas a Fecha-Hora Actual' },
        { Name: 'AddCurrentMinutes', Label: 'Adcionar Minutos a Fecha-Hora Actual' },
        { Name: 'AddCurrentSeconds', Label: 'Adcionar Segundos a Fecha-Hora Actual' },

        { Name: 'SubtractCurrentMonths', Label: 'Restar Meses a Fecha-Hora Actual' },
        { Name: 'SubtractCurrentDays', Label: 'Restar Dias a Fecha-Hora Actual' },
        { Name: 'SubtractCurrentHours', Label: 'Restar Horas a Fecha-Hora Actual' },
        { Name: 'SubtractCurrentMinutes', Label: 'Restar Minutos a Fecha-Hora Actual' },
        { Name: 'SubtractCurrentSeconds', Label: 'Restar Segundos a Fecha-Hora Actual' },


        { Name: 'AddMonths', Label: 'Adcionar Meses a Fecha-Hora' },
        { Name: 'AddDays', Label: 'Adcionar Dias a Fecha-Hora' },
        { Name: 'AddHours', Label: 'Adcionar Horas a Fecha-Hora' },
        { Name: 'AddMinutes', Label: 'Adcionar Minutos a Fecha-Hora' },
        { Name: 'AddSeconds', Label: 'Adcionar Segundos a Fecha-Hora' },

        { Name: 'SubtractMonths', Label: 'Restar Meses a Fecha-Hora' },
        { Name: 'SubtractDays', Label: 'Restar Dias a Fecha-Hora' },
        { Name: 'SubtractHours', Label: 'Restar Horas a Fecha-Hora' },
        { Name: 'SubtractMinutes', Label: 'Restar Minutos a Fecha-Hora' },
        { Name: 'SubtractSeconds', Label: 'Restar Segundos a Fecha-Hora' },
    ]
    public HelpTypesArr: Array<any> = [

        { Name: 'Text', Label: 'Texto' },
        { Name: 'Pdf', Label: 'Pdf' },
        { Name: 'Word', Label: 'Word' },
        { Name: 'Xml', Label: 'Xml' },
        { Name: 'Url', Label: 'Url' },
        { Name: 'Audio', Label: 'Audio' },
        { Name: 'Video', Label: 'Video' },
        { Name: 'TextToSpeech', Label: 'Texto a Voz' },
    ]
    public LogTypesArr: Array<any> = [

        { Name: 'Txt', Label: 'Archivo Plano' },
        { Name: 'EventViewer', Label: 'Visor de Eventos' },
        { Name: 'DataBase', Label: 'Base Datos' },
    ]
    public RequestAccessKeyTypesArr: Array<any> = [

        { Name: 'Url', Label: 'Url' },
        { Name: 'Ip', Label: 'Ip' },
        { Name: 'Header', Label: 'Header' },
        { Name: 'Mac', Label: 'Mac' },
        { Name: 'Imei', Label: 'Imei' },
    ]
    public SignatureTypesArr: Array<any> = [

        { Name: 'Digital', Label: 'Digital' },
        { Name: 'Image', Label: 'Image' },

    ]
    public UserTypesArr: Array<any> = [

        { Name: 'System', Label: 'Sistema' },
        { Name: 'External', Label: 'Externo' },
        { Name: 'Anonymous', Label: 'Anonimo' },

    ]
    public DateFormatsArr: Array<any> = [

        { Name: '100', Label: 'mon dd yyyy hh:miAM/PM	' },
        { Name: '101', Label: 'mm/dd/yyyy' },
        { Name: '102', Label: ' yyyy.mm.dd' },
        { Name: '103', Label: 'dd/mm/yyyy' },
        { Name: '104', Label: 'dd.mm.yyyy' },
        { Name: '105', Label: ' dd-mm-yyyy' },

    ]
    public ImportProcessTypesArr: Array<any> = [
        { Name: "BulkCopy", Label: "Copia a Granel" },
        { Name: "ValidateAllBulkCopyEndProcessInvalid", Label: "Validar Todo y Copia a Granel, pero finalizar proceso por invalido" },
        { Name: "ValidateAllAndBulkCopyIfAllIsValid", Label: "validar todo y Copia a Granel Si Todo Ok" },
        { Name: "ValidateAllAndBulkCopyOnlyValid", Label: "Validar todo y Copia a Granel solo Registros OK" },
        { Name: "ValidateAllAndSaveIfAllIsValid", Label: "Validar todo y Copia Si Todo Ok" },
        { Name: "ValidateAllAndBulkCopyOnlyValid", Label: "Validar todo y Copia solo Registros OK" }];

    public ProcessQueryTemplateTypesArr: Array<any> = [

        { Name: 'Value', Label: 'Como Valor' },
        { Name: 'Table', Label: 'Como Tabla' },
        { Name: 'OnlyRowsTable', Label: 'Como Solo Filas' },
        { Name: 'Foreach', Label: 'Como Bucle' },
        { Name: 'Signature', Label: 'Firma Usuario' },
        { Name: 'Image', Label: 'Imagen' },
    ]
    public TiposRellenoArr: Array<any> = [

        { Name: 'Ninguno', Label: 'Ninguno' },
        { Name: 'Derecho', Label: 'Derecho' },
        { Name: 'Izquierdo', Label: 'Izquierdo' },
    ]
    public TiposCampoConfigGenerarArr: Array<any> = [

        { Name: 'NombreObjeto', Label: 'NombreObjeto' },
        { Name: 'Valor', Label: 'Valor' },
        { Name: 'Autonumerico', Label: 'Autonumerico' },
        { Name: 'FechaActual', Label: 'FechaActual' },
        { Name: 'AnoActual', Label: 'AnoActual' },
        { Name: 'MesActual', Label: 'MesActual' },
        { Name: 'DiaActual', Label: 'DiaActual' },
        { Name: 'Empresa', Label: 'Empresa' },
        { Name: 'AreaEmpresaUsuarioActual', Label: 'AreaEmpresaUsuarioActual' },
        { Name: 'RoleUsuarioActual', Label: 'RoleUsuarioActual' },
        { Name: 'GrupoUsuarioActual', Label: 'GrupoUsuarioActual' },
        { Name: 'UsuarioActual', Label: 'UsuarioActual' },
        { Name: 'IdEmpresa', Label: 'IdEmpresa' },
        { Name: 'IdEmpresa', Label: 'IdEmpresa' },
        { Name: 'IdAreaEmpresaUsuarioActual', Label: 'IdAreaEmpresaUsuarioActual' },
        { Name: 'IdRoleUsuarioActual', Label: 'IdRoleUsuarioActual' },
        { Name: 'IdGrupoUsuarioActual', Label: 'IdGrupoUsuarioActual' },
        { Name: 'IdUsuarioActual', Label: 'IdUsuarioActual' },
        { Name: 'Modelo', Label: 'Modelo' },
        { Name: 'Control', Label: 'Control' },
        { Name: 'Guid', Label: 'GUID' },
    ]
    public FormatosTextoArr: Array<any> = [

        { Name: 'Normal', Label: 'Normal' },
        { Name: 'Mayusculas', Label: 'Mayusculas' },
        { Name: 'Minusculas', Label: 'Minusculas' },
        { Name: 'UpperCamelCase', Label: 'UpperCamelCase' },
        { Name: 'PascalCase', Label: 'PascalCase' },
        { Name: 'CamelCase', Label: 'CamelCase' },
    ]
    // WebAdmin,
    // WebGeneral,
    // MovilAdmin,
    // MovilGeneral,
    // TaskScheduler,
    // Web,
    // Movil,
    // Service,
    // WebService,
    // External
    public ApplicationAllowedTypesArr: Array<any> = [

        { Name: 'WebAdmin', Label: 'Administracion Web' },
        { Name: 'WebGeneral', Label: 'Negocio Web' },
        { Name: 'MovilAdmin', Label: 'Administracion Movil' },
        { Name: 'MovilGeneral', Label: 'Negocio Movil' },
        { Name: 'TaskScheduler', Label: 'Aplicacion de Tareas Programadas' },
        { Name: 'Web', Label: 'Web Externa' },
        { Name: 'Movil', Label: 'Movil Externa' },
        { Name: 'Service', Label: 'Servicio Externo' },
        { Name: 'WebService', Label: 'Servicio Web Externo' },
        { Name: 'External', Label: 'Externa' },

    ]
    public ImportTakeNumberRecordsTypesArr: Array<any> = [
        { Name: 'Maximum', Label: 'Numero Maximo de Registro' },
        { Name: 'Minimum', Label: 'Numero Minimo de Registro' },
    ]
    public ExportImportDestinationsArr: Array<any> = [

        { Name: 'None', Label: 'Ninguna' },
        { Name: 'List', Label: 'Lista' },
        { Name: 'Folder', Label: 'Folder' },
        { Name: 'Email', Label: 'Correo' },
        { Name: 'EmailRule', Label: 'Regla de Correo' },
        { Name: 'QueryName', Label: 'Consulta' },
        { Name: 'WebClient', Label: 'Cliente Web' },
        { Name: 'Grid', Label: 'Grilla' },
        { Name: 'Screen', Label: 'Pantalla' },
        { Name: 'Table', Label: 'Tabla' },
        { Name: 'QueryModel', Label: 'QueryModel' },
        { Name: 'Download', Label: 'Descarga' },
        { Name: 'DownloadLibrary', Label: 'DownloadLibrary' },
        { Name: 'UserLibrary', Label: 'UserLibrary' }
    ]
    public ExportImportTypesArr: Array<any> = [

        { Name: 'None', Label: 'Ninguna' },
        { Name: 'Excel', Label: 'Excel' },
        { Name: 'Csv', Label: 'Csv' },
        { Name: 'Txt', Label: 'Txt' },
        { Name: 'Pdf', Label: 'Pdf' },
        { Name: 'Html', Label: 'Html' },
        { Name: 'Sql', Label: 'Sql' },
        { Name: 'Xml', Label: 'Xml' },
        { Name: 'Access', Label: 'Access' },
        { Name: 'Grid', Label: 'Grilla' },
        { Name: 'Data', Label: 'Datos' },
    ]
    public ExportImportFieldMappingArr: Array<any> = [

        { Name: 'None', Label: 'Ninguna' },
        { Name: 'CreateWithFirstRegister', Label: 'Crear Con El Primer Registro' },
        { Name: 'CreateWithLastRegister', Label: 'Crear Con El Ultimo Registro' },
        { Name: 'CreateWithAllRegisters', Label: 'Crear Con Todos Registros' },
        { Name: 'CreateWithEspecificIndex', Label: 'Crear Con Indice especifico' },
        { Name: 'CreateWithFilter', Label: 'Crear Con Filtro' },
    ]

    public TemplateTypesArr: Array<any> = [

        { Name: 'None', Label: 'Ninguna' },
        { Name: 'Excel', Label: 'Excel' },
        { Name: 'Txt', Label: 'Txt' },
        { Name: 'Csv', Label: 'Csv' },
        { Name: 'Word', Label: 'Word' },
        { Name: 'Pdf', Label: 'Pdf' },
        { Name: 'Xml', Label: 'Xml' },
        { Name: 'Html', Label: 'Html' },
        { Name: 'Json', Label: 'Json' },
        { Name: 'JavaScript', Label: 'JavaScript' },
        { Name: 'Report', Label: 'Report' },
    ]
    public FileTypesArr: Array<any> = [

        { Name: 'None', Label: 'Ninguna' },
        { Name: 'Excel', Label: 'Excel' },
        { Name: 'Access', Label: 'Access' },
        { Name: 'Txt', Label: 'Txt' },
        { Name: 'Csv', Label: 'Csv' },
        { Name: 'Word', Label: 'Word' },
        { Name: 'Pdf', Label: 'Pdf' },
        { Name: 'Xml', Label: 'Xml' },
        { Name: 'Html', Label: 'Html' },
        { Name: 'Json', Label: 'Json' },
        { Name: 'JavaScript', Label: 'JavaScript' },
        { Name: 'Report', Label: 'Report' },
    ]
    public SearchOptionDirectoryArr: Array<any> = [

        { Name: 'TopDirectoryOnly', Label: 'Solo Directorio Top' },
        { Name: 'AllDirectories', Label: 'Todos los Directorios' },
    ]
    public ReadingFileTypesArr: Array<any> = [

        { Name: 'Default', Label: 'Ninguno' },
        { Name: 'ReadWithConnection', Label: 'Leer con Conexion' },
        { Name: 'ReadAsFile', Label: 'Leer Como Archivo' },
        { Name: 'ReadWithOpenXml', Label: 'Leer con OpenXml' },
    ]
    public EncryptionTypesArr: Array<any> = [

        { Name: 'None', Label: 'Ninguno' },
        { Name: 'MD5', Label: 'MD5' },
        { Name: 'SHA1', Label: 'SHA1' },
        { Name: 'TripleDES', Label: 'TripleDES' },
        { Name: 'Base64', Label: 'Base64' },
        { Name: 'Custom', Label: 'Personalizado' },
    ]
    public DirectoryActionsArr: Array<any> = [

        { Name: 'Create', Label: 'Crear' },
        { Name: 'Delete', Label: 'Eliminar' },
        { Name: 'Move', Label: 'Mover' },
        { Name: 'Copy', Label: 'Copiar' },
        { Name: 'GetDirectories', Label: 'Consulta Directorios' },
        { Name: 'GetFiles', Label: 'Consulta Archivos' },
        { Name: 'GetFilesWithUrl', Label: 'Consulta Archivos Con Url' },
        { Name: 'Exists', Label: 'Existe' }
    ]
    public JoinTypesArr: Array<any> = [

        { Name: 'InnerJoin', Label: 'InnerJoin' },
        { Name: 'LeftJoin', Label: 'LeftJoin' },
        { Name: 'RightJoin', Label: 'RightJoin' },
        { Name: 'FullOuterJoin', Label: 'FullOuterJoin' },
        { Name: 'LeftJoinExcludingInnerJoin', Label: 'LeftJoinExcludingInnerJoin' },
        { Name: 'RightExcludingInnerJoin', Label: 'RightExcludingInnerJoin' },
        { Name: 'FullOuterExcludingInnerJoin', Label: 'FullOuterExcludingInnerJoin' },
    ]
    public RestMethodsArr: Array<any> = [

        { Name: 'Post', Label: 'Post' },
        { Name: 'Get', Label: 'Get' },
        { Name: 'Put', Label: 'Put' },
        { Name: 'Delete', Label: 'Delete' },
        { Name: 'Patch', Label: 'Patch' },
    ]

    public ADQueryActionsArr: Array<any> = [
        { Name: 'None', Label: 'Ninguno' },
        { Name: 'CreateUser', Label: 'CreateUser' },
        { Name: 'UpdateUser', Label: 'Actualizar Usuario' },
        { Name: 'DeleteUser', Label: 'Eliminar Usuario' },
        { Name: 'UnlockUserAccount', Label: 'Desbloqeuar Cuenta' },
        { Name: 'ExpireUserPassword', Label: 'Expirar Cuanta Usuario' },
        { Name: 'DisableUserAccount', Label: 'DesHabilitar Cuenta Usuario' },
        { Name: 'EnableUserAccount', Label: 'Habilitar Cuenta Usuario' },
        { Name: 'SetUserPassword', Label: 'Asignar Password' },
        { Name: 'ValidateCredentials', Label: 'Validar Credenciales' },
        { Name: 'ValidateCredentialsGlobalCatalog', Label: 'Validar Credenciales en Catalogo Global' },
        { Name: 'IsUserExpired', Label: 'Usuario Esta Expirado' },
        { Name: 'IsUserExisiting', Label: 'Usuario Existe' },
        { Name: 'IsAccountLocked', Label: 'Usuario Esta Bloqueado' },
        { Name: 'GetUser', Label: 'Consultar Usuario' },
        { Name: 'GetUsers', Label: 'Consulta Usuarios' },
        { Name: 'GetUserGlobalCatalog', Label: 'Consulta Usuario en Catalogo Global' },
        { Name: 'GetUsersInGroups', Label: 'Consulta Usuarios en Grupos' },
        { Name: 'GetEmailsFromUsersInGroups', Label: 'Consultar Correos Usuarios en Grupos' },
        { Name: 'GetEmailsFromUsers', Label: 'Consultar Correos de Usuarios' },
        { Name: 'CreateGroup', Label: 'Crear Grupo' },
        { Name: 'DeleteGroup', Label: 'Eliminar Grupo' },
        { Name: 'AddUserToGroups', Label: 'Adicionar Usuario a Grupos' },
        { Name: 'DeleteUserFromGroups', Label: 'Eliminar Usuario del Grupo' },
        { Name: 'IsUserGroupMember', Label: 'El Usuario es miembro del Grupo' },
        { Name: 'GetUserGroups', Label: 'Consultar Grupos del Usuario' },
        { Name: 'GetUserAuthorizationGroups', Label: 'Consultar Grupos  Autorizados Usuario' },
        { Name: 'GetGroup', Label: 'Consultar Grupo' },
        { Name: 'GetGroups', Label: 'Consulta Grupos' },
    ]


    public WebServiceTypesArr: Array<any> = [

        { Name: 'Traditional', Label: 'Traditional' },
        { Name: 'Rest', Label: 'Rest' },
        { Name: 'WCF', Label: 'WCF' },
        { Name: 'TraditionalSoap', Label: 'TraditionalSoap' },
        { Name: 'WCFSoap', Label: 'WCFSoap' },
    ]
    public ParameterTypesArr: Array<any> = [
        { Name: 'In', Label: 'Entrada' },
        { Name: 'InFile', Label: 'Archivos Entrada' },
        { Name: 'InCollection', Label: 'Arreglo Entrada' },
        { Name: 'InKeyValue', Label: 'Llave Valor Entrada' },
        { Name: 'InMappingFile', Label: 'Mapeo Datos Archivo Entrada' },
        { Name: 'InMetadata', Label: 'Metadatos Entrada' },
        { Name: 'InMetaInMetadataJsondata', Label: 'Metadatos Entrada Json' },
        { Name: 'InOut', Label: 'Entrada / Salida' },
        { Name: 'ReturnFile', Label: 'Archivos Salida' },
        { Name: 'Return', Label: 'Retorno' },
        { Name: 'Out', Label: 'Salida' },
        // { Name: 'ReturnModel', Label: 'Retorno Modelo' },
        // { Name: 'ReturnCollection', Label: 'Retorno Arreglo' },
        // { Name: 'Out', Label: 'Salida' },
    ]
    public AllQueryTypesArr: Array<any> = [

        { Name: 'Sql', Label: 'Sql' },
        { Name: 'WebService', Label: 'WebService' },
        { Name: 'Assembly', Label: 'Assembly' },
        { Name: 'ActiveDirectory', Label: 'ActiveDirectory' },
        { Name: 'CSharp', Label: 'CSharp' },
        { Name: 'JavaScript', Label: 'JavaScript' },
        { Name: 'Import', Label: 'Import' },
        { Name: 'File', Label: 'File' },
        { Name: 'SharePoint', Label: 'SharePoint' },
        { Name: 'Join', Label: 'Join' },
        { Name: 'Grouped', Label: 'Grouped' },
        { Name: 'PowerShell', Label: 'PowerShell' },
        { Name: 'Process', Label: 'Process' },
        { Name: 'System', Label: 'System' },
        { Name: 'Directory', Label: 'Directory' },
        { Name: 'Select', Label: 'Select' },
        { Name: 'Insert', Label: 'Insert' },
        { Name: 'UpdateStatus', Label: 'UpdateStatus' },
        { Name: 'Update', Label: 'Update' },
        { Name: 'Delete', Label: 'Delete' },
        { Name: 'GetAll', Label: 'GetAll' },
        { Name: 'GetById', Label: 'GetById' },
        { Name: 'GetByFilter', Label: 'GetByFilter' },
        { Name: 'InsertUpdate', Label: 'InsertUpdate' },
        { Name: 'GetByFilterCompare', Label: 'GetByFilterCompare' },
        { Name: 'GetByFilterRemoveAllSpaces', Label: 'GetByFilterRemoveAllSpaces' },
        { Name: 'GetByFilterCompareRemoveAllSpaces', Label: 'GetByFilterCompareRemoveAllSpaces' },
        { Name: 'DeleteByFilter', Label: 'DeleteByFilter' },
        { Name: 'GetAllOrderBy', Label: 'GetAllOrderBy' },
        { Name: 'GetAllOrderByDesc', Label: 'GetAllOrderByDesc' },
        { Name: 'GetByFilterOrderBy', Label: 'GetByFilterOrderBy' },
        { Name: 'GetByFilterOrderByDesc', Label: 'GetByFilterOrderByDesc' },
    ]

    public OperationQueryTypesArr: Array<any> = [


        { Name: 'Select', Label: 'Select' },
        { Name: 'Insert', Label: 'Insert' },
        { Name: 'UpdateStatus', Label: 'UpdateStatus' },
        { Name: 'Update', Label: 'Update' },
        { Name: 'Delete', Label: 'Delete' },
        { Name: 'GetAll', Label: 'GetAll' },
        { Name: 'GetById', Label: 'GetById' },
        { Name: 'GetByFilter', Label: 'GetByFilter' },
        { Name: 'InsertUpdate', Label: 'InsertUpdate' },
        { Name: 'GetByFilterCompare', Label: 'GetByFilterCompare' },
        { Name: 'GetByFilterRemoveAllSpaces', Label: 'GetByFilterRemoveAllSpaces' },
        { Name: 'GetByFilterCompareRemoveAllSpaces', Label: 'GetByFilterCompareRemoveAllSpaces' },
        { Name: 'DeleteByFilter', Label: 'DeleteByFilter' },
        { Name: 'GetAllOrderBy', Label: 'GetAllOrderBy' },
        { Name: 'GetAllOrderByDesc', Label: 'GetAllOrderByDesc' },
        { Name: 'GetByFilterOrderBy', Label: 'GetByFilterOrderBy' },
        { Name: 'GetByFilterOrderByDesc', Label: 'GetByFilterOrderByDesc' },

        { Name: 'GetByFilterContains', Label: 'GetByFilterContains' },
        { Name: 'GetByFilterNotContains', Label: 'GetByFilterNotContains' },
        { Name: 'GetByFilterStartWith', Label: 'GetByFilterStartWith' },
        { Name: 'GetByFilterNotStartWith', Label: 'GetByFilterNotStartWith' },
        { Name: 'GetByFilterEndWith', Label: 'GetByFilterEndWith' },
        { Name: 'GetByFilterNotEndWith', Label: 'GetByFilterNotEndWith' },
        { Name: 'GetByFilterContainsOp', Label: 'GetByFilterContainsOp' },
        { Name: 'GetByFilterNotContainsOp', Label: 'GetByFilterNotContainsOp' },
    ]
    public QueryTypesArr: Array<any> = [

        { Name: 'Sql', Label: 'Sql' },
        { Name: 'WebService', Label: 'WebService' },
        { Name: 'Assembly', Label: 'Assembly' },
        { Name: 'ActiveDirectory', Label: 'ActiveDirectory' },
        { Name: 'CSharp', Label: 'CSharp' },
        { Name: 'JavaScript', Label: 'JavaScript' },
        { Name: 'Import', Label: 'Import' },
        { Name: 'File', Label: 'File' },
        { Name: 'SharePoint', Label: 'SharePoint' },
        { Name: 'Join', Label: 'Join' },
        { Name: 'Grouped', Label: 'Grouped' },
        { Name: 'PowerShell', Label: 'PowerShell' },
        { Name: 'Process', Label: 'Process' },
        { Name: 'System', Label: 'System' },
        { Name: 'Directory', Label: 'Directory' },
        { Name: 'XsdTrasnformation', Label: 'Transformacion XSLT' },
        { Name: 'XsdValidation', Label: 'validacion XSD' },

    ]
    public DataLayerTypesArr: Array<any> = [

        { Name: 'SqlServer', Label: 'SqlServer' },
        { Name: 'PostgreSQL', Label: 'PostgreSQL' },
        { Name: 'MySql', Label: 'MySql' },
        { Name: 'Oracle', Label: 'Oracle' },
        { Name: 'SQLLite', Label: 'SQLLite' },
        { Name: 'SharePoint', Label: 'SharePoint' },
        { Name: 'SharePointOnLine', Label: 'SharePointOnLine' },
        { Name: 'Excel', Label: 'Excel' },
        { Name: 'Access', Label: 'Access' },
        { Name: 'Txt', Label: 'Txt' },
        { Name: 'Csv', Label: 'Csv' },
        { Name: 'WebService', Label: 'WebService' },
        { Name: 'Assembly', Label: 'Assembly' },
        { Name: 'ActiveDirectory', Label: 'ActiveDirectory' },
        { Name: 'File', Label: 'File' },
        { Name: 'PowerShell', Label: 'PowerShell' },
        { Name: 'Process', Label: 'Process' },
        { Name: 'Xml', Label: 'Xml' },
        { Name: 'Html', Label: 'Html' },
        { Name: 'Json', Label: 'Json' },
        { Name: 'Xsd', Label: 'Xsd' },
    ]

    public FMT: Array<any> = [
        { Name: 'TabDelimited', Label: ' Delimitado Por Tabulacion' },
        { Name: 'CsvDelimited', Label: ' Delimitado Por Coma' },
        { Name: 'SemicolonDelimited', Label: 'Delimtado Punto y Coma' },
        { Name: 'CustomDelimited', Label: ' Delimitado Por Carater Especificado' },
        { Name: 'Fixed', Label: 'Columnas de longitud fija' },
    ]
    public HRD: Array<any> = [
        { Name: 'Yes', Label: 'Primer Fila Con Titulos' },
        { Name: 'No', Label: 'No tiene titulos' },
    ]
    public ContextOptions: Array<any> = [
        { Name: 'Negotiate', Label: 'Negotiate' },
        { Name: 'SimpleBind', Label: 'SimpleBind' },
        { Name: 'SecureSocketLayer', Label: 'SecureSocketLayer' },
        { Name: 'Signing', Label: 'Signing' },
        { Name: 'Sealing', Label: 'Sealing' },
        { Name: 'ServerBind', Label: 'ServerBind' }
    ]
    public FilterTypesLocationFile: Array<any> = [
        { Name: 'Ninguno', Label: 'Ninguno' },
        { Name: 'IdSedeEmpresa', Label: 'IdSedeEmpresa' },
        { Name: 'IdAreaEmpresaUsuarioActual', Label: 'IdAreaEmpresaUsuarioActual' },
        { Name: 'IdRoleUsuarioActual', Label: 'IdRoleUsuarioActual' },
        { Name: 'IdGrupoUsuarioActual', Label: 'IdGrupoUsuarioActual' },
        { Name: 'IdUsuarioActual', Label: 'IdUsuarioActual' }
    ]

    public TiposUbicacionArchivoFuenteArr: Array<any> = [
        { Name: 'None', Label: 'Ninguno' },
        { Name: 'FileSystem', Label: 'Sistema Archivos' },
        { Name: 'Table', Label: 'Tabla' },
        { Name: 'FilesStream', Label: 'FilesStream' },
        { Name: 'FileTable', Label: 'FileTable' },
        { Name: 'AzureFileStorage', Label: 'AzureFileStorage' },
        { Name: 'AzureBlobStorage', Label: 'AzureBlobStorage' },
        { Name: 'SharePoint', Label: 'SharePoint' },
        { Name: 'SharePointOnline', Label: 'SharePointOnline' },
        { Name: 'WebClient', Label: 'WebClient' },
        { Name: 'Query', Label: 'Consulta' },
        { Name: 'AWS3', Label: 'AWS3' }
    ]
    public TiposUbicacionArchivoDestinoArr: Array<any> = [
        { Name: 'None', Label: 'Ninguno' },
        { Name: 'FileSystem', Label: 'Sistema Archivos' },
        { Name: 'Table', Label: 'Tabla' },
        { Name: 'FilesStream', Label: 'FilesStream' },
        { Name: 'FileTable', Label: 'FileTable' },
        { Name: 'AzureFileStorage', Label: 'AzureFileStorage' },
        { Name: 'AzureBlobStorage', Label: 'AzureBlobStorage' },
        { Name: 'SharePoint', Label: 'SharePoint' },
        { Name: 'SharePointOnline', Label: 'SharePointOnline' },
        { Name: 'WebClient', Label: 'WebClient' },
        { Name: 'Query', Label: 'Consulta' },
        { Name: 'Screen', Label: 'Pantalla' },
        { Name: 'Var', Label: 'Variable' },
        { Name: 'AWS3', Label: 'AWS3' }
    ]

    public ExportImportFormatTypesArr: Array<any> = [
        { Name: 'None', Label: 'Ninguno' },
        { Name: 'Original', Label: 'Original' },
        { Name: 'Url', Label: 'Url' },
        { Name: 'Pdf', Label: 'Pdf' },
        { Name: 'Content', Label: 'Content' },
        { Name: 'Base64', Label: 'Base64' },
        { Name: 'ByteArray', Label: 'ByteArray' },
        { Name: 'Stream', Label: 'Stream' },
        { Name: 'Xml', Label: 'Xml' },
        { Name: 'Json', Label: 'Json' },
        { Name: 'Html', Label: 'Html' },
        { Name: 'Word', Label: 'Word' }
    ]

    public TemplateSourceTypesArr: Array<any> = [
        { Name: 'Template', Label: 'Plantilla' },
        { Name: 'DocumentaryType', Label: 'Tipo Documental' },
        { Name: 'Document', Label: 'Documento' },
        { Name: 'Proceedings', Label: 'Expediente' },
        { Name: 'LocationFile', Label: 'Ubicacion Expediente' },
        { Name: 'Custom', Label: 'Externo' },
        { Name: 'ZipDocument', Label: 'Zip Documento' },
        { Name: 'ZipProceedings', Label: 'Zip Expediente' },
        { Name: 'ZipProceedingsClosure', Label: 'Cierre Zip Expediente' },
    ]


    public DataTypesArray: Array<any> = [
        { Name: 'String', Label: 'Texto' },
        { Name: 'Number', Label: 'Numero' },
        { Name: 'Int32', Label: 'Int32' },
        { Name: 'Int64', Label: 'Int64' },
        { Name: 'Decimal', Label: 'Decimal' },
        { Name: 'Double', Label: 'Doble' },
        { Name: 'DateTime', Label: 'Fecha/Hora' },
        { Name: 'Boolean', Label: 'Falso / Verdadero' },
        { Name: 'Object', Label: 'Objeto' },
        { Name: 'Array', Label: 'Array' },
        { Name: 'Json', Label: 'Json' },
        { Name: 'File', Label: 'Archivos' },
        { Name: 'QueryResult', Label: 'Resultado Consulta' },
    ]
    public ComparisonOperatorsArray: Array<any> = [{ Name: 'None', Label: 'Ninguno' },
    { Name: 'Equal', Label: 'Igual A' },
    { Name: 'NotEqual', Label: 'No Igual A' },
    { Name: 'In', Label: 'Esta en la Lista' },
    { Name: 'NotIn', Label: 'No esta en la Lista' },
    { Name: 'Contains', Label: 'Contiene' },
    { Name: 'NotContains', Label: 'No Contiene' },
    { Name: 'StartsWith', Label: 'Inicia Con' },
    { Name: 'EndsWith', Label: 'Finaliza Con' },
    { Name: 'NotStartsWith', Label: 'No Incial Con' },
    { Name: 'NotEndsWith', Label: 'No Finaliza Con' },
    { Name: 'GreaterThan', Label: 'Mayor Que' },
    { Name: 'LessThan', Label: 'Menor Que' },
    { Name: 'GreaterOrEqual', Label: 'Mayor o Igual A' },
    { Name: 'LessOrEqual', Label: 'Menor o Igual A' },
    { Name: 'IsNull', Label: 'Es Nulo' },
    { Name: 'IsNotNull', Label: 'No Es Nulo' },
    { Name: 'IsUndefined', Label: 'Es Indefinido' },
    { Name: 'IsNotUndefined', Label: 'No Es Indefinido' },
    { Name: 'Range', Label: 'Rango' }];
    public ComparisonOperatorsCountArray: Array<any> = [{ Name: 'None', Label: 'Ninguno' },
    { Name: 'Equal', Label: 'Igual A' },
    { Name: 'NotEqual', Label: 'No Igual A' },
    { Name: 'GreaterThan', Label: 'Mayor Que' },
    { Name: 'LessThan', Label: 'Menor Que' },
    { Name: 'GreaterOrEqual', Label: 'Mayor o Igual A' },
    { Name: 'LessOrEqual', Label: 'Menor o Igual A' }];

    public LogicalOperatorsArray: Array<any> = [{ Name: 'And', Label: 'Y' }, { Name: 'Or', Label: 'O' }];

    public MailSendingFormsArr: Array<any> = [{ Name: 'Net', Label: 'Net' }, { Name: 'Net2', Label: 'Net2' }];

    public ReturnQueryTypesArray: Array<any> = [
        { Name: 'None', Label: 'Ninguno' },
        { Name: 'ToListOfDictionary', Label: 'Como Lista Generica' },
        { Name: 'ToDataTable', Label: 'Como Tabla Datos' },
        { Name: 'ToListOfType', Label: 'Como List Tipada' },
        { Name: 'Dictionary', Label: 'Diccionario' },
        { Name: 'JsonToListOfDictionary', Label: 'Como Lista Diccionarios' },
        { Name: 'Original', Label: 'Original' },
        { Name: 'OriginalClear', Label: 'Original Limpia' },
        { Name: 'JsonOriginal', Label: 'Json Original' },
        { Name: 'JsonOriginalClear', Label: 'Json Original Limpia' }
    ];


    public CrudActionsArray: Array<any> = [{ Name: 'None', Label: 'Ninguno' },
    { Name: 'Insert', Label: 'Crear' },
    { Name: 'Update', Label: 'Actualizar' },

    { Name: 'Delete', Label: 'Eliminar' },
    { Name: 'UpdateStatus', Label: 'Actualizar Estado' },
    { Name: 'GetAll', Label: 'Consultar Todo' }
    ];
    public Events: Array<any> = [
        { Name: 'click', Label: 'Click' },
        { Name: 'change', Label: 'Cambio Valor' },
        { Name: 'keydown', Label: 'Presionar Tecla' },
        { Name: 'keyup', Label: 'Soltar Tecla' },
        { Name: 'keypress', Label: 'Presionar y suelta la Tecla' },
        { Name: 'focus', Label: 'Foco' },
        { Name: 'blur', Label: 'Pierde Foco' },
        { Name: 'hover', Label: 'Cursor Sobre Elemento' },
        { Name: 'mouseover', Label: 'Mouse Sobre Elemento' },
        { Name: 'mouseout', Label: 'Mouse Sale del Elemento' }
    ];
    public FormEvents: Array<any> = [
        { Name: 'OnInit', Label: 'Inicio' },
        { Name: 'AfterViewInit', Label: 'Inicio de Vista' },
        { Name: 'AfterViewChecked', Label: 'Inicio Vista Validada' },
        { Name: 'OnDestroy', Label: 'Fin' },
        { Name: 'OnPreLoadGrid', Label: 'Antes Cargar Grilla' },
        { Name: 'OnPostLoadGrid', Label: 'Despues Cargar Grilla' },
        { Name: 'OnPreUpdate', Label: 'Antes Actualizar' },
        { Name: 'OnPostUpdate', Label: 'Despues Actualizar' },
        { Name: 'OnPreInsert', Label: 'Antes Crear' },
        { Name: 'OnPostInsert', Label: 'Despues Crear' },
        { Name: 'OnPreDelete', Label: 'Antes Eliminar' },
        { Name: 'OnPostDelete', Label: 'Despues Eliminar' },
        { Name: 'OnPreEdit', Label: 'Antes Editar' },
        { Name: 'OnPostEdit', Label: 'Despues Editar' },
        { Name: 'OnPostSetEdit', Label: 'Despues Editar Externo' },
        { Name: 'OnDownloadSharePoint', Label: 'Antes Descargar SharePoint' },
        { Name: 'OnDownloadAzureBlob', Label: 'Antes Descargar Azure Blob' },
        { Name: 'OnViewAzureBlob', Label: 'Antes de Visualizar Azure Blob' }
    ];


    public ActionsSets: Array<any> = [
        { Name: 'visible', Label: 'Visible' },
        { Name: 'hidden', Label: 'Hidden' },
        { Name: 'enabled', Label: 'Habilitado' },
        { Name: 'readonly', Label: 'SoloLectura' },
        { Name: 'pattern', Label: 'Expresion Regular' },
        { Name: 'maxlength', Label: 'Longitud Maxima' },
        { Name: 'minlength', Label: 'Longitud Minima' },
        { Name: 'max', Label: 'Valor Maximo' },
        { Name: 'min', Label: 'Valor Minimo' },
        { Name: 'required', Label: 'Es Requerido' },
        { Name: 'value', Label: 'Valor' },
        { Name: 'text', Label: 'Texto Etiqueta' },
        { Name: 'title', Label: 'ToolTip' },
        { Name: 'placeholder', Label: 'Placeholder' },
        { Name: 'dataSource', Label: 'Fuente Datos' },
        { Name: 'configprop', Label: 'Prop Configuracion' },
        { Name: 'configpropjson', Label: 'Prop Json Configuracion' },
        { Name: 'config', Label: 'Configuracion' },
        { Name: 'configcontrol', Label: 'Configuracion Control' },
        { Name: 'active', Label: 'Activar' },]






    public PositionYArray: Array<any> = [
        { Name: 'none', Label: 'Por Defecto' },
        { Name: 'bottom', Label: 'Inferior' },
        { Name: 'top', Label: 'Superior' }];
    public PositionXArray: Array<any> = [
        { Name: 'none', Label: 'Por Defecto' },
        { Name: 'left', Label: 'Izquierdo' },
        { Name: 'right', Label: 'Derecho' },
        { Name: 'center', Label: 'Centro' }];
    /*         { Name: 'alwaysleft', Label: 'Siempre Izquierdo' },
            { Name: 'alwaysright', Label: 'Siempre Derecho' }]; */
    public NotifyTypeArray: Array<any> = [
        { Name: 'none', Label: 'Por Defecto' },
        { Name: 'success', Label: 'Exitoso' },
        { Name: 'info', Label: 'Informacion' },
        { Name: 'warning', Label: 'Alerta' },
        { Name: 'error', Label: 'Error' }];

    public RuleTypes: Array<any> = [
        { Name: 'GeneralNotify', Label: 'Notificaciones Generales' },
        { Name: 'UI', Label: 'Reglas Cliente' },
        { Name: 'Server', Label: 'Reglas Servidor' }
    ];

    public ObjetComparationTypesL: Array<any> = [
        { Name: 'Control', Label: 'Control' },
        { Name: 'Property', Label: 'Propiedad' },

    ];
    public ObjetComparationTypesR: Array<any> = [
        { Name: 'Control', Label: 'Control' },
        { Name: 'Property', Label: 'Propiedad' },
        { Name: 'Value', Label: 'Valor' },
        { Name: 'GlobalVar', Label: 'Variable Global' },
        { Name: 'Field', Label: 'Campo' }
    ];
    public ObjetComparationTypes: Array<any> = [
        { Name: 'Control', Label: 'Control' },
        { Name: 'Property', Label: 'Propiedad' },
        { Name: 'Field', Label: 'Campo' },
        { Name: 'Value', Label: 'Valor' },
        { Name: 'GlobalVar', Label: 'Variable Global' },

    ];
    public GlobalVars: Array<any> = [
        { Name: 'islogin', Label: 'Esta Autenticado' },
        { Name: 'currentusername', Label: 'Usuario' },
        { Name: 'role', Label: 'Role' },
        { Name: 'idrole', Label: 'Id Role' },
        { Name: 'idUsuario', Label: 'Id Usuario' },
        { Name: 'idareaempresa', Label: 'Id Area Empresa' },
        { Name: 'idempresa', Label: 'Id Empresa' },
        { Name: 'idgrupo', Label: 'Id Grupo' },
        { Name: 'currentdate', Label: 'Fecha Actual' }

    ];
    public QueryTypes: Array<any> = [

        { Name: 'ActiveDirectoryQuery', Label: 'ActiveDirectoryQuery' },
        { Name: 'AssemblyQuery', Label: 'AssemblyQuery' },
        { Name: 'CSharpQuery', Label: 'CSharpQuery' },
        { Name: 'DirectoryQuery', Label: 'DirectoryQuery' },
        { Name: 'FileQuery', Label: 'FileQuery' },
        { Name: 'GroupedQuery', Label: 'GroupedQuery' },
        { Name: 'ImportQuery', Label: 'ImportQuery' },
        { Name: 'JavaScriptQuery', Label: 'JavaScriptQuery' },
        { Name: 'JoinQuery', Label: 'JoinQuery' },
        { Name: 'PowerShellQuery', Label: 'PowerShellQuery' },
        { Name: 'ProcessQuery', Label: 'ProcessQuery' },
        { Name: 'SharePointQuery', Label: 'SharePointQuery' },
        { Name: 'SqlQuery', Label: 'SqlQuery' },
        { Name: 'WebServiceQuery', Label: 'WebServiceQuery' },
        { Name: 'XsdQuery', Label: 'XsdQuery' },
    ];
    // ActiveDirectoryQuery,
    // AssemblyQuery,
    // CSharpQuery,
    // DirectoryQuery,
    // FileQuery,
    // GroupedQuery,
    // ImportQuery,
    // JavaScriptQuery,
    // JoinQuery,
    // PowerShellQuery,
    // ProcessQuery,
    // SharePointQuery,
    // SqlQuery,
    // SystemQuery,
    // WebServiceQuery,

    public GetFunctionOptions(dataTypeName: string, concatenatedFields: any): Array<any> {

        let options: Array<any> = [];
        switch (dataTypeName) {
            case 'Number':
                options = [{ label: 'Eliminar Funcion' }, { IsDivider: true },

                { label: 'Conteo', Id: 8, Function: 'COUNT' },
                { label: 'Conteo Distinto', Id: 9, Function: 'DISTINCT COUNT' },
                { label: 'Suma', Id: 10, Function: 'SUM' },
                { label: 'Promedio', Id: 11, Function: 'AVG' },
                { label: 'Maximo', Id: 12, Function: 'MAX' },
                { label: 'Minimo', Id: 13, Function: 'MIN' },
                { IsDivider: true }, { label: 'Sub Consulta', Id: 14 },
                { label: 'Eliminar Sub Consulta', Id: 15 },
                { IsDivider: true }, { label: 'Agrupar', Id: 16 },
                { label: 'Eliminar Agrupar', Id: 17 },
                { IsDivider: true },
                { label: 'Funciones Sql', Id: 18 },
                { label: 'Procedimientos y Funciones', Id: 19 }];
                break;
            case 'DateTime':
                options = [{ label: 'Eliminar Funcion' },
                { IsDivider: true },
                { label: 'Conteo', Id: 8, Function: 'COUNT' },
                { label: 'Conteo Distinto', Id: 9, Function: 'DISTINCT COUNT' },
                { label: 'Maximo', Id: 12, Function: 'MAX' },
                { label: 'Minimo', Id: 13, Function: 'MIN' },
                { IsDivider: true },
                { label: 'Sub Consulta', Id: 14 },
                { label: 'Eliminar Sub Consulta', Id: 15 },
                { IsDivider: true },
                { label: 'Agrupar', Id: 16 },
                { label: 'Eliminar Agrupar', Id: 17 },
                { IsDivider: true },
                { label: 'Funciones Sql', Id: 18 },
                { label: 'Procedimientos y Funciones', Id: 19 }];
                break;
            default:
                options = [{ label: 'Eliminar Funcion' },
                { IsDivider: true },
                { label: 'Conteo', Id: 8, Function: 'COUNT' },
                { label: 'Conteo Distinto', Id: 9, Function: 'DISTINCT COUNT' },
                { IsDivider: true },
                { label: 'Sub Consulta', Id: 14 },
                { label: 'Eliminar Sub Consulta', Id: 15 },
                { IsDivider: true },
                { label: 'Agrupar', Id: 16 },
                { label: 'Eliminar Agrupar', Id: 17 },
                { IsDivider: true },
                { label: 'Funciones Sql', Id: 18 },
                { label: 'Procedimientos y Funciones', Id: 19 }];
                break;


        }

        if (concatenatedFields) {
            options.push({ IsDivider: true });
            options.push({ label: 'Campo Compuesto', Id: 20 });

        }

        return options;
    }

    /*  let _paginas = new List<any>(StaticData.Usuario.Paginas);
     var pagina = _paginas.Where(function (x) {
         return x.Nombre == name;
     }).FirstOrDefault();
  */




    public AnyToEnum(type: any, enumeration: any): any {
        let result = type;
        if (type) {
            result = enumeration[type];
        }
        return result;
    }
    public EnumToString(type: any, enumeration: any): string {
        let result = type;
        if (type && Number.isInteger(type)) {
            result = enumeration[type];
        }
        return result;
    }


    public AnyToMailSendingForms(type: any): any {
        return this.AnyToEnum(type, MailSendingForms);
    }
    public AnyToReturnQueryTypes(type: any): any {
        return this.AnyToEnum(type, ReturnQueryTypes);
    }
    public AnyToCrudActions(type: any): any {
        return this.AnyToEnum(type, CrudActions);
    }
    public AnyToLogicalOperators(type: any): any {
        return this.AnyToEnum(type, LogicalOperators);
    }
    public AnyToComparisonOperators(type: any): any {
        return this.AnyToEnum(type, ComparisonOperators);
    }
    public AnyToDataTypes(type: any): any {
        return this.AnyToEnum(type, DataTypes);
    }


    public GetEnumArraryText(enumeration: any): Array<any> {
        let arr: Array<any> = [];

        for (let enumMember in enumeration) {
            var isValueProperty = parseInt(enumMember, 10) >= 0
            if (isValueProperty) {
                arr.push(enumeration[enumMember]);
            }
        }

        return arr;
    }
}