//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:19/02/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { FileUploaderComponent } from '../../controls/file-uploader/file-uploader.component';
import { StaticData } from 'src/app/helpers/static-data';
import { DataTableComponent } from '../../controls/data-table/data-table.component';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { ComponentsContainer } from 'src/app/models/components-container';
import { List } from '../../../../assets/linqts/compilado/index';


@Component({
	selector: 'app-rtipo-documental',
	templateUrl: './rtipo-documental.component.html',
	styleUrls: ['./rtipo-documental.component.css']
})
export class RTipoDocumentalComponent extends GeneralFormComponent implements OnInit, AfterViewInit {
	public childComponents: Array<any> = [];
	filUploader: FileUploaderComponent;
	isModelContainer: boolean = true;
	MessageUpdateTesauro: any;
	limpiarDocumento: boolean;
	@Input() pkValueEdit: any;
	// limpiarDocumento: boolean = true;
	// componentsContainer: ComponentsContainer = new ComponentsContainer();

	constructor(public baseService: BaseServiceService,
		public utility: Utilities,
		public helperImages: HelperImages,
		public helperRules: HelperRules,
		public helperActions: HelperActions) {

		//super(baseService, utility, Paginas.TipoDocumental, 'RTipoDocumental', null, false);
		super(baseService, utility, helperImages, helperRules, helperActions);
		this.pkName = 'IdTipoDocumental';
	}

	public LimpiarDocumentos() {


		if (this.filUploader && this.limpiarDocumento)
			this.filUploader.clear();
	}
	public Limpiar(callback: (data: any) => void = null, action?: any) {

		//this.CreateModel(false);
		this.MessageUpdateTesauro.Hidden = true;
		this.ClearModel();
		this.utility.SetFieldsToModel(this.modelo.modelo, null);//this.filterParent);
		if (callback) callback(this.modelo);
		this.SetModelToControls();
		this.parentClearCall.next({ modelo: this.modelo, action: action });
		this.SetProcesando(false);
		if (this.limpiarDocumento) {
			this.LimpiarDocumentos();
		}
		//this.componentsContainer.Tables[0].ReCargarGrilla();
	}

	public OnInitEnd() {
		//debugger;

		this.MessageUpdateTesauro = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "MessageUpdateTesauro" });

		this.preSaveEmitter.subscribe(modelo => {
			this.modelo.modelo.PalabrasNormalizadas = JSON.stringify(this.modelo.modeloVirtual.GridPalabrasNormalizadas);
		});

		this.postSaveEmitter.subscribe(event => {

			this.modelo.modelo.PalabrasNormalizadas = JSON.stringify(this.modelo.modeloVirtual.GridPalabrasNormalizadas);
			this.GuardarArchivo(event);
			this.limpiarDocumento = true;
			//this.ReCargarGrilla();			

			setTimeout(() => { this.componentsContainer.ReloadTable("RTipoDocumental", { IdEmpresa: -1 }, null, false); }, 7000);
			// setTimeout(() => { this.componentsContainer.ReloadTable("RTipoDocumental", { IdEmpresa: -1 }, null, false); }, 12000);
			// setTimeout(() => { this.componentsContainer.ReloadTable("RTipoDocumental", { IdEmpresa: -1 }, null, false); }, 17000);
			// setTimeout(() => { this.componentsContainer.ReloadTable("RTipoDocumental", { IdEmpresa: -1 }, null, false); }, 22000);
		});

	}

	_Guardar(form: FormGroup, configButtons?: any) {
		if(this.modelo.modelo.ConfiguracionTemp || this.modelo.modelo.ConfiguracionTemp != null ){
			this.modelo.modelo.Configuracion = this.modelo.modelo.ConfiguracionTemp
		  }
		  //this.modelo.modelo.DataAdd = {
			//PalabrasNormalizadas: JSON.stringify(this.modelo.modeloVirtual.GridPalabrasNormalizadas).replace('\\', '')
		//};
		super._Guardar(form, configButtons);
	}

	public OnSelectedComboBox(item: any, controlName: string, config: any) {
		try {

			if (controlName == "Descriptores") {
				if (!this.modelo.modeloVirtual["GridPalabrasNormalizadas"]) {
					this.modelo.modeloVirtual["GridPalabrasNormalizadas"] = [];	
				}

				if (item && this.modelo.modelo.Descriptores &&// (this.modelo.modeloVirtual.GridPalabrasNormalizadas != "" && this.modelo.modeloVirtual.GridPalabrasNormalizadas != null)) {
					!this.modelo.modeloVirtual["GridPalabrasNormalizadas"].find(x => { return x.Id == item.IdTesauro; })) {
					this.modelo.modeloVirtual["GridPalabrasNormalizadas"].push({ Id: item.IdTesauro, Palabra: item.Palabra });

					var itemsCombo = this.configs.ConfigDescriptores.Context.items;
					this.configs.ConfigDescriptores.Context.ResetComboBox(itemsCombo, true);
				}
			}
 
		} catch (error) {
			this.utility.logger.LogError(error, null, {
				componentName: this.componentName,
				method: "OnSelectedComboBox",
				controlName: controlName,
			});
			this.utility.VerModalDanger().then((data) => { });
		}
	}

	public GuardarArchivo(event) {

		//debugger;

		const that = this;
		let token: string = StaticData.Usuario.Token;
		let pkValue: any;
		let codigo: string;
		let idTipoUbicacionArchivo: any;
		let nombre: string;
		let descripcion: string;
		let version: string;
		let activo: any;
		let configuracion: any;
		let configuracionTemp: any;
		let palabrasNormalizadas: any;

		if (event) {
			pkValue = event.data.data.IdTipoDocumental;
			codigo = event.data.data.Codigo;
			idTipoUbicacionArchivo = event.data.data.IdTipoUbicacionArchivo;
			nombre = event.data.data.Nombre;
			descripcion = event.data.data.Descripcion;
			version = event.data.data.Version;
			activo = event.data.data.Activo;
			configuracion = event.data.data.Configuracion;
			configuracionTemp = event.data.data.ConfiguracionTemp;
			palabrasNormalizadas = event.data.data.PalabrasNormalizadas;
		}
		else {
			pkValue = this.modelo.IdTipoDocumental;
			pkValue = this.modelo.Codigo;
			idTipoUbicacionArchivo = this.modelo.IdTipoUbicacionArchivo;
			nombre = this.modelo.Nombre;
			descripcion = this.modelo.Descripcion;
			version = this.modelo.Version;
			activo = this.modelo.Activo;
			configuracion = this.modelo.Configuracion;
			configuracionTemp = this.modelo.ConfiguracionTemp;
			palabrasNormalizadas = this.modelo.PalabrasNormalizadas;
		}
		//if (pkValue) {

		let model: any = {
			IdTipoDocumental: pkValue,
			PkValue: pkValue,
			Token: token,
			AppKey: StaticData.AppKey,
			Codigo: codigo,
			IdTipoUbicacionArchivo: idTipoUbicacionArchivo,
			MethodNameUI: 'Guardar',
			Nombre: nombre,
			Descripcion: descripcion,
			Version: version,
			Activo: activo,
			Configuracion: configuracion,
			ConfiguracionTemp: configuracionTemp,
			PalabrasNormalizadas: palabrasNormalizadas,
			
		}
		if (!this.filUploader.isSelectedFiles()) {
			this.utility.logger.LogWarningText('No Hay Archivos para cargar');
			StaticData.ShowNotification("Guardado sin documentos", "warning", "top", "right", "Notificaci&oacute;n", 3000);
			return;
		}

		this.filUploader.uploadFiles(false, model);
		//}
	}
	public AddComponentInstanceHandler(event) {


		if (event instanceof FileUploaderComponent) {
			this.filUploader = event;
		}
		else {
			super.AddComponentInstanceHandler(event);
		}

		//this.componentsContainer.Tables[0].ReCargarGrilla();
	}

	public OnDataTableEditHandler(event) {

		if (event.data.data.findItem.PalabrasNormalizadas != "" && event.data.data.findItem.PalabrasNormalizadas != "[]") {
			this.modelo.modelo.PalabrasNormalizadas = event.data.data.findItem.PalabrasNormalizadas;
			this.modelo.modeloVirtual.GridPalabrasNormalizadas = JSON.parse(this.modelo.modelo.PalabrasNormalizadas);
		}
		this.SetModelToControls();
		super.OnDataTableEditHandler(event)
	}

	public OnActionEventHandler(event) {
		


		switch (event.ControlType) {
			case 'CommandButton':
				if (event.Option.Type == "Download" || event.Option.Type == "ViewDocument") {


					if (this.modelo.modelo.Archivos) {
						let Archivos = JSON.parse(this.modelo.modelo.Archivos);
						this.modelo.modelo.NombreDocumento = Archivos[0].Nombre
						this.componentName = "RTipoDocumental"
						this.helperActions.ExecuteActionsControl((event.Option) ? event.Option : event.config,
							false, this.modelo.modelo, this.modelo, this.permissionsConfig,
							this, null, new EventEmitter<any>(), this.componentsContainer,
							event.Option, this.modelo.modeloMetadata, { importUrl: this.importUrl });
					}
					else
						this.utility.VerModalOk("El tipo documental no tiene registrados documentos")

				}

				else if (event.Option.Type == "Edit") {
					this.MessageUpdateTesauro.Hidden = false;
					this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
					super.OnActionEventHandler(event);

				}

				else if (event.Option.Type == "New") {

					this.MessageUpdateTesauro.Hidden = true;
					this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
					super.OnActionEventHandler(event);

				}

				else
					super.OnActionEventHandler(event);
				break;
			case 'UploadFile':
				this.EventoCargarDocumento(event);
				break;
			case 'ApiResponse':

				this.RespuestaCargaDocumento(event);
				break;
			case 'Change':
				this.EventoSeleccionArchivo(event);
				break;
			case 'ProgressEvent':
				this.EventoProgresoCargaDocumento(event);
				break;
			case 'Button':
				if (event.Name == "searchTesauro") {
					this.configs.ConfigDescriptores.Params = { PalabraBusqueda: this.modelo.modeloVirtual.palabraTesauro };
					this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
				}
				else
					super.OnActionEventHandler(event);
				break;
			default:
				if(event.componentName == "DragAndDopMetadataComponent"){
					this.modelo.modelo.ConfiguracionTemp = this.modelo.modelo.Configuracion
				}
				super.OnActionEventHandler(event);
				break;

		}
	}


	public RespuestaCargaDocumento(event) {



		if (event.data) {
			let obj: any = JSON.parse(event.data);

			if (obj.Ok) {

				this.Limpiar();

				//this.componentsContainer.Tables[0].ReCargarGrilla();
			}
			else {
				this.filUploader.selectedFiles = [];
			}
		}
		//this.componentsContainer.Tables[0].ReCargarGrilla();
	}
	public EventoProgresoCargaDocumento(event) {


	}

	public EventoCargarDocumento(event) {

		this.limpiarDocumento = true;
		this.GuardarArchivo(null);
		//this.componentsContainer.Tables[0].ReCargarGrilla();

	}
	public EventoSeleccionArchivo(event) {

		this.limpiarDocumento = false;
	}
}
