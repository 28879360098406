import { Component, EventEmitter, OnInit } from "@angular/core";
import {
  ContactoComponent,
  hideContactoFields,
  validateContact,
} from "../../contacto/contacto.component";
import {
  ControlActions,
  ControllerMethods,
  GeneralPageControlTypes,
  EstadosSolicitud,
  OperacionesDeSolicitudes,
  TipoObjetoParaPermisos,
} from "src/app/models/general.enum";

import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from "src/app/components/controls/file-uploader/file-uploader.component";
import { FormActionsThroughEmitters } from "../../../../models/general.enum";
import { FormDetailsComponent } from "src/app/components/controls/form-details/form-details.component";
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { StaticData } from "src/app/helpers/static-data";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { Guid } from "guid-typescript";
import { BotonesFormularioCrudComponent } from "src/app/components/controls/botones-formulario-crud/botones-formulario-crud.component";

@Component({
  selector: "app-solicitud-common",
  templateUrl: "./solicitud-common.component.html",
  styleUrls: ["./solicitud-common.component.css"],
})

export class SolicitudCommonComponent
  extends GeneralFormComponent
  implements OnInit {
  FileBoxes: Array<FileUploaderComponent> = [];
  Genero: any;
  Cargo: any;
  PrimerNombre: any;
  SegundoNombre: any;
  PrimerApellido: any;
  SegundoApellido: any;
  RazonSocial: any;
  IdCausalRechazo: any;
  Observaciones: any;
  Change: boolean = false;
  NumeroOficios: any = 0;
  //NumeroFolios: any = 0;
  UsuarioResponsbale: any;
  Asunto: any;
  Respuesta: any;
  RequiereRespuesta: any;
  modeloDetalle: any;
  errorTipoAnexo: any;
  countTipoAnexos: any;

  IdTipoDocumental: any;

  Oficio: FileUploaderComponent;
  configArchivosAdjuntos: FileUploaderComponent;
  tipoSolicitud = "la PQRS";

  IdContacto: any;
  BtnContactos: any;
  BtnDetalleSolicitud: any;
  MensajeSeleccionarContacto: any;
  BtnAsociarSolicitud: any;
  BtnVincular : any;
  BtnGuardarVincular : any;
  NumeroFolios: any;

  calledCommandButton: string;

  valueAnexos: any;
  countAnexos: number;

  //PruebaRadicados: any;

  InfoAnexos: any;
  GridViewClearSearch: any;
  modalOpenContacto: boolean;
  //PruebasEntradaRadicado: any;//= null; //Pendiente

  public OnInitEnd() {
    //debugger;
    this.helperActions.comunicationService.receivedFormEvent.subscribe(
      (event) => {
        // debugger;
        if (
          event.Action == FormActionsThroughEmitters.Edit ||
          event.Action == FormActionsThroughEmitters.ClearModel ||
          event.Action == FormActionsThroughEmitters.ClearForm
        ) {
          if (!this.name.includes("SolicitudesAnulaciones")) {
            validateContact(this, undefined, undefined, ["Genero"], this.modelo.selectedItem);
          }
        }
      }
    );
    /**INICIO
     * 04/02/2021 16:55 Edward Morales
     * Opción para creación (stickers) - modificación Radicados
     */
    //debugger;
    this.postSaveEmitter.subscribe((modelo) => {
      //  debugger;
      if (!this.IsEdit) {
        if (this.Pagina.Pagina == SolicitudCommonComponent.Paginas.PQR
          || this.Pagina.Pagina == SolicitudCommonComponent.Paginas.Entrada
          || this.Pagina.Pagina == SolicitudCommonComponent.Paginas.Factura) {
          if (modelo.data.data.DataAdd.OperacionSolicitud !== OperacionesDeSolicitudes.GestionarConcepto) {
            this.countAnexos = 0;
            this.valueAnexos = "";
            this.utility.VerModalOk(
              "Se creó " +
              this.tipoSolicitud +
              " con código: " +
              "\n\r" +
              modelo.data.data.Codigo
            );
            if (this.modelo.modeloVirtual.GrillaAnexosDetalle) {
              this.modelo.modeloVirtual.GrillaAnexosDetalle &&
              this.modelo.modeloVirtual.GrillaAnexosDetalle.forEach((anexo) => {
                this.valueAnexos += "<li>";
                this.valueAnexos += anexo.Nombre + ": " + anexo.Cantidad;
                this.valueAnexos += "</li>";
                this.countAnexos += anexo.Cantidad;
              });
            }
            /**
             * Inicio
             * 15022021 - 17-16
             * Siguiente linea para Radicado de Entrada y Factura contar Anexos para Sticker
             */
            if (this.modelo.modeloVirtual.GrillaAnexos) {
              this.modelo.modeloVirtual.GrillaAnexos &&
              this.modelo.modeloVirtual.GrillaAnexos.forEach((anexo) => {
                this.valueAnexos += "<li>";
                this.valueAnexos += anexo.Nombre + ": " + anexo.Cantidad;
                this.valueAnexos += "</li>";
                this.countAnexos += anexo.Cantidad;
              });
            }
            this.VerSticker(modelo.data.data, this.countAnexos, this.valueAnexos);
          }
        }
      } else
        this.utility.VerModalOk(
          "Se modificó " +
          this.tipoSolicitud +
          " con código: " +
          "\n\r" +
          this.modelo.modelo.Codigo
        ).then(() => true);
    });
  }

  public PostNgAfterViewInit() {
    let ControlsToFind = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls);
    this.RazonSocial = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.RazonSocial;
      });
    this.Cargo = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.Cargo;
      });
    this.Genero = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.Genero;
      });
    this.PrimerNombre = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.PrimerNombre;
      });
    this.SegundoNombre = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.SegundoNombre;
      });
    this.PrimerApellido = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.PrimerApellido;
      });
    this.SegundoApellido = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.SegundoApellido;
      });
    this.IdCausalRechazo = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.IdCausalRechazo;
      });
    this.Observaciones = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.Observaciones;
      });

    this.IdTipoDocumental = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.IdTipoDocumental;
      });
    this.InfoAnexos = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.InfoAnexos;
      });
    this.IdContacto = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.IdContacto;
      });
    this.BtnContactos = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.BtnContactos;
      });
    this.BtnDetalleSolicitud = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.BtnDetalleSolicitud;
      });
    this.MensajeSeleccionarContacto = ControlsToFind.find((x) => {
        return ( x.Name === SolicitudCommonComponent.Controls.MensajeSeleccionarContacto
        );
      });
    this.BtnAsociarSolicitud = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.BtnAsociarSolicitud;
      });
    this.BtnGuardarVincular = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.BtnGuardarVincular;
      });
    this.BtnVincular = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.BtnVincular;
      });
    this.NumeroFolios = ControlsToFind.find((x) => {
        return x.Name === SolicitudCommonComponent.Controls.NumeroFolios;
      });
  }

  public AddComponentInstanceHandler(event) {
    if (event instanceof DataTableComponent) {
      if (
        event.name ==
        SolicitudCommonComponent.Controls.GrillaReasignarUsuario ||
        event.name ==
        SolicitudCommonComponent.Controls.GrillaAsignarExpediente ||
        event.name == SolicitudCommonComponent.Controls.GrillaUsuariosRechazo ||
        event.name == SolicitudCommonComponent.Controls.GrillaUsuarioConcepto
      ) {
        event.parentContext = this;
      }
      this.componentsContainer.Tables.push(event);
    } else if (event instanceof FileUploaderComponent) {
      if (event.name == "ArchivoOficio") this.Oficio = event;
      else if (event.name == "FileBox_Documentos") this.configArchivosAdjuntos = event;
      else this.FileBoxes.push(event);
    } else if (event instanceof FormDetailsComponent) {
      //
      //             this.modeloDetalle.NumeroRadicacion = 0;
      //             this.modeloDetalle.Codigo = 0;
      //             if (event.config && event.config.Name == "FormDetalleExpediente") {
      //               event.modelo = this.modeloDetalle;
      //             }
      //             this.componentsContainer.FormsDetails.push(event);
    }
    super.AddComponentInstanceHandler(event);
  }

  GetConfigPermisos() {
    const permissionsConfig: any = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Pagina, //TipoObjetoParaPermisos.Expediente
      Pagina: this.Pagina,
      clear: true,
      CreatedRowConfig: {},
    };
    return permissionsConfig;
  }

  VerBuscarContacto() {
    this.modalOpenContacto = true;
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[0],
      limpiarInternamente: true,
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Búsqueda de Contactos";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility
      .OpenWindow(componentName, config, modeloTemp)
      .then((dataResult) => {
        dataResult.componentInstance.parentContext = this;

        dataResult.componentInstance.postEditEmitter.subscribe((result) => {

          this.SetDatosPorTipoContacto(
            dataResult.componentInstance.modelo.IdTipoContacto
          );
          this.modelo.modelo.Identificacion =
            dataResult.componentInstance.modelo.Identificacion;
          this.modelo.modelo.TipoIdentificacion =
            dataResult.componentInstance.modelo.TipoIdentificacion;
          this.modelo.modelo.IdContacto =
            dataResult.componentInstance.modelo.IdContacto;
          this.modelo.modelo.RazonSocial =
            dataResult.componentInstance.modelo.RazonSocial;
          this.modelo.modelo.Cargo =
            dataResult.componentInstance.modelo.Cargo;
          this.modelo.modelo.CodigoPostal = dataResult.componentInstance.modelo.CodigoPostal;
          this.modelo.modelo.Genero =
            dataResult.componentInstance.modelo.Genero;
          this.modelo.modelo.PrimerNombre =
            dataResult.componentInstance.modelo.PrimerNombre;
          this.modelo.modelo.SegundoNombre =
            dataResult.componentInstance.modelo.SegundoNombre;
          this.modelo.modelo.PrimerApellido =
            dataResult.componentInstance.modelo.PrimerApellido;
          this.modelo.modelo.SegundoApellido =
            dataResult.componentInstance.modelo.SegundoApellido;
          this.modelo.modelo.Celular =
            dataResult.componentInstance.modelo.Celular;
          this.modelo.modelo.Telefono =
            dataResult.componentInstance.modelo.Telefono;
          this.modelo.modelo.Ext = dataResult.componentInstance.modelo.Ext;
          this.modelo.modelo.Direccion =
            dataResult.componentInstance.modelo.Direccion;
          this.modelo.modelo.Correo =
            dataResult.componentInstance.modelo.Correo;
          this.modelo.modelo.IdPais =
            dataResult.componentInstance.modelo.IdPais;
          this.modelo.modelo.IdDepartamento =
            dataResult.componentInstance.modelo.IdDepartamento;
          this.modelo.modelo.IdCiudad =
            dataResult.componentInstance.modelo.IdCiudad;
          this.modelo.modeloCascada.IdPais =
            dataResult.componentInstance.modelo.IdPais;
          this.modelo.modeloCascada.IdDepartamento =
            dataResult.componentInstance.modelo.IdDepartamento;
          // if(this.modelo.modeloVirtual.Indicativo)
          this.modelo.modelo.Indicativo =
            dataResult.componentInstance.modelo.Indicativo;
          if (this.modelo.modelo.IdContacto && this.MensajeSeleccionarContacto)
            this.MensajeSeleccionarContacto.Hidden = true;
          // this.configs.ConfigIdPais.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          // this.configs.ConfigIdDepartamento.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          // this.configs.ConfigIdCiudad.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          //debugger;
          validateContact(
            this,
            undefined,
            undefined,
            ["Genero"],
            dataResult.componentInstance.modelo
          );
          this.SetModelToControls();
          this.EditCascading();
        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(
          (result) => {
            if (result.Action === FormActionsThroughEmitters.ClearModel) {
              this.modelo.modelo.IdContacto = null;
              this.modelo.modelo.RazonSocial = null;
              this.modelo.modelo.Genero = null;
              this.modelo.modelo.Cargo = null;
              this.modelo.modelo.CodigoPostal = null;
              this.modelo.modelo.PrimerNombre = null;
              this.modelo.modelo.SegundoNombre = null;
              this.modelo.modelo.PrimerApellido = null;
              this.modelo.modelo.SegundoApellido = null;
              this.modelo.modelo.Celular = null;
              this.modelo.modelo.Telefono = null;
              this.modelo.modelo.Ext = null;
              this.modelo.modelo.Direccion = null;
              this.modelo.modelo.Correo = null;
              this.modelo.modelo.IdCiudad = null;
              this.modelo.modelo.IdDepartamento = null;
              this.modelo.modelo.IdPais = null;
              this.modelo.modeloCascada.IdDepartamento = null;
              this.modelo.modeloCascada.IdPais = null;
              this.modelo.modelo.Indicativo = null;
              this.MensajeSeleccionarContacto.Hidden = false;
              if (this.modelo.modelo.DataAdd)
                this.modelo.modelo.DataAdd = { OperacionSolicitud: null };

              this.SetModelToControls();
            }
          }
        );
        dataResult.onClosePromise.then((dataResultOnClose) => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
          this.modalOpenContacto = false;
        });
      });
  }

  VerBuscarPqrd() {
    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[1],
      limpiarInternamente: true,
    };

    const componentName = "DataTableComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Relacionar Pqrd";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility
      .OpenWindow(componentName, config, modeloTemp)
      .then((dataResult) => {
        dataResult.componentInstance.parentContext = this;

        dataResult.componentInstance.postEditEmitter.subscribe((result) => {
          this.modelo.modeloVirtual.CodigoPqrdAsociada =
            dataResult.componentInstance.modelo.Codigo;
          this.modelo.modelo.IdSolicitudPqrdAsociada =
            dataResult.componentInstance.modelo.IdSolicitudPqrd;

          this.SetModelToControls();
        });
        dataResult.componentInstance.onActionEventEmitter.subscribe(
          (result) => {
            if (result.Action === FormActionsThroughEmitters.ClearModel) {
              this.modelo.modeloVirtual.CodigoPqrdAsociada = null;
              this.modelo.modelo.IdSolicitudPqrdAsociada = null;
              this.SetModelToControls();
            }
          }
        );

        dataResult.onClosePromise.then((dataResultOnClose) => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
        });
      });
  }

  ClearSearchDataTable(DataTableClear, DataTableName, ComponentName) {
    var InputValue = ""
    //this.GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == "GrillaAprobacionUsuario"; });
    this.utility.GetDataTabledtInstance(DataTableClear.dtElement, DataTableName, ComponentName).then(dtInstance => {
      dtInstance.search(InputValue).draw();
    }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })

  }


  VerSticker(modelo, anexos = null, valorAnexos = null) {

    var tipoSolicitud = this.Pagina.Pagina == SolicitudCommonComponent.Paginas.PQR ? "PQRS" :
      this.Pagina.Pagina == SolicitudCommonComponent.Paginas.Entrada ? "Entrada" :
        this.Pagina.Pagina == SolicitudCommonComponent.Paginas.Factura ? "Factura" : "Radicado";

    const container = this.Pagina.ConfigPage.HiddenControls.find(
      (x) => x.Title === SolicitudCommonComponent.Controls.Resultado
    );
    const QRCodeBoxResult = container.Controls[0].Controls.find(
      (x) => x.Name === SolicitudCommonComponent.Controls.QRCodeBoxResult
    );
    const radicacion = new Date(modelo.FechaCreacion);

    //QRCodeBoxResult.Text = modelo.Codigo + "\n" + this.modelo.modelo.NumeroFolios + "\n" + "Test";
    var qrMessage =
      StaticData.Usuario.Nombre + "-" + StaticData.Usuario.Email + "\n";
    qrMessage += "Solicitud: " + modelo.Codigo + "\n";
    qrMessage += "Fecha: " + radicacion.toLocaleString() + "\n";
    // qrMessage += "N° Oficios: " + this.NumeroOficios + "\n";
    // qrMessage += "N° Folios: " + this.modelo.modelo.NumeroFolios;
    // if(anexos != null && valorAnexos != null)
    //     qrMessage += "\n Anexos: " + anexos;

    QRCodeBoxResult.Text = qrMessage; // modelo.Codigo;

    const HtmlText = container.Controls[0].Controls.find(
      (x) => x.Name === SolicitudCommonComponent.Controls.HtmlText
    );

    // container.Controls[3].Msg = `Usuario: ${StaticData.Usuario.Nombre} - ${StaticData.Usuario.Email}\r\n
    //                              Código Solicitud: ${modelo.Codigo} \r\n
    //                              Fecha Radicación: ${radicacion.toLocaleString()} \r\n
    //                              Anexos: ${anexos}`;

    // HtmlText.Value = (`<ul class='ul__style'>
    //                 <li>Usuario: ${StaticData.Usuario.Nombre} - ${StaticData.Usuario.Email}<br></li>
    //                 <li>Código Solicitud: ${modelo.Codigo}</li>
    //                 <li>Fecha: ${radicacion.toLocaleString()}</li>
    //                 <li>Número de Oficios: ${this.NumeroOficios}</li>
    //                 <li>Número de Folios: ${this.modelo.modelo.NumeroFolios}</li>
    //                 `)
    //         + (anexos != null && valorAnexos != null ? `
    //                 <li>Anexos: ${anexos}</li>
    //                 ${valorAnexos}` : "")
    //         + "</ul>";


    /**
     * INICIO
     * 10/03/2021 - 1327 Edward Morales
     * Mostrar cantidad NumeroFolios documento Principal
     */
    if (this.modelo.modelo.NumeroFolios == '') {
      if (modelo.NumeroFolios == null) { modelo.NumeroFolios = 0; }

      this.modelo.modelo.NumeroFolios = modelo.NumeroFolios;
    }
    /**
     FIN
     */

    HtmlText.Value =
      `<ul class='ul__style'>
                        <li>Área: ${StaticData.Usuario.AreaEmpresa}</li>
                        <li>Usuario: ${StaticData.Usuario.Nombre}</li>
                        <li>Email: ${StaticData.Usuario.Email}</li>
                        <li>Código Solicitud: ${modelo.Codigo}</li>
                        <li>Tipo de Radicado: ${tipoSolicitud}</li>
                        <li>Fecha: ${radicacion.toLocaleString()}</li>
                        <li>Número de Folios: ${this.modelo.modelo.NumeroFolios}</li>
                        ` +
      (anexos != null && valorAnexos != null
        ? `
                        <li>Anexos: ${anexos}</li>
                        ${valorAnexos}`
        : "") +
      "</ul>";

    HtmlText.Value = `<div style="padding-top:20%; margin-left: 20% !important;">
      <b>Código Solicitud:</b> ${modelo.Codigo} <br />
      <b>Área:</b> ${StaticData.Usuario.AreaEmpresa} <br />
      <b>Usuario:</b> ${StaticData.Usuario.Nombre} - ${StaticData.Usuario.Email} <br />
      <b>Email:</b> ${StaticData.Usuario.Email}<br />
      <b>Tipo de Radicado:</b> ${tipoSolicitud} <br />
      <b>Fecha:</b> ${radicacion.toLocaleString()} <br />
      <b>Número de Folios:</b> ${this.modelo.modelo.NumeroFolios}
      <div>`;

    const modeloTemp = {
      configPermisos: this.GetConfigPermisos(),
      config: this.Pagina.ConfigPage.HiddenControls[2],
      limpiarInternamente: true,
      containerControl: container,
    };

    const componentName = "DynamicControlsContainerComponent";
    const config: ConfigWindow = new ConfigWindow();
    config.classWindow = "Info";
    config.titleWindow = "Resultado";
    config.returnInstance = true;
    config.modal = false;
    config.width = 750;
    config.height = 450;
    config.viewOk = false;
    config.cancel = "Cerrar";
    config.closeable = true;
    config.resizable = true;
    config.draggable = true;

    this.utility
      .OpenWindow(componentName, config, modeloTemp)
      .then((dataResult) => {
        dataResult.componentInstance.parentContext = this;

        // dataResult.componentInstance.AddComponentInstanceHandler(result => {
        //

        // });

        dataResult.onClosePromise.then((dataResultOnClose) => {
          dataResult.componentInstance.ngOnDestroy();
          dataResult.componentInstance = null;
        });
      });
  }

  SetDatosPorTipoContacto(tipo) {
    //CM
    if (tipo == 7) {
      this.RazonSocial.Hidden = true;
      this.Cargo.Hidden = true;
      this.Genero.Hidden = false;
      this.PrimerNombre.Hidden = false;
      this.SegundoNombre.Hidden = false;
      this.PrimerApellido.Hidden = false;
      this.SegundoApellido.Hidden = false;
    } else if (tipo == 10) {
      this.RazonSocial.Hidden = false;
      this.Cargo.Hidden = false;
      this.Genero.Hidden = true;
      this.PrimerNombre.Hidden = true;
      this.SegundoNombre.Hidden = true;
      this.PrimerApellido.Hidden = true;
      this.SegundoApellido.Hidden = true;
    }

    //FIN CM

    /*if (this.PrimerNombre) {
            if (tipo == 7)// ciudaddano
            {

                this.PrimerNombre.Hidden = false;
                this.PrimerNombre.Label = "Primer Nombre";
                this.PrimerApellido.Hidden = false;
                this.SegundoApellido.Hidden = false;
                this.SegundoNombre.Hidden = false;
            }
            else if (tipo == 10)// Empresa
            {
                this.PrimerNombre.Label = "Razón Social";
                this.PrimerApellido.Hidden = true;
                this.SegundoApellido.Hidden = true;
                this.SegundoNombre.Hidden = true;
            }
        }*/
  }

  GetUsersCopy() {
    if (this.modelo.UsuariosCopia && this.modelo.UsuariosCopia.length > 0) {
      this.modelo.modelo.DataAdd = {
        OperacionSolicitud: OperacionesDeSolicitudes.Copiar,
      };
      const UsrCopy = this.modelo.UsuariosCopia.map((data) => ({
        IdUsuario: data.IdUsuario,
        Nombre: data.Nombre,
        Email: data.Email,
      }));
      this.modelo.modelo.DestinatariosCopias = JSON.stringify(UsrCopy);
    }
  }

  HasFiles() {
    if (!this.IsEdit && this.modelo.modelo.EsDigital == false) {
      this.modelo.modelo.DocumentoPendiente = true;

      //CM Se ponen en comentarios debido a que toda solicitud Fisica debe ir a
      // Pendiente de Oficio (Estado Creada sin documento)

      // if (this.Oficio.isSelectedFiles())
      //     this.modelo.modelo.IdEstadoSolicitud = EstadosSolicitud.Creada;
      // else

      this.modelo.modelo.IdEstadoSolicitud =
        EstadosSolicitud.CreadaSinDocumento;
    } else if (this.Oficio)
      if (this.Oficio.selectedFiles.length > 0)
        this.modelo.modelo.DocumentoPendiente = false;
  }

  _Guardar(form: FormGroup, configButtons?: any) {
    let obj: any = null;
    try {
      if (this.Pagina.Valida != true || this.Pagina.Guardar != true) return;
      if (form.invalid) return;

      const _isEdit = this.IsEdit;
      if (this.modelo.modeloVirtual.GridRadicadosAsociados && this.modelo.modeloVirtual.GridRadicadosAsociados.length > 0) {
        this.modelo.modelo.AsociacionMultiple = JSON.stringify(this.modelo.modeloVirtual.GridRadicadosAsociados);
      }
      //SolicitudCommonComponent.PruebasEntradaRadicado = null;

      // const that = this;
      const processMessage =
        this.modelo.modelo.PkValue == "0" || this.modelo.modelo.PkValue == ""
          ? "Creando Registro de " + this.Pagina.Titulo
          : "Actualizando registro de " + this.Pagina.Titulo;

      if (configButtons && configButtons.Actions) {
        this.helperActions.ExecuteActionsControl(
          configButtons,
          false,
          null,
          this.modelo,
          this.permissionsConfig,
          this,
          null,
          new EventEmitter<any>(),
          this.componentsContainer,
          null,
          this.modelo.modeloMetadata,
          { importUrl: this.importUrl }
        );
      }

      this.ExcecuteFuntion(ControllerMethods.Save, false);

      //ESTADO. Asigna estado 1 o 17 (desde el botón de guardar.)
      if (_isEdit)
        this.FireFormEvent({ Name: "OnPreUpdate", modelo: this.modelo });
      else this.FireFormEvent({ Name: "OnPreInsert", modelo: this.modelo });

      if (this.Pagina.Pagina == "ProcesarSolicitudFlujoDocumental" && this.modelo.modeloVirtual.Tipofirma && this.modelo.modeloVirtual.Tipofirma === 46) {
        this.modelo.modelo["DataAdd"].Pin = this.modelo.modeloVirtual.Pin,
          this.modelo.modelo["DataAdd"].IdTipoIdentificacion = 1,
          this.modelo.modelo["DataAdd"].TipoFirma = "Digital"
      }
      else if (this.Pagina.Pagina == "ProcesarSolicitudFlujoDocumental" && this.modelo.modeloVirtual.Tipofirma && this.modelo.modeloVirtual.Tipofirma === 123) {

        this.modelo.modelo["DataAdd"].Pin = this.modelo.modeloVirtual.Pin
        this.modelo.modelo["DataAdd"].IdTipoIdentificacion = 1
        this.modelo.modelo["DataAdd"].TipoFirma = "Electronica"
        this.modelo.modelo["DataAdd"].CodigoOtp = this.modelo.modeloVirtual.CodigoOtp
      }
      else if (this.Pagina.Pagina == "SolicitudFlujoDocumental" && this.modelo.modeloVirtual.Tipofirma && this.modelo.modeloVirtual.Tipofirma === 46) {
        this.modelo.modelo["DataAdd"].IdTipoDocumental = this.modelo.modeloVirtual.IdTipoDocumental;
        this.modelo.modelo.IdTipoDocumental = this.modelo.modeloVirtual.IdTipoDocumental;
      }
      if (this.modelo.modeloMetadata.Items.Count() > 0) {
        this.modelo.modeloMetadata.IdParentObject = this.modelo.modelo.PkValue;
        this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
        this.modelo.modelo.Metadata = this.utility.Clone(
          this.modelo.modeloMetadata
        );
        this.modelo.modelo.Metadata.Items = this.modelo.modelo.Metadata.Items.ToArray();
      }
      //this.modelo.modeloMetadata.Id = this.modelo.modelo.PkValue;
      // this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
      // this.modelo.modelo.Metadata = this.modelo.modeloMetadata;

      //ASIGNACION DE ESTADOS

      if (
        this.name ===
        SolicitudCommonComponent.Controls.RadicadoFacturaRecepcion ||
        this.name ===
        SolicitudCommonComponent.Controls.RadicadoEntradaRecepcion ||
        this.name === SolicitudCommonComponent.Controls.PqrdRecepcion
      ) {
        this.modelo.modelo.IdEstadoSolicitud = 18;
      } 
        else {
        switch (this.calledCommandButton) {
          case SolicitudCommonComponent.CommandBarActions.VerDetalle:
            if(this.modelo.modelo.IdEstadoSolicitud !== 29 || this.name !== "SolicitudRadicadoEntrada"){
              this.modelo.modelo.IdEstadoSolicitud =
                EstadosSolicitud.SolicitudActualizada;
            }
            break;
          case SolicitudCommonComponent.CommandBarActions.Reasignar:
            this.modelo.modelo.IdEstadoSolicitud = EstadosSolicitud.Reasignada;
            break;
          case SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente:
            this.modelo.modelo.IdEstadoSolicitud =
              EstadosSolicitud.ExpedienteIncluido;
            break;
          case SolicitudCommonComponent.CommandBarActions.CopiarPQRS:
            this.modelo.modelo.IdEstadoSolicitud = EstadosSolicitud.Copiado;
            break;
          case SolicitudCommonComponent.CommandBarActions.CargarOficio:
            this.modelo.modelo.IdEstadoSolicitud =
              EstadosSolicitud.DocumentoAsignado;
            break;
          case SolicitudCommonComponent.CommandBarActions.Nuevo:
            //debugger;
            if (this.modelo.modeloVirtual.GridRadicadosAsociados) {
              this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
            }
            /**
             * Edward Morales 19122023
             * Al generarse la respuesta inmediata dicho radicado queda cerrado.
             * Incidencia 0000306
             */
            this.modelo.modelo.IdEstadoSolicitud = this.modelo.modelo.RequiereRespuesta && this.modelo.modelo.RequiereRespuesta == true && this.Pagina.Pagina == SolicitudCommonComponent.Paginas.PQR ? 5 : EstadosSolicitud.Creada;
            if (this.UsuarioResponsbale) {
              this.modelo.modelo.IdUsuarioResponsable = this.UsuarioResponsbale;
            }
            if (this.modelo.modelo.DataAdd) {
              this.modelo.modelo.DataAdd[
                "TextoComentario"
                ] = this.modelo.modeloVirtual.ComentarioRadicado;
              this.modelo.modelo.DataAdd["GuardaComentario"] = "false";
            } else {
              this.modelo.modelo.DataAdd = {
                TextoComentario: this.modelo.modeloVirtual.ComentarioRadicado,
                GuardaComentario: "false",
              };
            }
            this.HasFiles();
            break;
            break;
          case SolicitudCommonComponent.CommandBarActions.GestionarConcepto:
            if (this.modelo.modelo.DataAdd)
              this.modelo.modelo.DataAdd["OperacionSolicitud"] =
                OperacionesDeSolicitudes.GestionarConcepto;
            else
              this.modelo.modelo.DataAdd = {
                OperacionSolicitud: OperacionesDeSolicitudes.GestionarConcepto,
              };
            break;
          case SolicitudCommonComponent.CommandBarActions.RechazarAnulacion:
            if (this.modelo.modelo.DataAdd)
              this.modelo.modelo.DataAdd["OperacionSolicitud"] =
                OperacionesDeSolicitudes.RechazarAnulacion;
            else
              this.modelo.modelo.DataAdd = {
                OperacionSolicitud: OperacionesDeSolicitudes.RechazarAnulacion,
              };
            break;
          case SolicitudCommonComponent.CommandBarActions.AprobarAnulacion:
            if (this.modelo.modelo.DataAdd)
              this.modelo.modelo.DataAdd["OperacionSolicitud"] =
                OperacionesDeSolicitudes.AprobarAnulacion;
            else
              this.modelo.modelo.DataAdd = {
                OperacionSolicitud: OperacionesDeSolicitudes.AprobarAnulacion,
              };
            break;
          case SolicitudCommonComponent.CommandBarActions.VerComentario:
            if (this.modelo.modelo.DataAdd) {
              this.modelo.modelo.DataAdd[
                "TextoComentario"
                ] = this.modelo.modeloVirtual.Comentario;
              this.modelo.modelo.DataAdd["GuardaComentario"] = "true";
            } else {
              this.modelo.modelo.DataAdd = {
                TextoComentario: this.modelo.modeloVirtual.Comentario,
                GuardaComentario: "true",
              };
            }
            break;
          default:
            this.HasFiles();
            break;
        }
      }

      //this.modelo.modelo.DataAdd["GuardaComentario"] = this.calledCommandButton == SolicitudCommonComponent.CommandBarActions.VerComentario ? "true" : "false";
      //debugger
      obj = this.utility.GetApiModel(
        "Guardar",
        this.componentName,
        null,
        null,
        this.modelo.modelo
      );
      //debugger
      let isGeneralSave = true;

      if (
        this.componentsContainer.FileBox &&
        this.componentsContainer.FileBox.isSelectedFiles()
      ) {

        isGeneralSave = false;
        let externalFiles: any;
        if (this.componentsContainer.FileBoxWorkFlow != null) {
          externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
        }
        this.NumeroOficios = this.componentsContainer.FileBox.modeloArchivo.length;
        this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
      } else {
        let fileBoxConcepto = this.FileBoxes.find((x) => {
          return x.name == "ArchivoConcepto";
        });
        if (fileBoxConcepto) {
          fileBoxConcepto.ApiResponse.subscribe((event) => {
            this.RespuestaCargaDocumento(event);
          });
          if (fileBoxConcepto.isSelectedFiles()) {
            isGeneralSave = false;
            let externalFiles: any;
            if (this.componentsContainer.FileBoxWorkFlow != null) {
              externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
            }
            fileBoxConcepto.uploadFiles(false, obj, externalFiles);
          }
        }
      }

      // ////////Meter validacion antes de este
      //debugger;
      if (isGeneralSave) {
        //debugger;
        this.baseService
          .InsertUpdate(
            obj,
            form,
            {
              componentName: this.componentName + "Controller",
              method: "Guardar",
              processMessage: processMessage,
            },
            false,
            this.componentName,
            false,
            true
          )
          .then((data) => {
            //this.modelo.modelo = data.Data != null ? data.Data : this.modelo.modelo;

            this.FinGuardar(data.Data);

            if (_isEdit)
              this.FireFormEvent({
                Name: "OnPostUpdate",
                modelo: this.modelo,
                data: data,
              });
            else
              this.FireFormEvent({
                Name: "OnPostInsert",
                modelo: this.modelo,
                data: data,
              });
          })
          .catch((error) => {
            this.SetProcesando(false);
          });
      }
    } catch (error) {
      this.utility.logger.LogError(error, obj, {
        componentName: this.componentName,
        method: "_Guardar",
      });
      this.utility.VerModalDanger().then((data) => { });
      this.SetProcesando(false);
    }
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    switch (controlName) {
      case SolicitudCommonComponent.Controls.IdTemaSolicitud: {

        if (item) {
          /* if (item.Configuracion || item.IdUsuarioResponsable) {
             const configUsuarioResp = JSON.parse(item.Configuracion);
             if(item.Configuracion == null){
               this.modelo.modelo.IdUsuarioResponsable = item.IdUsuarioResponsable;
             }
             else if(item.Configuracion && configUsuarioResp.length == 0){
               this.modelo.modelo.IdUsuarioResponsable = item.IdUsuarioResponsable;

             }else{
             this.modelo.modelo.IdUsuarioResponsable = configUsuarioResp[0].Id;
             }*/
          //debugger;
          if (item.IdUsuarioResponsable) {
            this.modelo.modelo.IdUsuarioResponsable = item.IdUsuarioResponsable;
          } else
            this.utility.VerModalError(
              "La temática seleccionada no posee responsable"
            );
        }
        break;
      }
      case SolicitudCommonComponent.Controls.IdTipoRadicacion: {
        debugger
        this.modelo.modelo.EsDigital =
          item.IdDatoBasico == 48
            ? false
            : item.IdDatoBasico == 49
            ? true
            : false;
        if (this.modelo.modelo.EsDigital && !this.Change)
          if (this.name != "SolicitudPqrs") {
            this.Oficio.RaiseForceValidateSaveEvent(false, true);
          } else {
            this.Oficio.RaiseForceValidateSaveEvent(false, true);
          }
        else {
          this.Oficio.RaiseForceValidateSaveEvent(true, false);
          if (this.Oficio.Caption.length > 0) this.Change = true;
          else this.Change = false;
        }

        break;
      }
      case SolicitudCommonComponent.Controls.IdPais: {

        let obj = this.utility.GetApiModel("ConsultaIndicativo", null);
        obj.QueryConfig = {
          Command: "ConsultaIndicativo",
          IsSp: true,
          Entity: {
            IdPais: this.modelo.modeloCascada.IdPais,
          },
        };
        let info = {
          componentName: this.componentName,
          controlName: "ConsultaIndicativo",
          processMessage: "Cargando ConsultaIndicativo......",
        };

        this.baseService
          .Get(obj, null, info, this.componentName, false, false)
          .then((data) => {

            this.modelo.modelo.Indicativo = data.Data[0].Indicativo;
            this.SetModelToControls();
          })
          .catch((err) => {
            this.utility.logger.LogError(err, obj, {
              componentName: this.componentName,
              method: "Consulta",
            });
          });

        const context = this;
        this.helperActions.MultiSetControlsValue(
          [
            {
              ControlsToUpdate: ["CodigoPostal"],
              PropertiesToSet: {
                required: item.IdPais != 1,
              },
            },
          ],
          context
        );
        break;
      }
    }
    super.OnSelectedComboBox(item, controlName, config);
  }

  public OnDataTableEditHandler(event) {
    //debugger;
    this.UsuarioResponsbale = this.modelo.modelo.IdUsuarioResponsable;
    //this.modelo.modeloVirtual.GridRadicadosAsociados = null;
    /*if(event.name && event.name != "GrillaAsignarUsuario"){
      this.modelo.modeloVirtual.GridRadicadosAsociados.length = 0;
      }*/
    if (this.modelo.modelo.AsociacionMultiple && this.modelo.modelo.AsociacionMultiple != "" && this.modelo.modelo.AsociacionMultiple != "[]") {
      this.modelo.modeloVirtual.GridRadicadosAsociados = JSON.parse(this.modelo.modelo.AsociacionMultiple);

      /*this.modelo.modeloVirtual.GridRadicadosAsociados.forEach((element) => {
        element.Disabled = true;
        element.DisabledDelete = true;
      });*/
    }
    /*
    if(event.name && event.name == "GrillaAsignarUsuario" && this.Pagina && this.Pagina.Pagina && this.Pagina.Pagina == SolicitudCommonComponent.Paginas.Entrada && this.PruebasEntradaRadicado && this.PruebasEntradaRadicado.length>0){
      this.modelo.modeloVirtual.GridRadicadosAsociados = this.PruebasEntradaRadicado;
      //this.modelo.modeloVirtual.GridRadicadosAsociados = SolicitudRadicadoEntradaComponent.PruebasEntradaRadicado;
    }
    */

    switch (event.name) {
      case "GrillaReasignarUsuario": {
        this.modelo.modeloVirtual.ObligatorioReasignar = 1;
        break;
      }
      case "GrillaUsuarioConcepto": {
        this.modelo.modeloVirtual.ObligatorioConcepto = 1;
        break;
      }

      case SolicitudCommonComponent.Controls.GridViewPQRSAsignadas: {

        const esDigital = event.data.data.findItem.EsDigital;
        this.modelo.modeloVirtual.IdTipoRadicacion =
          esDigital == true ? 49 : esDigital == false ? 48 : null;

        this.Oficio.RaiseForceValidateSaveEvent(true, false);
        if (this.Asunto) {
          this.Asunto.Disabled = true;
          this.Asunto.Readonly = true;
        }

        if (event.modelo.modelo.IdCanalRecepcion == 44) {
          this.SetDatosPorTipoContacto(event.modelo.modelo.IdTipoContacto);
        }

        const findItemClone = this.utility.Clone(event.data.data.findItem);

        if (event.modelo.modelo.Anexos) {
          this.modelo.modeloVirtual.GrillaAnexos = JSON.parse(
            event.modelo.modelo.Anexos
          );
          //Los elementos quedan desactivados (ineditables)
          this.modelo.modeloVirtual.GrillaAnexos.forEach((element) => {
            element.Disabled = true;
            element.DisabledDelete = true;
          });
        }
        this.modelo.modeloVirtual.CanalRecepcion = findItemClone.CanalRecepcion;
        this.modelo.modeloVirtual.CodigoPqrdAsociada = findItemClone.CodigoPqrdAsociada
          ? findItemClone.CodigoPqrdAsociada
          : "";
        const tbUsuarioRechazo = this.componentsContainer.Tables.find((x) => {
          return x.name == "GrillaUsuariosRechazo";
        });
        tbUsuarioRechazo.QueryConfig.Entity = {
          IdEmpresa: -1,
          IdSolicitudPqrd: event.modelo.modelo.IdSolicitudPqrd,
        };
        tbUsuarioRechazo.cargarGrilla = true;
        tbUsuarioRechazo.ReCargarGrilla();

        //Config IdCausalRechazo
        this.configs.ConfigIdCausalRechazo.Params = {
          IdEmpresa: -1,
          IdTipoSolicitud: findItemClone.IdTipoSolicitud,
        };
        this.configs.ConfigIdCausalRechazo.Context.CargarItemsComboBoxRemoveNotLoad(
          null,
          true,
          null,
          true,
          false
        );

        this.modelo.selectedItem = findItemClone;
        break;
      }
      case SolicitudCommonComponent.Controls.GrillaAsignarExpediente: {
        this.modelo.modeloVirtual.NombreExpediente =
          event.modelo.expediente.Nombre;
        this.modelo.modeloVirtual.CodigoExpediente =
          event.modelo.expediente.Codigo;
        this.modelo.modeloVirtual.NumeroRadicacionProceso =
          event.modelo.expediente.NumeroRadicacionProceso;
        this.modelo.modelo.DataAdd = {
          OperacionSolicitud: OperacionesDeSolicitudes.IncluirEnExpediente,
        };

        if (event.data.data.findItem.ConfiguracionTRD == "")
          event.data.data.findItem.ConfiguracionTRD =
            '{"TiposDocumentales":[]}';
        const trd = JSON.parse(event.data.data.findItem.ConfiguracionTRD);
        const tiposDoc = trd["TiposDocumentales"];
        let carpetas = []
        if (trd["Carpetas"])
          carpetas = trd["Carpetas"]
        this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);
        this.configs.ConfigIdCarpeta.Context.SetItemsComboBox(carpetas);
        break;
      }
      case SolicitudCommonComponent.Controls.GrillaAsignarUsuario: {
        this.modelo.modelo.IdUsuarioResponsable =
          event.data.data.findItem.IdUsuario;
        break;
      }
      case SolicitudCommonComponent.Controls
        .GridViewRadicadosEntradaAsignados: {
        const esDigital = event.data.data.findItem.EsDigital;
        this.modelo.modeloVirtual.IdTipoRadicacion =
          esDigital == true ? 49 : esDigital == false ? 48 : null;

        const findItemClone = this.utility.Clone(event.data.data.findItem);
        this.modelo.selectedItem = findItemClone;
        this.modelo.modeloVirtual.CodigoRadicadoAsociado = findItemClone.CodigoRadicadoAsociado
          ? findItemClone.CodigoRadicadoAsociado
          : "";
        break;
      }
      case SolicitudCommonComponent.Controls.GridViewFacturasAsignadas: {
        const esDigital = event.data.data.findItem.EsDigital;
        this.modelo.modeloVirtual.IdTipoRadicacion =
          esDigital == true ? 49 : esDigital == false ? 48 : null;

        const findItemClone = this.utility.Clone(event.data.data.findItem);
        this.modelo.selectedItem = findItemClone;
        this.modelo.modeloVirtual.CodigoRadicadoAsociado = findItemClone.CodigoRadicadoAsociado
          ? findItemClone.CodigoRadicadoAsociado
          : "";
        break;
      }
    }
    super.OnDataTableEditHandler(event);
  }
  cambiarValorDeLlave(arr:any, llave: string, nuevoValor: any) {
    arr.forEach((objeto) => {
      for (const key in objeto) {
        if (objeto.hasOwnProperty(key)) {
          if (key == llave ) {
            objeto[llave] = nuevoValor;

          }
        }
      }
    });
  }
  public OnActionEventHandler(event) {
    switch (event.ControlType) {
      case SolicitudCommonComponent.Controls.BtnVincular:{
        this.modelo.modelo.IdEstadoSolicitud = 12
        if (this.modelo.selectedItems.length > 0){
          this.cambiarValorDeLlave(this.modelo.selectedItems, 'IdExpediente',this.modelo.modelo.IdExpediente);
          this.cambiarValorDeLlave(this.modelo.selectedItems, 'IdTipoDocumental', this.modelo.modelo.IdTipoDocumental);
          this.cambiarValorDeLlave(this.modelo.selectedItems, 'IdEstadoSolicitud', this.modelo.modelo.IdEstadoSolicitud);
          this.modelo.selectedItems.forEach((objeto) => {
            {
              this.modelo.modelo = objeto

              let obj: any = null;
              let _isEdit = this.IsEdit;
              // const that = this;
              let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
              obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
              this.baseService.InsertUpdate(obj, null, {
                  componentName: this.componentName + 'Controller',
                  method: 'Guardar', processMessage: processMessage
                },
                false, this.componentName, true, true)

            }

          })
          this.ReCargarGrilla()

          break;
        }
      }
        break;
    }
    switch (this.calledCommandButton) {
      case SolicitudCommonComponent.CommandBarActions.GestionarConcepto: {
        break;
      }
      case SolicitudCommonComponent.CommandBarActions.IncluirEnExpediente: {
        if (
          event.Action == "ClearModel" &&
          event.Table == "GrillaAsignarExpediente"
        ) {
          return;
        }
        break;
      }
      case SolicitudCommonComponent.CommandBarActions.Reasignar: {
        if (
          event.Action == "ClearModel" &&
          event.Table == "GrillaReasignarUsuario"
        ) {
          this.modelo.modelo.IdUsuarioResponsable = null;
          this.modelo.modeloVirtual.ObligatorioReasignar = null;
          this.SetModelToControls();
          return;
        }
        break;
      }
      case SolicitudCommonComponent.CommandBarActions.SolicitarConcepto: {
        if (
          event.Action == "ClearModel" &&
          event.Table == "GrillaUsuarioConcepto"
        ) {
          this.modelo.modelo.IdUsuarioResponsable = null;
          this.modelo.modeloVirtual.ObligatorioConcepto = null;
          this.SetModelToControls();
          const gvConcepto = this.componentsContainer.Tables.find((x) => {
            return x.name == "GrillaUsuarioConcepto";
          });
          gvConcepto.LimpiarFilterControls()
          return;
        }
        break;
      }
      case SolicitudCommonComponent.CommandBarActions.Copiar: {
        if (
          event.Action == "ClearModel" &&
          event.Table == "GrillaUsuarioCopia"
        ) {
          //this.modelo.modelo.IdUsuarioResponsable = null;
          this.SetModelToControls();
          return;
        }
        break;
      }
      case SolicitudCommonComponent.CommandBarActions.CopiarPQRS: {
        //debugger;
        if (event.Action == "Click") {
          //debugger;
          this.modelo.UsuariosCopia = null;
        }
        if (
          event.Action == "ClearModel" &&
          event.Table == "GrillaUsuarioCopia"
        ) {
          //this.modelo.modelo.IdUsuarioResponsable = null;

          this.modelo.modeloVirtual.UsuariosCopias = null;
          this.SetModelToControls();
          return;
        }
        break;
      }
    }
    if (
      this.calledCommandButton ==
      SolicitudCommonComponent.CommandBarActions.GestionarConcepto
    ) {
      //this.Observaciones.Required = true;
    }

    if (event.actionType == "OnViewItemExtendableTable") {
      //debugger;
      var consulta = null;
      var parametro = null;
      let obj = this.utility.GetApiModel("GetDetalleSolicitud", null);
      if (this.Pagina.Pagina == SolicitudCommonComponent.Paginas.PQR) {
        consulta = "ConsultaDetallePQRSAsociada";
        //parametro = "IdSolicitudPqrdAsociada";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudPqrdAsociada: event.data.Id,
          },
        };
      }
      if (this.Pagina.Pagina == SolicitudCommonComponent.Paginas.Entrada) {
        consulta = "ConsultaDetalleEntradaAsociada";
        //parametro = "IdSolicitudRadicadoEntradaAsociado";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudRadicadoEntradaAsociado: event.data.Id,
          },
        };
      }
      if (this.Pagina.Pagina == SolicitudCommonComponent.Paginas.Factura) {
        consulta = "ConsultaDetalleFacturaAsociada";
        //parametro = "IdSolicitudRadicadoFacturadAsociado";

        obj.QueryConfig = {
          Command: consulta,
          IsSp: true,
          Entity: {
            IdEmpresa: -1,
            IdSolicitudRadicadoFacturaAsociado: event.data.Id,
          },
        };
      }
      //debugger;



      let info = {
        componentName: this.componentName,
        controlName: "GetDetalleSolicitud",
        processMessage: "Cargando Detalle de la Solicitud......",
      };

      this.baseService
        .Get(obj, null, info, this.componentName, false, false)
        .then((data) => {

          this.modeloDetalle = data.Data[0];
          if (
            this.modeloDetalle.Anexos &&
            this.modeloDetalle.Anexos != ""
          ) {
            const Anexos = JSON.parse(this.modeloDetalle.Anexos);
            let valorAnexos = "";
            Anexos.forEach((anexo) => {
              valorAnexos += anexo.Nombre + ": " + anexo.Cantidad + "\n";
            });
            this.modeloDetalle.Anexos = valorAnexos;
          }
          if (this.componentsContainer.FormsDetails.length > 0) {

            let FormDetalleSolicitud = this.componentsContainer.Find(
              "FormDetalleSolicitud"
            );
            FormDetalleSolicitud.modelo = this.modeloDetalle;
          }

          let sidePanelEx = this.componentsContainer.Find("DetalleRadicadoAsociado");
          sidePanelEx.OpenSidePanel();

        })
        .catch((err) => { });
    }

    validateContact(this, "selectedItem", undefined, ["Genero"]);
    super.OnActionEventHandler(event);
  }
  public SetControlsValueDocument(NombreControl: any, propiedad: any, valorPropiedad) {
    let nom: any;
    Array.isArray(NombreControl) ? nom = NombreControl : nom = [NombreControl];
    const controlsSet = {
      ControlDetails: {
        MappingData: [],
        MappingFilter: []
      },
      ControlType: "Action",
      ControlsForHidden: [],
      ControlsToUpdate: nom,
      Guid: Guid.create().toString(),
      IsPost: false,
      IsParentModel: false,
      MappingValues: [],
      MappingComponents: [],
      Property: propiedad,
      PropertyValue: valorPropiedad,
      ResultType: "Url",
      SendQueryString: false,
      TimerAction: 0,
      Type: ControlActions[ControlActions.SetControlProperty]
    };
    const context = this;
    this.helperActions.SetControlsValue(controlsSet, context);
  }
}

export namespace SolicitudCommonComponent {
  export enum CommandBarActions {
    Nuevo = "Nuevo",
    VerDetalle = "Detalle",
    Responder = "Responder",
    Asignar = "Asignar",
    IncluirEnExpediente = "IncluirenExpediente",
    IncluirEnExpedienteMasivo = "IncluirEnExpedienteMasivo",
    CopiarPQRS = "CopiarPQRS",
    VerEstados = "Estados",
    Documentos = "Documentos",
    Reasignar = "Reasignar",
    CargarOficio = "CargarOficio",
    SolicitarConcepto = "SolicitarConcepto",
    GestionarConcepto = "GestionarConcepto",
    Copiar = "Copiar",
    VerComentario = "VerComentarios",
    SolicitarVistoBueno = "SolicitarVistoBueno",
    SolicitarAprobacion = "SolicitarAprobación",
    SolicitarAnulacion = "SolicitaAnulacion",
    AprobarVoBo = "AprobarVoBo",
    AprobarDocumento = "AprobarDocumento",
    RechazarProceso = "RechazarProceso",
    RechazarAnulacion = "RechazarAnulacion",
    AprobarAnulacion = "AprobarAnulacion",
    Eliminar = "Eliminar",
    Filtro = "Filtro",
    // 19/10/2023- Andres Correa- HU Ferrocarriles Agg funcion Edición de plantilla
    EditarPlantilla="EditarPlantilla"

  }
  export enum Controls {
    InfoAnexos = "InfoAnexos",
    RazonSocial = "RazonSocial",
    Cargo = "Cargo",
    Genero = "Genero",
    PrimerNombre = "PrimerNombre",
    SegundoNombre = "SegundoNombre",
    PrimerApellido = "PrimerApellido",
    SegundoApellido = "SegundoApellido",
    IdCausalRechazo = "IdCausalRechazo",
    IdCanalRecepcion = "IdCanalRecepcion",
    CanalRecepcion = "CanalRecepcion",
    Asunto = "Asunto",
    Respuesta = "Respuesta",
    RequiereRespuesta = "RequiereRespuesta",
    Observaciones = "Observaciones",
    GrillaAnexos = "GrillaAnexos",
    // GrillaAnexosDetalle = "GrillaAnexosDetalle",
    IdTipoDocumental = "IdTipoDocumental",
    IdContacto = "IdContacto",
    BtnContactos = "BtnContactos",
    MensajeSeleccionarContacto = "MensajeSeleccionarContacto",
    BtnVincular = "BtnVincular",
    BtnGuardarVincular = "BtnGuardarVincular",
    BtnPqrdAsociada = "BtnPqrdAsociada",
    BtnAsociarSolicitud = "BtnAsociarSolicitud",
    BtnDetalleSolicitud = "BtnDetalleSolicitud",
    IdTemaSolicitud = "IdTemaSolicitud",
    IdTipoRadicacion = "IdTipoRadicacion",
    Resultado = "Resultado",
    QRCodeBoxResult = "QRCodeBoxResult",
    HtmlText = "HtmlText",
    GrillaReasignarUsuario = "GrillaReasignarUsuario",
    GrillaUsuarioConcepto = "GrillaUsuarioConcepto",
    GrillaUsuariosRechazo = "GrillaUsuariosRechazo",
    GrillaAsignarExpediente = "GrillaAsignarExpediente",
    GridViewPQRSAsignadas = "GridViewPQRSAsignadas",
    GrillaAsignarUsuario = "GrillaAsignarUsuario",
    GridViewRadicadosEntradaAsignados = "GridViewRadicadosEntradaAsignados",
    ResponsableManual = "ResponsableManual",
    GridViewFacturasAsignadas = "GridViewFacturasAsignadas",
    Recepcion = "Recepcion",
    RadicadoFacturaRecepcion = "RadicadoFacturaRecepcion",
    RadicadoEntradaRecepcion = "RadicadoEntradaRecepcion",
    PqrdRecepcion = "PqrdRecepcion",
    IdTipoSolicitud = "IdTipoSolicitud",
    IdPais = "IdPais",
    NumeroFolios = "NumeroFolios",
    BtnGuardarComentario = "BtnGuardarComentario",
    ObservacionesAnulacion = "ObservacionesAnulacion",
    GridRadicadosAsociados ="GridRadicadosAsociados",
    BtnBuscarRadicados = "BtnBuscarRadicados",
    BtnLimpiarRadicados = "BtnLimpiarRadicados",
    BtnLimpiarReasignar = "BtnLimpiarReasignar",
    BtnLimpiar = "BtnLimpiar"
  }
  /**INICIO
   * 04/02/2021 16:55 Edward Morales
   * Parametrizar Páginas para Stickers
   */
  export enum Paginas {
    PQR = "SolicitudPqrs",
    Entrada = "SolicitudRadicadoEntrada",
    Factura = "SolicitudRadicadoFactura"
  }
}
