import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ComponentsContainer } from 'src/app/models/components-container';
import { Utilities } from 'src/app/helpers/utilities';
import { DataTableComponent } from '../data-table/data-table.component';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { CommandBarComponent } from '../command-bar/command-bar.component';
import { ConfigWindow } from 'src/app/models/config-window';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { StaticData } from 'src/app/helpers/static-data';
import { FormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { HelperCharts } from 'src/app/helpers/helper-charts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('contextMenuDashboard', { static: false }) public contextMenuDashboard: ContextMenuComponent;
  @ViewChild(CommandBarComponent, { static: false }) public commandBar: CommandBarComponent;

  @Input() subControl: any;
  @Input() componentsContainer: ComponentsContainer = new ComponentsContainer();
  @Output() onInitialized = new EventEmitter<any>();
  @Output() name: string = "Dashboard_" + Guid.create().toString();
  @Input() Pagina: any = { Valida: false, ConfigPage: { ExtraControls: [], Controls: [] } };
  @Input() pageId: any;
  @Input() viewCommandBar: boolean = true;
  @Input() viewComponentHeader: boolean = true;
  componentName = "DashboardComponent";
  @Input() filterParent: any;
  internalFilter: any;
  viewFilterControl: boolean = false;
  isComponent: boolean = false;
  viewFilters: boolean = false;
  hasFilters: boolean = false;

  @Input() PaginaPadre: any
  @Input() inMainPage = true;

  @Input() form: FormGroup;

  @Input() isDesigner: boolean = false;
  labelTipoControlFiltro: string = "";
  labelTipoFiltro: string = "";

  public resizable: boolean;
  public expandMode: boolean;
  public minimizeMode: boolean;
  public collapseMode: boolean;
  helperCharts: HelperCharts = null;
  minHeight = null;

  public isDashboard: boolean = true;
  @Input() parentContext: any;
  @Input() currentContext: any;
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    this.resizable = true;
    this.expandMode = false;
    this.minimizeMode = false;
    this.collapseMode = false;
  }

  public GetTextMessage(method: string): string {
    return `componentName: ${this.componentName} method: ${method}`;
  }

  public InitChart() {
    if (!this.subControl.Style)
      this.subControl.Style = {};
    this.helperCharts = new HelperCharts(this, false, this.utility, "subControl", false);

    this.hasFilters = (this.subControl.FilterControls && this.subControl.FilterControls.length > 0) ? true : false;

    this.name = this.subControl.Name;

    this.helperCharts.SetlabelsTiposFilter();
  }
  ngOnInit() {

   
    try {

      if (this.subControl && this.subControl.Dashboard) {
        this.isComponent = true;
        if (this.subControl.Dashboard.PageId)
          this.pageId = this.subControl.Dashboard.PageId;
        if (this.subControl.Dashboard.IdPagina)
          this.pageId = this.subControl.Dashboard.IdPagina;
        this.name = this.subControl.Name;


      }


      //this.location.prepareExternalUrl(this.location.path());
      this.utility.logger.LogInfoText(this.GetTextMessage('ngOnInit'));

      if (!this.pageId && !this.isDesigner)
        this.pageId = this.utility.router.url.replace('/', '');

      if (!this.Pagina.Valida && this.pageId)
        this.Pagina = this.utility.ValidateUserAndPage(this.pageId, true, this.componentName, 'ngOnInit');

      if (!this.Pagina || (this.Pagina && this.Pagina.Valida !== true)) {

        return;

      }
      if (!this.subControl) {
        this.subControl = {
          FilterControls: this.Pagina.ConfigPage.FilterControls,
          ModalFilter: this.Pagina.ConfigPage.ModalFilter,
          WithQueryBuilder: this.Pagina.ConfigPage.WithQueryBuilder,
          HideToolBar: this.Pagina.ConfigPage.HideToolBar,
          ViewCommandBar: this.Pagina.ConfigPage.ViewCommandBar,
          ViewComponentHeader: this.Pagina.ConfigPage.ViewComponentHeader,
        };

      }
      this.viewCommandBar = this.subControl.ViewCommandBar;
      this.viewComponentHeader = this.subControl.ViewComponentHeader;

      this.InitChart();
      if (this.Pagina.ConfigPage.MaxContainer) {

        this.Pagina.Estilos.ClassContenedorGeneral = '';
        this.Pagina.Estilos.EstiloContenedorGeneral = '';
      }
      this.componentName = this.Pagina.Controlador.replace('Controller', '')

      if (this.utility["DashboardFilterParent"])
        this.filterParent = this.utility.Clone(this.utility["DashboardFilterParent"]);
      this.utility["DashboardFilterParent"] = null;

      if (this.filterParent) {

        if (this.commandBar) {
          if (this.filterParent.IsFromParentNavigation) {
            this.commandBar.SetHiddenOptionByType(false, "Return");
          }
          else {
            this.commandBar.SetHiddenOptionByType(true, "Return");
          }
        }
        //this.commandBar
      }



    }
    catch (error) {

      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'ngOnInit' });
    }
  }

  ngAfterViewInit() {
    // if (this.Pagina.Valida){
    //   this.componentsContainer.
    // }
    if (this.Pagina.Valida && this.onInitialized.observers.length) {
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }

    if (!this.isDesigner && this.filterParent) {

      setTimeout(() => {
        this.ExcecuteDataSource();
      }, 1000);
    }

  }
  public AddComponentInstanceHandler(event) {


    let controlToHide: any = null;

    if (event instanceof DataTableComponent) {
      this.componentsContainer.Tables.push(event);

    }
    else if (event instanceof DashboardComponent) {
      this.componentsContainer.Dashboards.push(event);
    }

  }

  public OnActionEventHandler(event) {

    if (event.ControlType == "CommandButton") {

     
      if (event.Option.Type == "Filter") {

      } else if (event.Option.Type == "Return") {
        this.utility.router.navigate([this.filterParent.Source]);
      }
      else {
        this.helperActions.ExecuteActionsControl((event.Option) ? event.Option : event.config,
          false, null, null, null,
          this, null, new EventEmitter<any>(), this.componentsContainer,
          event.Option, null, null);
      }

    }
  }
  ngOnDestroy() {

    this.componentsContainer.DestroyAll()
  }

  public RefreshData() {

    this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).forEach(formControl => {
      if (formControl.ControlType == "LineChart" ||
        formControl.ControlType == "AreaChart" ||
        formControl.ControlType == "StackedAreaChart" ||
        formControl.ControlType == "NormalizedAreaChart" ||
        formControl.ControlType == "HorizontalBarChart" ||
        formControl.ControlType == "VerticalBarChart" ||
        formControl.ControlType == "NormalizedHorizontalBarChart" ||
        formControl.ControlType == "StackedVerticalBarChart" ||
        formControl.ControlType == "GroupedHorizontalBarChart" ||
        formControl.ControlType == "NormalizedVerticalBarChart" ||
        formControl.ControlType == "StackedHorizontalBarChart" ||
        formControl.ControlType == "GroupedVerticalBarChart" ||
        formControl.ControlType == "PieChart" ||
        formControl.ControlType == "PieGridChart" ||
        formControl.ControlType == "AdvancedPieChart" ||
        formControl.ControlType == "HeatMapChart" ||
        formControl.ControlType == "GaugeChart" ||
        formControl.ControlType == "LinearGaugeChart" ||
        formControl.ControlType == "NumberCardChart" ||
        formControl.ControlType == "PolarRadarChart" ||
        formControl.ControlType == "TreeMapChart" ||
        formControl.ControlType == "Dashboard") {

        let child = this.componentsContainer.Find(formControl.Name);

        child.RefreshData();
      }
    });
  }
  public ExcecuteDataSource(filterParent?: any) {

    if (filterParent)
      this.filterParent = filterParent;


  }



  CURRENT_CONTROL: any;
  public VerEditor(tipo: any, subTipo: string) {


    const that = this;

    try {
      if (subTipo == 'modelo') {
        this.utility.VerEditor(tipo, this.subControl, true, "Editor de Modelo").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'workFlowItem') {
        this.utility.VerEditor(tipo, this.subControl, true, "Editor de WorkFlowItem").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'page') {
        this.utility.VerEditor(tipo, this.Pagina, true, "Editor de Pagina").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }
      else if (subTipo == 'form') {
        console.log(this.form);
        // let frm = JSON.stringify(this.form, null, 2);

        var cache = [];
        let frm = JSON.stringify(this.form, function (key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Duplicate reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        }, 2);
        cache = null; // Enable garbage collection
        this.utility.VerEditor(tipo, frm, true, "Editor de Formulario").then(data => {
          if (data.dialogResult == true &&
            data.modelo) {
          }
        });
      }


    }
    catch (error) {

      this.utility.logger.LogError(error, 'VerEditor')
    }

  }

  public ExportToJson() {

    const filename = this.Pagina.Pagina + '.json';

    const jsonStr = JSON.stringify(this.subControl, null, 2);// JSON.stringify(this.PaginaSeleccionada.ConfigPage);

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  public ImportToJson(evt) {
    try {

      let files = evt.target.files;
      if (!files.length) {
        alert('No selecciono un archivo!');
        return;
      }
      let file = files[0];
      let reader = new FileReader();
      const self = this;
      reader.onload = (event: any) => {


        this.subControl = JSON.parse(event.target.result.toString());


      };
      reader.readAsText(file);
    } catch (err) {
      console.error(err);
    }
  }
}
