import { Component, OnInit, AfterViewInit, Input, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service'
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { FileUploaderComponent } from '../../controls/file-uploader/file-uploader.component';
import { StaticData } from 'src/app/helpers/static-data';
import { HelperActions } from 'src/app/helpers/helper-actions';

@Component({
	selector: 'app-rplantilla',
	templateUrl: './rplantilla.component.html',
	styleUrls: ['./rplantilla.component.css']
})
export class RPlantillaComponent extends GeneralFormComponent implements OnInit, AfterViewInit {
	public childComponents: Array<any> = [];
	filUploader: FileUploaderComponent;
	isModelContainer: boolean = true;
	ConfigCodigo: any;
	limpiarDocumento: boolean;
	@Input() pkValueEdit: any;

	constructor(public baseService: BaseServiceService,
		public utility: Utilities,
		public helperImages: HelperImages,
		public helperRules: HelperRules,
		public helperActions: HelperActions) {
		super(baseService, utility, helperImages, helperRules, helperActions);
		this.pkName = 'IdPlantilla';
	}

	public LimpiarDocumentos() {		

		if (this.filUploader && this.limpiarDocumento)
			this.filUploader.clear();
	}
	public Limpiar(callback: (data: any) => void = null, action?: any) {
		
		this.ClearModel();
		this.utility.SetFieldsToModel(this.modelo.modelo, null);
		if (callback) callback(this.modelo);
		this.SetModelToControls();
		this.parentClearCall.next({ modelo: this.modelo, action: action });
		this.SetProcesando(false);
		if (this.limpiarDocumento) {
			this.LimpiarDocumentos();
		}
		//this.componentsContainer.Tables[0].ReCargarGrilla();
	}

	public OnInitEnd() {		

		this.ConfigCodigo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Codigo" });

		this.postSaveEmitter.subscribe(event => {
			this.GuardarArchivo(event);
			this.limpiarDocumento = true;
			this.ReCargarGrilla();			
			this.componentsContainer.ReloadTable("DataTable_AdministracionPlantilla", { IdEmpresa: -1 }, null, false);
		});

	}

	_Guardar(form: FormGroup, configButtons?: any) {
		
		super._Guardar(form, configButtons);
	}

	public GuardarArchivo(event) {

		const that = this;
		let token: string = StaticData.Usuario.Token;
		let pkValue: any;
		let codigo: string;
		let idTipoUbicacionArchivo: any;
		let nombre: string;
		let descripcion: string;
		let version: string;
		let activo: any;
		let ubicacionArchivo: any;

		if (event) {
			pkValue = event.data.data.IdPlantilla;
			codigo = event.data.data.Codigo;
			idTipoUbicacionArchivo = event.data.data.IdTipoUbicacionArchivo;
			nombre = event.data.data.Nombre;
			descripcion = event.data.data.Descripcion;
			version = event.data.data.Version;
			activo = event.data.data.Activo;
			ubicacionArchivo = event.data.data.UbicacionArchivo;
		}
		else {
			pkValue = this.modelo.IdPlantilla;
			pkValue = this.modelo.Codigo;
			idTipoUbicacionArchivo = this.modelo.IdTipoUbicacionArchivo;
			nombre = this.modelo.Nombre;
			descripcion = this.modelo.Descripcion;
			version = this.modelo.Version;
			activo = this.modelo.Activo;
			ubicacionArchivo = this.modelo.UbicacionArchivo;
		}

		let model: any = {
			IdPlantilla: pkValue,
			PkValue: pkValue,
			Token: token,
			AppKey: StaticData.AppKey,
			Codigo: codigo,
			IdTipoUbicacionArchivo: idTipoUbicacionArchivo,
			MethodNameUI: 'Guardar',
			Nombre: nombre,
			Descripcion: descripcion,
			Version: version,
			Activo: activo,
			UbicacionArchivo: ubicacionArchivo,
		}
		if (!this.filUploader.isSelectedFiles()) {
			this.utility.logger.LogWarningText('No hay Plantilla para cargar');
			StaticData.ShowNotification("Guardado sin plantilla", "warning", "top", "right", "Notificaci&oacute;n", 3000);
			return;
		}
		/**
		 * Inicio Edward Morales - 26022024
		 * Conservar nombre original de configuración de plantilla para proceso Upload
		 */
		const nombrePlantilla = JSON.parse(ubicacionArchivo).SourceLoction.Name;
		this.filUploader.modeloArchivo[0].Name = nombrePlantilla;
		this.filUploader.Caption[0] = nombrePlantilla;
		this.filUploader.uploadFiles(false, model);

	}
	public AddComponentInstanceHandler(event) {		

		if (event instanceof FileUploaderComponent) {
			this.filUploader = event;
		}
		else {
			super.AddComponentInstanceHandler(event);
		}		
	}

	public OnDataTableEditHandler(event) {
		this.SetModelToControls();
		super.OnDataTableEditHandler(event)
	}

	public OnActionEventHandler(event) {		

		switch (event.ControlType) {
			case 'CommandButton':
				if (event.Option.Type == "Download" || event.Option.Type == "ViewDocument") {

					this.componentName = "RPlantilla";
					this.helperActions.ExecuteActionsControl(
						event.Option ? event.Option : event.config,
						false,
						this.modelo.modelo,
						this.modelo,
						this.permissionsConfig,
						this,
						null,
						new EventEmitter<any>(),
						this.componentsContainer,
						event.Option,
						this.modelo.modeloMetadata,
						{ importUrl: this.importUrl }
					);

				}
				else if (event.Option.Type == "Edit") {
					this.ConfigCodigo.Readonly = true;
					super.OnActionEventHandler(event);

				}
				else if (event.Option.Type == "New") {
					this.ConfigCodigo.Readonly = false;
					super.OnActionEventHandler(event);
				}
				else
					super.OnActionEventHandler(event);
				break;
			case 'UploadFile':
				this.EventoCargarDocumento(event);
				break;
			case 'ApiResponse':

				this.RespuestaCargaDocumento(event);
				break;
			case 'Change':
				this.EventoSeleccionArchivo(event);
				break;
			case 'ProgressEvent':
				this.EventoProgresoCargaDocumento(event);
				break;
			case 'Button':
				super.OnActionEventHandler(event);
				break;
			default:
				super.OnActionEventHandler(event);
				break;
		}
	}


	public RespuestaCargaDocumento(event) {
		
		if (event.data) {
			let obj: any = JSON.parse(event.data);

			if (obj.Ok) {

				this.Limpiar();
				//this.componentsContainer.Tables[0].ReCargarGrilla();
			}
			else {
				this.filUploader.selectedFiles = [];
			}
		}
		//this.componentsContainer.Tables[0].ReCargarGrilla();
	}
	public EventoProgresoCargaDocumento(event) {


	}

	public EventoCargarDocumento(event) {
		
		this.limpiarDocumento = true;
		this.GuardarArchivo(null);
		//this.componentsContainer.Tables[0].ReCargarGrilla();

	}
	public EventoSeleccionArchivo(event) {
		
		this.limpiarDocumento = false;
	}
}
