//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods, DataTableAjaxOrderTypes, TypesUseForm, ControlActions } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { StaticData } from 'src/app/helpers/static-data';
import { Enumerable, List } from "../../../../assets/linqts/compilado/index";
import { ActionTemplate } from 'src/app/models/rule-engine/rules-models';

@Component({
  selector: 'app-rplantilla-usuario',
  templateUrl: './rplantilla-usuario.component.html',
  styleUrls: ['./rplantilla-usuario.component.css']
})
export class RPlantillaUsuarioComponent extends GeneralFormComponent implements OnInit, AfterViewInit {



  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    super(baseService, utility, helperImages, helperRules, helperActions);
  }
  @Input() set template(value: ActionTemplate) {
    this.modelo = value;
  }
  get template(): ActionTemplate {
    return this.modelo;
  }


  // ngAfterViewInit() {
    // 	this.userModel.Nueva();
  // }

  // public OnInitEnd() {
  // }

  public Limpiar(callback: (data: any) => void = null) {
    this.modelo.Content = null
    super.Limpiar()
  }

  public OnDataTableEditHandler(event) {
    
    this.modelo.Content = null;
    super.OnDataTableEditHandler(event)
  }
  public OnActionEventHandler(event) {
      
    switch (event.ControlType) {
      case 'Button':
        switch (event.Name) {
          case 'Btn_Plantilla': {
            this.VerEditorTemplate();
            break;
          }
        }

      default:
        super.OnActionEventHandler(event);
        break;
    }
  }

  public VerEditorTemplate() {

    // HelperQuery.ModalQueryEditor($scope.modelo.Command, function (comando) {
    //     $scope.modelo.Command = comando;
    // });
    
    this.template.Content = this.modelo.modelo.Plantilla
    this.utility.VerEditor('3', this.template.Content, true, 'EDITOR', 'html', true).then(data => {

      if (data.dialogResult == true) {        
        this.modelo.modelo.Plantilla = data.modelo;
      }
    });
  };
  
}

