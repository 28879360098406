import {
    Component,
    Input,
    OnInit
} from "@angular/core";

import { ConfigExpedienteDocumento } from "../../general-config/config-expediente-documento";

@Component({
    selector: "app-config-consecutivo-documento",
    templateUrl: "./config-consecutivo-documento.component.html",
    styleUrls: ["./config-consecutivo-documento.component.css"]
})
export class ConfigConsecutivoDocumentoComponent extends ConfigExpedienteDocumento implements OnInit {

  @Input() verGenerarNuevoCodigo =true;
  public OnInitStart() {
      this.setOpcionesPermisos = false;
      this.setTiposIdExpediente = false;
  }

}