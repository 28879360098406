import { EventEmitter, Injectable } from "@angular/core";

import { BaseServiceService } from "../services/base-service.service";
import { StaticData } from "../helpers/static-data";

export enum LogTypes {
  Info = 1,
  Success,
  Warning,
  Error,
  SuccessText,
  InfoText,
  WarningText,
  ErrorText,
}

@Injectable({
    providedIn: "root"
})
export class LoggerService {

  public ReceivedConsoleLog: EventEmitter<any> = new EventEmitter<any>();

  baseService: BaseServiceService;
  constructor() { }

  private GetMessage(message: string, textLogTypes: string, info: any, useConsoleTypes = false) {
      message = (useConsoleTypes) ? message + textLogTypes : "%c" + message + textLogTypes;
      if (info) {
          if (info.containerComponentName)
              message = message + " containerComponentName:" + info.containerComponentName;
          if (info.componentName)
              message = message + " componentName:" + info.componentName;
          if (info.funcion)
              message = message + " Function:" + info.funcion;
          if (info.method)
              message = message + " method:" + info.method;
          if (info.controlName)
              message = message + " controlName:" + info.controlName;
      }
      return message;
  }
  private GetColor(color: string): string {
      return "color:" + color + "; font-weight: bold;";
  }

  public ConsoleLog(textLogTypes: string, color: string, response: any, requestParam: any, info: any = null, url: string = null,
      tiempoProceso: any = null, print = true, printRespuesta = true,
      consoleProperty = "log", useConsoleTypes = false): string {


      let message = "";
      let messageResult = "";
      let objectoPrint={};
      if (typeof response === "string" || response instanceof String)
          message = message + " " + response.toString();
      else if (response && !message) {
          try {
              objectoPrint =response;
              message = JSON.stringify(response);
          }
          catch (subErr) {
      
       
          }
      }

      const currentTime = new Date();
      if (useConsoleTypes) {
          if (consoleProperty != "error" && consoleProperty != "warn")
              useConsoleTypes = false;
      }
      message = this.GetMessage(message, textLogTypes, info, useConsoleTypes);
      messageResult = message;

      if (print) {
          if (useConsoleTypes)
              console[consoleProperty](message + " [" + currentTime.toString() + "]",objectoPrint);
          else
              console[consoleProperty](message + " [" + currentTime.toString() + "]", this.GetColor(color),objectoPrint);
      }

      if (tiempoProceso) {
          if (print) {
              if (useConsoleTypes)
                  console[consoleProperty]("Tiempo Proceso:", tiempoProceso);
              else
                  console[consoleProperty]("%cTiempo Proceso:", this.GetColor(color), tiempoProceso);
          }
          messageResult = messageResult + ",Tiempo Proceso:" + tiempoProceso;
      }
      if (info) {
          if (print) {
              if (useConsoleTypes)
                  console[consoleProperty]("Informacion:", info);
              else
                  console[consoleProperty]("%cInformacion:", this.GetColor(color), info);
          }
          messageResult = messageResult + ",Informacion:" + JSON.stringify(info);
      }
      if (url) {
          if (print) {
              if (useConsoleTypes)
                  console[consoleProperty]("Url:", url);
              else
                  console[consoleProperty]("%cUrl:", this.GetColor(color), url);
          }
          messageResult = messageResult + ",Url:" + url;
      }
      if (requestParam) {
          if (print) {
              if (useConsoleTypes)
                  console[consoleProperty]("Parametro Solicitid:", requestParam);
              else
                  console[consoleProperty]("%cParametro Solicitid:", this.GetColor(color), requestParam);
          }
          messageResult = messageResult + ",Parametro Solicitid:" + JSON.stringify(requestParam);
      }
      if (response && printRespuesta) {
          if (useConsoleTypes)
              console[consoleProperty]("Respuesta Solicitud:", response);
          else
              console[consoleProperty]("%cRespuesta Solicitud:", this.GetColor(color), response);
      // messageResult = messageResult + ',Respuesta Solicitud:' + JSON.stringify(response);
      }

      if (print) {
          if (useConsoleTypes)
              console[consoleProperty]("Fin----------------------------------");
          else
              console[consoleProperty]("%cFin----------------------------------", this.GetColor(color));
      }

      return messageResult;
  }
  public Log(response: any, logType: LogTypes = LogTypes.Info, requestParam: any, info: any = null, url: string = null, tiempoProceso: any = null) {
      let msg = "";
      let consoleProperty = "log";
      try {

          /*   let message:string='';
        if (typeof response === 'string' || response instanceof String) 
          message =message +' '+ response.toString();
  
          let currentTime = new Date(); */

          /*  console.log('%c Success '+logType.toString(),"color:green; font-weight: bold;",requestParam,response);
       console.log('%c '+logType.toString(),requestParam,response, "color:blue; font-weight: bold;");
       console.log('%c '+logType.toString(),requestParam,response, "color:orange; font-weight: bold;");
       console.log('%c '+logType.toString(),requestParam,response, "color:red; font-weight: bold;");
       console.log('%c the green hulk got mad!', 'color: green; font-weight: bold;'); */
          const useConsoleTypes: boolean = (StaticData.GeneralConfig.ConfigUILog.DisableConsoleTypes != true) ? true : false;

          switch (logType) {
          case LogTypes.Success:
              if (useConsoleTypes)
                  consoleProperty = "log";
              msg = this.ConsoleLog(" Success", "green", response, requestParam, info, url, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirOk, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaOk, consoleProperty, useConsoleTypes);
              this.ConsoleLogOk(msg);
              this.SaveLog(msg, false, StaticData.GeneralConfig.ConfigUILog.EnviarServidorOk);
              break;
          case LogTypes.SuccessText:
              if (useConsoleTypes)
                  consoleProperty = "log";
              msg = this.ConsoleLog(" SuccessText", "green", response, null, null, null, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirOk, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaOk, consoleProperty, useConsoleTypes);
              this.ConsoleLogOk(msg);
              this.SaveLog(msg, false, StaticData.GeneralConfig.ConfigUILog.EnviarServidorOk);
              break;
          case LogTypes.Info:
              if (useConsoleTypes)
                  consoleProperty = "info";
              msg = this.ConsoleLog(" Info", "blue", response, requestParam, info, url, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirInfo, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaInfo, consoleProperty, useConsoleTypes);
              this.ConsoleLogInfo(msg);
              this.SaveLog(msg, false, StaticData.GeneralConfig.ConfigUILog.EnviarServidorInfo);
              break;
          case LogTypes.InfoText:
              if (useConsoleTypes)
                  consoleProperty = "info";
              msg = this.ConsoleLog(" InfoText", "blue", response, requestParam, info, url, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirInfo, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaInfo, consoleProperty, useConsoleTypes);
              this.ConsoleLogInfo(msg);
              this.SaveLog(msg, false, StaticData.GeneralConfig.ConfigUILog.EnviarServidorInfo);
              break;
          case LogTypes.Warning:
              if (useConsoleTypes)
                  consoleProperty = "warn";
              msg = this.ConsoleLog(" Warning", "orange", response, requestParam, info, url, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirAlerta, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaAlerta, consoleProperty, useConsoleTypes);
              this.ConsoleLogAlerta(msg);
              this.SaveLog(msg, false, StaticData.GeneralConfig.ConfigUILog.EnviarServidorAlerta);
              break;
          case LogTypes.WarningText:
              if (useConsoleTypes)
                  consoleProperty = "warn";
              msg = this.ConsoleLog(" WarningText", "orange", response, requestParam, info, url, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirAlerta, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaAlerta, consoleProperty, useConsoleTypes);
              this.ConsoleLogAlerta(msg);
              this.SaveLog(msg, false, StaticData.GeneralConfig.ConfigUILog.EnviarServidorAlerta);
              break;
          case LogTypes.Error:
              if (useConsoleTypes)
                  consoleProperty = "error";
              msg = this.ConsoleLog(" Error", "red", response, requestParam, info, url, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirError, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaError, consoleProperty, useConsoleTypes);
              this.ConsoleLogError(msg);
              this.SaveLog(msg, true, StaticData.GeneralConfig.ConfigUILog.EnviarServidorError);
              break;
          case LogTypes.ErrorText:
              if (useConsoleTypes)
                  consoleProperty = "error";
              msg = this.ConsoleLog(" ErrorText", "red", response, requestParam, info, url, tiempoProceso,
                  StaticData.GeneralConfig.ConfigUILog.ImprimirError, StaticData.GeneralConfig.ConfigUILog.ImprimirRespuestaError, consoleProperty, useConsoleTypes);
              this.ConsoleLogError(msg);
              this.SaveLog(msg, true, StaticData.GeneralConfig.ConfigUILog.EnviarServidorError);
              break;
          }
      }
      catch (Error) {
          console.log("Error interno en Log Objeto Error", Error);
          console.log("Error interno en Log ", logType.toString(), response, requestParam);
      }
      return msg;
  }
  public LogErrorText(text: string, tiempoProceso: any = null) {
      this.Log(text, LogTypes.ErrorText, null, null, tiempoProceso);
  }
  public LogWarningText(text: string, tiempoProceso: any = null) {
      this.Log(text, LogTypes.WarningText, null, null, tiempoProceso);
  }
  public LogInfoText(text: string, tiempoProceso: any = null) {
      this.Log(text, LogTypes.InfoText, null, null, tiempoProceso);
  }

  public LogSuccess(response: any, requestParam: any, info: any = null, url: string = null, tiempoProceso: any = null) {
      this.Log(response, LogTypes.Success, requestParam, info, url, tiempoProceso);
  }
  public LogInfo(response: any, requestParam: any, info: any = null, url: string = null, tiempoProceso: any = null) {
      this.Log(response, LogTypes.Info, requestParam, info, url, tiempoProceso);
  }
  public LogWarning(response: any, requestParam: any, info: any = null, url: string = null, tiempoProceso: any = null) {
      this.Log(response, LogTypes.Warning, requestParam, info, url, tiempoProceso);
  }
  public LogError(response: any, requestParam: any, info: any = null, url: string = null, tiempoProceso: any = null) {
      this.Log(response, LogTypes.Error, requestParam, info, url, tiempoProceso);
  }

  public LogInfoReglas(text: string, tipoObjetoLog: string, regla: string, valor: any, modelo?: any) {
      if (StaticData.GeneralConfig.ConfigUILog.ConsoleLog && StaticData.GeneralConfig.ConfigUILog.ConsoleLogOk) {
          const msg = this.Log(text, LogTypes.InfoText, modelo, null, null);
          this.ConsoleLogReglas(msg, tipoObjetoLog, regla, valor, modelo);
      }
  }
  public RaiseConsoleLog(x: any): void {
      this.ReceivedConsoleLog.emit(x);
  }

  public ConsoleLogOk(text: string, tipoObjetoLog?: string) {

      if (StaticData.GeneralConfig.ConfigUILog.ConsoleLog && StaticData.GeneralConfig.ConfigUILog.ConsoleLogOk) {
          const currentTime = this.GetCurrentDate();
          StaticData.ConsoleLogOk.push({ Id: StaticData.ConsoleLogOk.length + 1, Log: text, Fecha: currentTime.toString() });
          this.RaiseConsoleLog({ Type: "ConsoleLogOk" });
      }
  }

  public ConsoleLogInfo(text: string, tipoObjetoLog?: string) {

      if (StaticData.GeneralConfig.ConfigUILog.ConsoleLog && StaticData.GeneralConfig.ConfigUILog.ConsoleLogInfo) {
          const currentTime = new Date();
          StaticData.ConsoleLogInfo.push({ Id: StaticData.ConsoleLogInfo.length + 1, Log: text, Fecha: currentTime.toString() });
          this.RaiseConsoleLog({ Type: "ConsoleLogInfo" });
      }
  }
  public ConsoleLogAlerta(text: string, tipoObjetoLog?: string) {

      if (StaticData.GeneralConfig.ConfigUILog.ConsoleLog && StaticData.GeneralConfig.ConfigUILog.ConsoleLogAlerta) {
          const currentTime = this.GetCurrentDate();
          StaticData.ConsoleLogAlerta.push({ Id: StaticData.ConsoleLogAlerta.length + 1, Log: text, Fecha: currentTime.toString() });
          this.RaiseConsoleLog({ Type: "ConsoleLogAlerta" });
      }
  }
  public ConsoleLogError(text: string, tipoObjetoLog?: string) {

      if (StaticData.GeneralConfig.ConfigUILog.ConsoleLog && StaticData.GeneralConfig.ConfigUILog.ConsoleLogError) {
          const currentTime = this.GetCurrentDate();
          StaticData.ConsoleLogError.push({ Id: StaticData.ConsoleLogError.length + 1, Log: text, Fecha: currentTime.toString() });
          this.RaiseConsoleLog({ Type: "ConsoleLogError" });
      }
  }

  public GetCurrentDate(separator: any = "-") {

      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      const fecha = dd + separator + mm + separator + yyyy + " " + today.getHours() + today.getMinutes() + today.getSeconds();
      return fecha;
  }

  public ConsoleLogPermisos(text: string, tipoObjetoLog: string, permiso: string, valor: any,
      filtroPermiso?: string, nombreFiltro?: any, modelo?: any, configPermisos?: any, item?: any) {

      if (StaticData.GeneralConfig.ConfigUILog.ConsoleLogPermisos) {
          const currentTime = this.GetCurrentDate();
          const _configPermisos = (configPermisos) ? jQuery.extend(true, {}, configPermisos) : null;
          if (_configPermisos && _configPermisos.Pagina) {
              _configPermisos.Pagina.ConfigPage = null;
              _configPermisos.Pagina.Estilos = null;
          }
          StaticData.ConsoleLogPermisos.push({
              Id: StaticData.ConsoleLogPermisos.length + 1,
              Log: text, Fecha: currentTime.toString(),
              TipoObjeto: tipoObjetoLog,
              Permiso: permiso, Valor: valor,
              FiltroPermiso: filtroPermiso,
              NombreFiltro: nombreFiltro,
              Modelo: modelo,
              ConfigPermisos: _configPermisos,
              Item: item
          });
          this.RaiseConsoleLog({ Type: "ConsoleLogPermisos" });
      }
  }
  public ConsoleLogReglas(text: string, tipoObjetoLog: string, regla: string, valor: any, modelo?: any) {

      if (StaticData.GeneralConfig.ConfigUILog.ConsoleLogReglas) {
          const currentTime = this.GetCurrentDate();

          StaticData.ConsoleLogReglas.push({
              Id: StaticData.ConsoleLogReglas.length + 1,
              Log: text, Fecha: currentTime.toString(), TipoObjeto: tipoObjetoLog,
              Regla: regla,
              Valor: valor,
              Modelo: modelo
          });
          this.RaiseConsoleLog({ Type: "ConsoleLogReglas" });
      }
  }
  public SaveLog(msg: string, isError: boolean, send: boolean) {


      const that = this;

      try {
          const obj: any = { MethodNameUI: "SaveLog" };
          if (isError)
              obj.Log = { Error: msg };
          else
              obj.Log = { Log: msg };

          const info = {
              componentName: "Logger", controlName: "SaveLog",
              processMessage: "Guardando SaveLog......"
          };
          if (send) {
              this.baseService.SaveLog(obj).then(data => {

              }).catch(err => { });
          }
      }
      catch (Error) {
          console.log("Error interno en SaveLog ");
      }

  }
}
