import { Component, OnInit, Input } from '@angular/core';
import { StaticData } from 'src/app/helpers/static-data';
import { BaseControl } from 'src/app/models/base-control';

@Component({
  selector: 'app-tool-tip-validation',
  templateUrl: './tool-tip-validation.component.html',
  styleUrls: ['./tool-tip-validation.component.scss']
})
export class ToolTipValidationComponent extends BaseControl implements OnInit {

  @Input() controlType: string = null;

  IconIsInvalide: string = '';
  ColorIconIsInvalid: string = '';
  IconIsRequiredError: string = '';
  ColorIconIsRequiredError: string = '';
  IconIsMinlengthError: string = '';
  ColorIconIsMinlengthError: string = '';
  IconIsMaxlengthError: string = '';
  ColorIconIsMaxlengthError: string = '';
  IconIsMinError: string = '';
  ColorIconIsMinError: string = '';
  IconIsMaxError: string = '';
  ColorIsMaxError: string = '';
  IconIsPatternError: string = '';
  ColorIconIsPatternError: string = '';
  IconIsEmailError: string = '';
  ColorIconIsEmailError: string = '';
  IconGetCustomError: string = '';
  ColorIconGetCustomError: string = '';
  IconEditRequired: string = '';
  ColorIconEditRequired: string = '';



  SetClass() {
    //debugger;
    this.IconIsInvalide = StaticData.GeneralConfig.ConfigUI.IconIsInvalide;
    this.ColorIconIsInvalid = StaticData.GeneralConfig.ConfigUI.ColorIconIsInvalid;
    this.IconIsRequiredError = StaticData.GeneralConfig.ConfigUI.IconIsRequiredError;
    this.ColorIconIsRequiredError = StaticData.GeneralConfig.ConfigUI.ColorIconIsRequiredError;
    this.IconIsMinlengthError = StaticData.GeneralConfig.ConfigUI.IconIsMinlengthError;
    this.ColorIconIsMinlengthError = StaticData.GeneralConfig.ConfigUI.ColorIconIsMinlengthError;
    this.IconIsMaxlengthError = StaticData.GeneralConfig.ConfigUI.IconIsMaxlengthError;
    this.ColorIconIsMaxlengthError = StaticData.GeneralConfig.ConfigUI.ColorIconIsMaxlengthError;
    this.IconIsMinError = StaticData.GeneralConfig.ConfigUI.IconIsMinError;
    this.ColorIconIsMinError = StaticData.GeneralConfig.ConfigUI.ColorIconIsMinError;
    this.IconIsMaxError = StaticData.GeneralConfig.ConfigUI.IconIsMaxError;
    this.ColorIsMaxError = StaticData.GeneralConfig.ConfigUI.ColorIsMaxError;
    this.IconIsPatternError = StaticData.GeneralConfig.ConfigUI.IconIsPatternError;
    this.ColorIconIsPatternError = StaticData.GeneralConfig.ConfigUI.ColorIconIsPatternError;
    this.IconIsEmailError = StaticData.GeneralConfig.ConfigUI.IconIsEmailError;
    this.ColorIconIsEmailError = StaticData.GeneralConfig.ConfigUI.ColorIconIsEmailError;
    this.IconGetCustomError = StaticData.GeneralConfig.ConfigUI.IconGetCustomError;
    this.ColorIconGetCustomError = StaticData.GeneralConfig.ConfigUI.ColorIconGetCustomError;
    this.IconEditRequired = StaticData.GeneralConfig.ConfigUI.IconEditRequired;
    this.ColorIconEditRequired = StaticData.GeneralConfig.ConfigUI.ColorIconEditRequired;
  }
  ngOnInit() {

    this.SetClass();
    if (this.config.ControlType == "ComboBox" ||
      this.config.ControlType == "MultiComboBox" || this.config.ControlType == "FileBox") {

      this.controlType = this.config.ControlType;
    }
  }


  // validar() {
  //   return this.controlType == 'FileBox' && this.config.Required && (this.modelo == null || this.modelo.length == 0)
  // }

  ngAfterViewInit() {
    //(<any>$('[data-toggle="tooltip"]')).tooltip();
    //$('[data-toggle="tooltip"]').tooltip();
    // (<any>$('[data-toggle="tooltip"]')).tooltip({
    //   sanitizeFn: function (content) { return content; }
    // });
  }

}
