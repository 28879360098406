import { Component, OnInit } from '@angular/core';
import { DynamicControlsComponent } from '../dynamic-controls/dynamic-controls.component';

@Component({
  selector: 'app-dynamic-json-controls',
  templateUrl: './dynamic-json-controls.component.html',
  styleUrls: ['./dynamic-json-controls.component.scss']
})
export class DynamicJsonControlsComponent extends DynamicControlsComponent implements OnInit {

  //* FUNCIONES ANGULAR

  ngOnInit() {
  }

  //* END FUNCIONES ANGULAR
 
}
