import { Component, OnInit, Input } from '@angular/core';
import { StaticData } from 'src/app/helpers/static-data';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';

declare var fabric: any;
@Component({
  selector: 'app-console-log',
  templateUrl: './console-log.component.html',
  styleUrls: ['./console-log.component.css']
})
export class ConsoleLogComponent implements OnInit {

  @Input() public name: string = 'ConsoleLogComponent';
  constructor( public generalComunicationService:GeneralComunicationService) { }

  ngOnInit() {
  }

  get HiddenConsoleLogOk ():boolean{
    return !StaticData.GeneralConfig.ConfigUILog.ConsoleLogOk;
  }

  get HiddenConsoleLogInfo ():boolean{
    return !StaticData.GeneralConfig.ConfigUILog.ConsoleLogInfo;
  }
  get HiddenConsoleLogAlerta ():boolean{
    return !StaticData.GeneralConfig.ConfigUILog.ConsoleLogAlerta;
  }

  get HiddenConsoleLogError ():boolean{
    return !StaticData.GeneralConfig.ConfigUILog.ConsoleLogError;
  }
  
  get HiddenConsoleLogPermisos ():boolean{
    return !StaticData.GeneralConfig.ConfigUILog.ConsoleLogPermisos;
  }
  
  get HiddenConsoleLogReglas ():boolean{
    return !StaticData.GeneralConfig.ConfigUILog.ConsoleLogReglas;
  }
  
  Limpiar(){
    StaticData.ConsoleLogAlerta=[];
    StaticData.ConsoleLogError=[];
    StaticData.ConsoleLogInfo=[];
    StaticData.ConsoleLogOk=[];
    StaticData.ConsoleLogPermisos=[];
    this.generalComunicationService.raiseClearConsoleLog(null);
  }
  ActivarLog(){
    StaticData.GeneralConfig.ConfigUILog.ConsoleLog = true;
    StaticData.GeneralConfig.ConfigUILog.ConsoleLogPermisos= true;
  }
  ngAfterViewInit() {

    setTimeout(() => {
      var PivotElement = document.querySelector("#" + this.name);
      new fabric['Pivot'](PivotElement);

    
    }, 400);

   
  }
}
