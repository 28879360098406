import { Component, OnInit, Input, AfterViewInit,Output,EventEmitter } from '@angular/core';
import { Guid } from 'guid-typescript';

declare var QRCode: any;
@Component({
  selector: 'app-qrcode-box',
  templateUrl: './qrcode-box.component.html',
  styleUrls: ['./qrcode-box.component.css']
})
export class QRCodeBoxComponent implements OnInit, AfterViewInit {
  @Input() modelo: any;
  @Input() config: any;
  @Input() name: any = "QrCode_" + Guid.create().toString();
  qrcode: any;
  @Input() modeloTextQRCode: string = "";
  @Output() onInitialized: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }
  ngAfterViewInit(): void {
    this.InitQR();
  }

  ngOnInit() {
    if (this.config)
      this.name = this.config.Name;
    else
      this.config = {};

      if (this.onInitialized.observers.length) {
        setTimeout(() => {
          this.onInitialized.emit(this);
        }, 1000);
      }

  }
  public SetDefaultValue(prop, value) {
    if (!this.config[prop])
      this.config[prop] = value;
  }

  public InitQR() {

    this.SetDefaultValue("Width", 128);
    this.SetDefaultValue("Height", 128);
    this.SetDefaultValue("ColorDark", "#000000");
    this.SetDefaultValue("ColorLight", "#ffffff");
    
    this.qrcode = new QRCode(this.name, {
      text: this.config.Text ? this.config.Text : "NO DATA",
      width: this.config.Width,
      height: this.config.Height,
      colorDark: this.config.ColorDark,
      colorLight: this.config.ColorLight,
      correctLevel: QRCode.CorrectLevel.H
    });
  }

  public EnterMakeCode(event) {
    if (event.keyCode == 13)
      this.MakeCode();
  }
  public MakeCode(_modeloTextQRCode?: string) {
    if (_modeloTextQRCode)
      this.modeloTextQRCode = _modeloTextQRCode;
    this.qrcode.makeCode(this.modeloTextQRCode);
  }
}
