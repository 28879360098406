import { Component, OnInit,Input,Output,EventEmitter,forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import { ConfigBaseComponent} from '../../.././../models/config-base-component';

@Component({
  selector: 'app-mapping-field-container',
  templateUrl: './mapping-field-container.component.html',
  styleUrls: ['./mapping-field-container.component.css']
})
export class MappingFieldContainerComponent extends ConfigBaseComponent implements OnInit {


}
