import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var fabric: any;

export class ModalBase implements OnInit, AfterViewInit {


  @Input() config: any = undefined;
  @Input() descripcion: string = '';
  @Input() titulo: string = 'Informacion';
  @Input() ok: string = 'Ok';
  @Input() cancel: string = 'Cancelar';
  @Input() model: any = null;
  @Input() name: string = 'modal-notify-bext_doc';
  @Output() showEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() shownEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() hiddenEmitter: EventEmitter<any> = new EventEmitter<any>();

  botonProcesando: boolean = false;
  textoBoton: string = 'Login';
  textoBotonProcesando: string = '';

  dialogResult: boolean = false;

  constructor() { }

  SetProcesando(ok: boolean) {
    this.botonProcesando = ok;
    if (ok) {
      this.textoBoton = '';
      this.textoBotonProcesando = 'Ejecutando.......';
    }
    else {
      this.textoBoton = 'Login';
      this.textoBotonProcesando = '';
    }
  }
  ngOnInit() {

    this.SetProcesando(false);
  }

  private setConfig() {

    if (this.config && this.config.titulo)
      this.titulo = this.config.titulo;
    if (this.config && this.config.descripcion)
      this.descripcion = this.config.descripcion;
    if (this.config && this.config.ok)
      this.ok = this.config.ok;
    if (this.config && this.config.cancel)
      this.cancel = this.config.cancel;

  }
  private initModal() {

    const that = this;
    $('#' + this.name).off('hidden.bs.modal');
    $('#' + this.name).off('shown.bs.modal');
    $('#' + this.name).off('hide.bs.modal');
    $('#' + this.name).off('show.bs.modal');

    $('#' + this.name).on('hidden.bs.modal', function () {
      if (that.hiddenEmitter && that.hiddenEmitter.observers.length > 0)
        that.hiddenEmitter.emit(that.getDataResult());

    });
    $('#' + this.name).on('shown.bs.modal', function () {
      if (that.shownEmitter && that.shownEmitter.observers.length > 0)
        that.shownEmitter.emit(that.getDataResult());

    });
    $('#' + this.name).on('hide.bs.modal', function () {
      if (that.hideEmitter && that.hideEmitter.observers.length > 0)
        that.hideEmitter.emit(that.getDataResult());
      that.clear();
    });

    $('#' + this.name).on('show.bs.modal', function () {
      if (that.showEmitter && that.showEmitter.observers.length > 0)
        that.showEmitter.emit(that.getDataResult());

    });

  }
  private clear() {
    this.dialogResult = false;
    this.model = {};
  }
  public getDataResult(): any {
    let data = { name: this.name, dialogResult: this.dialogResult, model: this.model };

    return data;
  }
  public open(model: any = null, config: any = null) {
    if (model)
      this.model = model;
    if (config)
      this.config = config;
    this.setConfig();
    this.modalLoginElem.open();
  }
  public close(dialogResult: boolean = false) {
    this.dialogResult = dialogResult;
    this.modalLoginElem.close();
  }
  modalLoginElem: any;
  ngAfterViewInit() {
    if (!this.modalLoginElem) {
      var modal = document.querySelector(".ms-Dialog-Login");
      this.modalLoginElem = new fabric['Dialog'](modal);
    }
    this.initModal();
  }
}
