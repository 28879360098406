//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import {
  Paginas,
  ControllerMethods,
  DataTableAjaxOrderTypes,
  TypesUseForm,
  ControlActions,
} from "../../../models/general.enum";
import { Utilities } from "../../../helpers/utilities";
import {
  ModalNotifyComponent,
  ModalNotifyTypes,
} from "../../../components/modal-notify/modal-notify.component";
import { BaseServiceService } from "../../../services/base-service.service";
import { BaseCrud } from "../../../bussines/base-crud";
import { GeneralFormComponent } from "../../controls/general-form/general-form.component";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { HelperActions } from "src/app/helpers/helper-actions";
import { UserSettingsModelComponent } from "../../rule-engine/info-project/user-settings-model/user-settings-model.component";
import { Guid } from "guid-typescript";
import { StaticData } from 'src/app/helpers/static-data';


@Component({
  selector: "app-rusuario",
  templateUrl: "./rusuario.component.html",
  styleUrls: ["./rusuario.component.css"],
})
export class RUsuarioComponent
  extends GeneralFormComponent
  implements OnInit, AfterViewInit
{
  // @ViewChild('userModelComponent', { static: false }) userModelComponent: UserModelComponent;
  idAreaEmpresa: any;
  idRole: any;
  userSettingsModelComponent: UserSettingsModelComponent;
  textCommandBar: any;
  RequiereSession: any;
  Session: any;
  ConfigTodosPermisosExpedientes:any
  ConfigTodosPermisosDocumentosExpedientes:any
  ConfigTodosPermisosPaginas:any
  ConfigTodosPermisosMenus:any
  ConfigTodosPermisosQuerys:any
  ConfigTodosPermisosReglas:any
  constructor(
    public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions
  ) {
    super(baseService, utility, helperImages, helperRules, helperActions);
  }
  public AddComponentInstanceHandler(event) {
    if (event instanceof UserSettingsModelComponent) {
      this.userSettingsModelComponent = event;
    } else {
      super.AddComponentInstanceHandler(event);
    }
  }

  ngAfterViewInit() {

    this.RequiereSession = this.form.controls["PermiteTiempoSession"];
    this.Session = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name == "FechaSesion";
      });

    // if (this.RequiereSession) {
    //   this.RequiereSession.valueChanges.subscribe((value: boolean) => {
    //     if (this.textCommandBar == "Edit" || this.textCommandBar == "New") {
    //       if (value != null) {
    //         if (value) {
    //           this.Session.Hidden = false;
    //           const context = this;
    //           const actionTrue = {
    //             ControlDetails: {
    //               MappingData: [],
    //               MappingFilter: [],
    //             },
    //             ControlType: "Action",
    //             ControlsForHidden: [],
    //             ControlsToUpdate: ["FechaSesion"],
    //             Guid: Guid.create().toString(),
    //             IsPost: false,
    //             IsParentModel: false,
    //             MappingValues: [],
    //             MappingComponents: [],
    //             Property: "required",
    //             PropertyValue: "true",
    //             ResultType: "Url",
    //             SendQueryString: false,
    //             TimerAction: 0,
    //             Type: ControlActions[ControlActions.SetControlProperty],
    //           };
    //           this.helperActions.SetControlsValue(actionTrue, context);
    //           this.SetModelToControls();
    //           $("input[id='PermiteTiempoSession']").prop("checked", true);
    //         } else {
    //           this.RequiereSession.value = null;
    //           const context = this;
    //           const actionFalse = {
    //             ControlDetails: {
    //               MappingData: [],
    //               MappingFilter: [],
    //             },
    //             ControlType: "Action",
    //             ControlsForHidden: [],
    //             ControlsToUpdate: ["FechaSesion"],
    //             Guid: Guid.create().toString(),
    //             IsPost: false,
    //             IsParentModel: false,
    //             MappingValues: [],
    //             MappingComponents: [],
    //             Property: "required",
    //             PropertyValue: "false",
    //             ResultType: "Url",
    //             SendQueryString: false,
    //             TimerAction: 0,
    //             Type: ControlActions[ControlActions.SetControlProperty],
    //           };
    //           this.helperActions.SetControlsValue(actionFalse, context);
    //           this.modelo.modelo.Respuesta = null;
    //           this.SetModelToControls();
    //           this.Session.Hidden = false;
    //           $("input[id='PermiteTiempoSession']").prop("checked", false);
    //         }
    //       }
    //     }
    //   });
    // }
  	super.ngAfterViewInit();
  }

  public PostNgAfterViewInit(){
    super.PostNgAfterViewInit();
    this.Session = this.utility
    .GetAllFormControls(this.Pagina.ConfigPage.Controls)
    .find((x) => {
      return x.Name === "FechaSesion";
    });
  this.RequiereSession = this.utility
    .GetAllFormControls(this.Pagina.ConfigPage.Controls)
    .find((x) => {
      return x.Name == "PermiteTiempoSession";
    });

  }
  public OnInitEnd() {
    this.preSaveEmitter.subscribe((model) => {
      if (
        model.modelo.modelo.ConfigFirma != null &&
        typeof model.modelo.modelo.ConfigFirma === "object"
      )
        model.modelo.modelo.ConfigFirma = JSON.stringify(
          model.modelo.modelo.ConfigFirma
        );
      this.modelo.modelo.Configuracion =
        this.userSettingsModelComponent.GetConfiguracion();
    });
    this.preEditEmitter.subscribe((data) => {
      if (
        data.modelo.modelo.ConfigFirma != null &&
        data.modelo.modelo.ConfigFirma != "" &&
        typeof data.modelo.modelo.ConfigFirma === "string"
      )
        data.modelo.modelo.ConfigFirma = JSON.parse(
          data.modelo.modelo.ConfigFirma
        );
      this.userSettingsModelComponent.SetModelo(data.modelo);
    });

    if (!this.Pagina.IsCustom) {
      this.postSaveEmitter.subscribe((data) => {
        if (
          this.componentsContainer.FileBox &&
          data.modelo.modelo.Identificacion != null &&
          data.modelo.modelo.Identificacion != ""
        ) {
          data.modelo.modelo = data.data.data;
          this.componentsContainer.FileBox.sendCurrentModel = true;
          this.componentsContainer.FileBox.modelo = data.modelo;

          this.componentsContainer.FileBox.uploadFiles();
        }
        this.componentsContainer.FileBox.selectedFiles = [];
        this.componentsContainer.FileBox.hideSelectBtn = false;
        this.userSettingsModelComponent.Limpiar();
      });
    }

    this.postSaveEmitter.subscribe((modelo) => {
      this.Limpiar();
    });

    this.ConfigTodosPermisosExpedientes = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosExpedientes" });
    this.ConfigTodosPermisosDocumentosExpedientes = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosDocumentosExpedientes" });
    this.ConfigTodosPermisosPaginas = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosPaginas" });
    this.ConfigTodosPermisosMenus = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosMenus" });
    this.ConfigTodosPermisosQuerys = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosQuerys" });
    this.ConfigTodosPermisosReglas = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TodosPermisosReglas" });
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    switch (controlName) {
      case "ConfigFirma": {
        //case SolicitudCommonComponent.Controls.IdTemaSolicitud: {
        if (item) {
          if (item.length == 1 && item[0].Nombre == "Digital") {
            //const configUsuarioResp = JSON.parse(item.Configuracion);
            //this.modelo.modelo.IdUsuarioResponsable = configUsuarioResp[0].Id;
            //this.Oficio.RaiseForceValidateSaveEvent(false, true);
            this.componentsContainer.FileBox.config.Required = false;
          } else {
            this.componentsContainer.FileBox.config.Required = true;
          }
          //this.utility.VerModalError("La temática seleccionada no posee responsable");
        }
        break;
      }
    }
    super.OnSelectedComboBox(item, controlName, config);
  }

  _Guardar(form: FormGroup, configButtons?: any) {
    try {
      /*if(this.modelo.modelo.Celular)
      {
       var Indicativo =  this.modelo.modelo.Celular.toString().substring(0, 3);
       if(Indicativo != '+57')
       {
         this.modelo.modelo.Celular = '+57' + this.modelo.modelo.Celular;
       }
      }*/

      super._Guardar(form, configButtons);
    } catch (error) {
      this.utility.VerModalDanger().then((data) => {});
      this.SetProcesando(false);
    }
  }

  public OnActionEventHandler(event) {
    this.idAreaEmpresa = this.modelo.modelo.IdAreaEmpresa;
    this.idRole = this.modelo.modelo.IdRole;

    switch (event.ControlType) {
      case "CommandButton":
        if (event.Option.Type == "New" || event.Option.Type == "Edit") {
          this.textCommandBar = event.Option.Type;


          if(this.modelo.modelo.PermiteTiempoSession){
            this.Session.Hidden = false;
              const context = this;
              const actionTrue = {
                ControlDetails: {
                  MappingData: [],
                  MappingFilter: [],
                },
                ControlType: "Action",
                ControlsForHidden: [],
                ControlsToUpdate: ["FechaSesion"],
                Guid: Guid.create().toString(),
                IsPost: false,
                IsParentModel: false,
                MappingValues: [],
                MappingComponents: [],
                Property: "required",
                PropertyValue: "true",
                ResultType: "Url",
                SendQueryString: false,
                TimerAction: 0,
                Type: ControlActions[ControlActions.SetControlProperty],
              };
              this.helperActions.SetControlsValue(actionTrue, context);
              this.SetModelToControls();
          }else{
            this.Session.Hidden = true;
            const context2 = this;
            const actionTrue2 = {
              ControlDetails: {
                MappingData: [],
                MappingFilter: [],
              },
              ControlType: "Action",
              ControlsForHidden: [],
              ControlsToUpdate: ["FechaSesion"],
              Guid: Guid.create().toString(),
              IsPost: false,
              IsParentModel: false,
              MappingValues: [],
              MappingComponents: [],
              Property: "required",
              PropertyValue: "false",
              ResultType: "Url",
              SendQueryString: false,
              TimerAction: 0,
              Type: ControlActions[ControlActions.SetControlProperty],
            };
            this.helperActions.SetControlsValue(actionTrue2, context2);
            this.SetModelToControls();
          }

          this.configs.ConfigIdRole.Context.CargarItemsComboBoxRemoveNotLoad(
            null,
            true,
            null,
            true
          );
          this.configs.ConfigIdAreaEmpresa.Context.CargarItemsComboBoxRemoveNotLoad(
            null,
            true,
            null,
            true
          );

          if (this.modelo.modelo.IdUsuario) {
            setTimeout(() => {
              this.modelo.modelo.IdAreaEmpresa = this.idAreaEmpresa;
              this.modelo.modelo.IdRole = this.idRole;
              this.SetModelToControls();
            }, 7000);
          }
          if(StaticData.GeneralConfig.ConfigLicence.ValideLicence?StaticData.GeneralConfig.ConfigLicence.ValideLicence : false)this.licenciasConfigOnEvent()
          super.OnActionEventHandler(event);
        } else super.OnActionEventHandler(event);
        break;

      default:
        super.OnActionEventHandler(event);
        break;
    }
  }

  public ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null){
    if(StaticData.GeneralConfig.ConfigLicence.ValideLicence?StaticData.GeneralConfig.ConfigLicence.ValideLicence : false){
      if(data && data.controlName=="IdRole"){
        this.modelo.modelo.IdTipoRole=(data.item && data.item.IdTipoRole)?data.item.IdTipoRole:"";
        this.licenciasConfigOnEvent();
      }
      if(data && data.controlName=="IdAreaEmpresa" && this.modelo.modelo && this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole == 1){
        const keysPermisos = [
          "TodosPermisosExpedientes",
          "TodosPermisosDocumentosExpedientes",
          "TodosPermisosPaginas",
          "TodosPermisosMenus",
          "TodosPermisosQuerys",
          "TodosPermisosReglas"
        ];
        let tienePermiso=false
        if(data.item)tienePermiso = keysPermisos.some(permiso => data.item[permiso] === true);
        if(tienePermiso){
          this.modelo.modelo.IdAreaEmpresa=null
          this.utility.VerModalWarning({
            titulo: 'Alerta', descripcion: "El área "+data.item.Nombre+" contiene permisos no aptos para la licencia consulta",
            verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
          }).then(data => { });
        }
      }
    }
    super.ExcecuteFuntion(controllerMethod,isPost,data);
  }

  licenciasConfigOnEvent(){
    this.ConfigTodosPermisosExpedientes.Readonly = (this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosDocumentosExpedientes.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosPaginas.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosMenus.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosQuerys.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    this.ConfigTodosPermisosReglas.Readonly=(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1)? true  :false
    if(this.modelo.modelo.IdTipoRole && this.modelo.modelo.IdTipoRole==1){
      this.modelo.modelo.TodosPermisosDocumentosExpedientes = null
      this.modelo.modelo.TodosPermisosExpedientes = null
      this.modelo.modelo.TodosPermisosMenus = null
      this.modelo.modelo.TodosPermisosPaginas = null
      this.modelo.modelo.TodosPermisosQuerys = null
      this.modelo.modelo.TodosPermisosReglas = null
    }
    this.SetModelToControls();
  }
}
