import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { GeneralFormComponent } from 'src/app/components/controls/general-form/general-form.component';
import { ControlActions, TipoObjetoParaPermisos, ControllerMethods, FormActionsThroughEmitters } from 'src/app/models/general.enum';
import { ConfigWindow } from 'src/app/models/config-window';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { Utilities } from 'src/app/helpers/utilities';
import { FormGroup } from '@angular/forms';
import { debug } from 'util';
import { StaticData } from 'src/app/helpers/static-data';
import { Enumerable, List } from "../../../../../../assets/linqts/compilado/index";
import { Guid } from "guid-typescript";
declare var fabric: any;

@Component({
  selector: 'app-proceso-respuesta-fisico',
  templateUrl: './proceso-respuesta-fisico.component.html',
  styleUrls: ['./proceso-respuesta-fisico.component.css']
})
export class ProcesoRespuestaFisicoComponent extends GeneralFormComponent implements OnInit {

  IdPlanilla: any;
  configBotonDevolucion: any;
  configBotonEntregado: any;
  configComboDevoluciones: any;

  IdModoEnvio : any;
  modeloEnvios: any;
  modeloPlanilla: any;
  modeloRecibir: any;
  eventPagina: any;

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);

  }

  public OnInitEnd() {

    this.configBotonDevolucion = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "BtnDevolver"; });
    this.configBotonEntregado = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "BtnEntregado"; });
    this.configComboDevoluciones = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdDevolucion"; });

  }

  public OnLoadGridHandler(event) {
    
    if (event.controlName == "DataTable_Asignacion_Envios")
      this.modeloEnvios = this.modelo.modelo;
    else if (event.controlName == "DataTable_Asignacion_Planilla")
      this.modeloPlanilla = this.modelo.Datos;
    else if (event.controlName == "DataTable_PendientesRecibir")
      this.modeloRecibir = this.modelo.Datos;

    this.eventPagina = event.controlName
  }

  public Limpiar(){

    if(this.eventPagina == "DataTable_Asignacion_Envios"){
      var dvAsignacionEnvios = this.componentsContainer.Tables.find(x => { return x.name == "DataTable_Asignacion_Envios"; });  
      dvAsignacionEnvios.selectedItems = []  
      dvAsignacionEnvios.ReCargarGrilla() 
      super.Limpiar()
    }
    else if (this.eventPagina == "DataTable_Asignacion_Planilla"){
      var dvAsignacionPlanilla = this.componentsContainer.Tables.find(x => { return x.name == "DataTable_Asignacion_Planilla"; });
      dvAsignacionPlanilla.selectedItems = []    
      dvAsignacionPlanilla.ReCargarGrilla() 
    }
    else if (this.eventPagina == "DataTable_PendientesRecibir"){
      var dvPendientesRecibir = this.componentsContainer.Tables.find(x => { return x.name == "DataTable_PendientesRecibir"; });
      dvPendientesRecibir.selectedItems = []
      dvPendientesRecibir.ReCargarGrilla()
    }else {
      super.Limpiar()
    }
    this.SetModelToControls();
  }
  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    // if (item.Reprogramar) {
    //   if (item.Reprogramar == true) {
    //     this.modelo.modelo.IdEstadoSolicitud = 9;
    //     this.modelo.modeloVirtual.IdEstadoSolicitud = 9;
    //     super.SetModelToControls();
    //   }
    // }
    // this.modelo;
    // super.OnSelectedComboBox(item, controlName, config)

    if (item.Reprogramar != undefined) {

        this.modelo.modelo.IdEstadoSolicitud = (item.Reprogramar == true) ? 9 : 5;
        this.modelo.modeloVirtual.IdEstadoSolicitud = (item.Reprogramar == true) ? 9 : 5;
        this.modelo.modelo.IdCausalRechazo = this.modelo.modeloVirtual.IdDevolucion;
        super.SetModelToControls();

    }

    //this.modelo.modelo.Observaciones = item.Nombre;
    //super.SetModelToControls();
    this.modelo;
    this.IdModoEnvio = this.modelo.modelo.IdModoEnvio;
    super.OnSelectedComboBox(item, controlName, config)

  }
  GetConfigPermisos() {
    let permissionsConfig: any = {
      TipoObjetoParaPermiso: TipoObjetoParaPermisos.Expediente,
      Pagina: this.Pagina, clear: true, CreatedRowConfig: {}
    };
  }

  public OnActionEventHandler(event) {
    //debugger;

    //if (event.Option && event.Option.Name == "Devuelto" || event.Name == "BtnEntregado") {
    // if (event.Name == "BtnEntregado") {
    //   
    //   this.utility.VerModalConfirmacionGuardar().then(modelo => {
    //     if (modelo.dialogResult == true) {
    //       this._Guardar(this.form)
    //     }
    //   });
    // }
    if (event.Name == 'Btn_AsignarOficio' || event.Name == "ButtonDevolver") {
      
      this.validarExisteArchivo();
      // if (this.componentsContainer.FileBox && this.componentsContainer.FileBox.isSelectedFiles()) {
      //   this.utility.VerModalConfirmacionGuardar().then(modelo => {
      //     if (modelo.dialogResult == true) {
      //       this._Guardar(this.form)
      //     }
      //   });
      // }
      // else {
      //   this.utility.VerModalWarning({
      //     titulo: 'Alerta', descripcion: 'Debe ingresar un oficio para el proceso',
      //     verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
      //   }).then(data => { });
      // }
    }
    else if (event.Option && (event.Option.Name == "Entregado" || event.Option.Name == "Devuelto")) {

      this.configBotonDevolucion.Hidden = (event.Option.Name == "Devuelto") ? false : true;
      this.configBotonEntregado.Hidden = (event.Option.Name == "Devuelto") ? true : false;
      this.configComboDevoluciones.Hidden = (event.Option.Name == "Devuelto") ? false : true;

      const actionrequired = {
        ControlDetails: {
          MappingData: [],
          MappingFilter: []
        },
        ControlType: "Action",
        ControlsForHidden: [],
        ControlsToUpdate: ["IdDevolucion"],
        Guid: Guid.create().toString(),
        IsPost: false,
        IsParentModel: false,
        MappingValues: [],
        MappingComponents: [],
        Property: "required",
        PropertyValue: (event.Option.Name == "Devuelto") ? "true" : "false",
        ResultType: "Url",
        SendQueryString: false,
        TimerAction: 0,
        Type: ControlActions[ControlActions.SetControlProperty]
      };

      const context = this;
      this.helperActions.SetControlsValue(actionrequired, context);

      this.SetModelToControls();
    }
    else if (event.Name == "BtnEntregado") {

      this.validarExisteArchivo();

    }
    else if (event.Name == "BtnDevolver") {

      if(this.modelo.modelo.IdCausalRechazo && this.modelo.modelo.IdCausalRechazo > 0)
        this.validarExisteArchivo();
      else{
        this.utility.VerModalWarning({
          titulo: 'Alerta', descripcion: 'Debe ingresar un motivo de Devolución',
          verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
        }).then(data => { });
      }

    }

    else if (event.Action === FormActionsThroughEmitters.ClearModel){ 
      if (event.Table == "DataTable_Asignacion_Envios"){
      
        this.modelo.modelo = this.modeloEnvios;
        var GridName = "DataTable_Asignacion_Envios";
        var GridViewAsignacionEnvios = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
        GridViewAsignacionEnvios.selectedItems = this.modelo.modelo
        this.modelo.modelo.IdModoEnvio = this.IdModoEnvio;

       super.SetModelToControls();
       return;
     }
     if (event.Table == "DataTable_PendientesRecibir"){
      
      this.modelo.modelo = this.modeloRecibir;
      var GridName = "DataTable_PendientesRecibir";
      var GridViewAsignacionEnvios = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
      GridViewAsignacionEnvios.selectedItems = this.modelo.modelo
     super.SetModelToControls();
     return;
   }
     else if (event.Table == "DataTable_Asignacion_Planilla"){

        this.modelo.modelo = this.modeloPlanilla;
        this.modelo.Datos = this.modeloPlanilla;
        var GridName = "DataTable_Asignacion_Planilla";
        var GridViewAsignacionPlanilla = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
        GridViewAsignacionPlanilla.selectedItems = this.modelo.modelo
        this.modelo.modelo.IdModoEnvio = this.IdModoEnvio;

        super.SetModelToControls();
        return;
      }
      
    }

    super.OnActionEventHandler(event);
  }

  validarExisteArchivo() {

    if (this.componentsContainer.FileBox && this.componentsContainer.FileBox.isSelectedFiles()) {
      this.utility.VerModalConfirmacionGuardar().then(modelo => {
        if (modelo.dialogResult == true) {
          this._Guardar(this.form)
          super.Limpiar();
        }
      });
    }
    else {
      this.utility.VerModalWarning({
        titulo: 'Alerta', descripcion: 'Debe ingresar un oficio para el proceso',
        verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
      }).then(data => { });
    }

  }


  _Guardar(form: FormGroup, configButtons?: any) {
    debugger;
    let obj: any = null;
    try {
      if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
        return;
      if (form.invalid)
        return;

      if (this.Pagina.Pagina == "RadicadoPendienteRecibir") {
        this.modelo.modelo.forEach(item => {
          item.IdEstadoSolicitud = this.modelo.modeloVirtual.IdEstadoSolicitud
        });
      }

      if (this.Pagina.Pagina == "RecepcionEnvios" || this.Pagina.Pagina == "RadicadoSalidaPendienteOficio") {
         // desde acá
        //debugger;
        if (this.modelo.modelo.IdTipoSolicitudEntradaPadre == 5) {
          this.modelo.modelo["DataAdd"] =
          {
            IdTipoSolicitudEntradaPadre: this.modelo.modelo.IdTipoSolicitudEntradaPadre
          }
        }
        this.GuardarRegistroIndiviudal(form, configButtons);
      }
      else if (this.Pagina.Pagina == "AsignacionPlanilla") {
        //debugger;
        let obj = this.utility.GetApiModel('ConsultaIdPlanilla', null)
        obj.QueryConfig = {
          Command: 'ConsultaIdPlanilla',
          IsSp: true,
          Entity: {
            IdEmpresa: -1
          }
        };
        let info = {
          componentName: this.componentName, controlName: 'ConsultaIdPlanilla',
          processMessage: 'Cargando ConsultaIdPlanilla......'
        };

        this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

          
          this.IdPlanilla = data.Data[0].NuevaPlanilla
          this.modelo.Datos.forEach(item => {
            item.IdEstadoSolicitud = this.modelo.modeloVirtual.IdEstadoSolicitud
            item.IdPlanilla = this.IdPlanilla
          });
          this.modelo.modelo = this.modelo.Datos;
          this.GuardarRegistroMultiple(configButtons);
        }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Guardar' }); });
      }
      else {
        this.GuardarRegistroMultiple(configButtons);
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: '_Guardar' });
      this.utility.VerModalDanger().then(data => { })
      this.SetProcesando(false);
    }
  }

  GuardarRegistroIndiviudal(form: FormGroup, configButtons?: any) {
    //debugger;    
    let obj: any = null;
    let _isEdit = this.IsEdit;
    // const that = this;
    let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;

    if (configButtons && configButtons.Actions) {

      this.helperActions.ExecuteActionsControl(configButtons,
        false, null, this.modelo, this.permissionsConfig,
        this, null, new EventEmitter<any>(), this.componentsContainer,
        null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
    }

    this.ExcecuteFuntion(ControllerMethods.Save, false);


    if (_isEdit) {
      this.FireFormEvent({ Name: "OnPreUpdate", modelo: this.modelo });
      this.modelo.modelo.DestinatariosCopias = this.modelo.modelo.Copias_Destinos;
    }
    else
      this.FireFormEvent({ Name: "OnPreInsert", modelo: this.modelo });

    if (this.modelo.modeloMetadata.Items.Count() > 0) {
      this.modelo.modeloMetadata.IdParentObject = this.modelo.modelo.PkValue;
      this.modelo.modeloMetadata.IdPage = this.Pagina.IdPagina;
      this.modelo.modelo.Metadata = this.utility.Clone(this.modelo.modeloMetadata);
      this.modelo.modelo.Metadata.Items = this.modelo.modelo.Metadata.Items.ToArray();
    }
    obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);

    let isGeneralSave = true;
    
    if (this.componentsContainer.FileBox && this.componentsContainer.FileBox.isSelectedFiles()) {

      isGeneralSave = false;
      let externalFiles: any;
      if (this.componentsContainer.FileBoxWorkFlow != null) {
        externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
      }
      this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);

    }


    // ////////Meter validacion antes de este
    if (isGeneralSave) {


      this.baseService.InsertUpdate(obj, form, {
        componentName: this.componentName + 'Controller',
        method: 'Guardar', processMessage: processMessage
      },
        false, this.componentName, false, true).then(data => {
          //this.modelo.modelo = data.Data != null ? data.Data : this.modelo.modelo;

          this.FinGuardar(data.Data);

          if (_isEdit)
            this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
          else
            this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });
        }).catch(error => { this.SetProcesando(false); });
    }
  }

  GuardarRegistroMultiple(configButtons) {
    //debugger;
    /**Edward Morales - Inicio modificación, asignar a cada radicado seleccionado el mismo Modo de Envío, sólo tomaba el de posición 0 */
    var i = 0;
    var j = this.modelo.modelo.length;
    var k = 0;    
    /*if(this.modelo.modelo.IdModoEnvio){
      this.modelo.modelo[0].IdModoEnvio = this.modelo.modelo.IdModoEnvio;
    }
    let obj: any = null;
    let _isEdit = this.IsEdit;
    // const that = this;
    let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
    obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
    this.baseService.MultiInsertUpdate(obj, null, {
      componentName: this.componentName + 'Controller',
      method: 'Guardar', processMessage: processMessage
    },
      false, this.componentName, true, true).then(data => {
        
        if (configButtons && configButtons.Actions) {
          this.modelo.modelo = this.modelo.modelo[0];
          // this.modelo.modelo.IdPlanilla
          this.helperActions.ExecuteActionsControl(configButtons,
            false, null, this.modelo, this.permissionsConfig,
            this, null, new EventEmitter<any>(), this.componentsContainer,
            null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
        }
        this.FinGuardar(data.Data);
      }).catch(error => {
        this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'Guardar' });
      });
  }*/
  while (i<j){
    //debugger;
  if(this.modelo.modelo.IdModoEnvio){
    this.modelo.modelo[i].IdModoEnvio = this.modelo.modelo.IdModoEnvio;
  }
  i++;
}
  let obj: any = null;
  let _isEdit = this.IsEdit;
  // const that = this;
  let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
  obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
  this.baseService.MultiInsertUpdate(obj, null, {
    componentName: this.componentName + 'Controller',
    method: 'Guardar', processMessage: processMessage
  },
    false, this.componentName, true, true).then(data => {
      while (k<j){
        //debugger;
      if (configButtons && configButtons.Actions && this.modelo.modelo[k]) {
        this.modelo.modelo = this.modelo.modelo[k];
        // this.modelo.modelo.IdPlanilla
        this.helperActions.ExecuteActionsControl(configButtons,
          false, null, this.modelo, this.permissionsConfig,
          this, null, new EventEmitter<any>(), this.componentsContainer,
          null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
      }
      if(this.Pagina.Pagina != "AsignacionPlanilla"){
     this.FinGuardar(data.Data);
      }
     k++;
    }
    if(this.Pagina.Pagina == "AsignacionPlanilla"){
    this.FinGuardar(data.Data);
  }
      //i++;
    }).catch(error => {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'Guardar' });
    });
    //i++;
//}
//  i++;
}


}
