import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalBase } from '../../models/modal-base';
import { FormGroup } from '@angular/forms';
import { BaseServiceService } from '../../services/base-service.service'
import { StaticData } from '../../helpers/static-data'
import { Base64 } from 'js-base64';
import { Utilities } from '../../helpers/utilities';
import { CrudActions } from '../../models/general.enum';
import { ModalNotifyComponent } from '../modal-notify/modal-notify.component';
//import { asEnumerable } from 'linq-js';
import { Router } from '@angular/router';
import { List, Enumerable } from '../../../assets/linqts/compilado/index';
import { GeneralComunicationService } from '../../services/general-comunication.service';
import { NavItem } from '../menu-item/nav-item';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css']
})
export class ModalLoginComponent extends ModalBase implements OnInit {

  @ViewChild(ModalNotifyComponent, { static: false }) modalNotifyLogin: ModalNotifyComponent;


  model: any = { UData: 'marlong@bextsa.com', CData: '' };
  fullLoadData: boolean = false;

  constructor(public baseService: BaseServiceService, public utility: Utilities, private router: Router,
    public comunicationService: GeneralComunicationService) {

    super();
  }
  //   public GetDatosEstilos(estilosPorRole: List<any>, estilosGenerales: List<any>, nombreKey: any, valorPorDefecto: any): any {
  //     let obj = estilosPorRole.FirstOrDefault(x => x.Nombre == nombreKey);
  //     if (!obj)
  //       obj = estilosGenerales.FirstOrDefault(x => x.Nombre == nombreKey);
  //     if (!obj)
  //       return valorPorDefecto;
  //     else
  //       return obj.Valor;
  //   }
  //   public SetStyle(estilos: any, objetoColor: any, valorPorDefecto: any): any {
  //     let obj = (valorPorDefecto) ? valorPorDefecto : {};
  //     if (estilos && estilos.split(';').length > 1 && estilos.split(':').length > 1) {
  //       obj = { color: null };
  //       if (objetoColor)
  //         obj.color = objetoColor;
  //       $.each(estilos.split(';'), function (key, val) {

  //         obj[val.split(':')[0]] = val.split(':')[1].trim();
  //       });
  //     }
  //     return obj;
  //   }


  //   public CrearMenus(menus: any, parentMenu: NavItem, isFirst: boolean, context: ModalLoginComponent) {
  //     var i = 0;
  //     $.each(menus, function (key, menuItem: any) {
  //       //angular.forEach(menus, function (menuItem, key) {


  //       let currrent: NavItem = {
  //         displayName: menuItem.Menu,
  //         disabled: false,
  //         iconName: '',
  //         children: [],
  //         style: '',
  //         colorMenu: '',
  //         menu: menuItem
  //       };


  //       var style = (StaticData.Estilos.Menu) ? context.utility.Clone(StaticData.Estilos.Menu) : '';

  //       if (currrent.menu.ColorMenu) {
  //         style.background = currrent.menu.ColorMenu;
  //       }
  //       currrent.style = style;


  //       let _ReportesUsuario = new List<any>(StaticData.Usuario.ReportesUsuario);

  //       var report = _ReportesUsuario.Where(x => { return x.IdMenu == menuItem.IdMenu }).FirstOrDefault();

  //       if (menuItem.IdPagina) {
  //         //currrent.callback = $scope.MenuNavigate;
  //       }
  //       else if (report) {
  //         // currrent.callback = $scope.MenuNavigate;
  //         currrent.menu.Report = report;
  //         currrent.menu.Ruta = 'ReporteGeneral';
  //       }


  //       var menusHijos = new List<any>(StaticData.Usuario.MenusUsuario).Where(x => { return x.IdMenuPadre == menuItem.IdMenu }).ToArray();
  //       if (menusHijos.length > 0) {

  //         context.CrearMenus(menusHijos, currrent, false, context);
  //       }
  //       if (isFirst == true) {

  //         if (!menuItem.IdMenuPadre) {
  //           parentMenu.children.push(currrent);
  //         }
  //       }
  //       else {
  //         if (parentMenu) {
  //           parentMenu.children.push(currrent);
  //         }
  //       }
  //     });
  //   }

  //   onSubmit(frm: FormGroup) {
  //     StaticData.InitData();
  //     //this. baseService.modalNotify =this.modalNotifyLogin;
  //     //this.router.config.push( { path: 'formMdbostrap', component: FormMdbostrapComponent });
  //     const that = this;
  //     that.SetProcesando(true);
  //     that.comunicationService.raiseNavItemsEvent([]);

  //     let modeloTemp = this.utility.Clone(this.model);
  //     modeloTemp.UData = btoa(this.model.UData);
  //     //modeloTemp.CData = btoa(this.model.CData);
  //     let obj = this.utility.GetApiModel('Login', '', CrudActions.None, null, modeloTemp);
  //     this.baseService.Login(obj, frm,
  //       { componentName: 'ModalLoginComponent', method: 'Login',processMessage:'Procesando credenciales....' },
  //       'AuthenticationBase', true, true).then(data => {

  //         

  //         var s = that.router;
  //         if (!data.Data || data.Data.Table === undefined || data.Data.Table[0] === undefined) {
  //           that.utility.VerModalWarning(
  //             { titulo: 'Mensaje Alerta', descripcion: 'El usuario no es valido en el sistema.....', verOk: true, verCancelar: false, ok: 'ACEPTAR', cancelar: 'NO' }).then(data => { });

  //           //throw 'El usuario no es valido en el sistema';
  //           return;
  //         }
  //         StaticData.Usuario = data.Data.Table[0];
  //         StaticData.Usuario.Token = data.Data.Token;
  //         if (StaticData.Usuario.Activo == false) {
  //           StaticData.Usuario = null;
  //           that.utility.VerModalWarning(
  //             { titulo: 'Mensaje Alerta', descripcion: 'El usuario no se encuentra activo.....', verOk: true, verCancelar: false, ok: 'ACEPTAR', cancelar: 'NO' }).then(data => { });


  //           //throw 'El usuario no se encuentra activo';
  //           return;
  //         }
  //         if (!data.Data.Token) {
  //           StaticData.Usuario = null;


  //           that.utility.VerModalWarning(
  //             { titulo: 'Mensaje Alerta', descripcion: 'Token Invalido....', verOk: true, verCancelar: false, ok: 'ACEPTAR', cancelar: 'NO' }).then(data => { });

  //           //throw 'El usuario no se encuentra activo';
  //           return;
  //         }
  //         StaticData.Usuario.MenusUsuario = data.Data.Table1;
  //         StaticData.Usuario.PaginasUsuario = data.Data.Table2;
  //         StaticData.Usuario.Paginas = data.Data.Table3;
  //         StaticData.Usuario.ReportesUsuario = data.Data.Table4;





  // /* 
  //         $.each(StaticData.Usuario.PaginasUsuario, function (key, pagina) {

  //           // > [8, 10]
  //           //var camposPaginas = Enumerable.From(data.Data.Table6).Where(function (x) { return x.IdPagina == pagina.IdPagina }).ToArray();

  //           var paginaConfig = {
  //             IdPagina: pagina.IdPagina,
  //             Nombre: pagina.Pagina, Config: {}
  //           };
  //           if(pagina.Configuracion){

  //           }

  //           StaticData.ConfigPaginas.push(paginaConfig);
  //         }); */



  //         StaticData.Usuario.Rules = data.Data.Rules;
  //         $.each(StaticData.Usuario.ReportesUsuario, function (key, reporte) {

  //           if (reporte.ConfiguracionFiltro && reporte.ConfiguracionFiltro !== null) {
  //             reporte.ConfigFilter = JSON.parse(reporte.ConfiguracionFiltro);
  //             let shapes = new List<any>(reporte.ConfigFilter.Shapes);
  //             reporte.ConfigFilter.Shapes = shapes.Where(x => x.Active == true).ToArray();
  //             // reporte.ConfigFilter.Shapes = Enumerable.From(reporte.ConfigFilter.Shapes).Where(function (x) { return x.Active == true }).ToArray();
  //           }
  //         });






  //         // let BotonesComoMenu = StaticData.ParametrosGenerales.Where(x=>{return x.Nombre=='BotonesComoMenu' }).FirstOrDefault();
  //         // StaticData.BotonesComoMenu =(BotonesComoMenu)?BotonesComoMenu.ValorFalsoVerdadero:false;

  //         let estilosGenerales = (data.Data.Table5 && data.Data.Table5.length > 0) ? new List<any>(data.Data.Table5) : new List<any>([]);
  //         let estilosPorRole = (data.Data.Table6 && data.Data.Table6.length > 0) ? new List<any>(data.Data.Table6) : new List<any>([]);

  //         StaticData.ParametrosGenerales = (data.Data.Table7) ? new List<any>(data.Data.Table7) : new List<any>([]);


  //         let BotonesComoMenu: string = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'BotonesComoMenu', '');
  //         StaticData.BotonesComoMenu = false;

  //         if (BotonesComoMenu &&
  //           (BotonesComoMenu.toLowerCase() == 'si' ||
  //             BotonesComoMenu.toLowerCase() == 'ok' ||
  //             BotonesComoMenu.toLowerCase() == 'yes' ||
  //             BotonesComoMenu.toLowerCase() == 'true' ||
  //             BotonesComoMenu.toLowerCase() == '1'))
  //           StaticData.BotonesComoMenu = true;


  //         let Tema = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'Tema', '');

  //         let ColorMenu = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorMenu', 'blue');
  //         let ColorTitulos = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorTitulos', 'blue');
  //         let ColorBotonGuardar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonGuardar', 'blue');

  //         let ColorBotonLimpiar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonLimpiar', 'blue');
  //         let ColorBotonFiltro = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonFiltro', 'blue');
  //         let ColorBotonImportar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonImportar', 'blue');
  //         let ColorBotonExportarExcel = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonExportarExcel', 'blue');
  //         let ColorBotonExportarCsv = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonExportarCsv', 'blue');
  //         let ColorBotonRedireccion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonRedireccion', 'blue');
  //         let ColorBotonBuscar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ColorBotonBuscar', 'blue');

  //         let ClassMenu = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassMenu', '');
  //         //let ClassTitulos =that.GetDatosEstilos(estilosPorRole,estilosGenerales,'ClassTitulos','blue');
  //         let ClassBotonGuardar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonGuardar', 'btn btn-outline-success');
  //         let ClassBotonLimpiar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonLimpiar', 'btn btn-outline-info');
  //         let ClassBotonFiltro = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonFiltro', 'btn btn-outline-info');
  //         let ClassBotonImportar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonImportar', 'btn btn-outline-info');
  //         let ClassBotonExportarExcel = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonExportarExcel', 'btn btn-outline-info');
  //         let ClassBotonExportarCsv = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonExportarCsv', 'btn btn-outline-info');
  //         let ClassBotonRedireccion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonRedireccion', 'btn btn-outline-info');
  //         let ClassBotonBuscar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonBuscar', 'btn btn-outline-info');
  //         let ClassTitulo = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassTitulo', '');

  //         let ClassContenedorGeneral = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassContenedorGeneral', 'container');
  //         let ClassContenedor = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassContenedor', 'container card');
  //         let ClassHeader = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassHeader', 'card-header info-color white-text text-center py-4');
  //         let ClassContenedorForm = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassContenedorForm', 'card-body px-lg-5 pt-0');

  //         let ClassContenedorControlesAdd = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassContenedorControlesAdd', '');

  //         let ClassControles = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassControles', 'md-form');

  //         let ClassBotonOpcion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'ClassBotonOpcion', 'btn btn-info btn dropdown-toggle');

  //         let _EstiloTitulo = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloTitulo', 'blue');
  //         let EstiloTitulo = that.SetStyle(_EstiloTitulo, ColorTitulos, { color: ColorTitulos });
  //         let _EstiloBotonGuardar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonGuardar', '');
  //         let EstiloBotonGuardar = that.SetStyle(_EstiloBotonGuardar, ColorBotonGuardar, {
  //           'background-color': ColorBotonGuardar,
  //           'border-color': ColorBotonGuardar,
  //           'cursor': 'pointer'
  //         });
  //         let _EstiloBotonLimpiar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonLimpiar', '');
  //         let EstiloBotonLimpiar = that.SetStyle(_EstiloBotonLimpiar, ColorBotonLimpiar, {
  //           'background-color': ColorBotonLimpiar,
  //           'border-color': ColorBotonLimpiar,
  //           'cursor': 'pointer'
  //         });

  //         let _EstiloBotonFiltro = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonFiltro', '');
  //         let EstiloBotonFiltro = that.SetStyle(_EstiloBotonFiltro, ColorBotonFiltro, {
  //           'background-color': ColorBotonFiltro,
  //           'border-color': ColorBotonFiltro,
  //           'cursor': 'pointer'
  //         });

  //         let _EstiloBotonImportar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonImportar', '');
  //         let EstiloBotonImportar = that.SetStyle(_EstiloBotonImportar, ColorBotonImportar, {
  //           'background-color': ColorBotonImportar,
  //           'border-color': ColorBotonImportar,
  //           'cursor': 'pointer'
  //         });
  //         let _EstiloBotonExportarExcel = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonExportarExcel', '');
  //         let EstiloBotonExportarExcel = that.SetStyle(_EstiloBotonExportarExcel, ColorBotonExportarExcel, {
  //           'background-color': ColorBotonExportarExcel,
  //           'border-color': ColorBotonExportarExcel,
  //           'cursor': 'pointer'
  //         });
  //         let _EstiloBotonExportarCsv = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonExportarCsv', '');
  //         let EstiloBotonExportarCsv = that.SetStyle(_EstiloBotonExportarCsv, ColorBotonExportarCsv, {
  //           'background-color': ColorBotonExportarCsv,
  //           'border-color': ColorBotonExportarCsv,
  //           'cursor': 'pointer'
  //         });

  //         let _EstiloBotonRedireccion = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonRedireccion', '');
  //         let EstiloBotonRedireccion = that.SetStyle(_EstiloBotonRedireccion, ColorBotonRedireccion, {
  //           'background-color': ColorBotonRedireccion,
  //           'border-color': ColorBotonRedireccion,
  //           'cursor': 'pointer'
  //         });

  //         let _EstiloBotonBuscar = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloBotonBuscar', '');
  //         let EstiloBotonBuscar = that.SetStyle(_EstiloBotonBuscar, ColorBotonBuscar, {
  //           'background-color': ColorBotonBuscar,
  //           'border-color': ColorBotonBuscar,
  //           'cursor': 'pointer'
  //         });



  //         let _EstiloContenedorGeneral = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloContenedorGeneral', '');
  //         let EstiloContenedorGeneral = that.SetStyle(_EstiloContenedorGeneral, null, null);

  //         let _EstiloContenedor = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloContenedor', '');
  //         let EstiloContenedor = that.SetStyle(_EstiloContenedor, null, null);
  //         let _EstiloHeader = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloHeader', '');
  //         let EstiloHeader = that.SetStyle(_EstiloHeader, null, null);
  //         let _EstiloContenedorForm = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloContenedorForm', '');
  //         let EstiloContenedorForm = that.SetStyle(_EstiloContenedorForm, null, null);


  //         let _EstiloContenedorControlesAdd = that.GetDatosEstilos(estilosPorRole, estilosGenerales, 'EstiloContenedorControlesAdd', '');
  //         let EstiloContenedorControlesAdd = that.SetStyle(_EstiloContenedorControlesAdd, null, null);


  //         StaticData.Estilos = {
  //           EstiloBotonGuardar: EstiloBotonGuardar,
  //           EstiloBotonLimpiar: EstiloBotonLimpiar,
  //           EstiloBotonFiltro: EstiloBotonFiltro,
  //           EstiloBotonImportar: EstiloBotonImportar,
  //           EstiloBotonExportarExcel: EstiloBotonExportarExcel,
  //           EstiloBotonExportarCsv: EstiloBotonExportarCsv,
  //           ClassBotonGuardar: ClassBotonGuardar,
  //           ClassBotonLimpiar: ClassBotonLimpiar,
  //           ClassBotonFiltro: ClassBotonFiltro,
  //           ClassBotonImportar: ClassBotonImportar,

  //           ClassBotonExportarExcel: ClassBotonExportarExcel,
  //           ClassBotonExportarCsv: ClassBotonExportarCsv,
  //           ClassControles: ClassControles,
  //           ClassBotonOpcion:ClassBotonOpcion,
  //           EstiloTitulo: EstiloTitulo,
  //           ClassTitulo: ClassTitulo,
  //           EstiloContenedor: EstiloContenedor,
  //           ClassContenedor: ClassContenedor,

  //           EstiloHeader: EstiloHeader,
  //           ClassHeader: ClassHeader,
  //           EstiloContenedorForm: EstiloContenedorForm,
  //           ClassContenedorForm: ClassContenedorForm,
  //           EstiloContenedorGeneral: EstiloContenedorGeneral,
  //           ClassContenedorGeneral: ClassContenedorGeneral,
  //           EstiloContenedorControlesAdd: EstiloContenedorControlesAdd,
  //           ClassContenedorControlesAdd: ClassContenedorControlesAdd,
  //           Tema: Tema,
  //           EstiloBotonRedireccion: EstiloBotonRedireccion,
  //           ClassBotonRedireccion: ClassBotonRedireccion,
  //           EstiloBotonBuscar: EstiloBotonBuscar,
  //           ClassBotonBuscar: ClassBotonBuscar,

  //           MenuSuperior: {
  //             'border-bottom': ColorMenu + ' 2px solid'

  //           },
  //           Linea: {
  //             height: '50px', 'border-bottom': ColorMenu + ' 2px solid'

  //           },



  //         };





  //         let MainMenu: NavItem = {
  //           displayName: '',
  //           disabled: false,
  //           iconName: '',
  //           children: [],
  //           style: '',
  //           colorMenu: '',
  //           menu: {}
  //         };



  //         if (StaticData.Usuario.MenusUsuario) {
  //           that.CrearMenus(StaticData.Usuario.MenusUsuario, MainMenu, true, that);

  //           let Temp = MainMenu.children;
  //         }
  //         else
  //           console.log("No Hay menus asignados");

  //         that.comunicationService.raiseNavItemsEvent(MainMenu.children);
  //         that.SetProcesando(false);

  //         that.close(true);

  //       }).catch(error => {
  //         console.log(error);
  //         that.SetProcesando(false);
  //         this.close(false);
  //       });

  //     //baseService

  //   }

  End(event) {
    if (!StaticData.LoginLoadAllComponents) {
      this.cargarDatosComplementarios();
    }   
  }

  Close(event) {
    this.close(event);
  }

  resultmodalNotify(data: any) {


  }

  public cargarDatosComplementarios() {

    /*var obj = this.utility.GetApiModel('LoadComplementaryData', "", CrudActions.None, null);

    obj.QueryConfig.Command = 'ConsultaDatosComplementariosUsuario';
    obj.QueryConfig.IsSp = true;
    
    obj.QueryConfig.Entity = { IdUsuario: StaticData.Usuario.IdUsuario };*/


    // obj.QueryConfig.Entity = (esEdicion) ? { IdTablaRetencionDocumental: this.modelo.modelo.IdTablaRetencionDocumental } : this.modelo.modeloCascada;
    // this.baseService.showLoadNotifyType =true;
    this.baseService.LoadComplementaryData(StaticData.Usuario.IdUsuario, null,
      {
        componentName: 'ModalLoginComponent',
        method: 'LoadComplementaryData',
        processMessage: 'Cargando datos complementarios....'
      }, 'AuthenticationBase')
      .then(data => {        
        /*const estilosGenerales = (data.Data.Table[0] && data.Data.Table[0].length > 0) ? new List<any>(data.Data.Table[0]) : new List<any>([]);
        const estilosPorRole = (data.Data.Table1 && data.Data.Table1.length > 0) ? new List<any>(data.Data.Table1) : new List<any>([]);*/

        StaticData.ParametrosGenerales = (data.Data.Table2) ? new List<any>(data.Data.Table2) : new List<any>([]);
        /*  let ConfigGeneral = StaticData.ParametrosGenerales.FirstOrDefault(x => { return x.Nombre == 'ConfigGeneral' });
     if (ConfigGeneral) {
       StaticData.ConfigGeneral = JSON.parse(ConfigGeneral);
      
     } */
        //
        StaticData.RolesUsuariosGrupos = new List<any>(data.Data.Table3);
        StaticData.Carpetas = new List<any>(data.Data.Table4);
        StaticData.AreasEmpresa = new List<any>(data.Data.Table5);
        StaticData.Series = new List<any>(data.Data.Table6);
        StaticData.SubSeries = new List<any>(data.Data.Table7);

        /*const BotonesComoMenu: string = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "BotonesComoMenu", "");
        StaticData.BotonesComoMenu = false;

        if (BotonesComoMenu &&
          (BotonesComoMenu.toLowerCase() == "si" ||
            BotonesComoMenu.toLowerCase() == "ok" ||
            BotonesComoMenu.toLowerCase() == "yes" ||
            BotonesComoMenu.toLowerCase() == "true" ||
            BotonesComoMenu.toLowerCase() == "1"))
          StaticData.BotonesComoMenu = true;


        const Tema = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "Tema", "");

        const ColorMenu = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorMenu", "blue");
        const ColorTitulos = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorTitulos", "blue");
        const ColorBotonGuardar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonGuardar", "blue");

        const ColorBotonLimpiar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonLimpiar", "blue");
        const ColorBotonFiltro = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonFiltro", "blue");
        const ColorBotonImportar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonImportar", "blue");
        const ColorBotonExportarExcel = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonExportarExcel", "blue");
        const ColorBotonExportarCsv = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonExportarCsv", "blue");
        const ColorBotonRedireccion = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonRedireccion", "blue");
        const ColorBotonBuscar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ColorBotonBuscar", "blue");

        const ClassMenu = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassMenu", "");
        //let ClassTitulos =that.GetDatosEstilos(estilosPorRole,estilosGenerales,'ClassTitulos','blue');
        const ClassBotonGuardar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonGuardar", "btn btn-outline-success");
        const ClassBotonLimpiar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonLimpiar", "btn btn-outline-info");
        const ClassBotonFiltro = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonFiltro", "btn btn-outline-info");
        const ClassBotonImportar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonImportar", "btn btn-outline-info");
        const ClassBotonExportarExcel = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonExportarExcel", "btn btn-outline-info");
        const ClassBotonExportarCsv = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonExportarCsv", "btn btn-outline-info");
        const ClassBotonRedireccion = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonRedireccion", "btn btn-outline-info");
        const ClassBotonBuscar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonBuscar", "btn btn-outline-info");
        const ClassTitulo = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassTitulo", "");

        const ClassContenedorGeneral = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedorGeneral", "container");
        const ClassContenedor = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedor", "container card");
        const ClassHeader = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassHeader", "card-header info-color white-text text-center py-4");
        const ClassContenedorForm = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedorForm", "card-body px-lg-5 pt-0");

        const ClassContenedorControlesAdd = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassContenedorControlesAdd", "");

        const ClassControles = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassControles", "md-form");

        const ClassBotonOpcion = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassBotonOpcion", "btn btn-info btn dropdown-toggle");

        const _EstiloTitulo = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloTitulo", "blue");
        const EstiloTitulo = this.SetStyle(_EstiloTitulo, ColorTitulos, { color: ColorTitulos });
        const _EstiloBotonGuardar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonGuardar", "");
        const EstiloBotonGuardar = this.SetStyle(_EstiloBotonGuardar, ColorBotonGuardar, {
          "background-color": ColorBotonGuardar,
          "border-color": ColorBotonGuardar,
          "cursor": "pointer"
        });
        const _EstiloBotonLimpiar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonLimpiar", "");
        const EstiloBotonLimpiar = this.SetStyle(_EstiloBotonLimpiar, ColorBotonLimpiar, {
          "background-color": ColorBotonLimpiar,
          "border-color": ColorBotonLimpiar,
          "cursor": "pointer"
        });

        const _EstiloBotonFiltro = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonFiltro", "");
        const EstiloBotonFiltro = this.SetStyle(_EstiloBotonFiltro, ColorBotonFiltro, {
          "background-color": ColorBotonFiltro,
          "border-color": ColorBotonFiltro,
          "cursor": "pointer"
        });

        const _EstiloBotonImportar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonImportar", "");
        const EstiloBotonImportar = this.SetStyle(_EstiloBotonImportar, ColorBotonImportar, {
          "background-color": ColorBotonImportar,
          "border-color": ColorBotonImportar,
          "cursor": "pointer"
        });
        const _EstiloBotonExportarExcel = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonExportarExcel", "");
        const EstiloBotonExportarExcel = this.SetStyle(_EstiloBotonExportarExcel, ColorBotonExportarExcel, {
          "background-color": ColorBotonExportarExcel,
          "border-color": ColorBotonExportarExcel,
          "cursor": "pointer"
        });
        const _EstiloBotonExportarCsv = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonExportarCsv", "");
        const EstiloBotonExportarCsv = this.SetStyle(_EstiloBotonExportarCsv, ColorBotonExportarCsv, {
          "background-color": ColorBotonExportarCsv,
          "border-color": ColorBotonExportarCsv,
          "cursor": "pointer"
        });

        const _EstiloBotonRedireccion = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonRedireccion", "");
        const EstiloBotonRedireccion = this.SetStyle(_EstiloBotonRedireccion, ColorBotonRedireccion, {
          "background-color": ColorBotonRedireccion,
          "border-color": ColorBotonRedireccion,
          "cursor": "pointer"
        });

        const _EstiloBotonBuscar = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloBotonBuscar", "");
        const EstiloBotonBuscar = this.SetStyle(_EstiloBotonBuscar, ColorBotonBuscar, {
          "background-color": ColorBotonBuscar,
          "border-color": ColorBotonBuscar,
          "cursor": "pointer"
        });



        const _EstiloContenedorGeneral = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedorGeneral", "");
        const EstiloContenedorGeneral = this.SetStyle(_EstiloContenedorGeneral, null, null);

        const _EstiloContenedor = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedor", "");
        const EstiloContenedor = this.SetStyle(_EstiloContenedor, null, null);
        const _EstiloHeader = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloHeader", "");
        const EstiloHeader = this.SetStyle(_EstiloHeader, null, null);
        const _EstiloContenedorForm = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedorForm", "");
        const EstiloContenedorForm = this.SetStyle(_EstiloContenedorForm, null, null);


        const _EstiloContenedorControlesAdd = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "EstiloContenedorControlesAdd", "");
        const EstiloContenedorControlesAdd = this.SetStyle(_EstiloContenedorControlesAdd, null, null);



        const ClassNodos = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodos", "");//'container alert alert-primary'
        const ClassNodosNuevos = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosNuevos", "");
        const ClassNodosEliminados = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosEliminados", "");
        const ClassNodosModificados = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosModificados", "");
        const ClassNodosCambioPadre = this.GetDatosEstilos(estilosPorRole, estilosGenerales, "ClassNodosCambioPadre", "");


        StaticData.Estilos = {
          EstiloBotonGuardar: EstiloBotonGuardar,
          EstiloBotonLimpiar: EstiloBotonLimpiar,
          EstiloBotonFiltro: EstiloBotonFiltro,
          EstiloBotonImportar: EstiloBotonImportar,
          EstiloBotonExportarExcel: EstiloBotonExportarExcel,
          EstiloBotonExportarCsv: EstiloBotonExportarCsv,
          ClassBotonGuardar: ClassBotonGuardar,
          ClassBotonLimpiar: ClassBotonLimpiar,
          ClassBotonFiltro: ClassBotonFiltro,
          ClassBotonImportar: ClassBotonImportar,

          ClassBotonExportarExcel: ClassBotonExportarExcel,
          ClassBotonExportarCsv: ClassBotonExportarCsv,
          ClassControles: ClassControles,
          ClassBotonOpcion: ClassBotonOpcion,
          EstiloTitulo: EstiloTitulo,
          ClassTitulo: ClassTitulo,
          EstiloContenedor: EstiloContenedor,
          ClassContenedor: ClassContenedor,

          EstiloHeader: EstiloHeader,
          ClassHeader: ClassHeader,
          EstiloContenedorForm: EstiloContenedorForm,
          ClassContenedorForm: ClassContenedorForm,
          EstiloContenedorGeneral: EstiloContenedorGeneral,
          ClassContenedorGeneral: ClassContenedorGeneral,
          EstiloContenedorControlesAdd: EstiloContenedorControlesAdd,
          ClassContenedorControlesAdd: ClassContenedorControlesAdd,
          Tema: Tema,
          EstiloBotonRedireccion: EstiloBotonRedireccion,
          ClassBotonRedireccion: ClassBotonRedireccion,
          EstiloBotonBuscar: EstiloBotonBuscar,
          ClassBotonBuscar: ClassBotonBuscar,
          ClassNodos: ClassNodos,
          ClassNodosNuevos: ClassNodosNuevos,
          ClassNodosEliminados: ClassNodosEliminados,
          ClassNodosModificados: ClassNodosModificados,
          ClassNodosCambioPadre: ClassNodosCambioPadre,
          MenuSuperior: {
            "border-bottom": ColorMenu + " 2px solid"

          },
          Linea: {
            height: "50px", "border-bottom": ColorMenu + " 2px solid"

          },



        };*/

        //this.close(true);

      }).catch(err => {
        this.utility.logger.LogError(err, StaticData.Usuario.IdUsuario, {
            componentName: 'ModalLoginComponent',
            method: 'LoadComplementaryData',
            processMessage: 'Cargando datos complementarios....'
          }, 'AuthenticationBase')
      });



  }

  public GetDatosEstilos(estilosPorRole: List<any>, estilosGenerales: List<any>, nombreKey: any, valorPorDefecto: any): any {
    let obj = estilosPorRole.FirstOrDefault(x => x.Nombre == nombreKey);
    if (!obj)
      obj = estilosGenerales.FirstOrDefault(x => x.Nombre == nombreKey);
    if (!obj)
      return valorPorDefecto;
    else
      return obj.Valor;
  }
  public SetStyle(estilos: any, objetoColor: any, valorPorDefecto: any): any {
    let obj = (valorPorDefecto) ? valorPorDefecto : {};
    if (estilos && estilos.split(";").length > 1 && estilos.split(":").length > 1) {
      obj = { color: null };
      if (objetoColor)
        obj.color = objetoColor;
      $.each(estilos.split(";"), function (key, val) {

        obj[val.split(":")[0]] = val.split(":")[1].trim();
      });
    }
    return obj;
  }


}
