//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods, DataTableAjaxOrderTypes, TypesUseForm, ControlActions } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { StaticData } from 'src/app/helpers/static-data';
import { Enumerable, List } from "../../../../assets/linqts/compilado/index";

@Component({
  selector: 'app-restado-solicitud',
  templateUrl: './restado-solicitud.component.html',
  styleUrls: ['./restado-solicitud.component.css']
})
export class REstadoSolicitudComponent extends GeneralFormComponent implements OnInit, AfterViewInit {
	
	
	
	constructor(public baseService: BaseServiceService, public utility: Utilities,
		public helperImages: HelperImages,
		public helperRules: HelperRules,
		public helperActions: HelperActions) {

		super(baseService, utility, helperImages, helperRules, helperActions);
	}
	
	// ngAfterViewInit() {
	// 	
	// 	this.userModel.Nueva();
	// }

	public OnInitEnd() {
		
				

			this.postSaveEmitter.subscribe(data => {
				
				let obj = this.utility.GetApiModel("ConsultaRolesGruposUsuariosAreasEmpresaTipo", null);
        obj.QueryConfig = {
          Command: "ConsultaRolesGruposUsuariosAreasEmpresaTipo",
          IsSp: true,
          Entity: {
            IdEmpresa: 1
          },
        };
        let info = {
          componentName: this.componentName,
          controlName: "ConsultaRolesGruposUsuariosAreasEmpresaTipo",
          processMessage: "Cargando ConsultaIndicativo......",
        };

        this.baseService
          .Get(obj, null, info, this.componentName, false, false)
          .then((data) => {
            
			StaticData.RolesUsuariosGrupos = new List<any>(data.Data);            
          })
          .catch((err) => {
            this.utility.logger.LogError(err, obj, {
              componentName: this.componentName,
              method: "Consulta",
            });
          });				
				
			});
		

	}	
}
