import { Component, OnInit, forwardRef, Inject } from '@angular/core';
import { NavItem } from '../menu-item/nav-item';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { Router } from '@angular/router';
import { StaticData } from 'src/app/helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import PerfectScrollbar from 'perfect-scrollbar';
import { List } from '../../../assets/linqts/compilado/index';
import { Stopwatch } from 'ts-stopwatch';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/Home', title: 'Inicio', icon: 'dashboard', class: '' },
  { path: '/Login', title: 'Login', icon: 'person', class: '' },
  { path: '/user-profile', title: 'Perfil de Usuario', icon: 'person', class: '' },
  { path: '/table-list', title: 'Table List', icon: 'content_paste', class: '' },
  { path: '/typography', title: 'Typography', icon: 'library_books', class: '' },
  { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '' },
  { path: '/maps', title: 'Maps', icon: 'location_on', class: '' },
  { path: '/notifications', title: 'Notifications', icon: 'notifications', class: '' },
];
declare var shortcut: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  all_shortcuts: any[];
  menus_shortcuts: any[] = [];
  _all_shortcuts: any = new List<any>([]);

  //Items Traidos del Menú inicial
  navItems: NavItem[] = [];
  verIDE: boolean = false;
  textoLogin = 'Iniciar sesión';

  //* LOGO IAMGES
  bestDocSim: string;
  bestDocTexto: string;
  asignarShortCut: boolean = true;

  constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities, public comunicationService: GeneralComunicationService, public router: Router,
  public translate: TranslateService) {this.translate.setDefaultLang(StaticData.Language); }

  //------------------------------- Menú Inicial

  public NavegarLogin() {

    // this.modalLogin.open();
    if (this.textoLogin === 'Iniciar sesión') {
      this.comunicationService.raiseLoginEvent();
      this.textoLogin = 'Cerrar sesión';
    }
    else {
      this.textoLogin = 'Iniciar sesión';
      this.navItems = [];
      StaticData.Usuario = null;
      StaticData.Estilos = {};
      StaticData.ConfigPaginas = [];
      this.router.navigate(['Home']);
    }
  }

  public MenuInPos(eventElem) {
    $(".sidebar__text-inpos").toggleClass("sidebar__text-inpos");
    $(".inpos").toggleClass("inpos");
    $($(eventElem.target).parentsUntil('div')[$(eventElem.target).parentsUntil('div').length - 2]).toggleClass("inpos");
    if ($('span.active > a')["0"].hash) {
      $($('span.active > a')["0"].hash).collapse("toggle");
    }
    $(".active").toggleClass("active");
    $(".sidebar__text").toggleClass("sidebar__text-inpos");
  }

  public Navegar(item: NavItem) {

    StaticData.StopWatch = null;
    StaticData.StopWatch = new Stopwatch();
    StaticData.StopWatch.start();
    StaticData.StartProcess = new Date().getTime();

    StaticData.StopWatchView = null;
    StaticData.StopWatchView = new Stopwatch();
    StaticData.StopWatchView.start();
    StaticData.StartProcessView = new Date().getTime();

    if (item.config && item.config.ExternalUrl) {
      this.ProcesarUrlMenu(item);
    }
    else
      this.router.navigate([item.menu.Ruta]);
  }
  ProcesarUrlMenu(item: NavItem) {
    
    var cleanURl = '';
    let queryString: Array<string> = [];
    if (item.config.MappingParameters && item.config.MappingParameters.length > 0) {
      item.config.MappingParameters.forEach(parameter => {

        const value = this.utility.GetParmeterValue(parameter, null, null, null);
        if (item.config.IsParametersModels)
          this.utility.SetValueParmeterValue(parameter, value);
        else {
          queryString.push(`'${parameter.Name}'='${value}' `);
        }
      });
    }
    if (queryString.length > 0) {
      if (item.config.ExternalUrl.includes("window.open")) {
        var strignArray = '';
        var arrayUrl = item.config.ExternalUrl
          .replace('window.open(', '')
          .replace(');', '')
          .replace(/["]/g, '')
          .split(','); //remove characters and convert array
        const cleanQueryString = queryString.join("&").replace(/[']/g, '').replace(' ', '').trim(); //clean parameter join
        arrayUrl[0] += "?" + cleanQueryString + '\ '; //update url add parameters

        arrayUrl.forEach(a => {
          strignArray += "'" + a + "',";
        });

        if (arrayUrl.length > 0)
          strignArray = strignArray.slice(0, -1);
        cleanURl = "window.open(\ " + strignArray + ")";
      }
      else
        item.config.ExternalUrl += "?" + queryString.join("&");
    }
    if (item.config.IsLocalUrl) {
      this.router.navigate([item.config.ExternalUrl]);
    }
    else {
      if (item.config.ExternalUrl.includes("window.open")) {
        if (queryString.length > 0)
          eval(cleanURl)
        else
          eval(item.config.ExternalUrl);
      }
      else
        window.location.href = item.config.ExternalUrl
    }
  }
  public Menu(event) {
    if ($(event.currentTarget).attr('aria-expanded') != "true") {
      if ($(event.currentTarget).parent('.dropdown').children('a.dropdown-toggle').attr('aria-expanded') == 'false') {
        $('a.dropdown-toggle.nav-link').attr('aria-expanded', 'false');
        $('.show').removeClass('show')
      }
    } else {

      const targetAttr = $(event.currentTarget).attr('href');
      setTimeout(() => {
        //$(targetAttr).collapse('show');
        $(targetAttr).collapse('hide');

      }, 400);

    }
  }
  get UsuarioActual(): string {
    //return (StaticData.Usuario) ? ' Usuario: ' + StaticData.Usuario.Nombre : 'Usuario:  Anonimo';
    return (StaticData.Usuario) ? StaticData.Usuario.Nombre : 'Anonimo';
  }


  //------------------------------- Fin Menú Inicial

  ngOnInit() {
    //------------------------------- Menú Inicial

    this.comunicationService.receivedNavItemsEvent.subscribe(navItems => {
      this.navItems = navItems;
      this.verIDE = true;
      
      if(StaticData.GeneralConfig.ConfigUI.ActiveShortCutSideBar)
      {
        this.navItems.forEach(_option => {
          this.CreateShorCut(_option);
        })      
        if (this.menus_shortcuts.length > 0) {
          
          this.AddShortCuts();
        }
      }
    });

    //------------------------------- Fin Menú Inicial

    this.menuItems = ROUTES.filter(menuItem => menuItem);

    //* images setup
    this.bestDocSim = this.utility.GetPathImages(this.utility.GetNodeType('bestDocSim'));
    this.bestDocTexto = this.utility.GetPathImages(this.utility.GetNodeType('bestDocTexto'));


    // shortcut.add('Alt+R', ()=> {
    //   
    //   $( "#30" ).trigger('click');
    // });


  }
  default_options = {
    'type': 'keydown',
    'propagate': false,
    'disable_in_input': true,
    'target': window,//document.getElementById('sidebar_menu'),
    'keycode': false,
    'addEventListenerIsSet': false,
    'all_shortcuts': this._all_shortcuts,
    'item_menu': this.menus_shortcuts,
    'CodeOk': ''
  }

  public AddShortCuts() {
    shortcut.add('', () => {
      for (let i = 0; i < this.default_options.item_menu.length; i++) {

        if (this.default_options.item_menu[i].shortcut.toLowerCase().includes(this.default_options.CodeOk)) {
          var accionMenu = $("#" + this.default_options.item_menu[i].menu.IdMenu).trigger('click');
          if ($(accionMenu).attr("aria-expanded") == "false" || !$(accionMenu).attr("aria-expanded"))
            $('.sidebar').removeClass('sidebar__openShorCut')
          else
            $('.sidebar').addClass('sidebar__openShorCut')

          if (this.default_options.item_menu[i].config && this.default_options.item_menu[i].config.ExternalUrl
            || this.default_options.item_menu[i].menu.Ruta)
            this.Navegar(this.default_options.item_menu[i]);
          this.default_options.CodeOk = ''
          return;
        }

      }
    }, this.default_options);
  }

  public CreateShorCut(ItemsMenu: any) {

    if (ItemsMenu.shortcut) {
      this.menus_shortcuts.push(ItemsMenu)
      this._all_shortcuts.Add(ItemsMenu.shortcut)
    }
    if (ItemsMenu.children && ItemsMenu.children.length > 0)
      ItemsMenu.children.forEach(_optionChildren => {
        this.CreateShorCut(_optionChildren);

        // setTimeout(() => {
        //   this.CreateShorCut(_optionChildren);
        // }, 40000);
      })
  }

  ngAfterViewInit() {
    const elemMenu = document.querySelector('.sidebar') as HTMLElement;
    elemMenu.scrollTop = 0;
    let ps = new PerfectScrollbar(elemMenu, {
      handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']      
    });
    $('.sidebar').removeClass('sidebar__openShorCut')
    $(document).on('click', '#openMenu', function () {
      $('.sidebar').addClass('sidebar__open')
    })
    $(document).on('click', '#closeMenu', function () {
      $('.sidebar').removeClass('sidebar__open')
    })
  }

  // isMobileMenu() {
  //   if ($(window).width() > 991) {
  //     return false;
  //   }
  //   return true;
  // }
}
