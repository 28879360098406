import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BaseServiceService } from 'src/app/services/base-service.service';
import { DragAndDropListComponent } from '../../controls/drag-and-drop-list/drag-and-drop-list.component';
import { FormActionsThroughEmitters } from 'src/app/models/general.enum';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { HelperImages } from 'src/app/helpers/helper-images';
import { StaticData } from 'src/app/helpers/static-data';
import { Utilities } from 'src/app/helpers/utilities';

declare var fabric: any;

@Component({
  selector: 'app-asignar-carpetas-trd',
  templateUrl: './asignar-carpetas-trd.component.html',
  styleUrls: ['./asignar-carpetas-trd.component.scss']
})
export class AsignarCarpetasTrdComponent extends DragAndDropListComponent implements OnInit, AfterViewInit {

  isGrouped: boolean = false;
  @Output() onValidateEvent: EventEmitter<any> = new EventEmitter<any>();
  isProceced: boolean = false;
  @Input() withPagination: boolean = true;
  @Input() letras = [
    { Label: 'A', Name: 'A', Items: [], SearchText: "" },
    { Label: 'B', Name: 'B', Items: [], SearchText: "" },
    { Label: 'C', Name: 'C', Items: [], SearchText: "" },
    { Label: 'D', Name: 'D', Items: [], SearchText: "" },
    { Label: 'E', Name: 'E', Items: [], SearchText: "" },
    { Label: 'F', Name: 'F', Items: [], SearchText: "" },
    { Label: 'G', Name: 'H', Items: [], SearchText: "" },
    { Label: 'I', Name: 'I', Items: [], SearchText: "" },
    { Label: 'J', Name: 'J', Items: [], SearchText: "" },
    { Label: 'K', Name: 'K', Items: [], SearchText: "" },
    { Label: 'L', Name: 'L', Items: [], SearchText: "" },
    { Label: 'M', Name: 'M', Items: [], SearchText: "" },
    { Label: 'N', Name: 'N', Items: [], SearchText: "" },
    { Label: 'O', Name: 'O', Items: [], SearchText: "" },
    { Label: 'P', Name: 'Q', Items: [], SearchText: "" },
    { Label: 'R', Name: 'R', Items: [], SearchText: "" },
    { Label: 'S', Name: 'S', Items: [], SearchText: "" },
    { Label: 'T', Name: 'T', Items: [], SearchText: "" }
  ];
  constructor(public baseService: BaseServiceService,
    public utility: Utilities, public helperImages: HelperImages,
    public comunicationService: GeneralComunicationService) {
    super(baseService, utility, helperImages, comunicationService);
    this.imageSelectionItems = helperImages.GetRuleIcon("TipoDocumental");
  }


  public Ver(txt, search, rawPos) {
    var pos = rawPos > 0 ? rawPos | 0 : 0;
    return txt.substring(pos, pos + search.length) === search;
  }

  public GoupedChange() {

    if (this.isGrouped) {
      this.isProceced = true;
      this.letras.forEach(letra => {

        letra.Items = this.allSelectionItems.filter(result => {


          if (result && result.Nombre) {
            return this.Ver(result.Nombre.toLowerCase(), letra.Name.toLowerCase(), 0);
          }
          return false;
        })

        
      })
    }
  }
  public PushGouped(item) {

    if (this.isGrouped) {

      let letra = this.letras.find(x => { return this.Ver(item.Nombre.toLowerCase(), x.Name.toLowerCase(), 0) });
      if (letra) {
        letra.Items.push(item);
      }

    }
  }
  public OnEndInit() {
    if (this.config && this.config.Required && !this.config.RequiredMessage) {
      this.config.RequiredMessage = "Debe seleccionar una carpeta"
    }

  }
  InitControl(event) {
    
    this.InitList();
    setTimeout(() => {
      this.modelo = event.modelo;
      this.SetModelo(event.modelo);
      this.ValidateSelectionItems();
      this.FireValidation();
    }, 100);
  }
  ngOnInit() {


    this.isInit = true;
    this.OnSetConfig();
    this.OnInit();

    if (this.parentClearCall)
      this.parentClearCall.subscribe(event => {
        this.InitControl(event);
      });
    this.comunicationService.receivedFormEvent.subscribe(event => {
      
      if (
        event.Action === FormActionsThroughEmitters.Edit &&
        event.Name == "TablaRetencionDocumental"
      ) {
        this.InitControl(event);
      }
    });
    if (this.onInitialized.observers.length) {
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }

  }
  ngAfterViewInit() {
    /*var SearchBoxElements = document.querySelectorAll(".atd__search");
    for (var i = 0; i < SearchBoxElements.length; i++) {
      new fabric['SearchBox'](SearchBoxElements[i]);
    }*/
    setTimeout(() => {
      this.FireValidation();
    }, 2000);
  }
  ngAfterViewChecked() {

  }
  public Add(item, index, items?: Array<any>, remodeIndex?: any) {
    super.Add(item, index, items, remodeIndex);
    this.FireValidation();

  }
  public FireValidation() {
    
    if (this.config && this.config.Required) {
      this.onValidateEvent.emit((this.selectedItems.length > 0));
      this.comunicationService.raiseValidateSaveEvent({ Name: this.name, Ok: (this.selectedItems.length > 0) });
      if (this.selectedItems.length == 0 && (this.config.RequiredMsgType == 'Notification' || this.config.ViewNotification)) {
        StaticData.ShowNotification(this.config.RequiredMessage, "danger");
      }
    }
  }

  public FireUpdate() {
    super.SetConfiguracion();
  }

  public Delete(index, item) {

    this.selectedItems.splice(index, 1);
    this.deltetedItems.push(item);

    if (this.isGrouped) {
      this.PushGouped(item);
    }

    this.EndAction(item, this.addItems, 'Delete');
    this.FireValidation();

  }



  public ValidarSetModelo(modelo: any): boolean {

    
    if (modelo[this.propConfiguracion].startsWith("[")) {

      StaticData.ShowActionNotificationError("Los Tipos No Cumplen el Formato", "Error Modelo", 500);
      // this.utility.VerModalDanger({ titulo: "Error Modelo", descripcion: "Los metadatos estan mal formados" });
      return false;
    }
    else
      return true;
  }
}
