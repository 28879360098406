import { Component, OnInit, ViewChild } from '@angular/core';
import { GeneralFormComponent } from '../controls/general-form/general-form.component';
import { TipoParaPermisos } from 'src/app/models/general.enum';
import { AdminPermisosComponent } from '../admin-permisos/admin-permisos.component';
import { AdminPermitidosComponent } from '../admin-permitidos/admin-permitidos.component';

@Component({
  selector: 'app-admin-todo-permisos',
  templateUrl: './admin-todo-permisos.component.html',
  styleUrls: ['./admin-todo-permisos.component.scss']
})
export class AdminTodoPermisosComponent extends GeneralFormComponent implements OnInit {


  @ViewChild(AdminPermisosComponent, { static: false }) adminPermisos: AdminPermisosComponent;
  @ViewChild(AdminPermitidosComponent, { static: false }) adminPermitidos: AdminPermitidosComponent;
  //@ViewChild("adminPermitidos", { static: false }) adminPermitidos: AdminPermitidosComponent;
  modeloFiltro: Array<any> = [];
  modelosComparar: Array<any> = [];
  columns: Array<any> = [];
  usuariosRolesPorGrupo: Array<any> = [];
  itemFiltro: any;
  textoSeleccion = "";
  selectItem: any;
  view: boolean = true;
  isFirts: boolean = true;
  tipoFiltro: string = 'Usuario';
  subTipoFiltro: string = '';
  public OnActionEventHandler(event) {

    
    switch (event.actionType) {

      case 'SelectionItem':
        if (this.isFirts) {
          this.ConsultaPermisos(event.selectItem);
        }
        else {
          this.LimpiarTodo(false);

          setTimeout(() => {
            event.selectItem.Seleccionado = true;
            this.ConsultaPermisos(event.selectItem);
          }, 800);

        }
        this.isFirts = false;
        break;

      case 'Delete':
        this.LimpiarTodo(false);
        break;
    }
  }

  public LimpiarTodo(isButton) {
    this.tipoFiltro = "Usuario"
    this.subTipoFiltro = "";
    this.itemFiltro = null;
    this.textoSeleccion ="";
    this.modeloFiltro = [];
    if (isButton)
      this.modelosComparar = [];
    this.usuariosRolesPorGrupo = [];
    this.Filtrar(true);
    if (isButton){
      this.adminPermitidos.currentItem.Seleccionado = false;
    }
    this.adminPermitidos.LimpiarListas();
    //this.adminPermisos.LimpiarListas();

  }

  //adminPermisosComponentadminPermisosComponent
  public OnDataTableEditHandler(event) {

    
    switch (event.name) {
      case 'DataTable_Menus':
        this.view = false;
        this.subTipoFiltro = "Menu";
        this.adminPermitidos.SetItemFromJson(this.modelo.ModeloPermisos, this.modelo.ModeloPermisos.Nombre, "Menu",
          this.itemFiltro, this.tipoFiltro, this.subTipoFiltro);
        break;
      case 'DataTable_Paginas':
        this.view = true;
        this.subTipoFiltro = "Pagina";
        // this.adminPermisos.noEsPagina =false;
        this.adminPermisos.SetItemFromJson(this.modelo.ModeloPermisos, this.modelo.ModeloPermisos.Nombre, "Pagina",
          this.itemFiltro, this.tipoFiltro, this.subTipoFiltro);
        break;
      case 'DataTableExpedientes':
        this.view = true;
        this.subTipoFiltro = "Expediente";
        // this.adminPermisos.noEsPagina =true;
        this.adminPermisos.SetItemFromJson(this.modelo.ModeloPermisos, this.modelo.ModeloPermisos.Nombre, "Expediente",
          this.itemFiltro, this.tipoFiltro, this.subTipoFiltro);
        break;
      case 'DataTableDocumentos':
        this.view = true;
        this.subTipoFiltro = "Documento";
        // this.adminPermisos.noEsPagina =true;
        this.adminPermisos.SetItemFromJson(this.modelo.ModeloPermisos, this.modelo.ModeloPermisos.Nombre, "Documento",
          this.itemFiltro, this.tipoFiltro, this.subTipoFiltro);
        break;
    }


    // this.ExcecuteFuntion(ControllerMethods.Edit, false, this.modelo);

    // this.SetModelToControls();
    // this.SetProcesando(false);
    // this.EditCascading();
    // this.ExcecuteFuntion(ControllerMethods.Edit, true, this.modelo);
    // // this.parentClearCall.next(this.modelo);
    // this.FireFormEvent({ Name: "OnPostEdit", modelo: this.modelo });

    // this.RaiseFormEvent('Edit');
  }

  public AddParaComparar() {

    
    const ModeloFiltro: Array<any> = this.utility.Clone(this.modeloFiltro);
    // ModeloFiltro.splice( 0, 0, {title:this.tipoFiltro, data:this.textoSeleccion} );

    this.modelosComparar.push({
      TipoFiltro: this.tipoFiltro,
      TextoSeleccion: this.textoSeleccion,
      ModeloFiltro: ModeloFiltro,
      UsuariosRolesPorGrupo: this.utility.Clone(this.usuariosRolesPorGrupo)
    })
  }
  public ConsultaPermisos(item) {

    this.selectItem = item;
    const that = this;
    this.usuariosRolesPorGrupo = [];
    let Command = "ConsultaPermisosUsuario";
    let paramName = "IdUsuarioBuscar";
    try {
      switch (item.Tipo) {
        case 'Usuario':
        case TipoParaPermisos.Usuario:
          this.tipoFiltro = "Usuario"
          paramName = "IdUsuarioBuscar";
          Command = "ConsultaPermisosUsuario";
          this.textoSeleccion = "Usuario - " + item.Nombre;
          break;
        case 'Role':
        case TipoParaPermisos.Role:
          this.tipoFiltro = "Role"
          paramName = "IdRoleBuscar";
          Command = "ConsultaPermisosRole";
          this.textoSeleccion = "Role - " + item.Nombre;
          break;
        case 'AreaEmpresa':
        case TipoParaPermisos.AreaEmpresa:
          this.tipoFiltro = "AreaEmpresa"
          paramName = "IdAreaEmpresaBuscar";
          Command = "ConsultaPermisosAreaEmpresa";
          this.textoSeleccion = "AreaEmpresa - " + item.Nombre;
          break;
        case 'Grupo':
        case TipoParaPermisos.Grupo:
          this.tipoFiltro = "Grupo"
          paramName = "IdGrupoBuscar";
          Command = "ConsultaPermisosGrupo";
          this.textoSeleccion = "Grupo - " + item.Nombre;
          break;
      }

      let obj = this.utility.GetApiModel(Command, null)
      obj.QueryConfig = {
        Command: Command,
        IsSp: true,
        NotSetDefault: true,
        Entity: {
        }
      };
      obj.QueryConfig.Entity[paramName] = item.Id;

      let info = {
        componentName: this.componentName, controlName: Command,
        processMessage: 'Cargand Permisos......'
      };

      this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
        
        this.columns = [];
        this.itemFiltro = data.Data[0];
        this.modeloFiltro = [];
        for (var prop in data.Data[0]) {

          this.modeloFiltro.push({ title: prop, data: data.Data[0][prop] })
        }
        if (item.Tipo == "Grupo" || item.Tipo == TipoParaPermisos.Grupo) {


          if (this.itemFiltro.Usuarios) {
            let usuarios: Array<any> = this.itemFiltro.Usuarios.split('|');

            usuarios.forEach(_item => {
              let id = _item.replace('code', '');
              let obj = this.utility.GetItemsAreasRolesUsuarioSeries(TipoParaPermisos.Usuario).find(x => { return x.Id.toString() == id });
              if (obj)
                this.usuariosRolesPorGrupo.push({ Id: obj.Id, Nombre: obj.Nombre, Img: this.helperImages.ImagenUsuario });
            })
          }
          if (this.itemFiltro.Roles) {
            let roles: Array<any> = this.itemFiltro.Roles.split('|');

            roles.forEach(_item => {
              let id = _item.replace('code', '');
              let obj = this.utility.GetItemsAreasRolesUsuarioSeries(TipoParaPermisos.Role).find(x => { return x.Id.toString() == id });
              if (obj)
                this.usuariosRolesPorGrupo.push({ Id: obj.Id, Nombre: obj.Nombre, Img: this.helperImages.ImagenRole });
            })
          }
        }

      }).catch(err => {
        this.utility.logger.LogError(err, null, { componentName: this.componentName, method: 'ConsultaPermisos', controlName: "AdminTodoPermisos" });
        this.utility.VerModalDanger().then(data => { });
      });
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'ConsultaPermisos', controlName: "AdminTodoPermisos" });
      this.utility.VerModalDanger().then(data => { });
    }
  }

  Filtrar(isClear: any = false) {
    

    try {
      // @CodigoUsuarioBuscar varchar(100),
      // @CodigoRoleBuscar varchar(100),
      // @CodigoGrupoBuscar varchar(1000),
      // @CodigoAreaEmpresaBuscar varchar(100),
      
      let count = 0;
      if (isClear) {
        this.componentsContainer.Tables.forEach(tabla => {
          //  if (count == 0) {
          tabla.cargarGrilla = true;
          tabla.QueryConfig.Entity = {
            IdEmpresa: -1,
            TodosPermisos: false,
            Tipo: 'Usuario',
            CodigoUsuarioBuscar: "TEMPDATAS",
            CodigoRoleBuscar: "TEMPDATAS",
            CodigoGrupoBuscar: "TEMPDATAS",
            CodigoAreaEmpresaBuscar: "TEMPDATAS",

          }

          tabla.ReCargarGrilla();
          // }
          count++;
        })
      }
      else {
        this.componentsContainer.Tables.forEach(tabla => {
          //  if (count == 0) {
          tabla.cargarGrilla = true;
          tabla.QueryConfig.Entity = {
            IdEmpresa: -1,
            TodosPermisos: false,
            Tipo: this.tipoFiltro,
            CodigoUsuarioBuscar: this.itemFiltro.CodigoUsuario,
            CodigoRoleBuscar: this.itemFiltro.CodigoRole,
            CodigoGrupoBuscar: this.itemFiltro.CodigoGrupo,
            CodigoAreaEmpresaBuscar: this.itemFiltro.CodigoAreaEmpresa,

          }
          if (tabla.name == "DataTable_Menus")
            tabla.QueryConfig.Entity.TodosPermisos = this.itemFiltro.TodosPermisosMenus;
          else if (tabla.name == "DataTable_Paginas")
            tabla.QueryConfig.Entity.TodosPermisos = this.itemFiltro.TodosPermisosPaginas;
          else if (tabla.name == "DataTableExpedientes")
            tabla.QueryConfig.Entity.TodosPermisos = this.itemFiltro.TodosPermisosExpedientes;
          else if (tabla.name == "DataTableDocumentos")
            tabla.QueryConfig.Entity.TodosPermisos = this.itemFiltro.TodosPermisosDocumentosExpedientes;

          tabla.ReCargarGrilla();
          // }
          count++;
        })

      }


    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'Filtrotablas', controlName: "AdminTodoPermisos" });
      this.utility.VerModalDanger().then(data => { });
    }

  }
}
