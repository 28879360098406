import { Component, OnInit, Input,Output, forwardRef ,EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import {BaseControl } from '../../../models/base-control';
import {Utilities} from '../../../helpers/utilities'

@Component({
  selector: 'app-three-state-toggle-r',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThreeStateToggleRComponent),
      multi: true
    }
  ],
  templateUrl: './three-state-toggle-r.component.html',
  styleUrls: ['./three-state-toggle-r.component.scss']
})
export class ThreeStateToggleRComponent extends BaseControl implements OnInit,ControlValueAccessor {
  OnClick(val:any) {

    this.ctr.setValue(val);
  }
}
