import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control';
import { Utilities } from '../../../helpers/utilities'


@Component({
  selector: 'app-text-box',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextBoxComponent),
      multi: true
    }
  ],
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent extends BaseControl implements OnInit, ControlValueAccessor {

 /*  writeValue(value) {

    console.log('writeValue  ' + this.controlName)
    this.modelo = value;
    if (!value && this.config.Value)
      this.modelo = this.config.Value
    this.SetFoco();
  } */
}


