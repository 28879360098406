import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { UtilitiesColumns } from 'src/app/helpers/utilities-columns';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { debug } from 'util';

@Component({
  selector: 'app-extendable-table',
  templateUrl: './extendable-table.component.html',
  styleUrls: ['./extendable-table.component.scss']
})
export class ExtendableTableComponent implements OnInit {

  @Input() Pagina: any;
  @Input() modelo: Array<any> = [];
  @Input() config: any;
  @Input() componentName: string = "";
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoadedComboBoxEmitter: EventEmitter<any> = new EventEmitter<any>();
    constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityColumns: UtilitiesColumns,
    public helperActions: HelperActions,
    public helperImages: HelperImages) {


  }

  Validar() {

    /*   if (!this.modelo)
        this.modelo = []; */
    if (!this.modelo[this.config.ModelContainer])
      this.modelo[this.config.ModelContainer] = {};
    if (!this.modelo[this.config.ModelContainer][this.config.Name])
      this.modelo[this.config.ModelContainer][this.config.Name] = [];
  }
  ngOnInit() {



    this.Validar();
  }
  public Eliminar(index) {
    this.modelo[this.config.ModelContainer][this.config.Name].splice(index, 1);
    this.OnDeleteEmitter(event)
    // this.onActionEventEmitter.emit({ config: this.config, actionType: '', data: event })
  }
  public Ver(index)
  {
    this.onActionEventEmitter.emit({ config: this.config, actionType: 'OnViewItemExtendableTable', data: this.modelo[this.config.ModelContainer][this.config.Name][index] })
  }

  public Add(isTop: boolean = false) {
    this.Validar();
    if (isTop)
      this.modelo[this.config.ModelContainer][this.config.Name].unshift({});
    else
      this.modelo[this.config.ModelContainer][this.config.Name].push({});
  }

  public OnSelectedComboBoxEmitterHandler(event) {
    if (event._config.ResultModelProperty && event.index > -1) {
      let dataModel = this.utility.splitMulti(event._config.ResultModelProperty);
      if (dataModel.length > 0) {
        dataModel.forEach(element => {
          this.modelo[this.config.ModelContainer][this.config.Name][event.index][element] = event.item ? event.item[element] : undefined;
        });
      }
      else
        this.modelo[this.config.ModelContainer][this.config.Name][event.index][event._config.ResultModelProperty] = event.item;
    }
    this.OnSelectedComboBox(event.item, event.controlName, event.config);
    this.onActionEventEmitter.emit({ config: this.config, actionType: 'OnSelectedComboBoxExtendableTable', data: event })
  }

  public onChangeNumberBox(event, value) {  
    event.valueMetodo = value;
    this.onActionEventEmitter.emit({ config: this.config, actionType: 'OnChangeNumberBoxExtendableTable', data: event })
  }
  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {


      /*  this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, { item: item, controlName: controlName, config: config });
       // this.ExecuteCascading(item, controlName, config);
       if (config.IdMetadataPage) {
         this.SetFormControlsFromMetadataPage(config.IdMetadataPage);
       }
 
       if()
       this.comboBoxManager.ExecuteCascading(item, controlName, config);
       this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, { item: item, controlName: controlName, config: config });
       this.SetModelToControls(); */
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }


  public OnLoadedComboBoxEmitter(event) {

    this.onActionEventEmitter.emit({ config: this.config, actionType: 'OnLoadedComboBoxExtendableTable', data: event })
  }

  public OnDeleteEmitter(event) {
    this.onActionEventEmitter.emit({ config: this.config, actionType: 'EliminarItem', data: event })
  }

  public OnChangeCheckBox(event, item)
  {
    this.onActionEventEmitter.emit({ config: this.config, actionName: event.target.id.substring(0,event.target.id.lastIndexOf("_")), actionType: 'OnChangeCheckBoxxtendableTable', data: item })
  }
}
