import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import {
  ControllerMethods,
  FormActionsThroughEmitters,
  Paginas,
  TipoAdminPermisos,
  TipoObjetoParaPermisos, TipoParaPermisos, TipoSeguridad
} from '../../models/general.enum';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { Subject } from 'rxjs';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Guid } from 'guid-typescript';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { DataTableDirective } from 'angular-datatables';
import { GeneralFormBase } from 'src/app/models/general-form-base';
import { BaseServiceService, DataTablesResponse } from 'src/app/services/base-service.service';
import { BaseCrud } from 'src/app/bussines/base-crud';
import { HttpClient } from '@angular/common/http';
import { Stopwatch } from 'ts-stopwatch';
import { NullAstVisitor } from '@angular/compiler';
import { config } from 'process';
import { data } from 'jquery';
import { AnonymousSubscription } from 'rxjs-compat/Subscription';

// FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-admin-permisos-servidor',
  templateUrl: './admin-permisos-servidor.component.html',
  styleUrls: ['./admin-permisos-servidor.component.scss']
})

export class AdminPermisosServidorComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;

  imagenEditorJson: string = '';
  imagenAEditorAce: string = '';

  @Output() onInitialized = new EventEmitter<any>();
  @Output() componentName: string = 'AdminPermisosComponent';
  @Input() public name: string = 'AdminPermisosComponent';
  @Input() public nameSecurity: string = 'AdminPermisosComponent';
  @Input() public nameSecurityInternal: string = 'AdminPermisosComponentInternal';
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() config: any;
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;
  @Input() tituloUsuarios: string;
  @Input() tituloRoles: string;
  @Input() tituloGrupos: string;
  @Input() tituloAreasEmpresa: string;
  @Input() guardarPermitido: boolean = true;
  @Input() verPermitido: boolean = true;
  @Input() esDocumento: boolean = false;
  @Input() noEsPagina: boolean = true;
  @Input() esExpediente: boolean = false;
  @Input() esSolicitud: boolean = false;
  @Input() esImportar: boolean = false;
  @Input() conCamposPermisos: boolean = true;
  @Input() nombreTablaUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() nombreTablaRolesPermitidos: string = 'RolesPermitidos';
  @Input() nombreTablaGruposPermitidos: string = 'GruposPermitidos';
  @Input() nombreTablaAreasEmpresaPermitidos: string = 'AreasEmpresaPermitidos';
  @Input() nombreTabUsuarios: string = 'usuarios-tabcontent';
  @Input() nombreHrefTabUsuarios: string = '#usuarios-tabcontent';
  @Input() nombreTabRoles: string = 'roles-tabcontent';
  @Input() nombreHrefTabRoles: string = '#roles-tabcontent';
  @Input() nombreTabGrupos: string = 'grupos-tabcontent';
  @Input() nombreHrefTabGrupos: string = '#grupos-tabcontent';
  @Input() nombreTabAreas: string = 'areas-tabcontent';
  @Input() nombreHrefTabAreas: string = '#areas-tabcontent';
  @Input() index: number;

  isInit: boolean = false;
  _modelo: any;
  conFiltroIdEmpresa: boolean;
  configs: {};
  columns: {}[];
  pkName: any;
  onLoadedGridItem: any;
  loadedGridEmitter: any;
  dtOptionsAreasEmpresa: any;
  dtOptionsGrupos: any;
  dtOptionsRoles: any;
  dtOptionsUsuarios: any;
  permisosHeredadosExpedientes: any;
  areasEmpresaPermitidasBuscar: string = 'AreasPermitidas';




  @Input() set modelo(value: any) {
    this._modelo = value;
    if (this.isInit == true) {
      // setTimeout(() => {
      //
      //   this.SetModelo(this._modelo);
      // }, 500);

    }
  }



  @Input() propConfiguracionInterna: string = '';
  @Input() propConfiguracion: string = 'Configuracion';
  @Input() propUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() propRolesPermitidos: string = 'RolesPermitidos';
  @Input() propAreasEmpresaPermitidas: string = 'AreasEmpresaPermitidas';
  @Input() propGruposPermitidos: string = 'GruposPermitidos';
  @Input() maxWith: any = 600;

  estiloTablas: any = {};

  // dtOptionsUsuarios: any;
  // dtOptionsRoles: any;
  // dtOptionsGrupos: any;
  // // dtOptionsAreas: any;
  // dtOptionsTeste: any;

  dtTriggerUsuarios: Subject<any> = null;// new Subject<any>();
  dtTriggerRoles: Subject<any> = null;//
  dtTriggerGrupos: Subject<any> = null;//
  dtTriggerAreas: Subject<any> = null;//

  // @Input() itemsUsuarios: Array<any> = [];
  // @Input() itemsRoles: Array<any> = [];
  // @Input() itemsGrupos: Array<any> = [];
  // @Input() itemsAreasEmpresa: Array<any> = [];


  @Input() ___itemsUsuarios: Array<any> = [];

  itemsAreasEmpresa: Array<any> = [];
  itemsRoles: Array<any> = [];
  itemsGrupos: Array<any> = [];
  itemsUsuarios: Array<any> = [];
  UsuariosPermitidos = new List<any>([]);
  RolesPermitidos = new List<any>([]);
  GruposPermitidos = new List<any>([]);
  AreasEmpresaPermitidas = new List<any>([]);
  ListaPermisos = new List<any>([]);

  imagenChecked: string;
  imagenUnChecked: string;
  imagenUsuario: string;
  imagenRole: string;
  imagenGrupo: string;
  imagenArea: string;

  imagenEditar: string;
  imagen_Eliminar: string;
  imagenEliminar: string;
  imagenConsultar: string;
  imagenImportar: string;
  imagenExportar: string;
  imagenGuardar: string;
  imagenFiltrar: string;
  imagenVer: string;
  imagenVerPermisos: string;
  imagenAdminPermisos: string;
  imagenDescargar: string;
  imagenCambiarDoc: string;
  imagenCrearDoc: string;
  imagenTodos: string;
  imagenDecifrarGrid: string;
  imagenDecifrarForm: string;


  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;

  @Input() isForPermissions: boolean = false;
  @Input() setDefault: boolean = false;
  currentItem: any;
  tituloPermisos: string;
  tipoPermisos: string;
  encontradoPor = [];
  @Input() pageLength: any

  public get VerTabUsuarios(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosUsuario != true) ? true : false;
  }
  public get HabilitarTabUsuariosAcceso(): any {
    return StaticData.GeneralConfig.ConfigUI.HabilitarTabPermisosUsuariosEnAcceso;
  }
  public get VerTabRoles(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosRoles != true) ? true : false;
  }
  public get HabilitarTabRolesAcceso(): any {
    return StaticData.GeneralConfig.ConfigUI.HabilitarTabPermisosRolesEnAcceso;
  }
  public get VerTabGrupos(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosGrupos != true) ? true : false;
  }
  public get HabilitarTabGruposAcceso(): any {
    return StaticData.GeneralConfig.ConfigUI.HabilitarTabPermisosGruposEnAcceso;
  }
  public get VerTabAreaEmpresa(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosAreaEmpresa != true) ? true : false;
  }
  public get HabilitarTabAreaEmpresaAcceso(): any {
    return StaticData.GeneralConfig.ConfigUI.HabilitarTabPermisosAreaEmpresaEnAcceso;
  }
  public get PermisosUnificados(): any {
    return StaticData.GeneralConfig.ConfigUI.UnificarPermisos;
  }
  public get HeredaPermisosExpediente(): any {
    return StaticData.GeneralConfig.ConfigValidacionDocumento.HeredaPermisosExpediente
  }
  get modelo(): any {
    return this._modelo;
  }
  constructor(public http: HttpClient, public baseService: BaseServiceService, public utility: Utilities, public comunicationService: GeneralComunicationService) {
    //   this.OnInit();
  }

  //* FUNCIONES ANGULAR

  ngOnInit() {
    debugger;
    this.OnInit();
    this.OnSetConfig();
    if (this.maxWith) {
      this.estiloTablas = { 'overflow-x': 'scroll', 'overflow-y': 'hidden', 'max-width': this.maxWith + 'px' };
    }
    this.SetModelo(this.modelo);
    this.isInit = true;

    if (this.parentClearCall) {
      // console.error("AdminPermisosComponent,parentClearCall observers: "+this.parentClearCall.observers.length);
      this.parentClearCall.subscribe(event => {
        this.InitControl(event);
      });
    }
    this.comunicationService.receivedFormEvent.subscribe(event => {
      if (event.Action == FormActionsThroughEmitters.Edit || event.Action == FormActionsThroughEmitters.Clear) {
        this.InitControl(event);
        this.reloadTables();
      }
      else if (event.Action == FormActionsThroughEmitters.NewDocument && this.HeredaPermisosExpediente) {
        this.permisosHeredadosExpedientes = event.modelo.Configuracion;
        let permisosHeredados = JSON.parse(this.permisosHeredadosExpedientes)
        if (permisosHeredados.length > 0) {
          permisosHeredados.forEach(configPermisos => {
            let tipoPermiso = configPermisos.tipo
            let propValidapermiso = ""
            configPermisos.Descargar = configPermisos.Editar;
            configPermisos.Ver = configPermisos.Editar;

            switch (configPermisos.Tipo) {
              case "Usuario":
                tipoPermiso = 1
                propValidapermiso = "UsuariosPermitidos"
                break;
              case "Role":
                tipoPermiso = 2
                propValidapermiso = "RolesPermitidos"
                break;
              case "Grupo":
                tipoPermiso = 3
                propValidapermiso = "GruposPermitidos"
                break;
              case "AreaEmpresa":
                tipoPermiso = 4
                propValidapermiso = "AreasEmpresaPermitidas"
                break;
            }
            this.Adicionar(new List, configPermisos, tipoPermiso, propValidapermiso);
          })
        }
        this.reloadTables();
      }
    });

    if (this.onInitialized.observers.length) {
      // console.error("AdminPermisosComponent,onInitialized, observers: "+this.onInitialized.observers.length);
      setTimeout(() => {
        this.onInitialized.emit(this);

      }, 1000);
    }

    if (this.index) {
      this.nombreTablaUsuariosPermitidos = this.nombreTablaUsuariosPermitidos + this.index.toString();
      this.nombreTablaRolesPermitidos = this.nombreTablaRolesPermitidos + this.index.toString();
      this.nombreTablaGruposPermitidos = this.nombreTablaGruposPermitidos + this.index.toString();
      this.nombreTablaAreasEmpresaPermitidos = this.nombreTablaAreasEmpresaPermitidos + this.index.toString();
      this.nombreTabUsuarios = this.nombreTabUsuarios + this.index.toString();
      this.nombreHrefTabUsuarios = this.nombreHrefTabUsuarios + this.index.toString();
      this.nombreTabRoles = this.nombreTabRoles + this.index.toString();
      this.nombreHrefTabRoles = this.nombreHrefTabRoles + this.index.toString();
      this.nombreTabGrupos = this.nombreTabGrupos + this.index.toString();
      this.nombreHrefTabGrupos = this.nombreHrefTabGrupos + this.index.toString();
      this.nombreTabAreas = this.nombreTabAreas + this.index.toString();
      this.nombreHrefTabAreas = this.nombreHrefTabAreas + this.index.toString();
    }

  }



  public LimpiarListas() {
    this.itemsUsuarios = [];
    this.itemsRoles = [];
    this.itemsGrupos = [];
    this.itemsAreasEmpresa = [];
    this.tituloPermisos = null;
    this.tipoPermisos = '';
    this.encontradoPor = [];
  }
  public SetItemFromJson(item: any, _tituloPermisos: string, _tipoPermisos: string, itemFiltro: any, tipoFiltro: string, subTipoFiltro: string, propConfiguracion: string = 'Configuracion') {

    this.tituloPermisos = _tituloPermisos;
    this.tipoPermisos = _tipoPermisos;
    this.encontradoPor = [];
    if (item) {

      this.ListaPermisos = this.utility.GetPermisos(item, true, propConfiguracion, false, this.propConfiguracionInterna, true);
      let items = this.ListaPermisos.ToArray();
      // this.itemsUsuarios = items.filter(x => { return x.Tipo == TipoParaPermisos.Usuario || x.Tipo == 'Usuario' });
      // this.itemsRoles = items.filter(x => { return x.Tipo == TipoParaPermisos.Role || x.Tipo == 'Role' });
      // this.itemsGrupos = items.filter(x => { return x.Tipo == TipoParaPermisos.Grupo || x.Tipo == 'Grupo' });
      // this.itemsAreasEmpresa = items.filter(x => { return x.Tipo == TipoParaPermisos.AreaEmpresa || x.Tipo == 'AreaEmpresa' });




      this.SetSeleccionados(this.itemsUsuarios, TipoParaPermisos.Usuario, new List<any>([]), this.propUsuariosPermitidos);
      this.SetSeleccionados(this.itemsRoles, TipoParaPermisos.Role, new List<any>([]), this.propRolesPermitidos);
      this.SetSeleccionados(this.itemsAreasEmpresa, TipoParaPermisos.AreaEmpresa, new List<any>([]), this.propAreasEmpresaPermitidas);
      this.SetSeleccionados(this.itemsGrupos, TipoParaPermisos.Grupo, new List<any>([]), this.propGruposPermitidos);


      let dataGrupo = "";
      // if (tipoFiltro == 'Usuario') {
      if (subTipoFiltro == 'Menu' && itemFiltro.TodosPermisosMenus)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Pagina' && itemFiltro.TodosPermisosPaginas)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Expediente' && itemFiltro.TodosPermisosExpedientes)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Documento' && itemFiltro.TodosPermisosDocumentosExpedientes)
        this.encontradoPor.push("TodosPermisos");


      // if (itemFiltro.IdUsuario && this.itemsUsuarios.find(o => o.Id.toString() == itemFiltro.IdUsuario.toString()))
      //   this.encontradoPor.push("Usuario");
      // if (itemFiltro.IdRole && this.itemsRoles.find(o => o.Id.toString() == itemFiltro.IdRole.toString()))
      //   this.encontradoPor.push("Role");
      // if (itemFiltro.IdAreaEmpresa && this.itemsAreasEmpresa.find(o => o.Id.toString() == itemFiltro.IdAreaEmpresa.toString()))
      //   this.encontradoPor.push("AreaEmpresa");

      if (itemFiltro.Grupos && itemFiltro.Grupos.split('|').length > 0) {
        let grupos: Array<any> = itemFiltro.Grupos.split('|');
        for (var i = 0; i < grupos.length; i++) {
          let grupo = grupos[i].split('-');;
          if (this.itemsGrupos.find(o => o.Id.toString() == grupo[0].toString())) {

            dataGrupo += grupo[1] + "(" + grupo[0] + "),";
          }

        }

        if (dataGrupo) {
          this.encontradoPor.push("Grupos:[" + dataGrupo + "]");

        }
      }
      //}
      //this.Rerender();
    }
  }
  InitControl(event) {
    setTimeout(() => {
      this.modelo = event.modelo;
      this.SetModelo(event.modelo);
    }, 100);

  }
  ngAfterViewInit() {

    const that = this;
    
    this.dtOptionsAreasEmpresa.initComplete = () => {      
      this.validateClosefilter(this.propAreasEmpresaPermitidas);
    }
    this.dtOptionsUsuarios.initComplete = () => {      
      this.validateClosefilter(this.propUsuariosPermitidos);
    }

    this.dtOptionsRoles.initComplete = () => {
      this.validateClosefilter(this.propRolesPermitidos);      
    }

    this.dtOptionsGrupos.initComplete = () => {
      this.validateClosefilter(this.propGruposPermitidos);             
    }

    setTimeout(() => {
      var PivotElement = document.querySelector("#" + this.nameSecurity);
      new fabric['Pivot'](PivotElement);

      var PivotElement2 = document.querySelector("#" + this.nameSecurityInternal);
      new fabric['Pivot'](PivotElement2);

    }, 400);

    if (this.isForPermissions) {

      // this.dtTriggerUsuarios.next();
      // this.dtTriggerRoles.next();
      // this.dtTriggerGrupos.next();
      // this.dtTriggerAreas.next();

    }
  }

  public validateClosefilter(paramButton: string)
  {
    const that = this
      $('#' + paramButton + '_filter button').bind('click', function (e) {

        var valueImput = $('#msSearchBox_' + paramButton + ' input').val();
        if (valueImput !== '') {

          var input = this as HTMLInputElement;          
          that.utility.GetDataTabledtInstance(that.dtElements, paramButton, that.componentName).then(dtInstance => {
            dtInstance.search(input.value).draw();
            $('#msSearchBox_' + paramButton + ' > input').focus();
          }).catch(error => { that.utility.logger.LogError(error, { value: input.value, type: 'search' }) })

        }
      });
  }
  ngOnDestroy(): void {

    this.utility.Unsubscribe(this.parentClearCall);
    this.utility.Unsubscribe(this.parentCallRefresh);


    this.utility.Unsubscribe(this.dtTriggerAreas);
    this.utility.Unsubscribe(this.dtTriggerGrupos);
    this.utility.Unsubscribe(this.dtTriggerRoles);
    this.utility.Unsubscribe(this.dtTriggerUsuarios);

  }

  // Rerender(): void {

  //   let count = 1;
  //   this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {

  //     dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

  //       let name = dtInstance.name;
  //       if (name == 'UsuariosPermitidos') {
  //         //  dtInstance.destroy();
  //         //  this.dtTriggerUsuarios.next();
  //       }
  //       else if (name == 'RolesPermitidos') {
  //         //  dtInstance.destroy();
  //         // this.dtTriggerRoles.next();
  //       }
  //       else if (name == 'GruposPermitidos') {
  //         //  dtInstance.destroy();
  //         // this.dtTriggerGrupos.next();
  //       }
  //       else if (name == 'AreasPermitidos') {
  //         //  dtInstance.destroy();
  //         // this.dtTriggerAreas.next();
  //       }
  //       count++;
  //     });
  //   });
  //   // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //   //     // Destroy the table first

  //   //     dtInstance.destroy();
  //   //     // Call the dtTrigger to rerender again
  //   //     this.dtTrigger.next();
  //   // });
  // }
  //*  END FINCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS


  // public OnActionEventHandler(event) {

  //   switch (event.actionType) {
  //   }
  // }



  public GetDtOptionsTable(pageLength?: any) {
    let dtOptions: any = {
      pagingType: 'full_numbers',

      "lengthMenu": [5, 10, 25, 50, 100, 200, 300, 400, 500, 1000],
      language: this.utility.GetLanguaje(),
      serverSide: true,
      processing: true,
      responsive: false,
      scrollX: false,
    };
    dtOptions.language.sProcessing = "Procesando Registros";
    if (pageLength)
      dtOptions.pageLength = pageLength;
    return dtOptions;
  }


  public GetApiModel() {
    let obj = this.utility.GetApiModelGrillaValidation('_CargarGrilla', 'TExpediente', null, null, null, null, null, "");
    obj.AppKey = StaticData.AppKey;
    obj.AppName = StaticData.AppName;
    obj.Token = StaticData.Usuario.Token;

    return obj;
  }

  public GetQueryConfig(spname: any) {

    let QueryConfig = {
      Command: spname,
      Entity: {
        IdEmpresa: StaticData.Usuario.IdEmpresa
      },
      IsSp: true,
      OutPutParam: true,
      WithPagination: true
    }
    return QueryConfig;
  }
  public OnInit() {

    this.columns = [
      { data: '' },
    ];
    // if (!this.isForPermissions)
    //this.utility.SetItemsAreasRolesUsuarioSeries(this, true, true);
    // else {
    // }
    this.dtOptionsUsuarios = this.GetDtOptionsTable(this.pageLength);
    this.dtOptionsRoles = this.GetDtOptionsTable(this.pageLength);
    this.dtOptionsGrupos = this.GetDtOptionsTable(this.pageLength);
    this.dtOptionsAreasEmpresa = this.GetDtOptionsTable(this.pageLength);
    let url = 'TExpediente'
    let urlServer = this.utility.GetUrlServer(url);
    let objUsuarios = this.GetApiModel()
    let objRoles = this.GetApiModel()
    let objGrupos = this.GetApiModel()
    let objAreasEmpresa = this.GetApiModel()
    let QueryConfigAreaEmpresa = this.GetQueryConfig("BusquedaAreaEmpresaPermisos");
    let QueryConfigUsuarios = this.GetQueryConfig("BusquedaUsuarioPermisos");
    let QueryConfigGrupos = this.GetQueryConfig("BusquedaGrupoPermisos");
    let QueryConfigRoles = this.GetQueryConfig("BusquedaRolePermisos");

    objAreasEmpresa.QueryConfig = this.utility.Clone(QueryConfigAreaEmpresa);
    this.dtOptionsAreasEmpresa.ajax = this.AjaxDataTable(urlServer, this.name, this.componentName, objAreasEmpresa,
      null, TipoParaPermisos.AreaEmpresa);
    objUsuarios.QueryConfig = this.utility.Clone(QueryConfigUsuarios);
    this.dtOptionsUsuarios.ajax = this.AjaxDataTable(urlServer, this.name, this.componentName, objUsuarios,
      null, TipoParaPermisos.Usuario);
    objRoles.QueryConfig = this.utility.Clone(QueryConfigRoles);
    this.dtOptionsRoles.ajax = this.AjaxDataTable(urlServer, this.name, this.componentName, objRoles,
      null, TipoParaPermisos.Role);
    objGrupos.QueryConfig = this.utility.Clone(QueryConfigGrupos);
    this.dtOptionsGrupos.ajax = this.AjaxDataTable(urlServer, this.name, this.componentName, objGrupos,
      null, TipoParaPermisos.Grupo);
    this.imagenChecked = 'ms-Icon--CompletedSolid';
    this.imagenUnChecked = 'ms-Icon--ChromeClose';
    this.imagenUsuario = 'ms-Icon--FabricUserFolder';
    this.imagenRole = 'ms-Icon--UserFollowed';
    this.imagenGrupo = 'ms-Icon--Group';
    this.imagenArea = 'ms-Icon--Org';
    this.imagenEditar = 'ms-Icon--PageEdit';
    this.imagen_Eliminar = 'ms-Icon--Delete';
    this.imagenEliminar = 'ms-Icon--Delete';
    this.imagenConsultar = 'ms-Icon--SearchData';
    this.imagenImportar = 'ms-Icon--CloudImportExport';
    this.imagenExportar = 'ms-Icon--Upload';
    this.imagenGuardar = 'ms-Icon--Save';
    this.imagenFiltrar = 'ms-Icon--Filter';
    this.imagenVer = 'ms-Icon--RedEye';
    this.imagenVerPermisos = 'ms-Icon--Permissions';
    this.imagenAdminPermisos = 'ms-Icon--Permissions';
    this.imagenDescargar = 'ms-Icon--Download';
    this.imagenEditorJson = 'ms-Icon--CodeEdit';
    this.imagenAEditorAce = 'ms-Icon--EditSolidMirrored12';
    this.imagenCambiarDoc = 'ms-Icon--ChangeEntitlements';
    this.imagenCrearDoc = 'ms-Icon--PageAdd';
    this.imagenTodos = 'ms-Icon--PageAdd';
    this.imagenDecifrarForm = 'ms-Icon--Encryption';
    this.imagenDecifrarGrid = 'ms-Icon--Encryption';
  }


  public SetPermisosModel(_Configuracion: any, data: any, tipoPermiso: any) {
    var tipoPermisoStr = TipoParaPermisos[tipoPermiso];
    _Configuracion.forEach(configModel => {
      data.forEach(function (configTable, i) {
        // if (configModel.Id == configTable.Id && configModel.Tipo == tipoPermiso)
        if (configModel.Id == configTable.Id && (configModel.Tipo == tipoPermiso || configModel.Tipo == tipoPermisoStr)) {
          if (configModel.Tipo == "Usuario") {
            configModel.AreaEmpresa = configTable.AreaEmpresa;
            configModel.Role = configTable.Role;
          }
          data[i] = configModel;
          data[i].Seleccionado = true;
        }
      }
      )
    });
    return data
  }

  public EndNotify(notify: any) {
    if (notify) {

      notify.close();
      setTimeout(() => {
        notify.close();
      }, 200);

    }
  }

  public AjaxDataTable(urlServer: string, dttName: string, componentName: string, obj: any, _contex: any, _tipoPermiso: any) {
    const that = this;
    const isHtmlDataTable = false;
    const startProcess = new Date().getTime();
    const stopwatch = new Stopwatch();
    stopwatch.start();
    let notify: any = null;
    let ajax = (dataTablesParameters: any, callback) => {
      obj = obj;
      obj.PaginationSettingsModel = dataTablesParameters;
      obj.PaginationSettingsModel.notLoad = false;
      obj.PaginationSettingsModel.StartIndexColumn = 0;
      obj.PaginationSettingsModel.ConfigColumns = dataTablesParameters.columns;
      obj.PaginationSettingsModel.DataTableAjaxOrderType = 0;

      that.http
        .post<DataTablesResponse>(
          urlServer,
          obj, {}
        ).subscribe(resp => {
          const endProcess = new Date().getTime();
          stopwatch.stop();
          that.EndNotify(notify);
          var _Configuracion: any;

          if (resp.data) {
            if (this._modelo && this._modelo.modelo)
              if (this._modelo.modelo.ConfiguracionPermisos)
                _Configuracion = JSON.parse(this._modelo.modelo.ConfiguracionPermisos)
              else if (this._modelo.modelo.Configuracion)
                _Configuracion = JSON.parse(this._modelo.modelo.Configuracion)
            if (_Configuracion && resp.data.length > 0 && ((_Configuracion.length && _Configuracion.length > 0) || (_Configuracion.Permisos && _Configuracion.Permisos.length > 0))) {
              if (_Configuracion.Permisos && _Configuracion.Permisos.length > 0)
                _Configuracion = _Configuracion.Permisos;
              resp.data = this.SetPermisosModel(_Configuracion, resp.data, _tipoPermiso);
            }
            if (_tipoPermiso == TipoParaPermisos.AreaEmpresa)
              this.itemsAreasEmpresa = resp.data
            else if (_tipoPermiso == TipoParaPermisos.Usuario)
              this.itemsUsuarios = resp.data
            else if (_tipoPermiso == TipoParaPermisos.Role)
              this.itemsRoles = resp.data
            else if (_tipoPermiso == TipoParaPermisos.Grupo)
              this.itemsGrupos = resp.data
          }
          callback({
            recordsTotal: (resp.recordsTotal) ? resp.recordsTotal : 0,
            recordsFiltered: (resp.recordsFiltered) ? resp.recordsFiltered : 0,
            data: []
          });
          $('.dataTables_empty').css('display', 'none');

        }, error => {
          that.EndNotify(notify);
          var endProcess = new Date().getTime();
          stopwatch.stop();
          console.log('AjaxDataTables Tiempo: ', (endProcess - startProcess));
        });
    }

    return ajax;
  }

  public OnInitEnd() {
    let count = 1;
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {

      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

        let name = dtInstance.name;
        if (name == 'UsuariosPermitidos') {
          //  dtInstance.destroy();
          //  this.dtTriggerUsuarios.next();
        }
        else if (name == 'RolesPermitidos') {
          //  dtInstance.destroy();
          // this.dtTriggerRoles.next();
        }
        else if (name == 'GruposPermitidos') {
          //  dtInstance.destroy();
          // this.dtTriggerGrupos.next();
        }
        else if (name == 'AreasPermitidos') {
          //  dtInstance.destroy();
          // this.dtTriggerAreas.next();
        }
        count++;
      });
    });

  }
  public OnSetConfig() {
    this.nameSecurity = "msPivotAdminPermisos" + "_" + Guid.create().toString();
    this.nameSecurityInternal = "msPivotAdminPermisosInternal" + "_" + Guid.create().toString();
    this.tituloUsuarios = "Usuarios";
    this.tituloRoles = "Roles";
    this.tituloAreasEmpresa = "Áreas";
    this.tituloGrupos = "Grupos";
    // if (this.setDefault) {
    //   this.tituloUsuarios = "Usuarios";
    //   this.tituloRoles = "Roles";
    //   this.tituloAreasEmpresa = "Areas";
    //   this.tituloGrupos = "Grupos";
    // }
    // else
    if (this.config) {
      this.propConfiguracionInterna = this.config.ConfigInternalProp || this.propConfiguracionInterna;
      this.propConfiguracion = this.config.ConfigProp || this.propConfiguracion;
      this.tituloUsuarios = this.config.UsersTitle || this.tituloUsuarios;
      this.tituloRoles = this.config.RolesTitle || this.tituloRoles;
      this.tituloAreasEmpresa = this.config.AreasTitle || this.tituloAreasEmpresa;
      this.tituloGrupos = this.config.GroupsTitle || this.tituloGrupos;
      this.conCamposPermisos = (this.config.WithPermisionsField !== null) ? this.config.WithPermisionsField : this.conCamposPermisos;
      this.maxWith = this.config.MaxWith || this.maxWith;
      if (this.config.IsImport)
        this.esImportar = this.config.IsImport;
      if (this.config.IsDoc)
        this.esDocumento = true;// (this.config.IsDoc !== null) ? this.config.IsDoc : this.esDocumento;
      if (this.config.NotIsPage == false)
        this.noEsPagina = false;// (this.config.NotIsPage !== null) ? this.config.NotIsPage : this.noEsPagina;
      if (this.config.IsSolicitud == true){
        this.noEsPagina = true;
        this.esExpediente =  this.config.IsExpe;
        this.esSolicitud = this.config.IsSolicitud;
      }
      if (this.config.IsExpe)
        this.esExpediente = this.config.IsExpe;
      this.propUsuariosPermitidos = this.config.UsersProp || this.propUsuariosPermitidos;
      this.propRolesPermitidos = this.config.RolesProp || this.propRolesPermitidos;
      this.propAreasEmpresaPermitidas = this.config.AreasProp || this.propAreasEmpresaPermitidas;
      this.propGruposPermitidos = this.config.GroupsProp || this.propGruposPermitidos;
      this.name = this.config.Name || this.name;



      /*   @Input() propUsuariosPermitidos: string = 'UsuariosPermitidos';
        @Input() propRolesPermitidos: string = 'RolesPermitidos';
        @Input() propAreasEmpresaPermitidas: string = 'AreasEmpresaPermitidas';
        @Input() propGruposPermitidos: string = 'GruposPermitidos'; */

      let _config = {
        "ConfigInternalProp": "",
        "ConfigProp": "Configuracion",
        "UsersTitle": "",
        "RolesTitle": "",
        "AreasTitle": "",
        "GroupsTitle": "",
        "UsersProp": "UsuariosPermitidos",
        "RolesProp": "RolesPermitidos",
        "AreasProp": "AreasEmpresaPermitidas",
        "GroupsProp": "GruposPermitidos",
        "WithPermisionsField": true,
        "NotIsPage": true,
        "IsDoc": false,
        "MaxWith": 900

      };
    }
  }

  public SetModelo(modelo: any, force?: any) {
    if (force == undefined)
      force = true
    this._modelo = modelo;


    if (this.isForPermissions) {
      return;

    }
    if (this.modelo) {
      if (this.isModelContainer) {
        if (this.modelo.modelo && this.modelo.modelo.ConfiguracionPermisos)
          this.propConfiguracion = 'ConfiguracionPermisos'
        this.ListaPermisos = this.utility.GetPermisos(this.modelo.modelo, true, this.propConfiguracion, false, this.propConfiguracionInterna, force);
        if (this.conCamposPermisos == true) {
          this.UsuariosPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propUsuariosPermitidos);
          this.RolesPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propRolesPermitidos);
          this.AreasEmpresaPermitidas = this.utility.GetPermitidos(this.modelo.modelo, true, this.propAreasEmpresaPermitidas);
          this.GruposPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propGruposPermitidos);
        }
      }
      else {
        this.ListaPermisos = this.utility.GetPermisos(this.modelo, true, this.propConfiguracion, false, this.propConfiguracionInterna, true);
        if (this.conCamposPermisos == true) {
          this.UsuariosPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propUsuariosPermitidos);
          this.RolesPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propRolesPermitidos);
          this.AreasEmpresaPermitidas = this.utility.GetPermitidos(this.modelo, true, this.propAreasEmpresaPermitidas);
          this.GruposPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propGruposPermitidos);
        }
      }
    }
    else {
      this.ListaPermisos = new List<any>([]);
      this.UsuariosPermitidos = new List<any>([]);
      this.RolesPermitidos = new List<any>([]);
      this.AreasEmpresaPermitidas = new List<any>([]);
      this.GruposPermitidos = new List<any>([]);
    }

    this.SetSeleccionados(this.itemsUsuarios, TipoParaPermisos.Usuario, this.UsuariosPermitidos, this.propUsuariosPermitidos);
    this.SetSeleccionados(this.itemsRoles, TipoParaPermisos.Role, this.RolesPermitidos, this.propRolesPermitidos);
    this.SetSeleccionados(this.itemsAreasEmpresa, TipoParaPermisos.AreaEmpresa, this.AreasEmpresaPermitidas, this.propAreasEmpresaPermitidas);
    this.SetSeleccionados(this.itemsGrupos, TipoParaPermisos.Grupo, this.GruposPermitidos, this.propGruposPermitidos);
    this.SetConfiguracion();

  }

  //! NO HACE NADA
  public ValidarIndefinido(permiso: any, prop: string) {
    //if (permiso[prop] === undefined)
    // permiso[prop] = false
  }

  public SetSeleccionados(items: Array<any>, tipoParaPermisos: TipoParaPermisos, permitidos: List<any>, prop: string) {
    for (let i = 0; i < items.length; i++) {
      let stringType = TipoParaPermisos[tipoParaPermisos];
      let permiso = this.ListaPermisos.FirstOrDefault(x => {
        return x.Id == items[i].Id &&
          (x.Tipo == tipoParaPermisos || x.Tipo == stringType)
      });
      if (permiso) {

        items[i].Seleccionado = true;
        this.utility.SetPermisosItemActual(items[i], permiso, this.noEsPagina);

        if (!permitidos.Any(x => { return x == 'code' + items[i].Id })) {
          permitidos.Add('code' + items[i].Id);
        }
      }
      else {
        items[i].Seleccionado = false;
        this.utility.SetValorPermisosItemActual(items[i], false, false, this.noEsPagina);

        if (permitidos.Any(x => { return x == 'code' + items[i].Id })) {
          permitidos = permitidos.RemoveAll(x => { return x == 'code' + items[i].Id });
        }
      }
      //permitidos[i].Seleccionado = this.ListaPermisos.Any(x => { return x.Tipo == tipoParaPermisos });
    }

    this.SetPermitidos(permitidos, prop);

  }

  public Adicionar(permitidos: List<any>, item: any, tipoParaPermisos: TipoParaPermisos, prop: string) {
    if (!this.guardarPermitido)
      return;
    if (this.Disabled)
      return;
    if(this.config && this.config.IsSolicitud == true)
      item.Firmar = true

    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = true;
    item.Consultar = true;
    let stringType = TipoParaPermisos[tipoParaPermisos];
    if (!this.ListaPermisos.Any(x => {
      return x.Id == item.Id &&
        (x.Tipo == tipoParaPermisos || x.Tipo == stringType)
    })) {
      this.ListaPermisos.Add(item);
    }
    if (this.conCamposPermisos == true) {
      if (!permitidos.Any(x => { return x == 'code' + item.Id })) {
        permitidos.Add('code' + item.Id);
      }
    }

    this.SetConfiguracion();
    this.SetPermitidos(permitidos, prop);
    this.FireAction('Add');

  }

  public EliminarItems(permitidos: List<any>, item: any, tipoParaPermisos: TipoParaPermisos, prop: string) {

    if (!this.guardarPermitido)
      return;
    if (this.Disabled)
      return;
    let stringType = TipoParaPermisos[tipoParaPermisos];
    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = false;
    item.Consultar = false;
    this.SetInitValuesPermiso(item, false, true);
    this.ListaPermisos = this.ListaPermisos.RemoveAll(x => {
      return x.Id == item.Id &&
        (x.Tipo == tipoParaPermisos || x.Tipo == stringType)
    });

    if (this.conCamposPermisos == true)
      permitidos = permitidos.RemoveAll(x => { return x == 'code' + item.Id });

    this.SetConfiguracion();
    this.SetPermitidos(permitidos, prop);
    this.FireAction('Delete');

  }

  public SetConfiguracion() {

    if (this.modelo) {
      /*  if (this.propConfiguracionInterna) {
         let temp;
         if (typeof this.modelo[this.propConfiguracion] === 'string') {
           temp = (!this.modelo[this.propConfiguracion]) ? {} : JSON.parse(this.modelo[this.propConfiguracion]);
         }
         else {
           temp = (!this.modelo[this.propConfiguracion]) ? {} : this.modelo[this.propConfiguracion];
         }
         temp[this.propConfiguracionInterna] = this.ListaPermisos.ToArray();
         this.modelo[this.propConfiguracion] = JSON.stringify(temp);
       }
       else {
         this.modelo[this.propConfiguracion] = JSON.stringify(this.ListaPermisos.ToArray());
       }
       this.modelo.ListaPermisos = this.ListaPermisos; */
      if (this.isModelContainer)
        this._SetConfiguracion(this.modelo.modelo);
      else
        this._SetConfiguracion(this.modelo);
    }

  }

  public SetPermitidos(permitidos: List<any>, prop: string) {

    if (this.isModelContainer)
      this._SetPermitidos(this.modelo.modelo, permitidos, prop);
    else
      this._SetPermitidos(this.modelo, permitidos, prop);

  }

  public Limpiar(callback: (data: any) => void = null) {

    if (this.Disabled)
      return;
    if (this.isModelContainer)
      this._Limpiar(this.modelo.modelo, callback);
    else
      this._Limpiar(this.modelo, callback);

  }

  public AsignarPermiso(permitidos: List<any>, item: any, prop: string, permiso: string, tipoParaPermisos: TipoParaPermisos) {
    if (!this.guardarPermitido)
      return;

    let stringType = TipoParaPermisos[tipoParaPermisos];
    let _permiso = this.ListaPermisos.FirstOrDefault(x => { return x.Id == item.Id && (x.Tipo == tipoParaPermisos || x.Tipo == stringType) });

    if (item[permiso] == true) {
      item[permiso] = !item[permiso];
      if (_permiso && _permiso[permiso] !== item[permiso])
        _permiso[permiso] = item[permiso];
      this.SetConfiguracion();
      this.SetPermitidos(permitidos, prop);
    }
    else {
      item[permiso] = !item[permiso];
      if (_permiso && _permiso[permiso] !== item[permiso])
        _permiso[permiso] = item[permiso];
      this.Adicionar(permitidos, item, tipoParaPermisos, prop);
    }

    this.FireAction('Set');

  }

  public AsignarPermisoUsuario(item: any, permiso: string) {
    if (permiso == 'Editar' && this.PermisosUnificados) {
      this.AsignarPermisoUnificado(item, TipoParaPermisos.Usuario);
    }
    else {
      this.AsignarPermiso(this.UsuariosPermitidos, item, this.propUsuariosPermitidos, permiso, TipoParaPermisos.Usuario);
    }
    //this.dtTriggerUsuarios.next();

  }

  public AsignarPermisoUnificado(item: any, Tipo: TipoParaPermisos) {
    item.Guardar = item.Editar    
    if (Tipo == TipoParaPermisos.Usuario) {
      this.AsignarPermiso(this.UsuariosPermitidos, item, this.propUsuariosPermitidos, 'Editar', Tipo);
      this.AsignarPermiso(this.UsuariosPermitidos, item, this.propUsuariosPermitidos, 'Guardar', Tipo);
      if (this.esExpediente) {
        this.AsignarPermiso(this.UsuariosPermitidos, item, this.propUsuariosPermitidos, 'Eliminar', Tipo);
        this.AsignarPermiso(this.UsuariosPermitidos, item, this.propUsuariosPermitidos, 'CrearDoc', Tipo);
      }
    }
    else if (Tipo == TipoParaPermisos.AreaEmpresa) {
      this.AsignarPermiso(this.AreasEmpresaPermitidas, item, this.propAreasEmpresaPermitidas, 'Editar', Tipo);
      this.AsignarPermiso(this.AreasEmpresaPermitidas, item, this.propAreasEmpresaPermitidas, 'Guardar', Tipo);
      if (this.esExpediente) {
        this.AsignarPermiso(this.AreasEmpresaPermitidas, item, this.propAreasEmpresaPermitidas, 'Eliminar', Tipo);
        this.AsignarPermiso(this.AreasEmpresaPermitidas, item, this.propAreasEmpresaPermitidas, 'CrearDoc', Tipo);
      }
    }
    else if (Tipo == TipoParaPermisos.Role) {
      this.AsignarPermiso(this.RolesPermitidos, item, this.propRolesPermitidos, 'Editar', Tipo);
      this.AsignarPermiso(this.RolesPermitidos, item, this.propRolesPermitidos, 'Guardar', Tipo);
      if (this.esExpediente) {
        this.AsignarPermiso(this.RolesPermitidos, item, this.propRolesPermitidos, 'Eliminar', Tipo);
        this.AsignarPermiso(this.RolesPermitidos, item, this.propRolesPermitidos, 'CrearDoc', Tipo);
      }
    }
  }

  public SeleccionarUsuario(item: any) {
    if (item.Seleccionado == true) {
      this.EliminarItems(this.UsuariosPermitidos, item, TipoParaPermisos.Usuario, this.propUsuariosPermitidos);


    }
    else {
      this.Adicionar(this.UsuariosPermitidos, item, TipoParaPermisos.Usuario, this.propUsuariosPermitidos);
    }
    // this.AsignarPermisoUsuario(item, 'Exportar');
    // this.AsignarPermisoUsuario(item, '__Eliminar');
    // this.AsignarPermisoUsuario(item, 'Editar');
    // this.AsignarPermisoUsuario(item, 'Guardar');
    // this.AsignarPermisoUsuario(item, 'Importar');
    // this.AsignarPermisoUsuario(item, 'DecifrarGrid');
    // this.AsignarPermisoUsuario(item, 'DecifrarForm');
    // if (this.esDocumento) {
    //   this.AsignarPermisoUsuario(item, 'Ver');
    //   this.AsignarPermisoUsuario(item, 'Descargar');
    //   this.AsignarPermisoUsuario(item, 'CambiarDoc');
    // }
    // if(this.noEsPagina){
    //   this.AsignarPermisoUsuario(item, 'AdminPermisos');

    // }
    // if (this.esExpediente) {
    //   this.AsignarPermisoUsuario(item, 'CrearDoc');
    //   this.AsignarPermisoUsuario(item, 'Firmar');

    // }
  }

  public AsignarPermisoRole(item: any, permiso: string) {
    if (permiso == 'Editar' && this.PermisosUnificados) {
      this.AsignarPermisoUnificado(item, TipoParaPermisos.Role);
    }
    else {
      this.AsignarPermiso(this.RolesPermitidos, item, this.propRolesPermitidos, permiso, TipoParaPermisos.Role);
    }
    //this.AsignarPermiso(this.RolesPermitidos, item, this.propRolesPermitidos, permiso, TipoParaPermisos.Role);
    //this.dtTriggerRoles.next();

  }

  public SeleccionarRole(item: any) {
    if (item.Seleccionado == true) {
      this.EliminarItems(this.RolesPermitidos, item, TipoParaPermisos.Role, 'RolesPermitidos');
    }
    else {
      this.Adicionar(this.RolesPermitidos, item, TipoParaPermisos.Role, 'RolesPermitidos');
    }

  }

  public AsignarPermisoGrupo(item: any, permiso: string) {

    this.AsignarPermiso(this.GruposPermitidos, item, this.propGruposPermitidos, permiso, TipoParaPermisos.Grupo);
    //this.dtTriggerGrupos.next();

  }

  public SeleccionarGrupo(item: any) {

    if (item.Seleccionado == true) {
      this.EliminarItems(this.GruposPermitidos, item, TipoParaPermisos.Grupo, 'GruposPermitidos');
    }
    else {
      this.Adicionar(this.GruposPermitidos, item, TipoParaPermisos.Grupo, 'GruposPermitidos');
    }

  }

  public AsignarPermisoArea(item: any, permiso: string) {

    if (permiso == 'Editar' && this.PermisosUnificados) {
      this.AsignarPermisoUnificado(item, TipoParaPermisos.AreaEmpresa);
    }
    else {
      this.AsignarPermiso(this.AreasEmpresaPermitidas, item, this.propAreasEmpresaPermitidas, permiso, TipoParaPermisos.AreaEmpresa);
    }
    //this.dtTriggerAreas.next();

  }

  public SeleccionarArea(item: any) {

    if (item.Seleccionado == true) {
      this.EliminarItems(this.AreasEmpresaPermitidas, item, TipoParaPermisos.AreaEmpresa, this.propAreasEmpresaPermitidas);
    }
    else {
      this.Adicionar(this.AreasEmpresaPermitidas, item, TipoParaPermisos.AreaEmpresa, this.propAreasEmpresaPermitidas);
    }

  }

  public VerEditor(tipo: any) {

    this.utility.VerEditor(tipo, this.modelo.ListaPermisos, false, "Editor de [Permisos]").then(data => {
      if (data.dialogResult == true)
        this.SetConfiguracion();
    });

  }

  FireAction(actionType: string) {
    this.onActionEventEmitter.emit({
      modelo: this.modelo,
      type: this.name,
      componentName: this.componentName,
      actionType: actionType,
    });

  }

  VerAlertaPermisosGuardar() {

    StaticData.ShowNotification('No Posee permisos para esta funcionalidad', 'danger');

  }

  _Limpiar(modelo: any, callback: (data: any) => void = null) {

    modelo = {};
    modelo.PkValue = 0;
    modelo.UsuariosPermitidos = null;
    modelo.RolesPermitidos = null;
    modelo.AreasEmpresaPermitidas = null;
    modelo.GruposPermitidos = null;
    modelo.Configuracion = null;
    if (callback) callback(modelo);

  }

  _SetConfiguracion(modelo: any) {
    if (modelo.ConfiguracionPermisos)
      this.propConfiguracion = 'ConfiguracionPermisos'
    if (this.propConfiguracionInterna) {
      let temp;
      if (typeof modelo[this.propConfiguracion] === 'string') {
        temp = (!modelo[this.propConfiguracion]) ? {} : JSON.parse(modelo[this.propConfiguracion]);
      }
      else {
        temp = (!modelo[this.propConfiguracion]) ? {} : modelo[this.propConfiguracion];
      }
      temp[this.propConfiguracionInterna] = this.ListaPermisos.ToArray();
      modelo[this.propConfiguracion] = JSON.stringify(temp);
    }
    else {
      modelo[this.propConfiguracion] = JSON.stringify(this.ListaPermisos.ToArray());
    }
    modelo.ListaPermisos = this.ListaPermisos;

    //this.modelo.DataAdd = { Configuracion: JSON.stringify(this.ListaPermisos.ToArray()) };

  }

  reloadTables() {

    $('#AreasPermitidas').DataTable().ajax.reload();
    $('#GruposPermitidos').DataTable().ajax.reload();
    $('#RolesPermitidos').DataTable().ajax.reload();
    $('#UsuariosPermitidos').DataTable().ajax.reload();
  }

  _SetPermitidos(modelo: any, permitidos: List<any>, prop: string) {

    if (modelo && this.conCamposPermisos)
      modelo[prop] = permitidos.ToArray().join('|');// '|' + permitidos.ToArray().join('|');

  }
  currentItemNombre: string;
  OpenAdd(open: any, item: any) {

    if (!this.noEsPagina)
      return;
    const container = <any>$('.ap__add-container');
    const userTitle = <any>$('.ap__add-user');
    if (open) {
      if (!item)
        return;
      if (item.Seleccionado) {
        this.SetInitValuesPermiso(item, false)

        this.currentItem = item;
        container.css('display', 'initial');
        userTitle.html(item.Nombre);
      }
    } else {
      container.css('display', 'none');
      this.currentItem = null;
    }
  }

  SetInitValuesPermiso(item, value: boolean, clear: boolean = false) {
    if (!item.Heredar || item.Heredar.length == 0) {
      item.Heredar = [{ Nombre: '_Eliminar', Valor: value },
      { Nombre: 'Editar', Valor: value },
      { Nombre: 'Guardar', Valor: value }];
    }

    if (!item.HeredarExpediente || item.HeredarExpediente.length == 0) {
      item.HeredarExpediente = [
        { Nombre: '_Eliminar', Valor: value },
        { Nombre: 'Editar', Valor: value },
        { Nombre: 'Guardar', Valor: value },
        { Nombre: 'Descargar', Valor: value },
        { Nombre: 'Ver', Valor: value },
        { Nombre: 'CambiarDoc', Valor: value }];

    }

    if (clear) {
      item.Heredar.forEach(x => {
        x.Valor = value;
      });

      item.HeredarExpediente.forEach(x => {
        x.Valor = value;
      });
    }
    if (!item.Custom || item.Custom.length == 0)
      item.Custom = [];
  }
  AddNewCustomPermision() {
    if (this.currentItem) {
      if (!this.currentItem.Custom.find(x => { return x.Nombre == this.currentItemNombre })) {
        this.currentItem.Custom.push({ Nombre: this.currentItemNombre, Valor: true });
        this.currentItemNombre = null;
      }
    }
  }
  DeleteCustomPermision(items, index) {
    items.splice(index, 1);
  }
  //* END FUNCIONES PERSONALIZADAS

}
