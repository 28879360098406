import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlActions, FormActionsThroughEmitters, GeneralPageControlTypes } from 'src/app/models/general.enum';
import { BaseServiceService } from '../../../services/base-service.service'
import { FormGroup } from '@angular/forms';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { Guid } from 'guid-typescript';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { SolicitudCommonComponent } from '../../bussines/solicitudes/common/solicitud-common.component';
import { Utilities } from '../../../helpers/utilities';
import { DynamicControlsMetadataComponent } from '../../controls/dynamic-controls-metadata/dynamic-controls-metadata.component';
import { StaticData } from 'src/app/helpers/static-data';

@Component({
  selector: 'app-prestamo-documental',
  templateUrl: './prestamo-documental.component.html',
  styleUrls: ['./prestamo-documental.component.scss']
})
export class PrestamoDocumentalComponent extends GeneralFormComponent implements OnInit, AfterViewInit {
  isSearch: boolean = null;
  filtroGeneral = "";
  DynamicMetadata: DynamicControlsMetadataComponent;

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules, public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);
  }

  requeridosControlesAplazar: boolean = false;
  TipoPrestamo: any;
  GridUnidadConservacion: any;

  public OnInitEnd() {
    this.TipoPrestamo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "TipoPrestamo" });
    this.GridUnidadConservacion = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "GridUnidadConservacion" });
    this.postSaveEmitter.subscribe((modelo) => {
      //debugger;
      if(modelo.modelo.WorkFlowTaskModel.Aprobado == true)
        this.ValidarPermisoPrestamoDocumental(modelo.modelo.WorkFlowTaskModel.IdFlujoPrestamo_1);
    });

    super.OnInitEnd();
  }

  public OnActionEventHandler(event) {
    if (event.Action) {
      if (event.Action == "ClearModel") {
        if (event.Table) {
          if (event.Table == "DataTables_TExpedienteParaPrestamo") {
            super.Limpiar();
            super.ClearModel();
            super.OnActionEventHandler(event);
          }
        }
      }
    }

    switch (event.ControlType) {
      case GeneralPageControlTypes.CommandButton: {
        this.validateRequiredControls(["UnidadRequerido"],"false");
        if (event.Option.Type == 'Custom' || event.Option.Type == 'Filter') {
          this.configs.ConfigUnidadConservacion.Context.items = [];
          if(!this.modelo.WorkFlowTaskModel["GridUnidadConservacion"]){
            this.modelo.WorkFlowTaskModel["GridUnidadConservacion"] = new Array();
          }
          switch(event.Option.Name){
          case 'SolicitarPrestamo':{
            this.modelo.WorkFlowTaskModel["GridUnidadConservacion"].length = 0;
              if(this.modelo.WorkFlowTaskModel.ReferenciaUbicacionFisica == 'No'){
                this.modelo.WorkFlowTaskModel.TipoPrestamo = 49;
              }else{
                this.modelo.WorkFlowTaskModel.TipoPrestamo = 48;
              }
            this.ConsultaUbicacionFisica();
            break;
            }
            case 'Aprobar':{
              this.ConsultaUbicacionFisica();
              break;
            }
          }
            this.SetModelToControls();
            super.OnActionEventHandler(event);
        }

        break;
      }
      case GeneralPageControlTypes.Button:
        {
          switch (event.Name) {
            case 'btnBuscar': {
              this.Buscar(event.Name);
              break;
            }
            case 'BtnLimpiar': {
              this.controls.ForEach(control => {
                if (control.control && control.control.ModelContainer == "modeloVirtual")
                  this.modelo.modeloVirtual[control.control.Name] = null;
              }
              );
              this.modelo.modeloVirtual.Filter = "";
              this.modelo.modeloVirtual.FiltroGeneral = "";
              this.modelo.modeloVirtual.IdAreaEmpresa = StaticData.Usuario.IdAreaEmpresa;
              this.filtroGeneral = "";
              this.SetModelToControls();
              this.Buscar(event.Name);
              break;
            }
            case 'BtnLimpiarFormulario': {      
              this.modelo.WorkFlowTaskModel.UnidadConservacion =null;
              this.modelo.WorkFlowTaskModel.TipoPrestamo =null;
              this.modelo.WorkFlowTaskModel.FechaInicioPrestamo =null;
              this.modelo.WorkFlowTaskModel.FechaFinPrestamo =null;
              this.modelo.WorkFlowTaskModel.ObservacionesPrestamo =null;
              this.SetModelToControls();
              break;
            }

          }
          break;
        }
    }
  }


  IsNullableOp(opName) {
    return (opName == 'IsNull' || opName == 'IsNotNull' || opName == 'IsUndefined' || opName == 'IsNotUndefined'
      || opName == 'IsEmpty' || opName == 'IsNotEmpty');

  }

  GetOp(opName) {
    if (opName == 'Equal')
      return "=";
    else if (opName == 'NotEqual')
      return "<>";
    else if (opName == 'GreaterThan')
      return ">";
    else if (opName == 'LessThan')
      return "<"
    else if (opName == 'GreaterOrEqual')
      return ">=";
    else if (opName == 'LessOrEqual')
      return "<="
  }
  GetOpTexto(opName) {
    if (opName == 'Equal')
      return "ES IGUAL";
    else if (opName == 'NotEqual')
      return "NO ES IGUAL";
    else if (opName == 'GreaterThan')
      return "MAYOR QUE";
    else if (opName == 'LessThan')
      return "MENOR QUE"
    else if (opName == 'GreaterOrEqual')
      return "MAYOR IGUAL QUE";
    else if (opName == 'LessOrEqual')
      return "MENOR IGUAL QUE"
  }

  public GetFiltro(control) {
    let filtro = "";
    let filtroGeneral = "";

    var datatemp = '';
    var dateArr = [];
    if (control.Op == "In" || control.Op == "Range") {

      switch (control.DataType) {
        case 'String':
          control.Value.forEach(_value => {
            dateArr.push("'" + _value + "'");
          });

          datatemp = dateArr.join(',');
          filtro += control.SearcheableFieldName + " IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + datatemp + ")";
          break
        case 'DateTime':
          control.Value.forEach(_value => {
            dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
          });

          datatemp = dateArr.join(',');
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + " IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + datatemp + ")";
          //str += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.htmlEntities(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          break;

        default:
          filtro += control.SearcheableFieldName + " IN  (" + control.Value + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + control.Value + ")";
          break


      }

    }
    else if (control.Op == "NotIn") {

      switch (control.DataType) {
        case 'String':
          control.Value.forEach(_value => {
            dateArr.push("'" + _value + "'");
          });

          datatemp = dateArr.join(',');
          filtro += control.SearcheableFieldName + " NOT IN   (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + datatemp + ")";
          break
        case 'DateTime':
          control.Value.forEach(_value => {
            dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
          });

          datatemp = dateArr.join(',');
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + " NOT IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + datatemp + ")";
          //str += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.htmlEntities(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          break;

        default:
          filtro += control.SearcheableFieldName + "NOT IN  (" + control.Value + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + control.Value + ")";
          break


      }
      //str += control.SearcheableFieldName + " NOT IN (" + control.Value + ")";
    }
    else if (control.Op == 'ContainsText') {
      filtro += " Contains(" + control.SearcheableFieldName + " , '" + control.Value + "')";
      filtroGeneral += control.Label + " <strong> CONTIENE TEXTO </strong>  (" + control.Value + ")";
    }
    else if (control.Name == "IdTesauro" && control.ControlType == "MultiComboBox") {
      control.Value.forEach(_value => {
        filtro += control.SearcheableFieldName + " LIKE  '%" + _value + "%'" + " OR ";
        filtroGeneral += control.Label + " <strong> CONTIENE </strong>  (" + _value + ")" + " O ";
      });
      filtro = filtro.substr(0, filtro.length - 4);
      filtroGeneral = filtroGeneral.substr(0, filtroGeneral.length - 3);

    }
    else if (control.Op == "Contains" || control.ControlType == "TextBox") {
      filtro += control.SearcheableFieldName + " LIKE  '%" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> CONTIENE </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotContains") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '%" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> NO CONTIENE  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "StartsWith") {
      filtro += control.SearcheableFieldName + " LIKE  '" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> INICIA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotStartsWith") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> NO INICIA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "EndsWith") {
      filtro += control.SearcheableFieldName + " LIKE  '%" + control.Value + "'";
      filtroGeneral += control.Label + " <strong> FINALIZA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotEndsWith") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '%" + control.Value + "'";
      filtroGeneral += control.Label + " <strong> NO FINALIZA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "IsNull" || control.Op == "IsUndefined") {
      filtro += control.SearcheableFieldName + " IS NULL  ";
      filtroGeneral += control.Label + " <strong> ES NULO  </strong>";
    }
    else if (control.Op == "IsNotNull" || control.Op == "IsNotUndefined") {
      filtro += control.SearcheableFieldName + " NOT IS NULL  ";
      filtroGeneral += control.Label + " <strong> NO ES NULO  </strong>";
    }
    else if (control.Op == "IsEmpty") {
      filtro += control.SearcheableFieldName + " =  '' ";
      filtroGeneral += control.SearcheableFieldName + " <strong> ES VACIO  </strong>";
    }
    else if (control.Op == "IsNotEmpty") {
      filtro += control.SearcheableFieldName + " <>  '' ";
      filtroGeneral += control.Label + " <strong> NO ES VACIO  </strong>";
    }
    ///    str += control.SearcheableFieldName.Name + " LIKE '%" + group.rules[i].data + "%'";
    else {
      switch (control.DataType) {
        case 'String':
          filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " '" + control.Value + "' ";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          break
        case 'DateTime':
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.GetOp(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          break;
        case 'Boolean':
          let boolValue = (control.Value == "true" || control.Value == true) ? 1 : 0;
          filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " " + boolValue + " ";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          //filter += " CONVERT(DATETIME,'" + value + "',103)";
          /*     if (value == "true")
                this.filter += " " + control.Name + " = 1";
              else
                this.filter += " " + control.Name + " = 0"; */
          break;
        default:
          if (Array.isArray(control.Value) && control.Op === "Equal") {
            filtroGeneral +=
              control.Label +
              " ( <strong> " +
              this.GetOpTexto(control.Op) +
              "  </strong>  ";

            filtro += " ( "
            control.Value.forEach((element, index) => {
              filtro +=
                control.SearcheableFieldName +
                " " +
                this.GetOp(control.Op) +
                " " +
                element +
                " ";
              filtroGeneral += `'${element}'`
              if (index + 1 != control.Value.length) {
                filtro += " OR "
                filtroGeneral +=
                  " O " +
                  " <strong> " +
                  this.GetOpTexto(control.Op) +
                  "  </strong>  ";
              }
            });
            filtro += " ) ";
            filtroGeneral += ")"
          }
          else {
            filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " " + control.Value + " ";
            filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          }
          break;
      }
    }
    return { filtroGeneral: filtroGeneral, filtro: filtro };
  }

  public OnLoadGridHandler(event) {
    this.FinConsultaGrilla(event);
  }

  public FinConsultaGrilla(event: any) {
    this.SetProcesando(false);
    if (this.isSearch == true) {
      this.utility.VerModalOk("Búsqueda Finalizada");
    } else if (this.isSearch == false) {
      this.utility.VerModalOk("Limpieza Campos Finalizada");
    }
    this.isSearch = null;
  }

  public Buscar(boton) {
    try {
      this.SetProcesando(true);
      this.componentsContainer.Tables[0].QueryConfig.Entity = this.SetModelo();
      this.componentsContainer.Tables[0].cargarGrilla = true;
      this.componentsContainer.Tables[0]._CargarGrilla(true);
      if(boton == 'btnBuscar'){
          this.isSearch = true;
        } else {
          this.isSearch = false;
          this.componentsContainer.Tables[0].QueryConfig.Entity.Filtro=""
        }
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }

  public SetModelo() {
    this.modelo.modeloVirtual.FiltroGeneral = '';
    let clone = this.utility.Clone(this.modelo.modeloVirtual);
    let filtro = "";
    let filtroGeneral = "";
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modeloVirtual, 'IdAreaEmpresa', 'AreaEmpresa');
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modeloVirtual, 'IdSerie', 'Serie');
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modeloVirtual, 'IdSubSerie', 'SubSerie');

    if (this.SearcheableConfigs && this.SearcheableConfigs.length > 0) {

      let i: number = 0;
      let logicalOp = "";
      let logicalOp_ = "";
      $.each(this.modelo.modeloVirtual, (key, value: any) => {

        const config = this.SearcheableConfigs.find(x => x.Name == key);

        if (config) {

          if (this.modelo.modeloVirtual[key] || this.IsNullableOp(config.Op)) {

            if (i > 0 && filtro) {
              filtro += " " + logicalOp + " ";
              filtroGeneral += " " + logicalOp_ + " ";
            }

            config.Value = this.modelo.modeloVirtual[key];
            const filter = this.GetFiltro(config);
            filtro += filter.filtro;
            filtroGeneral += filter.filtroGeneral;

            logicalOp = ((config.LogicOp == "Or") ? "OR" : "AND");
            logicalOp_ = ((config.LogicOp == "Or") ? "<strong>  O  </strong>" : "<strong>  Y  </strong>");
          }

          clone[key] = undefined;
          if (key == 'IdAreaEmpresa')
            clone.AreaEmpresa = undefined;
          else if (key == 'IdSerie')
            clone.Serie = undefined;
          else if (key == 'IdSubSerie')
            clone.SubSerie = undefined;
        }
        else {
          //logicalOp = "AND";
        }

        i++;
      });
      clone.Filtro = filtro;
      this.modelo.modeloVirtual.FiltroGeneral = filtroGeneral;

      this.modelo.modeloVirtual.Filter = filtro;
    }
    else {

    }
    clone.FiltroMetadatos = null;

    return {
      IdEmpresa: -1,
      IdAreaEmpresaUsuario: -1,
      IdAreaEmpresa: clone.IdAreaEmpresa,
      AreaEmpresa: clone.AreaEmpresa,
      IdSerie: clone.IdSerie,
      Serie: clone.Serie,
      IdSubSerie: clone.IdSubSerie,
      SubSerie: clone.SubSerie,
      Codigo: clone.Codigo,
      Nombre: clone.Nombre,
      FechaCreacionInicial: clone.FechaCreacionInicial,
      FechaCreacionFinal: clone.FechaCreacionFinal,
      IdTipoBodega: clone.IdTipoBodega,
      Filtro: clone.Filtro,
    };
  }

  public ConsultaUbicacionFisica(){
    const that = this;
    let obj = this.utility.GetApiModel("ConsultaUbicacionFisica", null);
    obj.QueryConfig = {
      Command: "ConsultaUbicacionFisicaByIdExpediente",
      IsSp: true,
      Entity: {
        IdEmpresa: -1,
        IdExpediente: this.modelo.WorkFlowTaskModel.IdExpediente,
        TipoPrestamo: this.modelo.WorkFlowTaskModel.TipoPrestamo,        
      },
    };
    let info = {
      componentName: "TExpediente",//this.componentName,
      controlName: "ConsultaUbicacionFisica",
      processMessage: "Cargando Ubicación Física......",
      
    };

    this.baseService
      .Get(obj, null, info, /*this.componentName*/"TExpediente", false, false)
      .then((data) => {
        this.modelo.WorkFlowTaskModel["GridUnidadConservacion"].length = 0;
        const ubicacionFisica = data.Data;
        this.configs.ConfigUnidadConservacion.Context.SetItemsComboBox(ubicacionFisica);
        if(this.modelo.WorkFlowTaskModel.TipoPrestamo == 49){
          this.modelo.WorkFlowTaskModel.UnidadConservacion = -1;
          this.GridUnidadConservacion.Hidden = true;
          this.validateRequiredControls(["UnidadRequerido"],"false");
        }else{
          this.GridUnidadConservacion.Hidden = false;
          this.validateRequiredControls(["UnidadRequerido"],"true");
        }
        if (this.modelo.WorkFlowTaskModel.UnidadConservacionPrestamo && this.modelo.WorkFlowTaskModel.UnidadConservacionPrestamo != "" && this.modelo.WorkFlowTaskModel.UnidadConservacionPrestamo != "[]") {
          this.modelo.WorkFlowTaskModel.GridUnidadConservacion = JSON.parse(this.modelo.WorkFlowTaskModel.UnidadConservacionPrestamo);
        }
        this.SetModelToControls();
      })
      .catch((err) => { });
  }

  public ValidarPermisoPrestamoDocumental(IdFlujoPrestamo_1 : any){
    //debugger;
    const that = this;
    let obj = this.utility.GetApiModel("ValidarPermisoPrestamoDocumental", null);
    obj.QueryConfig = {
      Command: "ValidacionPermisosPrestamoDocumental",
      IsSp: true,
      Entity: {
        IdEmpresa: -1,
        IdRegistro: IdFlujoPrestamo_1,
        Filtro: null
      },
    };
    let info = {
      componentName: "TExpediente",
      controlName: "ValidarPermisoPrestamoDocumental"
    };

    this.baseService
      .Get(obj, null, info, "TExpediente", false, false)
      .then((data) => {
        //debugger;
        if(data.Data.length > 0)
          this.ActualizarPermisoPrestamoDocumental(data.Data);
      })
      .catch((err) => { });
  }

  public ActualizarPermisoPrestamoDocumental(modelo : any){
    //debugger;
    let obj = this.utility.GetApiModel(
      "ActualizarPermisoPrestamoDocumental",
      null
    );

    obj = {
      DataAdd:{ DataTable: modelo }
    };

    this.baseService
      .Ejecutar(obj, null, { componentName: "TExpediente", method: "ActualizarPermisoPrestamoDocumental" }, false, 'TExpediente' + '/ActualizarPermisoPrestamoDocumental', false, false)
      .then((data) => {
      });
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {
      switch (controlName) {
        case 'UnidadConservacion': {
          if(item
            && !this.modelo.WorkFlowTaskModel["GridUnidadConservacion"].find(x => { return x.IdUbicacionFisicaExpediente == item.IdUbicacionFisicaExpediente; })){
            this.modelo.WorkFlowTaskModel["GridUnidadConservacion"].push({IdUbicacionFisicaExpediente: item.IdUbicacionFisicaExpediente, Solicitud: item.Carpeta });
            this.validateRequiredControls(["UnidadRequerido"],this.modelo.WorkFlowTaskModel["GridUnidadConservacion"].length > 0 ? "false" : "true");
          }
          break;
        }
        case 'TipoPrestamo': {
          if(this.modelo.WorkFlowTaskModel.TipoPrestamo == null){
            this.validateRequiredControls(["TipoPrestamo"],"true");
          }
          else{
            this.validateRequiredControls(["TipoPrestamo"],"false");
            this.ConsultaUbicacionFisica();
          }

          break;
        }
      }
      super.OnSelectedComboBox(item, controlName, config);
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: "TExpediente", method: 'OnSelectedComboBox', controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }

  public validateRequiredControls(controlsName: any, yesNo: any){

    const action = {
        ControlDetails: {
            MappingData: [],
            MappingFilter: []
        },
        ControlType: "Action",
        ControlsForHidden: [],
        ControlsToUpdate: controlsName,
        Guid: Guid.create().toString(),
        IsPost: false,
        IsParentModel: false,
        MappingValues: [],
        MappingComponents: [],
        Property: "required",
        PropertyValue: yesNo,
        ResultType: "Url",
        SendQueryString: false,
        TimerAction: 0,
        Type: ControlActions[ControlActions.SetControlProperty]
    };

    const context = this;
    this.helperActions.SetControlsValue(action, context);
    super.SetModelToControls();

}

  _Guardar(form: FormGroup, configButtons?: any): void {

    this.modelo.WorkFlowTaskModel.UnidadConservacionPrestamo = JSON.stringify(this.modelo.WorkFlowTaskModel.GridUnidadConservacion);

    super._Guardar(form);
  }
}
