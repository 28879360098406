import { Component, OnInit,Input,Output,EventEmitter,forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import { ConfigBaseComponent} from '../../.././../models/config-base-component';

@Component({
  selector: 'app-mapping-field',
  templateUrl: './mapping-field.component.html',
  styleUrls: ['./mapping-field.component.css']
})
export class MappingFieldComponent extends ConfigBaseComponent implements OnInit {


}
