import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control'

@Component({
  selector: 'app-text-area-r',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaRComponent),
      multi: true
    }
  ],
  templateUrl: './text-area-r.component.html',
  styleUrls: ['./text-area-r.component.scss']
})
export class TextAreaRComponent extends BaseControl implements OnInit, ControlValueAccessor {


  public ConteoCaracteres() {

  
    if (this.config && this.control)
      return this.config.MaxLength - this.control.value.length;
    else
      return '';
  }

}