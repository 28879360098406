import { Component, Input, OnInit } from '@angular/core';
import { StaticData } from 'src/app/helpers/static-data';
import { Utilities } from 'src/app/helpers/utilities';
import { ExternalMethodConfig, ExternalMethod } from 'src/app/models/rule-engine/rules-models';

@Component({
  selector: 'app-external-method-config',
  templateUrl: './external-method-config.component.html',
  styleUrls: ['./external-method-config.component.css']
})
export class ExternalMethodConfigComponent implements OnInit {


  @Input() set externalModel(value: ExternalMethodConfig) {
    this.modelo[this.property] = value;
  }
  get externalModel(): ExternalMethodConfig {
    return this.modelo[this.property];
  }



  @Input() property: string = "ExternalMethod";
  @Input() modelo: any


  constructor(public utility:Utilities) { }

  ngOnInit() {
  }
  public Eliminar(index) {

    this.externalModel.ExternalMethods.splice(index, 1);
  }
  public Adicionar() {

    this.externalModel.ExternalMethods.push(new ExternalMethod());
  }
  Add() {
    this.externalModel = new ExternalMethodConfig();
  }
  Delete() {
    this.externalModel = null;
  }
  ASSEMBLY_QUERYS: Array<any> = [];
  GetAssemblyQuerys(force?: any) {
    if (StaticData.Project) {

      if (this.ASSEMBLY_QUERYS.length == 0 || force == true) {
        this.ASSEMBLY_QUERYS = [];
        this.ASSEMBLY_QUERYS =this.utility.GetQuerys(StaticData.Project,"AssemblyQuery");

      }

      return this.ASSEMBLY_QUERYS;

    }
    return [];
  }
}
