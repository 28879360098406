import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swap-form',
  templateUrl: './swap-form.component.html',
  styleUrls: ['./swap-form.component.scss']
})
export class SwapFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let grid = (<any>$('.swap__grid')).height();
    let form = (<any>$('.swap__form')).height();
    grid > form ? (<any>$('.swap__grid')).parent().height(grid):(<any>$('.swap__grid')).parent().height(form);
    /*if (grid > form) {
      (<any>$('.swap__grid')).parent().height(grid);
    }else{
      (<any>$('.swap__grid')).parent().height(form);
    }*/
  }

  changeSwap(event) {
    
    if(!(<any>$(event.target)).hasClass("active")){
      (<any>$('.swap__content > div')).toggleClass('active');
    }
  }

}
