import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-session-config',
  templateUrl: './session-config.component.html',
  styleUrls: ['./session-config.component.css']
})
export class SessionConfigComponent implements OnInit {

@Input() viewFilterProps:boolean =false;
@Input() modelo: any;

@Input() querys: Array<any> = [];
@Input() itemsAplications: Array<any> = [];
  ngOnInit() {
  }

}
