import { Component, OnInit, Input } from '@angular/core';
import { Guid } from 'guid-typescript';

declare var fabric;
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() public processMessage: string = '';
  @Input() public loadingName: string;
  @Input() public class: string = "ms-Spinner";
  @Input() public isLarge: boolean = false;
  constructor() {
    this.loadingName = "spinner-" + Guid.create().toString();
  }

  ngOnInit() {

    if (this.isLarge)
      this.class = "ms-Spinner ms-Spinner--large";
  }

  ngAfterViewInit() {

    var SpinnerElement = document.querySelector("#" + this.loadingName);
    new fabric['Spinner'](SpinnerElement);

  }

}
