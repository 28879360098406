import { Component, OnInit } from '@angular/core';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service'
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { Guid } from "guid-typescript";
import { StaticData } from 'src/app/helpers/static-data';
import { FormGroup } from "@angular/forms";
import { event } from 'jquery';
import { Enumerable, List } from "../../../../assets/linqts/compilado/index";

@Component({
  selector: 'app-rzona-empresa',
  templateUrl: './rzona-empresa.component.html',
  styleUrls: ['./rzona-empresa.component.css']
})
export class RZonaEmpresaComponent extends GeneralFormComponent implements OnInit {

  isSearch: boolean = null;
  filtroGeneral = "";
  GridViewAdjutosZona: any;
  EsPais: any;
  EsDepartamento: any;
  EsCiudad: any;
  EsSeccional: any;
  IdPais: any;
  IdDepartamento: any;
  IdCiudad: any;
  IdSeccional: any;

  constructor(
    public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    super(baseService, utility, helperImages, helperRules, helperActions);

  }
  
  public OnInitEnd() {
    debugger
    this.GridViewAdjutosZona = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "GridViewAdjutosZona" });
    this.EsPais = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "EsPais" });
    this.EsDepartamento = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "EsDepartamento" });
    this.EsCiudad = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "EsCiudad" });
    this.EsSeccional = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "EsSeccional" });
    this.IdPais = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdPais" });
    this.IdDepartamento = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdDepartamento" });
    this.IdCiudad = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCiudad" });
    this.IdSeccional = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdSeccional" });
    this.preSaveEmitter.subscribe(modelo => {
      this.modelo.modelo.AdjuntosZonaEmpresa = JSON.stringify(this.modelo.modeloVirtual.GridViewAdjutosZona);
    });
    super.OnInitEnd();
  }
  public OnActionEventHandler(event){
    switch (event.ControlType) {
      case 'CommandButton':
        if (event.Option.Type == "Edit") {
          if (this.modelo.modeloVirtual["GridViewAdjutosZona"].length > 0) {
            this.modelo.modeloVirtual.AdjuntosZona = 'ok';
            this.SetModelToControls();
          }
        }
        super.OnActionEventHandler(event);
        break;
      case 'Button':
          super.OnActionEventHandler(event);
        break;
      default:
        super.OnActionEventHandler(event);
        break;
    }
    if (event.actionType == "EliminarItem") {
      if (this.modelo.modeloVirtual["GridViewAdjutosZona"].length < 1) {
        this.modelo.modeloVirtual.AdjuntosZona = null;     
        this.SetModelToControls()
      }
    }
  }

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {
      var TypeZona = this.modelo.modelo.EsPais ? "Pais":
                     this.modelo.modelo.EsDepartamento ? "Departamento":
                     this.modelo.modelo.EsCiudad ? "Ciudad":
                     this.modelo.modelo.EsSeccional ? "Seccional":
                     ""
      if (!this.modelo.modeloVirtual["GridViewAdjutosZona"]) {
          this.modelo.modeloVirtual["GridViewAdjutosZona"] = [];  
      }
      if (this.modelo.modelo.EsPais){
        if (controlName == "IdPais") {
          if (this.modelo.modeloVirtual["GridViewAdjutosZona"].length > 0 && this.modelo.modeloVirtual["GridViewAdjutosZona"][0].Tipo != TypeZona) {
            this.modelo.modeloVirtual["GridViewAdjutosZona"] = [];  
          }
          if (item) {
            this.modelo.modeloVirtual["GridViewAdjutosZona"].push({ Id: item.IdPais, Nombre: item.Nombre, Tipo: TypeZona });
          }
        }else{
          super.OnSelectedComboBox(item,controlName,config);
        }
      }else if (this.modelo.modelo.EsDepartamento){
        if (controlName == "IdDepartamento") {
          if (this.modelo.modeloVirtual["GridViewAdjutosZona"].length > 0 && this.modelo.modeloVirtual["GridViewAdjutosZona"][0].Tipo != TypeZona) {
            this.modelo.modeloVirtual["GridViewAdjutosZona"] = [];  
          }
          if (item) {
            this.modelo.modeloVirtual["GridViewAdjutosZona"].push({ Id: item.IdDepartamento, Nombre: item.Nombre, Tipo: TypeZona });

            var itemsCombo = this.configs.ConfigIdDepartamento.Context.items;
            this.configs.ConfigIdDepartamento.Context.ResetComboBox(itemsCombo, true);
          }
        }else{
          super.OnSelectedComboBox(item,controlName,config);
        }
    }else if (this.modelo.modelo.EsCiudad){
      if (controlName == "IdCiudad") {
        if (this.modelo.modeloVirtual["GridViewAdjutosZona"].length > 0 && this.modelo.modeloVirtual["GridViewAdjutosZona"][0].Tipo != TypeZona) {
          this.modelo.modeloVirtual["GridViewAdjutosZona"] = [];  
        }
        if (item) {
          this.modelo.modeloVirtual["GridViewAdjutosZona"].push({ Id: item.IdCiudad, Nombre: item.Nombre, Tipo: TypeZona });

          var itemsCombo = this.configs.ConfigIdCiudad.Context.items;
          this.configs.ConfigIdCiudad.Context.ResetComboBox(itemsCombo, true);
        }
      }else{
        super.OnSelectedComboBox(item,controlName,config);
      }
    }else if (this.modelo.modelo.EsSeccional){
      if (controlName == "Seccionales") {
        if (this.modelo.modeloVirtual["GridViewAdjutosZona"].length > 0 && this.modelo.modeloVirtual["GridViewAdjutosZona"][0].Tipo != TypeZona) {
          this.modelo.modeloVirtual["GridViewAdjutosZona"] = [];  
        }
        if (item) {
          this.modelo.modeloVirtual["GridViewAdjutosZona"].push({ Id: item.IdSedeEmpresa, Nombre: item.Nombre, Tipo: TypeZona });

          var itemsCombo = this.configs.ConfigSeccionales.Context.items;
          this.configs.ConfigSeccionales.Context.ResetComboBox(itemsCombo, true);
        }
      }else{
        super.OnSelectedComboBox(item,controlName,config);
      }
    }
    if (this.modelo.modeloVirtual["GridViewAdjutosZona"].length == 1) {
      this.modelo.modeloVirtual.AdjuntosZona = 'ok';
      this.SetModelToControls();
    }
    } catch (error) {
      this.utility.logger.LogError(error, null, {
        componentName: this.componentName,
        method: "OnSelectedComboBox",
        controlName: controlName,
      });
      this.utility.VerModalDanger().then((data) => { });
    }
  }

  public OnDataTableEditHandler(event) {
    if (event.data.data.findItem.AdjuntosZonaEmpresa != "" && event.data.data.findItem.AdjuntosZonaEmpresa != "[]") {
      this.modelo.modelo.AdjuntosZonaEmpresa = event.data.data.findItem.AdjuntosZonaEmpresa;
      this.modelo.modeloVirtual.GridViewAdjutosZona = JSON.parse(this.modelo.modelo.AdjuntosZonaEmpresa);
    }
    this.SetModelToControls();
  }
}


