import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { JsonEditorComponent } from 'ang-jsoneditor';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { StaticData } from 'src/app/helpers/static-data';
import { FormGroup } from '@angular/forms';
import { ComponentsContainer } from 'src/app/models/components-container';
import { Guid } from 'guid-typescript';

declare var fabric: any;


@Component({
  selector: 'app-functional-audit',
  templateUrl: './functional-audit.component.html',
  styleUrls: ['./functional-audit.component.scss']
})
export class FunctionalAuditComponent implements OnInit, AfterViewInit {
  @ViewChild('functionalAuditSidePanel', { static: false }) public functionalAuditSidePanel: FunctionalAuditComponent;
  @ViewChild('functionalAuditLocal', { static: false }) public functionalAuditLocal: FunctionalAuditComponent;
  @Input() items: Array<any> = [];
  @Input() dataSourceResult: Array<any> = [];
  @Input() modelo: any;
  @Input() componentsContainer: ComponentsContainer = new ComponentsContainer();
  @Input() componentName: string = 'General';

  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;
  @Input() notLoad: boolean = false;
  @Input() config: any;
  @Input() name: string = '';
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() permissionsConfig: any;
  @Input() Pagina: any = { Valida: false, ConfigPage: { ExtraControls: [], Controls: [] } };


  @Input() saveModelContainer: any;
  @Input() parentContext: any;
  @Input() currentContext: any;
  @Input() currentItem: any;
  @Input() currentConfig: any;

  @Input() htmlMsg: any = "";
  verAlerta: boolean = false;
  @Input() form: FormGroup;
  @Output() onInitialized = new EventEmitter<any>();

  @Input() image: any = "";
  @Input() isChild: boolean = false;
  @Input() viewChild: boolean = false;
  @Input() viewSidPanelChild: boolean = false;
  public TiposAuditoriaFuncionalArr: Array<any> = [

    { Name: 'CreacionDeExpediente', Label: 'CreacionDeExpediente', Icon: 'ms-Icon ms-Icon--FabricNewFolder' },
    { Name: 'ActualizacionDatosExpediente', Label: 'ActualizacionDatosExpediente', Icon: 'ms-Icon ms-Icon--PostUpdate' },
    { Name: 'BorradoLogicoExpediente', Label: 'BorradoLogicoExpediente', Icon: 'ms-Icon ms-Icon-Delete' },
    { Name: 'ActivarExpediente', Label: 'ActivarExpediente', Icon: 'ms-Icon ms-Icon--ActivateOrders' },
    { Name: 'InclusionDocumentosExpediente', Label: 'InclusionDocumentosExpediente', Icon: 'ms-Icon ms-Icon--NewFolder' },
    { Name: 'BorradoLogicoDocumentoExpediente', Label: 'BorradoLogicoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--Delete' },
    { Name: 'ActivarDocumentoExpediente', Label: 'ActivarDocumentoExpediente', Icon: 'ms-Icon ms-Icon--ActivateOrders' },
    { Name: 'ActualizacionDatosDocumentoExpediente', Label: 'ActualizacionDatosDocumentoExpediente', Icon: 'ms-Icon ms-Icon--PostUpdate' },
    { Name: 'InclusionRadicadoExpediente', Label: 'InclusionRadicadoExpediente', Icon: 'ms-Icon ms-Icon--OpenInNewTab' },
    { Name: 'TransferenciaExpediente', Label: 'TransferenciaExpediente', Icon: 'ms-Icon ms-Icon--Transition' },
    { Name: 'PrestamoExpediente', Label: 'PrestamoExpediente', Icon: 'ms-Icon ms-Icon--Split' },
    { Name: 'CrearNuevaVersionDocumentoExpediente', Label: 'CrearNuevaVersionDocumentoExpediente', Icon: 'ms-Icon ms-Icon--VersionControlPush' },
    { Name: 'ActualizacionArchivoDocumentoExpediente', Label: 'ActualizacionArchivoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--Refresh' },
    { Name: 'InclusionArchivoDocumentosExpediente', Label: 'InclusionArchivoDocumentosExpediente', Icon: 'ms-Icon ms-Icon--OpenFile' },
    { Name: 'InclusionAdjuntoDocumentosExpediente', Label: 'InclusionAdjuntoDocumentosExpediente', Icon: 'ms-Icon ms-Icon--OpenFile' },

    { Name: 'VerArchivoDocumentoExpediente', Label: 'VerArchivoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--View' },
    { Name: 'DescargarArchivoDocumentoExpediente', Label: 'DescargarArchivoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--DownloadDocument' },
    { Name: 'ConsultaExpedientes', Label: 'ConsultaExpedientes', Icon: 'ms-Icon ms-Icon--FolderSearch' },
    { Name: 'ConsultaDocumentosExpediente', Label: 'ConsultaDocumentosExpediente', Icon: 'ms-Icon ms-Icon--DocumentSearch' },
    { Name: 'ConsultaVersionesDocumentosExpediente', Label: 'ConsultaVersionesDocumentosExpediente', Icon: 'ms-Icon ms-Icon--DocumentSearch' },

    { Name: 'ErrorCrearNuevaVersionDocumentoExpediente', Label: 'ErrorCrearNuevaVersionDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorActualizacionArchivoDocumentoExpediente', Label: 'ErrorActualizacionArchivoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorInclusionArchivoDocumentosExpediente', Label: 'ErrorInclusionArchivoDocumentosExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorInclusionAdjuntoDocumentosExpediente', Label: 'ErrorInclusionAdjuntoDocumentosExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },

    { Name: 'ErrorCreacionDeExpediente', Label: 'ErrorCreacionDeExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorActualizacionDatosExpediente', Label: 'ErrorActualizacionDatosExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorBorradoLogicoExpediente', Label: 'ErrorBorradoLogicoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorActivarExpediente', Label: 'ErrorActivarExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorInclusionDocumentosExpediente', Label: 'ErrorInclusionDocumentosExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorBorradoLogicoDocumentoExpediente', Label: 'ErrorBorradoLogicoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorActivarDocumentoExpediente', Label: 'ErrorActivarDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorActualizacionDatosDocumentoExpediente', Label: 'ErrorActualizacionDatosDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorInclusionRadicadoExpediente', Label: 'ErrorInclusionRadicadoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorTransferenciaExpediente', Label: 'ErrorTransferenciaExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorPrestamoExpediente', Label: 'ErrorPrestamoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },

    { Name: 'ErrorVerArchivoDocumentoExpediente', Label: 'ErrorVerArchivoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorDescargarArchivoDocumentoExpediente', Label: 'ErrorDescargarArchivoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorVerAdjuntoDocumentoExpediente', Label: 'ErrorVerAdjuntoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorDescargarAdjuntoDocumentoExpediente', Label: 'ErrorDescargarAdjuntoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorConsultaExpedientes', Label: 'ErrorConsultaExpedientes', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorConsultaDocumentosExpediente', Label: 'ErrorConsultaDocumentosExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },
    { Name: 'ErrorConsultaVersionesDocumentosExpediente', Label: 'ErrorConsultaVersionesDocumentosExpediente', Icon: 'ms-Icon ms-Icon--StatusErrorFull' },

    { Name: 'CierreExpediente', Label: 'CierreExpediente', Icon: 'ms-Icon ms-Icon--ChromeClose' },
    { Name: 'General', Label: 'General', Icon: 'ms-Icon ms-Icon--' },
    { Name: 'ReabrirExpediente', Label: 'ReabrirExpediente', Icon: 'ms-Icon ms-Icon--OpenFolderHorizontal' },
    { Name: 'FlujosdeTrabajo', Label: 'FlujosdeTrabajo', Icon: 'ms-Icon ms-Icon--' },
    { Name: 'InclusionArchivoFoliadoDocumentosExpediente', Label: 'InclusionArchivoFoliadoDocumentosExpediente', Icon: 'ms-Icon ms-Icon--OpenFile' },
    { Name: 'FasedeArchivo', Label: 'FasedeArchivo', Icon: 'ms-Icon ms-Icon--FolderListMirrored' },
    { Name: 'CreacionRadicadoSalida', Label: 'CreacionRadicadoSalida', Icon: 'ms-Icon ms-Icon--' },
    { Name: 'AprobadoRadicadoSalida', Label: 'AprobadoRadicadoSalida', Icon: 'ms-Icon ms-Icon--DocumentApproval' },
    { Name: 'RechazadoRadicadoSalida', Label: 'RechazadoRadicadoSalida', Icon: 'ms-Icon ms-Icon--DocumentReply' },
    { Name: 'PendienteRecibirenvioRadicadoSalida', Label: 'PendienteRecibirenvioRadicadoSalida', Icon: 'ms-Icon ms-Icon--Documentation' },



    { Name: 'ConsultaDetalleExpediente', Label: 'ConsultaDetalleExpediente', Icon: 'ms-Icon ms-Icon--FolderSearch' },
    { Name: 'ConsultaDetalleDocumentoExpediente', Label: 'ConsultaDetalleDocumentoExpediente', Icon: 'ms-Icon ms-Icon--DocumentSearch' },
    { Name: 'DescargarAdjuntoDocumentoExpediente', Label: 'DescargarAdjuntoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--DownloadDocument' },
    { Name: 'VerAdjuntoDocumentoExpediente', Label: 'VerAdjuntoDocumentoExpediente', Icon: 'ms-Icon ms-Icon--View' },

    { Name: 'DescargarArchivoRadicadoExpediente', Label: 'DescargarArchivoRadicadoExpediente', Icon: 'ms-Icon ms-Icon--DownloadDocument' },
    { Name: 'VerArchivoRadicadoExpediente', Label: 'VerArchivoRadicadoExpediente', Icon: 'ms-Icon ms-Icon--View' },
    { Name: 'DescargarAdjuntoRadicadoExpediente', Label: 'DescargarAdjuntoRadicadoExpediente', Icon: 'ms-Icon ms-Icon--DownloadDocument' },
    { Name: 'VerAdjuntoRadicadoExpediente', Label: 'VerAdjuntoRadicadoExpediente', Icon: 'ms-Icon ms-Icon--View' },
    { Name: 'DescargarPlantillaRadicadoExpediente', Label: 'DescargarPlantillaRadicadoExpediente', Icon: 'ms-Icon ms-Icon--DownloadDocument' },
    { Name: 'VerPlantillaRadicadoExpediente', Label: 'VerPlantillaRadicadoExpediente', Icon: 'ms-Icon ms-Icon--View' },
    { Name: 'DescargaIndice', Label: 'DescargaIndice', Icon: 'ms-Icon ms-Icon--DownloadDocument' }
 

  ];
  panelName = '';
  panelClass = "ms-Panel";
  sidePanel: any;

  constructor(public baseService: BaseServiceService,
    public comunicationService: GeneralComunicationService,
    public utility: Utilities,
    public helperActions: HelperActions,
    public helperImages: HelperImages) { }

  //* FUNCIONES ANGULAR 

  ngOnInit() {
    this.panelName = 'FuncionalAudit_' + Guid.create().toString();
    
    if (this.config) {
      this.name = this.config.Name;
      this.panelClass = (this.config.SidePanelClass) ? this.config.SidePanelClass : this.panelClass;
      this.notLoad = this.config.NotLoad;
      this.componentName = this.config.Controller;
      if (this.config.Type == "Expedientes" || this.config.Type == 'MultipleExpedientes') {
        this.image = this.helperImages.ImagenExpedientes;

      }
      else if (this.config.Type == "Documentos") {
        this.image = this.helperImages.ImagenDocumentosExpediente;


        let dataImage = "";
        this.config.MappingParameters.forEach(parameter => {
          dataImage = this.utility.GetParmeterValue(parameter, this.modelo, this.form, null, null, this, this.parentContext);
        });
        this.image = this.helperImages.GetRuleIcon(this.GetExt(dataImage));
      }
      else if (this.config.Type == "Solicitudes") {
        this.image = this.helperImages.ImageTipoSolicitud;
      }





    }

    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }
  }
  ngAfterViewInit() {

    
    if (this.config) {
      if (!this.config.TimerAferInit)
        this.config.TimerAferInit = 50;
      //  if (this.config && this.config.TimerAferInit) {
      setTimeout(() => {
        

        this.LoadAll();

      }, this.config.TimerAferInit);
      // }

    }
    else
      this.verAlerta = true;

    if (!this.isChild)
      this.sidePanel = document.querySelector("#" + this.panelName);
  }

  public LoadAll() {
    if (this.config.DataSources && this.config.DataSources.length > 0) {
      this.ExcecuteDataSource();
    }
    else
      this.ProcesarItems();
  }
  //* END FUNCIONES ANGULAR 



  public OnClick(configChild: any, item) {

    
    // @Input() parentContext: any;
    // @Input() currentContext: any;
    // @Input() currentItem: any;
    if (configChild.ViewType == "Modal") {

      const config = this.utility.GetConfigWindow(configChild.Label);

      config.width = 350;
      config.height = 500;
      let isNew: boolean = false;
      let windowModel: any = {
        config: configChild,
        isChild: true,
        parentContext: this.parentContext,
        currentContext: this.currentContext,
        currentItem: item,
        modelo: this.modelo
      };

      this.utility.VerModalComponente('FunctionalAuditComponent', windowModel, config).then(dataResultOnClose => {
        if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

          

        }
        else {

        }
      });
    }
    else if (configChild.ViewType == "SidePanel") {


      this.viewSidPanelChild = true;
      this.OpenSidePanel();
      if (this.functionalAuditSidePanel) {

        this.SetFunctionalAudit(this.functionalAuditSidePanel, configChild, item);

      }

    }
    else if (configChild.ViewType == "Local") {


      this.viewChild = true;
      if (this.functionalAuditLocal) {
        this.SetFunctionalAudit(this.functionalAuditLocal, configChild, item);
      }
    }
  }
  public SetFunctionalAudit(audit: FunctionalAuditComponent, configChild: any, item: any) {
    audit.verAlerta = false;
    audit.currentItem = item;
    audit.config = configChild;
    audit.ngOnInit();
    audit.LoadAll();
  }
  CloseLocal() {
    this.viewChild = false;
  }
  OpenSidePanel() {
    if (this.sidePanel)
      new fabric['Panel'](this.sidePanel);
  }


  GetExt(filename) {
    var idx = filename.lastIndexOf('.');
    return (idx < 1) ? "" : filename.substr(idx + 1);
  }

  public ExcecuteDataSource(filterParent?: any) {
    let obj = this.utility.GetApiModel('ExcecuteDataSource', null);
    this.items = [];
    this.dataSourceResult = [];
    try {
      if (this.config.DataSources.length == 0 || this.notLoad) {

        return;
      }
      // if (filterParent)
      //   this.filterParent = filterParent;
      obj.QueryConfigs = [];

      let index = 0;
      this.config.DataSources.forEach(ds => {


        let queryConfig = this.utility.Clone(ds);
        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = index;
          if (queryConfig.CrudAction == 'GetAll' || queryConfig.CrudAction == 'GetAllOrderBy'
            || queryConfig.CrudAction == 'GetAllOrderByDesc') {
            queryConfig.Entity = {};
          }
        }





        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, queryConfig, null, this, this.parentContext);
          })
        }

        obj.QueryConfigs.push(queryConfig);
        //this.dataSet.push({ index: index, data: null });
        index++;
      });

      this.baseService.MultiGet(obj, null,
        {
          componentName: this.componentName,
          method: 'ExcecuteDataSource',
          controlName: this.config.Name,
          processMessage: this.config.ProcessMessage
        }, this.config.Controller)
        .then(data => {
          index = 0;
          
          data.Data.forEach(dtt => {

            // this.dataSet[index].data = dtt;
            this.dataSourceResult = this.dataSourceResult.concat(dtt);
            //this.config.DataSources[index].data = dtt;
            index++;

          });
          
          this.ProcesarItems();
        }).catch(err => {
          this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
          this.utility.VerModalDanger().then(data => { })
        });

    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
      this.utility.VerModalDanger().then(data => { })
    }
  }

  public ProcessToolTip(property, file, currentItem, propertyList) {
    if (this.config[property]) {
      file[property] = this.config[property];
      if (file[property].includes("#")) {
        if (currentItem)
          file[property] = this.utility.ReplaceTextFromModels(currentItem, file[property]);
        file[property] = this.utility.ReplaceTextFromModels(this.modelo, file[property]);
      }
    }
    if (this.config[propertyList] && this.config[propertyList].length > 0) {
      file[propertyList] = this.config[propertyList];
      file[propertyList].forEach(_toolTip => {
        _toolTip.Class = (_toolTip.IsDivider) ? 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 divider my-3' : 'ms-Grid-col ms-sm12 ms-md12 ms-lg12';
        _toolTip.ToolTipView = _toolTip.ToolTip;
        if (_toolTip.ToolTipView.includes("#")) {
          _toolTip.ToolTipView = this.utility.ReplaceTextFromModels(currentItem, _toolTip.ToolTipView);
          _toolTip.ToolTipView = this.utility.ReplaceTextFromModels(this.modelo, _toolTip.ToolTipView);
        }

      });

    }
  }
  public ProcesarItems() {

    

    if (this.config.HtmlMsg) {

      this.htmlMsg = this.config.HtmlMsg;
      if (this.config.InfoModelsContainer && this.htmlMsg && this.htmlMsg.includes("#")) {
        let modelsnames: Array<any> = this.config.InfoModelsContainer.split(',');
        let tempModelo: any = {};
        modelsnames.forEach(modelName => {
          tempModelo[modelName] = this.modelo[modelName];
        });
        this.htmlMsg = this.utility.ReplaceTextFromModels(tempModelo, this.htmlMsg);
        if (this.isChild)
          this.htmlMsg = this.utility.ReplaceTextFromModels(this.currentItem, this.htmlMsg);
      }
      else {
        if (this.htmlMsg && this.htmlMsg.includes("#")) {
          if (this.isChild)
            this.htmlMsg = this.utility.ReplaceTextFromModels(this.currentItem, this.htmlMsg);
          else
            this.htmlMsg = this.utility.ReplaceTextFromModels(this.modelo, this.htmlMsg);

        }

      }
    }


    if (this.modelo || (this.dataSourceResult && this.dataSourceResult.length > 0)) {
      this.items = [];
      if (this.dataSourceResult && this.dataSourceResult.length > 0) {

        this.dataSourceResult.forEach(currentItem => {
          const auditoriaFuncional = this.TiposAuditoriaFuncionalArr.find(x => { return x.Name == currentItem.AuditoriaFuncional });
          if (auditoriaFuncional) {
            currentItem.Image = auditoriaFuncional.Image;
            currentItem.Icon = auditoriaFuncional.Icon;
            currentItem.Title = currentItem.AuditoriaFuncional;

            if (this.config.MsgItem && this.config.MsgItem.includes("#"))
              currentItem.Title = this.utility.ReplaceTextFromModels(currentItem, this.config.MsgItem);

            currentItem.ToolTip = "";
            currentItem.ToolTipImage = "";
            this.ProcessToolTip('ToolTip', currentItem, currentItem, 'ToolTips');
            this.ProcessToolTip('ToolTipImage', currentItem, currentItem, 'ToolTipsImage');
          }
          else
            console.error("No Existe Auditoria: " + currentItem.AuditoriaFuncional)


        });

        this.items = this.dataSourceResult;
      }



    }

    this.verAlerta = true;
  }

  removeItem() {

  }
  // openTooltip(e: Event) {
  //   const item = $(e.currentTarget);
  //   const tooltip = item.find(".att__tooltip");
  //   const gap = 30 + (item.hasClass("att__title") ? 30 : 0);
  //   if (item.offset().top > $(window).height() / 2) {
  //     tooltip.css("top", item.offset().top - 10);
  //   } else {
  //     tooltip.css("top", item.offset().top - 10);
  //     tooltip.css("left", item.offset().left - gap - tooltip.outerWidth(true));
  //   }
  // }

  openTooltip(e: Event) {
    const item = $(e.currentTarget);
    const tooltip = item.find(".att__tooltip");
    const gap = 5;
 
    // if element have space to the sides
    if ($(window).width() - item.parent().outerWidth() > tooltip.outerWidth(true)) {
      // if element have space to the left side
      if (item.offset().left >= tooltip.outerWidth(true)) {
        tooltip.css("left", item.parent().offset().left - gap - tooltip.outerWidth(true));
      } else {
        tooltip.css("left", item.parent().offset().left + item.parent().outerWidth(true) + gap);
      }
      //if element is down
      if (item.parent().offset().top > $(window).height() / 2) {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop() - tooltip.outerHeight() + item.parent().outerHeight());
      } else {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop());
      }
    } else {
      tooltip.css("left", item.parent().offset().left);
      //if element is down
      if (item.parent().offset().top > $(window).height() / 2) {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop() - tooltip.outerHeight(true) - gap);
      } else {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop() + item.parent().outerHeight() + gap);
      }
    }
  }


}
