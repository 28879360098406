import { Component, OnInit } from '@angular/core';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service'
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-rsubserie',
  templateUrl: './rsubserie.component.html',
  styleUrls: ['./rsubserie.component.css']
})
export class RsubserieComponent extends GeneralFormComponent implements OnInit {

  MessageUpdateTesauro: any;
  Descriptores: any;
  ConfigIdSerie: any;

  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    //super(baseService, utility, Paginas.TipoDocumental, 'RTipoDocumental', null, false);
    super(baseService, utility, helperImages, helperRules, helperActions);

  }

  public OnInitEnd() {

    this.MessageUpdateTesauro = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "MessageUpdateTesauro" });
    this.Descriptores = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Descriptores" });

    this.preSaveEmitter.subscribe(modelo => {
      this.modelo.modelo.PalabrasNormalizadas = JSON.stringify(this.modelo.modeloVirtual.GridPalabrasNormalizadas);
    });
  }

  /*_Guardar(form: FormGroup, configButtons?: any) {
    debugger;
    this.modelo.modelo.DataAdd = {
    PalabrasNormalizadas: JSON.stringify(this.modelo.modeloVirtual.GridPalabrasNormalizadas).replace('\\', '')
    };
    super._Guardar(form, configButtons);
  }*/

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {

      if (controlName == "Descriptores") {
        if (!this.modelo.modeloVirtual["GridPalabrasNormalizadas"]) {
          this.modelo.modeloVirtual["GridPalabrasNormalizadas"] = [];
        }

        if (item && this.modelo.modelo.Descriptores &&// (this.modelo.modeloVirtual.GridPalabrasNormalizadas != "" && this.modelo.modeloVirtual.GridPalabrasNormalizadas != null)) {
          !this.modelo.modeloVirtual["GridPalabrasNormalizadas"].find(x => { return x.Id == item.IdTesauro; })) {
          this.modelo.modeloVirtual["GridPalabrasNormalizadas"].push({ Id: item.IdTesauro, Palabra: item.Palabra });

          var itemsCombo = this.configs.ConfigDescriptores.Context.items;
					this.configs.ConfigDescriptores.Context.ResetComboBox(itemsCombo, true);
        }
      }

    } catch (error) {
      this.utility.logger.LogError(error, null, {
        componentName: this.componentName,
        method: "OnSelectedComboBox",
        controlName: controlName,
      });
      this.utility.VerModalDanger().then((data) => { });
    }
  }

  public OnActionEventHandler(event) {

    if(event.Action && event.Action == "ClearModel"){

      setTimeout(() => { this.componentsContainer.ReloadTable("DataTable_VersionesSubSerie", { IdEmpresa: -1, IdSubSerie: -1 }, null, false); }, 1000);
      this.Limpiar();
    }
    else{
      switch (event.ControlType) {
      case 'CommandButton':
        if (event.Option.Type == "Edit") {

          this.configs.ConfigIdSerie.Params = { IdEmpresa: 1, PkValue: this.modelo.modelo.PkValue};
          this.configs.ConfigIdSerie.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          

          this.MessageUpdateTesauro.Hidden = false;
          if(this.Descriptores.Activo)
            this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
          super.OnActionEventHandler(event);

        }

        if (event.Option.Type == "New") {

          this.configs.ConfigIdSerie.Params = { IdEmpresa: 1, PkValue: 0};
          this.configs.ConfigIdSerie.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);

          this.MessageUpdateTesauro.Hidden = true;
          if(this.Descriptores.Activo)
            this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
          super.OnActionEventHandler(event);

        }
        else
          super.OnActionEventHandler(event);
        break;
      case 'Button':
        if (event.Name == "searchTesauro") {
          this.configs.ConfigDescriptores.Params = { PalabraBusqueda: this.modelo.modeloVirtual.palabraTesauro };
          this.configs.ConfigDescriptores.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
        }
        else
          super.OnActionEventHandler(event);
        break;
      default:
        super.OnActionEventHandler(event);
        break;
      }
    }

  }

  public Limpiar() {
    this.MessageUpdateTesauro.Hidden = true;
    super.Limpiar();
  }

  public OnDataTableEditHandler(event) {

    if (event.data.data.findItem.PalabrasNormalizadas != "" && event.data.data.findItem.PalabrasNormalizadas != "[]") {
      this.modelo.modelo.PalabrasNormalizadas = event.data.data.findItem.PalabrasNormalizadas;
      this.modelo.modeloVirtual.GridPalabrasNormalizadas = JSON.parse(this.modelo.modelo.PalabrasNormalizadas);
    }
    this.SetModelToControls();
  }


}
