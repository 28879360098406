import { Injectable, NgModuleFactory } from '@angular/core';
import { PluginLoaderService } from './plugin-loader.service';
import { PLUGIN_CURRENT_APP_MAP, PLUGIN_EXTERNALS_MAP } from './plugin-externals';


const SystemJs = window['System'];

@Injectable({
  providedIn: 'root'
})
export class ClientPluginLoaderService extends PluginLoaderService {
  constructor() {
    super();
  }

  ProvideExternals() {
    
    Object.keys(PLUGIN_EXTERNALS_MAP).forEach(externalKey => {
      //window.define(externalKey, [], () => PLUGIN_EXTERNALS_MAP[externalKey])
     
      window['ng'][externalKey] = PLUGIN_EXTERNALS_MAP[externalKey];
    });
    window['currentApp']={};
    Object.keys(PLUGIN_CURRENT_APP_MAP).forEach(externalKey => {
      //window.define(externalKey, [], () => PLUGIN_EXTERNALS_MAP[externalKey])
   
      window['currentApp'][externalKey] = PLUGIN_CURRENT_APP_MAP[externalKey];
    });
  }

  Load<T>(pluginConfig): Promise<NgModuleFactory<T>> {
    
    if (!pluginConfig) {
      throw Error(`Can't find appropriate plugin`);
    }

    const depsPromises = (pluginConfig.Deps || []).map(dep => {
      return SystemJs.import(dep.Path).then(m => {

        window[(dep.Module)?dep.Module:dep.Module] = (m.default) ? m.default : m;
        //window['define'](dep, [], () => (m.default) ? m.default : m);
      });
    });

    return Promise.all(depsPromises).then(() => {
      return SystemJs.import(pluginConfig.Path).then(
        module => (module.default) ? module.default : module
      );
  });
}
}
