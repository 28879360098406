
import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service'
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { TDocumentoExpedienteComponent } from '../../bussines/tdocumento-expediente/tdocumento-expediente.component';
import { ControllerMethods } from 'src/app/models/general.enum';


@Component({
  selector: 'app-expediente-panel',
  templateUrl: './expediente-panel.component.html',
  styleUrls: ['./expediente-panel.component.scss']
})
export class ExpedientePanelComponent extends TDocumentoExpedienteComponent implements OnInit {



  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);
    this.pageId = 'ExpedientePanel';
  }

  public CargarComboTipoDocumental(items: Array<any>) {
    // this.modelo.modelo.IdExpediente = IdExpediente;
    
    this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(items, false);
  }

  public Editar(modeloEdit: any, configTD: Array<any> = []) {
    // this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(configTD, true);
    super.ngOnInit();

  }

  @Input() FuncGetTiposDocumental: any;

  public PostNgAfterViewInit() {

    if (this.FuncGetTiposDocumental) {

      setTimeout(() => {
        let items = this.FuncGetTiposDocumental();
       
        this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(items, false,this.modelo.modelo.IdTipoDocumental);
      }, 700);
    }



  }
  // public EndInternalSetModelo() {

  //   
  //   if (this.FuncGetTiposDocumental) {
  //     let items = this.FuncGetTiposDocumental();
  //     this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(items, true);
  //   }
  // }

  public InternalSetModelo(_modelo: any, permissionsConfig?: any) {
    //
    //CM
    
    if (permissionsConfig)
      this.permissionsConfig = this.permissionsConfig;

    // this.modelo.modelo = (this.modelo.modelo) ? this.utility.SetFieldsValue(this.modelo.modelo, _modelo.documento) : _modelo.modelo;
    this.modelo.modelo = (_modelo.documento) ? this.utility.SetFieldsValue(this.modelo.modelo, _modelo.documento) : this.modelo.modelo;

    this.modelo.modelo.PkValue = this.modelo.modelo[this.pkName];

    if (_modelo.modeloMetadata) {
      this.modelo.modeloMetadata = (this.modelo.modeloMetadata) ? this.utility.SetFieldsValue(this.modelo.modeloMetadata, _modelo.modeloMetadata) : _modelo.modeloMetadata;
      this.modelo.modeloMetadata.PkValue = this.modelo.modeloMetadata[this.pkName];
    }


    this.SetModelToControls();
    this.SetProcesando(false);
    //this.SetFocusModelo();
    if (this.palabrasClaveComponent) {
      this.palabrasClaveComponent.isModelContainer = true;
      this.palabrasClaveComponent.SetModelo(this.modelo);
    }
    if (this.adminPermisosComponent) {
      this.adminPermisosComponent.isModelContainer = true;
      this.adminPermisosComponent.SetModelo(this.modelo);
    }
    this.EditCascading();
    this.ExcecuteFuntion(ControllerMethods.Edit, true, { index: 0, id: this.modelo.modelo.PkValue });

    
    // if (this.FuncGetTiposDocumental) {
    //   let items = this.FuncGetTiposDocumental();
    //   this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(items, false);
    // }
  }
  // public InternalSetModelo(_modelo: any, permissionsConfig?: any) {
  //   //
  //   //CM
  //   
  //   if (permissionsConfig)
  //     this.permissionsConfig = this.permissionsConfig;

  //   this.modelo.modelo = (this.modelo.modelo) ? this.utility.SetFieldsValue(this.modelo.modelo, _modelo.modelo) : _modelo.modelo;
  //   this.modelo.modelo.PkValue = this.modelo.modelo[this.pkName];

  //   if (_modelo.modeloMetadata) {
  //     this.modelo.modeloMetadata = (this.modelo.modeloMetadata) ? this.utility.SetFieldsValue(this.modelo.modeloMetadata, _modelo.modeloMetadata) : _modelo.modeloMetadata;
  //     this.modelo.modeloMetadata.PkValue = this.modelo.modeloMetadata[this.pkName];
  //   }


  //   this.SetModelToControls();
  //   this.SetProcesando(false);
  //   //this.SetFocusModelo();
  //   if (this.palabrasClaveComponent) {
  //     this.palabrasClaveComponent.isModelContainer = true;
  //     this.palabrasClaveComponent.SetModelo(this.modelo);
  //   }
  //   if (this.adminPermisosComponent) {
  //     this.adminPermisosComponent.isModelContainer = true;
  //     this.adminPermisosComponent.SetModelo(this.modelo);
  //   }
  //   this.EditCascading();
  //   this.ExcecuteFuntion(ControllerMethods.Edit, true, { index: 0, id: this.modelo.modelo.PkValue });

  //   
  //   // if (this.FuncGetTiposDocumental) {
  //   //   let items = this.FuncGetTiposDocumental();
  //   //   this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(items, false);
  //   // }
  // }

}
