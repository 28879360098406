import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { JsonEditorComponent } from 'ang-jsoneditor';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { StaticData } from 'src/app/helpers/static-data';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-attachment-selection',
  templateUrl: './attachment-selection.component.html',
  styleUrls: ['./attachment-selection.component.scss']
})

export class AttachmentSelectionComponent implements OnInit, AfterViewInit {

  @Input() items: Array<any> = [];
  @Input() dataSourceResult: Array<any> = [];
  @Input() modelo: any;
  @Input() componentsContainer: any;
  @Input() componentName: string = 'General';

  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;
  @Input() notLoad: boolean = false;
  @Input() config: any;
  @Input() name: string = '';
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() permissionsConfig: any;
  @Input() Pagina: any = { Valida: false, ConfigPage: { ExtraControls: [], Controls: [] } };


  @Input() saveModelContainer: any;
  @Input() parentContext: any;

  @Input() htmlMsg: any = "";
  verAlerta: boolean = false;
  @Input() form: FormGroup;
  @Output() onInitialized = new EventEmitter<any>();

  @Input() currentContext: any;


  configDocViewer: any = null;

  constructor(public baseService: BaseServiceService,
    public comunicationService: GeneralComunicationService,
    public utility: Utilities,
    public helperActions: HelperActions,
    public helperImages: HelperImages) { }

  //* FUNCIONES ANGULAR

  ngOnInit() {


    if (this.config) {
      this.name = this.config.Name;
      this.notLoad = this.config.NotLoad;

      if (this.Pagina && this.Pagina && this.Pagina.ConfigPage &&
        this.Pagina.ConfigPage.HiddenControls && this.config && this.config.DocumentViewerConfig) {

        this.configDocViewer = this.Pagina.ConfigPage.HiddenControls.find(x => { return x.Name == this.config.DocumentViewerConfig.Name });
      }
    }

    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }
  }
  ngAfterViewInit() {


    if (this.config) {
      if (!this.config.TimerAferInit)
        this.config.TimerAferInit = 50;
      // if (this.config.TimerAferInit) {
      setTimeout(() => {

        if (this.config.DataSources && this.config.DataSources.length > 0) {
          this.ExcecuteDataSource();
        }
        else
          this.ProcesarItems();

      }, this.config.TimerAferInit);

    }
    else
      this.verAlerta = true;


  }
  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public ButtonClickDescargar(_atachment) {


    let atachment = this.DatosModelo(_atachment);

    // this.items.forEach(item => {
    //   if (item.FirmaHash == dato) {
    //     this.modelo.documento.Extension = item.Extension;
    //     this.modelo.documento.NombreDocumento = item.Nombre;
    //     this.modelo.documento.UbicacionArchivo = item.Ruta;
    //   }
    // });

    let Eventoption = {
      Actions: [{
        ControlDetails: {
          MappingData: [],
          MappingFilter: []
        },
        ControlType: "Action",
        ControlsForHidden: [],
        Guid: "e3e81747-673c-d089-27c0-236ad6370b03",
        IsPost: false,
        MappingValues: [],
        ResultType: "Url",
        SendQueryString: false,
        TimerAction: 0,
        Type: "DownloadAsBase64Document"
      }],
      Class: "ms-Icon ms-Icon--Download",
      IsDivider: false,
      Label: "Descargar Documento",
      Type: "Download"
    };

    this.componentName = "TDocumentoExpediente"
    this.helperActions.ExecuteActionsControl(Eventoption,
      false, atachment, this.modelo, this.permissionsConfig,
      this, null, new EventEmitter<any>(), this.componentsContainer,
      Eventoption, this.modelo.modeloMetadata, null);

  }

  public ButtonClicView(_atachment) {

    let atachment = this.DatosModelo(_atachment);

    let Eventoption = {
      Actions: [{
        ControlDetails: {
          MappingData: [],
          MappingFilter: []
        },
        ControlType: "Action",
        ControlsForHidden: [],
        Guid: "e3e81747-673c-d089-27c0-236ad6370b03",
        IsPost: false,
        MappingValues: [],
        ResultType: "Url",
        SendQueryString: false,
        TimerAction: 0,
        Type: "ViewDocument"
      }],
      Class: "ms-Icon ms-Icon--Download",
      IsDivider: false,
      Label: "Ver Documento",
      Type: "ViewDocument"
    };


    this.componentName = "TDocumentoExpediente"
    this.helperActions.ExecuteActionsControl(Eventoption,
      false, atachment, this.modelo, this.permissionsConfig,
      this, null, new EventEmitter<any>(), this.componentsContainer,
      Eventoption, this.modelo.modeloMetadata, null);

  }

  public DatosModelo(attachment: any) {
    //let atachment =this.items.find(x => {return x.FirmaHash == dato});

    attachment.NombreDocumento = attachment.Nombre;
    attachment.IdDocumentoExpediente = this.modelo.documento.IdDocumentoExpediente;
    attachment.PkValue = this.modelo.documento.IdDocumentoExpediente;
    attachment.UbicacionArchivo = attachment.ConfigTipoUbicacionArchivo ? JSON.stringify(attachment.ConfigTipoUbicacionArchivo) : attachment.Ruta;
    attachment.IdExpediente = this.modelo.documento.IdExpediente;
    attachment.Codigo = this.modelo.documento.Codigo;
    attachment.IdTipoUbicacionArchivo = this.modelo.documento.IdTipoUbicacionArchivo
    return attachment;
  }
  //* END FUNCIONES PERSONALIZADAS

  public OnClick(option: any, attachment) {



    if (this.Disabled)
      return;

    if (this.config.FileModelType == 'General') {
      let modeloToSend = this.saveModelContainer();
      modeloToSend.DownloadFile = attachment;

      if (!modeloToSend.UbicacionArchivo && modeloToSend.ModelContainer && modeloToSend.ModelContainer.currentItem)
        modeloToSend.UbicacionArchivo = modeloToSend.ModelContainer.currentItem.UbicacionArchivo

      this.helperActions.ExecuteActionsControl(option,
        false, modeloToSend, this.modelo, this.permissionsConfig,
        this, null, new EventEmitter<any>(), this.componentsContainer,
        option, this.modelo.modeloMetadata, null);
    }
    else if (this.config.FileModelType == "GeneralFiles" ||
      this.config.FileModelType == "MultipleGeneralFiles") {
      let modeloToSend = this.saveModelContainer();
      modeloToSend.DownloadFile = attachment;

      this.helperActions.ExecuteActionsControl(option,
        false, attachment, attachment.Modelo, this.permissionsConfig,
        this, null, new EventEmitter<any>(), this.componentsContainer,
        option, this.modelo.modeloMetadata, null);
    }
    else {
      this.helperActions.ExecuteActionsControl(option,
        false, attachment, this.modelo, this.permissionsConfig,
        this, null, new EventEmitter<any>(), this.componentsContainer,
        option, this.modelo.modeloMetadata, null);
    }

  }

  GetModel() {
    if (!this.modelo || !this.modelo[this.config.FilesModelContainer])
      return null;
    else {
      return this.modelo[this.config.FilesModelContainer];
    }
  }
  GetPkValue() {
    if (!this.modelo || !this.modelo[this.config.PkValueModelContainer])
      return null;
    else {
      return this.modelo[this.config.PkValueModelContainer][this.config.PkName];
    }
  }

  ProcesarAdjunto(atachment: any, modelo: any) {
    //let atachment =this.items.find(x => {return x.FirmaHash == dato});

    atachment.NombreDocumento = atachment.Nombre;
    atachment[this.config.PkName] = modelo[this.config.PkName];
    atachment.PkValue = atachment[this.config.PkName];
    atachment.UbicacionArchivo = atachment.ConfigTipoUbicacionArchivo ? JSON.stringify(atachment.ConfigTipoUbicacionArchivo) : atachment.Ruta;
    atachment.IdExpediente = modelo.IdExpediente;
    atachment.Codigo = modelo.Codigo;
    atachment.IdTipoUbicacionArchivo = modelo.IdTipoUbicacionArchivo
    return atachment;
  }
  GetExt(filename) {
    var idx = filename.lastIndexOf('.');
    // handle cases like, .htaccess, filename
    return (idx < 1) ? "" : filename.substr(idx + 1);
  }

  public ExcecuteDataSource(filterParent?: any) {
    let obj = this.utility.GetApiModel('ExcecuteDataSource', null);
    this.items = [];
    this.dataSourceResult = [];
    try {
      if (this.config.DataSources.length == 0 || this.notLoad) {

        return;
      }
      // if (filterParent)
      //   this.filterParent = filterParent;
      obj.QueryConfigs = [];

      let index = 0;
      this.config.DataSources.forEach(ds => {


        let queryConfig = this.utility.Clone(ds);
        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = index;
          if (queryConfig.CrudAction == 'GetAll' || queryConfig.CrudAction == 'GetAllOrderBy'
            || queryConfig.CrudAction == 'GetAllOrderByDesc') {
            queryConfig.Entity = {};
          }
        }





        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, queryConfig, null, this, this.parentContext);
          })
        }

        obj.QueryConfigs.push(queryConfig);
        //this.dataSet.push({ index: index, data: null });
        index++;
      });

      this.baseService.MultiGet(obj, null,
        {
          componentName: this.componentName,
          method: 'ExcecuteDataSource',
          controlName: this.config.Name,
          processMessage: this.config.ProcessMessage
        }, this.config.Controller)
        .then(data => {
          index = 0;

          data.Data.forEach(dtt => {

            // this.dataSet[index].data = dtt;
            this.dataSourceResult = this.dataSourceResult.concat(dtt);
            //this.config.DataSources[index].data = dtt;
            index++;

          });

          this.ProcesarItems();
        }).catch(err => {
          this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
          this.utility.VerModalDanger().then(data => { })
        });

    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
      this.utility.VerModalDanger().then(data => { })
    }
  }

  public ProcessToolTip(property, file, currentItem, propertyList) {
    if (this.config[property]) {
      file[property] = this.config[property];
      if (file[property].includes("#")) {
        if (currentItem)
          file[property] = this.utility.ReplaceTextFromModels(currentItem, file[property]);

        file[property] = this.utility.ReplaceTextFromModels(file, file[property]);
        file[property] = this.utility.ReplaceTextFromModels(this.modelo, file[property]);
      }
    }
    if (this.config[propertyList] && this.config[propertyList].length > 0) {
      file[propertyList] = this.config[propertyList];
      file[propertyList].forEach(_toolTip => {
        _toolTip.Class = (_toolTip.IsDivider) ? 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 divider my-3' : 'ms-Grid-col ms-sm12 ms-md12 ms-lg12';
        _toolTip.ToolTipView = _toolTip.ToolTip;
        if (_toolTip.ToolTipView.includes("#")) {
          _toolTip.ToolTipView = this.utility.ReplaceTextFromModels(currentItem, _toolTip.ToolTipView);
          _toolTip.ToolTipView = this.utility.ReplaceTextFromModels(file, _toolTip.ToolTipView);
          _toolTip.ToolTipView = this.utility.ReplaceTextFromModels(this.modelo, _toolTip.ToolTipView);
        }

      });

    }
  }
  public ProcesarItems() {



    if (this.config.HtmlMsg) {

      this.htmlMsg = this.config.HtmlMsg;
      if (this.config.InfoModelsContainer && this.htmlMsg && this.htmlMsg.includes("#")) {
        let modelsnames: Array<any> = this.config.InfoModelsContainer.split(',');
        let tempModelo: any = {};
        modelsnames.forEach(modelName => {
          tempModelo[modelName] = this.modelo[modelName];
        });
        this.htmlMsg = this.utility.ReplaceTextFromModels(tempModelo, this.htmlMsg);
      }
      else {
        if (this.htmlMsg && this.htmlMsg.includes("#"))
          this.htmlMsg = this.utility.ReplaceTextFromModels(this.modelo, this.htmlMsg);

      }
    }

    switch (this.config.FileModelType) {
      case 'GeneralFiles':
      case 'MultipleGeneralFiles':
        this.componentName = this.config.Controller;
        this.config.FilesField = this.config.FilesField || "Archivos";
        this.config.FileNameField = this.config.FileNameField || "Nombre";
        break;
      case 'AllDocuments':
        this.componentName = this.config.Controller;
        this.config.FilesField = this.config.FilesField || "Archivos";
        this.config.FileNameField = this.config.FileNameField || "Nombre";
        break;
      case 'General':
        this.componentName = "General"
        this.config.FilesField = this.config.FilesField || "UbicacionArchivo";
        this.config.FileNameField = this.config.FileNameField || "Nombre";
        break;
      case 'Documents':
        this.componentName = "TDocumentoExpediente"
        this.config.PkName = this.config.PkName || "IdDocumentoExpediente";
        this.config.FilesField = this.config.FilesField || "UbicacionArchivo";
        this.config.FileNameField = this.config.FileNameField || "NombreDocumento";
        break;
      case 'Attachments':
        this.componentName = "TDocumentoExpediente"
        this.config.PkName = this.config.PkName || "IdDocumentoExpediente";
        this.config.FilesField = this.config.FilesField || "ConfiguracionAdicional";
        this.config.FileNameField = this.config.FileNameField || "Nombre";

        break;
    }
    if (!this.config.FilesModelContainer && !this.config.PkValueModelContainer) {
      this.config.FilesModelContainer = "modelo";
      this.config.PkValueModelContainer = "modelo";
    }
    if (!this.config.FilesModelContainer)
      this.config.FilesModelContainer = this.config.PkValueModelContainer;
    else if (!this.config.PkValueModelContainer)
      this.config.PkValueModelContainer = this.config.FilesModelContainer;
    let modelo = this.GetModel();
    let pkValue = this.GetPkValue();
    if (modelo && modelo[this.config.FilesField] || (this.dataSourceResult && this.dataSourceResult.length > 0)) {
      this.items = [];
      let fileContainer: any = null;

      switch (this.config.FileModelType) {


        case 'GeneralFiles':
        case 'MultipleGeneralFiles':
          if (this.dataSourceResult && this.dataSourceResult.length > 0) {
            let allFiles: Array<any> = [];
            this.dataSourceResult.forEach(currentItem => {

              if (currentItem[this.config.FilesField]) {
                let files = JSON.parse(currentItem[this.config.FilesField]);

                if (files && files.length > 0) {
                  // files = files.filter(x => { return x.EsPlantilla == false });
                  files.forEach(file => {
                    file[this.config.PkName] = currentItem[this.config.PkName];
                    file.PkValue = currentItem[this.config.PkName];
                    file.Image = this.helperImages.GetRuleIcon(this.GetExt(file[this.config.FileNameField]));
                    file.Title = file[this.config.FileNameField];
                    file.FileModelType = this.config.FileModelType;
                    file.FileToSearch = (this.config.GuidField) ? file[this.config.GuidField] : file[this.config.FileNameField];
                    file.Modelo = currentItem;
                    file.ToolTip = "";
                    file.ToolTipImage = "";


                    this.ProcessToolTip('ToolTip', file, currentItem, 'ToolTips');
                    this.ProcessToolTip('ToolTipImage', file, currentItem, 'ToolTipsImage');
                    // if (this.config.ToolTip) {
                    //   file.ToolTip = this.config.ToolTip;
                    //   if (file.ToolTip.includes("#")) {
                    //     file.ToolTip = this.utility.ReplaceTextFromModels(currentItem, file.ToolTip);
                    //     file.ToolTip = this.utility.ReplaceTextFromModels(this.modelo, file.ToolTip);
                    //   }
                    // }
                    // if (this.config.ToolTips && this.config.ToolTips.length > 0) {

                    //   this.config.ToolTips.forEach(_toolTip => {
                    //     _toolTip.ToolTipView = _toolTip.ToolTip;
                    //     if (_toolTip.ToolTipView.includes("#")) {
                    //       _toolTip.ToolTipView = this.utility.ReplaceTextFromModels(currentItem, _toolTip.ToolTipView);
                    //       _toolTip.ToolTipView = this.utility.ReplaceTextFromModels(this.modelo, _toolTip.ToolTipView);
                    //     }

                    //   });

                    // }
                    // if (this.config.ToolTipImage) {
                    //   file.ToolTipImage = this.config.ToolTipImage;
                    //   if (file.ToolTipImage.includes("#")) {
                    //     file.ToolTipImage = this.utility.ReplaceTextFromModels(currentItem, file.ToolTipImage);
                    //     file.ToolTipImage = this.utility.ReplaceTextFromModels(this.modelo, file.ToolTipImage);
                    //   }
                    // }

                  });
                  allFiles = allFiles.concat(files);
                }
              }
            });

            this.items = allFiles;
          }
          else {
            fileContainer = JSON.parse(modelo[this.config.FilesField]);

            fileContainer.Files.forEach(file => {
              file[this.config.PkName] = pkValue;
              file.PkValue = pkValue;
              file.Image = this.helperImages.GetRuleIcon(this.GetExt(file[this.config.FileNameField]));
              file.Title = file[this.config.FileNameField];
              file.FileModelType = this.config.FileModelType;
              this.ProcessToolTip('ToolTip', file, null, 'ToolTips');
              this.ProcessToolTip('ToolTipImage', file, null, 'ToolTipsImage');
            });

            this.items = fileContainer.Files;
          }
          break;
        case 'AllDocuments':
          if (this.dataSourceResult && this.dataSourceResult.length > 0) {
            let allFiles: Array<any> = [];
            this.dataSourceResult.forEach(currentItem => {

              if (currentItem[this.config.FilesField]) {
                let files = JSON.parse(currentItem[this.config.FilesField]);

                if (files && files.length > 0) {
                  files.forEach(file => {
                    file[this.config.PkName] = pkValue;
                    file.PkValue = pkValue;
                    file.Image = this.helperImages.GetRuleIcon(this.GetExt(file[this.config.FileNameField]));
                    file.Title = file[this.config.FileNameField];
                    this.ProcessToolTip('ToolTip', file, currentItem, 'ToolTips');

                    this.ProcessToolTip('ToolTipImage', file, currentItem, 'ToolTipsImage');
                  });

                  allFiles = allFiles.concat(files);
                }
              }
            });

            this.items = allFiles;
          }
          else {
            fileContainer = JSON.parse(modelo[this.config.FilesField]);

            fileContainer.Files.forEach(file => {
              file[this.config.PkName] = pkValue;
              file.PkValue = pkValue;
              file.Image = this.helperImages.GetRuleIcon(this.GetExt(file[this.config.FileNameField]));
              file.Title = file[this.config.FileNameField];
              file.FileModelType = this.config.FileModelType;
              this.ProcessToolTip('ToolTip', file, null, 'ToolTips');
              this.ProcessToolTip('ToolTipImage', file, null, 'ToolTipsImage');
            });

            this.items = fileContainer.Files;
          }
          break;
        case 'General':
          let jsonString = modelo[this.config.FilesField];
          let escapedJsonString = this.escapeBackslashes(jsonString);
          fileContainer = JSON.parse(escapedJsonString);

          fileContainer.Files.forEach(file => {
            file[this.config.PkName] = pkValue;
            file.PkValue = pkValue;
            file.Image = this.helperImages.GetRuleIcon(file.Extension);
            file.Title = file[this.config.FileNameField];
            this.ProcessToolTip('ToolTip', file, null, 'ToolTips');
            this.ProcessToolTip('ToolTipImage', file, null, 'ToolTipsImage');
          });

          this.items = fileContainer.Files;
          break;
        case 'Documents':
          let file: any = {};
          file.NombreDocumento = modelo.NombreDocumento;
          file[this.config.PkName] = modelo[this.config.PkName];
          file.PkValue = file[this.config.PkName];
          file.UbicacionArchivo = modelo.UbicacionArchivo;
          file.IdExpediente = modelo.IdExpediente;
          file.Codigo = modelo.Codigo;
          file.IdTipoUbicacionArchivo = modelo.IdTipoUbicacionArchivo
          file.Image = this.helperImages.GetRuleIcon(modelo.Extension);
          file.Title = file[this.config.FileNameField];
          this.items.push(file);
          break;
        case 'Attachments':


          fileContainer = JSON.parse(modelo[this.config.FilesField]);

          fileContainer.Files.forEach(file => {

            this.ProcesarAdjunto(file, modelo);
            file.Image = this.helperImages.GetRuleIcon(file.Extension);
            file.Title = file[this.config.FileNameField];
          });

          this.items = fileContainer.Files;
          break;
      }

      fileContainer = null;

    }

    this.verAlerta = true;
  }

  removeItem() {

  }
  // openTooltip(e: Event) {
  //   const item = $(e.currentTarget);
  //   const tooltip = item.find(".att__tooltip");
  //   const gap = 30 + (item.hasClass("att__title") ? 30 : 0);
  //   if (item.offset().top > $(window).height() / 2) {
  //     tooltip.css("top", item.offset().top - 10);
  //   } else {
  //     tooltip.css("top", item.offset().top - 10);
  //     tooltip.css("left", item.offset().left - gap - tooltip.outerWidth(true));
  //   }
  // }

  openTooltip(e: Event) {
    const item = $(e.currentTarget);
    const tooltip = item.find(".att__tooltip");
    const gap = 5;
    console.log("offset top: " + item.parent().offset().top);
    console.log("position top: " + item.parent().position().top);
    console.log("window scroll: " + $(window).scrollTop());
    // if element have space to the sides
    if ($(window).width() - item.parent().outerWidth() > tooltip.outerWidth(true)) {
      // if element have space to the left side
      if (item.offset().left >= tooltip.outerWidth(true)) {
        tooltip.css("left", item.parent().offset().left - gap - tooltip.outerWidth(true));
      } else {
        tooltip.css("left", item.parent().offset().left + item.parent().outerWidth(true) + gap);
      }
      //if element is down
      if (item.parent().offset().top > $(window).height() / 2) {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop() - tooltip.outerHeight() + item.parent().outerHeight());
      } else {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop());
      }
    } else {
      tooltip.css("left", item.parent().offset().left);
      //if element is down
      if (item.parent().offset().top > $(window).height() / 2) {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop() - tooltip.outerHeight(true) - gap);
      } else {
        tooltip.css("top", item.parent().offset().top - $(window).scrollTop() + item.parent().outerHeight() + gap);
      }
    }
  }
  escapeBackslashes(jsonString) {
    return jsonString.replace(/\\/g, '\\\\');
  }


}
