import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { NavItem } from '../menu-item/nav-item';
import { GeneralComunicationService } from '../../services/general-comunication.service';
import { Router } from '@angular/router';
import { ModalLoginComponent } from '../modal-login/modal-login.component';
import { StaticData } from 'src/app/helpers/static-data';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalLoginComponent, { static: true }) modalLogin: ModalLoginComponent;
  navItems: NavItem[] = [];
  verIDE: boolean = false;
  textoLogin = 'Login';
  constructor(public comunicationService: GeneralComunicationService, public router: Router) {

  }

  public NavegarLogin() {
    
    // this.modalLogin.open();
    if (this.textoLogin == 'Login') {
      this.comunicationService.raiseLoginEvent();
      this.textoLogin = 'LogOut'
    }
    else
    {
      this.LimpiarLogin();
    }
  }
  public LimpiarLogin(){

    this.textoLogin = 'Login';
    this.navItems=[];
    StaticData.Usuario = null;
    StaticData.Estilos ={};
    StaticData.ConfigPaginas = [];
    this.router.navigate(['Home']);
  }
  public Navegar(item: NavItem) {
   
    this.router.navigate([item.menu.Ruta]);
  }
  get UsuarioActual(): string {
    return (StaticData.Usuario) ? ' Usuario: ' + StaticData.Usuario.Nombre : 'Usuario:  Anonimo';
  }
  ngOnInit() {

    this.comunicationService.receivedNavItemsEvent.subscribe(navItems => {
      this.navItems = navItems;
      this.verIDE = true;
    });

    this.comunicationService.receivedLoginErrorEvent.subscribe(x => {
      this.LimpiarLogin();
    });
    /*  this.comunicationService.receivedLoginEvent.subscribe(data => {
       this.modalLogin.open();
     }); */
  }

  ngAfterViewInit() {

  }


}
