import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
declare var fabric: any;
@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.css']
})
export class ProgressIndicatorComponent implements OnInit, AfterViewInit {
  @Input() parentName: string;
  @Input() config: any;
  @Input() name: string = "Genera";
  @Input() label: string = "procesando";
  @Input() automatic: boolean = true;
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  public ProgressIndicator: any;
  public ProgressIndicatorElement: any;
  progress = 0;
  percent = 1;
  constructor() { }

  ngOnInit() {
    if (!this.name)
      this.name = 'ProgressIndicator_' + Guid.create().toString();
  }
  ngAfterViewInit() {


    this.ProgressIndicatorElement = document.querySelector("#" + this.name);
    this.ProgressIndicator = new fabric['ProgressIndicator'](this.ProgressIndicatorElement);
    let i = 60;
    if (this.automatic) {
      //this.ProgressIndicator.setTotal(100);
      //  this.Progress() this.Start();;
      this.Percent();
     
    }

  }
  public Stop() {
    this.ProgressIndicator.setProgressPercent(0);
  }
  public Start() {
    this.ProgressIndicator.setProgressPercent(1);
  }
  Percent() {
    setTimeout(() => {
      if (this.automatic) {
        this.ProgressIndicator.setProgressPercent(this.percent);
       
        if (this.percent ==1) {
          this.percent = 0;
        }
        else {
          this.percent = 1;
        }
        this.Percent();
      }
      else {
        this.Stop();
      }
    }, 1000);
  }
  Progress() {
    setTimeout(() => {
      if (this.automatic) {
        this.progress++;
        this.ProgressIndicator.setProgress(this.progress);
        if (this.progress >= 100) {
          this.progress = 0;

          this.Progress();
        }
        else {
          this.Progress();
        }
      }
      else {
        return;
      }
    }, 100);
  }
  public SetProgressPercent(value: number) {
    this.ProgressIndicator.setProgressPercent(value);
  }
  public SetProgress(value: number) {
    this.ProgressIndicator.setProgress(value);
  }
  public SetTotal(value: number) {
    this.ProgressIndicator.setTotal(value);
  }
}
