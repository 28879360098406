import {
  Component, OnInit, ViewChild, AfterViewInit, ElementRef, AfterViewChecked, Input,
  Output, EventEmitter, QueryList, forwardRef, Inject, OnDestroy
} from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { MatList } from '@angular/material';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem }
  from '@angular/cdk/drag-drop';
import { DockManager } from "dock-spawn-ts/lib/js/DockManager";
import { PanelContainer } from "dock-spawn-ts/lib/js/PanelContainer";
import { PanelType } from "dock-spawn-ts/lib/js/enums/PanelContainerType";
import { StaticData } from '../../../helpers/static-data';
import { ConfigWindow } from '../../../models/config-window';
import { Guid } from 'guid-typescript';
import { QueryBuilderConfig, QueryBuilderComponent } from 'angular2-query-builder';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, QueryConfigIDETypes, EntityMetadataTypes
} from '../../../models/general.enum';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BaseCrud } from '../../../bussines/base-crud';

import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { debug } from 'util';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { PropertyGridControlsComponent } from '../../dynamic-templates/property-grid-controls/property-grid-controls.component';
import { JstreeComponent } from '../../jstree/jstree.component';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';
import { HelperImages } from 'src/app/helpers/helper-images';
@Component({
  selector: 'app-form-tipo-solicitud',
  templateUrl: './form-tipo-solicitud.component.html',
  styleUrls: ['./form-tipo-solicitud.component.scss']
})
export class FormTipoSolicitudComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toolbox_controls', { static: true }) child: ElementRef;
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  @ViewChild(PropertyGridControlsComponent, { static: false }) propertyGridControls: PropertyGridControlsComponent;
  @ViewChild(JstreeComponent, { static: false }) jstree: JstreeComponent;
  @ViewChild("editorAcePrincipal", { static: false }) public editorAcePrincipal: EditorAceComponent;
  @Output() onUpdateEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() onEditParentEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() preSaveEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() entities: Array<any> = [];
  @Input() procedures: Array<any> = [];
  @Input() tiposDatoBasico: Array<any> = [];
  @Input() formControls: Array<any> = [];
  @Input() formStateControls: Array<any> = [];

  public direction: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'horizontal';
  EstiloBuscarContenedor: any = { height: "600px" };
  @Input() project: any;
  @Input() isCustomComponent: boolean = false;

  @Input() IsEdit: boolean = false;

  label: string = "Metadatos";
  labelAdd: string = "Adicionar o Actualizar en Metadatos";

  controlesBasiscos: Array<any> = [
    {
      "Type": "text",
      "Icon": "fa-font",
      "Label": "Text",
      "ControlType": "TextBox"
    },
    {
      "Type": "email",
      "Icon": "fa-envelope",
      "Label": "Email",
      "ControlType": "EmailBox"
    },
    {

      "Type": "phone",
      "Icon": "fa-phone",
      "Label": "Phone",
      "ControlType": "PhoneBox"
    },

    {

      "Type": "number",
      "Icon": "fas fa-th-list",
      "Label": "Number",
      "ControlType": "NumberBox"
    },
    {
      "Type": "date",
      "Icon": "fa-calendar",
      "Label": "Date",
      "ControlType": "DateBox"

    },
    {
      "Type": "datetime-local",
      "Icon": "fa-calendar",
      "Label": "DateTime",
      "ControlType": "DateTimeBox"
    },
    {
      "Type": "textarea",
      "Icon": "fa-text-width",
      "Label": "TextArea",
      "ControlType": "TextArea"
    },
    {
      "Type": "select",
      "Label": "ComboBox",
      "Icon": "fa-list",
      "ControlType": "ComboBox"
    },
    // {
    //     "Type": "select",
    //     "Label": "MultiComboBox",
    //     "Icon": "fas fa-th-list",
    //     "ControlType": "MultiComboBox"
    // },


    {
      "Type": "checkbox",
      "Label": "Checkbox",
      "Icon": "far fa-check-square",
      "ControlType": "CheckBox"
    },
    // {
    //     "Type": "radio",
    //     "Icon": "fa-list-ul",
    //     "Label": "Radio",
    //     "ControlType": "RadioBox"
    // },
    // {
    //     "Type": "file",
    //     "Icon": "fa-file",
    //     "Label": "File Upload",
    //     "ControlType": "FileBox"
    // },
    {
      "Type": "Label",
      "Icon": "fas fa-tags",
      "Label": "Label",
      "ControlType": "Label"
    },
    {
      "Type": "Paragraph",
      "Icon": "fa-paragraph",
      "Label": "Paragraph",
      "ControlType": "Paragraph"
    },
    {
      "Type": "H1",
      "Icon": "fas fa-heading",
      "Label": "H1",
      "ControlType": "H1"
    },
    {
      "Type": "H2",
      "Icon": "fas fa-heading",
      "Label": "H2",
      "ControlType": "H2"
    },
    {
      "Type": "H3",
      "Icon": "fas fa-heading",
      "Label": "H3",
      "ControlType": "H3"
    },
    {
      "Type": "H4",
      "Icon": "fas fa-heading",
      "Label": "H4",
      "ControlType": "H4"
    },
    {
      "Type": "H5",
      "Icon": "fas fa-heading",
      "Label": "H5",
      "ControlType": "H5"
    },

    {
      "Type": "Acoordion",
      "Icon": "fas fa-chevron-up",
      "Label": "Acoordion",
      "ControlType": "Acoordion"
    },
    {
      "Type": "Tabs",
      "Icon": "fas fa-outdent",
      "Label": "Tabs",
      "ControlType": "Tabs"
    },
    {
      "Type": "ContainerBox",
      "Icon": "fas fa-border-style",
      "Label": "ContainerBox",
      "ControlType": "ContainerBox"
    },
    {
      "Type": "Container",
      "Icon": "fas fa-border-style",
      "Label": "ContainerBoxHeader",
      "ControlType": "ContainerBoxHeader",
      "Groups": "Metadatos"
    },
    {
      "Type": "DataTable",
      "Icon": "fas fa-border-style",
      "Label": "GridView",
      "ControlType": "GridView"
    },
    {
      "Type": "CrudButtons",
      "Icon": "fas fa-circle",
      "Label": "CrudButtons",
      "ControlType": "CrudButtons"
    },
    {
      "Type": "Div",
      "Icon": "fas fa-heading",
      "Label": "Div",
      "ControlType": "Div"
    },
    {
      "Type": "I",
      "Icon": "",
      "Label": "I",
      "ControlType": "I"
    }

  ];
  _modelo: any = this.GetModeloBasico();

  _modeloConfiguracionPaginaSolicitud: any = null;//this.GetModeloPagina();
  _modeloConfiguracionPaginaEstadoSolicitud: any = null;// this.GetModeloPagina();
  @Input() UserControls: Array<any> = [];


  _modeloExterno: any = null;
  @Input() set modeloExterno(value: any) {
    if (value) {
      this._modeloExterno = value;// JSON.parse(value);
      if (this._modeloExterno.ConfiguracionPaginaSolicitud)
        this._modeloConfiguracionPaginaSolicitud = JSON.parse(this._modeloExterno.ConfiguracionPaginaSolicitud);
      if (this._modeloExterno.ConfiguracionPaginaEstadoSolicitud)
        this._modeloConfiguracionPaginaEstadoSolicitud = JSON.parse(this._modeloExterno.ConfiguracionPaginaEstadoSolicitud);
    }
  }
  get modeloExterno(): any {
    this._modeloExterno.ConfiguracionPaginaSolicitud = JSON.stringify(this._modeloConfiguracionPaginaSolicitud);
    this._modeloExterno.ConfiguracionPaginaEstadoSolicitud = JSON.stringify(this._modeloConfiguracionPaginaEstadoSolicitud);
    //let temp = { Id: 0, ConfigPage: this._modeloConfiguracionPaginaEstadoSolicitud };
    //this._modeloExterno.Configuracion = JSON.stringify(temp);
    return this._modeloExterno;// JSON.stringify(this._modeloExterno);
  }
  //@Input() UserControl: any;
  @Input() set modeloJson(value: any) {
    if (value) {
      this._modelo = JSON.parse(value);


    }
  }
  get modeloJson(): any {
    return JSON.stringify(this.modelo);
  }
  @Input() set modelo(value: any) {
    this._modelo = value;
  }
  get modelo(): any {

    this.ValidarModelo();
    return this._modelo;
  }

  ValidarModelo() {
    if (!this._modelo)
      this._modelo = { Id: 0, Controls: [], StateControls: [] };
  }
  @Input() componentName: string;
  currentBusyRecord: any = null;




  @Input() set CurrentBusyRecord(value: any) {
    this.currentBusyRecord = value;
    if (this.currentBusyRecord)
      localStorage.setItem('Metadata_CurrentBusyRecord', JSON.stringify(this.currentBusyRecord));
    else
      localStorage.setItem('Metadata_CurrentBusyRecord', null);
  }
  get CurrentBusyRecord(): any {
    if (this.currentBusyRecord)
      return this.currentBusyRecord;
    else {
      const _currentBusyRecord = localStorage.getItem('Metadata_CurrentBusyRecord');
      if (_currentBusyRecord)
        this.currentBusyRecord = JSON.parse(_currentBusyRecord);
      return this.currentBusyRecord;
    }
  }

  Estilos: any;

  listToConnect: Array<any> = ['drop-list-form', 'drop-list-state-form', 'drop-list-form-metadata', 'drop-list-extra-metadata', 'drop-list-meta-metadata', 'drop-list-list-metadata', 'drop-list-metadata'];
  adminPermisos: boolean = true;
  constructor(public baseService: BaseServiceService, public utility: Utilities, public helperImages: HelperImages) {

  }

  public SetFormControls() {
    this.formControls = this.utility.GetAllControls(this._modeloConfiguracionPaginaSolicitud.Controls);
    this.formStateControls = this.utility.GetAllControls(this._modeloConfiguracionPaginaEstadoSolicitud);
  }
  public VerEditor(tipo: any) {


    // this.utility.VerEditor(tipo, this.UserControl.ConfigPage, false, "Editor Config [" + this.UserControl.Nombre + ']').then(data => {

    // });

  }

  public GetModeloBasico() {
    return { Id: 0, Controls: [], StateControls: [], Title: null, Title2: null };
  }

  public GetModeloPagina() {
    return {
      "Filter": null,
      "ChildPages": [],
      "QuerysByRole": [],
      "RoleFilters": [],
      "HiddenControls": [],
      "ExtraControls": [],
      "Controls": [],
      "Metadata": [],
      "UniqueKeys": [],
      "LoadSettingsByApplication": [],
      "CommandBar": {},
      "Views": [],
    };
  }

  public ControlesBasicosSolicitud() {
    return [

      {
        "Id": "3e02c29f-f6f8-32cb-a6bf-21d576a2b1e3",
        "IdPadre": null,
        "ControlType": "GridView",
        "Title": "",
        "Label": "GridView",
        "ColSpan": 12,
        "KeyCache": null,
        "ConfigCache": null,
        "ConfigControl": {
          "Controller": "General",
          "Params": {},
          "QueryConfig": {
            "Command": "#SP#",
            "IsSp": true,
            "NotSetDefault": true,
            "Entity": { "IdEmpresa": -1 },
            "WithPagination": true,
            "OutPutParam": true,
            "CrudAction": "None",
            "MappingParameters": []
          },
          "NotLoad": false
        },
        "ConfigSearchControl": null,
        "Type": "DataTable",
        "IdQuery": null,
        "Activo": true,
        "Controls": [
          {
            "Guid": "fb06cf58-1b51-32f2-d027-ef1131d7f24a",
            "Title": "",
            "Name": "",
            "DataField": "",
            "CustomName": "",
            "ColumnType": "Edit",
            "Icon": "",
            "Icon2": "",
            "ToolTip": "",
            "ControlType": "Column",
            "SearchField": "ObjType",
            "IconField": "Icon",
            "DataIconField": "",
            "Actions": [],
            "TimerActions": 0,
            "TimerToAllActions": 0,
            "ChildOptions": [],
            "WithSelection": true,
            "Tags": [],
            "IsParentModel": false
          },
          {
            "Guid": "d0e7dfc0-cede-fee7-eebe-6f7dbd76fdb6",
            "Title": "Id",
            "Name": "",
            "DataField": "#PkName#",
            "CustomName": "#PkName#",
            "ColumnType": 0,
            "Icon": "",
            "Icon2": "",
            "ToolTip": "",
            "ControlType": "Column",
            "SearchField": "",
            "IconField": "",
            "DataIconField": "",
            "Actions": [],
            "TimerActions": 0,
            "TimerToAllActions": 0,
            "ChildOptions": [],
            "Tags": [],
            "Hidden": false
          }

        ],
        "Name": "#NameTable#",
        "ProcessMessage": "",
        "PkName": "#PkName#",
        "ModelContainer": "modeloVirtual",
        "IsParentModel": false,
        "QuickFilters": [],
        "Notifications": [],
        "TitleField": "Titulo",
        "MsgField": "Msg",
        "TypeField": "Tipo",
        "ExportButtons": [],
        "ClassAlerts": [],
        "Categories": [],
        "CategoryField": ""
      },

      {
        "Id": "bf8fd3da-3b3e-bfe6-4b6e-75e8cba74cdc",
        "IdPadre": null,
        "ControlType": "SidePanel",
        "Title": "",
        "Label": "#LabelPanel#",
        "ColSpan": 12,
        "Type": "Container",
        "Activo": true,
        "Controls": [
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "#PkName#",
            "Label": "#PkName#",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": true,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "#PkName#"
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "IdEmpresa",
            "Label": "IdEmpresa",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "IdEmpresa"
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "IdTipoSolicitud",
            "Label": "IdTipoSolicitud",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "IdTipoSolicitud"
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "IdEstadoSolicitud",
            "Label": "IdEstadoSolicitud",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "IdEstadoSolicitud"
          },
          {
            "Id": "58ea9895-4365-4874-83af-f5c3adcf86f5",
            "IdPadre": null,
            "Name": "PalabrasClave",
            "MinLength": 0,
            "MaxLength": 1000,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "[ñáéíóúA-Za-z0-90-9 ]+",
            "ControlType": "TextArea",
            "DataType": "String",
            "IsFk": false,
            "ModelContainer": "modelo",
            "Title": "Campo PalabrasClave",
            "Placeholder": "Digite PalabrasClave",
            "Label": "PalabrasClave",
            "ColSpan": 12,
            "Order": 4,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": "",
            "ConfigSearchControl": "",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "text",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "IsParentModel": false,
            "DisabledPaste": false
          },
          {
            "Id": "58ea9895-4365-4874-83af-f5c3adcf86f5",
            "IdPadre": null,
            "Name": "Asunto",
            "MinLength": 0,
            "MaxLength": 5000,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "[ñáéíóúA-Za-z0-90-9 ]+",
            "ControlType": "TextArea",
            "DataType": "String",
            "IsFk": false,
            "ModelContainer": "modelo",
            "Title": "Campo Asunto",
            "Placeholder": "Digite Asunto",
            "Label": "Asunto",
            "ColSpan": 12,
            "Order": 4,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": "",
            "ConfigSearchControl": "",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "text",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "IsParentModel": false,
            "DisabledPaste": false
          },
          {
            "Id": "d1ed73cf-4fe0-4a13-bafd-fbcb52a7cc2c",
            "IdPadre": null,
            "Name": "Aprobado",
            "MinLength": 0,
            "MaxLength": 200000,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "",
            "ControlType": "CheckBox",
            "DataType": "Boolean",
            "IsFk": false,
            "ModelContainer": "modelo",
            "Title": "Aprobado",
            "Placeholder": "Aprobado",
            "Label": "Aprobado",
            "ColSpan": 4,
            "Order": 5,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": "",
            "ConfigSearchControl": "",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "checkbox",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "IsParentModel": false,
            "Hidden": false
          },
          {
            "Id": "2918a605-d8a6-48e8-b372-8b77fb2d399a",
            "IdPadre": null,
            "Name": "IdCausalRechazo",
            "MinLength": 0,
            "MaxLength": 10,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "",
            "ControlType": "ComboBox",
            "DataType": "Int32",
            "IsFk": true,
            "ModelContainer": "modelo",
            "Title": "Campo Causal de Rechazo",
            "Placeholder": "Causal de Rechazo",
            "Label": "Causal de Rechazo",
            "ColSpan": "6",
            "Order": 0,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": {
              "Controller": "General",
              "Params": {},
              "QueryConfig": {
                "Command": "",
                "IsSp": false,
                "Entity": {},
                "WithPagination": false,
                "OutPutParam": false,
                "CrudAction": "GetByFilter",
                "EntityName": "RCausalRechazo",
                "Filter": "Activo=1 AND IdEmpresa=-1 AND IdTipoSolicitud=-1",
                "MappingParameters": []
              },
              "NotLoad": false
            },
            "ConfigSearchControl": null,
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "Select",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "PkField": "IdCausalRechazo",
            "TextField": "Nombre",
            "IsParentModel": false,
            "Hidden": false
          },
          {
            "Id": "58ea9895-4365-4874-83af-f5c3adcf86f5",
            "IdPadre": null,
            "Name": "Observaciones",
            "MinLength": 0,
            "MaxLength": 700,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "[ñáéíóúA-Za-z0-90-9 ]+",
            "ControlType": "TextArea",
            "DataType": "String",
            "IsFk": false,
            "ModelContainer": "modelo",
            "Title": "Campo Observaciones",
            "Placeholder": "Digite Observaciones",
            "Label": "Asunto",
            "ColSpan": 12,
            "Order": 4,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": "",
            "ConfigSearchControl": "",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "text",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "IsParentModel": false,
            "DisabledPaste": false
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "IdUsuarioResponsable",
            "Label": "IdUsuarioResponsable",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "IdUsuarioResponsable"
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "IdUsuarioGestionActual",
            "Label": "IdUsuarioGestionActual",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "IdUsuarioGestionActual"
          },


          {
            "Id": "bb465df8-6203-47e9-84d7-9b1581c0c312",
            "IdPadre": null,
            "Name": "Activo",
            "MinLength": 0,
            "MaxLength": 200000,
            "Min": 0,
            "Max": 0,
            "Required": true,
            "Pattern": "[ñáéíóúA-Za-z0-90-9 ]+",
            "ControlType": "CheckBox",
            "DataType": "Boolean",
            "IsFk": false,
            "ModelContainer": "modelo",
            "Title": "Campo Activo",
            "Placeholder": "Digite Activo",
            "Label": "Activo",
            "ColSpan": 4,
            "Order": 14,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": "",
            "ConfigSearchControl": "",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "checkbox",
            "IdQuery": 0,
            "Mask": null,
            "Value": "",
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": []
          },
          {
            "Id": "883bff68-3867-cf2b-55f9-2a8a4f7c836a",
            "IdPadre": null,
            "ControlType": "CrudButtons",
            "Title": "",
            "Label": "CrudButtons",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "CrudButtons",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "String",
            "GeneralDataType": "String",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": null,
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modeloVirtual",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "883bff68-3867-cf2b-55f9-2a8a4f7c836a",
            "LabelSave": "Guardar",
            "LabelClear": "Limpiar",
            "NombreBotonGuardar": "btnGuardar",
            "NombreBotonLimpiar": "btnLimpiar",
            "ShortcutSave": "Alt+S",
            "ShortcutClear": "Alt+L"
          }
        ],
        "Actions": [],
        "Permissions": [],
        "Configs": [],
        "IsMultiple": false,
        "ModelContainer": "modelo",
        "Name": "#NamePanel#",
        "Class": "ms-Panel ms-Panel--xl"
      }
    ];
  }

  public ControlesBasicosSolicitudEstado() {
    return [

      {
        "Id": "3e02c29f-f6f8-32cb-a6bf-21d576a2b1e3",
        "IdPadre": null,
        "ControlType": "GridView",
        "Title": "",
        "Label": "GridView",
        "ColSpan": 12,
        "KeyCache": null,
        "ConfigCache": null,
        "ConfigControl": {
          "Controller": "General",
          "Params": {},
          "QueryConfig": {
            "Command": "#SP#",
            "IsSp": true,
            "NotSetDefault": true,
            "Entity": { "IdEmpresa": -1 },
            "WithPagination": true,
            "OutPutParam": true,
            "CrudAction": "None",
            "MappingParameters": []
          },
          "NotLoad": false
        },
        "ConfigSearchControl": null,
        "Type": "DataTable",
        "IdQuery": null,
        "Activo": true,
        "Controls": [
          {
            "Guid": "fb06cf58-1b51-32f2-d027-ef1131d7f24a",
            "Title": "",
            "Name": "",
            "DataField": "",
            "CustomName": "",
            "ColumnType": "Edit",
            "Icon": "",
            "Icon2": "",
            "ToolTip": "",
            "ControlType": "Column",
            "SearchField": "ObjType",
            "IconField": "Icon",
            "DataIconField": "",
            "Actions": [],
            "TimerActions": 0,
            "TimerToAllActions": 0,
            "ChildOptions": [],
            "WithSelection": true,
            "Tags": [],
            "IsParentModel": false
          },
          {
            "Guid": "d0e7dfc0-cede-fee7-eebe-6f7dbd76fdb6",
            "Title": "Id",
            "Name": "",
            "DataField": "#PkName#",
            "CustomName": "#PkName#",
            "ColumnType": 0,
            "Icon": "",
            "Icon2": "",
            "ToolTip": "",
            "ControlType": "Column",
            "SearchField": "",
            "IconField": "",
            "DataIconField": "",
            "Actions": [],
            "TimerActions": 0,
            "TimerToAllActions": 0,
            "ChildOptions": [],
            "Tags": [],
            "Hidden": false
          }

        ],
        "Name": "#NameTable#",
        "ProcessMessage": "",
        "PkName": "#PkName#",
        "ModelContainer": "modeloVirtual",
        "IsParentModel": false,
        "QuickFilters": [],
        "Notifications": [],
        "TitleField": "Titulo",
        "MsgField": "Msg",
        "TypeField": "Tipo",
        "ExportButtons": [],
        "ClassAlerts": [],
        "Categories": [],
        "CategoryField": ""
      },

      {
        "Id": "bf8fd3da-3b3e-bfe6-4b6e-75e8cba74cdc",
        "IdPadre": null,
        "ControlType": "SidePanel",
        "Title": "",
        "Label": "#LabelPanel#",
        "ColSpan": 12,
        "Type": "Container",
        "Activo": true,
        "Controls": [
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "#PkName#",
            "Label": "#PkName#",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": true,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "#PkName#"
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "IdEmpresa",
            "Label": "IdEmpresa",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "IdEmpresa"
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "#FkName#",
            "Label": "",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "#FkName#"
          },
          {
            "Id": "627fbb79-61cb-66ba-0990-c2459a976ae1",
            "IdPadre": null,
            "ControlType": "HiddenField",
            "Title": "IdEstadoSolicitud",
            "Label": "IdEstadoSolicitud",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "hidden",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "Int32",
            "GeneralDataType": "Int32",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": "-1",
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modelo",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "IdEstadoSolicitud"
          },


          {
            "Id": "d1ed73cf-4fe0-4a13-bafd-fbcb52a7cc2c",
            "IdPadre": null,
            "Name": "Aprobado",
            "MinLength": 0,
            "MaxLength": 200000,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "",
            "ControlType": "CheckBox",
            "DataType": "Boolean",
            "IsFk": false,
            "ModelContainer": "modelo",
            "Title": "Aprobado",
            "Placeholder": "Aprobado",
            "Label": "Aprobado",
            "ColSpan": 4,
            "Order": 5,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": "",
            "ConfigSearchControl": "",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "checkbox",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "IsParentModel": false,
            "Hidden": false
          },
          {
            "Id": "2918a605-d8a6-48e8-b372-8b77fb2d399a",
            "IdPadre": null,
            "Name": "IdCausalRechazo",
            "MinLength": 0,
            "MaxLength": 10,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "",
            "ControlType": "ComboBox",
            "DataType": "Int32",
            "IsFk": true,
            "ModelContainer": "modelo",
            "Title": "Campo Causal de Rechazo",
            "Placeholder": "Causal de Rechazo",
            "Label": "Causal de Rechazo",
            "ColSpan": "10",
            "Order": 0,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": {
              "Controller": "General",
              "Params": {},
              "QueryConfig": {
                "Command": "",
                "IsSp": false,
                "Entity": {},
                "WithPagination": false,
                "OutPutParam": false,
                "CrudAction": "GetByFilter",
                "EntityName": "RCausalRechazo",
                "Filter": "Activo=1 AND IdEmpresa=-1 AND IdTipoSolicitud=-1",
                "MappingParameters": []
              },
              "NotLoad": false
            },
            "ConfigSearchControl": null,
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "Select",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "PkField": "IdCausalRechazo",
            "TextField": "Nombre",
            "IsParentModel": false,
            "Hidden": false
          },
          {
            "Id": "58ea9895-4365-4874-83af-f5c3adcf86f5",
            "IdPadre": null,
            "Name": "Observaciones",
            "MinLength": 0,
            "MaxLength": 700,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "Pattern": "[ñáéíóúA-Za-z0-90-9 ]+",
            "ControlType": "TextArea",
            "DataType": "String",
            "IsFk": false,
            "ModelContainer": "modelo",
            "Title": "Campo Observaciones",
            "Placeholder": "Digite Observaciones",
            "Label": "Observaciones",
            "ColSpan": 12,
            "Order": 4,
            "Rows": 0,
            "Cols": 0,
            "KeyCache": "",
            "ConfigCache": null,
            "ViewSearchControl": false,
            "ViewSearchButton": true,
            "ConfigControl": "",
            "ConfigSearchControl": "",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "JsonEditor": false,
            "AceEditor": false,
            "Type": "text",
            "IdQuery": 0,
            "Mask": null,
            "Value": null,
            "IdPaginaAdministracion": 0,
            "ViewAdminButton": false,
            "Activo": true,
            "Controls": [],
            "IsParentModel": false,
            "DisabledPaste": false
          },

          {
            "Id": "883bff68-3867-cf2b-55f9-2a8a4f7c836a",
            "IdPadre": null,
            "ControlType": "CrudButtons",
            "Title": "",
            "Label": "CrudButtons",
            "ColSpan": 12,
            "KeyCache": null,
            "ConfigCache": null,
            "ConfigControl": null,
            "ConfigSearchControl": null,
            "Type": "CrudButtons",
            "IdQuery": null,
            "Activo": true,
            "Controls": [],
            "IsPk": false,
            "MinLength": 0,
            "MaxLength": 100,
            "Min": 0,
            "Max": 0,
            "Required": false,
            "DataType": "String",
            "GeneralDataType": "String",
            "Placeholder": "",
            "Order": 0,
            "Rows": null,
            "Cols": null,
            "ViewSearchControl": false,
            "ViewSearchButton": false,
            "Mask": null,
            "Value": null,
            "Identity": false,
            "IsFk": false,
            "ModelContainer": "modeloVirtual",
            "HtmlEditor": false,
            "MultilineEditor": false,
            "Multiline": false,
            "IdPaginaAdministracion": null,
            "ViewAdminButton": false,
            "Name": "883bff68-3867-cf2b-55f9-2a8a4f7c836a",
            "LabelSave": "Guardar",
            "LabelClear": "Limpiar",
            "NombreBotonGuardar": "btnGuardar",
            "NombreBotonLimpiar": "btnLimpiar",
            "ShortcutSave": "Alt+S",
            "ShortcutClear": "Alt+L"
          }
        ],
        "Actions": [],
        "Permissions": [],
        "Configs": [],
        "IsMultiple": false,
        "ModelContainer": "modelo",
        "Name": "#NamePanel#",
        "Class": "ms-Panel ms-Panel--xl"
      }
    ];
  }

  public GetCommandBarSolicitud() {

    return {
      "Buttons": [
        {
          "Actions": [
            {
              "Type": "OpenSidePanel"
            }
          ],
          "Label": "Nuevo",
          "Class": "ms-Icon ms-Icon--Add",
          "Type": "New",
          "IsDivider": false,
          "Activo": true,
          "HideBySelection": false,
          "NotEmitAction": false,
          "ControlType": "CommandButton",
          "Configs": [],
          "Shortcut": ""
        },
        {
          "Actions": [
            {
              "Type": "OpenSidePanel"
            }
          ],
          "Label": "Editar",
          "Class": "ms-Icon ms-Icon--EditMirrored",
          "Type": "Edit",
          "IsDivider": false,
          "ViewBySelection": true,
          "ControlType": "CommandButton",
          "Shortcut": "Alt+E"
        },
        {
          "Actions": [
            {
              "Type": "ActiveInactive"
            }
          ],
          "Label": "Activar\\Inactivar",
          "Class": "ms-Icon ms-Icon--ActivateOrders",
          "Type": "Active",
          "IsDivider": false,
          "ViewBySelection": true,
          "HideBySelection": false
        }
      ],
      "ModelContainer": "modelo",
      "IsParentModel": false
    };
  }
  InitModelos() {
    this._modeloConfiguracionPaginaSolicitud = this.GetModeloPagina();
    this._modeloConfiguracionPaginaSolicitud.Controls = this.ControlesBasicosSolicitud();
    this._modeloConfiguracionPaginaSolicitud.CommandBar = this.GetCommandBarSolicitud();
    this._modeloConfiguracionPaginaEstadoSolicitud = this.GetModeloPagina();

    this._modeloConfiguracionPaginaEstadoSolicitud.Controls = this.ControlesBasicosSolicitudEstado();
    this._modeloConfiguracionPaginaEstadoSolicitud.IsStatePage = true;
  }
  public LimpiarPagina() {


    this._modelo = this.GetModeloBasico();
    this.InitModelos();
    this.currentFieldModel = null;

    this.selectField({ control: null, index: 0 });
  }


  public UpdateEditor() {

    if (this.editorAcePrincipal)
      this.editorAcePrincipal.Update(this.modelo);

  }
  public PropertyGridChangeHandler(event) {


  }

  ngAfterViewInit() {

  }

  ngOnInit() {


    this.Estilos = StaticData.Estilos;
    this.LimpiarPagina();
    this.CargarEntidadesSP();
    this.CargarTiposDatosBasicos();

    setTimeout(() => {

      this.InitModelos();

    }, 500);
    this.preSaveEmitter.subscribe(event => {

      

      const nombre = this.utility.ToUpperCamelCase(this.modeloExterno.Nombre);
      const nombreEstados = nombre + "Estado";
      this._modeloConfiguracionPaginaSolicitud.Controls[0].ConfigControl.QueryConfig.Command = "Busqueda" + nombre;
      this._modeloConfiguracionPaginaSolicitud.Controls[0].Controls[1].DataField = "Id" + nombre;
      this._modeloConfiguracionPaginaSolicitud.Controls[0].Controls[1].CustomName = "Id" + nombre;
      this._modeloConfiguracionPaginaSolicitud.Controls[0].Name = "DataTable_" + nombre;
      this._modeloConfiguracionPaginaSolicitud.Controls[0].PkName = "Id" + nombre;

      this._modeloConfiguracionPaginaSolicitud.Controls[1].Name = "SidePanel" + nombre;
      this._modeloConfiguracionPaginaSolicitud.Controls[1].Label = "";
      this._modeloConfiguracionPaginaSolicitud.TableName = "Solicitud" + nombre;

      this._modeloConfiguracionPaginaSolicitud.Controls[1].Controls[0].Name = "Id" + nombre;
      this._modeloConfiguracionPaginaSolicitud.Controls[1].Controls[0].Label = "Id" + nombre;
      this._modeloConfiguracionPaginaSolicitud.Controls[1].Controls[0].Title = "Id" + nombre;





      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[0].ConfigControl.QueryConfig.Command = "Busqueda" + nombreEstados;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[0].Controls[1].DataField = "Id" + nombreEstados;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[0].Controls[1].CustomName = "Id" + nombreEstados;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[0].Name = "DataTable_" + nombreEstados;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[0].PkName = "Id" + nombreEstados;

      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Name = "SidePanel" + nombreEstados;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Label = "";


      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Controls[0].Name = "Id" + nombreEstados;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Controls[0].Label = "Id" + nombreEstados;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Controls[0].Title = "Id" + nombreEstados;

      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Controls[2].Name = "Id" + nombre;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Controls[2].Label = "Id" + nombre;
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls[1].Controls[2].Title = "Id" + nombre;

      this._modeloConfiguracionPaginaEstadoSolicitud.TableName = "Solicitud" + nombreEstados;


      const temp = this.modeloExterno;

      this.UpdateEmitter();

    });
    this.onEditParentEmitter.subscribe(event => {

      

      let obj = this.utility.GetApiModel("ConsultaPaginasTipoSolicitud", null);
      obj.QueryConfig = {
        Command: "ConsultaPaginasTipoSolicitud",
        IsSp: true,
        NotSetDefault: true,
        Entity: {
          IdEmpresa: -1,
          IdPaginaSolicitud: event.modelo.modelo.IdPaginaSolicitud,
          IdPaginaGestion: event.modelo.modelo.IdPaginaGestion
        },
      };
      let info = {
        componentName: this.componentName,
        controlName: "ConsultaPaginasTipoSolicitud",
        processMessage: "Cargando Paginas del Tipo de Solicitud......",
      };

      this.baseService
        .Get(obj, null, info, this.componentName, false, false)
        .then((data) => {
          

          if (data.Data[0].Configuracion)
            this._modeloConfiguracionPaginaSolicitud = JSON.parse(data.Data[0].Configuracion);
          if (data.Data[1].Configuracion) {
            this._modeloConfiguracionPaginaEstadoSolicitud = JSON.parse(data.Data[1].Configuracion);
            this._modeloConfiguracionPaginaEstadoSolicitud.IsStatePage = true;
          }


          this._modeloExterno.TituloPaginaSolicitud = data.Data[0].Titulo;
          this._modeloExterno.TituloPaginaEstadoSolicitud = data.Data[1].Titulo;
          const temps = this.modeloExterno;
          this.ValidarExisteSolicitud('T' + data.Data[0].Pagina)
        })
        .catch((err) => {
          this.utility.logger.LogError(err, obj, {
            componentName: this.componentName,
            method: "ConsultaPaginasTipoSolicitud",
          });
        });
    });

  }

  public ValidarExisteSolicitud(nombreTabla) {
    

    let obj = this.utility.GetApiModel("ValidarExisteSolicitud", null);
    obj.QueryConfig = {
      Command: "ValidarExisteSolicitud",
      IsSp: true,
      NotSetDefault: true,
      Entity: {
        IdEmpresa: -1,
        Nombre: nombreTabla
      },
    };
    let info = {
      componentName: this.componentName,
      controlName: "ValidarExisteSolicitud",
      processMessage: "validando SOlicitudes asociadas......",
    };

    this.baseService
      .Get(obj, null, info, this.componentName, false, false)
      .then((data) => {
        

      })
      .catch((err) => {
        this.utility.logger.LogError(err, obj, {
          componentName: this.componentName,
          method: "ValidarExisteSolicitud",
        });
      });
  }
  public CargarTiposDatosBasicos() {




    let obj = this.utility.GetApiModel("ConsultaTipoDatoBasico", null);
    obj.QueryConfig = {
      Command: "ConsultaTipoDatoBasico",
      IsSp: true,
      NotSetDefault: true,
      Entity: {
        IdEmpresa: -1
      },
    };

    let info = {
      componentName: this.componentName,
      controlName: "ConsultaTipoDatoBasico",
      processMessage: "Cargando Tipos de Dato Basico......",
    };


    this.baseService
      .Get(obj, null, info, this.componentName, false, false)
      .then((data) => {
        this.tiposDatoBasico = data.Data;


      });




  }
  public CargarEntidadesSP() {

    try {
      this.baseService.showLoadNotifyType = true;
      let queryConfig = { Command: "ConsultaConsecutivo", IsSp: true, Entity: { IdEmpresa: -1 } }
      this.baseService.ConsultarEntidades(this.componentName, true, queryConfig).then(data => {
        

        let temp = data.Data.Entities;
        this.procedures = data.Data.Procedures;
        //EntityMetadataTypes
        this.entities = new List<any>(temp).Where(function (x) {
          return x.EntityMetadataType == EntityMetadataTypes.Table ||
            x.EntityMetadataType == EntityMetadataTypes.View ||
            x.EntityMetadataType == "Table" || x.EntityMetadataType == "View"
        }).ToArray();;
        this.utility.SetCache('KeyEntidadesNegocio', this.entities);



      }).catch(_error => {

        this.utility.logger.LogError(_error, null, { componentName: this.componentName, method: 'CargarEntidadesSP', controlName: this.componentName });
        this.utility.VerModalDanger().then(data => { }).catch(error => { });
      });
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'CargarEntidadesSP' });
      this.utility.VerModalDanger().then(data => { })
    }
  }
  ngOnDestroy() {
    

  }

  ////////////////// DRAG AND ROP CONTROLS/////////////////////////////////




  _currentIndex;
  _currentField;
  currentFieldModel;
  currentIndexFieldModel;


  dragStart(event: CdkDragStart) {
    // this._currentIndex = this.fieldModels.indexOf(event.source.data); // Get index of dragged type
    //this._currentField = this.child.nativeElement.children[this._currentIndex]; // Store HTML field
  }

  moved(event: CdkDragMove) {
    // Check if stored HTML field is as same as current field

    // if (this.child.nativeElement.children[this._currentIndex] !== this._currentField) {
    //   // Replace current field, basically replaces placeholder with old HTML content
    //   // this.child.nativeElement.replaceChild(this._currentField, this.child.nativeElement.children[this._currentIndex]);
    // }
  }
  itemDroppedControls(event: CdkDragDrop<any[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(this._modeloConfiguracionPaginaSolicitud.Controls, event.previousIndex, event.currentIndex);
    } else {
      this.addField(event.item.data, event.currentIndex, 1);
    }
  }
  itemDroppedStateControls(event: CdkDragDrop<any[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(this._modeloConfiguracionPaginaEstadoSolicitud.Controls, event.previousIndex, event.currentIndex);
    } else {
      this.addField(event.item.data, event.currentIndex, 2);
    }
  }

  UpdateEmitter() {
    this.onUpdateEmitter.emit({
      Configuracion: this.modeloJson,
      ConfiguracionPaginaSolicitud: this._modeloExterno.ConfiguracionPaginaSolicitud,
      ConfiguracionPaginaEstadoSolicitud: this._modeloExterno.ConfiguracionPaginaEstadoSolicitud
    });
  }
  Add() {

    

    this.UpdateEmitter();
  }
  Delete(control, index: number, tipo?: number) {
    
    if (tipo == 2) {
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls.splice(index, 1);
    }
    else
      this._modeloConfiguracionPaginaSolicitud.Controls.splice(index, 1);
    this.UpdateEmitter();
    this.utility.VerModalInfo({ titulo: "Info", descripcion: "Control Eliminado" })
    this.SetFormControls();
  }



  removeField(event, tipo?: number) {
    
    if (tipo === 1)
      this._modeloConfiguracionPaginaSolicitud.Controls.splice(event.index, 1);
    else
      this._modeloConfiguracionPaginaEstadoSolicitud.Controls.splice(event.index, 1);

    this.currentFieldModel = null;
    this.currentIndexFieldModel = null;
    this.UpdateEmitter();
    this.SetFormControls();
  }
  selectField(event: any) {


    this.internalsSelectField({ control: null, index: 0 });
    setTimeout(() => {
      this.internalsSelectField(event);
    }, 50);
  }

  internalsSelectField(event: any) {


    this.currentFieldModel = event.control;// this.model.Controls[event.index];
    this.currentIndexFieldModel = event.index;
    if (this.propertyGridControls)
      this.propertyGridControls.UpdateEditor(this.currentFieldModel);
    if (event.isWindow) {
      this.verProperty(event.index);
    }

  }


  verProperty(index): any {
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Propiedades";
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';
    let windowModel: any = {
      subControl: this.currentFieldModel,
      subControlIndex: this.currentIndexFieldModel
    };

    this.utility.OpenWindow('PropertyGridControlsComponent',
      config, windowModel).then(dataResult => {
        // console.log(dataResult);

        dataResult.onClosePromise.then(dataResultOnClose => {
          // console.log(dataResultOnClose);


        });

      })

  }
  public EditorControlesBasicos() {
    this.utility.VerEditor(2, this.modelo.Controls, true, "Campos basicos").then(dataResult => {
      // console.log(dataResult);

      if (dataResult.dialogResult)
        this.modelo.Controls = dataResult.modelo;

    })
  }
  addFieldEspecifProp(index: number, _currentFieldModel: any, tipo: number) {

    if (tipo === 1) {
      if (index == -1)
        this._modeloConfiguracionPaginaSolicitud.Controls.push(_currentFieldModel);
      else
        this._modeloConfiguracionPaginaSolicitud.Controls.splice(index, 0, _currentFieldModel);
    }
    else {
      if (index == -1)
        this._modeloConfiguracionPaginaEstadoSolicitud.Controls.push(_currentFieldModel);
      else
        this._modeloConfiguracionPaginaEstadoSolicitud.Controls.splice(index, 0, _currentFieldModel);
    }


  }
  addField(fiedlModel: any, index: number, tipo: number) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName

    
    let _currentFieldModel: any = null;
    let currentIndex = -1;

    this.addFieldInternal(fiedlModel, index, tipo, currentIndex);



  }

  addFieldInternal(fiedlModel: any, index: number, tipo: number, currentIndex: number) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName

    let _currentFieldModel: any = null;




    _currentFieldModel = this.utility.GeModelControl(fiedlModel.ControlType, fiedlModel.Type, fiedlModel);

    // if (!_currentFieldModel.Icon) {
    //   let currentIndex = StaticData.ToolBox.Controls.findIndex(x => { return x.ControlType == _currentFieldModel.ControlType });
    //   if (currentIndex > -1) {
    //     _currentFieldModel.Icon = StaticData.ToolBox.Controls[currentIndex].Icon;
    //   }
    // }
    _currentFieldModel.ModelContainer = 'modelo';

    if (tipo === 1) {


      this.addFieldEspecifProp(index, _currentFieldModel, tipo);

    }
    else if (tipo === 2) {



      this.addFieldEspecifProp(index, _currentFieldModel, tipo);

    }
    else if (tipo === 3) {

      // this.addFieldEspecifProp(index, 'Metadata', _currentFieldModel);

    }
    else {


    }
    this.UpdateEmitter();
    this.SetFormControls();
  }

  get GetlistToConnect(): Array<any> {
    return this.listToConnect;
  }


  public CreateListToConnect(name: string) {
    this.listToConnect.splice(0, 0, name);

  }

  optionsAce: any = { printMargin: true };
  public OnChangeHandlerAceEditor(event) {

    //this.UserControl.ConfigPage = event;

  }

  public ExportToJson() {

    const filename = (this.modelo.Nombre) ? this.modelo.Nombre + '.json' : this.modelo.Id + '.json';

    const jsonStr = '';// JSON.stringify(this.UserControl.ConfigPage, null, 2);// JSON.stringify(this.UserControl.ConfigPage);

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  public ImportToJson(evt) {
    try {

      let files = evt.target.files;
      if (!files.length) {
        alert('No selecciono un archivo!');
        return;
      }
      let file = files[0];
      let reader = new FileReader();
      const self = this;
      reader.onload = (event: any) => {



        // this.UserControl.ConfigPage = JSON.parse(event.target.result.toString());

        this.UpdateEditor();
      };
      reader.readAsText(file);
    } catch (err) {
      console.error(err);
    }
  }
}
