import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralConfigComponent } from '../../../general-config/general-config/general-config.component';
import { debug } from 'util';


@Component({
	selector: 'app-metadatos',
	templateUrl: './metadatos.component.html',
	styleUrls: ['./metadatos.component.scss']
})
export class MetadatosComponent extends BaseCrud implements OnInit {

	@ViewChild(GeneralConfigComponent, { static: false })
	generalConfigComponent: GeneralConfigComponent;
	LimpiarModelo: boolean = false;


	UserControl: any = {
		"Label": "",
		"Name": "",
		"Icon": "",
		"ControlType": 'UserControl',
		"Controls": []

	};
	nombrePrevio: string = '';
	constructor(public baseService: BaseServiceService, public utility: Utilities) {

		super(baseService, utility, Paginas.ParametroGeneral, 'RParametroGeneral', null, false);
		this.pkName = 'IdParametroGeneral';
	}

	public onSpecificStart() {
		this.isAddEventsColumns = true;
		this.conFiltroIdEmpresa = true;
		this.configs = {};
		this.columns = [{ data: this.pkName, title: 'Id' },
		{ data: 'Nombre', title: 'Nombre' },
		{ data: 'TipoDato', title: 'Tipo Dato' },
			/* { data: 'ValorEntero', title: 'Valor Entero' },
			{ data: 'ValorNumerico', title: 'Valor Numerico' }, */
			/* 	this.GetBooleanColumn('Valor Falso Verdadero', 'Valor Falso Verdadero', 'ValorFalsoVerdadero', 'IdParametroGeneral', 'ValorFalsoVerdadero', null, true), */
		];

	}
	public OnInitEnd() {

		this.GetMetadataInfo();

		this.preSaveEmitter.subscribe(event => {


			this.GetModeloJson();

		});

		this.postEditEmitter.subscribe(event => {

			this.SetModeloJson();
			this.nombrePrevio = this.modelo.Nombre;
		});

	}

	public GetMetadataInfo()
	{
		let obj = this.utility.GetApiModel('BusquedaMetadatosConfigGeneral', null)
		// obj.QueryConfig.Command = 'BusquedaTipoDocumental';
		obj.QueryConfig.Command = 'BusquedaMetadatosConfigGeneral';
		obj.QueryConfig.IsSp = true;
		obj.QueryConfig.IsMulti = false;
		obj.QueryConfig.Entity = {
			IdEmpresa: -1
		};
		let info = {
			componentName: this.componentName, controlName: 'BusquedaMetadatosConfigGeneral',
			processMessage: 'Cargando Metadatos......'
		};
		this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
			
			this.modelo.ValorString = data.Data[0].ValorString
			this.modelo.Nombre = data.Data[0].Nombre
			this.modelo.PkValue =  data.Data[0].IdParametroGeneral
			this.modelo.IdParametroGeneral =  data.Data[0].IdParametroGeneral
			this.modelo.TipoDato = data.Data[0].TipoDato
			this.SetModelToControls()
			this.IsEdit;
		}).catch(err => { });
	}

	public Limpiar(callback: (data: any) => void = null) {
		debugger;
		this.UserControl = {
			"Label": "",
			"Name": "",
			"Icon": "",
			"ControlType": 'UserControl',
			"Controls": []

		}
		if (this.modelo)
			this.nombrePrevio = this.modelo.Nombre;
		this.modelo = {};
		this.modelo.IdParametroGeneral = 0;
		this.modelo.PkValue = 0;
		this.modelo.IdEmpresa = -1;
		this.modelo.Nombre = '';
		this.modelo.TipoDato = '';
		this.modelo.ValorString = null;
		this.modelo.ValorEntero = null;
		this.modelo.ValorNumerico = null;
		this.modelo.ValorFecha = null;
		this.modelo.ValorFalsoVerdadero = null;
		this.modelo.Activo = true;
		this.utility.SetFieldsToModel(this.modelo, this.filterParent);
		if (callback) callback(this.modelo);
		//this.SetModelToControls();
		this.SetModeloJson();
		this.LimpiarModelo = false;
		setTimeout(()=> {this.LimpiarModelo = true;},200);
		
		this.GetMetadataInfo();
			
		
		/*debugger;
		var i = 0
		while(i < 30){
		if(document.getElementById("prueba"+i+"")){
			document.getElementById("prueba"+i+"").innerHTML = null;			
		}
		i++;
		}*/
		
	}


	public OnUpdateHandler(value) {
		this.modelo.ValorString = value;
	}
	public SetModeloJson() {
		if (this.nombrePrevio == 'GeneralConfig') {
			if (this.generalConfigComponent)
				this.generalConfigComponent.SetModeloJson(this.modelo.ValorString);
		}
	}
	public GetModeloJson() {
		if (this.nombrePrevio == 'GeneralConfig') {
			if (this.generalConfigComponent)
				this.modelo.ValorString = this.generalConfigComponent.GetModeloJson();
		}
	}


	// public _GuardaRegistros(event_form,event_config)
	// {

	// 	if(this.IsEdit)
	// 	{
	// 		this.GuardarForm(event_form,event_config);
	// 		this.OnInitEnd();	
	// 	}
	// }


}
