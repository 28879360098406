import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/helpers/utilities';
import { UtilityRuleEngine } from 'src/app/helpers/utility-rule-engine';

@Component({
  selector: 'app-auth-base',
  templateUrl: './auth-base.component.html',
  styleUrls: ['./auth-base.component.css']
})
export class AuthBaseComponent implements OnInit {

  constructor(public utility: Utilities,
    public utilityRuleEngine: UtilityRuleEngine) {


  }
  @Input() modelo: any;
  ngOnInit() {
  }

}
