import { Component, EventEmitter, OnInit } from "@angular/core";
import { ControllerMethods, TipoObjetoParaPermisos } from "src/app/models/general.enum";

import { CheckBoxRComponent } from "src/app/components/controls/check-box-r/check-box-r.component";
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from "src/app/components/controls/file-uploader/file-uploader.component";
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { SolicitudRadicadoEntradaComponent } from "../solicitud-radicado-entrada/solicitud-radicado-entrada.component";
import { StaticData } from "src/app/helpers/static-data";

@Component({
    selector: "app-solicitud-radicado-entrada-oficio",
    templateUrl: "./solicitud-radicado-entrada-oficio.component.html",
    styleUrls: ["./solicitud-radicado-entrada-oficio.component.css"]
})
export class SolicitudRadicadoEntradaOficioComponent extends SolicitudRadicadoEntradaComponent {

    public OnInitEnd() {
        this.postSaveEmitter.subscribe(modelo => {
            
            setTimeout(() => {
            this.utility.VerModalOk("Se asignó un oficio al Radicado de Entrada con código: " + "\n\r" + modelo.data.data.Codigo);
            }, 500);
        });
    }



}
