
import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  OnDestroy,
  QueryList, forwardRef, Inject, DebugElement
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, DataTableColumnTypes, DataTableAjaxOrderTypes,
  DataTableActions
} from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { ComboBoxManager } from '../../../bussines/combo-box-manager';
import { ConfigWindow } from '../../../models/config-window';
import { UtilitiesColumns } from '../../../helpers/utilities-columns';
import { BaseComponentComponent } from '../../../components/base-component/base-component.component';
import { GeneralBaseDataTable } from 'src/app/models/general-base-data-table';
import { FormDetailsComponent } from '../form-details/form-details.component';
import { ComponentsContainer } from 'src/app/models/components-container';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { DataTableComponent } from '../data-table/data-table.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-table-ng-for',
  templateUrl: './data-table-ng-for.component.html',
  styleUrls: ['./data-table-ng-for.component.scss']
})
export class DataTableNgForComponent extends DataTableComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {


  // @Input() public isModelContainer: boolean = false;
  // @Input() public parentCallRefresh: Subject<any>;
  // @Input() componentsContainer: ComponentsContainer;

  // @Output() onInitialized = new EventEmitter<any>();
  // @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  // @Input() cargarGrilla: boolean = true;
  // @Input() pkName: string = null;

  // @Input() modelo: any;
  // @Input() modeloClone: any = null;
  // @Input() url: string = null;


  // @Input() config: any = null;
  // @Input() columns:  Array<any> = [];
  // @Input() conFiltroIdEmpresa: boolean = true;
  // @Input() QueryConfig: any = null;
  // @Input() withPagination: boolean = false;

  // @Input() outPutParam: boolean = false;

  // @Input() public name: string;
  // @Input() public processMessage: string = 'Procesando ........';

  // @Input() public componentName: string


  // @Output() onConfigEmitter: EventEmitter<any> = new EventEmitter<any>();


  // @Output() onCloneModelEmitter: EventEmitter<any> = new EventEmitter<any>();
  // @Output() onMetadataEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityColumns: UtilitiesColumns,
    public helperActions: HelperActions,
    public helperImages: HelperImages, public translate: TranslateService) {

    super(baseService, utility, utilityColumns, helperActions, translate);

    
  }

  // ngOnInit() {

  //   this.OnInit();

  //   if (this.onInitialized.observers.length) {
  //     setTimeout(() => {
  //       this.onInitialized.emit(this);
  //     }, 1000);
  //   }

  // }
  // ngAfterViewInit() {


  // }
  // ngAfterViewChecked() {

  // }

  // public OnInitEnd() {

  // }
  // public onSpecificStart() {

  // }
  // public GetIcon(_icon: string, setDEfault: boolean = true): string {

  //   let icon: string;
  //   if (_icon && _icon.includes('.'))
  //     icon = _icon;
  //   else {
  //     if (_icon && setDEfault) {
  //       let obj = this.utility.GetNodeType(_icon);
  //       icon = obj.Icon;
  //     }
  //   }
  //   return icon;
  // }
  // withActiveColumn: boolean = false;
  // withDeleteColumn: boolean = false;
  // withEditColumn: boolean = false;
  // withSelectionColumn: boolean = false;
  // withBoolColumn: boolean = false;
  // withOptions: boolean = false;
  // withIcon: boolean = false;

  // public OnSetConfig() {


  //   if (this.config) {
  //     const that = this;
  //     this.name = this.config.Name;
  //     this.pkName = this.config.PkName;
  //     if (!this.componentName)
  //       this.componentName = this.config.ConfigControl.Controller;
  //     if (this.config.ConfigControl.QueryConfig.Command) {
  //       this.QueryConfig = this.config.ConfigControl.QueryConfig;
  //     }
  //     this.outPutParam = this.config.ConfigControl.QueryConfig.OutPutParam;
  //     this.withPagination = this.config.ConfigControl.QueryConfig.WithPagination;
  //     this.cargarGrilla = !this.config.ConfigControl.NotLoad;
  //     this.processMessage = (this.config.ProcessMessage) ? this.config.ProcessMessage : this.processMessage;

  //     this.columns = [];
  //     this.config.Controls.forEach((column: any, index: number) => {
  //      let columnType: DataTableColumnTypes = Number.isInteger(column.ColumnType) ? parseInt(column.ColumnType) : DataTableColumnTypes["" + column.ColumnType];  //parseInt(column.ColumnType);

  //       let icon = this.GetIcon(column.Icon);
  //       column.Title = (column.Title) ? column.Title : 'SIN TITULO';
  //       column.ToolTip = (column.ToolTip) ? column.ToolTip : 'SIN TOOLTIP';
  //       column.SearchField = (column.SearchField) ? column.SearchField : 'ObjType';
  //       column.IconField = (column.IconField) ? column.IconField : 'Icon';

  //       let _colum: any = this.utility.Clone(column);
  //       _colum.Icon = icon;
  //       this.columns.push(_colum);

  //     });


  //   }


  // }
  public OnInit() {

    try {
     
      if (!this.config.IsExtendableTable) {
        if (this.parentCallRefresh)
          this.parentCallRefresh.subscribe(event => {
            console.error('parentCallRefresh ' + this.name)
            this.CargarGrilla();
          });

        this.OnSetConfig();
        this.onSpecificStart();
        this.OnInitEnd();
        this.onConfigEmitter.emit({ columns: this.columns, startIndexColumn: this.startIndexColumn, configPermisos: this.configPermisos, config: this.config, isPost: true });


        this.CargarGrilla();
      }
      else {

      }
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.name, method: 'onInit' });
    }
  }

  public CargarGrilla() {

    if (!this.cargarGrilla) {
      if (this.dataTableMosaic && this.config.WithMosaics)
        this.dataTableMosaic.SetItems(this.items);
      return;
    }

    var obj = this.utility.GetApiModel('CargarGrilla', this.componentName);

    try {
      if (!this.cargarGrilla)
        return;
      if (this.QueryConfig)
        obj.QueryConfig = this.QueryConfig;

      if (this.config && this.config.Query) {
        obj.QueryConfig.Query = this.config.Query;
      }
      if (this.Pagina.IsCustom) {
        obj.Form = {
          GUID: this.Pagina.IdPagina,
          Name: this.Pagina.Nombre,
          ObjectType: "Form",
        }
      };
      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          method: 'CargarGrilla',
          processMessage: this.processMessage
        }, this.componentName)
        .then(data => {


          this.items = data.Data;

          this.ExcecuteFuntion(ControllerMethods.LoadedGrid, true, this.items);

          if (this.dataTableMosaic && this.config.WithMosaics)
            this.dataTableMosaic.SetItems(this.items);

        }).catch(err => {
          this.utility.logger.LogError(err, obj, {
            componentName: this.componentName,
            method: 'CargarGrilla'
          }, this.componentName)
        });

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.name, method: 'CargarGrilla' });
    }

  }
  public OnSelectedComboBoxEmitterHandler(event) {

    this.OnSelectedComboBox(event.item, event.controlName, event.config);
  }
  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    try {
      /*  this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, false, { item: item, controlName: controlName, config: config });
       // this.ExecuteCascading(item, controlName, config);
       if (config.IdMetadataPage) {
         this.SetFormControlsFromMetadataPage(config.IdMetadataPage);
       }
 
       this.comboBoxManager.ExecuteCascading(item, controlName, config);
       this.ExcecuteFuntion(ControllerMethods.SelectedComboBox, true, { item: item, controlName: controlName, config: config });
       this.SetModelToControls(); */
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'OnSelectedComboBox', controlName: controlName });
      this.utility.VerModalDanger().then(data => { });
    }
  }
  public SeleccionarItem(item: any, index: number, id: any, column?: any) {
    const that = this;
    try {


      
      this.ExcecuteFuntion(ControllerMethods.SelectedItem, false, { index: index, id: id, column: column });

      this.helperActions.ExecuteActionsControl(column, false, item, this.modelo,
        null, this, null, this.onActionEventEmitter, this.componentsContainer, null, null, null);

      this.ExcecuteFuntion(ControllerMethods.SelectedItem, true, { findItem: item, index: index, id: id, column: column });

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'SeleccionarItem' });
      this.utility.VerModalDanger().then(data => { }).catch(data => { });
    }
  }

  preSeleccionItem: (modelo: any) => void;
  postSeleccionItem: (modelo: any) => void;
  onSeleccionItem: (modelo: any) => void;
  onLoadedGridItem: (modelo?: any) => void;



  @Output() preSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() loadedGridEmitter: EventEmitter<any> = new EventEmitter<any>();


  public ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null): any {

    var result = null;
    switch (controllerMethod) {

      case ControllerMethods.SelectedItem:

        if (!isPost && this.preSeleccionItem)
          this.preSeleccionItem(data);
        else if (isPost == true && this.postSeleccionItem)
          this.postSeleccionItem(data);

        if (this.onSeleccionItem)
          this.onSeleccionItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


        if (!isPost && this.preSeleccionItemEmitter.observers.length > 0)
          this.preSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
        else if (isPost == true && this.postSeleccionItemEmitter.observers.length > 0)
          this.postSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

        if (this.onSeleccionItemEmitter.observers.length > 0)
          this.onSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
        break;
      case ControllerMethods.LoadedGrid:

        if (this.onLoadedGridItem)
          this.onLoadedGridItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


        if (this.loadedGridEmitter.observers.length > 0)
          this.loadedGridEmitter.emit({ controlName: this.name, isPost: isPost, data: data, controllerMethod: controllerMethod });
        break;

    }
    return result;
  }


  ngOnDestroy() {

    this.utility.Unsubscribe(this.parentCallRefresh);

  }

}
