import { Component, OnInit } from '@angular/core';
import { ConfigBaseComponent } from 'src/app/models/config-base-component';

@Component({
  selector: 'app-dynamic-controls-metadata',
  templateUrl: './dynamic-controls-metadata.component.html',
  styleUrls: ['./dynamic-controls-metadata.component.scss']
})

export class DynamicControlsMetadataComponent extends ConfigBaseComponent implements OnInit {

  Controls: Array<any> = [];
  config:any;
}