//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import {
  Component,
  OnInit
} from "@angular/core";

import {
  ControlActions,
  FormActionsThroughEmitters
} from "../../../models/general.enum";
import { GeneralFormComponent } from "./../../controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { ConfigWindow } from "src/app/models/config-window";
import { StaticData } from "src/app/helpers/static-data";
import { ExportImportConfig, ExportImportItemConfig } from "src/app/models/rule-engine/rules-models";

@Component({
  selector: "app-contacto",
  templateUrl: "./contacto.component.html",
  styleUrls: ["./contacto.component.scss"],
})
export class ContactoComponent extends GeneralFormComponent implements OnInit {
  TipoIdentificacion: any;
  Departamento: any;
  Genero: any;
  GridViewFiltroAuditoria: any;
  GridViewAuditoria: any;

  public OnSelectedComboBox(item: any, controlName: string, config: any) {
    const context = this;
    if(controlName === "IdTipoContacto"){
      this.modelo.modelo.IdTipoIdentificacion = null;
    }
    if (
      controlName === "IdTipoContacto" &&
      item &&
      item.hasOwnProperty("Codigo")
    ) {

      if (item.Codigo !== "PUB") {
        hideContactoFields(context, item.Codigo === "C", undefined, undefined, true);
      }

      this.helperActions.MultiSetControlsValue(
        [
          {
            ControlsToUpdate: ["Identificacion"],
            PropertiesToSet: {
              required: item.Codigo == "PUB" ? false : true,
            },
          },
        ],
        context
      );

    } else if (controlName === "IdTipoIdentificacion") {
      //debugger;
      this.helperActions.MultiSetControlsValue(
        [
          {
            ControlsToUpdate: ["Identificacion"],
            PropertiesToSet: {
              required: item.IdDatoBasico == 60 ? false : true,
            },
          },
        ],
        context
      );

    } else if (controlName === "IdPais") {
      //debugger;
      const context = this;
      this.modelo.modelo.IdDepartamento = null;
      this.modelo.modelo.IdCiudad = null;

      //
      //debugger;
      if (this.modelo.modeloCascada.IdPais != 1 && this.modelo.modeloCascada.IdPais != null) {
        //debugger;
        this.modelo.modeloCascada.IdCiudad = null;
        this.modelo.modeloCascada.Departamento = null;
        this.Departamento = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdDepartamento" });
        this.Departamento.Hidden = true;
        //this.Departamento.Required = false;
        const context = this;
        const action = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: []
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["IdDepartamento"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: "false",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty]
        };
        this.helperActions.SetControlsValue(action, context);

        if (this.modelo.modeloCascada.IdPais) {
          let obj = this.utility.GetApiModel('ConsultaPaisCiudad', null)
          obj.QueryConfig = {
            Command: 'ConsultaPaisCiudad',
            IsSp: true,
            Entity: {
              IdPais: this.modelo.modeloCascada.IdPais
            }
          };
          let info = {
            componentName: this.componentName, controlName: 'ConsultaPaisCiudad',
            processMessage: 'Cargando Ciudad......'
          };

          this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {

            //debugger;
            this.modelo.modeloCascada.IdDepartamento = data.Data[0].IdDepartamento;
            super.EditCascading();
            //this.SetModelToControls();
            //super.EditCascading();
          }).catch(err => { this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'Consulta' }); });
        }
        else {
          this.modelo.modeloVirtual.Indicativo = null;
        }
      } else {
        //debugger;
        this.Departamento = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdDepartamento" });
        this.Departamento.Hidden = false;
        //this.Departamento.Required = true;
        const context = this;
        const action = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: []
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["IdDepartamento"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: "true",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty]
        };
        this.helperActions.SetControlsValue(action, context);
      }
      //
      this.helperActions.MultiSetControlsValue(
        [
          {
            ControlsToUpdate: ["CodigoPostal"],
            PropertiesToSet: {
              required: item.IdPais != 1,
            },
          },
        ],
        context
      );
    }

    super.OnSelectedComboBox(item, controlName, config);
  }

  public PostNgAfterViewInit() {
    super.PostNgAfterViewInit();
    this.TipoIdentificacion = this.utility
      .GetAllFormControls(this.Pagina.ConfigPage.Controls)
      .find((x) => {
        return x.Name === "IdTipoIdentificacion";
      });
    if (
      this.TipoIdentificacion &&
      this.TipoIdentificacion.hasOwnProperty("ConfigControl") &&
      this.TipoIdentificacion.ConfigControl.hasOwnProperty("Context")
    )
      this.TipoIdentificacion.ConfigControl.Context.modelo = this.modelo;
  }

  public ngOnInit() {
    this.helperActions.comunicationService.receivedFormEvent.subscribe(
      (event) => {
        if (
          event.Action == FormActionsThroughEmitters.Edit ||
          event.Action == FormActionsThroughEmitters.ClearModel ||
          event.Action == FormActionsThroughEmitters.ClearForm
        ) {
          validateContact(this, undefined, undefined, undefined, undefined, true);
        }
      }
    );

    super.ngOnInit();
  }

  public OnDataTableEditHandler(event) {
    debugger;
    //this.modelo.modeloVirtual.GridRadicadosAsociados = null;
    //this.PruebasEntradaRadicado = this.modelo.modeloVirtual.GridRadicadosAsociados;
    if(event.name && event.name == "RContacto"){
      this.componentName = "RContacto"
      validateContact(this, undefined, undefined, undefined, undefined, true);
      let obj = this.utility.GetApiModel('CrearAuditoriaContactoCreacion', null)
      obj.QueryConfig.Command = 'CrearAuditoriaContactoCreacion'; // 'CrearAuditoriaContactoCreacion';
      obj.QueryConfig.IsSp = true;
      obj.QueryConfig.Entity = {
        IdEmpresa: -1,
        IdRegistro: this.modelo.modelo.IdContacto//,
        //UsuarioCreacion: StaticData.Usuario.Email,
        //IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
        //Maquina: -1
      };
      let info = {
        componentName: this.componentName, controlName: 'CrearAuditoriaContactoCreacion',
        processMessage: null
      };
      this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
      }).catch(err => { }).finally();

  }
  super.EditCascading();
  this.SetModelToControls();
  //super.EditCascading();
  //super.OnActionEventHandler(event);
}

  public OnActionEventHandler(event) {
    debugger;
    if (event.ControlType) {
      if (event.Option && event.Option.Type == 'New') {
        //this.VerObservacionesInactivar();
        const context = this;
        this.helperActions.MultiSetControlsValue(
          [
            {
              ControlsToUpdate: ["IdTipoContacto", "IdTipoIdentificacion", "Identificacion"],
              PropertiesToSet: {
                enabled: true,
              },
            },
          ],
          context
        );
        this.Genero = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdGenero" });
        this.Genero.Hidden = false;

        const action = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: []
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["IdGenero"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: "true",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty]
        };
        this.helperActions.SetControlsValue(action, context);
        this.SetModelToControls();
      }
      if (event.Option && event.Option.Type == 'Edit') {
        //this.VerObservacionesInactivar();
        const context = this;
        this.helperActions.MultiSetControlsValue(
          [
            {
              ControlsToUpdate: ["IdTipoContacto", "IdTipoIdentificacion", "Identificacion"],
              PropertiesToSet: {
                enabled: false,
              },
            },
          ],
          context
        );

        this.Genero = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdGenero" });
        if(this.modelo.modelo.IdTipoContacto == 10){
        this.Genero.Hidden = true;

        const action = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: []
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["IdGenero"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: "false",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty]
        };
        this.helperActions.SetControlsValue(action, context);
        this.SetModelToControls();
      }else{
        this.Genero.Hidden = false;
        const context = this;
        const action = {
          ControlDetails: {
            MappingData: [],
            MappingFilter: []
          },
          ControlType: "Action",
          ControlsForHidden: [],
          ControlsToUpdate: ["IdGenero"],
          Guid: Guid.create().toString(),
          IsPost: false,
          IsParentModel: false,
          MappingValues: [],
          MappingComponents: [],
          Property: "required",
          PropertyValue: "true",
          ResultType: "Url",
          SendQueryString: false,
          TimerAction: 0,
          Type: ControlActions[ControlActions.SetControlProperty]
        };
        this.helperActions.SetControlsValue(action, context);
        this.SetModelToControls();

      }
      }

      if (event.Option && event.Option.Type == 'Active') {
        this.VerObservacionesInactivar();
      }
      else if (event.Name && event.Name == 'btnBuscar') {
        this.FiltroAuditoria(this.modelo.modelo.IdContacto);
      }
      /**
       * Inicio Exportar Auditoría Contacto .xlsx
       * Edward Morales - 22012024
       */
      else if (event.Name && event.Name == 'BtnAuditoria'){
        this.GridViewAuditoria = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Filtro"; });
        this.GridViewAuditoria.QueryConfig.ExportSettings = new ExportImportConfig();

        var date = new Date();
        let day = ("0" + date.getDate()).slice(-2);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        this.GridViewAuditoria.QueryConfig.ExportSettings.FileName = 'BusquedaLTAuditoriaFuncional_' + day + "_" + month + "_" + year + "-" + hours + "-" + minutes + "-" + seconds;
        this.GridViewAuditoria.QueryConfig.Entity.PageSize = null;
        this.GridViewAuditoria.QueryConfig.Entity.Skip = null;
        this.GridViewAuditoria.QueryConfig.Entity.SearchValue = null;
        this.GridViewAuditoria.QueryConfig.Entity.SortOrderColumn = null;
        this.GridViewAuditoria.QueryConfig.Entity.SortColumnDir = null;
        this.GridViewAuditoria.QueryConfig.Entity.IsCount = null;

        var ItemConfig = new ExportImportItemConfig();
        ItemConfig.ResultType = event.config.Actions[0].ResultType;
        ItemConfig.DestinationType = 'None';
        ItemConfig.ToType = event.config.Actions[0].ExportType;

        this.GridViewAuditoria.QueryConfig.ExportSettings.ItemsConfig.push(ItemConfig);

        super.OnActionEventHandler(event);
      }
      else if (event.Name && event.Name == 'BtnVertodo') {
        this.modelo.modelo.FechaCreacionInicial = null;
        this.modelo.modelo.FechaCreacionFinal = null;
        this.FiltroAuditoria(this.modelo.modelo.IdContacto);
      }
      else
        super.OnActionEventHandler(event);
    }
    else
      super.OnActionEventHandler(event);
  }

  VerObservacionesInactivar(): any {
    let config: ConfigWindow = new ConfigWindow();
    let modeloInterno: any = { VersionModelData: { ObservacionesModificacion: '' } };
    config.classWindow = 'info';
    config.titleWindow = "Observaciones";
    config.returnInstance = false;
    config.ok = "Aceptar";
    config.modal = true;
    config.width = 600;
    config.height = 300;
    let windowModel: any = {
      modelo: modeloInterno.VersionModelData,
      config: this.utility.GetConfigObservacionesVersion()
    };

    this.utility.OpenWindow('ObservacionesModificacionVersionComponent',
      config, windowModel).then(dataResult => {

        dataResult.onClosePromise.then(dataResultOnClose => {

          if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

            if (dataResultOnClose.modelo.ObservacionesModificacion) {

              this.utility.VerModalConfirmacionActivar().then(modelo => {
                if (modelo.dialogResult == true) {

                  this.GuardarAuditoriaContacto(this.modelo.modelo.Activo ? 'Inactivar' : 'Activar', dataResultOnClose.modelo.ObservacionesModificacion);
                  var gridContacto = this.componentsContainer.Tables.find(x => { return x.name == "RContacto"; });
                  gridContacto.selectedItems = [];
                  gridContacto.ReCargarGrilla()
                  super.Limpiar();
                }
              });

            }
            else
              this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
          }
        });
      })

  }

  public GuardarAuditoriaContacto(option: any, observacion: any) {
    var description: string;
    var spName: string;

    switch (option) {
      case "Inactivar":
        description = observacion + ', Contacto con Id: ' + this.modelo.modelo.IdContacto;
        spName = "CrearAuditoriaInactivarContacto";
        break;
      case "Activar":
        description = observacion + ', Contacto con Id: ' + this.modelo.modelo.IdContacto;
        spName = "CrearAuditoriaActivarContacto";
        break;
    }


    this.componentName = "RContacto"
    let obj = this.utility.GetApiModel(spName, null)
    obj.QueryConfig.Command = spName; // 'CrearAuditoriaInactivar';
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdRegistro: this.modelo.modelo.IdContacto,
      UsuarioCreacion: StaticData.Usuario.Email,
      IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
      Maquina: -1,
      Descripcion: description
    };
    let info = {
      componentName: this.componentName, controlName: spName,
      processMessage: 'Auditoria Contacto......'
    };
    this.baseService.Get(obj, null, info, this.componentName, false, false).then(data => {
    }).catch(err => { }).finally();

  }

  public FiltroAuditoria(idExpediente) {
    try {
      //this.SetProcesando(true);
      this.GridViewFiltroAuditoria = this.componentsContainer.Tables.find(x => { return x.name == "DataTables_Auditoria_Filtro"; });
      if (this.GridViewFiltroAuditoria) {
        this.GridViewFiltroAuditoria.QueryConfig.Entity = {
          FechaCreacionInicial: this.modelo.modelo.FechaCreacionInicial,
          FechaCreacionFinal: this.modelo.modelo.FechaCreacionFinal,
          IdEmpresa: -1,
          IdExpediente: (idExpediente == null) ? -1 : this.modelo.modelo.IdContacto
        };
        this.GridViewFiltroAuditoria.cargarGrilla = true;
        this.GridViewFiltroAuditoria._CargarGrilla(true);
      }
      // fin
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }





}

export function validateContact(
  context,
  modelContainer = "modelo",
  camposOcultarCiudadano = ["Cargo", "RazonSocial"],
  camposOcultarEmpresa = ["IdGenero"],
  modeloAlternativo = null,
  marcarRequerido = false
) {
  if (
    context.modelo &&
    (context.modelo[modelContainer] &&
      context.modelo[modelContainer].hasOwnProperty("IdTipoContacto"))
  ) {
    hideContactoFields(
      context,
      context.modelo[modelContainer].IdTipoContacto == 7,
      camposOcultarCiudadano,
      camposOcultarEmpresa,
      marcarRequerido
    );
  }
  else if (modeloAlternativo) {
    hideContactoFields(
      context,
      modeloAlternativo.IdTipoContacto == 7,
      camposOcultarCiudadano,
      camposOcultarEmpresa,
      marcarRequerido
    );
  }
}

export function hideContactoFields(
  context,
  esCiudadano = true,
  camposOcultarCiudadano = ["Cargo", "RazonSocial"],
  camposOcultarEmpresa = ["IdGenero"],
  marcarRequerido = false
) {
  return (
    context.helperActions &&
    context.helperActions.MultiSetControlsValue &&
    context.helperActions.MultiSetControlsValue(
      [
        {
          ControlsToUpdate: camposOcultarCiudadano,
          PropertiesToSet: {
            hidden: esCiudadano,
            required: !esCiudadano && marcarRequerido,
          },
        },
        {
          ControlsToUpdate: camposOcultarEmpresa,
          PropertiesToSet: {
            hidden: !esCiudadano,
            required: esCiudadano && marcarRequerido,
          },
        },
      ],
      context
    )
  );
}
