// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Local
  //APIEndpoint: "http://localhost:58205",
  //Producto BestDoc
  APIEndpoint: "http://74.249.102.229", //Ambiente Producto Pruebas
  //APIEndpoint: "http://192.168.7.41:9090", //Ambiente Producto Producción VPN
  //APIEndpoint: "http://201.184.103.171:9090", //Ambiente Producto Producción
  //AND
  //APIEndpoint: 'http://192.168.15.102:90', //Ambiente Pruebas
  //APIEndpoint: 'http://10.11.4.213:90',    //Ambiente Producción
  //ILC
  //APIEndpoint: 'http://10.0.0.4',         //Ambiente Pruebas  
  //APIEndpoint: 'http://apisgd.ilc.com.co:8080',//'http://172.17.0.4:8080',  //Ambiente Producción
  //UAO
  //APIEndpoint: 'http://sgdpruebas:90',  //Ambiente Pruebas
  //APIEndpoint: 'http://SGD-APP:90',     //Ambiente Producción Principal
  //APIEndpoint: 'http://SGD-APP2:90',    //Ambiente Producción Secundario
  //CSJ
  //APIEndpoint: 'http://168.62.56.174:90',   //Ambiente Pruebas
  //APIEndpoint: "http://52.255.157.200:90/", //Ambiente Producción 
  PathImages:'',//'../assets/images/jstree/',
  HostingType:''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
