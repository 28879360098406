import List from './list';
export default class Enumerable {
    /**
     * Generates a sequence of integral numbers within a specified range.
     */
    static Range(start, count) {
        let result = new List();
        while (count--) {
            result.Add(start++);
        }
        return result;
    }
    /**
     * Generates a sequence that contains one repeated value.
     */
    static Repeat(element, count) {
        let result = new List();
        while (count--) {
            result.Add(element);
        }
        return result;
    }
}
