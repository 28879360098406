import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/helpers/utilities';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { UtilityRuleEngine } from 'src/app/helpers/utility-rule-engine';
import { StaticData } from 'src/app/helpers/static-data';
import { QueryConfigIDETypes } from 'src/app/models/general.enum';

@Component({
  selector: 'app-user-settings-model',
  templateUrl: './user-settings-model.component.html',
  styleUrls: ['./user-settings-model.component.css']
})
export class UserSettingsModelComponent implements OnInit {

  constructor(public utility: Utilities,
    public utilityRuleEngine: UtilityRuleEngine,
    public baseService: BaseServiceService) {


  }
  @Input() modelo: any;
  @Input() project: any;
  @Input() querys: Array<any> = [];
  @Input() itemsAplications: Array<any> = [];
  IsExternal: boolean = false;
  AppKey: string = "";
  UData: string = "";

  ngOnInit() {

    if (this.modelo) {
      this.getUserModel();
    }
    this.CargarProject();
  }

  public getUserModel() {
    if (!this.modelo.ApplicationsAllowed || this.modelo.ApplicationsAllowed.length == 0) {
      this.modelo.ApplicationsAllowed = [];
      this.AppKey = "";
    }
    else
      this.AppKey = this.modelo.ApplicationsAllowed[0].GUID;

    if (!this.modelo.Theme)
      this.modelo.Theme = {
        PrimaryColor: '', SecundaryColor: '', PrimaryColor70: '',
        SideBarBackgroundImage: '', BackgroundImage: '',
        WithSideBarBackgroundImage: true, WithBackgroundImage: false
      };
  }

  public Limpiar() {
    this.modelo = {};
    this.getUserModel();
  }

  public GetConfiguracion(): string {
    let ConfiguracionStr = JSON.stringify(this.modelo);
    return ConfiguracionStr;
  }
  public SetModelo(modelo: any) {

    this.UData = btoa(modelo.modelo.Email);
    this.modelo = Object.assign({}, JSON.parse(modelo.modelo.Configuracion));
    if (!this.modelo.ApplicationsAllowed || this.modelo.ApplicationsAllowed.length == 0)
      this.AppKey = "";
    else
      this.AppKey = this.modelo.ApplicationsAllowed[0].GUID;

    if (this.modelo.UserType == "External")
      this.IsExternal = true;
    else
      this.IsExternal = false;


    this.getUserModel();
  }
  public ChangeTypeUser(event) {
    if (!event)
      this.IsExternal = false;
    else if (event.Name == "External")
      this.IsExternal = true;
    else
      this.IsExternal = false;
  }
  public ChangeAplication(event) {
    if (this.modelo.ApplicationsAllowed.length > 0)
      this.AppKey = event[0].GUID;
    else
      this.AppKey = "";

  }


  public CargarProject() {

    if (StaticData.Usuario && !this.project) {

      let componentName = 'RUsuario';
      let command = 'ConsultaConfiguracionGeneral';
      let processMessage = 'Cargando Configuracion General .....';

      this.baseService.ConsultaQueryConfigIDE(QueryConfigIDETypes.ConfiguracionGeneral, 'RUsuario',
        componentName, 0, false, command, processMessage).then(data => {
          // COLOCAR LA LECTURA DE PROYECTO

          this.project = JSON.parse(data.Data[0].Configuration);
          this.project.Name = data.Data[0].Name;
          this.project.IdConfiguracionGeneral = data.Data[0].Id;
          this.project.IdEmpresa = data.Data[0].IdEmpresa;

          this.itemsAplications = this.utility.ConcatMap(this.project.ApplicationsAllowed, this.itemsAplications);
          this.querys = this.utility.GetQuerys(this.project);

        });
    }
  }
}
