import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control';
import { Utilities } from '../../../helpers/utilities'

@Component({
  selector: 'app-three-state-toggle',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThreeStateToggleComponent),
      multi: true
    }
  ],
  templateUrl: './three-state-toggle.component.html',
  styleUrls: ['./three-state-toggle.component.scss']
})
export class ThreeStateToggleComponent extends BaseControl implements OnInit, ControlValueAccessor {

 

}
