import {
  Component, OnInit, Input, Output, EventEmitter, QueryList, ViewContainerRef, ViewChild,
  ComponentFactoryResolver, Injector, OnDestroy
} from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { FormGroup, FormControl, NgForm, Validators } from '@angular/forms';
import { StaticData } from '../../../helpers/static-data';
import { Subject } from 'rxjs';
import { DataTableComponent } from '../data-table/data-table.component';
import { ComponentsContainer } from 'src/app/models/components-container';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { List } from '../../../../assets/linqts/compilado/index';
import { HelperImages } from 'src/app/helpers/helper-images';

// FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-dynamic-controls',
  templateUrl: './dynamic-controls.component.html',
  styleUrls: ['./dynamic-controls.component.scss']
})

export class DynamicControlsComponent extends ConfigBaseComponent implements OnInit, OnDestroy {

  @ViewChild('DynamicComponentViewer', { read: ViewContainerRef, static: false }) DynamicComponentViewer: ViewContainerRef;

  @Input() comboxConfigs: any = {};
  @Input() public parentCallRefresh: Subject<any>;
  @Input() public parentClearCall: Subject<any>;
  @Input() componentsContainer: ComponentsContainer;
  @Input() hideDataTable: boolean = false;
  @Input() isModelContainer: boolean = false;
  @Input() modeloClone: any = null;
  @Input() configPermisos: any = null;
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDataTableEditEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSetConfigEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloneEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoadGridEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectedComboBoxEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() botonProcesando: string;
  @Input() textoBotonProcesando: string;
  @Input() textoBoton: string;
  @Output() onGuardarForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLimpiarConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onModalFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onImportarForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() isReactive: boolean = true;
  @Output() public parentContext: any;

  public name: string;

  @Output() cumpleValidacion: boolean = true;

  public sidePanel: any;
  public sidePanelInstance: any;

  @Input() public workFlowItem: any = null;

  // false = color blanco, true = color = gris
  panelColor = true;
  @Input() form: FormGroup;

  @Input() SearcheableConfigs: Array<any> = [];
  constructor(

    public utility: Utilities,
    public baseService: BaseServiceService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public injector: Injector,
    public sanitizer: DomSanitizer,
    public helperImages: HelperImages) {

    super(utility, baseService, sanitizer, helperImages);

  }

  SetSidePanel() {
    if (!this.sidePanel)
      this.sidePanel = document.querySelector("#" + this.subControl.Name);
  }
  //* FUNCIONES ANGULAR

  ngOnDestroy() {

    this.sidePanelInstance = null;

  }
  ngAfterViewInit() {
    if (this.subControl && this.subControl.ControlType === 'SidePanel') {
      this.name = this.subControl.Name;        
        if(this.subControl.OpenaPanel)
          this.OpenSidePanel();                  
        else   
          this.SetSidePanel();      
      if (this.onInitialized.observers.length) {
        setTimeout(() => {
          this.onInitialized.emit(this);
        }, 1000);
      }
    }

    if (this.subControl && this.subControl.ControlType === 'AdminPermisosPalabrasClave') {

      this.name = this.subControl.Name;
      var PivotElement = document.querySelector("#" + this.subControl.Name);
      if (PivotElement)
        new fabric['Pivot'](PivotElement);

    }

    if (this.subControl && this.subControl.ControlType === 'PermisosTiposDocMetadatos') {
      this.name = this.subControl.Name;
      var PivotElement = document.querySelector("#" + this.subControl.Name);
      if (PivotElement)
        new fabric['Pivot'](PivotElement);
    }
    /*(<any>$('.dc__tooltip')).tooltip({
      sanitizeFn: function (content) { return content; }
    });*/

    if(this.subControl.ViewLog){
      this.LogTimeView();
    }

  }

  ngOnInit() {

    if (this.subControl) {
      if (this.subControl.Type == "WorkFlowTask") {
        if (this.isModelContainer && !this.modelo.WorkFlowTaskModel) {
          this.modelo.WorkFlowTaskModel = {};
        }
      }

      if (!this.subControl.ModelContainer)
        this.subControl.ModelContainer = "modelo";
      /*  if (this.subControl && this.subControl.ControlType === 'Pagina') {
        
         let pagina = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', this.subControl.IdPagina) //this.utility.FindAndConfigPage(this.subControl .IdPagina);
         if (pagina) {
           this.subControl.Controls = pagina.ConfigPage.Controls;
         }
         else {
           this.utility.logger.LogWarningText('No se encontro la pagina: ' + this.subControl.Name + ', Con Id:' + this.subControl.IdPagina + ' Para DynamicControlsComponent');
         }
       } */
      if (this.subControl.ControlType == 'DynamicComponentViewer') {
        setTimeout(() => {
          this.LoadDynamicComponent();
        }, 200);
      }

      if (this.subControl && (this.subControl.ControlType === 'FormContainer' ||
        this.subControl.ControlType === 'FormGroup')) {
        if (this.isModelContainer && this.subControl.ModelContainer && !this.modelo[this.subControl.ModelContainer]) {
          this.modelo[this.subControl.ModelContainer] = {};
        }
        if (this.onInitialized.observers.length) {
          setTimeout(() => {
            this.onInitialized.emit(this);
          }, 1000);
        }
      }
      if (this.subControl.Searcheable) {
        this.SetOp("Equal");
      }

    }
    this.OnInitInternal();

  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  changeSwap(event) {

    if (this.Pagina.ConfigPage.Swap) {
      console.log("aca un swap");
      if (!(<any>$(event.target)).hasClass("active") && ((<any>$(event.target)).hasClass("swap__form") || (<any>$(event.target)).hasClass("swap__grid"))) {
        (<any>$('.swap__grid')).toggleClass('active');
        (<any>$('.swap__form')).toggleClass('active');
      }
    }

  }

  public OnValidate(event) {

    this.cumpleValidacion = event;

  }

  public OpenSidePanel() {

    this.SetSidePanel();
    this.sidePanelInstance = new fabric['Panel'](this.sidePanel);
    // var PivotElements = document.querySelectorAll(".ms-Pivot");
    // for (var i = 0; i < PivotElements.length; i++) {
    //   new fabric['Pivot'](PivotElements[i]);
    // }

  }

  //! NO HACE NADA
  public OnInitInternal() { }

  public InternoonLimpiarConfirm() {

    alert('Sesundoa');
    this.onLimpiarConfirm.emit();

  }

  //! NO HACE NADA
  public OnSelectedComboBox(item, controlName, config) { }

  public get ComboxConfig(): any {

    if (this.comboxConfigs)
      return this.comboxConfigs['Config' + this.subControl.Name];
    else
      return null;

  }

  //! NO HACE NADA
  public AdicionarControlFormulario() {
    /*  if(this.frm && this.frm.form && this.subControl && this.utility.EsControlFormulario(this.subControl)){
      
       let ctrl =new FormControl('');
       this.frm.form.addControl(this.subControl.Name,ctrl);
     //this.frm.form.controls
     } */
    // let fieldsCtrls = {};
    // for (let f of this.fields) {
    //   if (f.type != 'checkbox') {
    //     fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
    //   } else {
    //     let opts = {};
    //     for (let opt of f.options) {
    //       opts[opt.key] = new FormControl(opt.value);
    //     }
    //     fieldsCtrls[f.name] = new FormGroup(opts)
    //   }
    // }
    // this.frm.form = new FormGroup(fieldsCtrls);
  }

  minimizeMode: boolean = true;
  _colSpan: string;

  resize() {

    if (this.minimizeMode) {
      this._colSpan = this.subControl.ColSpan;
      this.subControl.ColSpan = 12;

    }
    else
      this.subControl.ColSpan = this._colSpan;

    this.minimizeMode = !this.minimizeMode;

  }

  get ColSpan(): string {

    /*    if (this.subControlParent && (this.subControlParent.ControlType == 'DragContainerBox' ||
         this.subControlParent.ControlType == 'DragPanel')) {
         return '12';
       } */
    if (this.subControlParent || this.isFirst) {
      return '12';
    }
    return this.subControl.ColSpan;

  }

  itemDropped(event: CdkDragDrop<any[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Controls, event.previousIndex, event.currentIndex);
    } else { }

  }

  LoadDynamicComponent() {

    if (this.subControl.Activo != true)
      return;
    let _bodyComponent: any;
    var factories = Array.from(this.componentFactoryResolver['_factories'].keys());
    _bodyComponent = factories.find((x: any) => x.name === this.subControl.ComponentName);

    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory<any>(_bodyComponent)
      .create(this.injector);
    const component: any = componentRef.instance;

    if (component) {
      component.parentCallRefresh = this.parentCallRefresh;
      component.parentCallRefresh = this.parentCallRefresh;
      component.parentClearCall = this.parentClearCall;
      component.componentsContainer = this.componentsContainer;
      component.modelo = this.modelo;
      component.onActionEventEmitter = this.onActionEventEmitter;
      component.config = this.subControl;
      //COmentareada la linea por marlon Granda 29/10/2020
      // component.name = this.subControl.Name;
      component.isDynamic;
      //component.onInitialized = this.onInitialized;
      //component.valueChange.subscribe(val => this.selectedValue = val);
      this.AddChild(component);
    }

    this.DynamicComponentViewer.insert(componentRef.hostView);

  }

  public AddChild(component) {

    if (component) {
      if (!this.childComponents)
        this.childComponents = [];
      let _component = new List(this.childComponents).FirstOrDefault(x => { return x.name == component.name });
      if (!_component) {
        this.onInitialized.emit(component);
      }
      //this.childComponents.push(component);
    }

  }

  //* END FUNCIONES PERSONALIZADAS

  searcheableConfig: any;

  IsLogicalOp(op) {

    if (this.SearcheableConfigs && this.SearcheableConfigs.length > 0) {
      this.searcheableConfig = this.SearcheableConfigs.find(x => x.Name == this.subControl.Name);
      if (this.searcheableConfig)
        return (this.searcheableConfig.LogicOp == op);
      else
        return null;
    }
    else
      return null;
  }


  SetLogicalOp(op) {

    if (this.SearcheableConfigs.length > 0) {
      this.searcheableConfig = this.SearcheableConfigs.find(x => x.Name == this.subControl.Name);
      if (this.searcheableConfig)
        this.searcheableConfig.LogicOp = op;
      else
        this.SearcheableConfigs.push({
          Name: this.subControl.Name, Label: this.subControl.Label, Op: "Equal",
          LogicOp: op, SearcheableFieldName: this.subControl.SearcheableFieldName,
          DataType: this.subControl.DataType, ControlType: this.subControl.ControlType
        })

    }
    else
      this.SearcheableConfigs.push({
        Name: this.subControl.Name, Label: this.subControl.Label, Op: "Equal",
        LogicOp: op, SearcheableFieldName: this.subControl.SearcheableFieldName,
        DataType: this.subControl.DataType, ControlType: this.subControl.ControlType
      });

    this.onActionEventEmitter.emit({ Name: this.subControl.Name, Action: 'SetLogicalOp' });
  }
  IsOp(op) {

    if (this.SearcheableConfigs && this.SearcheableConfigs.length > 0) {
      this.searcheableConfig = this.SearcheableConfigs.find(x => x.Name == this.subControl.Name);
      if (this.searcheableConfig)
        return (this.searcheableConfig.Op == op);
      else
        return null;
    }
    else
      return null;
  }


  SetOp(op) {

    if (this.SearcheableConfigs.length > 0) {
      this.searcheableConfig = this.SearcheableConfigs.find(x => x.Name == this.subControl.Name);
      if (this.searcheableConfig)
        this.searcheableConfig.Op = op;
      else {
        this.SearcheableConfigs.push({
          Name: this.subControl.Name, Label: this.subControl.Label, Op: op, LogicOp: "And", SearcheableFieldName: this.subControl.SearcheableFieldName,
          DataType: this.subControl.DataType, ControlType: this.subControl.ControlType
        })
      }

    }
    else
      this.SearcheableConfigs.push({
        Name: this.subControl.Name, Label: this.subControl.Label, Op: op, LogicOp: "And",
        SearcheableFieldName: this.subControl.SearcheableFieldName,
        DataType: this.subControl.DataType, ControlType: this.subControl.ControlType
      });

    this.onActionEventEmitter.emit({ Name: this.subControl.Name, Action: 'SetOp' });
  }



  labelTime: any = "";
  public LogTimeView() {
    if (StaticData.StopWatchView) {
      StaticData.StopWatchView.stop();
      const time = StaticData.StopWatchView.getTime();
      const timeFormat = this.utility.FormatStopWatch(time);
      console.info("TIEMPO PAGINA S1: " + time);
      console.info("TIEMPO PAGINA S2: " + timeFormat);
      this.labelTime = "TIEMPO PAGINA S1: " + time + "\n" + "TIEMPO PAGINA S2: " + timeFormat + "\n";
    }
    var endProcess = new Date().getTime();
    const time = endProcess - StaticData.StartProcessView;
    const timeFormat = this.utility.FormatStopWatch(time);
    console.info("TIEMPO PAGINA1: " + time);
    console.info("TIEMPO PAGINA2: " + timeFormat);

    this.labelTime += "TIEMPO PAGINA1: " + time + "\n" + "TIEMPO PAGINA2: " + timeFormat + "\n";
  }

}
