import { Component, OnInit, Input } from '@angular/core';

import { TipoParaPermisos } from 'src/app/models/general.enum';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { UtilityRuleEngine } from '../../../../helpers/utility-rule-engine';

import { HelperImages } from 'src/app/helpers/helper-images';

@Component({
  selector: 'app-info-permission',
  templateUrl: './info-permission.component.html',
  styleUrls: ['./info-permission.component.css']
})
export class InfoPermissionComponent implements OnInit {

  @Input() name: string = "";
  @Input() config: any;
  @Input() modelo: any;
  @Input() responsibles: Array<any> = [];
  @Input() propPermission: string = "Permissions";
  estiloTablas: any = {};
  @Input() tituloUsuarios: string = "Usuarios";
  @Input() tituloRoles: string = "Roles";
  @Input() tituloGrupos: string = "Grupos";
  @Input() tituloAreasEmpresa: string = "Áreas Empresa";
  @Input() tituloSedesEmpresa: string = null;
  @Input() tituloApplicaciones: string = null;

  @Input() tipoFiltroResponsable: string = "None";
  @Input() verUsuarios: Boolean = true;
  @Input() verRoles: Boolean = true;
  @Input() verGrupos: Boolean = true;
  @Input() verAreasEmpresa: Boolean = true;
  @Input() verSedesEmpresa: Boolean = false;
  @Input() verApplicaciones: Boolean = false;

  @Input() nombreTablaUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() nombreTablaRolesPermitidos: string = 'RolesPermitidos';
  @Input() nombreTablaGruposPermitidos: string = 'GruposPermitidos';
  @Input() nombreTablaAreasEmpresaPermitidos: string = 'AreasEmpresaPermitidos';

  @Input() nombreTabUsuarios: string = 'usuarios-tabcontent';
  @Input() nombreHrefTabUsuarios: string = '#usuarios-tabcontent';
  @Input() nombreTabRoles: string = 'roles-tabcontent';
  @Input() nombreHrefTabRoles: string = '#roles-tabcontent';
  @Input() nombreTabGrupos: string = 'grupos-tabcontent';
  @Input() nombreHrefTabGrupos: string = '#grupos-tabcontent';
  @Input() nombreTabAreas: string = 'areas-tabcontent';
  @Input() nombreHrefTabAreas: string = '#areas-tabcontent';

  @Input() multiple: Boolean = false;
  @Input() configCampoAsunto: any = {
    Activo: false,
    Label: 'Asunto',
    Required: true,
    MinLength: 0,
    MaxLength: 1000,
    Title: '',
    Readonly: false,
    Rows: 3,
    Cols: null,
    Pattern: ''
  };

  labelComboxUno: string = "";
  labelComboxDos: string = "";
  idComboxUno: number;
  modeloComboBoxDos: any = { DeniedObsertvations: null };
  AsuntoWrokFlow: any;
  itemsComboxUno: Array<any> = [];
  itemsComboxDos: Array<any> = [];

  tipo: TipoParaPermisos = TipoParaPermisos.AreaEmpresa;
  stringType: string = "";
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityRuleEngine: UtilityRuleEngine,
    public helperImages: HelperImages) { }

  public OnChangeComboxUno(event) {

    if (event) {


      let subStringType: string = "";

      if (this.tipoFiltroResponsable == "UserByArea") {
        subStringType = TipoParaPermisos[TipoParaPermisos.Usuario];
        if (this.responsibles.length > 0)
          this.itemsComboxDos = this.utility.Clone(this.responsibles.filter(x => { return x.Tipo == TipoParaPermisos.Usuario || x.Tipo == this.stringType }));
        else
          this.itemsComboxDos = this.utility.GetItemsAreasRolesUsuarioSeries(TipoParaPermisos.Usuario, true, true).filter(x => { return x.IdAreaEmpresa == event.Id });
      }
      else if (this.tipoFiltroResponsable == "RoleByArea") {
        subStringType = TipoParaPermisos[TipoParaPermisos.Role];
        if (this.responsibles.length > 0)
          this.itemsComboxDos = this.utility.Clone(this.responsibles.filter(x => { return x.Tipo == TipoParaPermisos.Role || x.Tipo == this.stringType }));
        else
          this.itemsComboxDos = this.utility.GetItemsAreasRolesUsuarioSeries(TipoParaPermisos.Role, true, true).filter(x => { return x.IdAreaEmpresa == event.Id });
      }
      else if (this.tipoFiltroResponsable == "UserByRole") {
        subStringType = TipoParaPermisos[TipoParaPermisos.Usuario];
        if (this.responsibles.length > 0)
          this.itemsComboxDos = this.utility.Clone(this.responsibles.filter(x => { return x.Tipo == TipoParaPermisos.Usuario || x.Tipo == this.stringType }));
        else
          this.itemsComboxDos = this.utility.GetItemsAreasRolesUsuarioSeries(TipoParaPermisos.Usuario, true, true).filter(x => { return x.IdRole == event.Id });
      }


    }
    else {
      this.itemsComboxDos = [];
      this.modelo[this.propPermission] = [];
    }
  }
  public OnChangeComboxDos(event) {

    if (!this.multiple) {
      if (event) {
        this.modelo[this.propPermission].push(this.modeloComboBoxDos);
      }
      else {

        this.modeloComboBoxDos = { DeniedObsertvations: null };
        this.modelo[this.propPermission] = [];
      }
    }
  }
  ngOnInit() {

    this.OnSetConfig();
    if (this.tipoFiltroResponsable != "None") {
      this.modelo[this.propPermission] = [];
      this.SetItemsComboxUno();
    }

  }
  public OnSetConfig() {

    if (this.config) {

      this.name = this.config.Name;
      this.tipoFiltroResponsable = this.config.ResponsibleFilterType;

      this.responsibles = this.config.Responsibles;
      if (this.config.PropPermission)
        this.propPermission = this.config.PropPermission;

      if (this.config.UserTitle)
        this.tituloUsuarios = this.config.UserTitle;
      if (this.config.RoleTitle)
        this.tituloRoles = this.config.RoleTitle;
      if (this.config.GroupTitle)
        this.tituloGrupos = this.config.GroupTitle;
      if (this.config.AreaTitle)
        this.tituloAreasEmpresa = this.config.AreaTitle;
      if (this.config.SedeTitle)
        this.tituloSedesEmpresa = this.config.SedeTitle;
      if (this.config.ApplicationTitle)
        this.tituloApplicaciones = this.config.ApplicationTitle;

      this.verUsuarios = this.config.AllowUserReassignment;
      this.verRoles = this.config.AllowRolReassignment;
      this.verGrupos = this.config.AllowGroupReassignment;
      this.verAreasEmpresa = this.config.AllowAreaReassignment;
      this.verSedesEmpresa = this.config.AllowSedepReassignment;
      this.verApplicaciones = this.config.AllowApplicationReassignment;

      this.multiple = this.config.IsMultiple;
      this.labelComboxUno = this.config.LabelComboxParent;
      this.labelComboxDos = this.config.LabelComboxChild;

      if (this.config.SubjectField)
        this.configCampoAsunto = this.config.SubjectField;

    }
  }
  public SetItemsComboxUno() {


    if (this.tipoFiltroResponsable == "UserByArea") {
      this.tipo = TipoParaPermisos.AreaEmpresa;
    }
    else if (this.tipoFiltroResponsable == "RoleByArea") {
      this.tipo = TipoParaPermisos.AreaEmpresa;
    }
    else if (this.tipoFiltroResponsable == "UserByRole") {
      this.tipo = TipoParaPermisos.Role;
    }
    this.stringType = TipoParaPermisos[this.tipo];
    if (this.responsibles.length > 0)
      this.itemsComboxUno = this.utility.Clone(this.responsibles.filter(x => { return x.Tipo == this.tipo || x.Tipo == this.stringType }));
    else
      this.itemsComboxUno = this.utility.GetItemsAreasRolesUsuarioSeries(this.tipo, true, true);
  }

  public Add() {
   
    this.modelo[this.propPermission].push(this.modeloComboBoxDos);
    this.modeloComboBoxDos = { DeniedObsertvations: null };
  }
  public Eliminar(index) {

    this.modelo[this.propPermission].splice(index, 1);
  }
  public Editar(item) {

    this.modeloComboBoxDos = item;
  }
}
