import { composeComparers, negate, isObj, equal, keyComparer } from './helpers';
class List {
    /**
     * Defaults the elements of the list
     */
    constructor(elements = []) {
       // this._elements = elements;
       this.ConvertElements(elements);
    }

    ConvertElements(elements)
    {
        const that = this;
        if(Array.isArray(elements))
          this._elements = elements;
        else{
            this._elements =[];
            $.each(elements, function (key, value) {
                that._elements.push({key:key,value:value});
            });
        }
    }
    /**
     * Adds an object to the end of the List<T>.
     */
    Add(element) {
        this._elements.push(element);
    }
    /**
     * Adds the elements of the specified collection to the end of the List<T>.
     */
    AddRange(elements) {
        this._elements.push(...elements);
    }
    /**
     * Applies an accumulator function over a sequence.
     */
    Aggregate(accumulator, initialValue) {
        return this._elements.reduce(accumulator, initialValue);
    }
    /**
     * Determines whether all elements of a sequence satisfy a condition.
     */
    All(predicate) {
        return this._elements.every(predicate);
    }
    Any(predicate) {
        return predicate
            ? this._elements.some(predicate)
            : this._elements.length > 0;
    }
    Average(transform) {
        return this.Sum(transform) / this.Count(transform);
    }
    /**
     * Casts the elements of a sequence to the specified type.
     */
    Cast() {
        return new List(this._elements);
    }
    /**
     * Concatenates two sequences.
     */
    Concat(list) {
        return new List(this._elements.concat(list.ToArray()));
    }
    /**
     * Determines whether an element is in the List<T>.
     */
    Contains(element) {
        return this._elements.some(x => x === element);
    }
    Count(predicate) {
        return predicate ? this.Where(predicate).Count() : this._elements.length;
    }
    /**
     * Returns the elements of the specified sequence or the type parameter's default value
     * in a singleton collection if the sequence is empty.
     */
    DefaultIfEmpty(defaultValue) {
        return this.Count() ? this : new List([defaultValue]);
    }
    /**
     * Returns distinct elements from a sequence by using the default equality comparer to compare values.
     */
    Distinct() {
        return this.Where((value, index, iter) => (isObj(value)
            ? iter.findIndex(obj => equal(obj, value))
            : iter.indexOf(value)) === index);
    }
    /**
     * Returns distinct elements from a sequence according to specified key selector.
     */
    DistinctBy(keySelector) {
        const groups = this.GroupBy(keySelector);
        return Object.keys(groups).reduce((res, key) => {
            res.Add(groups[key][0]);
            return res;
        }, new List());
    }
    /**
     * Returns the element at a specified index in a sequence.
     */
    ElementAt(index) {
        if (index < this.Count() && index >= 0) {
            return this._elements[index];
        }
        else {
            const MSG = 'ArgumentOutOfRangeException: index is less than 0 or greater than or equal to the number of elements in source.';
            throw new Error(MSG);
        }
    }
    /**
     * Returns the element at a specified index in a sequence or a default value if the index is out of range.
     */
    ElementAtOrDefault(index) {
        return this.ElementAt(index) !== undefined && this.ElementAt(index);
    }
    /**
     * Produces the set difference of two sequences by using the default equality comparer to compare values.
     */
    Except(source) {
        return this.Where(x => !source.Contains(x));
    }
    First(predicate) {
        if (this.Count()) {
            return predicate ? this.Where(predicate).First() : this._elements[0];
        }
        else {
            throw new Error('InvalidOperationException: The source sequence is empty.');
        }
    }
    FirstOrDefault(predicate) {
        return this.Count(predicate) ? this.First(predicate) : undefined;
    }
    /**
     * Performs the specified action on each element of the List<T>.
     */
    ForEach(action) {
        return this._elements.forEach(action);
    }
    /**
     * Groups the elements of a sequence according to a specified key selector function.
     */
    GroupBy(grouper, mapper) {
        const initialValue = {};
        if (!mapper) {
            mapper = val => val;
        }
        return this.Aggregate((ac, v) => {
            const key = grouper(v);
            const existingGroup = ac[key];
            const mappedValue = mapper(v);
            if (existingGroup) {
                existingGroup.push(mappedValue);
            }
            else {
                ac[key] = [mappedValue];
            }
            return ac;
        }, initialValue);
    }
    /**
     * Correlates the elements of two sequences based on equality of keys and groups the results.
     * The default equality comparer is used to compare keys.
     */
    GroupJoin(list, key1, key2, result) {
        return this.Select(x => result(x, list.Where(z => key1(x) === key2(z))));
    }
    /**
     * Returns the index of the first occurence of an element in the List.
     */
    IndexOf(element) {
        return this._elements.indexOf(element);
    }
    /**
     * Inserts an element into the List<T> at the specified index.
     */
    Insert(index, element) {
        if (index < 0 || index > this._elements.length) {
            throw new Error('Index is out of range.');
        }
        this._elements.splice(index, 0, element);
    }
    /**
     * Produces the set intersection of two sequences by using the default equality comparer to compare values.
     */
    Intersect(source) {
        return this.Where(x => source.Contains(x));
    }
    /**
     * Correlates the elements of two sequences based on matching keys. The default equality comparer is used to compare keys.
     */
    Join(list, key1, key2, result) {
        return this.SelectMany(x => list.Where(y => key2(y) === key1(x)).Select(z => result(x, z)));
    }
    Last(predicate) {
        if (this.Count()) {
            return predicate
                ? this.Where(predicate).Last()
                : this._elements[this.Count() - 1];
        }
        else {
            throw Error('InvalidOperationException: The source sequence is empty.');
        }
    }
    LastOrDefault(predicate) {
        return this.Count(predicate) ? this.Last(predicate) : undefined;
    }
    Max(selector) {
        const id = x => x;
        return Math.max(...this._elements.map(selector || id));
    }
    Min(selector) {
        const id = x => x;
        return Math.min(...this._elements.map(selector || id));
    }
    /**
     * Filters the elements of a sequence based on a specified type.
     */
    OfType(type) {
        let typeName;
        switch (type) {
            case Number:
                typeName = typeof 0;
                break;
            case String:
                typeName = typeof '';
                break;
            case Boolean:
                typeName = typeof true;
                break;
            case Function:
                typeName = typeof function () { }; // tslint:disable-line no-empty
                break;
            default:
                typeName = null;
                break;
        }
        return typeName === null
            ? this.Where(x => x instanceof type).Cast()
            : this.Where(x => typeof x === typeName).Cast();
    }
    /**
     * Sorts the elements of a sequence in ascending order according to a key.
     */
    OrderBy(keySelector, comparer = keyComparer(keySelector, false)) {
        return new OrderedList(this._elements, comparer);
    }
    /**
     * Sorts the elements of a sequence in descending order according to a key.
     */
    OrderByDescending(keySelector, comparer = keyComparer(keySelector, true)) {
        return new OrderedList(this._elements, comparer);
    }
    /**
     * Performs a subsequent ordering of the elements in a sequence in ascending order according to a key.
     */
    ThenBy(keySelector) {
        return this.OrderBy(keySelector);
    }
    /**
     * Performs a subsequent ordering of the elements in a sequence in descending order, according to a key.
     */
    ThenByDescending(keySelector) {
        return this.OrderByDescending(keySelector);
    }
    /**
     * Removes the first occurrence of a specific object from the List<T>.
     */
    Remove(element) {
        return this.IndexOf(element) !== -1
            ? (this.RemoveAt(this.IndexOf(element)), true)
            : false;
    }
    /**
     * Removes all the elements that match the conditions defined by the specified predicate.
     */
    RemoveAll(predicate) {
        return this.Where(negate(predicate));
    }
    /**
     * Removes the element at the specified index of the List<T>.
     */
    RemoveAt(index) {
        this._elements.splice(index, 1);
    }
    /**
     * Reverses the order of the elements in the entire List<T>.
     */
    Reverse() {
        return new List(this._elements.reverse());
    }
    /**
     * Projects each element of a sequence into a new form.
     */
    Select(selector) {
        return new List(this._elements.map(selector));
    }
    /**
     * Projects each element of a sequence to a List<any> and flattens the resulting sequences into one sequence.
     */
    SelectMany(selector) {
        return this.Aggregate((ac, _, i) => (ac.AddRange(this.Select(selector)
            .ElementAt(i)
            .ToArray()),
            ac), new List());
    }
    /**
     * Determines whether two sequences are equal by comparing the elements by using the default equality comparer for their type.
     */
    SequenceEqual(list) {
        return !!this._elements.reduce((x, y, z) => (list._elements[z] === y ? x : undefined));
    }
    /**
     * Returns the only element of a sequence, and throws an exception if there is not exactly one element in the sequence.
     */
    Single(predicate) {
        if (this.Count(predicate) !== 1) {
            throw new Error('The collection does not contain exactly one element.');
        }
        else {
            return this.First(predicate);
        }
    }
    /**
     * Returns the only element of a sequence, or a default value if the sequence is empty;
     * this method throws an exception if there is more than one element in the sequence.
     */
    SingleOrDefault(predicate) {
        return this.Count(predicate) ? this.Single(predicate) : undefined;
    }
    /**
     * Bypasses a specified number of elements in a sequence and then returns the remaining elements.
     */
    Skip(amount) {
        return new List(this._elements.slice(Math.max(0, amount)));
    }
    /**
     * Bypasses elements in a sequence as long as a specified condition is true and then returns the remaining elements.
     */
    SkipWhile(predicate) {
        return this.Skip(this.Aggregate(ac => (predicate(this.ElementAt(ac)) ? ++ac : ac), 0));
    }
    Sum(transform) {
        return transform
            ? this.Select(transform).Sum()
            : this.Aggregate((ac, v) => (ac += +v), 0);
    }
    /**
     * Returns a specified number of contiguous elements from the start of a sequence.
     */
    Take(amount) {
        return new List(this._elements.slice(0, Math.max(0, amount)));
    }
    /**
     * Returns elements from a sequence as long as a specified condition is true.
     */
    TakeWhile(predicate) {
        return this.Take(this.Aggregate(ac => (predicate(this.ElementAt(ac)) ? ++ac : ac), 0));
    }
    /**
     * Copies the elements of the List<T> to a new array.
     */
    ToArray() {
        return this._elements;
    }
    ToDictionary(key, value) {
        return this.Aggregate((dicc, v, i) => {
            dicc[this.Select(key)
                .ElementAt(i)
                .toString()] = value ? this.Select(value).ElementAt(i) : v;
            dicc.Add({
                Key: this.Select(key).ElementAt(i),
                Value: value ? this.Select(value).ElementAt(i) : v
            });
            return dicc;
        }, new List());
    }
    /**
     * Creates a List<T> from an Enumerable.List<T>.
     */
    ToList() {
        return this;
    }
    /**
     * Creates a Lookup<TKey, TElement> from an IEnumerable<T> according to specified key selector and element selector functions.
     */
    ToLookup(keySelector, elementSelector) {
        return this.GroupBy(keySelector, elementSelector);
    }
    /**
     * Produces the set union of two sequences by using the default equality comparer.
     */
    Union(list) {
        return this.Concat(list).Distinct();
    }
    /**
     * Filters a sequence of values based on a predicate.
     */
    Where(predicate) {
        return new List(this._elements.filter(predicate));
    }
    /**
     * Applies a specified function to the corresponding elements of two sequences, producing a sequence of the results.
     */
    Zip(list, result) {
        return list.Count() < this.Count()
            ? list.Select((x, y) => result(this.ElementAt(y), x))
            : this.Select((x, y) => result(x, list.ElementAt(y)));
    }
}
/**
 * Represents a sorted sequence. The methods of this class are implemented by using deferred execution.
 * The immediate return value is an object that stores all the information that is required to perform the action.
 * The query represented by this method is not executed until the object is enumerated either by
 * calling its ToDictionary, ToLookup, ToList or ToArray methods
 */
class OrderedList extends List {
    constructor(elements, _comparer) {
        super(elements);
        this._comparer = _comparer;
        this._elements.sort(this._comparer);
    }
    /**
     * Performs a subsequent ordering of the elements in a sequence in ascending order according to a key.
     * @override
     */
    ThenBy(keySelector) {
        return new OrderedList(this._elements, composeComparers(this._comparer, keyComparer(keySelector, false)));
    }
    /**
     * Performs a subsequent ordering of the elements in a sequence in descending order, according to a key.
     * @override
     */
    ThenByDescending(keySelector) {
        return new OrderedList(this._elements, composeComparers(this._comparer, keyComparer(keySelector, true)));
    }
}
export default List;
