import { Input, Output, EventEmitter } from '@angular/core';
import { Utilities } from '../helpers/utilities';
import { BaseServiceService } from '../services/base-service.service';
import { AnonymousSubject } from 'rxjs/internal/Subject';
//import { List } from '../../assets/linqts/compilado/index';
import { List } from '../../assets/linqts/compilado/index';
import { Guid } from 'guid-typescript';
import { CrudActions } from '../models/general.enum';
import { ComponentsContainer } from '../models/components-container';
import { DataTableComponent } from '../components/controls/data-table/data-table.component';



export class ComboBoxManager {


    constructor(public utility: Utilities,
        public baseService: BaseServiceService,
        public configs: any,
        public componentName: string,
        public modelo: any,
        public modeloCascada: any,
        public parentOption?: any,
        public context?: any,
        public isModelContainer?: boolean,
        public componentsContainer?: ComponentsContainer
    ) {

    }

    SetMapping(modelo, config, itemValid, item) {
        $.each(config.MappingFields, function (index, mapping: any) {
            if (itemValid) {
                if (item.hasOwnProperty(mapping.From))
                    modelo[mapping.To] = item[mapping.From];
                else
                    modelo[mapping.To] = mapping.From;
            }
            else {
                if (config.DefaultMapping != undefined) {
                    modelo[mapping.To] = config.DefaultMapping;
                }
                else {
                    if (item.hasOwnProperty(mapping.From))
                        modelo[mapping.To] = item[mapping.Default];
                    else
                        modelo[mapping.To] = mapping.Default;
                }
            }
        });
    }
    public CascadingTables(item: any, controlName: string, config: any, resetModel: boolean = true,
        mappingFieldsEvent: EventEmitter<any> = new EventEmitter<any>(), isEdit: boolean = false,
        property: string, dataProperty: string, isClean: boolean) {

        if (!this.componentsContainer)
            return;

        let tables = new List<any>(this.componentsContainer.Tables).Where(x => {
            return x.config.ConfigControl.ConfigParent && x.config.ConfigControl.ConfigParent.ParentName == controlName;
        }).ToArray();

        $.each(tables, (index, table: DataTableComponent) => {
            //let fieldName = config.key.replace("Config", "");
            // if (config.value.Context) {
            table.cargarGrilla = !isClean;

            if (table.QueryConfig.Entity && !isClean) {

                if (!table.QueryConfig.EntityTemp)
                    table.QueryConfig.EntityTemp = this.utility.Clone(table.QueryConfig.Entity);
                else
                    table.QueryConfig.Entity = this.utility.Clone(table.QueryConfig.EntityTemp);
                if (Array.isArray(item)) {
                    if (item.length == 1)
                        table.QueryConfig.Entity[property] = item[0][dataProperty];
                    else
                        table.QueryConfig.Entity[property.replace('Id', '')] = new List<any>(item).Select(x => { return x[dataProperty] }).ToArray().join(',');
                }
                else
                    table.QueryConfig.Entity[property] = item[dataProperty];
            }
            table.ReCargarGrilla();


        });

    }
    public OnSelectedComboBox(item: any, controlName: string, config: any,
        mappingFieldsEvent: EventEmitter<any> = new EventEmitter<any>()) {

        this.ExecuteCascading(item, controlName, config, true, mappingFieldsEvent);
    }
    public ExecuteCascading(item: any, controlName: string, config: any, resetModel: boolean = true,
        mappingFieldsEvent: EventEmitter<any> = new EventEmitter<any>(), isEdit: boolean = false) {
        const that = this;
        try {

            let _configs = new List<any>(this.configs);
            var configs = _configs.Where(x => {
                return x.value.ConfigParent && x.value.ConfigParent.ParentName == controlName;
            }).ToArray();

            let property = controlName;
            let dataProperty = controlName;

            if (config.ConfigParent) {
                property = (config.ConfigParent.Property) ? config.ConfigParent.Property : property;
                dataProperty = (config.ConfigParent.DataProperty) ? config.ConfigParent.DataProperty : dataProperty;
            }

            let itemValid = ((item && !Array.isArray(item)) || (item && Array.isArray(item) && item.length > 0))

            if (config.MappingFields && config.MappingFields.length > 0 && isEdit !== true) {
                if (this.isModelContainer) {
                    this.SetMapping(this.modelo.modelo, config, itemValid, item);
                }
                else
                    this.SetMapping(this.modelo, config, itemValid, item);

                if (mappingFieldsEvent)
                    mappingFieldsEvent.emit(this.modelo);
            }

            if (itemValid) {

                $.each(configs, function (index, config: any) {
                    let fieldName = config.key.replace("Config", "");
                    // if (config.value.Context) {

                    if (config.value.ConfigParent.JsonProperty) {

                        let items = JSON.parse(item[config.value.ConfigParent.JsonProperty]);
                        if (!items) {
                            that.utility.logger.LogWarningText(`ControlName: ${this.controlName} method: ExecuteCascading, La propiedad es nula ${config.value.ConfigParent.JsonProperty}`)
                        }
                        if (items && config.value.ConfigParent.ItemsProperty) {
                            items = items[config.value.ConfigParent.ItemsProperty];
                            config.value.Context.CargarItemsComboBox(null, true, items, true, resetModel);
                        }
                    }
                    else {
                        config.value.NotLoad = false;

                        if (config.value.Filter) {
                            if (!config.value.FilterTemp)
                                config.value.FilterTemp = config.value.Filter;
                            else
                                config.value.Filter = config.value.FilterTemp;
                            if (Array.isArray(item)) {
                                config.value.Filter += ' && ' + property + ' IN (' + new List<any>(item).Select(x => { return x[dataProperty] }).ToArray().join(',') + ')';
                            }
                            else
                                config.value.Filter += ' && ' + property + ' = ' + item[dataProperty];
                        }
                        else if (config.value.Params) {

                            if (!config.value.ParamsTemp)
                                config.value.ParamsTemp = that.utility.Clone(config.value.Params);
                            else
                                config.value.Params = that.utility.Clone(config.value.ParamsTemp);
                            if (Array.isArray(item)) {
                                if (item.length == 1) {
                                    config.value.Params[property] = item[0][dataProperty];

                                    if (fieldName == 'IdSubSerie' && config.value.QueryConfig.Command == 'ConsultaSubSeries') {
                                        config.value.Params[property.replace('Id', '')] = item[0][dataProperty];
                                    }
                                }
                                else
                                    config.value.Params[property.replace('Id', '')] = new List<any>(item).Select(x => { return x[dataProperty] }).ToArray().join(',');
                            }
                            else
                                config.value.Params[property] = item[dataProperty];
                        }
                        // OJO QUE ACA SE DEBE IMPLEMENTAR EL CODIGO
                        //(callback: (data:any) => void,config:any,controlName:string,reload:boolean,_items:any[],notCache:boolean)

                        if (config.value.Context) {

                            //config.value.Context.notLoad=true;
                            config.value.Context.CargarItemsComboBox(null, true, null, true, resetModel);
                        }
                        else if (that.context)
                            that.CargarItemsComboBox(null, config.value, fieldName, true, null, true, resetModel);
                        else
                            that.utility.logger.LogError('No se encuentra asignado el contexto para el control', null, { componentName: that.componentName, method: 'ExecuteCascading', controlName: fieldName });

                        //config.value.CargarItems(null, true,null,true);
                        // }

                    }

                });


                this.CascadingTables(item, controlName, config, resetModel,
                    mappingFieldsEvent, isEdit, property, dataProperty, false);
            }
            else {
                $.each(configs, function (index, config: any) {
                    let fieldName = config.key.replace("Config", "");
                    // if (config.value.Context) {
                    config.value.NotLoad = false;
                    if (config.value.ConfigParent.NotClear == true) {
                        if (config.value.Filter) {
                            if (config.value.FilterTemp)
                                config.value.Filter = config.value.FilterTemp;
                        }
                        // OJO QUE ACA SE DEBE IMPLEMENTAR EL CODIGO
                        //(callback: (data:any) => void,config:any,controlName:string,reload:boolean,_items:any[],notCache:boolean)

                        // config.value.Context.CargarItemsComboBox(null, true, null, true);
                        if (config.value.Context)
                            config.value.Context.CargarItemsComboBox(null, true, null, true, resetModel);
                        else if (that.context)
                            that.CargarItemsComboBox(null, config.value, fieldName, true, null, true, resetModel);

                        else
                            that.utility.logger.LogError('No se encuentra asignado el contexto para el control', null,
                                { componentName: that.componentName, method: 'ExecuteCascading', controlName: fieldName });

                        //config.value.CargarItems(null, true,null,true);
                    }
                    else {
                        // OJO QUE ACA SE DEBE IMPLEMENTAR EL CODIGO
                        //(callback: (data:any) => void,config:any,controlName:string,reload:boolean,_items:any[],notCache:boolean)

                        // config.value.Context.CargarItemsComboBox(null, false, [], true);
                        if (config.value.Context)
                            config.value.Context.CargarItemsComboBox(null, false, [], true, resetModel);
                        else if (that.context)
                            that.CargarItemsComboBox(null, config.value, fieldName, false, [], true, resetModel);

                        else
                            that.utility.logger.LogError('No se encuentra asignado el contexto para el control', null,
                                { componentName: that.componentName, method: 'ExecuteCascading', controlName: fieldName });

                        // config.value.CargarItems(null, false, [],true);
                    }


                });

                this.CascadingTables(item, controlName, config, resetModel,
                    mappingFieldsEvent, isEdit, property, dataProperty, true);
            }
        }
        catch (error) {
            that.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'ExecuteCascading', controlName: controlName });
            that.utility.VerModalDanger().then(data => { });
        }
    }


    // public EditCascading(modelo?: any, modeloCascada?: any) {

    //     if (modelo)
    //         this.modelo = modelo;
    //     if (modeloCascada)
    //         this.modeloCascada = modeloCascada;
    //     if (!this.modeloCascada)
    //         this.modeloCascada = {};
    //     let _configs = new List<any>(this.configs);
    //     var configs = _configs.Where(x => {
    //         return x.value.ConfigParent;
    //     }).OrderByDescending(order => {
    //         return order.value.ConfigParent.Index;
    //     }).ToArray();

    //
    //     if (configs.length > 0) {


    //         let parentConfig = configs[0];
    //         // if (parentConfig.value.ConfigParent.InModel) {
    //         let controlName = (parentConfig.value.PropertyInclude) ? parentConfig.value.PropertyInclude :
    //             parentConfig.key.replace("Config", "");
    //         let item = null;
    //         if (this.isModelContainer) {
    //             item = parentConfig.value.Context.FindItem(this.modelo[parentConfig.value.Context.config.ModelContainer][controlName]);
    //         }
    //         else
    //             item = parentConfig.value.Context.FindItem(this.modelo[controlName]);
    //         this.ExecuteCascading(item, controlName, parentConfig.value, false, null, true);

    //         // }
    //         //else
    //         //    this.LoadSelectValueCascading(configs);
    //     }
    // }

     public InternalEditCascading(config?: any, index?: any) {
                if(index >= config.length)
                {
                    return true;
                }
                let parentConfig = config[index];
                // if (parentConfig.value.ConfigParent.InModel) {
                let controlName = (parentConfig.value.PropertyInclude) ? parentConfig.value.PropertyInclude :
                    parentConfig.key.replace("Config", "");
                let item = null;

                if (this.isModelContainer) {
                    item = parentConfig.value.Context.FindItem(this.modelo[parentConfig.value.Context.config.ModelContainer][controlName]);
                }
                else
                    item = parentConfig.value.Context.FindItem(this.modelo[controlName]);
                if (!item && parentConfig.value.Context.cascadingLoadEmitter.observers.length === 0) {
                      parentConfig.value.Context.cascadingLoadEmitter.subscribe(data => {
                         item = data && parentConfig.value.Context && parentConfig.value.Context.FindItem ? this.isModelContainer ? parentConfig.value.Context.FindItem(this.modelo[parentConfig.value.Context.config.ModelContainer][controlName]) : parentConfig.value.Context.FindItem(this.modelo[controlName]) : item;
                        this.ExecuteCascading(item, controlName, parentConfig.value, false, null);
                        return this.InternalEditCascading(config, index + 1);
                    })
                }
                else
                    {
                        this.ExecuteCascading(item, controlName, parentConfig.value, false, null);
                        return this.InternalEditCascading(config, index + 1);
                    }
            
    }

    public EditCascading(modelo?: any, modeloCascada?: any) {
        if (modelo)
            this.modelo = modelo;
        if (modeloCascada)
            this.modeloCascada = modeloCascada;
        if (!this.modeloCascada)
            this.modeloCascada = {};
        let _configs = new List<any>(this.configs);
        let globalParents = _configs.Where(x => {
            return x.value.ConfigParent;
        }).OrderByDescending(order => {
            return -order.value.ConfigParent.Index;
        }).GroupBy(group => {
            return group.value.ConfigParent.Group;
        });
        //RECORRER GRUPOS
        
        for (const uniqueParent in globalParents) {
            if (Object.prototype.hasOwnProperty.call(globalParents, uniqueParent)) {
                const config = globalParents[uniqueParent];
                this.InternalEditCascading(config, 0);
            }
        }
            
    }
    private CreateInclude(configs: any, currentIndex: number): any {

        let currentJoin = null;

        if (configs.length > 0 && currentIndex <= (configs.length - 1)) {
            let config = configs[currentIndex];
            let fieldName = (config.value.PropertyInclude) ? config.value.PropertyInclude : config.key.replace("Config", "");
            currentJoin = { Name: config.value.Controller, Joins: [], Fields: fieldName, PropertyInclude: config.value.PropertyInclude };
            currentIndex++;
            if (configs.length > 0 && currentIndex <= (configs.length - 1)) {
                currentJoin.Joins.push(this.CreateInclude(configs, currentIndex));
            }
        }
        return currentJoin;
    }
    GetValueFromModel(fieldName: string): any {
        if (this.modelo.modelo) {
            return (this.modelo.modelo[fieldName] === undefined) ? this.modelo.modeloCascada[fieldName] : this.modelo.modelo[fieldName];
        }
        else {
            return (this.modelo[fieldName] === undefined) ? this.modeloCascada[fieldName] : this.modelo[fieldName];
        }

    }
    public LoadSelectValueCascading(configs: any) {

        if (configs.length > 0) {


            const that = this;
            let _configsOrder = new List<any>(configs);
            let configsOrder = _configsOrder.OrderBy(order => {
                return order.value.ConfigParent.Index;
            }).ToArray();

            let configFilter = configsOrder[0];
            let obj = that.utility.GetApiModel('LoadSelectValueCascading', this.componentName);
            let fieldName = '';
            let valor;
            let controlName = configFilter.key.replace("Config", "");
            if (!(Object.keys(configFilter.value.QueryConfig).length === 0 && configFilter.value.QueryConfig.constructor === Object)) {
                obj.QueryConfig = configFilter.value.QueryConfig;
            }
            else if (configFilter.value.EditCascading) {
                fieldName = (configFilter.value.EditCascading.Property) ?
                    configFilter.value.EditCascading.Property : controlName;

                valor = this.GetValueFromModel(controlName);
                obj.QueryConfig.IsSp = true;
                obj.QueryConfig.Command = configFilter.value.EditCascading.Command;
                obj.QueryConfig.OutPutParam = configFilter.value.EditCascading.OutPutParam;
                obj.QueryConfig.Entity = {};
                obj.QueryConfig.Entity[fieldName] = valor;
            }
            else {
                obj.QueryConfig.Include = { JoinModel: this.CreateInclude(configsOrder, 0) }
                obj.QueryConfig.Fields = {};
                fieldName = "Id" + obj.QueryConfig.Include.JoinModel.Name.substr(1);
                valor = this.GetValueFromModel(fieldName);
                obj.QueryConfig.Fields[fieldName] = fieldName;
                obj.QueryConfig.Filter = obj.QueryConfig.Include.JoinModel.Name + '.' + fieldName + ' = ' + valor;

                $.each(configsOrder, function (index, config) {

                    if (config.value.Filter) {
                        var filters = config.value.Filter.split('&&');
                        $.each(filters, function (_index, _filter) {
                            obj.QueryConfig.Filter += ' AND ' + config.value.Controller + '.' + _filter.toString().replace('true', '1').replace('false', '0');
                        });
                    }
                    else if (config.value.Params) {

                        $.each(config.value.Params, function (key: string, paramValue: string) {
                            obj.QueryConfig.Filter += ' AND ' + config.value.Controller + '.' + key + ' = ' + paramValue.toString().replace('true', '1').replace('false', '0');
                        });
                    }
                });
            }


            this.baseService.Get(obj, null, { componentName: this.componentName + 'Controller', method: 'LoadSelectValueCascading' },
                this.componentName).then(data => {

                    that.LoadItemsCascading(configs, (configs.length - 1), data.Data[0]);

                });
        }
    }
    public LoadItemsCascading(configs: any, currentIndex: number, data: any) {
        const that = this;
        if (configs.length > 0 && currentIndex > 0) {// && currentIndex <= (configs.length - 1)) {

            let config = configs[currentIndex];
            //let fieldName =  config.key.replace("Config", "");


            //let fieldName = '';

            let controlName = config.key.replace("Config", "");

            let fieldName = (config.value.EditCascading && config.value.EditCascading.Property) ?
                config.value.EditCascading.Property : controlName;

            if (config.value.Context) {


                // MODIFICAR PARA CARGA
                //(callback: (data:any) => void,config:any,controlName:string,reload:boolean,_items:any[],notCache:boolean)
                // config.value.CargarItems(function (resultItems) {
                if (currentIndex == (configs.length - 1)) {
                    if (!that.modelo.hasOwnProperty(controlName)) {
                        that.modeloCascada[controlName] = data[fieldName];
                        that.ExecuteCascading(that.modeloCascada, controlName, config.value, false);
                    }
                    else
                        that.ExecuteCascading(that.modelo, controlName, config.value, false);
                    currentIndex--;
                    that.LoadItemsCascading(configs, currentIndex, data);
                }
                else {
                    config.value.Context.CargarItemsComboBox(function (resultItems) {

                        if (!that.modelo.hasOwnProperty(controlName)) {
                            that.modeloCascada[controlName] = data[fieldName];
                            that.ExecuteCascading(that.modeloCascada, controlName, config.value, false);
                        }
                        currentIndex--;
                        that.LoadItemsCascading(configs, currentIndex, data);
                    }, true, null, true, false);
                }

            }
            else if (config.value.NotLoad == true) {

                if (currentIndex == (configs.length - 1)) {
                    if (!that.modelo.hasOwnProperty(controlName)) {
                        that.modeloCascada[controlName] = data[fieldName];
                        that.ExecuteCascading(that.modeloCascada, controlName, config.value, false);
                    }
                    currentIndex--;
                    that.LoadItemsCascading(configs, currentIndex, data);
                }

            }
            else if (this.context) {
                this.CargarItemsComboBox(function (resultItems) {
                    if (!this.modelo.hasOwnProperty(controlName)) {

                        this.modeloCascada[controlName] = data[fieldName];

                        this.ExecuteCascading(this.modeloCascada, controlName, config.value);
                    }

                    currentIndex++;
                    this.LoadItemsCascading(configs, currentIndex, data);
                }, config.value, controlName, true, null, true, false);
            }

            else if (config.value.NotLoad !== true) {
                that.utility.logger.LogError('No se encuentra asignado el contexto para el control', null, { componentName: this.componentName, method: 'LoadItemsCascading', controlName: fieldName });


            }
        }
    }

    public FilterControlConfigs() {

        if (this.parentOption && this.parentOption.ConfigControlsToFilter) {

            const that = this;
            setTimeout(() => {

                $.each(that.parentOption.ConfigControlsToFilter, function (index, controlToFilter) {

                    var config = that.configs['Config' + controlToFilter.ControlName];
                    if (config && config.CargarItems) {

                        if (config.Filter) {
                            if (!config.FilterTempFilter)
                                config.FilterTempFilter = config.Filter;
                            else
                                config.Filter = config.FilterTempFilter;

                            config.Filter += ' && ' + controlToFilter.FilterField + ' = ' + controlToFilter.Value;
                        }
                        else if (config.Params) {
                            if (!config.ParamsTempFilter)
                                config.ParamsTempFilter = that.utility.Clone(config.Params); //config.Params;
                            else
                                config.Params = that.utility.Clone(config.ParamsTempFilter);

                            config.Params[controlToFilter.FilterField] = controlToFilter.Value;
                        }
                        else {
                            config.Params = {};
                            config.Params[controlToFilter.FilterField] = controlToFilter.Value;
                        }
                        // OJO QEU ESTO HAY QUE CAMBIARLO
                        //(callback: (data:any) => void,config:any,controlName:string,reload:boolean,_items:any[],notCache:boolean)

                        if (config.value.Context)
                            config.value.Context.CargarItemsComboBox(null, true, null, true);
                        else if (that.context) {
                            that.CargarItemsComboBox(null, config, controlToFilter.ControlName, true, null, true);
                        }
                        else
                            that.utility.logger.LogError('No se encuentra asignado el contexto para el control', null,
                                { componentName: that.componentName, method: 'ExecuteCascading', controlName: controlToFilter.ControlName });
                        //config.CargarItems(null, true,null,true);
                    }
                });
                //isSetRuleEngine =true;
            }, 500);



        }

    }


    /////// METODOS PARA CARGA LOCAL////////////////////////

    private SetItemsComboBox(controlName: string, items: any[], context: any, config: any, resetModel: boolean = true) {
        const that = this;
        try {
            context.itemsSelectControls['Items' + controlName] = items;
            context.itemsSelectControls['Items' + controlName] = context.itemsSelectControls['Items' + controlName].slice();
            if (resetModel && context.modelo.hasOwnProperty(controlName)) {
                if (config.IsMultiple)
                    context.modelo[controlName] = [];
                else
                    context.modelo[controlName] = null;
            }
        }
        catch (error) {
            that.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'SetItemsComboBox', controlName: controlName });
            that.utility.VerModalDanger().then(data => { });
        }
    }


    public CargarItemsComboBox(callback: (data: any) => void, config: any, controlName: string, reload: boolean, _items: any[], notCache: boolean,
        resetModel: boolean = true) {


        const that = this;
        try {
            that.SetItemsComboBox(controlName, [], that, config, resetModel);

            if (config.SetCargarItems)
                config.CargarItemsComboBox = this.CargarItemsComboBox;

            if (config && !config.SetConfig)
                config.SetConfig = null;//_SetConfig;
            if (_items) {
                //that.context.itemsSelectControls['Items'+controlName]=_items;
                that.SetItemsComboBox(controlName, _items, that, config, resetModel);
                if (config.keyCache && that.context.itemsSelectControls['Items' + controlName] && that.context.itemsSelectControls['Items' + controlName].length > 0)
                    that.utility.SetCache(config.keyCache + controlName, that.context.itemsSelectControls['Items' + controlName]);
                return;
            }
            if (config.keyCache && !reload) {
                var temp = that.utility.GetCache(config.keyCache + controlName);
                if (temp) {
                    // that.context.itemsSelectControls['Items'+controlName] = temp;
                    that.SetItemsComboBox(controlName, temp, that, config, resetModel);
                    that.SetCallBack(config, controlName, that.context.itemsSelectControls['Items' + controlName]);
                    return;
                }
            }
            if (config && config.CallbackLoad) {

                config.CallbackLoad(config, function (__items) {

                    // that.itemsSelectControls['Items'+controlName] = __items;
                    that.SetItemsComboBox(controlName, __items, that, config);
                })
            }
            else {
                //if (!vm.config && !vm.cmd)
                //    throw 'comboBox [' + vm.controlName + '] Config no pude ser nulo...'
                if (config && config.NotLoad == true) {
                }
                else {
                    if (config && config.Items) {
                        that.utility.logger.LogInfoText('ComboBox [' + controlName + '] datos desde config.dataSource');
                        //that.context.itemsSelectControls['Items'+controlName] = config.Items;
                        that.SetItemsComboBox(controlName, config.Items, that, config, resetModel);
                        that.SetCallBack(config, controlName, that.context.itemsSelectControls['Items' + controlName]);
                    }
                    else {

                        var cmd = config.Cmd;
                        var controller = config.Controller;

                        cmd = (!cmd) ? controller : cmd;
                        that.utility.logger.LogInfoText('ComboBox [' + controlName + '] Cmd: [' + cmd + ']');


                        if (!controller) {
                            that.utility.logger.LogWarningText('comboBox [' + controlName + '] El Controller y la config con nulo o vacio');
                            return;
                        }

                        if (!controller && !config.Url)
                            throw 'comboBox [' + controlName + '] El Controller y la Url no pude ser nulo o vacio';


                        var obj = that.utility.GetApiModel('CargarItemsComboBoxComboBox', cmd);
                        if (config) {
                            if (config.CrudAction)
                                obj.QueryConfig.CrudAction = config.CrudAction;

                            if (config.QueryConfig)
                                obj.QueryConfig = config.QueryConfig;

                            if (config.IsSp == true) {

                                obj.QueryConfig = null;
                                obj.Filter = config.Filter;
                                obj.SActionName = config.Cmd;
                                if (config.Params) {
                                    obj.Parameters = {};
                                    $.each(config.Params, function (key, val) {
                                        obj.Parameters[key] = val;
                                    });
                                }
                            }
                            else if (config.Command) {

                                obj.QueryConfig = null;
                                obj.Command = config.Command;
                            }
                            else if (config.Filter && config.Filter != '') {

                                obj.QueryConfig = null;
                                obj.Filter = config.Filter;
                                obj.Skip = config.Skip;
                                obj.Take = config.Take;
                                obj.OrderBY = config.OrderBY;
                            }
                            else if (config.Params && !config.IsSp) {

                                obj.QueryConfig = { AppSettingsKey: (!cmd) ? controller : cmd, CrudAction: CrudActions.GetByFilter };

                            }

                            if (obj.QueryConfig) {
                                obj.QueryConfig.Entity = {};

                                if (config.Params && !config.IsSp) {
                                    $.each(config.Params, function (key, val) {
                                        obj.QueryConfig.Entity[key] = val;
                                    });
                                }
                            }
                        }
                        else {
                            obj.QueryConfig.CrudAction = CrudActions.GetAllOrderByDesc;
                        }
                        if (config && config.Url) {


                            that.baseService.Ejecutar(obj, null,
                                {
                                    componentName: that.componentName,
                                    method: 'CargarItemsComboBox Ejecutar',
                                    controlName: controlName,
                                    processMessage: config.processMessage
                                }, false, config.Url).then(data => {

                                    if (config.FilterCallBack) {
                                        that.SetItemsComboBox(controlName, config.FilterCallBack(data.Data), that, config, resetModel);
                                        // that.itemsSelectControls['Items'+controlName] =config.FilterCallBack(data.Data);
                                    }
                                    else {
                                        that.SetItemsComboBox(controlName, data.Data, that, config, resetModel);
                                        //that.itemsSelectControls['Items'+controlName] = data.Data;
                                    }
                                    that.SetCache(notCache, config, controlName, that.context.itemsSelectControls['Items' + controlName]);
                                    if (callback)
                                        callback(that.context.itemsSelectControls['Items' + controlName]);
                                    that.SetCallBack(config, controlName, that.context.itemsSelectControls['Items' + controlName]);
                                });

                        }
                        else {
                            that.baseService.Get(obj, null,
                                {
                                    componentName: that.componentName,
                                    method: 'CargarItemsComboBox Get',
                                    controlName: controlName,
                                    processMessage: config.processMessage
                                }, controller)
                                .then(data => {
                                    //that.itemsSelectControls['Items'+controlName] = data.Data;
                                    that.SetItemsComboBox(controlName, data.Data, that, config, resetModel);

                                    that.SetCache(notCache, config, controlName, that.context.itemsSelectControls['Items' + controlName]);
                                    if (callback)
                                        callback(that.context.itemsSelectControls['Items' + controlName]);
                                    that.SetCallBack(config, controlName, that.context.itemsSelectControls['Items' + controlName]);
                                });
                        }
                    }
                }
            }
        }
        catch (error) {
            that.utility.logger.LogError(error, null, { method: 'CargarItemsComboBox', componentName: this.componentName, controlName: controlName, config: config });
        }
    }

    public SetCache(notCache: boolean, config: any, controlName: string, items: any[]) {
        if (config.keyCache && items && items.length > 0 && notCache != true)
            this.utility.SetCache(config.keyCache + controlName, items);
    }

    public SetCallBack(config: any, controlName: string, items: any[]) {

        if (config && config.Callback)
            config.Callback(items);
    }


}
