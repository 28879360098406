import { Injectable, EventEmitter } from '@angular/core';
import { NavItem } from '../components//menu-item/nav-item';

@Injectable({
  providedIn: 'root'
})
export class GeneralComunicationService {

  receivedNavItemsEvent: EventEmitter<NavItem[]> = new EventEmitter<NavItem[]>();
  receivedLoginEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedLogOutEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedLoginErrorEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedValidateSaveEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedChangeCnnEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedChangeGridViewEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedViewChangeViewCommandEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedFormEvent: EventEmitter<any> = new EventEmitter<any>();

  receivedConsoleLog: EventEmitter<any> = new EventEmitter<any>();
  receivedClearConsoleLog: EventEmitter<any> = new EventEmitter<any>();
  recivedOverlay: EventEmitter<any> = new EventEmitter<any>();
  public storage: any;
  constructor() { }


  // 2
  raiseNavItemsEvent(navItems?: NavItem[]): void {

    this.receivedNavItemsEvent.emit(navItems);
  }
  raiseChangeGridViewEvent(config: any): void {
    this.receivedChangeGridViewEvent.emit(config);
  }
  raiseViewChangeViewCommandEvent(config: any): void {
    this.receivedViewChangeViewCommandEvent.emit(config);
  }

  raiseLoginEvent(): void {
    this.receivedLoginEvent.emit();
  }
  raiseLogOutEvent(): void {
    this.receivedLogOutEvent.emit();
  }
  raiseLoginErrorEvent(x: any): void {
    this.receivedLoginErrorEvent.emit(x);
  }
  raiseValidateSaveEvent(x: any): void {
    this.receivedValidateSaveEvent.emit(x);
  }

  raiseChangeCnnEvent(x: any): void {
    this.receivedChangeCnnEvent.emit(x);
  }

  raiseFormEvent(x: any): void {
    this.receivedFormEvent.emit(x);
  }
  raiseConsoleLog(x: any): void {
    this.receivedConsoleLog.emit(x);
  }
  raiseClearConsoleLog(x: any): void {
    this.receivedClearConsoleLog.emit(x);
  }
  raiseOverlay(x: any): void {
    this.recivedOverlay.emit(x);
  }

  public ClearFormEvents(){
    this.receivedValidateSaveEvent = new EventEmitter<any>();
    this.receivedChangeCnnEvent = new EventEmitter<any>();
    this.receivedChangeGridViewEvent = new EventEmitter<any>();
    this.receivedViewChangeViewCommandEvent = new EventEmitter<any>();
    this.receivedFormEvent = new EventEmitter<any>();
  }
}
