import { AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { Guid } from 'guid-typescript';
import { StaticData } from 'src/app/helpers/static-data';
import { Utilities } from 'src/app/helpers/utilities';
import { ConfigWindow } from 'src/app/models/config-window';
import { QueryConfigIDETypes } from 'src/app/models/general.enum';
import { BaseServiceService } from 'src/app/services/base-service.service';

@Component({
  selector: 'app-document-download-settings-container',
  templateUrl: './document-download-settings-container.component.html',
  styleUrls: ['./document-download-settings-container.component.css']
})
export class DocumentDownloadSettingsContainerComponent implements OnInit, AfterViewInit {


  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() modelo: any;
  configDownloadSettings: any = {
    DownloadSettings: [], Id: null, Name: null
  };
  @Input() title: string = "";

  deletedItems: Array<any> = [];
  currentItem: any;
  url: string = 'TDocumentoExpediente';
  configButtons: any = {};
  @Input() modeloConfiguracion: any;

  DocumentDownloadSettingsTypes: Array<any> = [
    { Name: 'DocumentReplacement', Label: 'Reemplazo Documento' },
    { Name: 'TextProcessing', Label: 'Procesamiento Texto' },
    { Name: 'TemplateSettings', Label: 'Procesamiento Por Configuracion de Plantilla' },
    { Name: 'Custom', Label: 'Ensamblado o Servicio Externo' },
    { Name: 'CustomByInterface', Label: 'Ensamblado Externo, Implementa Interface' }]
  constructor(public baseService: BaseServiceService, public utility: Utilities) { }
  ngAfterViewInit(): void {


  }



  ngOnInit() {

    this.GetProject();
  }

  public ReloadGeneral() {
 
    this.GetDownloadSettings();
  }
  public GetProject() {
    
    if (StaticData.Usuario && !StaticData.Project) {



      let command = 'ConsultaConfiguracionGeneral';
      let processMessage = 'Cargando Configuracion General .....';

      this.baseService.ConsultaQueryConfigIDE(QueryConfigIDETypes.ConfiguracionGeneral, this.url, this.url, 0,
        false, command, processMessage).then(data => {

          // COLOCAR LA LECTURA DE PROYECTO
          
          StaticData.Project = JSON.parse(data.Data[0].Configuration);
          StaticData.Project.Name = data.Data[0].Name;
          StaticData.Project.IdConfiguracionGeneral = data.Data[0].Id;
          StaticData.Project.IdEmpresa = data.Data[0].IdEmpresa;



        });
    }
  }
  public GetDownloadSettings() {

this.Limpiar();
    const that = this;


    let obj = this.utility.GetApiModel('GetDownloadSettings', null)
    obj.QueryConfig = {
      Command: 'ConsultarDocumentoExpedienteConfiguracion',
      IsSp: true,
      NotSetDefault: true,
      Entity: {
        IdEmpresa: -1,
        IdDocumentoExpediente: this.modelo.modelo.IdDocumentoExpediente
      }
    };

    let info = {
      componentName: "DocumentDownloadSettingsContainerComponent", method: 'GetDownloadSettings',
      processMessage: 'Cargando Config Documento Para Descargas......'
    };

    this.baseService.Get(obj, null, info, this.url, false, false).then(data => {
      


      if (data.Data && data.Data.length > 0) {
        if (data.Data[0].Configuracion)
          this.configDownloadSettings = JSON.parse(data.Data[0].Configuracion);
        else {
          this.configDownloadSettings = {
            DownloadSettings: [], Id: null, Name: null
          };
        }

        this.configDownloadSettings.IdDocumentoExpedienteConfiguracion = data.Data[0].IdDocumentoExpedienteConfiguracion
      }
      else {
        this.configDownloadSettings = {
          DownloadSettings: [], Id: null, Name: null
        };
      }
      this.configDownloadSettings.IdDocumentoExpediente = this.modelo.modelo.IdDocumentoExpediente

    }).catch(err => { });

  }
  public GetType(_type) {
    const item = this.DocumentDownloadSettingsTypes.find(x => { return x.Name == _type });
    return (item) ? item.Label : "No encontrado";
  }

  public GetTemplateOkStatus(text) {
    const template = `<div class="ms-MessageBar ms-MessageBar--success">
    <div class="ms-MessageBar-content">
      <div class="ms-MessageBar-icon">
        <i class="ms-Icon ms-Icon--Completed"></i>
      </div>
      <div class="ms-MessageBar-text">
      #MSG#
      </div>
    </div>
  </div>`
    return template.replace('#MSG#', text);
  }
  public GetTemplateErrorStatus(text) {
    const template = `<div class="dt__group--menu">
    <div  class="ms-MessageBar ms-MessageBar--error">
    <div class="ms-MessageBar-content">
      <div class="ms-MessageBar-icon">
        <i class="ms-Icon ms-Icon--ErrorBadge"></i>
      </div>
      <div class="ms-MessageBar-text">
       #MSG#
      </div>
    </div>
  </div>`
    return template.replace('#MSG#', text);
  }
  public GetEstatus(item) {


    let msg = "";
    if (!item.Permissions || (item.Permissions &&
      item.Permissions.length == 0))
      msg = "Debe asignar Permisos";
    if (item.Type == 'DocumentReplacement') {
      if (!item.File || (item.File && !item.File.Nombre))
        return this.GetTemplateErrorStatus("No se a Cargado el archivo asociado" + ((msg) ? "," + msg : msg));
      else {
        if (msg)
          return this.GetTemplateErrorStatus(msg);
        else
          return this.GetTemplateOkStatus("OK");
      }
    }
    else if (item.Type == 'TextProcessing') {
      if (!item.TextReplacementSettings || (item.TextReplacementSettings &&
        item.TextReplacementSettings.Configs.length == 0))
        return this.GetTemplateErrorStatus("Debe configurar los textos de reemplazo" + ((msg) ? "," + msg : msg));
      else {
        if (msg)
          return this.GetTemplateErrorStatus(msg);
        else
          return this.GetTemplateOkStatus("OK");
      }
    }
    else if (item.Type == 'CustomByInterface' || item.Type == 'Custom') {


      if (!item.ExternalMethod || (item.ExternalMethod &&
        item.ExternalMethod.ExternalMethods.length == 0))
        return this.GetTemplateErrorStatus("Debe configurar el metodo externo" + ((msg) ? "," + msg : msg));
      else {
        if (msg)
          return this.GetTemplateErrorStatus(msg);
        else
          return this.GetTemplateOkStatus("OK");
      }
    }
    return "";
  }

  public GetInfo(item) {


    let msg = "";

    if (item.Type == 'DocumentReplacement') {
      if (item.File && item.File.Nombre)
        msg ="Documento Reemplazo: " + item.File.Nombre;

    }
    else if (item.Type == 'TextProcessing') {
      if (item.TextReplacementSettings &&
        item.TextReplacementSettings.Configs.length > 0)
        msg = "Textos configurados: " + item.TextReplacementSettings.Configs.length;

    }
    else if (item.Type == 'CustomByInterface' || item.Type == 'Custom') {


      if (item.ExternalMethod &&
        item.ExternalMethod.ExternalMethods.length > 0)
        msg = "Textos configurados: " + item.ExternalMethods.ExternalMethods.length;

    }
    return msg;
  }
  public Add() {


    this.configDownloadSettings.DownloadSettings.push({
      GUID: Guid.create().toString(),
      ObjectType: 'DocumentDownloadSettings',
      Type: 'DocumentReplacement',
      Active: true,
      Name: this.configDownloadSettings.DownloadSettings.length + '_Config',
      Description: null,
      Permissions: [],
      File: { Nombre: null },
      TextReplacementSettings: null,
      Template: null,
      ExternalMethod: null,
    
    });
  }
  public Delete(index) {

    
    this.deletedItems.push(this.configDownloadSettings.DownloadSettings[index]);
    this.configDownloadSettings.DownloadSettings.splice(index, 1);
  }
  public Restore(item,index) {

    
    this.deletedItems.splice(index, 1);
    this.configDownloadSettings.DownloadSettings.push(item);
  }
  GetConfigWindow() {
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Propiedades";
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';

    return config;
  }

  public Edit(item) {
    
    this.currentItem = item;
  }

  public Permissions(item) {

    let config: ConfigWindow = this.GetConfigWindow();

    config.titleWindow = "Permisos";
    let windowModel: any;

    const component: string = 'InfoPermissionComponent';
    config.width = 850;
    config.height = 700;
    windowModel = {
      project: StaticData.Project,
      modelo: item,

    };
    this.utility.VerModalComponente(component, windowModel, config).then(dataResultOnClose => {
      if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {


        
      }
      else {

      }
    });
  }
  public Limpiar() {
    this.currentItem = null;
    this.deletedItems = [];
  }

  public Guardar() {
    
    const that = this;
    let obj = this.utility.GetApiModel('DeleteDownloadSettings', null)


    obj.IdDocumentoExpediente = this.configDownloadSettings.IdDocumentoExpediente;
    obj.PkValue = this.configDownloadSettings.IdDocumentoExpedienteConfiguracion;
    obj.DeletedDownloadSettings = this.deletedItems;
    obj.DocumentoExpedienteConfiguracion = {
      PkValue: this.configDownloadSettings.IdDocumentoExpedienteConfiguracion,
      IdDocumentoExpedienteConfiguracion: this.configDownloadSettings.IdDocumentoExpedienteConfiguracion,
      IdDocumentoExpediente: this.configDownloadSettings.IdDocumentoExpediente,
    }
    obj.ConfigDocumentSettings = this.configDownloadSettings


    
    let info = {
      componentName: this.url, method: 'DeleteDownloadSettings',
      processMessage: 'Actualizando Cambios Configuracion......'
    };
    this.baseService.Ejecutar(obj, null, info, false, this.url + '/DeleteDownloadSettings', true, false).then(data => {



      this.Limpiar();


    }).catch(err => { });
  }

  OnActionEventHandler(event) {


    if ((event.Action == 'ResponseUpload' && event.Ok) ||
      event.Action == 'Save') {
      this.Limpiar();
      this.GetDownloadSettings();
    }

    else if (event.Action == 'Clear') {
      this.Limpiar();
    }
  }

}

