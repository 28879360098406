import { Component, OnInit, Input, Output, forwardRef, EventEmitter, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control'

@Component({
  selector: 'app-check-box-r',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxRComponent),
      multi: true
    }
  ],
  templateUrl: './check-box-r.component.html',
  styleUrls: ['./check-box-r.component.scss']
})
export class CheckBoxRComponent extends BaseControl implements OnInit, AfterViewInit, ControlValueAccessor {

  // set value(val) {
  //   this.modelo = val;
  //   this.onChange(val);
  //   this.onTouched();
  //   if (this.config.FabricElement) {
  //     if (val == true || val == "true")
  //       this.config.FabricElement.check();
  //     else
  //       this.config.FabricElement.unCheck();
  //   }
  //   // this.SetFoco();
  // }

}

