import { Component, OnInit, Input,Output, forwardRef ,EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR,FormControl, FormGroup } from '@angular/forms';
import {BaseControl } from '../../../models/base-control'
import {List} from '../../../../assets/linqts/compilado/index';
import { strictEqual } from 'assert';
import { BaseComboBox } from '../../../models/base-combo-box';

@Component({
  selector: 'app-multi-combo-box',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiComboBoxComponent),
      multi: true
    }
  ],
  templateUrl: './multi-combo-box.component.html',
  styleUrls: ['./multi-combo-box.component.css']
})
export class MultiComboBoxComponent extends BaseComboBox implements OnInit,ControlValueAccessor {


  get seleccionados(): any {
    if(this.modelo != null)
    {
      var listItems =new List<any>(this.items);
      var itemsModel =new List<any>(this.modelo);
      var selectItems =[];
      const that = this;
      $.each(listItems, function (key, value) {
        selectItems.push(value[that.textField]);
    });

     
       var resultado = '('+itemsModel.Count()+') '+ itemsModel.Select(o=>{ return o}).ToArray().join(',');
       return resultado;
    }
    else
    return this.config.Label +' 0 Seleccionados';
  }

  getSeleccionados(selectItems:any[]): any {
    if(this.modelo != null)
    {
      var listItems =new List<any>(selectItems);
      
       var resultado = '('+selectItems.length+') '+ listItems.Take(2).Select(o=>{ return o[this.textField]}).ToArray().join(',')+'.....';
       return resultado;
    }
    else
    return this.config.Label +' (0) Seleccionados';
  }

  public onSelectAllComboBox() {
    try{
    this.modelo = this.items.map(item => item[this.pkField]);
    this.updateChanges();
    }
    catch(error)
    {
        this.utility.logger.LogError(error,null,{omponentName:this.componentName,method:'onSelectAllComboBox', controlName:this.controlName});
    }
  }
 
  public onClearAllComboBox() {
      try{
      this.modelo=[];
      this.updateChanges();
    }
    catch(error)
    {
        this.utility.logger.LogError(error,null,{componentName:this.componentName,method:'onClearAllComboBox', controlName:this.controlName});
    }
  }
}


