import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from '../../../models/base-control';
import { Utilities } from '../../../helpers/utilities'

@Component({
  selector: 'app-text-box-r',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextBoxRComponent),
      multi: true
    }
  ],
  templateUrl: './text-box-r.component.html',
  styleUrls: ['./text-box-r.component.scss']
})
export class TextBoxRComponent extends BaseControl implements OnInit, ControlValueAccessor {
  showPass(show, id) {
    const tipo = document.getElementById(id);
    if(show == true){
      tipo.setAttribute('type', 'text');
    }else {
      tipo.setAttribute('type', 'password');
    }
  }

  public onKeydown(event){

    return event.keyCode !== 69;

   }
}
