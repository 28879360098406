import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges, forwardRef, Inject } from "@angular/core";
// import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../helpers/utilities";
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { StaticData } from 'src/app/helpers/static-data';


const maxFileSizeMB = 10;
const bufferChunkSize = maxFileSizeMB * (1024 * 1024);
const batchSize = 30;
const numberFailedChunkRetries = 10;

interface FileObject {
  file: any,
  status: 'UPLOADED' | 'FAILED' | 'PENDING',
  filePartName?: string
}
@Component({
  selector: 'app-file-uploader-chunk',
  templateUrl: './file-uploader-chunk.component.html',
  styleUrls: ['./file-uploader-chunk.component.css']
})
export class FileUploaderChunkComponent implements OnInit {
  progress: number;
  progressVisual: boolean = false;
  public processDocumentChunk: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient, public utility: Utilities) { }

  ngOnInit() {
  }

  // uploadFileChunk(Chunk, FileName, progressUpload: number) {

  //   const formData = new FormData();
  //   formData.append('file', Chunk, FileName);

  //   this.http
  //     .post(StaticData.UrlServer + '/api/fileuploadlarge/UploadChunk/', formData, {
  //       reportProgress: true,
  //       observe: "events"
  //     })
  //     .pipe(
  //       map((event: any) => {          
  //         if (event.type == HttpEventType.UploadProgress) {
  //           if (this.progress <= progressUpload) {
  //             this.progress = progressUpload;
  //           }
  //           if (progressUpload === 100) {
  //             //this.progressVisual = false;
  //             console.log("Julian Higuera M");
  //           }
  //         } else if (event.type == HttpEventType.Response) {

  //         }
  //       }),
  //       catchError((err: any) => {
  //         this.progress = null;
  //         console.log(err.message);
  //         return throwError(err.message);
  //       })
  //     )
  //     .toPromise();

  // }


  uploadFileChunk(fileObject: FileObject, position?: any, partTotal?: any, lastPart? : boolean, target?: any) {

    const formData = new FormData();
    const _lastPart = lastPart? lastPart : false
    formData.append('file', fileObject.file, fileObject.filePartName);

    return this.http
      .post(StaticData.UrlServer + '/api/fileuploadlarge/UploadChunk/', formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map(_ => {          
          if(_.type == HttpEventType.UploadProgress && !_lastPart)
          {
            if (position && partTotal) {
              let progressUpload = Number(((position / partTotal) * 100).toFixed(2))
              let positionlast = partTotal - position;
              if (positionlast <= 0)
                progressUpload = 99.9            
              this.processDocumentChunk.emit({ progressUpload: progressUpload , lastPart : _lastPart, target, finaly : false});            
            }            
          }
          else if (_.type == HttpEventType.Response && lastPart && _.body ==  "finally")
          {            
            let progressUpload = 100            
            this.processDocumentChunk.emit({ progressUpload: progressUpload , lastPart : _lastPart, target, finally: true});               
          }
          else if(_.type == HttpEventType.Response && lastPart && _.body ==  "true")
          {
            debugger;
            fileObject.status = "FAILED";
            let progressUpload = 99.99
            this.processDocumentChunk.emit({ progressUpload: progressUpload , lastPart : _lastPart, target, finally: true});               
            //return of();
            this.utility.VerModalError("El archivo " + target.name+ " presenta error al cargar sus partes, por favor intente nuevamente");
            
          }
          fileObject.status = "UPLOADED";
        }),
        catchError(_ => {          
          fileObject.status = "FAILED";
          return of();
        })
      )
      .toPromise();

  }
  // uploadFile(TargetFile) {

  //   // create array to store the buffer chunks
  //   const fileChunk = [];
  //   // the file object itself that we will work with
  //   // set up other initial vars
  //   const maxFileSizeMB = 3;
  //   const bufferChunkSize = maxFileSizeMB * (1024 * 1024);
  //   // let ReadBuffer_Size = 1024;
  //   let fileStreamPos = 0;
  //   // set the initial chunk length
  //   let endPos = bufferChunkSize;
  //   const size = TargetFile.size;

  //   // add to the FileChunk array until we get to the end of the file
  //   while (fileStreamPos < size) {
  //     // "slice" the file from the starting position/offset, to  the required length
  //     fileChunk.push(TargetFile.slice(fileStreamPos, endPos));
  //     fileStreamPos = endPos; // jump by the amount read
  //     endPos = fileStreamPos + bufferChunkSize; // set next chunk length
  //   }
  //   // get total number of "files" we will be sending
  //   const totalParts = fileChunk.length;
  //   let partCount = 0;
  //   this.progress = 1;
  //   this.progressVisual = true;

  //   for (let valueChunk of fileChunk) {
  //     partCount++;
  //     const filePartName = TargetFile.name + '.part_' + partCount + '.' + totalParts;
  //     // send the file
  //     this.uploadFileChunk(valueChunk, filePartName, Number(((partCount / totalParts) * 100).toFixed(2)));

  //     if (partCount === totalParts) {
  //       this.progress = null;
  //     }
  //   }
  // }
  async uploadFile(target: any) {
    //let targetFile = target.files[0];
    const fileChunk = this.splitFile(target)
    const lastPart = fileChunk.pop();
    this.progress = 1;
    this.progressVisual = true;

    await this.uploadinBatch(fileChunk, target);

    const filesFailed = fileChunk.filter(file => file.status === "FAILED")

    if (filesFailed.length > 0)
      await this.proccessFailedChunk(filesFailed, numberFailedChunkRetries)

    if (lastPart) {      
      await this.uploadFileChunk(lastPart, null, null, true, target)
    }


    console.log("valueChunk", filesFailed, fileChunk, lastPart, fileChunk.filter(file => file.status === "FAILED"))
  }
  splitFile(targetFile: any): FileObject[] {
    let fileChunk: FileObject[] = [];
    let fileStreamPos = 0;
    let endPos = bufferChunkSize;
    let partCount = 0;
    const size = targetFile.size;
    while (fileStreamPos < size) {
      fileChunk = [...fileChunk, {
        file: targetFile.slice(fileStreamPos, endPos),
        status: "PENDING"
      }]
      fileStreamPos = endPos;
      endPos = fileStreamPos + bufferChunkSize;
    }

    for (let valueChunk of fileChunk) {
      partCount++;
      valueChunk.filePartName = StaticData.Usuario.IdUsuario + targetFile.name + '.part_' + partCount + '.' + fileChunk.length;
    }
    return fileChunk;
  }

  async uploadinBatch(fileChunk: FileObject[], target: any) {
    let position = 0;
    let results: any[] = [];
    while (position < fileChunk.length) {      
      const itemsForBatch = fileChunk.slice(position, position + batchSize);
      results = [...results,
      ...await Promise.all(
        itemsForBatch.map(valueChunk =>
          this.uploadFileChunk(valueChunk, position + batchSize, fileChunk.length,false, target)
        )
      )
      ];
      position += batchSize;
    }
  }

  async proccessFailedChunk(fileChunkFailed: FileObject[], retryNumber: number) {
    console.log("TRIED", retryNumber, fileChunkFailed)
    if (retryNumber > 0) {
      await this.uploadinBatch(fileChunkFailed, null);

      const filesFailed = fileChunkFailed.filter(file => file.status === "FAILED")

      console.log("FILES FAILED AFTER FIRST TRIED", filesFailed)
      if (filesFailed.length > 0) {
        await this.proccessFailedChunk(filesFailed, numberFailedChunkRetries - 1)
      }

    }

  }


}
