//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControllerMethods, Paginas } from '../../../models/general.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';

import { AdminPermisosComponent } from '../../admin-permisos/admin-permisos.component';
import { BaseCrud } from '../../../bussines/base-crud';
import { BaseServiceService } from '../../../services/base-service.service'
import { ContextMenuComponent } from 'ngx-contextmenu';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { StaticData } from 'src/app/helpers/static-data';
import { Utilities } from '../../../helpers/utilities';

@Component({
  selector: "app-rpagina",
  templateUrl: "./rpagina.component.html",
  styleUrls: ["./rpagina.component.scss"],
})
export class RPaginaComponent extends GeneralFormComponent {
  

	public OnInitEnd() { 
		this.postSaveEmitter.subscribe(suscription => { 
			this.LoadCache();
		})
		super.OnInitEnd();
	}


	public LoadCache() {
    let obj: any = null;
    let componentName = "RPagina";
    try {
      const that = this;
      let processMessage = "Actualizando Cache ";

      let _modelo: any = {
        IdEmpresa: -1,

        Activo: true,
      };
      obj = this.utility.GetApiModel(
        "LoadCache",
        componentName,
        null,
        null,
        _modelo
      );
      this.baseService
        .LoadCache(
          obj,
          {
            componentName: componentName + "Controller",
            method: "LoadCache",
            processMessage: processMessage,
          },
          false,
          false,
          true
        )
        .then((data) => {
        })
        .catch((error) => {
          this.utility.logger.LogError(error, obj, {
            componentName: this.componentName,
            method: "LoadCache",
          });
        });
    } catch (error) {
      this.utility.logger.LogError(error, obj, {
        componentName: this.componentName,
        method: "LoadCache",
      });
      this.utility.VerModalDanger().then((data) => {});
    }
  }
}
