import { FormGroup, FormControl, NgForm, Validators, DefaultValueAccessor, AbstractControl } from '@angular/forms';
import {
    ComparisonOperators, LogicalOperators, DataTypes, CrudActions,
    ReturnQueryTypes, MailSendingForms
} from '../models/general.enum';
import { Guid } from 'guid-typescript';
import { List, Enumerable } from '../../assets/linqts/compilado/index';
import { StaticData } from '../helpers/static-data';
import { Utilities } from '../helpers/utilities';
import { BaseServiceService } from '../services/base-service.service';
import { UtilityRuleEngine } from '../helpers/utility-rule-engine';
import { forwardRef, Inject, EventEmitter } from '@angular/core';
import { ModalNotifyTypes } from '../components/modal-notify/modal-notify.component';
import { Subject } from 'rxjs';
import { ComponentsContainer } from '../models/components-container';

export class HelperRules {

    constructor(@Inject(forwardRef(() => BaseServiceService)) public baseService: BaseServiceService,
        @Inject(forwardRef(() => Utilities)) public utility: Utilities,
        @Inject(forwardRef(() => UtilityRuleEngine)) public utilityRuleEngine: UtilityRuleEngine) {

    }

    public GetRulesPages(rules: List<any>, event: string): List<any> {

        return rules.Where(x => {
            return (new List<any>(x.FormEvents).Where(_event => { return _event.Name == event }).Count() > 0)
        });
    }
    public SetRuleEngine(scope: any,
        pagina: any,
        controllerName: string,
        form?: FormGroup,
        isModelContainer?: boolean,
        formEvent?: Subject<any>,
        listLog?: Array<any>,
        onSelectedComboBoxForRules?: EventEmitter<any>,
        externalRules?: List<any>,
        notExecute?: boolean): any {

        const that = this;


        that.utility.logger.LogInfoText(`_SetRuleEngine para la Pagina '${pagina.IdPagina}'`);
        if (!StaticData.Usuario) {
            that.utility.logger.LogInfoText(`Usuario No Autenticado, Para Ejecutar Reglas de  '${pagina.IdPagina}','${pagina.Pagina}'`);
            return;
        }
        if (!StaticData.Usuario.Rules) {
            that.utility.logger.LogInfoText(`El objeto Usuario.Rules es nulo para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
            return;
        }
        let rulesList: List<any> = new List<any>(StaticData.Usuario.Rules);
        let rules = (externalRules) ? externalRules :
            rulesList.Where(x => { return x.IdPage == pagina.IdPagina && x.RuleType == 'UI' && x.IsExternal !== true });

        let formEventRules: any = {};
        if (!notExecute)
            for (let i = 0; i < this.utilityRuleEngine.FormEvents.length; i++) {

                const eventFormName = this.utilityRuleEngine.FormEvents[i].Name;
                formEventRules['rules' + eventFormName] = this.GetRulesPages(rules, eventFormName);

            }
        /*  let rulesOnInit = this.GetRulesPages(rules, 'OnInit');
         let rulesAfterViewInit = this.GetRulesPages(rules, 'AfterViewInit');
         let rulesAfterViewChecked = this.GetRulesPages(rules, 'AfterViewChecked');
         let rulesOnDestroy = this.GetRulesPages(rules, 'OnDestroy');

         let rulesOnPreLoadGrid = this.GetRulesPages(rules, 'OnPreLoadGrid');
         let rulesOnPostLoadGrid = this.GetRulesPages(rules, 'OnPostLoadGrid');

         let rulesOnPreUpdate = this.GetRulesPages(rules, 'OnPreUpdate');
         let rulesOnPostUpdate = this.GetRulesPages(rules, 'OnPostUpdate');

         let rulesOnPreInsert = this.GetRulesPages(rules, 'OnPreInsert');
         let rulesOnPostInsert = this.GetRulesPages(rules, 'OnPostInsert');

         let rulesOnPreDelete = this.GetRulesPages(rules, 'OnPreDelete');
         let rulesOnPostDelete = this.GetRulesPages(rules, 'OnPostDelete');

         let rulesOnPreEdit = this.GetRulesPages(rules, 'OnPreEdit');
         let rulesOnPostEdit = this.GetRulesPages(rules, 'OnPostEdit'); */


        let notificationsRules = rulesList.Where(x => {
            return x.RuleType == 'GeneralNotify' &&
                (new List<any>(x.Pages).Where(page => { return page.id == pagina.IdPagina || page.id == -1 }).FirstOrDefault())
        }).ToArray();
        let generalRules = rulesList.Where(x => {
            return x.Pages.length == 0 && x.RuleType == 'GeneralNotify';
        }).ToArray();

        that.utility.logger.LogInfoText(`Reglas encontradas '${rules.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        if (!notExecute)
            for (let i = 0; i < this.utilityRuleEngine.FormEvents.length; i++) {

                const eventFormName = this.utilityRuleEngine.FormEvents[i].Name;

                that.utility.logger.LogInfoText(`Reglas evento '${eventFormName}' '${formEventRules['rules' + eventFormName].Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);

            }
        // // { Name: 'OnInit', Label: 'Inicio' },
        // that.utility.logger.LogInfoText (`Reglas evento OnInit '${rulesOnInit.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'AfterViewInit', Label: 'Inicio de Vista' },
        // that.utility.logger.LogInfoText (`Reglas evento AfterViewInit '${rulesAfterViewInit.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'AfterViewChecked', Label: 'Inicio Vista Validada' },
        // that.utility.logger.LogInfoText (`Reglas evento AfterViewChecked '${rulesAfterViewChecked.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnDestroy', Label: 'Fin' },
        // that.utility.logger.LogInfoText (`Reglas evento OnDestroy '${rulesOnDestroy.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPreLoadGrid', Label: 'Antes Cargar Grilla' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPreLoadGrid '${rulesOnPreLoadGrid.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPostLoadGrid', Label: 'Despues Cargar Grilla' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPostLoadGrid '${rulesOnPostLoadGrid.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPreUpdate', Label: 'Antes Actualizar' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPreUpdate '${rulesOnPreUpdate.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPostUpdate', Label: 'Despues Actualizar' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPostUpdate '${rulesOnPostUpdate.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPreInsert', Label: 'Antes Crear' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPreInsert '${rulesOnPreInsert.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPostInsert', Label: 'Despues Crear' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPostInsert '${rulesOnPostInsert.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPreDelete', Label: 'Antes Eliminar' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPreDelete '${rulesOnPreDelete.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPostDelete', Label: 'Despues Eliminar' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPostDelete '${rulesOnPostDelete.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPreEdit', Label: 'Antes Editar' },
        // that.utility.logger.LogInfoText (`Reglas evento OnPreEdit '${rulesOnPreEdit.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        // // { Name: 'OnPostEdit', Label: 'Despues Editar' }
        // that.utility.logger.LogInfoText (`Reglas evento OnPostEdit '${rulesOnPostEdit.Count()}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);



        that.utility.logger.LogInfoText(`Notificaciones encontradas '${notificationsRules.length}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
        that.utility.logger.LogInfoText(`Reglas ejecucuion general encontradas '${generalRules.length}' para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);

        //let configPaginaTemp = Enumerable.From(General.ConfigPaginas).Where(x=>{ return x.IdPagina == pagina.IdPagina }).FirstOrDefault();
        let configPagina = pagina;//Utilidades.Clone(configPaginaTemp);
        let controlsList = new List<any>(this.utility.GetAllFormControls(configPagina.ConfigPage.Controls));

        // Adicion de Botones del Command Bar para reglas
        if (pagina.ConfigPage.CommandBar && pagina.ConfigPage.CommandBar.Buttons && pagina.ConfigPage.CommandBar.Buttons.length > 0) {
            pagina.ConfigPage.CommandBar.Buttons.forEach(element => {
                if (element.Name) {
                    element.ControlType = 'CommandButton';
                    element.Id = element.Name;

                    controlsList.Add(element);
                }
            });

        }

        let metadataControlsList = new List<any>(this.utility.GetFormControls(StaticData.ToolBox.UserControls, false, true));
        scope.ContainerData = {};
        if (!notExecute) {
            if (configPagina && configPagina.ConfigPage) {
                if (rules.Count() > 0)
                    that.utility.logger.LogInfoText(`Asignando Reglas para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`); {
                    __SetRuleEngine(configPagina);
                }
                if (notificationsRules.length > 0) {
                    that.utility.logger.LogInfoText(` Ejecutando Notificaciones  para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                    ExecuteRules(notificationsRules, null, null, { Name: 'notifications' }, { Name: 'notifications' });
                }
            }
            else if (notificationsRules && notificationsRules.length > 0) {
                that.utility.logger.LogInfoText(` Ejecutando Notificaciones  para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                ExecuteRules(notificationsRules, null, null, { Name: 'notifications' }, { Name: 'notifications' });
            }
            else {

                that.utility.logger.LogInfoText(`No existe Configuracion Para La Pagina '${pagina.IdPagina}'`);
            }
        }
        function SetFunctionContext() {
            scope.helperRulesContext = this;

        };
        function __SetRuleEngine(configPagina) {
            // 

            __SetRuleEngineControls(configPagina, configPagina.ConfigPage.Controls, controlsList, "Controls", false);
            __SetRuleEngineControls(configPagina, configPagina.ConfigPage.Metadata, metadataControlsList, "MetadataControls", true);
            //__SetRuleEngineMetadataControls(configPagina);

            if (generalRules.length > 0) {

                that.utility.logger.LogInfoText(`Ejecutando reglas generales  para la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                ExecuteRules(generalRules, null, null, { Name: 'GeneralesPagina', Pagina: pagina.Pagina }, { Name: 'GeneralesPagina' });
            }

            if (formEvent && !formEvent.closed)
                formEvent.subscribe(event => {
                    setTimeout(() => {

                        that.utility.logger.LogInfoText(`Ejecutando Evento: '${event.Name}', de la pagina'${pagina.IdPagina}','${pagina.Pagina}'`);

                        ExecuteRules(formEventRules['rules' + event.Name].ToArray(), null, null, { Name: pagina.Pagina, Pagina: pagina.Pagina }, event);

                    }, 300);


                });

        }
        function __SetRuleEngineControls(configPagina, controlsArr, controlsListArr: List<any>,
            propControls: string, isMetadata: Boolean) {

            //GetAllFormControls
            let controls: any = controlsListArr.ToArray();//  that.utility.GetFormControls(controlsArr, false, true);
            // let temComtrols = rules.Where(x => {
            //     return (x[propControls] && new List<any>(x[propControls]).Where(c => {
            //         return controlsListArr.Any(f => { return f.Name == c.Name })
            //     }).Count() > 0)
            // });
            let comboBoxEvents = [];
            $.each(controls, (index: string, controlConfig) => {



                if (controlConfig.Activo) {
                    let $control = null;
                    let $label = null;

                    let controlRules = rules.Where(x => {
                        return (x[propControls] && new List<any>(x[propControls]).Where(c => {
                            return c.Name == controlConfig.Name
                        }).Count() > 0)
                    });
                    that.utility.logger.LogInfoText(`Reglas encontradas '${controlRules.Count()}' para el control '${controlConfig.Name}' en la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);

                    if (controlRules.Count() > 0) {
                        //General.Events
                        //General.Events
                        $control = $('#' + controlConfig.Name);
                        $label = $("label[for='" + controlConfig.Name + "']");
                        $.each(that.utilityRuleEngine.Events, (keyEvent, event) => {

                            let controlRulesByEvent = controlRules.Where(xx => {
                                return (new List<any>(xx.Events).Where(_event => { return _event.Name == event.Name }).Count() > 0)
                            }).ToArray();
                            that.utility.logger.LogInfoText(`Reglas encontradas '${controlRulesByEvent.length}' para el control '${controlConfig.Name}' y el evento '${event.Name}' en la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                            if (controlRulesByEvent.length > 0) {
                                SetEventHandler($control, $label, controlConfig, event, controlRulesByEvent, comboBoxEvents);
                            }


                        });
                    }

                }
            });





        }
        function GetControlsRules(controlConfig): Array<any> {
            let controlRules = rules.Where(x => {
                return (new List<any>(x.Controls).Where(c => {
                    return c.Name == controlConfig.Name
                }).Count() > 0)
            }).ToArray();
            return controlRules;
        }
        function __SetRuleEngineMetadataControls(configPagina) {



            let temComtrols = rules.Where(x => {
                return (new List<any>(x.Controls).Where(c => {
                    return controlsList.Any(f => { return f.Name == c.Name })
                }).Count() > 0)
            });
            $.each(configPagina.ConfigPage.Controls, (index: string, controlConfig) => {


                let $control = null;
                let $label = null;

                let controlRules = rules.Where(x => {
                    return (new List<any>(x.Controls).Where(c => {
                        return c.Name == controlConfig.Name
                    }).Count() > 0)
                });
                that.utility.logger.LogInfoText(`Reglas encontradas '${controlRules.Count()}' para el control '${controlConfig.Name}' en la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);

                if (controlRules.Count() > 0) {
                    //General.Events
                    //General.Events
                    $control = $('#' + controlConfig.Name);
                    $label = $("label[for='" + controlConfig.Name + "']");
                    $.each(that.utilityRuleEngine.Events, (keyEvent, event) => {

                        let controlRulesByEvent = controlRules.Where(xx => {
                            return (new List<any>(xx.Events).Where(_event => { return _event.Name == event.Name }).Count() > 0)
                        }).ToArray();
                        that.utility.logger.LogInfoText(`Reglas encontradas '${controlRulesByEvent.length}' para el control '${controlConfig.Name}' y el evento '${event.Name}' en la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                        if (controlRulesByEvent.length > 0) {
                            SetEventHandler($control, $label, controlConfig, event, controlRulesByEvent, []);
                        }


                    });
                }
            });


        }
        function SetEventHandler($control, $label, controlConfig, event, rules, comboBoxEvents: Array<any>) {
            that.utility.logger.LogInfoText(`Asignado Evento '${event.Name}' para el control '${controlConfig.Name}'  de la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
            
            if (controlConfig.ControlType == "ComboBox" || controlConfig.ControlType == "MultiComboBox") {

                onSelectedComboBoxForRules.subscribe(data => {

                    if (data.controlName == controlConfig.Name) {
                        // 
                        controlConfig.ExtraData = data;
                        that.utility.logger.LogInfoText(`Ejecutando Evento '${event.Name}' para el control '${controlConfig.Name}'  de la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                        ExecuteRules(rules, $control, $label, controlConfig, event)
                    }
                })
                /*  // 
                  comboBoxEvents.push(controlConfig);
                  if (onSelectedComboBoxForRules.observers.length == 0) {
                      onSelectedComboBoxForRules.subscribe(data => {
                         // 
                          let _controlConfig = comboBoxEvents.find(x => x.Name == data.controlName);
                          if (_controlConfig) {
                              _controlConfig.ExtraData = data;
                              that.utility.logger.LogInfoText(`Ejecutando Evento '${event.Name}' para el control '${_controlConfig.Name}'  de la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                              ExecuteRules(rules, $control, $label, _controlConfig, event)
                          }
                      })
                  } */
            }
            else {
                if ($control.hasHandlers && !$control.hasHandlers(event.Name)) {
                    //$control.on(event.Name, function (ev) {

                    //    ExecuteRules(rules, controlConfig, event)
                    //});

                    $control.on(event.Name, (ev) => {                        
                        that.utility.logger.LogInfoText(`Ejecutando Evento '${event.Name}' para el control '${controlConfig.Name}'  de la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`);
                        ExecuteRules(rules, $control, $label, controlConfig, event)
                    });
                }
                else {
                    that.utility.logger.LogInfoText(`El Evento '${event.Name}' para el control '${controlConfig.Name}'  de la Pagina '${pagina.IdPagina}','${pagina.Pagina}' ya esta asignado`);
                }
            }
        }


        function GetControlConfig(name) {
            let control = controlsList.Where(x => { return x.Name == name }).FirstOrDefault();
            return control;
        }

        function GetValueControl(controlConfig) {
            let value;
            if (form) {

                const ctr = form.controls[controlConfig.Name];
                if (ctr) {
                    value = ctr.value;
                }
            }
            else {
                value = (isModelContainer) ? scope.modelo[controlConfig.ModelContainer][controlConfig.Name] :
                    scope[controlConfig.ModelContainer][controlConfig.Name];
            }
            return value;
        }



        function ExtractValueFromVar(objectValue: any, value: any): any {

            let resultValue: any = value;
            if (value && value.Data) {


                switch (value.DataType) {
                    case "QueryResult":
                        resultValue = (objectValue.Property) ? value.Data[0][objectValue.Property] : value.Data[0][objectValue.Object];
                        break;
                    case "Json":
                        let arr: Array<any> = [];
                        resultValue = JSON.parse(value.Data);
                        if (objectValue.Property) {

                            arr = objectValue.Property.split('=').join(',').split(':').join(',').split(',').join(',').split(';');
                            arr.forEach(property => {

                                resultValue = resultValue[property];
                            });
                        }
                        break;
                    case "Object":
                        resultValue = (objectValue.Property) ? value.Data[objectValue.Property] : value.Data;
                        break;
                }
            }
            return resultValue;
        }
        function InternalCurrentDate(separator: any = "-", format?: string) {

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            let fecha = null;
            if (format) {
                switch (format.toLowerCase()) {
                    case 'ddmmyyyy':
                        fecha = dd + separator + mm + separator + yyyy;
                        break;
                    case 'mmddyyyy':
                        fecha = mm + separator + dd + separator + yyyy;
                        break;
                    case 'yyyymmdd':
                        fecha = yyyy + separator + mm + separator + dd;
                        break;
                    case 'yyyyddmm':
                        fecha = yyyy + separator + dd + separator + mm;
                        break;
                }
            }
            else
                fecha = dd + separator + mm + separator + yyyy;//+ ' ' + today.getHours() + today.getMinutes() + today.getSeconds();
            return fecha;
        }
        function GetCurrentDate(separator: any = "-", format?: string) {
            let fecha = null;
            if (format) {
                switch (format.toLowerCase()) {
                    case 'dd/mm/yy':
                    case 'dd/mm/yyyy':
                        separator = '/';
                        format = 'ddmmyyyy';
                        break;
                    case 'mm/dd/yy':
                    case 'mm/dd/yyyy':
                        separator = '/';
                        format = 'mmddyyyy';
                        break;
                    case 'yy/mm/dd':
                    case 'yyyy/mm/dd':
                        separator = '/';
                        format = 'yyyymmdd';
                        break;
                    case 'yy/dd/mm':
                    case 'yyyy/dd/mm':
                        separator = '/';
                        format = 'yyyyddmm';
                        break;
                    case 'dd-mm-yy':
                    case 'dd-mm-yyyy':
                        separator = '-';
                        format = 'ddmmyyyy';
                        break;
                    case 'mm-dd-yy':
                    case 'mm-dd-yyyy':
                        separator = '-';
                        format = 'mmddyyyy';
                        break;
                    case 'yy-mm-dd':
                    case 'yyyy-mm-dd':
                        separator = '-';
                        format = 'yyyymmdd';
                        break;
                    case 'yy-dd-mm':
                    case 'yyyy-dd-mm':
                        separator = '-';
                        format = 'yyyyddmm';
                        break;
                    case 'dd mm yy':
                    case 'dd mm yyyy':
                        separator = ' ';
                        format = 'ddmmyyyy';
                        break;
                    case 'mm dd yy':
                    case 'mm dd yyyy':
                        separator = ' ';
                        format = 'mmddyyyy';
                        break;
                    case 'yy mm dd':
                    case 'yyyy mm dd':
                        separator = ' ';
                        format = 'yyyymmdd';
                        break;
                    case 'yy dd mm':
                    case 'yyyy dd mm':
                        separator = ' ';
                        format = 'yyyyddmm';
                        break;

                }
            }

            fecha = InternalCurrentDate(separator, format);//+ ' ' + today.getHours() + today.getMinutes() + today.getSeconds();
            return fecha;
        }
        function toCamelCase(str) {
            return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function (match) {
                return match.charAt(match.length - 1).toUpperCase();
            });
        }
        function toPascalCase(string) {
            return `${string}`
                .replace(new RegExp(/[-_]+/, 'g'), ' ')
                .replace(new RegExp(/[^\w\s]/, 'g'), '')
                .replace(
                    new RegExp(/\s+(.)(\w+)/, 'g'),
                    ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
                )
                .replace(new RegExp(/\s/, 'g'), '')
                .replace(new RegExp(/\w/), s => s.toUpperCase());
        }

        function toUpperCamelCase(str: string) {

            // let word = str.substring(1).toLowerCase();
            // let first = str.substring(0, 1).toUpperCase();
            // word = first + toCamelCase(word);
            return that.utility.ToUpperCamelCase(str);
        }
        function FormatoTexto(value: string, format: string) {
            switch (format) {
                case 'Normal':

                    break;
                case 'Mayusculas':
                    value = value.toUpperCase();
                    break;
                case 'Minusculas':
                    value = value.toLowerCase();
                    break;
                case 'PascalCase':
                    value = toPascalCase(value);
                    break;
                case 'UpperCamelCase':
                    value = toUpperCamelCase(value);
                    break;
                case 'CamelCase':
                    value = toCamelCase(value);
                    break;
            }

            return format;
        }
        function Reemplazo(tempValor: string, valoresReemplazo: any) {
            if (valoresReemplazo) {


                for (var [key, value] of Object.entries(valoresReemplazo)) {
                    if (key == "REGEX") {
                        //  tempValor = Regex.Replace(tempValor.toString(), value, "", RegexOptions.None);
                        var reg = new RegExp(value.toString());

                        tempValor = tempValor.toString().replace(reg, "");
                    }
                    else if (key == "REMOVER_VACIOS" || key == "VACIOS" || key == "REMOVER VACIOS") {
                        // tempValor = Regex.Replace(tempValor.ToString(), @"\s", "", RegexOptions.None);
                        var reg = new RegExp("[ ]+", "g");

                        tempValor = tempValor.toString().replace(reg, "");
                    }
                    else
                        tempValor = tempValor.toString().replace(key, value.toString());
                }


            }
            return tempValor;
        }
        function SplitMultiple(str, ...tokens: string[]) {
            var tempChar = tokens[0]; // We can use the first token as a temporary join character
            for (var i = 1; i < tokens.length; i++) {
                str = str.split(tokens[i]).join(tempChar);
            }
            str = str.split(tempChar);
            return str;
        }
        function splitMulti(str, tokens: Array<string> = ['=', ',', ':', '|', ';']) {
            var tempChar = tokens[0]; // We can use the first token as a temporary join character
            for (var i = 1; i < tokens.length; i++) {
                str = str.split(tokens[i]).join(tempChar);
            }
            str = str.split(tempChar);
            return str;
        }
        function CamposToString(campos: Array<any>, carcaterSeparacion: string, valoresReemplazo: any,
            formatoTexto: string, maxLength: number, dtt: any, controlConfig: any, nombre?: string): string {

            if (!carcaterSeparacion)
                carcaterSeparacion = "";
            let today = new Date();
            let resultado: string = "";
            //  const carcaterSeparacion: string = (objectValue.AutoGenerateField.CharacterSeparation) ? objectValue.AutoGenerateField.CharacterSeparation : "";
            //let valoresReemplazo: any = objectValue.AutoGenerateField.ReplacementValues;
            campos.forEach(campo => {

                let valor: string = campo.Valor;
                let formato: string = campo.Formato;
                let tempValor: any = null;
                switch (campo.TipoCampo) {
                    case 'NombreObjeto':

                        if (!nombre)
                            throw `El valor nombre es nulo para el campo:'${campo.Nombre}'`;
                        tempValor = "NOMBRE_NULO";

                        break;
                    case 'Valor':
                        tempValor = valor;
                        break;
                    case 'AnoActual':
                        tempValor = today.getFullYear();
                        break;
                    case 'AreaEmpresaUsuarioActual':
                        tempValor = StaticData.Usuario.AreaEmpresa;
                        break;
                    case 'Guid':
                        tempValor = Guid.create().toString();
                        break;
                    case 'Autonumerico':
                        if (!nombre)
                            throw `Autonumerico no implementado para el campo:'${campo.Nombre}'`;
                        tempValor = "#AUTONUMERICO#";
                        break;
                    case 'DiaActual':
                        tempValor = today.getDay();
                        break;
                    case 'FechaActual':
                        tempValor = GetCurrentDate("-", formato);
                        break;
                    case 'GrupoUsuarioActual':
                        tempValor = GetPrimerNombreGrupo();
                        break;
                    case 'Empresa':
                        tempValor = StaticData.Usuario.Empresa;
                        break;
                    case 'IdEmpresa':
                        tempValor = StaticData.Usuario.IdEmpresa;
                        break;
                    case 'IdAreaEmpresaUsuarioActual':
                        tempValor = StaticData.Usuario.IdAreaEmpresa;
                        break;
                    case 'IdGrupoUsuarioActual':
                        tempValor = GetPrimerIdGrupo();
                        break;
                    case 'IdRoleUsuarioActual':
                        tempValor = StaticData.Usuario.IdRole;
                        break;
                    case 'IdUsuarioActual':
                        tempValor = (StaticData.Usuario.UserId) ? StaticData.Usuario.UserId : StaticData.Usuario.IdUsuario;
                        break;
                    case 'MesActual':
                        tempValor = today.getMonth();
                        break;
                    case 'RoleUsuarioActual':
                        tempValor = StaticData.Usuario.Role;
                        break;
                    case 'UsuarioActual':
                        tempValor = (StaticData.Usuario.User) ? StaticData.Usuario.User : StaticData.Usuario.Usuario;
                        break;
                    case 'NombreUsuarioActual':
                        tempValor = StaticData.Usuario.Nombre;
                        break;
                    case 'Control':
                        // 
                        let control = pagina.ConfigPage.Controls.find(ctr => ctr.Name == campo.Nombre);
                        tempValor = GetValueControl(control);
                        if ((control.ControlType == "ComboBox" || control.ControlType == "MultiComboBox") && campo.Valor) {
                            tempValor = "";
                            let currentItem: any = {};
                            if (controlConfig && controlConfig.ExtraData) {
                                currentItem = controlConfig.ExtraData.item;
                            }
                            else {
                                if (scope.configs['Config' + campo.Nombre])
                                    currentItem = scope.configs['Config' + campo.Nombre].Context.FindItem();
                            }
                            if (currentItem) {
                                if (campo.Fields.length > 0) {

                                    campo.Fields.forEach(field => {

                                        if (field.TipoCampo == "Valor") {
                                            field.Valor = currentItem[field.Valor];
                                            if (!field.Valor)
                                                field.Valor = field.Comando;
                                        }
                                    });
                                    tempValor = CamposToString(that.utility.Clone(campo.Fields), campo.CharacterSeparation,
                                        campo.ReplacementValues, campo.TextFormat, campo.MaxLength, dtt, controlConfig);
                                }
                                else {
                                    let props: Array<string> = splitMulti(campo.Valor);
                                    props.forEach(field => {

                                        tempValor += currentItem[field];
                                    });
                                }
                            }
                            else {
                                tempValor = campo.Comando;
                            }


                        }

                        break;
                }
                if (campo.TipoCampo != 'Control' && campo.Fields.length > 0) {
                    tempValor += CamposToString(that.utility.Clone(campo.Fields),
                        campo.CharacterSeparation, campo.ReplacementValues, campo.TextFormat, campo.MaxLength, dtt, controlConfig);
                }
                if (!tempValor)
                    tempValor = valor;
                if (!tempValor)
                    tempValor = "";
                if (campo.FormatoTexto != 'Normal') {
                    tempValor = FormatoTexto(tempValor.toString(), campo.FormatoTexto);
                }

                if (campo.ValoresReemplazo)
                    tempValor = Reemplazo(tempValor, campo.ValoresReemplazo);
                if (valoresReemplazo)
                    tempValor = Reemplazo(tempValor, valoresReemplazo);
                if (!tempValor)
                    tempValor = "";

                tempValor = tempValor.toString().trim();



                switch (campo.TipoRelleno) {
                    case 'Derecho':
                        tempValor = tempValor.toString().padEnd(campo.AnchoRelleno, campo.CaracterRelleno);

                        break;
                    case 'Izquierdo':
                        tempValor = tempValor.toString().padStart(campo.AnchoRelleno, campo.CaracterRelleno);

                        break;
                }

                campo.Valor = tempValor.toString();
                if (campo.TamanoMaximo > 0 && campo.Valor.Length > campo.TamanoMaximo) {
                    campo.Valor = campo.Valor.substring(0, campo.TamanoMaximo);
                }
            });
            // 
            if (campos.length > 0) {

                let resultcampos: Array<any> = campos.map(item => {
                    return item.Valor.trim();
                })
                resultado = resultcampos.join(carcaterSeparacion);

            }
            if (formatoTexto != 'Normal') {

                resultado = FormatoTexto(resultado, formatoTexto);

            }
            if (maxLength > 0 && resultado.length > maxLength) {

                resultado = resultado.substring(0, maxLength);

            }
            return resultado;
        }
        function GetPrimerNombreGrupo(): any {
            let grupos = GetNombreGrupos();

            return (grupos.length > 0) ? grupos[0] : "";
        }
        function GetNombreGrupos(): Array<any> {
            let grupos: Array<any> = [];
            let temp = StaticData.Usuario.Grupos.split('|').filter(item => {
                return (item);
            });
            temp.forEach(item => {
                let subSplit: Array<any> = item.split('-');
                if (subSplit.length > 0 && subSplit[1])
                    grupos.push(item.split('-')[1])
            });

            return grupos;
        }
        function GetIdGrupos(): Array<any> {
            let grupos: Array<any> = [];
            grupos = StaticData.Usuario.Grupos.split('|').filter(item => {
                return (item);
            });
            return grupos;
        }
        function GetPrimerIdGrupo(): any {
            let grupos = GetIdGrupos();

            return (grupos.length > 0) ? grupos[0] : "";
        }
        // function SplitPropertyToModel(property) {
        //     let model = { model: null, property: null };
        //     let propModel = property;

        //     let listProps = (propModel) ? propModel.split('.') : null;
        //     if (propModel && listProps.length > 1) {
        //         model.model = listProps[0];
        //         model.property = listProps[1];
        //     }
        //     else
        //         model.model = propModel;
        //     return model;
        // }

        function SplitPropertyToModel(property) {
            let model = { property: property, listProps: null, multiProps: null };
            model.listProps = (property) ? property.split('.') : null;
            model.multiProps = SplitMultiple(property, '|', ',', ';');
            if (model.multiProps.length <= 1) {
                model.multiProps = null;
            }
            return model;
        }
        function GetValueFromPropList(modelo: any, listProps: Array<any>) {
            let value: any = null;

            if (listProps && listProps.length > 0) {
                value = modelo[listProps[0]];
                listProps.splice(0, 1);
                listProps.forEach(property => {
                    value = value[property]
                });
            }
            else
                value = modelo;

            return value;
        }
        function SetValueFromPropList(modelo: any, listProps: Array<any>, value: any) {
            let currentModel: any = modelo;

            if (listProps && listProps.length > 0) {
                if (listProps && listProps.length == 1) {

                    if (modelo[listProps[0]].setValue)
                        modelo[listProps[0]].setValue(value);
                    else
                        modelo[listProps[0]] = value;

                }
                else {
                    listProps.forEach(property => {
                        if (!currentModel[property])
                            currentModel[property] = {};
                        currentModel = currentModel[property];
                    });
                    if (currentModel.setValue)
                        currentModel.setValue(value);
                    else
                        currentModel = value
                }
            }
            else
                modelo = value;


        }
        function ConvertToList(valueProperty: any, objectValue: any) {
            throw "Metodo ConvertToList No Implementado";
        }
        function GetValueFromObject(obj: any, objectValue: any) {
            let valueProperty: any = null;
            if (obj == null)
                return valueProperty;

            if (Array.isArray(obj)) {
                if (objectValue.Object.includes("FIRST")) {

                    valueProperty = obj[0];
                    var propTemp = objectValue.Object.replace("FIRST", "").replace("[", "").replace("]", "");
                    if (propTemp && valueProperty) {
                        let temp = valueProperty[propTemp];
                        valueProperty = valueProperty[propTemp];
                    }
                }
                else if (objectValue.Object.includes("LAST")) {

                    valueProperty = obj[obj.length - 1];
                    var propTemp = objectValue.Object.replace("LAST", "").replace("[", "").replace("]", "");
                    if (propTemp && valueProperty)
                        valueProperty = valueProperty[propTemp];
                }
                else if (objectValue.Object.includes("INDEX")) {

                    var temp = objectValue.Object.Replace("INDEX[", "");
                    temp = temp.Substring(0, temp.IndexOf("]"));

                    valueProperty = obj[parseInt(temp)];

                    var propTemp = objectValue.Object.replace(temp, "").replace("INDEX", "").replace("[", "").replace("]", "");
                    if (propTemp && valueProperty)
                        valueProperty = valueProperty[propTemp];
                }
                else {

                    obj.forEach(dir => {
                        if (dir.hasOwnProperty(objectValue.Object)) {
                            valueProperty = dir[objectValue.Object];
                            if ((objectValue.DataType == 'Array' || objectValue.DataType == 'ArrayInt')) {
                                valueProperty = ConvertToList(valueProperty, objectValue);
                            }
                            return valueProperty;
                        }
                    });

                }
            }
            else if (typeof obj === 'string' || obj instanceof String)
                return valueProperty;
            else if (typeof obj === 'object') {
                valueProperty = obj[objectValue.Object];
            }
            else {

            }
            return valueProperty;
        }
        function GetGeneralValueFromObject(objectValue: any, dtt: any, extract: boolean = true, controlConfig?: any) {
            let value = objectValue.Object;

            if (objectValue.ObjectType == 'Value') {
                if (objectValue.DataType == "Boolean") {
                    if (objectValue.Object != null && objectValue.Object != undefined) {
                        if (objectValue.Object.toLowerCase() == "true" || objectValue.Object.toLowerCase() == "si")
                            value = true;
                        else if (objectValue.Object.toLowerCase() == "false" || objectValue.Object.toLowerCase() == "no")
                            value = false;
                        else
                            value = objectValue.Object;
                    }
                    else
                        value = objectValue.Object;
                }
                else {
                    if (objectValue.Object != null && objectValue.Object != undefined) {
                        if (objectValue.Object.toLowerCase() == "true")
                            value = true;
                        else if (objectValue.Object.toLowerCase() == "false")
                            value = false;
                        else
                            value = objectValue.Object;
                    }
                    else
                        value = objectValue.Object;
                }
            }
            else if (objectValue.ObjectType == 'AutoGenerateField') {
                // 
                value = CamposToString(that.utility.Clone(objectValue.AutoGenerateField.Fields),
                    objectValue.AutoGenerateField.CharacterSeparation,
                    objectValue.AutoGenerateField.ReplacementValues,
                    objectValue.AutoGenerateField.TextFormat,
                    objectValue.AutoGenerateField.MaxLength, dtt, controlConfig);
            }
            else if (objectValue.ObjectType == 'Control' || objectValue.ObjectType == 'Property') {

                let control = GetControlConfig(objectValue.Object);
                if (!objectValue.Property || objectValue.Property == 'value')
                    value = GetValueControl(control);
                else
                    value = $("#" + objectValue.Object).attr(objectValue.Property);
            }
            else if (objectValue.ObjectType == 'Model') {

                let model = SplitPropertyToModel(objectValue.Property);
                if (objectValue.Object == "currentItem") {

                    if (model.listProps && model.listProps.length > 0) {
                        let control = GetControlConfig(model.listProps[0]);
                        if (control) {
                            if (model.listProps.length >= 1)
                                value = control[model.listProps[1]];
                            else
                                value = control.ExtraData;
                        }
                        else if (dtt) {
                            value = GetValueFromPropList(dtt, model.listProps);
                        }

                    }
                    else if (controlConfig && controlConfig.ExtraData)
                        value = controlConfig.ExtraData;
                    else
                        value = dtt;
                }
                else if (objectValue.Object == "staticModel") {

                    value = GetValueFromPropList(that.utility, model.listProps);
                }
                else if (objectValue.Object == "staticDataModel") {
                    if (model.multiProps) {
                        value = { isMultiProps: true, multiProps: [] };
                        model.multiProps.forEach(property => {
                            value.multiProps.push({ property: property, value: StaticData[property].value });//
                        });
                    } else
                        value = GetValueFromPropList(StaticData, model.listProps);
                }
                else if (objectValue.Object == "formModel") {
                    if (model.multiProps) {
                        value = { isMultiProps: true, multiProps: [] };
                        model.multiProps.forEach(property => {
                            value.multiProps.push({ property: property, value: form.controls[property].value });//
                        });
                    } else {
                        value = GetValueFromPropList(form.controls, model.listProps);
                        if (value.value)
                            value = value.value;
                    }

                }
                else if (objectValue.Object == "formInternalModel") {
                    value = GetValueFromPropList(form, model.listProps);
                }
                else if (objectValue.Object == "queryConfig") {
                    if (scope.QueryConfig) {
                        value = GetValueFromPropList(scope.QueryConfig, model.listProps);
                    }
                }
                else if (objectValue.Object == "queryParameters") {
                    if (scope.QueryConfig) {
                        value = GetValueFromPropList(scope.QueryConfig.Entity, model.listProps);
                    }

                    else
                        throw new Error("No se encontro configuracion para lectura de valor")

                }
                else if (objectValue.Object == "modeloInterno") {
                    value = GetValueFromPropList(scope, model.listProps);
                }
                else {

                    if (model.multiProps) {
                        value = { isMultiProps: true, multiProps: [] };
                        model.multiProps.forEach(property => {
                            if (!objectValue.Object) {
                                value.multiProps.push({ property: property, value: scope.modelo[property] });// = GetValueFromPropList(scope.modelo, model.listProps);
                            }
                            else {
                                value.multiProps.push({ property: property, value: scope.modelo[objectValue.Object][property] });//
                                //value = GetValueFromPropList(scope.modelo[objectValue.Object], model.listProps);
                            }
                        });
                    }
                    else {
                        if (!objectValue.Object) {
                            value = GetValueFromPropList(scope.modelo, model.listProps);
                        }
                        else {
                            value = GetValueFromPropList(scope.modelo[objectValue.Object], model.listProps);
                        }
                    }
                }

            }
            else if (objectValue.ObjectType == 'Field') {


                if (objectValue.Object.includes(".")) {


                    const props = objectValue.Object.split('.');
                    let index = 0;
                    props.forEach(property => {

                        const _objectValue = {
                            DataType: objectValue.DataType,
                            ObjectType: objectValue.ObjectType,
                            Object: property,
                            Tag: objectValue.Tag,
                            Property: objectValue.Property,
                            ObjectValues: objectValue.ObjectValues,
                            AutoGenerateField: objectValue.AutoGenerateField
                        }
                        value = GetValueFromObject((index == 0) ? dtt : value, _objectValue);
                        index++;
                    }
                    );
                }
                else
                    value = dtt[0][objectValue.Object];
            }
            else if (objectValue.ObjectType == 'StaticVar') {

                value = that.utility.GetCache(objectValue.Object);
                if (extract)
                    value = ExtractValueFromVar(objectValue, value);

            }
            else if (objectValue.ObjectType == 'ContextVar') {

                value = scope[objectValue.Object];
                if (extract)
                    value = ExtractValueFromVar(objectValue, value);

            }
            else if (objectValue.ObjectType == 'LocalStorageVar') {

                value = localStorage.getItem(objectValue.Object);
                value = JSON.parse(value);
                if (extract)
                    value = ExtractValueFromVar(objectValue, value);

            }
            else if (objectValue.ObjectType == 'SessionStorageVar') {

                value = sessionStorage.getItem(objectValue.Object);
                value = JSON.parse(value);
                if (extract)
                    value = ExtractValueFromVar(objectValue, value);

            }
            else if (objectValue.ObjectType == 'GlobalVar') {
                if (!IsReservedWord(objectValue.Object))
                    throw `No existe el control '${objectValue.Object}' en la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`;
                else {
                    value = getValueFromConditionObject(objectValue.Object);
                }
            }
            if (objectValue.DataType == "DateTime" && !(value instanceof Date))
                value = new Date(value);
            return value;
        }
        function GetValueControlByName(name) {
            let control = GetControlConfig(name);
            if (!control) {
                if (!IsReservedWord(name))
                    throw `No existe el control '${name}' en la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`;
                else {
                    return getValueFromConditionObject(name);
                }
            }

            let value = GetValueControl(control);
            return value;
        }
        function getValueFromConditionObject(object) {

            let value = object;
            let sourceModel = [];
            if (!object)
                value = object;
            else if (object == '')
                value = object;
            else {
                //CurrentItemParameter
                if (object.trim().toLowerCase() == "currentuser.name" || object.trim().toLowerCase() == "currentusername") {
                    if (StaticData.Usuario) {
                        //return ($scope.CurrentUser.Name == ((valueToCompare) ? valueToCompare.trim() : ''));
                        value = StaticData.Usuario.Usuario;
                        return value;
                    }
                    else
                        return '';
                }
                else if (object.trim().toLowerCase() == "role" || object.trim().toLowerCase() == "rolename") {
                    if (StaticData.Usuario) {
                        //return ($scope.CurrentUser.Name == ((valueToCompare) ? valueToCompare.trim() : ''));
                        value = StaticData.Usuario.Role;
                        return value;
                    }
                    else
                        return '';
                }
                else if (object.trim().toLowerCase() == "idrole" || object.trim().toLowerCase() == "roleid") {
                    if (StaticData.Usuario) {
                        //return ($scope.CurrentUser.Name == ((valueToCompare) ? valueToCompare.trim() : ''));
                        value = StaticData.Usuario.IdRole;
                        return value;
                    }
                    else
                        return '';
                }
                else if (object.trim().toLowerCase() == "idusuario") {
                    if (StaticData.Usuario) {
                        //return ($scope.CurrentUser.Name == ((valueToCompare) ? valueToCompare.trim() : ''));
                        value = StaticData.Usuario.IdUsuario;
                        return value;
                    }
                    else
                        return '';
                }
                else if (object.trim().toLowerCase() == "islogin") {
                    if (StaticData.Usuario) {

                        return true;
                    }
                    else
                        return false;
                }
                else if (object.trim().toLowerCase() == "idareaempresa") {
                    if (StaticData.Usuario) {
                        value = StaticData.Usuario.IdAreaEmpresa;
                        return value;
                    }
                    else
                        return '';
                }
                else if (object.trim().toLowerCase() == "currentdate") {
                    if (StaticData.Usuario) {
                        value = new Date();
                        return value;
                    }
                    else
                        return '';
                }
                else if (object.trim().toLowerCase() == "currentdatetime") {
                    if (StaticData.Usuario) {
                        value = new Date();
                        return value;
                    }
                    else
                        return '';
                }
            }
            return value
        }

        function IsReservedWord(object) {

            let value = object;
            let sourceModel = [];
            let GlobalVars: Array<any> = [
                { Name: 'islogin', Label: 'Esta Autenticado' },
                { Name: 'currentusername', Label: 'Usuario' },
                { Name: 'role', Label: 'Role' },
                { Name: 'idrole', Label: 'Id Role' },
                { Name: 'idUsuario', Label: 'Id Usuario' },
                { Name: 'idareaempresa', Label: 'Id Area Empresa' },
                { Name: 'idempresa', Label: 'Id Empresa' },
                { Name: 'idgrupo', Label: 'Id Grupo' },
                { Name: 'currentdate', Label: 'Fecha Actual' },
                { Name: 'currentdatetime', Label: 'Fecha/Hora Actual' }

            ];
            if (!object)
                return false;
            else if (object == '')
                return false;
            else {
                //CurrentItemParameter
                if (GlobalVars.find(x => { return x.Name == object.trim().toLowerCase() })) {
                    return true;
                }
                if (object.trim().toLowerCase() == "currentuser.name" || object.trim().toLowerCase() == "currentusername") {
                    return true;
                }
                else if (object.trim().toLowerCase() == "role" || object.trim().toLowerCase() == "rolename") {
                    return true;
                }
                else if (object.trim().toLowerCase() == "idrole" || object.trim().toLowerCase() == "roleid") {
                    return true;
                }
                else if (object.trim().toLowerCase() == "idrole" || object.trim().toLowerCase() == "roleid") {
                    return true;
                }
            }
            return false;
        }
        function convertGetValue(property, valueToSet, dataOfType) {


            let type = jQuery.type(property);
            let _dataOfType: DataTypes = that.utilityRuleEngine.AnyToDataTypes(dataOfType);
            let _msgLog = `convertGetValue property:'${property}'valueToSet:'${valueToSet}'dataOfType:'${dataOfType}'type:'${type}'`;
            that.utility.logger.LogInfoText(_msgLog);
            if (jQuery.type(property) === "boolean") {
                switch (valueToSet.toLowerCase().trim()) {
                    case "true":
                    case "yes":
                    case "1":
                        valueToSet = true;
                        break;
                    case "false": case "no": case "0": case null:
                        valueToSet = false;
                        break;

                }
            }
            else if (jQuery.type(property) === "number") {
                valueToSet = parseInt(valueToSet)
            }
            else {
                if (_dataOfType) {

                    if (_dataOfType == DataTypes.Number) {
                        valueToSet = parseInt(valueToSet)
                    }
                    //else if (dataOfType == dsf.Core.Utility.BasicDataTypes.Decimal.Id ||
                    //   dataOfType == dsf.Core.Utility.BasicDataTypes.Double.Id ||
                    //   dataOfType == dsf.Core.Utility.BasicDataTypes.Single.Id ||
                    //   dataOfType == dsf.Core.Utility.BasicDataTypes.Float.Id) {
                    //    valueToSet = parseFloat(valueToSet)
                    //}
                    else if (_dataOfType == DataTypes.Boolean) {
                        switch (valueToSet.toLowerCase().trim()) {
                            case "true":
                            case "yes":
                            case "1":
                                valueToSet = true;
                                break;
                            case "false": case "no": case "0": case null:
                                valueToSet = false;
                                break;

                        }
                    }
                }
            }
            that.utility.logger.LogInfoText("convertGetValue", valueToSet);
            return valueToSet;
        }
        function Compare(value, valueToCompare, op) {
            let cumple = true;
            that.utility.logger.LogInfoText(`Compare:[Valor:${value},Operador:${op},ValorComparar:${valueToCompare}]`);

            if (value) {
                let temp = value.toString().toLowerCase().trim();
                if (temp == "islogin") {
                    if (StaticData.Usuario)
                        return true;
                    else
                        return false;
                }
                else if (temp == "currentuser.name" || temp == "currentusername") {
                    if (StaticData.Usuario) {
                        //value = (StaticData.Usuario.Usuario == valueToCompare) ? true : false;
                        value = StaticData.Usuario.Usuario;
                    }
                    else
                        return false;
                }
                else if (temp == "role" || temp == "rolename") {
                    if (StaticData.Usuario) {
                        //value = (StaticData.Usuario.Role == valueToCompare) ? true : false;
                        value = StaticData.Usuario.Role;
                    }
                    else
                        return false;
                }
                else if (temp == "idrole" || temp == "roleid") {
                    if (StaticData.Usuario) {
                        // value = (StaticData.Usuario.IdRole == valueToCompare) ? true : false;
                        value = StaticData.Usuario.IdRole;
                    }
                    else
                        return false;
                }

                else if (temp == "currentuserroles" || temp == "currentuser.roles") {
                    if (StaticData.Usuario) {

                        let ok = false;
                        let rolesNames = valueToCompare.trim().split(',');
                        ok = rolesNames.indexOf(StaticData.Usuario.Role) > -1;


                        return ok;

                    }
                    else
                        return false;
                }
            }
            if (valueToCompare) {
                var temp = valueToCompare.toString().toLowerCase().trim();
                if (temp == "islogin") {
                    if (StaticData.Usuario)
                        return true;
                    else
                        return false;
                }
                else if (temp == "currentuser.name" || temp == "currentusername") {
                    if (StaticData.Usuario) {
                        //return ($scope.CurrentUser.Name == ((value) ? value.trim() : ''));
                        valueToCompare = StaticData.Usuario.Usuario;
                    }
                    else
                        return false;
                }
                else if (temp == "role" || temp == "rolename") {
                    if (StaticData.Usuario) {
                        //value = (StaticData.Usuario.Role == valueToCompare) ? true : false;
                        valueToCompare = StaticData.Usuario.Role;
                    }
                    else
                        return false;
                }
                else if (temp == "idrole" || temp == "roleid") {
                    if (StaticData.Usuario) {
                        // value = (StaticData.Usuario.IdRole == valueToCompare) ? true : false;
                        valueToCompare = StaticData.Usuario.IdRole;
                    }
                    else
                        return false;
                }
                else if (temp == "currentuserroles" || temp == "currentuser.roles") {
                    if (StaticData.Usuario) {

                        var ok = false;
                        var rolesNames = value.trim().split(',');
                        ok = rolesNames.indexOf(StaticData.Usuario.Role) > -1;

                        return ok;

                    }
                    else
                        return false;
                }
            }
            const _op: ComparisonOperators = that.utilityRuleEngine.AnyToComparisonOperators(op);
            switch (_op) {
                case ComparisonOperators.Equal:
                    cumple = (value == valueToCompare);
                    break;
                case ComparisonOperators.NotEqual:
                    cumple = (value != valueToCompare);
                    break;
                case ComparisonOperators.In:
                    cumple = ((valueToCompare) ? valueToCompare.indexOf(value) > -1 : false);
                    break;
                case ComparisonOperators.NotIn:
                    cumple = ((valueToCompare) ? !(valueToCompare.indexOf(value) > -1) : true);
                    break;
                case ComparisonOperators.Contains:
                    cumple = ((value && valueToCompare) ? value.toString().contains(valueToCompare.toString()) : false);
                    break;
                case ComparisonOperators.NotContains:
                    cumple = ((value && valueToCompare) ? !value.toString().contains(valueToCompare.toString()) : true);
                    break;
                case ComparisonOperators.StartsWith:
                    cumple = ((value && valueToCompare) ? value.toString().startsWith(valueToCompare.toString()) : false);
                    break;
                case ComparisonOperators.EndsWith:
                    cumple = ((value && valueToCompare) ? value.toString().endsWith(valueToCompare.toString()) : false);
                    break;
                case ComparisonOperators.NotStartsWith:
                    cumple = ((value && valueToCompare) ? !value.toString().startsWith(valueToCompare.toString()) : true);
                    break;
                case ComparisonOperators.NotEndsWith:
                    cumple = ((value && valueToCompare) ? !value.toString().endsWith(valueToCompare.toString()) : true);
                    break;
                case ComparisonOperators.GreaterThan:
                    cumple = (value > valueToCompare);
                    break;
                case ComparisonOperators.LessThan:
                    cumple = (value < valueToCompare);
                    break;
                case ComparisonOperators.GreaterOrEqual:
                    cumple = (value >= valueToCompare);
                    break;
                case ComparisonOperators.LessOrEqual:
                    cumple = (value <= valueToCompare);
                    break;
                case ComparisonOperators.Range:
                    cumple = ((valueToCompare) ? valueToCompare.indexOf(value) > -1 : false);
                    break;
                case ComparisonOperators.IsNull:
                    cumple = (!value);
                    break;
                case ComparisonOperators.IsNotNull:
                    cumple = (value);
                    break;
                case ComparisonOperators.IsUndefined:
                    cumple = (value === undefined);
                    break;
                case ComparisonOperators.IsNotUndefined:
                    cumple = (value !== undefined);
                    break;


            }

            that.utility.logger.LogInfoText(`Compare:[(${value} ${op} ${valueToCompare}) = ${cumple}]`);
            return cumple;
        }

        function GetValueAndValueToCompare(subcondition, isQuery) {
            var result = { value: null, valueToCompare: null };
            var value = null;
            var valueToCompare = null;
            var controlL = GetControlConfig(subcondition.Property);
            if (!controlL) {
                if (!IsReservedWord(subcondition.Property))
                    throw `No existe el control '${subcondition.Property}' en la Pagina '${pagina.IdPagina}'`;
                else
                    result.value = getValueFromConditionObject(subcondition.Property);
            }
            else
                result.value = GetValueControl(controlL);
            if (subcondition.Field) {
                var controlR = GetControlConfig(subcondition.Field);
                if (!controlR) {
                    if (!IsReservedWord(subcondition.Field))
                        throw `No existe el control '${subcondition.Field}' en la Pagina '${pagina.IdPagina}'`;
                    else
                        result.valueToCompare = getValueFromConditionObject(subcondition.Field);
                    //throw `No existe el control '${subcondition.Field}' en la Pagina '${pagina.IdPagina}'`;
                }
                else
                    result.valueToCompare = GetValueControl(controlR);
            }
            else
                result.valueToCompare = getValueFromConditionObject(subcondition.Value);

            return result;
        }
        function ExecuteSubCondition(subcondition, controlConfig, event, rule, dtt: any) {

            that.utility.logger.LogInfoText(`Ejecutando SubCondicion '${subcondition.GUID}', en el evento:'${event.Name}' `);

            if (!subcondition)
                return true;

            let _cumple = true;
            let value = GetGeneralValueFromObject(subcondition.LeftObject, dtt);
            let valueToCompare = GetGeneralValueFromObject(subcondition.RightObject, dtt);



            that.utility.logger.LogInfoText(`([${subcondition.LeftObject.ObjectType}] ${subcondition.LeftObject.Object}  ${subcondition.Op} [${subcondition.RightObject.ObjectType}] ${subcondition.RightObject.Object})`);
            _cumple = Compare(value, valueToCompare, subcondition.Op);
            that.utility.logger.LogInfoText(`Ejecutando Sub Condicion  '${subcondition.GUID}', en el evento:'${event.Name}', cumple:'${_cumple}' `, subcondition);

            // that.utility.logger.LogInfoText("ExecuteSubCondition", _cumple);
            return _cumple;
        }
        function ExecuteConditionQueryInternal(condition, controlConfig, event, rule, dataQuery, callback) {

            var cumple = true;
            let containsOr: boolean = false;
            var cumplimientosGenerales = [];
            var querys = '';

            var count = 0;

            $.each(dataQuery, (key, dttTemp) => {

                var dtt = dttTemp.Data;
                var query = dttTemp.Query;// condition.ConditionsQuerys[count];

                if (query.ValuesToCompare.length > 0) {

                    if (dtt && dtt.length > 0) {

                        var cumplimientos = [];
                        $.each(query.ValuesToCompare, (key, subcondition: any) => {
                            var _cumple = ExecuteSubCondition(subcondition, controlConfig, event, rule, dtt);
                            cumplimientos.push(_cumple);
                        });


                        var valueToCompare = (condition.IsNegated) ? false : true;

                        var resultOr = new List<any>(condition.ConditionsQuerys).Where(x => { return x.LogicalOp == "Or" }).FirstOrDefault();
                        if (resultOr) {
                            containsOr = true;
                            resultOr = new List<any>(cumplimientos).Where(x => { return x == valueToCompare }).ToArray().length;
                            if (resultOr == 0) {
                                cumplimientosGenerales.push(false);
                                if (condition.ExistForFirstValidation) {
                                    cumple = false;
                                    return false;
                                }
                            }
                            else
                                cumplimientosGenerales.push(true);
                        }
                        else {
                            var conteo = new List<any>(cumplimientos).Where(x => { return x == valueToCompare }).ToArray().length;
                            if (conteo != query.ValuesToCompare.length) {
                                cumplimientosGenerales.push(false);
                                if (condition.ExistForFirstValidation) {
                                    cumple = false;
                                    return false;
                                }
                            }
                            else
                                cumplimientosGenerales.push(true);
                        }

                    }
                }
                else {

                    var cumplimiento = false;
                    if (dtt && dtt.length > 0) {
                        if (dtt.length == 1 && dtt["CONTEO_TOTAL"])
                            cumplimiento = Compare(dtt["CONTEO_TOTAL"], query.Count, query.OpCount);
                        else
                            cumplimiento = Compare(dtt.length, query.Count, query.OpCount);
                    }
                    cumplimientosGenerales.push(cumplimiento);
                    if (condition.ExistForFirstValidation && !cumplimiento) {
                        cumple = false;
                        return false;
                    }
                }

                count++;
            });


            var GeneralalueToCompare = (condition.IsNegated) ? false : true;

            var conteoGeneral = new List<any>(cumplimientosGenerales).Where(x => { return x == GeneralalueToCompare }).ToArray().length;
            var resultOrGeneral = new List<any>(condition.ConditionsQuerys).Where(x => { return x.LogicalOp == "Or" }).FirstOrDefault();
            if (resultOrGeneral) {
                cumple = (conteoGeneral > 0);
                containsOr = true;
            }
            else
                cumple = (conteoGeneral == condition.ConditionsQuerys.length);

            that.utility.logger.LogInfoText("ExecuteConditionQuery", cumple);
            if (callback) {
                callback({ cumple: cumple, containsOr: containsOr });
                //if (condition.ConditionsVars.length > 0 && cumple == true) {
                //    ExecuteConditionVars(condition, callback);
                //}
                //else {
                //    callback(cumple);
                //}
            }

            return cumple;
        }
        function ExecuteConditionQuery(condition, controlConfig, event, rule, callback, especificModel?: any) {


            var cumple = true;
            var cumplimientosGenerales = [];
            var querys = '';
            var dataQuery = [];
            var whithRunGeneral = false;
            var include = [];
            // 
            that.utility.logger.LogInfoText(`Ejecutando Condicion de Consulta '${condition.GUID}', en el evento:'${event.Name}' `);
            for (var i = 0; i < condition.ConditionsQuerys.length; i++) {


                let _temp = GetDataQueryFromMemory(condition.ConditionsQuerys[i], dataQuery, include);
                if (_temp) {
                    whithRunGeneral = true;
                    if (i < condition.ConditionsQuerys.length - 1)
                        querys += condition.ConditionsQuerys[i].Name + ',';
                    else
                        querys += condition.ConditionsQuerys[i].Name;
                }
                /*   if (scope.ContainerData[condition.ConditionsQuerys[i].Name]) {

                      dataQuery.push({ Query: condition.ConditionsQuerys[i], Data: scope.ContainerData[condition.ConditionsQuerys[i].Name], Index: i });
                      that.utility.logger.LogInfoText('ExecuteConditionQuery Existe en container ' + condition.ConditionsQuerys[i].Name);
                  }
                  else {
                      whithRunGeneral = true;
                      if (i < condition.ConditionsQuerys.length - 1)
                          querys += condition.ConditionsQuerys[i].Name + ',';
                      else
                          querys += condition.ConditionsQuerys[i].Name;

                      include.push(condition.ConditionsQuerys[i].Name);
                      dataQuery.push({ Query: condition.ConditionsQuerys[i], Data: null, Index: i });
                  } */
            }
            if (whithRunGeneral == true) {

                let modelo = (isModelContainer) ? scope.modelo.modelo : scope.modelo;
                var obj = that.utility.GetApiModel('RunRules', controllerName, null, null, modelo);
                obj.Include = include;

                obj.ExecutionObject = GetExecutionObject(rule, controlConfig, event, 'Condition', include, null, condition.ConditionsQuerys);// rule + ';Condition;' + controlConfig.Name + ';' + event.Name;

                if (especificModel) {
                    if (especificModel.Model)
                        obj.ExecutionObject.Model = especificModel.Model;
                    if (especificModel.ExtraObject)
                        obj.ExecutionObject.ExtraObject = especificModel.ExtraObject;
                }
                that.baseService.RunRules(obj, {
                    componentName: controllerName,
                    processMessage: 'Cargando Entidades',
                    method: 'RunRules'
                }, controllerName, false, false).then(data => {

                    let count: number = 0;

                    $.each(data.Data, (key, dttTemp) => {

                        let dtt: any = (dttTemp.Data) ? dttTemp.Data : dttTemp;
                        $.each(dataQuery, (_key, query) => {

                            if (query.Query.Name == key || query.Query.Name == dttTemp.Name) {
                                query.Data = dtt;
                                SetDataQueryToMemory(query, dtt);
                                // if (query.SetInMemory == true) {
                                //     that.utility.logger.LogInfoText('ExecuteConditionQuery Set en container ' + query.Name);
                                //     scope.ContainerData[query.Query.Name] = dtt;
                                // }
                            }
                        });
                        count++;
                    });

                    count = 0;

                    ExecuteConditionQueryInternal(condition, controlConfig, event, rule, dataQuery, callback);

                });


                //runGeneral(querys, 'RunQuery', function (data) {


                //})
            }
            else
                ExecuteConditionQueryInternal(condition, controlConfig, event, rule, dataQuery, callback);


            return cumple;
        }
        function ExecuteCondition(condition, controlConfig, event, rule) {

            that.utility.logger.LogInfoText(`Ejecutando Condicion:'${condition.Name}',GUID '${condition.GUID}', en el evento:'${event.Name}' `);
            let cumple = true;
            let containsOr: boolean = false;
            let cumplimientos = [];
            if (condition.ConditionsValues.length == 0)
                return cumple;
            $.each(condition.ConditionsValues, (key, subcondition) => {

                if (cumple == true) {
                    let _cumple = ExecuteSubCondition(subcondition, controlConfig, event, rule, null);;

                    cumplimientos.push(_cumple);
                    if (condition.ExistForFirstValidation && !_cumple) {
                        cumple = false;
                        return false;
                    }

                }
            });
            if (cumple == true) {

                let valueToCompare = (condition.IsNegated) ? false : true;
                //Enumerable.From(condition.Conditions).Where(x=>{ return x.LogicalOp == General.Enums.LogicalOperators.Or }).FirstOrDefault()
                let resultOr = new List<any>(condition.ConditionsValues).Where(x => { return x.LogicalOp == "Or" }).FirstOrDefault();
                if (resultOr) {
                    containsOr = true;
                    resultOr = new List<any>(cumplimientos).Where(x => { return x == valueToCompare }).FirstOrDefault();
                    cumple = (resultOr);
                }
                else {
                    let conteo = new List<any>(cumplimientos).Where(x => { return x == valueToCompare }).ToArray().length;
                    cumple = (conteo == condition.ConditionsValues.length);//(cumplimientos.Where(o => o == valueToCompare).Count() == condition.Conditions.Count());
                }

            }
            //cumple = ExecuteConditionsScript(condition, controlConfig, event, rule, null);

            that.utility.logger.LogInfoText("ExecuteCondition", cumple);
            return { cumple: cumple, containsOr: containsOr };
        }
        function ExecuteConditionsScript(condition, controlConfig, event, rule, callback) {

            that.utility.logger.LogInfoText(`Ejecutando Condicion Script:'${condition.Name}',GUID: '${condition.GUID}', en el evento:'${event.Name}' `);
            let cumple = true;
            let containsOr: boolean = false;
            let cumplimientos = [];
            if (condition.Scripts.length == 0)
                return { cumple: cumple, containsOr: containsOr };
            $.each(condition.Scripts, (key, subcondition) => {

                if (cumple == true) {
                    let _cumple = ExecuteGeneralScript(subcondition.ExecuteByEval, subcondition.Script, controlConfig, event, rule, callback);
                    that.utility.logger.LogInfoText(`Ejecutando Sub Condicion  Script:'${subcondition.Name}',GUID:  '${subcondition.GUID}', en el evento:'${event.Name}', cumple:'${_cumple}' `, subcondition);

                    cumplimientos.push(_cumple);
                    if (condition.ExistForFirstValidation && !_cumple) {
                        cumple = false;
                        return false;
                    }

                }
            });
            if (cumple == true) {

                let valueToCompare = (condition.IsNegated) ? false : true;
                //Enumerable.From(condition.Conditions).Where(x=>{ return x.LogicalOp == General.Enums.LogicalOperators.Or }).FirstOrDefault()
                let resultOr = new List<any>(condition.Scripts).Where(x => { return x.LogicalOp == "Or" }).FirstOrDefault();
                if (resultOr) {
                    containsOr = true;
                    resultOr = new List<any>(cumplimientos).Where(x => { return x == valueToCompare }).FirstOrDefault();
                    cumple = (resultOr);
                }
                else {
                    let conteo = new List<any>(cumplimientos).Where(x => { return x == valueToCompare }).ToArray().length;
                    cumple = (conteo == condition.Scripts.length);//(cumplimientos.Where(o => o == valueToCompare).Count() == condition.Conditions.Count());
                }

            }
            //cumple = ExecuteConditionsScript(condition, controlConfig, event, rule, null);
            that.utility.logger.LogInfoText("ExecuteCondition", cumple);
            return { cumple: cumple, containsOr: containsOr };
        }

        function SetAttributo($control, controlConfig, attName: string, valueToSet: any, defaultValue: any, parse: boolean) {

            if (form) {
                if (valueToSet)
                    $control.attr(attName.toLowerCase(), valueToSet);
                else
                    $control.removeAttr(attName.toLowerCase());
            }
            else {
                if (valueToSet)
                    controlConfig[attName] = (parse) ? parseInt(valueToSet) : valueToSet;
                else
                    controlConfig[attName] = defaultValue;

            }
        }
        function SetValidations(formControl) {

            let ctr: AbstractControl = form.controls[formControl.Name];
            ctr.clearValidators();
            let validations = [];
            if (formControl.Required)
                validations.push(Validators.required);
            if (formControl.MaxLength)
                validations.push(Validators.maxLength(formControl.MaxLength));
            if (formControl.MinLength)
                validations.push(Validators.minLength(formControl.MinLength));
            if (formControl.ControlType == 'NumberBox') {
                if (formControl.Max != null && !isNaN(formControl.Max) && formControl.Max != formControl.Min)
                    validations.push(Validators.max(Number(formControl.Max)));
                if (formControl.Min != null && formControl.Max != null && formControl.Min == formControl.Max)
                    console.warn(`Se ignoró validación Max dado que es igual que Min, en ${formControl.Name}`);
                if (formControl.Min != null && !isNaN(formControl.Min))
                    validations.push(Validators.min(Number(formControl.Min)));
            }
            if (formControl.ControlType == 'EmailBox')
                validations.push(Validators.email);
            if (formControl.Pattern)
                validations.push(Validators.pattern(formControl.Pattern));


            ctr.setValidators(validations);

            setTimeout(() => {
                ctr.updateValueAndValidity();
            }, 70);

            //const ctr = new FormControl(null, validations);
        }
        function SetControlValue($control, $label, controlConfig, valueToSet, controlProperty) {
            if (!controlProperty)
                throw `SetControlValue  EL VALOR DE LA PROPIEDAD A SIGNAR NO PUEDE SER NULO EN EL CONTROL :'${controlConfig.Name}' en la Pagina '${pagina.IdPagina}''${pagina.Pagina}'`;
            let ctr: AbstractControl;
            if (form) {
                // ctr = form.controls[controlConfig.Name];

            }
            let isInput = ($control.length > 0 || form.controls[controlConfig.Name]);

            let _valueToSet = Array.isArray(valueToSet) ? 'Array de ' + valueToSet.length + ' Elementos' : valueToSet;

            that.utility.logger.LogInfoText(`SetControlValue  asignar la propiedad '${controlProperty}' el valor  '${valueToSet}' del Control:'${controlConfig.Name}' en la Pagina '${pagina.IdPagina}''${pagina.Pagina}'`);

            if (isInput) {
                SetInputControlValue($control, $label, controlConfig, valueToSet, controlProperty);
            }
            else {
                // 
                let component: any = null;

                let componentsContainer: ComponentsContainer = scope.componentsContainer;
                component = componentsContainer.Find(controlConfig.Name);
                // component = componentsContainer.Tables.find(x => { return x.name == controlConfig.Name });
                // if (!component)
                //     component = componentsContainer.ChildComponents.find(x => { return x.name == controlConfig.Name });
                // if (!component && componentsContainer.FileBox && componentsContainer.FileBox.name == controlConfig.Name)
                //     component = componentsContainer.FileBox;
                // if (!component && componentsContainer.FileBoxWorkFlow && componentsContainer.FileBoxWorkFlow.name == controlConfig.Name)
                //     component = componentsContainer.FileBoxWorkFlow;
                // if (!component)
                //     component = componentsContainer.FloatPanels.find(x => { return x.name == controlConfig.Name });
                // if (!component)
                //     component = componentsContainer.FormsDetails.find(x => { return x.name == controlConfig.Name });

                if (!component) {
                    that.utility.logger.LogErrorText("SetControlValue,No se encontro el componente:" + controlConfig.Name);
                    return;
                }
                switch (controlProperty.toLowerCase()) {
                    case 'visible':

                        if (valueToSet == 'true') {

                            component.Hidden = false;
                        }
                        else {
                            component.Hidden = true;
                        }
                        break;
                    case 'active':
                        let isactive = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        controlConfig.Activo = isactive;
                        component.Disabled = isactive;
                        break;
                    case 'hidden':
                        let ishidden = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        controlConfig.Hidden = ishidden;
                        component.Hidden = ishidden;
                        break;
                    case 'enabled':
                        let Isdisabled = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        $control.attr("disabled", !Isdisabled);
                        $label.attr("disabled", !Isdisabled);


                        component.Disabled = Isdisabled;
                        break;
                    case 'readonly':
                        let isreadonly = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                        $control.attr("readonly", isreadonly);
                        component.Disabled = isreadonly;
                        break;
                    case 'pattern':

                        break;
                    case 'maxlength':


                        break;
                    case 'minlength':


                        break;
                    case 'max':

                        break;
                    case 'min':

                        break;
                    case 'required':


                        break;
                    case 'datasource':

                        break;
                    case 'config':

                        break;
                    case 'configcontrol':

                        break;
                    case 'configprop':

                        break;
                    case 'configpropjson':

                        break;
                    //
                    case 'value':
                        if (component.Execute)
                            component.Execute();
                        break;
                    case 'text':
                        //control.val(reglaData[2]);
                        $label.text(valueToSet);
                        break;
                    case 'title':
                        $control.attr("title", valueToSet);
                        break;
                    case 'placeholder':
                        $control.attr("placeholder", valueToSet);
                        break;

                }
            }
        }
        function SetInputControlValue($control, $label, controlConfig, valueToSet, controlProperty) {
            if (!controlProperty)
                throw `SetControlValue  EL VALOR DE LA PROPIEDAD A SIGNAR NO PUEDE SER NULO EN EL CONTROL :'${controlConfig.Name}' en la Pagina '${pagina.IdPagina}''${pagina.Pagina}'`;
            let ctr: AbstractControl;
            if (form) {
                // ctr = form.controls[controlConfig.Name];

            }
            let _valueToSet = Array.isArray(valueToSet) ? 'Array de ' + valueToSet.length + ' Elementos' : valueToSet;

            that.utility.logger.LogInfoText(`SetControlValue  asignar la propiedad '${controlProperty}' el valor  '${valueToSet}' del Control:'${controlConfig.Name}' en la Pagina '${pagina.IdPagina}''${pagina.Pagina}'`);

            // 
            switch (controlProperty.toLowerCase()) {
                case 'visible':


                    if (valueToSet == 'true') {

                        $control.show();
                        $label.show();
                    }
                    else {
                        if (pagina.IdPagina == 2102) {
                            $control.show();
                            $label.show();

                        } else {
                            $control.hide();
                            $label.hide();
                        }
                    }
                    break;
                case 'active':
                    let isactive = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                    controlConfig.Activo = isactive;
                    break;
                case 'hidden':
                    let ishidden = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                    if (controlConfig.Name == "btnGuardar")
                        controlConfig.HideSave = ishidden;
                    else if (controlConfig.Name == "btnLimpiar")
                        controlConfig.HideClear = ishidden;
                    controlConfig.Hidden = ishidden;
                    break;
                case 'enabled':
                    let Isdisabled = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                    $control.attr("disabled", !Isdisabled);
                    $label.attr("disabled", !Isdisabled);
                    //controlConfig.Disabled =!Isdisabled;
                    ctr = form.controls[controlConfig.Name];
                    if (Isdisabled)
                        ctr.enable();
                    else
                        ctr.disable();
                    break;
                case 'readonly':
                    let isreadonly = (valueToSet == 'true' || valueToSet == '1' || valueToSet == 'si') ? true : false;
                    $control.attr("readonly", isreadonly);
                    break;
                case 'pattern':
                    if (valueToSet) {
                        controlConfig.Pattern = valueToSet;
                    }
                    else {
                        controlConfig.Pattern = '';

                    }
                    SetValidations(controlConfig);
                    //SetAttributo($control, controlConfig, 'Pattern', valueToSet, '', false);
                    /*
                                        if (form) {
                                            if (valueToSet)
                                                $control.attr("pattern", valueToSet);
                                            else
                                                $control.removeAttr("pattern");
                                        }
                                        else {

                                            if (valueToSet) {
                                                controlConfig.Pattern = valueToSet;
                                            }
                                            else {
                                                controlConfig.Pattern = '';

                                            }
                                        } */
                    break;
                case 'maxlength':

                    if (valueToSet) {
                        controlConfig.MaxLength = parseInt(valueToSet);
                    }
                    else {
                        controlConfig.MaxLength = 0;
                    }
                    SetValidations(controlConfig);
                    //SetAttributo($control, controlConfig, 'MaxLength', valueToSet, 0, true);
                    /*   if (form) {
                          if (valueToSet)
                              $control.attr("maxlength", valueToSet);
                          else
                              $control.removeAttr("maxlength");
                      }
                      else {
                          if (valueToSet) {
                              controlConfig.MaxLength = parseInt(valueToSet);
                          }
                          else {
                              controlConfig.MaxLength = 0;
                          }
                      } */
                    break;
                case 'minlength':

                    if (valueToSet) {
                        controlConfig.MinLength = parseInt(valueToSet);
                    }
                    else {
                        controlConfig.MinLength = 0;
                    }
                    SetValidations(controlConfig);
                    //SetAttributo($control, controlConfig, 'MinLength', valueToSet, 0, true);
                    // if (form) {
                    //     if (valueToSet)
                    //         $control.attr("maxlength", valueToSet);
                    //     else
                    //         $control.removeAttr("maxlength");
                    // }
                    // else {
                    //     if (valueToSet) {
                    //         controlConfig.MinLength = parseInt(valueToSet);
                    //     }
                    //     else {
                    //         controlConfig.MinLength = 0;
                    //     }
                    // }
                    break;
                case 'max':
                    //SetAttributo($control, controlConfig, 'Max', valueToSet, 0, true);
                    if (valueToSet) {
                        controlConfig.Max = parseInt(valueToSet);
                    }
                    else {
                        controlConfig.Max = 0;
                    }
                    SetValidations(controlConfig);
                    break;
                case 'min':
                    //SetAttributo($control, controlConfig, 'Min', valueToSet, 0, true);
                    if (valueToSet) {
                        controlConfig.Min = parseInt(valueToSet);
                    }
                    else {
                        controlConfig.Min = 0;
                    }
                    SetValidations(controlConfig);
                    break;
                case 'required':

                    let Isrequired = (valueToSet == 'true' || valueToSet == true || valueToSet == 1) ? true : false;

                    //SetAttributo($control, controlConfig, 'Required', Isrequired, false, false);

                    if (valueToSet == 'true' || valueToSet == true || valueToSet == 1) {
                        controlConfig.Required = Isrequired;
                    }
                    else {
                        controlConfig.Required = false;
                    }
                    SetValidations(controlConfig);
                    break;
                case 'msgvalidation':

                    ctr = form.controls[controlConfig.Name];
                    ctr.setErrors({ custom: valueToSet });
                    break;
                case 'datasource':
                    //control.val(reglaData[2]);
                    if (controlConfig.ControlType == 'ComboBox' || controlConfig.ControlType == 'MultiselectBox') {
                        //throw 'Asiganar dataSource no implementado'


                        if (scope.configs['Config' + controlConfig.Name]) {
                            setTimeout(() => {

                                let items = JSON.parse(valueToSet);
                                scope.configs['Config' + controlConfig.Name].Context.CargarItemsComboBox(null, true, items, true);
                                //.CargarItems(null, false, valueToSet);
                            }, 100);
                        }


                    }
                    break;
                case 'config':
                    //control.val(reglaData[2]);
                    if (controlConfig.ControlType == 'ComboBox' || controlConfig.ControlType == 'MultiselectBox') {
                        //throw 'Asiganar dataSource no implementado'

                        if (scope.configs['Config' + controlConfig.Name]) {
                            setTimeout(() => {
                                let _config = JSON.parse(valueToSet);
                                scope.configs['Config' + controlConfig.Name].Context.SetConfig(_config);
                                scope.configs['Config' + controlConfig.Name] = _config;
                                scope.configs['Config' + controlConfig.Name].Context.CargarItemsComboBox(null, true, null, false);
                            }, 100);
                        }

                    }
                    else {
                        controlConfig = JSON.parse(valueToSet);
                    }
                    break;
                case 'configcontrol':
                    controlConfig.ConfigControl = JSON.parse(valueToSet);
                    break;
                case 'configprop':
                    let dataProp = valueToSet.split('|');
                    controlConfig[dataProp[0]] = dataProp[1];
                    break;
                case 'configpropjson':
                    let dataPropJson = valueToSet.split('|');
                    controlConfig[dataPropJson[0]] = JSON.parse(dataPropJson[1]);
                    break;
                //
                case 'value':
                    //control.val(reglaData[2]);
                    if (controlConfig.ControlType == 'ComboBox' || controlConfig.ControlType == 'ComboBox') {

                    }
                    if (form) {
                        ctr = form.controls[controlConfig.Name];
                        if (ctr) {
                            ctr.setValue(valueToSet);

                        }


                    }
                    else {
                        setTimeout(() => {
                            if (isModelContainer)
                                scope.modelo[controlConfig.ModelContainer][controlConfig.Name] = valueToSet;
                            else
                                scope[controlConfig.ModelContainer][controlConfig.Name] = valueToSet;
                        }, 100);
                    }

                    break;
                case 'text':
                    //control.val(reglaData[2]);
                    $label.text(valueToSet);
                    break;
                case 'title':
                    $control.attr("title", valueToSet);
                    break;
                case 'placeholder':
                    $control.attr("placeholder", valueToSet);
                    break;

            }
        }
        function SetValueToVar(objectValue: any, value: any, dataType: string) {


            let data = { Data: value, DataType: dataType };

            if (objectValue.ObjectType == 'StaticVar') {

                that.utility.SetCache(objectValue.Object, data);

            }
            else if (objectValue.ObjectType == 'ContextVar') {

                scope[objectValue.Object] = data;
            }
            else if (objectValue.ObjectType == 'LocalStorageVar') {

                localStorage.setItem(objectValue.Object, JSON.stringify(data));

            }
            else if (objectValue.ObjectType == 'SessionStorageVar') {

                sessionStorage.setItem(objectValue.Object, JSON.stringify(data));
            }

        }
        function SetValueToModel(objectValue: any, value: any, dataType: string) {


            let data = { Data: value, DataType: dataType };
            if (objectValue.ObjectType == 'Model') {
                //SetValueFromPropList
                let model = SplitPropertyToModel(objectValue.Property);
                if (objectValue.Object == "currentItem") {

                    if (model.listProps && model.listProps.length > 0) {
                        let control = GetControlConfig(model.listProps[0]);
                        if (control) {
                            if (model.listProps.length == 1)
                                control.ExtraData = value;
                            else
                                control[model.listProps[1]] = value;
                        }
                    }
                    else
                        throw new Error("No Hay Opcion para Asignar Valor Modelo");

                }
                else if (objectValue.Object == "selectedItems") {

                    if (model.listProps && model.listProps.length > 0) {
                        let componentsContainer: ComponentsContainer = scope.componentsContainer;
                        if (model.multiProps) {
                            componentsContainer.Tables[0].GetSelectedItems().forEach(item => {
                                for (let i = 0; i < model.multiProps.length; i++) {
                                    item[model.multiProps[i]] = value.multiProps[i].value;
                                }
                            });
                        }
                        else {
                            componentsContainer.Tables[0].GetSelectedItems().forEach(item => {
                                SetValueFromPropList(item, model.listProps, value);
                            });
                        }

                    }
                    else
                        throw new Error("No Hay Opcion para Asignar Valor Modelo");

                }
                else if (objectValue.Object == "staticModel") {
                    SetValueFromPropList(that.utility, model.listProps, value);
                    // if (model.property)
                    //     this.utility[model.model][model.property] = value;
                    // else if (model.model)
                    //     this.utility[model.model] = value;

                }
                else if (objectValue.Object == "staticDataModel") {
                    SetValueFromPropList(StaticData, model.listProps, value);

                }
                else if (objectValue.Object == "formModel") {

                    SetValueFromPropList(form.controls, model.listProps, value);
                }
                else if (objectValue.Object == "formInternalModel") {

                    SetValueFromPropList(form, model.listProps, value);
                }
                else if (objectValue.Object == "queryParameters") {
                    if (scope.QueryConfig) {
                        SetValueFromPropList(scope.QueryConfig.Entity, model.listProps, value);

                    }
                    else
                        throw new Error("No Hay Opcion para Asignar Valor Modelo");

                }
                else if (objectValue.Object == "queryConfig") {
                    if (scope.QueryConfig) {
                        SetValueFromPropList(scope.QueryConfig, model.listProps, value);

                    }
                    else
                        throw new Error("No Hay Opcion para Asignar Valor Modelo");

                }
                else if (objectValue.Object == "modeloInterno") {
                    SetValueFromPropList(scope, model.listProps, value);


                }
                else {

                    if (!objectValue.Object)
                        SetValueFromPropList(scope, model.listProps, value);
                    else
                        SetValueFromPropList(scope[objectValue.Object], model.listProps, value);

                }

            }

        }
        function ExecuteActionValuesToSet(action, controlConfig, event, rule) {


            if (!action)
                return;
            if (action.ValuesToSet) {
                that.utility.logger.LogInfoText("ExecuteActionValuesToSet", action.ValuesToSet);
                if (action.ValuesToSet.length == 0)
                    return;
                $.each(action.ValuesToSet, (key, valueToSet) => {

                    that.utility.logger.LogInfoText("ExecuteActionValuesToSet", valueToSet);
                    ExecuteActionValueToSet(valueToSet, controlConfig, event, rule, null);

                });
            }
            else if (action.ActionValuesToSet) {
                that.utility.logger.LogInfoText("ExecuteActionValuesToSet", action.ActionValuesToSet);
                if (action.ActionValuesToSet.length == 0)
                    return;
                $.each(action.ActionValuesToSet, (key, valueToSet) => {

                    that.utility.logger.LogInfoText("ExecuteActionValuesToSet", valueToSet);
                    ExecuteActionValueToSet(valueToSet, controlConfig, event, rule, null);

                });
            }
        }



        function ExecuteActionValueToSet(valueToSet, _controlConfig, event, rule, dtt: any) {


            if (!valueToSet)
                return;



            that.utility.logger.LogInfoText("ExecuteActionValueToSet", valueToSet);
            let controlConfig = null;
            let _valueToSet = null;
            let property: any = valueToSet.Charcater;
            if (valueToSet.LeftObject.ObjectType == 'Control' || valueToSet.LeftObject.ObjectType == 'Property') {
                controlConfig = GetControlConfig(valueToSet.LeftObject.Object);
                property = valueToSet.LeftObject.Property;
                if (dtt && valueToSet.RightProperty == 'dataSource')
                    _valueToSet = dtt;
                else
                    _valueToSet = GetGeneralValueFromObject(valueToSet.RightObject, dtt);
            }
            else if (valueToSet.RightObject.ObjectType == 'Control' || valueToSet.RightObject.ObjectType == 'Property') {
                controlConfig = GetControlConfig(valueToSet.RightObject.Object);
                property = valueToSet.RightObject.Property;
                if (dtt && valueToSet.LeftProperty == 'dataSource')
                    _valueToSet = dtt;
                else
                    valueToSet = GetGeneralValueFromObject(valueToSet.LeftObject, dtt);
            }

            if (controlConfig) {

                let $control = $('#' + controlConfig.Name);
                let $label = $("label[for='" + controlConfig.Name + "']")

                SetControlValue($control, $label, controlConfig, _valueToSet, property);
            }
            else {

                if (valueToSet.LeftObject.ObjectType == 'Model') {

                    _valueToSet = GetGeneralValueFromObject(valueToSet.RightObject, dtt);
                    SetValueToModel(valueToSet.LeftObject, _valueToSet, valueToSet.DataType)
                }
                else if (valueToSet.RightObject.ObjectType == 'Model') {

                    valueToSet = GetGeneralValueFromObject(valueToSet.LeftObject, dtt);
                    SetValueToVar(valueToSet.RightObject, _valueToSet, valueToSet.DataType)
                }
                else if (that.utility.IsVar(valueToSet.LeftObject.ObjectType)) {

                    _valueToSet = GetGeneralValueFromObject(valueToSet.RightObject, dtt);
                    SetValueToVar(valueToSet.LeftObject, _valueToSet, valueToSet.DataType)
                }
                else if (that.utility.IsVar(valueToSet.RightObject.ObjectType)) {

                    valueToSet = GetGeneralValueFromObject(valueToSet.LeftObject, dtt);
                    SetValueToVar(valueToSet.RightObject, _valueToSet, valueToSet.DataType)
                }
                else
                    throw new Error("No se encontro opcion para accion de asignacion")
            }
            //let value = GetGeneralValueFromObject(valueToSet.LeftObject,valueToSet.LeftProperty, valueToSet.LeftObject.ObjectType, null);

            // let valueToCompare = GetGeneralValueFromObject(valueToSet.RightObject,valueToSet.RightProperty, valueToSet.RightObject.ObjectType, null);

            // if (!controlConfig)
            //    throw `No existe el control '${valueToSet.Property}' en la Pagina '${pagina.IdPagina}','${pagina.Pagina}'`;



            // _valueToSet = (valueToSet.Field) ? GetValueControlByName(valueToSet.Field) :
            //    getValueFromConditionObject(valueToSet.Value);



        }
        function ExecuteActionQueryInternal(action, _controlConfig, event, rule, dataQuery, callback) {

            let cumple = true;
            let cumplimientosGenerales = [];
            let querys = '';
            let count = 0;

            $.each(dataQuery, (key, dttTemp) => {

                let dtt = dttTemp.Data;
                let query = dttTemp.Query;// condition.ConditionsQuerys[count];

                if (query.ValuesToSet.length > 0 && dtt && dtt.length > 0) {

                    $.each(query.ValuesToSet, (key, valueToSet) => {

                        that.utility.logger.LogInfoText("ExecuteActionQueryInternal", valueToSet);

                        ExecuteActionValueToSet(valueToSet, _controlConfig, event, rule, dtt);
                    });
                }

            });
            if (callback)
                callback();

        }
        function ExecuteActionQuery(action, controlConfig, event, rule, callback) {


            let querys = '';
            let dataQuery = [];
            let whithRunGeneral = false;
            let include = [];
            for (let i = 0; i < action.ActionQuerys.length; i++) {



                let temp = GetDataQueryFromMemory(action.ActionQuerys[i], dataQuery, include);
                if (temp) {
                    whithRunGeneral = true;
                    if (i < action.ActionQuerys.length - 1)
                        querys += action.ActionQuerys[i].Name + ',';
                    else
                        querys += action.ActionQuerys[i].Name;


                }
                /*   if (scope.ContainerData[action.ActionQuerys[i].Name]) {

                      dataQuery.push({ Query: action.ActionQuerys[i], Data: scope.ContainerData[action.ActionQuerys[i].Name], Index: i });
                      that.utility.logger.LogInfoText('ExecuteActionQuery Existe en container ' + action.ActionQuerys[i].Name);
                  }
                  else {
                      whithRunGeneral = true;
                      if (i < action.ActionQuerys.length - 1)
                          querys += action.ActionQuerys[i].Name + ',';
                      else
                          querys += action.ActionQuerys[i].Name;

                      include.push(action.ActionQuerys[i].Name);
                      dataQuery.push({ Query: action.ActionQuerys[i], Data: null, Index: i });
                  } */
            }
            if (whithRunGeneral == true) {

                let modelo = (isModelContainer) ? scope.modelo.modelo : scope.modelo;
                let obj = that.utility.GetApiModel('RunRules', controllerName, null, null, modelo);
                obj.Include = include;
                obj.ExecutionObject = GetExecutionObject(rule, controlConfig, event, 'Action', include, null, null);// rule + ';Action;' + controlConfig.Name + ';' + event.Name;


                that.baseService.RunRules(obj, {
                    componentName: controllerName,
                    processMessage: 'Cargando Entidades',
                    method: 'ConsultarEntidades'
                }, controllerName, false, false).then(data => {

                    let count = 0;

                    $.each(data.Data, (key, dttTemp) => {

                        let dtt = (dttTemp.Data) ? dttTemp.Data : dttTemp;
                        $.each(dataQuery, (_key, query) => {

                            if (query.Query.Name == key || query.Query.Name == dttTemp.Name) {
                                query.Data = dtt;
                                SetDataQueryToMemory(query, dtt);
                                // if (query.SetInMemory == true) {
                                //     that.utility.logger.LogInfoText('ExecuteActionQuery Set en container ' + query.Name);
                                //     scope.ContainerData[query.Query.Name] = dtt;
                                // }
                            }
                        });
                        count++;
                    });

                    count = 0;

                    ExecuteActionQueryInternal(action, controlConfig, event, rule, dataQuery, callback);

                });


                //runGeneral(querys, 'RunQuery', function (data) {


                //})
            }
            else
                ExecuteActionQueryInternal(action, controlConfig, event, rule, dataQuery, callback);



        }
        function ExecuteActionSingleQuery(actionQuery, controlConfig, event, rule, callback) {


            let querys = actionQuery.Name;
            let dataQuery = [];
            let whithRunGeneral = false;
            let include = [];


            whithRunGeneral = GetDataQueryFromMemory(actionQuery, dataQuery, include);
            // if (scope.ContainerData[actionQuery.Name]) {

            //     dataQuery.push({ Query: actionQuery, Data: scope.ContainerData[actionQuery.Name], Index: 0 });
            //     that.utility.logger.LogInfoText('ExecuteActionQuery Existe en container ' + actionQuery.Name);
            // }
            // else {
            //     whithRunGeneral = true;

            //     include.push(actionQuery.Name);
            //     dataQuery.push({ Query: actionQuery, Data: null, Index: 0 });
            // }

            if (whithRunGeneral == true) {

                let modelo = (isModelContainer) ? scope.modelo.modelo : scope.modelo;
                let obj = that.utility.GetApiModel('RunRules', controllerName, null, null, modelo);
                //obj.Include = include;
                obj.ExecutionObject = GetExecutionObject(rule, controlConfig, event, 'Action', include, actionQuery, null);// rule + ';Action;' + controlConfig.Name + ';' + event.Name;


                that.baseService.RunRules(obj, {
                    componentName: controllerName,
                    processMessage: 'ExecuteActionSingleQuery',
                    method: 'ExecuteActionSingleQuery'
                }, controllerName, false, false).then(data => {

                    let count = 0;

                    $.each(data.Data, (key, dttTemp) => {

                        let dtt = (dttTemp.Data) ? dttTemp.Data : dttTemp;
                        $.each(dataQuery, (_key, query) => {

                            if (query.Query.Name == key || query.Query.Name == dttTemp.Name) {
                                query.Data = dtt;
                                SetDataQueryToMemory(actionQuery, dtt);
                                // if (query.SetInMemory == true) {
                                //     that.utility.logger.LogInfoText('ExecuteActionSingleQuery Set en container ' + query.Name);
                                //     scope.ContainerData[query.Query.Name] = dtt;
                                // }
                            }
                        });
                        count++;
                    });

                    count = 0;

                    ExecuteActionQueryInternal(actionQuery, controlConfig, event, rule, dataQuery, callback);

                });

            }
            else
                ExecuteActionQueryInternal(actionQuery, controlConfig, event, rule, dataQuery, callback);



        }

        function ExecuteActionMail(actionMail, controlConfig, event, rule, callback, action) {

            let include: Array<any> = [];
            if (action) {
                for (let i = 0; i < action.RulesEmails.length; i++) {

                    include.push({
                        Name: action.RulesEmails[i].Name,
                        GUID: action.RulesEmails[i].GUID,
                        ObjectType: action.RulesEmails[i].ObjectType
                    });
                }
            } else
                include.push({
                    Name: actionMail.Name,
                    GUID: actionMail.GUID,
                    ObjectType: actionMail.ObjectType
                });
            let modelo = (isModelContainer) ? scope.modelo.modelo : scope.modelo;
            let obj = that.utility.GetApiModel('RunRules', controllerName, null, null, modelo);
            //obj.Include = include;
            obj.ExecutionObject = GetExecutionObject(rule, controlConfig, event, 'Mail', include, actionMail, null);// { rule: rule, ObjectType: 'Mail', ControlName: controlConfig.Name, EventName: event.Name } //rule + ';Mail;' + controlConfig.Name + ';' + event.Name;

            that.baseService.RunRules(obj, {
                componentName: controllerName,
                processMessage: 'Ejecutando Reglas',
                method: 'RunRules'
            }, controllerName, false, false).then(data => {


            });


        }


        function ExecuteActionTemplate(actionTemplate, controlConfig, event, rule, callback, action) {

            let include: Array<any> = [];
            if (action) {
                for (let i = 0; i < action.ActionTemplates.length; i++) {

                    include.push({
                        Name: action.ActionTemplates[i].Name,
                        GUID: action.ActionTemplates[i].GUID,
                        ObjectType: action.ActionTemplates[i].ObjectType
                    });
                }
            } else
                include.push({
                    Name: actionTemplate.Name,
                    GUID: actionTemplate.GUID,
                    ObjectType: actionTemplate.ObjectType
                });
            let modelo = (isModelContainer) ? scope.modelo.modelo : scope.modelo;
            let obj = that.utility.GetApiModel('RunRules', controllerName, null, null, modelo);
            //obj.Include = include;
            obj.ExecutionObject = GetExecutionObject(rule, controlConfig, event, 'Template', include, actionTemplate, null);// { rule: rule, ObjectType: 'Mail', ControlName: controlConfig.Name, EventName: event.Name } //rule + ';Mail;' + controlConfig.Name + ';' + event.Name;

            that.baseService.RunRules(obj, {
                componentName: controllerName,
                processMessage: 'Ejecutando Reglas',
                method: 'RunRules'
            }, controllerName, false, false).then(data => {


            });


        }
        function ExecuteAction(action, controlConfig, event, rule, callback) {

            if (action) {
                if (action.ExitCurrentOperation == true) {

                    throw "Salida de la operacion por Accion configurada";
                }

                ExecuteActionPreScript(action, controlConfig, event, rule, null);
                ExecuteActionValuesToSet(action, controlConfig, event, rule);
                ExecuteActionQuery(action, controlConfig, event, rule, null);
                ExecuteActionNotification(action, controlConfig, event, rule, null);
                ExecuteActionPostScript(action, controlConfig, event, rule, null)
                ExecuteActionMail(null, controlConfig, event, rule, callback, action);

                if (action.ExecuteAndExitCurrentOp == true) {

                    throw "Ejecutar y Salida de la operacion por Accion configurada";
                }
            }

            if (callback)
                callback();

        }

        function ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex: number, callback, especificModel?: any) {


            if (action) {




                if (action.ExitCurrentOperation == true) {

                    throw "Salida de la operacion por Accion configurada";
                }
                if (currentIndex < action.ActionsOrder.length) {


                    let actionOrder: any = action.ActionsOrder[currentIndex];
                    if (controlConfig.Pagina)
                        that.utility.logger.LogInfoText(`Ejecutando Accion '${actionOrder.GUID}', Pagina:'${controlConfig.Pagina}', en el evento:'${event.Name}', Tipo:'${actionOrder.ObjectType}' `);
                    else
                        that.utility.logger.LogInfoText(`Ejecutando Accion '${actionOrder.GUID}', Control'${controlConfig.Name}', en el evento:'${event.Name}', Tipo:'${actionOrder.ObjectType}' `);


                    currentIndex++;
                    let currentAction: any;
                    switch (actionOrder.ObjectType) {

                        case 'ActionValue':
                            currentAction = action.ActionValuesToSet.find(x => x.GUID == actionOrder.GUID);
                            ExecuteActionValueToSet(currentAction, controlConfig, event, rule, null);
                            ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                            break;
                        case 'ActionNotification':
                        case 'ActionMessageBox':
                            currentAction = action.ActionNotifications.find(x => x.GUID == actionOrder.GUID);
                            if (currentAction.Source == null) {

                                setTimeout(() => {
                                    CreateNotification(currentAction, controlConfig, event, callback);
                                    ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                                }, 100);


                            }
                            else
                                ExecuteActionNotificationSingleQuery(currentAction, controlConfig, event, rule, (resulQuery) => {
                                    ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                                }, especificModel);
                            break;

                        case 'ActionScript':
                            currentAction = action.ActionScripts.find(x => x.GUID == actionOrder.GUID);
                            ExecuteGeneralScript(currentAction.ExecuteByEval, currentAction.Script, controlConfig, event, rule, null);
                            ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                            break;
                        case 'ActionMail':
                            currentAction = action.ActionMails.find(x => x.GUID == actionOrder.GUID);
                            ExecuteActionMail(currentAction, controlConfig, event, rule, null, action);
                            ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                            break;
                        case 'ActionTemplate':
                            currentAction = action.ActionTemplates.find(x => x.GUID == actionOrder.GUID);
                            ExecuteActionTemplate(currentAction, controlConfig, event, rule, null, action);
                            ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                            break;
                        case 'ActionQuery':
                            currentAction = action.ActionQuerys.find(x => x.GUID == actionOrder.GUID);
                            ExecuteActionSingleQuery(currentAction, controlConfig, event, rule, (resulQuery) => {
                                ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                            })
                            break;
                        case 'ActionCondition':
                            currentAction = action.ActionConditions.find(x => x.GUID == actionOrder.GUID);
                            currentAction.rule = currentAction.GUID;
                            ExecuteRuleIternal(currentAction, controlConfig, event, (resulQuery) => {
                                ExecuteActionsOrder(action, controlConfig, event, rule, currentIndex, callback);
                            })
                            break;
                        case 'ActionForm':
                            currentAction = action.ActionForms.find(x => x.GUID == actionOrder.GUID);

                            break;
                        case 'ActionRule':
                            currentAction = action.ActionRules.find(x => x.GUID == actionOrder.GUID);

                            break;
                        case 'ActionTaskScheduler':
                            currentAction = action.ActionTaskSchedulers.find(x => x.GUID == actionOrder.GUID);

                            break;

                        case 'ActionApprovalTask':
                            currentAction = action.ActionApprovalTasks.find(x => x.GUID == actionOrder.GUID);

                            break;

                    }

                }
                else {
                    if (callback)
                        callback();
                }
                /*  let executedQueries: Array<any> = [];
                 let ValuesToSet = new List<any>(action.ActionValuesToSet);
                 let Querys = new List<any>(action.ActionQuerys);
                 let RulesEmails = new List<any>(action.RulesEmails);
                 let Notifications = new List<any>(action.Notifications);
                 let ActionConditions = new List<any>(action.ActionConditions);
                 let Scripts = new List<any>(action.Scripts); */

                // action.ActionsOrder.forEach(actionOrder => {

                //     let currentAction: any;
                //     switch (actionOrder.ObjectType) {

                //         case 'ActionValue':
                //             currentAction = action.ActionValuesToSet.find(x => x.GUID == actionOrder.GUID);
                //             ExecuteActionValueToSet(currentAction, controlConfig, event, rule);
                //             break;
                //         case 'ActionNotification':
                //         case 'ActionMessageBox':
                //             currentAction = action.Notifications.find(x => x.GUID == actionOrder.GUID);
                //             if (currentAction.Source == null)
                //                 CreateNotification(currentAction);
                //             else
                //                 ExecuteActionNotificationSingleQuery(currentAction, controlConfig, event, rule, null);
                //             break;

                //         case 'ActionScript':
                //             currentAction = action.Scripts.find(x => x.GUID == actionOrder.GUID);
                //             ExecuteGeneralScript(currentAction.Script, controlConfig, event, rule, null);
                //             break;
                //         case 'ActionMail':
                //             currentAction = action.RulesEmails.find(x => x.GUID == actionOrder.GUID);
                //             break;
                //         case 'ActionQuery':
                //             currentAction = action.ActionQuerys.find(x => x.GUID == actionOrder.GUID);
                //             ExecuteActionSingleQuery(currentAction, controlConfig, event, rule, (resulQuery) => {

                //             })
                //             break;
                //         case 'ActionCondition':
                //             currentAction = action.Notifications.find(x => x.GUID == actionOrder.GUID);
                //             break;

                //     }



                // });


                if (action.ExecuteAndExitCurrentOp == true) {

                    throw "Ejecutar y Salida de la operacion por Accion configurada";
                }
            }



        }


        function CreateNotification(_actionNotification, controlConfig, event, callback) {

            that.utility.logger.LogInfoText('CreateNotification ', _actionNotification);


            if (_actionNotification.ObjectType == 'ActionMessageBox') {

                let modalModel = { titulo: _actionNotification.Title, descripcion: _actionNotification.Message };
                switch (_actionNotification.Type) {

                    case 'info':
                        that.utility.VerModalInfo(modalModel).then(result => { }).catch(err => {
                            that.utility.logger.LogError(err, _actionNotification,
                                { componentName: controllerName, method: 'CreateNotification' });
                        });
                    case 'warning':
                        that.utility.VerModalWarning(modalModel).then(result => { }).catch(err => {
                            that.utility.logger.LogError(err, _actionNotification,
                                { componentName: controllerName, method: 'CreateNotification' });
                        });
                        break;
                    case 'success':
                        that.utility.VerModalSuccess(modalModel).then(result => { }).catch(err => {
                            that.utility.logger.LogError(err, _actionNotification,
                                { componentName: controllerName, method: 'CreateNotification' });
                        });
                        break;
                    case 'error':
                        that.utility.VerModalDanger(modalModel).then(result => { }).catch(err => {
                            that.utility.logger.LogError(err, _actionNotification,
                                { componentName: controllerName, method: 'CreateNotification' });
                        });
                        break;
                    case 'question':
                    case 'question-warning':
                    case '"question-danger':
                        let tempModalNotifyTypes = ModalNotifyTypes.Question;
                        if (_actionNotification.Type == 'question-warning')
                            tempModalNotifyTypes = ModalNotifyTypes.QuestionWarning;
                        else if (_actionNotification.Type == 'question-danger')
                            tempModalNotifyTypes = ModalNotifyTypes.QuestionDanger;
                        that.utility.VerModalConfirmacionGeneral(modalModel.titulo, modalModel.descripcion, tempModalNotifyTypes).then(result => {



                            if (result == true) {
                                if (_actionNotification.ActionOk) {
                                    _actionNotification.ActionOk.GUID = _actionNotification.ActionOk.GUID;
                                    ExecuteRuleIternal(_actionNotification.ActionOk, controlConfig, event, null);
                                }
                            }
                            else {
                                if (_actionNotification.ActionCancel) {
                                    _actionNotification.ActionCancel.GUID = _actionNotification.ActionCancel.GUID;
                                    ExecuteRuleIternal(_actionNotification.ActionCancel, controlConfig, event, null);
                                }
                            }

                        }).catch(err => {
                            that.utility.logger.LogError(err, _actionNotification,
                                { componentName: controllerName, method: 'CreateNotification' });
                        });
                        break;
                }
            }
            else {
                let actionNotification: any = that.utility.Clone(_actionNotification);
                actionNotification.Delay = (!_actionNotification.Delay) ? null : parseInt(_actionNotification.Delay) * 1000;
                actionNotification.PositionY = (_actionNotification.PositionY == 'none') ? undefined : _actionNotification.PositionY;
                actionNotification.PositionX = (_actionNotification.PositionX == 'none') ? undefined : _actionNotification.PositionX;
                actionNotification.Type = (_actionNotification.Type == 'none' || !_actionNotification.Type) ? 'info' : _actionNotification.Type;

                StaticData.ShowActionNotification(actionNotification.Message,
                    actionNotification.Type,
                    actionNotification.PositionY,
                    actionNotification.PositionX,
                    actionNotification.Title,
                    actionNotification.Delay,
                    actionNotification.Url,
                    actionNotification.Target);
            }

        }

        function ExecuteActionNotificationQueryInternal(action, controlConfig, event, rule, dataQuery, callback) {

            let cumple = true;
            let cumplimientosGenerales = [];
            let querys = '';

            let count = 0;

            $.each(dataQuery, (key, dttTemp) => {

                let dtt = dttTemp.Data;
                let _actionNotification = dttTemp.Query;// condition.ConditionsQuerys[count];

                if (dtt && dtt.length > 0) {

                    $.each(dtt, (key, row) => {

                        that.utility.logger.LogInfoText("ExecuteNotificationQueryInternal", row);


                        let actionNotification = {
                            Title: (row[_actionNotification.Title]) ? row[_actionNotification.Title] : _actionNotification.Title,
                            Message: (row[_actionNotification.Message]) ? row[_actionNotification.Message] : _actionNotification.Message,
                            Delay: (!row['Duracion']) ? null : parseInt(row['Duracion']) * 1000,
                            PositionY: (!row['PosicionY'] || row['PosicionY'] == 'none') ? undefined : row['PosicionY'],
                            PositionX: (!row['PosicionX'] || row['PosicionX'] == 'none') ? undefined : row['PosicionX'],
                            Type: (!row['Tipo'] || row['Tipo'] == 'none') ? undefined : row['Tipo'],
                        };

                        CreateNotification(actionNotification, controlConfig, event, callback);


                    });
                }

            });
            if (callback)
                callback();

        }
        function ExecuteActionNotificationQuery(action, controlConfig, event, rule, callback) {


            let querys = '';
            let dataQuery = [];
            let whithRunGeneral = false;
            let include = [];
            let actionQuerys = new List<any>(action.Notifications).Where(x => { return x.Source != null }).ToArray();
            for (let i = 0; i < actionQuerys.length; i++) {


                let temp = GetDataQueryFromMemory(actionQuerys[i], dataQuery, include);
                if (temp) {
                    whithRunGeneral = true;
                    if (i < actionQuerys.length - 1)
                        querys += actionQuerys[i].Name + ',';
                    else
                        querys += actionQuerys[i].Name;
                }
                // if (scope.ContainerData[actionQuerys[i].Name]) {

                //     dataQuery.push({ Query: actionQuerys[i], Data: scope.ContainerData[actionQuerys[i].Name], Index: i });
                //     that.utility.logger.LogInfoText('ExecuteNotificationQuery Existe en container ' + actionQuerys[i].Name);
                // }
                // else {
                //     whithRunGeneral = true;
                //     if (i < actionQuerys.length - 1)
                //         querys += actionQuerys[i].Name + ',';
                //     else
                //         querys += actionQuerys[i].Name;

                //     include.push(actionQuerys[i].Name);
                //     dataQuery.push({ Query: actionQuerys[i], Data: null, Index: i });
                // }
            }
            if (whithRunGeneral == true) {
                let modelo = (isModelContainer) ? scope.modelo.modelo : scope.modelo;
                let obj = that.utility.GetApiModel('RunRules', controllerName, null, null, modelo);
                // obj.Include = include;
                obj.ExecutionObject = GetExecutionObject(rule, controlConfig, event, 'Notification', include, null, null);// rule + ';Notification;' + controlConfig.Name + ';' + event.Name;

                that.baseService.RunRules(obj, {
                    componentName: controllerName,
                    processMessage: 'Ejecutando Reglas',
                    method: 'RunRules'
                }, controllerName, false, false).then(data => {

                    let count = 0;

                    $.each(data.Data, (key, dttTemp) => {

                        let dtt = (dttTemp.Data) ? dttTemp.Data : dttTemp;
                        $.each(dataQuery, (_key, query) => {

                            if (query.Query.Name == key || query.Query.Name == dttTemp.Name) {
                                query.Data = dtt;
                                SetDataQueryToMemory(query, dtt);
                                // if (query.SetInMemory == true) {
                                //     that.utility.logger.LogInfoText('ExecuteNotificationQuery Set en container ' + query.Name);
                                //     scope.ContainerData[query.Query.Name] = dtt;
                                // }
                            }
                        });
                        count++;
                    });

                    count = 0;

                    ExecuteActionNotificationQueryInternal(action, controlConfig, event, rule, dataQuery, callback);


                });


                //runGeneral(querys, 'RunQuery', function (data) {


                //})
            }
            else
                ExecuteActionNotificationQueryInternal(action, controlConfig, event, rule, dataQuery, callback);



        }

        function ExecuteActionNotificationSingleQuery(actionQuery, controlConfig, event, rule, callback, especificModel?: any) {


            let querys = actionQuery.Name;
            let dataQuery = [];
            let whithRunGeneral = false;
            let include = [];

            whithRunGeneral = GetDataQueryFromMemory(actionQuery, dataQuery, include);

            // if (scope.ContainerData[actionQuery.Name]) {

            //     dataQuery.push({ Query: actionQuery, Data: scope.ContainerData[actionQuery.Name], Index: 0 });
            //     that.utility.logger.LogInfoText('ExecuteActionNotificationSingleQuery Existe en container ' + actionQuery.Name);
            // }
            // else {
            //     whithRunGeneral = true;

            //     include.push(actionQuery.Name);
            //     dataQuery.push({ Query: actionQuery, Data: null, Index: 0 });
            // }
            if (whithRunGeneral == true) {
                let modelo = (isModelContainer) ? scope.modelo.modelo : scope.modelo;
                let obj = that.utility.GetApiModel('RunRules', controllerName, null, null, modelo);
                //obj.Include = include;
                obj.ExecutionObject = GetExecutionObject(rule, controlConfig, event, 'Notification', include, actionQuery, null);// rule + ';Notification;' + controlConfig.Name + ';' + event.Name;

                if (especificModel) {
                    if (especificModel.Model)
                        obj.ExecutionObject.Model = especificModel.Model;
                    if (especificModel.ExtraObject)
                        obj.ExecutionObject.ExtraObject = especificModel.ExtraObject;
                }
                that.baseService.RunRules(obj, {
                    componentName: controllerName,
                    processMessage: 'Ejecutando Reglas',
                    method: 'RunRules'
                }, controllerName, false, false).then(data => {

                    let count = 0;

                    $.each(data.Data, (key, dttTemp) => {

                        let dtt = (dttTemp.Data) ? dttTemp.Data : dttTemp;
                        $.each(dataQuery, (_key, query) => {

                            if (query.Query.Name == key || query.Query.Name == dttTemp.Name) {
                                query.Data = dtt;
                                SetDataQueryToMemory(query, dtt);
                                // if (query.SetInMemory == true) {
                                //     that.utility.logger.LogInfoText('ExecuteNotificationQuery Set en container ' + query.Name);
                                //     scope.ContainerData[query.Query.Name] = dtt;
                                // }
                            }
                        });
                        count++;
                    });

                    count = 0;

                    ExecuteActionNotificationQueryInternal(actionQuery, controlConfig, event, rule, dataQuery, callback);


                });


                //runGeneral(querys, 'RunQuery', function (data) {


                //})
            }
            else
                ExecuteActionNotificationQueryInternal(actionQuery, controlConfig, event, rule, dataQuery, callback);



        }

        function ExecuteActionNotification(action, controlConfig, event, rule, callback) {


            let querys = '';
            let dataQuery = [];
            let whithRunGeneral = false;
            let include = [];
            let notifications = new List<any>(action.Notifications).Where(x => { return x.Source == null }).ToArray();
            for (let i = 0; i < notifications.length; i++) {

                CreateNotification(notifications[i], controlConfig, event, callback);
            }

            ExecuteActionNotificationQuery(action, controlConfig, event, rule, callback)
        }
        function ExecuteActionPreScript(action, controlConfig, event, rule, callback) {


            if (action.PreScript) {
                let functionGeneric = new Function(action.PreScript);
                functionGeneric();
            }

        }
        function ExecuteActionPostScript(action, controlConfig, event, rule, callback) {


            if (action.PostScript) {
                let functionGeneric = new Function(action.PostScript);
                functionGeneric();
            }

        }
        // function ExecuteActionScript(isEval,actionScript, controlConfig, event, rule, callback) {
        //     let result = ExecuteGeneralScript(isEval,actionScript, controlConfig, event, rule, callback);

        //     return result;
        // }

        function ExecuteGeneralScript(isEval, script, controlConfig, event, rule, callback): any {
            let result = null;

            if (script) {
                if (isEval)
                    result = eval(script);
                else {
                    let functionGeneric = new Function(script);
                    result = functionGeneric();
                }
            }
            return result;
        }
        function ExecuteRule(rule, controlConfig, event, callback?: any, excludeAction?: boolean, especificModel?: any) {

            if (rule.Querys && rule.Querys != '' && rule.Querys.split(',').length > 0) {
                that.utility.logger.LogInfoText("ExecuteRule RunQuery generales ", rule.Querys);
                /*    runGeneral(rule.Querys, 'RunQuery', function (data) {

                       $.each(data.Data, function (key, dttTemp) {

                           let dtt = (dttTemp.Data) ? dttTemp.Data : dttTemp;
                           scope.ContainerData[dttTemp.Name] = dtt;

                       });
                       ExecuteRuleIternal(rule, controlConfig, event);
                   }); */
            }
            else {

                ExecuteRuleIternal(rule, controlConfig, event, callback, excludeAction, especificModel);
            }
        }

        function ExecuteRuleIternalIfElse(rule, controlConfig, event, callback, cumple: Boolean, especificModel?: any) {

            if (rule.RuleAction.RunQueriesFirst) {
                if (cumple)
                    ExecuteActionsOrder(rule.RuleAction, controlConfig, event, rule, 0, (resultExecuteAction: any) => {

                    }, especificModel);
                else
                    ExecuteActionsOrder(rule.RuleActionElse, controlConfig, event, rule, 0, (resultExecuteAction: any) => {

                    }, especificModel);

                if (callback)
                    callback(cumple);
            }
            else {
                if (cumple)
                    ExecuteActionsOrder(rule.RuleAction, controlConfig, event, rule, 0, (resultExecuteAction: any) => {
                        if (callback)
                            callback(cumple);

                    }, especificModel);
                else
                    ExecuteActionsOrder(rule.RuleActionElse, controlConfig, event, rule, 0, (resultExecuteAction: any) => {
                        if (callback)
                            callback(cumple);
                    }, especificModel);


            }
        }
        function ExecuteRuleIternal(rule, controlConfig, event, callback, excludeAction?: boolean, especificModel?: any) {

            let cumple = true;
            let resultExecuteCondition: any = {};
            let resultExecuteConditionScript: any = {};
            that.utility.logger.LogInfoText(`Ejecutando regla:'${rule.Name}',GUID:'${rule.GUID}', en el evento:'${event.Name}' `);
            if (rule.RuleCondition.ConditionsQuerys.length > 0) {

                ExecuteConditionQuery(rule.RuleCondition, controlConfig, event, rule, (resultExecuteConditionQuery: any) => {


                    resultExecuteCondition = ExecuteCondition(rule.RuleCondition, controlConfig, event, rule);
                    resultExecuteConditionScript = ExecuteConditionsScript(rule.RuleCondition, controlConfig, event, rule, null);

                    // if (resultExecuteConditionQuery.cumple && resultExecuteCondition.cumple && resultExecuteConditionScript.cumple)
                    //     cumple = true;
                    // else if (!resultExecuteConditionQuery.cumple && !resultExecuteCondition.cumple && !resultExecuteConditionScript.cumple)
                    //     cumple = false;
                    // else if (resultExecuteConditionQuery.containsOr || resultExecuteCondition.containsOr || resultExecuteConditionScript.containsOr)
                    //     cumple = (resultExecuteConditionQuery.cumple || resultExecuteCondition.cumple || resultExecuteConditionScript.cumple);
                    // else
                    //     cumple = false;

                    if (resultExecuteConditionQuery.containsOr || resultExecuteCondition.containsOr || resultExecuteConditionScript.containsOr)
                        cumple = (resultExecuteConditionQuery.cumple || resultExecuteCondition.cumple || resultExecuteConditionScript.cumple);
                    else
                        cumple = (resultExecuteConditionQuery.cumple && resultExecuteCondition.cumple && resultExecuteConditionScript.cumple);

                    if (excludeAction)
                        callback(rule, cumple);
                    else
                        ExecuteRuleIternalIfElse(rule, controlConfig, event, callback, cumple);

                }, especificModel);
            }
            else {

                resultExecuteCondition = ExecuteCondition(rule.RuleCondition, controlConfig, event, rule);
                resultExecuteConditionScript = ExecuteConditionsScript(rule.RuleCondition, controlConfig, event, rule, null);


                if (resultExecuteCondition.containsOr || resultExecuteConditionScript.containsOr)
                    //cumple = (resultExecuteCondition.cumple || resultExecuteConditionScript.cumple);
                    cumple = (resultExecuteCondition || resultExecuteConditionScript.cumple);
                else
                    //cumple = (resultExecuteCondition.cumple && resultExecuteConditionScript.cumple);
                    cumple = (resultExecuteCondition && resultExecuteConditionScript.cumple);

                if (excludeAction)
                    callback(rule, cumple);
                else
                    ExecuteRuleIternalIfElse(rule, controlConfig, event, callback, cumple);
                /*   if (cumple)
                      ExecuteAction(rule.RuleAction, controlConfig, event, rule);
                  //else
                  //    ExecuteAction(rule.RuleActionElse);

                  if (callback)
                      callback(cumple); */
            }
        }
        function ExecuteRules(rules: Array<any>, $control, $label, controlConfig, event, callback?: any, excludeAction?: boolean) {
            if (rules && rules.length > 0)
                if (controlConfig.Pagina)
                    that.utility.logger.LogInfoText(`Ejecutando reglas  para la Pagina '${controlConfig.Pagina}', en el evento:'${event.Name}' `);
                else
                    that.utility.logger.LogInfoText(`Ejecutando reglas  para el control '${controlConfig.Name}', en el evento:'${event.Name}' `);


            $.each(rules, (key, rule) => {
                ExecuteRule(rule, controlConfig, event, callback, excludeAction);
            });

        }


        function SetDataQueryToMemory(query, dtt) {
            if (query.SetInMemory == true) {
                that.utility.logger.LogInfoText('SetDataQueryToMemory asignar datos en memoria, en container ' + query.Name);
                scope.ContainerData[query.Query.Name] = dtt;
            }
            else if (query.CachingUiVar) {


                SetValueToVar(query.CachingUiVar, dtt, 'QueryResult');
            }
        }

        function GetDataQueryFromMemory(query, dataQuery: Array<any>, include: Array<any>) {

            let whithRunGeneral = false;

            let data = null;
            let nameVar: string;
            if (query.CachingUiVar) {

                let objectValue = {
                    Object: query.CachingUiVar.Name,
                    ObjectType: query.CachingUiVar.ObjectType, DataType: query.CachingUiVar.DataType
                };
                data = GetGeneralValueFromObject(objectValue, null, false);

                if (data) {
                    dataQuery.push({ Query: query, Data: data, Index: 0 });
                    that.utility.logger.LogInfoText('GetDataQuery Existe en contaila variable ' + nameVar + ' del tipo: ' + query.TypeVar);

                    return whithRunGeneral;
                }
            }
            if (scope.ContainerData[query.Name]) {

                dataQuery.push({ Query: query, Data: scope.ContainerData[query.Name], Index: 0 });
                that.utility.logger.LogInfoText('GetDataQuery Existe en container ' + query.Name);
            }
            else {
                whithRunGeneral = true;

                include.push({ Name: query.Name, GUID: query.GUID, ObjectType: query.ObjectType });
                dataQuery.push({ Query: query, Data: null, Index: 0 });
            }

            return whithRunGeneral;
        }

        function SetClientValues(value: any, ExecutionObject: any, prefixProp: string) {
            let _currentValue: any;
            let objectName = prefixProp + 'Object';
            if (that.utility.IsClientVar(value[objectName].ObjectType)) {
                _currentValue = GetGeneralValueFromObject(value[objectName], null, true);
                ExecutionObject.ObjectValues.push({
                    LeftObject: {
                        ObjectType: value[objectName].ObjectType,
                        Object: _currentValue,
                        DataType: value[objectName].DataType
                    },
                    Name: value[objectName],
                })
            }
        }
        function GetExecutionObject(rule, controlConfig, event, type, include, currentAction, coditionQuerys) {

            let ExecutionObject: any = {
                IsRule: true,
                GUID: rule.GUID,
                Name: rule.Name,
                ObjectType: 'Rule',// type,
                ControlName: controlConfig.Name,
                EventName: event.Name,
                ObjectsToRun: include,
                ObjectValues: []
            }
            if (currentAction) {

                currentAction.ValuesToReplace.forEach(_value => {

                    SetClientValues(_value, ExecutionObject, 'Left');
                    SetClientValues(_value, ExecutionObject, 'Right');

                });
                if (currentAction.ValuesToSet)
                    currentAction.ValuesToSet.forEach(_value => {

                        SetClientValues(_value, ExecutionObject, 'Left');
                        SetClientValues(_value, ExecutionObject, 'Right');

                    });
                if (currentAction.ActionValuesToSet)
                    currentAction.ActionValuesToSet.forEach(_value => {

                        SetClientValues(_value, ExecutionObject, 'Left');
                        SetClientValues(_value, ExecutionObject, 'Right');

                    });
                currentAction.TagsToReplace.forEach(_value => {

                    SetClientValues(_value, ExecutionObject, 'Left');
                    SetClientValues(_value, ExecutionObject, 'Right');

                });
            }
            if (coditionQuerys) {

                let _coditionQuerys = new List<any>(coditionQuerys).Where(x => {
                    return (new List<any>(x.ValuesToReplace).Any(c => {
                        return that.utility.IsClientVar(c.LeftObject.ObjectType)
                    })) || (new List<any>(x.ValuesToReplace).Any(c => {
                        return that.utility.IsClientVar(c.RightObject.ObjectType)
                    })) ||
                        (new List<any>(x.ValuesToCompare).Any(c => {
                            return that.utility.IsClientVar(c.LeftObject.ObjectType)
                        })) || (new List<any>(x.ValuesToCompare).Any(c => {
                            return that.utility.IsClientVar(c.RightObject.ObjectType)
                        })) ||
                        (new List<any>(x.TagsToReplace).Any(c => {
                            return that.utility.IsClientVar(c.LeftObject.ObjectType)
                        })) || (new List<any>(x.TagsToReplace).Any(c => {
                            return that.utility.IsClientVar(c.RightObject.ObjectType)
                        }))
                }).ToArray();
                let _currentValue: any;
                _coditionQuerys.forEach(query => {

                    query.ValuesToReplace.forEach(_value => {

                        SetClientValues(_value, ExecutionObject, 'Left');
                        SetClientValues(_value, ExecutionObject, 'Right');

                    });
                    query.ValuesToCompare.forEach(_value => {

                        SetClientValues(_value, ExecutionObject, 'Left');
                        SetClientValues(_value, ExecutionObject, 'Right');

                    });

                });

            }
            return ExecutionObject;
        }
        return {

            ExecuteRules: function (rules: Array<any>, $control, $label, controlConfig, event, callback?: any, excludeAction?: boolean, especificModel?: any) {
                ExecuteRules(rules, $control, $label, controlConfig, event)
            },
            ExecuteRule: function (rule, controlConfig, event, callback?: any, excludeAction?: boolean, especificModel?: any) {

                ExecuteRule(rule, controlConfig, event, callback, excludeAction, especificModel)
            },
            ExecuteRuleIternal: function (rule, controlConfig, event, callback?: any, excludeAction?: boolean) {
                ExecuteRuleIternal(rule, controlConfig, event, callback, excludeAction)
            },
            ExecuteCondition: function (condition, controlConfig, event, rule): any {
                return ExecuteCondition(condition, controlConfig, event, rule);
            },
            Compare: function (value, valueToCompare, op): any {
                return Compare(value, valueToCompare, op);
            },
            GetControlsRules: function (controlConfig): Array<any> {
                return GetControlsRules(controlConfig);
            },




        }
    };
}
