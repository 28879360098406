import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import {
  nodeTypes, TipoObjetoParaPermisos, TipoParaPermisos
} from '../../models/general.enum';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { BaseTextoSplit } from '../../models/base-texto-split';
import { HelperImages } from 'src/app/helpers/helper-images';


@Component({
  selector: 'app-palabras-clave',
  templateUrl: './palabras-clave.component.html',
  styleUrls: ['./palabras-clave.component.scss']
})
export class PalabrasClaveComponent extends BaseTextoSplit implements OnInit, AfterViewInit, OnDestroy {

  constructor(public utility: Utilities,public helperImages: HelperImages) {
    super(utility,helperImages);
  }
  public Limpiar() {
    this.items = [];
  }
}
