import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { StaticData } from '../helpers/static-data';

export class ConfigBotonesFormulario {
    botonProcesando: boolean = false;
    textoBoton: string = '';
    textoBotonProcesando: string = '';
}
export class BotonesFormularioBase implements OnInit {


    @Input() isDesigner: any;
    @Input() config: any;
    @Input() Pagina = { Valida: true, Estilos: StaticData.Estilos,BotonesComoMenu:null,Guardar:true,Exportar:true,Pagina:'' };

    @Input() form: FormGroup;
    @Input() componentName: string = '';
    //@Input() configProcesando:any =null;
    // @Input() botonProcesando:boolean =false;
    // @Input() textoBoton:string ='Guardar';
    // @Input() textoBotonProcesando:string ='';

    constructor() {

    }

    ngOnInit() {


    }

}