import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, forwardRef, EventEmitter, Inject, } from '@angular/core';
import { Utilities } from '../../../helpers/utilities';
import { BaseServiceService } from '../../../services/base-service.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multi-check-data-table',
  templateUrl: './multi-check-data-table.component.html',
  styleUrls: ['./multi-check-data-table.component.css']
})
export class MultiCheckDataTableComponent implements OnInit, OnDestroy {

  @Input() controlName: string;
  @Input() config: any = { columns: [], componentName: '' };
  dtOptions: any
  @Input() items: Array<any> = [];
  @Output() updateItemsEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() conFiltroIdEmpresa: boolean = false;
  @Input() componentName: string;
  Encabezados: any = {

  }
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities,
    @Inject(forwardRef(() => BaseServiceService)) public baseService: BaseServiceService) { }

  ngOnInit() {


    this._CargarGrilla();

  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    if (this.dtTrigger)
      this.dtTrigger.unsubscribe();
  }

  public Todo(campo: string, column: any, valor: any = null) {

    this.Encabezados[campo] = (column.forThreeState) ? valor : !this.Encabezados[campo];
    const that = this;
    $.each(this.items, function (i, item) {

      if (campo == that.config.propSeleccionada || (campo != that.config.propSeleccionada &&
        item[that.config.propSeleccionada] == true
        && item['Permite' + campo] == true)) {
        item[campo] = that.Encabezados[campo];
        console.log('Campo : ', campo);
        console.log('ItemActualizado : ', item);

      }
    });
    if (this.updateItemsEmitter.observers.length > 0)
      this.updateItemsEmitter.emit({ items: this.GetItemsSeleccionados(), campo: campo });
  }
  public GetItemsSeleccionados(): Array<any> {
    return new List<any>(this.items).Where(x => { return x[this.config.propSeleccionada] == true; }).ToArray();
  }

  public SetValorThreeState(event: any, index: any, item: any, column: any, value: any) {

    

    //$('.jtoggler-btn-wrapper').removeClass('is-active');
    // $(event.target).parent().addClass('is-active');
    if (value === 'true')
      value = true;
    else if (value === 'false')
      value = false;
    else if (value === 'null')
      value = null;
    this.SetValor(index, item, column, value);





  }
  public SetValor(index: any, item: any, column: any, value: any = undefined) {

    if ((column.withActionEmitter || this.config.withActionEmitter) && this.actionEmitter.observers.length > 0)
      this.actionEmitter.emit({ items: this.GetItemsSeleccionados(), column: column, item: item });
    else if (column.actionCallBack)
      column.actionCallBack((this.config.context) ? this.config.context : column.context, { items: this.GetItemsSeleccionados(), column: column, item: item });
    else if (this.config.actionCallBack)
      this.config.actionCallBack(this.config.context, { items: this.GetItemsSeleccionados(), column: column, item: item });
    else {
      if (value !== undefined)
        item[column.data] = value;
      else
        item[column.data] = !item[column.data];

      if (this.updateItemsEmitter.observers.length > 0)
        this.updateItemsEmitter.emit({ items: this.GetItemsSeleccionados(), campo: column.data, item: item });
    }
  }
  private _CargarGrilla(): void {

    const that = this;
    try {
      this.dtOptions = this.utility.GetDtOptionsHtmlTable();
      if (this.config.conFiltroIdEmpresa !== undefined)
        this.conFiltroIdEmpresa = this.config.conFiltroIdEmpresa;

      if (this.config.componentName !== undefined)
        this.componentName = this.config.componentName;

      if (!this.componentName)
        throw 'la propiedad componentName no puede ser nula o vacia';

      if (this.config.items && this.config.items.length > 0)
        this.items = this.config.items;


      $.each(this.config.columns, function (key, column) {

        if (column.forCheck)
          that.Encabezados[column.data] = false;
        if (column.forThreeState)
          that.Encabezados[column.data] = null;
      });

      let filter = '';


      if (this.config.serverPage == true) {
        if (this.dtTrigger) {
          this.dtTrigger.unsubscribe();
          this.dtTrigger = undefined;
        }
      }
      if (this.items.length > 0) {
        if (this.dtTrigger) {
          this.dtTrigger.unsubscribe();
          this.dtTrigger = undefined;
        }
        this.dtOptions = this.utility.GetDtOptions(null, false);
        return;
      }
      if (this.conFiltroIdEmpresa)
        filter = this.componentName + '.IdEmpresa = ' + StaticData.Usuario.IdEmpresa;


      let obj = (this.config.obj) ? this.config.obj : this.utility.GetApiModelGrillaValidation('_CargarGrilla', (this.config.idGrillaQuery) ? this.config.idGrillaQuery : this.componentName, null, null, null, {}, null, filter);
      if (this.config.include)
        obj.QueryConfig.Include = this.config.include;
      if (this.config.queryConfig)
        obj.QueryConfig.Include = this.config.queryConfig;
      if (this.config.sActionName) {
        obj.SActionName = this.config.sActionName;
        obj.Parameters = {};
        if (this.config.parameters)
          obj.Parameters = this.config.parameters;
        if (this.conFiltroIdEmpresa)
          obj.Parameters['IdEmpresa'] = StaticData.Usuario.IdEmpresa;
      }



      var okUser = this.utility.ValidateUser(obj);

      let urlServer = this.utility.GetUrlServer(this.componentName);




      if (this.config.serverPage == true) {
        this.dtOptions = this.utility.GetDtOptions((this.config.serverPage == true) ? this.config.columns : null, (!this.config.serverPage) ? false : this.config.serverPage);
        this.dtOptions.ajax = this.baseService.AjaxDataTables(urlServer, this.config.dataTableName, this.componentName, obj,
          true, null, this, this.CallbackDataTablesOk, this.CallbackDataTablesError);
      }
      else {
        this.baseService.Get(obj, null, { componentName: this.componentName, method: '_CargarGrilla' },
          this.componentName).then(data => {
            
            that.items = data.Data;
            that.dtTrigger.next();


            setTimeout(() => {
              (<any>$('.jtoggler')).jtoggler({
                onClick: function (event, resultValue) {
                  
                  let _column = new List<any>(that.config.columns).FirstOrDefault(o => { return o.data == resultValue.prop });
                  var _value = resultValue.value;
                  if (_value === 'true')
                    _value = true;
                  else if (_value === 'false')
                    _value = false;
                  else if (_value === 'null')
                    _value = null;
                  that.SetValor(resultValue.index, that.items[resultValue.index], _column, _value);
                  // _context.UpdateProperties(resultValue.index, resultValue.id, resultValue.value, resultValue.prop,false);

                }
              });
            }, 150);
          });
      }






    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.config.componentName, method: '_CargarGrilla', controlName: this.controlName });
    }
  }

  public CallbackDataTablesError(context: any, error: any) {



  }
  public CallbackDataTablesOk(context: any, items: any) {

    context.items = items;
  }

}
