import { NgModuleFactory } from '@angular/core';

export abstract class PluginLoaderService {
  protected constructor() {
    this.ProvideExternals();
  }

  abstract ProvideExternals(): void;

  abstract Load<T>(pluginConfig): Promise<NgModuleFactory<T>>;
}
