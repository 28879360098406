import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  forwardRef
} from '@angular/core';
import {
  TipoObjetoParaPermisos,
  TipoParaPermisos
} from '../../models/general.enum';

import { ContextMenuComponent } from 'ngx-contextmenu';
import { FormActionsThroughEmitters } from 'src/app/models/general.enum';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { Guid } from 'guid-typescript';
import { HelperImages } from 'src/app/helpers/helper-images';
import { List } from '../../../assets/linqts/compilado/index';
import { StaticData } from '../../helpers/static-data';
import { Subject } from 'rxjs';
import { Utilities } from '../../helpers/utilities';

// FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-admin-permitidos',
  templateUrl: './admin-permitidos.component.html',
  styleUrls: ['./admin-permitidos.component.scss']
})

export class AdminPermitidosComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  imagenEditorJson: string = '';
  imagenAEditorAce: string = '';

  imagenChecked: string;
  imagenUnChecked: string;
  imagenUsuario: string;
  imagenRole: string;
  imagenGrupo: string;
  imagenArea: string;

  imagenEditar: string;
  imagenEliminar: string;
  imagenConsultar: string;
  imagenImportar: string;
  imagenExportar: string;
  imagenGuardar: string;
  imagenFiltrar: string;
  imagenVer: string;
  imagenVerPermisos: string;
  imagenAdminPermisos: string;
  imagenDescargar: string;
  imagenCambiarDoc: string;
  imagenCrearDoc: string;
  imagenTodos: string;
  imagenDecifrarGrid: string;
  imagenDecifrarForm: string;

  @Output() onInitialized = new EventEmitter<any>();
  componentName: string = 'AdminPermisosComponent';
  @Output() name: string = 'AdminPermisosComponent';
  @Input() nameSecurity: string = 'AdminPermisosComponent';
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() config: any;
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;
  @Input() tituloUsuarios: string;
  @Input() tituloRoles: string;
  @Input() tituloGrupos: string;
  @Input() tituloAreasEmpresa: string;
  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;
  @Input() UsarComoFiltro: boolean = false;

  isInit: boolean = false;
  _modelo: any;

  currentItem: any;
  @Input() set modelo(value: any) {

    this._modelo = value;
    if (this.isInit == true) {
      // setTimeout(() => {
      //   
      //   this.SetModelo(this._modelo);
      // }, 500);
    }

  }

  get modelo(): any {

    return this._modelo;

  }

  @Input() propUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() propRolesPermitidos: string = 'RolesPermitidos';
  @Input() propAreasEmpresaPermitidas: string = 'AreasEmpresaPermitidas';
  @Input() propGruposPermitidos: string = 'GruposPermitidos';
  @Input() maxWith: any;

  estiloTablas: any = {};
  dtOptionsUsuarios: any;
  dtTriggerUsuarios = new Subject<any>();
  dtOptionsRoles: any;
  dtTriggerRoles = new Subject<any>();
  dtOptionsGrupos: any;
  //dtTriggerGrupos = new Subject<any>();
  dtOptionsAreas: any;
  //dtTriggerAreas = new Subject<any>();
  itemsUsuarios: Array<any> = [];
  itemsRoles: Array<any> = [];
  itemsGrupos: Array<any> = [];
  itemsAreasEmpresa: Array<any> = [];

  UsuariosPermitidos = new List<any>([]);
  RolesPermitidos = new List<any>([]);
  GruposPermitidos = new List<any>([]);
  AreasEmpresaPermitidas = new List<any>([]);
  @Input() isForPermissions: boolean = false;
  @Input() setDefault: boolean = false;
  tituloPermisos: string;
  tipoPermisos: string;
  encontradoPor = [];
  @Input() pageLength: any
  public get VerTabUsuarios(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosUsuario != true) ? true : false;
  }
  public get VerTabRoles(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosRoles != true) ? true : false;
  }
  public get HabilitarTabRolesAcceso(): any {
    return StaticData.GeneralConfig.ConfigUI.HabilitarTabPermisosRolesEnAcceso;
  } 
  public get VerTabGrupos(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosGrupos != true) ? true : false;
  }
  public get VerTabAreaEmpresa(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosAreaEmpresa != true) ? true : false;
  }
  constructor(public utility: Utilities, public helperImages: HelperImages,
    public comunicationService: GeneralComunicationService) {

    // this.OnInit();

  }

  //* FUNCIONES ANGULAR

  public LimpiarListas() {
    //this.itemsUsuarios = [];
    //this.itemsRoles = [];
    //this.itemsGrupos = [];
    //this.itemsAreasEmpresa = [];

    //this.tituloPermisos = null;
    //this.tipoPermisos = '';
    //this.encontradoPor = [];
    this.SetModelo(null);
  }
  public SetItemFromJson(item: any, _tituloPermisos: string, _tipoPermisos: string, itemFiltro: any, tipoFiltro: string, subTipoFiltro: string) {


    this.tituloPermisos = _tituloPermisos;
    this.tipoPermisos = _tipoPermisos;
    this.encontradoPor = [];

    
    if (item) {


      this.UsuariosPermitidos = this.utility.GetPermitidos(item, true, this.propUsuariosPermitidos);
      this.RolesPermitidos = this.utility.GetPermitidos(item, true, this.propRolesPermitidos);
      this.AreasEmpresaPermitidas = this.utility.GetPermitidos(item, true, this.propAreasEmpresaPermitidas);
      this.GruposPermitidos = this.utility.GetPermitidos(item, true, this.propGruposPermitidos);

      // StaticData.RolesUsuariosGrupos.Where(x => { return x.Tipo == TipoParaPermisos.Role }).ToArray()
      this.itemsUsuarios = StaticData.RolesUsuariosGrupos.Where(x => {
        return x.Tipo == TipoParaPermisos.Usuario
          && this.UsuariosPermitidos.Any(o => o == "code" + x.Id.toString())
      }).ToArray();
      this.itemsRoles = StaticData.RolesUsuariosGrupos.Where(x => {
        return x.Tipo == TipoParaPermisos.Role
          && this.RolesPermitidos.Any(o => o == "code" + x.Id.toString())
      }).ToArray();
      this.itemsAreasEmpresa = StaticData.RolesUsuariosGrupos.Where(x => {
        return x.Tipo == TipoParaPermisos.AreaEmpresa
          && this.AreasEmpresaPermitidas.Any(o => o == "code" + x.Id.toString())
      }).ToArray();
      this.itemsGrupos = StaticData.RolesUsuariosGrupos.Where(x => {
        return x.Tipo == TipoParaPermisos.Grupo
          && this.GruposPermitidos.Any(o => o == "code" + x.Id.toString())
      }).ToArray();

      this.itemsUsuarios.forEach(item => {
        item.Seleccionado = true;
      })
      this.itemsRoles.forEach(item => {
        item.Seleccionado = true;
      })
      this.itemsAreasEmpresa.forEach(item => {
        item.Seleccionado = true;
      })
      this.itemsGrupos.forEach(item => {
        item.Seleccionado = true;
      })


      let dataGrupo = "";
      // if (tipoFiltro == 'Usuario') {


      if (subTipoFiltro == 'Menu' && itemFiltro.TodosPermisosMenus)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Pagina' && itemFiltro.TodosPermisosPaginas)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Expediente' && itemFiltro.TodosPermisosExpedientes)
        this.encontradoPor.push("TodosPermisos");
      if (subTipoFiltro == 'Documento' && itemFiltro.TodosPermisosDocumentosExpedientes)
        this.encontradoPor.push("TodosPermisos");

      if (itemFiltro.IdUsuario && this.UsuariosPermitidos.Any(o => o == "code" + itemFiltro.IdUsuario.toString()))
        this.encontradoPor.push("Usuario");
      if (itemFiltro.IdRole && this.RolesPermitidos.Any(o => o == "code" + itemFiltro.IdRole.toString()))
        this.encontradoPor.push("Role");
      if (itemFiltro.IdAreaEmpresa && this.AreasEmpresaPermitidas.Any(o => o == "code" + itemFiltro.IdAreaEmpresa.toString()))
        this.encontradoPor.push("AreaEmpresa");

      if (itemFiltro.Grupos && itemFiltro.Grupos.split('|').length > 0) {
        let grupos: Array<any> = itemFiltro.Grupos.split('|');
        for (var i = 0; i < grupos.length; i++) {
          let grupo = grupos[i].split('-');;
          if (this.GruposPermitidos.Any(o => o == "code" + grupo[0].toString())) {

            dataGrupo += grupo[1] + "(" + grupo[0] + "),";
          }

        }

        if (dataGrupo) {
          this.encontradoPor.push("Grupos:[" + dataGrupo + "]");

        }
      }
      //}
    }
  }
  ngOnInit() {

    this.OnInit();
    this.OnSetConfig();

    if (this.maxWith) {
      this.estiloTablas = { 'overflow-x': 'scroll', 'overflow-y': 'hidden', 'max-width': this.maxWith + 'px' };
    }

    this.SetModelo(this.modelo);
    this.isInit = true;

    if (this.parentClearCall) {
      console.error("AdminPermisosComponent,parentClearCall, observers: " + this.parentClearCall.observers.length);
      this.parentClearCall.subscribe(event => {
        this.InitControl(event);
      });
    }
    this.comunicationService.receivedFormEvent.subscribe(event => {
      if (event.Action == FormActionsThroughEmitters.Edit) {
        this.InitControl(event);
      }
    });
    if (this.onInitialized.observers.length) {
      console.error("AdminPermisosComponent,onInitialized, observers: " + this.onInitialized.observers.length);
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }

  }
  InitControl(event) {
    setTimeout(() => {

      this.modelo = event.modelo;
      this.SetModelo(event.modelo);

    }, 100);
  }
  ngAfterViewInit() {

    //this.dtTriggerUsuarios.next();

    setTimeout(() => {
      var PivotElement = document.querySelector("#" + this.nameSecurity);
      new fabric['Pivot'](PivotElement);
      /*   var UsuariosPermitidos = document.querySelector('#UsuariosPermitidos_length');
        var Dropdown = new fabric['Dropdown'](UsuariosPermitidos);
        var RolesPermitidos = document.querySelector('#RolesPermitidos_length');
        new fabric['Dropdown'](RolesPermitidos);
      
        var GruposPermitidos = document.querySelector('#GruposPermitidos_length');
        new fabric['Dropdown'](GruposPermitidos);
        var AreasEmpresaPermitidos = document.querySelector('#AreasEmpresaPermitidos_length');
        new fabric['Dropdown'](AreasEmpresaPermitidos); */
    }, 400);

  }

  ngOnDestroy(): void {

    this.utility.Unsubscribe(this.parentClearCall);
    this.utility.Unsubscribe(this.parentCallRefresh);
    //this.dtTriggerUsuarios.unsubscribe();

  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public OnInit() {

    if (!this.isForPermissions)
      this.utility.SetItemsAreasRolesUsuarioSeries(this, true, true);
    this.dtOptionsUsuarios = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    this.dtOptionsRoles = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    this.dtOptionsGrupos = this.utility.GetDtOptionsHtmlTable(this.pageLength);
    this.dtOptionsAreas = this.utility.GetDtOptionsHtmlTable(this.pageLength);

    this.imagenChecked = 'ms-Icon--CompletedSolid';
    this.imagenUnChecked = 'ms-Icon--ChromeClose';
    this.imagenUsuario = 'ms-Icon--FabricUserFolder';
    this.imagenRole = 'ms-Icon--UserFollowed';
    this.imagenGrupo = 'ms-Icon--Group';
    this.imagenArea = 'ms-Icon--Org';
    this.imagenEditar = 'ms-Icon--PageEdit';
    this.imagenEliminar = 'ms-Icon--Delete';
    this.imagenConsultar = 'ms-Icon--SearchData';
    this.imagenImportar = 'ms-Icon--CloudImportExport';
    this.imagenExportar = 'ms-Icon--Upload';
    this.imagenGuardar = 'ms-Icon--Save';
    this.imagenFiltrar = 'ms-Icon--Filter';
    this.imagenVer = 'ms-Icon--RedEye';
    this.imagenVerPermisos = 'ms-Icon--Permissions';
    this.imagenAdminPermisos = 'ms-Icon--Permissions';
    this.imagenDescargar = 'ms-Icon--Download';
    this.imagenEditorJson = 'ms-Icon--CodeEdit';
    this.imagenAEditorAce = 'ms-Icon--EditSolidMirrored12';
    this.imagenCambiarDoc = 'ms-Icon--ChangeEntitlements';
    this.imagenCrearDoc = 'ms-Icon--PageAdd';
    this.imagenTodos = 'ms-Icon--PageAdd';
    this.imagenDecifrarForm = 'ms-Icon--Encryption';
    this.imagenDecifrarGrid = 'ms-Icon--Encryption';
    /*     this.imagenChecked = this.utility.GetPathImages(this.utility.GetNodeType('Activo'));
        this.imagenUnChecked = this.utility.GetPathImages(this.utility.GetNodeType('Inactivo'));
        this.imagenUsuario = this.utility.GetPathImages(this.utility.GetNodeType('Usuario'));
        this.imagenRole = this.utility.GetPathImages(this.utility.GetNodeType('Role'));
        this.imagenGrupo = this.utility.GetPathImages(this.utility.GetNodeType('Grupo'));
        this.imagenArea = this.utility.GetPathImages(this.utility.GetNodeType('AreaEmpresa'));
    
    
        this.imagenEditorJson = this.utility.GetPathImages(this.utility.GetNodeType('EditorJson'));
        this.imagenAEditorAce = this.utility.GetPathImages(this.utility.GetNodeType('EditorAce')); */
  }

  public SetDefault() {

    this.maxWith = "600";

    if (this.config.DefaultType == "Grupo") {
      this.tituloUsuarios = "Usuarios";
      this.tituloRoles = "Roles";
      this.propUsuariosPermitidos = "Usuarios";
      this.propRolesPermitidos = "Roles";
    }
    else if (this.config.DefaultType == "AreasEmpresa") {
      this.tituloUsuarios = "Usuarios";
      this.tituloRoles = "Roles";
      this.tituloGrupos = "Grupos";
      this.propUsuariosPermitidos = "UsuariosResponsable";
      this.propRolesPermitidos = "RolesResponsable";
      this.propGruposPermitidos = "GruposResponsable";
    }
    else if (this.config.DefaultType == "AplicacionPermitida") {
      this.tituloUsuarios = "Usuarios";
      this.tituloRoles = "Roles";
      this.tituloGrupos = "Grupos";
      this.propUsuariosPermitidos = "UsuariosPermitidos";
      this.propRolesPermitidos = "RolesPermitidos";
      this.propGruposPermitidos = "GruposPermitidos";
    }
    else if (this.config.DefaultType == "General" || this.config.DefaultType == "Menu") {
      this.tituloUsuarios = "Usuarios";
      this.tituloRoles = "Roles";
      this.tituloAreasEmpresa = "Áreas";
      this.tituloGrupos = "Grupos";
      this.propUsuariosPermitidos = "UsuariosPermitidos";
      this.propRolesPermitidos = "RolesPermitidos";
      this.propAreasEmpresaPermitidas = "AreasEmpresaPermitidas";
      this.propGruposPermitidos = "GruposPermitidos";
    }

  }

  public OnSetConfig() {
    this.nameSecurity = "msPivotAdminPermisos" + "_" + Guid.create().toString();
    if (this.setDefault) {
      this.tituloUsuarios = "Usuarios";
      this.tituloRoles = "Roles";
      this.tituloAreasEmpresa = "Dependencias";
      this.tituloGrupos = "Grupos";
      this.propUsuariosPermitidos = "UsuariosPermitidos";
      this.propRolesPermitidos = "RolesPermitidos";
      this.propAreasEmpresaPermitidas = "AreasEmpresaPermitidas";
      this.propGruposPermitidos = "GruposPermitidos";
    }
    else if (this.config) {
      if (this.config.DefaultType) {
        this.SetDefault();
      }
      else {
        this.tituloUsuarios = this.config.UsersTitle || this.tituloUsuarios;
        this.tituloRoles = this.config.RolesTitle || this.tituloRoles;
        this.tituloAreasEmpresa = this.config.AreasTitle || this.tituloAreasEmpresa;
        this.tituloGrupos = this.config.GroupsTitle || this.tituloGrupos;

        this.maxWith = this.config.MaxWith || this.maxWith;

        this.propUsuariosPermitidos = this.config.UsersProp || this.propUsuariosPermitidos;
        this.propRolesPermitidos = this.config.RolesProp || this.propRolesPermitidos;
        this.propAreasEmpresaPermitidas = this.config.AreasProp || this.propAreasEmpresaPermitidas;
        this.propGruposPermitidos = this.config.GroupsProp || this.propGruposPermitidos;
      }

      this.name = (this.config.Name !== null) ? this.config.Name : this.name;
      let _config = {
        "UsersTitle": "Usuari",
        "RolesTitle": "",
        "AreasTitle": "",
        "GroupsTitle": "",
        "UsersProp": "UsuariosPermitidos",
        "RolesProp": "RolesPermitidos",
        "AreasProp": "AreasEmpresaPermitidas",
        "GroupsProp": "GruposPermitidos",
        "MaxWith": 900
      };
    }

  }

  public SetModelo(modelo: any) {

    if (this.isForPermissions) {
      return;

    }
    this._modelo = modelo;

    if (this.modelo) {
      if (this.isModelContainer) {
        this.UsuariosPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propUsuariosPermitidos);
        this.RolesPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propRolesPermitidos);
        this.AreasEmpresaPermitidas = this.utility.GetPermitidos(this.modelo.modelo, true, this.propAreasEmpresaPermitidas);
        this.GruposPermitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propGruposPermitidos);
      }
      else {
        this.UsuariosPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propUsuariosPermitidos);
        this.RolesPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propRolesPermitidos);
        this.AreasEmpresaPermitidas = this.utility.GetPermitidos(this.modelo, true, this.propAreasEmpresaPermitidas);
        this.GruposPermitidos = this.utility.GetPermitidos(this.modelo, true, this.propGruposPermitidos);
      }
    } else {
      this.UsuariosPermitidos = new List<any>([]);
      this.RolesPermitidos = new List<any>([]);
      this.AreasEmpresaPermitidas = new List<any>([]);
      this.GruposPermitidos = new List<any>([]);
    }

    this.SetSeleccionados(this.itemsUsuarios, TipoParaPermisos.Usuario, this.UsuariosPermitidos, this.propUsuariosPermitidos);
    this.SetSeleccionados(this.itemsRoles, TipoParaPermisos.Role, this.RolesPermitidos, this.propRolesPermitidos);
    this.SetSeleccionados(this.itemsAreasEmpresa, TipoParaPermisos.AreaEmpresa, this.AreasEmpresaPermitidas, this.propAreasEmpresaPermitidas);
    this.SetSeleccionados(this.itemsGrupos, TipoParaPermisos.Grupo, this.GruposPermitidos, this.propGruposPermitidos);

  }

  //! NO HACE NADA
  public ValidarIndefinido(permiso: any, prop: string) {
    //if (permiso[prop] === undefined)
    // permiso[prop] = false
  }

  public SetSeleccionados(items: Array<any>, tipoParaPermisos: TipoParaPermisos, permitidos: List<any>, prop: string) {

    items.forEach((item) => {
      item.Seleccionado = permitidos.Any((x) => `code${item.Id}` === x);
    });

    this.SetPermitidos(permitidos, prop);

  }

  public Filtrar(permitidos: List<any>, item: any, tipoParaPermisos: TipoParaPermisos, prop: string) {

    if (this.Disabled)
      return;

    if (this.currentItem)
      this.currentItem.Seleccionado = false;
    this.currentItem = null;
    setTimeout(() => {

      this.currentItem = item;
      item.Seleccionado = true;

      this.FireAction('SelectionItem', item);

    }, 200);


  }
  public Adicionar(permitidos: List<any>, item: any, tipoParaPermisos: TipoParaPermisos, prop: string) {

    if (this.UsarComoFiltro) {

      this.Filtrar(permitidos, item, tipoParaPermisos, prop);
      return
    }
    if (this.Disabled)
      return;


    item.Seleccionado = true;
    if (!permitidos.Any(x => { return x == 'code' + item.Id }))
      permitidos.Add('code' + item.Id);

    this.SetPermitidos(permitidos, prop);

    this.FireAction('Add');

  }

  public Eliminar(permitidos: List<any>, item: any, tipoParaPermisos: TipoParaPermisos, prop: string) {

    if (this.Disabled)
      return;

    item.Seleccionado = false;
    permitidos = permitidos.RemoveAll(x => { return x == 'code' + item.Id });
    //Juanbr 28/12/2020
    switch (prop) {
      case this.propUsuariosPermitidos:
        this.UsuariosPermitidos = permitidos.ToList();
        break;
      case this.propGruposPermitidos:
        this.GruposPermitidos = permitidos.ToList();
        break;
      case this.propAreasEmpresaPermitidas:
        this.AreasEmpresaPermitidas = permitidos.ToList();
        break;
      case this.propRolesPermitidos:
        this.RolesPermitidos = permitidos.ToList();
        break;
    }
    this.SetPermitidos(permitidos, prop);
    this.FireAction('Delete');

  }

  public SetPermitidos(permitidos: List<any>, prop: string) {

    if (this.isModelContainer)
      this._SetPermitidos(this.modelo.modelo, permitidos, prop);
    else
      this._SetPermitidos(this.modelo, permitidos, prop);

  }

  public SeleccionarUsuario(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.UsuariosPermitidos, item, TipoParaPermisos.Usuario, this.propUsuariosPermitidos);
    }
    else {
      this.Adicionar(this.UsuariosPermitidos, item, TipoParaPermisos.Usuario, this.propUsuariosPermitidos);
    }

  }

  public SeleccionarRole(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.RolesPermitidos, item, TipoParaPermisos.Role, this.propRolesPermitidos);
    }
    else {
      this.Adicionar(this.RolesPermitidos, item, TipoParaPermisos.Role, this.propRolesPermitidos);
    }

  }

  public SeleccionarGrupo(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.GruposPermitidos, item, TipoParaPermisos.Grupo, this.propGruposPermitidos);
    }
    else {
      this.Adicionar(this.GruposPermitidos, item, TipoParaPermisos.Grupo, this.propGruposPermitidos);
    }

  }

  public SeleccionarArea(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(this.AreasEmpresaPermitidas, item, TipoParaPermisos.AreaEmpresa, this.propAreasEmpresaPermitidas);
    }
    else {
      this.Adicionar(this.AreasEmpresaPermitidas, item, TipoParaPermisos.AreaEmpresa, this.propAreasEmpresaPermitidas);
    }

  }

  //! NO HACE NADA
  public VerEditor(tipo: any) {

    /*  this.utility.VerEditor(tipo, this.modelo.ListaPermisos, false, "Editor").then(data => {
       if (data.dialogResult == true)
         this.SetConfiguracion();
     }); */

  }

  FireAction(actionType: string, item?: any) {

    this.onActionEventEmitter.emit({
      modelo: this.modelo,
      type: this.name,
      componentName: this.componentName,
      actionType: actionType,
      selectItem: item
    });

  }

  _SetPermitidos(modelo: any, permitidos: List<any>, prop: string) {

    if (modelo) {
      modelo[prop] = permitidos.ToArray().join('|');
    }// '|' + permitidos.ToArray().join('|');

  }

  //* END FUNCIONES PERSONALIZADAS

}
