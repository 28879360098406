import { Component, OnInit, Input } from '@angular/core';
import { PropertyGridComponent } from '../property-grid/property-grid.component';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-property-grid-config-control',
  templateUrl: './property-grid-config-control.component.html',
  styleUrls: ['./property-grid-config-control.component.css']
})
export class PropertyGridConfigControlComponent extends PropertyGridComponent implements OnInit {

  public OnChangeHandler(event) {


    this.subControl.ConfigControl = event;
    this.UpdateEditor(this.subControl);
  }
  public OnChangeHandlerGidViewParams(event) {

    
    this.subControl.ConfigControl.Params = event;
    this.UpdateEditor(this.subControl);
  }
  public OnChangeHandlerGidViewParamsQueryConfig(event) {

    
    this.subControl.ConfigControl.QueryConfig.Entity = event;
    this.UpdateEditor(this.subControl);
  }

  public ConfigExportar(isPrincipal: boolean) {
    //ExportImportConfigComponent

    let view = false;
    if (isPrincipal && this.subControl.ConfigControl.QueryConfig) {
      if (!this.subControl.ConfigControl.QueryConfig.ExportSettings)
        this.subControl.ConfigControl.QueryConfig.ExportSettings = {};
      view = true;
    }
    else if (!isPrincipal && this.subControl.ConfigControl.QueryConfigAll) {
      if (!this.subControl.ConfigControl.QueryConfigAll.ExportSettings)
        this.subControl.ConfigControl.QueryConfigAll.ExportSettings = {};
      view = true;
    }


    if (view) {
      const config = this.utility.GetConfigWindow('');
      let isNew: boolean = false;
      let windowModel: any = {

        modelo: (isPrincipal) ? this.subControl.ConfigControl.QueryConfig.ExportSettings : this.subControl.ConfigControl.QueryConfigAll.ExportSettings,

      };

      this.utility.VerModalComponente('ExportImportConfigComponent', windowModel, config).then(dataResultOnClose => {
        if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

          

        }
        else {
          if (isPrincipal)
            this.subControl.ConfigControl.QueryConfig.ExportSettings = null;
          else
            this.subControl.ConfigControl.QueryConfigAll.ExportSettings = null;
        }
      });
    }
  }
  /*  @Input() set subControl(value: any) {
     
     this._subControl = value;
     this.UpdateEditor(this.subControl);
   }; */
  public Update() {
    this.UpdateEditor(this.subControl);
  }
  public UpdateEditor(subControl?: any) {

    this.subControl = subControl;
    if (this.subControl && this.subControl.ControlType !== 'Column') {

      if (this.editorAce)
        this.editorAce.Update(this.subControl.ConfigControl);



      // if (this.editorAceEntity)
      //   this.editorAceEntity.Update(this.subControl.ConfigControl.QueryConfig.Entity);
      // if (this.editorAceParams)
      //   this.editorAceParams.Update(this.subControl.ConfigControl.Params);
    }
  }

}
