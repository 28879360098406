import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import { TipoObjetoParaPermisos, TipoParaPermisos
} from '../../models/general.enum';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { ConfigWindow } from '../../models/config-window';
import { GeneralConfigComponent } from '../../general-config/general-config/general-config.component';
import {BaseServiceService} from '../../services/base-service.service';
import { HelperImages } from 'src/app/helpers/helper-images';

@Component({
  selector: 'app-config-consecutivo',
  templateUrl: './config-consecutivo.component.html',
  styleUrls: ['./config-consecutivo.component.css']
})
export class ConfigConsecutivoComponent extends GeneralConfigComponent implements OnInit {

  @Input() public idTipoConsecutivo:number=0;
  @Input() verPrueba:boolean=false;


  @Input() verDocumento: boolean = true;
	@Input() verExpediente: boolean = true;
	@Input() labelConfigDocumento: string = "Config Documento";
  @Input() labelConfigExpediente: string = "Config Expediente";
  
  constructor(public baseService: BaseServiceService, public utility: Utilities, public helperImages: HelperImages) {
    super(utility,helperImages);
    
  }
  public AddConfigExpedientesEventHandler() {
   
    this.modelo.ConfigsExpediente.push(this.utility.GetModeloConfigConsecutivoExpediente())
  }

  public Probar(){

  }
}
