import { Component, OnInit, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { GeneralFormComponent } from 'src/app/components/controls/general-form/general-form.component';
import { ControlActions, TipoObjetoParaPermisos, ControllerMethods } from 'src/app/models/general.enum';
import { DataTableComponent } from 'src/app/components/controls/data-table/data-table.component';
import { ConfigWindow } from 'src/app/models/config-window';


@Component({
  selector: 'app-estado-carga',
  templateUrl: './estado-carga.component.html',
  styleUrls: ['./estado-carga.component.css']
})
export class EstadoCargaComponent extends GeneralFormComponent implements OnInit, AfterViewInit {

  CustomLabel: any;
  context: any;
  DescargarPlantillaXml: any;
  DescargarPlantilla: any;
  exportXmlTemplate: any;
  Importar: any;
  VerCommandBar: boolean = true;

  public PostNgAfterViewInit() {

    this.CustomLabel = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "CustomLabel" });
    if (this.context && this.context.modeloVirtual && this.context.modeloVirtual.CustomLabel) {
      this.CustomLabel.stringTemplate.next(this.context.modeloVirtual.CustomLabel);
      this.CustomLabel.Hidden = false;
    }
  }

  public OnInitEnd() {
    this.DescargarPlantillaXml = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DescargarPlantillaXml" });
    this.DescargarPlantilla = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "DescargarPlantilla" });
    this.Importar = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Importar" });
    if (this.exportXmlTemplate) {
      this.DescargarPlantillaXml.Hidden = false;
      this.DescargarPlantilla.Hidden = true;
      this.Importar.formatsAllowed = ".xml";
    }
    // if (this.importUrl || this.importUrl != '')
    //   this.VerCommandBar = false

    // if (this.importUrl == null)
    //   this.VerCommandBar = true

    if (this.importUrl == null) {
      this.VerCommandBar = true;
    } else {
      this.VerCommandBar = false;
    }

    this.SetModelToControls();
    super.OnInitEnd();
  }

}
