import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';

@Component({
  selector: 'app-general-component-for-ide',
  templateUrl: './general-component-for-ide.component.html',
  styleUrls: ['./general-component-for-ide.component.css']
})
export class GeneralComponentForIDEComponent extends ConfigBaseComponent implements OnInit {


  @Input() Pagina: any;
  @Input() viewComponentTitle: boolean = true;
  @Input() viewComponentHeader: boolean = true;
  @Output() ayuda: EventEmitter<any> = new EventEmitter<any>();
  viewComponent: boolean = true;
  ngOnInit() {

    if (this.Pagina && !this.Pagina.IdPagina) {
      this.Pagina = this.utility.FindPageForComponentIDE(this.Pagina.ControlType.substring(1), 'component');
      if (!this.Pagina)
      {
        this.viewComponent=false;
        this.Pagina = { ControlType: 'Indefinido' };
      }
      else  
      this.viewComponent=false;
    }
  }
}
