import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject, ElementRef
} from '@angular/core';
import {
  TipoObjetoParaPermisos, TipoParaPermisos
} from '../../models/general.enum';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { ConfigWindow } from '../../models/config-window';
import { MatList } from '@angular/material';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { HelperImages } from 'src/app/helpers/helper-images';



@Component({
  selector: 'app-config-campos',
  templateUrl: './config-campos.component.html',
  styleUrls: ['./config-campos.component.css']
})
export class ConfigCamposComponent implements OnInit {

  @Input() fileModelType: string = 'Documents';
  @ViewChild('toolbox_controls', { static: true }) child: ElementRef;
  public direction: string = 'horizontal';
  public directionOrgCharts: string = 'vertical';
  public directionGrid: string = 'horizontal';
  EstiloBuscarContenedor: any = { height: "600px" };

  @Input() comandos: Array<any> = [];
  @Input() modelo: any;
  @Input() listName: string = "drop-list-list";
  @Input() propCampos: string = "Campos";
  @Input() propCaracterSeparacion: string = "CaracterSeparacion";
  @Input() propCaracterRutaInvalida: string = "CaracterRutaInvalida";
  @Input() propValoresReemplazo: string = "ValoresReemplazo";
  @Input() propFormatoTexto: string = "FormatoTexto";
  @Input() propTamanoMaximo: string = "TamanoMaximo";
  @Input() propTipoNombreFolder: string = "TipoNombreFolder";
  @Input() verNombre: boolean = false;
  @Input() verTipoNombreFolder: boolean = false;
  @Input() verGenerarNuevoCodigo: boolean = false;
  @Input() verModelo: boolean = false;
  @Input() listToConnect: Array<any> = [];
  @Input() controls: Array<any> = [];
  @Input() verControles: boolean = false;
  FormatosTexto: Array<any> = [];
  TiposNombreFolder: Array<any> = [];
  modeloInterno: any = { Campos: [], CaracterSeparacion: '', ValoresReemplazo: null, FormatoTexto: 'Normal', TamanoMaximo: 0 };

  comandosInterno: Array<any> = [];
  Pagina: any = {
    ConfigPage: {
      Controls: [
        { ColSpan: 12, Label: 'Formato Texto', Activo: true },
        { ColSpan: 12, Label: 'Caracter Separacion', Activo: true },
        { ColSpan: 12, Label: 'Caracter Ruta Inválida', Activo: true },
        { ColSpan: 12, Label: 'Tamano Maximo', Activo: true },
        { ColSpan: 12, Label: 'Nombre', Activo: true },
        { ColSpan: 12, Label: 'Tipo Nombre Folder', Activo: true },
        { ColSpan: 12, Label: 'Generar Nuevo Codigo', Activo: true },


      ]
    }
  };
  searchText:any =  {};
  constructor(public utility: Utilities, public helperImages: HelperImages) {


    this.FormatosTexto.push({ Id: 'Normal', Name: 'Normal' });
    this.FormatosTexto.push({ Id: 'Mayusculas', Name: 'Mayusculas' });
    this.FormatosTexto.push({ Id: 'Minusculas', Name: 'Minusculas' });
    this.FormatosTexto.push({ Id: 'UpperCamelCase', Name: 'PrimeraLetraMayuscula' }); //UpperCamelCase
    //this.FormatosTexto.push({ Id: 'PascalCase' });
    this.TiposNombreFolder = this.utility.GetTiposTiposNombreFolderText();
    this.ConvertModeloToModeloInterno();




  }

  public ConvertModeloToModeloInterno() {

    //this.modeloInterno.
  }
  public ConvertModeloInternoToModelo() {

  }
  ngOnInit() {


    if (!this.modelo) {
      return;
    }
    
    this.utility.SetTextFormatosTextoCampo(this.modelo, this.propFormatoTexto);
    if (this.verTipoNombreFolder)
      this.utility.SetTextTipoNombreFolder(this.modelo);
    const that = this;
    this.listToConnect.push(this.listName);
    let comando: any = { Nombre: 'CamposEspeciales', Etiqueta: 'Campos Especiales', Campos: [] };
    this.searchText[comando.Nombre] = "";
    comando.Campos.push({
      Nombre: 'NombreObjeto', Etiqueta: 'Nombre Objeto',
      TipoCampo: 'NombreObjeto', Icon: this.utility.GetPathImages(this.utility.GetNodeType('NombreObjeto')),
      Clase: 'alert alert-primary'
    });

    // Valor =1,
    comando.Campos.push({
      Nombre: 'Valor', Etiqueta: 'Valor',
      TipoCampo: 'Valor', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Valor')),
      Clase: 'alert alert-primary'
    });
    // Autonumerico = 2,
    comando.Campos.push({
      Nombre: 'Autonumerico', Etiqueta: 'Autonumerico',
      TipoCampo: 'Autonumerico', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Autonumerico')),
      Clase: 'alert alert-primary'
    });
    // FechaActual = 3,
    comando.Campos.push({
      Nombre: 'FechaActual', Etiqueta: 'Fecha Actual',
      TipoCampo: 'FechaActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('FechaActual')),
      Clase: 'alert alert-primary'
    });
    // AnoActual = 4,
    comando.Campos.push({
      Nombre: 'AnoActual', Etiqueta: 'Ano Actual',
      TipoCampo: 'AnoActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('AnoActual')),
      Clase: 'alert alert-primary'
    });
    // MesActual = 5,
    comando.Campos.push({
      Nombre: 'MesActual', Etiqueta: 'Mes Actual',
      TipoCampo: 'MesActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('MesActual')),
      Clase: 'alert alert-primary'
    });
    // DiaActual = 6,
    comando.Campos.push({
      Nombre: 'DiaActual', Etiqueta: 'Dia Actual',
      TipoCampo: 'DiaActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('DiaActual')),
      Clase: 'alert alert-primary'
    });
    // Empresa = 7,
    comando.Campos.push({
      Nombre: 'Empresa', Etiqueta: 'Empresa',
      TipoCampo: 'Empresa', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Empresa')),
      Clase: 'alert alert-primary'
    });
    // AreaEmpresaUsuarioActual = 8,
    comando.Campos.push({
      Nombre: 'AreaEmpresaUsuarioActual', Etiqueta: 'Area Empresa Usuario Actual',
      TipoCampo: 'AreaEmpresaUsuarioActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('AreaEmpresa')),
      Clase: 'alert alert-primary'
    });
    // RoleUsuarioActual = 9,
    comando.Campos.push({
      Nombre: 'RoleUsuarioActual', Etiqueta: 'Role Usuario Actual',
      TipoCampo: 'RoleUsuarioActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Role')),
      Clase: 'alert alert-primary'
    });
    // GrupoUsuarioActual = 10,
    comando.Campos.push({
      Nombre: 'GrupoUsuarioActual', Etiqueta: 'Grupo Usuario Actual',
      TipoCampo: 'Grupo', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Grupo')),
      Clase: 'alert alert-primary'
    });
    // UsuarioActual = 11,
    comando.Campos.push({
      Nombre: 'UsuarioActual', Etiqueta: 'Usuario Actual',
      TipoCampo: 'UsuarioActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Usuario')),
      Clase: 'alert alert-primary'
    });
    // IdEmpresa = 12,
    comando.Campos.push({
      Nombre: 'IdEmpresa', Etiqueta: 'IdEmpresa',
      TipoCampo: 'IdEmpresa', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Empresa')),
      Clase: 'alert alert-success'
    });
    // IdAreaEmpresaUsuarioActual = 13,
    comando.Campos.push({
      Nombre: 'IdAreaEmpresaUsuarioActual', Etiqueta: 'IdAreaEmpresaUsuarioActual',
      TipoCampo: 'IdAreaEmpresaUsuarioActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('AreaEmpresa')),
      Clase: 'alert alert-success'
    });
    // IdRoleUsuarioActual = 14,
    comando.Campos.push({
      Nombre: 'IdRoleUsuarioActual', Etiqueta: 'IdRoleUsuarioActual',
      TipoCampo: 'IdRoleUsuarioActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Role')),
      Clase: 'alert alert-success'
    });
    // IdGrupoUsuarioActual = 15,
    comando.Campos.push({
      Nombre: 'IdGrupoUsuarioActual', Etiqueta: 'IdGrupoUsuarioActual',
      TipoCampo: 'IdGrupoUsuarioActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Grupo')),
      Clase: 'alert alert-success'
    });
    // IdUsuarioActual = 16,
    comando.Campos.push({
      Nombre: 'IdUsuarioActual', Etiqueta: 'IdUsuarioActual',
      TipoCampo: 'IdUsuarioActual', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Usuario')),
      Clase: 'alert alert-success'
    });

    if (this.verModelo) {
      comando.Campos.push({
        Nombre: 'Control', Etiqueta: 'Control',
        TipoCampo: 'Control', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Control')),
        Clase: 'alert alert-info'
      });
      comando.Campos.push({
        Nombre: 'Modelo', Etiqueta: 'Modelo',
        TipoCampo: 'Modelo', Icon: this.utility.GetPathImages(this.utility.GetNodeType('Modelo')),
        Clase: 'alert alert-info'
      });
    }
    comando.Campos.push({
      Nombre: 'GUID', Etiqueta: 'GUID',
      TipoCampo: 'Guid', Icon: this.utility.GetPathImages(this.utility.GetNodeType('GUID')),
      Clase: 'alert alert-info'
    });
    // Modelo,
    // this.comandosInterno.push({ Nombre: 'Modelo', Etiqueta: 'Modelo', TipoCampo: 'Modelo' });

    this.comandosInterno.push(comando)

    // if (this.fileModelType == 'Documents') {
    $.each(that.comandos,  (i, _comando)=> {

      let comandoClone = that.utility.Clone(_comando);
      this.searchText[comandoClone.Nombre] = "";
      $.each(comandoClone.Campos,  (ii, campo)=> {
        campo.Comando = comandoClone.Nombre;

        campo.Icon = that.utility.GetPathImages(that.utility.GetNodeType('Modelo')),
          campo.Clase = 'alert alert-info',
          campo.TipoCampo = 'Modelo'
      });

      that.comandosInterno.push(comandoClone);

    });
    // }
    //this.comandosInterno = this.utility.Clone(this.comandos);

    if (!this.modelo[this.propCampos])
      this.modelo[this.propCampos] = [];
    //this.comandosInterno.splice(1, 0, comando);

  }






  ////////////////// DRAG AND ROP CONTROLS/////////////////////////////////

  PaginaTemp: any = { ConfigPage: { Controls: [] }, Config: [] };
  //componetModels: Array<any> = [];

  public GetComponents(): Array<any> {

    let list: Array<any> = [];
    if (StaticData.Usuario) {
      $.each(StaticData.Usuario.Paginas, function (key, pagina) {
        list.push({
          "Type": "component",
          "Icon": "far fa-window-maximize",
          "Label": pagina.Titulo,
          "ControlType": pagina.Nombre
        })

      });
    }
    return list
  }
  fieldModels = this.utility.GetBasicFields();
  componetModels = this.GetComponents();

  Controls: Array<any> = [];

  model: any = {
    name: 'App name...',
    description: 'App Description...',
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: ""
    },
    Controls: this.Controls
  };

  _currentIndex;
  _currentField;
  currentFieldModel;


  dragStart(event: CdkDragStart) {
    // this._currentIndex = this.fieldModels.indexOf(event.source.data); // Get index of dragged type
    // this._currentField = this.child.nativeElement.children[this._currentIndex]; // Store HTML field
  }

  moved(event: CdkDragMove) {
    // Check if stored HTML field is as same as current field
    // if (this.child.nativeElement.children[this._currentIndex] !== this._currentField) {
    //   // Replace current field, basically replaces placeholder with old HTML content
    //   // this.child.nativeElement.replaceChild(this._currentField, this.child.nativeElement.children[this._currentIndex]);
    // }
  }
  itemDroppedConfigPage(event: CdkDragDrop<any[]>) {
    console.error('itemDropped:  ', event);

    if (event.previousContainer === event.container) {
      moveItemInArray(this.PaginaTemp.ConfigPage.Controls, event.previousIndex, event.currentIndex);
    } else {
      this.addField(event.item.data, event.currentIndex, true);
    }
  }

  itemDropped(event: CdkDragDrop<any[]>) {
    console.error('itemDropped:  ', event);

    if (event.previousContainer === event.container) {
      moveItemInArray(this.modelo[this.propCampos], event.previousIndex, event.currentIndex);
    } else {
      this.addField(event.item.data, event.currentIndex);
    }
  }
  removeField(event, isConfig?: boolean) {
    //
    // if (isConfig == true)
    //   this.PaginaTemp.ConfigPage.Controls.splice(event.index, 1);
    // else
    this.modelo[this.propCampos].splice(event.index, 1);

    this.currentFieldModel = null;
  }
  selectField(event: any) {


    this.currentFieldModel = event.control;// this.model.Controls[event.index];

    if (event.isWindow) {
      this.verProperty();
    }

  }


  verProperty(): any {
    if (!this.currentFieldModel.ValoresReemplazo)
      this.currentFieldModel.ValoresReemplazo = {};
    let config: ConfigWindow = new ConfigWindow();
    config.classWindow = 'info';
    config.titleWindow = "Propiedades [" + this.currentFieldModel.Nombre + ']';
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';
    let windowModel: any = {
      subControl: this.currentFieldModel,
      controls: this.controls
    };

    this.utility.OpenWindow('PropertGridCampoComponent',
      config, windowModel).then(dataResult => {
        // console.log(dataResult);
        
        dataResult.onClosePromise.then(dataResultOnClose => {
          // console.log(dataResultOnClose);
          

        });

      })

  }

  addField(fiedlModel: any, index: number, isConfig?: boolean) {
    //let copy: any = this.utility.Clone(fiedlModel);
    //FindPageByName

    // public TiposCampoConfigGenerar TipoCampo { get; set; }
    // public string Valor { get; set; }
    // public string Cultura { get; set; }
    // public string Formato { get; set; }
    // public string CaracterRelleno { get; set; }
    // public int AnchoRelleno { get; set; }
    // public TiposRelleno TipoRelleno { get; set; }
    // public Dictionary<string,string> ValoresReemplazo { get; set; }
    // public FormatosTexto FormatoTexto { get; set; }
    // public int TamanoMaximo { get; set; }

    let _currentFieldModel = {
      Nombre: fiedlModel.Nombre,
      TipoCampo: fiedlModel.TipoCampo,
      Comando: fiedlModel.Comando,
      Valor: null,
      Cultura: '',
      Formato: null,
      CaracterRelleno: null,
      AnchoRelleno: 0,
      TipoRelleno: 'Ninguno',
      ValoresReemplazo: {},
      FormatoTexto: 'Normal',
      TamanoMaximo: 0

    };// this.utility.GeModelControl(fiedlModel.ControlType, fiedlModel.Type);
    if (index == -1)
      this.modelo[this.propCampos].push(_currentFieldModel);
    else
      this.modelo[this.propCampos].splice(index, 0, _currentFieldModel);
    /*  if (isConfig == true) {
       if (index == -1)
         this.PaginaTemp.ConfigPage.Controls.push(_currentFieldModel);
       else
         this.PaginaTemp.ConfigPage.Controls.splice(index, 0, _currentFieldModel);
     }
     else {
       if (index == -1)
       this.modelo[this.propCampos].push(_currentFieldModel);
       else
       this.modelo[this.propCampos].splice(index, 0, _currentFieldModel);
     } */
  }

  get GetlistToConnect(): Array<any> {
    return this.listToConnect;
  }
  //listToConnect = ['drop-list', 'drop-list-list'];

  public CreateListToConnect(name: string) {
    this.listToConnect.splice(0, 0, name);

  }


}
