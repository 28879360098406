import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { StaticData } from 'src/app/helpers/static-data';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { ComponentsContainer } from 'src/app/models/components-container';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {

  @Input() componentsContainer: ComponentsContainer;
  @Input() name: string = '';
  @Input() configPermisos: any;
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() config: any = { Label: '', Controls: [], TitleOptions: '', IconOptions: null, LabelOptions: null };
  @Input() form: FormGroup;
  estilos: any = StaticData.Estilos;
  @Input() parentContext: any;
  @Input() isSingleButton: boolean = true;
  @Input() viewButton: any;
  /*  constructor(public baseService: BaseServiceService,
     public utility: Utilities,
     public helperImages: HelperImages,
     public helperRules: HelperRules,
     public helperActions: HelperActions) { } */
  constructor(public helperActions: HelperActions) { }

  //* FUNCIONES ANGULAR
  ngOnInit() {

    if (this.config) {
      this.name = this.config.Name;
      this.isSingleButton = (this.config && this.config.Controls && this.config.Controls.length == 0);
      this.viewButton = this.config.Controls.find(item => item.SubControlType == 'ButtonOption');
    }

  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public ViewButtons(controlType) {

    return this.config.Controls.filter(item => item.SubControlType == controlType).length;

  }

  public GetButtons(controlType) {

    return this.config.Controls.filter(item => item.SubControlType == controlType);

  }

  public ButtonClick(option: any) {

    let notEmitAction: any = (option) ? option.NotEmitAction : this.config.NotEmitAction;

    if (notEmitAction) {

      this.helperActions.ExecuteActionsControl(option, false, null, null, this.configPermisos,
        this, null, this.onActionEventEmitter, this.componentsContainer, option, null, this.config);
    }
    else {
      this.onActionEventEmitter.emit({
        config: this.config,
        ControlType: this.config.ControlType, Name: this.config.Name, Action: 'Click',
        Option: option,
        SubControlType: this.config.SubControlType
      });
    }

  }

  //* END FUNCIONES PERSONALIZADAS

}
