import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { SolicitudCommonComponent } from '../../common/solicitud-common.component';


@Component({
  selector: 'app-solicitud-pqrd-adjuntos',
  templateUrl: './solicitud-pqrd-adjuntos.component.html',
  styleUrls: ['./solicitud-pqrd-adjuntos.component.css']
})
export class SolicitudPqrdAdjuntosComponent extends SolicitudCommonComponent {

  public OnInitEnd() {
    this.postSaveEmitter.subscribe(modelo => {
        
        this.utility.VerModalOk("Se agregó adjuntos a la Solicitud con código: " + "\n\r" + modelo.data.data.Codigo);
    });
    super.OnInitEnd();
  }

  _Guardar(form: FormGroup, configButtons?: any) {
    debugger;

    if (this.modelo.modelo.DataAdd) {
      this.modelo.modelo.DataAdd[
        "DocumentAdded"
      ] = true;
    } else {
      this.modelo.modelo.DataAdd = {
        DocumentAdded: true
      };
    }
  
    super._Guardar(form, configButtons);
  }



}
