//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component,ViewChild, OnInit, AfterViewInit ,Input,Output,EventEmitter } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';
import { Paginas,ControllerMethods } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService} from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';


@Component({
  selector: 'app-ltlog-app',
  templateUrl: './ltlog-app.component.html',
  styleUrls: ['./ltlog-app.component.css']
})
export class LTLogAppComponent  extends BaseCrud implements OnInit {


 
 
   constructor(public baseService:BaseServiceService,public utility:Utilities) { 
     
       super(baseService,utility,Paginas.TLogApp,'LTLogApp',null,false);
       this.pkName='IdLogApp';
   }
 
   	public onSpecificStart(){
		this.conFiltroIdEmpresa = true;
		this.configs = {};
		this.columns=[{data:this.pkName, title:'Id'},
				{data:'IdAplicacionPermitida', title:'Aplicación Permitida'},
				{data:'Type', title:'Type'},
				{data:'GUID', title:'G U I D'},
				{data:'AppName', title:'App Name'},
				{data:'WebServiceName', title:'Web Service Name'},
				{data:'MethodName', title:'Method Name'},
				{data:'MethodNameUI', title:'Method Name U I'},
				{data:'UserId', title:'User Id'},
				{data:'UserName', title:'User Name'},
				{data:'Imei', title:'Imei'},
				{data:'UserMachineInfo', title:'User Machine Info'},
				{data:'ServerIP', title:'Server I P'},
				{data:'ErrorMessage', title:'Error Message'},
				{data:'ErrorType', title:'Error Type'},
		];

	}

	public Limpiar(callback:(data:any) => void=null){
		this.modelo = {};
		this.modelo.IdLogApp = 0;
		this.modelo.PkValue = 0;
		this.modelo.IdEmpresa =-1;
		this.modelo.IdAplicacionPermitida = null;
		this.modelo.AplicacionPermitida = null;
		this.modelo.Type = '';
		this.modelo.GUID = null;
		this.modelo.AppName = null;
		this.modelo.WebServiceName = null;
		this.modelo.MethodName = null;
		this.modelo.MethodNameUI = null;
		this.modelo.UserId = null;
		this.modelo.UserName = null;
		this.modelo.Token = null;
		this.modelo.Imei = null;
		this.modelo.UserMachineInfo = null;
		this.modelo.ServerIP = null;
		this.modelo.Data = null;
		this.modelo.ErrorMessage = null;
		this.modelo.Source = null;
		this.modelo.Method = null;
		this.modelo.ErrorType = null;
		this.modelo.Trace = null;
		this.modelo.CreationDate = '';
		 this.utility.SetFieldsToModel(this.modelo,this.filterParent);
		  if (callback)callback(this.modelo);
	}


 
 }
