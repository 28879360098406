import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { StaticData } from 'src/app/helpers/static-data';
import { FormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ConfigWindow } from 'src/app/models/config-window';
import { ComponentsContainer } from 'src/app/models/components-container';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicResourceLoaderServiceService } from '../../../services/dynamic-resource-loader-service.service';

declare var bestdoc_context: any;
@Component({
  selector: 'app-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss']
})
export class HtmlComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() modelo: any;
  @Input() form: FormGroup;
  @Input() isDesigner: boolean = false;
  @Input() isHtmlFile: boolean = false;
  @Input() subControl: any;
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Input() name: string = "Html_" + Guid.create().toString();
  @Input() componentsContainer: ComponentsContainer = new ComponentsContainer();
  @Input() PaginaPadre: any
  @Input() value: any = "";
  @Input() url: any = "";
  @Input() setExternalContext: boolean = false;
  componentName = "HtmlComponent";

  viewTable: boolean = false;
  viewFilterControl: boolean = false;
  notLoad: boolean = false;

  viewFilters: boolean = false;
  hasFilters: boolean = false;
  hasChildReports: boolean = false;
  filterParent: any;
  internalFilter: any;

  public resizable: boolean;
  public expandMode: boolean;
  public minimizeMode: boolean;
  public collapseMode: boolean;


  public isHtml: boolean = true;
  @Input() parentContext: any;
  @Input() currentContext: any;
  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions,
    public sanitizer: DomSanitizer,
    public dynamicResourceLoaderService: DynamicResourceLoaderServiceService) {


    this.resizable = true;
    this.expandMode = false;
    this.minimizeMode = false;
    this.collapseMode = false;

  }

  ngOnInit() {

    if (!this.subControl.Style)
      this.subControl.Style = {};
    this.hasFilters = (this.subControl.FilterControls && this.subControl.FilterControls.length > 0) ? true : false;

    this.name = this.subControl.Name;
    this.hasChildReports = (this.subControl.ReportsChild && this.subControl.ReportsChild.length > 0) ? true : false;
    this.value = this.subControl.Value;
    this.notLoad = this.subControl.NotLoad;
    // this.url = this.subControl.Url;
    if (this.subControl.Url) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.subControl.Url);

    }
  }
  ngAfterViewInit(): void {
    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }

    if (!this.isDesigner && !this.isHtmlFile) {

      setTimeout(() => {
        this.LoadAll();

      }, 1000);
    }
    if (!this.componentsContainer.Htmls.find(x => { return x.name == this.name }))
      this.componentsContainer.Htmls.push(this);
  }
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }

  ProcessData() {

    try {
      this.subControl.DataSources.forEach(ds => {


        if (ds.UseType == 'Table') {
          //creates table
          var table = $('<table></table>');

          var tr = $('<tr></tr>') //creates row
          var th = $('<th></th>') //creates table header cells
          var td = $('<td></td>') //creates table cells

          var header = tr.clone() //creates header row

          //fills header row
          Object.keys(ds.data[0]).forEach((d) => {
            header.append(th.clone().text(d))
          })

          //attaches header row
          table.append($('<thead></thead>').append(header))

          //creates 
          var tbody = $('<tbody></tbody>')

          //fills out the table body
          ds.data.forEach((d) => {

            var row = tr.clone() //creates a row
            d.forEach((e, j) => {

              row.append(td.clone().text(e)) //fills in the row
            })
            tbody.append(row) //puts row on the tbody
          })

          $("#" + ds.Tag).append(table.append(tbody))
        }
        else if (ds.UseType == 'Value') {
          let item = ds.data[0];
          ds.Tags.forEach(tag => {
            this.value = this.value.replace(tag.Tag, item[tag.Field]);
          });

        }
        else if (ds.UseType == 'ForEach') {

          let value = "";

          ds.data.forEach(item => {
            let htmlContent = ds.HtmlContent;
            ds.Tags.forEach(tag => {
              htmlContent = htmlContent.replace(tag.Tag, item[tag.Field]);
            });
            value += htmlContent;

          });
          this.value = this.value.replace(ds.Tag, value);
        }

      })
    }
    catch (error) {
      this.utility.logger.LogError(error, {}, { componentName: this.componentName, method: 'ProcessData' });
      this.utility.VerModalDanger().then(data => { })
    }
  }
  public OnLoad() {

  }
  LoadAll() {
    if (this.subControl.Resources && this.subControl.Resources.length > 0)
      this.LoadResources();
    else
      this.ExcecuteDataSource();
  }
  public LoadResources() {
    
    if (this.subControl.Resources && this.subControl.Resources.length > 0) {


      // this.dynamicResourceLoaderService.LoadAllByResource(this.subControl.Resources).then(_htmls => {

      //   
      // }).catch(error => {
      //   
      //   console.error(error);

      // });

      this.dynamicResourceLoaderService.loadHtmlsArrayByResource([], this.subControl.Resources).then(_htmls => {

        

        _htmls.forEach(html => {
          
          this.value += html.result;
        })
        
        this.value = this.sanitizer.bypassSecurityTrustHtml(this.value);
        
        setTimeout(() => {
          this.dynamicResourceLoaderService.LoadScriptsStylesByResource(this.subControl.Resources).then(dataResult => {

            
            setTimeout(() => {
              
              if (bestdoc_context) {
                bestdoc_context[this.name] = {};
                bestdoc_context[this.name] = this;
                bestdoc_context.Init(this, this.name);
                this.setExternalContext = true;
              }
              this.ExcecuteDataSource();

            }, 200);
          }).catch(error => {
            
            console.error(error);

          });

        }, 500);
      }).catch(error => {
        
        console.error(error);

      });

    }
  }
  testAlert() {
    alert("testAlert");
  }
  public ExcecuteDataSource(filterParent?: any) {

    if (!this.value && this.subControl.Value)
      this.value = this.subControl.Value;
    if (this.subControl.Url) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.subControl.Url);

    }
    let obj = this.utility.GetApiModel('ExcecuteDataSource', null);
    try {


      if (!this.subControl.DataSources)
        return;
      if (this.subControl.DataSources.length == 0 || this.notLoad) {

        return;
      }
      if (filterParent)
        this.filterParent = filterParent;
      obj.QueryConfigs = [];

      let index = 0;
      this.subControl.DataSources.forEach(ds => {


        let queryConfig = this.utility.Clone(ds);

        if (queryConfig.Filter == undefined || queryConfig.Filter == null)
          queryConfig.Filter = "";
        if (!queryConfig.Entity)
          queryConfig.Entity = {};
        queryConfig.Entity["IdEmpresa"] = StaticData.Usuario.IdEmpresa;
        if (queryConfig.Command)
          queryConfig.Name = queryConfig.Command;
        else if (queryConfig.Query)
          queryConfig.Name = queryConfig.Query.Name;
        else {
          queryConfig.Name = index;
          if (queryConfig.CrudAction == 'GetAll' || queryConfig.CrudAction == 'GetAllOrderBy'
            || queryConfig.CrudAction == 'GetAllOrderByDesc') {
            queryConfig.Entity = {};
          }
        }





        if (queryConfig.MappingParameters) {
          queryConfig.MappingParameters.forEach(parameter => {
            queryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, this.form, queryConfig,null,this,this.parentContext);
          })
        }

        if (this.filterParent) {
          if (this.filterParent.Filter) {
            if (queryConfig.Filter)
              queryConfig.Filter += " AND ";
            queryConfig.Filter += " " + this.filterParent.Filter;
          }
          else if (this.filterParent.Parameters) {

            this.filterParent.Parameters.forEach(parameter => {
              queryConfig.Entity[parameter.Name] = parameter.Value;
            });

          }
        }
        if (this.internalFilter && this.internalFilter.Filter) {
          if (this.internalFilter.Filter) {
            if (queryConfig.Filter)
              queryConfig.Filter += " AND ";
            queryConfig.Filter += " " + this.internalFilter.Filter;
          }
          else if (this.internalFilter.Parameters) {

            this.internalFilter.Parameters.forEach(parameter => {
              queryConfig.Entity[parameter.Name] = parameter.Value;
            });

          }
        }
        obj.QueryConfigs.push(queryConfig);
        //this.dataSet.push({ index: index, data: null });
        index++;
      });

      this.baseService.MultiGet(obj, null,
        {
          componentName: this.componentName,
          method: 'ExcecuteDataSource',
          controlName: this.subControl,
          processMessage: this.subControl.processMessage
        }, this.subControl.Controller)
        .then(data => {
          index = 0;

          data.Data.forEach(dtt => {

            // this.dataSet[index].data = dtt;
            this.subControl.DataSources[index].data = dtt;
            index++;

          });

          this.ProcessData();
        }).catch(err => {
          this.utility.logger.LogError(err, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
          this.utility.VerModalDanger().then(data => { })
        });

    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'ExcecuteDataSource' });
      this.utility.VerModalDanger().then(data => { })
    }
  }
  Search(event) {
    this.internalFilter = { Filter: event.filter };
    this.ExcecuteDataSource();
  }
  ClearSearch(event) {
    this.internalFilter = null;
    this.ExcecuteDataSource();
  }

  minHeight = null;
  OnChangeMinHeight(item) {

    if (this.minHeight) {

      if (!this.subControl.Style)
        this.subControl.Style = {};
      this.subControl.Style["min-height"] = this.minHeight;
    }
    else {
      delete this.subControl.Style["min-height"];
      this.subControl.Style = null;
    }

  }
  ExpandCollapse(event) {
    $("#" + this.name).toggleClass("expand");
    this.expandMode = !this.expandMode;

  }

  PrintNewWindow() {

    const elementContainer = document.getElementById(this.name);
    const w = window.open();
    w.document.write(elementContainer.innerHTML);
    w.print();
    w.close();
  }
  Print() {
    window.print();
  }

  Load(event) {

    //event.defaultPrevented=true;
  }
}
