import { Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter, QueryList, forwardRef, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { DataTableDirective } from 'angular-datatables';
import { config } from 'rxjs';
import { ConfigWindow } from '../../../models/config-window';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { Guid } from 'guid-typescript';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, QueryConfigIDETypes, TipoParaPermisos, TiposCrearNodos
} from '../../../models/general.enum';
import { ConfigBaseComponent } from '../../../models/config-base-component';


declare var OrgChart: any;
declare var BALKANGraph: any;
@Component({
  selector: 'app-org-chart-js',
  templateUrl: './org-chart-js.component.html',
  styleUrls: ['./org-chart-js.component.css']
})
export class OrgChartJsComponent extends ConfigBaseComponent implements OnInit, AfterViewInit, OnDestroy {


  @Input() title: string = "";
  @Input() name: string = "orgchart";
  @Input() selectionMode: boolean = false;
  @Input() selectedNodes = new List<any>([]);
  @Input() notSelectablesNodesTypes = new List<any>([]);
  @Input() templateOrg: string = 'isla';
  @Input() orientacionOrg: number = 0;
  chart: any;
  @Input() nodes: Array<any> = [];
  @Input() enableDragDrop: boolean = false;
  @Input() conMenuExport: boolean = false;
  @Input() conMenuNodo: boolean = false;



  zoomInEventEmitter = new EventEmitter<any>();
  zoomOutEventEmitter = new EventEmitter<any>();
  onSelectedComboBoxEventEmitter = new EventEmitter<any>();

  @Output() selectedNodeEventEmitter = new EventEmitter<any>();
  @Output() onClickNodeEventEmitter = new EventEmitter<any>();
  modeloConfig: any;
  ngOnInit() {

    // this.nodes = [
    //   { id: 0, text: 'Condiciones' },
    //   { id: 1, pid: 0, text: 'Condicion 1', tags: ["Condiciones"] },
    //   { id: 2, pid: 0, text: 'Condicion 2', tags: ["Condiciones"] },
    //   // { id: 3, pid: 1, text: 'Acciones SI' },
    //   // { id: 4, pid: 1, text: 'Acciones NO' },
    //   { id: 5, pid: 1, text: 'Accion 1', tags: ["AccionesSI"] },
    //   { id: 6, pid: 5, text: 'Accion 2', tags: ["AccionesSI"] },
    //   { id: 7, pid: 6, text: 'Accion 3', tags: ["AccionesSI"] },
    //   { id: 8, pid: 1, text: 'Accion 1', tags: ["AccionesNO"] },
    //   { id: 9, pid: 8, text: 'Accion 2', tags: ["AccionesNO"] },
    //   { id: 10, pid: 9, text: 'Accion 3', tags: ["AccionesNO"] },
    //   { id: 11, pid: 10, text: 'Accion Condicion', tags: ["AccionesNO"] },
    //   { id: 12, pid: 11, text: 'Condicion 1', tags: ["SubCondiciones"] },
    //   { id: 13, pid: 11, text: 'Condicion 2', tags: ["SubCondiciones"] },
    //   // { id: 14, pid: 13, text: 'Acciones SI' },
    //   // { id: 15, pid: 13, text: 'Acciones NO' },
    //   { id: 16, pid: 12, text: 'Accion 1', tags: ["SubAccionesSI"] },
    //   { id: 17, pid: 16, text: 'Accion 2', tags: ["SubAccionesSI"] },
    //   { id: 18, pid: 17, text: 'Accion 3', tags: ["SubAccionesSI"] },
    //   { id: 19, pid: 12, text: 'Accion 1', tags: ["SubAccionesNO"] },
    //   { id: 20, pid: 19, text: 'Accion 2', tags: ["SubAccionesNO"] },
    //   { id: 21, pid: 20, text: 'Accion 3', tags: ["SubAccionesNO"] }
    // ];



    //   this.nodes = [
    //    { id: 0, text: 'Condiciones' },
    //    { id: 1, pid: 0, text: 'Condicion 1', tags: ["Condiciones"] },
    //    { id: 2, pid: 0, text: 'Condicion 2', tags: ["Condiciones"] },
    //    { id: 3, pid: 1, text: 'Acciones SI' },
    //    { id: 4, pid: 1, text: 'Acciones NO' },
    //    { id: 5, pid: 3, text: 'Accion 1' },
    //    { id: 6, pid: 5, text: 'Accion 2' },
    //    { id: 7, pid: 6, text: 'Accion 3' },
    //    { id: 8, pid: 4, text: 'Accion 1' },
    //    { id: 9, pid: 8, text: 'Accion 2' },
    //    { id: 10, pid: 9, text: 'Accion 3' },
    //    { id: 11, pid: 9, text: 'Accion Condicion' },
    //    { id: 12, pid: 11, text: 'Condicion 1', tags: ["SubCondiciones"]  },
    //    { id: 13, pid: 11, text: 'Condicion 2', tags: ["SubCondiciones"]  },
    //    { id: 14, pid: 13, text: 'Acciones SI' },
    //    { id: 15, pid: 13, text: 'Acciones NO' },
    //    { id: 16, pid: 14, text: 'Accion 1' },
    //    { id: 17, pid: 16, text: 'Accion 2' },
    //    { id: 18, pid: 17, text: 'Accion 3' },
    //    { id: 19, pid: 15, text: 'Accion 1' },
    //    { id: 20, pid: 19, text: 'Accion 2' },
    //    { id: 21, pid: 20, text: 'Accion 3' }
    //  ];
    this.modeloConfig = {};

    this.zoomInEventEmitter.subscribe(modelo => {

      this.ZoomIn();
    });
    this.zoomOutEventEmitter.subscribe(modelo => {
      this.ZoomOut();
    });
    this.onSelectedComboBoxEventEmitter.subscribe(modelo => {
      this.OnSelectedComboBox(modelo.item, modelo.controlName, null);
    });
    this.modeloConfig.zoomInEventEmitter = this.zoomInEventEmitter;
    this.modeloConfig.zoomOutEventEmitter = this.zoomOutEventEmitter;
    this.modeloConfig.onSelectedComboBoxEventEmitter = this.onSelectedComboBoxEventEmitter;
  }


  public preview() {
    OrgChart.pdfPrevUI.show(this.chart, {
      format: 'A4'
    });
  }
  ngAfterViewInit() {



    setTimeout(() => {
      this.OnInitOrgChart();
      this.LoadNodes(this.nodes);

    }, 500)

  }

  public SetAreas() {
    this.nodes = [];
    this.CreateNodeTree(this.nodes, StaticData.AreasEmpresa.ToArray(), 'AreasEmpresa', this, TiposCrearNodos.Recursiva);
    this.chart.load(this.nodes);
  }
  public SetSeries() {
    this.nodes = [];

    let configSeries = {
      items: StaticData.SubSeries.ToArray(),
      idParent: 'IdParent',
      idParentExternal: 'IdSerie',
      nodeTypeData: this.utility.GetNodeType('SubSeries'),
      TiposCrearNodo: TiposCrearNodos.Recursiva
    };

    this.CreateNodeTree(this.nodes, StaticData.Series.ToArray(), 'Series', this, TiposCrearNodos.Normal, configSeries);
    this.chart.load(this.nodes);

  }

  public GetNodeByType(nodeTypeData: any, item?: any, parentId?: any): any {
    if (item && !item.ObjType)
      item.ObjType = nodeTypeData.ObjType;
    let resultNode = {
      id: Guid.create().toString(),// (nodeTypeData.UniqueID) ? nodeTypeData.UniqueID : nodeTypeData.ObjType,
      text: nodeTypeData.NodeText,
      icon: this.utility.GetPathImages(nodeTypeData),
      data: (item) ? item : nodeTypeData,
      class: StaticData.Estilos.ClassNodos,// 'container alert alert-primary',
      pid: (parentId) ? parentId : null,
      children: [],
      // tags:['QA'] 

    }
    return resultNode;
  }
  public GetNode(nodes: Array<any>, nodeType: any, item?: any, childrenItems?: Array<any>, allItems?: Array<any>, config?: any, configNodeType?: any): any {
    const that = this;
    let resultNode: any;
    let resultGeneralContent: any;
    let iconData: any;
    nodeType = (nodeType) ? nodeType : item.ObjType;
    let nodeTypeData = this.utility.GetNodeType(nodeType);// new List<any>(nodeTypes).Where(x => { return x.ObjType == nodeType }).FirstOrDefault();
    if (!nodeTypeData) {
      nodeTypeData = { UniqueID: item.UniqueID, ObjType: item.ObjType, Icon: item.Icon, NodeText: item.NodeText };
    }
    else if (item && item.NodeText) {
      nodeTypeData.NodeText = item.NodeText;
    }
    if (configNodeType) {
      let separator = (configNodeType.Separator) ? configNodeType.Separator : ' ';
      if (configNodeType.NodeTextField) {
        if (configNodeType.NodeTextField.split(';').length > 0) {
          nodeTypeData.NodeText = '';
          $.each(configNodeType.NodeTextField.split(';'), function (index, nodeTextField) {
            nodeTypeData.NodeText += item[nodeTextField] + separator;
          });
        }
        else
          nodeTypeData.NodeText = item[configNodeType.NodeTextField];
      }
      else if (configNodeType.NodeTextFields && configNodeType.NodeTextFields.length > 0) {

        nodeTypeData.NodeText = '';
        $.each(configNodeType.NodeTextFields.split(';'), function (index, nodeTextField) {
          let _separator = (nodeTextField.Separator) ? nodeTextField.Separator : separator;
          let _pre = (nodeTextField.Prefix) ? nodeTextField.Prefix : '';
          let _post = (nodeTextField.Postfix) ? nodeTextField.Postfix : '';
          nodeTypeData.NodeText += _pre + item[nodeTextField.Field] + _post + _separator;
        });
      }


      nodeTypeData.Icon = (configNodeType.Icon) ? configNodeType.Icon : nodeTypeData.Icon;
      nodeTypeData.ObjType = (configNodeType.ObjType) ? configNodeType.ObjType : nodeTypeData.ObjType;
      nodeTypeData.UniqueID = (configNodeType.UniqueIDField) ? item[configNodeType.UniqueIDField] : nodeTypeData.UniqueID;
    }



    resultNode = (item && item.children) ? item : this.GetNodeByType(nodeTypeData, item);


    if (item && config && config.items && config.items.length > 0) {

      let idParent = (config.idParent) ? config.idParent : 'IdParent';
      let firstItem = config.items[0];
      if (firstItem.ObjType !== item.ObjType) {
        idParent = (config.idParentExternal) ? config.idParentExternal : 'IdParent';
      }
      let externalChildrenItems = new List<any>(config.items).Where(x => { return x[idParent] == item.UniqueID }).ToArray();
      if (config.nodeTypeData && externalChildrenItems.length > 0) {

        //let parentResultNode = that.GetNodeByType(config.nodeTypeData);
        var node = that.GetNode(nodes, config.nodeTypeData.ObjType, null, externalChildrenItems, allItems, config);
        node.pid = resultNode.id;
        //resultNode.children.push(node);
        nodes.push(node);
      }
    }


    if (childrenItems && childrenItems.length > 0) {

      $.each(childrenItems, function (i, children) {

        let _childrenItems = (allItems) ? new List<any>(allItems).Where(x => { return x.IdParent == children.UniqueID && x.ObjType == children.ObjType }).ToArray() : new List<any>(childrenItems).Where(x => { return x.IdParent == children.UniqueID }).ToArray();

        var node = that.GetNode(nodes, null, children, _childrenItems, allItems, config);
        node.pid = resultNode.id;
        // resultNode.children.push(node);
        nodes.push(node);
      });

    }

    if (config) {
      if (config.count && item) {

        let tempText = item[config.count.fieldItem];
        resultNode.text += ' [' + config.count.items.Where(x => { return x[config.count.field] == tempText }).Count() + ']';
      }
      if (config.nodeConfig && item) {

        $.each(config.nodeConfig, function (i, _nodeConfig) {

          let isChild = item[_nodeConfig.fieldItem] == _nodeConfig.id;
          if (isChild) {
            _nodeConfig.node.pid = resultNode.id;
            // resultNode.children.push(_nodeConfig.node);
            nodes.push(_nodeConfig);
          }

        });


      }
    }

    return resultNode;
  }

  public CreateNodeTree(nodes: Array<any>, items: any[], objContainerType: string, that: OrgChartJsComponent,
    tiposCrearNodo: TiposCrearNodos = TiposCrearNodos.Normal, config?: any) {

    if (tiposCrearNodo == TiposCrearNodos.ListadoNodos) {
      let nodes: Array<any> = [];
      $.each(items, function (i, item) {
        var node = that.GetNode(nodes, null, item, null, null, null);
        node.pid = item.IdParent;
        nodes.push(node);
      });
      return nodes;
    }

    let AllItems = this.utility.Clone(items);
    let nodeChild = this.GetNode(nodes, objContainerType);
    //nodeChild.pid = nodeChild.id;
    //parentNode.children.push(nodeChild);
    nodes.push(nodeChild);
    if (tiposCrearNodo == TiposCrearNodos.Normal) {
      $.each(items, function (i, item) {

        var node = that.GetNode(nodes, null, item, null, AllItems, config);
        node.pid = nodeChild.id;
        nodes.push(node);
      });
    }
    else if (tiposCrearNodo == TiposCrearNodos.Recursiva) {

      $.each(items, function (i, item) {

        if (!item.IdParent) {

          let _childrenItems = new List<any>(items).Where(x => { return x.IdParent == item.UniqueID }).ToArray();

          var node = that.GetNode(nodes, null, item, _childrenItems, AllItems, config);

          node.pid = nodeChild.id;
          nodes.push(node);
        }
      });

    }

  }

  public ZoomIn() {
    this.chart.zoom(true);
  }
  public ZoomOut() {
    this.chart.zoom(false);
  }
  public OnSelectedComboBox(item, controlName, config) {

    if (item) {
      if (controlName == 'templateOrg')
        this.chart.config.template = item.Id;
      else if (controlName == 'orientacionOrg')
        this.chart.config.orientation = item.Id;
      else if (controlName == 'zoomOrg')
        this.chart.zoom(true);
      else if (controlName == 'InOrg')
        this.chart.zoom(false);

      //InOrg
      this.chart.draw();
    }
  }

  public SeleccionNodo(node: any) {

    const that = this;
    let currentNode = null;
    if (this.selectionMode !== true) {
      this.onClickNodeEventEmitter.emit({ node: node });
      return;
    }

    let current = this.selectedNodes.FirstOrDefault(x => { return x.node.id == node.id });
    if (current) {
      node.class = current.class;//;
      currentNode = new List<any>(that.chart.config.nodes).FirstOrDefault(x => { return x.id == node.id });
      if (currentNode) {
        that.chart.removeNodeTag(node.id, "Selected");
        // currentNode.tags = undefined;
        that.chart.draw();
        this.selectedNodes.Remove(current);

      }
    }
    else {

      if (!this.notSelectablesNodesTypes.Any(x => { return x == node.data.ObjType })) {


        currentNode = new List<any>(that.chart.config.nodes).FirstOrDefault(x => { return x.id == node.id });
        //that.chart.addNodeTag(node.id, "Selected");
        //currentNode.text = 'SHAHSHASHHS';
        currentNode.tags = ['Selected'];
        that.chart.updateNode(currentNode);
        // currentNode.tags =['Selected'] ;//["derek-template"];
        //  that.chart.config.tags["derek-template"] = { template: "derek" };
        that.chart.draw();
        this.selectedNodes.Add({ node: node, class: node.class });
        // node.class = StaticData.Estilos.ClassNodosNuevos;
      }
    }

    if (this.selectedNodeEventEmitter.observers.length > 0)
      this.selectedNodeEventEmitter.emit({ node: node, selectedNodes: this.selectedNodes });
  }

  public GetMenu(context?: any) {
    const that = (context) ? context : this;
    if (!that.conMenuExport)
      return null;
    let menu = {
      pdfPreview: {
        text: "PDF Preview",
        icon: OrgChart.icon.pdf(24, 24, '#7A7A7A'),
        onClick: this.preview
      },
      pdf: { text: "Exportar PDF" },
      png: { text: "Exportar PNG" },
      svg: { text: "Exportar SVG" },
      csv: { text: "Exportar CSV" }
    }
    return menu;
  }
  public GetNodeMenu(context?: any) {
    const that = (context) ? context : this;
    if (!that.conMenuNodo)
      return null;
    let nodeMenu = {
      edit: { text: "Editar" },
      add: { text: "Nuevo" },
      remove: { text: "Eliminar" },
      restore: {
        text: "Restaurar",
        icon: '',
        onClick: function (nodeId) {
          
          that.callHandler(nodeId, that);
        }
      }
    }
    return nodeMenu;
  }
  public callHandler(nodeId, context?: any) {

    const that = (context) ? context : this;

    
  }
  // showXScroll: BALKANGraph.scroll.visible,
  // showYScroll: BALKANGraph.scroll.visible,
  // mouseScrool: BALKANGraph.action.zoom,



  public LoadNodes(_nodes?: Array<any>) {
    this.nodes = _nodes;
    if (this.nodes && this.chart)
      this.chart.load(this.nodes);
  }

  isSetTags: boolean = false;
  public SetRemoveTags() {
    if (this.isSetTags)
      this.RemoveTags();
    else
      this.SetTags();
  }
  public RemoveTags() {
    this.isSetTags = false;
    this.chart.config.tags = {};
    this.chart.config.tags["derek-template"] = { template: "derek" };
    this.chart.draw();
  }
  public SetTags() {

    this.isSetTags = true;
    this.chart.config.tags["derek-template"] = { template: "derek" };
    this.chart.config.tags["Condiciones"] = {
      group: true,
      groupName: "Condiciones",
      groupState: OrgChart.EXPAND,

      template: "group_grey"
    }

    this.chart.config.tags['SubCondiciones'] = {
      group: true,
      groupName: "SubCondiciones",
      groupState: OrgChart.EXPAND,

      template: "group_grey"
    }
    this.chart.config.tags['AccionesSI'] = {
      group: true,
      groupName: "AccionesSI",
      groupState: OrgChart.EXPAND,

      template: "group_orange"
    }
    this.chart.config.tags['AccionesNO'] = {
      group: true,
      groupName: "AccionesNO",
      groupState: OrgChart.EXPAND,

      template: "group_orange"
    }
    this.chart.config.tags['SubAccionesSI'] = {
      group: true,
      groupName: "SubAccionesSI",
      groupState: OrgChart.EXPAND,

      template: "group_orange"
    }
    this.chart.config.tags['SubAccionesNO'] = {
      group: true,
      groupName: "SubAccionesNO",
      groupState: OrgChart.EXPAND,

      template: "group_orange"
    }
    
    OrgChart.templates.group_orange.columns = 1;
    this.chart.draw();
  }
  public SetNewNodes() {
    this.nodes = [
      { id: 0 },
      { id: 1, },
      { id: 2, },
      { id: 3, },
      { id: 4, },
      { id: 5, },
      { id: 6 }


    ]
    this.chart.config.slinks = [
      { from: 6, to: 0, label: 'text' },
      { from: 5, to: 1, template: 'yellow', label: 'lorem ipsum' }]
    this.LoadNodes(this.nodes);
  }
  public OnInitOrgChart(_nodes?: Array<any>) {
    if (_nodes)
      this.nodes = _nodes;
    const that = this;
    
    let elem = document.getElementById(this.name);
    if (!elem) {
      return;
    }
    this.chart = new OrgChart(document.getElementById(this.name), {
      template: that.templateOrg,
      enableDragDrop: that.enableDragDrop,
      // layout: BALKANGraph.normal,


      orientation: that.orientacionOrg,
      tags: {},
      slinks: [],
      /* slinks: [
        { from: 2, to: 5, label: 'text' },
        { from: 2, to: 8, template: 'blue', label: '4 reports to 3' }
      ], */
      menu: that.GetMenu(that),
      nodeMenu: that.GetNodeMenu(that),
      nodeBinding: {
        field_0: "text",
        field_1: "",
        field_2: "title",
        field_3: "objectRule",
        img_0: "icon"
      },
      nodes: that.nodes
    });


    this.chart.on('drop', (sender, draggedNodeId, droppedNodeId) => {
      
      let data = this;
      // return false;
      if (draggedNodeId == 1) {
        return false;
      }

      if (droppedNodeId == 4) {
        return false;
      }
    });
    this.chart.on('update', (sender, oldNode, newNode) => {

      return true;
    });

    this.chart.on('click', (sender, node) => {

      
      that.SeleccionNodo(node);

      return false;
    });
    //edit
    this.chart.on('edit', (sender, node) => {

      

      return false;
    });
    this.chart.on('add', (sender, node) => {

      

      return false;
    });

    this.chart.on('remove', (sender, nodeId) => {
      sender.removeNode(nodeId);
      return true;
    });
  };


  public VerConfig() {

    const that = this;
    this.selectedNodes = new List<any>([]);
    let configWindow = new ConfigWindow();


    configWindow.height = 300;
    configWindow.width = 400;
    configWindow.titleWindow = 'Configuracion';
    configWindow.resizable = true;
    configWindow.modal = false;
    configWindow.closeable = true;
    configWindow.draggable = true
    configWindow.viewOk = false;
    configWindow.viewCancel = false;


    this.utility.OpenWindow('OrgChartJsConfigComponent', configWindow, this.modeloConfig).then(cretedModel => {

      cretedModel.onClosePromise.then(_modelo => {

        if (_modelo.dialogResult == true) {


        }


      })

    });
  }
  ngOnDestroy() {

    this.chart = null;

  }


}
