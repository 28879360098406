import { Component, OnInit } from '@angular/core';
import { DynamicControlsComponent } from '../dynamic-controls/dynamic-controls.component';

@Component({
  selector: 'app-dynamic-charts',
  templateUrl: './dynamic-charts.component.html',
  styleUrls: ['./dynamic-charts.component.css']
})
export class DynamicChartsComponent extends DynamicControlsComponent implements OnInit {





}
