import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';

import { BotonesFormularioBase } from '../../../models/botones-formulario-base';
import { ComponentsContainer } from 'src/app/models/components-container';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { TipoObjetoParaPermisos } from 'src/app/models/general.enum';
import { Utilities } from 'src/app/helpers/utilities';

@Component({
  selector: 'app-botones-formulario-crud',
  templateUrl: './botones-formulario-crud.component.html',
  styleUrls: ['./botones-formulario-crud.component.scss']
})
export class BotonesFormularioCrudComponent extends BotonesFormularioBase implements OnInit, AfterViewInit {

  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() botonProcesando: boolean = false;
  @Input() _textoBoton: string = 'Guardar';
  @Input() disabledValidation: boolean = false;

  @Input() set textoBoton(text: string) {
    if (this.config && this.config.LabelSave) {
      this._textoBoton = this.config.LabelSave;
    }
    else
      this._textoBoton = text;
  }

  get textoBoton(): string {

    // if(!this._textoBoton )
    // this._textoBoton  ="Guardar";
    return this._textoBoton;

  }

  @Input() textoBotonProcesando: string = '';
  @Input() botonProcesandoImportar: boolean = false;
  @Input() textoBotonImportar: string = 'Importar';
  @Input() textoBotonProcesandoImportar: string = '';
  @Output() Guardar: EventEmitter<any> = new EventEmitter<any>();
  @Output() LimpiarConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLimpiarConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() ModalFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() Importar: EventEmitter<any> = new EventEmitter<any>();
  @Output() Redireccionar: EventEmitter<any> = new EventEmitter<any>();
  @Input() verRedirect: boolean = true;
  @Input() textoRedirect: string = 'Redireccionar';
  @Input() guardarValido: boolean = true;
  @Input() componentsContainer: ComponentsContainer;
  @Input() name: string = '';
  @Input() configPermisos: any;
  @Input() config: any;
  @Input() viewButton: any;

  validationItems: Array<any> = [];
  textoLimpiar = "Limpiar";
  @Input() verBotonGuardar: boolean = true;

  @Input() nombreBotonGuardar: string = "btnGuardar";
  @Input() nombreBotonLimpiar: string = "btnLimpiar";


  constructor(public helperActions: HelperActions,
    public utility: Utilities,
    public comunicationService: GeneralComunicationService) { super(); }

  //* FUNCIONES ANGULAR

  ngOnInit() {

    let temp = this;
    // if (this.Pagina && this.Pagina.Pagina) {
    //   this.nombreBotonGuardar = this.nombreBotonGuardar + "_" + this.Pagina.Pagina;
    //   this.nombreBotonLimpiar = this.nombreBotonLimpiar + "_" + this.Pagina.Pagina;
    // }

    if (this.config) {
      this.viewButton = this.config.Controls.find(item => item.SubControlType == 'ButtonOption');
      if (!this.config.TitleClear)
        this.config.TitleClear = '';
      if (!this.config.TitleSave)
        this.config.TitleSave = '';
      if (this.config.LabelClear)
        this.textoLimpiar = this.config.LabelClear;

      if (this.config.NombreBotonGuardar) {
        this.nombreBotonGuardar = this.config.NombreBotonGuardar;
      }
      if (this.config.NombreBotonLimpiar) {
        this.nombreBotonLimpiar = this.config.NombreBotonLimpiar;
      }
    }

    this.comunicationService.receivedValidateSaveEvent.subscribe(item => {

      let index: number = this.validationItems.findIndex(x => { return x.Name == item.Name });

      if (index > -1) {
        this.validationItems[index] = item;
      } else {
        this.validationItems.push(item);
      }

      this.guardarValido = (this.validationItems.length == this.validationItems.filter(x => { return x.Ok == true; }).length);

      this.utility.logger.ConsoleLogPermisos(`Guardar Valido:${this.guardarValido}`, "Disabled", "Disabled", this.guardarValido, "GuardarValido", "GuardarValido");

    });

    this.comunicationService.receivedFormEvent.subscribe(event => {
      //
      switch (event.Action) {

        case 'OnCreatePermissionsConfig':
        case 'OnUpdatePermissionsConfig':
        case 'Edit':
        case 'Clear':
          //


          if (this.config.DynamicHideSave) {
            debugger;
            let hideSave = this.config.DynamicHideSave.split(".").reduce(
              (o, i) => o[i],
              event.modelo
            );
            // if(hideSave && this.config.HideSaveForValidation) {
            //   hideSave = !eval(this.config.HideSaveForValidation);
            // }
            if (hideSave === undefined) {
              console.warn(
                `No se encontró la propiedad ${this.config.DynamicHideSave} dentro del modelo:`,
                event.modelo
              );
            } else this.config.HideSave = !(hideSave);
          }
          if (this.config.HideSaveForValidation) {
            debugger;
            this.config.HideSave = !eval(this.config.HideSaveForValidation);
          }

          if (this.config.DynamicHideClear) {
            let hideClear = this.config.DynamicHideClear.split(".").reduce(
              (o, i) => o[i],
              event.modelo
            );
            if (hideClear === undefined) {
              console.warn(
                `No se encontró la propiedad ${this.config.DynamicHideClear} dentro del modelo:`,
                event.modelo
              );
            } else this.config.HideClear = !(hideClear);
          }

          if (event.permissionsConfig)
            this.configPermisos = event.permissionsConfig;
          this.SetPermisos(event.modelo.modelo);
          break;

      }

    });


  }

  public SetPermisos(modelo: any) {
    if (this.configPermisos &&
      (this.configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Expediente ||
        this.configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Documento)) {
      let TipoObjetoParaPermiso: string = TipoObjetoParaPermisos[this.configPermisos.TipoObjetoParaPermiso];
      let textoLog = `BotonGuardar`
      if (modelo && modelo.PkValue)
        textoLog += `,Id:${modelo.PkValue}`
      if (modelo && modelo.Nombre)
        textoLog += `,Nombre:${modelo.Nombre}`
      let ok = this.utility.TienePemisoUsuarioDos(this.configPermisos, modelo, 'Guardar', false, textoLog);
      if (this.configPermisos.TipoObjetoParaPermiso === TipoObjetoParaPermisos.Documento && this.configPermisos.expediente && (
        modelo.PkValue == 0 || modelo.PkValue == null)) {
        ok = this.utility.TienePemisoUsuarioDos(this.configPermisos, this.configPermisos.expediente, 'CrearDoc' ,false, textoLog);
      }
      this.verBotonGuardar = ok;
    }
  }
  ngAfterViewInit() {


    setTimeout(() => {

      //
      this.SetPermisos({});

    }, 200);

  }
  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public internalLimpiar() {

    alert('dsfsdf');
    this.onLimpiarConfirm.emit();

  }

  public ViewButtons(controlType) {

    if (this.config)
      return this.config.Controls.filter(item => item.SubControlType == controlType).length;
    else
      return null;

  }

  public GetButtons(controlType) {

    if (this.config)
      return this.config.Controls.filter(item => item.SubControlType == controlType);
    else
      return [];

  }

  public ButtonClick(option: any) {

    let notEmitAction: any = (option) ? option.NotEmitAction : this.config.NotEmitAction;
    if (notEmitAction) {
      this.helperActions.ExecuteActionsControl(option, false, null, null, this.configPermisos,
        this, null, this.onActionEventEmitter, this.componentsContainer, option, null, this.config);
    }
    else {
      this.onActionEventEmitter.emit({
        config: this.config,
        ControlType: this.config.ControlType, Name: this.config.Name, Action: 'Click',
        Option: option,
        SubControlType: this.config.SubControlType
      });
    }

  }

  //* END FUNCIONES PERSONALIZADAS

}
