import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges, forwardRef, Inject, ViewChild } from "@angular/core";
import { Utilities } from "../../../helpers/utilities";
import { StaticData } from "../../../helpers/static-data";
import { Subject } from "rxjs";
import { GeneralComunicationService } from "src/app/services/general-comunication.service";
import { Guid } from "guid-typescript";
import { ScanDocumentsService } from "src/app/services/scan-documents.service";
import { ModalNotifyTypes } from "../../modal-notify/modal-notify.component";

import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { FormActionsThroughEmitters } from "src/app/models/general.enum";
import { FileUploaderChunkComponent } from "../file-uploader-chunk/file-uploader-chunk.component";

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
  //styles: [`.constraints-info{margin-top:10px;font-style:italic}.padMarg{padding:0;margin-bottom:0}.caption{margin-right:5px}.textOverflow{white-space:nowrap;padding-right:0;overflow:hidden;text-overflow:ellipsis}.up_btn{color:#000;background-color:transparent;border:2px solid #5c5b5b;border-radius:22px}.delFileIcon{text-decoration:none;color:#ce0909}.dragNDrop .div1{display:border-box;border:2px dashed #5c5b5b;height:6rem;width:20rem}.dragNDrop .div1>p{text-align:center;font-weight:700;color:#5c5b5b;margin-top:1.4em}.dragNDropBtmPad{padding-bottom:2rem}@media screen and (max-width:620px){.caption{padding:0}}@media screen and (max-width:510px){.sizeC{width:25%}}@media screen and (max-width:260px){.caption,.sizeC{font-size:10px}}.resetBtn{margin-left:3px}`]
})

export class FileUploaderComponent implements OnInit, OnChanges {



  @Input() modelo: any
  @Input() modeloArchivo: Array<any> = [];
  @Input() config: any = {};
  @Input() name: string;
  resetUpload: boolean = this.config["resetUpload"];
  @Input() public isModelContainer = false;
  @Input() public parentCallRefresh: Subject<any>;

  @Output() onInitialized = new EventEmitter<any>();
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() ApiResponse: EventEmitter<any>;
  @Output() OnProgressEventEmitter: EventEmitter<any>;
  @Output() UploadFilesEventEmitter: EventEmitter<any>;
  @Output() OnChangeEventEmitter: EventEmitter<any>;
  @Output() OnRemoveEventEmitter: EventEmitter<any>;
  @Output() OnClearEventEmitter: EventEmitter<any>;
  @Input() theme: string;
  @Input() id: number;
  @Input() view = true;
  @Input() allowSave = true;
  @Input() sendCurrentModel = false;
  @Input() hideProgressBar: boolean;
  @Input() maxSize: number;
  @Input() maxLength: number;
  @Input() uploadAPI: string;
  @Input() changeNotificationLoad: boolean;
  @Input() formatsAllowed: string;
  @Input() multiple: boolean;
  @Input() headers: any;
  @Input() hideResetBtn: boolean;
  @Input() hideSelectBtn: boolean;
  @Input() hideUploadBtn: boolean;
  @Input() hideIfNotIsEdit: boolean;
  @Input() disableIfNotIsEdit: boolean;
  @Input() idDate: number;
  @Input() reg: RegExp;
  public selectedFiles: Array<any>;
  public notAllowedList: Array<Object>;
  @Input() Caption: Array<string>;
  public singleFile: boolean;
  @Input() progressBarShow: boolean;
  @Input() uploadBtn: boolean;
  @Input() uploadMsg: boolean;
  @Input() afterUpload: boolean;
  @Input() uploadClick: boolean;
  @Input() uploadClickFormatMsg: boolean;
  @Input() uploadMsgText: string;
  @Input() uploadMsgClass: string;
  @Input() percentComplete: number;
  @Input() replaceTexts: any;
  @Input() invalidFormatMsg: string;
  @Input() invalidPrincipalMessage: string;
  @Input() DefaultPrincipal: boolean;
  @Input() invalidSizeMsg: string;
  @Input() notManageResponseInternal: boolean;
  @Input() currentComponentName: string;
  public principalFile: string = undefined;
  public esGrande: boolean = false;
  public templateFile: string;
  @Input() isPrincipal = true;
  @Input() isExtensibleTable = false;
  @Input() isCustomPage = false;
  @Input() isForWorkFlow = false;
  @Input() setControlNameAsFileModelName = false;
  @Input() notPrincipal = false;
  @Input() notTemplate = true;
  @Input() scannerPort: string = null;
  @Input() withScanner = false;
  @Input() viewStatusScanner = false;
  @Input() viewStatusScannerNotify = false;
  configStatusScanner: any = null;
  validations = [];
  isHidden = false;
  isDisabled = false;
  folderArchivo: string;
  scanDocumentsService: ScanDocumentsService = null;
  fileChunk: FileUploaderChunkComponent = null
  scannerFileName: string;
  DictionaryAllowedFormats: Object;
  progress: number;
  progressVisual = false;
  validatePrincipalMessage = false;
  selectedFileChunck: any


  AllowsConvertDocument: any = { ColSpan: 4, Label: 'Convertir Documentos Word a Pdf', Activo: true }
  modeloAllowsConvertDocument: any = null;

  constructor(@Inject(forwardRef(() => Utilities)) public utility: Utilities,
    public comunicationService: GeneralComunicationService, private http: HttpClient) {

    this.config = {};
    this.resetUpload = this.config["resetUpload"];
    this.ApiResponse = new EventEmitter();
    this.OnProgressEventEmitter = new EventEmitter();
    this.UploadFilesEventEmitter = new EventEmitter();
    this.OnChangeEventEmitter = new EventEmitter();
    this.OnRemoveEventEmitter = new EventEmitter();
    this.OnClearEventEmitter = new EventEmitter<any>();
    this.idDate = +new Date();
    this.reg = /(?:\.([^.]+))?$/;
    this.selectedFiles = [];
    this.notAllowedList = [];
    this.Caption = [];
    this.singleFile = true;
    this.progressBarShow = false;
    this.uploadBtn = false;
    this.uploadMsg = false;
    this.afterUpload = false;
    this.uploadClick = true;
    this.uploadClickFormatMsg = true;
    this.notManageResponseInternal = false;
    this.invalidFormatMsg = "Extensión no permitida";
    this.invalidSizeMsg = "Tamaño límite inválido";
    this.invalidPrincipalMessage = "Archivo Principal Requerido";
    this.DefaultPrincipal = false;

  }

  //* FUNCIONES ANGULAR

  ngOnInit() {

    this.folderArchivo = this.utility.GetPathImages(this.utility.GetNodeType("subirFolderArchivo"));

    //console.log("Id: ", this.id); this.OnSetConfig();
    this.resetUpload = false;
    this.OnSetConfig();
    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }

    setTimeout(() => {
      this.ValidateMaxLength();
      this.ExecuteScripts();

    }, 1500);

    this.comunicationService.receivedFormEvent.subscribe(event => {

      this.config.EditRequired = false;
      if (this.hideIfNotIsEdit) {
        switch (event.Action) {
          case "Edit":
            this.isHidden = false;
            break;
          case "Clear":
            this.isHidden = true;
            this.config.EditRequired = true;

            break;
        }
      }
      else if (this.disableIfNotIsEdit) {
        switch (event.Action) {
          case "Edit":
            this.isDisabled = false;

            break;
          case "Clear":
            this.isDisabled = true;
            this.config.EditRequired = true;

            break;
        }
      }

      if (
        event.Action === FormActionsThroughEmitters.Clear ||
        event.Action === FormActionsThroughEmitters.Edit
      )
        this.InternalResetFileUpload();
    });
    this.isHidden = this.hideIfNotIsEdit;
    this.isDisabled = this.disableIfNotIsEdit;

    this.fileChunk = new FileUploaderChunkComponent(this.http, this.utility)
    this.fileChunk.processDocumentChunk.subscribe(data => {
      this.progressVisual = true;
      this.progress = data.progressUpload;

      if (this.progressVisual && data.progressUpload === 100 && data.lastPart && data.finally) {
        this.progressVisual = false;
        if (this.modeloArchivo.length == 0) {
          this.GenerateModeldocument(data.target);
        }
        else if (this.modeloArchivo.find(x => x.Name == data.target.name) == null) {
          this.GenerateModeldocument(data.target);
        }
      } else if (this.progressVisual && data.progressUpload === 99.99 && data.lastPart && data.finally) {
        this.progressVisual = false;
        this.clear();
        this.InternalResetFileUpload();/*
        if (this.modeloArchivo.length == 0) {
          this.GenerateModeldocument(data.target);
        }
        else if (this.modeloArchivo.find(x => x.Name == data.target.name) == null) {
          this.GenerateModeldocument(data.target);
        }*/
      }
    })

  }
  public GenerateModeldocument(target: any) {
    let currentFileExt = this.reg.exec(target.name);
    this.selectedFiles.push(target);
    this.Caption.push(target.name);
    this.modeloArchivo.push({
      Name: target.name, Size: target.size, Ext: currentFileExt[1],
      EsAdjunto: (this.principalFile != target.name),
      EsPlantilla: (this.templateFile == target.name),
      EsGrande: true
    });
    this.ValidateMaxLength();

    this.esGrande = true;
  }
  ExecuteScripts() {
    this.ValidateScriptRequired(this.config);
    this.ScriptGeneral(this.config);
  }
  ngOnChanges(rst) {

    if (rst["config"]) {
      this.OnSetConfig();
    }
    if (rst["resetUpload"]) {
      if (rst["resetUpload"].currentValue == true) {
        this.resetFileUpload();
      }
    }

  }

  //* END FUNCIONES ANGULAR

  //* FUNCIONES PERSONALIZADAS

  public ChangePrincipal(file, index) {
    var ok = false
    this.modeloArchivo.forEach(item => {
      item.EsAdjunto = (this.principalFile != item.Name);
    });
    //debugger;
    if (this.principalFile)
      ok = true
    this.ValidatePrincipal(ok);

    //this.modeloArchivo[index].EsAdjunto = (this.principalFile != file.Name);

  }
  public ChangeTemplate(file, index) {
    debugger;

    if (this.config.FormatsTemplate) {

      var TemplateFormats = this.config.FormatsTemplate.replace(/ /g, '')
      TemplateFormats = Object.assign({}, ...TemplateFormats.split(",").map((x, i) => ({ [x]: true })));
      this.modeloArchivo.forEach(item => {
        item.EsPlantilla = (this.templateFile == item.Name);
        if (item.EsPlantilla) {
          if (!TemplateFormats["." + item.Ext.toLowerCase()]) {
            this.RaiseValidateSaveEvent(false);
            this.uploadClickFormatMsg = false;
            this.notAllowedList = [];
            this.notAllowedList.push({
              fileName: item.Name,
              fileSize: this.convertSize(item.Size),
              errorMsg: "Las extensiones validas para las plantillas son " + this.config.FormatsTemplate + " seleccione un archivo que coincida con alguna de las anteriores "
            });
          }
          else {
            this.uploadClickFormatMsg = true;
            this.RaiseValidateSaveEvent(true);
            this.notAllowedList = [];
          }
        }
      });
    }
    else {
      this.modeloArchivo.forEach(item => {
        item.EsPlantilla = (this.templateFile == item.Name);
      });
    }

    //  var TemplateFormats = this.config.FormatsTemplate.split(",");



  }
  public RaiseValidateSaveEvent(isValid = true) {

    if (this.config.Required) {

      this.comunicationService.raiseValidateSaveEvent({ Name: this.name, Ok: (this.selectedFiles.length > 0 && isValid) });
    }

  }
  public RaiseForceValidateSaveEvent(isValid = true, required = true) {


    this.config.Required = required;

    this.comunicationService.raiseValidateSaveEvent({ Name: this.name, Ok: isValid });

  }

  InternalValidateScriptRequired(config) {
    let ok = true;
    if (config.ScriptRequired) {
      if (config.IsEvalScriptRequired)
        ok = eval(config.ScriptRequired);
      else {
        const functionGeneric = new Function(config.ScriptRequired);
        ok = functionGeneric();
      }
    }
    return ok;
  }
  public ValidateScriptRequired(config) {
    if (config.ScriptRequired) {
      const ok = this.InternalValidateScriptRequired(config);
      this.comunicationService.raiseValidateSaveEvent({ Name: this.name, Ok: ok });
    }
  }

  public ScriptGeneral(config) {

    if (config.ScriptGeneral) {
      if (config.IsEvalScriptGeneral)
        eval(config.ScriptGeneral);
      else {
        const functionGeneric = new Function(config.ScriptGeneral);
        functionGeneric();
      }
    }

  }
  public blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }
  public ValidateMaxLength(file?: any, _currentFileExt?: any) {

    let ok = true;
    this.validations = [];
    this.RaiseValidateSaveEvent();

    this.config.MaxLengthValidation = null;
    if (this.maxLength > 0 && this.selectedFiles.length >= this.maxLength) {
      ok = false;
      if (this.config.ShowValidationInNotification)
        StaticData.ShowActionNotification2("El Maximo numero permitido de archivos es de " + this.maxLength, "warning", "Alerta", 550);
      if (this.config.ShowValidationInToolTip)
        this.config.MaxLengthValidation = "El Maximo numero permitido de archivos es de " + this.maxLength;
      if (this.config.ShowValidationInTable)
        this.validations.push("El Maximo numero permitido de archivos es de " + this.maxLength);

      this.RaiseValidateSaveEvent(false);

    }
    else if (this.maxLength > 0 && this.selectedFiles.length >= this.maxLength) {
      ok = false;
      if (this.config.ShowValidationInNotification)
        StaticData.ShowActionNotification2("El Maximo numero permitido de archivos es de " + this.maxLength, "warning", "Alerta", 550);
      if (this.config.ShowValidationInToolTip)
        this.config.MaxLengthValidation = "El Maximo numero permitido de archivos es de " + this.maxLength;
      if (this.config.ShowValidationInTable)
        this.validations.push("El Maximo numero permitido de archivos es de " + this.maxLength);

      this.RaiseValidateSaveEvent(false);

    }
    else if (this.config.RequiredExtensions && this.config.RequiredExtensions.length >= 0) {
      let formatsCount;
      let currentFileExt;
      let ext;
      const listExt = [];

      this.config.RequiredExtensions.forEach(validation => {
        if (!validation.ListExt)
          validation.ListExt = validation.Ext.split(",");
        if (!validation.Type)
          validation.Type = validation.ListExt[0];
        if (validation.Min > 0 || validation.Max > 0) {
          validation.Total = 0;
          validation.ListExt.forEach(ext => {
            listExt.push(ext);
            validation.Total += this.modeloArchivo.filter(x => { return x.Ext == ext || "." + x.Ext == ext; }).length;
          });
          if (validation.Min == validation.Max) {
            if (validation.Total > validation.Max) {
              if (_currentFileExt && validation.ListExt.find(x => { return x.Name == _currentFileExt; }))
                ok = false;
              this.validations.push("El Maximo numero permitido de archivos " + validation.Type + " es de " + validation.Min + " y hay " + validation.Total);
            }
            else if (validation.Total == validation.Max) {
              if (_currentFileExt && validation.ListExt.find(x => { return x.Name == _currentFileExt; }))
                ok = false;
              //validations.push("El Maximo numero permitido de archivos " + validation.Type + " es de " + validation.Min+" y hay "+validation.Total);
            }
            else if (validation.Total != validation.Min) {
              this.validations.push("El Maximo numero permitido de archivos " + validation.Type + " es de " + validation.Min + " y hay " + validation.Total);
            }
          }
          else if (validation.Min > validation.Total) {
            if (_currentFileExt && validation.ListExt.find(x => { return x.Name == _currentFileExt; }))
              ok = false;
            this.validations.push("El Minimo numero requerido de archivos " + validation.Type + " es de " + validation.Min + " y hay " + validation.Total);
          }
          else if (validation.Max < validation.Total) {
            if (_currentFileExt && validation.ListExt.find(x => { return x.Name == _currentFileExt; }))
              ok = false;
            this.validations.push("El Maximo numero permitido de archivos " + validation.Type + " es de " + validation.Max + " y hay " + validation.Total);
          }
          else if (validation.Max == validation.Total) {
            if (_currentFileExt && validation.ListExt.find(x => { return x.Name == _currentFileExt; }))
              ok = false;
            //validations.push("El Maximo numero permitido de archivos " + validation.Type + " es de " + validation.Max + " y hay " + validation.Total);
          }
          //validation.ListExt =validation.Ext.split(",");
        }
      });
      if (this.validations.length > 0) {

        const msg = this.validations.join("\r\n");
        if (this.config.ShowValidationInNotification)
          StaticData.ShowActionNotification2(msg, "warning", "Alerta", 550);
        if (this.config.ShowValidationInToolTip)
          this.config.MaxLengthValidation = msg;

        this.RaiseValidateSaveEvent(false);

      }
      if (file && this.modeloArchivo.find(x => { return x.Name == file.name; })) {
        ok = false;
        StaticData.ShowActionNotification2("Ya existe un archivo con el nombre " + file.name, "warning", "Alerta", 550);
      }
    }


    return ok;

  }

  public ValidatePrincipal(validation: boolean) {

    // this.config.ValidatePrincipal = true;
    if (this.config.ValidatePrincipal) {
      this.RaiseValidateSaveEvent(validation);
      this.validatePrincipalMessage = !validation;
    }

  }

  PropiedadTieneValor(name): boolean {
    return (this.config[name] != null && this.config[name] != undefined) ? true : false;
  }
  OnSetConfig() {

    if (!this.name)
      this.name = this.config["Name"] || Guid.create().toString();
    this.theme = this.config["theme"] || "";
    this.id =
      this.config["id"] ||
      parseInt((this.idDate / 10000).toString().split(".")[1]) +
      Math.floor(Math.random() * 20) * 10000;

    this.notPrincipal = this.PropiedadTieneValor("NotPrincipal") ? this.config["NotPrincipal"] : false;
    this.notTemplate = this.PropiedadTieneValor("NotTemplate") ? this.config["NotTemplate"] : true;
    this.sendCurrentModel = this.config["SendCurrentModel"] || false;
    this.hideProgressBar = this.config["hideProgressBar"] || false;
    this.hideResetBtn = this.config["hideResetBtn"] || false;
    this.hideSelectBtn = this.config["hideSelectBtn"] || false;
    this.hideUploadBtn = this.config["hideUploadBtn"] == false ? this.config["hideUploadBtn"] : true;


    this.hideIfNotIsEdit = this.config["hideIfNotIsEdit"] || false;
    this.notManageResponseInternal = this.config["NotManageResponseInternal"] || false;
    this.disableIfNotIsEdit = this.config["disableIfNotIsEdit"] || false;
    this.maxSize = this.config["maxSize"] || 30;  //Tamaño en Gb, para validacion de maximo soportado
    this.maxLength = this.config["maxLength"] || 0;
    this.uploadAPI = this.config["uploadAPI"]["url"];
    this.changeNotificationLoad = this.config["CambiarNotificacionCarga"] || false;
    if (this.currentComponentName && this.uploadAPI)
      this.uploadAPI = this.uploadAPI.replace("#Controller#", this.currentComponentName);
    if (this.uploadAPI)
      this.uploadAPI = this.uploadAPI.replace("#UrlServer#", StaticData.UrlServer);
    this.formatsAllowed =
      //   this.config["formatsAllowed"] || ".jpg,.png,.pdf,.docx,.txt,.gif,.jpeg,.zip,.rar,.doc,.xls,.xlsx,.sql,.aspx,.html,.htm,.xml,.mdf,.ldf";
      this.config["formatsAllowed"] || StaticData.GeneralConfig.ExtensionesValidas || " .txt, .csv, .docx, .xlsx, .pptx, .odt, .ods, .odp, .pdf, .xml, .html, .json, .xpdl, .jpeg, .jpg, .jpe, .jfif, .jfi, .jif, .jpg2, .jp2, .mng, .odg, .png, .tiff, .xpm, .cgm, .svg, .svgz, .mp3, .bwf, .wav, .mp4, .mpeg, .m4v, .mkv, .mka, .mks, .mk3d, .mj2, .mjp2, .mpg, .mpeg, .mp1, .mp2, .mp3, .m1v, .m1a, .m2a, .mpa, .mpv, .mp2, .mxf, .webm, .ogg, .ogv, .oga, .ogx, .ogm, .spx, .opus, .gml, .shp, .shx, .sbn, .xml, .gz, .zip, .dbf, .mdbx, .siard, .warc, .eml, .mbox, .mbx, .xls, .doc,.msg";
    this.multiple = this.config["multiple"] || false;
    this.headers = this.config["uploadAPI"]["headers"] || {};

    this.invalidFormatMsg = this.config["invalidFormatMsg"] || this.invalidFormatMsg;
    this.invalidSizeMsg = this.config["invalidSizeMsg"] || this.invalidSizeMsg;
    this.invalidPrincipalMessage = this.config["invalidPrincipalMessage"] || this.invalidPrincipalMessage;
    this.DefaultPrincipal = this.config["DefaultPrincipal"] || false;

    /** @type {?} */
    const defaultReplaceTextsValues = {
      selectFileBtn: this.multiple ? "Select Files" : "Select File",
      resetBtn: "Reset",
      uploadBtn: "Upload",
      dragNDropBox: "Drag N Drop",
      attachPinBtn: this.multiple ? "Attach Files..." : "Attach File...",
      afterUploadMsg_success: "Successfully Uploaded !",
      afterUploadMsg_error: "Upload Failed !"
    };
    this.replaceTexts = Object.assign({}, defaultReplaceTextsValues);
    if (this.config["replaceTexts"]) {
      this.replaceTexts = Object.assign({}, defaultReplaceTextsValues, this.config["replaceTexts"]);
    }

    //this.isPrincipal = this.config.IsPrincipal;
    this.isExtensibleTable = this.config.IsExtendableFileTable;
    this.isForWorkFlow = this.config.IsForWorkFlow;


    this.config.EditRequired = (this.hideIfNotIsEdit || this.disableIfNotIsEdit);


    // Codigo para ESCANER
    if (this.config.WithScanner) {
      this.withScanner = this.config.WithScanner;
      this.scannerPort = (this.config.ScannerPort) ? this.config.ScannerPort : "8181";
      this.viewStatusScanner = this.config.ViewStatusScanner;
      this.viewStatusScannerNotify = this.config.ViewStatusScannerNotify;

      if (!this.scanDocumentsService) {
        this.scanDocumentsService = new ScanDocumentsService(this.utility,
          this.scannerPort, true, this.selectedFiles,
          (this.currentComponentName) ? this.currentComponentName : "FileUploader", false, true);

        this.scanDocumentsService.onStatus.subscribe(data => {

          if (this.viewStatusScanner) {


            switch (data.modalNotifyType.Id) {
              case ModalNotifyTypes.Info.Id:

                this.configStatusScanner = { status: data.status, class: "ms-MessageBar", icon: "ms-Icon ms-Icon--Info" };
                break;
              case ModalNotifyTypes.Success.Id:
                this.configStatusScanner = { status: data.status, class: "ms-MessageBar ms-MessageBar--success", icon: "ms-Icon ms-Icon--Completed" };
                break;
              case ModalNotifyTypes.Warning.Id:
                this.configStatusScanner = { status: data.status, class: "ms-MessageBar ms-MessageBar--warning", icon: "ms-Icon ms-Icon--Warning" };
                break;
              case ModalNotifyTypes.Danger.Id:
                this.configStatusScanner = { status: data.status, class: "ms-MessageBar ms-MessageBar--error", icon: "ms-Icon ms-Icon--ErrorBadge" };
                break;
            }
          }
          const titulo = "Estado Escaner:";
          if (this.viewStatusScannerNotify) {
            switch (data.modalNotifyType.Id) {
              case ModalNotifyTypes.Success.Id:
                StaticData.ShowActionNotificationSuccess(data.status, titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
                break;
              case ModalNotifyTypes.Info.Id:
                StaticData.ShowActionNotificationInfo(data.status, titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
                break;
              case ModalNotifyTypes.Warning.Id:
                StaticData.ShowActionNotificationWarning(data.status, titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
                break;
              case ModalNotifyTypes.Danger.Id:
                StaticData.ShowActionNotificationError(data.status, titulo, StaticData.GeneralConfig.ConfigUI.TiempoMensajesAdicionalModalEnNotifi);
                break;
            }
          }
        });
        this.scanDocumentsService.onMessage.subscribe(data => {

          if (data.type == "postBlob") {

            this.scannerFileName = null;
            //CHECK SIZE
            if (data.scanFile.size > this.maxSize * 1024000) {
              //console.log("SIZE NOT ALLOWED ("+file[i].size+")");

              this.notAllowedList.push({
                fileName: data.scanFile.name,
                fileSize: this.convertSize(data.scanFile.size),
                errorMsg: this.invalidSizeMsg
              });

            }
            else {
              //if (this.ValidateMaxLength(data.scanFile, "png")) {
              if (this.ValidateMaxLength(data.scanFile, "pdf")) {
                //format allowed and size allowed then add file to selectedFile array


                if (!this.multiple) {
                  this.selectedFiles = [];
                  this.Caption = [];
                  this.modeloArchivo = [];

                }
                //this.principalFile = undefined;
                if (!this.principalFile && this.config.DefaultPrincipal) {
                  data.scanFile.principal = true;
                  this.principalFile = data.scanFile.name + ".pdf";
                }
                //this.selectedFiles.push(this.blobToFile(data.scanFile, data.scanFile.name + ".png"));
                this.selectedFiles.push(this.blobToFile(data.scanFile, data.scanFile.name + ".pdf"));
                this.Caption.push(data.scanFile.name);
                // if (this.selectedFiles.length == 1)
                //   this.principalFile = file[i].name

                this.modeloArchivo.push({
                  //Name: data.scanFile.name, Size: data.scanFile.size, Ext: "png",
                  Name: data.scanFile.name, Size: data.scanFile.size, Ext: "pdf",
                  EsAdjunto: (this.principalFile != data.scanFile.name),
                  EsPlantilla: (this.templateFile == data.scanFile.name),
                  EsEscaneado: true
                });
                this.ValidateMaxLength();
              }
            }
          }
        });
        this.scanDocumentsService.StartWebSocekt();
      }
    }


    this.AllowsConvertDocument.Activo = this.config.AllowsConvertDocument;
    this.AllowsConvertDocument.Label = this.config.AllowsConvertDocumentText;
    this.validatePrincipalMessage = false;

  }

  OnChangeFileName(file, index) {

    const _index = this.selectedFiles.findIndex(x => { return x.name == file.name; });
    if (_index != index) {
      StaticData.ShowActionNotification2("Ya existe un archivo con el nombre " + file.name, "warning", "Alerta", 550);
      file.name = "CAMBIAR_NOMBRE_EXISTENTE";
    }
  }
  public EsEscaneado(index) {
    return this.modeloArchivo[index].EsEscaneado;
  }

  public EsGrande(index) {
    return this.modeloArchivo[index].EsGrande;
  }

  public ScanImage() {

    this.scanDocumentsService.ScanImage();
  }
  public ViewscanFiles(file) {

    this.scanDocumentsService.ViewscanFiles(file);
  }
  public resetFileUpload() {

    this.InternalResetFileUpload();
    this.ValidateMaxLength();
    this.ExecuteScripts();


  }
  public InternalResetFileUpload() {

    this.selectedFiles = [];
    this.Caption = [];
    this.notAllowedList = [];
    this.uploadMsg = false;
    this.uploadBtn = false;
    this.modeloArchivo = [];
    this.principalFile = "";
    this.templateFile = "";
    this.uploadMsgText = "";
    this.esGrande = false;


  }
  onChange(event) {

    //console.log(this.maxSize + this.formatsAllowed + this.multiple);
    this.notAllowedList = [];
    //console.log("onchange hit");
    if (this.afterUpload || !this.multiple) {
      this.selectedFiles = [];
      this.Caption = [];
      this.modeloArchivo = [];
      this.afterUpload = false;
      this.principalFile = undefined;
    }

    //FORMATS ALLOWED LIST
    //ITERATE SELECTED FILES
    /** @type {?} */
    let file;
    if (event.type == "drop") {
      file = event.dataTransfer.files;
      //console.log("type: drop");
    }
    else {
      file = event.target.files || event.srcElement.files;
      //console.log("type: change");
    }
    //console.log(file);
    /** @type {?} */
    let currentFileExt;
    /** @type {?} */
    let ext;
    /** @type {?} */
    let frmtAllowed;
    for (let i = 0; i < file.length; i++) {
      let currentformatsAllowed;
      //CHECK FORMAT
      //CURRENT FILE EXTENSION
      currentFileExt = this.reg.exec(file[i].name);
      //Extensión a minúsculas.
      currentFileExt = currentFileExt[1].toLowerCase();
      //TODO: PREGUNTAR SI SE PUEDE ADJUNTAR ARCHIVOS CON EL MISMO NOMBRE (CREADOS EN DIFERENTES FECHAS)
      if (this.selectedFiles.filter(e => e.name === file[i].name && e.lastModified == file[i].lastModified).length == 1) {
        this.utility.VerModalWarning({ "titulo": "Archivo repetido", "descripcion": "El archivo " + file[i].name + " ya se encuentra adjunto." });
        continue;
      }
      //Validar si el nombre es vacío
      if (file[i].name.toString().replace("." + currentFileExt, "") == "") {
        this.utility.VerModalWarning({ "titulo": "Archivo sin nombre", "descripcion": "El archivo" + file[i].name + " no tiene ningún nombre." });
        continue;
      }
      if (!this.DictionaryAllowedFormats || Object.keys(this.DictionaryAllowedFormats).length === 0) {


        // variables = this.formatsAllowed.trim();
        currentformatsAllowed = this.formatsAllowed.replace(/ /g, '')
        // this.formatsAllowed.replace(" ",'')
        this.DictionaryAllowedFormats = Object.assign({}, ...currentformatsAllowed.split(",").map((x, i) => ({ [x]: true })));
      }
      frmtAllowed = (currentformatsAllowed == "*");
      //VALIDAR SOLO SI NO
      if (!frmtAllowed) {
        //VALIDACION UTILIZANDO DICCIONARIO
        frmtAllowed = this.DictionaryAllowedFormats["." + currentFileExt.toLowerCase()] ? true : false;
      }

      if (frmtAllowed) {
        //console.log("FORMAT ALLOWED");
        //CHECK SIZE      
        debugger
        if (file[i].size > this.maxSize * 1024000000) {
          //if (file[i].size > 20 * 1024000) {
          //console.log("SIZE NOT ALLOWED ("+file[i].size+")");
          this.notAllowedList.push({
            fileName: file[i].name,
            fileSize: this.convertSize(file[i].size),
            errorMsg: this.invalidSizeMsg
          });
          continue;
        }
        else if (file[i].size > 900 * 1024000) {/// Si el archivo pesa mas de 900 mb, se envia por medio de chunk
          if (this.ValidateMaxLength(file[i], currentFileExt)) {
            this.selectedFileChunck = file[i];
            this.fileChunk.uploadFile(file[i]);
            if (!this.principalFile && this.config.DefaultPrincipal) {
              file[i].principal = true;
              this.principalFile = file[i].name;
            }
            if (!this.templateFile && this.config.DefaultTemplate) {
              file[i].EsPlantilla = true;
              this.templateFile = file[i].name;
            }
          }
        }
        else {
          if (this.ValidateMaxLength(file[i], currentFileExt)) {
            // //El primer archivo será el principal
            //debugger;
            if (this.principalFile == undefined || this.principalFile == null)
              this.principalFile = undefined;


            if (!this.principalFile && this.config.DefaultPrincipal) {
              file[i].principal = true;
              this.principalFile = file[i].name;
            }
            if (!this.templateFile && this.config.DefaultTemplate) {
              file[i].EsPlantilla = true;
              this.templateFile = file[i].name;
            }
            //format allowed and size allowed then add file to selectedFile array
            this.selectedFiles.push(file[i]);
            this.Caption.push(file[i].name);
            // if (this.selectedFiles.length == 1)
            //   this.principalFile = file[i].name

            this.modeloArchivo.push({
              Name: file[i].name, Size: file[i].size, Ext: currentFileExt,
              EsAdjunto: (this.principalFile != file[i].name),
              EsPlantilla: (this.templateFile == file[i].name),
              EsGrande: false
            });
            this.ValidateMaxLength();
            //this.ValidatePrincipal()
          }
        }
      }
      else {
        //console.log("FORMAT NOT ALLOWED");
        this.notAllowedList.push({
          fileName: file[i].name,
          fileSize: this.convertSize(file[i].size),
          errorMsg: this.invalidFormatMsg
        });
        continue;
      }
    }
    this.OnChangeEventEmitter.emit({ notAllowedList: this.notAllowedList, selectedFiles: this.selectedFiles });
    if (this.selectedFiles.length !== 0) {
      this.uploadBtn = true;
      if (this.theme == "attachPin")
        this.uploadFiles();
    }
    else {
      this.uploadBtn = false;
    }
    this.uploadMsg = false;
    this.uploadClick = true;
    this.uploadClickFormatMsg = true;
    this.percentComplete = 0;
    event.target.value = null;
    this.ValidateMaxLength();

    if (this.modeloArchivo && this.modeloArchivo.length > 0) {
      var validation: boolean = false;
      this.modeloArchivo.forEach(document => {
        if (!document.EsAdjunto)
          validation = true;
      });
      this.ValidatePrincipal(validation)
    }

  }

  uploadFileChunk(chunk, fileName, progressUpload: number, targetFile, currentFileExt) {
    const formData = new FormData();
    formData.append('file', chunk, fileName);

    this.http
      .post(StaticData.UrlServer + '/api/fileuploadlarge/UploadChunk/', formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            if (this.progress <= progressUpload) {
              this.progress = progressUpload;
            }
            if (this.progressVisual && progressUpload === 100) {
              this.progressVisual = false;

              this.selectedFiles.push(targetFile);
              this.Caption.push(targetFile.name);
              this.modeloArchivo.push({
                Name: targetFile.name, Size: targetFile.size, Ext: currentFileExt,
                EsAdjunto: (this.principalFile != targetFile.name),
                EsPlantilla: (this.templateFile == targetFile.name),
                EsGrande: true
              });
              this.ValidateMaxLength();

              this.esGrande = true;

            }
          } else if (event.type == HttpEventType.Response) {

          }
        }),
        catchError((err: any) => {
          this.progress = null;
          console.log(err.message);
          return throwError(err.message);
        })
      )
      .toPromise();

  }

  uploadFileInChunks(targetFile, currentFileExt) {
    // create array to store the buffer chunks
    const fileChunk = [];
    // the file object itself that we will work with
    // set up other initial vars
    const maxFileSizeMB = 3;
    const bufferChunkSize = maxFileSizeMB * (1024 * 1024);
    // let ReadBuffer_Size = 1024;
    let fileStreamPos = 0;
    // set the initial chunk length
    let endPos = bufferChunkSize;
    const size = targetFile.size;

    // add to the FileChunk array until we get to the end of the file
    while (fileStreamPos < size) {
      // "slice" the file from the starting position/offset, to  the required length
      fileChunk.push(targetFile.slice(fileStreamPos, endPos));
      fileStreamPos = endPos; // jump by the amount read
      endPos = fileStreamPos + bufferChunkSize; // set next chunk length
    }
    // get total number of "files" we will be sending
    const totalParts = fileChunk.length;
    let partCount = 0;
    this.progress = 1;
    this.progressVisual = true;

    for (let valueChunk of fileChunk) {
      partCount++;
      const filePartName = targetFile.name + '.part_' + partCount + '.' + totalParts;
      // send the file
      this.uploadFileChunk(valueChunk, filePartName, Number(((partCount / totalParts) * 100).toFixed(2)), targetFile, currentFileExt);
    }
  }

  isSelectedFiles(): boolean {

    return !(!this.selectedFiles || (this.selectedFiles && this.selectedFiles.length == 0));

  }

  public ManageResponse(event: any) {

    if (!event.response || this.notManageResponseInternal)
      return;

    const resultObject: any = JSON.parse(event.response);
    if (resultObject.Ok) {
      this.utility.VerModalOk(resultObject.ValidationUI || "El proceso de carga de archivo se ejecutó correctamente.").then(value => { });
      this.utility.logger.LogSuccess(resultObject, this.uploadAPI);
    }
    else if (resultObject.IsAuthenticated) {
      this.utility.VerModalWarning({
        titulo: "No Autenticado",
        descripcion: ((resultObject.ValidationUI) ? resultObject.ValidationUI : resultObject.Error)
      }).then(value => { });

      this.uploadMsgText = resultObject.ValidationUI;
      this.utility.logger.LogWarning(resultObject, this.uploadAPI);
    }
    else {
      const titulo = (resultObject.InfoRule) ? "Excepción de Regla de Negocio" : "Excepción de Negocio";

      if (resultObject.ValidationUI) {
        this.uploadMsgText = "Hubo un error al validar el archivo.";
        this.uploadMsgClass = "text-danger lead";
        this.utility.VerModalDanger({ titulo: titulo, descripcion: resultObject.ValidationUI }).then(value => { });
      }
      else
        this.utility.VerModalError().then(value => { });

      this.uploadMsgText = resultObject.ValidationUI;
      this.utility.logger.LogError(resultObject, this.uploadAPI);
    }

  }

  public clear() {

    this.uploadMsgText = "";
    this.selectedFiles = [];
    this.Caption = [];
    this.modeloArchivo = [];
    this.modeloAllowsConvertDocument = null;
    this.ValidateMaxLength();
    this.OnClearEventEmitter.emit();

  }

  public EndNotify(notify: any) {

    if (notify) {
      notify.close();
      setTimeout(() => {
        notify.close();
      }, 200);
    }

  }

  public GetForExternalFilesModel() {

    return {
      Caption: this.Caption,
      selectedFiles: this.selectedFiles,
      modeloArchivo: this.modeloArchivo,
      keyName: (this.setControlNameAsFileModelName) ? this.config.Name : ((this.isForWorkFlow) ? "WorkFlowTask" : "Form")
      // keyName: (this.isForWorkFlow) ? "fileWorkFlowModel" : this.config.Name
    };

  }

  uploadFiles(validateEmitter = true, model?: any, externalFiles?: any) {
    /** @type {any} */
    let notify: any = null;
    if (validateEmitter && this.UploadFilesEventEmitter.observers.length > 0) {
      this.UploadFilesEventEmitter.emit(this);
      return;
    }

    if (!this.isSelectedFiles())
      return;
    /** @type {string} */
    const mensaje = "Cargando Archivo....";    
    notify = StaticData.ShowNotification(mensaje, "warning", "top", "right", 
    "Procesando....", 0, true,this.changeNotificationLoad);

    if (this.currentComponentName && this.uploadAPI)
      this.uploadAPI = this.uploadAPI.replace("#Controller#", this.currentComponentName);

    /** @type {?} */
    let i;
    this.progressBarShow = true;
    this.uploadClick = false;
    this.uploadClickFormatMsg = false;
    this.notAllowedList = [];
    /** @type {?} */
    let isError = false;
    /** @type {?} */
    const xhr = new XMLHttpRequest();
    /** @type {?} */
    const formData = new FormData();

    if (model) {
      if (model.DataAdd && model.DataAdd.Adjuntos) {
        this.principalFile = null;
        this.modeloArchivo.forEach(documento => {
          documento.EsAdjunto = true;
        });
        this.selectedFiles.forEach(doc => {
          delete doc.principal
        });
      }
    }

    //CM
    if (this.principalFile != undefined || this.principalFile != null) {
      formData.append("principalFile", this.principalFile);
    }

    for (i = 0; i < this.selectedFiles.length; i++) {
      if (this.Caption[i] == undefined)
        this.Caption[i] = "file" + i;
      //Add DATA TO BE SENT      
      this.esGrande = false
      if (this.selectedFiles[i].size > 900 * 1024000)
        this.esGrande = true

      if (!this.esGrande) {
        formData.append(this.Caption[i], this.selectedFiles[i] /*, this.selectedFiles[i].name*/);
      }
      else {
        // if (this.modeloArchivo.find(x => x.Name == this.selectedFiles[i].name && x.EsGrande == false).item != null) {
        //formData.append("EsGrande", "true");
        formData.append(this.Caption[i], new File([""], this.selectedFiles[i].name) /*, this.selectedFiles[i].name*/);
        // }
      }

      //console.log(this.selectedFiles[i]+"{"+this.Caption[i]+" (Caption)}");
    }

    if (externalFiles) {
      for (i = 0; i < externalFiles.selectedFiles.length; i++) {
        if (externalFiles.Caption[i] == undefined)
          externalFiles.Caption[i] = "file" + i;

        formData.append(externalFiles.Caption[i], externalFiles.selectedFiles[i]);
      }
    }
    if (model) {
      model.Token = StaticData.Usuario.Token;
      model.AppKey = StaticData.AppKey;
    }
    else {
      if (this.sendCurrentModel) {
        model = (this.isModelContainer) ? this.modelo.modelo : this.modelo;
        model.Token = StaticData.Usuario.Token;
        model.AppKey = StaticData.AppKey;
      }
      else
        model = { Token: StaticData.Usuario.Token, AppKey: StaticData.AppKey, };
    }

    if (!model.DataAdd)
      model.DataAdd = {};
    if (this.setControlNameAsFileModelName) {
      model.DataAdd[this.config.Name] = this.modeloArchivo;
    }
    else {
      if (this.isForWorkFlow) {
        model.DataAdd["WorkFlowTask"] = this.modeloArchivo;
        // formData.append('filesWorkFlowModel', JSON.stringify(this.modeloArchivo));
      }
      else {
        model.DataAdd["Form"] = this.modeloArchivo;
        //   formData.append('filesModel', JSON.stringify(this.modeloArchivo));
      }
    }
    if (externalFiles) {
      model.DataAdd[externalFiles.keyName] = externalFiles.modeloArchivo;
      //formData.append(externalFiles.keyName, JSON.stringify(externalFiles.modeloArchivo));
    }
    model.DataAdd["AllowsConvertDocument"] = this.modeloAllowsConvertDocument;
    formData.append("modelo", JSON.stringify(model));

    if (i > 1) {
      this.singleFile = false;
    }
    else {
      this.singleFile = true;
    }
    xhr.onreadystatechange = evnt => {
      //console.log("onready");
      if (xhr.readyState === 4) {
        if (xhr.status !== 200 && xhr.status !== 201) {
          isError = true;
          this.progressBarShow = false;
          this.uploadBtn = false;
          this.uploadMsg = true;
          this.afterUpload = true;
          this.uploadMsgText = this.replaceTexts.afterUploadMsg_error;
          this.uploadMsgClass = "text-danger lead";
          //console.log(this.uploadMsgText);
          //console.log(evnt);
        }
        this.EndNotify(notify);
        this.ApiResponse.emit(xhr);
        this.ManageResponse(xhr);
      }
    };
    xhr.upload.onprogress = evnt => {
      this.uploadBtn = false; // button should be disabled by process uploading
      if (evnt.lengthComputable) {
        this.percentComplete = Math.round((evnt.loaded / evnt.total) * 100);
        this.OnProgressEventEmitter.emit(this.percentComplete);
      }
      //console.log("Progress..."/*+this.percentComplete+" %"*/);
    };
    xhr.onload = evnt => {
      //console.log("onload");
      //console.log(evnt);
      this.progressBarShow = false;
      this.uploadBtn = false;
      this.uploadMsg = true;
      this.afterUpload = true;
      const objResponse = evnt.currentTarget && evnt.currentTarget["response"] ? JSON.parse(evnt.currentTarget["response"].toString()) : "";
      if (objResponse != "") {
        isError = !objResponse.Ok;
      }

      if (!isError) {
        this.uploadMsgText = this.replaceTexts.afterUploadMsg_success;
        this.uploadMsgClass = "text-success lead";
        //console.log(this.uploadMsgText + " " + this.selectedFiles.length + " file");
      }
      else {
        this.uploadMsgText = objResponse.ValidationUI ? objResponse.ValidationUI : this.replaceTexts.afterUploadMsg_error;
        this.uploadMsgClass = "text-danger lead";
      }
    };
    xhr.onerror = evnt => {
      //console.log("onerror");
      //console.log(evnt);
      this.utility.logger.LogError(evnt, this.uploadAPI);
      this.EndNotify(notify);
    };
    xhr.open("POST", this.uploadAPI, true);
    for (const key of Object.keys(this.headers)) {
      // Object.keys will give an Array of keys
      xhr.setRequestHeader(key, this.headers[key]);
    }
    //let token = sessionStorage.getItem("token");
    //xhr.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    //xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.send(formData);

  }

  removeFile(i, sf_na) {

    if (sf_na == "sf") {
      debugger
      if (this.selectedFiles[i].name === this.principalFile) {
        this.principalFile = undefined;
        //   if(this.selectedFiles.length != 1){
        //   let searchTo = i === 0 ? i + 1 : i - 1;
        //   this.selectedFiles[searchTo].principal = true;
        //   this.principalFile = this.selectedFiles[searchTo].name;
        //   this.modeloArchivo[searchTo].EsAdjunto = false;
      }
      // else
      // {
      //   this.principalFile = null;
      // }

      // }
      this.selectedFiles.splice(i, 1);
      this.Caption.splice(i, 1);
      this.templateFile = '';
      this.modeloArchivo.splice(i, 1);

      if (this.notAllowedList.length > 0 && this.modeloArchivo.length == 0) {
        this.notAllowedList = [];
      }
    }
    else {
      this.notAllowedList.splice(i, 1);
    }
    if (this.selectedFiles.length == 0) {
      this.uploadBtn = false;
    }
    this.ValidateMaxLength();
    if (this.modeloArchivo && this.modeloArchivo.length > 0) {
      var validation: boolean = false;
      this.modeloArchivo.forEach(document => {
        if (!document.EsAdjunto)
          validation = true;
      });
      this.ValidatePrincipal(validation)
    }
    else
      this.validatePrincipalMessage = false


    this.OnRemoveEventEmitter.emit({ notAllowedList: this.notAllowedList, selectedFiles: this.selectedFiles });
  }

  PrincipalFile(i, sf_na) {

    if (sf_na == "sf") {
      this.selectedFiles.forEach(file => {
        if (file.name == this.selectedFiles[i].name)
          file.principal = true;
        else
          file.principal = false;

      });
    }

  }

  convertSize(fileSize) {

    return fileSize < 1024000
      ? (fileSize / 1024).toFixed(2) + " KB"
      : (fileSize / 1024000).toFixed(2) + " MB";

  }

  attachpinOnclick() {

    (/** @type {?} */ (document.getElementById("sel" + this.id))).click();

  }

  drop(event) {

    event.stopPropagation();
    event.preventDefault();
    //console.log("drop: ", event);
    //console.log("drop: ", event.dataTransfer.files);
    this.onChange(event);

  }

  allowDrop(event) {

    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
    //console.log("allowDrop: ",event)

  }

  public ContineArchivosCorreoMsg() {
    let ver: boolean = false;

    for (let i = 0; i < this.selectedFiles.length; i++) {

      if (this.selectedFiles[i].name.toLowerCase().includes('.msg')) {
        ver = true;

        return ver;
      }

    }

    return ver;
  }

  public get ViewAllowsConvertDocument() {
    let ver: boolean = false;

    for (let i = 0; i < this.selectedFiles.length; i++) {

      if (!ver) {

        let ext = this.reg.exec(this.selectedFiles[i].name)[1].toLowerCase();
        ver = (ext == 'doc' || ext == 'docx' || ext == 'html' || ext == 'htm' || ext == 'txt');

      }
    }

    return ver;
  }

  public get ConfigConvertirCorreo() {

    return (this.config.CamposConfigConvertirCorreo &&
      this.config.VerInfoConfigConvertirCorreo && this.ContineArchivosCorreoMsg()) ? true : false;
  }

  public GetEmailFields(campo: any) {

    let campos: string = '';


    campo.EmailFields.forEach(campo => {

      campos = campos + campo.Field + '|';
    });

    return campos;
  }
  //* END FUNCIONES PERSONALIZADAS

}
