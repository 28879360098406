import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { ModalNotifyComponent, ModalNotifyTypes } from '../modal-notify/modal-notify.component';
import { ModalLoginComponent } from '../modal-login/modal-login.component';
import { Utilities } from '../../helpers/utilities';
import { BaseServiceService } from '../../services/base-service.service';
import { FormGroup } from '@angular/forms';
import { StaticData } from '../../helpers/static-data';
import { WindowService } from '../window.component/window.service';

import { config } from 'rxjs';
import { ConfigWindow } from '../../models/config-window';
import { GeneralComunicationService } from '../../services/general-comunication.service';


import { MatList } from '@angular/material';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';

import { Guid } from 'guid-typescript';
import { ComboBoxManager } from '../../bussines/combo-box-manager';
import { AngularFileUploaderComponent } from "angular-file-uploader";
import { FileUploaderComponent } from '../controls/file-uploader/file-uploader.component';
declare var $: any;
declare var fabric: any;
declare var jsPanel: any;
// declare var THREE: any;
// declare var Stats: any;

// import { ColladaLoader } from '../../../assets/three/three.js-dev/three.js-dev/examples/jsm/loaders/ColladaLoader';
// import { STLLoader } from '../../../assets/three/three.js-dev/three.js-dev/examples/jsm/loaders/STLLoader';
// import { OrbitControls } from '../../../assets/three/three.js-dev/three.js-dev/examples/jsm/controls/OrbitControls';
// import { Stats } from '../../../assets/three/three.js-dev/three.js-dev/examples/jsm/libs/stats.module.js';


//import * as SpriteText  from '../../../assets/three/three-spritetext-master/src/index';
//import Stats from './jsm/libs/stats.module.js';
//import * as THREE from '../../../assets/three/';

//const scene = new THREE.Scene();



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @Input() Pagina: any = {
    Controlador: "General",
    Valida: true, ConfigPage:
      { ExtraControls: [], Controls: [] }
  };

  DOC: string = "http://52.184.141.81:7575/sites/bestdoc/EX2021306300051/DOC2021GRUPO DE GESTIN DOCUMENTAL0010438.pdf";

  pageId: any;
  @ViewChild(ModalNotifyComponent, { static: true }) modalNotify: ModalNotifyComponent;
  @ViewChild(ModalLoginComponent, { static: true }) modalLogin: ModalLoginComponent;



  constructor(public utility: Utilities, private windowService: WindowService,

    public comunicationService: GeneralComunicationService,
    public baseService: BaseServiceService) {





  }


  public get IsLoged(): any {
    return (StaticData.Usuario != null);
  }
  ngOnInit() {


    //this.CargarArbol();
    // this.internalOnInit();

    this.comunicationService.receivedNavItemsEvent.subscribe(navItems => {

      if (navItems && navItems.length > 0) {

      }
    });



    this.internalOnInit();

    // window.onresize= function() {

    //   

    //   const c = window;
    // };
  }

  //var myWindow;

   openWin() {
    //window = window.open("", "", "width=250, height=250");
  }
  
 resizeWinTo() {
   
  window.resizeTo(800, 600);
  window.focus();
  }
  
   resizeWinBy() {
    
    window.resizeBy(-100, -50);
    window.focus();
  }

  internalOnInit() {

    try {



      this.pageId = this.utility.router.url.replace('/', '');



      if (this.Pagina.ConfigPage.MaxContainer) {

        this.Pagina.Estilos.ClassContenedorGeneral = '';
        this.Pagina.Estilos.EstiloContenedorGeneral = '';
      }
      this.componentName = 'TExpediente';// this.Pagina.Controlador.replace('Controller', '')





    }
    catch (error) {

      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'ngOnInit' });
    }

  }
  treeState: any;
  dialogComponent: any;
  PanelExamplePanel: any;
  sidePanel_Left: any;
  ngAfterViewInit() {
    $('#responsive').on('click', function () {
      if ($(this).hasClass("responsive")) {
        $(this).removeClass('responsive')
        $('.sidebar').css({ 'left': '-66px', 'min-width': '0', 'max-width': '0' })
      }
      else {
        $(this).addClass('responsive')
        $('.sidebar__login').after('<i class="icon-times-circle .d-inline-block" id="closemenu" style="float: right;position: relative;color: white;right: 45px;top:12px;font-size: 25px;"></i>')
        $('.sidebar').css({ 'left': '0', 'min-width': '100%', 'max-width': '100%' })
      }
    })

    $(document).on('click', '#closemenu', function () {
      $('#responsive').removeClass('responsive')
      $('.sidebar').css({ 'left': '-66px', 'min-width': '0', 'max-width': '0' })
      $('#closemenu').remove();
    })


    //   // create a demo jsPanel
    //   jsPanel.create({
    //     headerTitle: 'demo panel',
    //     theme: 'dark',
    //     content: '<p style="text-align:center;">A simple demo panel.</p>'
    //   });
    //   jsPanel.create({
    //     theme: 'dimgray filled',
    //     position: 'center-top 0 70',
    //     contentSize: '450 500',
    //     contentOverflow: 'hidden',
    //     content: '<object style="width:100%;height:100%;" data="http://localhost:58205/TempDocs/PLANTILLA_FINLA_EXPEDIENTE1.docx" ></object>'
    // });
    //   jsPanel.create({
    //     theme: 'dimgray filled',
    //     position: 'center-top 0 70',
    //     contentSize: '450 500',
    //     contentOverflow: 'hidden',
    //     content: '<object style="width:100%;height:100%;" data="http://localhost:58205/TempDocs/TemplateExpediente.xml.pdf" type="application/pdf"></object>'
    // });


    //   jsPanel.create({
    //     theme: '#011842 filled',
    //     headerTitle: 'Spherical Panorama Image',
    //     contentSize: {
    //         width:  $(window).width() * 0.8,
    //         height: $(window).height() * 0.8
    //     },
    //     contentOverflow: 'hidden',
    //     content: '<iframe src="http://localhost:4200/#/Home" style="width: 100%; height: 100%;"></iframe>'
    // });

    if (StaticData["MessajeNoAutenticado"]) {
      StaticData["MessajeNoAutenticado"] = null;
      this.utility.VerModalWarning({
        titulo: 'Alerta', descripcion: 'El usuario no se encuentra autenticado',
        verOk: true, verCancelar: false, ok: 'Aceptar', cancelar: 'NO'
      }).then(data => { });
    }

    window.resizeTo(
      window.screen.availWidth / 2,
      window.screen.availHeight / 2
    );
  }



  

  validarUsuario() {

    if (this.utility.ValidateUser(null)) {

    }
    else {

    }

  }
  login() {
    this.modalLogin.open();
  }
  verIDE = false;
  @Input() public componentName: string;


  Estilos = {};
  /*   Pagina = {
      Estilos: {}, Valida: true, Guardar: true,
  
      "Controls": [
        {
          "Id": "2918a605-d8a6-48e8-b372-8b77fb2d399a",
          "IdPadre": null,
          "Name": "IdDepartamento",
          "MinLength": 0,
          "MaxLength": 10,
          "Min": 0,
          "Max": 0,
          "Required": false,
          "Pattern": "",
          "ControlType": "ComboBox",
          "DataType": "Int32",
          "IsFk": true,
          "ModelContainer": "modelo",
          "Title": "Campo Sede Empresa",
          "Placeholder": "Digite Sede Empresa",
          "Label": "Sede Empresa",
          "ColSpan": 12,
          "Order": 0,
          "Rows": 0,
          "Cols": 0,
          "KeyCache": "RDepartamento",
          "ConfigCache": null,
          "ViewSearchControl": false,
          "ViewSearchButton": true,
          "ConfigControl": {
            "SetCargarItems": true,
            "Controller": "RDepartamento",
            "Filter": "Activo=true && IdEmpresa=-1"
          },
          "ConfigSearchControl": {
            "PagedServer": true,
            "SearchParam": "Nombre",
            "ViewFieldModel": "Nombre",
            "SetLike": true,
            "Include": {},
            "Params": { "IdEmpresa": -1 },
            "Controller": "RDepartamento",
            "Filter": "Activo=1",
            "MappingFields": { "IdDepartamento": "IdDepartamento" },
            "Columns": ["IdDepartamento", "Nombre"],
            "Props": ["IdDepartamento", "Nombre"]
          },
          "HtmlEditor": false,
          "MultilineEditor": false,
          "JsonEditor": false,
          "AceEditor": false,
          "Type": "Select",
          "IdQuery": 0,
          "Mask": null,
          "Value": null,
          "IdPaginaAdministracion": 40,
          "ViewAdminButton": false,
          "Activo": true,
          "Controls": []
        },
        {
          "Id": "4e920c54-8647-43ef-b529-a10cd9493144",
          "IdPadre": null,
          "Name": "IdCiudad",
          "MinLength": 0,
          "MaxLength": 10,
          "Min": 0,
          "Max": 0,
          "Required": false,
          "Pattern": "",
          "ControlType": "ComboBox",
          "DataType": "Int32",
          "IsFk": true,
          "ModelContainer": "modelo",
          "Title": "Campo Area Empresa",
          "Placeholder": "Digite Area Empresa",
          "Label": "Area Empresa",
          "ColSpan": 12,
          "Order": 1,
          "Rows": 0,
          "Cols": 0,
          "KeyCache": "RCiudad",
          "ConfigCache": null,
          "ViewSearchControl": false,
          "ViewSearchButton": true,
          "ConfigControl": {
            "SetCargarItems": true,
            "Controller": "RCiudad",
            "Params": {
              "Activo": true,
              "IdEmpresa": -1
            }
          },
          "ConfigSearchControl": {
            "PagedServer": true,
            "SearchParam": "Nombre",
            "ViewFieldModel": "Nombre",
            "SetLike": true,
            "Include": {},
            "Params": { "IdEmpresa": -1 },
            "Controller": "RCiudad",
            "Filter": "Activo=1",
            "MappingFields": { "IdCiudad": "IdCiudad" },
            "Columns": ["IdCiudad", "Nombre"],
            "Props": ["IdCiudad", "Nombre"]
          },
          "HtmlEditor": false,
          "MultilineEditor": false,
          "JsonEditor": false,
          "AceEditor": false,
          "Type": "Select",
          "IdQuery": 0,
          "Mask": null,
          "Value": null,
          "IdPaginaAdministracion": 69,
          "ViewAdminButton": false,
          "Activo": true,
          "Controls": []
        }
      ]
  
    } */
  resultLogin(data: any = null) {
    this.verIDE = true;
    let r = data;
    const that = this;

    this.Estilos = StaticData.Estilos;
    this.Pagina.Estilos = StaticData.Estilos;
    /*  if(!this.utility.GetUsuario())
   {
       setTimeout(() => {
         that.modalLogin.open();
       }, 500);
     } */


  }

  configFileUploader = {
    NotManageResponseInternal: true,
    multiple: true,
    formatsAllowed: "*",
    maxSize: 2000000000,
    Required: false,
    Label: "Documento",
    ControlType: "FileBox",
    Name: 'FileBoxDocumentoExpediente',
    NotPrincipal: false,
    uploadAPI: {
      url: StaticData.UrlServer + "/api/TDocumentoExpediente/UploadFile",
      headers: {
        modelo: ""
      }
    },
    WithScanner: true,
    ViewStatusScannerNotify: true,
    ViewStatusScanner: true,
    theme: "dragNDrop",
    hideProgressBar: false,
    hideResetBtn: false,
    //hideSelectBtn: false,
    replaceTexts: {
      selectFileBtn: 'Seleccione el documento',
      resetBtn: 'Limpiar',
      uploadBtn: 'Cargar Documento',
      dragNDropBox: 'Drag & Drop',
      attachPinBtn: 'Adjunte Documento...',
      afterUploadMsg_success: 'Carga Correcta !',
      afterUploadMsg_error: 'Error al cargar !'
    }
  }
  public Report() {


    let _url = "http://localhost:58205/api/TDocumentoExpediente/Report";
    var obj = this.utility.GetApiModel('Report', _url);
    let xhr = new XMLHttpRequest();
    let formData = new FormData();
    formData.append('principalFile', "asdhasdhd");
    xhr.onreadystatechange = evnt => {
      //console.log("onready");
      if (xhr.readyState === 4) {
        if (xhr.status !== 200 && xhr.status !== 201) {

        }


        var file = new Blob([xhr.response], { type: "application/pdf" });
        var fileURL = window.URL.createObjectURL(file);
        var a = document.createElement(a);
        a.href = fileURL;
        a.target = "_blank";
        a.download = "MyZipFileName.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    xhr.upload.onprogress = evnt => {

    };
    xhr.onload = evnt => {

    };
    xhr.onerror = evnt => {
      //console.log("onerror");
      //console.log(evnt);

    };
    xhr.open("POST", _url, true);

    xhr.send(formData);
    // this.baseService.Ejecutar(obj, null,
    //   {
    //     url: _url,
    //     componentName: this.componentName,
    //     method: 'Report',
    //     processMessage: 'Report...'
    //   }, false, _url, false, true, true)
    //   .then(data => {

    //     
    //     var file = new Blob([data.Data], { type: "application/pdf" });
    //     var fileURL = window.URL.createObjectURL(file);
    //     var a = document.createElement(a);
    //     a.href = fileURL;
    //     a.target = "_blank";
    //     a.download = "MyZipFileName.pdf";
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);

    //   }).catch(err => {
    //     this.utility.logger.LogError(err, obj, {
    //       componentName: this.componentName,
    //       method: 'Report',
    //       processMessage: 'Report...'
    //     }, _url)
    //   });
  }


  configPlugin = {
    Path: '../../../assets/plugins/prueba/prueba-libreria.umd - Copy.js',
    Name: 'Test',
    Module: 'PruebaLibreriaModule',
    Deps: [{
      Path: '../../../assets/plugins/my-lib.umd.js',
      Name: 'MyLibModule',
      Module: 'MyLibModule'
    }],
    ComponentTypes: [{ Name: 'DataTableComponent', Type: null }]
  }
  configHtml = {

    Name: 'Test',
    Template: `<div class="container-fluid">



    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Clase Persona</h4>
        </div>
        <div class="card-body">
            <form #frm="ngForm" novalidate (ngSubmit)="Guardar(frm.form)">
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>ClasePersona</label>
                        <input type="text" class="form-control" name="ClasePersona"
                            [ngClass]="GetControlClassByNgForm(frm,'ClasePersona')"
                            [(ngModel)]="modelo.ClasePersona" placeholder="ClasePersona" required maxlength="50"
                            data-toggle="tooltip" data-trigger="hover" data-placement="bottom">


                    </div>

                    <div class="col-md-12">
                        <input type="checkbox" class="" id="Activo" name="Activo" [(ngModel)]="modelo.Activo">
                        <label class="form-check-label" for="Activo">Activo</label>
                    </div>
                </div>

                <button type="submit" [disabled]="frm.invalid" class="btn btn-info btn-fill pull-right">Guardar</button>
                <button type="button" class="btn btn-info btn-fill pull-right"
                    (click)="Limpiar()">Limpiar</button>
                <div class="clearfix"></div>
            </form>
        </div>
    </div>
    <button type="button" class="btn btn-info btn-fill pull-right" (click)="CargarTabla()">CargarTabla</button>
  
    <app-data-table >
    </app-data-table>

</div>`,
  }
  verPlu = false;
  VerHtml = false;
  VerPlugin() {

  }

}
