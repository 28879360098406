import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-text-replacement-settings',
  templateUrl: './document-text-replacement-settings.component.html',
  styleUrls: ['./document-text-replacement-settings.component.css']
})
export class DocumentTextReplacementSettingsComponent implements OnInit {

  @Input() modelo:any;
  @Input() title:string="Config Reemplazo";
  constructor() { }

  ngOnInit() {
  }


  public Add(){
    this.modelo.Configs.push({});
  }
  public Delete(index){
    this.modelo.Configs.splice(index, 1);
  }
}
