import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
// import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
// import { ComponentPortal,Portal,
//   TemplatePortalDirective } from '@angular/cdk/portal';
// import { SpinnerComponent } from '../spinner/spinner.component';

import { StaticData } from 'src/app/helpers/static-data';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  @Input() public processMessage: string = '';
  @Input() public name: string = "overlayGeneral";
  @Input() public isLoading: boolean = false;
  @Input() public disableSpinner: boolean = true;
  @Input() public closeOnClick: boolean = true;
  @Input() public nextPosition: number = 0;

  @Output() onActionEmitter: EventEmitter<any> = new EventEmitter<any>();
  isMenuOpen: boolean = false;

  index:number =5000;

  //public overlayRef: OverlayRef;
  // constructor(public generalComunicationService: GeneralComunicationService, public overlay: Overlay,
  //   public viewContainerRef: ViewContainerRef) { }

  constructor(public generalComunicationService: GeneralComunicationService) { }

  ngOnInit() {
   
    this.generalComunicationService.recivedOverlay.subscribe(config => {

      this.Set(config);
    
    })
    StaticData.Overlay =this;
  }
  public Set(config?:any){
    this.isLoading = config.IsLoading;
    this.processMessage = (config.DisableSpinner != null && config.DisableSpinner != undefined) ? config.ProcessMessage : this.processMessage;
    if (config.DisableSpinner != null && config.DisableSpinner != undefined)
      this.disableSpinner = config.DisableSpinner;
    else
      this.disableSpinner = true;
  }
  public CloseOverly(){
    this.isLoading =false;
    this.onActionEmitter.emit({ Action: 'Close' });
  }
  public OpenOverly(config?:any){
    this.isLoading =true;
    if(config){
      config.IsLoading =true;
      this.Set(config);
    }
    this.onActionEmitter.emit({ Action: 'Open' });
  }
  // public CloseOverly() {
  //   
  //   if (this.overlayRef)
  //     this.overlayRef.dispose();
  //  // this.isMenuOpen = false;
  //   this.onActionEmitter.emit({ Action: 'Close' });
  // }

  // private getOverlayConfig(): OverlayConfig {
  //   const positionStrategy = this.overlay.position()
  //     .global()
  //     .centerHorizontally()
  //     .centerVertically();

  //   const overlayConfig = new OverlayConfig({
  //     hasBackdrop: true,
  //     backdropClass:  'dark-backdrop',
  //     scrollStrategy: this.overlay.scrollStrategies.block(),
  //     positionStrategy
  //   });

  //   return overlayConfig;
  // }
  // public OpenOverly() {
  //   
  //   //this.CloseOverly();
  //   let config =this. getOverlayConfig();// new OverlayConfig();

  //   // config.positionStrategy = this.overlay.position()
  //   //   .global()
  //   //   .left(`${this.nextPosition}px`)
  //   //   .top(`${this.nextPosition}px`);

  //   // // this.nextPosition += 30;

  //   // config.hasBackdrop = true;
  //   // config.backdropClass= 'dark-backdrop';
  //   // config.panelClass= 'tm-file-preview-dialog-panel';
  //   this.overlayRef = this.overlay.create(config);

  //   this.overlayRef.backdropClick().subscribe(() => {
  //     if (this.closeOnClick)
  //       this.CloseOverly();
  //   });

  //   this.overlayRef.attach(new ComponentPortal(SpinnerComponent, this.viewContainerRef));
  //   //this.isMenuOpen = true;
  //   this.onActionEmitter.emit({ Action: 'Open' });
  // }



}
