import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CdkDragDrop, CdkDragMove, CdkDragStart, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { BaseServiceService } from 'src/app/services/base-service.service';
import { FormActionsThroughEmitters } from 'src/app/models/general.enum';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { Guid } from 'guid-typescript';
import { HelperImages } from 'src/app/helpers/helper-images';
import { List } from '../../../../assets/linqts/compilado/index';
import { StaticData } from 'src/app/helpers/static-data';
import { Subject } from 'rxjs';
import { Utilities } from 'src/app/helpers/utilities';

@Component({
  selector: 'app-drag-and-drop-list',
  templateUrl: './drag-and-drop-list.component.html',
  styleUrls: ['./drag-and-drop-list.component.css']
})
export class DragAndDropListComponent implements OnInit, OnDestroy {

  @Input() withPagination: boolean = false;
  pageOfItems: Array<any> = [];
  @Output() onInitialized = new EventEmitter<any>();
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() config: any;
  @Input() name: string = 'DragAndDropListComponent';


  @Input() QueryConfigSelection: any;
  @Input() QueryConfigSelected: any;
  _modelo: any;

  public _startWith: string;
  public searchText: string;
  @Input() set modelo(value: any) {
    this._modelo = value;
    if (this.isInit == true) {
      // setTimeout(() => {
      //  
      //   this.SetModelo(this._modelo);
      // }, 500);

    }
  }
  get modelo(): any {
    return this._modelo;
  }

  @Input() mappingProps: Array<any> = [];
  @Input() propConfiguracionInterna: string = '';
  @Input() propConfiguracion: string = 'Configuracion';
  @Input() componentName: string = 'DragAndDropListComponent';

  @Input() url: string = '';
  @Input() processMessageSelection: string = 'Cargando Tipos documentales...';
  @Input() processMessageSelected: string = '';
  @Input() pkField: string = '';
  @Input() textField: string = '';
  @Input() loadOnInit: boolean = true;
  @Input() listName: string = "drop-list-selection";
  @Input() listToConnect: Array<any> = [];
  @Input() titleSelectionItems: string = '';
  @Input() titleSelectedItems: string = '';
  @Input() removeSelectedItems: boolean = true;
  @Input() imageSelectionItems: string;

  isInit: boolean = false;
  allSelectionItems: Array<any> = [];
  selectionItems: Array<any> = [];
  selectedItems: Array<any> = [];
  deltetedItems: Array<any> = [];
  addItems: Array<any> = [];

  constructor(public baseService: BaseServiceService, public utility: Utilities, public helperImages: HelperImages,
    public comunicationService: GeneralComunicationService) { }


  public InitAll(modelo) {

    this.InitList();
    this.SetConfiguracion();
    this.SetModelo(modelo);

  }
  ngOnDestroy(): void {

    this.utility.Unsubscribe(this.parentClearCall);
    this.utility.Unsubscribe(this.parentCallRefresh);
  }
  ngOnInit() {


    this.isInit = true;
    this.OnSetConfig();
    this.OnInit();

    if (this.parentClearCall)
      this.parentClearCall.subscribe(event => {
        this.InitControl(event);
      });
    this.comunicationService.receivedFormEvent.subscribe(event => {
      if (event.Action === FormActionsThroughEmitters.Edit) {
        this.InitControl(event);
      }
    });
    if (this.onInitialized.observers.length) {
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }

  }
  InitControl(event) {
    this.InitList();
    setTimeout(() => {
      this.modelo = event.modelo;
      this.SetModelo(event.modelo);
      this.ValidateSelectionItems();
    }, 100);
  }
  InitList() {
    this.selectionItems = this.utility.Clone(this.allSelectionItems);
    this.selectedItems = [];
    this.deltetedItems = [];
    this.addItems = [];
  }
  OnInit() {

    this.SetConfiguracion();
    if (this.loadOnInit == true) {
      this.Load();
    }
    this.OnEndInit();
  }
  OnEndInit() {


  }
  public OnSetConfig() {

    this.listToConnect.push(this.listName);
    if (this.config) {

      if (!this.url)
        this.url = this.config.ConfigControl.Controller;
      if (this.config.ConfigControl.QueryConfigSelection.Command) {
        this.QueryConfigSelection = this.config.ConfigControl.QueryConfigSelection;

        if (this.config.ConfigControl.QueryConfigSelection.ProcessMessage)
          this.processMessageSelection = this.config.ConfigControl.QueryConfigSelection.ProcessMessage;
      }
      if (this.config.ConfigControl.QueryConfigSelected.Command) {
        this.QueryConfigSelected = this.config.ConfigControl.QueryConfigSelected;

        if (this.config.ConfigControl.QueryConfigSelected.ProcessMessage)
          this.processMessageSelected = this.config.ConfigControl.QueryConfigSelected.ProcessMessage;
      }
      this.pkField = this.config.PkField || this.pkField;
      this.textField = this.config.TextField || this.textField;
      this.propConfiguracionInterna = this.config.ConfigInternalProp || this.propConfiguracionInterna;
      this.titleSelectionItems = this.config.TitleSelectionItems || this.titleSelectionItems;
      this.titleSelectedItems = this.config.TitleSelectedItems || this.titleSelectedItems;
      this.mappingProps = this.config.MappingProps || this.mappingProps;
      this.propConfiguracion = this.config.ConfigProp || this.propConfiguracion;
      this.name = this.config.Name || this.name;
      this.removeSelectedItems = (this.config.RemoveSelectedItems !== null &&
        this.config.RemoveSelectedItems != undefined) ? this.config.RemoveSelectedItems : this.removeSelectedItems;

      let _config = {
        "Controller": "",
        "ProcessMessage": "",
        "PkField": "Id",
        "TextField": "",
        "ConfigInternalProp": "",
        "ConfigProp": "Configuracion",
        "TitleSelectionItems": "",
        "TitleSelectedItems": "",
        "MappingProps": [],
        "ConfigControl": {
          "QueryConfigSelection": { "Url": "", "ProcessMessage": "", "Command": "" },
          "QueryConfigSelected": { "Url": "", "ProcessMessage": "", "Command": "" }
        }
      };


    }

    if (!this.name)
      this.name = this.componentName;
    if (!this.name)
      this.name = Guid.create().toString();
  }

  public ValidarSetModelo(modelo: any): boolean {

    return true;
  }
  _SetModelo(modelo: any) {
    let temp;
    this.selectedItems = [];

    if (this.propConfiguracionInterna) {

      if (typeof modelo[this.propConfiguracion] === 'string') {

        // if (modelo[this.propConfiguracion].startsWith("[")) {

        //   StaticData.ShowActionNotificationError("Los Tipos No Cumplen el Formato", "Error Modelo", 500);
        //   // this.utility.VerModalDanger({ titulo: "Error Modelo", descripcion: "Los metadatos estan mal formados" });
        //   return false;
        // }
        
        if (this.ValidarSetModelo(modelo))
          temp = (!modelo[this.propConfiguracion]) ? {} : JSON.parse(modelo[this.propConfiguracion]);
        else
          return



      }
      else {
        temp = (!modelo[this.propConfiguracion]) ? {} : modelo[this.propConfiguracion];

      }
      if (temp.hasOwnProperty(this.propConfiguracionInterna))
        this.selectedItems = temp[this.propConfiguracionInterna];
      else {
        this.selectedItems = [];
        temp[this.propConfiguracionInterna] = this.selectedItems;

      }

    }
    else {
      temp = JSON.parse(modelo[this.propConfiguracion]);
      this.selectedItems = temp;

    }
  }
  public SetModelo(modelo: any) {

    this._modelo = modelo;
    let temp;
    this.selectedItems = [];
    if (this.modelo) {

      if (this.isModelContainer)
        this._SetModelo(this.modelo.modelo);
      else
        this._SetModelo(this.modelo);
    }
    else {
      this.selectedItems = []
    }
    this.SetConfiguracion();

  }
  _SetConfiguracion(modelo: any) {
    if (this.propConfiguracionInterna) {
      let temp;
      if (typeof modelo[this.propConfiguracion] === 'string') {

        // if (modelo[this.propConfiguracion].startsWith("[")) {

        //   StaticData.ShowActionNotificationError("Los Tipos No Cumplen el Formato", "Error Modelo", 500);
        //   // this.utility.VerModalDanger({ titulo: "Error Modelo", descripcion: "Los metadatos estan mal formados" });
        //   return false;
        // }
        temp = (!modelo[this.propConfiguracion]) ? {} : JSON.parse(modelo[this.propConfiguracion]);
        // if (this.ValidarSetModelo(modelo))
        //   temp = (!modelo[this.propConfiguracion]) ? {} : JSON.parse(modelo[this.propConfiguracion]);
        // else
        //   return;
      }
      else {
        temp = (!modelo[this.propConfiguracion]) ? {} : modelo[this.propConfiguracion];
      }

      temp[this.propConfiguracionInterna] = this.selectedItems;
      modelo[this.propConfiguracion] = JSON.stringify(temp);
    }
    else {
      modelo[this.propConfiguracion] = JSON.stringify(this.selectedItems);
    }
  }
  public SetConfiguracion() {

    if (this.modelo) {

      if (this.isModelContainer)
        this._SetConfiguracion(this.modelo.modelo);
      else
        this._SetConfiguracion(this.modelo);
      //this.modelo.DataAdd = { Configuracion: JSON.stringify(this.ListaPermisos.ToArray()) };
    }
  }
  public ValidateSelectionItems() {

    if (this.removeSelectedItems !== true)
      return;
    this.selectionItems = this.utility.Clone(this.allSelectionItems);
    this.selectedItems.forEach((item, index) => {

      let currentIndex = this.selectionItems.findIndex(x => { return x[this.pkField] == item[this.pkField]; })
      if (currentIndex > -1) {
        this.selectionItems.splice(currentIndex, 1);

      }
      else {
        // this.selectionItems.push(item);
      }
    })

  }

  RemoveItem(item: any, items: Array<any>) {

    let currentIndex = items.findIndex(x => { return x[this.pkField] == item[this.pkField]; })
    if (currentIndex > -1) {
      items.splice(currentIndex, 1);
    }
  }
  EndAction(item: any, items: Array<any>, actionType: string) {

    this.RemoveItem(item, items);
    this.ValidateSelectionItems();
    this.SetConfiguracion();
    this.FireAction(actionType);
  }
  public Add(item, index, items?: Array<any>, remodeIndex?: any) {

    let newItem: any = {};
    newItem[this.pkField] = item[this.pkField];
    newItem[this.textField] = item[this.textField];

    this.mappingProps.forEach((prop, index) => {

      newItem[prop] = item[prop];
    })

    this.selectedItems.push(newItem);
    this.addItems.push(item);

    this.EndAction(item, this.deltetedItems, 'Add');


  }

  public Delete(index, item, items?: Array<any>) {

    this.selectedItems.splice(index, 1);
    this.deltetedItems.push(item);
    this.EndAction(item, this.addItems, 'Delete');

  }
  FireAction(actionType: string) {
    this.onActionEventEmitter.emit({
      modelo: this.modelo,
      deltetedItems: this.deltetedItems,
      selectedItems: this.selectedItems,
      addItems: this.addItems,
      type: this.name,
      componentName: this.componentName,
      actionType: actionType,
    });
  }
  public Load() {

    try {
      if (this.allSelectionItems && this.allSelectionItems.length > 0) {

      }
      else if (this.QueryConfigSelection && this.QueryConfigSelection.Command) {
        let obj = this.utility.GetApiModel('Load', '');
        obj.QueryConfig = this.QueryConfigSelection;
        const _url = (this.QueryConfigSelection.Url) ? this.QueryConfigSelection.Url : this.url;
        this.baseService.Get(obj, null,
          {
            componentName: this.componentName,
            processMessage: this.processMessageSelection,
            method: 'Load'
          }, _url, false, true).then(result => {


            this.allSelectionItems = this.utility.Clone(result.Data);
            this.selectionItems = result.Data;
            this.LoadSelected();
            this.Loaded();

          }).catch(error => {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'Load' });
          })
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'Load' });
      this.utility.VerModalDanger().then(data => { });
    }

  }
  public Loaded() {
  }
  public LoadSelected() {

    try {

      if (this.QueryConfigSelected && this.QueryConfigSelected.Command) {
        let obj = this.utility.GetApiModel('LoadSelected', '');
        obj.QueryConfig = this.QueryConfigSelected;
        const _url = (this.QueryConfigSelected.Url) ? this.QueryConfigSelected.Url : this.url;
        this.baseService.Get(obj, null,
          {
            componentName: this.componentName,
            processMessage: this.processMessageSelection,
            method: 'LoadSelected'
          }, _url, true, true).then(result => {


            this.selectedItems = result.Data;
            this.ValidateSelectionItems();


          }).catch(error => {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'LoadSelected' });
          })
      }
      else {
        this.SetModelo(this.modelo);
        this.ValidateSelectionItems();
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'LoadSelected' });
      this.utility.VerModalDanger().then(data => { });
    }

  }


  dragStart(event: CdkDragStart) {
    // this._currentIndex = this.fieldModels.indexOf(event.source.data); // Get index of dragged type
    // this._currentField = this.child.nativeElement.children[this._currentIndex]; // Store HTML field
  }

  moved(event: CdkDragMove) {
    // Check if stored HTML field is as same as current field
    // if (this.child.nativeElement.children[this._currentIndex] !== this._currentField) {
    //   // Replace current field, basically replaces placeholder with old HTML content
    //   // this.child.nativeElement.replaceChild(this._currentField, this.child.nativeElement.children[this._currentIndex]);
    // }
  }

  itemDropped(event: CdkDragDrop<any[]>) {


    if (event.previousContainer === event.container) {
      moveItemInArray(this.selectedItems, event.previousIndex, event.currentIndex);
    } else {
      this.Add(event.item.data, event.currentIndex);
    }
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  ngAfterViewInit() {

  }
  ngAfterViewChecked() {

  }


}
