import { Injectable } from "@angular/core";
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

const CHAT_URL = `ws://localhost:8282/`;

export interface Message {
    IdAreaEmpresa: number;
    IdRadicado: number
    Nombre: string;
    TipoRadicado: string;
    Token: string;
    Appkey: string;
    IdUsuario: number,
    UsuarioAutenticado: string,
    Base64: string;
    ConfiguracionWebsocket: ConfigWebsocket

}

export interface ConfigWebsocket {
  RutaArchivosTemporales: string,
  RutaArchivosLogs: string,
  ExtensionArchivosWord: string,
  NombreDocumentoTemporalWord: string,
  WebSocketUrl: string
}
// export interface ConfigWebsocket {
//   RutaArchivosTemporales:"C:\\Temp\\",
//   RutaArchivosLogs:"C:\\Temp\\Logs\\",
//   ExtensionArchivosWord:".docx",
//   NombreDocumentoTemporalWord:"DocumentoTemporalBestDoc",
//   WebSocketUrl:"ws://0.0.0.0:8282"
// }

export interface MessageService {

}

@Injectable()
export class DocumentTemplateSocketService {

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<Message>;

    constructor() {
        this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
            map(
                (response: MessageEvent): Message => {
                    console.log(response.data);
                    let data = JSON.parse(response.data)
                    return data;
                }
            )
        );
    }

    public connect(url): AnonymousSubject<MessageEvent> {
      if (!this.subject) {
          this.subject = this.create(url);
          console.log("Successfully connected: " + url);
      }
      return this.subject;
    }

    private create(url): AnonymousSubject<MessageEvent> {
      let ws = new WebSocket(url);
      let observable = new Observable((obs: Observer<MessageEvent>) => {
          ws.onmessage = obs.next.bind(obs);
          ws.onerror = obs.error.bind(obs);
          ws.onclose = obs.complete.bind(obs);
          return ws.close.bind(ws);
      });
      let observer = {
          error: null,
          complete: null,
          next: (data: Object) => {
              console.log('Message sent to websocket: ', data);
              if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify(data));
              }
          }
      };
      return new AnonymousSubject<MessageEvent>(observer, observable);
  }


}
