import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import { StaticData } from '../../helpers/static-data';
import { Utilities } from '../../helpers/utilities';
import { List } from '../../../assets/linqts/compilado/index';
import { BaseTextoSplit } from '../../models/base-texto-split';
import { HelperImages } from 'src/app/helpers/helper-images';
import { GeneralFormComponent } from '../controls/general-form/general-form.component';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-config-extensiones',
  templateUrl: './config-extensiones.component.html',
  styleUrls: ['./config-extensiones.component.scss']
})
export class ConfigExtensionesComponent extends GeneralFormComponent implements OnInit {
  
  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);

  }
  public OnInitEnd() {
    
    if (StaticData.GeneralConfig.ExtensionesValidas)
    {
      this.modelo.modelo.ExtensionesPermitidas = StaticData.GeneralConfig.ExtensionesValidas      
      this.SetModelToControls();
    }    
  }

  _Guardar(form: FormGroup, configButtons?: any) {

    this.modelo.modelo.CtrName = this.modelo.modelo.ExtensionesPermitidas
    let obj: any = null;
    try {
      if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
        return;
      if (form.invalid)
        return;


      const _isEdit = this.IsEdit;
      // const that = this;
      const processMessage = (this.modelo.modelo.PkValue == "0" || this.modelo.modelo.PkValue == "") ? "Creando Registro de " + this.Pagina.Titulo : "Actualizando registro de " + this.Pagina.Titulo;

      if (configButtons && configButtons.Actions) {

        this.helperActions.ExecuteActionsControl(configButtons,
          false, null, this.modelo, this.permissionsConfig,
          this, null, new EventEmitter<any>(), this.componentsContainer,
          null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
      }

      obj = this.utility.GetApiModel("Guardar", this.componentName, null, null, this.modelo.modelo);

      let isGeneralSave = true;

      // ////////Meter validacion antes de este
      if (isGeneralSave) {

        this.baseService.InsertUpdate(obj, form, {
          componentName: this.componentName + "Controller",
          method: "Guardar", processMessage: processMessage
        },
          false, this.componentName, false, true).then(data => {
            
            this.FinGuardar(data.Data);

            if (_isEdit)
              this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
            else
              this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });   

              
              StaticData.GeneralConfig.ExtensionesValidas =  data.Data.CtrName;
            this.OnInitEnd();  
          }).catch(error => { this.SetProcesando(false); });
      }
    }
    catch (error) {
      this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: "_Guardar" });
      this.utility.VerModalDanger().then(data => { });
      this.SetProcesando(false);
    }
  }
}
