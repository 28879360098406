import { Component, OnInit } from '@angular/core';
import { SolicitudRadicadoFacturaComponent } from '../solicitud-radicado-factura/solicitud-radicado-factura.component';

@Component({
  selector: 'app-radicado-factura-recepcion',
  templateUrl: './radicado-factura-recepcion.component.html',
  styleUrls: ['./radicado-factura-recepcion.component.css']
})
export class RadicadoFacturaRecepcionComponent extends SolicitudRadicadoFacturaComponent implements OnInit {

}
