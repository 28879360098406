import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { ConfigWindow } from '../../../models/config-window';
import { EditorAceComponent } from '../../controls/editor-ace/editor-ace.component';

import { ContextMenuComponent } from 'ngx-contextmenu';
import {
  CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray,
  copyArrayItem, transferArrayItem
} from '@angular/cdk/drag-drop';
import { StaticData } from 'src/app/helpers/static-data';

import { Utilities } from 'src/app/helpers/utilities';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { HelperImages } from 'src/app/helpers/helper-images';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-property-grid',
  templateUrl: './property-grid.component.html',
  styleUrls: ['./property-grid.component.css']
})
export class PropertyGridComponent extends ConfigBaseComponent implements OnInit {

  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  @ViewChild('editorAce', { static: false }) public editorAce: EditorAceComponent;
  @ViewChild("editorAceParams", { static: false }) public editorAceParams: EditorAceComponent;
  @ViewChild("editorAceEntity", { static: false }) public editorAceEntity: EditorAceComponent;

  @Input() entities: Array<any> = [];
  @Input() procedures: Array<any> = [];
  @Input() consecutivos: Array<any> = [];
  @Input() viewPermisions: boolean = false;

  @Input() isMetadata: boolean = false;
  @Input() index: number = -1;
  @Output() onDeleteEmitter: EventEmitter<any> = new EventEmitter<any>()
  @Output() onChangedEmitter: EventEmitter<any> = new EventEmitter<any>()
  @Input() formControls: Array<any> = [];
  @Input() formStateControls: Array<any> = [];

  @Input() project: any;

  @Input() tiposDatoBasico: Array<any> = [];




  public crudActionsArr: Array<any> = [{ Name: "GetAll", Label: "GetAll" },
  { Name: "GetById", Label: "GetById" },
  { Name: "GetByFilter", Label: "GetByFilter" },
  { Name: "GetByFilterCompare", Label: "GetByFilterCompare" },
  { Name: "GetByFilterRemoveAllSpaces", Label: "GetByFilterRemoveAllSpaces" },
  { Name: "GetByFilterCompareRemoveAllSpaces", Label: "GetByFilterCompareRemoveAllSpaces" },
  { Name: "GetAllOrderBy", Label: "GetAllOrderBy" },
  { Name: "GetByFilterOrderByDesc", Label: "GetByFilterOrderByDesc" },
  { Name: "GetByFilterContains", Label: "GetByFilterContains" },
  { Name: "GetByFilterStartWith", Label: "GetByFilterStartWith" },
  { Name: "GetByFilterEndWith", Label: "GetByFilterEndWith" }];



  optionsAce: any = { maxLines: 60, printMargin: true };
  /*  constructor(public utility: Utilities,
     public baseService: BaseServiceService,
     public sanitizer: DomSanitizer,
     public helperImages: HelperImages) {
 
     super(utility, baseService, sanitizer, helperImages);
     
 
   } */
  ngOnInit() {


    this.OnInit();
  }
  public Delete(index?: any) {
    this.onDeleteEmitter.emit(this.index);
  }
  public OnInit() {






  }
  resultParentControls: Array<any> = [];
  public GetFormControlsParents(): Array<any> {
    let resultControls: Array<any> = [];
    this.SearchFormControl(this.formControls, resultControls, true);
    //resultControls.find()
    return resultControls.filter(x => this.utility.EsComboBox(x, true));
  }
  public GetFormControls(): Array<any> {
    let resultControls: Array<any> = [];
    this.SearchFormControl(this.formControls, resultControls, false);
    return resultControls.filter(x => this.utility.EsControlFormulario(x));
  }
  SearchFormControl(controls: Array<any>, resultControls: Array<any>, includeGrid: boolean, include?: Array<any>) {
    if (controls && controls.length > 0) {
      controls.forEach(formControl => {
        if (this.utility.EsControlFormulario(formControl) || includeGrid && formControl.ControlType == "GridView") {
          resultControls.push(formControl);
        }
        else {
          if (formControl.Controls && formControl.Controls.length > 0) {
            if (formControl.ControlType === 'Pagina') {
              let pagina = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', formControl.IdPagina);
              this.SearchFormControl(pagina.Controls, resultControls, includeGrid, include)
            }
            else if (this.utility.EsContenedorControles(formControl))
              this.SearchFormControl(formControl.Controls, resultControls, includeGrid, include)
          }
        }
      });
    }
  }
  SearchDataSourceControl(controls: Array<any>, resultControls: Array<any>) {
    if (controls && controls.length > 0) {
      controls.forEach(formControl => {
        if (this.utility.EsComboBox(formControl, true) || formControl.ControlType == "FormDetails") {
          resultControls.push(formControl);
        }
        else {
          if (formControl.Controls && formControl.Controls.length > 0) {
            if (formControl.ControlType === 'Pagina') {
              let pagina = this.utility.FindPage(StaticData.Usuario.Paginas, 'IdPagina', formControl.IdPagina);
              this.SearchDataSourceControl(pagina.Controls, resultControls)
            }
            else if (this.utility.EsContenedorControles(formControl))
              this.SearchDataSourceControl(formControl.Controls, resultControls)
          }
        }
      });
    }
  }
  public ObjectTypesArr: Array<any> = [
    { Name: 'None', Label: 'Ninguno' },
    { Name: 'Rule', Label: 'Regla' },
    { Name: 'Action', Label: 'Accion' },
    { Name: 'Query', Label: 'Proceso' },
    { Name: 'Mail', Label: 'Correo' },
    { Name: 'Template', Label: 'Plantilla' }
    // { Name: 'Notification', Label: 'Notificacion' },
    //{ Name: 'LoadProcess', Label: 'SupeProceso de Carga' }
  ];
  public GetRules(force: Boolean = false): Array<any> {
    return [];
  }
  public GetQuerys(force: Boolean = false): Array<any> {
    return [];
  }
  public GetObjectSystem(objectType, force: Boolean = false): Array<any> {

    return [];
  }
  public OnChangeHandler(event, setUpdate: boolean = true) {


    this.subControl.ConfigControl = event;
    if (setUpdate)
      this.UpdateEditor(this.subControl);
  }

  public OnChangesubControlHandler(event) {


    this.subControl = event;

  }

  itemDropped(event: CdkDragDrop<any[]>) {


    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Controls, event.previousIndex, event.currentIndex);

      // this.onChangedEmitter.emit(this.subControl);

    } else {

    }
  }
  itemDroppedFilterControls(event: CdkDragDrop<any[]>) {


    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.FilterControls, event.previousIndex, event.currentIndex);

      // this.onChangedEmitter.emit(this.subControl);

    } else {

    }
  }
  public IsControlWithConfigControl(): boolean {
    return (this.subControl.ControlType == 'GridView' ||
      this.subControl.ControlType == 'ComboBox' || this.subControl.ControlType == 'MultiComboBox');
  }
  public UpdateEditor(subControl?: any) {
    this._subControl = subControl;
    if (this.subControl && this.subControl.ControlType !== 'Column') {
      if (this.editorAce)
        this.editorAce.Update(this.subControl.ConfigControl);
    }
  }
  public VerEditor(tipo: any, subTipo?: any) {

    const that = this;
    this.utility.VerEditor(tipo, that.subControl, true, "Editor de [" + this.subControl.Name + ']').then(data => {

      if (data.dialogResult == true &&
        data.modelo) {

        that.subControl = data.modelo;
        if (tipo !== 1) {
          //that.editorAce.Update(that.subControl.ConfigControl);
          that.UpdateEditor(that.subControl);
        }
      }
    });

  }
  public VerEditorPlantilla() {

    const that = this;
    this.utility.VerEditor(2, that.subControl.Template, true, "Editor de [" + this.subControl.Name + ']', "html", true).then(data => {

      if (data.dialogResult == true &&
        data.modelo) {

        that.subControl.Template = data.modelo;

      }
    });

  }


  public ExportToJson() {

    const filename = this.subControl.Name + '.json';

    const jsonStr = JSON.stringify(this.subControl, null, 2);// JSON.stringify(this.PaginaSeleccionada.ConfigPage);

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  public ImportToJson(evt) {
    try {
      
      let files = evt.target.files;
      if (!files.length) {
        alert('No selecciono un archivo!');
        return;
      }
      let file = files[0];
      let reader = new FileReader();
      const self = this;
      reader.onload = (event: any) => {

        setTimeout(() => {
          let importObject: any = JSON.parse(event.target.result.toString());

          for (var prop in importObject)
            this.subControl[prop] = importObject[prop];
          evt.target.value = null;
          this.UpdateEditor();
        }, 50);

        //this.subControl = JSON.parse(event.target.result.toString());
        //this.UpdateEditor();

      };
      reader.readAsText(file);
    } catch (err) {
      console.error(err);
    }
  }


  actionsDropped(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.subControl.Actions, event.previousIndex, event.currentIndex);

    } else {

    }
  }
  public AddAction() {
    if (!this.subControl.Actions)
      this.subControl.Actions = [];
    this.subControl.Actions.push(this.utility.GetColumnActionModel());
  }

  public DeleteAction(event) {
    if (this.subControl && this.subControl.Actions) {
      this.subControl.Actions.splice(event, 1);
    }
  }

  public ViewHelps() {

    //FieldMappingConfigComponent
    if (!this.subControl.Helps)
      this.subControl.Helps = [];
    const config = this.utility.GetConfigWindow('');
    let isNew: boolean = false;
    let windowModel: any = {

      modelo: this.subControl.Helps,
      project: this.project
    };

    this.utility.VerModalComponente('HelpsConfigComponent', windowModel, config).then(dataResultOnClose => {
      if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {



      }
      else {

      }
    });
  }

  public VerPermisos() {
    //InfoPermissionComponent

    let config: ConfigWindow = new ConfigWindow();

    if (!this.subControl.Permissions)
      this.subControl.Permissions = [];
    config.classWindow = 'info';
    config.titleWindow = "Permisos";
    config.returnInstance = false;
    config.modal = false;
    config.width = 600;
    config.height = 600;
    config.viewOk = false;
    config.cancel = 'Cerrar';
    let windowModel: any = {
      project: this.project,
      modelo: this.subControl,

    };
    this.utility.VerModalComponente('InfoPermissionComponent', windowModel, config).then(dataResultOnClose => {
      if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {



      }
      else {

      }
    });

  }

  public ViewPropertyGridCollections(prop, ControlType, extraPropertys?: any) {

    
    const config = this.utility.GetConfigWindow('');
    let isNew: boolean = false;
    if (!this.subControl[prop])
      this.subControl[prop] = [];
    let windowModel: any = {

      controlType: ControlType,
      items: this.subControl[prop],
      modelo: this.subControl,
      project: this.project,
      entities: this.entities,
      procedures: this.procedures,
      formControls: this.formControls,
      formStateControls: this.formStateControls,
      index: this.index + 1
    };
    if (extraPropertys) {
      for (const prop in extraPropertys)
        windowModel[prop] = extraPropertys[prop];
    }

    this.utility.VerModalComponente('PropertyGridCollectionsComponent', windowModel, config).then(dataResultOnClose => {
      if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {

        

      }
      else {

      }
    });
  }
  // public ViewPropertyGridCollections(prop, ControlType) {


  //   const config = this.utility.GetConfigWindow('');
  //   let isNew: boolean = false;
  //   let windowModel: any = {

  //     controlType: ControlType,
  //     items: this.subControl[prop],
  //     modelo: this.subControl,
  //     project: this.project,
  //     entities: this.entities,
  //     procedures: this.procedures,
  //     formControls: this.formControls
  //   };

  //   this.utility.VerModalComponente('PropertyGridCollectionsComponent', windowModel, config).then(dataResultOnClose => {
  //     if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {



  //     }
  //     else {

  //     }
  //   });
  // }

  public ViewItems(isGridView) {


    const config = this.utility.GetConfigWindow('');
    let isNew: boolean = false;
    let windowModel: any = {

      modelo: this.subControl.ConfigControl,
      columns: null,
      pkField: null,
      textField: null
    };
    if (!this.subControl.ConfigControl.Items)
      this.subControl.ConfigControl.Items = [];
    if (isGridView) {
      windowModel.columns = this.subControl.Controls;
    }
    else {
      windowModel.pkField = this.subControl.PkField;
      windowModel.textField = this.subControl.TextField;
    }
    this.utility.VerModalComponente('PropertiGridItemsComponent', windowModel, config).then(dataResultOnClose => {
      if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {



      }
      else {


        this.subControl.ConfigControl.Items = null;

      }
    });
  }
}

