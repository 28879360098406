import {Component, Input,Output, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NavItem} from '../menu-item/nav-item';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  @Input() items: NavItem[];
  @ViewChild('childMenu',{static:true}) public childMenu;
 
  @Output() navegarEmitter :EventEmitter<any> = new EventEmitter<any>();

  public Navegar(item:any){
    
    if(this.navegarEmitter.observers.length>0)
       this.navegarEmitter.emit(item);
  }
  constructor(public router: Router) {
  }

  ngOnInit() {
  }
}
