import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import {
  DataTableAjaxOrderTypes,
  FormActionsThroughEmitters,
  TipoAdminPermisos,
  TipoObjetoParaPermisos, TipoParaPermisos
} from '../../../../models/general.enum';
import { StaticData } from '../../../../helpers/static-data';
import { Utilities } from '../../../../helpers/utilities';
import { List } from '../../../../../assets/linqts/compilado/index';
import { Subject } from 'rxjs';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Guid } from 'guid-typescript';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { DataTableDirective } from 'angular-datatables';
import { BaseServiceService } from 'src/app/services/base-service.service';

// FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-permission-table',
  templateUrl: './permission-table.component.html',
  styleUrls: ['./permission-table.component.css']
})
export class PermissionTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() onInitialized = new EventEmitter<any>();

  @Output() componentName: string = 'AdminPermisosComponent';
  @Input() url: string = "TExpediente";
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() name: string = Guid.create().toString();
  @Input() tableName: string = Guid.create().toString();
  @Input() type: TipoParaPermisos = TipoParaPermisos.Usuario;
  @Input() tipoAdminPermisos: TipoAdminPermisos = TipoAdminPermisos.Documento;
  @Input() config: any;
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;
  @Input() titulo: string;
  @Input() guardarPermitido: boolean = true;
  @Input() verPermitido: boolean = true;



  isInit: boolean = false;
  _modelo: any;

  @Input() set modelo(value: any) {
    this._modelo = value;
    if (this.isInit == true) {

    }
  }

  get modelo(): any {
    return this._modelo;
  }

  @Input() maxWith: any = 600;

  estiloTablas: any = {};

  dtOptions: any;

  imagenChecked: string;
  imagenUnChecked: string;
  imagenUsuario: string;
  imagenRole: string;
  imagenGrupo: string;
  imagenArea: string;

  imagenEditar: string;
  imagenEliminar: string;
  imagenConsultar: string;
  imagenImportar: string;
  imagenExportar: string;
  imagenGuardar: string;
  imagenFiltrar: string;
  imagenVer: string;
  imagenVerPermisos: string;
  imagenAdminPermisos: string;
  imagenDescargar: string;
  imagenCambiarDoc: string;
  imagenCrearDoc: string;
  imagenTodos: string;
  imagenDecifrarGrid: string;
  imagenDecifrarForm: string;
  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;

  @Input() isForPermissions: boolean = false;
  @Input() setDefault: boolean = false;
  currentItem: any;
  tituloPermisos: string;
  tipoPermisos: string;
  encontradoPor = [];
  @Input() pageLength: any;
  stringType: string = "";
  filterProp: string = "Id";

  imagenEditorJson: string = '';
  imagenAEditorAce: string = '';

  //@Input() propPermission: string = "Permissions";
  @Input() propName: string = "Nombre";
  @Input() propDescription: string = null;
  @Input() label: string = "Nombre";
  @Input() labelDescription: string = null;

  @Input() propConfiguracionInterna: string = '';
  @Input() propConfiguracion: string = 'Configuracion';
  @Input() propPermitidos: string = 'UsuariosPermitidos';
  imagen: string;

  @Input() ListaPermisos = new List<any>([]);
  permitidos = new List<any>([]);
  @Input() conCamposPermisos: boolean = true;
  @Input() items: Array<any> = [];

  @Output() loadedGridEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() columns: any = [];

  @Input() QueryConfig: any = null;
  @Input() withPagination: boolean = true;

  @Input() processMessage: string = "";

  @Input() dataTableAjaxOrderType: DataTableAjaxOrderTypes = DataTableAjaxOrderTypes.Default;
  @Input() outPutParam: boolean = true;

  constructor(public baseService: BaseServiceService, public utility: Utilities, public comunicationService: GeneralComunicationService) { }

  ngOnInit() {

    

    this.loadedGridEmitter.subscribe(result => {

      
      this.items = result.data;
      this.SetSeleccionados();

      this.SetConfiguracion();
  
    });


    //this.dtOptions = this.utility.GetDtOptionsHtmlTable(this.pageLength);

    
    let url = (this.url) ? this.url : this.componentName;
    if (this.config.ConfigControl && this.config.ConfigControl.Controller)
      url = this.config.ConfigControl.Controller;

    let urlServer = this.utility.GetUrlServer(url);

  

    this.stringType = TipoParaPermisos[this.type];
    this.imagen = this.utility.GetPathImages(this.utility.GetNodeType(TipoParaPermisos[this.type]));

    this.imagenChecked = 'ms-Icon--CompletedSolid';
    this.imagenUnChecked = 'ms-Icon--ChromeClose';
    this.imagenUsuario = 'ms-Icon--FabricUserFolder';
    this.imagenRole = 'ms-Icon--UserFollowed';
    this.imagenGrupo = 'ms-Icon--Group';
    this.imagenArea = 'ms-Icon--Org';
    this.imagenEditar = 'ms-Icon--PageEdit';
    this.imagenEliminar = 'ms-Icon--Delete';
    this.imagenConsultar = 'ms-Icon--SearchData';
    this.imagenImportar = 'ms-Icon--CloudImportExport';
    this.imagenExportar = 'ms-Icon--Upload';
    this.imagenGuardar = 'ms-Icon--Save';
    this.imagenFiltrar = 'ms-Icon--Filter';
    this.imagenVer = 'ms-Icon--RedEye';
    this.imagenVerPermisos = 'ms-Icon--Permissions';
    this.imagenAdminPermisos = 'ms-Icon--Permissions';
    this.imagenDescargar = 'ms-Icon--Download';
    this.imagenEditorJson = 'ms-Icon--CodeEdit';
    this.imagenAEditorAce = 'ms-Icon--EditSolidMirrored12';
    this.imagenCambiarDoc = 'ms-Icon--ChangeEntitlements';
    this.imagenCrearDoc = 'ms-Icon--PageAdd';
    this.imagenTodos = 'ms-Icon--PageAdd';
    this.imagenDecifrarForm = 'ms-Icon--Encryption';
    this.imagenDecifrarGrid = 'ms-Icon--Encryption';

    if (this.maxWith) {
      this.estiloTablas = { 'overflow-x': 'scroll', 'overflow-y': 'hidden', 'max-width': this.maxWith + 'px' };
    }

    this.isInit = true;

    //   if (!this.modelo[this.propConfiguracion])
    //   this.modelo[this.propConfiguracion] = [];
    // this.ListaPermisos = new List<any>(this.modelo[this.propConfiguracion]);
    switch (this.type) {
      case TipoParaPermisos.Usuario:

        this.propDescription = "Descripcion";
        this.label = "Usuario";
        this.labelDescription = "Nombre";
        this.propPermitidos = 'UsuariosPermitidos';
        this.columns = [{ data: this.propName }, { data: 'firstName' }, { data: 'lastName' }];

        this.QueryConfig = {
          Command: 'BusquedaUsuariosParaPermisos',
          IsSp: true,
          WithPagination: true,
          Entity: {
            IdEmpresa: -1,

          }
        };

        break;
      case TipoParaPermisos.Role:

        this.propDescription = null;
        this.label = "Role";
        this.labelDescription = null;
        this.propPermitidos = 'RolesPermitidos';

        this.columns = [{ data: this.propName }, { data: 'Exportar' }];
        this.QueryConfig = {
          Command: 'BusquedaRoleCrud',
          IsSp: true,
          WithPagination: true,
          Entity: {
            IdEmpresa: -1,

          }
        };
        this.processMessage = "Cargando Roles";
        break;
      case TipoParaPermisos.AreaEmpresa:

        this.propDescription = null;
        this.label = "Area Empresa";
        this.labelDescription = null;
        this.propPermitidos = 'AreasEmpresaPermitidas';

        this.QueryConfig = {
          Command: 'BusquedaAreasParaPermisos',
          IsSp: true,
          WithPagination: true,
          Entity: {
            IdEmpresa: -1,

          }
        };
        this.processMessage = "Cargando Roles";
        break;
      case TipoParaPermisos.Grupo:

        this.propDescription = null;
        this.label = "Grupo";
        this.labelDescription = null;
        this.propPermitidos = 'GruposPermitidos';

        this.QueryConfig = {
          Command: 'BusquedaGruposParaPermisos',
          IsSp: true,
          WithPagination: true,
          Entity: {
            IdEmpresa: -1,

          }
        };
        this.processMessage = "Cargando Grupos";
        break;
      case TipoParaPermisos.SedeEmpresa:

        this.propDescription = null;
        this.label = "Sede Empresa";
        this.labelDescription = null;
        this.propPermitidos = 'SedesPermitidas';

        this.QueryConfig = {
          Command: 'BusquedaSedesParaPermisos',
          IsSp: true,
          WithPagination: true,
          Entity: {
            IdEmpresa: -1,

          }
        };
        this.processMessage = "Cargando Sedes";
        break;
      case TipoParaPermisos.ApplicationAllowed:

        this.propDescription = null;
        this.label = "Aplicacion Permitida";
        this.labelDescription = null;
        this.processMessage = "Cargando Aplicaciones";
        break;
    }

    this.dtOptions = this.utility.GetDtOptions(this.columns, true, this.config.WithScrolling,
      this.config.ScrollY, this.config.ExportButtons, false,
      this.config.RowGroupColumns, this.config.IsResponsive);

    this.dtOptions.ajax = this.baseService.AjaxDataTables(urlServer, this.name, this.componentName, null,
      true, null, this, null, null,
      null, false, this.loadedGridEmitter);

  }

  public GetDataTableFilter(): any {

    let filter = '';
    let filterParent = null;
    let obj = this.utility.GetApiModelGrillaValidation('_CargarGrilla', this.componentName, filterParent, null, null, null, null, filter);
    if (this.QueryConfig) {
      obj.QueryConfig = this.utility.Clone(this.QueryConfig);

      if (this.QueryConfig.MappingParameters) {
        if (!obj.QueryConfig.Entity)
          obj.QueryConfig.Entity = {};
        this.QueryConfig.MappingParameters.forEach(parameter => {
          obj.QueryConfig.Entity[parameter.Name] = this.utility.GetParmeterValue(parameter, this.modelo, null, this.QueryConfig, null, this, null);
        })
      }
    }
    if (this.config && this.config.Query) {
      obj.QueryConfig.Query = this.config.Query;
    }
    /*   if (this.include)
          obj.QueryConfig.Include = this.include; */
    if (!this.QueryConfig)
      obj.QueryConfig.WithPagination = this.withPagination;

    var okUser = this.utility.ValidateUser(obj);

    obj.CtrName = this.name;
    this.processMessage =this.processMessage;
    return {
      obj: obj,
      notLoad: false,
      processMessage: this.processMessage,
      startIndexColumn: 0,
      columns: this.columns,
      dataTableAjaxOrderType: this.dataTableAjaxOrderType,
      outPutParam: this.outPutParam,
      loadNotifyType: this.config.LoadNotifyType
    };
  }

  ngAfterViewInit() {


  }

  ngOnDestroy(): void {
    // this.utility.Unsubscribe(this.parentClearCall);
    // this.utility.Unsubscribe(this.parentCallRefresh);
  }


  public SetModelo(modelo: any) {

    this._modelo = modelo;
    if (this.isForPermissions) {
      return;

    }
    if (this.modelo) {
      if (this.isModelContainer) {
        this.ListaPermisos = this.utility.GetPermisos(this.modelo.modelo, true, this.propConfiguracion, false, this.propConfiguracionInterna, true);
        if (this.conCamposPermisos == true) {
          this.permitidos = this.utility.GetPermitidos(this.modelo.modelo, true, this.propPermitidos);

        }
      }
      else {
        this.ListaPermisos = this.utility.GetPermisos(this.modelo, true, this.propConfiguracion, false, this.propConfiguracionInterna, true);
        if (this.conCamposPermisos == true) {
          this.permitidos = this.utility.GetPermitidos(this.modelo, true, this.propPermitidos);

        }
      }
    }
    else {
      this.ListaPermisos = new List<any>([]);
      this.permitidos = new List<any>([]);

    }

    this.SetSeleccionados();

    this.SetConfiguracion();

  }
  public SetSeleccionados() {

    for (let i = 0; i < this.items.length; i++) {
      let permiso = this.ListaPermisos.FirstOrDefault(x => { return x[this.filterProp] == this.items[i][this.filterProp] && (x.Tipo == this.type || x.Tipo == this.stringType) });

      if (permiso) {
        this.items[i].Seleccionado = true;

        this.utility.SetPermisosItemActual(this.items[i], permiso, false);
        if (!this.permitidos.Any(x => { return x == 'code' + this.items[i].Id })) {
          this.permitidos.Add('code' + this.items[i].Id);
        }

      }
      else {
        this.items[i].Seleccionado = false;

        this.utility.SetValorPermisosItemActual(this.items[i], false, false, false);

        if (this.permitidos.Any(x => { return x == 'code' + this.items[i].Id })) {
          this.permitidos = this.permitidos.RemoveAll(x => { return x == 'code' + this.items[i].Id });
        }
      }
      //permitidos[i].Seleccionado = this.ListaPermisos.Any(x => { return x.Tipo == tipoParaPermisos });
    }
  }

  _SetConfiguracion(modelo: any) {

    if (this.propConfiguracionInterna) {
      let temp;
      if (typeof modelo[this.propConfiguracion] === 'string') {
        temp = (!modelo[this.propConfiguracion]) ? {} : JSON.parse(modelo[this.propConfiguracion]);
      }
      else {
        temp = (!modelo[this.propConfiguracion]) ? {} : modelo[this.propConfiguracion];
      }
      temp[this.propConfiguracionInterna] = this.ListaPermisos.ToArray();
      modelo[this.propConfiguracion] = JSON.stringify(temp);
    }
    else {
      modelo[this.propConfiguracion] = JSON.stringify(this.ListaPermisos.ToArray());
    }
  }

  public SetConfiguracion() {

    if (this.modelo) {

      if (this.isModelContainer)
        this._SetConfiguracion(this.modelo.modelo);
      else
        this._SetConfiguracion(this.modelo);
    }

  }
  _SetPermitidos(modelo: any) {

    if (modelo && this.conCamposPermisos)
      modelo[this.propPermitidos] = this.permitidos.ToArray().join('|');// '|' + permitidos.ToArray().join('|');

  }
  public SetPermitidos() {

    if (this.isModelContainer)
      this._SetPermitidos(this.modelo.modelo);
    else
      this._SetPermitidos(this.modelo);

  }

  public Adicionar(item: any) {

    if (!this.guardarPermitido)
      return;
    if (this.Disabled)
      return;

    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = true;
    item.Consultar = true;

    if (!this.ListaPermisos.Any(x => {
      return x.Id == item.Id &&
        (x.Tipo == this.type || x.Tipo == this.stringType)
    })) {
      this.ListaPermisos.Add(item);
    }
    if (this.conCamposPermisos == true) {
      if (!this.permitidos.Any(x => { return x == 'code' + item.Id })) {
        this.permitidos.Add('code' + item.Id);
      }
    }

    this.SetConfiguracion();
    this.SetPermitidos();
    this.FireAction('Add');

  }

  public Eliminar(item: any) {

    if (!this.guardarPermitido)
      return;
    if (this.Disabled)
      return;

    item.IdEmpresa = StaticData.Usuario.IdEmpresa;
    item.Seleccionado = false;
    item.Consultar = false;
    this.SetInitValuesPermiso(item, false, true);
    this.ListaPermisos = this.ListaPermisos.RemoveAll(x => {
      return x.Id == item.Id &&
        (x.Tipo == this.type || x.Tipo == this.stringType)
    });

    if (this.conCamposPermisos == true)
      this.permitidos = this.permitidos.RemoveAll(x => { return x == 'code' + item.Id });

    this.SetConfiguracion();
    this.SetPermitidos();
    this.FireAction('Delete');

  }

  SetInitValuesPermiso(item, value: boolean, clear: boolean = false) {
    if (!item.Heredar || item.Heredar.length == 0) {
      item.Heredar = [{ Nombre: 'Eliminar', Valor: value },
      { Nombre: 'Editar', Valor: value },
      { Nombre: 'Guardar', Valor: value }];
    }

    if (!item.HeredarExpediente || item.HeredarExpediente.length == 0) {
      item.HeredarExpediente = [
        { Nombre: 'Eliminar', Valor: value },
        { Nombre: 'Editar', Valor: value },
        { Nombre: 'Guardar', Valor: value },
        { Nombre: 'Descargar', Valor: value },
        { Nombre: 'Ver', Valor: value },
        { Nombre: 'CambiarDoc', Valor: value }];

    }

    if (clear) {
      item.Heredar.forEach(x => {
        x.Valor = value;
      });

      item.HeredarExpediente.forEach(x => {
        x.Valor = value;
      });
    }
    if (!item.Custom || item.Custom.length == 0)
      item.Custom = [];
  }


  public Seleccionar(item: any) {

    if (item.Seleccionado == true) {
      this.Eliminar(item);
    }
    else {
      this.Adicionar(item);
    }

  }

  public AsignarPermiso(item: any, permiso: string) {

    if (!this.guardarPermitido)
      return;

    let _permiso = this.ListaPermisos.FirstOrDefault(x => {
      return x.Id == item.Id &&
        (x.Tipo == this.type || x.Tipo == this.stringType)
    });

    if (item[permiso] == true) {
      item[permiso] = !item[permiso];
      if (_permiso && _permiso[permiso] !== item[permiso])
        _permiso[permiso] = item[permiso];
      this.SetConfiguracion();
      this.SetPermitidos();
    }
    else {
      item[permiso] = !item[permiso];
      if (_permiso && _permiso[permiso] !== item[permiso])
        _permiso[permiso] = item[permiso];
      this.Adicionar(item);
    }

    this.FireAction('Set');

  }
  FireAction(actionType: string) {

    this.onActionEventEmitter.emit({
      modelo: this.modelo,
      type: this.name,
      componentName: this.componentName,
      actionType: actionType,
    });

  }



}
