import {
  Component, ViewChildren, ViewChild, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  QueryList, forwardRef, Inject
} from '@angular/core';
import {
  FormActionsThroughEmitters,
  TipoAdminPermisos,
  TipoObjetoParaPermisos, TipoParaPermisos
} from '../../../../models/general.enum';
import { StaticData } from '../../../../helpers/static-data';
import { Utilities } from '../../../../helpers/utilities';
import { List } from '../../../../../assets/linqts/compilado/index';
import { Subject } from 'rxjs';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Guid } from 'guid-typescript';
import { GeneralComunicationService } from 'src/app/services/general-comunication.service';
import { PermissionTableComponent } from '../permission-table/permission-table.component';

// FABRIC VARIABLE (necesaria para inicializar elementos de FABRIC UI en typeScript)
declare var fabric: any;

@Component({
  selector: 'app-permission-tab',
  templateUrl: './permission-tab.component.html',
  styleUrls: ['./permission-tab.component.css']
})
export class PermissionTabComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;

  @ViewChildren(PermissionTableComponent) permissionTables: QueryList<PermissionTableComponent>;


  @Output() onInitialized = new EventEmitter<any>();
  @Output() componentName: string = 'AdminPermisosComponent';
  @Input() public name: string = 'AdminPermisosComponent';
  @Input() public nameSecurity: string = 'AdminPermisosComponent';
  @Input() public nameSecurityInternal: string = 'AdminPermisosComponentInternal';
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() config: any;
  @Input() isModelContainer: boolean = false;
  @Input() public parentClearCall: Subject<any>;
  @Input() public parentCallRefresh: Subject<any>;
  @Input() tituloUsuarios: string;
  @Input() tituloRoles: string;
  @Input() tituloGrupos: string;
  @Input() tituloAreasEmpresa: string;
  @Input() guardarPermitido: boolean = true;
  @Input() verPermitido: boolean = true;
  @Input() conCamposPermisos: boolean = true;
  @Input() nombreTablaUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() nombreTablaRolesPermitidos: string = 'RolesPermitidos';
  @Input() nombreTablaGruposPermitidos: string = 'GruposPermitidos';
  @Input() nombreTablaAreasEmpresaPermitidos: string = 'AreasEmpresaPermitidos';
  @Input() nombreTabUsuarios: string = 'usuarios-tabcontent';
  @Input() nombreHrefTabUsuarios: string = '#usuarios-tabcontent';
  @Input() nombreTabRoles: string = 'roles-tabcontent';
  @Input() nombreHrefTabRoles: string = '#roles-tabcontent';
  @Input() nombreTabGrupos: string = 'grupos-tabcontent';
  @Input() nombreHrefTabGrupos: string = '#grupos-tabcontent';
  @Input() nombreTabAreas: string = 'areas-tabcontent';
  @Input() nombreHrefTabAreas: string = '#areas-tabcontent';
  @Input() index: number;

  isInit: boolean = false;
  _modelo: any;

  @Input() set modelo(value: any) {
    this._modelo = value;
    if (this.isInit == true) {

    }
  }

  get modelo(): any {
    return this._modelo;
  }


  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;

  @Input() isForPermissions: boolean = false;
  @Input() setDefault: boolean = false;
  currentItem: any;
  tituloPermisos: string;
  tipoPermisos: string;
  encontradoPor = [];
  @Input() pageLength: any

  public get VerTabUsuarios(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosUsuario != true) ? true : false;
  }
  public get VerTabRoles(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosRoles != true) ? true : false;
  }
  public get VerTabGrupos(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosGrupos != true) ? true : false;
  }
  public get VerTabAreaEmpresa(): any {
    return (StaticData.GeneralConfig.ConfigUI.DeshabilitarTabPermisosAreaEmpresa != true) ? true : false;
  }

  @Input() propConfiguracionInterna: string = '';
  @Input() propConfiguracion: string = 'Configuracion';
  @Input() propUsuariosPermitidos: string = 'UsuariosPermitidos';
  @Input() propRolesPermitidos: string = 'RolesPermitidos';
  @Input() propAreasEmpresaPermitidas: string = 'AreasEmpresaPermitidas';
  @Input() propGruposPermitidos: string = 'GruposPermitidos';
  @Input() maxWith: any = 600;

  estiloTablas: any = {};

  @Input() tipoAdminPermisos: TipoAdminPermisos = TipoAdminPermisos.Documento;


  constructor(public utility: Utilities, public comunicationService: GeneralComunicationService) { }
  ngOnInit() {

    
    this.OnInit();
    this.OnSetConfig();
    if (this.maxWith) {
      this.estiloTablas = { 'overflow-x': 'scroll', 'overflow-y': 'hidden', 'max-width': this.maxWith + 'px' };
    }
    this.SetModelo(this.modelo);
    this.isInit = true;

    if (this.parentClearCall) {
      // console.error("AdminPermisosComponent,parentClearCall observers: "+this.parentClearCall.observers.length);
      this.parentClearCall.subscribe(event => {
        this.InitControl(event);
      });
    }
    this.comunicationService.receivedFormEvent.subscribe(event => {
      if (event.Action == FormActionsThroughEmitters.Edit) {
        this.InitControl(event);
      }
    });
    if (this.onInitialized.observers.length) {
      // console.error("AdminPermisosComponent,onInitialized, observers: "+this.onInitialized.observers.length);
      setTimeout(() => {

        this.onInitialized.emit(this);
      }, 1000);
    }

    if (this.index) {
      this.nombreTablaUsuariosPermitidos = this.nombreTablaUsuariosPermitidos + this.index.toString();
      this.nombreTablaRolesPermitidos = this.nombreTablaRolesPermitidos + this.index.toString();
      this.nombreTablaGruposPermitidos = this.nombreTablaGruposPermitidos + this.index.toString();
      this.nombreTablaAreasEmpresaPermitidos = this.nombreTablaAreasEmpresaPermitidos + this.index.toString();
      this.nombreTabUsuarios = this.nombreTabUsuarios + this.index.toString();
      this.nombreHrefTabUsuarios = this.nombreHrefTabUsuarios + this.index.toString();
      this.nombreTabRoles = this.nombreTabRoles + this.index.toString();
      this.nombreHrefTabRoles = this.nombreHrefTabRoles + this.index.toString();
      this.nombreTabGrupos = this.nombreTabGrupos + this.index.toString();
      this.nombreHrefTabGrupos = this.nombreHrefTabGrupos + this.index.toString();
      this.nombreTabAreas = this.nombreTabAreas + this.index.toString();
      this.nombreHrefTabAreas = this.nombreHrefTabAreas + this.index.toString();
    }

  }
  ngAfterViewInit() {

    setTimeout(() => {
      var PivotElement = document.querySelector("#" + this.nameSecurity);
      new fabric['Pivot'](PivotElement);

      var PivotElement2 = document.querySelector("#" + this.nameSecurityInternal);
      new fabric['Pivot'](PivotElement2);
    }, 400);

  }

  ngOnDestroy(): void {

    this.utility.Unsubscribe(this.parentClearCall);
    this.utility.Unsubscribe(this.parentCallRefresh);

  }
  InitControl(event) {
    setTimeout(() => {

      this.modelo = event.modelo;
      this.SetModelo(event.modelo);

    }, 100);
  }
  public OnInit() {


  }

  public OnSetConfig() {

    this.nameSecurity = "msPivotAdminPermisos" + "_" + Guid.create().toString();
    this.nameSecurityInternal = "msPivotAdminPermisosInternal" + "_" + Guid.create().toString();
    if (this.setDefault) {
      this.tituloUsuarios = "Usuarios";
      this.tituloRoles = "Roles";
      this.tituloAreasEmpresa = "Areas";
      this.tituloGrupos = "Grupos";
    }
    else if (this.config) {
      this.propConfiguracionInterna = this.config.ConfigInternalProp || this.propConfiguracionInterna;
      this.propConfiguracion = this.config.ConfigProp || this.propConfiguracion;
      this.tituloUsuarios = this.config.UsersTitle || this.tituloUsuarios;
      this.tituloRoles = this.config.RolesTitle || this.tituloRoles;
      this.tituloAreasEmpresa = this.config.AreasTitle || this.tituloAreasEmpresa;
      this.tituloGrupos = this.config.GroupsTitle || this.tituloGrupos;
      this.conCamposPermisos = (this.config.WithPermisionsField !== null) ? this.config.WithPermisionsField : this.conCamposPermisos;
      this.maxWith = this.config.MaxWith || this.maxWith;

      if (this.config.NotIsPage == false)
        this.tipoAdminPermisos = TipoAdminPermisos.Pagina;// (this.config.NotIsPage !== null) ? this.config.NotIsPage : this.noEsPagina;
      if (this.config.IsExpe)
        this.tipoAdminPermisos = TipoAdminPermisos.Expediente;

      this.propUsuariosPermitidos = this.config.UsersProp || this.propUsuariosPermitidos;
      this.propRolesPermitidos = this.config.RolesProp || this.propRolesPermitidos;
      this.propAreasEmpresaPermitidas = this.config.AreasProp || this.propAreasEmpresaPermitidas;
      this.propGruposPermitidos = this.config.GroupsProp || this.propGruposPermitidos;
      this.name = this.config.Name || this.name;



      /*   @Input() propUsuariosPermitidos: string = 'UsuariosPermitidos';
        @Input() propRolesPermitidos: string = 'RolesPermitidos';
        @Input() propAreasEmpresaPermitidas: string = 'AreasEmpresaPermitidas';
        @Input() propGruposPermitidos: string = 'GruposPermitidos'; */

      let _config = {
        "ConfigInternalProp": "",
        "ConfigProp": "Configuracion",
        "UsersTitle": "",
        "RolesTitle": "",
        "AreasTitle": "",
        "GroupsTitle": "",
        "UsersProp": "UsuariosPermitidos",
        "RolesProp": "RolesPermitidos",
        "AreasProp": "AreasEmpresaPermitidas",
        "GroupsProp": "GruposPermitidos",
        "WithPermisionsField": true,
        "NotIsPage": true,
        "IsDoc": false,
        "MaxWith": 900

      };
    }
  }

  public SetModelo(modelo: any) {

    this._modelo = modelo;
    
    if (this.permissionTables)
      this.permissionTables.forEach((element: PermissionTableComponent, index: number) => {

        element.SetModelo(modelo);
      });
  }

}
