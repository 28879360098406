import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigBaseComponent } from '../../../models/config-base-component';
import { FormGroup, FormControl, NgForm, Validators } from '@angular/forms';
import { DynamicControlsComponent } from '../dynamic-controls/dynamic-controls.component';
import { Guid } from 'guid-typescript';
import { StaticData } from 'src/app/helpers/static-data';

@Component({
  selector: 'app-dynamic-controls-container',
  templateUrl: './dynamic-controls-container.component.html',
  styleUrls: ['./dynamic-controls-container.component.css']
})
export class DynamicControlsContainerComponent extends DynamicControlsComponent implements OnInit {


  @Input() containerControl: any = null;
  @Input() controls: Array<any> = null;
  public OnInitInternal() {
    
    if (this.subControl) {
      this.name = this.subControl.Name;
      if (this.Pagina && this.subControl.HiddenControl) {
        this.containerControl = this.Pagina.ConfigPage.HiddenControls.find(x => {
          return x.Id == this.subControl.HiddenControl ||
            x.Name == this.subControl.HiddenControl
        });
      }
      else if (this.subControl.CustomComponent) {
        this.containerControl = StaticData.ToolBox.Components.find((x: any) => { return x.Name == this.subControl.CustomComponent.Name });
      }
      else {
        this.containerControl = this.subControl;
      }
    }

    if (this.containerControl && !this.name) {
      this.name = this.containerControl.Name;
    }

    if (!this.name) {
      this.name = "DCC_" + Guid.create().toString();
    }
  }
}
