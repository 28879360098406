import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { Guid } from 'guid-typescript';
import { StaticData } from 'src/app/helpers/static-data';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { Utilities } from 'src/app/helpers/utilities';

declare var $: any;
declare var fabric: any;
// declare var jsPanel: any;
// declare var tinymce: any;

declare var FileSaver: any;
declare var saveAs: any;
declare var XLSX: any;
declare var XLSXStyle: any;
declare var htmlDocx: any;
declare var pdfMake: any;
declare var html2canvas: any;

declare var SUNEDITOR: any;

declare var docx2html: any;

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.css']
})
export class WysiwygEditorComponent implements OnInit, AfterViewInit {

  @ViewChild(FileUploaderComponent, { static: false }) fileUploader: FileUploaderComponent;
  sunEditor: any;
  @Input() viewDownloadLink: boolean = true;
  @Input() name: string = "sunEditor_" + Guid.create().toString();
  downloadLinkName: string;
  configFileUploader: any;
  @Input() modelo: any;
  @Input() timer: any;
  constructor(public utility: Utilities) { }

  ngOnInit() {

    this.downloadLinkName = "download-link-" + this.name;

  }
  ngAfterViewInit() {

    if (this.timer) {
      setTimeout(() => {
        this.CreateSunEditor();
      }, this.timer);
    }
    else
      this.CreateSunEditor();
  }

  ImportDoc(evt) {

    
    let files = evt.target.files;
    docx2html(files[0]).then(function (html) {
      
      let data = html.toString();
      
    })
  }
  // Define custom plugin
  // Common properties
  exportToWordSunEditorPlugin: any = {
    // @Required @Unique
    name: 'exportToWord',
    // @Required
    display: 'command',// ('container' || 'command' || 'submenu' || 'dialog'),

    // @options
    // * You can also set from the button list
    // HTML title attribute (tooltip) - default: plugin's name
    title: 'Exportar a Word',
    // HTML to be append to button (icon)
    // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
    innerHTML: '<i class="far fa-file-word"></i>',// '<i class="fa fa-file" aria-hidden="true"></i>',
    // The class of the button. - default: "se-btn"
    // "se-code-view-enabled": It is not disable when on code view mode.
    // "se-resizing-enabled": It is not disable when on using resizing module.
    buttonClass: '',

    // @Required
    add: function (core, targetElement) {
      


      const context = core.context;
      //const rangeTag = core.util.createElement('div');
      //core.util.addClass(rangeTag, '__se__format__range_custom');

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.exportToWord = {
        targetButton: targetElement
      };
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function (core, targetElement) {


      //this.sunEditor.save();

      let _contentDocument = this.context.element.wysiwyg;//.getContents();
      var regularImages = _contentDocument.querySelectorAll("img");
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      [].forEach.call(regularImages, (imgElement) => {
        // preparing canvas for drawing
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = imgElement.width;
        canvas.height = imgElement.height;

        ctx.drawImage(imgElement, 0, 0);
        // by default toDataURL() produces png image, but you can also export to jpeg
        // checkout function's documentation for more details
        var dataURL = canvas.toDataURL();
        imgElement.setAttribute('src', dataURL);
      })
      canvas.remove();


      var content = '<!DOCTYPE html>' + _contentDocument.outerHTML;
      // var orientation = document.querySelector('.page-orientation input:checked').nodeValue;
      var converted = htmlDocx.asBlob(content, { orientation: 'portrait' });

      saveAs(converted, 'test.docx');

      var link = document.createElement('a');
      link.href = URL.createObjectURL(converted);
      link.download = 'document.docx';
      link.appendChild(
        document.createTextNode('Click aca si su descarga no inicia automaticamente'));
      var downloadArea = document.getElementById('download-area');
      downloadArea.innerHTML = '';
      downloadArea.appendChild(link);

    }


  }

  subMenuExportToWordPlugin: any = {
    wysiwygEditorComponent: this,
    // @Required @Unique
    name: 'subMenuExportToWord',
    // @Required
    display: 'submenu',// ('container' || 'command' || 'submenu' || 'dialog'),

    // @options
    // * You can also set from the button list
    // HTML title attribute (tooltip) - default: plugin's name
    title: 'Exportar a Word',
    // HTML to be append to button (icon)
    // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
    innerHTML: '<i class="far fa-file-word"></i>',// '<i class="fa fa-file" aria-hidden="true"></i>',
    // The class of the button. - default: "se-btn"
    // "se-code-view-enabled": It is not disable when on code view mode.
    // "se-resizing-enabled": It is not disable when on using resizing module.
    buttonClass: '',

    // @Required
    add: function (core, targetElement) {


      const context = core.context;
      context.subMenuExportToWord = {
        targetButton: targetElement,
        textElement: null,
        currentSpan: null
      };

      if (!context.wysiwygEditorComponent)
        context.wysiwygEditorComponent = this.wysiwygEditorComponent;
      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      let listDiv = this.setSubmenu.call(core);

      // Input tag caching
      context.subMenuExportToWord.textElement = listDiv.querySelector('input');

      // You must bind "core" object when registering an event.
      /** add event listeners */
      listDiv.querySelector('.se-btn-primary').addEventListener('click', this.onClick.bind(core));
      //listDiv.querySelector('.se-btn').addEventListener('click', this.onClickRemove.bind(core));

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, listDiv);
    },



    setSubmenu: function () {

      
      const listDiv = this.util.createElement('DIV');
      // @Required
      // A "se-submenu" class is required for the top level element.
      listDiv.className = 'se-submenu se-list-layer';
      listDiv.innerHTML = '' +
        '<div class="se-list-inner">' +
        '<ul class="se-list-basic" style="width: 260px;">' +
        '<li>' +
        '<div class="se-submenu-form-group">' +
        '<input class="se-input-form" type="text" value="documento.docx" placeholder="Nombre Archivo" style="border: 1px solid #CCC;" />' +
        '<button type="button" class="se-btn-primary se-tooltip">' +
        '<strong>Exportar</strong>' +
        '<span class="se-tooltip-inner">' +
        '<span class="se-tooltip-text">Exportar</span>' +
        '</span>' +
        '</button>' +

        '</div>' +
        '</li>' +
        '</ul>' +
        '</div>';

      return listDiv;
    },

    // @Override core
    // Plugins with active methods load immediately when the editor loads.
    // Called each time the selection is moved.
    active: function (element) {
      
      // If no tag matches, the "element" argument is called with a null value.
      if (!element) {
        this.util.removeClass(this.context.subMenuExportToWord.targetButton, 'active');
        this.context.subMenuExportToWord.textElement.value = '';
        this.context.subMenuExportToWord.currentSpan = null;
      } else if (this.util.hasClass(element, 'se-custom-tag')) {
        this.util.addClass(this.context.subMenuExportToWord.targetButton, 'active');
        this.context.subMenuExportToWord.textElement.value = element.textContent;
        this.context.subMenuExportToWord.currentSpan = element;
        return true;
      }

      return false;
    },

    // @Override submenu
    // Called after the submenu has been rendered
    on: function () {
      this.context.subMenuExportToWord.textElement.focus();
    },

    onClickRemove: function () {
      const span = this.context.subMenuExportToWord.currentSpan;
      if (span) {
        this.util.removeItem(span);
        this.context.subMenuExportToWord.currentSpan = null;

        this.submenuOff();
        this.focus();
      }
    },

    onClick: function () {

      const docName = this.context.subMenuExportToWord.textElement.value.trim();
      if (!docName) return;


      this.context.subMenuExportToWord.textElement.value = 'documento.docx';

      //this.sunEditor.save();

      let _contentDocument = this.context.element.wysiwyg;//.getContents();

      // this.context.wysiwygEditorComponent.ProcessImages(_contentDocument);

      // var content = '<!DOCTYPE html>' + _contentDocument.outerHTML;
      // //var orientation = document.querySelector('.page-orientation input:checked').nodeValue;
      // var converted = htmlDocx.asBlob(content, { orientation: 'portrait' });

      // saveAs(converted, docName);
      // this.context.wysiwygEditorComponent.CreateDownloadLink(converted, docName);

      $(_contentDocument).wordExport('Documento');

      this.submenuOff();
    },



  }
  subMenuExportToHtmlPlugin: any = {
    wysiwygEditorComponent: this,
    // @Required @Unique
    name: 'subMenuExportToHtml',
    // @Required
    display: 'submenu',// ('container' || 'command' || 'submenu' || 'dialog'),

    // @options
    // * You can also set from the button list
    // HTML title attribute (tooltip) - default: plugin's name
    title: 'Exportar Html',
    // HTML to be append to button (icon)
    // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
    innerHTML: '<i class="fas fa-file-code"></i>',// '<i class="fa fa-file" aria-hidden="true"></i>',
    // The class of the button. - default: "se-btn"
    // "se-code-view-enabled": It is not disable when on code view mode.
    // "se-resizing-enabled": It is not disable when on using resizing module.
    buttonClass: '',

    // @Required
    add: function (core, targetElement) {


      const context = core.context;
      context.subMenuExportToHtml = {
        targetButton: targetElement,
        textElement: null,
        currentSpan: null
      };

      if (!context.wysiwygEditorComponent)
        context.wysiwygEditorComponent = this.wysiwygEditorComponent;
      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      let listDiv = this.setSubmenu.call(core);

      // Input tag caching
      context.subMenuExportToHtml.textElement = listDiv.querySelector('input');

      // You must bind "core" object when registering an event.
      /** add event listeners */
      listDiv.querySelector('.se-btn-primary').addEventListener('click', this.onClick.bind(core));
      //listDiv.querySelector('.se-btn').addEventListener('click', this.onClickRemove.bind(core));

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, listDiv);
    },



    setSubmenu: function () {

      
      const listDiv = this.util.createElement('DIV');
      // @Required
      // A "se-submenu" class is required for the top level element.
      listDiv.className = 'se-submenu se-list-layer';
      listDiv.innerHTML = '' +
        '<div class="se-list-inner">' +
        '<ul class="se-list-basic" style="width: 260px;">' +
        '<li>' +
        '<div class="se-submenu-form-group">' +
        '<input class="se-input-form" type="text" value="documento.html" placeholder="Nombre Archivo" style="border: 1px solid #CCC;" />' +
        '<button type="button" class="se-btn-primary se-tooltip">' +
        '<strong>Exportar</strong>' +
        '<span class="se-tooltip-inner">' +
        '<span class="se-tooltip-text">Exportar</span>' +
        '</span>' +
        '</button>' +

        '</div>' +
        '</li>' +
        '</ul>' +
        '</div>';

      return listDiv;
    },

    // // @Override core
    // // Plugins with active methods load immediately when the editor loads.
    // // Called each time the selection is moved.
    // active: function (element) {

    //   // If no tag matches, the "element" argument is called with a null value.
    //   if (!element) {
    //     this.util.removeClass(this.context.subMenuExportToHtml.targetButton, 'active');
    //     this.context.subMenuExportToHtml.textElement.value = '';
    //     this.context.subMenuExportToHtml.currentSpan = null;
    //   } else if (this.util.hasClass(element, 'se-custom-tag')) {
    //     this.util.addClass(this.context.subMenuExportToHtml.targetButton, 'active');
    //     this.context.subMenuExportToHtml.textElement.value = element.textContent;
    //     this.context.subMenuExportToHtml.currentSpan = element;
    //     return true;
    //   }

    //   return false;
    // },

    // @Override submenu
    // Called after the submenu has been rendered
    on: function () {
      this.context.subMenuExportToHtml.textElement.focus();
    },


    onClick: function () {

      const docName = this.context.subMenuExportToHtml.textElement.value.trim();
      if (!docName) return;
      this.context.subMenuExportToHtml.textElement.value = 'documento.html';
      //this.sunEditor.save();

      let _contentDocument = this.context.element.wysiwyg;//.getContents();

      this.context.wysiwygEditorComponent.ProcessImages(_contentDocument);

      var link = document.createElement('a');
      let mimeType = 'text/html';
      link.setAttribute('download', docName);
      link.setAttribute('href', 'data:' + mimeType + ';charset=utf-8,' + encodeURIComponent(_contentDocument.outerHTML));
      link.click();



      this.submenuOff();
    },

  }
  subMenuExportToPngPlugin: any = {
    wysiwygEditorComponent: this,
    // @Required @Unique
    name: 'subMenuExportToPng',
    // @Required
    display: 'submenu',// ('container' || 'command' || 'submenu' || 'dialog'),

    // @options
    // * You can also set from the button list
    // HTML title attribute (tooltip) - default: plugin's name
    title: 'Exportar Png',
    // HTML to be append to button (icon)
    // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
    innerHTML: '<i class="far fa-file-image"></i>',// '<i class="fa fa-file" aria-hidden="true"></i>',
    // The class of the button. - default: "se-btn"
    // "se-code-view-enabled": It is not disable when on code view mode.
    // "se-resizing-enabled": It is not disable when on using resizing module.
    buttonClass: '',

    // @Required
    add: function (core, targetElement) {


      const context = core.context;
      context.subMenuExportToPng = {
        targetButton: targetElement,
        textElement: null,
        currentSpan: null
      };

      if (!context.wysiwygEditorComponent)
        context.wysiwygEditorComponent = this.wysiwygEditorComponent;
      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      let listDiv = this.setSubmenu.call(core);

      // Input tag caching
      context.subMenuExportToPng.textElement = listDiv.querySelector('input');

      // You must bind "core" object when registering an event.
      /** add event listeners */
      listDiv.querySelector('.se-btn-primary').addEventListener('click', this.onClick.bind(core));
      //listDiv.querySelector('.se-btn').addEventListener('click', this.onClickRemove.bind(core));

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, listDiv);
    },



    setSubmenu: function () {

      
      const listDiv = this.util.createElement('DIV');
      // @Required
      // A "se-submenu" class is required for the top level element.
      listDiv.className = 'se-submenu se-list-layer';
      listDiv.innerHTML = '' +
        '<div class="se-list-inner">' +
        '<ul class="se-list-basic" style="width: 260px;">' +
        '<li>' +
        '<div class="se-submenu-form-group">' +
        '<input class="se-input-form" type="text" value="documento.png" placeholder="Nombre Archivo" style="border: 1px solid #CCC;" />' +
        '<button type="button" class="se-btn-primary se-tooltip">' +
        '<strong>Exportar</strong>' +
        '<span class="se-tooltip-inner">' +
        '<span class="se-tooltip-text">Exportar</span>' +
        '</span>' +
        '</button>' +

        '</div>' +
        '</li>' +
        '</ul>' +
        '</div>';

      return listDiv;
    },

    onClick: function () {

      const docName = this.context.subMenuExportToPng.textElement.value.trim();
      if (!docName) return;
      this.context.subMenuExportToPng.textElement.value = 'documento.png';
      //this.sunEditor.save();

      let _contentDocument = this.context.element.wysiwyg;
      html2canvas(_contentDocument).then(function (canvas) {

        canvas.toBlob(function (blob) {

          saveAs(blob, docName);
        });
      });

      this.submenuOff();
    },

  }
  subMenuExportToPdfPlugin: any = {
    wysiwygEditorComponent: this,
    // @Required @Unique
    name: 'subMenuExportToPdf',
    // @Required
    display: 'submenu',// ('container' || 'command' || 'submenu' || 'dialog'),

    // @options
    // * You can also set from the button list
    // HTML title attribute (tooltip) - default: plugin's name
    title: 'Exportar Pdf',
    // HTML to be append to button (icon)
    // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
    innerHTML: '<i class="far fa-file-pdf"></i>',// '<i class="fa fa-file" aria-hidden="true"></i>',
    // The class of the button. - defasubMenuExportToPdfult: "se-btn"
    // "se-code-view-enabled": It is not disable when on code view mode.
    // "se-resizing-enabled": It is not disable when on using resizing module.
    buttonClass: '',

    // @Required
    add: function (core, targetElement) {
      const context = core.context;
      context.subMenuExportToPdf = {
        targetButton: targetElement,
        textElement: null,
        currentSpan: null
      };
      if (!context.wysiwygEditorComponent)
        context.wysiwygEditorComponent = this.wysiwygEditorComponent;
      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      let listDiv = this.setSubmenu.call(core);

      // Input tag caching
      context.subMenuExportToPdf.textElement = listDiv.querySelector('input');

      // You must bind "core" object when registering an event.
      /** add event listeners */
      listDiv.querySelector('.se-btn-primary').addEventListener('click', this.onClick.bind(core));
      //listDiv.querySelector('.se-btn').addEventListener('click', this.onClickRemove.bind(core));

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, listDiv);
    },



    setSubmenu: function () {

      
      const listDiv = this.util.createElement('DIV');
      // @Required
      // A "se-submenu" class is required for the top level element.
      listDiv.className = 'se-submenu se-list-layer';
      listDiv.innerHTML = '' +
        '<div class="se-list-inner">' +
        '<ul class="se-list-basic" style="width: 260px;">' +
        '<li>' +
        '<div class="se-submenu-form-group">' +
        '<input class="se-input-form" type="text" value="documento.pdf" placeholder="Nombre Archivo" style="border: 1px solid #CCC;" />' +
        '<button type="button" class="se-btn-primary se-tooltip">' +
        '<strong>Exportar</strong>' +
        '<span class="se-tooltip-inner">' +
        '<span class="se-tooltip-text">Exportar</span>' +
        '</span>' +
        '</button>' +

        '</div>' +
        '</li>' +
        '</ul>' +
        '</div>';

      return listDiv;
    },

    onClick: function () {

      const docName = this.context.subMenuExportToPdf.textElement.value.trim();
      if (!docName) return;
      this.context.subMenuExportToPdf.textElement.value = 'documento.pdf';
      //this.sunEditor.save();

      let _contentDocument = this.context.element.wysiwyg;
      // html2canvas(_contentDocument).then(function (canvas) {

      //   canvas.toBlob(function (blob) {

      //     saveAs(blob, docName);
      //   });
      // });

      html2canvas(_contentDocument, {
        height: 500,
        width: 1000,
        scale: 3,
        backgroundColor: null,
        logging: false,
        onclone: (document) => {
          // document.getElementById('download-chart').style.visibility = 'visible';
        }
      }).then((canvas) => {

        // Get chart data so we can append to the pdf
        const chartData = canvas.toDataURL();
        // Prepare pdf structure
        const docDefinition = {
          content: [],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
              alignment: 'left'
            },
            subsubheader: {
              fontSize: 12,
              italics: true,
              margin: [0, 10, 0, 25],
              alignment: 'left'
            }
          },
          defaultStyle: {
            // alignment: 'justify'
          }
        };

        // Add some content to the pdf
        const title = { text: 'Here is the export of charts to the PDF', style: 'subheader' };
        const description = { text: 'Some description', style: 'subsubheader' };
        // docDefinition.content.push(title);
        // docDefinition.content.push(description);
        // Push image of the chart
        docDefinition.content.push({ image: chartData, width: 500 });



        pdfMake.createPdf(docDefinition).download(docName);

      });

      this.submenuOff();
    },

  }

  public CreateDownloadLink(converted, docName) {

    if (this.viewDownloadLink) {
      var link = document.createElement('a');
      link.href = URL.createObjectURL(converted);
      link.download = docName;
      link.appendChild(
        document.createTextNode('Click aca si su descarga no inicia automaticamente'));
      var downloadArea = document.getElementById(this.downloadLinkName);
      downloadArea.innerHTML = '';
      downloadArea.appendChild(link);
    }
  }
  public ProcessImages(_contentDocument: any) {

    var regularImages = _contentDocument.querySelectorAll("img");
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    [].forEach.call(regularImages, (imgElement) => {
      // preparing canvas for drawing
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = imgElement.width;
      canvas.height = imgElement.height;

      ctx.drawImage(imgElement, 0, 0);
      // by default toDataURL() produces png image, but you can also export to jpeg
      // checkout function's documentation for more details
      var dataURL = canvas.toDataURL();
      imgElement.setAttribute('src', dataURL);
    })
    canvas.remove();

  }
  CreateSunEditor() {
    
    this.sunEditor = SUNEDITOR.create(this.name, {
      display: 'block',
      "katex": "window.katex",
      width: '100%',
      height: '30%',
      popupDisplay: 'full',
      plugins: [this.subMenuExportToWordPlugin,
      this.subMenuExportToHtmlPlugin,
      this.subMenuExportToPngPlugin,
      this.subMenuExportToPdfPlugin],
      buttonList: [
        ["undo", "redo"],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'align', 'strike', 'subscript', 'superscript',
          'horizontalRule', 'list',
          "italic",
          "fontColor",
          "hiliteColor",
          "textStyle",
          "removeFormat"],
        ['table', 'link', 'image',
          "video",
          "audio",],
        ["outdent", "indent", "align", 'horizontalRule', 'list', 'lineHeight'],
        ["math",
          "imageGallery",
          "fullScreen",
          "showBlocks",
          "codeView",
          "preview",
          "print",
          "save",
          "template"],
        ['subMenuExportToWord', 'subMenuExportToHtml', 'subMenuExportToPng', 'subMenuExportToPdf']
      ],
      placeholder: ''
    });

    this.sunEditor.onClick = function (e) {
      
      console.log('onClick', e)
    }
    if (this.modelo) {
      setTimeout(() => {
        this.sunEditor.setContents(this.modelo);
      }, 500);

    }
    this.sunEditor.onChange = (contents, core) => { this.modelo = contents; }
  }

  public GetConfigFileUpload() {
    let formatsAllowed = "*";


    this.configFileUploader = {
      multiple: false,
      formatsAllowed: ".docx,.doc,.html,.htm",
      maxSize: 2000,
      uploadAPI: {
        url: StaticData.UrlServer + "/api/RConfiguracionGeneral/ConvertFile",
        headers: {
          modelo: ""
        }
      },
      theme: "dragNDrop",
      hideProgressBar: false,
      hideResetBtn: false,
      //hideSelectBtn: false,
      replaceTexts: {
        selectFileBtn: 'Seleccione el documento',
        resetBtn: 'Limpiar',
        uploadBtn: 'Cargar Documento',
        dragNDropBox: 'Drag & Drop',
        attachPinBtn: 'Adjunte Documento...',
        afterUploadMsg_success: 'Carga Correcta !',
        afterUploadMsg_error: 'Error al cargar !'
      }
    };

    return this.configFileUploader;
  }
  public RespuestaCargaDocumento(event) {

    if (event.response) {
      
      let obj: any = JSON.parse(event.response);
      if (obj.Ok) {
        this.sunEditor.setContents(obj.Data);
      }
      else {

      }
    }
  }
  public EventoProgresoCargaDocumento(event) {


  }

  public EventoCargarDocumento(event) {

    this.GuardarArchivo(null);
  }
  public EventoSeleccionArchivo(event) {
    let name: string = this.fileUploader.selectedFiles[0].name.toLowerCase();

    if (!name.includes(".docx") && !name.includes(".doc"))
      this.SetFiletToEditor();

  }
  public GuardarArchivo(event) {
    


    if (!this.fileUploader.isSelectedFiles()) {
      this.utility.logger.LogWarningText('No Hay Archivos para cargar');
      return;
    }
    let name: string = this.fileUploader.selectedFiles[0].name.toLowerCase();

    if (name.includes(".docx") || name.includes(".doc")) {


      let token: string = StaticData.Usuario.Token;

      let model: any = {

        Token: token,
        MethodNameUI: 'GuardarArchivo',
        DataAdd: {}
      }
      this.fileUploader.uploadFiles(false, model);


    }
    else {

      this.SetFiletToEditor();
    }
  }

  public SetFiletToEditor() {
    let file = this.fileUploader.selectedFiles[0];
    let reader = new FileReader();
    const self = this;
    reader.onload = (event: any) => {

      this.sunEditor.setContents(event.target.result.toString());

    };
    reader.readAsText(file);
  }
}
