import { Component, OnInit, Input, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { KpiEstadoSolicitudBaseComponent } from '../kpi-estado-solicitud-base/kpi-estado-solicitud-base.component';

@Component({
  selector: 'app-kpi-estado-solicitud',
  templateUrl: './kpi-estado-solicitud.component.html',
  styleUrls: ['./kpi-estado-solicitud.component.scss']
})
export class KpiEstadoSolicitudComponent extends KpiEstadoSolicitudBaseComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy  {

  @Input() items;
  @Input() collapseId;

  ngOnInit() {
  }

  public changeIcon(event){
    (<any>$(event.target)).children("i").toggleClass('icon-caret-up');
  }

  public CargarDatos(modelo?: any) {

    try {

      if(this.modelo){
        return
      }
      if (!this.QueryConfig)
        return;

   

      if (this.notLoad)
        return;
    

      var obj = this.utility.GetApiModel('CargarDatos', '');
      obj.QueryConfig = this.QueryConfig;

      this.baseService.Get(obj, null,
        {
          componentName: this.componentName,
          processMessage: this.processMessage,
          method: 'OnInit'
        }, this.url, true, true).then(result => {

          this.modelo = result.Data[0];

         // this.MappingModelo();


        }).catch(error => {
          this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: 'CargarDatos' });
        })



    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'CargarDatos' });
    }
  }


}
