import { Component, OnInit, Input, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Utilities } from 'src/app/helpers/utilities';
import { UtilitiesColumns } from 'src/app/helpers/utilities-columns';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { ComponentsContainer } from 'src/app/models/components-container';

@Component({
  selector: 'app-form-json',
  templateUrl: './form-json.component.html',
  styleUrls: ['./form-json.component.css']
})
export class FormJsonComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('frm', { static: false }) public frm: NgForm;

  _modelo: any = null;
  public componentsContainer: ComponentsContainer;
  public childComponents: Array<any> = [];
  @Input() form: FormGroup = null;
  @Input() Pagina: any;
  @Input() set modelo(value: any) {

    this._modelo = value;
    
    if(value && this.config){
      if (!this._modelo[this.config.ModelContainer][this.config.Name])
      this._modelo[this.config.ModelContainer][this.config.Name] = {};
    }
  }
  get modelo(): any {
    return this._modelo;
  }
  @Input() config: any;
  @Input() componentName: string = "";

  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityColumns: UtilitiesColumns,
    public helperActions: HelperActions,
    public helperImages: HelperImages) {


  }
  Validar() {

    if (!this.modelo[this.config.ModelContainer][this.config.Name])
      this.modelo[this.config.ModelContainer][this.config.Name] = {};
  }
  ngOnInit() {

    
    this.Validar();


  }

  ngAfterViewInit() {
    
    // this.form = this.frm.form;

  }
  ngAfterViewChecked() {
    //this.form = this.frm.form;
  }
}
