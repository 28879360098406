import { Component, OnInit } from '@angular/core';
import { GeneralFormBase } from 'src/app/models/general-form-base';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-rtema-solicitud',
  templateUrl: './rtema-solicitud.component.html',
  styleUrls: ['./rtema-solicitud.component.css']
})
export class RtemaSolicitudComponent extends GeneralFormComponent implements OnInit {

  GridViewUsuario: any;

  public Limpiar(callback: (data: any) => void = null, action?: any) {
    debugger;
    if(action == undefined){
      this.GridViewUsuario = this.componentsContainer.Tables.find((val:any)=> val.name == "Usuario");
      this.GridViewUsuario.ReCargarGrilla();
      this.modelo.modelo.UsuarioNombre = '';
		this.ClearModel();
    }
    else if(action == 'ClearModel'){
      this.modelo.modelo.UsuarioNombre = '';
    }
		this.utility.SetFieldsToModel(this.modelo.modelo, null);
		if (callback) callback(this.modelo);
		this.SetModelToControls();
		this.parentClearCall.next({ modelo: this.modelo, action: action });
		this.SetProcesando(false);
  }

  public OnActionEventHandler(event) {
    //debugger;    
    if(event.Action){
      if(event.Action == "ClearModel"){
        if(event.Table){
        if(event.Table == "DataTables_RTemaTipoSolicitud"){
        //debugger;
        super.Limpiar();
        super.ClearModel();
       }
      }
    }
  }
  super.OnActionEventHandler(event);    
  }  
}
