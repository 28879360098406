import {
  Component, ViewChildren, ViewChild, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter,
  OnDestroy,
  QueryList, forwardRef, Inject, DebugElement
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  Paginas, ControllerMethods, CrudActions, ValidateUserAndPagesTypes, DataTableColumnTypes, DataTableAjaxOrderTypes,
  DataTableActions,
  TipoParaPermisos
} from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service';
import { ModalNotifyComunicationService } from '../../../services/modal-notify-comunication.service';
import { List, Enumerable } from '../../../../assets/linqts/compilado/index';
import { StaticData } from '../../../helpers/static-data';
import { ComboBoxManager } from '../../../bussines/combo-box-manager';
import { ConfigWindow } from '../../../models/config-window';
import { UtilitiesColumns } from '../../../helpers/utilities-columns';
import { ComponentsContainer } from 'src/app/models/components-container';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { BrowserStack } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-data-table-versiones-crud-trd',
  templateUrl: './data-table-versiones-crud-trd.component.html',
  styleUrls: ['./data-table-versiones-crud-trd.component.css']
})
export class DataTableVersionesCrudTrdComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {


  @Input() public isModelContainer: boolean = false;
  @Input() public parentCallRefresh: Subject<any>;
  @Input() componentsContainer: ComponentsContainer;

  @Output() onInitialized = new EventEmitter<any>();
  @Output() onActionEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() cargarGrilla: boolean = false;
  @Input() pkName: string = null;
  @Input() guardarValido: boolean = false;
  @Input() IsEditModelo: boolean = false;
  @Input() modeloTRD: any;
  @Input() modelo: any;
  @Input() modeloClone: any = null;
  @Input() url: string = 'TExpediente';
  @Input() items: Array<any> = [];
  @Input() configs: any = {};
  @Input() Hidden: boolean = false;
  @Input() Disabled: boolean = false;
  @Input() public name: string = 'DataTableVersionesCrudTrdComponent';
  @Input() public processMessage: string = 'Procesando Versiones TRD........';

  @Input() public componentName: 'DataTableVersionesCrudTrdComponent'




  constructor(public baseService: BaseServiceService,
    public utility: Utilities,
    public utilityColumns: UtilitiesColumns,
    public helperActions: HelperActions,
    public helperImages: HelperImages) {

    //super();


  }

  ngOnInit() {

    this.OnInit();

    if (this.onInitialized.observers.length) {
      setTimeout(() => {
        this.onInitialized.emit(this);
      }, 1000);
    }

  }
  ngAfterViewInit() {


  }
  ngAfterViewChecked() {

  }

  public Limpiar() {
    this.modeloTRD = null;
    this.items = [];
    this.IsEditModelo = false;

  }
  FireAction(actionType: string, data?: any) {

    this.onActionEventEmitter.emit({
      modelo: this.modelo,
      type: this.name,
      name: this.name,
      actionType: actionType,
      data: data,
      componentName: this.componentName
    });
  }

  public OnInit() {

    try {


      // if (this.parentCallRefresh)
      //   this.parentCallRefresh.subscribe(event => {
      //     //console.error('parentCallRefresh ' + this.name)
      //     this.BuscarVersionTRD(false);
      //   });

      if (this.onInitialized.observers.length) {
        setTimeout(() => {

          this.onInitialized.emit(this);
        }, 1000);
      }


    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.name, method: 'onInit' });
    }
  }

  public BuscarVersionTRD(esEdicion: boolean) {
    //debugger;

    var obj = this.utility.GetApiModel('BuscarVersionTRD', this.componentName);

    obj.QueryConfig.Command = 'BusquedaVersionTRD';
    obj.QueryConfig.IsSp = true;

    obj.QueryConfig.Entity = (esEdicion) ? {
      IdAreaEmpresa: this.modelo.modelo.IdAreaEmpresa,
      IdSerie: this.modelo.modelo.IdSerie,
      IdSubSerie: this.modelo.modelo.IdSubSerie,
      IdVersionTablaRetencionDocumental: this.modelo.modelo.IdVersionTablaRetencionDocumental
    } : {
      IdAreaEmpresa: this.modelo.modeloCascada.IdAreaEmpresa,
      IdSerie: this.modelo.modeloCascada.IdSerie,
      IdSubSerie: this.modelo.modeloCascada.IdSubSerie,
      IdVersionTablaRetencionDocumental: null // MySQL

    }; // this.modelo.modeloCascada;

    //obj.QueryConfig.Entity = (esEdicion) ? { IdVersionTablaRetencionDocumental: this.modelo.modelo.IdVersionTablaRetencionDocumental } : this.modelo.modeloCascada;
    // obj.QueryConfig.Entity = (esEdicion) ? { IdTablaRetencionDocumental: this.modelo.modelo.IdTablaRetencionDocumental } : this.modelo.modeloCascada;
    // this.baseService.showLoadNotifyType =true;
    this.baseService.Get(obj, null,
      {
        componentName: this.componentName,
        method: 'BuscarVersionTRD',
        processMessage: 'Buscando tabla TRD...'
      }, this.url)
      .then(data => {

        if (esEdicion) {
          this.modeloTRD = data.Data[0]
          // this.modelo.modeloCascada = {
          //   IdAreaEmpresa: this.modeloTRD.IdAreaEmpresa,
          //   IdSerie: this.modeloTRD.IdSerie,
          //   IdSubSerie: this.modeloTRD.IdSubSerie
          // };
          this.modelo.modeloCascada.IdAreaEmpresa = this.modeloTRD.IdAreaEmpresa;
          this.modelo.modeloCascada.IdSerie = this.modeloTRD.IdSerie;
          this.modelo.modeloCascada.IdSubSerie = this.modeloTRD.IdSubSerie;

          this.modelo.modeloCascada.IdDepartamento = this.modelo.modelo.IdDepartamento;
          this.modelo.modeloCascada.IdCiudad = this.modelo.modelo.IdCiudad;
          //debugger;
          if(this.configs.ConfigIdCiudad){
          this.configs.ConfigIdCiudad.Params =  { IdDepartamento: this.modelo.modeloCascada.IdDepartamento };
          this.configs.ConfigIdCiudad.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true, false);
          }
          



          //llamar SetModelToControls, llama modelos al control.
          //this.configs.ConfigIdSubSerie.Entity = { IdSerie: this.modeloTRD.IdSerie };
          //this.configs.ConfigIdSubSerie.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
          this.items = data.Data;//this.BuscarVersionTRD(false);


        }
        else
          this.items = data.Data;
        this.FireAction('LoadData', this.modeloTRD);

      }).catch(err => {
        this.utility.logger.LogError(err, obj, {
          componentName: this.componentName,
          method: 'BuscarVersionTRD',
          processMessage: 'Buscando tabla TRD...'
        }, this.url)
      });
  }

  public SeleccionarTRD(item: any, configuracion: string) {
    // var removeMetadata = false;
    // if(this.IsEditModelo || this.Disabled)
    // return

    // if (this.modelo.modelo.IdTablaRetencionDocumental === id) {
    //   this.modelo.modelo.IdTablaRetencionDocumental = null;
    //   this.modelo.modelo.IdVersionTablaRetencionDocumental = null;
    //   removeMetadata = true;
    // }
    // else{
    //   this.modelo.modelo.IdTablaRetencionDocumental = id;


    // }

    var removeMetadata = false;
    if (this.IsEditModelo || this.Disabled)
      return

    if (this.modelo.modelo.IdTablaRetencionDocumental === item.IdTablaRetencionDocumental) {
      this.modelo.modelo.IdTablaRetencionDocumental = null;
      this.modelo.modelo.IdVersionTablaRetencionDocumental = null;
      if (StaticData.GeneralConfig.ConfigValidacionExpediente.HeredarTipoSeguridadDeTRD){
        this.modelo.modelo.IdTipoSeguridadDocumento = null;
      }
      removeMetadata = true;
    }
    else {
      this.modelo.modelo.IdTablaRetencionDocumental = item.IdTablaRetencionDocumental;
      this.modelo.modelo.IdVersionTablaRetencionDocumental = item.IdVersionTablaRetencionDocumental;
      if (StaticData.GeneralConfig.ConfigValidacionExpediente.HeredarTipoSeguridadDeTRD) {
        this.modelo.modelo.IdTipoSeguridadDocumento = item.TipoSeguridad;
      }
    }


    var permisos = this.ExtractPropertyFromJSON(configuracion, "Permisos")
    if (permisos != null) {

      if (this.modelo.modelo.Configuracion == permisos) {
        this.modelo.modelo.Configuracion = "";
        this.modelo.modelo.RolesPermitidos = "";
        this.modelo.modelo.AreasEmpresaPermitidas = "";
        this.modelo.modelo.GruposPermitidos = "";
        this.modelo.modelo.UsuariosPermitidos = "";
      }
      else {
        this.modelo.modelo.Configuracion = permisos;
        let PermisosRoles  = new List<any>([]);
        let PermisosAreas = new List<any>([]);
        let PermisosGrupos  = new List<any>([]);
        let PermisosUsuarios =  new List<any>([]);
        let config: Array<any> = [] = JSON.parse(this.modelo.modelo.Configuracion);
        config.forEach(configPermisos => {
          switch (configPermisos.Tipo) {
            case TipoParaPermisos.Role:
              PermisosRoles.Add('code' + configPermisos.Id)
              break
            case TipoParaPermisos.AreaEmpresa:
              PermisosAreas.Add('code' + configPermisos.Id)
              break
            case TipoParaPermisos.Grupo:
              PermisosGrupos.Add('code' + configPermisos.Id)
              break
            case TipoParaPermisos.Usuario:
              PermisosUsuarios.Add('code' + configPermisos.Id)
              break
          }

        });
        this.modelo.modelo.RolesPermitidos = PermisosRoles.ToArray().join('|');
        this.modelo.modelo.AreasEmpresaPermitidas = PermisosAreas.ToArray().join('|');
        this.modelo.modelo.GruposPermitidos = PermisosGrupos.ToArray().join('|');
        this.modelo.modelo.UsuariosPermitidos = PermisosUsuarios.ToArray().join('|');
      }
    }




    this.ValidarGuardarValido();

    this.FireAction('SeleccionarTRD', {
      Configuracion: configuracion,
      IdTablaRetencionDocumental: item.IdTablaRetencionDocumental,
      IdVersionTablaRetencionDocumental: item.IdVersionTablaRetencionDocumental,
      IdTipoSeguridadDocumento: item.TipoSeguridad,
      RemoveMetadata: removeMetadata
    });

  }
  public get EsGuardarValido(): boolean {
    return this.ValidarGuardarValido();
  }
  public ValidarGuardarValido(): boolean {
    this.guardarValido = (this.modelo.modelo.IdVersionTablaRetencionDocumental > 0);
    //this.guardarValido = (this.modelo.modelo.IdTablaRetencionDocumental > 0);
    return this.guardarValido;
  }
  public SeleccionarItem(item: any, index: number, id: any, column?: any) {
    const that = this;
    try {




      this.ExcecuteFuntion(ControllerMethods.SelectedItem, false, { index: index, id: id, column: column });

      // this.helperActions.ExecuteActionsControl(column, false, item, this.modelo,
      //   null, this, null, this.onActionEventEmitter, this.componentsContainer);

      this.ExcecuteFuntion(ControllerMethods.SelectedItem, true, { findItem: item, index: index, id: id, column: column });

    }
    catch (error) {
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'SeleccionarItem' });
      this.utility.VerModalDanger().then(data => { }).catch(data => { });
    }
  }

  preSeleccionItem: (modelo: any) => void;
  postSeleccionItem: (modelo: any) => void;
  onSeleccionItem: (modelo: any) => void;
  onLoadedGridItem: (modelo?: any) => void;



  @Output() preSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() postSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSeleccionItemEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() loadedGridEmitter: EventEmitter<any> = new EventEmitter<any>();


  public ExcecuteFuntion(controllerMethod: ControllerMethods, isPost: boolean, data: any = null): any {

    var result = null;
    switch (controllerMethod) {

      case ControllerMethods.SelectedItem:

        if (!isPost && this.preSeleccionItem)
          this.preSeleccionItem(data);
        else if (isPost == true && this.postSeleccionItem)
          this.postSeleccionItem(data);

        if (this.onSeleccionItem)
          this.onSeleccionItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


        if (!isPost && this.preSeleccionItemEmitter.observers.length > 0)
          this.preSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
        else if (isPost == true && this.postSeleccionItemEmitter.observers.length > 0)
          this.postSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });

        if (this.onSeleccionItemEmitter.observers.length > 0)
          this.onSeleccionItemEmitter.emit({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });
        break;
      case ControllerMethods.LoadedGrid:

        if (this.onLoadedGridItem)
          this.onLoadedGridItem({ isPost: isPost, data: { data: data, controllerMethod: controllerMethod } });


        if (this.loadedGridEmitter.observers.length > 0)
          this.loadedGridEmitter.emit({ controlName: this.name, isPost: isPost, data: data, controllerMethod: controllerMethod });
        break;

    }
    return result;
  }


  ngOnDestroy() {

    this.utility.Unsubscribe(this.parentCallRefresh);

  }

  public ExtractPropertyFromJSON(json: string, property: string, returnObjectIfNull?: any) {

    if (json != null) {
      var config = JSON.parse(json);
      if (config[property] === undefined) {
        //Modificado por Marlon Granda 29/10/2020
        //Adicion de Validacion
        if (!config.filter)
          return null;
        //Fin de Modificacion Marlon Granda 29/10/2020
        else {
          let propr = config.filter(function (itm) { return itm.key == property });
          if (propr[0].value.length > 0) {
            return JSON.stringify(propr[0].value);
          }
          else
            return null;
        }
      }
      else {
        if (config[property].length > 0)
          return JSON.stringify(config[property]);
        else
          return null;
      }
    }
    else {
      return null;
    }
  }

}
