//Clase de la aplicacion BextDocUI
//Autor:Marlon Granda-Fecha:25/21/2019 - Mail:mar_gran2003@yahoo.com.ar
//Empresa:Marlon Granda

import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Paginas, ControllerMethods, DataTableAjaxOrderTypes, TypesUseForm, ControlActions, EntityMetadataTypes, TipoParaPermisos } from '../../../models/general.enum';
import { Utilities } from '../../../helpers/utilities';
import { ModalNotifyComponent, ModalNotifyTypes } from '../../../components/modal-notify/modal-notify.component';
import { BaseServiceService } from '../../../services/base-service.service'
import { BaseCrud } from '../../../bussines/base-crud';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { StaticData } from 'src/app/helpers/static-data';
import { Enumerable, List } from "../../../../assets/linqts/compilado/index";

@Component({
  selector: 'app-rtipo-solicitud',
  templateUrl: './rtipo-solicitud.component.html',
  styleUrls: ['./rtipo-solicitud.component.scss']
})
export class RTipoSolicitudComponent extends GeneralFormComponent implements OnInit, AfterViewInit {

  onEditParentEmitter: EventEmitter<any> = new EventEmitter<any>();

  crearPaginas = false;
  nombreLleno = false;
  tiposSolicitudes: Array<any> = [1
    , 2
    , 3
    , 4
    , 5
    , 6
    , 7
    , 8
    , 9
    , 10
    , 13
    , 14
    , 15
    , 16
    , 21
    , 22];


  get VerAdminForm(): any {

    let tipo = null;
    if (this.modelo && this.modelo.modelo) {
      tipo = this.tiposSolicitudes.find((x => { return x == this.modelo.modelo.IdTipoSolicitud; }));
    }
    return (this.crearPaginas && !tipo);
  }

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules,
    public helperActions: HelperActions) {

    super(baseService, utility, helperImages, helperRules, helperActions);
  }

  // ngAfterViewInit() {
  // 	
  // 	this.userModel.Nueva();
  // }

  public OnInitEnd() {
    

    // this.postClearEmitter.subscribe(data => {
    //   this.nombreLleno = false;
    // });

 


    // this.postSaveEmitter.subscribe(data => {
    // 	
    // 	let obj = this.utility.GetApiModel("ConsultaRolesGruposUsuariosAreasEmpresaTipo", null);
    //   obj.QueryConfig = {
    //     Command: "ConsultaRolesGruposUsuariosAreasEmpresaTipo",
    //     IsSp: true,
    //     Entity: {
    //       IdEmpresa: 1
    //     },
    //   };
    //   let info = {
    //     componentName: this.componentName,
    //     controlName: "ConsultaRolesGruposUsuariosAreasEmpresaTipo",
    //     processMessage: "Cargando ConsultaIndicativo......",
    //   };

    //   this.baseService
    //     .Get(obj, null, info, this.componentName, false, false)
    //     .then((data) => {
    //       
    // StaticData.RolesUsuariosGrupos = new List<any>(data.Data);            
    //     })
    //     .catch((err) => {
    //       this.utility.logger.LogError(err, obj, {
    //         componentName: this.componentName,
    //         method: "Consulta",
    //       });
    //     });				

    // });


  }

  public OnDataTableEditHandler(event) {
    
    super.OnDataTableEditHandler(event);
    this.nombreLleno = true;
    this.onEditParentEmitter.emit(event);
  }
  public OnUpdateHandler(value, configuracion) {
    debugger;
    //this.form.controls['Configuracion'].setValue(value.Configuracion);
    this.form.controls['ConfiguracionPaginaSolicitud'].setValue(value.ConfiguracionPaginaSolicitud);
    this.form.controls['ConfiguracionPaginaEstadoSolicitud'].setValue(value.ConfiguracionPaginaEstadoSolicitud);
  }

   _Guardar(form: FormGroup, configuracion) {
    debugger;
    this.modelo.modelo.UsuariosPermitidos = ''
    const ids: number[] = [];
    let PermisosUsuarios =  new List<any>([]);
  if(this.modelo.modelo.Configuracion != null || this.modelo.modelo.Configuracion !="[]"){
    try {
      const jsonArray = JSON.parse(this.modelo.modelo.Configuracion);
  
      if (Array.isArray(jsonArray)) {
        for (const jsonObject of jsonArray) {
          if (jsonObject && jsonObject.Id !== undefined) {
            const id = jsonObject.Id;
            if (typeof id === 'number') {
              PermisosUsuarios.Add('code' + id)
            }
          }
        }
        this.modelo.modelo.UsuariosPermitidos = PermisosUsuarios.ToArray().join('|');
        
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  
    }
    super._Guardar(form, configuracion)
  }

  public OnActionEventHandler(event) {

    super.OnActionEventHandler(event);

  }
}


