import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { ControlActions,GeneralPageControlTypes, ControllerMethods, EstadosSolicitud, FiltroProcesoFirma, FormActionsThroughEmitters, TipoObjetoParaPermisos, TiposConsecutivo } from "src/app/models/general.enum";

import { AnimationMetadataType } from "@angular/animations";
import { BaseServiceService } from "src/app/services/base-service.service";
import { ConfigWindow } from "src/app/models/config-window";
import { DataTableComponent } from "src/app/components/controls/data-table/data-table.component";
import { FileUploaderComponent } from 'src/app/components/controls/file-uploader/file-uploader.component';
import { FormGroup } from "@angular/forms";
import { GeneralFormComponent } from "src/app/components/controls/general-form/general-form.component";
import { Guid } from "guid-typescript";
import { HelperActions } from "src/app/helpers/helper-actions";
import { HelperImages } from "src/app/helpers/helper-images";
import { HelperRules } from "src/app/helpers/helper-rules";
import { SolicitudCommonComponent } from "../../common/solicitud-common.component";
import { StaticData } from "src/app/helpers/static-data";
import { Utilities } from "src/app/helpers/utilities";
import { validateContact } from "../../../contacto/contacto.component";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { DataTableDirective } from "angular-datatables";

declare let fabric: any;

@Component({
    selector: "app-solicitud-documentos",
    templateUrl: "./solicitud-documentos.component.html",
    styleUrls: ["./solicitud-documentos.component.css"]
})
export class SolicitudDocumentosComponent extends SolicitudCommonComponent implements OnInit {


    IdCausalRechazo: any;
    IdCuaderno: any;
    crudButtonGestionVoBo: any;
    Observaciones: any;
    Tipofirma: any;
    TipofirmaEnvio: any;
    configPin: any;
    FiltroFirma: any;
    TipoFiltro: any
    configArchivosAdjuntos: any;
    GridViewAprobacionDocumento: any;
    GridViewVistaBuenoDocumento: any;
    DivInfoFirma: any;



    constructor(public baseService: BaseServiceService, public utility: Utilities,
        public helperImages: HelperImages,
        public helperRules: HelperRules,
        public helperActions: HelperActions) {
        super(baseService, utility, helperImages, helperRules, helperActions);

    }

    public OnInitEnd() {

        this.IdCausalRechazo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCausalRechazo"; });
        this.IdCuaderno = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "IdCuaderno"; });
        this.crudButtonGestionVoBo = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Guardar_VoBo"; });
        this.Observaciones = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Observaciones"; });
        this.Tipofirma = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Tipofirma"; });
        this.configPin = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "Pin"; });
        this.FiltroFirma = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "FiltroProceso"; });
        this.DivInfoFirma = this.utility.GetAllFormControls(this.Pagina.ConfigPage.Controls).find(x => { return x.Name == "InformacionFirma"; });
        this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Pagina);
    }


    public OnSelectedComboBox(item: any, controlName: string, config: any) {

        try {
            if (this.Pagina.Pagina != "SolicitudDocumentos") {
                if (controlName === "Tipofirma") {
                    if (this.modelo.modeloVirtual.Tipofirma === 123)
                        //this.modelo.modeloVirtual.IdEstadoSolicitudProceso = EstadosSolicitud.PendienteFirmaElectronica;
                        this.modelo.modeloVirtual.IdEstadoSolicitudProceso = EstadosSolicitud.Aprobada;
                    else
                        this.modelo.modeloVirtual.IdEstadoSolicitudProceso = EstadosSolicitud.Aprobada;
                    this.TipofirmaEnvio = this.modelo.modeloVirtual.Tipofirma;
                    let validacion = controlName === "Tipofirma" ? this.modelo.modeloVirtual.Tipofirma === 46 ? "true" : "false" : "false";
                    //Lina Figueredo
                    //Se ajuta la validacion de validacionDocumentosFirmar con el fin que habilite los controles para tipo de firma Manuscrita. se quita de la condicion this.modelo.modeloVirtual.Tipofirma === 47.
                    //let validacionDocumentosFirmar = controlName === "Tipofirma" ? this.modelo.modeloVirtual.Tipofirma === 47 || this.modelo.modeloVirtual.Tipofirma === 123 || this.modelo.modeloVirtual.Tipofirma === 124 ? "false" : "true" : "true";
                    let validacionDocumentosFirmar = controlName === "Tipofirma" ? this.modelo.modeloVirtual.Tipofirma === 123 || this.modelo.modeloVirtual.Tipofirma === 124 ? "false" : "true" : "true";
                    // let validacionFirmaElectronica = controlName === "Tipofirma" ? this.modelo.modeloVirtual.Tipofirma === 123 ? "true" : "false" : "false";
                    this.configPin.Hidden = validacion == "true" ? false : true;
                    this.SetControlsValueDocument("Pin", "required", validacion)
                    this.FiltroFirma.Hidden = validacionDocumentosFirmar == "false" ? true : false;
                    // this.DivInfoFirma.Hidden = validacionFirmaElectronica == "true" ? false : true;
                    this.SetControlsValueDocument("FiltroProceso", "required", validacionDocumentosFirmar)
                    this.modelo.modeloVirtual.Pin = "";
                    this.SetModelToControls();
                }
                if (controlName === "FiltroProceso") {
                    if (this.modelo.modeloVirtual.FiltroProceso == FiltroProcesoFirma.TodosDocumentos)
                        this.TipoFiltro = "TodosDocumentos"
                    else if (this.modelo.modeloVirtual.FiltroProceso == FiltroProcesoFirma.Principal)
                        this.TipoFiltro = "Principal"
                    else if (this.modelo.modeloVirtual.FiltroProceso == FiltroProcesoFirma.Adjuntos)
                        this.TipoFiltro = "Adjuntos"
                }
                let hidenDiv = true
                if ((controlName === "Tipofirma" || controlName === "FiltroProceso") && this.modelo.modeloVirtual.Tipofirma === 47 && this.modelo.modeloVirtual.FiltroProceso)
                    hidenDiv = false
                if (this.DivInfoFirma)
                    this.DivInfoFirma.Hidden = hidenDiv
            }
            else {
                if (controlName === "IdCarpeta") {
                    if (this.IdCuaderno.Activo) {
                        if (this.modelo.expediente && this.modelo.modeloVirtual.IdCarpeta) {
                            this.modelo.modeloVirtual.IdCuaderno = null;
                            this.configs.ConfigIdCuaderno.Params = { IdExpediente: this.modelo.expediente.IdExpediente, IdCarpeta: this.modelo.modeloVirtual.IdCarpeta };
                            this.configs.ConfigIdCuaderno.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
                        }
                    }
                }
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, null, { componentName: this.componentName, method: "OnSelectedComboBox", controlName: controlName });
            this.utility.VerModalDanger().then(data => { });
        }

        super.OnSelectedComboBox(item, controlName, config);
    }

    public OnDataTableEditHandler(event) {
        if (event.name == "GrillaAsignarExpediente") {
            super.OnDataTableEditHandler(event);
            if (this.IdCuaderno.Activo) {
                if (this.modelo.expediente) {
                    if (this.modelo.modeloVirtual.IdCarpeta) {
                        this.modelo.modeloVirtual.IdCuaderno = null;
                        this.configs.ConfigIdCuaderno.Params = { IdExpediente: this.modelo.expediente.IdExpediente, IdCarpeta: this.modelo.modeloVirtual.IdCarpeta };
                        this.configs.ConfigIdCuaderno.Context.CargarItemsComboBoxRemoveNotLoad(null, true, null, true);
                    }
                }
            }

        }
        else {
            super.OnDataTableEditHandler(event);
        }

        /*this.configs.ConfigIdTipoSolicitud.Context.SetItemsComboBox([], true);*/



    }

    public OnActionEventHandler(event) {
        switch (event.ControlType) {
            case GeneralPageControlTypes.Button: {
              switch (event.Name) {
                case SolicitudCommonComponent.Controls.BtnVincular:{
                    this.modelo.modelo.IdEstadoSolicitud = 12
                    var OperacionSolicitud = this.modelo.modelo.DataAdd
                    if (this.modelo.selectedItems.length > 0){
                      super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdExpediente',this.modelo.expediente.IdExpediente);
                      super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdTipoDocumental', this.modelo.modelo.IdTipoDocumental);
                      super.cambiarValorDeLlave(this.modelo.selectedItems, 'IdEstadoSolicitud', this.modelo.modelo.IdEstadoSolicitud);
                      this.modelo.selectedItems.forEach((objeto) => {
                        {
                          this.modelo.modelo = objeto
                          this.modelo.modelo.Nombre = this.modelo.modelo.NombreDocumento
                          this.modelo.modelo.Activo = false
                          this.modelo.modelo.DataAdd = OperacionSolicitud
                          this.modelo.modelo.IdCarpeta = this.modelo.modeloVirtual.IdCarpeta
                          this.modelo.modelo.IdCuaderno = this.modelo.modeloVirtual.IdCuaderno

                            let obj: any = null;
                            let _isEdit = this.IsEdit;
                            // const that = this;
                            let processMessage = (this.modelo.modelo.PkValue == '0' || this.modelo.modelo.PkValue == '') ? 'Creando Registro de ' + this.Pagina.Titulo : 'Actualizando registro de ' + this.Pagina.Titulo;
                            obj = this.utility.GetApiModel('Guardar', this.componentName, null, null, this.modelo.modelo);
                            this.baseService.InsertUpdate(obj, null, {
                            componentName: this.componentName + 'Controller',
                            method: 'Guardar', processMessage: processMessage
                            },
                            false, this.componentName, true, true)

                        }
                      })
                    this.ReCargarGrilla()
                    }
                  break;
                }
                case SolicitudCommonComponent.Controls.BtnLimpiar: {
                    this.modelo.modeloVirtual.Tipofirma = null;
                    this.modelo.modeloVirtual.IdEstadoSolicitudProceso = null;
                    this.modelo.modeloVirtual.FiltroProceso = null;
                    this.DivInfoFirma.Hidden = true;
                    this.configPin.Hidden = true;
                    
                    this.SetModelToControls();
                    break;
                }
              }
              break;
            }
            case GeneralPageControlTypes.CommandButton: {
                  if(event.Option.Name === SolicitudCommonComponent.CommandBarActions.IncluirEnExpedienteMasivo){
                    this.BtnVincular.Hidden = false
                    this.BtnGuardarVincular.HideSave = true
                    super.SetModelToControls();
                  }
                  if(event.Option.Name === "EnviarExpediente"){
                    this.BtnVincular.Hidden = true
                    this.BtnGuardarVincular.HideSave = false
                    super.SetModelToControls();
                    break;
                  }

                break;
            }
        }
        if (event.Action && event.Action == "ClearModel" && event.Table && event.Table != "GridView_SolicitudDocumentos") {
            this.modelo.modeloVirtual.CodigoExpediente = null;
            this.modelo.modelo.IdTipoDocumental = null;
            this.modelo.modeloVirtual.NombreExpediente = null;
            if (event.Table == "GrillaVoBoUsuario" || event.Table == "GrillaAprobacionUsuario")
                this.modelo.modeloVirtual.IdUsuarioSeleccionado = null;
            this.SetModelToControls();
            return;
        }
        if (this.Pagina.Pagina != "SolicitudDocumentos") {
            if (event.ControlType && event.ControlType == "CommandButton") {
                if (event.Option && event.Option.Name) {
                    let validacion = event.Option.Name == "RechazarProceso" ? "true" : "false"
                    this.SetControlsValueDocument("IdCausalRechazo", "required", validacion)
                    this.IdCausalRechazo.Hidden = validacion == "true" ? false : true;
                    this.modelo.modelo.IdCausalRechazo = validacion == "false" ? null : this.modelo.modelo.IdCausalRechazo;
                    let validacionApruebaFirma = event.Option.Name == "AprobarDocumento" ? "true" : "false"
                    this.SetControlsValueDocument("Tipofirma", "required", validacionApruebaFirma)
                    this.Tipofirma.Hidden = validacionApruebaFirma == "true" ? false : true;
                    this.Observaciones.Hidden = validacionApruebaFirma == "true" ? true : false;
                    this.FiltroFirma.Hidden = validacionApruebaFirma == "true" ? false : true;
                    this.SetControlsValueDocument("FiltroProceso", "required", validacionApruebaFirma)
                    if (this.modelo.modeloVirtual.Tipofirma != 46)
                        validacionApruebaFirma = "false"
                    this.configPin.Hidden = validacionApruebaFirma == "true" ? false : true;
                    this.SetControlsValueDocument("Pin", "required", validacionApruebaFirma)
                    if (this.DivInfoFirma) {
                        if (this.modelo.modeloVirtual.Tipofirma === 47 && this.modelo.modeloVirtual.FiltroProceso && validacionApruebaFirma != "false")
                            this.DivInfoFirma.Hidden = false
                        else
                            this.DivInfoFirma.Hidden = true
                    }
                }

                switch (event.Option.Name) {
                    case SolicitudCommonComponent.CommandBarActions.AprobarVoBo:
                        this.modelo.modeloVirtual.IdEstadoSolicitudProceso =
                            EstadosSolicitud.VoBo;
                        break;
                    case SolicitudCommonComponent.CommandBarActions.AprobarDocumento:
                        this.modelo.modeloVirtual.IdEstadoSolicitudProceso =
                            EstadosSolicitud.Aprobada;
                        break;
                    case SolicitudCommonComponent.CommandBarActions.RechazarProceso:
                        this.modelo.modeloVirtual.IdEstadoSolicitudProceso =
                            EstadosSolicitud.Rechazada;
                        break;
                }
                this.SetModelToControls();
            }
            else if (event.ControlType && event.ControlType == "Button") {
                if (event.Name == "VistaPrevia") {
                    let obj = this.utility.GetApiModel('ViewPrewview', null)
                    obj = {
                        IdSolicitudDocumento: this.modelo.modelo.IdSolicitudDocumento,
                        DataAdd: {
                            NumeroPagina: this.modelo.modeloVirtual.PaginaFirma,
                            PosicionX: this.modelo.modeloVirtual.PosicionX,
                            PosicionY: this.modelo.modeloVirtual.PosicionY,
                            AnchoImagen: this.modelo.modeloVirtual.AnchoImagen,
                            AltoImagen: this.modelo.modeloVirtual.AltoImagen,
                            FiltroProceso: this.TipoFiltro
                        }
                    };

                    let info = {
                        componentName: this.componentName, controlName: 'CerrarExpediente',
                        processMessage: 'Generando la Vista previa del documento'
                    };
                    return this.baseService.Ejecutar(obj, null, info, false, this.componentName + '/ViewPrewview', false, false).then(data => {
                        if (data.Ok != true) {
                            this.utility.VerModalError(data.ValidationUI);
                        }
                        else {
                            this.helperActions.OpenDocumentViewer(data.Data, "Preview");
                        }
                    }).catch(err => {
                        this.utility.VerModalError(err);
                    });
                }
            }
        }
        else {
            if (event.ControlType && event.ControlType == "CommandButton") {
                this.componentsContainer.FileBox.notPrincipal = false;
                if (this.IsEdit)
                    this.componentsContainer.FileBox.notPrincipal = true;

                this.SetControlsValueDocument("IdUsuarioSeleccionado", "required", "false")
                this.modelo.modeloVirtual.IdUsuarioSeleccionado = null;
                let validacionTipodocumental = event.Option.Name === "EnviarExpediente" || event.Option.Name=== "IncluirEnExpedienteMasivo" ? "true" : "false";
                this.SetControlsValueDocument("IdTipoDocumental", "required", validacionTipodocumental)
                this.IdCuaderno.Value = null;
                debugger
                if (this.IdCuaderno.Hidden != true){
                    this.SetControlsValueDocument("IdCuaderno", "required", validacionTipodocumental)
                    this.SetControlsValueDocument("IdCarpeta", "required", validacionTipodocumental)
                }
                else {
                    this.SetControlsValueDocument("IdCuaderno", "null", validacionTipodocumental)
                    this.SetControlsValueDocument("IdCarpeta", "null", validacionTipodocumental)
                }
                let validacionSeleccionUsuarios = event.Option.Name === "VistoBueno" ? "true" : event.Option.Name === "Aprobacion" ? "true" : "false";
                this.SetControlsValueDocument("IdUsuarioSeleccionado", "required", validacionSeleccionUsuarios)
                let validacionFileUploader = event.Option.Name === "Nuevo" ? true : false;
                // this.configArchivosAdjuntos.Required = validacionFileUploader;
                // this.FileBoxes[0].config.Required = validacionFileUploader;
                this.configArchivosAdjuntos.RaiseForceValidateSaveEvent(!validacionFileUploader, validacionFileUploader);
                var InputValue = ""

                if (event.Option && event.Option.Name && event.Option.Name == "Eliminar") {
                    this.utility.VerModalConfirmacionGeneral("Eliminar Documento", "El documento sera eliminado, ¿esta seguro que desea continuar?").then(modelo => {

                        if (modelo.dialogResult == true) {
                            this.modelo.modelo.IdEstadoSolicitud = 34
                            this.modelo.modelo.Activo = false;
                            this._Guardar(this.form)
                        }
                    });
                }
                if (event.Option && event.Option.Name && event.Option.Name == "EnviarExpediente") {
                    this.modelo.modeloVirtual.CodigoExpediente = null;
                    this.modelo.modelo.IdTipoDocumental = null;
                    this.modelo.modeloVirtual.NombreExpediente = null;
                    this.SetModelToControls();
                    var TiposDocs = '{"TiposDocumentales":[]}';
                    const trd = JSON.parse(TiposDocs);
                    const tiposDoc = trd["TiposDocumentales"];
                    this.configs.ConfigIdTipoDocumental.Context.SetItemsComboBox(tiposDoc);
                }
                if (event.Option && event.Option.Name && event.Option.Name == "Aprobacion") {

                    var GridName = "GrillaAprobacionUsuario";
                    var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
                    this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

                    /*this.GridViewAprobacionDocumento = this.componentsContainer.Tables.find(x => { return x.name == "GrillaAprobacionUsuario"; });
                    this.utility.GetDataTabledtInstance(this.GridViewAprobacionDocumento.dtElement, "GrillaAprobacionUsuario", this.componentName).then(dtInstance => {
                        dtInstance.search(InputValue).draw();
                    }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })*/
                }
                if (event.Option && event.Option.Name && event.Option.Name == "VistoBueno") {

                    var GridName = "GrillaVoBoUsuario";
                    var GridViewClearSearch = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
                    this.ClearSearchDataTable(GridViewClearSearch, GridName, this.componentName);

                    /*this.GridViewVistaBuenoDocumento = this.componentsContainer.Tables.find(x => { return x.name == "GrillaVoBoUsuario"; });
                    this.utility.GetDataTabledtInstance(this.GridViewVistaBuenoDocumento.dtElement, "GrillaVoBoUsuario", this.componentName).then(dtInstance => {
                        dtInstance.search(InputValue).draw();
                    }).catch(error => { this.utility.logger.LogError(error, { value: InputValue, type: 'search' }) })*/
                }
            }
            this.SetModelToControls();
        }

        if (event.ControlType && event.ControlType == "CommandButton")
            if (event.Option && event.Option.Name && event.Option.Name == "EnviarExpediente") {
                this.componentsContainer.ReloadTable("GrillaAsignarExpediente", {
                    IdEmpresa: -1,
                    IdAreaEmpresaUsuario: -1
                });
                this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Expediente);
            }
            else
                this.OnCreatePermissionsConfig(TipoObjetoParaPermisos.Pagina);

        super.OnActionEventHandler(event);

    }

    public OnCreatePermissionsConfig(TipoObjeto: any) {

        this.permissionsConfig = {
            TipoObjetoParaPermiso: TipoObjeto,
            Pagina: this.Pagina, clear: true, expediente: null, CreatedRowConfig: {}
        };
        this.RaiseFormEvent('OnCreatePermissionsConfig', true);
    }

    _Guardar(form: FormGroup, configButtons?: any) {
        this.modelo.modelo.Nombre = this.modelo.modelo.NombreDocumento
        var IdUsuarioGestionActual = this.modelo.modelo.IdUsuarioGestionActual;
        let obj: any = null;
        try {
            if (this.Pagina.Valida != true || this.Pagina.Guardar != true)
                return;
            if (form.invalid)
                return;


            const _isEdit = this.IsEdit;
            // const that = this;
            const processMessage = (this.modelo.modelo.PkValue == "0" || this.modelo.modelo.PkValue == "") ? "Creando Registro de " + this.Pagina.Titulo : "Actualizando registro de " + this.Pagina.Titulo;

            if (configButtons && configButtons.Actions) {

                this.helperActions.ExecuteActionsControl(configButtons,
                    false, null, this.modelo, this.permissionsConfig,
                    this, null, new EventEmitter<any>(), this.componentsContainer,
                    null, this.modelo.modeloMetadata, { importUrl: this.importUrl });
            }

            this.ExcecuteFuntion(ControllerMethods.Save, false);


            if (_isEdit) {
                this.FireFormEvent({ Name: "OnPreUpdate", modelo: this.modelo });
                this.modelo.modelo.IdUsuarioResponsable =
                    this.modelo.modelo.IdUsuarioResponsable ||
                    StaticData.Usuario.IdUsuario;
            }
            else {
                this.FireFormEvent({ Name: "OnPreInsert", modelo: this.modelo });
                this.modelo.modelo.IdUsuarioResponsable = StaticData.Usuario.IdUsuario;
            }
            if (this.Pagina.Pagina == "ProcesarSolicitudDocumento" && this.modelo.modeloVirtual.Tipofirma && this.modelo.modeloVirtual.Tipofirma === 47)
                this.modelo.modelo["DataAdd"] = {
                    TipoFirma: "Manuscrita",
                    FiltroProceso: this.TipoFiltro,
                    NumeroPagina: this.modelo.modeloVirtual.PaginaFirma,
                    PosicionX: this.modelo.modeloVirtual.PosicionX,
                    PosicionY: this.modelo.modeloVirtual.PosicionY,
                    AnchoImagen: this.modelo.modeloVirtual.AnchoImagen,
                    AltoImagen: this.modelo.modeloVirtual.AltoImagen,
                };
            else if (this.Pagina.Pagina == "ProcesarSolicitudDocumento" && this.modelo.modeloVirtual.Tipofirma && this.modelo.modeloVirtual.Tipofirma === 46) {
                this.modelo.modelo["DataAdd"] = {
                    Pin: this.modelo.modeloVirtual.Pin,
                    IdTipoIdentificacion: 1,
                    FiltroProceso: this.TipoFiltro,
                    TipoFirma: "Digital"
                };
            }
            else if (this.Pagina.Pagina == "ProcesarSolicitudDocumento" && this.modelo.modeloVirtual.Tipofirma && this.modelo.modeloVirtual.Tipofirma === 123) {
                this.modelo.modelo["DataAdd"] = {
                    Pin: this.modelo.modeloVirtual.Pin,
                    IdTipoIdentificacion: 1,
                    FiltroProceso: this.TipoFiltro,
                    TipoFirma: "Electronica"
                };
                //this.modelo.modelo.IdUsuarioGestionActual = IdUsuarioGestionActual;
            }


            if (this.Pagina.Pagina == "SolicitudDocumentos") {
                if (this.modelo.modelo.DataAdd) {
                    this.modelo.modelo.DataAdd["IdCarpeta"] = this.modelo.modeloVirtual.IdCarpeta;
                    this.modelo.modelo.DataAdd["IdCuaderno"] = this.IdCuaderno.Activo ? this.modelo.modeloVirtual.IdCuaderno : null;
                }
            }

            obj = this.utility.GetApiModel("Guardar", this.componentName, null, null, this.modelo.modelo);

            let isGeneralSave = true;
            if (this.componentsContainer.FileBox && this.componentsContainer.FileBox.isSelectedFiles()) {

                isGeneralSave = false;
                let externalFiles: any;
                if (this.componentsContainer.FileBoxWorkFlow != null) {
                    externalFiles = this.componentsContainer.FileBoxWorkFlow.GetForExternalFilesModel();
                }
                var principal = this.componentsContainer.FileBox.modeloArchivo.find(x => { return x.EsAdjunto == false; });
                var ConfigSolicitudDocumentos = StaticData.GeneralConfig.ConfigUbicaciones.find(x => { return x.IdTipoSolicitud == TiposConsecutivo.SolicitudDocumentos; });
                if (_isEdit && principal && ConfigSolicitudDocumentos.ConfigValidacionDocumento.PermitirActualizarDocumento) {

                    // if (this.modelo.modelo.Extension && this.documentoComponente.principalFile) {
                    let titleWindow: string = "Desea Actualizar el archivo principal del documento?";
                    let modeloInterno: any = { VersionModelData: { ObservacionesModificacion: '' } };
                    let config: ConfigWindow = new ConfigWindow();
                    config.classWindow = 'warning';
                    config.titleWindow = titleWindow;
                    config.returnInstance = false;
                    config.modal = true;
                    config.width = 750;
                    config.height = 400;
                    config.extra = 'Crear Nueva Version';
                    config.ok = 'Actualizar Archivo';
                    config.extraValue = 'extra';
                    config.Okextra = 'Cargar Documentos como adjuntos';
                    config.viewOkExtra = true;
                    // config.viewOkExtra = false;
                    config.OkextraValue = "okExtra";
                    let windowModel: any = {
                        modelo: modeloInterno.VersionModelData,
                        config: this.utility.GetConfigObservacionesVersion()
                    };

                    this.utility.OpenWindow('ObservacionesModificacionVersionComponent',
                        config, windowModel).then(dataResult => {
                            dataResult.onClosePromise.then(dataResultOnClose => {
                                let limpiar = true;
                                if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == true) {
                                    if (dataResultOnClose.modelo.ObservacionesModificacion) {
                                        obj.DataAdd = { FileModelType: 'Documents', Actualizar: true }
                                        obj.Observaciones = dataResultOnClose.modelo.ObservacionesModificacion;
                                        this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
                                    }
                                    else
                                        this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });
                                }
                                else if (dataResultOnClose.dialogResult && dataResultOnClose.dialogResult == 'okExtra') {
                                    if (dataResultOnClose.modelo.ObservacionesModificacion) {
                                        obj.DataAdd = { FileModelType: 'Documents', Actualizar: true, Adjuntos: true }
                                        obj.VersionModelData = dataResultOnClose.modelo;
                                        this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
                                    }
                                    else
                                        this.utility.VerModalWarning({ titulo: 'Alerta Observaciones', descripcion: 'El Campo Observaciones es requerido..' }).then(result => { }).catch(err => { this.utility.logger.LogError(err, {}) });

                                }
                            });

                        })
                }
                else {
                    this.componentsContainer.FileBox.uploadFiles(false, obj, externalFiles);
                    let validacionFileUploader = true;
                    this.configArchivosAdjuntos.RaiseForceValidateSaveEvent(!validacionFileUploader, validacionFileUploader);

                }
            }


            // ////////Meter validacion antes de este
            if (isGeneralSave) {

                this.baseService.InsertUpdate(obj, form, {
                    componentName: this.componentName + "Controller",
                    method: "Guardar", processMessage: processMessage
                },
                    false, this.componentName, false, true).then(data => {
                        //this.modelo.modelo = data.Data != null ? data.Data : this.modelo.modelo;

                        this.FinGuardar(data.Data);

                        if (_isEdit)
                            this.FireFormEvent({ Name: "OnPostUpdate", modelo: this.modelo, data: data });
                        else
                            this.FireFormEvent({ Name: "OnPostInsert", modelo: this.modelo, data: data });

                        // this.utility.VerModalOk(`Proceso realizado correctamente para el Radicado de salida con código: ${data.Data.Codigo}`).then(() => true);
                        // this.solicitudEntrada = null;
                        this.OnInitEnd();
                    }).catch(error => { this.SetProcesando(false); });
            }
        }
        catch (error) {
            this.utility.logger.LogError(error, obj, { componentName: this.componentName, method: "_Guardar" });
            this.utility.VerModalDanger().then(data => { });
            this.SetProcesando(false);
        }
    }
}
