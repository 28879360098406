import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlActions, FormActionsThroughEmitters, GeneralPageControlTypes } from 'src/app/models/general.enum';

import { BaseServiceService } from '../../../services/base-service.service'
import { FormGroup } from '@angular/forms';
import { GeneralFormComponent } from '../../controls/general-form/general-form.component';
import { Guid } from 'guid-typescript';
import { HelperActions } from 'src/app/helpers/helper-actions';
import { HelperImages } from 'src/app/helpers/helper-images';
import { HelperRules } from 'src/app/helpers/helper-rules';
import { SolicitudCommonComponent } from '../../bussines/solicitudes/common/solicitud-common.component';
import { Utilities } from '../../../helpers/utilities';
import { DynamicControlsMetadataComponent } from '../../controls/dynamic-controls-metadata/dynamic-controls-metadata.component';
import { StaticData } from 'src/app/helpers/static-data';

@Component({
  selector: 'app-transferencia-expediente',
  templateUrl: './transferencia-expediente.component.html',
  styleUrls: ['./transferencia-expediente.component.scss']
})

export class TransferenciaExpedienteComponent extends GeneralFormComponent implements OnInit, AfterViewInit {
  isSearch: boolean = null;
  filtroGeneral = "";
  DynamicMetadata: DynamicControlsMetadataComponent;

  constructor(public baseService: BaseServiceService, public utility: Utilities,
    public helperImages: HelperImages,
    public helperRules: HelperRules, public helperActions: HelperActions) {
    super(baseService, utility, helperImages, helperRules, helperActions);
  }

  requeridosControlesAplazar: boolean = false;

  public OnActionEventHandler(event) {    
    if (event.Action) {
      if (event.Action == "ClearModel") {
        if (event.Table) {
          if (event.Table == "DataTable_TransferenciasAprobadas") {            
            super.Limpiar();
            super.ClearModel();
            super.OnActionEventHandler(event);
          }
        }
      }      
    }  

    switch (event.ControlType) {
      case GeneralPageControlTypes.CommandButton: {
        if (event.Option.Type == 'New') {

          this.utility.VerModalConfirmacionGeneral("Generar Transferencia", "Se generará la transferencia de los expedientes seleccionados, ¿Desea continuar?").then(modelo => {

            if (modelo.dialogResult == true) {
              this._Guardar(this.form);
              super.OnActionEventHandler(event)
            }
            else {
              return
            }
          });

        }
        if (event.Option.Type == 'Custom' || event.Option.Type == 'Filter') {
          /**
           * Inicio Edward Morales 02/03/2023
           * Valida opción commandbar para redireccionar o continuar proceso anterior
           * IdExpediente en modelo para descarga de FUID
           */
          if (event.Option.Name == 'UbicacionFisicaExpediente') {
            this.NavegarPaginaHija(event.Option.Name);
          } else {
            this.modelo.modeloVirtual.ObservacionesAplazamiento = null;
            this.modelo.modeloVirtual.FechaAplazamiento = null;
            this.requeridosControlesAplazar = true;
            if (event.Option.Name == 'DescargarFuid') {                  
              switch(this.Pagina.Pagina){
                case 'SolicitarTransferencias':{
                  this.modelo.modelo.Expedientes = this.GetExpediente(this.modelo.TTransferenciaExpediente);             
                  break;
                }
                case 'TransferenciasAprobadas':{
                  this.modelo.modelo.Expedientes = this.modelo.expediente.IdExpediente;             
                  break;
                }
                case 'TransferenciasAH':{
                  this.modelo.modelo.Expedientes = this.modelo.expediente.IdExpediente;             
                  break;
                }
              }                        
            }
            if (event.Option.Name == 'Zip') {  
              switch(this.Pagina.Pagina){                
                case 'TransferenciasAH':{
                  this.GuardarAuditoriaExpediente(event.Option.Name, this.modelo.expediente.IdExpediente);            
                  break;
                }
              } 
            }
            super.OnActionEventHandler(event);
          }
        }        
        break;
      }              
      case GeneralPageControlTypes.Button:
        {
          switch (event.Name) {
            case 'Btn_Aplazar_save':
              {
                this.ProcesoAplazar();
                break;
              }
            case 'btnBuscar': {
              this.Buscar(event.Name);
              break;
            }
            case 'BtnLimpiar': {              
              this.controls.ForEach(control => {
                if (control.control && control.control.ModelContainer == "modelo")
                  this.modelo.modelo[control.control.Name] = null;
              }
              );
              this.modelo.modelo.FiltroGeneral = "";
              this.filtroGeneral = "";
              this.ClearControlsMetadatos();
              this.SetModelToControls();
              this.Buscar(event.Name);
              break;
            }
              
          }
          break;
        }
    }


    const requireds = {
      ControlDetails: {
        MappingData: [],
        MappingFilter: []
      },
      ControlType: "Action",
      ControlsForHidden: [],
      ControlsToUpdate: ["ObservacionesAplazamiento", "FechaAplazamiento"],
      Guid: Guid.create().toString(),
      IsPost: false,
      IsParentModel: false,
      MappingValues: [],
      MappingComponents: [],
      Property: "required",
      //PropertyValue: "false",
      PropertyValue: this.requeridosControlesAplazar ? "true" : "false",
      ResultType: "Url",
      SendQueryString: false,
      TimerAction: 0,
      Type: ControlActions[ControlActions.SetControlProperty]
    };
    const context = this;
    this.helperActions.SetControlsValue(requireds, context);
    super.SetModelToControls();

    if (event.Table == "DataTables_TExpedienteParaTransferencia")
      if (event.Action === FormActionsThroughEmitters.ClearModel) {

        var GridName = "DataTables_TExpedienteParaTransferencia";
        var GridViewUsuarioCopia = this.componentsContainer.Tables.find(x => { return x.name == GridName; });
        GridViewUsuarioCopia.selectedItems = this.modelo.TTransferenciaExpediente;

        this.SetModelToControls();
        return;
      }




  }

  /**
   *Inicio Edward Morales 22032023
   *Registrar auditoría de Expediente según opción de commandBar
   * @param option Opción del CommandBar
   * @param id IdExpediente para Auditoría
   */
    public GuardarAuditoriaExpediente(option: any, id: any) {

    var description: string;
    var spName: string;

    switch (option) {
      case "Indice":
        description = 'Descarga de Indice en el expediente con Id: ' + id;
        spName = "CrearAuditoriaDescargaIndiceExpediente";
        break;
      case "Zip":
        description = 'Descarga de ZIP en el expediente con Id: ' + id;
        spName = "CrearAuditoriaDescargaZipExpediente";
        break;
      case "BtnAuditoria":
        description = 'Exportar auditoria en el expediente con Id: ' + id;
        spName = "CrearAuditoriaExportarAuditoria";
        break;
    }

    //this.componentName = ""
    let obj = this.utility.GetApiModel(spName, null)
    obj.QueryConfig.Command = spName; // 'CrearAuditoriaDescargaIndiceExpediente';
    obj.QueryConfig.IsSp = true;
    obj.QueryConfig.Entity = {
      IdEmpresa: -1,
      IdRegistro: id,
      UsuarioCreacion: StaticData.Usuario.Email,
      IdUsuarioCreacion: StaticData.Usuario.IdUsuario,
      Maquina: -1,
      Descripcion: description // 'Descarga de Indice en el expediente con Id: ' + this.modelo.modelo.IdExpediente
    };
    let info = {
      componentName: "TExpediente", controlName: spName,
      processMessage: 'Auditoria Expediente......'
    };
    this.baseService.Get(obj, null, info, "TExpediente", false, false).then(data => {
    }).catch(err => { }).finally();

  }

  public GetExpediente(expedientes: Array<any>) {
    /**
     * Inicio Edward Morales 10-03-2023
     * Capturar varios IdExpediente para procesar en SP & Descargar FUID 
     */
    var result = "";
    for (var i = 0; i < expedientes.length; i++) {      
      result = result + expedientes[i].IdExpediente + ( ((i+1) != expedientes.length) ? "," : "");
    }
    return result;
  }

  public ProcesoAplazar() {

    let obj = this.utility.GetApiModel('AplazaTransferencia', null)
    obj = {
      IdExpediente: this.modelo.TTransferenciaExpediente[0].IdExpediente,
      DataAdd:
      {
        ModeloTransferencias: this.modelo.TTransferenciaExpediente,
        FechaAplazamiento: this.modelo.modeloVirtual.FechaAplazamiento,
        ObservacionAplazamiento: this.modelo.modeloVirtual.ObservacionesAplazamiento
      }
    };

    let info = {
      componentName: 'TExpediente', controlName: 'AplazaTransferencia',
      processMessage: 'Realizando el aplazamiento de la transferencia de los expediente'
    };
    this.baseService.Ejecutar(obj, null, info, false, 'TExpediente' + '/AplazaTransferencia', false, false).then(data => {

      if (!data.Data)
        this.utility.VerModalError("La transferencia no se pudo aplazar correctamente");
      else {
        this.utility.VerModalOk("La transferencia se aplazo correctamente");
        this.modelo.modeloVirtual.ObservacionesAplazamiento = null;
        this.modelo.modeloVirtual.FechaAplazamiento = null;
        this.componentsContainer.Tables[0].ReCargarGrilla();
        this.SetModelToControls();
      }
    }).catch(err => {
      this.utility.VerModalError(err);

    });
    //super.OnActionEventHandler(event)
  }
  /**
  * Inicio Edward Morales 02/03/2023
  * Redirección a página configurada en commandBar
  */
  public NavegarPaginaHija(url) {
    switch (this.Pagina.Pagina) {
      case 'SolicitarTransferencias': {
        this.utility.ParentDetailsItem = this.modelo.TTransferenciaExpediente[0];
        break;
      }
      case 'TransferenciasAprobadas': {
        this.utility.ParentDetailsItem = this.modelo.expediente;
        break;
      }
      case 'TransferenciasAH': {
        this.utility.ParentDetailsItem = this.modelo.expediente;
        break;
      }
    }
    this.utility.ParentDetailsItem.Pagina = this.Pagina.Pagina;
    this.utility.router.navigate([url]);
  }

  /**
   * Inicio Edward Morales21032023 
   * Búsqueda avanzada Expedientes Filtro
   */
  IsNullableOp(opName) {
    return (opName == 'IsNull' || opName == 'IsNotNull' || opName == 'IsUndefined' || opName == 'IsNotUndefined'
      || opName == 'IsEmpty' || opName == 'IsNotEmpty');

  }

  GetOp(opName) {
    if (opName == 'Equal')
      return "=";
    else if (opName == 'NotEqual')
      return "<>";
    else if (opName == 'GreaterThan')
      return ">";
    else if (opName == 'LessThan')
      return "<"
    else if (opName == 'GreaterOrEqual')
      return ">=";
    else if (opName == 'LessOrEqual')
      return "<="
  }
  GetOpTexto(opName) {
    if (opName == 'Equal')
      return "ES IGUAL";
    else if (opName == 'NotEqual')
      return "NO ES IGUAL";
    else if (opName == 'GreaterThan')
      return "MAYOR QUE";
    else if (opName == 'LessThan')
      return "MENOR QUE"
    else if (opName == 'GreaterOrEqual')
      return "MAYOR IGUAL QUE";
    else if (opName == 'LessOrEqual')
      return "MENOR IGUAL QUE"
  }

  public GetFiltro(control) {    
    let filtro = "";
    let filtroGeneral = "";
    //" TRegistroVersionSubSeriePaginaMetadatosDetalle.TipoDato  = '" + element.Name + "' AND ";


    var datatemp = '';
    var dateArr = [];
    if (control.Op == "In" || control.Op == "Range") {

      switch (control.DataType) {
        case 'String':
          control.Value.forEach(_value => {
            dateArr.push("'" + _value + "'");
          });

          datatemp = dateArr.join(',');
          filtro += control.SearcheableFieldName + " IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + datatemp + ")";
          break
        case 'DateTime':
          control.Value.forEach(_value => {
            dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
          });

          datatemp = dateArr.join(',');
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + " IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + datatemp + ")";
          //str += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.htmlEntities(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          break;

        default:
          filtro += control.SearcheableFieldName + " IN  (" + control.Value + ")";
          filtroGeneral += control.Label + " <strong> EN LISTA  </strong>  (" + control.Value + ")";
          break


      }

    }
    else if (control.Op == "NotIn") {

      switch (control.DataType) {
        case 'String':
          control.Value.forEach(_value => {
            dateArr.push("'" + _value + "'");
          });

          datatemp = dateArr.join(',');
          filtro += control.SearcheableFieldName + " NOT IN   (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + datatemp + ")";
          break
        case 'DateTime':
          control.Value.forEach(_value => {
            dateArr.push("CONVERT(DATETIME,'" + _value + "',103) ");
          });

          datatemp = dateArr.join(',');
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + " NOT IN  (" + datatemp + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + datatemp + ")";
          //str += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.htmlEntities(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          break;

        default:
          filtro += control.SearcheableFieldName + "NOT IN  (" + control.Value + ")";
          filtroGeneral += control.Label + " <strong> NO EN LISTA  </strong>  (" + control.Value + ")";
          break


      }
      //str += control.SearcheableFieldName + " NOT IN (" + control.Value + ")";
    }
    else if (control.Op == 'ContainsText') {
      filtro += " Contains(" + control.SearcheableFieldName + " , '" + control.Value + "')";
      filtroGeneral += control.Label + " <strong> CONTIENE TEXTO </strong>  (" + control.Value + ")";
    }
    else if (control.Name == "IdTesauro" && control.ControlType == "MultiComboBox") {
      control.Value.forEach(_value => {
        filtro += control.SearcheableFieldName + " LIKE  '%" + _value + "%'" + " OR ";
        filtroGeneral += control.Label + " <strong> CONTIENE </strong>  (" + _value + ")" + " O ";
      });
      filtro = filtro.substr(0, filtro.length - 4);
      filtroGeneral = filtroGeneral.substr(0, filtroGeneral.length - 3);

    }
    else if (control.Op == "Contains" || control.ControlType == "TextArea") {
      filtro += control.SearcheableFieldName + " LIKE  '%" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> CONTIENE </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotContains") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '%" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> NO CONTIENE  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "StartsWith") {
      filtro += control.SearcheableFieldName + " LIKE  '" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> INICIA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotStartsWith") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '" + control.Value + "%'";
      filtroGeneral += control.Label + " <strong> NO INICIA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "EndsWith") {
      filtro += control.SearcheableFieldName + " LIKE  '%" + control.Value + "'";
      filtroGeneral += control.Label + " <strong> FINALIZA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "NotEndsWith") {
      filtro += control.SearcheableFieldName + " NOT LIKE  '%" + control.Value + "'";
      filtroGeneral += control.Label + " <strong> NO FINALIZA CON  </strong>  (" + control.Value + ")";
    }
    else if (control.Op == "IsNull" || control.Op == "IsUndefined") {
      filtro += control.SearcheableFieldName + " IS NULL  ";
      filtroGeneral += control.Label + " <strong> ES NULO  </strong>";
    }
    else if (control.Op == "IsNotNull" || control.Op == "IsNotUndefined") {
      filtro += control.SearcheableFieldName + " NOT IS NULL  ";
      filtroGeneral += control.Label + " <strong> NO ES NULO  </strong>";
    }
    else if (control.Op == "IsEmpty") {
      filtro += control.SearcheableFieldName + " =  '' ";
      filtroGeneral += control.SearcheableFieldName + " <strong> ES VACIO  </strong>";
    }
    else if (control.Op == "IsNotEmpty") {
      filtro += control.SearcheableFieldName + " <>  '' ";
      filtroGeneral += control.Label + " <strong> NO ES VACIO  </strong>";
    }
    ///    str += control.SearcheableFieldName.Name + " LIKE '%" + group.rules[i].data + "%'";
    else {
      switch (control.DataType) {
        case 'String':
          filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " '" + control.Value + "' ";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          break
        case 'DateTime':
          filtro += "CONVERT(DATETIME," + control.SearcheableFieldName + ",103) " + this.GetOp(control.Op) + "  CONVERT(DATETIME,'" + control.Value + "',103)";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          break;
        case 'Boolean':
          let boolValue = (control.Value == "true" || control.Value == true) ? 1 : 0;
          filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " " + boolValue + " ";
          filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          //filter += " CONVERT(DATETIME,'" + value + "',103)";
          /*     if (value == "true")
                this.filter += " " + control.Name + " = 1";
              else
                this.filter += " " + control.Name + " = 0"; */
          break;
        default:
          if (Array.isArray(control.Value) && control.Op === "Equal") {
            filtroGeneral +=
              control.Label +
              " ( <strong> " +
              this.GetOpTexto(control.Op) +
              "  </strong>  ";

            filtro += " ( "
            control.Value.forEach((element, index) => {
              filtro +=
                control.SearcheableFieldName +
                " " +
                this.GetOp(control.Op) +
                " " +
                element +
                " ";
              filtroGeneral += `'${element}'`
              if (index + 1 != control.Value.length) {
                filtro += " OR "
                filtroGeneral +=
                  " O " +
                  " <strong> " +
                  this.GetOpTexto(control.Op) +
                  "  </strong>  ";
              }
            });
            filtro += " ) ";
            filtroGeneral += ")"
          }
          else {
            filtro += control.SearcheableFieldName + " " + this.GetOp(control.Op) + " " + control.Value + " ";
            filtroGeneral += control.Label + " <strong> " + this.GetOpTexto(control.Op) + "  </strong>  '" + control.Value + "' ";
          }
          break


      }
    }

    return { filtroGeneral: filtroGeneral, filtro: filtro };
  }

  public ClearControlsMetadatos() {

    this.RemoveMetadataControls(null);
    if (this.DynamicMetadata)
      this.DynamicMetadata.Controls = [];

    this.Pagina.ConfigPage.Metadata = null;
  }

  public OnLoadGridHandler(event) {
    this.FinConsultaGrilla(event);
  }

  public FinConsultaGrilla(event: any) {
    this.SetProcesando(false);
    if (this.isSearch == true) {
      this.utility.VerModalOk("Búsqueda Expediente Finalizada");
    } else if (this.isSearch == false) {
      this.utility.VerModalOk("Limpieza Campos Finalizada");
    }
    this.isSearch = null;
  }

  public Buscar(boton) {    
    try {
      this.SetProcesando(true);
      this.componentsContainer.Tables[0].QueryConfig.Entity = this.SetModelo();
      this.componentsContainer.Tables[0].cargarGrilla = true;
      this.componentsContainer.Tables[0]._CargarGrilla(true);
      if(boton == 'btnBuscar'){      
          this.isSearch = true;
        } else {
          this.isSearch = false;
        }
    }
    catch (error) {
      this.SetProcesando(false);
      this.utility.logger.LogError(error, null, { componentName: this.componentName, method: 'onInit' });
    }

  }

  public SetModelo() {
    this.modelo.modelo.FiltroGeneral = '';
    let clone = this.utility.Clone(this.modelo.modelo);
    let filtro = "";
    let filtroGeneral = "";
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdAreaEmpresa', 'AreaEmpresa');
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdSerie', 'Serie');
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdSubSerie', 'SubSerie');    
    this.utility.SetValorPropiedadBusquedaIn(clone, this.modelo.modelo, 'IdUsuarioResponsable', 'UsuarioResponsable');

    if (this.SearcheableConfigs && this.SearcheableConfigs.length > 0) {

      let i: number = 0;
      let logicalOp = "";
      let logicalOp_ = "";
      $.each(this.modelo.modelo, (key, value: any) => {

        const config = this.SearcheableConfigs.find(x => x.Name == key);

        if (config) {

          if (this.modelo.modelo[key] || this.IsNullableOp(config.Op)) {

            if (i > 0 && filtro) {
              filtro += " " + logicalOp + " ";
              filtroGeneral += " " + logicalOp_ + " ";
            }


            config.Value = this.modelo.modelo[key];
            const filter = this.GetFiltro(config);
            filtro += filter.filtro;
            filtroGeneral += filter.filtroGeneral;

            logicalOp = ((config.LogicOp == "Or") ? "OR" : "AND");
            logicalOp_ = ((config.LogicOp == "Or") ? "<strong>  O  </strong>" : "<strong>  Y  </strong>");
          }

          clone[key] = undefined;
          if (key == 'IdAreaEmpresa')
            clone.AreaEmpresa = undefined;
          else if (key == 'IdSerie')
            clone.Serie = undefined;
          else if (key == 'IdSubSerie')
            clone.SubSerie = undefined;
          else if (key == 'IdUsuarioResponsable')
            clone.UsuarioResponsable = undefined;

        }
        else {
          //logicalOp = "AND";
        }

        i++;
      });
      clone.Filtro = filtro;
      this.modelo.modelo.FiltroGeneral = filtroGeneral;

      this.modelo.modelo.Filter = filtro;
      
    }
    else {

    }   
    clone.FiltroMetadatos = null;
    if (this.modelo.modeloMetadata && this.modelo.modeloMetadata.Items && this.modelo.modeloMetadata.Items.Count()) {
      this.modelo.modeloMetadata.Items.ToArray().forEach(element => {                
        if (element.Value != null && (element.DataType != 'Boolean' ? element.Value.trim().length > 0 : element.Value)) {

          if (clone.FiltroMetadatos) {
            clone.FiltroMetadatos += " OR ";
          }
          else
            clone.FiltroMetadatos = '';

          clone.FiltroMetadatos += " (TRegistroVersionSubSeriePaginaMetadatosDetalle.TipoDato  = '" + element.DataType + "' AND ";
          clone.FiltroMetadatos += " TRegistroVersionSubSeriePaginaMetadatosDetalle.NombreControl = '" + element.Name + "' ";

          switch (element.DataType) {
            case "Int32":
            case "Int64":
            case "Int16":
              //ValorEntero
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorEntero = " + element.Value + ")";
              break;
            case "Decimal":
            case "Number":
              //ValorNumero
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorNumero = " + element.Value + ")";
              break;
            case "Boolean":
              //ValorBoolean
              if (element.Value == 'true' || element.Value == 1)
                clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorBoolean = 1)";
              else
                clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorBoolean = 0)";
              break;
            case "DateTime":
              //ValorFecha
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorFecha = CONVERT(VARCHAR(10),'" + element.Value + "',103))";              
              break;
            case "String":
              //ValorTextoCuatrocientos
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorTextoCuatrocientos = '" + element.Value + "')  ";
              break;
            case "SemiLargeString":
              //ValorTextoMil
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorTextoMil = '" + element.Value + "')  ";
              break;
            case "LargeString":
            case "Json":
              //ValorObservaciones
              clone.FiltroMetadatos += " AND TRegistroVersionSubSeriePaginaMetadatosDetalle.ValorObservaciones = '" + element.Value + "')  ";
              break;
          }
        }

      });
    }

    return {
      IdEmpresa: -1,
      IdAreaEmpresaUsuario: -1,
      IdAreaEmpresa: clone.IdAreaEmpresa,
      AreaEmpresa: clone.AreaEmpresa,
      IdSerie: clone.IdSerie,
      Serie: clone.Serie,
      IdSubSerie: clone.IdSubSerie,
      SubSerie: clone.SubSerie,
      Codigo: clone.Codigo,
      Nombre: clone.Nombre,
      PalabrasClave: clone.PalabrasClave,
      FechaCreacionInicial: clone.FechaCreacionInicial,
      FechaCreacionFinal: clone.FechaCreacionFinal,
      FechaFinalInicial: clone.FechaFinalInicial,
      Extensiones: clone.Extensiones,
      FechaFinalFinal: clone.FechaFinalFinal,
      IdUsuarioResponsable: clone.IdUsuarioResponsable,
      UsuarioResponsable: clone.UsuarioResponsable,
      FiltroMetadatos: clone.FiltroMetadatos,
      Filtro: clone.Filtro,
    };
  }

  _Guardar(form: FormGroup, configButtons?: any) {    
    /**
     * Inicio Edward Morales 13032023
     * Limpiar modelo para Iniciar Transferencia
     */
    if (this.Pagina.Pagina == 'SolicitarTransferencias') {
      delete this.modelo.modelo.IdFlujoTransferencias_1;
      delete this.modelo.modelo.Expedientes;
    }
    
    if (configButtons && configButtons.Name == "Btn_Aplazar_save"){
      this.ProcesoAplazar();
      super.Limpiar();
    }
    else
      super._Guardar(form);
  }
}
